import {
	Box,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { StudentResultStyle } from "../../../styles/StudentResultStyle";
import clsx from "clsx";
import { useTranslation } from 'react-i18next';

export const RubricItemRow = (props) => {
	const classes = useEmotionStyles( StudentResultStyle );
	const { itemInfo } = props;
	const i18next = useTranslation();

	return (
		<TableRow >
			{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
			<TableCell width='8%' align="center" className={classes.rubricScoreBgColor}>
				<Typography>{itemInfo.name}</Typography>
			</TableCell>
			<TableCell width='24%' className={classes.rubricScoreBgColor}>
				<Typography className={classes.rubricText}><span>{itemInfo.score}</span>{i18next.t("점")}</Typography>
			</TableCell>
			<TableCell width='50%' align="left" className={classes.borderLine}>
				<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
					<Typography className={classes.tableText}>
						{itemInfo.standard}
					</Typography>
				</Box>
			</TableCell>
		</TableRow>
	);
};