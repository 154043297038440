
// TODO - All constructors should take a pointer, plus a private token

import { NeoPDFWorkerEmployer } from "../PdfWorkerEmployerClass";
import { finalizer } from "./finalizer";
// import { uuidv4 } from "./UtilFuncs";


export class EmployerDisposable {
  public name: string;

  // public _uuid: string = uuidv4();

  public employer: NeoPDFWorkerEmployer = undefined;

  // public ctx: number = null;

  protected _fzId: string = null;

  private _ctxId: string = null;

  public get ctxId() { return this._ctxId; }

  public set ctxId(id: string) {
    this._ctxId = id;
  }

  set fzId(id: string) {
    finalizer.unregister(this);
    finalizer.register(this, () => {
      // console.log(`finalizer: ${this.constructor.name}(${this._uuid})`);
      this.dropFunction(id);
    }, this);
    this._fzId = id;
  }

  get fzId() { return this._fzId; }

  // public _ready: Promise<number> = new Promise((r1, r2) => { });

  protected dropFunction: any;

  constructor(employer: NeoPDFWorkerEmployer, ctxId: string, fzId: string, dropFunction: any, nullable = true) {
    this.employer = employer;
    this._fzId = fzId;

    if (!fzId && !nullable) {
      throw new Error("ID is null");
    }

    this.ctxId = ctxId;

    this.dropFunction = dropFunction;

    // console.log(`${this.constructor.name}: ${dropFunction}`);

    // if (typeof pointer !== "number" || pointer === 0)
    //   throw new Error(`cannot create ${this.constructor.name}: invalid pointer param value '${pointer}'`);
    if (dropFunction == null)
      throw new Error(`cannot create ${this.constructor.name}: dropFunction is null`);
    if (typeof dropFunction !== "function")
      throw new Error(`cannot create ${this.constructor.name}: dropFunction value '${dropFunction}' is not a function`);

    finalizer.register(this, () => {
      // console.log(`finalizer: ${this.constructor.name}(${this._uuid})`);
      dropFunction(fzId)
    }, this);
  }

  protected async superFree() {
    finalizer.unregister(this);
    if (this.fzId?.length) {
      const fzId = this.fzId;
      this.fzId = null;

      // console.log(`finalizer: ${this.constructor.name}, ${(this as any).pageIndex}, (${this._uuid})`);
      await this.dropFunction(fzId);
    }
  }

  get id() {
    return this._fzId;
  }

  toString() {
    return `[${this.constructor.name} ${this.fzId}]`;
  }
}
