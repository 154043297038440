/**
 *
 *
 * @export
 * @enum {number}
 */
export enum PenEventName {
  /**
   * stroke: NeoStroke
   * strokeKey: string
   * inputType: INeoSmartpenType,
   * mac: string
   * pen: INeoSmartPen,
   * section: number,
   * owner: number,
   * book: number,
   * page: number,
   * */
  ON_PEN_UP = "pen_up",
  ON_CALIBRATION_PEN_UP = "cal_up",

  /**
   * inputType: INeoSmartpenType,
   * strokeKey: string,
   * mac: string
   * stroke: NeoStroke,
   * dot: NeoDot,
   * pen: INeoSmartPen,
   * event: IPenCommEvent
   */
  ON_PEN_MOVE = "pen_move",
  ON_CALIBRATION_PEN_MOVE = "cal_move",

  /**
   * inputType: INeoSmartpenType,
   * pen: INeoSmartPen,
   * mac: string,
   * event: IPenCommEvent
   */
  ON_HOVER_MOVE = "hover_move",
  ON_CALIBRATION_HOVER_MOVE = "cal_hover_move",

  /**
   * strokeKey: string
   * mac: string
   * time: number
   * stroke: NeoStroke
   * pen: INeoSmartPen,
   * inputType: INeoSmartpenType,
   */
  ON_PEN_DOWN = "pen_down",

  ON_CALIBRATION_PEN_DOWN = "cal_down",

  /**
   * strokeKey: string,
   * mac: string,
   * stroke: NeoStroke,
   * section: number,
   * owner: number,
   * book: number,
   * page: number,
   * time: number
   */
  ON_PEN_PAGEINFO = "pen_page_info",

  /**
   * mac: string,
   * section: number,
   * owner: number,
   * book: number,
   * page: number,
   * time: number
   */
  ON_PEN_HOVER_PAGEINFO = "hover_page_info",

  /**
   * inputType: INeoSmartpenType,
   * pen: INeoSmartPen,
   * mac: string,
   * event: IPenCommEvent
   */
  ON_PW_REQUIRED = "pw_req",

  /**
   * inputType: INeoSmartpenType,
   * pen: INeoSmartPen,
   * mac: string,
   * event: IPenCommEvent
   */
  ON_PW_SET_COMPLETED = "pw_comp",

  /**
   * inputType: INeoSmartpenType,
   * pen: INeoSmartPen,
   * mac: string,
   * event: IPenCommEvent
   */
  ON_UPGRADE_NEEDED = "fw_up",

  /**
   * inputType: INeoSmartpenType,
   * pen: INeoSmartPen,
   * mac: string,
   * event: IPenCommEvent
   */
  ON_DISCONNECTED = "disconnected",

  /**
   * inputType: INeoSmartpenType,
   * pen: INeoSmartPen,
   * mac: string,
   * event: IPenCommEvent
   */
  ON_CONNECTED = "connected",

  /**
   * inputType: INeoSmartpenType,
   * pen: INeoSmartPen,
   * mac: string,
   * event: IPenCommEvent
   */
  ON_NCODE_ERROR = "error",

  // ON_CALIBRATION_UP = "calibration_up",

  // ON_ERASER_MOVE = "erasermove",

  // ON_START_TO_CONNECT = "start_to_connect",

  /**
   * pen move 도중 페이지가 바뀜에 따라 생성하는 virtual event
   *
   * virual pen down/up은, 펜 스트로크가 이어지고 있음에도 페이지가 바뀌는 경우에 발생한다
   * 잉크 스토리지에는 각각의 페이지에 새로운 스트로크로 등록되도록 설계되어 있다
   *
   * 이 이벤트를 분리하는 이유는, post it 등, 아래의 종이와 겹쳐진 상태에서, 그 위치를 파악하기 위함이다
   *
   * strokeKey: string
   * mac: string
   * time: number
   * stroke: NeoStroke
   */
  ON_PEN_DOWN_VIRTUAL = "pen_down_virtual",

  /**
   * pen move 도중 페이지가 바뀜에 따라 생성하는 virtual event
   *
   * stroke: NeoStroke
   * strokeKey: string
   * mac: string,
   * pen: INeoSmartPen,
   * inputType: INeoSmartpenType,
   * section: number,
   * owner: number,
   * book: number,
   * page: number,
   */
  ON_PEN_UP_VIRTUAL = "penup_virtual",

  // TODO: 확인할 것
  /**
   * ??????
   * PenManager.ts:283
   */
  ON_COLOR_CHANGED = "color_changed",

  ON_THICKNESS_CHANGED = "thickness_changed",

  /**
   * 2021/06/13
   *
   * mac: string
   * pen: INeoSmartPen,
   * inputType: INeoSmartpenType,
   * writerId: string,
   */
  ON_WRITER_CHANGED = "writer_changed",

  /**
   * 2021/06/13
   *
   * mac: string
   * pen: INeoSmartPen,
   * inputType: INeoSmartpenType,
   * surfaceOwnerId: string,
   */
  ON_SURFACE_OWNER_CHANGED = "surface_owner_changed",

  /**
   * this는 펜
   *
   * stroke: NeoStroke
   * strokeKey: string
   * inputType: INeoSmartpenType,
   * mac: string
   * pen: INeoSmartPen,
   * section: number, owner: number, book: number, page: number,
   */
  ON_STROKE_RECEIVED = "on_stroke_received",

  /**
   * 전달되는 이벤트 속성, 2021/07/05
   *
   * inputType: INeoSmartpenType,
   * puiCommand: cmd - string,
   * pen: INeoSmartPen,
   * mac: string,
   * stroke: NeoStroke
   */
  ON_PUI_COMMAND = "on_pui_touched",

  /**
   * 2021/08/18
   * 펜 상태 정보 수신 시
   */
  ON_PEN_STATUS_RESPONSE = "pen_status",

  /**
   * 2022/10/13
   * 장치 발견시
   *
   * IDiscoveredDevices[]
   */
  ON_DEVICE_DISCOVERY_INFO_UPDDATED = "discovered",

  /**
   * 2024/05/02
   * 스캔 종료시
   */
  ON_DEVICE_DISCOVERY_FINISHED = "discovery_finished",

  /**
   * 2023/06/28
   * Echo Mode 등 펜 퍼포먼스 관련 기능 유무
   */
  ON_PEN_SYSTEM_PERFORMANCE_RESPONSE = "pen_system_performance",

  /**
   * 2023/06/30
   * 펌웨어 업그레이드 시작 시
   */
  ON_FIRMWARE_UPGRADE_STARTED = "fw_upgrade_started",

  /**
   * 2023/06/30
   * 펌웨어 업그레이드 시작 시
   */
  ON_FIRMWARE_UPGRADE_PROGRESS = "fw_upgrade_progress",

  /**
   * 2023/12/05
   * 장치가 꺼질 시
   */
  ON_SHUTDOWN_EVENT = "shutdown",

  /**
   * 2023/12/05
   * 장치의 배터리가 일정 이하일 시
   */
  ON_LOW_BATTERY_EVENT = "low_battery",

}
