import { EventDispatcher } from "./EventDispatcher";

export type IEvent<T, A> = {
  eventName: T,
} & A;

class EventClassBase<EVENT_NAME_ENUM, EVENT_TYPE> {
  dispatcher: EventDispatcher = new EventDispatcher();


  public setEventListener = (eventName: EVENT_NAME_ENUM, listener: (event: EVENT_TYPE) => void, filter?: any) => {
    this.dispatcher.set(eventName as string, listener, filter);
  }

  public addEventListener = (eventName: EVENT_NAME_ENUM, listener: (event: EVENT_TYPE) => void, filter?: any) => {
    this.dispatcher.add(eventName as string, listener, filter);
    // console.log("bound", listener);
  }

  public removeEventListener = (eventName: EVENT_NAME_ENUM, listener: (event: EVENT_TYPE) => void) => {
    this.dispatcher.remove(eventName as string, listener);
  }

  public removeSpecificEventListener = (eventName: EVENT_NAME_ENUM) => {
    this.dispatcher.remove(eventName as string);
  }


  public removeEventListenerAll = () => {
    this.dispatcher.offAll();
  }

  public dispose = () => {
    this.removeEventListenerAll();
    delete this.dispatcher;
    this.dispatcher = undefined;
  }
}

export default EventClassBase;
