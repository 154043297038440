import { useMemo } from 'react';
import ProjectExamStore from '../../../../stores/ProjectExamStore';
import { IExamInfo } from '../../../../repositories/model/transfer/IExamInfo';
import { IQuestionInfo } from '../../../../repositories/model/transfer/IQuestionInfo';
import { ChangeFlag } from '../../../../repositories/model/support/ChangeFlag';

// const currentOrder = React.useMemo(() => {
// 	const questionListLen = examInfo.questions.filter(q => q.changeFlag !== FlagType.DELETED).length;
// 	return projectExamStore.setQuestionFullOrderByQuestionListLen(questionListLen, examInfo.order, questionInfo.order);
// }, [examInfo, examInfo.order, questionInfo.order]);


export function useCurrentOrder(
	projectExamStore: ProjectExamStore,
	examInfo: IExamInfo,
	questionInfo: IQuestionInfo
) {
	return useMemo(() => {
		const questionListLen = examInfo.questions.filter(q => q.changeFlag !== ChangeFlag.DELETED).length;
		const orderString = projectExamStore.setQuestionFullOrderByQuestionListLen(
			questionListLen,
			examInfo.order,
			questionInfo.order
		);

		return orderString;
	}, [examInfo.questions, examInfo.order, projectExamStore, questionInfo.order]);
}

// 사용 예시
// const currentOrder = useCurrentOrder(projectExamStore, examInfo, questionInfo);
