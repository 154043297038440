import NeoSmartpen from "./Pens/NeoSmartpen";
import {
	DEFAULT_PEN_RENDERER_TYPE,
	DEFAULT_PEN_THICKNESS,
	EventClassBase,
	IBrushEnum,
	IDiscoveredDevices,
	INeoSmartpen,
	INeoSmartpenType,
	IPenCommEvent,
	IPenIdType,
	IPenManager,
	IPenToViewerEvent,
	NEO_SMARTPEN_TYPE,
	PenEventName,
} from "../../../nl-lib3-common";
import { DotNetApi } from "../nl-lib-maui/maui-bridge/js-to-maui";
import { BluetoothTypeEnum } from "../nl-lib-bt-devices";
import VirtualPen from "./Pens/VirtualPen";

// 네오스튜디오 색상
export const NeoStudioPenColors = {
	DARK_GRAY: '#AAAAAA',
	RED: '#FF0200',
	YELLOW: '#FFD001',
	NAVY: '#012EE2',
	BLACK: '#000000',
	LIGHT_GRAY: '#E5E5E5',
	ORANGE: '#FF6500',
	GREEN: '#3CDD00',
	BLUE: '#00ABEB',
	PURPLE: '#6C00E2',
};

export type OnDeviceDiscovered = (event: IPenToViewerEvent) => void;


export default class PenManager extends EventClassBase<PenEventName, IPenToViewerEvent> implements IPenManager {
	static pm_instance = null as IPenManager;
	active_pen: INeoSmartpen = null;

	private _pens: INeoSmartpen[] = [];
	private _currentPen: INeoSmartpen;

	/** ID+MAC address to pen */
	private _mactoPenItem: { [key: string]: INeoSmartpen } = {};

	private userId: string = null;

	private constructor() {
		super();
	}

	public static getInstance() {
		if (PenManager.pm_instance) return PenManager.pm_instance;

		PenManager.pm_instance = new PenManager();
		return PenManager.pm_instance;
	}

	public static get instance() {
		return this.getInstance();
	}



	// UserStore.ts:162 에 Id를 세팅하는 부분이 있다.
	public setUserId(userId: string) {
		this.userId = userId;
	}

	public getUserId() {
		return this.userId;
	}

	getPens = () => this._pens.filter((pen) => pen.connected);


	// 기존 색상
	// penColors = {
	//   DARK_GRAY: '#A9A9A9', // rgb(169, 169, 169)',
	//   RED: '#FF0000', //rgb(255, 0, 0)',
	//   YELLOW: '#FFFF02', //rgb(255, 255, 2)',
	//   NAVY: '#000080', // rgb(0, 0, 128)', // 3 NAVY #FF012EE2
	//   BLACK: '#000000', //rgb(0, 0, 0)', // 4 BLACK #FF000000
	//   LIGHT_GRAY: '#D3D3D3', // rgb(255, 255, 255)', // 5 LIGHT_GRAY #FFE5E5E5
	//   ORANGE: '#FFA500', // rgb(255, 165, 0)', // 6 ORANGE #FFFF6500#FFA500
	//   GREEN: '#008000', // rgb(0, 128, 0)', // 7 GREEN #FF3CDD00
	//   BLUE: '#0000FF', // rgb(0, 0, 255)', // 8 BLUE #FF00ABEB
	//   PURPLE: '#800080', // rgb(128, 0, 128)', // 9 PURPLE #FF6C00E2
	// };


	color: string = NeoStudioPenColors.BLACK;

	thickness: number = DEFAULT_PEN_THICKNESS;

	penRendererType: IBrushEnum = DEFAULT_PEN_RENDERER_TYPE;

	calibrationPoint = { section: 0, owner: 0, book: 0, page: 0, x: 0, y: 0 };

	calibrationData = {
		section: 0,
		owner: 0,
		book: 0,
		page: 0,
		points: new Array(0),
	};

	virtualPenSerial = 0;

	_inCalibration: boolean;

	_virtualPen: INeoSmartpen;

	_enablePenEvent: boolean = true;

	init = () => {
		this.setThickness(DEFAULT_PEN_THICKNESS);
		this.setPenRendererType(DEFAULT_PEN_RENDERER_TYPE);
		this.setColor(NeoStudioPenColors.BLACK);
	};

	/**
	 *
	 */
	private addPenEvent = (pen: INeoSmartpen) => {
		pen.addEventListener(PenEventName.ON_CONNECTED, this.onConnected);
		pen.addEventListener(PenEventName.ON_DISCONNECTED, this.onDisconnected);
		pen.addEventListener(PenEventName.ON_PEN_PAGEINFO, this.onLivePenPageInfo);

		pen.addEventListener(PenEventName.ON_SURFACE_OWNER_CHANGED, this.onSurfaceOwnerIdChanged);
		pen.addEventListener(PenEventName.ON_WRITER_CHANGED, this.onWriterIdChanged);

		pen.addEventListener(PenEventName.ON_PUI_COMMAND, this.onPuiCommand);

		// 2021/08/18
		pen.addEventListener(PenEventName.ON_PEN_STATUS_RESPONSE, this.onPenStatusResponse);
		pen.addEventListener(PenEventName.ON_PW_REQUIRED, this.onPasswordRequired);

		// 2022/09/18
		pen.addEventListener(PenEventName.ON_PEN_DOWN, this.onPenDown);
		pen.addEventListener(PenEventName.ON_PEN_UP, this.onPenUp);
		pen.addEventListener(PenEventName.ON_PEN_MOVE, this.onPenMove);
		pen.addEventListener(PenEventName.ON_HOVER_MOVE, this.onPenHover);

		pen.addEventListener(
			PenEventName.ON_PEN_SYSTEM_PERFORMANCE_RESPONSE,
			this.onPenSystemPerformanceStatusResponse
		);
		pen.addEventListener(PenEventName.ON_FIRMWARE_UPGRADE_STARTED, this.onfirmwareUpgradeStart);
		pen.addEventListener(PenEventName.ON_FIRMWARE_UPGRADE_PROGRESS, this.onfirmwareUpgradeProgress);

		pen.addEventListener(PenEventName.ON_PW_SET_COMPLETED, this.onPasswordSetupCompleted);

		pen.addEventListener(PenEventName.ON_SHUTDOWN_EVENT, this.onShutDownEvent);
		pen.addEventListener(PenEventName.ON_LOW_BATTERY_EVENT, this.onLowBatteryEvent);
	};

	// eslint-disable-next-line class-methods-use-this
	private removePenEvent = (pen: INeoSmartpen) => {
		pen.removeEventListenerAll();
	};

	// eslint-disable-next-line arrow-body-style
	public createPen = (args: {
		userId: string,
		bluetoothType: BluetoothTypeEnum
	}): INeoSmartpen => {
		const { userId, bluetoothType = BluetoothTypeEnum.WEB } = args;
		if (bluetoothType === BluetoothTypeEnum.WEB) {
			return this.createWebBluetoothPen(userId);
		}

		if (bluetoothType === BluetoothTypeEnum.MAUI) {
			return this.createMauiBluetoothPen(userId);
		}

		return undefined;
	};

	public createWebBluetoothPen = (userId: string): INeoSmartpen => {
		const pen = new NeoSmartpen({ manager: this, bluetoothType: BluetoothTypeEnum.WEB, userId });
		this.addPenEvent(pen);

		return pen;
	};

	public createMauiBluetoothPen = (userId: string): INeoSmartpen => {
		const pen = new NeoSmartpen({ manager: this, bluetoothType: BluetoothTypeEnum.MAUI, userId });
		this.addPenEvent(pen);

		return pen;
	};

	public registerCustomPen = (pen: INeoSmartpen): INeoSmartpen => {
		this.addPenEvent(pen);

		return pen;
	};


	public getRegistedPen = (arg: {
		webBluetoothId: string;
		inputType: INeoSmartpenType;
		mac: string;
	}) => {
		const penUID = this.getPenUID(arg);
		return this._mactoPenItem[penUID];
	};

	// eslint-disable-next-line arrow-body-style, class-methods-use-this
	private getPenUID = (opt: {
		webBluetoothId: string;
		inputType: INeoSmartpenType;
		mac: string;
	}) => {
		return `${opt.webBluetoothId}_${opt.inputType.type}_${opt.inputType.local}_${opt.mac}`;
	};

	onLivePenPageInfo = (event: IPenToViewerEvent) => {
		if (this._enablePenEvent) {
			this.dispatcher.dispatch(PenEventName.ON_PEN_PAGEINFO, event);
		}
	};

	/**
	 *
	 * @param pen
	 * @param device
	 */
	public add = (pen: INeoSmartpen) => {
		if (!pen.mac) {
			console.error(`MAC address of pen is not registered`);
			return { pen, newlyAdded: false };
		}

		let newlyAdded = false;
		// console.log(`Pen Added, ${pen.webBluetoothId}`);
		const penUID = this.getPenUID({
			webBluetoothId: pen.webBluetoothId,
			mac: pen.mac,
			inputType: pen.inputType,
		});

		const found = this._mactoPenItem[penUID];
		if (!found) {
			this._pens.push(pen);
			this._mactoPenItem[penUID] = pen;

			newlyAdded = true;
			console.log(`PenManager: pen added, mac=${pen.mac}, pens=${this._pens.length}`);
		}

		// console.log(`PenManager: pen added, mac=${pen.mac}`);
		return { pen, newlyAdded };
	};

	/**
	 *
	 * @param device
	 */
	public isBtPenAlreadyConnected = (deviceId: IPenIdType): boolean => {
		const index = this._pens.findIndex((pen) => {
			const idMatched = (pen.webBluetoothId === deviceId) && pen.connected;
			return idMatched;
		});
		if (index > -1) return true;

		return false;
	};

	/**
	 *
	 * @param pen
	 */
	private removePen = (pen: INeoSmartpen) => {
		const penUID = this.getPenUID({
			webBluetoothId: pen.webBluetoothId,
			mac: pen.mac,
			inputType: pen.inputType,
		});

		const found = this._mactoPenItem[penUID];
		if (found) {
			this.removePenEvent(found);
		}

		if (this.getActivePen() === found) {
			this.active_pen = null;
		}
		// const index = this._pens.findIndex(p => p.mac === pen.mac);
		const index = this._pens.findIndex((p) => p === found);

		if (index > -1) {
			// const pen = this._pens[index];
			this._pens.splice(index, 1);

			delete this._mactoPenItem[penUID];
			return true;
		}

		return false;
	};

	public getActivePen = () => {
		return this.active_pen;
	};

	setActivePen = (mac: string) => {
		const pen = this._pens.find((p) => p.mac === mac);
		if (pen) {
			this.active_pen = pen;
		}
	};

	public getConnectedPenByMac = (mac: string) => {
		const connected = this.getConnectedPens();
		const pen = connected.find((p) => p.mac === mac);
		if (pen) {
			return pen;
		}
	};

	setColor = (color: string) => {
		if (this.active_pen) {
			this.active_pen.setColor(color);
			this.dispatcher.dispatch(PenEventName.ON_COLOR_CHANGED, this);
		}
	};

	setPenRendererType = (type: IBrushEnum) => {
		this.penRendererType = type;

		if (this.active_pen) {
			this.active_pen.setPenRendererType(this.penRendererType);
			this.active_pen.setColor(this.color);
		}
	};

	setThickness = (thickness: number) => {
		// $("#thickness_num").text(thickness);

		thickness /= 10;

		if (this.active_pen) {
			this.active_pen.setThickness(thickness);
			this.dispatcher.dispatch(PenEventName.ON_THICKNESS_CHANGED, this);
		}
	};

	public setEnablePenEvent = (enabled: boolean) => {
		this._enablePenEvent = enabled;
	}

	public getEnablePenEvent = () => {
		return this._enablePenEvent;
	}

	/**
	 *
	 *
	 * @param {IPenToViewerEvent} event
	 * @memberof PenManager
	 */
	public onConnected = (event: IPenToViewerEvent) => {
		const { pen } = event;
		pen.connected = true;

		const mac = pen.protocolHandler.getMac();
		const { webBluetoothId } = pen;
		this.add(pen);
		this.dispatcher.dispatch(PenEventName.ON_CONNECTED, event);
	};

	/**
	 *
	 *
	 * @param {IPenToViewerEvent} event - {pen: INeoSmartpen, mac: string, event: IPenCommEvent}
	 * @memberof PenManager
	 */
	public onDisconnected = (event: IPenToViewerEvent) => {
		const { pen } = event;

		if (this.removePen(pen)) {
			// 연결된 상위 listener에 전달
			this.dispatcher.dispatch(PenEventName.ON_DISCONNECTED, event);
		} else {
			console.log('PenManager: something wrong, un-added pen disconnected');
		}
		// $("#pen_id").text(`${this.penArray.length}`);
		// if (this.penArray.length === 0) {
		//   const $elem = $("#btn_connect").find(".c2");
		//   $elem.removeClass("checked");
		// }
	};

	public onPenDown = (event: IPenToViewerEvent) => {
		if (this._enablePenEvent) {
			this.dispatcher.dispatch(PenEventName.ON_PEN_DOWN, event);
		}
	};

	public onPenMove = (event: IPenToViewerEvent) => {
		if (this._enablePenEvent) {
			this.dispatcher.dispatch(PenEventName.ON_PEN_MOVE, event);
		}
	};

	public onPenUp = (event: IPenToViewerEvent) => {
		if (this._enablePenEvent) {
			this.dispatcher.dispatch(PenEventName.ON_PEN_UP, event);
		}
	};

	public onPenHover = (event: IPenToViewerEvent) => {
		if (this._enablePenEvent) {
			this.dispatcher.dispatch(PenEventName.ON_HOVER_MOVE, event);
		}
	};

	// eslint-disable-next-line class-methods-use-this,
	public onNcodeError = (opt: { pen: INeoSmartpen; event: IPenCommEvent }) => {
		// const { pen, event } = opt;
	};

	public onSurfaceOwnerIdChanged = (e: IPenToViewerEvent) => {
		this.dispatcher.dispatch(PenEventName.ON_SURFACE_OWNER_CHANGED, e);
	};

	public onWriterIdChanged = (e: IPenToViewerEvent) => {
		this.dispatcher.dispatch(PenEventName.ON_WRITER_CHANGED, e);
	};

	public onPuiCommand = (e: IPenToViewerEvent) => {
		this.dispatcher.dispatch(PenEventName.ON_PUI_COMMAND, e);
	};

	public onPenStatusResponse = (event: IPenToViewerEvent) => {
		// const status = event.event.status;
		this.dispatcher.dispatch(PenEventName.ON_PEN_STATUS_RESPONSE, event);
	};

	public onPasswordRequired = (event: IPenToViewerEvent) => {
		if (!this.dispatcher.hasListener(PenEventName.ON_PW_REQUIRED)) {
			const { pen } = event;
			const { passcodeStatus } = event.pencommEvent;
			const { retryCount, retryLimit } = passcodeStatus;

			let passcode = "0000";
			while (passcode.length !== 4 || passcode === "0000") {
				passcode = prompt(pen.passwordRequestMsg(retryCount, retryLimit));
				if (!passcode) {
					pen.disconnect();
					return;
				};
			}

			pen.sendPasscode(passcode);
		} else {
			this.dispatcher.dispatch(PenEventName.ON_PW_REQUIRED, event);
		}
	};

	public onPasswordSetupCompleted = (event: IPenToViewerEvent) => {
		this.dispatcher.dispatch(PenEventName.ON_PW_SET_COMPLETED, event);
	};

	public setPasswordPromptCallback = (listener: (event: IPenToViewerEvent) => void) => {
		this.addEventListener(PenEventName.ON_PW_REQUIRED, listener);
	};

	public onPenSystemPerformanceStatusResponse = (event: IPenToViewerEvent) => {
		this.dispatcher.dispatch(PenEventName.ON_PEN_SYSTEM_PERFORMANCE_RESPONSE, event);
	};

	public onfirmwareUpgradeStart = (event: IPenToViewerEvent) => {
		this.dispatcher.dispatch(PenEventName.ON_FIRMWARE_UPGRADE_STARTED, event);
	};

	public onfirmwareUpgradeProgress = (event: IPenToViewerEvent) => {
		this.dispatcher.dispatch(PenEventName.ON_FIRMWARE_UPGRADE_PROGRESS, event);
	};

	public onShutDownEvent = (event: IPenToViewerEvent) => {
		this.dispatcher.dispatch(PenEventName.ON_SHUTDOWN_EVENT, event);
	};

	public onLowBatteryEvent = (event: IPenToViewerEvent) => {
		this.dispatcher.dispatch(PenEventName.ON_LOW_BATTERY_EVENT, event);
	};

	/**
	 * 2021/08/18
	 *
	 * 패스워드 처리 custom UI의 절차
	 * 1) PenEventName.ON_PW_REQUIRED 의 이벤트를 받아서
	 * 2) password를 입력 받고
	 * 3) PenManager.getInstance().sendPasscode( event.pen, passcode )
	 *
	 * @param pen
	 * @param passcode
	 */
	// eslint-disable-next-line class-methods-use-this
	public sendPasscode = (pen: INeoSmartpen, passcode: string) => {
		pen.sendPasscode(passcode);
	};

	/**
	 *
	 */
	getConnectedPens = (): INeoSmartpen[] => {
		const connected = this._pens.filter((pen) => pen.connected);
		return connected;
	};

	get isCalibrationMode() {
		return this._inCalibration;
	}

	setCalibrationMode = (mode) => {
		this._inCalibration = mode;
	};


	/**
	 * for web view pen (via Mobile SDK)
	 */
	private getPen = (mac: string) => {
		const pen = this.getRegistedPen({
			mac,
			webBluetoothId: "sdk-pen", // WebViewPen.generateWebBluetoothId(),
			inputType: NEO_SMARTPEN_TYPE.SDK_PEN, // WebViewPen.getInputType(),
		});

		return pen;
	};


	/**
	 * for web view pen (via Mobile SDK)
	 */
	onWebViewPenDisconnected = (e: {
		mac: string;
		pen: INeoSmartpen;
		timestamp?: number; // TODO: SDK에서 이게 올라오는지 확인 부탁, 2021/07/26
	}) => {
		const timeStamp = e.timestamp ? e.timestamp : new Date().getTime();
		const pen = this.getPen(e.mac);
		if (pen) {
			pen.onDisconnected({
				mac: e.mac,
				timeStamp,
			});
		}
	};


	private discovered: IDiscoveredDevices[] = [];

	public setDeviceDiscovered(devices: IDiscoveredDevices[]) {
		this.discovered = devices;

		const event: IPenToViewerEvent = {
			inputType: undefined,
			pen: undefined,
			mac: undefined,
			discoveredInfo: devices,
		};

		this.dispatcher.dispatch(PenEventName.ON_DEVICE_DISCOVERY_INFO_UPDDATED, event);
		console.log(this.discovered);
	}


	public onReportDeviceScanningFinished(devices: IDiscoveredDevices[]) {
		const event: IPenToViewerEvent = {
			inputType: undefined,
			pen: undefined,
			mac: undefined,
			discoveredInfo: devices,
		};

		this.dispatcher.dispatch(PenEventName.ON_DEVICE_DISCOVERY_FINISHED, event);
	}





	public addDeviceDiscoveredDelegate(listener: OnDeviceDiscovered) {
		this.addEventListener(PenEventName.ON_DEVICE_DISCOVERY_INFO_UPDDATED, listener);
	}

	public removeDeviceDiscoveredDelegate(listener: OnDeviceDiscovered) {
		this.removeEventListener(PenEventName.ON_DEVICE_DISCOVERY_INFO_UPDDATED, listener);
	}

	public async startScan() {
		await DotNetApi.instance.invoke('StartScan');
	}


	public async stopScan() {
		await DotNetApi.instance.invoke('StopScan');
	}


	private registerVirtualPen(pen: VirtualPen) {
		this.addPenEvent(pen);

		// this.add(pen);  // connect에서 해 준다
		pen.connect();

		this.virtualPenSerial++;
		return pen;
	};

	public getVirtualPen(): INeoSmartpen {
		const { mac, webBluetoothId } = VirtualPen.generateVirualPenMacAndId();
		const penUID = this.getPenUID({ webBluetoothId, mac, inputType: VirtualPen.getInputType() });

		let found = this._mactoPenItem[penUID];

		if (!found) {
			const pen = new VirtualPen({ manager: this });
			// const connectedPens = this._pens.filter( (pen) => pen.inputType.type === "mouse" && pen.inputType.local );
			// if (this._pens.length === 0) {
			this.registerVirtualPen(pen);
			this.setActivePen(pen.mac);
			// }
			found = pen;
		}
		return found;
	};



}

export const penManagerInstance = {
	pm: undefined as unknown as IPenManager,
};

// 초기화
(() => {
	penManagerInstance.pm = PenManager.instance;
})();
