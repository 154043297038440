import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const CompareDataStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
	},
	// emptyBox:{
	//     width: '100%',
	//     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
	//     display:'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center',
	//     '& p':{
	//         fontSize: '1.375rem',
	//         color: '#87888d',
	//         fontWeight:400,
	//         textAlign: 'center',
	//         lineHeight: '30.36px'
	//     }
	// },
	// boxWrap: {
	//     backgroundColor: '#fff',
	//     padding: 20,
	//     borderRadius: 8,
	//     height: 'calc(100vh - 500px)'
	// },

	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			// marginLeft: "10px",
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},


	dialogBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff'
		}
	},


	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},





	/////////////////////
	padding: {
		// padding: 15,
	},
	mainContentBox: {
		// border: '1px solid #CBCBCB',
		// padding: 20,
		// height: 'calc(100vh - 90px)',
		// overflowY: 'auto',
		background: '#fff',
		// margin: '0px 15px',
	},
	// mainContentBoxLef: {
	//     border: '1px solid #CBCBCB',
	//     padding: 20,
	//     height: 'calc(100vh - 130px)',
	//     overflowY: 'auto',
	//     background: '#fff',
	//     width: 'calc(100vw - 770px)',
	// },
	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		overflowY: 'auto',
		background: '#fff',
		width: '620px',
		boxSizing: 'border-box',
		marginLeft: "10px",
	},


	commonSubTitle: {
		marginBottom: "10px",
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
		}
	},
	schoolNameSubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			letterSpacing: '-0.5px',
			marginBottom: "15px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	searchSchoolInput: {
		'& .MuiFormControl-root': {
			maxWidth: 830,
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 50
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},


	headerFont: {
		paddingLeft: '5px',
		fontSize: '1rem',
	},


	greenBtn: {
		marginLeft: "15px",
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008c73',
				fontWeight: 'bold'
			}
		}
	},
	selectText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 'bold',
			marginRight: "15px",
		}
	},

	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1.125rem',
			boxSizing: 'border-box',
			color: '#777',
			letterSpacing: '-0.5px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #87888d',
			borderRadius: '6px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},



	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: '#fff',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
			marginTop: "3px",
		}
	},
	pageNumber: {
		marginTop: "20px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentName: {
		'&.MuiTypography-root': {
			color: '#008C73'
		}
	},
	tableBox: {
		marginBottom: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1rem',
				color: '#323232',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '1rem',
				color: '#323232',
				height: "36px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				borderRight: '1px solid rgba(119, 119, 119, 0.7)',
				borderLeft: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},

	dialogInput: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "280px",
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "300px",
			marginBottom: "20px",
		},
	},
	stepInputBox: {
		'& .MuiIconButton-root': {
			margin: '20px 10px 5px 40px'
		}
	},

	inputStep: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 36
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
			letterSpacing: '-0.5px'
		}
	},
	inputStepMulti: {
		'& .MuiOutlinedInput-root': {
			height: "60px",
		},
		'& .MuiOutlinedInput-input': {
			height: "38px",
		}
	},
	stepText: {
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			color: '#2F80ED',
			marginRight: "20px",
		}
	},

	addSentence: {
		'& .MuiButton-label': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: 'transparent'
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontSize: '0.875rem',
				marginTop: "2px",
			}
		}
	},

	boxList: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FAFAFA',
		borderRadius: 6,
		width: '100%',
		height: "200px",
		margin: '0px 5px',
		padding: 10,
		'& .MuiTypography-root': {
			color: '#6F6F6F',
			fontSize: '0.75rem',
			minHeight: 93,
		},
		'&:hover': {
			outline: '2px solid #56CCF2'
		}
	},
	boxListTitle: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 700,
			marginTop: "5px",
			marginBottom: "5px",
			minHeight: 'auto'
		}
	},
	fileUploadBox: {
		width: '50%',
		background: '#fff',
		height: 'calc(100vh - 45px)',
		// height: 'calc(100vh - 106px)',
		border: '1px solid #CBCBCB',
		position: "relative",
	},
	boardSize: {
		position: 'absolute',
		right: 10,
		bottom: 10,
		display: 'flex',
		flexDirection: 'column'
	},
	fileUploadTitle: {
		marginTop: '-18px',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 500,
			marginBottom: "20px",
		}
	},
	fileUploadText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	fileUploadBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			width: "170px",
			height: "36px",
			borderRadius: 3,
			border: '1px solid #CBCBCB',
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	fileUploadSubText: {
		margin: '15px 0px'
	},
	urlInputBox: {
		'&.MuiPaper-root': {
			width: "600px",
			height: "35px",
			border: '1px solid #CBCBCB',
			marginBottom: "15px",
		},
		'& .MuiInputBase-root': {
			width: "480px",
		},
		'& .MuiInputBase-input': {
			padding: '8px 10px 7px',
		}
	},
	urlBtn: {
		marginLeft: "10px",
		'&.MuiButtonBase-root': {
			width: "82px",
			height: "27px",
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				letterSpacing: '-0.5px',
			}
		}
	},
	urlBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#fff'
			}
		}
	},

	summaryAiBtn: {
		'&.MuiButtonBase-root': {
			background: '#CDCDCD',
			width: '100%',
			marginBottom: "20px",
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold',
			}
		}
	},
	summaryAiBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				color: '#fff',
			}
		}
	},
	aiSubText: {
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
		}
	},
	sideList: {
		position: 'absolute',
		left: 0,
		top: 0,
		background: '#4F4F4F',
		width: "114px",
		height: 'calc(100vh - 190px)',
		// height: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	sideListInner: {
		overflowY: 'auto',
		padding: '10px 7px',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	img: {
		position: 'relative',
		'& img': {
			width: '100%',
			borderRadius: '5px',
			'&:hover': {
				outline: '2px solid #03BF69'
			},
		},

	},
	examInfoIcon: {
		marginLeft: "10px",
		marginBottom: "13px",
	},
	pageNum: {
		position: 'absolute',
		bottom: 5,
		background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.6), #fff)',
		width: '100%',
		borderRadius: '0 0 5px 5px',
		'& .MuiTypography-root': {
			padding: '4px 5px',
			textAlign: 'right'
		}
	},
	newTabHeader: {
		borderBottom: '1px solid #cbcbcb',
		padding: 20,
	},
	newTabContent: {
		padding: '20px 20px 20px 20px',
		background: '#E5E5E9',
		height: 'calc(100vh - 40px)',
		// height: 'calc(100vh - 104px)',
	},
	newTabTitle: {
		'&.MuiTypography-root': {
			color: '#555',
			marginRight: "14px",

		}
	},
	newTabHeaderTitle: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.125rem',
			letterSpacing: '-1px',
			fontWeight: 700,
			marginLeft: "30px",
		}
	},
	newTabName: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.125rem',
			letterSpacing: '-1px'
		}
	},
	newTabTitlePointer: {
		marginLeft: "25px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-13px',
			top: 10,
		},
	},
	newTabSubject: {
		'&.MuiTypography-root': {
			fontWeight: 600
		}
	},
	newTabPage: {
		'&.MuiTypography-root': {
			color: '#008C73',
			fontWeight: 600,
		}
	},
	compareTop: {
		borderBottom: '1px solid #cbcbcb',
		padding: 20,
		height: "30px",
		'& > .MuiTypography-root': {
			color: '#111',
			fontWeight: 700,
			fontSize: '1.25rem'
		}
	},
	compareTopColor: {
		'& > .MuiTypography-root': {
			color: '#008C73',
		}
	},

	recognizedProgress: {
		padding: 10,
		paddingLeft: 20,
		height: "25px",
		overflow: 'hidden',
	},


	compareContent: {
		padding: 20,
		height: 'calc(100vh - 224px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		'&::-webkit-scrollbar': {
			width: '12px',
			height: '12px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	compareRound: {
		border: '1px solid #cbcbcb',
		borderRadius: 10,
		padding: 20,
		marginBottom: "20px",
		background: 'rgba(233, 243, 252, 0.10)',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
		// height: 'calc(100vh - 360px)',
		// overflowY: 'auto',

	},
	editerBox: {
		background: '#f3f3f3',
		padding: 20,
		borderRadius: 10,
		border: '1px solid #cbcbcb',
		marginBottom: "40px",
	},
	editerBoxBtn: {
		display: 'flex',
		justifyContent: 'right',
		'& .MuiButtonBase-root': {
			width: "114px",
			height: "37px",
			border: '1px solid #cbcbcb',
			background: '#fff'
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#2F80ED',
			fontWeight: 700,
		}
	},
	textFieldBox: {
		background: '#fff',
		padding: 10,
		marginBottom: "20px",
	},
	scanToggleBtn: {
		marginBottom: "10px",
		'& .MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginRight: "5px",
		}
	},
	questionList: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			fontWeight: 700,
		}
	},
	questionGuideText: {
		border: '1px solid #111',
		padding: '14px 18px',
		borderRadius: 3,
		marginTop: "10px",
		'& .MuiTypography-root': {
			fontSize: '1.25rem',
			letterSpacing: '-0.4px'
		}
	},
	titleBox: {
		background: '#cbcbcb',
		fontWeight: 700,
		fontSize: '1.125rem'
	},
	compareText: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#2F80ED',
			fontWeight: 700,
			letterSpacing: '-0.5px'
		}
	},
	compareCloseBtn: {
		'&.MuiButtonBase-root': {
			marginLeft: "10px",
			width: "90px",
			height: "40px",
			border: '1px solid #cbcbcb',
			background: '#fff',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#2F80ED',
				fontWeight: 700,
				letterSpacing: '-0.5px',
				"& .disabled": {
					color: '#fff',
				}
			}
		},
		"&.Mui-disabled": {
			background: '#cbcbcb',
			'& .MuiTypography-root': {
				color: '#fff !important', // 비활성화 시 텍스트 색상
			},
		}
	},


	compareTopBtn: {
		'&.MuiButtonBase-root': {
			width: "120px",
			height: "40px",
			border: '1px solid #cbcbcb',
			background: '#fff',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#2F80ED',
				fontWeight: 700,
				letterSpacing: '-0.5px',
				"& .disabled": {
					color: '#fff',
				}
			}
		},
		"&.Mui-disabled": {
			background: '#cbcbcb',
			'& .MuiTypography-root': {
				color: '#fff !important', // 비활성화 시 텍스트 색상
			},
		}
	},
	confirmHandwritingDataBtn: {
		'&.MuiButtonBase-root': {
			width: "200px",
			height: "40px",
			border: '1px solid #cbcbcb',
			background: '#008C73',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#fff',
				fontWeight: 700,
				letterSpacing: '-0.5px'
			},
		},
		"&.Mui-disabled": {
			background: '#cbcbcb',
			'& .MuiTypography-root': {
				color: '#fff !important', // 비활성화 시 텍스트 색상
			},
		}
	},

	selectOtherStudentBtn: {
		'&.MuiButtonBase-root': {
			width: "180px",
			height: "40px",
			border: '1px solid #cbcbcb',
			background: '#fff',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#2F80ED',
				fontWeight: 700,
				letterSpacing: '-0.5px'
			}
		},
		"&.Mui-disabled": {
			background: '#cbcbcb',
			'& .MuiTypography-root': {
				color: '#fff !important', // 비활성화 시 텍스트 색상
			},
		}
	},
	questionInputBox: {
		'& .MuiFormControl-root': {
			width: '100%',
			height: "72px",
			boxSizing: 'border-box',
			background: '#fff',
			marginBottom: "10px",
			marginTop: "15px",
		},
		'& .MuiOutlinedInput-root': {
			height: "72px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		},
		'& .MuiInputBase-input': {
			fontSize: '1.25rem',
			color: '#787878',
			letterSpacing: '-0.7px',
		}
	},
	bestAnswerBox: {
		border: '2px solid #111',
		borderRadius: 3,
		height: "68px",
		marginTop: "34px",
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
		'& .MuiTypography-root': {
			padding: '18px 30px',
			fontSize: '1.25rem',
			letterSpacing: '-0.4px'
		}
	},
	bestAnswerBoxHeight: {
		height: "220px",
	},
	bestAnswerBoxMuti: {
		height: "720px",
	},
	bestAnswerBoxOne: {
		height: "1000px",
	},

	// kitty
	textArticleNumber: {},
	textArticleTitle: {},
	textArticle: {},
	page: {},

	numberBox: {
		width: "51px",
		height: "34px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 99,
		background: '#f3f3f3',
		boxSizing: 'border-box',
		marginRight: "11px",
		'& .MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#777',
			fontWeight: 'bold',
			'& span': {
				color: '#38cb89',
				fontWeight: 'bold'
			}
		}
	},

	progressBox: {
		maxWidth: "80%",
		width: '100%',
		'& .MuiLinearProgress-root': {
			height: "16px",
			background: '#eee',
			borderRadius: 99,
		},
		'& .MuiLinearProgress-bar': {
			background: '#38cb89'
		}
	},
	progressText: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#111',
			marginLeft: "11px",
			'@media all and (max-width: 1550px)': {
				fontSize: '0.75rem',
			},
		}
	},


	dialogBox: {
		borderRadius: 4,
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			width: "400px", // 작은 화면 (sm 이하)에서 더 좁게 설정
		},
		[theme.breakpoints.between('sm', 'md')]: {
			width: "500px", // sm과 md 사이에서의 설정
		},
		[theme.breakpoints.up('md')]: {
			width: "600px", // md 이상에서 설정
		},
		// // 특정 Dialog-paper 스타일을 커스터마이징 하고 싶다면 주석을 제거하고 수정
		// '& .MuiDialog-paperWidthSm': {
		// 	width: "450px", // 소형 다이얼로그 커스터마이징
		// },
		// '& .MuiDialog-paperWidthMd': {
		// 	width: "600px", // 중형 다이얼로그 커스터마이징
		// },
	},

	dialogContent: {
		display: 'flex',
		textAlign: 'center',
		minHeight: '200px',
		alignItems: 'center',
		// width: "785px",
		paddingTop: '25px',
		paddingBottom: '25px',
		// paddingLeft: '20px',
		// paddingRight: '20px',
	},
});












