import { getNumWorkers } from "../../config/mupdfWorkerConfig";
import { NeoPDFWorkerEmployer } from "../PdfWorkerEmployerClass";
import { NeoPDFPage } from "./NeoPDFPage";
import { sleep, uuidv4 } from "../../common/UtilFuncs";
import { NeoPDFDocument } from "./NeoPDFDocument";

const setImmediatePolyfill = (fn: () => void) => Promise.resolve().then(fn);

// eslint-disable-next-line no-use-before-define
let gPdfManager: NeoPDFRendererManager | null = null;
const MAX_RENDERING_RETRY_COUNT = 3;
const DEGREE_DPI_RATIO = 0.9;

export type RenderMangagerReturnType = {
  dpi: number,
  width: number,
  height: number,

  dataUrl?: string,
  png?: ArrayBuffer,
  imageData?: ImageData,
  canvas?: HTMLCanvasElement,

  lastUploadTime?: number
};

export type NeoRenderTask = {
  promise: Promise<RenderMangagerReturnType>,
  cancel: () => Promise<void>,

  resolve?: (value: RenderMangagerReturnType) => void,
  reject?: (reason?: any) => void,

  taskId: string,
  refCount: number,

  canceled: boolean,
  cookieId: string,

  employer: NeoPDFWorkerEmployer,
  pdf: NeoPDFDocument,
  pageIndex: number,
}

type RequestRenderOptions = {
  // employer: NeoPDFWorkerEmployer,
  pdf: NeoPDFDocument,
  pageIndex: number,
  dpi: number,
  canvas?: HTMLCanvasElement,
  topPriority?: boolean,
  type: "canvas" | "png" | "pixmap" | "dataurl",
};

type RenderingJob = RequestRenderOptions & {
  docId: string,
  task: NeoRenderTask,
};

export class NeoPDFRendererManager {
  private numRenererWorkers = 1;

  private _currentJobs: RenderingJob[] = undefined;

  private _renderQueue: RenderingJob[] = [];

  private _ready: Promise<void>;

  private workerBusyFlag: boolean[] = undefined;

  constructor(numRenererWorkers: number) {
    this.workerBusyFlag = Array.from({ length: numRenererWorkers }).map((v, i) => false);
    this._currentJobs = Array.from({ length: numRenererWorkers }).map((v, i) => null);
    this.numRenererWorkers = numRenererWorkers;
  }

  static getInstance() {
    if (gPdfManager) return gPdfManager;
    gPdfManager = new NeoPDFRendererManager(getNumWorkers());
    return gPdfManager;
  }

  static get instance() {
    return NeoPDFRendererManager.getInstance();
  }

  static cancelRender = async (t: NeoRenderTask) => {
    const { employer, pageIndex, cookieId } = t;
    if (cookieId?.length) {
      const cookie = await employer.getCookiePointer(cookieId);
      if (cookie) {
        console.log(`Rendering Canceled by cookie, page #${pageIndex}`);
        const int32pointer = cookie >> 2;
        const wasmMemoryView32 = new Int32Array(employer.wasmMemory);
        wasmMemoryView32[int32pointer] = 1;
      }
    }
  }

  public cancelJob = async (id: string) => {
    const currentJob = this._currentJobs.find((j) => j?.task.taskId === id);
    if (currentJob.task.taskId === id) {
      await sleep(0);
      const t = currentJob.task;
      t.refCount--;

      if (t.refCount < 1) {
        NeoPDFRendererManager.cancelRender(t);

        t.canceled = true;
        // t.resolve = undefined;
        t.reject(new Error(`current job canceled - ${t.taskId}, job remained=${this._renderQueue.length}`));
        console.log(`current job canceled - ${t.taskId}, job remained=${this._renderQueue.length}`);
      }

      // if (!this._loop)
      this.renderLoop();
      return;
    }

    const jobIdx = this._renderQueue.findIndex((t) => t.task.taskId === id);
    if (jobIdx > -1) {
      const job = this._renderQueue[jobIdx];
      job.task.refCount--;
      if (job.task.refCount < 1) {
        const t = job.task;
        t.canceled = true;
        // t.resolve = undefined;

        delete this._renderQueue[jobIdx];
        this._renderQueue.splice(jobIdx, 1);
        t.reject(new Error(`future job canceled - #${t.pageIndex}, ${t.taskId}, job remained=${this._renderQueue.length}`));

        console.log(`future job canceled - #${t.pageIndex}, ${t.taskId}, job remained=${this._renderQueue.length}`);
        // if (!this._loop)
        this.renderLoop();
      }
    }
  }

  public requestRenderPageAsync = (arg: RequestRenderOptions) => {
    const { pdf, pageIndex, canvas, topPriority, type } = arg;
    const { employer } = pdf;
    let { dpi } = arg;
    dpi = Math.round(dpi);

    // // 이전의 job이 있으면 그걸 return한다.
    // const prevJob = this._renderQueue.find((j) =>
    //   j.pdf === pdf &&
    //   j.pageIndex == pageIndex &&
    //   j.dpi === dpi
    // );

    // if (prevJob) {
    //   prevJob.task.refCount++;
    //   return prevJob.task;
    // }

    // console.log(`rendering Request ${pageNum}`);

    let resolve = undefined as unknown as (value: RenderMangagerReturnType) => void;
    let reject = undefined as unknown as (reason?: any) => void;
    const pr = new Promise<RenderMangagerReturnType>((rsv, rjt) => {
      resolve = rsv;
      reject = rjt;
    });

    const taskId = uuidv4();
    const cancel = async () => {
      const inst = NeoPDFRendererManager.instance;
      await inst.cancelJob(taskId);
    }

    const task: NeoRenderTask = { employer, pdf, pageIndex, promise: pr, resolve, reject, taskId, cancel, refCount: 1, canceled: false, cookieId: null };
    const job: RenderingJob = { pdf, pageIndex, canvas, task, dpi, type, docId: pdf.id };

    // console.log(`job queued dpi:${dpi}, ${taskId}, page #${page.pageIndex}, QueueLength: ${this._renderQueue.length + 1}`);
    if (topPriority) {
      this._renderQueue.unshift(job);
    } else {
      this._renderQueue.push(job);
    }
    // if (!this._loop)
    this.renderLoop();

    // console.log(`request rendering #${page?.pageIndex} ${task.taskId}, dpi=${dpi}, numJobs=${this._renderQueue.length}`);
    return task;
  }

  // private _loop = false;

  private renderLoop = async () => {
    const job = this._renderQueue[0];
    if (!job) return;

    while (this._renderQueue.length > 0) {
      let workerNum = -1;
      for (let i = 0; i < this.numRenererWorkers; i++) {
        if (!this.workerBusyFlag[i]) {
          workerNum = i;
          break;
        }
      }
      if (workerNum === -1) break;

      // this.processNext(workerNum, job.type);

      // 아래의 finally는 generic-thread-pool.ts의 코드를 참고하면 이해될 듯
      this.processNextModule(workerNum, job.type)
        .finally(() => {
          setImmediatePolyfill(() => this.renderLoop());
        });
    }
  }


  private processNext = async (workerNum: number, type: "canvas" | "png" | "pixmap" | "dataurl") => {
    await this.processNextModule(workerNum, type);

    this.renderLoop();
  }


  private processNextModule = async (workerNum: number, type: "canvas" | "png" | "pixmap" | "dataurl") => {
    if (type === "png") {
      await this.renderNextPng(workerNum);
    }
    else if (type === "canvas") {
      await this.renderNextCanvasDirect(workerNum);
    }
    else if (type === "pixmap") {
      await this.renderNextPixmap(workerNum);
    }
    else if (type === "dataurl") {
      await this.renderNextDataUrl(workerNum);
    }
  }



  private renderNextDataUrl = async (workerNum: number) => {
    if (workerNum < 0) return;
    const job = this._renderQueue.shift();
    if (!job) return;

    this.workerBusyFlag[workerNum] = true;
    const { pdf, task, pageIndex, docId } = job;
    const { employer } = pdf;
    let { dpi } = job;
    this._currentJobs[workerNum] = job;

    let page: NeoPDFPage | undefined = undefined;

    // console.log(`rendering(${employer.name}) => DATAURL ${page?.pageIndex} ${task.taskId}, dpi=${dpi}`);
    try {
      let success = false;
      let cnt = MAX_RENDERING_RETRY_COUNT;

      page = await pdf.getPage(pageIndex);
      await page.ready;

      // console.log(`rendering(${employer.name}) => PageID: ${page.id} DATAURL ${page?.pageIndex} ${task.taskId}, dpi=${dpi}`);

      if (!page.id) {
        console.error(`page id is null - ${pageIndex}`, page);
        throw new Error(`page id is null - ${pageIndex}`);
      }
      const cookie = await employer.createCookie(pdf.ctxId);
      task.cookieId = cookie;
      const sz = await page.getSize();

      while (!success && cnt > 0) {
        try {
          // const renderedDpi = dpi * devicePixelRatio;
          const renderedDpi = dpi;
          const renderPromise = employer.drawPageAsPNG(page.id, renderedDpi, cookie);
          const pngData = await renderPromise;

          let binary = '';

          for (let i = 0, len = pngData.byteLength; i < len; i++)
            binary += String.fromCharCode(pngData[i]);
          const b64encoded = window.btoa(binary);
          const dataUrl = "data:image/png;base64," + b64encoded;

          success = true;
          this.workerBusyFlag[workerNum] = false;
          this._currentJobs[workerNum] = null;

          // console.log(`rendered(${employer.name}) => ${page?.pageIndex} ${task.taskId} - resolve:${!!task.resolve}, dpi=${dpi}, numJobs=${this._renderQueue.length}`);

          if (task.canceled) {
            task.reject(new Error(`canceled - ${task.taskId}`));
          }
          else {
            task.resolve?.({
              dpi: renderedDpi,
              dataUrl,
              width: Math.round(sz.width * renderedDpi / 72),
              height: Math.round(sz.height * renderedDpi / 72)
            });
          }
        }
        catch (e) {
          console.error(`PdfJs Renderer: retry to draw page ${page.pageIndex}, count: ${cnt} , dpi=${dpi}`);
          dpi = Math.round(dpi * DEGREE_DPI_RATIO);
          cnt--;
          if (cnt === 0) {
            throw e;
          }
        }
      }

      this.workerBusyFlag[workerNum] = false;
      this._currentJobs[workerNum] = null;
    }
    catch (e) {
      this.workerBusyFlag[workerNum] = false;
      this._currentJobs[workerNum] = null;

      task.reject(new Error(`rendering error(page:${pageIndex}), ${e}`));
    }
    finally {
      await page?.free();
    }
  }

  private renderNextPixmap = async (workerNum: number) => {
    if (workerNum < 0) return;

    if (this.workerBusyFlag[workerNum]) return;
    const job = this._renderQueue.shift();
    if (!job) return;

    this.workerBusyFlag[workerNum] = true;
    const { pdf, task, pageIndex, docId } = job;
    const { employer } = pdf;
    let { dpi } = job;
    this._currentJobs[workerNum] = job;

    console.log(`rendering Pixmap ${pageIndex} ${task.taskId}, dpi=${dpi}`);
    let page: NeoPDFPage | undefined = undefined;
    try {
      let success = false;
      let cnt = MAX_RENDERING_RETRY_COUNT;

      page = await pdf.getPage(pageIndex);
      await page.ready;

      const cookie = await employer.createCookie(pdf.ctxId);
      task.cookieId = cookie;

      while (!success && cnt > 0) {
        try {
          // const renderedDpi = dpi * devicePixelRatio;
          const renderedDpi = dpi;
          const size = await employer.getPageSize(page.id);
          const renderPromise = employer.drawPageAsPixmap(page.id, renderedDpi, cookie);
          const pixmap = await renderPromise;
          success = true;
          this.workerBusyFlag[workerNum] = false;
          this._currentJobs[workerNum] = null;
          console.log(`rendered ${page?.pageIndex} ${task.taskId} - resolve:${!!task.resolve}, dpi=${dpi}, numJobs=${this._renderQueue.length}`);

          task.resolve?.({
            dpi: renderedDpi,
            imageData: pixmap,
            width: Math.round(size.width * renderedDpi / 72),
            height: Math.round(size.height * renderedDpi / 72),
          });
        }
        catch (e) {
          console.error(`PdfJs Renderer: retry to draw page ${page.pageIndex}, count: ${cnt} , dpi=${dpi}`);
          dpi = Math.round(dpi * DEGREE_DPI_RATIO);
          cnt--;
          if (cnt === 0) {
            throw e;
          }
        }
      }

      this.workerBusyFlag[workerNum] = false;
      this._currentJobs[workerNum] = null;
    }
    catch (e) {
      this.workerBusyFlag[workerNum] = false;
      this._currentJobs[workerNum] = null;
      task.reject(new Error(`rendering error(page:${pageIndex}), ${e}`));
    }
    finally {
      await page?.free();
    }
  }

  private renderNextPng = async (workerNum: number) => {
    if (workerNum < 0) return;

    if (this.workerBusyFlag[workerNum]) return;

    const job = this._renderQueue.shift();
    if (!job) return;

    this.workerBusyFlag[workerNum] = true;
    const { pdf, task, pageIndex } = job;
    const { employer } = pdf;
    let { dpi } = job;
    this._currentJobs[workerNum] = job;

    let page: NeoPDFPage | undefined = undefined;
    try {
      let success = false;
      let cnt = MAX_RENDERING_RETRY_COUNT;

      page = await pdf.getPage(pageIndex);
      await page.ready;

      const cookie = await employer.createCookie(pdf.ctxId);
      task.cookieId = cookie;
      const sz = await page.getSize();
      // console.log(`rendering PNG Data #${pageIndex} size:${sz.width},${sz.height}  ${task.taskId}, docPtr=${docPtr}, dpi=${dpi}, numJobs=${this._renderQueue.length}`);

      while (!success && cnt > 0) {
        try {
          // const renderedDpi = dpi * devicePixelRatio;
          const renderedDpi = dpi;
          const renderPromise = employer.drawPageAsPNG(page.id, renderedDpi, cookie);
          const pngData = await renderPromise;

          success = true;
          this.workerBusyFlag[workerNum] = false;
          this._currentJobs[workerNum] = null;
          // console.log(`rendered #${page?.pageIndex} ${task.taskId} - resolve:${!!task.resolve}, dpi=${dpi}, numJobs=${this._renderQueue.length}`);

          task.resolve?.({
            dpi: renderedDpi,
            png: pngData,
            width: Math.round(sz.width * renderedDpi / 72),
            height: Math.round(sz.height * renderedDpi / 72)
          });
        }
        catch (e) {
          console.error(`Renderer: retry to draw page ${page.pageIndex}, count: ${cnt} , dpi=${dpi}`);
          dpi = Math.round(dpi * DEGREE_DPI_RATIO);
          cnt--;
          if (cnt === 0) {
            throw e;
          }
        }
      }

      this.workerBusyFlag[workerNum] = false;
      this._currentJobs[workerNum] = null;
    }
    catch (e) {
      this.workerBusyFlag[workerNum] = false;
      this._currentJobs[workerNum] = null;
      task.reject(new Error(`rendering error(page:${pageIndex}), ${e}`));
    }
    finally {
      await page?.free();
    }
  }


  private renderNextCanvasDirect = async (workerNum: number) => {
    if (workerNum < 0) return;

    if (this.workerBusyFlag[workerNum]) return;

    const job = this._renderQueue.shift();
    if (!job) return;

    this.workerBusyFlag[workerNum] = true;
    const { pdf, task, pageIndex, docId, canvas } = job;
    const { employer } = pdf;
    let { dpi } = job;
    this._currentJobs[workerNum] = job;

    console.log(`rendering BackCanvas ${pageIndex} ${task.taskId}, dpi=${dpi}`);
    let page: NeoPDFPage | undefined = undefined;
    try {
      let success = false;
      let cnt = MAX_RENDERING_RETRY_COUNT;

      page = await pdf.getPage(pageIndex);
      await page.ready;

      const cookie = await employer.createCookie(pdf.ctxId);
      task.cookieId = cookie;

      while (!success && cnt > 0) {
        try {
          // const renderedDpi = dpi * devicePixelRatio;
          const renderedDpi = dpi;
          const size = await employer.getPageSize(page.id);
          const renderPromise = employer.drawPageAsPixmap(page.id, renderedDpi, cookie);
          const pixmap = await renderPromise;
          canvas.width = pixmap.width;
          canvas.height = pixmap.height;

          const c = canvas.getContext("2d");
          c.putImageData(pixmap, 0, 0);


          success = true;
          this.workerBusyFlag[workerNum] = false;
          this._currentJobs[workerNum] = null;
          console.log(`rendered ${page?.pageIndex} ${task.taskId} - resolve:${!!task.resolve}, dpi=${dpi}, numJobs=${this._renderQueue.length}`);

          task.resolve?.({
            dpi: renderedDpi,
            imageData: pixmap,
            canvas,
            width: Math.round(size.width * renderedDpi / 72),
            height: Math.round(size.height * renderedDpi / 72),
          });
        }
        catch (e) {
          console.error(`PdfJs Renderer: retry to draw page ${page.pageIndex}, count: ${cnt} , dpi=${dpi}`);
          dpi = Math.round(dpi * DEGREE_DPI_RATIO);
          cnt--;
          if (cnt === 0) {
            throw e;
          }
        }
      }

      this.workerBusyFlag[workerNum] = false;
      this._currentJobs[workerNum] = null;
    }
    catch (e) {
      this.workerBusyFlag[workerNum] = false;
      this._currentJobs[workerNum] = null;
      task.reject(new Error(`rendering error(page:${pageIndex}), ${e}`));
    }
    finally {
      await page?.free();
    }
  }
}

