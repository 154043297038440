import {
	Box
} from "@mui/material";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";
import { IRubricInfo } from "../../../../repositories/model/transfer/IRubricInfo";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TemplateProjectViewStyle } from "../../styles/TemplateProjectViewStyle";
import { QuestionRow } from "./RubricRow";

type Props = {
	projectExamQuestionsList: IQuestionInfo[],
	questionListLenObjPerExam: number,
	projectRubricTransferList: IRubricInfo[],
	questionKeyAndSelectedProjectRubricIdxMap: any,
	questionKeyAndSystemRubricConditionsToSearchMap: any,
	questionKeyAndSystemRubricListMap: any,
	questionKeyAndSelectedSystemRubricIdsMap: any
}

export const RubricTab = (props: Props) => {
	const classes = useEmotionStyles( TemplateProjectViewStyle );

	const {
		projectExamQuestionsList,
		questionListLenObjPerExam,
		projectRubricTransferList,
		questionKeyAndSelectedProjectRubricIdxMap,
		questionKeyAndSystemRubricConditionsToSearchMap,
		questionKeyAndSystemRubricListMap,
		questionKeyAndSelectedSystemRubricIdsMap
	} = props;

	// projectExamQuestionsList에 있는 question을 모두 돌면서 question.parentOrder에 속한 question이 몇개인지 확인하여 questionListLenObjPerExam에 저장
	const questionsPerExam = {};

	// const projectExamQuestionsList = toJS(pa) as IQuestionInfo[];
	projectExamQuestionsList.forEach(question => {
		const examIndex = question.parentOrder;
		if (!questionsPerExam[examIndex]) {
			questionsPerExam[examIndex] = 1;
		} else {
			questionsPerExam[examIndex] += 1;
		}
	});


	return (
		<Box className={classes.tabBox}>
			{projectExamQuestionsList.length > 0 &&
				projectExamQuestionsList.map((question, idx) => {
					const examIndex = question.parentOrder;
					const questionIndex = question.projectExamQuestionNum;

					let questionString = `${examIndex}`;
					if (questionsPerExam[examIndex] > 1) {
						questionString = `${examIndex}-${questionIndex}`;
					}
					else {
						questionString = `${examIndex}`;
					}

					return (
						<div key={question.projectExamCode + question.order}>
							{questionString})
							<QuestionRow
								key={idx}
								questionInfo={question}
								questionListLen={questionListLenObjPerExam[question.parentOrder]}
								projectRubricList={projectRubricTransferList}
								selectedProjectRubricIdx={questionKeyAndSelectedProjectRubricIdxMap[question.key]}
								systemRubricConditionsToSearch={questionKeyAndSystemRubricConditionsToSearchMap[question.key]}
								systemRubricList={questionKeyAndSystemRubricListMap[question.key]}
								selectedSystemRubricIdLIST={questionKeyAndSelectedSystemRubricIdsMap[question.key]}
							/>
						</div>
					)
				}
				)
			}
		</Box>
	)
}


