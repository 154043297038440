
//Colour formats
export enum PATH_FILL_TYPE {
  PATH_FILL_TYPE_STROKE = 0,
  PATH_FILL_TYPE_FILL = 1,
  PATH_FILL_TYPE_FILL_AND_STROKE = 2
}

export function pathFillTypeToNumber(pathFillType: PATH_FILL_TYPE) {
  switch (pathFillType) {
    case PATH_FILL_TYPE.PATH_FILL_TYPE_STROKE:
      return 0;
    case PATH_FILL_TYPE.PATH_FILL_TYPE_FILL:
      return 1;
    case PATH_FILL_TYPE.PATH_FILL_TYPE_FILL_AND_STROKE:
      return 2;
    default:
      throw new Error(`Unknown PATH_FILL_TYPE: ${pathFillType}`);
  }
}

// https://opensource.adobe.com/dc-acrobat-sdk-docs/pdfstandards/PDF32000_2008.pdf, page 125
export enum LINE_CAP_STYLE {
  LINE_CAP_STYLE_INNER = 0,
  LINE_CAP_STYLE_ROUND = 1,
  LINE_CAP_STYLE_OUTER = 2
}

export function lineCapStyleToNumber(lineCapStyle: LINE_CAP_STYLE) {
  switch (lineCapStyle) {
    case LINE_CAP_STYLE.LINE_CAP_STYLE_INNER:
      return 0;
    case LINE_CAP_STYLE.LINE_CAP_STYLE_ROUND:
      return 1;
    case LINE_CAP_STYLE.LINE_CAP_STYLE_OUTER:
      return 2;
    default:
      throw new Error(`Unknown LINE_CAP_STYLE: ${lineCapStyle}`);
  }
}

// https://opensource.adobe.com/dc-acrobat-sdk-docs/pdfstandards/PDF32000_2008.pdf, page 126
export enum LINE_JOIN_STYLE {
  LINE_JOIN_STYLE_SHARP = 0,
  LINE_JOIN_STYLE_ROUND = 1,
  LINE_JOIN_STYLE_MINIMUM = 2
}

export function lineJoinStyleToNumber(lineJoinStyle: LINE_JOIN_STYLE) {
  switch (lineJoinStyle) {
    case LINE_JOIN_STYLE.LINE_JOIN_STYLE_SHARP:
      return 0;
    case LINE_JOIN_STYLE.LINE_JOIN_STYLE_ROUND:
      return 1;
    case LINE_JOIN_STYLE.LINE_JOIN_STYLE_MINIMUM:
      return 2;
    default:
      throw new Error(`Unknown LINE_JOIN_STYLE: ${lineJoinStyle}`);
  }
}