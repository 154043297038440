import {
	Box
} from "@mui/material";



import { ChangeFlag } from "../../../../../../repositories/model/support/ChangeFlag";
import { QuestionAndReferenceTabExamRow } from "./QuestionAndReferenceTabExamRow";

type Props = {
	projectExamList: any;
	editMode: boolean;
	answerList: any;
	changeAnswer: any;
}

export const QuestionAndReferenceTab = (props: Props) => {
	const { projectExamList, editMode, answerList, changeAnswer } = props;

	return (
		<Box sx={{ position: 'relative' }}>
			{projectExamList.length > 0 &&
				projectExamList.filter(e => e.changeFlag !== ChangeFlag.DELETED)
					.map((exam, index) => {
						return (
							<QuestionAndReferenceTabExamRow
								key={exam.key}
								examInfo={exam}
								index={index + 1}
								editMode={editMode}
								changeAnswer={changeAnswer}
								answerList={answerList}
								// projectExamList={projectExamList}
							/>
						)
					})}
		</Box>
	)
}