import { action, makeAutoObservable, runInAction } from "mobx";
import ProjectRepository from "../repositories/ProjectRepository";
import SystemRubricRepository from "../repositories/SystemRubricRepository";
import AiRubricRepository from "../repositories/AiRubricRepository";
import MessageStore from "./MessageStore";
import { ChangeFlag } from "../repositories/model/support/ChangeFlag";
import { RubricStatus } from "../repositories/model/support/RubricStatus";
import { IQuestionInfo } from "../repositories/model/transfer/IQuestionInfo";

const LogPrefix = "[ProjectRubricStore]";

// const EmptyProjectExamQuestionTransfer = {
// 	projectExamCode: "",
// 	projectExamQuestionNum: "",
// 	parentOrder: "",
// 	order: "",
// 	question: "",
// 	score: "",
// 	correctedAnswer: "",
// 	correctedKeyword: "",
// 	changeFlag: FlagType.CREATED,

// 	key: ""
// };

const EmptySystemRubricItem = {
	rubricId: "",
	rubricItemNum: "",
	name: "",
	standard: ""
};

// const EmptySystemRubricTransfer = {
// 	id: "",
// 	name: "",
// 	subject: "",
// 	standardCount: "",
// 	description: "",
// 	items: []
// };

// const EmptySystemRubricList = {
// 	rubrics: [],
// 	totalCount: ""
// };

const EmptyProjectRubricItemTransfer = {
	rubricId: "",
	rubricItemNum: "",
	name: "",
	score: 0,
	standard: "",
	changeFlag: ChangeFlag.CREATED
};

const EmptyProjectRubricTransfer = {
	id: "",
	systemRubricId: "", // data 받아오는 용으로만
	projectExamCode: "",
	projectExamQuestionNum: 0,
	name: "",
	subject: "",
	status: RubricStatus.ENABLE,

	// systemRubricName: "",
	score: 0,
	key: "",
	questionKey: "",

	changeFlag: ChangeFlag.CREATED,
	// items: Array(3).fill({ ...EmptyProjectRubricItemTransfer })
	items: [
		{ ...EmptyProjectRubricItemTransfer, rubricItemNum: 1, name: '상' },
		{ ...EmptyProjectRubricItemTransfer, rubricItemNum: 2, name: '중' },
		{ ...EmptyProjectRubricItemTransfer, rubricItemNum: 3, name: '하' }
	]
};

const EmptySystemRubricConditionsToSearch = {
	// orderType,
	orderBy: "",

	projectCode: "",
	projectExamQuestionNum: 0,

	keyword: "",
	page: 0,
	rowsPerPage: 10,
	// totalCount: "",
}

type ProjectRubricStoreProps = {
	projectRepository: ProjectRepository;
	systemRubricRepository: SystemRubricRepository;
	aiRubricRepository: AiRubricRepository;
	messageStore: MessageStore;
}

export default class ProjectRubricStore {
	public projectRepository: ProjectRepository;
	public systemRubricRepository: SystemRubricRepository;
	public aiRubricRepository: AiRubricRepository;
	public messageStore: MessageStore;

	public projectCode: string;
	public projectExamQuestionsList: any[];
	public projectRubricTransferList: any[];
	public oriProjectRubricTransferList: any[];
	public questionListLenObjPerExam: any;
	public questionKeyAndSelectedSystemRubricIdsMap: any;
	public questionKeyAndSystemRubricConditionsToSearchMap: any;
	public questionKeyAndSystemRubricListMap: any;
	public questionKeyAndSelectedProjectRubricIdxMap: any;
	public examQuestionOrderArr: any;

	public isProjectRubricListPreparedArr: any[];

	public isNew: boolean;
	public isSelectingProjectExamQuestions: boolean;
	public isSelectingInitialSystemRubric: boolean;
	public isCreatingProjectRubricList: boolean;
	public isUpdatingProjectRubricList: boolean;
	public isConfirmForUpdateRequired: boolean;


	constructor(props: ProjectRubricStoreProps) {
		makeAutoObservable(this);

		const ps = props;
		const { projectRepository, systemRubricRepository, aiRubricRepository, messageStore } = ps;

		this.projectRepository = projectRepository;
		this.systemRubricRepository = systemRubricRepository;
		this.aiRubricRepository = aiRubricRepository;
		this.messageStore = messageStore;

		this.projectCode = "";
		this.projectExamQuestionsList = [];
		this.projectRubricTransferList = [];
		this.oriProjectRubricTransferList = [];
		this.questionListLenObjPerExam = {};
		this.questionKeyAndSelectedSystemRubricIdsMap = {};
		this.questionKeyAndSystemRubricConditionsToSearchMap = {};
		this.questionKeyAndSystemRubricListMap = {};
		this.questionKeyAndSelectedProjectRubricIdxMap = {};
		this.examQuestionOrderArr = [];
		this.isProjectRubricListPreparedArr = [];

		this.isNew = true; // new or existing
		this.isSelectingProjectExamQuestions = false;
		this.isSelectingInitialSystemRubric = false;
		this.isCreatingProjectRubricList = false;
		this.isUpdatingProjectRubricList = false;
		this.isConfirmForUpdateRequired = false;

	}

	init() {
		this.projectCode = "";
		this.projectExamQuestionsList = [];
		this.projectRubricTransferList = [];
		this.oriProjectRubricTransferList = [];
		this.questionListLenObjPerExam = {};
		this.questionKeyAndSelectedSystemRubricIdsMap = {};
		this.questionKeyAndSystemRubricConditionsToSearchMap = {};
		this.questionKeyAndSystemRubricListMap = {};
		this.questionKeyAndSelectedProjectRubricIdxMap = {};
		this.examQuestionOrderArr = [];
		this.isProjectRubricListPreparedArr = [];

		this.isNew = true; // new or existing
		this.isSelectingProjectExamQuestions = false;
		this.isSelectingInitialSystemRubric = false;
		this.isCreatingProjectRubricList = false;
		this.isUpdatingProjectRubricList = false;
		this.isConfirmForUpdateRequired = false;
	};

	setProjectCode = code => this.projectCode = code;

	setIsNew = boolean => this.isNew = boolean;

	applyChangesToProjectRubricTransferList() {
		this.projectRubricTransferList = [...this.projectRubricTransferList];
		this.changeIsConfirmForUpdateRequired(true);
	};

	getRubricInfoByProjectRubricKey(projectRubricKey) {
		return this.projectRubricTransferList.find(rubric => rubric.key === projectRubricKey);
	};

	getProjectRubricItemInfoByProjectRubricKeyAndItemNum(projectRubricKey, itemNum) {
		const rubricInfo = this.getRubricInfoByProjectRubricKey(projectRubricKey);
		if (!rubricInfo) return;

		return rubricInfo.items.find(item => item.rubricItemNum === itemNum);
	};

	addEmptySystemRubric = action((questionKey) => {
		this.questionKeyAndSystemRubricListMap = {
			...this.questionKeyAndSystemRubricListMap,
			[questionKey]: []
		}
	});

	addEmptySystemRubricConditionsToSearch(questionKey) {
		this.questionKeyAndSystemRubricConditionsToSearchMap = {
			...this.questionKeyAndSystemRubricConditionsToSearchMap,
			[questionKey]: { ...EmptySystemRubricConditionsToSearch }
		}
	};

	addEmptySelectedSystemRubricIdList(questionKey) {
		this.questionKeyAndSelectedSystemRubricIdsMap = {
			...this.questionKeyAndSelectedSystemRubricIdsMap,
			[questionKey]: []
		}
	};

	addEmptyProjectRubric(questionInfo) {
		// const questionProjectRubricList = this.projectRubricTransferList.filter(rubric => rubric.projectExamCode === questionInfo.projectExamCode && rubric.projectExamQuestionNum === questionInfo.projectExamQuestionNum);
		const questionProjectRubricList = this.projectRubricTransferList.filter(rubric => rubric.questionKey === questionInfo.key);
		const idx = questionProjectRubricList.length;
		this.changeQuestionKeyAndSelectedProjectRubricIdxMap(questionInfo.key, idx);

		// add empty project rubic
		const randomNumber = Math.floor(10000000 + Math.random() * 90000000);

		const projectRubricTransfer = {
			...EmptyProjectRubricTransfer,
			projectExamCode: questionInfo.projectExamCode,
			projectExamQuestionNum: questionInfo.projectExamQuestionNum,
			// score: questionInfo.score,

			key: randomNumber,
			questionKey: questionInfo.key
		};

		this.projectRubricTransferList = [...this.projectRubricTransferList, projectRubricTransfer];

		return projectRubricTransfer;
	};

	deleteProjectRubricByKey(questionInfo, rubricKey) {
		this.projectRubricTransferList = this.projectRubricTransferList.filter(rubric => !(rubric.questionKey === questionInfo.key && rubric.key === rubricKey));
		this.changeQuestionKeyAndSelectedProjectRubricIdxMap(questionInfo.key, 0);

		this.applyChangesToProjectRubricTransferList();
	};

	changeQuestionKeyAndSystemRubricConditionsMapKeyword(questionKey, keyword) {
		this.questionKeyAndSystemRubricConditionsToSearchMap[questionKey].keyword = keyword;
		this.questionKeyAndSystemRubricConditionsToSearchMap = { ...this.questionKeyAndSystemRubricConditionsToSearchMap };
	};

	changeQuestionKeyAndSystemRubricConditionsMapPage(questionKey, page) {
		this.questionKeyAndSystemRubricConditionsToSearchMap[questionKey].page = page;
		this.questionKeyAndSystemRubricConditionsToSearchMap = { ...this.questionKeyAndSystemRubricConditionsToSearchMap };
	};

	changeQuestionKeyAndSelectedProjectRubricIdxMap(questionKey, idx) {
		this.questionKeyAndSelectedProjectRubricIdxMap = {
			...this.questionKeyAndSelectedProjectRubricIdxMap,
			[questionKey]: idx
		};
	};

	changeProjectRubricName(projectRubricKey, name) {
		const rubricInfo = this.getRubricInfoByProjectRubricKey(projectRubricKey);
		if (!rubricInfo) return;

		rubricInfo.name = name;

		this.applyChangesToProjectRubricTransferList();
	};

	changeIsProjectRubricListPreparedArr(idx, isPrepared) {
		if (idx > this.isProjectRubricListPreparedArr.length - 1 || idx < 0) return;

		this.isProjectRubricListPreparedArr[idx] = isPrepared;
	};

	changeIsSelectingProjectExamQuestions(isSeleting) {
		this.isSelectingProjectExamQuestions = isSeleting;
	};

	changeIsConfirmForUpdateRequired(isRequired) {
		if (this.isConfirmForUpdateRequired === isRequired) return;

		this.isConfirmForUpdateRequired = isRequired;
	};

	checkProjectRubricNameDuplicated(projectRubricKey, questionInfo) {
		const questionProjectRubricListToCompare = this.projectRubricTransferList.filter(rubric => rubric.questionKey === questionInfo.key && rubric.key !== projectRubricKey);
		if (questionProjectRubricListToCompare.length === 0) return false;

		const rubricInfo = this.getRubricInfoByProjectRubricKey(projectRubricKey);
		if (!rubricInfo) return false;

		let isDuplicated = false;
		questionProjectRubricListToCompare.map(item => {
			if (item.name !== rubricInfo.name) return;

			isDuplicated = true;
		});

		return isDuplicated;
	};

	decreaseScore(infoObj) {
		let score;
		const preScore = infoObj.score;

		if (preScore > 1) {
			score = preScore - 1;
		} else {
			score = 0;
		}

		return score;
	};

	invalidProjectRubricScore(projectRubricKey: number, score: number, questionInfo: IQuestionInfo) {
		if (score > questionInfo.score) {
			// alert("문항에 부여된 배점을 초과할 수 없습니다.");
			this.messageStore.alert("문항에 부여된 배점을 초과할 수 없습니다.");
			return true;
		}

		const questionSumScore =
			this.projectRubricTransferList
				.reduce((acc, cur) => {
					if (cur.key !== projectRubricKey
						&& cur.questionKey === questionInfo.key) {
						return acc + cur.score;
					}

					return acc;
				}, 0)
			+ score;

		if (questionSumScore > questionInfo.score) {
			// alert("각 평가 기준표의 배점의 합이 문항에 부여된 배점을 초과할 수 없습니다.");
			this.messageStore.alert("각 평가 기준표의 배점의 합이 문항에 부여된 배점을 초과할 수 없습니다.");
			return true;
		}

		return false;
	};

	changeProjectRubricScore(projectRubricKey: number, score: number, questionInfo: IQuestionInfo) {
		const invalid = this.invalidProjectRubricScore(projectRubricKey, score, questionInfo);
		if (invalid) return;

		const rubricInfo = this.getRubricInfoByProjectRubricKey(projectRubricKey);
		if (!rubricInfo) return;

		rubricInfo.score = score;

		const itemsLen = rubricInfo.items.length;
		rubricInfo.items.map((item, idx) => item.score = Math.ceil(score * ((itemsLen - idx - 1) / (itemsLen - 1))));

		this.applyChangesToProjectRubricTransferList();
	};

	increaseProjectRubricScore(projectRubricKey, questionInfo) {
		const rubricInfo = this.getRubricInfoByProjectRubricKey(projectRubricKey);
		if (!rubricInfo) return;

		this.changeProjectRubricScore(projectRubricKey, rubricInfo.score + 1, questionInfo);
	};

	decreaseProjectRubricScore(projectRubricKey, questionInfo) {
		const rubricInfo = this.getRubricInfoByProjectRubricKey(projectRubricKey);
		if (!rubricInfo) return;

		const score = this.decreaseScore(rubricInfo);
		this.changeProjectRubricScore(projectRubricKey, score, questionInfo);
	};

	addProjectRubricItem(projectRubricKey) {
		const rubricInfo = this.getRubricInfoByProjectRubricKey(projectRubricKey);
		if (!rubricInfo) return;

		const nextItemNum = rubricInfo.items.reduce((acc, cur) => {
			return acc > cur.rubricItemNum ? acc : cur.rubricItemNum
		}, 0) + 1;

		rubricInfo.items.push({
			...EmptyProjectRubricItemTransfer,
			rubricItemNum: nextItemNum,
			rubricId: rubricInfo?.id ? rubricInfo.id : "",
		});

		this.applyChangesToProjectRubricTransferList();
	};

	deleteProjectRubricItem(projectRubricKey, itemIdx) {
		const rubricInfo = this.getRubricInfoByProjectRubricKey(projectRubricKey);
		if (!rubricInfo) return;

		rubricInfo.items = [...rubricInfo.items.filter((_, idx) => idx !== itemIdx)];

		this.applyChangesToProjectRubricTransferList();
	};

	invalidProjectRubricItemScore(projectRubricKey, itemNum, score, questionInfo) {
		if (score > questionInfo.score) {
			// alert("문항에 부여된 배점을 초과할 수 없습니다.");
			this.messageStore.alert("문항에 부여된 배점을 초과할 수 없습니다.");
			return true;
		}

		const rubricInfo = this.getRubricInfoByProjectRubricKey(projectRubricKey);
		if (!rubricInfo) return true;

		// if (rubricInfo.score > 0 && score > rubricInfo.score) {
		if (score > rubricInfo.score) {
			// alert("평가항목에 부여된 배점을 초과할 수 없습니다.");
			this.messageStore.alert("평가항목에 부여된 배점을 초과할 수 없습니다.");
			return true;
		}
	}

	changeProjectRubricItemScore(projectRubricKey, itemNum, score, questionInfo) {
		const invalid = this.invalidProjectRubricItemScore(projectRubricKey, itemNum, score, questionInfo);
		if (invalid) return false;

		const itemInfo = this.getProjectRubricItemInfoByProjectRubricKeyAndItemNum(projectRubricKey, itemNum);
		if (!itemInfo) return false;

		itemInfo.score = score;

		this.applyChangesToProjectRubricTransferList();
		return true;
	};

	increaseProjectRubricItemScore(projectRubricKey, itemNum, questionInfo) {
		const itemInfo = this.getProjectRubricItemInfoByProjectRubricKeyAndItemNum(projectRubricKey, itemNum);
		if (!itemInfo) return false;

		this.changeProjectRubricItemScore(projectRubricKey, itemNum, itemInfo.score + 1, questionInfo);
	};

	decreaseProjectRubricItemScore(projectRubricKey, itemNum, questionInfo) {
		const itemInfo = this.getProjectRubricItemInfoByProjectRubricKeyAndItemNum(projectRubricKey, itemNum);
		if (!itemInfo) return false;

		const score = this.decreaseScore(itemInfo);
		this.changeProjectRubricItemScore(projectRubricKey, itemNum, score, questionInfo);
	};

	changeProjectRubricItemName(projectRubricKey, itemNum, name) {
		const itemInfo = this.getProjectRubricItemInfoByProjectRubricKeyAndItemNum(projectRubricKey, itemNum);
		if (!itemInfo) return;

		itemInfo.name = name;

		this.applyChangesToProjectRubricTransferList();
	};

	changeProjectRubricItemStandard(projectRubricKey, itemNum, standard) {
		const itemInfo = this.getProjectRubricItemInfoByProjectRubricKeyAndItemNum(projectRubricKey, itemNum);
		if (!itemInfo) return;

		itemInfo.standard = standard;

		this.applyChangesToProjectRubricTransferList();
	};

	async changeProjectRubricBySystemRubric(questionInfo, systemRubricInfo) {
		// check idx to be changed
		const idx = this.questionKeyAndSelectedProjectRubricIdxMap[questionInfo.key];
		if (idx === undefined || idx === "") return;

		const selectedProjectRubricTransfer = this.projectRubricTransferList.filter(rubric => rubric.questionKey === questionInfo.key)[idx];
		// .filter(rubric =>  rubric.projectExamCode === questionInfo.projectExamCode && rubric.projectExamQuestionNum === questionInfo.projectExamQuestionNum)[idx];
		if (!selectedProjectRubricTransfer) return;

		// alert if there is something for user to edit
		const systemRubricName = this.questionKeyAndSystemRubricListMap[questionInfo.key].rubrics.find(systemRubric => systemRubric.id === selectedProjectRubricTransfer.systemRubricId)?.name;

		// console.log("test=", await this.messageStore.confirm("다른 표준 항목을 선택하면 기 작성분이 삭제됩니다. 진행하시겠습니까?"));

		if ((selectedProjectRubricTransfer.systemRubricId
			? systemRubricName !== selectedProjectRubricTransfer.name
			: selectedProjectRubricTransfer.name)
			|| selectedProjectRubricTransfer.score > 0
			|| selectedProjectRubricTransfer.items.some(item => (item.score > 0 || !!item.standard || !!item.name))
		) {
			const result = await this.messageStore.confirm("다른 표준 항목을 선택하면 기 작성분이 삭제됩니다. 진행하시겠습니까?");
			if (!result) return;
		}

		// project rublic item
		let items = [];
		let itemName = ['상', '중', '하'];
		systemRubricInfo.items.map((item, idx) => {
			const rubricItem = {
				...EmptyProjectRubricItemTransfer,
				rubricItemNum: item.rubricItemNum,
				rubricId: selectedProjectRubricTransfer?.id ? selectedProjectRubricTransfer.id : "",
				name: itemName[idx],
			};

			items.push(rubricItem);
			return items;
		});

		// project rublic obj
		const projectRubricTransfer = {
			...selectedProjectRubricTransfer, // id, key, questionKey, status, changeFlag
			systemRubricId: systemRubricInfo.id,
			projectExamCode: questionInfo.projectExamCode,
			projectExamQuestionNum: questionInfo.projectExamQuestionNum,
			name: systemRubricInfo.name,
			subject: systemRubricInfo.subject,
			score: 0,
			items,
		};

		// project rublic obj를 projectRubricTransferList에 추가
		const selectedIdx = this.projectRubricTransferList.findIndex(rubric => rubric.questionKey === questionInfo.key && rubric.key === selectedProjectRubricTransfer.key);
		// const selectedIdx = this.projectRubricTransferList.findIndex(rubric =>  rubric.projectExamCode === questionInfo.projectExamCode && rubric.projectExamQuestionNum === questionInfo.projectExamQuestionNum && rubric.key === selectedProjectRubricTransfer.key);
		this.projectRubricTransferList[selectedIdx] = projectRubricTransfer;
		this.applyChangesToProjectRubricTransferList();

		// 선택된 system rublic에 체크되도록
		this.questionKeyAndSelectedSystemRubricIdsMap[questionInfo.key] = [...(this.questionKeyAndSelectedSystemRubricIdsMap[questionInfo.key]?.filter(id => id !== selectedProjectRubricTransfer.systemRubricId) || []), systemRubricInfo.id];

		this.questionKeyAndSelectedSystemRubricIdsMap = { ...this.questionKeyAndSelectedSystemRubricIdsMap };
	};

	getQuestionFullOrderByQuestionKey(questionKey) {
		const questionInfo = this.projectExamQuestionsList.find(question => Number(question.key) === Number(questionKey));
		if (!questionInfo) {
			return "";

		} else {
			const questionListLen = this.questionListLenObjPerExam[questionInfo.parentOrder];
			if (!questionListLen) {
				return "";

			} else {
				let questionFullOrder = questionInfo.parentOrder;
				if (questionListLen > 1) {
					questionFullOrder = `${questionInfo.parentOrder}-${questionInfo.order}`;
				}

				return questionFullOrder;
			}
		}
	};

	invalidProjectRubricTransferList() {
		let invalid = false;

		const questionKeyAndSumScoreMap = {};
		this.projectExamQuestionsList.map(question => {
			if (!questionKeyAndSumScoreMap[question.key]) {
				questionKeyAndSumScoreMap[question.key] = {};
			}

			if (questionKeyAndSumScoreMap[question.key].oriSum) {
				return;
			}

			questionKeyAndSumScoreMap[question.key].oriSum = question.score;
		});

		let projectRubricList = [...this.projectRubricTransferList];
		// 완전히 비어있는 projectRubric 제외
		projectRubricList = projectRubricList.filter(rubric =>
			!(!rubric.name
				&& rubric.items.every(item => !item.standard && !item.name))
		);

		// [1] projectRubric이 없는 문항이 있는가 (비어있는 projectRubric만 갖고 있는 문항도 과제평가표가 없는 것으로 간주)
		// if (this.isNew) {
		const originalQuestionKeySet = new Set(Object.keys(questionKeyAndSumScoreMap).map(questionKey => questionKey));
		const newQuestionKeySet = new Set(projectRubricList.map(rubric => rubric.questionKey.toString()));

		for (const key of Array.from(originalQuestionKeySet)) {
			if (!newQuestionKeySet.has(key)) {
				const order = this.getQuestionFullOrderByQuestionKey(key);

				invalid = true;

				if (order) {
					this.messageStore.alert(`${order}번 문항 : 과제 평가 기준이 없는 문항이 있습니다.`);
					// alert(`${order}번 문항 : 과제 평가 기준이 없는 문항이 있습니다.`);

				} else {
					this.messageStore.alert(`과제 평가 기준이 없는 문항이 있습니다.`);
					// alert(`과제 평가 기준이 없는 문항이 있습니다.`);
				}

				break;
			}
		}
		// }

		if (invalid) return invalid;

		// [2] 과제평가 기준의 [배점]이 0점인 경우가 있는가 / 과제평가 기준의 [평가 항목], [배점명], [채점 기준] 중 빈 값이 있는가
		// this.projectRubricTransferList.map(rubric => {
		projectRubricList.map(rubric => {
			if (invalid) return;

			const order = this.getQuestionFullOrderByQuestionKey(rubric.questionKey);

			if (!rubric.name) {
				invalid = true;
				if (order) {
					// alert(`${order}번 문항 : 평가 항목 이름이 없는 기준표가 있습니다.`);
					this.messageStore.alert(`${order}번 문항 : 평가 항목 이름이 없는 기준표가 있습니다.`);

				} else {
					// alert("평가 항목 이름이 없는 기준표가 있습니다.");
					this.messageStore.alert("평가 항목 이름이 없는 기준표가 있습니다.");
				}

				return invalid;
			}

			if (rubric.score === 0) {
				invalid = true;
				if (order) {
					// alert(`${order}번 문항 : 상위 평가 배점이 0점인 기준표가 있습니다.`);
					this.messageStore.alert(`${order}번 문항 : 상위 평가 배점이 0점인 기준표가 있습니다.`);

				} else {
					// alert("상위 평가 배점이 0점인 기준표가 있습니다.");
					this.messageStore.alert("상위 평가 배점이 0점인 기준표가 있습니다.");

				}
				return invalid;
			}

			rubric.items.map(item => {
				if (invalid) return;

				// if (item.score === undefined || item.score === "") {
				//   invalid = true;
				//   alert("하위 평가 배점이 없는 기준표가 있습니다.");
				//   return invalid;
				// }

				if (!item.standard) {
					invalid = true;
					if (order) {
						// alert(`${order}번 문항 : 채점 기준이 없는 기준표가 있습니다.`);
						this.messageStore.alert(`${order}번 문항 : 채점 기준이 없는 기준표가 있습니다.`);

					} else {
						// alert("채점 기준이 없는 기준표가 있습니다.");
						this.messageStore.alert("채점 기준이 없는 기준표가 있습니다.");
					}
					return invalid;
				}

				if (!item.name) {
					invalid = true;
					if (order) {
						// alert(`${order}번 문항 : 배점명이 없는 기준표가 있습니다.`);
						this.messageStore.alert(`${order}번 문항 : 배점명이 없는 기준표가 있습니다.`);

					} else {
						// alert("배점명이 없는 기준표가 있습니다.");
						this.messageStore.alert("배점명이 없는 기준표가 있습니다.");
					}
					return invalid;
				}
			})
		});

		if (invalid) return invalid;

		// [3] 과제평가 기준의 배점 합산이 문항의 배점과 [같지 않은 경우]가 있는가
		projectRubricList.map(rubric => {
			if (invalid) return;

			if (!questionKeyAndSumScoreMap[rubric.questionKey]) {
				invalid = true;
				return invalid;
			}

			if (!questionKeyAndSumScoreMap[rubric.questionKey].newSum) {
				questionKeyAndSumScoreMap[rubric.questionKey].newSum = rubric.score;
			} else {
				questionKeyAndSumScoreMap[rubric.questionKey].newSum += rubric.score;
			}
		});

		if (invalid) return invalid;

		Object.keys(questionKeyAndSumScoreMap).map(key => {
			if (invalid) return;

			if (questionKeyAndSumScoreMap[key].oriSum !== questionKeyAndSumScoreMap[key].newSum) {
				const order = this.getQuestionFullOrderByQuestionKey(key);

				invalid = true;
				if (order) {
					// alert(`${order}번 문항 : 과제 평가 기준들의 배점 합산이 문항의 배점과 같지 않은 문항이 있습니다.`);
					this.messageStore.alert(`${order}번 문항 : 과제 평가 기준의 최고 배점들의 합산이 문항의 배점과 같지 않습니다.`);

				} else {
					// alert("과제 평가 기준들의 배점 합산이 문항의 배점과 같지 않은 문항이 있습니다.");
					this.messageStore.alert("과제 평가 기준의 최고 배점들의 합산이 문항의 배점과 같지 않은 문항이 있습니다.");
				}
				return invalid;
			}
		});

		return invalid;
	};

	setChangeFlagToProjectRubricTransferList() {

		// const oriRubricKeySet = this.oriProjectRubricTransferList.map(rubric => rubric.key);
		// const newRubricKeySet = this.projectRubricTransferList.map(rubric => rubric.key);

		// const deletedRubricKeyArr = Array.from(oriRubricKeySet).filter(oriKey => !newRubricKeySet.has(oriKey));
		// const createdRubricKeyArr = Array.from(newRubricKeySet).filter(newKey => !oriRubricKeySet.has(newKey));

		// const oriListWithUpdatedOrNoneFlag = this.oriProjectRubricTransferList.filter(rubric => !deletedRubricKeyArr.includes(rubric.key));
		// const newListWithUpdatedOrNoneFlag = this.projectRubricTransferList.filter(rubric => !createdRubricKeyArr.includes(rubric.key));


		// deleted flag
		const deletedRubricList = this.oriProjectRubricTransferList.filter(oriR =>
			this.projectRubricTransferList.every(newR => newR.key !== oriR.key)
		);

		deletedRubricList.map(r => {
			r.changeFlag = ChangeFlag.DELETED;

			r.items.map(i => {
				i.changeFlag = ChangeFlag.DELETED;
				return i;
			});

			return r;
		});


		// created, updated, none flag
		this.projectRubricTransferList =
			this.projectRubricTransferList
				.filter(rubric =>
					!(!rubric.name
						&& rubric.items.every(item => !item.standard && !!item.name))
				)
				.map(newR => {
					const oriR = this.oriProjectRubricTransferList.find(oriR => oriR.key === newR.key);

					if (!oriR) {
						newR.changeFlag = ChangeFlag.CREATED;

						newR.items.map(newI => {
							newI.changeFlag = ChangeFlag.CREATED;
							return newI;
						})

						return newR;
					}

					if (newR.name !== oriR.name || newR.score !== oriR.score) {
						newR.changeFlag = ChangeFlag.UPDATED;
					} else {
						newR.changeFlag = ChangeFlag.NONE;
					}

					newR.items.map(newI => {
						const oriI = oriR.items.find(oriI => oriI.rubricItemNum === newI.rubricItemNum);
						if (!oriI) {
							newI.changeFlag = ChangeFlag.CREATED;
							return newI;
						}

						if (newI.score !== oriI.score || newI.standard !== oriI.standard || newI.name !== oriI.name) {
							newI.changeFlag = ChangeFlag.UPDATED;
						} else {
							newI.changeFlag = ChangeFlag.NONE;
						}

						return newI;
					})

					const newRubricItemNumSet = new Set(newR.items.map(newI => newI.rubricItemNum));

					const rubricItemsToDelete = oriR.items.filter(oriI => !newRubricItemNumSet.has(oriI.rubricItemNum));
					rubricItemsToDelete.map(oriI => oriI.changeFlag = ChangeFlag.DELETED);

					newR.items = [...newR.items, ...rubricItemsToDelete];

					return newR;
				});

		this.projectRubricTransferList = [...this.projectRubricTransferList, ...deletedRubricList];
	};

	createRubricItemArr(rubricArr) {
		rubricArr.map(rubric => {
			let itemArr = [];

			for (let i = 0; i < rubric.standardCount; i++) {
				const rubricItem = {
					...EmptySystemRubricItem,
					rubricId: rubric.id,
					rubricItemNum: i + 1
				};

				itemArr.push(rubricItem);
			}

			rubric.items = [...itemArr];

			return rubric;
		});

		return rubricArr;
	};

	// applyNumberTypeToProjectRubricTransferList () {
	//   this.projectRubricTransferList.map(rubric => {
	//     rubric.score = Number(rubric.score);
	//     rubric.items.map(item => {
	//       item.score = Number(item.score);
	//     })
	//   });
	//
	//   this.applyChangesToProjectRubricTransferList();
	// };

	processEmptyRubric() {
		let projectRubricList = [...this.projectRubricTransferList];
		// 완전히 비어있는 projectRubric 제외
		projectRubricList = projectRubricList.filter(rubric =>
			!(!rubric.name
				&& rubric.items.every(item => !item.standard && !item.name))
		);
		this.projectRubricTransferList = [...projectRubricList];
	};

	async getProjectExamQuestions(projectCode: string) {
		try {
			this.changeIsSelectingProjectExamQuestions(true);
			// this.isSelectingProjectExamQuestions = true;

			if (this.projectExamQuestionsList.length > 0) {
				this.changeIsSelectingProjectExamQuestions(false);
				return false;
			}

			const data = await this.projectRepository.getProjectExamQuestions(projectCode);
			if (!data) {
				this.changeIsSelectingProjectExamQuestions(false);
				return false;
			}

			if (data.length === 0) {
				this.changeIsSelectingProjectExamQuestions(false);
				return true;
			}

			// 문항 order에 따른 정렬
			const projectExamQuestionsList = data.map(question => {
				const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
				question.key = randomNumber;
				question.score = Number(question.score);
				return question;
			}).sort((a, b) => {
				if (a.parentOrder !== b.parentOrder) {
					return a.parentOrder - b.parentOrder;
				}

				return a.order - b.order;
			});

			// 문항 번호를 위한 questionListLenObjPerExam 세팅
			let examOrderSet = new Set(projectExamQuestionsList.map(question => question.parentOrder));
			// let questionListLenObjPerExam = {};
			runInAction(() => {
				Array.from(examOrderSet).map(examOrder => {
					const questionListLen = projectExamQuestionsList.filter(question => question.parentOrder === examOrder).length;
					this.questionListLenObjPerExam = {
						...this.questionListLenObjPerExam,
						// [examOrder]: questionListLen
						examOrder: questionListLen
					};

					if (questionListLen === 1) {
						this.examQuestionOrderArr = [...this.examQuestionOrderArr, examOrder];

					} else if (questionListLen > 1) {

						projectExamQuestionsList.map(question => {
							if (question.parentOrder !== examOrder) return false;

							const questionFullOrder = `${examOrder}-${question.projectExamQuestionNum}`;
							this.examQuestionOrderArr = [...this.examQuestionOrderArr, questionFullOrder];
						})

						// let orderArr = [];
						// for (let i = 0; i < questionListLen; i++) {
						//   orderArr.push(`${examOrder}-${i + 1}`);
						// }
						// this.examQuestionOrderArr = [ ...this.examQuestionOrderArr, ...orderArr];
					}
				});

				this.oriProjectRubricTransferList = [];
				this.projectRubricTransferList = [];

				this.isProjectRubricListPreparedArr = Array(projectExamQuestionsList.length).fill(false);


				// this.changeIsSelectingProjectExamQuestions(false);


				projectExamQuestionsList.map((question, idx) => {
					this.addEmptySystemRubric(question.key);
					this.addEmptySystemRubricConditionsToSearch(question.key);
					this.addEmptySelectedSystemRubricIdList(question.key);
					this.changeQuestionKeyAndSelectedProjectRubricIdxMap(question.key, 0);
					this.getProjectRubricList(question.projectExamCode, question.projectExamQuestionNum, question.key, idx);
					this.addEmptyProjectRubric(question);
				});

				this.projectExamQuestionsList = projectExamQuestionsList;
			});


			console.log(LogPrefix, "Success getProjectExamQuestions ...", this.projectExamQuestionsList);
			return true;

		} catch (e) {
			console.log(LogPrefix, "Cannot getProjectExamQuestions ...", e);
			return false;
		} finally {
			// this.isSelectingProjectExamQuestions = false;
		}
	};

	async getSystemRubric(projectCode, questionKey) {
		try {
			if (!projectCode) return false;

			const data = await this.systemRubricRepository.getSystemRubricList({
				...this.questionKeyAndSystemRubricConditionsToSearchMap[questionKey],
				projectCode
			});

			if (!data) {
				return false;
			}

			runInAction(() => {
				data.rubrics = this.createRubricItemArr(data.rubrics);

				// this.systemRubricConditionsToSearch = { ...this.systemRubricConditionsToSearch, totalCount: data.totalCount };

				this.questionKeyAndSystemRubricListMap[questionKey] = data;
				this.questionKeyAndSystemRubricListMap = { ...this.questionKeyAndSystemRubricListMap };
			});

			return true;

		} catch (e) {
			console.log(LogPrefix, "Cannot getSystemRubric ...", e);
			return false;
		}
	};

	async getInitialSystemRubric(projectCode) {
		try {
			this.isSelectingInitialSystemRubric = true;

			if (!projectCode) return false;

			let data = await this.systemRubricRepository.getSystemRubricList({
				...EmptySystemRubricConditionsToSearch, projectCode
			});

			if (!data) {
				return false;
			}

			runInAction(() => {
				data.rubrics = this.createRubricItemArr(data.rubrics);

				// this.systemRubricConditionsToSearch = { ...this.systemRubricConditionsToSearch, totalCount: data.totalCount };

				// this.questionKeyAndSystemRubricListMap[questionKey] = data;
				Object.keys(this.questionKeyAndSystemRubricListMap).map(key => this.questionKeyAndSystemRubricListMap[key] = data);
				this.questionKeyAndSystemRubricListMap = { ...this.questionKeyAndSystemRubricListMap };
			});

			// console.log(LogPrefix, "Success getInitialSystemRubric ...", this.questionKeyAndSystemRubricListMap);
			return true;

		} catch (e) {
			console.log(LogPrefix, "Cannot getInitialSystemRubric ...", e);
			return false;
		} finally {
			runInAction(() => {
				this.isSelectingInitialSystemRubric = false;
			});
		}
	};

	processIsProjectRubricListPrepared(currentIdx) {
		if (currentIdx === undefined) {
			this.changeIsSelectingProjectExamQuestions(false);
		}

		this.changeIsProjectRubricListPreparedArr(currentIdx, true);
		const isAllPrepared = this.isProjectRubricListPreparedArr.every((item, idx) => {
			if (idx === currentIdx) return true;

			return item;
		});

		if (isAllPrepared) {
			this.changeIsSelectingProjectExamQuestions(false);
		}

		// return isAllPrepared;
	};

	async getProjectRubricList(projectExamCode, projectExamQuestionNum, questionKey, idx) {
		try {
			const projectRubricListToAdd = await this.projectRepository.getProjectRubricList(projectExamCode, projectExamQuestionNum);
			if (!projectRubricListToAdd || projectRubricListToAdd.length === 0) {
				this.processIsProjectRubricListPrepared(idx)

				// if (idx !== undefined) {
				//   this.processIsProjectRubricListPrepared(idx)
				//     .then(result => {
				//       if (!result) return;
				//
				//       this.changeIsSelectingProjectExamQuestions(false);
				//     })
				// }

				return false;
			}

			this.setIsNew(false);

			projectRubricListToAdd.map(rubric => {
				const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
				rubric.key = randomNumber;
				rubric.questionKey = questionKey;

				rubric.items.sort((a, b) => b.score - a.score);

				return rubric;
			})

			// this.changeQuestionKeyAndSelectedProjectRubricIdxMap(question.key, 0);

			runInAction(() => {
				this.oriProjectRubricTransferList = [...this.oriProjectRubricTransferList, ...projectRubricListToAdd];
				this.projectRubricTransferList = [
					...this.projectRubricTransferList.filter(rubric => rubric.questionKey !== questionKey),
					...projectRubricListToAdd
				];

				this.processIsProjectRubricListPrepared(idx)
			});

			// if (idx !== undefined) {
			//   this.processIsProjectRubricListPrepared(idx)
			//     .then(result => {
			//       if (!result) return;
			//
			//       this.changeIsSelectingProjectExamQuestions(false);
			//     })
			// }

			return true;

		} catch (e) {
			console.log(LogPrefix, "Cannot getProjectRubricList ...", e);

			this.processIsProjectRubricListPrepared(idx)

			// if (idx !== undefined) {
			//   this.processIsProjectRubricListPrepared(idx)
			//     .then(result => {
			//       if (!result) return;
			//
			//       this.changeIsSelectingProjectExamQuestions(false);
			//     })
			// }

			return false;
		}
	};

	async createProjectRubricList() {
		try {
			this.isCreatingProjectRubricList = true;

			if (this.invalidProjectRubricTransferList()) {
				return;
			}

			this.processEmptyRubric();

			// this.applyNumberTypeToProjectRubricTransferList();

			const data = await this.projectRepository.createProjectRubricList(this.projectRubricTransferList);
			if (!data) {
				console.log(LogPrefix, "Cannot createProjectRubricList ...");
				return false;
			}

			return true;

		} catch (e) {
			console.log(LogPrefix, "Cannot createProjectRubricList ...", e);
			return false;
		} finally {
			this.isCreatingProjectRubricList = false;
		}
	};

	async updateProjectRubricList() {
		try {
			this.isUpdatingProjectRubricList = true;

			if (this.invalidProjectRubricTransferList()) {
				return;
			}

			this.processEmptyRubric();
			this.setChangeFlagToProjectRubricTransferList();

			// this.applyNumberTypeToProjectRubricTransferList();

			const data = await this.projectRepository.updateProjectRubricList(this.projectRubricTransferList);

			return true;

		} catch (e) {
			console.log(LogPrefix, "Cannot updateProjectRubricList ...", e);
			return false;
		} finally {
			this.isUpdatingProjectRubricList = false;
		}
	};

	async createAIRubric(examCode, questionNum, rubric, itemCount, language: string) {
		try {
			const params = {
				examCode,
				questionNum,
				rubric,
				itemCount
			};

			const response = await this.aiRubricRepository.createAIRubric(params, language);
			console.log(LogPrefix, "Success createRubricAI ...", response);
			return response;

		} catch (e) {
			console.log(LogPrefix, "Cannot createRubricAI ...", e);
		}
	};

	async getAIRubricByExamCodeAndQuestionNum(examCode, questionNum) {
		try {
			const response = await this.aiRubricRepository.getAIRubricByExamCodeAndQuestionNum(examCode, questionNum);
			console.log(LogPrefix, "Success getAIRubricByExamCodeAndQuestionNum ...", response);
			return response;

		} catch (e) {
			console.log(LogPrefix, "Cannot getAIRubricByExamCodeAndQuestionNum ...", e);
		}
	};

	async getAIRubricByRubricId(rubricId) {
		try {
			const response = await this.aiRubricRepository.getAIRubricByRubricId(rubricId);
			// console.log(LogPrefix, "Success getAIRubricByRubricId ...", response);
			return response;

		} catch (e) {
			console.log(LogPrefix, "Cannot getAIRubricByRubricId ...", e);
		}
	};
}
