import { Box, Button, Popover, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { StudentTaskListStlye } from "../../styles/StudentTaskListStlye";

import useEmotionStyles from "../../styles/useEmotionStyles";
import { ISubmissionTransfer } from "../../../../repositories/model/transfer/ISubmissionTransfer";
import { convertUTCToKST } from "../../../../hooks/convertUTCToKST";

import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
//import { ReactComponent as SmartPenEnroll } from '../../../../../common/images/SmartPenEnroll.svg';
import { ReactComponent as SmartPenEnroll } from '../../../../common/images/SmartPenEnroll.svg';

import clsx from "clsx";
import { IProjectSubmissionTransfer } from "../../../../repositories/model/transfer/IProjectSubmissionTransfer";
import { ProjectSubmitType } from "../../../../repositories/model/support/ProjectSubmitType";
import i18next from "i18next";
import React from "react";
import { INcodeAllocationResponse } from "../../../../repositories/model/transfer/INcodeAllocationResponse";
import { useStores } from "../../../../hooks/useStores";

type Props = {
	studentTransfer: ISubmissionTransfer,
	projectSubmissionTransfer: IProjectSubmissionTransfer | null,
	projectCode: string,
	index: number,
    rowsPerPage: number,
    page: number,
    handleClickAnswer: any,
    handleClickCompletedReview: any,
    handleClickReview: any,
    handleClickProjectName: any,
	handleOpenPenDialog(projectCode: string, submissionIndex: number): void,
}

export function StudentTaskListRow(props: Props) {

    const { studentTransfer, projectSubmissionTransfer, projectCode, index, rowsPerPage, page, handleClickAnswer, handleClickCompletedReview, handleClickReview, handleClickProjectName, handleOpenPenDialog } = props;
	const classes = useEmotionStyles(StudentTaskListStlye);
	const { projectStore, ncodeAllocationStore } = useStores();
	
	const [ncodeAllocated, setNcodeAllocated] = React.useState<INcodeAllocationResponse>(null);

	const isPenUploaded = projectSubmissionTransfer?.submitType === ProjectSubmitType.PEN;


    const checkCompared = (state) => {
		switch (state) {
			case ProjectStateType.CREATED:
			case ProjectStateType.POSED:
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
				return false;
			case ProjectStateType.COMPARED:
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
				return true;
		}
	}

	React.useEffect(() => {
		const getNCodeAllocation = async () => {
			const projectInfo = await projectStore.fetchProject(projectCode);
			
			if (projectInfo) {
				const allocation = await ncodeAllocationStore.getProjectLastAllocation(projectCode, projectInfo?.templateCode);
				setNcodeAllocated(allocation);
			}
		}

		if (projectCode)
			getNCodeAllocation();

	}, [projectCode]);
    

    return (
        <TableRow key={index} sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}>
													<TableCell align="center">
														{(rowsPerPage * (page - 1)) + index + 1}
													</TableCell>
													<TableCell>
														<Button className={classes.tableStudentNameBtn} onClick={e => handleClickProjectName(studentTransfer.state, studentTransfer.projectCode, studentTransfer.submissionCode)} disableRipple>
															<Typography>{studentTransfer.projectName}</Typography>
														</Button>
													</TableCell>
													<TableCell align="center">
														<Typography className={classes.resultNumber}><span>{studentTransfer.scoredCount}</span>/{studentTransfer.totalCount}</Typography>
													</TableCell>
													<TableCell>
														{/*todo 채점결과 발표 === exported 일때 인데  어떻게 발표일을 정해야 할지 정해야함*/}
														{studentTransfer.scoredDatetime ? convertUTCToKST(studentTransfer.scoredDatetime).format("YYYY.MM.DD hh:mm") : "-"}
													</TableCell>
													<TableCell>
														{/*todo transfer에 온라인 제출 여부 포함필요*/}
														{studentTransfer.submittedDatetime ? convertUTCToKST(studentTransfer.submittedDatetime).format("YYYY.MM.DD hh:mm") : "-"}
													</TableCell>
													<TableCell>
														{/*todo transfer에 온라인 제출 기한 포함필요*/}
														{studentTransfer.deadline ? convertUTCToKST(studentTransfer.deadline).format("YYYY.MM.DD hh:mm") : "-"}
													</TableCell>
													{/*<TableCell>*/}
													{/*    {transfer.reviewedDatetime ? dayjs(transfer.reviewedDatetime).format("YYYY.MM.DD hh:mm") : "-"}*/}
													{/*</TableCell>*/}
													<TableCell>
														{studentTransfer.state === ProjectStateType.EXPORTED ?
															<>{studentTransfer.reviewedDatetime ?
																<Button className={clsx(classes.stepOpen, classes.taskSave)} onClick={(e) => { handleClickCompletedReview(studentTransfer.projectCode, studentTransfer.submissionCode) }} disableRipple>
																	<Typography>결과 리뷰완료</Typography>
																</Button>
																:
																<Button className={clsx(classes.stepOpen, classes.taskSave)} onClick={(e) => { handleClickReview(studentTransfer.projectCode, studentTransfer.submissionCode) }} disableRipple>
																	<Typography>결과 리뷰하기</Typography>
																</Button>
															}</>
															:
															<>{checkCompared(studentTransfer.state) ?
																<Button className={clsx(classes.stepOpen, classes.taskSave)} onClick={e => handleClickAnswer(studentTransfer.projectCode, studentTransfer.state, studentTransfer.submissionCode)} disableRipple>
																	<Typography>답안 제출완료</Typography>
																</Button>
																:
																<Button className={clsx(classes.stepOpen, classes.taskResult)} onClick={e => handleClickAnswer(studentTransfer.projectCode, studentTransfer.state, studentTransfer.submissionCode)} disableRipple>
																	<Typography>답안 제출하기</Typography>
																</Button>
															}</>
														}
													</TableCell>
												</TableRow>)

}