import { makePdfId, getNowTimeStr, cloneObj } from "../../nl-lib3-common/util/functions/indepFunctions";
import { storageAvailable } from "../../nl-lib3-common/util/functions/storageAvailable";
import { convertNuToPu } from "../../nl-lib3-common/util/unit-converters";
import { g_defaultNcode, g_defaultOverlayNcode, g_defaultTemporaryNcode } from "./constants/mapper-constants";
import { MappingItem } from "./MappingItem";
import PdfDocMapper from "./PdfDocMapper";
import { IAutoLoadDocPageDesc, IGetNPageTransformType, IMappingData, IPageMapItem, IPdfToNcodeMapItem } from "./types/MapDataTypes";
import { INoteServerItem_forPOD, IPageSOBP, IPolygonArea, IRectDpi, isSamePage } from "../../nl-lib3-common";
import { generateTempPaperTypeFromPdf } from "./functions/generateTempPaperTypeFromPdf";
import { generateTempPaperTypeFromSize } from "./functions/generateTempPaperTypeFromSize";
import { getNPaperInfo, getNPaperSize_pu } from "./functions/getNPaperInfo";
import { IPdfPageDesc } from "./types/IPdfPageDesc";
import { TransformParameters } from "../../nl-lib3-common/structures/mapper/TransformParameters";
import CoordinateTanslater from "./CoordinateTanslater";
import { EventDispatcher } from "../../nl-lib3-common/event/EventDispatcher";
import { availablePagesInSection, getNextNcodePage } from "../../nl-lib3-common/util/functions/depFunctions";
import { nullNcode } from "../../nl-lib3-common/constants/ncode-constants";
import { NeoPDFDocument } from "../../../wasm-pdf-core/src/worker-employer/classes/NeoPDFDocument";
import { IPdfManagerEvent, NeoPDFManager, PdfManagerEventName } from "../../../wasm-pdf-core/src/worker-employer/classes/NeoPDFManager";


export const BASECODE_PAGES_PER_SHEET = 16384;

export enum MappingStorageEventName {
	ON_MAPINFO_REFRESHED = "on_map_refreshed",
	ON_MAPINFO_ADDED = "on_map_changed",

	ON_PDF_UPDATED = "on_pdf_updated",
}


export type IMappingStorageEvent = {
	status: string,
	mapper?: PdfDocMapper,      // ON_MAP_INFO_CHANGED
	pdf?: NeoPDFDocument,
	docMap?: IPdfToNcodeMapItem
}

let globalMsInstance: MappingStorage = null as unknown as MappingStorage;
const LOCAL_STORAGE_ID = "GridaBoard_codeMappingInfo_v2";


/**
 *
 */
export class MappingStorage {
	/** 프로그램 실행이 끝나도 저장되어야 하는 mapping table */
	private _data: IMappingData = {
		nextIssuable: { ...g_defaultNcode },
		arrDocMap: []
	};

	/** 프로그램이 실행되는 동안만 유지되는 mapping table */
	private _temporary: IMappingData = {
		nextIssuable: { ...g_defaultTemporaryNcode },
		arrDocMap: []
	};

	/** 2021/08/25 오버레이를 위한 것 */
	private _overlayMap: IMappingData = {
		nextIssuable: { ...g_defaultOverlayNcode },
		arrDocMap: []
	};


	get rawData() {
		return this._data;
	}

	get rawDataTemp() {
		return this._temporary;
	}

	// _testStorage;

	dispatcher: EventDispatcher = new EventDispatcher();


	private constructor() {
		// https://www.bsidesoft.com/1426 , [js] localStorage 키별 용량 제약 처리
		// 이것 참고해서 더 수정할 것

		// this.loadMappingInfo();

		// PDF가 refresh 되면, 현재 page의 배경을 update해 줘야 할수도 있다. 2021/06/06

		NeoPDFManager.addEventListener(PdfManagerEventName.ON_PDF_LOADED, this.onPdfLoaded);

		this.initNextIssuable();
	}

	static getInstance() {
		if (globalMsInstance) return globalMsInstance;

		globalMsInstance = new MappingStorage();
		return globalMsInstance;
	}

	static get instance() {
		return MappingStorage.getInstance();
	}

	static makeAssignedNcodeArray = (startPage: IPageSOBP, numPages: number) => {
		const pages: IPageSOBP[] = [];
		for (let i = 0; i < numPages; i++) {
			const pi = getNextNcodePage(startPage, i);
			pages.push(pi);
		}

		return pages;
	}

	/**
	 * PDF가 load되었으면, fingerprint에서 undefined로 된 것을 update해 준다
	 * 2021/06/06 kitty
	 *
	 * @param event
	 */
	onPdfLoaded = ({ pdf }: IPdfManagerEvent) => {
		// console.log(`PDF LOADING: MSI catch the message ${pdf?.fingerprint}`)

		const found = this._data.arrDocMap.find((m) => m.url === pdf?.url);
		if (found) {
			const id_arr = found.id.split("/");
			found.id = (pdf?.fingerprint || "") + "/" + id_arr[1];
			// console.log("pdf loaded", found);

			this.dispatcher.dispatch(
				MappingStorageEventName.ON_PDF_UPDATED,
				{ status: "pdf updated", pdf, docMap: found }
			);
		}
	}



	private initNextIssuable = () => {
		if (this._data.nextIssuable.section === -1) {
			this.nextIssuable = g_defaultNcode;
		}
	}

	public reset = () => {
		this._data = {
			nextIssuable: { ...g_defaultNcode },
			arrDocMap: [],
		};
	}

	get nextIssuable() {
		return this._data.nextIssuable;
	}

	set nextIssuable(sobp: IPageSOBP) {
		this._data.nextIssuable = { ...sobp };
	}

	/**
	 * PDF fingerprint와 pagesPerSheet로 mapping된 ncode를 찾는다
	 *
	 * 이 함수를 dialog로 수정함으로써 프린터 옵션을 바꿀 수 있다
	 * @param {string} fingerprint
	 * @param {number} pagesPerSheet
	 * @param {number} docNumPages
	 * @memberof MappingStorage
	 */
	public getAssociatedMappingInfo = (fingerprint: string, pagesPerSheet: number) => {
		const theSames = this.findAssociatedPrintNcode(fingerprint, pagesPerSheet);
		const theSame = theSames.length > 0 ? theSames[0] : undefined;

		return theSame;
	}


	/**
	 * pageInfo에서 PDF page를 찾는다
	 * @param {IPageSOBP} sobp
	 * @memberof MappingStorage
	 */
	public getAssociatedPdfPage = (sobp: IPageSOBP) => this.findPdfPage(sobp);

	// 2022/07/18, pdfDoc의 setPageOverview가 불려진 상태에서 이 함수를 호출해야 한다.
	// eslint-disable-next-line class-methods-use-this
	private makeDummyParamsForBasePageInfo = async (
		arg: {
			sobp: IPageSOBP,
			url: string,
			filename: string,
			fingerprint: string,
			pdfDoc: NeoPDFDocument | undefined,   // 2021/08/02

			numPages: number,
			background_image_url_rule: string,
			isPod: boolean,
			shouldGetPaperInfoFromPdf?: boolean,

			// 2021/08/25
			size_pu?: { width: number, height: number }
		}
	) => {
		// const { docNumPages: numPages, fingerprint, url, filename } = printOption;

		const {
			sobp,
			url, filename, fingerprint,
			numPages,
			background_image_url_rule,
			isPod,
			pdfDoc,
			shouldGetPaperInfoFromPdf,
			size_pu
		} = arg;


		// dummy
		const pages: IPageMapItem[] = [];
		for (let i = 0; i < numPages; i++) {
			const pageNo = i + 1;
			const mapData = new MappingItem(pageNo);      // h의 기본값은 여기서 세팅된다.

			let pi: INoteServerItem_forPOD;
			if (shouldGetPaperInfoFromPdf) {
				pi = await generateTempPaperTypeFromPdf(sobp, pdfDoc, i);
			} else if (size_pu) {
				// 2021/08/25, Overlay를 위해
				pi = generateTempPaperTypeFromSize(sobp, size_pu, i);
			} else {
				pi = getNPaperInfo(sobp);
			}

			const paperSize_pu = getNPaperSize_pu(pi);

			// dummy Ncode page info
			const { Xmin: x0, Xmax: x1, Ymin: y0, Ymax: y1 } = pi.margin;

			// 아래 부분은 고쳐야 한다. kitty, 2021/01/02
			const rect: IRectDpi = { unit: "nu", x: x0, y: y0, width: x1 - x0, height: y1 - y0 };
			const npageArea: IPolygonArea = [{ x: x0, y: y0 }, { x: x1, y: y0 }, { x: x1, y: y1 }, { x: x0, y: y1 }];

			let page = sobp.page + i;
			if (isPod) {
				const maxPages = availablePagesInSection(sobp.section);
				page = (page + maxPages) % maxPages;
			}

			const printPageInfo = { ...sobp, page };
			mapData.setNcodeArea({ sobp: printPageInfo, rect, npageArea });

			// dummy PDF page info
			const pdfPageInfo: IPdfPageDesc = {
				url,
				filename,
				fingerprint,
				id: makePdfId({ paperGroupId: fingerprint, pagesPerSheet: BASECODE_PAGES_PER_SHEET }),
				numPages,
				pageNum: pageNo,
				size_pu: paperSize_pu,
			};
			mapData.setPdfArea({ pdfPageInfo, rect: { unit: "pu", x: 0, y: 0, width: paperSize_pu.width, height: paperSize_pu.height } });

			pages.push(mapData.page);
		}

		const basePdfToNcodeMap: IPdfToNcodeMapItem = {
			url,
			filename,
			fingerprint,
			pagesPerSheet: BASECODE_PAGES_PER_SHEET,
			id: makePdfId({ paperGroupId: fingerprint, pagesPerSheet: BASECODE_PAGES_PER_SHEET }),
			numPages,
			background_image_url_rule,

			printPageInfo: { ...sobp },
			sobp: { ...sobp },

			pages,
			timeString: getNowTimeStr(),

			// 2021/08/12
			// pdfDoc: null,
		}
		return basePdfToNcodeMap;
	}


	// eslint-disable-next-line class-methods-use-this
	private calcHfromNote = (arg: { Xmin: number, Ymin: number, Xmax?: number, Ymax?: number, pageNo: number }) => {
		const { Xmin: x0, Ymin: y0, Xmax: x1, Ymax: y1, pageNo } = arg;
		if (x1 === undefined || y1 === undefined) {
			const h = new TransformParameters();
			return h;
		}
		const mapData = new MappingItem(pageNo);
		const w_nu = x1 - x0;
		const h_nu = y1 - y0;

		const w_pu = convertNuToPu(w_nu);
		const h_pu = convertNuToPu(h_nu);


		const srcPoints: IPolygonArea = [{ x: x0, y: y0 }, { x: x1, y: y0 }, { x: x1, y: y1 }, { x: x0, y: y1 }];
		const dstPoints: IPolygonArea = [{ x: 0, y: 0 }, { x: w_pu, y: 0 }, { x: w_pu, y: h_pu }, { x: 0, y: h_pu }];
		mapData.setSrc4Points_ncode(srcPoints);
		mapData.setDst4Points_pdf(dstPoints);

		const trans = new CoordinateTanslater();
		const h = trans.calc(mapData);
		return h;
	}

	public getNPageTransform = (sobp: IPageSOBP) => {
		let h: TransformParameters;
		const ret: IGetNPageTransformType = { pdf: {} } as IGetNPageTransformType;

		// 1) Ncode 페이지 맵에 있는지 확인한다.
		const noteItem = getNPaperInfo(sobp);
		if (!noteItem.isDefault) {
			h = this.calcHfromNote({ ...noteItem.margin, pageNo: sobp.page });
			ret.h = h;
			ret.type = "note";
			ret.sobp = sobp;

			ret.pdf.filename = noteItem.pdf_name || "";

			return ret;
		}

		// 2) Mapping된 PDF 페이지인지 확인한다.
		// const pdfItem = this.findPdfPage({ ...sobp, x: 10, y: 10 });
		const pdfItem = this.getAssociatedPdfPage(sobp);
		if (pdfItem) {
			const pageMap = pdfItem.pageMapping;
			ret.h = pageMap.h;
			ret.type = "pod";
			ret.sobp = pageMap.sobp;

			ret.pdf = {
				url: pdfItem.pdf.url,
				filename: pdfItem.pdf.filename,
				fingerprint: pdfItem.pdf.fingerprint,
				numPages: pdfItem.pdf.numPages,

				pdfPageNo: pageMap.pdfPageNo,
				pagesPerSheet: pdfItem.pdf.pagesPerSheet,
			}
			return ret;
		}

		// 3) 아니면 그냥 A4를 리턴한다.
		const defaultItem = getNPaperInfo({ section: -1, owner: -1, book: -1, page: -1 });
		h = this.calcHfromNote({ ...defaultItem.margin, pageNo: sobp.page });
		ret.h = h;

		ret.type = "default";
		ret.sobp = sobp;

		return ret;
	}

	/**
	 * mapping되지 않은 PDF나, ncode 공책들의 임시 mapping table을 생성하고 돌려주는 주고
	 */
	public makeTemporaryAssociateMapItem = async (option: {
		/** Ncode 로 추가되는 페이지의 경우 */
		n_paper: IPageSOBP,
		/** PDF로 추가되는 페이지의 경우 */
		pdf: {
			url: string,
			filename: string,
			fingerprint: string,
			numPages: number,
			pdfDoc: NeoPDFDocument
		},
		/** Blank page의 경우 */
		numBlankPages: number,
		background_image_url_rule: string,

		/** 2021/08/25, size가 있는 경우, overlay를 위해 */
		size_pu?: { width: number, height: number, sobp?: IPageSOBP }
	}) => {
		const { pdf, n_paper, numBlankPages, background_image_url_rule, size_pu } = option;

		if (!pdf && !n_paper && !numBlankPages) {
			console.error("n_paper neighter pdf has not been given to makeTemporaryAssociateMapItem ");
			return undefined;
		}

		// 2021/08/04 kitty, n_paper로 SOBP가 주어지면 따로 로컬에서 임시 코드를 가져오지 않는다.
		const nextLocalCode = getNextNcodePage(this._temporary.nextIssuable, 0);
		const nextCode = n_paper || nextLocalCode;

		let basePdfToNcodeMap: IPdfToNcodeMapItem;
		if (pdf) {
			const { url, filename, fingerprint, numPages } = pdf.pdfDoc;

			await pdf.pdfDoc.setPageOverview();

			// 매핑되지 않은 PDF를 추가
			if (isSamePage(nextLocalCode, n_paper)) {
				this._temporary.nextIssuable = getNextNcodePage(this._temporary.nextIssuable, pdf.numPages);
			}

			basePdfToNcodeMap = await this.makeDummyParamsForBasePageInfo({
				sobp: nextCode,
				// url: pdf.url,
				// filename: pdf.filename,
				// fingerprint: pdf.fingerprint,
				// numPages: pdf.numPages,
				url,
				filename: filename || "",
				fingerprint: fingerprint || "",
				numPages,
				pdfDoc: pdf.pdfDoc,   // 2021/08/02 kitty
				shouldGetPaperInfoFromPdf: true,    // 2021/08/02 kitty

				background_image_url_rule,
				isPod: true
			});
		} else if (n_paper) {
			// Ncode가 들어와서 추가되는 페이지
			const pi: INoteServerItem_forPOD = getNPaperInfo(n_paper);
			const sobp = { ...n_paper, page: pi.ncode_start_page || 0 };

			basePdfToNcodeMap = await this.makeDummyParamsForBasePageInfo({
				sobp,
				url: pi.pdf_name || "",
				filename: pi.pdf_name || "",
				fingerprint: pi.pdf_name || "",
				pdfDoc: undefined,
				numPages: (pi?.pdf_page_count || 0) + 1,
				background_image_url_rule,
				isPod: false
			});
		} else if (size_pu) {
			let nextCode2 = size_pu.sobp;
			if (!nextCode2) {
				// sobp가 주어지지 않았으면 임시로 만든다
				nextCode2 = size_pu.sobp ? size_pu.sobp : getNextNcodePage(this._overlayMap.nextIssuable, 0);
				this._overlayMap.nextIssuable = getNextNcodePage(this._overlayMap.nextIssuable, numBlankPages);
			}

			basePdfToNcodeMap = await this.makeDummyParamsForBasePageInfo({
				sobp: nextCode2,
				url: "",
				filename: "",
				fingerprint: "",
				pdfDoc: undefined,
				numPages: numBlankPages,
				background_image_url_rule,
				isPod: true,
				size_pu
			});
		} else {
			//
			const nextCode3 = getNextNcodePage(this._temporary.nextIssuable, 0);
			this._temporary.nextIssuable = getNextNcodePage(this._temporary.nextIssuable, numBlankPages);

			const piBlank: INoteServerItem_forPOD = getNPaperInfo(nullNcode());

			// if (!piBlank.pdf_name) throw new Error("fingerprint not provided");
			basePdfToNcodeMap = await this.makeDummyParamsForBasePageInfo({
				sobp: nextCode3,
				url: piBlank.pdf_name || "",
				filename: piBlank.pdf_name || "",
				fingerprint: piBlank.pdf_name || "",
				pdfDoc: undefined,
				numPages: numBlankPages,
				background_image_url_rule,
				isPod: true
			});
			// nextCode, piBlank.pdf_name, piBlank.pdf_name, piBlank.pdf_name, numBlankPages, background_image_url_rule, true);
		}

		this._temporary.arrDocMap.unshift(basePdfToNcodeMap);

		this.dispatcher.dispatch(
			MappingStorageEventName.ON_MAPINFO_ADDED,
			{ status: "new temp map added", pdf: pdf?.pdfDoc, docMap: basePdfToNcodeMap }
		);

		return basePdfToNcodeMap;
	}


	/**
	 * 오버레이에서만 쓰만 함수, temporary에 등록된 오버레이용 페이지의 사이즈를 수정할 때 쓴다
	 * @param sobp
	 * @param size_pu
	 */
	changeMappedPdfPageSize = (arg: {
		sobp: IPageSOBP,
		size_pu: { width: number, height: number }
	}) => {
		const { sobp, size_pu } = arg;
		const found = this.getAssociatedPdfPage(sobp);
		if (!found) return;

		const { pageMapping } = found;

		const { pdfDesc, npageArea } = pageMapping;

		const pu_to_nu_x = npageArea[2].x / pdfDesc.size_pu.width;
		const pu_to_nu_y = npageArea[2].y / pdfDesc.size_pu.height;

		pageMapping.pdfDesc.size_pu = {
			width: size_pu.width,
			height: size_pu.height
		};
		npageArea[2].x = size_pu.width * pu_to_nu_x;
		npageArea[2].y = size_pu.height * pu_to_nu_y;

		npageArea[1].x = size_pu.width * pu_to_nu_x;
		npageArea[3].y = size_pu.height * pu_to_nu_y;

		// H는 그대로 둔다

		// 페이지가 바뀌었다고 알려줍시다.
		this.dispatcher.dispatch(
			MappingStorageEventName.ON_MAPINFO_ADDED,
			{ status: "new temp map added", pageMap: pageMapping }
		);
	}


	register = (mapper: PdfDocMapper) => {
		mapper.makeSummary();

		// 2021/08/12 중복되는 것은 지워주자
		const foundIndex = this._data.arrDocMap.findIndex((map) => (
			mapper.docMap.fingerprint === map.fingerprint
			&& isSamePage(mapper.docMap.printPageInfo, map.printPageInfo)
			&& mapper.docMap.numPages === map.numPages
		));

		const { docMap } = mapper;
		docMap.timeString = getNowTimeStr();
		if (foundIndex < 0) {
			// 이제 등록하자
			this._data.arrDocMap.unshift(docMap);
			this.dispatcher.dispatch(
				MappingStorageEventName.ON_MAPINFO_ADDED,
				{ status: "new map added", docMap }
			);
		} else {
			this._data.arrDocMap[foundIndex] = docMap;
		}
		// this.storeMappingInfo();
	}

	/**
	 * pen down시에 새로운 SOBP라면, 관련된 PDF가 있는지 찾을 때 쓰인다
	 */
	findPdfPage = (sobp: IPageSOBP) => {
		// 2021/08/18
		if (!sobp) {
			return undefined;
		}
		const { section: s, owner: o, book: b } = sobp;
		const pageIncluded = (m: IPdfToNcodeMapItem) => {
			const { section: s2, owner: o2, book: b2 } = m.printPageInfo;
			if (s !== s2 || o !== o2 || b !== b2) return false;
			const pageFound = m.pages.find((p) => isSamePage(sobp, p.sobp));
			return !!pageFound;
		}

		// 2021/08/18
		const found = this._data.arrDocMap.find((m) => pageIncluded(m));
		// const found = this._data.arrDocMap.find(m => isPageInRange(ncodeXy, m.printPageInfo, m.numPages));
		if (found) {
			/** 원래는 폴리곤에 속했는지 점검해야 하지만, 현재는 같은 페이지인지만 점검한다  2020/12/06 */
			const pageMap = found.pages.find((param) => isSamePage(sobp, param.sobp));
			return { pdf: found, pageMapping: pageMap } as IAutoLoadDocPageDesc;
		}

		// 2021/08/18
		const found_temporary = this._temporary.arrDocMap.find((m) => pageIncluded(m));
		// const found_temporary = this._temporary.arrDocMap.find(m => isPageInRange(ncodeXy, m.printPageInfo, m.numPages));
		if (found_temporary) {
			/** 원래는 폴리곤에 속했는지 점검해야 하지만, 현재는 같은 페이지인지만 점검한다  2020/12/06 */
			const pageMap = found_temporary.pages.find((param) => isSamePage(sobp, param.sobp));
			return { pdf: found_temporary, pageMapping: pageMap } as IAutoLoadDocPageDesc;
		}

		return undefined;
	}

	/**
	 * Ncode가 발행된 적이 있는지를 점검하기 위해서 쓰인다.
	 */
	findAssociatedNcode = (fingerprint: string, pagesPerSheet: number) => {
		const theBase = this.findAssociatedBaseNcode(fingerprint);
		const theSames = this.findAssociatedPrintNcode(fingerprint, pagesPerSheet);

		return { theBase, theSames }
	}

	findAssociatedPrintNcode = (fingerprint: string, pagesPerSheet: number) => {
		const id = makePdfId(fingerprint, pagesPerSheet as number);

		const theSames = this._data.arrDocMap.filter((m) => id === m.id);
		// theSames.sort((a, b) => b.timeString > a.timeString ? 1 : (b.timeString === a.timeString ? 0 : -1));

		return theSames;
	}

	findAssociatedBaseNcode = (fingerprint: string) => {
		const baseId = makePdfId(fingerprint, BASECODE_PAGES_PER_SHEET);

		// const theBase = this._data.arrDocMap.find(m => baseId === m.id);
		const theBases = this._data.arrDocMap.filter((m) => baseId === m.id);
		// theBases.sort((a, b) => b.timeString > a.timeString ? 1 : (b.timeString === a.timeString ? 0 : -1));
		const theBase = theBases.length > 0 ? theBases[0] : undefined;

		return theBase;
	}

	dump = (prefix: string) => {
		console.log(`[${prefix}]==============================================================================================================================`);
		this.dumpJson(prefix, this._data.nextIssuable);
		console.log(`[${prefix}]..............................................................................................................................`);

		for (let i = 0, l = this._data.arrDocMap.length; i < l; i++) {
			const item = this._data.arrDocMap[i];
			const clone = cloneObj(item);
			clone.params = null;
			this.dumpJson(prefix, clone);
			console.log(`[${prefix}]..............................................................................................................................`);
		}
		console.log(`[${prefix}]==============================================================================================================================`);
	}

	// eslint-disable-next-line class-methods-use-this
	dumpJson = (prefix: string, obj: any) => {
		const str = JSON.stringify(obj, null, "  ");
		const arr = str.split("\n");

		for (let i = 0, l = arr.length; i < l; i++) {
			console.log(`[${prefix}] ${arr[i]}`);
		}
	}

	clear = () => {
		this.reset();
		this.storeMappingInfo();
		console.log("Mapping information cleared");
	}

	storeMappingInfo = () => {
		try {
			if (storageAvailable("localStorage")) {
				const key = LOCAL_STORAGE_ID;
				const value = JSON.stringify(this._data);
				// console.log(`Pdf Ncode Info Saved   ${key}: ${value}`);
				localStorage.setItem(key, value);

				return true;
			}

			return false;
		} catch (e) {
			console.error(e);
			return false;
		}
	}

	public addEventListener = (eventName: MappingStorageEventName, listener: (event: IMappingStorageEvent) => void) => {
		this.dispatcher.add(eventName, listener);
	}

	/**
	 *
	 * @param eventName
	 * @param listener
	 */
	public removeEventListener = (eventName: MappingStorageEventName, listener: (event: IMappingStorageEvent) => void) => {
		this.dispatcher.remove(eventName, listener);
	}


	/**
	 * app이 기동되면 반드시 처음에 load하자
	 *
	 * @return {boolean}
	 */
	loadMappingInfo = () => {
		if (storageAvailable("localStorage")) {
			const key = LOCAL_STORAGE_ID;
			const value = localStorage.getItem(key);

			if (value) {
				this._data = JSON.parse(value);

				// 최신순으로 소팅
				// eslint-disable-next-line no-nested-ternary
				this._data.arrDocMap.sort((a, b) => (b.timeString > a.timeString ? 1 : (b.timeString === a.timeString ? 0 : -1)));

				// 개발중 코드, Data Structure가 바뀐 것이라면 reset kitty
				const validStoredData = true;

				// const found = this._data.arrDocMap.forEach(m => { if (!m.printPageInfo || !m.numPages) validStoredData = false; });
				if (!validStoredData) {
					this.clear();
				}


				// this.dump("loading");

				// const debug = JSON.stringify(this._arrMapped);
				// console.log(`Pdf Ncode Info Loaded   ${key}: ${debug}`);
				return true;
			}
		}

		return false;
	}
}

