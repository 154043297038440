import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Theme,
	Tooltip,
	Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditContainedIcon } from '../../../../common/images/EditContainedIcon.svg';
import { ReactComponent as PageUpIcon } from '../../../../common/images/PageUpIcon.svg';
import { ReactComponent as TableListOrder } from '../../../../common/images/TableListOrder.svg';
import { SchoolLevelSubjects } from "../../../../common/SchoolLevelSubjects";

import { useParams } from "react-router-dom";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { useStores } from "../../../../hooks/useStores";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
import { ProjectSchoolType, ProjectUserSortColumn } from "../../../../stores/enums/ProjectStoreEnums";
import { setQuestionHTMLWithOrderAndScore } from "../../../../util/setQuestionHTMLWithOrderAndScore";
import { getSemesterTypeString } from "../../000_Common/001_Functions/getSemesterTypeString";
import { AnswerTab } from "../../000_Common/002_Tabs/AnswerTab";
import { PlanTab } from "../../000_Common/002_Tabs/PlanTab";
import { QuestionAndReferenceTab } from "../../000_Common/002_Tabs/QuestionAndReferenceTab";
import { RubricTab } from "../../000_Common/002_Tabs/RubricTab";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { CustomProjectAssignmentStyle } from "../../styles/CustomProjectAssignmentStyle";
import { ProjectUserTableRow } from "./ProjectUserTableRow";



const NavigateType = {
	ASSIGNMENT_INFO: "ASSIGNMENT_INFO",
	ASSIGNMENT_TARGET: "ASSIGNMENT_TARGET",
	PROJECT_INFO: "PROJECT_INFO",
};


type Props = {
}

function CustomProjectAssignment(props: Props) {
	const params = useParams();
	const classes = useEmotionStyles( CustomProjectAssignmentStyle );
	const i18next = useTranslation();

	const basicInfoRef = React.useRef<HTMLElement>();
	const testTargetRef = React.useRef<HTMLElement>();
	const templateProjectRef = React.useRef<HTMLElement>();

	const [tabIndex, setTabIndex] = React.useState(0);
	const [projectCode, setProjectCode] = React.useState("");
	const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [isSelectingAll, setIsSelectingAll] = React.useState(false);

	const { projectStore, projectRagStore, projectExamStore, projectRubricStore, messageStore } = useStores();
	const { project, projectUsers, projectStates } = projectStore;
	const {
		projectExamQuestionsList,
		questionListLenObjPerExam,
		projectRubricTransferList,
		questionKeyAndSelectedProjectRubricIdxMap,
		questionKeyAndSystemRubricConditionsToSearchMap,
		questionKeyAndSystemRubricListMap,
		questionKeyAndSelectedSystemRubricIdsMap,
	} = projectRubricStore;

	const naviCustom = useNaviCustom();


	const isMenuOpen = Boolean(menuAnchorEl);

	React.useEffect(() => {
		const currentProjectCode = params?.projectCode;
		if (!currentProjectCode || currentProjectCode === projectCode) return;

		setProjectCode(currentProjectCode);
	}, [params]);

	React.useEffect(() => {
		if (!projectCode) return;

		projectStore.downloadProjectInfoAndStore(projectCode);
		projectStore.getProjectStates(projectCode);
		projectStore.getProjectUserTransfers(projectCode, []);
		projectStore.getProjectPlanTransfer(projectCode);
		projectExamStore.downloadProjectExamListAndStore(projectCode);
		projectRagStore.getProjectRag(projectCode);
		projectRubricStore.getProjectExamQuestions(projectCode);

		setIsSelectingAll(true);

		return () => {
			projectStore.init();
			projectExamStore.init();
			projectRagStore.init();
			projectRubricStore.init();
		};

	}, [projectCode]);

	React.useEffect(() => {
		if (!isSelectingAll) return;

		if (
			!projectStore.isSelectingProjectInfo &&
			!projectStore.isSelectingProjectStates &&
			!projectStore.isSelectingProjectUserTransfers &&
			!projectStore.isSelectingProjectPlanTransfer &&
			!projectExamStore.isSelectingProjectExamList &&
			!projectRagStore.isSelectingProjectRag &&
			!projectRubricStore.isSelectingProjectExamQuestions
		) {
			setIsSelectingAll(false);
		}

	}, [
		isSelectingAll,
		projectStore.isSelectingProjectInfo,
		projectStore.isSelectingProjectStates,
		projectStore.isSelectingProjectUserTransfers,
		projectStore.isSelectingProjectPlanTransfer,
		projectExamStore.isSelectingProjectExamList,
		projectRagStore.isSelectingProjectRag,
		projectRubricStore.isSelectingProjectExamQuestions,
	]);

	const handleChangeTab = (event, newIndex) => {
		switch (newIndex) {
			case 0:
				basicInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
				break;
			case 1:
				testTargetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				break;
			case 2:
				templateProjectRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				break;
		}

		setTabIndex(newIndex);
	};

	const handleClickScrollToTopBtn = () => {
		const projectTotalInfo = document.getElementById('projectTotalInfo');
		if (projectTotalInfo) {
			projectTotalInfo.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}


	const currentAssignmentType = React.useMemo(() => {
		return project?.includedOnline ? i18next.t("온라인 평가과제") : i18next.t("오프라인 평가과제");
	}, [project?.includedOnline]);

	const currentSchoolLevelObj = React.useMemo(() => {
		return SchoolLevelSubjects.find(school => school.name === project?.schoolType);
	}, [project]);

	const currentSubjectGroupObj = React.useMemo(() => {
		if (!currentSchoolLevelObj) return;

		return currentSchoolLevelObj.subjectGroups.find(group => group.name === project?.subject);
	}, [project, currentSchoolLevelObj]);

	const currentSubjectObj = React.useMemo(() => {
		if (!currentSubjectGroupObj) return;

		return currentSubjectGroupObj.subjects.find(subject => subject.name === project?.subjectName);
	}, [project, currentSubjectGroupObj]);

	const handleClickTemplateProjectInfo = () => {
		if (!projectCode) return;

		naviCustom.go(`/projects/${projectCode}/create/view`);
	}

	const [basicInfoComplete, setBasicInfoComplete] = React.useState(false);
	const [testTargetDone, setTestTargetDone] = React.useState(false);
	const [menuItemAnchorEl, setMenuItemAnchorEl] = React.useState(null);
	const menuItemOpen = Boolean(menuItemAnchorEl);
	const [questionDone, setQuestionDone] = React.useState(false);
	const [projectPosed, setProjectPosed] = React.useState(false);
	const [onlineAnchorEl, setOnlineAnchorEl] = React.useState(null);
	const openOnlineTool = Boolean(onlineAnchorEl);
	const onlineId = openOnlineTool ? 'simple-popover' : undefined;
	const anchorRef = React.useRef(null);
	const open = Boolean(onlineAnchorEl);
	const id = open ? 'simple-popper' : undefined;

	const handleClickPosedBtn = () => {
		handleClickPosed();
	};

	const handleClickDisabledPosedBtn = () => {
		messageStore.alert(i18next.t("이미 제시되었습니다."));
	};

	const handleClickPosed = async () => {
		if (projectStates?.submit) {
			return;
		}

		await projectStore.updateProjectState(projectCode, ProjectStateType.POSED);

		const alertText = project?.includedOnline ? i18next.t("온라인 출제되었습니다.") : i18next.t("오프라인 출제되었습니다.");

		await messageStore.alert(alertText)
			.then(_ => {
				naviCustom.go(`/assignments/${projectCode}/view`);
			})
	};

	const handleClickOfflinePosed = async () => {
		if (projectStates?.submit) {
			return;
		}

		await projectStore.updateProjectState(projectCode, ProjectStateType.POSED);

		await messageStore.alert(i18next.t("오프라인 출제되었습니다."))
			.then(_ => {
				naviCustom.go(`/assignments`);
			})
	}

	const handleClickOnlinePosed = async () => {
		if (projectStates?.submit) {
			return;
		}

		await projectStore.updateProjectState(projectCode, ProjectStateType.POSED);

		await messageStore.alert(i18next.t("온라인 출제되었습니다."))
			.then(_ => {
				naviCustom.go(`/assignments`);
			})
	}

	const rowStartIdx = React.useMemo(() => {
		return page * rowsPerPage;
	}, [page, rowsPerPage]);

	const handleClickPage = (e, value) => {
		setPage(value - 1);
	};

	const projectUsersCount = React.useMemo(() => {
		if (!projectUsers) return 0;

		const totalCount = projectUsers?.length;

		return totalCount > 0
			? totalCount > rowsPerPage
				? Math.ceil(totalCount / rowsPerPage)
				: 1
			: 0
	}, [projectUsers, rowsPerPage]);

	const [sortStateMap, setSortStateMap] = React.useState({
		[ProjectUserSortColumn.NAME]: { sort: false },
		[ProjectUserSortColumn.SCHOOL]: { sort: false },
		[ProjectUserSortColumn.GRADE_CLASSNAME]: { sort: false },
	});

	const handleClickSort = (columnName) => {
		const sortState = sortStateMap[columnName];
		if (sortState) {
			sortState.sort = !sortState.sort;
			setSortStateMap({
				...sortStateMap,
				[columnName]: sortState,
			});

			projectStore.sortProjectUserTransfer(columnName, sortState.sort);
		}
	};

	const handleClickEdit = (type) => {
		if (!projectCode) return;

		switch (type) {
			case NavigateType.ASSIGNMENT_INFO:
				naviCustom.go(`/assignments/${projectCode}/create/info`);
				break;
			case NavigateType.ASSIGNMENT_TARGET:
				naviCustom.go(`/assignments/${projectCode}/create/target`);
				break;
			case NavigateType.PROJECT_INFO:
				naviCustom.go(`/projects/${projectCode}/create/info`);
		}
	};

	const handleClickMoveToList = () => {
		naviCustom.go(`/assignments`);
	};

	const currentSemesterType = React.useMemo(() => {
		const semesterType = project?.semesterType;
		return getSemesterTypeString(project?.year, semesterType);
	}, [project?.semesterType]);

	return (
		<div className={classes.root}>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 85px)'
				}}
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box className={classes.commonTopTitle}>
						<Typography className={classes.commonMainTitle}>{i18next.t("4. 과제 제시하기")}</Typography>
						<Box>
							<Button
								className={classes.saveBtn}
								onClick={handleClickMoveToList}
								disableRipple>
								<Typography>{i18next.t("목록으로")}</Typography>
							</Button>

							<Tooltip
								title={projectStates?.submit ?
									i18next.t("이미 제시되었습니다.")
									:
									i18next.t("'제시'는 과제별 1회만 가능합니다. 동일 과제를 여러번 제시하려면 과제를 복제하여 사용해 주세요.")}
								placement="bottom"
								classes={{ tooltip: classes.lightTooltip }}>
								<Button
									className={classes.nextBtn}
									id="basic-button"
									onClick={handleClickPosedBtn}
									disabled={projectStates?.submit}
									disableRipple>
									<Typography>{i18next.t("제시하기")}</Typography>
								</Button>
							</Tooltip>
						</Box>
					</Box>

					<Tabs value={tabIndex} onChange={handleChangeTab} className={classes.trigger2}>
						<Tab value={0} label={i18next.t('평가과제 운영 정보')} disableRipple />
						<Tab value={1} label={i18next.t('평가 대상')} disableRipple />
						<Tab value={2} label={i18next.t('평가과제')} disableRipple />
					</Tabs>
					<div className={classes.root}>
						<Box className={classes.scrollBox}>
							<Box ref={basicInfoRef} display='flex' alignItems='center' justifyContent='left'
								className={classes.commonTitleBox}>
								<Box>
									<Typography className={classes.commonSubTitle3} id={"projectTotalInfo"}>{i18next.t("평가과제 운영 정보")}</Typography>
								</Box>
								<IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple
									onClick={() => handleClickEdit(NavigateType.ASSIGNMENT_INFO)}>
									<EditContainedIcon />
								</IconButton>
							</Box>

							<Box className={classes.innerBox2}>
								<Box>
									<Box className={classes.displayStyle}>
										<Typography className={classes.basicTitle}>{i18next.t("학년도 / 학기")}</Typography>
										<Typography
											className={classes.basicText}>{`${project?.year} ${i18next.t("학년도")} / ${currentSemesterType}`}</Typography>
									</Box>
									<Box className={classes.displayStyle}>
										<Typography className={classes.basicTitle}>{i18next.t("과목")}</Typography>
										<Typography className={classes.basicText}>
											{currentSubjectObj?.text || '-'}
										</Typography>
									</Box>
									<Box className={classes.displayStyle}>
										<Typography className={classes.basicTitle}>{i18next.t("평가 과제운영명")}</Typography>
										<Typography className={classes.basicText}>{project?.name || '-'}</Typography>
									</Box>
									<Box className={classes.displayStyle}>
										<Typography className={classes.basicTitle}>{i18next.t("제시 방식")}</Typography>
										<Typography className={classes.basicText}>{currentAssignmentType}</Typography>
									</Box>
								</Box>
							</Box>

							<Box ref={testTargetRef} className={classes.title3Box}>
								<Typography className={classes.commonSubTitle3}>{i18next.t("평가 대상")} (<span>{projectUsers.length}</span>)</Typography>
								<IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple
									onClick={() => handleClickEdit(NavigateType.ASSIGNMENT_TARGET)}>
									<EditContainedIcon />
								</IconButton>
							</Box>

							<Box>
								<TableContainer component={Paper} className={classes.tableBox}>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell width='10%' className={classes.numberColumn}>{i18next.t("No.")}</TableCell>
												<TableCell width='15%'>
													<Box style={{ cursor: 'pointer' }} display='flex'
														onClick={() => handleClickSort(ProjectUserSortColumn.NAME)}>
														{i18next.t("이름")}
														<IconButton className={classes.iconBtnReset} style={{
															transform: sortStateMap[ProjectUserSortColumn.NAME].sort ? 'none' : 'rotate(180deg)',
															transition: 'transform 0.3s ease'
														}} disableRipple>
															<TableListOrder />
														</IconButton>
													</Box>
												</TableCell>
												<TableCell width='29%'>{i18next.t("이메일")}</TableCell>
												<TableCell width='18%'>
													<Box style={{ cursor: 'pointer' }} display='flex'
														onClick={() => handleClickSort(ProjectUserSortColumn.SCHOOL)}>
														{i18next.t("학교")}
														<IconButton className={classes.iconBtnReset} style={{
															transform: sortStateMap[ProjectUserSortColumn.SCHOOL].sort ? 'none' : 'rotate(180deg)',
															transition: 'transform 0.3s ease'
														}} disableRipple>
															<TableListOrder />
														</IconButton>
													</Box>
												</TableCell>
												<TableCell width='18%'>
													<Box style={{ cursor: 'pointer' }} display='flex'
														onClick={() => handleClickSort(ProjectUserSortColumn.GRADE_CLASSNAME)}>
														{i18next.t("학년반")}
														<IconButton className={classes.iconBtnReset} style={{
															transform: sortStateMap[ProjectUserSortColumn.GRADE_CLASSNAME].sort ? 'none' : 'rotate(180deg)',
															transition: 'transform 0.3s ease'
														}} disableRipple>
															<TableListOrder />
														</IconButton>
													</Box>
												</TableCell>
												<TableCell width='18%'>{i18next.t("그룹")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{projectUsers && projectUsers
												.slice(rowStartIdx, rowStartIdx + rowsPerPage)
												.map((user, idx) => {
													return (
														<ProjectUserTableRow
															key={idx}
															idx={idx}
															rowStartNum={rowStartIdx + 1}
															user={user}
														/>
													)
												})
											}
										</TableBody>
									</Table>
								</TableContainer>
								<Box className={classes.pageNumber} display='flex' alignItems='center'
									justifyContent='center'>
									<Stack spacing={2}>
										<Pagination count={projectUsersCount}
											onChange={handleClickPage}
											boundaryCount={2}
											showFirstButton
											showLastButton
											variant="outlined"
										/>
									</Stack>
								</Box>
							</Box>

							<Box ref={templateProjectRef} className={classes.title3Box}>
								<Typography className={classes.commonSubTitle3}>{i18next.t("평가 과제")}</Typography>
								<IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple
									onClick={() => handleClickEdit(NavigateType.PROJECT_INFO)}>
									<EditContainedIcon />
								</IconButton>
							</Box>

							<Box className={classes.commonBoxInner}>
								<Box className={classes.commonSubTitleBasicBox}>
									<Typography className={classes.commonSubTitleBasic2}>{i18next.t("과제 개요")}</Typography>
								</Box>

								<Box className={classes.listBoxTop}>
									<Box className={classes.displayStyle}>
										<Typography className={classes.basicTitle2}>{i18next.t("교육과정 시기")}</Typography>
										<Typography
											className={classes.basicText2}>{`${project?.curriculumYear || 0} ${i18next.t("년 개정")}`}</Typography>
									</Box>
									<Box className={classes.displayStyle}>
										<Typography className={classes.basicTitle2}>{i18next.t("학교급")}</Typography>
										<Typography className={classes.basicText2}>
											{project.schoolType === ProjectSchoolType.ELEM ? i18next.t("초등학교") :
												project.schoolType === ProjectSchoolType.MIDD ? i18next.t("중학교") : i18next.t("고등학교")}
										</Typography>
									</Box>
									<Box className={classes.displayStyle}>
										<Typography className={classes.basicTitle2}>{i18next.t("학년/교과/과목")}</Typography>
										<Typography
											className={classes.basicText2}>{`${project?.grade || 0}${i18next.t("학년")} / ${currentSubjectGroupObj?.text || '-'} / ${currentSubjectObj?.text || '-'}`}</Typography>
									</Box>
									<Box className={classes.displayStyle}>
										<Typography className={classes.basicTitle2}>{i18next.t("단원명")}</Typography>
										<Typography
											className={classes.basicText2}>{project?.chapter || '-'}</Typography>
									</Box>
									<Box className={classes.displayStyle}>
										<Typography className={classes.basicTitle2}>{i18next.t("평가과제명")}</Typography>
										<Typography className={classes.basicText2}>{project.name || '-'}</Typography>
									</Box>
								</Box>
							</Box>

							<Box sx={{ marginLeft: "20px", marginRight: "20px" }}>
								<Typography className={classes.commonSubTitleBasic18px}>{i18next.t("성취기준 및 평가기준")}</Typography>
								<PlanTab key={"planTab_Main"} />

								<Box className={clsx(classes.sectionTitle, classes.commonSubTitleBasicBox)}>
									<Typography className={classes.commonSubTitleBasic18px}>{i18next.t("문항 및 참고자료")}</Typography>
								</Box>
								<QuestionAndReferenceTab
									projectExamList={projectExamStore.projectExamList}
									sideBarStepNum={tabIndex}
									onSetQuestionHTMLWithOrderAndScore={setQuestionHTMLWithOrderAndScore}

									onClickScrollToTopBtn={handleClickScrollToTopBtn}
									questionRef={testTargetRef}

									handleClickRagEdit={() => { }}
									handleClickQuestionEdit={() => { }}
								/>

								<Box className={clsx(classes.sectionTitle, classes.commonSubTitleBasicBox)}>
									<Typography className={classes.commonSubTitleBasic18px}>{i18next.t("모범 답안")}</Typography>
								</Box>

								<AnswerTab
									projectExamList={projectExamStore.projectExamList}
								/>

								<Box className={clsx(classes.sectionTitle, classes.commonSubTitleBasicBox)}>
									<Typography className={classes.commonSubTitleBasic18px}>{i18next.t("평가 기준표")}</Typography>
								</Box>

								<RubricTab
									projectExamQuestionsList={projectExamQuestionsList}
									questionListLenObjPerExam={questionListLenObjPerExam}
									projectRubricTransferList={projectRubricTransferList}
									questionKeyAndSelectedProjectRubricIdxMap={questionKeyAndSelectedProjectRubricIdxMap}
									questionKeyAndSystemRubricConditionsToSearchMap={questionKeyAndSystemRubricConditionsToSearchMap}
									questionKeyAndSystemRubricListMap={questionKeyAndSystemRubricListMap}
									questionKeyAndSelectedSystemRubricIdsMap={questionKeyAndSelectedSystemRubricIdsMap} />
							</Box>

						</Box>
					</div>
				</Box>
			</Box>

			<IconButton
				className={classes.pageUpIcon}
				onClick={handleClickScrollToTopBtn}
				disableRipple>
				<PageUpIcon />
			</IconButton>

			{isSelectingAll &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={isSelectingAll}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("조회 중입니다...")}</Typography>
				</Backdrop>
			}

			{projectStore.isUpdatingProjectState &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectStore.isUpdatingProjectState}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("제시 중입니다...")}</Typography>
				</Backdrop>
			}

		</div>
	);
}
export default observer<typeof CustomProjectAssignment>(CustomProjectAssignment);


