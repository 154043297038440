import { IPageSOBP, IPoint, IPolygonArea } from '../../../nl-lib3-common';
import CoordinateTanslater from '../CoordinateTanslater';
import { MappingItem } from '../MappingItem';
import { IPdfPageDesc } from '../types/IPdfPageDesc';


/**
 *
 * @param pageNo - PDF Page 번호
 * @param pdfPageDesc - PDF page descriptor
 * @param sobp - Ncode PageInfo
 * @param pts - 매핑되는 점들
 */
export const getTranslater = (
  pageNo: number,
  pdfPageDesc: IPdfPageDesc,

  sobp: IPageSOBP,

  pts: {
    nu: { p0: IPoint, p1: IPoint, p2: IPoint, p3: IPoint, },
    pu: { p0: IPoint, p1: IPoint, p2: IPoint, p3: IPoint, }
  }
) => {
  const mapData = new MappingItem(pageNo);


  const { nu, pu } = pts;

  // Ncode 좌표 (펜으로 부터)
  const srcPoints: IPolygonArea = [nu.p0, nu.p1, nu.p2, nu.p3];

  // PDF 좌표 (캘리브레이션 마크가 찍힌 자리의 PU 좌표)
  const dstPoints: IPolygonArea = [pu.p0, pu.p1, pu.p2, pu.p3];


  /** NCode 좌표계 */
  mapData.setNcodeArea({
    sobp,

    // 주1)
    // rect와 npageArea로 정의된 값은 이 class에서는 쓸모 없다.
    // setSrc4Points_ncode에 의해 다시 값이 정해진다.
    //
    // 이렇게 쓴 이유는, setNcodeArea가 회전 안된 직사각형에만 대응되는 함수여서,
    // 당연히 함수를 추가해서 수정해야 하지만, MappingItem class를 수정하지 않기 위해서
    rect: {
      unit: "nu",
      x: nu.p0.x,
      y: nu.p0.y,
      width: nu.p2.x - nu.p0.x,
      height: nu.p2.y - nu.p0.y,
    },
    npageArea: srcPoints,
    // 여기까지
  });
  // 위 주1의 rect, npageArea로 구해진 4개 점을 새로 세팅하도록 한다.
  mapData.setSrc4Points_ncode(srcPoints);


  /** PDF 좌표계 */
  mapData.setPdfArea({
    pdfPageInfo: { ...pdfPageDesc, pageNum: pageNo },

    // 주1과 같은 이유
    rect: {
      unit: "pu",
      x: pu.p0.x,
      y: pu.p0.y,
      width: pu.p2.x - pu.p0.x,
      height: pu.p2.y - pu.p0.y,
    }
  });
  // 위 주1의 rect, npageArea로 구해진 4개 점을 새로 세팅하도록 한다.
  mapData.setDst4Points_pdf(dstPoints);


  const trans = new CoordinateTanslater();
  trans.calc(mapData);
  return trans
}
