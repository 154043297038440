import { makeAutoObservable, runInAction } from "mobx";
import { DataUtils } from "../util/data-utils";
import ProjectSubmissionAnswerRepository from "../repositories/ProjectSubmissionAnswerRepository";
import AiScoreRepository from "../repositories/AiScoreRepository";
import ProjectSubmissionAnswerScoreRepository from "../repositories/ProjectSubmissionAnswerScoreRepository";
import MessageStore from "./MessageStore";
import { IExamInfo } from "../repositories/model/transfer/IExamInfo";
import { IQuestionInfo } from "../repositories/model/transfer/IQuestionInfo";
import { IRubricInfo, IRubricItem } from "../repositories/model/transfer/IRubricInfo";
import { IScoreInfo } from "../repositories/model/transfer/IScoreInfo";
import { IAnswerInfo } from "../repositories/model/transfer/IAnswerInfo";

import { ChangeFlag } from "../repositories/model/support/ChangeFlag";
import { ProjectExamType } from "../repositories/model/support/ProjectExamType";
import { RubricStatus } from "../repositories/model/support/RubricStatus";
import { SortingHintType } from "../repositories/model/support/SortingHintType";
import { StoreStatus } from "./support/StoreStatus";
import { IProjectSubmissionTransfer } from "../repositories/model/transfer/IProjectSubmissionTransfer";

const LogPrefix = "[ProjectSubmissionAnswerStore]";


// // about feedback-list
// const EmptyAnswerScore = {
// 	projectCode: "",
// 	userCode: "",
// 	userName: "",
// 	grade: 0,
// 	className: "",
// 	state: "", // stateType
// 	submissionCode: "",
// 	totalScore: 0,
// 	scoredScore: 0,
// 	totalCount: 0,
// 	scoredCount: 0,
// 	scoredDatetime: "",
// 	useAI: false
// };

const EmptyAnswerScoreListConditionsToSearch = {
	projectCode: "",
	keyword: "",
	page: 0,
	// rowsPerPage: 10,
	rowsPerPage: 30,
	totalCount: 0,
	checkCount: 0,
	stateType: "",
	except: "",
	condition: undefined as {
		totalCount: number,
		rowsPerpage: number,
	},
};

// about feedback-detail
const EmptyRubricItemInfo: IRubricItem = {
	rubricId: 0,
	rubricItemNum: 0,
	name: "",
	score: 0,
	standard: "",
	changeFlag: ChangeFlag.NONE, // "", //changeFlag
};

const EmptyRubricInfo: IRubricInfo = {
	id: 0,
	systemRubricId: 0,
	projectExamCode: "",
	projectExamQuestionNum: 0,
	name: "",

	status: RubricStatus.DISABLE, //"", //RubricStatus
	score: 0,
	createdDatetime: "",
	updatedDatetime: "",
	changeFlag: ChangeFlag.NONE, // "", //changeFlag
	items: Array(1).fill({ ...EmptyRubricItemInfo }),

	selectedItemIdx: 0
};

const EmptyScoreInfo: IScoreInfo = {
	id: 0,
	projectSubmissionAnswerCode: "",
	rubricId: 0,
	rubricItemNum: 0,
	rubricName: "",
	rubricItemName: "",
	score: 0, // 해당 rubric 선택한 배점
	createdDatetime: "",
	updatedDatetime: "",
	standardScore: 0, // 해당 rubric 최고 배점
	totalScore: 0, // 해당 rubric에 대한 학생들의 점수 합산
	totalCount: 0, // 해당 rubric이 적용된 학생들의 수
	changeFlag: 0, //changeFlag

	aiFeedback: "",
};

const EmptyAnswerInfo: IAnswerInfo = {
	code: "",
	projectExamCode: "",
	projectExamQuestionNum: "",
	projectSubmissionCode: "",
	ocrResult: "",
	answer: "",
	scored: false,
	scoredDatetime: "",
	createdDatetime: "",
	updatedDatetime: "",
	ownerFeedback: "", // feedback
	aiFeedback: "",
	aiPrompt: "",
	aiResult: "",
	changeFlag: "", //changeFlag
	rubrics: Array(1).fill({ ...EmptyRubricInfo }),
	scores: Array(1).fill({ ...EmptyScoreInfo }),

	totalScore: 0, // rubric 선택 배점 총합
	initialOwnerFeedback: ""
};

const EmptyQuestionInfo: IQuestionInfo = {
	projectExamCode: "",
	projectExamQuestionNum: 0,
	parentOrder: 0,
	order: 0,
	question: "",
	score: 0,
	createdDatetime: "",
	updatedDatetime: "",
	correctedAnswer: "",
	correctedKeyword: "",
	auto: false,
	aiAnswer: "",
	aiKeyword: "",
	aiPrompt: "",
	aiResult: "",
	changeFlag: ChangeFlag.NONE, // "", //changeFlag
	answer: Object.assign({}, EmptyAnswerInfo),
	submissionCode: "",
};

const EmptyExamInfo: IExamInfo = {
	code: "",
	projectCode: "",
	type: ProjectExamType.DESCRIPTIVE, //  ."", //ProjectExamType
	order: 0,
	directive: "",
	paragraph: "",
	source: "",
	createdDatetime: "",
	updatedDatetime: "",
	questions: Array(1).fill({ ...EmptyQuestionInfo }),
	changeFlag: ChangeFlag.NONE, // . "", //ChangeFlag
	submissionCode: "",
};

const EmptySubmissionFileInfo = {
	code: "",
	projectSubmissionCode: "",
	order: 0,
	file: 0,
	ocrResult: "",
	fileName: "",
	downloadUrl: "",
};

const EmptyUserProfile = {
	projectCode: "",
	avatarImageObject: 0,
	name: "",
	gender: "",
	birth: "",
	organization: "",
	school: "",
	grade: 0,
	className: "",
	createdDatetime: "",
	updatedDatetime: "",
};

const EmptySubmissionInfo = {
	projectCode: "",
	userCode: "",
	state: "", // stateType
	submissionCode: "",
	submitType: "",
	createdDatetime: "",
	updatedDatetime: "",
	userProfile: Object.assign({}, EmptyUserProfile),
	files: Array(1).fill({ ...EmptySubmissionFileInfo }),
	projectName: "",
	userName: "",
	grade: 0,
	className: "",
	order: 0,
	totalCount: 0,
	exams: Array(1).fill({ ...EmptyExamInfo }),
};

const EmptySortingHint = {
	column: "",
	hint: ""
};

const EmptySubmissionAnswer = {
	code: "",//빈칸
	projectExamCode: "",
	projectExamQuestionNum: "",
	projectSubmissionCode: "",
	ocrResult: "",
	answer: "",

	isScored: "",//
	scoredDatetime: "",
	ownerFeedback: "",
	aiFeedback: "",
	aiPrompt: "",
	aiResult: "",

	changeFlag: "",//서버에서 조정함.
	rubrics: "",
	scores: ""
}

type Props = {
	projectSubmissionAnswerRepository: ProjectSubmissionAnswerRepository;
	projectSubmissionAnswerScoreRepository: ProjectSubmissionAnswerScoreRepository;
	aiScoreRepository: AiScoreRepository;
	messageStore: MessageStore;
};


export default class ProjectSubmissionAnswerStore {
	public answerReposityory: ProjectSubmissionAnswerRepository;
	public answerScoreReposityory: ProjectSubmissionAnswerScoreRepository;
	public aiScoreRepository: AiScoreRepository;
	public messageStore: MessageStore;

	public projectCode: string;
	public answerScoreList: any[];
	public answerScoreTotalList: any[];
	public answerScoreListConditionsToSearch: typeof EmptyAnswerScoreListConditionsToSearch;

	public submissionInfo: IProjectSubmissionTransfer;
	public questionList: any[];
	public oriAnswerList: any[];
	public answerList: any[];
	public examOrderAndQuestionsLengthMap: any;
	public addedFeedbackImageIdList: any[];

	public questionKeyAndResetStateMap: any;
	public sortingHintArr: any[];
	public requestionAIMap: any[];

	public submissionAnswers: any[];

	public isSelectingSubmissionInfoBySubmissionCode: boolean;
	public isSelectingAnswerScoreList: boolean;
	public isUpdatingAnswerInfo: boolean;

	public answerCallbackFlag: boolean;
	public settingQuestionListState: StoreStatus;






	constructor(props: Props) {
		const ps = props;
		const { messageStore } = ps;
		this.answerReposityory = props.projectSubmissionAnswerRepository;
		this.answerScoreReposityory = props.projectSubmissionAnswerScoreRepository;
		this.aiScoreRepository = props.aiScoreRepository;
		this.messageStore = messageStore;

		this.projectCode = '';
		this.answerScoreList = [];
		this.answerScoreTotalList = [];
		this.answerScoreListConditionsToSearch = Object.assign({}, EmptyAnswerScoreListConditionsToSearch);

		this.submissionInfo = {} as IProjectSubmissionTransfer;
		this.questionList = [];
		this.oriAnswerList = [];
		this.answerList = [];
		this.examOrderAndQuestionsLengthMap = {};
		this.addedFeedbackImageIdList = [];
		this.questionKeyAndResetStateMap = {};

		this.sortingHintArr = [];
		this.requestionAIMap = [];

		this.submissionAnswers = [];

		this.isSelectingSubmissionInfoBySubmissionCode = false;
		this.isSelectingAnswerScoreList = false;
		this.isUpdatingAnswerInfo = false;

		this.answerCallbackFlag = false;
		this.settingQuestionListState = StoreStatus.INITIAL;

		makeAutoObservable(this);
	}

	init() {
		this.projectCode = '';
		this.answerScoreList = [];
		this.answerScoreTotalList = [];
		this.answerScoreListConditionsToSearch = Object.assign({}, EmptyAnswerScoreListConditionsToSearch);

		this.submissionInfo = null;
		this.questionList = [];
		this.oriAnswerList = [];
		this.answerList = [];
		this.examOrderAndQuestionsLengthMap = {};
		this.addedFeedbackImageIdList = [];
		this.questionKeyAndResetStateMap = {};

		this.sortingHintArr = [];
		this.requestionAIMap = [];

		this.submissionAnswers = [];

		this.isSelectingSubmissionInfoBySubmissionCode = false;
		this.isSelectingAnswerScoreList = false;
		this.isUpdatingAnswerInfo = false;

		this.answerCallbackFlag = false;
		this.settingQuestionListState = StoreStatus.INITIAL;
	};

	initAnswerScore() {
		// this.projectCode = '';
		this.answerScoreList = [];
		this.answerScoreTotalList = [];
		// this.answerScoreListConditionsToSearch = Object.assign({}, EmptyAnswerScoreListConditionsToSearch);

		this.submissionInfo = null;
		this.questionList = [];
		this.oriAnswerList = [];
		this.answerList = [];
		this.examOrderAndQuestionsLengthMap = {};
		this.addedFeedbackImageIdList = [];
		this.questionKeyAndResetStateMap = {};

		this.sortingHintArr = [];
		this.requestionAIMap = [];

		this.submissionAnswers = [];

		this.isSelectingSubmissionInfoBySubmissionCode = false;
		this.isSelectingAnswerScoreList = false;
		this.isUpdatingAnswerInfo = false;

		this.answerCallbackFlag = false;
		this.settingQuestionListState = StoreStatus.INITIAL;
	};

	setProjectCode = code => this.projectCode = code;

	applyChangesToAnswerScoreList() {
		this.answerScoreList = [...this.answerScoreList];
	};

	applyChangesToSubmissionInfo() {
		this.submissionInfo = { ...this.submissionInfo };
	};

	applyChangesToQuestionList() {
		this.questionList = [...this.questionList];
	};

	applyChangesToExamOrderAndQuestionsLengthMap() {
		this.examOrderAndQuestionsLengthMap = { ...this.examOrderAndQuestionsLengthMap };
	};

	applyChangesToQuestionKeyAndResetStateMap() {
		this.questionKeyAndResetStateMap = { ...this.questionKeyAndResetStateMap };
	};

	applyChangesToSortingHintArr() {
		this.sortingHintArr = [...this.sortingHintArr];
	};

	applyChangesToRequestingAIMap() {
		this.requestionAIMap = [...this.requestionAIMap];
	};

	changeAnswerScoreListConditionsToSearchPage(page) {
		this.answerScoreListConditionsToSearch.page = page;
	};

	changeAnswerScoreListConditionsToSearchTotalCount(totalCount) {
		this.answerScoreListConditionsToSearch.totalCount = totalCount;
	};

	changeAnswerScoreListConditionsToSearchCheckCount(checkCount) {
		this.answerScoreListConditionsToSearch.checkCount = checkCount;
	};

	changeAnswerScoreListConditionsToSearchKeyword(keyword) {
		this.answerScoreListConditionsToSearch.keyword = keyword;
	};

	changeAnswerScoreListConditionsToSearchStateType(state) {
		this.answerScoreListConditionsToSearch.stateType = state;
	};

	changeAnswerScoreListConditionsToSearchExcept(state) {
		this.answerScoreListConditionsToSearch.except = state;
	};

	changeIsSelectingSubmissionInfoBySubmissionCode(boolean) {
		this.isSelectingSubmissionInfoBySubmissionCode = boolean;
	};

	changeResetState(questionKey, boolean) {
		this.questionKeyAndResetStateMap = { ...this.questionKeyAndResetStateMap, [questionKey]: boolean };
	};

	changeAllResetState(boolean) {
		for (const key in this.questionKeyAndResetStateMap) {
			this.questionKeyAndResetStateMap[key] = boolean;
		}

		this.applyChangesToQuestionKeyAndResetStateMap();
	};

	changeAnswerFeedback(questionKey, html) {
		const questionInfo = this.questionList.find(question => question.key === questionKey);
		if (!questionInfo || !questionInfo.answer) return;

		questionInfo.answer.ownerFeedback = html;

		this.applyChangesToQuestionList();
	};

	changeSettingQuestionListState(status) {
		this.settingQuestionListState = status;
	};

	initSubmissionAnswer() {
		this.submissionAnswers = [];
	}

	checkAnswerFeedbackEmptyWithTrim(questionKey) {
		const questionInfo = this.questionList.find(question => question.key === questionKey);
		if (!questionInfo || !questionInfo.answer) return;

		const feedback = questionInfo.answer.ownerFeedback;

		const isEmpty = DataUtils.getTagArrFromHtml(feedback, 'img').length === 0 && !DataUtils.getTextFromHtml(feedback).trim();

		return isEmpty;
	};

	initAnswerFeedback(questionKey) {
		const questionInfo = this.questionList.find(question => question.key === questionKey);
		if (!questionInfo || !questionInfo.answer) return;

		questionInfo.answer.initialOwnerFeedback = "";

		this.applyChangesToQuestionList();
	};

	changeSortingHint(column, sortType) {
		console.log(column, sortType)
		const hint = sortType ? SortingHintType.ASC : SortingHintType.DESC;

		const selectedHintObj = this.sortingHintArr.find(hint => hint.column === column);
		if (selectedHintObj) {
			selectedHintObj.column = column;
			selectedHintObj.hint = hint;

			this.applyChangesToSortingHintArr();

		} else {
			const hintObj = {
				column,
				hint
			};

			this.sortingHintArr = [hintObj];
		}

		this.getAnswerScoreList(this.projectCode);
	};

	// initAnswerFeedback (questionKey)  {
	//     const questionInfo = this.questionList.find(question => question.key === questionKey);
	//     if (!questionInfo || !questionInfo.answer) return;
	//
	//     questionInfo.answer.ownerFeedback = "";
	//
	//     this.applyChangesToQuestionList();
	// };

	addImageId = id => {
		this.addedFeedbackImageIdList = [...this.addedFeedbackImageIdList, id.toString()];
	};

	// addRequestingAIMap (answerCode, rubricId)  {
	addRequestingAIMap(answerCode) {
		const selectedObj = this.requestionAIMap.find(item => item.answerCode === answerCode);
		if (selectedObj) return;

		const requestingObj = {
			answerCode,
			// rubricId,
			requesting: false
		};

		this.requestionAIMap = [...this.requestionAIMap, requestingObj];
	};

	// getRequestingAIFromMap (answerCode, rubricId)  {
	getRequestingAIFromMap(answerCode) {
		// const selectedObj = this.requestionAIMap.find(item => item.answerCode === answerCode && item.rubricId === rubricId);
		const selectedObj = this.requestionAIMap.find(item => item.answerCode === answerCode);

		return selectedObj;
	};

	// changeRequestingAI (answerCode, rubricId, requesting)  {
	changeRequestingAI(answerCode, requesting) {
		// const selectedObj = this.requestionAIMap.find(item => item.answerCode === answerCode && item.rubricId === rubricId);
		const selectedObj = this.requestionAIMap.find(item => item.answerCode === answerCode);
		if (!selectedObj) return;

		selectedObj.requesting = requesting;

		this.applyChangesToRequestingAIMap();
	};

	changeAllRequestingAI(requesting) {
		this.requestionAIMap.map(item => {
			item.requesting = requesting;
			return item;
		});

		this.applyChangesToRequestingAIMap();
	};

	resetAnswerScores(questionKey) {
		const questionInfo = this.questionList.find(question => question.key === questionKey);
		if (!questionInfo || !questionInfo.answer || !questionInfo.answer.scores) {
			return false;
		}

		if (questionInfo.answer.scores.length === 0) return true;

		questionInfo.answer.totalScore = 0;
		questionInfo.answer.scored = false;
		questionInfo.answer.ownerFeedback = '';

		questionInfo.answer.rubrics.map(rubric => {
			rubric.selectedItemIdx = -1;

			return rubric;
		});
		questionInfo.answer.scores = [];

		console.log("questionInfo.answer.scores=", questionInfo.answer.scores);
		this.applyChangesToQuestionList();

		return true;
	};

	// changeRubricSelectedItem (questionKey, rubricId, idx)  {
	changeRubricSelectedItem(questionKey, rubricId: number, idx: number, aiFeedback: string) {
		const questionInfo = this.questionList.find(question => question.key === questionKey);
		if (!questionInfo || !questionInfo.answer || !questionInfo.answer.rubrics || questionInfo.answer.rubrics.length === 0) return;

		const rubricInfo = questionInfo.answer.rubrics.find(rubric => rubric.id === rubricId);
		if (!rubricInfo) return;

		// idx 변경
		rubricInfo.selectedItemIdx = idx;

		// score transfer 추가해야 함 (rubricId scoreInfo가 이미 존재한다면 update, 없다면 create)
		// answer.totalScore에 score 추가하기
		const selectedRubricItemInfo = rubricInfo.items[idx];
		const selectedScoreInfo = questionInfo.answer.scores.find(score =>
			score.rubricId === rubricId
		);

		if (!selectedScoreInfo) { // create
			questionInfo.answer.totalScore += selectedRubricItemInfo.score;

			const theHighestScore = rubricInfo.items.reduce((acc, cur) => acc > cur.score ? acc : cur.score);

			const scoreTransfer = {
				...EmptyScoreInfo,
				projectSubmissionAnswerCode: questionInfo.answer.code,
				rubricId: rubricInfo.id,
				rubricItemNum: selectedRubricItemInfo.rubricItemNum,
				rubricName: rubricInfo.name,
				rubricItemName: selectedRubricItemInfo.name,
				score: selectedRubricItemInfo.score, // 해당 rubric 선택한 배점
				standardScore: theHighestScore, // 해당 rubric 최고 배점
			};

			if (aiFeedback) {
				scoreTransfer.aiFeedback = aiFeedback;
			}

			questionInfo.answer.scores = [...questionInfo.answer.scores, scoreTransfer];

		} else { // update
			questionInfo.answer.totalScore -= selectedScoreInfo.score;
			questionInfo.answer.totalScore += selectedRubricItemInfo.score;

			selectedScoreInfo.rubricItemNum = selectedRubricItemInfo.rubricItemNum;
			selectedScoreInfo.rubricItemName = selectedRubricItemInfo.name;
			selectedScoreInfo.score = selectedRubricItemInfo.score;

			if (aiFeedback) {
				selectedScoreInfo.aiFeedback = aiFeedback;
			}

			questionInfo.answer.scores = [...questionInfo.answer.scores];
		}

		// 해당 문제에 대한 모든 루브릭 체크가 완료되었는지.
		let selectedRubricIds = [];
		let totalRubricIds = [];
		questionInfo.answer.rubrics.map(rubric => {
			totalRubricIds.push(Number(rubric.id));
		});
		questionInfo.answer.scores.map(score => {
			selectedRubricIds.push(Number(score.rubricId));
		});

		const haveNoSelectedRubric = totalRubricIds.some(id => !selectedRubricIds.includes(id));

		if (!haveNoSelectedRubric) {
			questionInfo.answer.scored = true;
		}

		// console.log("@@@@questionInfo.answer : ", questionInfo.answer);
		// console.log("@@@@questionInfo.answer.aiStatus : ", questionInfo.answer.aiStatus);

		this.applyChangesToQuestionList();
	};

	// resetSubmissionInfo ()  {
	//     this.submissionInfo.exams.map(exam => {
	//         exam.questions.map(question => {
	//             // question.answer.ownerFeedback = "<p></p>";
	//             // question.answer.totalScore = 0;
	//             // question.answer.scored = false;
	//             //
	//             // question.answer.rubrics.map(rubric => {
	//             //     rubric.selectedItemIdx = -1;
	//             //
	//             //     return rubric;
	//             // });
	//
	//             question.answer.scores = [];
	//
	//             return question;
	//         })
	//
	//         return exam;
	//     });
	//
	//     return this.submissionInfo;
	// };

	resetAllInfo() {
		// const submissionInfo = this.resetSubmissionInfo();

		// this.questionList = [];
		//
		// this.submissionInfo.exams.map(exam => {
		//     this.questionList = [ ...this.questionList, ...exam.questions ];
		//     this.examOrderAndQuestionsLengthMap[exam.order] = exam.questions.length;
		//
		//     exam.questions.map(question => {
		//         question.answer.scores = [];
		//
		//         return question;
		//     });
		//
		//     return exam;
		// });

		this.questionList
			.map(question => {
				const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
				question.key = randomNumber;
				this.questionKeyAndResetStateMap = { ...this.questionKeyAndResetStateMap, [question.key]: false };

				question.answer.scores = [];
				question.answer.ownerFeedback = "<p></p>";
				question.answer.scored = false;
				question.answer.totalScore = 0;

				question.answer.rubrics.map(rubric => {
					rubric.selectedItemIdx = -1;

					return rubric;
				});

				return question;
			})
			.sort((a, b) => {
				if (a.parentOrder !== b.parentOrder) {
					return a.parentOrder - b.parentOrder;
				}

				return a.order - b.order;
			});

		this.applyChangesToQuestionList();
		// this.applyChangesToExamOrderAndQuestionsLengthMap();
		this.applyChangesToQuestionKeyAndResetStateMap();
	};

	setQuestionList(submissionInfo) {
		this.questionList = [];
		this.oriAnswerList = [];

		submissionInfo.exams.map(exam => {
			this.questionList = [...this.questionList, ...exam.questions];
			this.examOrderAndQuestionsLengthMap[exam.order] = exam.questions.length;

			exam.questions.map(question => {
				this.oriAnswerList = [...this.oriAnswerList, question.answer];
			});
		});

		this.questionList
			.map(question => {
				const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
				question.key = randomNumber;
				this.questionKeyAndResetStateMap = { ...this.questionKeyAndResetStateMap, [question.key]: false };

				question.answer.totalScore = 0;
				question.answer.initialOwnerFeedback = question.answer.ownerFeedback;

				this.addRequestingAIMap(question.answer.code);

				let totalRubricIds = [];
				question.answer.rubrics.map(rubric => {
					rubric.selectedItemIdx = -1;
					// this.addRequestingAIMap(question.answer.code, rubric.id);

					// 3-1. prepare for checking if answer.scored is true
					totalRubricIds.push(Number(rubric.id));

					return rubric;
				});

				if (question.answer.scores.length > 0) {

					let selectedRubricIds = [];

					question.answer.scores = [...question.answer.scores.filter(scoreInfo => scoreInfo.rubricItemNum)];

					question.answer.scores.map(scoreInfo => {
						const rubricInfo = question.answer.rubrics.find(rubric => rubric.id === scoreInfo.rubricId);
						if (!rubricInfo) return;

						// three process for the existence of score objs
						// 1. selected rubric item idx
						const selectedIdx = rubricInfo.items.findIndex(item => item.rubricItemNum === scoreInfo.rubricItemNum);
						rubricInfo.selectedItemIdx = selectedIdx;

						// 2. add score to answer's totalScore
						question.answer.totalScore += Number(scoreInfo.score);

						// 3-2. prepare for checking if answer.scored is true
						selectedRubricIds.push(Number(scoreInfo.rubricId));

						question.answer.rubrics = [...question.answer.rubrics];
					})

					// 3-3. check if answer.scored is true
					const haveNoSelectedRubric = totalRubricIds.some(id => !selectedRubricIds.includes(id));

					if (!haveNoSelectedRubric) {
						question.answer.scored = true;
					}
				}

				return question;
			})
			.sort((a, b) => {
				if (a.parentOrder !== b.parentOrder) {
					return a.parentOrder - b.parentOrder;
				}

				return a.order - b.order;
			});

		this.applyChangesToQuestionList();
		this.applyChangesToExamOrderAndQuestionsLengthMap();
		this.applyChangesToQuestionKeyAndResetStateMap();

		this.changeSettingQuestionListState(StoreStatus.COMPLETED);
	};

	proceeSubmissionInfo(submissionInfo) {
		this.setProjectCode(submissionInfo.projectCode);

		submissionInfo.exams.sort((a, b) => a.order - b.order);
		this.submissionInfo = { ...submissionInfo };
		this.setQuestionList(submissionInfo);

		this.applyChangesToSubmissionInfo();
	};

	processAnswerList() {
		this.questionList.map(question => {
			const answerInfo = question.answer;

			const oriAnswerInfo = this.oriAnswerList.find(oriAnswer =>
				oriAnswer.code === answerInfo.code
			);
			if (!oriAnswerInfo) return;

			if (DataUtils.getTagArrFromHtml(answerInfo.ownerFeedback, 'img').length === 0 && !DataUtils.getTextFromHtml(answerInfo.ownerFeedback).trim()) {
				answerInfo.ownerFeedback = null;
			}

			if (answerInfo.ownerFeedback !== oriAnswerInfo.ownerFeedback
				|| answerInfo.scored !== oriAnswerInfo.scored
			) {
				answerInfo.changeFlag = ChangeFlag.UPDATED;
			} else {
				answerInfo.changeFlag = ChangeFlag.NONE;
			}

			answerInfo.scores.map(score => {
				const oriScoreInfo = oriAnswerInfo.scores.find(oriScore =>
					oriScore.projectSubmissionAnswerCode === score.projectSubmissionAnswerCode
					&& oriScore.rubricId === score.rubricId
				);

				if (oriScoreInfo) { // update or none
					if (score.rubricItemNum !== oriScoreInfo.rubricItemNum) {
						score.changeFlag = ChangeFlag.UPDATED;
					} else {
						score.changeFlag = ChangeFlag.NONE;
					}

				} else { // create
					score.changeFlag = ChangeFlag.CREATED;
				}

				return score;
			});


			this.answerList = [
				...this.answerList.filter(answer => answer.code !== answerInfo.code)
				, answerInfo
			];

			return question;
		});
	};

	// insertOCRtoAnswer () {
	//     this.submissionAnswers.forEach((item) =>{
	//         if(item.ocrResult && !item.answer){
	//             item.answer = item.ocrResult;
	//         }
	//     })
	// }

	changeCompareAnswer(answerList) {
		this.submissionAnswers = answerList.map(item => item);
	}
	changeAnswer(answer, index) {
		// console.log("@@@",answer);
		// let tempObject = Object.assign({}, this.submissionAnswers[index]);
		// tempObject.answer = answer;
		// this.submissionAnswers[index] = tempObject;
		if (this.submissionAnswers[index])
			this.submissionAnswers[index].answer = answer;
	}

	async createSubmissionAnswers(submissionCode: string, answerList, callbackFlag = false) {
		try {
			console.log(LogPrefix, "Start createSubmissionAnswers...");
			let data = {};
			if (answerList)
				data = answerList;
			await this.answerReposityory.createSubmissionAnswers(submissionCode, data);
			console.log(LogPrefix, "Success createSubmissionAnswers...");
		} catch (e) {
			console.log(LogPrefix, "Failed createSubmissionAnswers...", e);
		} finally {
			runInAction(() => {
				if (callbackFlag)
					this.answerCallbackFlag = !this.answerCallbackFlag;
			});
		}
	}

	async downloadSubmissionAnswersAndStore(submissionCode: string) {
		try {

			console.log(LogPrefix, "Start getSubmissionAnswers...",)
			const response = await this.answerReposityory.getSubmissionAnswers(submissionCode);

			runInAction(() => {
				this.submissionAnswers = response;
			});
			console.log(LogPrefix, "Success getSubmissionAnswers...", this.submissionAnswers);
			return response;
		} catch (e) {
			console.log(LogPrefix, "Failed getSubmissionAnswers...", e)
		}
	}
	async updateSubmissionAnswers(callbackFlag = false) {
		try {
			console.log(LogPrefix, "Start updateSubmissionAnswers...")
			let data = [...this.submissionAnswers];
			data.forEach((item) => item.changeFlag = "UPDATED");

			let response = await this.answerReposityory.updateSubmissionAnswers(data);
			console.log(LogPrefix, "Success updateSubmissionAnswers...", response)
		} catch (e) {
			console.log(LogPrefix, "Failed updateSubmissionAnswers...", e)
		} finally {
			runInAction(() => {
				if (callbackFlag)
					this.answerCallbackFlag = !this.answerCallbackFlag;
			});
		}
	}

	async getAnswerScoreList(projectCode: string) {
		try {
			this.isSelectingAnswerScoreList = true;

			const response = await this.answerScoreReposityory.getAnswerScoreList(projectCode, this.answerScoreListConditionsToSearch, this.sortingHintArr);
			// console.log("response=", response);

			runInAction(() => {
				this.answerScoreList = response.transfers;
				this.changeAnswerScoreListConditionsToSearchTotalCount(response.totalCount);
				this.changeAnswerScoreListConditionsToSearchCheckCount(response.option.checkCount);
				this.applyChangesToAnswerScoreList();
			});

			// console.log(LogPrefix, "Success getAnswerScoreList ...",response);

		} catch (e) {
			console.log(LogPrefix, "Cannot getAnswerScoreList ...", e);
		} finally {
			runInAction(() => {
				this.isSelectingAnswerScoreList = false;
			});
		}
	};

	async getAnswerScoreTotalList(projectCode) {
		try {
			const response = await this.answerScoreReposityory.getAnswerScoreTotalList(projectCode, this.answerScoreListConditionsToSearch);
			runInAction(() => {
				this.answerScoreTotalList = [...response];
			});
		} catch (e) {
			console.log(LogPrefix, "Cannot getAnswerScoreTotalList ...", e);
		}
	};

	async validateSubmissionInfo(submissionInfo) {
		let valid = true;

		submissionInfo.exams.map(exam => {
			exam.questions.map(question => {
				if (!question.answer) {
					valid = false;
				}
			})
		});

		return valid;
	};

	async getSubmissionInfoBySubmissionCode(submissionCode, callBack) {
		try {
			this.changeIsSelectingSubmissionInfoBySubmissionCode(true);

			const submissionInfo = await this.answerScoreReposityory.getSubmissionInfo(submissionCode)

			this.validateSubmissionInfo(submissionInfo)
				.then(async result => {
					if (!result) {
						await this.messageStore.alert("데이터를 조회하는 도중 에러가 발생했습니다. 목록으로 돌아갑니다.");
						this.init();

						callBack && callBack();
						return false;
					}

					runInAction(() => {
						this.proceeSubmissionInfo(submissionInfo);
						this.changeIsSelectingSubmissionInfoBySubmissionCode(false);
					});

					return true;
				})

			// console.log(LogPrefix, "Success getSubmissionInfoBySubmissionCode ...",this.submissionInfo);

		} catch (e) {
			console.log(LogPrefix, "Cannot getSubmissionInfoBySubmissionCode ...", e);

			runInAction(() => {
				this.isSelectingSubmissionInfoBySubmissionCode = false;
			});
		}
		// finally {
		//     this.isSelectingSubmissionInfoBySubmissionCode = false;
		// }
	};

	async getSubmissionInfoByOrder() {
		try {
			const params = {
				order: this.submissionInfo.order + 1
			};

			const submissionInfo = await this.answerScoreReposityory.getSubmissionInfoByOrder(this.projectCode, params);

			console.log(LogPrefix, "Success getSubmissionInfoByOrder ...", submissionInfo);

			return submissionInfo.submissionCode;

		} catch (e) {
			console.log(LogPrefix, "Cannot getSubmissionInfoByOrder ...", e);
		}
	};

	async updateAnswerInfo() {
		try {
			this.isUpdatingAnswerInfo = true;
			this.processAnswerList();

			await this.answerReposityory.updateSubmissionAnswers(this.answerList);

			// console.log("this.answerList=", this.answerList);
			// console.log(LogPrefix, "Success getSubmissionInfoByOrder ...");

			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot updateAnswerInfo ...", e);
		} finally {
			runInAction(() => {
				this.isUpdatingAnswerInfo = false;
			});
		}

		return false;
	};

	async createAIScore(answerCode: string, language: string, force: boolean) {
		try {
			await this.aiScoreRepository.createAIScore(answerCode, language, force);
			console.log(LogPrefix, "Success createAIScore ...");

		} catch (e) {
			console.log(LogPrefix, "Cannot createAIScore ...", e);
		}
	};

	async getAIStatus(answerCode) {
		try {
			const response = await this.aiScoreRepository.getAIStatus(answerCode);
			console.log(LogPrefix, "Success getAIStatus ...", response);
			return response;

		} catch (e) {
			console.log(LogPrefix, "Cannot getAIStatus ...", e);
		}
	};

	async getAIScore(answerCode) {
		try {
			const response = await this.aiScoreRepository.getAIScore(answerCode);
			console.log(LogPrefix, "Success getAIScore ...", response);
			return response;

		} catch (e) {
			console.log(LogPrefix, "Cannot getAIScore ...", e);
		}
	};
}
