import { IPolygonArea, ITransformParameters, NU_TO_PU, solveHomography, TransformPointPairs } from "../..";

/**
 * 결과물, 아래의 함수에서 불러서 쓰자
 * function applyTransform(mtx: TransformParameters, pt: IPoint): IPoint
 */
export class TransformParameters implements ITransformParameters {
  type: "homography" | "affine" = "homography";

  a = NU_TO_PU;

  b = 0;

  c = 0;

  d = 0;

  e = NU_TO_PU;

  f = 0;

  g = 0;

  h = 0;

  i = 1;

  tx = 0;

  ty = 0;

  constructor(arg?: ITransformParameters) {
    if (arg) {
      this.type = arg.type;
      this.a = arg.a;
      this.b = arg.b;
      this.c = arg.c;
      this.d = arg.d;
      this.e = arg.e;
      this.f = arg.f;
      this.g = arg.g;
      this.h = arg.h;
      this.i = arg.i;
      this.tx = arg.tx;
      this.ty = arg.ty;
    }
  }

  /**
   * src와 dst를 넣으면 a, b, c, d, e, f, g, h, i, tx, ty를 넣어둔다
   * @param src 4개 점
   * @param dst 4개 점
   */
  static solveNuToPuHomography = (src: IPolygonArea, dst: IPolygonArea) => {
    const pairPoints: TransformPointPairs = {
      src: {
        type: "homography",
        unit: "nu",
        pts: src
      },
      dst: {
        type: "homography",
        unit: "pu",
        pts: dst
      }
    }

    const h = solveHomography(pairPoints);
    return h;
  }

  static solvePuToNuHomography = (src: IPolygonArea, dst: IPolygonArea) => {
    const pairPoints: TransformPointPairs = {
      src: {
        type: "homography",
        unit: "pu",
        pts: src
      },
      dst: {
        type: "homography",
        unit: "nu",
        pts: dst
      }
    }

    const h = solveHomography(pairPoints);
    return h;
  }
}
