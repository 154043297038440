
import { makeAutoObservable } from "mobx";
import i18next, { getBrowserLanguage } from "../lang";
import dayjs from "dayjs";


export const topHeight = 54;
export const openSideWidth = 220;
export const closeSideWidth = 62;

export default class NavigateStore {
	public sideBarOpen: boolean = true;
	public navigateRootPage: boolean = true;
	public navigateDetailPage: string | null = null;
	public withoutBars: boolean = false;
	public sideBarMakeProjectStepNum: number = 0;
	public sideBarStepNum: number = 0;
	public tableHomePage: number = 0;

	// 과제보기 1 ~ 결과 내보내기 5

	public tableHomeRowsPerPage: number = 5;
	public projectSearchKeyword: string = "";
	public projectState: string = "";
	public topBarLeftItem: () => JSX.Element = null;
	public topBarRightItem: () => JSX.Element = null;
	public isConfirmForUpdateRequired: boolean = false;

	public language: string;

	public get region(): string {
		switch (this.language) {
			case 'ko':
				return 'KR';
			case 'en':
				return 'US';
			default:
				return 'JP';
		}
	}

	public simpleMode: boolean;


	constructor() {
		this.changeLanguage(getBrowserLanguage());
		this.simpleMode = true;
		// this.locale = getBrowserLanguage();
		this.sideBarOpen = true;
		this.navigateRootPage = true;

		this.navigateDetailPage = null;

		this.withoutBars = false;

		this.sideBarMakeProjectStepNum = 0;
		// 과제보기 1 ~ 결과 내보내기 5
		this.sideBarStepNum = 0;

		this.tableHomePage = 0;
		this.tableHomeRowsPerPage = 5;
		this.projectSearchKeyword = "";
		this.projectState = "";

		this.topBarLeftItem = null;
		this.topBarRightItem = null;

		this.isConfirmForUpdateRequired = false;

		makeAutoObservable(this);
	}

	initTableHome() {
		this.projectState = "";
		this.tableHomePage = 0;
		this.tableHomeRowsPerPage = 5;
		this.projectSearchKeyword = "";
		this.sideBarStepNum = 0;
		this.withoutBars = false;
	}

	changeWithoutBars(flag: boolean) {
		this.withoutBars = flag;
	}
	changeNavigateDetailPage(type: string) {
		this.navigateDetailPage = type;
	}

	changeNavigateRootPage(flag: boolean) {
		this.navigateRootPage = flag;
	}

	changeTableHomePage(page: number) {
		this.tableHomePage = page - 1;
	}
	changeProjectSearchKeyword(keyword: string) {
		this.projectSearchKeyword = keyword;
	}
	changeTableHomeRowsPerPage(perPage: number) {
		this.tableHomeRowsPerPage = perPage;
	}

	changeProjectState(state) {
		this.projectState = state;
	}

	changSideBarOpen(open: boolean) {
		// this.sideBarOpen = !this.sideBarOpen;
		this.sideBarOpen = open;
	}

	setSideBarOpen(open: boolean) {
		this.sideBarOpen = open;
	};

	//페이지 좀 더 나오면 과제보기랑 제출물 관리 채점및 피드백에 관련된 네비게이트 옵션 추가해야함. =>   this.sideBarStepNum = 0;
	changeProjectSideBarStep(stepNum: number) {
		this.sideBarMakeProjectStepNum = stepNum;
	}

	changeSideBarStepNum(stepNum: number) {
		this.sideBarStepNum = stepNum;
	}

	changeTopBarLeftItem(item: () => JSX.Element) {
		this.topBarLeftItem = item;
	};

	changeTopBarRightItem(item: () => JSX.Element) {
		this.topBarRightItem = item;
	};

	changeIsConfirmForUpdateRequired(boolean) {
		this.isConfirmForUpdateRequired = boolean;
	};

	changeFont(language) {
		const body = document.body;


		let fontFamily;
		switch (language) {
			case 'ko':
				fontFamily = "'맑은 고딕' !important";
				break;
			case 'en':
				fontFamily = 'Arial !important';
				break;
			default:
				fontFamily = "'Meiryo UI'!important";
				break;
		}
		let existingStyle = document.getElementById('dynamic-font-style');
		if (existingStyle) {
			existingStyle.remove();
		}

		// 새로운 스타일 태그 추가
		const style = document.createElement('style');
		style.id = 'dynamic-font-style';
		style.innerHTML = `* { font-family: "${fontFamily}"; }`;
		document.head.appendChild(style);
		document.body.appendChild(style);
	}

	public locale = "ko_KR";

	private getLocalString(language: string) {
		switch (language) {
			case 'ko':
				return 'ko_KR';
			case 'en':
				return 'en_US';
			case "ja":
				return 'ja_JP';
			default:
				return 'ko_KR';
		}
	}

	changeLanguage(language: string) {
		this.language = language;
		this.locale = this.getLocalString(language);
		i18next.changeLanguage(language);

		const htmlTag = document.documentElement;

		// lang 속성 변경
		htmlTag.setAttribute('lang', language);
		dayjs.locale(language);

		// 한국어 환경이 아니면, 학교용 모드는 필요 없다.
		if (language !== "ko") {
			this.simpleMode = true;
		}
	}

	changeSimpleMode(flag: boolean) {
		this.simpleMode = flag;
	}
}
