import {
	Box,
	Paper,
	TableContainer,
	Typography
} from "@mui/material";
import React from "react";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { PrintTextStyle } from "../../styles/PrintTextStyle";

import { observer } from "mobx-react";

// import 'dayjs/locale/ko';
import { useParams } from "react-router-dom";
import { useStores } from "../../../../hooks/useStores";
import { setQuestionHTMLWithOrderAndScore } from "../../../../util/setQuestionHTMLWithOrderAndScore";
import { PrintWorksheetHeader } from "../../000_Common/PrintWorksheetHeader";
import { QuestionAndReferenceTab } from "./QuestionAndReferenceTab";

type Props = {
}

function PrintTestPaper(props: Props) {
	const params = useParams();
	const { navigateStore, projectStore, projectExamStore } = useStores();

	const classes = useEmotionStyles(PrintTextStyle);
	const { projectCode } = params;
	const { project } = projectStore;
	// const i18next = useTranslation();

	React.useEffect(() => {
		getProjectInformation();
		navigateStore.changeWithoutBars(true);
		return () => {
			navigateStore.changeWithoutBars(false);
		}
	}, [])

	const getProjectInformation = async () => {
		await projectStore.downloadProjectInfoAndStore(projectCode);//기본정보 %필수정보
		await projectStore.getProjectPlanTransfer(projectCode);//평가 계획 %옵션 정보
		await projectStore.getProjectUserTransfers(projectCode, []);//평가 계획 %옵션 정보
		await projectExamStore.downloadProjectExamListAndStore(projectCode);// 문항 생성 %필수 정보
	}

	const handleClick = () => {
		window.print();
	}
	const handleClickPrint = () => {
		window.print();
	}
	return (
		<div className={classes.root} onClick={handleClick}>
			<Box className={classes.padding}>
				<Box className={classes.mainContentBox}>
					<Box className={classes.titleBoxStudent}>
						<Typography>{project && project.name}</Typography>
					</Box>
					<TableContainer component={Paper} className={classes.tableBox}>

						<PrintWorksheetHeader
							titleBoxClassName={classes.titleBox}
							projectTargetGroupName={project.targetGroupName}
							projectYear={project.year}
							projectSemester={project.semesterType}
							projectSubjectName={project.subjectName}
						/>
					</TableContainer>


					<Box className={classes.compareContent}>
						<QuestionAndReferenceTab
							projectExamList={projectExamStore.projectExamList}
							onSetQuestionHTMLWithOrderAndScore={setQuestionHTMLWithOrderAndScore}
						/>
					</Box>


				</Box>
			</Box>
		</div>
	);
}

export default observer<typeof PrintTestPaper>(PrintTestPaper);

