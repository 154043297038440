import {
	Box
} from "@mui/material";



import { ChangeFlag } from "../../../../../../repositories/model/support/ChangeFlag";
import { QuestionAndReferenceTabExamRow } from "./QuestionAndReferenceTabExamRow";
import { IExamInfo } from "../../../../../../repositories/model/transfer/IExamInfo";

type Props = {
	projectExamList: IExamInfo[];
	editMode: boolean;
	answerList: any;
	changeAnswer: any;
	isRecognizedAll: boolean;
}

export const QuestionAndReferenceTab = (props: Props) => {
	const { projectExamList, editMode, answerList, changeAnswer, isRecognizedAll } = props;
	return (
		<Box sx={{ position: 'relative' }}>
			{projectExamList.length > 0 &&
				projectExamList.filter(e => e.changeFlag !== ChangeFlag.DELETED)
					.map((exam, index) => {
						return (
							<QuestionAndReferenceTabExamRow
								isRecognizedAll={isRecognizedAll}
								key={exam.key}
								examInfo={exam}
								index={index + 1}
								editMode={editMode}
								changeAnswer={changeAnswer}
								answerList={answerList}
							/>
						)
					})}
		</Box>
	)
}
