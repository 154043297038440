import { DotTypes } from "./DotTypes";
import { DEFAULT_MAX_FORCE } from "../NeoStrokeConstants";
import { NeoDot } from "./NeoDot";


export class NeoDotBuilder {
  private mDot: NeoDot;

  private RefindMaxForce = 1023;

  private maxForce = -1;

  private scale = -1.0;

  constructor(maxForce?: number) {
    if (maxForce) {
      this.maxForce = maxForce;
      this.scale = this.RefindMaxForce / maxForce;
    }
    this.mDot = new NeoDot();
  }


  public owner(owner: number) {
    this.mDot.Owner = owner;
    return this;
  }

  public section(section: number) {
    this.mDot.Section = section;
    return this;
  }

  public note(note: number) {
    this.mDot.Note = note;
    return this;
  }

  public page(page: number) {
    this.mDot.Page = page;
    return this;
  }

  public timestamp(timestamp: bigint) {
    this.mDot.Timestamp = timestamp;
    return this;
  }

  public coord(x: number, y: number) {
    this.mDot.x = x;
    this.mDot.y = y;
    return this;
  }

  public tilt(x: number, y: number) {
    this.mDot.TiltX = x;
    this.mDot.TiltY = y;
    return this;
  }

  public twist(twist: number) {
    this.mDot.Twist = twist;
    return this;
  }

  public force(force: number) {
    if (this.maxForce === -1) {
      // normalize, 2022/06/29
      this.mDot.f = force / DEFAULT_MAX_FORCE;
    } else {
      this.mDot.f = Math.floor((force * this.scale) + 0.5); // 반올림

      // if (PressureCalibration.Instance.Factor != null) {
      //   this.mDot.f = PressureCalibration.Instance.Factor[this.mDot.Force];
      // }

      // normalize, 2022/06/29
      this.mDot.f /= this.maxForce;
    }

    if (this.mDot.f > 1) {
      this.mDot.f = 1;
    }

    return this;
  }

  public dotType(dotType: DotTypes) {
    this.mDot.DotType = dotType;
    return this;
  }

  public color(color: number) {
    this.mDot.Color = color;
    return this;
  }

  public Build() {
    return this.mDot;
  }
}
