import {
	Box,
	IconButton,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as DocumentIcon } from '../../../../common/images/DocumentIcon.svg';
import { ReactComponent as ZoomInActive } from '../../../../common/images/ZoomInActive.svg';
import TextViewer from "../../../../components/common/TextViewer";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TemplateProjectViewStyle } from "../../styles/TemplateProjectViewStyle";

import { Trans, useTranslation } from 'react-i18next';
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";

import { globalConfig } from "../../../../config/globalConfig";
import { RagType } from "../../../../stores/support/RagType";
import { useCurrentOrder } from "../001_Functions/useCurrentOrder";
import { useCurrentQuestionHTML } from "../001_Functions/useCurrentQuestionHTML";
import { useRag, UseRagResult } from "../001_Functions/useRag";
import { useStores } from "../../../../hooks/useStores";
import { observer } from "mobx-react";

type Props = {
	examInfo: IExamInfo,
	questionInfo: IQuestionInfo,
	idx: number,
	sideBarStepNum,
	handleClickRagEdit
}


export const QuestionAndReferenceTabQuestionRow = observer((props: Props) => {
	const i18next = useTranslation();

	const { examInfo, questionInfo } = props;
	const { projectExamStore, projectRagStore } = useStores();

	const classes = useEmotionStyles( TemplateProjectViewStyle );
	const { projectRagList } = projectRagStore;

	// const currentOrder = React.useMemo(() => {
	// 	const questionListLen = examInfo.questions.filter(q => q.changeFlag !== FlagType.DELETED).length;
	// 	return projectExamStore.setQuestionFullOrderByQuestionListLen(questionListLen, examInfo.order, questionInfo.order);
	// }, [examInfo, examInfo.order, questionInfo.order]);
	const currentOrder = useCurrentOrder(projectExamStore, examInfo, questionInfo);

	// const currentQuestionHTML = React.useMemo(() => {
	// 	return setQuestionHTMLWithOrderAndScore(questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder);
	// }, [questionInfo, questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder]);
	const currentQuestionHTML = useCurrentQuestionHTML(projectExamStore, questionInfo, currentOrder);

	// const rag = React.useMemo(() => {
	// 	for (let i = 0; i < projectRagList.length; i++) {
	// 		for (let j = 0; j < projectRagList[i].rags.length; j++) {
	// 			const rag = projectRagList[i].rags[j];
	// 			const question = rag?.question;
	// 			const { order, projectExamQuestionNum, projectExamCode } = questionInfo || {};
	// 			if (
	// 				question &&
	// 				order === questionInfo.order &&
	// 				projectExamQuestionNum === questionInfo.projectExamQuestionNum &&
	// 				projectExamCode === questionInfo.projectExamCode
	// 			) {
	// 				const ret1 = projectRagList[i];
	// 				const ret2 = projectRagList[i].rags[j];
	// 				return {
	// 					info: ret1,
	// 					refPages: ret2
	// 				}
	// 			}
	// 		}
	// 	}
	// 	return null;
	// }, [projectRagList, questionInfo])
	const rag: UseRagResult = useRag(projectRagList, questionInfo);

	const convertRagType = (type) => {
		switch (type) {
			case RagType.TEXTBOOK:
				return i18next.t('교과서');
			case RagType.NEWS:
				return i18next.t('뉴스 기사');
			case RagType.BOOK:
				return i18next.t('참고 도서');
			case RagType.PAPER:
				return i18next.t('학술 논문');
			case RagType.OTHERS:
				return i18next.t('기타');
			default:
				return type;
		}
	}

	const handleClickPreview = () => {
		if (!!examInfo.projectCode || !!rag[0]?.fileId || !!rag[1]?.pages)
			window.open(`/preview/${examInfo.projectCode}/${rag[0].fileId}/${rag[1].pages}`, '_blank')
	};

	return (
		<>
			{!!currentQuestionHTML && (
				<>
					<div style={{ overflow: "hidden" }}>
						<Box display='flex' alignItems='center' justifyContent='left' className={classes.lightQuestionSentence}>
							<TextViewer
								currentHTML={currentQuestionHTML}
							/>
						</Box>
					</div>

					{/* <Box display='flex' alignItems='center' justifyContent='left' className={classes.lightBorderEmptyAnswerBox} /> */}

					{globalConfig.useRag && (
						<Box>
							{!rag ?
								<Typography className={classes.previewRagSubText2}>
									<Trans i18nKey="평가과제관리의 '<strong>참고자료</strong>' 단계에서 자료를 등록할 수 있습니다.">
										평가과제관리의 '<strong>참고자료</strong>' 단계에서 자료를 등록할 수 있습니다.
									</Trans>
								</Typography>
								:
								<>
									<Typography className={classes.previewRagSubText1}>
										{`${currentOrder}) ${i18next.t('참고자료')}`}
									</Typography>
									<Box display='flex' alignItems='center' justifyContent='left' className={classes.zoomPageNum}>
										<IconButton className={classes.iconBtnReset} disableRipple onClick={handleClickPreview}>
											<ZoomInActive />
										</IconButton>
										<Typography className={clsx(classes.matching, classes.matchingActive)}>{`p.${rag.info.rags[1].pages}`}</Typography>
										<DocumentIcon />
										<Typography className={classes.dataFileText}>{rag.info.fileName}<span>{` / ${convertRagType(rag.info.type)}`}</span></Typography>
									</Box>
								</>
							}
						</Box>
					)}

					<Box style={{ marginBottom: '20px' }} />
				</>
			)}
		</>
	);
});
