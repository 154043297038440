import { NeoBtDeviceBase } from "../../../../nl-lib3-common/interfaces/neo-bt-device-base";
import { DotNetApi } from "../../nl-lib-maui/maui-bridge/js-to-maui";
import { IPenIdType, sleep } from "../../../../nl-lib3-common";
import { DEVICE_STATE } from "../bt-protocol/bluetooth-device-status-enum";
import { escapePenPacket } from "../bt-protocol/escape-unescape-packet";

export class NeoBTDeviceMaui extends NeoBtDeviceBase {
	protected _name = "maui_bluetooth";

	private _mtuSize = 160;


	/**
	 *
	 * @param server
	 */


	/**
	 *
	 * @param btDevice
	 * @param protocolStartCallback
	 */
	async connectMauiBluetooth(
		args: {
			mauiDeviceId: IPenIdType,
			protocolStartCallback: () => void,
			shouldStopScanTask?: boolean
		}
	): Promise<{ status: string, success: boolean }> {
		const { mauiDeviceId, protocolStartCallback, shouldStopScanTask = true } = args;
		if (this._state !== DEVICE_STATE.disconnected) {
			return {
				status: "already connected",
				success: false
			}
		}

		this._btId = mauiDeviceId;
		this._connected = false;
		this._inConnecting = true;

		// const ret = await DotNetApi.connect(mauiDeviceId);
		let connResult;
		try {
			connResult = await DotNetApi.instance.invoke("Connect", mauiDeviceId, shouldStopScanTask);
			if (!connResult) {
				return {
					status: "connection failed, maui return",
					success: false
				};
			}
		} catch (e) {
			if (!connResult) {
				return {
					status: "connection failed, maui return",
					success: false
				};
			}
		}

		const { result, mtuSize } = connResult;

		if (!result) {
			return {
				status: "connection failed, maui return",
				success: false
			};
		}

		this._mtuSize = mtuSize;

		console.log("BLE Connected.");
		this.mauiPenCommManager.addPenCommBase(mauiDeviceId, this);

		this._connected = true;
		this._inConnecting = false;

		protocolStartCallback();

		return {
			status: "connnection initiated",
			success: true,
		};
	}


	/**
	 *
	 * @param btDevice
	 * @param infoRequestProtocolStart
	 */
	registerConnectedMauiBluetooth(mauiDeviceId: IPenIdType, infoRequestProtocolStart: () => Promise<void>): { status: string, success: boolean } {
		if (this._state !== DEVICE_STATE.disconnected) {
			return {
				status: "already connected",
				success: false
			}
		}

		this._btId = mauiDeviceId;
		this._connected = true;
		this._inConnecting = false;

		console.log("BLE re-Connected.");
		this._mtuSize = null;

		console.log("BLE Connected.");
		this.mauiPenCommManager.addPenCommBase(mauiDeviceId, this);

		this._connected = true;
		this._inConnecting = false;

		infoRequestProtocolStart();

		return {
			status: "connnection initiated",
			success: true,
		};
	}



	/**
	 * disconnect function:
	 */
	async disconnect() {
		console.log(`     DISCONNECT operation`);
		this._inDisconnecting = true;
		if (!this._connected || (!this._btId)) {
			console.log(`     DISCONNECT operation failed`);
			return;
		}

		// DotNetApi.disconnect(this._btId);
		await DotNetApi.instance.invoke("Disconnect", this._btId);
	}

	/**
	 *
	 */
	// eslint-disable-next-line class-methods-use-this
	preDisconnected(): void {
		throw new Error("Not implemented: preDisconnected");
	}

	/**
	 *
	 */
	onDeviceDisconnected = () => {
		this._inDisconnecting = false;
		this._connected = false;
		this._state = DEVICE_STATE.disconnected;

		super.onDeviceDisconnected();
	}

	/**
	 *
	 * @param unescaped
	 */

	// async writeArray(arr: number[]) {
	//   const len = arr.length;

	//   const bf = escapePenPacket(arr.slice(1, len - 1));
	//   bf.unshift(arr[0]);
	//   bf.push(arr[len - 1]);
	//   const escaped = new Uint8Array(bf);

	//   let reqSuccess = false;
	//   let reqRestCnt = 6;
	//   while (!reqSuccess && reqRestCnt > 0) {
	//     try {
	//       // DotNetApi.write(this._btId, escaped);
	//       DotNetApi.instance.invoke("Write", this._btId, escaped);
	//       reqSuccess = true;
	//     } catch (e) {
	//       // eslint-disable-next-line no-await-in-loop
	//       await sleep(50);
	//       reqRestCnt--;
	//     }
	//   }

	//   return reqSuccess;
	// }

	// eslint-disable-next-line class-methods-use-this
	async writeArray(bytes: number[]) {
		// transmission data size is mtu - 3 (opcode 1byte + attribute handle 2byte)

		// console.log(`Bluetooth Packet write (${bytes.length.toString().padStart(5, " ")})  ===> ${bytes.reduce((acc, v) => { const c = decimalToHex(v, 2); return acc + c + " "; }, "")}`);


		if (!this._mtuSize || this._mtuSize === 0) {
			const mtuSize = await DotNetApi.instance.invoke("GetMtuSize", this._btId);
			this._mtuSize = mtuSize;
		}

		const CHUNK_SIZE = this._mtuSize - 3;
		const len = bytes.length;

		const bf = escapePenPacket(bytes.slice(1, len - 1));
		bf.unshift(bytes[0]);
		bf.push(bytes[len - 1]);
		const escaped = new Uint8Array(bf);

		// chunk 없이 전송 시 MTU size 를 넘어갈 경우 다음과 같은 에러 메시지가 반환된다.
		// Failed to execute 'writeValue' on 'BluetoothRemoteGATTCharacteristic': Value can't exceed 512 bytes.
		let startIndex = 0;
		let endIndex = 0;

		let reqSuccess = false;

		while (startIndex < escaped.length && CHUNK_SIZE > 0) {
			endIndex = Math.min(startIndex + CHUNK_SIZE, escaped.length);
			const chunk = escaped.slice(startIndex, endIndex);

			let reqRestCnt = 6;
			while (!reqSuccess && reqRestCnt > 0) {
				try {
					// DotNetApi.write(this._btId, escaped);
					if (escaped.length < CHUNK_SIZE) {
						DotNetApi.instance.invoke("Write", this._btId, chunk);
					} else {
						// 청크로 보낼 경우에는 순서를 맞춰 보내지도록 해야한다.
						await DotNetApi.instance.invoke("Write", this._btId, chunk);
					}
					reqSuccess = true;
					startIndex = endIndex;
				} catch (e) {
					// eslint-disable-next-line no-await-in-loop
					console.error(`BLE WRITE ERROR: ${e}`);
					await sleep(50);
					reqRestCnt--;
				}
			}
			if (!reqSuccess) {
				return false;
			}

			reqSuccess = false;
		}

		return true;
	}

	async write(buf: Uint8Array) {
		const arr = Array.from(buf);
		const ret = await this.writeArray(arr);
		return ret;
	}
}


