import { assert } from "./functions";
import { FzBuffer } from "./FzBuffer";
import { Wrapper } from "./Wrapper";


export class FzOutput extends Wrapper {
  constructor(libmupdf: any, ctx: number, pointer: number) {
    super(libmupdf, ctx, pointer, libmupdf._wasm_drop_output);
  }

  static withBuffer(libmupdf: any, ctx: number, buffer) {
    assert(buffer instanceof FzBuffer, "invalid buffer argument");
    return new FzOutput(libmupdf, ctx, libmupdf._wasm_new_output_with_buffer(ctx, buffer.pointer));
  }

  close() {
    const { libmupdf } = this;
    libmupdf._wasm_close_output(this.ctx, this.pointer);
  }
}
