import {
	Box
} from "@mui/material";


import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { QuestionAndReferenceTabExamRow } from "./QuestionAndReferenceTabExamRow";
import { useStores } from "../../../../hooks/useStores";

type Props = {
	editOnlinePaper: any,
	onlineAnswers: any,
	changeAnswers: any
}

export const QuestionAndReferenceTab = (props: Props) => {
	const { editOnlinePaper, onlineAnswers, changeAnswers } = props;
	const { projectExamStore } = useStores();
	const { projectExamList } = projectExamStore;

	return (
		<Box sx={{ position: 'relative' }}>
			{projectExamList.length > 0 &&
				projectExamList.filter(e => e.changeFlag !== ChangeFlag.DELETED)
					.map((exam, index) => {
						return (
							<QuestionAndReferenceTabExamRow
								editOnlinePaper={editOnlinePaper}
								onlineAnswers={onlineAnswers}
								changeAnswers={changeAnswers}
								key={`exam_${index}`}
								examInfo={exam}
								index={index + 1}
								projectExamList={projectExamList}
							/>
						)
					})}
		</Box>
	)
}
