import { CallbackItem, EventCallbackType, PenEventFilter } from "./EventTypesDef";

export class DispatcherEvent {
  eventName: string;

  callbacks: CallbackItem[];

  constructor(eventName: string) {
    this.eventName = eventName;
    this.callbacks = [];
  }

  /**
   *
   * @param {function} callback
   * @param {{mac:string}=} filter
   */
  public registerCallback(callback: EventCallbackType, filter?: PenEventFilter) {
    if (!filter) {
      filter = { mac: undefined, allowAll: true };
    }

    const callback_item = { callback, filter };
    if (!this.callbacks.includes(callback_item)) {
      this.callbacks.push(callback_item);
    }
  };

  /**
   *
   * @param {function} callback
   * @return {boolean}
   */
  public unregisterCallback(callback: EventCallbackType): boolean {
    // Get the index of the callback in the callbacks array
    // const index = this.callbacks.indexOf(callback);
    const index = this.callbacks.findIndex((item) => item.callback === callback);

    // If the callback is in the array then remove it
    if (index > -1) {
      this.callbacks.splice(index, 1);
      return true;
    }

    return false;
  };


  public unregisterCallbackAll(): boolean {
    this.callbacks = [];
    return true;
  };

  /**
   *
   * @param {object} data - which can have "mac" property to filter
   */
  public fire(data: any) {
    // We loop over a cloned version of the callbacks array
    // in case the original array is spliced while looping
    const callbacks = this.callbacks.slice(0);

    // loop through the callbacks and call each one
    callbacks.forEach((item) => {
      const { filter } = item;
      if ((data && !data.mac) || filter.allowAll || !filter.mac || filter.mac === data.mac || filter.mac.indexOf(data.mac) > -1) {
        item.callback(data);
      }
    });
  };
}
