import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const ManageStudentStlye : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
	},
	// emptyBox:{
	//     width: '100%',
	//     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
	//     display:'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center',
	//     '& p':{
	//         fontSize: '1.375rem',
	//         color: '#87888d',
	//         fontWeight:400,
	//         textAlign: 'center',
	//         lineHeight: '30.36px'
	//     }
	// },
	// boxWrap: {
	//     backgroundColor: '#fff',
	//     padding: 20,
	//     borderRadius: 8,
	//     height: 'calc(100vh - 500px)'
	// },

	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			letterSpacing: '-0.5px',
			marginLeft: "7px",
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},


	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center',
			padding: '5px 16px',
		},
		'&.MuiListItemButton-root:hover': {
			padding: '5px 16px',
			background: '#fff'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		},
		'& .MuiListItemButton-root:hover': {
			background: '#DEF8F3'
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		},
		'& .MuiButtonBase-root': {
			height: 42
		},
		'& .MuiListItemButton-root:hover': {
			background: '#DEF8F3'
		},
		'& .MuiTypography-root': {
			display: 'none'
		}
	},





	/////////////////////
	padding: {
		padding: 15,
	},
	mainContentBox: {
		border: '1px solid #EAECEB',
		padding: '22px 31px',
		height: 'calc(100vh - 84px)',
		overflowY: 'auto',
		background: '#fff',
		width: '100%',
		maxWidth: '1440px',
		boxSizing: 'border-box',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	mainContentBoxLeft: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 130px)',
		overflowY: 'auto',
		background: '#fff',
		width: 'calc(100vw - 770px)',
	},
	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		overflowY: 'auto',
		background: '#fff',
		width: '620px',
		boxSizing: 'border-box',
		marginLeft: "10px",
	},


	commonSubTitle: {
		textAlign: 'left',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			marginBottom: "20px",
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "20px",
		}
	},
	schoolNameSubText: {
		color: '#555',
		fontSize: '1rem',
		letterSpacing: '-0.5px',
		marginBottom: "15px",
	},
	dialogInput: {
		'&.MuiFormControl-root': {
			width: "429px",
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'&.MuiOutlinedInput-root': {
			height: "36px",
			borderRadius: 2,
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px',
		},
		'& .MuiInputBase-input': {
			fontSize: '1rem',
			color: '#787878',
			fontWeight: 400,
			letterSpacing: '-0.7px'
		},
		'& .MuiInputBase-root': {
			borderRadius: 3,
		},


	},
	groupNameInput: {
		'&.MuiFormControl-root': {
			width: "300px",
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'&.MuiOutlinedInput-root': {
			height: "36px",
			borderRadius: 2,
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px',
		},
		'& .MuiInputBase-input': {
			fontSize: '1rem',
			color: '#787878',
			fontWeight: 400,
			letterSpacing: '-0.7px'
		},
		'& .MuiInputBase-root': {
			borderRadius: 3,
		},


	},
	dialogInputName: {
		'&.MuiFormControl-root': {
			width: '100%',
		},
	},
	dialogInputSchool: {
		'&.MuiFormControl-root': {
			width: '100%',
			// marginRight: "7px",
		},
		'& .MuiInputBase-root': {
			borderRadius: 3,
		}
	},

	dialogInputNewGroupName: {

		'&.MuiFormControl-root': {
			width: "625px",
			marginRight: "10px",
		},
		'& .MuiInputBase-root': {
			borderRadius: 3,
		}
	},


	dialogInputSchoolEach: {
		'&.MuiFormControl-root': {
			width: "555px",
			marginRight: "7px",
		},
		'& .MuiInputBase-root': {
			borderRadius: 3,
		}
	},

	dialogInputLastNameTitle: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '1rem',
			marginLeft: "0px",
			marginRight: "5px",
			width: "60px",
			textAlign: 'right'
		}
	},

	dialogInputLastName: {
		'&.MuiFormControl-root': {
			width: '120px',
		},
		'& .MuiInputBase-input': {
			textAlign: 'center'

		}
	},


	dialogInputFirstNameTitle: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '1rem',
			marginLeft: "5px",
			marginRight: "5px",
			width: "60px",
			textAlign: 'right'
		}
	},
	dialogInputFirstName: {
		'&.MuiFormControl-root': {
			width: '120px',
		},
		'& .MuiInputBase-input': {
			textAlign: 'center'
		}
	},



	dialogInputFullNameTitle: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '1rem',
			marginLeft: "5px",
			marginRight: "5px",
			width: "80px",
			textAlign: 'right'
		}
	},

	dialogInputFullName: {
		'&.MuiFormControl-root': {
			width: '260px',
		},
		'& .MuiInputBase-input': {
			textAlign: 'center'
		}
	},


	dialogInputRoomNo: {
		'&.MuiFormControl-root': {
			width: '108px',
		},
		'& .MuiInputBase-input': {
			textAlign: 'right'
		}
	},
	dialogInputRoomNo1: {
		'&.MuiFormControl-root': {
			width: '70px',
		},
		'& .MuiInputBase-input': {
			textAlign: 'right'
		}
	},
	dialogInputRoomNo2: {
		'&.MuiFormControl-root': {
			width: '200px',
		},
		'& .MuiInputBase-input': {
			textAlign: 'right'
		}
	},
	dialogInputRoomNo3: {
		'&.MuiFormControl-root': {
			width: '70px',
		},
		'& .MuiInputBase-input': {
			textAlign: 'right'
		}
	},
	textFieldBox: {
		display: 'flex',
		alignItems: 'center',
	},
	textFieldBoxGroup: {
		marginTop: "10px",
		marginBottom: "10px",
	},
	groupListText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			fontWeight: 400,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			marginTop: "25px",
		}
	},
	blackBtn: {
		'&.MuiButtonBase-root': {
			width: "84px",
			height: "36px",
			border: '1px solid #cbcbcb',
			borderRadius: 3,
			background: '#fff',
			'& .MuiTypography-root': {
				color: '#111',
				fontSize: '0.875rem',
				fontWeight: 700,
				letterSpacing: '-0.5px'
			}
		},
		'&.Mui-disabled': {
			opacity: 0.5,
			border: '1px solid rgba(0, 0, 0, 0.12)',
			'& .MuiTypography-root': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		}

	},

	greenBtn: {
		marginRight: "20px",
		'&.MuiButtonBase-root': {
			width: "140px",
			height: "36px",
			background: '#008C73',
			borderRadius: 3,
			'&:hover': {
				background: '#008C73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 700,
			}
		}
	},

	greenBtnN: {
		'&.MuiButtonBase-root': {
			width: "140px",
			height: "36px",
			background: '#025949',
			borderRadius: 3,
			'&:hover': {
				background: '#025949',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 700,
			}
		}
	},
	lineBtn: {
		marginRight: "20px",
		'&.MuiButtonBase-root': {
			width: "140px",
			height: "36px",
			background: '#fff',
			borderRadius: 3,
			border: '1px solid #111',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#111',
				fontWeight: 700,
				letterSpacing: '-0.5px',
			}
		}
	},
	selectText: {
		'&.MuiTypography-root': {
			width: "60px",
			textAlign: "center",
			marginRight: "15px",
			color: '#111',
			fontWeight: 700,
			letterSpacing: '-0.5px'
		}
	},

	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "30px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'& em': {
				fontStyle: 'normal !important',
				color: '#787878',
				letterSpacing: '-0.8px',
			},
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		},
		'&.Mui-disabled': {
			fontStyle: 'normal !important',
			color: '#787878',
			letterSpacing: '-0.8px',
		}
	},

	area1: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "203px",
			marginRight: "7px",
		},
		'& .MuiSelect-select': {
			width: "202px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},
	area1_batch: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "200px",
			marginRight: "7px",
		},
		'& .MuiSelect-select': {
			width: "202px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},


	area2: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "293px",
			marginRight: "7px",
		},
		'& .MuiSelect-select': {
			width: "293px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},

	area2_batch: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "200px",
			marginRight: "7px",
		},
		'& .MuiSelect-select': {
			width: "293px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},

	area3: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "203px",
		},
		'& .MuiSelect-select': {
			width: "202px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},

	area3_batch: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "200px",
		},
		'& .MuiSelect-select': {
			width: "202px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},

	area4: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: '100%',
			marginRight: "9px",
		},
		'& .MuiSelect-select': {
			width: '100%',
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},

	area1Each: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "180px",
			marginRight: "10px",
		},
		'& .MuiSelect-select': {
			width: "180px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},
	area2Each: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "263px",
			marginRight: "10px",
		},
		'& .MuiSelect-select': {
			width: "263px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},
	area1Group: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: '100%',
			marginRight: "7px",
		},
		'& .MuiSelect-select': {
			width: '100%',
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#82878B',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},








	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: 'transparent',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem'
		}
	},
	pageNumber: {
		marginTop: "20px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},
	pageNumberBorder: {
		borderBottom: '1px solid #cbcbcb',
		paddingBottom: 8,
		marginTop: "8px",
	},
	pageNumberGroup: {
		marginTop: "10px",
		marginBottom: "28px",
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',

		}
	},
	tableStudentNameBtn: {
		'&.MuiButtonBase-root': {
			background: 'transparent',
			minWidth: 'auto',
			padding: 0,
			'& hover': {
				background: 'transparent',
			},
			'& .MuiTypography-root': {
				color: '#008C73',
				fontSize: '0.875rem',
				textDecoration: 'underline',
				letterSpacing: '-0.5px'
			}
		}

	},
	tableBox: {
		marginTop: "20px",
		// minWidth: 900,
		// maxWidth: 1300,
		'&.MuiPaper-root': {
			borderTop: '1px solid #cbcbcb',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "44px",
				fontWeight: 800,
				borderBottom: '1px solid #cbcbcb',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				fontWeight: 400,
				color: '#111',
				height: "50px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid #cbcbcb',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},
	tableNoBox: {
		paddingLeft: '20px !important',
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},
	iconAdd: {
		'& svg': {
			marginBottom: "2px",
			marginRight: "5px",
		}
	},

	stepOpen: {
		'&.MuiButtonBase-root': {
			border: '1px solid #111',
			width: "120px",
			boxSizing: 'border-box',
			background: '#fff',
			color: '#111',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.813rem',
				fontWeight: 'bold',
			}
		}
	},
	taskSave: {
		'&.MuiButtonBase-root': {
			border: '1px solid #E97C17',
			color: '#E97C17',
		}
	},
	taskMark: {
		'&.MuiButtonBase-root': {
			border: '1px solid #7730BA',
			color: '#7730BA',
		}
	},
	taskResult: {
		'&.MuiButtonBase-root': {
			border: '1px solid #3850D5',
			color: '#3850D5',
		}
	},



	numberBox: {
		width: "41px",
		height: "27px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 13,
		background: '#eee',
		boxSizing: 'border-box',
		marginRight: "11px",
		'& p': {
			fontSize: '0.875rem',
			color: '#777',
			fontWeight: 'bold',
			'& span': {
				color: '#38cb89',
				fontWeight: 'bold'
			}
		}
	},
	progressBox: {
		width: "80px",
		'& .MuiLinearProgress-root': {
			height: "6px",
			background: '#eee',
			borderRadius: 3
		},
		'& .MuiLinearProgress-bar': {
			background: '#38cb89'
		}
	},
	progressText: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#323232',
			marginLeft: "11px",
			minWidth: 30,
			textAlign: 'right',
			'@media all and (max-width: 1550px)': {
				fontSize: '0.75rem',
			},
		}
	},

	popoverBox: {
		'& .MuiPaper-root': {
			width: "147px",
			boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.05)',
			border: '1px solid #bbb',
			borderRadius: 6,
			'& ul': {
				padding: '4px 0',
				'& li': {
					color: '#6f6f6f',
					letterSpacing: '-0.5px',
					display: 'flex',
					justifyContent: 'left',
					'& .MuiTypography-root': {
						marginLeft: "6px",
						fontSize: '0.875rem',
					},
					'&:hover': {
						background: '#eee'
					},
				},
			},
			'& *': {
				fontFamily: 'Pretendard-Regular !important',
			},
		}
	},
	deleteText: {
		'&.MuiTypography-root': {
			color: '#F31825'
		},
	},




	taskOpenDialog: {
		'& .MuiPaper-root': {
			width: "785px",
			height: '90vh',
			borderRadius: '15px'
		},
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 785,
		}
	},

	dialogTop: {
		borderBottom: '1px solid #CBCBCB',
		marginTop: "23px",
		paddingBottom: 23,
		'& .MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			marginLeft: "20px",
		},
		'& .MuiIconButton-root': {
			marginRight: "20px",
		}
	},
	dialogContent: {
		padding: '30px 30px 0px 30px',
		overflowY: 'auto',
		marginBottom: "30px",
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px"
		},
	},
	dialogContentSave: {
		padding: '20px 20px 30px 20px',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px"
		},
		marginBottom: "30px",
		position: 'relative',
		'&:after': {
			content: "''",
			width: '2px',
			height: "800px",
			// borderRight: '2px solid #738DED',
			background: '#738DED',
			display: 'inline-block',
			position: 'absolute',
			left: '34px',
			top: 30,

		},
	},
	dialogContentGroup: {
		padding: '22px 30px 0px 30px',
		overflowY: 'auto',
		marginBottom: "30px",
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px"
		},
	},

	dialogHalf: {
		width: '50%',
	},
	dialogTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			marginBottom: "20px",
			marginTop: "30px",
			'& span': {
				fontSize: '1rem',
				fontWeight: 'normal',
				color: '#555'
			}
		}
	},
	dialogSubTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	dialogTime: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 'bold',
			marginLeft: "40px",
			marginRight: 10.
		}
	},

	dialogBtnGroup: {
		marginTop: "20px",
		marginBottom: "20px",
	},
	dialogBtn: {
		'&.MuiButtonBase-root': {
			border: '1px solid #CBCBCB',
			width: "120px",
			height: "40px",
		},
		'&.MuiButton-root:hover': {
			background: '#fff'
		},
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	dialogBtnSave: {
		'&.MuiButtonBase-root': {
			border: '1px solid #2F80ED',
			background: '#2F80ED',
			width: "120px",
			height: "40px",
			marginLeft: "15px",
		},
		'&.MuiButton-root:hover': {
			background: '#2F80ED'
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '1rem',
			fontWeight: 'bold'
		},
		'&.Mui-disabled': {
			opacity: 0.5,
			border: '1px solid rgba(0, 0, 0, 0.12)',
			'& .MuiTypography-root': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		}
	},
	dialogYear: {
		position: 'relative',
		marginRight: "20px",
		'& .MuiFormControl-root': {
			width: '110px',
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 64
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
		}
	},
	yearSelectIcon: {
		position: 'absolute',
		right: 0,
		top: 5,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	iconYearSelect: {
		'&.MuiIconButton-root': {
			margin: '4px 5px',
		}
	},

	dateBox: {
		'& .MuiStack-root': {
			paddingTop: 0,
			// paddingBottom: 28,
			display: 'flex',
			alignItems: 'center',
			overflow: 'unset'
		},
		'& .MuiFormControl-root': {
			minWidth: '145px !important',
			maxWidth: '145px !important',
			margin: 0
		},
		'& .MuiInputBase-root': {
			paddingRight: 0,
			borderRadius: 6
		},
		'& .MuiInputBase-input': {
			height: "30px",
			boxSizing: 'border-box',
			fontSize: '0.875rem',
			color: '#323232',
			letterSpacing: '-0.5px',
			fontWeight: 500,
			padding: '0 11px'
		},
		'& .MuiInputAdornment-root': {
			marginLeft: "0px",
			'& .MuiButtonBase-root': {
				padding: 0,
				marginRight: "5px",
				'&:hover': {
					background: 'transparent'
				}
			},
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 'solid 1px #bbbbbb',
			borderRadius: 6
		},
	},
	calendarBox: {
		cursor: 'pointer',
		width: "23px",
		height: "23px",
		marginRight: 5
	},
	waveText: {
		'&.MuiTypography-root': {
			marginLeft: "10px",
			marginRight: "10px",
		}
	},

	subSideMenu: {
		'& .MuiStepIcon-root.MuiStepIcon-active': {
			color: '#025949'
		},
		'& .MuiStepIcon-root.MuiStepIcon-completed': {
			color: '#025949'
		},
		'& .MuiStepIcon-root': {
			color: '#DDDDDD'
		},
		'& .MuiStepConnector-vertical': {
			padding: '0px 0 2px',
			marginLeft: "6px",
		},
		'& .MuiStepLabel-root': {
			'& .MuiStepLabel-vertical': {

			}
		},
		'& .MuiStepper-vertical': {
			padding: '24px 0px 24px 24px'
		},
		'& .Mui-disabled': {
			'& rect': {
				fill: '#ddd'
			}
		},
		'& .MuiStepLabel-labelContainer': {
			textAlign: 'left'
		},

	},
	nameSearchBtn: {
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#787878',
				marginLeft: "5px",
			}
		}
	},

	sortButton: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		'& .MuiTypography-root': {
			fontWeight: 800,
			fontSize: '0.875rem',
			color: '#555',
			marginRight: "4px",
			letterSpacing: '-0.5px'
		}
	},
	urlInputBox: {
		'&.MuiPaper-root': {
			width: "180px",
			height: "35px",
			border: '1px solid #87878;',
			boxShadow: 'none'
		},
		'& .MuiInputBase-root': {
			width: "150px",
		},
		'& .MuiInputBase-input': {
			padding: '8px 10px 7px',
			fontSize: '0.875rem',
			fontWeight: 700,
			color: '#787878'
		}
	},
	urlBtn: {
		marginLeft: "10px",
		'&.MuiButtonBase-root': {
			width: "82px",
			height: "27px",
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				letterSpacing: '-0.5px',
			}
		}
	},
	urlBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#fff'
			}
		}
	},
	passwordCheckBoxInner: {
		// display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'left'
		marginTop: "8px",
	},
	rememberId: {
		height: "20px",
		marginTop: "3px",
		marginRight: "30px",
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555',
			letterSpacing: '-0.5px',
		},
		'& .MuiIconButton-root': {
			padding: 0,
			marginRight: "9px",
			marginLeft: "12px",
		}
	},

	dataOpenDialog: {
		'& > .MuiPaper-root': {
			width: "785px",

			// height: '25vh',

		},
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 785,
			borderRadius: '16px',
		}
	},
	listBox: {
		borderBottom: '1px solid #cbcbcb',
		// padding: '26px 0px',
		paddingBottom: "20px",
		marginBottom: "20px",
	},
	listBoxTitle: {
		marginBottom: "10px",
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#111',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			'& span': {
				fontWeight: 400,
				color: '#555'
			}
		},
	},
	listBoxTitleEach: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#111',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			'& span': {
				fontWeight: 400,
				color: '#555'
			}
		},
	},
	listBoxTitleSearch: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 600,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			marginBottom: "22px",
		},
	},

	borderBox: {
		padding: '24px 30px',
		borderRadius: 2,
		border: '1px solid #EAECEB',
		background: '#FAFAFA',
		marginTop: "8px",
		marginBottom: "30px",
	},
	listBoxLast: {
		borderBottom: '0px solid #cbcbcb',
		paddingBottom: 0,
		marginBottom: "0px",
	},
	emailList: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left'
	},
	emailError: {
		color: '#e33131',
	},
	TextFieldStyle: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 2,
			width: "233px",
			padding: '9.5px 8px'
		}
	},
	emailText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			marginLeft: "10px",
			marginRight: 20

		}
	},
	emailBtn: {
		'&.MuiButtonBase-root': {
			border: '1px solid #008C73',
			borderRadius: 3,
			width: "160px",
			height: "36px",
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008C73',
				fontWeight: 500,
				letterSpacing: '-0.5px',

			}
		},
		'&.Mui-disabled': {
			opacity: 0.5,
			border: '1px solid rgba(0, 0, 0, 0.12)',
			'& .MuiTypography-root': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		}
	},
	roomNoBtn: {
		'&.MuiButtonBase-root': {
			border: '1px solid #008C73',
			borderRadius: 3,
			width: "176px",
			height: "36px",
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008C73',
				fontWeight: 500,
				letterSpacing: '-0.5px',

			}
		},
		'&.Mui-disabled': {
			opacity: 0.5,
			border: '1px solid rgba(0, 0, 0, 0.12)',
			'& .MuiTypography-root': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		}
	},
	duplicationCheckBt: {
		'&.Mui-disabled': {
			opacity: 0.5,
			border: '1px solid rgba(0, 0, 0, 0.12)',
			'& .MuiTypography-root': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		}
	},
	passText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			fontSize: '0.938rem',
			letterSpacing: '-0.5px',

		}
	},
	boxNoticeText: {
		'&.MuiTypography-root': {
			color: '#6f6f6f',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '0.875rem',
			textAlign: 'center'
		}
	},
	textBoxSub: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '1rem',
			marginLeft: "10px",
			marginRight: "40px",
		}
	},

	textBoxSub1: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '1rem',
			marginLeft: "5px",
			marginRight: "30px",
		}
	},
	textBoxSub2: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '1rem',
			marginLeft: "5px",
			marginRight: "30px",
		}
	},
	textBoxSub3: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '1rem',
			marginLeft: "5px",
			marginRight: "30px",
		}
	},


	deleteTextBoxEdit: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		background: '#F3F3F3',
		marginTop: "10px",
		padding: '16px 24px',
		boxSizing: 'border-box',
		borderRadius: 10,
	},
	deleteTextBox: {
		background: '#F3F3F3',
		marginTop: "20px",
		padding: '16px 24px 8px 24px',
		boxSizing: 'border-box',
		borderRadius: 10,
	},
	deleteTextBoxInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: "8px",
	},
	deleteTextBoxInnerForGroupAdd: {
		border: '2px solid transparent',
		'&:hover': {
			border: '2px dashed darkorange'
		}
	},
	deleteTextBoxGroup: {
		background: '#F3F3F3',
		marginTop: "5px",
		padding: '16px 24px 8px 24px',
		boxSizing: 'border-box',
		borderRadius: 10,
	},
	guideTextBox: {
		background: '#F3F3F3',
		height: "61px",
		marginTop: "20px",
		padding: '16px 24px',
		boxSizing: 'border-box',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 10,

	},
	textBoxName: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#037B6C',
			fontWeight: 600,
			lineHeight: 'normal',
		}
	},

	stepCont: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
	},
	stepContFive: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		marginTop: "30px",
	},
	stepContBtn: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: "30px",
	},

	stepNumber: {
		zIndex: '10',
		background: '#2F80ED',
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#fff',
			textAlign: 'center',
			fontWeight: 600,
		},
		width: "24px",
		height: "24px",
		borderRadius: '100%',
		marginRight: "8px",
		lineHeight: 1.8

	},
	fileUploadTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 700,
			marginBottom: "20px",
			marginTop: "10px",
			lineHeight: 'normal',
		}
	},
	fileUploadText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
		}
	},
	// stepNumberLast: {
	//     '&.MuiTypography-root': {
	//         '&:after': {
	//             content:"''",
	//             width: '20px',
	//             height: "10px",
	//             background: 'red',
	//         }
	//     },
	//
	// },
	nexStepText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			letterSpacing: '-0.5px',
			marginTop: "7px",
		}
	},
	tableInner: {
		marginTop: "6px",
	},
	tableBoxTopBtn: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& > .MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 500,
			color: '#111',
			letterSpacing: '-0.5px',
			'& span': {
				color: '#008C73',
				fontWeight: 700,
			}
		},
		'& .MuiButtonBase-root': {
			background: '#fff',
			border: '1px solid #008C73',
			width: "136px",
			'& .MuiTypography-root': {
				fontSize: '1rem',
				fontWeight: 500,
				color: '#008C73',
				letterSpacing: '-0.5px',
			},
		}
	},
	tableNoticeText: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		marginTop: "10px",
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 500,
			color: '#FF0000',
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			marginLeft: "5px",
		}
	},
	fileBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			border: '1px solid #008C73',
			width: "184px",
			height: "36px",
			'& .MuiTypography-root': {
				color: '#008C73',
				letterSpacing: '-0.5px',
				fontWeight: 500,
			}
		}
	},
	folderListTop: {
		borderTop: '1px solid #cbcbcb',
		marginTop: "25px",
	},
	folderList: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: '1px solid #cbcbcb',
		padding: 10,
	},
	folderListBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 500,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			marginLeft: "14px",
		}
	},
	UsersListBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 500,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			marginLeft: "6px",
			marginRight: "10px",
		}
	},
	GroupUsersProfilesText: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 500,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			color: '#555',
			'& span': {
				color: '#008C73',
				fontWeight: 700,
				marginRight: "2px",
			}
		}
	},
	fileTitle: {
		display: 'flex',
		alignItems: 'center'
	},
	greenTitle: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: "10px",
		'&.MuiTypography-root': {
			color: '#025949',
			fontWeight: 600,
		}
	},
	guideTextList: {
		'&.MuiTypography-root': {
			color: '#666',
			fontWeight: 400,
			marginLeft: "25px",
		}
	},
	redTitle: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: "10px",
		'&.MuiTypography-root': {
			color: '#F31825',
			fontWeight: 700,
			fontSize: '0.875rem'
		}
	},
	guideTextListRed: {
		'&.MuiTypography-root': {
			color: '#F31825',
			fontWeight: 400,
			marginLeft: "25px",
			fontSize: '0.875rem'
		}
	},

	// kitty
	selectPopover: {},
	stepNumberLast: {},
	checkedColor: {},


});












