/**
 * base64로 인코딩된 binary를 Uint8Array로 변환
 * @param base64String
 * @returns
 */
function base64ToUint8Array(base64String: string) {
  // Base64 문자열을 디코딩
  const binaryString = atob(base64String);
  const len = binaryString.length;

  // 결과를 저장할 Uint8Array를 생성
  const bytes = new Uint8Array(len);

  // 각 문자의 문자 코드를 bytes 배열에 저장
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
}

/**
 * Binary의 uint8array를 base64로 변환
 * @param uint8Array
 * @returns
 */
export async function uint8ArrayToBase64(uint8Array: Uint8Array) {
  const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = reject;
    reader.onloadend = () => {
      // 결과에서 "data:application/octet-stream;base64," 접두사 제거
      resolve((reader.result as string).split(',')[1]);
    };
    reader.readAsDataURL(blob);
  });
}


/**
 * data:...로 인코딩된 URI를 uint8array로 변환
 * @param dataURI
 * @returns
 */
export function dataURItoUint8Array(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return ia;
  // // write the ArrayBuffer to a blob, and you're done
  // var blob = new Blob([ab], { type: mimeString });
  // return blob;
}


/**
 * Uint8Array를 DataUri로 변환
 * @param uint8Array
 * @param type "application/pdf" | "image/png" | "image/jpeg"
 * @returns
 */
export function uint8ArrayToDataUri(uint8Array: Uint8Array, type: string) {
  let binary = '';
  for (let i = 0, len = uint8Array.byteLength; i < len; i++)
    binary += String.fromCharCode(uint8Array[i]);

  const b64encoded = window.btoa(binary);
  const dataUri = "data:" + type + ";base64," + b64encoded;
  return dataUri;
}




/**
 * object에 있는 uint8array를 { isBase64Uint8Array: true, data: base64 }로 변환
 * @param obj
 * @returns
 */
export async function encodeUint8ArrayInObject(obj: any) {
  if (!obj) return obj;

  if (typeof obj === "object") {
    if (obj instanceof Uint8Array) {
      // Uint8Array를 Blob으로 변환
      const base64 = await uint8ArrayToBase64(obj);
      const arrObj = {
        isBase64Uint8Array: true,
        data: base64,
      };

      return arrObj;
    }
    else {
      const keys = Object.keys(obj);
      const objResult = {} as any;
      for (let i = 0, ln = keys.length; i < ln; i++) {
        const key = keys[i];
        const value = obj[key];
        const child = await encodeUint8ArrayInObject(value);
        objResult[key] = child;
      }
      return objResult;
    }
  }

  return obj;
}


export async function convertUint8ArrayToObject(obj: any) {
  if (!obj) return obj;

  if (typeof obj === "object") {
    if (obj instanceof Uint8Array) {
      // Uint8Array를 Blob으로 변환
      const base64 = await uint8ArrayToBase64(obj);
      const arrObj = {
        isBase64Uint8Array: true,
        data: base64,
      }
      return arrObj;
    }
    const objResult = await encodeUint8ArrayInObject(obj);
    return objResult;
  }

  return obj;
}

/**
 * object에 있는 { isBase64Uint8Array: true, data: base64 }를 uint8array로 환원
 * @param obj
 * @returns
 */
export function decodeUint8ArrayInObject(obj: any) {
  if (!obj) return obj;

  if (typeof obj === "object") {
    if (obj.hasOwnProperty("isBase64Uint8Array") && obj.hasOwnProperty("data")) {
      // Uint8Array를 Blob으로 변환
      const u8arr = base64ToUint8Array(obj.data);
      return u8arr;
    }

    let isArray = true;
    const arr = [];
    const entries = Object.entries(obj);
    for (let i = 0, ln = entries.length; i < ln; i++) {
      const key = entries[i][0];
      if ( parseInt( key ) !== i ) {
        isArray = false;
        break;
      }
      const value = entries[i][1];
      arr.push(value);
    }

    if ( isArray ) {
      return arr;
    }
    else {
      const keys = Object.keys(obj);
      const objResult = {} as any;
      for (let i = 0, ln = keys.length; i < ln; i++) {
        const key = keys[i];
        const value = obj[key];
        const child = decodeUint8ArrayInObject(value);
        objResult[key] = child;
      }
      return objResult;
    }
  }

  return obj;
}


