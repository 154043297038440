import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const TemplateProjectListForPublicStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
	},
	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			letterSpacing: '-0.5px',
			marginLeft: "7px",
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},


	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center',
			padding: '5px 16px',
		},
		'&.MuiListItemButton-root:hover': {
			padding: '5px 16px',
			background: '#fff'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		},
		'& .MuiListItemButton-root:hover': {
			background: '#DEF8F3'
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		},
		'& .MuiButtonBase-root': {
			height: 42
		},
		'& .MuiListItemButton-root:hover': {
			background: '#DEF8F3'
		},
		'& .MuiTypography-root': {
			display: 'none'
		}
	},





	/////////////////////
	padding: {
		padding: 15,
	},
	titleTopMg: {
		marginTop: "12px",
	},
	mainContentBox: {
		border: '1px solid #EAECEB',
		padding: '22px 31px',
		height: 'calc(100vh - 84px)',
		overflowY: 'auto',
		background: '#fff',
		width: '100%',
		maxWidth: '1440px',
		boxSizing: 'border-box'
	},
	mainContentBoxLeft: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 130px)',
		overflowY: 'auto',
		background: '#fff',
		width: 'calc(100vw - 770px)',
	},
	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		overflowY: 'auto',
		background: '#fff',
		width: '620px',
		boxSizing: 'border-box',
		marginLeft: "10px",
	},


	commonSubTitle: {
		textAlign: 'left',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			marginBottom: "20px",
			lineHeight: 'normal',
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "20px",
		}
	},
	schoolNameSubText: {
		color: '#555',
		fontSize: '1rem',
		letterSpacing: '-0.5px',
		marginBottom: "15px",
	},
	dialogInput: {
		'& .MuiFormControl-root': {
			width: '100%',
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: 220
		},
	},
	greenBtn: {
		'&.MuiButtonBase-root': {
			width: "168px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "126px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008c73',
				fontWeight: 'bold'
			}
		}
	},

	selectText: {
		'&.MuiTypography-root': {
			width: "60px",
			textAlign: "center",
			marginRight: "15px",
			color: '#111',
			fontWeight: 700,
			letterSpacing: '-0.5px'
		}
	},

	formControl: {
		'&.MuiFormControl-root': {
			margin: 0,
			width: "300px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "300px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#777',
			letterSpacing: '-0.5px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '2px !important',
			'& em': {
				fontStyle: 'normal !important',
				color: '#787878',
				letterSpacing: '-0.8px',
			},
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #2F80ED',
			borderRadius: 3,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: '1px solid #2F80ED',
			height: "34px",
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		},
	},



	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: 'transparent',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem'
		}
	},
	pageNumber: {
		marginTop: "20px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentNameBtn: {
		'&.MuiButtonBase-root': {
			background: 'transparent',
			padding: 0,
			minWidth: 0,
			'& hover': {
				background: 'transparent',
			},
			'& .MuiTypography-root': {
				color: '#008C73',
				fontSize: '0.875rem',
				textDecoration: 'underline',
				letterSpacing: '-0.5px',
				overflow: 'hidden',
				// textOverflow:'ellipsis',
				whiteSpace: 'nowrap',
			}
		}
	},

	tableBox: {
		marginTop: "20px",
		// minWidth: 900,
		// maxWidth: 1300,
		'&.MuiPaper-root': {
			borderTop: '1px solid #cbcbcb',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "44px",
				fontWeight: 800,
				borderBottom: '1px solid #cbcbcb',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				fontWeight: 400,
				color: '#111',
				height: "50px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid #cbcbcb',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},
	iconAdd: {
		marginLeft: "10px",
		'& svg': {
			marginBottom: "2px",
			marginRight: "5px",
		}
	},

	stepOpen: {
		'&.MuiButtonBase-root': {
			border: '1px solid #111',
			width: "120px",
			boxSizing: 'border-box',
			background: '#fff',
			borderRadius: 3,
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.813rem',
				fontWeight: 700,
				letterSpacing: '-0.5px',
				color: '#111'
			}
		}
	},
	taskSave: {
		'&.MuiButtonBase-root': {
			border: '1px solid #E97C17',
			color: '#E97C17',
			'& .MuiTypography-root': {
				color: '#E97C17'
			}
		}
	},
	taskMark: {
		'&.MuiButtonBase-root': {
			border: '1px solid #7730BA',
			color: '#7730BA',
			'& .MuiTypography-root': {
				color: '#7730BA'
			}
		}
	},
	taskResult: {
		'&.MuiButtonBase-root': {
			border: '1px solid #3850D5',
			color: '#3850D5',
			'& .MuiTypography-root': {
				color: '#3850D5'
			}
		}
	},



	numberBox: {
		width: "41px",
		height: "27px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 13,
		background: '#f3f3f3',
		fontWeight: 700,
		boxSizing: 'border-box',
		marginRight: "11px",
		'& p': {
			fontSize: '0.875rem',
			color: '#787878',
			fontWeight: 400,
			'& span': {
				color: '#38cb89',
				fontWeight: 700
			}
		}
	},
	progressBox: {
		width: "80px",
		'& .MuiLinearProgress-root': {
			height: "6px",
			background: '#eee',
			borderRadius: 3
		},
		'& .MuiLinearProgress-bar': {
			background: '#38cb89'
		}
	},
	progressText: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#111',
			fontWeight: 400,
			marginLeft: "11px",
			'@media all and (max-width: 1550px)': {
				fontSize: '0.75rem',
			},
		}
	},

	popoverBox: {
		'& .MuiPaper-root': {
			width: "120px",
			boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
			border: '1px solid #bbb',
			borderRadius: 6,
			'& ul': {
				padding: '4px 0',
				'& li': {
					color: '#6f6f6f',
					letterSpacing: '-0.5px',
					display: 'flex',
					justifyContent: 'center',
					'& .MuiTypography-root': {
						marginLeft: "6px",
						fontSize: '0.875rem',
					},
					'&:hover': {
						background: '#eee'
					},
				},
			},
			'& *': {
				fontFamily: 'Pretendard-Regular !important',
			},
		}
	},
	deleteText: {
		'&.MuiTypography-root': {
			color: 'red'
		},
	},




	taskOpenDialog: {
		'& .MuiPaper-root': {
			width: "785px",
			height: '90vh',
			borderRadius: '15px'
		},
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 785,
		}
	},

	dialogTop: {
		borderBottom: '1px solid #CBCBCB',
		height: "70px",
		'& .MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			marginLeft: "20px",
		},
		'& .MuiIconButton-root': {
			marginRight: "20px",
		}
	},
	dialogContent: {
		padding: '0px 20px 30px 20px',
		overflowY: 'auto',
		marginBottom: "30px",
	},
	dialogHalf: {
		width: '50%',
	},
	dialogTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			marginBottom: "20px",
			marginTop: "30px",
			'& span': {
				fontSize: '1rem',
				fontWeight: 'normal',
				color: '#555'
			}
		}
	},
	dialogSubTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	dialogTime: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 'bold',
			marginLeft: "40px",
			marginRight: 10.
		}
	},

	dialogBtnGroup: {
		marginTop: "30px",
		marginBottom: "30px",
	},
	dialogBtn: {
		'&.MuiButtonBase-root': {
			border: '1px solid #CBCBCB',
			width: "120px",
			height: "40px",
		},
		'&.MuiButton-root:hover': {
			background: '#fff'
		},
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	dialogBtnSave: {
		'&.MuiButtonBase-root': {
			border: '1px solid #2F80ED',
			background: '#2F80ED',
			width: "120px",
			height: "40px",
			marginLeft: "15px",
		},
		'&.MuiButton-root:hover': {
			background: '#2F80ED'
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	dialogYear: {
		position: 'relative',
		marginRight: "20px",
		'& .MuiFormControl-root': {
			width: '110px',
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 64
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},
	yearSelectIcon: {
		position: 'absolute',
		right: 0,
		top: 5,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	iconYearSelect: {
		'&.MuiIconButton-root': {
			margin: '4px 5px',
		}
	},

	dateBox: {
		'& .MuiStack-root': {
			paddingTop: 0,
			// paddingBottom: 28,
			display: 'flex',
			alignItems: 'center',
			overflow: 'unset'
		},
		'& .MuiFormControl-root': {
			minWidth: '145px !important',
			maxWidth: '145px !important',
			margin: 0
		},
		'& .MuiInputBase-root': {
			paddingRight: 0,
			borderRadius: 6
		},
		'& .MuiInputBase-input': {
			height: "30px",
			boxSizing: 'border-box',
			fontSize: '0.875rem',
			color: '#323232',
			letterSpacing: '-0.5px',
			fontWeight: 500,
			padding: '0 11px'
		},
		'& .MuiInputAdornment-root': {
			marginLeft: "0px",
			'& .MuiButtonBase-root': {
				padding: 0,
				marginRight: "5px",
				'&:hover': {
					background: 'transparent'
				}
			},
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 'solid 1px #bbbbbb',
			borderRadius: 6
		},
	},
	calendarBox: {
		cursor: 'pointer',
		width: "23px",
		height: "23px",
		marginRight: 5
	},
	waveText: {
		'&.MuiTypography-root': {
			marginLeft: "10px",
			marginRight: "10px",
		}
	},

	subSideMenu: {
		'& .MuiStepIcon-root.MuiStepIcon-active': {
			color: '#025949'
		},
		'& .MuiStepIcon-root.MuiStepIcon-completed': {
			color: '#025949'
		},
		'& .MuiStepIcon-root': {
			color: '#DDDDDD'
		},
		'& .MuiStepConnector-vertical': {
			padding: '0px 0 2px',
			marginLeft: "6px",
		},
		'& .MuiStepLabel-root': {
			'& .MuiStepLabel-vertical': {

			}
		},
		'& .MuiStepper-vertical': {
			padding: '24px 0px 24px 24px'
		},
		'& .Mui-disabled': {
			'& rect': {
				fill: '#ddd'
			}
		},
		'& .MuiStepLabel-labelContainer': {
			textAlign: 'left'
		},

	},
	nameSearchBtn: {
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#787878',
				marginLeft: "5px",
			}
		}
	},

	sortButton: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555',
			marginRight: "4px",
			letterSpacing: '-0.5px'
		}
	},
	urlInputBox: {
		'&.MuiPaper-root': {
			width: "30%",
			height: "35px",
			border: '1px solid #CDCDCD;',
			boxShadow: 'none',
			borderRadius: 0,
		},
		'& .MuiInputBase-root': {
			width: "150px",
		},
		'& .MuiInputBase-input': {
			padding: '8px 10px 7px',
			fontSize: '0.875rem',
			fontWeight: 700,
			color: '#787878'
		}
	},
	urlBtn: {
		marginLeft: "10px",
		'&.MuiButtonBase-root': {
			width: "82px",
			height: "27px",
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				letterSpacing: '-0.5px',
			}
		}
	},
	urlBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#fff'
			}
		}
	},
	tableNoBox: {
		paddingLeft: '20px !important',
	},
	manageBtnList: {
		'& .MuiIconButton-root': {
			marginRight: "20px",
		}
	},
	stepIconCont: {
		display: 'flex',
		alignItems: 'center',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		// width: "370px",
	},
	stepIconBox: {
		display: 'flex',
		alignItems: 'center',
		marginRight: "26px",
		position: 'relative',
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#cbcbcb',
			lineHeight: 'normal',
			fontWeight: 600,
			letterSpacing: '-0.5px',
			marginLeft: "6px",
			maxWidth: 62,
		},
		'&:after': {
			content: "''",
			width: '10px',
			height: "1px",
			borderTop: '1px solid #CBCBCB',
			display: 'inline-block',
			position: 'absolute',
			right: '-18px',
			top: 7,
		},

	},
	stepIconBoxList: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#cbcbcb',
			lineHeight: 'normal',
			fontWeight: 600,
			letterSpacing: '-0.5px',
			marginLeft: "6px",
		},
	},
	stepIconActiveBox: {
		'& .MuiTypography-root': {
			color: '#03BF69',
		}
	},
	tooltipStepIconCont: {
		display: 'flex',
		alignItems: 'center',
		width: "510px",
	},
	popoverTooltip: {
		'& .MuiPopover-paper': {
			background: '#fff',
			color: '#fff',
			width: "510px",
			padding: '16px 20px',
			marginTop: "15px",
			boxSizing: 'border-box',
			boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.1)'
		},
	},
	tooltipTop: {
		position: 'absolute',
		top: -10,
		left: '47%',
		color: '#000'
	},
	tooltipBtn: {
		'& .MuiButtonBase-root': {
			padding: 0,
			minWidth: 'auto',
			'&:hover': {
				background: 'transparent'
			}
		}
	},
	tooltipTitle: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '1.125rem',
			letterSpacing: '-0.5px',
			fontWeight: 700,
			marginBottom: "5px",
		}
	},
	tooltipText: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '0.938rem',
			letterSpacing: '-0.5px',
			marginBottom: "10px",
		}
	},
	overTooltip: {
		'&.MuiTooltip-popper': {
			background: 'red'
		}
	},
	popover: {
		'& .MuiPopover-paper': {
			boxShadow: '0px 1px 1px -1px rgba(255,255,0,0.1)',
			border: '0.5px solid #F3A50E',
			borderRadius: 0,
			marginTop: "5px",
		},
		'& .MuiTypography-root': {
			background: '#F4D219',
			color: '#111',
			fontSize: '0.75rem',
		}
	},

	basicTitleBox: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: 890,
		margin: "10px 10px 0px 10px"
	},
	dialogTitle2: {
		width: "100px",
		marginRight: "20px",
		color: '#111',
		fontWeight: 700,
	},


	radioGroupBtn: {
		'& .MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 400,
			color: '#555',
			letterSpacing: '-0.5px',
			marginRight: "45px",
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#2F80ED'
		},
		'& .MuiRadio-colorSecondary': {
			padding: 0,
			marginRight: "5px",
		},
		'& .MuiFormControlLabel-root': {
			marginLeft: "0px",
		}
	},

	dialogHalf2: {
		width: '100%',
		margin: 5
	},
	selectBoxWidth: {
		width: "200px",
		marginRight: "20px",
		position: 'relative',
		'& .MuiFormControl-root': {
			width: "210px",
			// minHeight: 20,
		}
	},
	centerDialog: {
		width: "50%",
		height: 'calc(100vh - 120px)',
		'& .MuiDialog-container': {
			position: "relative",
			top: "60px",
			left: 'calc(50%)',
		},
	},

	// kitty
	formControlInner: {},
});