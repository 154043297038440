import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const AnswerSummitStyle: (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
		overflow: 'auto',
		background: '#F2F7F3',
		height: 'calc(100vh - 56px)',
	},
	// emptyBox:{
	//     width: '100%',
	//     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
	//     display:'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center',
	//     '& p':{
	//         fontSize: '1.375rem',
	//         color: '#87888d',
	//         fontWeight:400,
	//         textAlign: 'center',
	//         lineHeight: '30.36px'
	//     }
	// },
	// boxWrap: {
	//     backgroundColor: '#fff',
	//     padding: 20,
	//     borderRadius: 8,
	//     height: 'calc(100vh - 500px)'
	// },

	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			// marginLeft: "10px",
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},

	headerFont: {
		paddingLeft: '5px',
		fontSize: '1rem',
	},


	dialogBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff'
		}
	},


	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},


	stepOpen: {
		'&.MuiButtonBase-root': {
			border: '1px solid #111',
			width: "120px",
			boxSizing: 'border-box',
			background: '#fff',
			color: '#111',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.813rem',
				fontWeight: 'bold',
			}
		}
	},
	projectPrint: {
		'&.MuiButtonBase-root': {
			border: '1px solid rgb(3,123,108)',
			color: 'rgb(3,123,108)',
		}
	},
	onlineSolve: {
		'&.MuiButtonBase-root': {
			border: '1px solid rgb(3,123,108)',
			color: '#ffffff',
			background: 'rgb(3,123,108)',
		}
	},


	/////////////////////
	padding: {
		// padding: 15,
	},
	mainContentBox: {
		// border: '1px solid #CBCBCB',
		// padding: 20,
		// overflow: 'auto',
		height: 'auto',
		background: '#fff',
		// padding: '0px 10%',
		maxWidth: "1000px",
		position: 'relative',
		// margin: '0px 15px',
		margin: "20px 0px",
		padding: "35px 30px",
		// paddingBottom:"10px",
		minHeight: 'calc(100vh - 170px)',
	},

	// mainContentBoxLef: {
	//     border: '1px solid #CBCBCB',
	//     padding: 20,
	//     height: 'calc(100vh - 130px)',
	//     overflowY: 'auto',
	//     background: '#fff',
	//     width: 'calc(100vw - 770px)',
	// },
	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		overflowY: 'auto',
		background: '#fff',
		width: '620px',
		boxSizing: 'border-box',
		marginLeft: "10px",
	},


	commonSubTitle: {
		marginBottom: "10px",
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
		}
	},
	schoolNameSubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			letterSpacing: '-0.5px',
			marginBottom: "15px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	searchSchoolInput: {
		'& .MuiFormControl-root': {
			maxWidth: 830,
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 50
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
		}
	},




	greenBtn: {
		marginLeft: "15px",
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008c73',
				fontWeight: 'bold'
			}
		}
	},
	selectText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 'bold',
			marginRight: "15px",
		}
	},

	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#111',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},



	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: '#fff',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
			marginTop: "3px",
		}
	},
	pageNumber: {
		marginTop: "20px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentName: {
		'&.MuiTypography-root': {
			color: '#008C73'
		}
	},
	tableBox: {
		marginTop: "30px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1rem',
				color: '#323232',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '1rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},
	tableBox2: {
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1.5rem',
				color: '#323232',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 2px',
				boxSizing: 'border-box',
				height: "45px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '1.5rem',
				color: '#323232',
				height: "45px",
				letterSpacing: '-0.5px',
				padding: '0 2px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},
	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},

	dialogInput: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
			height: "440px",
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "200px",
		},
	},
	stepInputBox: {
		'& .MuiIconButton-root': {
			margin: '20px 10px 5px 40px'
		}
	},

	inputStep: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 36
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
			letterSpacing: '-0.5px'
		}
	},
	inputStepMulti: {
		'& .MuiOutlinedInput-root': {
			height: "60px",
		},
		'& .MuiOutlinedInput-input': {
			height: "38px",
		}
	},
	stepText: {
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			color: '#2F80ED',
			marginRight: "20px",
		}
	},

	addSentence: {
		'& .MuiButton-label': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: 'transparent'
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontSize: '0.875rem',
				marginTop: "2px",
			}
		}
	},

	boxList: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FAFAFA',
		borderRadius: 6,
		width: '100%',
		height: "200px",
		margin: '0px 5px',
		padding: 10,
		'& .MuiTypography-root': {
			color: '#6F6F6F',
			fontSize: '0.75rem',
			minHeight: 93,
		},
		'&:hover': {
			outline: '2px solid #56CCF2'
		}
	},
	boxListTitle: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 700,
			marginTop: "5px",
			marginBottom: "5px",
			minHeight: 'auto'
		}
	},
	fileUploadBox: {
		marginTop: "30px",
		background: '#E0E0E0',
		height: 'calc(100vh - 190px)',
		overflowY: 'auto',
		position: 'relative'
	},
	boardSize: {
		position: 'absolute',
		right: 10,
		bottom: 10,
		display: 'flex',
		flexDirection: 'column'
	},
	fileUploadTitle: {
		marginTop: '-18px',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 500,
			marginBottom: "20px",
		}
	},
	fileUploadText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	fileUploadBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			width: "170px",
			height: "36px",
			borderRadius: 3,
			border: '1px solid #CBCBCB',
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	fileUploadSubText: {
		margin: '15px 0px'
	},
	urlInputBox: {
		'&.MuiPaper-root': {
			width: "600px",
			height: "35px",
			border: '1px solid #CBCBCB',
			marginBottom: "15px",
		},
		'& .MuiInputBase-root': {
			width: "480px",
		},
		'& .MuiInputBase-input': {
			padding: '8px 10px 7px',
		}
	},
	urlBtn: {
		marginLeft: "10px",
		'&.MuiButtonBase-root': {
			width: "82px",
			height: "27px",
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				letterSpacing: '-0.5px',
			}
		}
	},
	urlBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#fff'
			}
		}
	},

	summaryAiBtn: {
		'&.MuiButtonBase-root': {
			background: '#CDCDCD',
			width: '100%',
			marginBottom: "20px",
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold',
			}
		}
	},
	summaryAiBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				color: '#fff',
			}
		}
	},
	aiSubText: {
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
		}
	},
	sideList: {
		position: 'absolute',
		left: 0,
		top: 0,
		background: '#4F4F4F',
		width: "114px",
		height: 'calc(100vh - 190px)',
		// height: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	sideListInner: {
		overflowY: 'auto',
		padding: '10px 7px',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	img: {
		position: 'relative',
		'& img': {
			width: '100%',
			borderRadius: '5px',
			'&:hover': {
				outline: '2px solid #03BF69'
			},
		},

	},
	examInfoIcon: {
		marginLeft: "10px",
		marginBottom: "13px",
	},
	pageNum: {
		position: 'absolute',
		bottom: 5,
		background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.6), #fff)',
		width: '100%',
		borderRadius: '0 0 5px 5px',
		'& .MuiTypography-root': {
			padding: '4px 5px',
			textAlign: 'right'
		}
	},
	newTabHeader: {
		borderBottom: '1px solid #cbcbcb',
		padding: 20,
	},
	newTabContent: {
		padding: '29px 20px 20px 20px',
		background: '#fafafa'
	},
	newTabTitle: {
		'&.MuiTypography-root': {
			color: '#555',
			marginRight: "14px",

		}
	},
	newTabHeaderTitle: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.125rem',
			letterSpacing: '-1px',
			fontWeight: 700,
			marginLeft: "30px",
		}
	},
	newTabName: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.125rem',
			letterSpacing: '-1px'
		}
	},
	newTabTitlePointer: {
		marginLeft: "25px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-13px',
			top: 10,
		},
	},
	newTabSubject: {
		'&.MuiTypography-root': {
			fontWeight: 600
		}
	},
	newTabPage: {
		'&.MuiTypography-root': {
			color: '#008C73',
			fontWeight: 600,
		}
	},
	titleBox: {
		background: '#F3F3F3',
		fontSize: "0.8em",
		'&.MuiTableCell-root': {
			fontWeight: 700,
		}
	},
	amountScoreTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			marginTop: "20px",
		}
	},
	amountScore: {
		width: "170px",
		padding: '0px',
		marginLeft: "10px",
		'& .MuiTypography-root': {
			fontSize: '3.375rem',
			fontWeight: 500,
			color: '#787878',
			'& span': {
				color: '#008C73',
				fontWeight: 700,
			},
		},
	},
	amountScoreText: {
		fontSize: '1.25rem',
		color: '#787878 !important',
		marginLeft: "5px",
	},
	compareContent: {
		// padding: 20,
		// height: 'calc(100vh - 224px)',
		// overflowY: 'auto',
		marginTop: "20px",
	},
	compareRound: {
		border: '1px solid #cbcbcb',
		borderRadius: 10,
		padding: 20,
		marginBottom: "20px",
		background: 'rgba(233, 243, 252, 0.10)',
		// height: 'calc(100vh - 360px)',
		// overflowY: 'auto'
	},
	textArticleNumber: {
		paddingTop: '20px',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 500,
		}
	},
	textDirective: {
		paddingTop: '35px',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 500,
		}
	},
	textArticleTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 700,
		}
	},
	textArticle: {
		border: '1px solid #111',
		borderRadius: 3,
		'& .MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 500,
		}
	},
	textArticleReference: {
		textAlign: 'right',
		'& .MuiTypography-root': {
			color: '#787878',
			fontSize: '0.875rem',
		}
	},
	questionList: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			fontWeight: 700,
			letterSpacing: '-0.5px'
		}
	},
	questionGuideText: {
		border: '1px solid #111',
		padding: '14px 18px',
		borderRadius: 3,
		marginTop: "10px",
		'& .MuiTypography-root': {
			fontSize: '1.25rem',
			letterSpacing: '-0.4px'
		}
	},
	bestAnswerList: {
		marginTop: "20px",
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			letterSpacing: '-1px',

		}
	},

	greenBorderBox: {
		border: '4px solid #008C73',
		borderRadius: 10,
		padding: 20,
	},
	bgColor: {
		height: '140px !important',
		background: '#DFDFDF',
		borderRight: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},
	rubricText: {
		'&.MuiTypography-root': {
			fontWeight: 400,
			fontSize: '0.875rem',
			textAlign: 'center',
			'& span': {
				fontSize: '1.3rem',
				fontWeight: 700,
				marginRight: "3px",
			}
		}
	},
	markText: {
		'&.MuiTypography-root': {
			fontWeight: 400,
			fontSize: '0.875rem',
			textAlign: 'center',
			'& span': {
				fontSize: '2rem',
				fontWeight: 700,
				marginRight: "3px",
			}
		}
	},
	markTextColor: {
		'&.MuiTypography-root': {
			'& span': {
				color: '#008C73'
			}
		}
	},
	tableText: {
		// height: "120px",
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#323232',
			letterSpacing: '-0.5px',
			padding: '0 5px',
			// overflow:'hidden',
			// textOverflow:'ellipsis',
			whiteSpace: 'break-spaces',
			overflow: 'auto',
			textOverflow: 'ellipsis',
		}
	},
	greenInnerBox: {
		marginTop: "80px",
		border: '2px solid #008C73',
		background: '#E9F8F5',
		borderRadius: 10,
		padding: '20px 40px 40px 40px',
		position: 'relative',
	},
	noResultMark: {
		background: '#fff',
	},
	noResultBox: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 490,
		'& .MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 700,
			letterSpacing: '-0.2px',
			color: '#111',
			marginTop: "24px",
		}
	},
	noResultSub: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 400,
			letterSpacing: '-0.5px',
			color: '#555',
		}
	},
	redInnerBox: {
		border: '2px solid #EB5757',
		background: '#fff',
	},
	tagNameBox: {
		position: 'absolute',
		top: '-38px',
		left: 10,
		background: '#008C73',
		borderRadius: '10px 10px 0 0',
		padding: '8px 30px',
		'& .MuiTypography-root': {
			color: '#fff',
			fontWeight: 700,
			fontSize: '0.875rem'
		}
	},
	tagNameBoxRed: {
		background: '#EB5757',
	},
	tagBtnView: {
		position: 'absolute',
		top: '-38px',
		right: '-10px',
		'& .MuiButtonBase-root': {
			background: '#fff',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 500,
				color: '#555',
				marginRight: "5px",

			}
		}
	},
	bgColorGreen: {
		background: '#008C73 !important',
		color: '#fff !important'
	},
	tableTextGreen: {
		'&.MuiTypography-root': {
			color: '#008C73'
		}
	},
	greenBoxTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',

		}
	},
	slider: {

	},
	progressBox: {
		maxWidth: 440,
		width: '100%',
		position: 'relative',
		'& .MuiLinearProgress-root': {
			height: "16px",
			background: '#eee',
			borderRadius: 99,
		},
		'& .MuiLinearProgress-bar': {
			background: '#EB5757',
			borderRadius: 99,
		}
	},
	progressLeftText: {
		position: 'absolute',
		top: '-20px',
		left: 0,
		'&.MuiTypography-root': {
			color: '#787878',
			fontSize: '0.813rem'
		}
	},
	progressRightText: {
		position: 'absolute',
		top: '-20px',
		right: 0,
		'&.MuiTypography-root': {
			color: '#787878',
			fontSize: '0.813rem'
		}
	},
	progressGuide: {
		position: 'absolute',
		'& .MuiTypography-root': {
			color: '#787878',
			fontSize: '0.813rem',
			position: 'relative',
			'&:before': {
				content: "''",
				// width: "1px",
				height: "45px",
				// background:'red',
				// borderRadius: '50%',
				display: 'inline-block',
				position: 'absolute',
				left: '25px',
				top: 22,
				border: '1px dashed #555'
			},
		}
	},
	viewMoreText: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			color: '#111',
			letterSpacing: '-0.5px',
			marginRight: "5px",
		}
	},
	resultBtnToggle: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			marginBottom: "30px",
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
			letterSpacing: '-0.5px'
		}
	},
	redContainer: {
		background: '#FBE3E3',
		borderRadius: 10,
		padding: '17px 18px',
		'& .MuiTypography-root': {
			color: '#111',
			letterSpacing: '-0.5px'
		}
	},
	TaskContainer: {
		border: '1px solid #cbcbcb',
		borderRadius: 10,
		padding: '0px 30px 30px 30px',
	},
	TaskContainerInner: {
		border: '3px solid #111',
		borderRadius: 3,
		padding: 10,
		minHeight: 200,
	},
	pageUpIcon: {
		position: 'fixed',
		bottom: '10px',
		right: 0,
		'&.MuiIconButton-root': {
			border: '1px solid #fff',
			background: '#fff',
			boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
			width: "40px",
			height: "40px",
			'&:hover': {
				background: '#fff'
			}

		}
	},
	borderGuide: {
		marginBottom: "30px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: '500px',
			height: "1px",
			borderTop: '1px dashed #cbcbcb',
			display: 'inline-block',
			position: 'absolute',
			right: '55px',
			top: 20,
		},
		'&:after': {
			content: "''",
			width: '500px',
			height: "1px",
			borderTop: '1px dashed #cbcbcb',
			display: 'inline-block',
			position: 'absolute',
			left: '55px',
			top: 20,
		},
	},
	borderGuideText: {
		marginTop: "30px",
		marginBottom: "30px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: '500px',
			height: "1px",
			borderTop: '1px dashed #cbcbcb',
			display: 'inline-block',
			position: 'absolute',
			right: '100px',
			top: 10,
		},
		'&:after': {
			content: "''",
			width: '500px',
			height: "1px",
			borderTop: '1px dashed #cbcbcb',
			display: 'inline-block',
			position: 'absolute',
			left: '100px',
			top: 10,
		},
		'& .MuiTypography-root': {
			fontSize: '0.813rem',
			color: '#787878'
		}
	},
	saveLineMarkBtn: {
		'& .MuiButtonBase-root': {
			width: "110px",
			height: "37px",
			background: '#fff',
			border: '1px solid #888',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#888'
			}
		}
	},
	saveLineMarkBtnActive: {
		'& .MuiButtonBase-root': {
			border: '1px solid #111',
			'& .MuiTypography-root': {
				color: '#111',
				fontWeight: 700,
			}
		}
	},
	applyMarkBtn: {
		'& .MuiButtonBase-root': {
			width: "110px",
			height: "37px",
			background: '#cbcbcb',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#888'
			}
		}
	},
	applyMarkBtnActive: {
		'& .MuiButtonBase-root': {
			background: '#008C73',
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 700,
			}
		}
	},
	titleBoxStudent: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '16px',
		'& .MuiTypography-root': {
			color: '#111',
			fontWeight: 700,
			fontSize: '1.75rem',
		}
	},
	onlyPrint: {
		display: 'none',
	},
	answerSheet: {
		marginTop: "25px",
		marginBottom: "30px",
		marginLeft: "16px",
		marginRight: "16px"
	},
	'@media print': {
		pageDivine: {
			breakAfter: 'page',
		},
		onlyPrint: {
			display: 'block',
			breakBefore: 'page',
		},
		answerSheet: {
			display: 'none',
		},
		iconBtnReset: {
			display: 'none',
		},
		mainContentBox: {
			padding: '0px',
			background: '#fff',
		},
		questionName: {
			margin: 0,
		},
		bestAnswerBox: {
			marginTop: "0px",
		},
		textArticleTitle: {
			'&.MuiTypography-root': {
				fontSize: '1.25rem',
				color: '#111',
				letterSpacing: '-0.5px',
				fontWeight: 700,
				margin: 0,
			}
		},
		textArticle: {
			border: '2px solid #111',
			borderRadius: 3,
			padding: 0,
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#111',
				letterSpacing: '-0.5px',
			}
		},
		tableBox2: {
			'&.MuiPaper-root': {
				borderTop: '1px solid rgba(119, 119, 119, 0.7)',
				boxShadow: 'none',
				borderRadius: 0,
				'& table': {
					tableLayout: 'fixed'
				},
				'& .MuiTableCell-head': {
					fontSize: '1.5rem',
					color: '#323232',
					letterSpacing: '-0.5px',
					fontWeight: 'bold',
					padding: '0',
					boxSizing: 'border-box',
					height: "45px",
					borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
					'& span': {
						fontSize: '0.875rem',
						fontWeight: 'normal'
					},
					'@media all and (max-width: 1550px)': {
						fontSize: '0.875rem',
						'& span': {
							fontSize: '0.688rem',
						}
					},
				},
				'& .MuiTableCell-body': {
					padding: '0',
					boxSizing: 'border-box',
					borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					'@media all and (max-width: 1550px)': {
						fontSize: '0.875rem',
					},
				}
			}
		},
		headerFont: {
			paddingLeft: '3px',
			fontSize: '0.875rem',
		}
	},
	questionName: {
		marginTop: "0px"
	},
	nextTaskPage: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			width: "139px",
			height: "45px",
			border: '1px solid #111',
			borderRadius: 6,
			'& .MuiTypography-root': {
				color: '#111',
				fontWeight: 500,
				fontSize: '1.25rem'
			}
		}
	},
	progressContainer: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		height: "80px",
		marginBottom: "25px"
	},
	flexCenter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	bestAnswerBox1: {
		border: '2px solid #111',
		borderRadius: 3,
		height: "68px",
		overflowY: 'auto',
		'& .MuiTypography-root': {
			padding: '6px 5px',
			fontSize: '1.25rem',
			letterSpacing: '-0.4px'
		}
	},
	bestAnswerBox2: {
		border: '2px solid #111',
		borderRadius: 3,
		height: "200px",
		overflowY: 'auto',
		'& .MuiTypography-root': {
			padding: '6px 5px',
			fontSize: '1.25rem',
			letterSpacing: '-0.4px'
		}
	},

	bestAnswerBoxMuti: {
		height: "720px",
	},

	// kitty
	bestAnswerBox: {
		border: '2px solid #111',
		borderRadius: 3,
		height: "68px",
		overflowY: 'auto',
		'& .MuiTypography-root': {
			padding: '6px 5px',
			fontSize: '1.25rem',
			letterSpacing: '-0.4px'
		}
	},

	solveButton: {
		'& .MuiTypography-root': {
			fontSize: '0.813rem',
			fontWeight: 'bold',
		}
	},
});












