

// A list of RegExp objects to check function names against
export const logConf = {
  logFilters: [] as RegExp[]
};

export function logCall(id, funcName, args) {
  for (const filter of logConf.logFilters) {
    if (filter.test(funcName)) {
      console.log(`(${id}) CALL ${funcName}:`, args);
      return;
    }
  }
}

export function logReturn(id, funcName, value) {
  for (const filter of logConf.logFilters) {
    if (filter.test(funcName)) {
      console.log(`(${id}) RETURN ${funcName}:`, value);
      return;
    }
  }
}


//