

export enum PenTypeEnum {
  NETWORK_PEN = "network",
  REAL_PEN = "real",
  MOUSE_PEN = "mouse",
  STYLUS_PEN = "stylus",
  FINGER_PEN = "finger",
  SDK_PEN = "from_sdk",
  INKSTORE_PEN = "real",
  TESTING = "test",
  MENU_ACTION = "menu",
  OFFLINE_DATA = "real",
}

export type INeoSmartpenType = { type: PenTypeEnum, local: boolean };
