import React from "react";

import {
	Box,
	Paper,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { MarkFeedbackStyle } from "../../../styles/MarkFeedbackStyle";

import { useTranslation } from 'react-i18next';
import TextViewer from "../../../../../components/common/TextViewer";
import { useStores } from "../../../../../hooks/useStores";
import { IExamInfo } from "../../../../../repositories/model/transfer/IExamInfo";
import { IQuestionInfo } from "../../../../../repositories/model/transfer/IQuestionInfo";
import { useCurrentOrder } from "../../../000_Common/001_Functions/useCurrentOrder";
import { useCurrentQuestionHTML } from "../../../000_Common/001_Functions/useCurrentQuestionHTML";

type Props = {
	examInfo: IExamInfo,
	questionInfo: IQuestionInfo,
}

export const QuestionRow = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( MarkFeedbackStyle );
	const { examInfo, questionInfo } = props;
	const { projectExamStore } = useStores();
	// const [rotated, setRotated] = React.useState(false);

	const currentOrder = useCurrentOrder(projectExamStore, examInfo, questionInfo);
	const currentQuestionHTML = useCurrentQuestionHTML(projectExamStore, questionInfo, currentOrder);

	return (
		<>
			<Paper sx={{ mb: 5, padding: "10px" }} variant={"outlined"} square={true}>
				<div style={{ overflow: "hidden" }}>
					<Box sx={{ marginTop: "10px" }}>
						<Box className={classes.lightQuestionSentence}>
							<TextViewer
								currentHTML={currentQuestionHTML}
							/>
						</Box>
					</Box>
				</div>
				<Box className={classes.lightBorderTaskBox}>
					<Typography>
						{i18next.t(questionInfo.answer.answer)}
					</Typography>
				</Box>
			</Paper>
		</>
	)
};
