const useWorkerConfig = {
  use: true,
  numWorkers: 1
};

export function setUsePdfWorkers(use: boolean) {
  useWorkerConfig.use = use;
}


function isSafari() {
  let isWKWebView = false;
  if (navigator.platform.substr(0, 2) === 'iP') {    // iOS detected
    if ((window as any).webkit && (window as any).webkit.messageHandlers) {
      isWKWebView = true;
    }
  }

  return isWKWebView;
}


export const getNumWorkers = () => {
  // const performance = window.performance || (window as any).mozPerformance || (window as any).msPerformance || (window as any).webkitPerformance || {};
  const cpus = window.navigator.hardwareConcurrency || 2;
  let numWorkers = Math.floor(cpus / 2) ;
  if ( numWorkers < 1 ) numWorkers = 1;

  // FIXME: MAC에서 동작확인하고 되돌릴 것, 위의 내용으로
  const isMAc = isSafari();
  if (isMAc) {
    numWorkers = 1;
  }

  useWorkerConfig.numWorkers = numWorkers;

  if (useWorkerConfig.use)
    return useWorkerConfig.numWorkers;

  return 1;
}

export const setNumWorkers = (numWorkers: number) => {
  useWorkerConfig.numWorkers = numWorkers;
}

export const getUseWorkers = () => {
  return useWorkerConfig.use;
}
