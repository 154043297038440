class DateTimeUtils {

  static formatStringDigits(value, digitLength) {
    if (value === undefined) return;

    if (typeof value !== 'string') {
      return value.toString().padStart(digitLength, '0');

    } else {
      return value.padStart(digitLength, '0');
    }
  };

}

export { DateTimeUtils };
