import { sprintf } from 'sprintf-js';
// eslint-disable-next-line import/no-cycle
import SmartPenBase from '../SmartPenBase';
import { IPenManager, NEO_SMARTPEN_TYPE, PenEventName, uuidv4 } from '../../../../nl-lib3-common';

const _mousePenDebugMode = false;
// const NUM_HOVER_POINTERS = 6;

export default class VirtualPen extends SmartPenBase {

	objname = "virtualPen-" + uuidv4();

	static virtualPenMaxPressure = 800;

	static virtualPenMacString = "_MOUSE";

	/**
	 * Creates an instance of VirtualPen.
	 */
	constructor(args: { manager: IPenManager }) {
		const { manager } = args;

		super({ manager, userId: null });
		console.log(_mousePenDebugMode);

		this.maxPenPressureValue = VirtualPen.virtualPenMaxPressure;
		this.inputType = VirtualPen.getInputType();
		this.connected = true;

		const { color } = this.manager;
		for (let i = 0, l = this.penState.length; i < l; i++) {
			this.penState[i] = {
				thickness: 0.2,
				color,
			};
		}

		const { mac, webBluetoothId } = VirtualPen.generateVirualPenMacAndId();
		this.mac = mac;
		this.webBluetoothId = webBluetoothId;
	}

	static generateVirualPenMacAndId() {
		const penMacString = VirtualPen.virtualPenMacString;

		const mac = sprintf(
			"%02x:%02x:%02x:%02x:%02x:%02x",
			penMacString.charCodeAt(0),
			penMacString.charCodeAt(1),
			penMacString.charCodeAt(2),
			penMacString.charCodeAt(3),
			penMacString.charCodeAt(4),
			penMacString.charCodeAt(5)
		);
		const webBluetoothId = "mouse-" + mac;

		return { mac, webBluetoothId };
	}

	static getInputType() {
		return NEO_SMARTPEN_TYPE.MOUSE_PEN;
	}


	public getMac() { return this.mac; }

	/**
	 *
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async connect(): Promise<{ success: boolean, reason: string }> {
		// this.manager.onConnected({ pen: this, event });
		this.dispatcher.dispatch(
			PenEventName.ON_CONNECTED,
			{
				inputType: this.inputType,
				pen: this,
				mac: this.mac,
				event: undefined
			}
		);

		return { success: true, reason: "" };
	}

	async disconnect() {
		this.dispatcher.dispatch(
			PenEventName.ON_DISCONNECTED,
			{
				inputType: this.inputType,
				pen: this,
				mac: this.getMac(),
			}
		);

		// throw new Error(`disconnecting virtual pen has not been implemented.`);
	}
}
