


import React from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useStores } from "../../../../hooks/useStores";


type ReCaptchaProps = {
	setRefreshReCaptcha: any,
};

export const YourReCaptchaComponent = (props: ReCaptchaProps) => {
	const { authStore } = useStores();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleReCaptchaVerify = React.useCallback(async () => {
		try {
			if (!executeRecaptcha) {
				console.log('Execute recaptcha not yet available');
				return;
			}
			const token = await executeRecaptcha();
			authStore.verifyCaptcha(token);
		} catch (e) {
			console.log("error", e);
			props.setRefreshReCaptcha(prev => !prev);
		}
	}, [executeRecaptcha]);

	React.useEffect(() => {
		handleReCaptchaVerify();
	}, [handleReCaptchaVerify]);

	return <button style={{ display: 'none' }} onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
};