
import {
	Box,
	Typography
} from "@mui/material";
import { ResultReviewStyle } from "../../styles/ResultReviewStyle";
import useEmotionStyles from "../../styles/useEmotionStyles";

import { useTranslation } from 'react-i18next';
import TextViewer from "../../../../components/common/TextViewer";

import { observer } from "mobx-react";
import { ProjectExamType } from "../../../../repositories/model/support/ProjectExamType";
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";
import { useCurrentParagraphHTML } from "../../000_Common/001_Functions/useCurrentParagraphHtml";
import { useIsParagraphAndSourceBoxEmpty } from "../../000_Common/001_Functions/useIsParagraphAndSourceBoxEmpty";
import { QuestionRow } from "./QuestionRow";

type Props = {
	examInfo: IExamInfo;
	questionList: IQuestionInfo[];
	examOrderAndQuestionsLengthMap: any;
};


export const ExamRow = observer((props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( ResultReviewStyle );
	const { examInfo, questionList, examOrderAndQuestionsLengthMap } = props;

	// const currentParagraphHTML = React.useMemo(() => {
	// 	if (!examInfo.paragraph && !examInfo.source) return "<></>";
	// 	const sourceFontSize = "0.75rem";
	// 	const sourceColor = "#787878";
	// 	const source = examInfo.source.replace(/\n/g, "<br>");
	// 	return "<p>" + examInfo.paragraph + "\n\n" + `<div style='margin-top: 15px; color: ${sourceColor};font-size: ${sourceFontSize}; text-align: right'>` + source + '</div>' + "</p>";
	// }, [examInfo.paragraph, examInfo.source]);
	const currentParagraphHTML = useCurrentParagraphHTML({ examInfo });

	// const isParagraphAndSourceBoxEmpty = React.useMemo(() => {
	// 	const emptyParagraph = !examInfo.paragraph || (DataUtils.getTagArrFromHtml(examInfo.paragraph, 'img').length === 0 && !DataUtils.getTextFromHtml(examInfo.paragraph));
	// 	const emptySource = !examInfo.source;

	// 	return emptyParagraph && emptySource;
	// }, [examInfo.paragraph, examInfo.source]);
	const isParagraphAndSourceBoxEmpty = useIsParagraphAndSourceBoxEmpty(examInfo);

	const submitType = (type) => {
		switch (type) {
			case ProjectExamType.DESCRIPTIVE: return i18next.t("서술형");
			case ProjectExamType.ESSAY: return i18next.t("논술형");
			default: return "";
		}
	};

	return (
		<>
			<Box className={classes.compareContent}>
				<Box className={classes.textArticleNumberBox}>
					<Typography className={classes.textArticleNumber}>{examInfo.order}{i18next.t('번 문항')} ({submitType(examInfo.type)})</Typography>
				</Box>

				<Box className={classes.compareRound}>
					{examInfo.directive &&
						<Typography className={classes.textArticleTitle}>{examInfo.directive}</Typography>
					}

					{!isParagraphAndSourceBoxEmpty && (
						<Box className={classes.textArticle}>
							<TextViewer
								currentHTML={currentParagraphHTML}
							/>
						</Box>
					)}

					{questionList.map((question, idx) =>
						<QuestionRow
							key={idx}
							idx={idx}
							// examInfo={examInfo}
							questionInfo={question}
							examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
						/>
					)}
				</Box>
			</Box>
		</>
	);
});
