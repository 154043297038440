import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import clsx from "clsx";
import i18next from "i18next";
import React from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as SideBarToggleIcon } from "../../../common/images/SideBarToggleIcon.svg";
import { SideBarStyle } from "../styles/SideBarStyle";
import { HtmlTooltip } from "../Tooltip";
import useEmotionStyles from "../../main/styles/useEmotionStyles";

type Props = {
	onClick: () => void;
	icon: React.ReactNode;
	expanded: boolean;
	menu: string;
	urlPrefix: string;
	disabled?: boolean;
	child?: React.ReactNode;
};


function MenuItemComponent(props: Props) {
	const classes = useEmotionStyles( SideBarStyle );
	const { pathname } = useLocation();

	const [childExpand, isChildExpand] = React.useState(true);
	const { icon, expanded, menu, urlPrefix, disabled, child, } = props;

	const handleClick = () => {
		if (child) isChildExpand(!childExpand);
		props.onClick();
	}

	const menuLocalized = i18next.t(menu);

	return (
		<>
			<ListItem className={classes.subSideMenuList} disablePadding>

				<ListItemButton className={pathname.includes(urlPrefix) ? clsx(classes.listButton, classes.listButtonActive) : classes.listButton}
					onClick={handleClick}
					disabled={disabled}
					disableRipple>
					<HtmlTooltip title={menuLocalized} disableHoverListener={expanded}>
						<ListItemIcon className={classes.listIconSideBar}>
							{icon}
						</ListItemIcon>
					</HtmlTooltip>

					{expanded &&
						<>
							<ListItemText
								primary={menuLocalized}
								style={{
									whiteSpace: "nowrap",
									fontFamily: 'Pretendard-Regular !important',
								}} />
							{child ?
								<IconButton
									// className={clsx(classes.iconBtnReset, classes.iconMenuToggle)}
									className={clsx(classes.iconBtnResetSideBar)}
									style={{
										transform: !childExpand ? "rotate(180deg)" : "none",
										transition: "transform 0.3s ease"
									}}
									disableRipple
								>
									<SideBarToggleIcon />
								</IconButton>
								: null
							}
						</>
					}
				</ListItemButton>

			</ListItem>
			{child && childExpand ? child : null}
		</>
	);
}

export default MenuItemComponent;