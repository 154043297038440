
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import React from "react";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { StudentResultStyle } from "../../../styles/StudentResultStyle";


import clsx from "clsx";


import { useTranslation } from 'react-i18next';
import { RubricItemRow } from "./RubricItemRow";
import { IRubricInfo } from "../../../../../repositories/model/transfer/IRubricInfo";

type Props = {
	rubrics: IRubricInfo[];
}

export const RubricTable = (props: Props) => {
	const classes = useEmotionStyles( StudentResultStyle );
	const { rubrics } = props;
	const i18next = useTranslation();

	return (
		<TableContainer component={Paper} className={clsx(classes.tableBox2, classes.tableBoxMarginTop)}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell width='25%' align="center">{i18next.t("평가항목")}</TableCell>
						<TableCell width='20%' align="center" colSpan={2} className={classes.scoreCell}>{i18next.t("배점")}</TableCell>
						<TableCell width='55%' align="center">{i18next.t("채점 기준")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rubrics.map((rubricInfo, idx) =>
						<React.Fragment key={idx}>
							{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
							<TableRow >
								<TableCell width='17%' rowSpan={rubricInfo.items?.length + 1 || 0} align="center" className={classes.rubricCellBgColor}>
									<Box display='flex' flexDirection='column' alignItems='center'>
										<Typography>{rubricInfo.name}</Typography>
									</Box>
								</TableCell>
							</TableRow>
							{rubricInfo?.items.length > 0 &&
								rubricInfo.items.map((item, idx) =>
									<RubricItemRow key={idx} itemInfo={item} />
								)}
						</React.Fragment>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
