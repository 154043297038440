import { PdfPage } from "../../emscripten-wrapper/mupdf-wrapper";
import { SelectedAnnotation } from "./SelectedAnnotation";

export let currentSelection = null;

function findAnnotationAtPos(pdfPage, x, y) {
  // using Array.findLast would be more elegant, but it isn't stable on
  // all major platforms
  const {annotations} = pdfPage.getAnnotations();
  for (let i = annotations.length - 1; i >= 0; i--) {
    const annotation = annotations[i];
    const bbox = annotation.bound();
    if (x >= bbox.x0 && x <= bbox.x1 && y >= bbox.y0 && y <= bbox.y1) {
      // TODO - remove this if
      if (annotation.hasRect())
        return annotation;
    }
  }
  return null;
}

export class SelectAnnot {
  initial_x;

  initial_y;

  hovered;

  pdfPage: PdfPage;

  pageIndex: number;

  constructor() {
    this.initial_x = null;
    this.initial_y = null;
    this.hovered = null;
    this.pdfPage = null;
    this.pageIndex = null;
  }

  resetPage(pdfPage, pageIndex) {
    this.pdfPage = pdfPage;
    this.pageIndex = pageIndex;
    currentSelection = null;
  }

  mouseDown(x, y) {
    const clickedAnnotation = findAnnotationAtPos(this.pdfPage, x, y);
    const selectionChanged = (currentSelection?.annotation !== clickedAnnotation);

    if (clickedAnnotation != null) {
      currentSelection = new SelectedAnnotation(
        clickedAnnotation,
        x, y
      );
      this.initial_x = x;
      this.initial_y = y;
    }
    else {
      currentSelection = null;
    }

    return selectionChanged;
  }

  mouseDrag(x, y) {
    if (currentSelection == null)
      return false;

    return currentSelection?.mouseDrag(x, y);
  }

  mouseMove(x, y) {
    const prevHovered = this.hovered;
    this.hovered = findAnnotationAtPos(this.pdfPage, x, y);
    return prevHovered?.pointer !== this.hovered?.pointer;
  }

  mouseUp(_x, _y) {
    // do nothing
  }

  drawOnPage(pixmap, dpi) {
    if (this.hovered != null) {
      const rect = this.hovered.bound();
      pixmap.drawGrabHandle(rect.x0 * dpi / 72, rect.y0 * dpi / 72);
      pixmap.drawGrabHandle(rect.x0 * dpi / 72, rect.y1 * dpi / 72);
      pixmap.drawGrabHandle(rect.x1 * dpi / 72, rect.y0 * dpi / 72);
      pixmap.drawGrabHandle(rect.x1 * dpi / 72, rect.y1 * dpi / 72);
    }
    if (currentSelection != null) {
      const rect = currentSelection.annotation.bound();
      pixmap.drawGrabHandle(rect.x0 * dpi / 72, rect.y0 * dpi / 72);
      pixmap.drawGrabHandle(rect.x0 * dpi / 72, rect.y1 * dpi / 72);
      pixmap.drawGrabHandle(rect.x1 * dpi / 72, rect.y0 * dpi / 72);
      pixmap.drawGrabHandle(rect.x1 * dpi / 72, rect.y1 * dpi / 72);
    }
  }

  deleteItem() {
    if (currentSelection?.annotation) {
      this.pdfPage.deleteAnnotation(currentSelection?.annotation);
      return true;
    }

    return false;
  }
}
