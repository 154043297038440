export enum DotTypes {
  PEN_DOWN = 1,
  PEN_MOVE = 2,
  PEN_UP = 3,
  PEN_HOVER = 101,
  PEN_ERROR = 999,
}

export enum DOT_TYPE {
  none = 0,
  pendown = 1,
  penmove = 2,
  penup = 3,
  penhover = 101,
  penerror = 999,
}
