
import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import clsx from "clsx";
import { ReactComponent as MarkViewMore } from '../../../../common/images/MarkViewMore.svg';
import { ReactComponent as NoResultIcon } from '../../../../common/images/NoResultIcon.svg';
import { ReactComponent as ViewRightIcon } from '../../../../common/images/ViewRightIcon.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ResultReviewStyle } from "../../styles/ResultReviewStyle";

import React from "react";
import { useTranslation } from 'react-i18next';
import TextViewer from "../../../../components/common/TextViewer";
import { useStores } from "../../../../hooks/useStores";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";
import { RubricTable } from "./RubricTable";

type Props = {
	questionInfo: IQuestionInfo;
	examOrderAndQuestionsLengthMap: any;
}

export const ResultRow = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( ResultReviewStyle );
	const { questionInfo, examOrderAndQuestionsLengthMap } = props;
	const { projectExamStore } = useStores();
	const [rubricIdx, setRubricIdx] = React.useState(0);
	const [openResult, setOpenResult] = React.useState(true);

	const currentOrder = React.useMemo(() => {
		const questionListLen = examOrderAndQuestionsLengthMap[questionInfo.parentOrder];

		return projectExamStore.setQuestionFullOrderByQuestionListLen(questionListLen, questionInfo.parentOrder, questionInfo.order);
	}, [examOrderAndQuestionsLengthMap, questionInfo.parentOrder, questionInfo.order]);

	const [openRubric, setOpenRubric] = React.useState(false);

	const handleClickRubricFolded = () => {
		setOpenRubric(prev => !prev);
	};

	const handleClickStandardAnswer = () => {
		const answerTag = document.getElementById(`standard-answer-${currentOrder}`);
		if (answerTag) {
			answerTag.scrollIntoView({ behavior: "smooth" });
			// answerTag.scrollTop = 0;
		}
	};

	const handleClickRublicPage = (e, value) => {
		const idx = value - 1;
		setRubricIdx(idx);
	};

	const currentRubricInfo = React.useMemo(() => {
		return questionInfo?.answer?.rubrics[rubricIdx];
	}, [rubricIdx]);

	const currentScoreInfo = React.useMemo(() => {
		const scoreInfo = questionInfo?.answer?.scores.find(scoreInfo => scoreInfo.rubricId === currentRubricInfo?.id);
		return scoreInfo;
	}, [currentRubricInfo]);

	const currentRubricItemInfo = React.useMemo(() => {
		const currentRubricItemInfo = currentRubricInfo?.items.find(item => item.rubricItemNum === currentScoreInfo?.rubricItemNum);
		return currentRubricItemInfo;
	}, [currentScoreInfo]);

	const scoreAverage = React.useMemo(() => {
		if (currentScoreInfo?.totalScore === undefined || currentScoreInfo?.totalCount === undefined) return "-";

		return (currentScoreInfo.totalScore / currentScoreInfo.totalCount).toFixed(1);
	}, [currentScoreInfo]);

	const scoreAveragePercentage = React.useMemo(() => {
		if (scoreAverage === "-" || currentScoreInfo?.standardScore === undefined) return "-";

		const val = parseFloat(scoreAverage);
		return (val / (currentScoreInfo?.standardScore || 10000)) * 100;
	}, [scoreAverage, currentScoreInfo]);

	const scorePercentage = React.useMemo(() => {
		return Math.ceil((currentScoreInfo?.score / currentScoreInfo?.standardScore) * 100);
	}, [currentScoreInfo]);

	const itemScore = React.useMemo(() => {
		if (currentScoreInfo?.score === undefined) {
			return '-';
		}

		return currentScoreInfo?.score;
	}, [currentScoreInfo?.score]);

	// const handleClickResultFold = () => {
	// 	setOpenResult(prev => !prev);
	// };

	const receivedScore = React.useMemo(() => {
		if (!questionInfo || !questionInfo.answer || !questionInfo.answer.scores || questionInfo.answer.scores.length === 0) {
			return 0;
		}

		const totalScore = questionInfo.answer.scores.reduce((acc, cur) => acc + cur.score, 0);
		return totalScore;
	}, [questionInfo]);

	return (
		<Box className={classes.questionBoxMarginBottom}>
			<Box className={classes.taskNumberBox}>
				<Box className={classes.taskNumber}>
					<Typography>{currentOrder}</Typography>
				</Box>
				<Box className={classes.amountScore}>
					<Typography><span>{receivedScore}</span>/{questionInfo?.score || 0}<span className={classes.amountScoreText}>{i18next.t("점")}</span></Typography>
				</Box>
			</Box>

			{openResult &&
				<Box className={classes.greenBorderBox}>
					<Box className={questionInfo?.answer?.scores.length > 0 ? classes.greenInnerBox : clsx(classes.greenInnerBox, classes.noResultMark)}>
						<Box className={classes.tagBtnView}>
							<Button disableRipple onClick={handleClickStandardAnswer}>
								<Typography>{i18next.t("모범 답안 보기")}</Typography>
								<ViewRightIcon />
							</Button>
						</Box>

						<Box className={classes.tagNameBox}>
							<Typography>{i18next.t("채점 결과")}</Typography>
						</Box>


						{questionInfo?.answer?.scores.length > 0 ?
							<>
								<Box display='flex' alignItems='flex-end' justifyContent='space-between' className={classes.progressMainBox}>

									{/*<Box className={classes.progressContainer}>*/}
									<Box className={classes.progressTitle}>
										<Typography className={classes.greenBoxTitle}>{i18next.t("평균 대비 내 위치")}</Typography>
									</Box>
									<Box className={classes.progressBox}>
										<Typography className={classes.progressLeftText}>0</Typography>
										<Box >
											<LinearProgress variant="determinate" value={scorePercentage} />
										</Box>
										<Box className={classes.progressGuide} sx={{ top: '-40px', left: `${scoreAveragePercentage}%` }}>
											<Typography>{i18next.t("평균")} {scoreAverage}</Typography>
										</Box>
										<Typography className={classes.progressRightText}>{currentScoreInfo.standardScore}</Typography>
									</Box>
								</Box>
								<TableContainer component={Paper} className={classes.tableBox2}>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell width='25%' align="center">{i18next.t("평가항목")}</TableCell>
												<TableCell width='20%' colSpan={2} align="center" className={classes.scoreCell}>{i18next.t("점수")}</TableCell>
												<TableCell width='55%' align="center" className={classes.bgColorGreen}>{i18next.t("채점 기준")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow >
												{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
												<TableCell width='25%' align="center" className={classes.rubricCommonCell}>
													{/*<Box display='flex' flexDirection='column' alignItems='center'>*/}
													<Typography>{currentScoreInfo?.rubricName || '-'}</Typography>
													{/*</Box>*/}
												</TableCell>
												<TableCell width='10%' align="center" className={classes.rubricCommonCell}>
													<Typography>{currentScoreInfo?.rubricItemName || '-'}</Typography>
												</TableCell>
												<TableCell width='10%' className={classes.rubricCommonCell2}>
													<Box>
														{/*<Typography className={clsx(classes.markText, classes.markTextColor)}><span>{currentScoreInfo?.score || '-'}</span></Typography>*/}
														<Typography className={clsx(classes.markText, classes.markTextColor)}><span>{itemScore}</span></Typography>
													</Box>
													<Typography className={clsx(classes.markText, classes.markTextColor)}>{i18next.t("점")}</Typography>
												</TableCell>
												<TableCell width='55%' align="left" className={classes.borderLine}>
													<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
														<Typography className={clsx(classes.tableText, classes.tableTextGreen)}>
															{currentRubricItemInfo?.standard || '-'}
														</Typography>
													</Box>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>

								{currentScoreInfo?.aiFeedback &&
									<TableContainer component={Paper} className={classes.tableBox3}>
										<Table aria-label="simple table">
											<TableBody>
												<TableRow >
													{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
													<TableCell width='25%' align="center" className={classes.feedbackBgColor}>
														<Typography>{i18next.t("AI 채점 근거")}</Typography>
													</TableCell>
													<TableCell width='75%' align="left" className={classes.feedbackBox}>
														<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
															<Typography style={{ fontWeight: 500 }}>
																{currentScoreInfo?.aiFeedback}
															</Typography>
														</Box>
													</TableCell>
												</TableRow>
												{/*<TableRow sx={{'&:last-child .MuiTableCell-body': {border: '0px !important'}}}>*/}
												{/*    <TableCell width='10%' align="center" className={classes.bgColor}>*/}
												{/*        <Typography>{i18next.t("피드백")}</Typography>*/}
												{/*    </TableCell>*/}
												{/*    <TableCell width='90%' align="left" className={classes.borderLine}>*/}
												{/*        <Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>*/}
												{/*            <TextViewer*/}
												{/*              currentHTML={questionInfo.answer.ownerFeedback}*/}
												{/*            />*/}
												{/*        </Box>*/}
												{/*    </TableCell>*/}
												{/*</TableRow>*/}
											</TableBody>
										</Table>
									</TableContainer>
								}

								<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
									<Stack spacing={2}>
										<Pagination
											count={questionInfo?.answer?.rubrics.length || 0}
											boundaryCount={2}
											showFirstButton
											showLastButton
											onChange={handleClickRublicPage}
											variant="outlined"
										/>
									</Stack>
								</Box>

								{questionInfo?.answer?.ownerFeedback &&
									<TableContainer component={Paper} className={clsx(classes.tableBox2, classes.tableBoxMarginTop)}>
										<Table aria-label="simple table">
											<TableBody>
												<TableRow >
													{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
													<TableCell width='25%' align="center" className={classes.feedbackBgColor}>
														<Typography>{i18next.t("피드백")}</Typography>
													</TableCell>
													<TableCell width='75%' align="left" className={clsx(classes.feedbackBox, classes.feedbackBoxPadding)}>
														<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
															{/*<Typography className={classes.tableText}>*/}
															{/*    제시된 두 논거가 설득력을 갖추고 있다. <br/>제시된 두 논거가 설득력을 갖추고 있다. 글의 흐름이 일관성이 있으며 논리적으로 전개된다. 제시된 두 논거가 설득력을 갖추고 있다.*/}
															{/*</Typography>*/}
															<TextViewer
																currentHTML={questionInfo.answer.ownerFeedback}
															/>
														</Box>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								}
							</>
							:
							<Box className={classes.noResultBox}>
								<NoResultIcon />
								<Typography>{i18next.t("채점 결과가 없습니다.")}</Typography>
							</Box>
						}
					</Box>

					<Box className={clsx(classes.iconBtn, classes.iconBtnSupport)} display='flex' alignItems='center' justifyContent='left' onClick={handleClickRubricFolded}>
						<IconButton className={classes.iconBtnReset} style={{ transform: openRubric ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>
							<MarkViewMore />
						</IconButton>
						<Typography className={classes.viewMoreText}>{i18next.t("채점 기준표")} {openRubric ? i18next.t("접기") : i18next.t("보기")}</Typography>
					</Box>

					{/*<Box display='flex' alignItems='center' justifyContent='left'>*/}
					{/*    <IconButton className={classes.iconBtnReset} onClick={handleClickRubricFolded} style={{ transform: openRubric ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>*/}
					{/*        <MarkViewMore />*/}
					{/*    </IconButton>*/}
					{/*    <Typography className={classes.viewMoreText}>{i18next.t("채점 기준표 보기")}</Typography>*/}
					{/*</Box>*/}
					<Box>
						{openRubric &&
							questionInfo?.answer?.rubrics.length > 0 &&
							<RubricTable
								rubricInfo={currentRubricInfo}
							/>
						}
					</Box>
				</Box>
			}

			{/*<Box display='flex' alignItems='center' justifyContent='center'>*/}
			{/*    <Box className={classes.resultBtnToggle} display="flex" alignItems="center" onClick={handleClickResultFold}>*/}
			{/*        <Typography>{currentOrder}번 문항 결과 {openResult ? i18next.t("접기") : i18next.t("열기")} </Typography>*/}
			{/*        <IconButton className={classes.iconBtnReset} style={{ transform: openResult ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>*/}
			{/*            <MarkViewMore />*/}
			{/*        </IconButton>*/}
			{/*    </Box>*/}
			{/*</Box>*/}
			{/*<Box display='flex' alignItems='center' justifyContent='center'>*/}
			{/*    <Box className={classes.borderGuide}>*/}
			{/*        <AiStudyIcon />*/}
			{/*    </Box>*/}
			{/*</Box>*/}


			{/*<Box className={classes.flexCenter}>*/}
			{/*    <Box className={classes.resultBtnToggle}>*/}
			{/*        <Typography>{currentOrder}번 문항 결과 접기</Typography>*/}
			{/*        <IconButton className={classes.iconBtnReset} onClick={handleClickResultFold} style={{ transform: openResult ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>*/}
			{/*            <MarkViewMore />*/}
			{/*        </IconButton>*/}
			{/*    </Box>*/}
			{/*</Box>*/}
		</Box>
	);
}