import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TemplateProjectViewStyle } from "../../styles/TemplateProjectViewStyle";

import { useTranslation } from 'react-i18next';
import { useStores } from "../../../../hooks/useStores";
import { observer } from "mobx-react";

type Props = {
}

export const PlanTab = observer((props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( TemplateProjectViewStyle );

	const { projectStore } = useStores();
	const { projectPlanTransfer } = projectStore;

	return (
		<Box className={classes.tabBox}>
			{projectPlanTransfer &&
				projectPlanTransfer.standards &&
				projectPlanTransfer.standards.map((standard, standardIndex) => {
					return (
						<TableContainer key={standardIndex} component={Paper} className={classes.tableBoxN}>
							<Table aria-label="simple table" key={`standards-${standardIndex}`}>
								<TableBody>
									<TableRow >
										{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
										<TableCell width='10%' align="center" className={classes.planTableTitle}>
											<Typography className={classes.bgColorTitle}>{i18next.t('성취기준코드')}</Typography>
										</TableCell>
										<TableCell width='90%' align="left" colSpan={9} className={classes.borderLine}>
											<Typography>{standard.externalPlanCode}</Typography>
										</TableCell>
									</TableRow>

									<TableRow >
										{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
										<TableCell width='21%' align="center" className={classes.planTableTitle}>
											<Typography className={classes.bgColorTitle}>{i18next.t('성취기준 내용')}</Typography>
										</TableCell>
										<TableCell width='79%' align="left" colSpan={9} className={classes.borderLine}>
											<Typography>{standard.standard}</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell width='21%' rowSpan={standard?.grades.length + 1} align="center" className={classes.planTableTitle}>
											<Typography className={classes.bgColorTitle}>{i18next.t('평가기준')}</Typography>
										</TableCell>
									</TableRow>

									{standard?.grades.map((grade, gardeIndex) => {
										return (
											<TableRow key={standardIndex + "/" + gardeIndex}>
												<TableCell colSpan={1} width='10%' align="center">
													<Typography className={classes.tableColumnGradeName}>{grade.grade}</Typography>
												</TableCell>
												<TableCell colSpan={8} width='90%' align="left" className={classes.borderLine}>
													<Typography>{grade.contents}</Typography>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					);
				})}

			<TableContainer component={Paper} className={classes.tableBoxN}>
				<Table aria-label="simple table">
					<TableBody>
						<TableRow >
							{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
							<TableCell width='10%' align="center" className={classes.planTableTitle}>
								<Typography className={classes.bgColorTitle}>{i18next.t('교과 역량')}</Typography>
							</TableCell>
							<TableCell width='90%' align="left" className={classes.borderLine}>
								<Typography>{projectPlanTransfer && projectPlanTransfer.requiredSubjectAbility}</Typography>
							</TableCell>
						</TableRow>

						<TableRow >
							{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
							<TableCell align="center" className={classes.planTableTitle}>
								<Typography className={classes.bgColorTitle}>{i18next.t('출제 의도')}</Typography>
							</TableCell>
							<TableCell align="left" className={classes.borderLine}>
								<Typography>{projectPlanTransfer && projectPlanTransfer.purpose}</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
});
