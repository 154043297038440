import { MauiRelaySocket } from "../maui-ws-relay/maui-relay-websocket";
import { DotNetApi } from "./js-to-maui";
import MauiPenCommManager from "./maui-pencomm-proxy";
import { MauiToJsBridge } from "./maui-to-js";

export async function dotnetLog(s: string) {
	const { NeoDotNet } = (window as any);
	await NeoDotNet.invokeMethodAsync("Log", s);
}


/**
 * 처음 index.tsx에서 부를 때에는 이것을 불러야 한다.
 * @returns
 */

async function initialConnectMauiBridgeSocket() {
	let isConnected = false;
	try {
		isConnected = await DotNetApi.instance.connectWebSocket();
	} catch (e) {
	}
	console.log(`deeplink: Initial webSocket connected: ${isConnected}`);
	return isConnected;
};



export async function initMauiToJsBridge() {
	const connected = await initialConnectMauiBridgeSocket();

	return connected;
}

let nowConnecting = false;
let lastRetryLimit = 0;

async function sleep(t: number) {
	return new Promise((resolve) => setTimeout(resolve, t));
}

export async function connectMauiBridgeSocket(retryLimit: number) {
	let retryCount = 0;
	console.log(`WebSocket Connecting: nowConnecting: ${nowConnecting}`);
	if (nowConnecting && lastRetryLimit >= retryLimit) {
		return;
	}

	lastRetryLimit = retryLimit;

	let isConnected = false;
	nowConnecting = true;
	while (!isConnected && retryCount < retryLimit) {
		retryCount++;
		try {
			await DotNetApi.instance.connectWebSocket();
			isConnected = await MauiRelaySocket.instance.isConnectedAfterWaiting();
			if (!isConnected) {
				await sleep(2000);
			}
		} catch (e) {
			console.log('WebSocket not Connected, retrying in 1 seconds');
			await sleep(2000);
		}
	}

	nowConnecting = false;

	console.log(`WebSocket connected: ${isConnected}`);
	return isConnected;
};
