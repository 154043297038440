import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const DownloadNativePopupStyle: (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	body: {
		borderRadius: 16,
		background: '#fff',
		boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)',
		padding: '40px 42px',
		boxSizing: 'border-box',
	},
	center: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	main: {
		display: 'flex',
		flexDirection: 'column',
		// alignItems: 'center',
	},
	borderLine: {
		marginTop: "17px",
		marginBottom: "37px",
	},
	loginBox: {
		width: "100%",
	},
	loginBoxTitle: {
		'& .MuiTypography-root': {
			color: '#0F0F10',
			fontSize: '1.5rem',
			fontWeight: 600,
			textAlign: 'center',
			marginBottom: "31px"
		}
	},
	logo: {
		width: "200px",
		height: "52px"
	},
	title: {
		fontSize: '1.5rem',
		fontWeight: 800,
		marginBottom: "20px",
	},
	detail: {
		fontSize: '1.0rem',
		textAlign: 'left',
	},

	clickUpper: {
		fontSize: '1.0rem',
		textAlign: 'center',
		color: '#FF0000',
	},
	dialogBox: {
		borderRadius: 4,
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			width: "400px", // 작은 화면 (sm 이하)에서 더 좁게 설정
		},
		[theme.breakpoints.between('sm', 'md')]: {
			width: "500px", // sm과 md 사이에서의 설정
		},
		[theme.breakpoints.up('md')]: {
			width: "600px", // md 이상에서 설정
		},
		// // 특정 Dialog-paper 스타일을 커스터마이징 하고 싶다면 주석을 제거하고 수정
		// '& .MuiDialog-paperWidthSm': {
		// 	width: "450px", // 소형 다이얼로그 커스터마이징
		// },
		// '& .MuiDialog-paperWidthMd': {
		// 	width: "600px", // 중형 다이얼로그 커스터마이징
		// },
	},

	dialogContent: {
		display: 'flex',
		textAlign: 'center',
		minHeight: '200px',
		alignItems: 'center',
		// width: "785px",
		paddingTop: '25px',
		paddingBottom: '25px',
		// paddingLeft: '20px',
		// paddingRight: '20px',
	},


	loginPageBtn: {
		'& .MuiButtonBase-root': {
			width: "440px",
			height: "60px",
			background: '#cbcbcb',
			borderRadius: 4,
			marginTop: "24px",
			'& .MuiTypography-root': {
				fontSize: '1.125rem',
				color: '#fafafa',
				fontWeight: 600,
			}
		}
	},


	loginPageBtnActive: {
		'& .MuiButtonBase-root': {
			background: '#DEF8F3',
			'& .MuiTypography-root': {
				color: '#008C73',
			},
			'&:disabled': { // disabled 상태 스타일
				background: '#F5F5F5', // 연한 회색
				'& .MuiTypography-root': {
					color: '#B0B0B0', // 연한 글자 색
				}
			}
		}
	},

	backBtn: {
		borderRadius: 4,
		width: "200px",
		border: '1px solid #333',
		marginTop: "24px",

		height: "50px",
		background: '#ffffff',
		'& .MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#000000',
			fontWeight: 600,
		}
	},


});
