import { getUseWorkers } from "../config/mupdfWorkerConfig";
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-this-alias */
import WasmMethodClass from "../worker/mupdf-worker-methods-class";
import MuPdfWorker, { WorkerSyncMethodsType } from "../worker/mupdf.worker";
import { uuidv4 } from "../common/UtilFuncs";

const defaultWasmURL = "/lib/lib-core.wasm";

class NeoPDFWorkerEmployer {

  workerMethods: WasmMethodClass = null;

  public _jobReady = new Promise<void>((resolve) => resolve());

  name: string;

  worker: typeof MuPdfWorker = null;

  messagePromises = new Map();

  lastPromiseId = 0;

  ready: Promise<typeof MuPdfWorker> = new Promise((resolve, reject) => { });

  useWorker: boolean;

  wasmBinaryUrl: string = null;

  id: string;

  constructor(args: { useWorker?: boolean, name?: string, wasmPath?: string }) {
    const { useWorker = getUseWorkers(), name = "worker", wasmPath = defaultWasmURL } = args;
    const uuid = uuidv4();
    this.workerMethods = new WasmMethodClass({ wasmBinaryUrl: wasmPath, uuid });
    this.wasmBinaryUrl = wasmPath;

    this.name = name;
    this.useWorker = useWorker;

    this.id = uuid;

    if (useWorker) {
      // console.log(`mupdfWorkerEmployer.ts: worker = new MuPdfWorker();`);
      // console.log("BBB");
      // (window as any).AAA = wasmPath;
      // const worker = new MuPdfWorker(wasmPath);
      // this.worker = worker;
      // let readyResolve = null;
      // this.ready = new Promise((resolve, reject) => { readyResolve = resolve; });
      // readyResolve(worker);
      // console.log(`mupdfWorkerEmployer.ts: worker = new MuPdfWorker(); excuted`);
    }
    else {
      // const moduleWrapper = new WasmMethodClass({ wasmBinaryUrl: defaultWasmURL, uuid });
      // this.loadWasm();
      // this.workerMethods = this;
      // this.ready.then((result) => {
      //   let readyResolve = null;
      //   this.ready = new Promise((resolve, reject) => { readyResolve = resolve; });
      //   readyResolve(null);
      // });
    }
  }

  public _promiseResolve: () => void = null;

  public lock = async () => {
    await this._jobReady;
    this._jobReady = new Promise<void>((resolve) => this._promiseResolve = resolve);
  }

  public unlock = () => {
    this._promiseResolve?.();
  }

  wrap = (func: string) => {
    // console.log(`mupdfWorkerEmployer.ts: wrap(${func})`);
    const reservedThis = this;

    if (this.worker) {
      return function (...args) {
        const promise = new Promise<any>(async (resolve, reject) => {
          // await self.lock();
          const id = reservedThis.lastPromiseId++;
          reservedThis.messagePromises.set(id, { resolve, reject });
          if (args[0] instanceof ArrayBuffer) {
            reservedThis.worker.postMessage([func, id, args], [args[0]]);
          } else {
            reservedThis.worker.postMessage([func, id, args]);
          }
        });
        return promise;
      };
    }

    return function (...args) {
      const promise = new Promise<any>(async (resolve, reject) => {
        // await self.lock();
        // const id = reservedThis.lastPromiseId++;
        // reservedThis.messagePromises.set(id, { resolve, reject });

        const result = reservedThis.workerMethods[func](args);
        resolve(result);
      });

      return promise;
    };

  }

  wrap_openStreamFromUrl = this.wrap("openStreamFromUrl");

  wrap_openDocumentFromStreamPointer = this.wrap("openDocumentFromStreamPointer");


  // TODO - Add cancelation for trylater queues


  onWorkerMessage = (event) => {
    const [type, id, ts, valueType, result] = event.data;
    const lap = (new Date()).getTime();
    // console.log(`Worker job #${id}(${valueType}) takes ${lap - ts}ms to transfer`);
    const self = this;
    // self.unlock();

    if (type === "RESULT") {
      this.messagePromises.get(id).resolve(result);
    }
    else if (type === "READY") {
      this.messagePromises.get(id).reject(new Error("Unexpected READY message"));
    }
    else if (type === "ERROR") {
      const r = event.data[2];
      const error = new Error(r.message);
      error.name = r.name;
      error.stack = r.stack;
      this.messagePromises.get(id).reject(error);
    }
    else {
      this.messagePromises.get(id).reject(new Error(`Unexpected result type '${type}'`));
    }

    this.messagePromises.delete(id);
  }

  nonWorkerMethod = {};

  initWorker = () => {
    const reservedThis = this;
    const wasmPath = this.wasmBinaryUrl;

    return new Promise<NeoPDFWorkerEmployer>(async (resolve, reject) => {
      if (!this.useWorker) {
        const ret = await reservedThis.workerMethods.loadWasm();
        const methods = Object.keys(reservedThis.workerMethods);
        for (const method of methods) {
          reservedThis[method] = async function (...args) {
            return reservedThis.workerMethods[method](...args);
          }
        }

        reservedThis.wasmMemory = null;

        let readyResolve = null;
        reservedThis.ready = new Promise((resolve, reject) => { readyResolve = resolve; });
        readyResolve(reservedThis);

        resolve(reservedThis);
        return;
      }

      console.log(`mupdfWorkerEmployer.ts: worker = new MuPdfWorker();`);
      const worker = new MuPdfWorker(wasmPath);
      reservedThis.worker = worker;
      let readyResolve = null;
      reservedThis.ready = new Promise((resolve, reject) => { readyResolve = resolve; });
      readyResolve(worker);
      // console.log(`mupdfWorkerEmployer.ts: worker = new MuPdfWorker(); excuted`);

      reservedThis.worker.onmessage = function (event: { data: any[] }) {
        const type = event.data[0];
        if (type === "READY") {
          // console.log("workerReady in initWorker");

          // reservedThis.mupdfWorkerEmployer.wasmMemory = event.data[1];
          reservedThis.wasmMemory = event.data[1];
          const methodNames = event.data[2];

          for (const method of methodNames) {
            // reservedThis.mupdfWorkerEmployer[method] = reservedThis.wrap(method);
            reservedThis[method] = reservedThis.wrap(method);
          }

          reservedThis.worker.onmessage = reservedThis.onWorkerMessage;
          resolve(reservedThis);

          // let readyResolve = null;
          // reservedThis.ready = new Promise((resolve, reject) => { readyResolve = resolve; });
          // readyResolve(reservedThis.worker);

        } else if (type === "ERROR") {
          const error = event.data[1];
          reject(new Error(error));
        } else {
          reject(new Error(`Unexpected first message: ${event.data}`));
        }
      };
    });
  }

  neoOpenDocumentFromUrl = async (ctxId: string, url: string, contentLength: number, progressive: number, prefetch: number, magic: string) => {
    if (this.worker) {
      const streamPointer = await this.wrap_openStreamFromUrl(url, contentLength, progressive, prefetch);
      const ret = await this.wrap_openDocumentFromStreamPointer(streamPointer, magic);
      return ret as string;
    }

    const streamId = this.workerMethods.openStreamFromUrl(ctxId, url, contentLength, progressive, prefetch);
    return this.workerMethods.openDocumentFromStreamId(ctxId, streamId, magic);
  };

  neoOpenDocumentFromBuffer = async (ctxId: string, buffer: ArrayBuffer, magic: string, isJobStart = false) => {
    if (this.worker) {
      const id = await this.wrap("openDocumentFromBuffer")(buffer, magic, ctxId, isJobStart);
      return id as string;
    }

    const id = this.workerMethods.openDocumentFromBuffer(buffer, magic, ctxId, isJobStart);
    return id;
  };

  terminate = () => {
    if (this.worker) {
      this.worker.terminate();
    }
  };

  wasmMemory = undefined;

  setLogFilters = this.wrap("setLogFilters");

  // TODO: for debug
  getFzDocument = (docId: string) => {
    const item = this.workerMethods.openDocuments.get(docId);
    return item;
  }

}

function createPDFWorkerEmployer(args: { useWorker?: boolean, name?: string, wasmPath?: string }) : (NeoPDFWorkerEmployer & WorkerSyncMethodsType) {
  const mupdfWorkerEmployer = new NeoPDFWorkerEmployer(args);

  return mupdfWorkerEmployer as (NeoPDFWorkerEmployer & WorkerSyncMethodsType);
}

// const mupdfWorkerEmployer: NeoPDFWorkerEmployer & WorkerSyncMethodsType = { ...this.nonWorkerMethod, ...this.mupdfViewModule };

type NeoPDFWorkerEmployerType = ReturnType<typeof createPDFWorkerEmployer>;

export { createPDFWorkerEmployer };
export type { NeoPDFWorkerEmployerType as NeoPDFWorkerEmployer };
// export default NeoPDFWorkerEmployer;
