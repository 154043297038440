import {
	Box,
	Button,
	Checkbox,
	Dialog,
	FormControlLabel,
	IconButton,
	InputBase,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography
} from "@mui/material";
import { useState } from "react";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { ReactComponent as CheckCircleIcon } from '../../../../../common/images/CheckCircleIcon.svg';
import { ReactComponent as CheckCircleIconOff } from '../../../../../common/images/CheckCircleIconOff.svg';
import { ReactComponent as EmptyUserBg } from '../../../../../common/images/EmptyUserBg.svg';
import { ReactComponent as NoMatchResult } from '../../../../../common/images/NoMatchResult.svg';
import { ReactComponent as SearchNameIcon } from '../../../../../common/images/SearchNameIcon.svg';
import { ReactComponent as TableListOrder } from '../../../../../common/images/TableListOrder.svg';

import { observer } from "mobx-react";

import { Backdrop, CircularProgress } from "@mui/material";
// import 'dayjs/locale/ko';
import useNaviCustom from "../../../../../hooks/useNaviCustom";
import MarkFeedback from "../../3_ScoringAndFeedback/MarkFeedBackPopUp/MarkFeedback";
import CompareNeoPenData from "./CompareNeoPenData/CompareNeoPenData";
import CompareData from "./CompareScannedData/CompareData";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../../hooks/useStores";
import { OcrStatus } from "../../../../../repositories/model/support/OcrStatus";
import { ProjectStateType } from "../../../../../repositories/model/support/ProjectStateType";
import { ProjectSubmitType } from "../../../../../repositories/model/support/ProjectSubmitType";
import { INcodeAllocationResponse } from "../../../../../repositories/model/transfer/INcodeAllocationResponse";
import { IProjectSubmissionTransfer } from "../../../../../repositories/model/transfer/IProjectSubmissionTransfer";
import { StoreStatus } from "../../../../../stores/support/StoreStatus";
import { SimpleDialog } from "./SimpleDialog";
import { SubmitStatusRow } from "./SubmitStatusRow";
import { BatchUploadDialog } from "./CompareBatchNeoPenData/BatchUploadDialog";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { SubmitManagementStyle } from "../../../styles/SubmitManagementStyle";


type Props = {
}

const rowSortingHits = [
	{
		name: "none",
		func: (isAsending: boolean) => [],
		isAsending: true,
	},
	{
		name: "studentID",
		func: (isAsending: boolean) => {
			const orderString = isAsending ? "ASC" : "DESC";
			const sortingHint = [
				{ column: "up.grade", hint: orderString, },
				{ column: "up.class_name", hint: orderString, },
				{ column: "up.number", hint: orderString, },
			];
			return sortingHint;
		},
		isAsending: true,
	},
	{
		name: "studentName",
		func: (isAsending: boolean) => {
			const orderString = isAsending ? "ASC" : "DESC";
			const sortingHint = [
				{ column: "up.name", hint: orderString, },
			];
			return sortingHint;
		},
		isAsending: true,
	},
	{
		name: "createdDate",
		func: (isAsending: boolean) => {
			const orderString = isAsending ? "ASC" : "DESC";
			const sortingHint = [{ column: "ps.created_datetime", hint: orderString }];
			return sortingHint;
		},
		isAsending: false,
	},
];

const getSortingHint = (name: string) => {
	const row = rowSortingHits.find(row => row.name === name);
	const isAsending = row.isAsending;
	return row.func(isAsending);
}

// const setSortingHitOrderBy = (name: string, isAsending: boolean) => {
// 	const row = rowSortingHits.find(row => row.name === name);
// 	row.isAsending = isAsending;
// }

const getSortingHitOrderBy = (name: string) => {
	const row = rowSortingHits.find(row => row.name === name);
	return row.isAsending;
}

const reverseSortingHitOrderBy = (name: string) => {
	const row = rowSortingHits.find(row => row.name === name);
	row.isAsending = !row.isAsending;
}



// 정렬 가능한 헤더 component
const SortingHeaderCell = (props: { sortRows: (val: string) => void, sortingHintName: string, title: string, sortKey: string }) => {
	const classes = useEmotionStyles(SubmitManagementStyle);


	const { title, sortKey, sortRows, sortingHintName } = props;

	return (
		<Box className={classes.sortButton} style={{ cursor: 'default' }}>
			<Typography style={{ fontWeight: 800 }}>
				{title}
			</Typography>
			<IconButton
				onClick={() => { sortRows(sortKey); }}
				className={classes.iconBtnReset}
				style={{
					transform: sortingHintName !== sortKey ? 'rotate(90deg)' : (!getSortingHitOrderBy(sortKey) ? 'rotate(180deg)' : 'none'),
					transition: 'transform 0.3s ease'
				}}
				disableRipple
			>
				<TableListOrder />
			</IconButton>
		</Box>

	)
}




function SubmitManagement(props: Props) {
	const params = useParams();
	const { projectSubmissionStore, projectStore, projectSubmissionAnswerStore, ncodeAllocationStore } = useStores();
	const classes = useEmotionStyles(SubmitManagementStyle);
	const i18next = useTranslation();

	// 행 표시 정렬 관련
	const [sortingHints, setSortingHints] = React.useState<string>("studentID");


	const [anchorEl, setAnchorEl] = useState<Element>(null);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [selectedFilter, setSelectedFilter] = React.useState(null);

	const [dialogType, setDialogType] = React.useState(null);
	const [files, setFiles] = React.useState(null);
	const [transferIndex, setTransferIndex] = React.useState(null);

	const [keyword, setKeyword] = React.useState("");
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(30);


	const [filterState, setFilterState] = React.useState("");
	const [onlineState, setOnlineState] = React.useState(false);
	const [comparedOpen, setComparedOpen] = React.useState(false);

	const [noShowPenDialog, setNoShowPenDialog] = React.useState(false);
	const [compareNeoPenOpen, setCompareNeoPenOpen] = React.useState(false);


	const [selectedSubmissionCode, setSelectedSubmissionCode] = React.useState(null);
	const [selectedSubmissionTransfer, setSelectedSubmissionTransfer] = React.useState<IProjectSubmissionTransfer & { submissionIndex: number }>(null);
	const [openMarkDialog, setOpenMarkDialog] = React.useState(false);
	const [templateProjectCode, setTemplateProjectCode] = React.useState(null);
	const [ncodeAllocated, setNcodeAllocated] = React.useState<INcodeAllocationResponse>(null);

	const { projectCode } = params;
	const { projectSubmissionList, projectSubmissionTotalCount, projectSubmissionStatus } = projectSubmissionStore;
	const { answerScoreListConditionsToSearch } = projectSubmissionAnswerStore;
	const { updatedProjectUserState, project } = projectStore;

	const [localUpdatedProjectUserState, setLocalUpdatedProjectUserState] = React.useState(updatedProjectUserState);

	const naviCustom = useNaviCustom();


	React.useEffect(() => {
		// const sortingHint = [{ column: "ps.created_datetime", hint: "DESC" }];
		// setSortingHints(sortingHint);
		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchStateType(ProjectStateType.COMPARED);
		projectSubmissionAnswerStore.getAnswerScoreList(projectCode);
		// projectStore.getProjectInfo(projectCode);
		// projectSubmissionStore.getSubmissionTransfers(projectCode,selectedFilter,keyword,page-1,rowsPerPage,sortingHint);
		return () => {
			projectSubmissionStore.init();
		}
	}, [])



	React.useEffect(() => {
		const getTemplateProjectCode = async () => {
			const projectInfo = await projectStore.fetchProject(projectCode);
			setTemplateProjectCode(projectInfo?.templateCode);
			if (projectInfo) {
				const allocation = await ncodeAllocationStore.getProjectLastAllocation(projectCode, projectInfo?.templateCode);
				setNcodeAllocated(allocation);
			}
		}

		if (projectCode)
			getTemplateProjectCode();

	}, [projectCode]);

	React.useEffect(() => {
		// if (sortingHints !== "none" && selectedFilter?.length) {
		console.log(`getSubmissionTransfers #1, selectedFilter: ${selectedFilter}`);
		projectSubmissionStore.getSubmissionTransfers(projectCode, selectedFilter, keyword, page - 1, rowsPerPage, getSortingHint(sortingHints)
		);
		// }
	}, [selectedFilter])

	React.useEffect(() => {
		setLocalUpdatedProjectUserState(updatedProjectUserState);
	}, [updatedProjectUserState])


	React.useEffect(() => {
		setTimeout(() => {
			console.log(`getSubmissionTransfers #2, localUpdatedProjectUserState: ${localUpdatedProjectUserState}`);
			projectSubmissionStore.getSubmissionTransfers(projectCode, selectedFilter, keyword, page - 1, rowsPerPage, getSortingHint(sortingHints));
		}, 200)
	}, [localUpdatedProjectUserState])


	const sortRows = React.useCallback(
		async (hintName: string, pageNum?: number) => {
			if (pageNum === undefined) { pageNum = page; }

			if (sortingHints === hintName) {
				reverseSortingHitOrderBy(hintName);
			}
			const sortingHint = getSortingHint(hintName);
			setSortingHints(hintName);
			console.log(`getSubmissionTransfers #3-1`);
			await projectSubmissionStore.getSubmissionTransfers(projectCode, selectedFilter, keyword, pageNum - 1, rowsPerPage, sortingHint);
		}, []);


	React.useEffect(() => {
		console.log(`getSubmissionTransfers #3, page: ${page}`);
		sortRows("studentID", page);
	}, [page])

	// React.useEffect(()=>{
	//     console.log("@@@@",files)
	// },[files])

	React.useEffect(() => {
		const intervalId = setInterval(() => {
			let clearFlag = projectSubmissionStore.projectSubmissionList.length;
			for (let i = 0; i < projectSubmissionStore.projectSubmissionList.length; i++) {
				if (projectSubmissionStore.projectSubmissionList[i].ocrStatus === OcrStatus.RQST) {
					projectSubmissionStore.getSubmissionTransferForInterval(projectCode, projectSubmissionStore.projectSubmissionList[i].submissionCode).then(response => {
						if (response.ocrStatus !== OcrStatus.RQST && response.ocrStatus !== null) {
							projectSubmissionStore.changeOcrStatus(response.ocrStatus, i);
							--clearFlag;
						}
					})
				} else {
					--clearFlag;
				}
			}
			if (clearFlag === 0)
				clearInterval(intervalId);
		}, 10000)
		return () => clearInterval(intervalId);
	}, [projectSubmissionStore.projectSubmissionList]);

	const openTool = Boolean(anchorEl);
	const id = openTool ? 'simple-popover' : undefined;

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};

	const handleClickPage = (e, value) => {
		setPage(value);
	}

	const handleChangeSearchKeyword = (e) => {
		setKeyword(e.target.value);
	}

	const handleClickKeywordSearch = () => {
		console.log(`getSubmissionTransfers #4`);
		projectSubmissionStore.getSubmissionTransfers(projectCode, selectedFilter, keyword, page - 1, rowsPerPage, getSortingHint(sortingHints));
	}

	const handleKeyDown = (e) => {
		if (e.key === i18next.t("Enter")) {
			e.preventDefault();
			console.log(`getSubmissionTransfers #5`);
			projectSubmissionStore.getSubmissionTransfers(projectCode, selectedFilter, keyword, page - 1, rowsPerPage, getSortingHint(sortingHints));
		}
	}

	const handleClickTooltip = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenDialog = (dialogType, index) => {
		if (projectSubmissionList.length === 0)
			return;
		setTransferIndex(index);
		setDialogType(dialogType);
		setDialogOpen(true);
	}

	function handleOpenPenDialog(submissionTransfer: IProjectSubmissionTransfer, submissionIndex: number) {
		const { submissionCode } = submissionTransfer;
		setSelectedSubmissionCode(submissionCode);
		setSelectedSubmissionTransfer({ ...submissionTransfer, submissionIndex, submissionCode });

		setNoShowPenDialog(false);
		setCompareNeoPenOpen(true);
	}

	const handleClickView = (submissionTransfer: IProjectSubmissionTransfer, submissionIndex: number) => {
		setSelectedSubmissionCode(submissionTransfer.submissionCode);
		setSelectedSubmissionTransfer({ ...submissionTransfer, submissionIndex });

		setNoShowPenDialog(true);
		setComparedOpen(true);

		// console.log(submissionTransfer);
		// window.open(`/assignments/${projectCode}/submissions/${submissionTransfer.submissionCode}/compare/detail`, "_blank")
		return;
	}
	const handleClickComparing = (submissionTransfer: IProjectSubmissionTransfer, submissionIndex: number) => {
		setSelectedSubmissionCode(submissionTransfer.submissionCode);
		setSelectedSubmissionTransfer({ ...submissionTransfer, submissionIndex });

		setNoShowPenDialog(true);
		setCompareNeoPenOpen(true);

		return;
	}

	const handleClickClose = () => {
		setComparedOpen(false);
		setCompareNeoPenOpen(false);
	}

	const handleClickWatchingProject = () => {
		naviCustom.go(`/projects/${projectCode}/create/view`);
		// props.navigate(`/projects/${projectCode}/create/view`);
	}

	const handleConfirmDelete = (submissionCode) => {
		projectSubmissionStore.deleteSubmission(submissionCode).then(() => {
			console.log(`getSubmissionTransfers #6`);
			projectSubmissionStore.getSubmissionTransfers(projectCode, filterState, keyword, page - 1, rowsPerPage, getSortingHint(sortingHints))
		});
	}

	const handleConfirmOverWrite = (submissionCode: string, projectCode: string, userCode: string, submitType: ProjectSubmitType) => {
		projectSubmissionStore.deleteSubmission(submissionCode).then(() =>
			projectSubmissionStore.createAndUploadFileForSubmission(projectCode, userCode, submitType, files).then(() => {
				console.log(`getSubmissionTransfers #7`);
				projectSubmissionStore.getSubmissionTransfers(projectCode, filterState, keyword, page - 1, rowsPerPage, getSortingHint(sortingHints));
			}));
		// projectSubmissionStore.createSubmission(projectCode, userCode, submitType, files);
	}

	const handleClickFileUpload = (index) => {
		let fileInput = document.getElementById(`fileInputNum${index}`)
		fileInput.click();
	}

	const handleFileUpload = async (e, submissionIndex) => {
		const formData = new FormData();
		// const submitType = {
		//     submitType: ProjectSubmitType.OFFLINE
		// }
		formData.append('submitType', new Blob([JSON.stringify(ProjectSubmitType.OFFLINE)], { type: 'application/json' }))
		// formData.append('submitType',ProjectSubmitType.OFFLINE);
		for (const file of e.target.files) {
			formData.append('mFiles', file); // 서버에서 받을 파라미터 이름을 지정해야 합니다. 예: 'mFiles'
		}

		if (!projectSubmissionList[submissionIndex].submissionCode) {
			await projectSubmissionStore.createAndUploadFileForSubmission(projectCode, projectSubmissionList[submissionIndex].userCode, ProjectSubmitType.OFFLINE,
				formData).then(() => {
					e.target.value = "";
					console.log(`getSubmissionTransfers #8`);
					projectSubmissionStore.getSubmissionTransfers(projectCode, filterState, keyword, page - 1, rowsPerPage, getSortingHint(sortingHints));
				});
		} else {
			setFiles(formData);
			handleOpenDialog(1, submissionIndex);
		}
		e.target.value = "";
	}

	const handleClickSubmitFilter = (e) => {
		setOnlineState(!onlineState);
		// console.log(selectedFilter,selectedFilter === HomeworkStateType.SUBMITTED);
		// if(selectedFilter === HomeworkStateType.SUBMITTED)
		//     await setSelectedFilter("");
		// else
		//     await setSelectedFilter(HomeworkStateType.SUBMITTED);
	}
	const handleClickCompareFilter = async (e) => {
		if (selectedFilter === ProjectStateType.COMPARED)
			setSelectedFilter(null);
		else
			setSelectedFilter(ProjectStateType.COMPARED);
	}

	const handleCloseMarkDialog = () => {
		setSelectedSubmissionCode(null);
		setOpenMarkDialog(false);
	};

	const handleChangeSubmissionCode = (code: string) => {
		setSelectedSubmissionCode(code);
	};

	const handleClickRetryOCR = async (userCode: string) => {
		await projectSubmissionStore.requestRetryOCR(projectCode, userCode).then(() => {
			console.log(`getSubmissionTransfers #9`);

			projectSubmissionStore.getSubmissionTransfers(projectCode, selectedFilter, keyword, page - 1, rowsPerPage, getSortingHint(sortingHints))
		})

	}

	const handleClickScored = (submissionCode: string) => {
		naviCustom.go(`/assignments/${projectCode}/feedbacks?submissionCode=${submissionCode}`);
	}

	const [openBatchUploadDlg, setOpenBatchUploadDlg] = React.useState(false);

	const handleBatchUpload = () => {
		setOpenBatchUploadDlg(true);
	}

	const handleCloseBatchUploadDlg = () => {
		setOpenBatchUploadDlg(false);
		projectSubmissionStore.getSubmissionTransfers(projectCode, selectedFilter, keyword, page - 1, rowsPerPage, getSortingHint(sortingHints));

	}

	const refreshProjectSumissionList = () => {
		projectSubmissionStore.getSubmissionTransfers(projectCode, selectedFilter, keyword, page - 1, rowsPerPage, getSortingHint(sortingHints));
	}


	return (
		<div className={classes.root}>
			{/* ******콘텐츠 영역******* */}
			<Box component="main"
				sx={{ flexGrow: 1, background: '#F2F7F3', height: 'calc(100vh - 86px)' }}
				style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
				className={classes.padding}>
				<Box className={classes.mainContentBox}>
					<Box>
						<Typography className={classes.commonSubTitle}>
							{i18next.t("제출물 관리")}
							{project.includedOnline && <>(<span>{i18next.t("온라인 병용과제")}</span>)</>}
							{!project.includedOnline && <>(<span>{i18next.t("오프라인 평가과제")}</span>)</>}
						</Typography>
					</Box>

					<Box>
						<TableContainer style={{ border: "none" }}>
							<TableRow style={{ border: "none" }}>
								<TableCell width='6%' align="left" style={{ padding: 0, border: "none" }}>
									<Typography className={classes.commonSubmit}>
										{i18next.t("평가대상")} (
										<span>{projectSubmissionTotalCount}</span> / {projectSubmissionTotalCount})
									</Typography>
								</TableCell>
								<TableCell width='6%' align="right" style={{ padding: 0, border: "none" }}>
									<Button className={classes.greenBtn} onClick={handleBatchUpload}>
										<Typography>
											{i18next.t("데이터 일괄등록")}
										</Typography>
									</Button>
								</TableCell>
							</TableRow>
						</TableContainer>
					</Box>
					{/*
					<Box>
						<Typography className={classes.submitSubText}>
							{i18next.t("평가 대상에 해당되는 학생 목록입니다. 업로드 방식을 선택하고 제출물을 등록해 주세요.")}
						</Typography>
					</Box> */}

					<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} sx={{ marginTop: "5px" }}>
						<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}  >
							<Box sx={{ marginRight: "20px" }}>
								<FormControlLabel
									control={
										<Checkbox
											icon={<CheckCircleIconOff />}
											checkedIcon={
												<CheckCircleIcon className={classes.checkedColor} />
											}
											checked={selectedFilter === ProjectStateType.COMPARED}
											onClick={handleClickCompareFilter}
											disableRipple
										/>
									}
									label={i18next.t("제출물 대조 완료만")}
									className={classes.checkMargin}

								/>
								{/*<FormControlLabel*/}
								{/*    control={*/}
								{/*        <Checkbox*/}
								{/*            icon={<CheckCircleIconOff />}*/}
								{/*            checkedIcon={<CheckCircleIcon className={classes.checkedColor} />}*/}
								{/*            checked={onlineState}*/}
								{/*            onClick={handleClickSubmitFilter}*/}
								{/*            disableRipple*/}
								{/*        />*/}
								{/*    }*/}
								{/*    label={i18next.t("온라인 제출만")}*/}
								{/*    className={classes.checkMargin}*/}
								{/*/>*/}
							</Box>

							<Paper
								component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
								className={classes.urlInputBox}
							>
								<IconButton className={classes.iconBtnReset} onClick={handleClickKeywordSearch} disableRipple>
									<SearchNameIcon />
								</IconButton>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder={i18next.t("이름 검색")}
									value={keyword}
									onChange={handleChangeSearchKeyword}
									inputProps={{ 'aria-label': i18next.t("과제검색") }}
									onKeyDown={handleKeyDown}
								/>
							</Paper>
						</Box>

						<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={classes.submitScanText}>
							<Typography>
								{i18next.t("대조업무 완료: ")}
								<span>{answerScoreListConditionsToSearch.totalCount}</span>
							</Typography>
						</Box>
					</Box>

					<Box>
						<TableContainer component={Paper} className={classes.tableBox} sx={{ paddingRight: "20px" }}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell width='6%' align="center">{i18next.t("No.")}</TableCell>
										<TableCell width='8%' >
											<SortingHeaderCell title={i18next.t("학생명")} sortKey="studentName" sortRows={sortRows} sortingHintName={sortingHints} />
										</TableCell>
										<TableCell width='13%' >
											<SortingHeaderCell title={i18next.t("학년/반/번호")} sortKey="studentID" sortRows={sortRows} sortingHintName={sortingHints} />
										</TableCell>

										<TableCell width='10%'>{i18next.t("스마트페이퍼")}</TableCell>
										<TableCell width='15%'>{i18next.t("제출물")}</TableCell>
										<TableCell width='16%'>{i18next.t("진행상태")}</TableCell>
										<TableCell width='15%'>
											<SortingHeaderCell title={i18next.t("갱신일시")} sortKey="createdDate" sortRows={sortRows} sortingHintName={sortingHints} />
										</TableCell>
										<TableCell width='10%'>{i18next.t("제출물 관리")}</TableCell>
										<TableCell width='5%'>{i18next.t("채점")}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>

									{projectSubmissionList &&
										projectSubmissionList.map((submissionTransfer, submissionIndex) =>
											<SubmitStatusRow
												key={(submissionTransfer?.submissionCode || "") + submissionTransfer.userCode}
												ncodeAllocated={ncodeAllocated ? true : false}
												submissionTransfer={submissionTransfer}
												submissionIndex={submissionIndex}
												handleClickView={handleClickView}
												handleClickComparing={handleClickComparing}
												handleClickScored={handleClickScored}
												handleOpenPenDialog={handleOpenPenDialog}
												handleFileUpload={handleFileUpload}
												handleClickFileUpload={handleClickFileUpload}
												handleOpenDialog={handleOpenDialog}
												handleClickRetryOCR={handleClickRetryOCR}
											/>
										)
									}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>

					<SimpleDialog dialogType={dialogType} open={dialogOpen} onClose={handleCloseDialog} submissionTransfer={projectSubmissionList[transferIndex]}
						projectCode={projectCode} confirmDelte={handleConfirmDelete} overWrite={handleConfirmOverWrite} />
					{projectSubmissionList.length === 0 && projectSubmissionStatus !== StoreStatus.PROGRESS &&
						<>
							{selectedFilter === "" ?
								<Box className={classes.emptyTableData} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
									<Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
										sx={{ height: '500px' }}>
										<EmptyUserBg />
										<Typography className={classes.emptyBgTitle}>{i18next.t("출제한 대상이 없습니다.")}</Typography>
										<Typography className={classes.emptyBgText}>{i18next.t("‘과제 보기' 메뉴에서 생성한 과제 확인 후 대상을 추가하여 출제해 보세요.")}</Typography>
										<Box>
											<Button className={classes.emptyViewBtn}
												onClick={handleClickWatchingProject} disableRipple>
												<Typography>{i18next.t("과제 보기")}</Typography>
											</Button>
										</Box>

									</Box>
								</Box>
								:
								<>{onlineState === false &&
									<Box className={classes.emptyTableData} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
										<Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} sx={{ height: '500px' }}>
											<NoMatchResult />
											<Typography className={classes.emptyBgTitle}>{i18next.t("조건에 해당하는 대상이 없습니다.")}</Typography>
										</Box>
									</Box>
								}
								</>
							}
						</>
					}
					{projectSubmissionStatus === StoreStatus.PROGRESS &&
						<Backdrop
							sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
							open={projectSubmissionStatus === StoreStatus.PROGRESS}
						>
							<CircularProgress color="inherit" />
							{projectSubmissionStore.ocrRequestFlag &&
								<>
									<Typography style={{ color: '#fff' }}>{i18next.t("OCR 요청중입니다.")}</Typography>
									<Typography style={{ color: '#fff' }}>{i18next.t("잠시만 기다려주세요.")}</Typography>
								</>
							}
						</Backdrop>
					}

					<Box className={classes.pageNumber} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Stack spacing={2}>
							<Pagination
								count={projectSubmissionTotalCount < rowsPerPage ? 1 : Math.ceil(projectSubmissionTotalCount / rowsPerPage)}
								page={page}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleClickPage}
								variant="outlined"
							/>
						</Stack>
					</Box>
				</Box>
			</Box>

			<Dialog className={classes.fullDialog} fullScreen open={comparedOpen} >
				<CompareData
					projectCode={projectCode}
					handleClickClose={handleClickClose}
					submissionCode={selectedSubmissionCode}
				/>
			</Dialog>

			<Dialog className={classes.fullDialog} fullScreen open={compareNeoPenOpen} >
				<CompareNeoPenData
					projectCode={projectCode}
					templateProjectCode={templateProjectCode}
					handleClickClose={handleClickClose}
					submissionTransfer={selectedSubmissionTransfer}
					// submissionCode={selectedSubmissionCode}
					noShowPenDialog={noShowPenDialog}
				/>
			</Dialog>

			{
				openMarkDialog &&
				<Dialog className={classes.fullDialog} fullScreen open={openMarkDialog}>
					<MarkFeedback
						projectCode={projectCode}
						submissionCode={selectedSubmissionCode}
						handleClickClose={handleCloseMarkDialog}
						handleChangeSubmissionCode={handleChangeSubmissionCode}
					/>
				</Dialog>
			}

			{
				openBatchUploadDlg &&
				<BatchUploadDialog
					projectCode={projectCode}
					handleClose={handleCloseBatchUploadDlg}
					projectSubmissionList={projectSubmissionList}
					ncodeAllocation={ncodeAllocated}
					open={openBatchUploadDlg}
					templateProjectCode={templateProjectCode}
					refreshProjectSumissionList={refreshProjectSumissionList}
				/>
			}
		</div >
	);
}

export default observer<typeof SubmitManagement>(SubmitManagement);
