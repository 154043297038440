type ReturnType = string;

type CashDataBaseType = {
  promise: Promise<ReturnType>
  resolve: (ret: ReturnType) => void,
  reject: any,
}


let fetchCacherInstance = undefined as unknown as PdfFetcher;

class PdfFetcher {
  protected _cache = new Map<string, CashDataBaseType>();

  static get instance() {
    if (!fetchCacherInstance) {
      fetchCacherInstance = new PdfFetcher();
    }
    return fetchCacherInstance;
  }

  // eslint-disable-next-line class-methods-use-this
  protected searchKeyToString(args: { input: URL | RequestInfo, init?: RequestInit }) {
    const { input } = args;
    const idx = (input as string).search(/\/([^/]+)\/([^/]+)\/([^/]+)\.pdf/);

    if (idx > -1) {
      return (input as string).substring(idx);
    }

    return input.toString();
  }

  public cachedFetch(input: URL | RequestInfo, init?: RequestInit) {
    const key = this.searchKeyToString({ input, init });
    if (this._cache.has(key)) {
      // console.log("PDF: read from CACHE");
      return this._cache.get(key).promise;
    }

    // console.log("PDF: read from SERVER");
    const cacheItem: CashDataBaseType = {
      promise: null,
      resolve: null,
      reject: null,
    }

    const promise = new Promise<ReturnType>(async (resolve, reject) => {
      cacheItem.resolve = resolve;
      cacheItem.reject = reject;

      try {
        let res: Response;
        if (input.toString().startsWith("http://localhost")) {
          res = await fetch(input);
        }
        else {
          res = await fetch(input, init);
        }
        // ControlledMultiFetcher.fetch(input, init).then(async (res) => {
        const blob = await res.blob();

        const pdfBytes = new Uint8Array(await blob.arrayBuffer());
        let binary = '';
        for (let i = 0, len = pdfBytes.byteLength; i < len; i++)
          binary += String.fromCharCode(pdfBytes[i]);
        const b64encoded = window.btoa(binary);
        const dataUrl = "data:application/pdf;base64," + b64encoded;

        // const dataUrl = URL.createObjectURL(blob);
        resolve(dataUrl);
      }
      catch (e) {
        reject(e);
      }
    });

    cacheItem.promise = promise;
    this._cache.set(key, cacheItem);

    return promise;
  }
}

export default PdfFetcher;
