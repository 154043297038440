import i18next from "../lang";
import { DataUtils } from "./data-utils";



export function setQuestionHTMLWithOrderAndScore(question: string, score: number, reference: string, orderString: string) {
	if (!question
		|| (DataUtils.getTagArrFromHtml(question, 'img').length === 0
			&& !DataUtils.getTextFromHtml(question))) {
		return "<p style='font-size: 17px'>" + orderString + ". " + "</p>";
	}

	const imageList = DataUtils.getTagArrFromHtml(question, 'img');
	imageList.forEach(image => {
		image.style.cssText = 'breakBefore:avoid';
	})
	// console.log(question);
	// console.log(imageList);
	const parser = new DOMParser();
	const doc = parser.parseFromString(question, 'text/html');

	const firstTag = doc.body.firstChild;
	const lastTag = doc.body.lastChild;
	if (!firstTag || !lastTag || !(firstTag as any).style) {
		return question;
	}

	// 2024.04.03, hkkim@onthelive. 문항이 퍼블리싱에 맞춰 보일 수 있도록 해당 부분 주석처리 --->
	// firstTag.style.fontSize = "20px";
	// firstTag.style.fontWeight = "500";
	// firstTag.style.marginBottom = '15px';중
	// <---


	// viewer상 image가 가장 앞에 있는 경우, [하위 문항 번호] 이후에 '한 줄 띄어쓰기' 되도록.
	// let isImageTagAtFirst = false;
	// const secondNode = firstTag.querySelector(':first-child');
	// if (secondNode && secondNode.tagName.toLowerCase() === 'img') {
	//   isImageTagAtFirst = true;
	// }

	// order
	if (orderString) {
		// if (isImageTagAtFirst) {
		//   const newSecondNode = doc.createElement('br');
		//   firstTag.insertBefore(newSecondNode, firstTag.firstChild);
		// }

		// const spanTag = doc.createElement('span');
		// spanTag.style.color = "#000000";
		// spanTag.style.marginRight = "5px";
		// const newFirstNode = doc.createTextNode(orderString + ") ");
		// spanTag.appendChild(newFirstNode);
		// spanTag.style.fontSize = "17px";


		// firstTag.insertBefore(spanTag, firstTag.firstChild);
		// firstTag.insertBefore(newFirstNode, firstTag.firstChild);
	}

	// score
	if (score) {
		const spanTag = doc.createElement('span');
		spanTag.style.color = "#787878";
		spanTag.style.fontSize = "15px";
		spanTag.style.marginLeft = "5px";
		const scoreText = " (" + i18next.t("{{score}}점", { score }) + ")";
		const newLastNode = doc.createTextNode(scoreText);
		spanTag.appendChild(newLastNode)
		// spanTag.style.fontSize = "17px"
		lastTag.appendChild(spanTag);
		// lastTag.appendChild(newLastNode);
	}

	if (DataUtils.getTagArrFromHtml(reference, 'img').length !== 0 || DataUtils.getTextFromHtml(reference)) {
		return doc.body.innerHTML + reference;
	}

	return doc.body.innerHTML;
};
