import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	IconButton,
	LinearProgress,
	Paper,
	Popover,
	TableContainer,
	Theme,
	Toolbar,
	Tooltip,
	Typography
} from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { ReactComponent as TooltipClose } from '../../../../../common/images/TooltipClose.svg';
import { ReactComponent as TooltipTipIcon } from '../../../../../common/images/TooltipTipIcon.svg';
import { ReactComponent as WarningIcon } from '../../../../../common/images/WarningIcon.svg';
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { MarkFeedbackStyle } from "../../../styles/MarkFeedbackStyle";

import { observer } from "mobx-react";
import { convertUTCToKST } from "../../../../../hooks/convertUTCToKST";
import useNaviCustom from "../../../../../hooks/useNaviCustom";
import MessageDialog from "../../../../components/MessageDialog";

import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../../hooks/useStores';
import { ProjectStateType } from '../../../../../repositories/model/support/ProjectStateType';
import { StoreStatus } from '../../../../../stores/support/StoreStatus';
import { ResultHeaderTable } from '../../../000_Common/ResultHeader';
import { ExamRow } from './ExamRow';
import { QuestionMarkRow } from './QuestionMarkRow';

// const MarkType = {
// 	DIRECT: "DIRECT",
// 	AI: "AI"
// };

type Props = {
	projectCode?: string;
	submissionCode?: string;
	handleClickClose?: () => void;
	handleChangeSubmissionCode?: (submissionCode: string) => void;
};

function MarkFeedback(props: Props) {
	const i18next = useTranslation();
	const classes = useEmotionStyles( MarkFeedbackStyle );
	const { handleClickClose, handleChangeSubmissionCode, } = props;
	const { projectSubmissionStore, projectSubmissionAnswerStore, projectStore, messageStore } = useStores();
	const {
		submissionInfo,
		questionList,
		examOrderAndQuestionsLengthMap,
		questionKeyAndResetStateMap,
		requestionAIMap,
		settingQuestionListState
	} = projectSubmissionAnswerStore;

	let { projectCode, submissionCode } = props;
	const { project } = projectStore;
	if (!projectCode) {
		projectCode = project.code;
	}
	if (!submissionCode) {
		const submissionCode2 = projectSubmissionStore.projectSubmissionCode;
		submissionCode = projectSubmissionAnswerStore.submissionInfo.submissionCode;
		if (submissionCode2 !== submissionCode) {
			throw new Error("submissionCode is not matched");
		}
	}




	const [markType, setMarkType] = React.useState("");
	const [anchorEl, setAnchorEl] = useState<Element>(null);
	const openTool = Boolean(anchorEl);
	const id = openTool ? 'simple-popover' : undefined;
	const [openAIAlertByMounted, setOpenAIAlertByMounted] = React.useState(false);
	const [openAIAlertByClick, setOpenAIAlertByClick] = React.useState(false);
	// const [requestingAllAI, setRequestingAllAI] = React.useState(false);
	const [isUpdating, setIsUpdating] = React.useState(false);
	const [checkedAiProcessingWhenMountedArr, setCheckedAiProcessingWhenMountedArr] = React.useState([]);

	const naviCustom = useNaviCustom();

	const questionBoxTopRef = React.useRef(null);
	let questionBoxScrollingSetTimeout;

	// const disabledToMoveToNextStudent = React.useMemo(() => {
	// 	return (submissionInfo.order < 1
	// 		|| submissionInfo.totalCount < 1
	// 		|| submissionInfo.order >= submissionInfo.totalCount);

	// }, [submissionInfo?.order, submissionInfo?.totalCount]);

	const standardTotalScore = React.useMemo(() => {
		let totalScore = 0;
		questionList.map(question => {
			totalScore += Number(question.score);
		})

		return totalScore;
	}, [questionList]);

	const markedTotalScore = React.useMemo(() => {
		let totalScore = 0;
		questionList.map(question => {
			if (!question.answer) return;

			totalScore += Number(question.answer.totalScore);
		});

		return totalScore;
	}, [questionList]);

	const totalQuestionCount = React.useMemo(() => {
		return questionList.length || 0;
	}, [questionList]);

	const markedQuestionCount = React.useMemo(() => {
		let count = 0;
		questionList.map(question => {
			if (question.answer && question.answer.scored) {
				count += 1;
			}
		});

		return count;
	}, [questionList]);

	const markedPercentage = React.useMemo(() => {
		return Math.ceil((markedQuestionCount / totalQuestionCount) * 100);
	}, [totalQuestionCount, markedQuestionCount]);

	const isAiMarkingEach = React.useMemo(() => {
		return requestionAIMap.some(item => item.requesting);
	}, [requestionAIMap]);

	const notAllMarked = React.useMemo(() => {
		return totalQuestionCount !== markedQuestionCount;
	}, [totalQuestionCount, markedQuestionCount]);

	const isExported = React.useMemo(() => {
		console.log("submissionInfo?.state=", submissionInfo?.state)
		return submissionInfo?.state === ProjectStateType.EXPORTED;
	}, [submissionInfo?.state]);

	React.useEffect(() => {
		const callBack = () => handleClickClose && handleClickClose();

		if (!projectCode || !submissionCode) {
			callBack();
			return;
		}

		projectSubmissionAnswerStore.getSubmissionInfoBySubmissionCode(submissionCode, callBack);

		return () => {
			projectSubmissionAnswerStore.initAnswerScore();
		}
	}, [projectCode, submissionCode]);

	React.useEffect(() => {
		if (settingQuestionListState === StoreStatus.COMPLETED) {
			if (questionList.length > 0) {
				if (checkedAiProcessingWhenMountedArr.length === 0) {
					const checkedAiProcessingArr = Array(questionList.length).fill(false);
					setCheckedAiProcessingWhenMountedArr(checkedAiProcessingArr);

				} else {
					const checkedAll = checkedAiProcessingWhenMountedArr.every(item => item);
					const isNotMarkedAtAll = questionList.every(q => !q?.answer?.scores || q.answer.scores.length === 0);
					if (checkedAll && isNotMarkedAtAll) {
						// setOpenAIAlertByMounted(true);
					}
				}

				if (questionBoxTopRef.current) {
					questionBoxScrollingSetTimeout = setTimeout(
						() => {
							if (questionBoxTopRef.current) {
								questionBoxTopRef.current.scrollTop = 0;

							}
							clearTimeout(questionBoxScrollingSetTimeout);
						}, 0);
				}
			}
		}
	}, [settingQuestionListState, questionList, checkedAiProcessingWhenMountedArr]);

	const changeCheckedAiProcessing = (idx, checked) => {
		setCheckedAiProcessingWhenMountedArr(prev => {
			const newArr = [...prev];
			newArr[idx] = checked;
			return newArr;
		})
	};

	const topBarRightItem = () => {
		return (
			<Box display='flex'>
				{!isExported &&
					<Box className={isAiMarkingEach ? classes.saveLineMarkBtn : clsx(classes.saveLineMarkBtn, classes.saveLineMarkBtnActive)} sx={{ marginRight: "10px" }}>
						<Button disableRipple onClick={isAiMarkingEach ? handleClickDisabledSaveBtn : handleClickSave}>
							<Typography>{i18next.t("임시 저장")}</Typography>
						</Button>
					</Box>
				}
				<Box className={(isExported || isAiMarkingEach || notAllMarked) ? classes.applyMarkBtn : clsx(classes.applyMarkBtn, classes.applyMarkBtnActive)}>
					<Button disableRipple
						onClick={(isExported || isAiMarkingEach || notAllMarked) ? handleClickDisabledApplyMarkBtn : handleClickApplyMarkBtn}
					>
						<Typography>{i18next.t(isExported ? '채점 마감 완료' : '채점 마감')} </Typography>
					</Button>
				</Box>
			</Box>
		)
	};

	// const topBarLeftItem = () => {
	// 	return <></>;
	// };

	const updateAnswerInfo = async () => {
		const result = await projectSubmissionAnswerStore.updateAnswerInfo();
		return result;
	}

	const handleClickSave = async () => {
		const r2 = await messageStore.confirm(i18next.t("저장하시겠습니까?"));
		if (!r2) return;


		await updateAnswerInfo().then(async (result) => {
			if (!result) {
				await messageStore.alert(i18next.t("저장 실패했습니다."));
				return;
			}

			await messageStore.alert(i18next.t("저장되었습니다."));

			handleClickClose && handleClickClose();
		})
	};

	const handleClickApplyMarkBtn = async () => {
		const r2 = await messageStore.confirm(i18next.t("채점 반영하시겠습니까?"));
		if (!r2) return;

		await updateAnswerInfo().then(async result => {
			if (!result) {
				await messageStore.alert(i18next.t("반영 실패했습니다."));
				return;
			}

			await messageStore.alert(i18next.t("반영되었습니다."));
			await projectStore.updateProjectUserState(
				projectSubmissionAnswerStore.submissionInfo.projectCode,
				projectSubmissionAnswerStore.submissionInfo.userCode,
				ProjectStateType.SCORED
			);

			handleClickClose && handleClickClose();
		})
	};

	const handleClickDisabledApplyMarkBtn = () => {
		if (isExported) {
			messageStore.alert(i18next.t("결과 내보내기가 완료되어서 채점 마감 버튼을 누를 수 없습니다."));
		} else if (isAiMarkingEach) {
			messageStore.alert(i18next.t("AI 채점 중에는 채점 마감 버튼을 누를 수 없습니다."));
		} else if (notAllMarked) {
			messageStore.alert(i18next.t("채점이 완료되지 않아서 채점 마감 버튼을 누를 수 없습니다."));
		}
	};

	const handleClickDisabledSaveBtn = () => {
		messageStore.alert(i18next.t("AI 채점 중에는 저장 버튼을 누를 수 없습니다."));
	};

	const handleClickNextStudentBtn = async () => {
		console.log("handleClickNextStudentBtn");

		const submissionCode = await projectSubmissionAnswerStore.getSubmissionInfoByOrder();
		if (!submissionCode || !projectCode) return;

		naviCustom.go(`/assignments/${projectCode}/submissions/${submissionCode}/feedbacks/detail`);
	};

	const handleClickTooltip = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClickTooltipClosedBtn = () => {
		setAnchorEl(null);
	};

	const handleClickSubmitBtnInAIAlert = () => {
		handleCloseAIAlert();
		handleAllMarkByAI();
	};

	const handleAllMarkByAI = () => {
		projectSubmissionAnswerStore.changeAllRequestingAI(true);
	};

	const handleCloseAIAlert = () => {
		setOpenAIAlertByMounted(false);
		setOpenAIAlertByClick(false);
	};

	const handleClickMarkAI = () => {
		setOpenAIAlertByClick(true);
	};


	const currentCreatedDatetime = React.useMemo(() => {
		if (submissionInfo?.project?.createdDatetime) {
			return convertUTCToKST(submissionInfo.project.createdDatetime).format(`YYYY${i18next.t("년")} M${i18next.t("월")} D${i18next.t("일")}`);
		}

		return '-';
	}, [submissionInfo]);

	const currentGradeClassName = React.useMemo(() => {
		if (!submissionInfo) return;
		if (!submissionInfo.grade || !submissionInfo.className) return '-';

		return `${submissionInfo.grade}${i18next.t("학년")} ${submissionInfo.className}${i18next.t("반")}`;
	}, [submissionInfo, submissionInfo?.grade, submissionInfo?.className]);

	const getSubmission = (submissionCode) => {
		handleChangeSubmissionCode && handleChangeSubmissionCode(submissionCode);
	};

	const changeIsUpdating = (isUpdatingType) => {
		if (isUpdatingType === isUpdating) return;

		setIsUpdating(isUpdatingType);
	};

	const handleClickCloseBtn = async () => {
		const messageTxt = isExported ? i18next.t("나가시겠습니까?") : isUpdating ? i18next.t("저장하지 않고 나가시겠습니까?") : i18next.t("나가시겠습니까?");
		const accepted = await messageStore.confirm(messageTxt);
		if (!accepted) return;

		props.handleClickClose && props.handleClickClose();
	};

	return (
		<div className={classes.root}>
			{!projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode &&
				<Box className={classes.padding}>
					<Toolbar className={classes.topBar}>
						<Box display='flex'>
							{!isExported &&
								<Box className={isAiMarkingEach ? classes.saveLineMarkBtn : clsx(classes.saveLineMarkBtn, classes.saveLineMarkBtnActive)} sx={{ marginRight: "10px" }}>
									<Button disableRipple onClick={isAiMarkingEach ? handleClickDisabledSaveBtn : handleClickSave}>
										<Typography>{i18next.t("임시 저장")}</Typography>
									</Button>
								</Box>
							}
							<Box className={(isExported || isAiMarkingEach || notAllMarked) ? classes.applyMarkBtn : clsx(classes.applyMarkBtn, classes.applyMarkBtnActive)}>
								<Button disableRipple
									onClick={(isExported || isAiMarkingEach || notAllMarked) ? handleClickDisabledApplyMarkBtn : handleClickApplyMarkBtn}
								>
									<Typography>{i18next.t(isExported ? '채점 마감 완료' : '채점 마감')} </Typography>
								</Button>
							</Box>
							<Button onClick={handleClickCloseBtn}>X</Button>
						</Box>
					</Toolbar>
					<Box className={classes.mainContentBox}>
						<Box className={classes.newTabContent}
							display='flex'
							alignItems='center'
						>
							<Box className={classes.mainContentBoxLeft}>
								<Box className={classes.mainContentBoxHeader}>
									<Box className={classes.nextStudentBtnBox}>
										<Button className={submissionInfo?.preCode ? classes.mainContentBoxHeaderButton : clsx(classes.mainContentBoxHeaderButton, classes.disabledMainContentBoxHeaderButton)} disableRipple>
											<Tooltip title={i18next.t(submissionInfo?.preCode ? '이전 학생' : '이전 학생 없음')} classes={{ tooltip: clsx(classes.contentTooltip, classes.tooltipMarginLeft) }} placement="bottom">
												<ArrowBackIosIcon
													className={submissionInfo?.preCode ? classes.mainContentBoxHeaderButtonIcon : classes.disabledMainContentBoxHeaderButtonIcon}
													onClick={submissionInfo?.preCode ? () => getSubmission(submissionInfo?.preCode) : null}
												/>
											</Tooltip>
										</Button>
									</Box>

									<TableContainer component={Paper} className={classes.infoTableBox}>
										<ResultHeaderTable
											schoolName={submissionInfo?.school}
											currentGradeClassName={currentGradeClassName}
											userName={submissionInfo?.userName}
											year={submissionInfo?.project?.year}
											semester={submissionInfo?.project?.semesterType}
											currentCreatedDatetime={currentCreatedDatetime}
											titleBoxClassName={classes.titleBox}
										/>

									</TableContainer>

									<Box className={classes.nextStudentBtnBox}>
										<Button className={submissionInfo?.nextCode ? classes.mainContentBoxHeaderButton : clsx(classes.mainContentBoxHeaderButton, classes.disabledMainContentBoxHeaderButton)} disableRipple>
											<Tooltip title={i18next.t(submissionInfo?.nextCode ? '다음 학생' : '다음 학생 없음')} classes={{ tooltip: clsx(classes.contentTooltip, classes.tooltipMarginLeft) }} placement="bottom">
												<ArrowForwardIosIcon
													className={submissionInfo?.nextCode ? classes.mainContentBoxHeaderButtonIcon : classes.disabledMainContentBoxHeaderButtonIcon}
													onClick={submissionInfo?.nextCode ? () => getSubmission(submissionInfo?.nextCode) : null}
												/>
											</Tooltip>
										</Button>
									</Box>
								</Box>

								<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.compareTop}>
									<Typography className={classes.mainContentTitle}>{i18next.t(submissionInfo?.projectName)} (<span>{submissionInfo?.order}</span>/{submissionInfo?.totalCount})</Typography>
									<Box>
										{(isAiMarkingEach || isExported) ?
											<Button className={classes.exportBtn} disableRipple>
												<Typography>{i18next.t("AI 채점하기")}</Typography>
											</Button> :
											<Button className={clsx(classes.exportBtn, classes.exportBtnActive)} disableRipple onClick={handleClickMarkAI}>
												<Typography>{i18next.t("AI 채점하기")}</Typography>
											</Button>
										}
									</Box>
								</Box>
								<Box className={classes.compareContent}>
									<Box className={classes.compareRound}>
										{submissionInfo?.exams?.length > 0 &&
											submissionInfo?.exams.map((exam, idx) =>
												<ExamRow key={idx} examInfo={exam} />
											)}
									</Box>
								</Box>
							</Box>

							<Box className={classes.mainContentBoxRight} >
								<Box display='flex' alignItems='center' justifyContent='space-between' className={clsx(classes.compareTop, classes.compareTopColor)}>
									<Box display='flex' alignItems='center' justifyContent='center'>
										<Typography className={classes.commonSubTitle}>
											{i18next.t("채점 결과")}
										</Typography>
										<IconButton className={clsx(classes.iconBtnReset, classes.examInfoIcon)} onClick={handleClickTooltip} disableRipple >
											<WarningIcon />
										</IconButton>
										<Popover
											id={id}
											open={openTool}
											anchorEl={anchorEl}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
											className={classes.popoverTooltip}
										>
											<Box className={classes.tooltipTop}>
												<TooltipTipIcon />
											</Box>
											<Box display={'flex'} justifyContent={'flex-end'} className={classes.tooltipBtn}>
												<Button disableRipple onClick={handleClickTooltipClosedBtn}>
													<TooltipClose />
												</Button>
											</Box>
											<Typography className={classes.tooltipTitle}>{i18next.t("채점 결과는 어떻게 제공되나요?")}</Typography>
											<Typography className={classes.tooltipText}>
												{i18next.t("채점은 'AI 채점'과 '직접 채점' 방식으로 진행됩니다.")}<br /><br />
												<span>{i18next.t("AI 채점: 과제 생성 시 작성된 '모범 답안'과 '평가 기준표'를 채점 지표로 AI 엔진이 빠르고 정확하게 분석하여 평가합니다.")}<br />{i18next.t("채점 결과 점수와 근거도 자동으로 표시됩니다.")}</span><br />
												<span>{i18next.t("직접 채점: 선생님이 학생의 답안을 직접 확인하고 '채점 기준표'를 참고하여 점수를 부여합니다.")}</span>
											</Typography>
										</Popover>
									</Box>
								</Box>
								<Box>
									<Box display='flex' alignItems='center' sx={{ position: 'relative' }}>
										<Box className={classes.numberBox}>
											<Typography><span>{markedQuestionCount}</span>/{totalQuestionCount}</Typography>
										</Box>
										<Box className={classes.progressBox}>
											<LinearProgress variant="determinate" value={markedPercentage} />
										</Box>
										<Typography className={classes.progressText}>{markedPercentage}%</Typography>
									</Box>

									<Box>
										<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginTop: "40px" }}>
											<Typography className={classes.amountScoreTitle}>{i18next.t("총점")}</Typography>
										</Box>
										<Box className={classes.amountScore}>
											<Typography><span>{markedTotalScore}</span>/{standardTotalScore}<span className={classes.amountScoreText}>{i18next.t("점")}</span></Typography>
										</Box>
										<Box className={classes.scrollBottomBox} ref={questionBoxTopRef}>
											{questionList.length > 0 &&
												questionList.map((question, idx) =>
													<QuestionMarkRow
														key={question.key}
														idx={idx}
														questionInfo={question}
														examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
														markType={markType}
														resetState={questionKeyAndResetStateMap[question.key]}
														requestionAIMap={requestionAIMap}
														changeIsUpdating={changeIsUpdating}
														isExported={isExported}
														checkedAiProcessingWhenMounted={checkedAiProcessingWhenMountedArr[idx]}
														changeCheckedAiProcessing={checked => changeCheckedAiProcessing(idx, checked)}
													/>
												)}
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			}

			{!projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode &&
				openAIAlertByMounted &&
				<MessageDialog
					open={openAIAlertByMounted}
					titleText={i18next.t("채점 바로 시작")}
					submitText={i18next.t("AI채점하기")}
					onClick={handleClickSubmitBtnInAIAlert}
					onClose={handleCloseAIAlert}
					isConfirm={true}
				>
					<React.Fragment>
						<Box className={classes.uploadBox}>
							<Typography>{i18next.t("전체 문항을 'AI 채점'으로 자동 평가할 수 있습니다.")}<br />{i18next.t("AI 채점을 시작하시겠습니까?")}</Typography>
						</Box>
						<Box className={classes.SubTextBox}>
							<Typography className={classes.SubText}>{i18next.t("*문항 개수에 따라 다소 시간이 걸릴 수 있습니다.")}</Typography>
						</Box>
					</React.Fragment>
				</MessageDialog>
			}

			{openAIAlertByClick &&
				<MessageDialog
					open={openAIAlertByClick}
					titleText={i18next.t("AI 전체 채점하기")}
					submitText={i18next.t("AI채점하기")}
					onClick={handleClickSubmitBtnInAIAlert}
					onClose={handleCloseAIAlert}
					isConfirm={true}
				>
					{i18next.t("AI 채점이 실행되면 기존 결과값은 사라집니다. 실행하시겠습니까?")}
				</MessageDialog>
			}

			{projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("조회 중입니다...")}</Typography>
				</Backdrop>
			}

			{(projectSubmissionAnswerStore.isUpdatingAnswerInfo || projectStore.isUpdatingProjectUserState) &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectSubmissionAnswerStore.isUpdatingAnswerInfo || projectStore.isUpdatingProjectUserState}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("저장 중입니다...")}</Typography>
				</Backdrop>
			}

		</div>
	);
}
export default observer<typeof MarkFeedback>(MarkFeedback);


