

// TODO - Port fitz geometry methods

import { assert } from "./functions";

// See platform/java/src/com/artifex/mupdf/fitz/Point.java
export class FzPoint {
  x: number;

  y: number;

  constructor(x, y) {
    assert(typeof x === "number" && !Number.isNaN(x), "invalid x argument");
    assert(typeof y === "number" && !Number.isNaN(y), "invalid y argument");
    this.x = x;
    this.y = y;
  }

  // TODO - See 'FzMatrix.from' below
  // static from(value)

  static fromPtr(libmupdf: any, ptr: number) {
    ptr >>= 2;
    return new FzPoint(
      libmupdf.HEAPF32[ptr],
      libmupdf.HEAPF32[ptr + 1],
    );
  }
}
