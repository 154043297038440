import clsx from 'clsx';
import { Component } from 'react';
// import { ReactComponent as StudyLiveTopBarLogo } from '../../common/images/StudyLiveTopBarLogo.svg';
import { Box, Typography } from "@mui/material";
import { Styles, withStyles } from '@mui/styles';
// import Footer from '../footer/Footer';

const styles: Styles<any, any> = {
	root: {},
	topBar: {
		background: '#fff',
		borderBottom: '1px solid #c0c2c3',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '30px 0',
		boxSizing: 'border-box',
	},
	contentsBox: {
		'@media all and (min-width: 1500px)': {
			width: "1440px",
		},
		width: "1200px",
		margin: '0 auto',
		paddingTop: 50,
		paddingBottom: 70,
	},
	titleText: {
		fontSize: '1.938rem',
		fontWeight: 'bold',
		marginBottom: "40px",
	},
	textStyle1: {
		fontSize: '1.25rem',
		fontWeight: 'bold',
		marginBottom: "20px",
	},
	textStyle2: {
		fontSize: '1rem',
		marginBottom: "30px",
		textAlign: 'justify',
	},
	bold: {
		fontWeight: 'bold',
		marginBottom: "10px",
	},
	marginBottom: {
		marginBottom: "10px",
	},
	table: {
		'& table': {
			width: '100%',
			border: '1px solid #000',
			borderCollapse: 'collapse',
		},
		'& th, td': {
			border: '1px solid #000',
			padding: 10,
		},
	},
	alignCenter: {
		textAlign: 'center',
	},
};

type Props = {
	classes: any;
};

class PrivacyPolicy_ko extends Component<Props> {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				{/*<Box className={classes.topBar} id={'top'}>*/}
				{/*    <StudyLiveTopBarLogo />*/}
				{/*</Box>*/}
				<Box className={classes.contentsBox}>
					<Typography className={classes.titleText}>개인정보처리방침</Typography>

					<Typography className={classes.textStyle1}>1. 개인정보처리방침이란?</Typography>
					<Typography className={classes.textStyle2}>
						아이글 (이하"서비스")는 이용자의 ‘동의를 기반으로 개인정보를 수집•이용 및 제공’하고 있으며, ‘이용자의 권리 (개인정보
						자기결정권)를 적극적으로 보장’합니다.
						<br />
						<br />
						서비스는 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을 준수하고 있습니다.
						“개인정보처리방침”이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 준수해야 할 지침을
						의미합니다. 본 개인정보처리방침은 서비스가 제공하는 아이글 계정 기반의 서비스에 적용됩니다.
					</Typography>

					<Typography className={classes.textStyle1}>2. 개인정보 수집</Typography>
					<Typography className={classes.textStyle2}>
						서비스 제공을 위한 필요 최소한의 개인정보를 수집하고 있습니다.
						<br />
						<br />
						회원 가입 시 또는 서비스 이용 과정에서 홈페이지 또는 개별 어플리케이션이나 프로그램 등을 통해 아래와 같은 서비스 제공을 위해
						필요한 최소한의 개인정보를 수집하고 있습니다.
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>
						[아이글 계정을 외부 계정 통하지 않고 직접 생성 하는 경우]
					</Typography>
					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 필수: 아이디(이메일 주소), 비밀번호, 이름, 이메일(본인 확인 용도), 가입일시
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 선택: 생년월일, 성별, 국적
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>
						[아이글을 외부 계정 통하여 가입 하고 사용 하는 경우]
					</Typography>
					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 필수: 아이디(이메일 주소), 이름, 가입일시, 프로필사진 URL
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 선택: 생년월일, 성별, 국적
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						일부 서비스에서는 특화된 여러 기능들을 제공하기 위해 ‘아이글 계정’에서 공통으로 수집하는 정보 이외에 이용자에게 동의를
						받고 추가적인 개인정보를 수집할 수 있습니다.
					</Typography>
					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 필수정보란?: 해당 서비스의 본질적 기능을 수행하기 위한 정보
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 선택정보란?: 보다 특화된 서비스를 제공하기 위해 추가 수집하는 정보 (선택 정보를 입력하지 않은
						경우에도 서비스 이용 제한은 없습니다.)
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						개인정보를 수집하는 방법은 다음과 같습니다.
						<br />
						개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하고 있으며, 아래와 같은 방법을 통해
						개인정보를 수집합니다.
					</Typography>
					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를
						입력하는 경우
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 제휴 서비스 또는 단체 등으로부터 개인정보를 제공받은 경우
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 온•오프라인에서 진행되는 이벤트/행사 등 참여
					</Typography>

					<Typography className={classes.textStyle2}>
						서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.
						<br />
						<br />
						PC웹, 모바일 웹/앱 이용 과정에서 단말기정보(OS, 화면사이즈, 디바이스 아이디, 폰기종, 단말기 모델명), IP주소, 쿠키, 방문일시,
						부정이용기록, 서비스 이용 기록, 계정 수정일시, 로그인 앱 정보, 앱 가입일시, 가장 최근 로그인 일시, 로그인 횟수, 스마트펜
						디바이스 정보(필기 데이터, 파일, 디바이스 Mac address, Inklink url 및 시간, 위치 정보 등), 사용한 리소스 정보(타입, 사용량,
						개수, 사용일시), 데이터 동기화 로그(데이터 타입, 데이터 고유 번호, 데이터를 업로드한 앱 정보, 동기화 결과, 에러 로그, 재시도
						횟수, 동기화 일시) 등의 정보가 자동으로 생성되어 수집될 수 있습니다.
					</Typography>

					<Typography className={classes.textStyle1}>3. 개인정보 이용</Typography>

					<Typography className={classes.textStyle2}>
						회원관리, 서비스 제공•개선, 신규 서비스 개발 등을 위해 이용합니다. 회원 가입 시 또는 서비스 이용 과정에서 홈페이지 또는 개별
						어플리케이션이나 프로그램 등을 통해 아래와 같이 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다.
					</Typography>

					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 회원 식별/가입의사 확인, 본인/연령 확인, 부정이용 방지
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 신규 서비스 개발, 다양한 서비스 제공, 문의사항 또는 불만처리, 공지사항 전달
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정 이용 행위 등 포함)에 대한 방지 및
						제재
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 마케팅에 활용
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계, 프라이버시 보호 측면의 서비스 환경
						구축, 서비스 개선에 활용
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 서비스앱(네오스튜디오, 펜매니저, 그리다보드, 페이퍼튜브 등)의 활용 목적 상 이용될 수 있음
					</Typography>

					<Typography className={classes.textStyle1}>4. 개인정보 제공</Typography>

					<Typography className={classes.textStyle2}>
						서비스는 이용자의 별도 동의가 있는 경우나 법령에 규정된 경우를 제외하고는 이용자의 개인정보를 제3자에게 제공하지 않습니다.
						제3자 서비스와의 연결을 위해 아래와 같이 개인정보를 제공하고 있습니다.
						<br />
						<br />
						서비스는 이용자의 사전 동의 없이 개인정보를 제3자에게 제공하지 않습니다. 다만, 이용자가 외부 제휴사 등의 서비스를 이용하기
						위하여 필요한 범위 내에서 이용자의 동의를 얻은 후에 개인정보를 제3자에게 제공하고 있습니다.
					</Typography>
					<Box className={classes.table}>
						<table>
							<th>서비스명</th>
							<th>제공받은자</th>
							<th>제공하는목적</th>
							<th>제공하는 개인정보항목</th>
							<tr className={classes.alignCenter}>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
							</tr>
						</table>
					</Box>
					<Typography className={classes.textStyle2}>
						서비스 제공을 위해 아래와 같은 업무를 위탁하고 있습니다.
						<br />
						<br />
						서비스 제공에 있어 필요한 업무 중 일부를 외부 업체로 하여금 수행하도록 개인정보를 위탁하고 있습니다. 그리고 위탁 받은 업체가
						관계 법령을 준수하도록 관리•감독하고 있습니다.
					</Typography>

					<Box className={classes.table}>
						<table>
							<th>업체명</th>
							<th>위탁업무 목적</th>
							<tr className={classes.alignCenter}>
								<td>GoogleInc</td>
								<td>클라우드 플랫폼 서비스 사용(서비스 운영, 데이터 저장/관리)</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>MyScript</td>
								<td>필기 인식 엔진 사용</td>
							</tr>
						</table>
					</Box>
					<Typography className={classes.textStyle2}>개인정보 처리위탁 중 국외에서 처리하는 위탁업무는 아래와 같습니다.</Typography>

					<Box className={classes.table} mb={3}>
						<table>
							<th>수탁업체</th>
							<th>정보관리책임자 및 연락처</th>
							<th>이전목적</th>
							<th>이전되는 개인정보 항목</th>
							<th>이전되는 국가</th>
							<th>이전일시 및 방법</th>
							<th>개인정보 이용기간</th>
							<tr className={classes.alignCenter}>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
							</tr>
						</table>
					</Box>

					<Typography className={classes.textStyle1}>5. 개인정보 파기</Typography>
					<Typography className={classes.textStyle2}>
						개인정보는 수집 및 이용목적이 달성되면 지체 없이 파기하며, 절차 및 방법은 아래와 같습니다.
						<br />
						<br />
						전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나 소각하여
						파기합니다.
						<br />
						<br />이 외에 법령에 따라 일정기간 보관해야 하는 개인정보 및 해당 법령은 아래 표와 같습니다.
					</Typography>

					<Box className={classes.table} mb={3}>
						<table>
							<th>보존항목</th>
							<th>근거법령</th>
							<th>보존기간</th>
							<tr className={classes.alignCenter}>
								<td>계약 또는 청약철회 등에 관한 기록</td>
								<td rowSpan={4}>전자상거래 등에서의 소비자 보호에 관한 법률</td>
								<td>5년</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>대금결제 및 재화 등의 공급에 관한 기록</td>
								<td>5년</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
								<td>3년</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>표시/광고에 관한 기록</td>
								<td>6개월</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>세법이 규정하는 모든 거래에 솬한 장부 및 증빙 서류</td>
								<td>국세기본법</td>
								<td>5년</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>전자금융 거래에 관한 기록</td>
								<td>전자금융거래법</td>
								<td>5년</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>서비스 방문 기록</td>
								<td>통신비밀보호법</td>
								<td>3개월</td>
							</tr>
						</table>
					</Box>

					<Typography className={classes.textStyle1}>6. 기타</Typography>
					<Typography className={classes.textStyle2}>
						서비스는 여러분의 권리를 보호합니다. 이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 수집・이용에 대한 동의
						철회 또는 가입 해지를 요청할 수 있습니다. 보다 구체적으로는 서비스 내 설정을 통한 회원정보 수정 기능이나 회원탈퇴 기능을
						이용할 수 있고, 고객센터를 통해 서면, 전화 또는 이메일로 요청하시면 지체 없이 조치하겠습니다. 개인정보의 오류에 대한 정정을
						요청하신 경우 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 웹기반 서비스의 제공을 위하여 쿠키를
						이용하는 경우가 있습니다. 쿠키는 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 사용됩니다.
					</Typography>

					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 쿠키란?
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은
						텍스트 파일로서 이용자 컴퓨터에 저장됩니다.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 사용목적
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로
						불러오는 쿠키를 사용합니다.
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의
						디바이스에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하게 됩니다.
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;쿠키는 이용자가 웹 사이트를 방문할 때, 웹 사이트 사용을
						설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다.
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;또한, 이용자의 웹사이트 방문 기록, 이용 형태를 통해서
						최적화된 광고 등 맞춤형 정보를 제공하기 위해 활용됩니다.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 쿠키 수집 거부
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o 쿠키에는 이름, 전화번호 등 개인을 식별하는 정보를 저장하지 않으며, 이용자는
						쿠키 설치에 대한 선택권을 가지고 있습니다.
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든
						쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다.
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다만, 쿠키 설치를 거부할 경우 웹 사용이 불편해지며, 로그인이
						필요한 일부 서비스 이용에 어려움이 있을 수 있습니다. 설정 방법의 예<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o Internet Explorer의 경우: 웹 브라우저 상단의 도구 메뉴 ＞ 인터넷 옵션 ＞
						개인정보 ＞ 설정
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o Chrome의 경우: 웹 브라우저 우측의 설정 메뉴 ＞ 화면 하단의 고급 설정 표시 ＞
						개인정보의 콘텐츠 설정 버튼 ＞ 쿠키
					</Typography>

					<Typography className={classes.textStyle2}>
						서비스는 이용자의 소중한 개인정보 보호를 위해 다음의 노력을 하고 있습니다. 이용자의 개인정보를 가장 소중한 가치로 여기고
						개인정보를 처리함에 있어서 다음과 같은 노력을 다하고 있습니다.
						<br />
						<br />
						이용자의 개인정보를 암호화하고 있습니다.
					</Typography>

					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해
						외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다.
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;해커 등의 침입을 탐지하고 차단할 수 있는 시스템을 설치하여 24시간 감시하고
						있으며, 백신 프로그램을 설치하여 시스템이 최신 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다.
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;또한 새로운 해킹/보안 기술에 대해 지속적으로 연구하여 서비스에 적용하고
						있습니다.
					</Typography>

					<Typography className={classes.textStyle2}>
						서비스는 유럽연합 일반 개인정보보호법(General Data Protection Regulation) 및 각 회원국의 법률을 준수합니다.
						<br />
						<br />
						유럽연합 내 이용자를 대상으로 서비스를 제공하는 경우, 아래 내용이 적용될 수 있습니다.
					</Typography>
					<Typography className={clsx(classes.textStyle2, classes.bold)}>[개인정보 처리의 목적 및 처리근거]</Typography>
					<Typography className={classes.textStyle2}>
						서비스는 수집한 개인정보를 "3. 개인정보의 이용"에 기재된 목적으로만 이용하며, 사전에 이용자에게 해당 사실을 알리고 동의를
						구하고 있습니다. 그리고 GDPR 등 적용되는 법률에 따라, 서비스는 아래 하나에 해당하는 경우에 이용자의 개인정보를 처리할 수
						있습니다.
					</Typography>
					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 정보주체의 동의
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 정보주체와의 계약의 체결 및 이행을 위한 경우
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 법적 의무사항 준수를 위한 경우
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 정보주체의 중대한 이익을 위해 처리가 필요한 경우
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 서비스의 적법한 이익 추구를 위한 경우(정보주체의 이익과 권리 또는 자유가 그 이익보다 중요한
						경우는 제외)
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>[유럽연합 내 이용자의 권리 보장]</Typography>
					<Typography className={classes.textStyle2}>
						서비스는 "여러분의 권리를 보호합니다"에서 알린 바와 같이, 이용자의 개인정보를 소중하게 보호합니다. GDPR 등 적용되는 법률에
						따라, 이용자는 자신의 개인정보를 다른 관리자에게 이전해 달라고 요청할 수 있고, 자신의 정보 처리 거부를 요청 할 수 있습니다.
						그리고 이용자는 개인정보보호 권한 당국에 불만을 제기할 권리가 있습니다. 또한, 서비스는 이벤트 및 광고 등 마케팅을 제공하기
						위해 개인정보를 활용할 수 있으며, 사전에 이용자의 동의를 구하고 있습니다. 이용자는 원하지 않은 경우 언제든지 동의를 철회할 수
						있습니다. 위와 관련한 요청사항은 고객센터를 통해 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>개인정보보호와 관련해서 궁금하신 사항은?</Typography>
					<Typography className={classes.textStyle2}>
						서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 개인정보 보호책임자 및 담당부서로 연락해
						주시기 바랍니다. 서비스는 여러분의 목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록 최선을 다하겠습니다.
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>개인정보 보호책임자</Typography>

					<Typography className={classes.textStyle2} style={{ color: '#e8441e' }}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 책임자: 김정호
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 소속: ㈜네오랩컨버전스
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 전화: 02-3462-2981
					</Typography>
					<Typography className={classes.textStyle2}>
						또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
					</Typography>

					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 개인정보침해 신고센터
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o (국번없이)118
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o https://privacy.kisa.or.kr
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 대검찰청 사이버수사과
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o (국번없이)1301
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o cid@spo.go.kr
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 경찰청 사이버안전국
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o (국번없이)182
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o https://cyberbureau.police.go.kr
						<br />
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>개인정보처리방침이 변경되는 경우</Typography>

					<Typography className={classes.textStyle2}>
						법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보처리방침을 수정할 수 있습니다. 개인정보처리방침이 변경되는 경우
						서비스는 변경 사항을 게시하며, 변경된 개인정보처리방침은 게시한 날로부터 7일 후부터 효력이 발생합니다.
						<br />
						<br />
						서비스는 이용자 여러분의 정보를 소중히 생각하며, 이용자가 더욱 안심하고 서비스를 이용할 수 있도록 최선의 노력을 다할것을
						약속드립니다.
					</Typography>
				</Box>
				{/*<Footer />*/}
			</div>
		);
	}
}

export default withStyles(styles)(PrivacyPolicy_ko);
