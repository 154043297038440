import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	IconButton,
	MenuItem,
	MenuList,
	Popover,
	Switch,
	Tab,
	Tabs,
	Theme,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { ReactComponent as DeleteSubMenu } from '../../../../common/images/DeleteSubMenu.svg';
import { ReactComponent as DotsThreeMore } from '../../../../common/images/DotsThreeMore.svg';
import { ReactComponent as EditContainedIcon } from '../../../../common/images/EditContainedIcon.svg';
import { ReactComponent as PageUpIcon } from "../../../../common/images/PageUpIcon.svg";
import { SchoolLevelSubjects } from "../../../../common/SchoolLevelSubjects";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { HtmlTooltip } from "../../../components/Tooltip";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TemplateProjectViewStyle } from "../../styles/TemplateProjectViewStyle";

import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useStores } from "../../../../hooks/useStores";
import { ProjectCreateStatusType } from "../../../../repositories/model/support/ProjectCreateStatusType";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
import { ProjectSchoolType, ProjectSharedState } from "../../../../stores/enums/ProjectStoreEnums";
import { DetailPageType } from "../../../../stores/support/DetailPageType";
import { setQuestionHTMLWithOrderAndScore } from "../../../../util/setQuestionHTMLWithOrderAndScore";
import { getSemesterTypeString } from "../../000_Common/001_Functions/getSemesterTypeString";
import { AnswerTab } from "../../000_Common/002_Tabs/AnswerTab";
import { PlanTab } from "../../000_Common/002_Tabs/PlanTab";
import { QuestionAndReferenceTab } from "../../000_Common/002_Tabs/QuestionAndReferenceTab";
import { RubricTab } from "../../000_Common/002_Tabs/RubricTab";

type Props = {
};

function TemplateProjectView(props: Props) {
	const params = useParams();
	const { navigateStore, projectStore, projectRagStore, projectExamStore, projectRubricStore, messageStore, userStore } = useStores();
	const i18next = useTranslation();

	const classes = useEmotionStyles(TemplateProjectViewStyle);
	// const { navigate, location } = props;
	const { project, projectStates } = projectStore;
	// const { projectExamList } = projectExamStore;
	// const { projectRagList, projectExamQuestionList } = projectRagStore;
	const {
		projectExamQuestionsList,
		questionListLenObjPerExam,
		projectRubricTransferList,
		questionKeyAndSelectedProjectRubricIdxMap,
		questionKeyAndSystemRubricConditionsToSearchMap,
		questionKeyAndSystemRubricListMap,
		questionKeyAndSelectedSystemRubricIdsMap,
	} = projectRubricStore;

	const basicInfoRef = React.useRef<HTMLDivElement>();
	// const testTargetRef = React.useRef();
	const planRef = React.useRef();
	const questionRef = React.useRef<HTMLDivElement>();
	const goodAnswerRef = React.useRef<HTMLDivElement>();
	const rubricRef = React.useRef<HTMLDivElement>();

	const [tabIndex, setTabIndex] = React.useState(0);
	const [projectCode, setProjectCode] = React.useState("");
	// const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
	// const [projectUserListOpenArrowRotated, setProjectUserListOpenArrowRotated] = React.useState(false);
	// const [projectUserListSortingArrowRotated, setProjectUserListSortingArrowRotated] = React.useState(true);// true : asc
	// const [basicInfoComplete, setBasicInfoComplete] = React.useState(false);
	// const [questionDone, setQuestionDone] = React.useState(false);
	// const [testTargetDone, setTestTargetDone] = React.useState(false);
	// const [isEmptyTestTarget, setIsEmptyTestTarget] = React.useState(true);
	const [projectPosed, setProjectPosed] = React.useState(false);
	const [keyword, setKeyword] = React.useState(null);
	const [selectedGroup, setSelectedGroup] = React.useState(-1);
	const [rotated, setRotated] = React.useState(false);
	const [testTargetEditorOpen, setTestTargetEditorOpen] = React.useState(false);
	// const [anchorEl, setAnchorEl] = React.useState(null);
	// const [targetProjectName, setTargetProjectName] = React.useState("");
	// const [steps, setSteps] = React.useState([
	// 	{ step: 1, active: false, label: i18next.t('과제 개요') },
	// 	{ step: 2, active: false, label: i18next.t('문항 생성') },
	// 	{ step: 3, active: false, label: i18next.t('참고 자료') },
	// 	{ step: 4, active: false, label: i18next.t('모범 답안') },
	// 	{ step: 5, active: false, label: i18next.t('평가 기준표') },
	// ]);
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	// const [menuItemAnchorEl, setMenuItemAnchorEl] = React.useState(null);
	const [moreAnchorEl, setMoreAnchorEl] = React.useState(null);
	const [isSelectingAll, setIsSelectingAll] = React.useState(false);
	// const [isSelectingProjectRubricList, setIsSelectingProjectRubricList] = React.useState(false);

	// const menuItemOpen = Boolean(menuItemAnchorEl);
	// const isMenuOpen = Boolean(menuAnchorEl);
	const registrationOpen = Boolean(moreAnchorEl);
	// const openTool = Boolean(anchorEl);
	// const id2 = openTool ? 'simple-popover' : undefined;

	const naviCustom = useNaviCustom();


	React.useEffect(() => {
		navigateStore.changeNavigateRootPage(false);
		navigateStore.changeNavigateDetailPage(DetailPageType.PROJECT);
		navigateStore.changeTopBarLeftItem(topBarLeftItem);

		return () => {
			navigateStore.changeNavigateDetailPage(null);
			navigateStore.changeTopBarLeftItem(null);
		}
	}, []);

	React.useEffect(() => {
		setKeyword(null);
		setSelectedGroup(-1);
	}, [testTargetEditorOpen])

	React.useEffect(() => {
		getMyUsers()
	}, [selectedGroup, rotated, page])

	React.useEffect(() => {
		setProjectPosed(checkPosed());
	}, [projectStore.project.state])

	React.useEffect(() => {
		const currentProjectCode = params?.projectCode;
		if (!currentProjectCode || currentProjectCode === projectCode) return;

		setProjectCode(currentProjectCode);
	}, [params]);

	React.useEffect(() => {
		if (!projectCode) return;

		projectStore.downloadProjectInfoAndStore(projectCode);
		projectStore.getProjectStates(projectCode);
		projectStore.getProjectUserTransfers(projectCode, []);
		projectStore.getProjectPlanTransfer(projectCode);
		projectExamStore.downloadProjectExamListAndStore(projectCode);
		projectRagStore.getProjectRag(projectCode);
		projectRubricStore.getProjectExamQuestions(projectCode)
		setIsSelectingAll(true);

		return () => {
			projectStore.init();
			projectExamStore.init();
			projectRagStore.init();
			projectRubricStore.init();
		}
	}, [projectCode]);

	React.useEffect(() => {
		if (!isSelectingAll) return;

		if (
			!projectStore.isSelectingProjectInfo &&
			!projectStore.isSelectingProjectStates &&
			!projectStore.isSelectingProjectUserTransfers &&
			!projectStore.isSelectingProjectPlanTransfer &&
			!projectExamStore.isSelectingProjectExamList &&
			!projectRagStore.isSelectingProjectRag &&
			!projectRubricStore.isSelectingProjectExamQuestions
			// isSelectingProjectRubricList
		) {
			setIsSelectingAll(false);
			// setIsSelectingProjectRubricList(false);
		}

	}, [
		isSelectingAll,
		projectStore.isSelectingProjectInfo,
		projectStore.isSelectingProjectStates,
		projectStore.isSelectingProjectUserTransfers,
		projectStore.isSelectingProjectPlanTransfer,
		projectExamStore.isSelectingProjectExamList,
		projectRagStore.isSelectingProjectRag,
		projectRubricStore.isSelectingProjectExamQuestions,
		// isSelectingProjectRubricList
	]);

	React.useEffect(() => {
		navigateStore.changeTopBarLeftItem(topBarLeftItem);
	}, [project.name]);

	const topBarLeftItem = () => {
		return (
			<p>&nbsp;&nbsp;&nbsp;<strong>{project.name}</strong></p>
		)
	};

	const checkPosed = (localState?: ProjectStateType) => {
		localState = localState || projectStore.project.state;
		switch (localState) {
			case ProjectStateType.CREATED:
				return false;

			case ProjectStateType.POSED:
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
			case ProjectStateType.COMPARED:
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
				return true;
			default:
				return false;
		}
	}

	const checkSubmitted = (type: ProjectStateType) => {
		switch (type) {
			case ProjectStateType.CREATED:
			case ProjectStateType.POSED:
				return false;
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
			case ProjectStateType.COMPARED:
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
				return true;
			default:
				return false;
		}
	}

	const getMyUsers = () => {
		let sorting: { column: string, hint?: string }[] = [{ column: "up.name" }];
		if (rotated)
			sorting[0].hint = "DESC";
		else
			sorting[0].hint = "ASC";
		let groupCode = null;
		if (selectedGroup !== -1 && userStore.myGroups[selectedGroup]) {
			groupCode = userStore.myGroups[selectedGroup].code;
		}
		userStore.getMyUsers(groupCode, keyword, page - 1, rowsPerPage, sorting);
	}

	const handleClickMoreButton = (e) => {
		setMoreAnchorEl(e.currentTarget)
	}
	const handleClosePopover = () => {
		setMoreAnchorEl(null);
	}
	const handleClickDel = async () => {
		if (checkPosed(projectStore.project.state)) {
			//제출 했음
			const findingResult = projectStore.projectUsers.find(user => checkSubmitted(user.state));
			// console.log(findingResult);
			if (findingResult === undefined) {
				//기획은 다른 멘트. 온라인, 오프라인 출제 분류 기능 추가 될때 수정
				let confirm = await messageStore.confirm(i18next.t('모든 데이터는 삭제와 동시에 복구할 수 없습니다. {{projectName}} 과제를 삭제하시겠습니까?', { projectName: projectStore.project.name }));
				if (confirm) {
					projectStore.deleteProject();
					await messageStore.alert(i18next.t('삭제되었습니다.'));
					naviCustom.go('/projects');
					// props.navigate('/projects');
				}
			} else {
				// window.alert("학생 제출물이 있어 과제를 삭제 할 수 없습니다.")
				messageStore.alert(i18next.t('학생 제출물이 있어 과제를 삭제 할 수 없습니다.'));
			}
		} else {
			//제출 안했음
			let confirm = await messageStore.confirm(i18next.t('모든 데이터는 삭제와 동시에 복구할 수 없습니다. {{projectName}} 과제를 삭제하시겠습니까?', { projectName: projectStore.project.name }));
			if (confirm) {
				projectStore.deleteProject();
				await messageStore.alert(i18next.t('삭제되었습니다.'));
				naviCustom.go('/projects');
				// props.navigate('/projects');
			}
		}
	}

	const handleChangeTab = (event, newIndex) => {
		switch (newIndex) {
			case 0:
				basicInfoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				break;
			case 1:
				questionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				break;
			case 2:
				goodAnswerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				break;
			case 3:
				rubricRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				break;
		}

		setTabIndex(newIndex);
	};

	const handleClickScrollToTopBtn = () => {
		const projectInfo = document.getElementById('projectInfo');
		if (projectInfo) {
			projectInfo.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const handleClickInfoEdit = () => {
		naviCustom.go(`/projects/${projectCode}/create/info`);
		// props.navigate(`/projects/${projectCode}/create/info`);
	};

	const handleClickQuestionEdit = () => {
		naviCustom.go(`/projects/${projectCode}/create/question`);
		// props.navigate(`/projects/${projectCode}/create/question`);
	};

	const handleClickRagEdit = () => {
		naviCustom.go(`/projects/${projectCode}/create/rag`);
		// props.navigate(`/projects/${projectCode}/create/rag`);
	};

	const handleClickAnswerEdit = () => {
		naviCustom.go(`/projects/${projectCode}/create/answer`);
		// props.navigate(`/projects/${projectCode}/create/answer`);
	};

	const handleClickRubricEdit = () => {
		naviCustom.go(`/projects/${projectCode}/create/rubric`);
		// props.navigate(`/projects/${projectCode}/create/rubric`);
	};

	const currentSchoolLevelObj = React.useMemo(() => {
		return SchoolLevelSubjects.find(school => school.name === project?.schoolType);
	}, [project]);

	const currentSubjectGroupObj = React.useMemo(() => {
		if (!currentSchoolLevelObj) return;

		return currentSchoolLevelObj.subjectGroups.find(group => group.name === project?.subject);
	}, [project, currentSchoolLevelObj]);

	const currentSubjectObj = React.useMemo(() => {
		if (!currentSubjectGroupObj) return;

		return currentSubjectGroupObj.subjects.find(subject => subject.name === project?.subjectName);
	}, [project, currentSubjectGroupObj]);


	const isEmptyStage = (type: ProjectCreateStatusType) => {
		if (!type || !projectStates) return;

		const isValidType = Object.values(ProjectCreateStatusType).includes(type);
		if (!isValidType) return true;

		return !projectStates[type.toLowerCase()];
	};

	const activeInfo = React.useMemo(() => {
		if (!projectStates) return;

		return projectStates[ProjectCreateStatusType.INFO.toLowerCase()];
	}, [projectStates]);

	const activePlan = React.useMemo(() => {
		if (!projectStates) return;

		return projectStates[ProjectCreateStatusType.PLAN.toLowerCase()];
	}, [projectStates]);

	const activeExam = React.useMemo(() => {
		if (!projectStates) return;

		return projectStates[ProjectCreateStatusType.EXAM.toLowerCase()];
	}, [projectStates]);

	const activeRag = React.useMemo(() => {
		if (!projectStates) return;

		return projectStates[ProjectCreateStatusType.RAG.toLowerCase()];
	}, [projectStates]);

	const activeAnswer = React.useMemo(() => {
		if (!projectStates) return;

		return projectStates[ProjectCreateStatusType.ANSWER.toLowerCase()];
	}, [projectStates]);

	const activeRubric = React.useMemo(() => {
		if (!projectStates) return;

		return projectStates[ProjectCreateStatusType.RUBRIC.toLowerCase()];
	}, [projectStates]);

	const handleChangeSharedState = () => {
		const isShared = projectStore.project.sharedState === ProjectSharedState.PUBLIC;
		const sharedState = !isShared ? ProjectSharedState.PUBLIC : ProjectSharedState.PRIVATE;
		projectStore.modifyProjectSharedState(projectCode, sharedState);
	}

	const currentSemesterString = getSemesterTypeString(project?.year, project?.semesterType);
	const isNotSimpleModeAssignment = currentSemesterString.length > 1;

	return (
		<div className={classes.root}>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					// background: 'red',
					height: 'calc(100vh - 86px)'
				}}
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box >
						<Box display='flex' alignItems='center' justifyContent='space-between'>
							<Typography className={classes.commonSubTitleN}>{i18next.t('평가과제')}</Typography>

							<Box display='flex' alignItems='center' >
								<Box sx={{ marginLeft: "15px" }}>
									{i18next.t("과제 공유")}
								</Box>
								<Box sx={{ marginLeft: "0px" }}>
									<HtmlTooltip title={i18next.t("과제 공유")}>
										<span>
											<Switch checked={project.sharedState === ProjectSharedState.PUBLIC}
												disabled={!projectStates || !projectStates.rubric}
												onChange={handleChangeSharedState}
												color='default' />
										</span>
									</HtmlTooltip>
								</Box>

								<Box sx={{ marginLeft: "15px" }}>
									<Button onClick={handleClickInfoEdit}
										className={classes.examEditBtn}>
										<Typography>{i18next.t("평가과제 편집")}</Typography>
									</Button>
								</Box>

								<Box sx={{ marginLeft: "15px" }}>
									<IconButton className={clsx(classes.iconBtnReset, classes.iconBtnMoreMenuN)} onClick={handleClickMoreButton} disableRipple>
										<DotsThreeMore />
									</IconButton>
									<Popover
										id="simple-popper"
										open={registrationOpen}
										anchorEl={moreAnchorEl}
										onClose={handleClosePopover}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										className={classes.popoverBox}
									>
										<Box>
											<MenuList>
												<MenuItem onClick={handleClickDel} disableRipple>
													<DeleteSubMenu /><Typography className={classes.deleteText}>{i18next.t("과제 삭제")}</Typography>
												</MenuItem>
											</MenuList>
										</Box>
									</Popover>
								</Box>
							</Box>
						</Box>
					</Box>
					{/*<Typography className={classes.commonSubTextN}>기본 정보부터 평가과제 내용을 한눈에 바로 확인할 수 있습니다.</Typography>*/}
					<Typography className={classes.commonSubTextN}>{i18next.t('과제 개요부터 평가과제 내용을 한눈에 바로 확인할 수 있습니다.')}</Typography>

					<Box>
						<Tabs value={tabIndex} onChange={handleChangeTab} className={classes.trigger2}>
							{/* <Tab value={0} label={'기본 정보'} disableRipple /> */}
							<Tab value={0} label={i18next.t('과제 개요')} disableRipple />
							<Tab value={1} label={i18next.t('문항 및 참고 자료')} disableRipple />
							<Tab value={2} label={i18next.t('모범 답안')} disableRipple />
							<Tab value={3} label={i18next.t('평가 기준표')} disableRipple />
						</Tabs>
						<div className={classes.root}>
							<Box className={classes.scrollBox}>
								<Box ref={basicInfoRef}>
									<Box className={classes.commonSubTitleBasicBox} >
										{/*<Typography className={classes.commonSubTitleBasic}>기본 정보</Typography>*/}
										<Typography className={classes.commonSubTitleBasic18px} id={"projectInfo"}>{i18next.t('과제 개요')}</Typography>
										{activeInfo &&
											<IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple onClick={handleClickInfoEdit}>
												<EditContainedIcon />
											</IconButton>
										}
									</Box>

									{/*{!isEmptyStage(TemplateProjectStateType.INFO) &&*/}
									{activeInfo &&
										<Box className={classes.listBoxTop}>
											{isNotSimpleModeAssignment &&
												<>
													<Box className={classes.displayStyle}>
														<Typography className={classes.basicTitle2}>{i18next.t('교육과정 시기')}</Typography>
														<Typography className={classes.basicText2}>{`${project?.curriculumYear || 0}${i18next.t('년 개정')}`}</Typography>
													</Box>
													<Box className={classes.displayStyle}>
														<Typography className={classes.basicTitle2}>{i18next.t('학교급')}</Typography>
														<Typography className={classes.basicText2}>
															{project.schoolType === ProjectSchoolType.ELEM ? i18next.t('초등학교') :
																project.schoolType === ProjectSchoolType.MIDD ? i18next.t('중학교') : i18next.t('고등학교')}
														</Typography>
													</Box>
													<Box className={classes.displayStyle}>
														<Typography className={classes.basicTitle2}>{i18next.t('학년/교과/과목')}</Typography>
														<Typography className={classes.basicText2}>{`${project?.grade || 0}${i18next.t('학년')} / ${currentSubjectGroupObj?.text || '-'} / ${currentSubjectObj?.text || '-'}`}</Typography>
														{/*<Typography className={classes.basicText}>{`${project.year}${i18next.t('학년도')} / ${project.semesterType === ProjectSemesterType.FIRST ? i18next.t('1') : i18next.t('2')}${i18next.t('학기')}`}</Typography>*/}
													</Box>
												</>
											}
											{!isNotSimpleModeAssignment &&
												<Box className={classes.displayStyle}>
													<Typography className={classes.basicTitle2}>{i18next.t('레벨')}</Typography>
													<Typography className={classes.basicText2}>
														{`${project?.grade || ""}`}
													</Typography>
													{/*<Typography className={classes.basicText}>{`${project.year}${i18next.t('학년도')} / ${project.semesterType === ProjectSemesterType.FIRST ? i18next.t('1') : i18next.t('2')}${i18next.t('학기')}`}</Typography>*/}
												</Box>

											}
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle2}>{i18next.t('단원명')}</Typography>
												<Typography className={classes.basicText2}>{project?.chapter || '-'}</Typography>
											</Box>
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle2}>{i18next.t('평가과제명')}</Typography>
												<Typography className={classes.basicText2}>{project.name || '-'}</Typography>
											</Box>
										</Box>
									}
								</Box>
								{isNotSimpleModeAssignment &&
									<Box className={classes.sectionTitle}>
										<Typography className={classes.commonSubTitleBasic18px} ref={planRef}>{i18next.t('성취기준 및 평가기준')}</Typography>
										{activePlan &&
											<IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple onClick={handleClickInfoEdit}>
												<EditContainedIcon />
											</IconButton>
										}
									</Box>
								}

								{/*{!isEmptyStage(TemplateProjectStateType.PLAN) &&*/}
								{isNotSimpleModeAssignment && activePlan &&
									<PlanTab />
								}

								<Box className={clsx(classes.sectionTitle, classes.commonSubTitleBasicBox)}>
									<Typography className={classes.commonSubTitleBasic18px} ref={questionRef}>{i18next.t('문항')}</Typography>
									{activeExam &&
										<IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple onClick={handleClickQuestionEdit}>
											<EditContainedIcon />
										</IconButton>
									}

									<Typography className={classes.commonSubTitleBasic18px}>&nbsp;{i18next.t('참고자료')}</Typography>
									{activeRag &&
										<IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple onClick={handleClickRagEdit}>
											<EditContainedIcon />
										</IconButton>
									}
								</Box>

								{/*{ (!isEmptyStage(TemplateProjectStateType.EXAM) || !isEmptyStage(TemplateProjectStateType.RAG)) &&*/}
								{(activeExam || activeRag) &&
									<QuestionAndReferenceTab
										onClickScrollToTopBtn={handleClickScrollToTopBtn}
										projectExamList={projectExamStore.projectExamList}
										onSetQuestionHTMLWithOrderAndScore={setQuestionHTMLWithOrderAndScore}
										sideBarStepNum={navigateStore.sideBarMakeProjectStepNum}
										handleClickRagEdit={handleClickRagEdit}
										questionRef={questionRef}
										handleClickQuestionEdit={handleClickQuestionEdit}
									/>
								}

								<Box className={clsx(classes.sectionTitle, classes.commonSubTitleBasicBox)}>
									<Typography className={classes.commonSubTitleBasic18px} ref={goodAnswerRef}>{i18next.t('모범 답안')}</Typography>
									{activeAnswer &&
										<IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple onClick={handleClickAnswerEdit}>
											<EditContainedIcon />
										</IconButton>
									}
								</Box>

								{/*{ !isEmptyStage(TemplateProjectStateType.ANSWER) &&*/}
								{activeAnswer &&
									<AnswerTab
										projectExamList={projectExamStore.projectExamList}
									/>
								}

								<Box className={clsx(classes.sectionTitle, classes.commonSubTitleBasicBox)}>
									<Typography className={classes.commonSubTitleBasic18px} ref={rubricRef}>{i18next.t('평가 기준표')}</Typography>
									{activeRubric &&
										<IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple onClick={handleClickRubricEdit}>
											<EditContainedIcon />
										</IconButton>
									}
								</Box>

								{/*{ !isEmptyStage(TemplateProjectStateType.RUBRIC) &&*/}
								{activeRubric &&
									<RubricTab
										projectExamQuestionsList={projectExamQuestionsList}
										questionListLenObjPerExam={questionListLenObjPerExam}
										projectRubricTransferList={projectRubricTransferList}
										questionKeyAndSelectedProjectRubricIdxMap={questionKeyAndSelectedProjectRubricIdxMap}
										questionKeyAndSystemRubricConditionsToSearchMap={questionKeyAndSystemRubricConditionsToSearchMap}
										questionKeyAndSystemRubricListMap={questionKeyAndSystemRubricListMap}
										questionKeyAndSelectedSystemRubricIdsMap={questionKeyAndSelectedSystemRubricIdsMap}
									/>
								}
							</Box>
						</div>
					</Box>
				</Box>
			</Box>

			<IconButton
				className={classes.pageUpIcon}
				onClick={handleClickScrollToTopBtn}
				disableRipple>
				<PageUpIcon />
			</IconButton>

			{isSelectingAll &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={isSelectingAll}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t('조회 중입니다...')}</Typography>
				</Backdrop>
			}

			{projectStore.isDeletingProject &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectStore.isDeletingProject}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t('삭제 중입니다...')}</Typography>
				</Backdrop>
			}

		</div>
	);
}




export default observer<typeof TemplateProjectView>(TemplateProjectView);


