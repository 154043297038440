import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function convertUTCToKST(dateStr?: string) {
	if (dateStr) {
		const utcDateStr = `${dateStr}Z`;
		// console.log("convert : ", dayjs(utcDateStr).tz('Asia/Seoul').format("YYYY년 MM월 DD일 HH:mm:ss"));
		return dayjs(utcDateStr).tz('Asia/Seoul');
	}

	return dayjs(null).tz('Asia/Seoul');
}
