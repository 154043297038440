import { UNIT_TO_DPI, g_availablePagesInSection, NU_TO_PU, PU_TO_NU } from "../../constants/ncode-constants";
import { INcodeSOBPRect, IPageSOBP, IPoint, IPointDpi, IPointRect, IRect, IRectDpi, IUnitString } from "../../structures/Structures";
import { ITransformParameters } from "../../structures/mapper/ITransformParameters";
import { NDPInkStorePage } from "../../typedef/ndp-types/NDPInkStorePage";
import { NDPPage } from "../../typedef/ndp-types/NDPPage";
import { ClonedIPageDataType } from "../../typedef/webdb-fs-types/ClonedIPageDataType";
import { IPageData } from "../../typedef/webdb-fs-types/IPageData";
import { getPageSerialFromSobp } from "./getPageSerialFromSobp";
import { getFilenameOnly } from "./indepFunctions";



export const sobpSeparator = ".";
export const uuidSeparator = "!";
export const pageIdSeparator = ":";



export function makeNPageIdStr(sobp: IPageSOBP, separator = sobpSeparator) {
	if (sobp) {
		const { section, owner, book, page, pageUuid, noteUuid } = sobp;

		if (page !== undefined) return `${section}${separator}${owner}${separator}${book}${separator}${page}`;

		if (book !== undefined) return `${section}${separator}${owner}${separator}${book}`;

		if (owner !== undefined) return `${section}${separator}${owner}`;

		if (section !== undefined) return `${section}`;

		return `${-1}${separator}${-1}${separator}${-1}${separator}${-1}${uuidSeparator}${noteUuid}${pageIdSeparator}${pageUuid}}`;
	}

	return `${sobp}`;
}

export function makeNPageIdStrWithUuid(sobp: IPageSOBP | NDPPage, separator =
	sobpSeparator, sobpOnly = false) {
	if (sobp) {
		let { section, owner, book, page, pageUuid, noteUuid: noteUuid } = sobp as IPageSOBP;
		const { section: s2, owner: o2, bookCode: b2, pageNumber: p2, id: pi2, noteId: ni2 } = sobp as NDPPage;
		if (section === undefined) section = s2;
		if (owner === undefined) owner = o2;
		if (book === undefined) book = b2;
		if (page === undefined) page = p2;
		if (pageUuid === undefined) pageUuid = pi2;
		if (noteUuid === undefined) noteUuid = ni2;


		let uuidStr = "";
		if (!sobpOnly && (pageUuid !== undefined && noteUuid !== undefined))
			uuidStr = `${uuidSeparator}${noteUuid}${pageIdSeparator}${pageUuid}`;

		if (page !== undefined) return `${section}${separator}${owner}${separator}${book}${separator}${page}` + uuidStr;

		if (book !== undefined) return `${section}${separator}${owner}${separator}${book}` + uuidStr;

		if (owner !== undefined) return `${section}${separator}${owner}` + uuidStr;

		if (section !== undefined) return `${section}` + uuidStr;

		// return `${section}${separator}${owner}${separator}${book}${separator}${page}${uuidSeparator}${noteUuid}${pageIdSeparator}${pageUuid}` + uuid;
		return `${-1}${separator}${-1}${separator}${-1}${separator}${-1}` + uuidStr;
	}

	return `${sobp}`;
}


export function isIncludedSOBP(sobp: IPageSOBP, start: IPageSOBP, end: IPageSOBP) {
	const { page } = sobp;
	const { page: startPage } = start;
	const { page: endPage } = end;

	if (start.section !== sobp.section) return false;
	if (start.owner !== sobp.owner) return false;
	if (start.book !== sobp.book) return false;

	if (end.section !== sobp.section) return false;
	if (end.owner !== sobp.owner) return false;
	if (end.book !== sobp.book) return false;


	if (page >= startPage && page <= endPage) return true;

	if (startPage === -1) return true;
	if (!startPage || !endPage) return true;

	return false;
}



export function makeNPageRangeIdStr(startSobp: IPageSOBP, numPages: number) {
	return `${makeNPageIdStr(startSobp)}-${numPages}`;
}


export function encodeComplexSobId(page: { section: number, owner: number, book: number }) {

	const { section: s, owner: o, book: b } = page;
	return `${s}${sobpSeparator}${o}${sobpSeparator}${b}`;
}

export function encodeComplexSobpId(page: { section: number, owner: number, book: number, page: number }) {

	const { section: s, owner: o, book: b, page: p } = page;
	return `${s}${sobpSeparator}${o}${sobpSeparator}${b}${sobpSeparator}${p}`;
}

/**
 * IPageSOBP to stringID
 * @param page
 * @returns string ("s.o.b.p!noteId:pageId")
 */
export function encodeComplexPageId(page: NDPInkStorePage | ClonedIPageDataType | { noteUUID: string, pageUUID: string, section: number, owner: number, bookCode: number, pageNumber: number } | IPageSOBP | IPageData) {
	if ((page as { noteUUID: string, pageUUID: string, section: number, owner: number, bookCode: number, pageNumber: number }).bookCode !== undefined) {
		// NDPInkStorePage인 경우
		const { section: s, owner: o, bookCode: b, pageNumber: p, noteUUID: ni, pageUUID: pi } = page as { noteUUID: string, pageUUID: string, section: number, owner: number, bookCode: number, pageNumber: number };
		if (pi !== undefined)
			return `${s}${sobpSeparator}${o}${sobpSeparator}${b}${sobpSeparator}${p}${uuidSeparator}${ni}${pageIdSeparator}${pi}`;

		// IPageData인 경우
		const { section: s2, owner: o2, bookCode: b2, pageNumber: p2, noteId: ni2, id: pi2 } = page as IPageData;
		if (pi2 !== undefined)
			return `${s2}${sobpSeparator}${o2}${sobpSeparator}${b2}${sobpSeparator}${p2}${uuidSeparator}${ni2}${pageIdSeparator}${pi2}`;

		console.error(`encodeComplexPageId, Invalid page type`, page);
		throw new Error(`encodeComplexPageId, Invalid page type:` + page.toString());
	}

	const { section: s, owner: o, book: b, page: p, noteUuid: ni, pageUuid: pi } = page as IPageSOBP;

	return `${s}${sobpSeparator}${o}${sobpSeparator}${b}${sobpSeparator}${p}${uuidSeparator}${ni}${pageIdSeparator}${pi}`;
}


/**
 * get IPageSOBP from pageId string ("s.o.b.p!noteId:pageId")
 *
 * @static
 * @param {string} complexPageId
 * @return IPageSOBP
 * @memberof InkStorage
 */
export function decodeComplexPageId(complexPageId: string) {
	// const pageIdRegex = /(\d+)\.(\d+)\.(\d+)\.(\s+)(?:-(\s+))?/;
	if (!complexPageId) return undefined as unknown as IPageSOBP;
	const ids = complexPageId.split(uuidSeparator);
	if (ids.length !== 2) {
		// console.error("Complex page ID parsing error, s.o.b.p!noteId:pageId", complexPageId);
		return null;
	}
	const sobpStr = ids[0];
	const uuidStr = ids[1];

	const sobpArr = sobpStr.split(sobpSeparator);
	const idArr = uuidStr ? uuidStr.split(pageIdSeparator) : [];
	if (idArr.length !== 2) {
		console.error("Note ID and Page ID parsing error, noteId:pageId", complexPageId);
	}


	// if (sobpArr.length < 4) return undefined as unknown as IPageSOBP;

	const ret: IPageSOBP = {
		section: sobpArr?.[0] ? parseInt(sobpArr[0], 10) : undefined,
		owner: sobpArr?.[1] ? parseInt(sobpArr[1], 10) : undefined,
		book: sobpArr?.[2] ? parseInt(sobpArr[2], 10) : undefined,
		page: sobpArr?.[3] ? parseInt(sobpArr[3], 10) : undefined,
		noteUuid: idArr?.[0] ? idArr[0] : undefined,
		pageUuid: idArr?.[1] ? idArr[1] : undefined,
	}

	return ret;
}


function isValidPage(pg: IPageSOBP | null | undefined) {
	if (pg === undefined || pg === null) return false;

	const { section: s, owner: o, book: b, page: p } = pg;
	if (s === undefined || s === null || s < 0) return false;
	if (o === undefined || o === null || o < 0) return false;
	if (b === undefined || b === null || b < 0) return false;
	if (p === undefined || p === null || p < 0) return false;

	return true;
}

export function getUniqueSOBPs(sobps: IPageSOBP[]) {
	const uniqueSOBPs: IPageSOBP[] = sobps.filter(
		(obj, index, self) =>
			index === self.findIndex((t) => isSamePage(t, obj))
	);

	return uniqueSOBPs;
}

export const groupSOBPs = getUniqueSOBPs;


export function isSamePage(pg1: IPageSOBP | null | undefined, pg2: IPageSOBP | null | undefined): boolean {
	if (!isValidPage(pg1) && !isValidPage(pg2)) return true;
	if (isValidPage(pg1) && !isValidPage(pg2)) return false;
	if (!isValidPage(pg1) && isValidPage(pg2)) return false;

	if (!pg1 || !pg2) return false;
	if (pg1.page !== pg2.page || pg1.book !== pg2.book || pg1.owner !== pg2.owner || pg1.section !== pg2.section) {
		return false;
	}

	if (pg1.noteUuid && pg2.noteUuid && pg1.noteUuid !== pg2.noteUuid) {
		return false;
	}

	if (pg1.pageUuid && pg2.pageUuid && pg1.pageUuid !== pg2.pageUuid) {
		return false;
	}

	return true;
}


export function isSamePageOnlySOBP(pg1: IPageSOBP | null | undefined, pg2: IPageSOBP | null | undefined): boolean {
	if (!pg1 && !pg2) return true;
	if (pg1 && !pg2) return false;
	if (!pg1 && pg2) return false;

	// if (!isValidPage(pg1) && !isValidPage(pg2)) return true;
	// if (isValidPage(pg1) && !isValidPage(pg2)) return false;
	// if (!isValidPage(pg1) && isValidPage(pg2)) return false;

	// if (!pg1 || !pg2) return false;
	if (pg1.page !== pg2.page || pg1.book !== pg2.book || pg1.owner !== pg2.owner || pg1.section !== pg2.section) {
		return false;
	}

	return true;
}


export function isSameH(h1: ITransformParameters, h2: ITransformParameters): boolean {
	if (h1.a !== h2.a) return false;
	if (h1.b !== h2.b) return false;
	if (h1.c !== h2.c) return false;
	if (h1.d !== h2.d) return false;
	if (h1.e !== h2.e) return false;
	if (h1.f !== h2.f) return false;
	if (h1.g !== h2.g) return false;
	if (h1.h !== h2.h) return false;
	return true;
}


export function includesPage(pgs: IPageSOBP[], pg2: IPageSOBP): boolean {
	for (let i = 0, l = pgs.length; i < l; i++) if (isSamePage(pgs[i], pg2)) return true;

	return false;
}


export function unionPages(pgs1: IPageSOBP[], pgs2: IPageSOBP[]): IPageSOBP[] {
	const pages = [...pgs1];
	pgs2.forEach((pg) => {
		if (!includesPage(pages, pg)) pages.push(pg);
	});

	return pages;
}




export function isValidSOBP(sobp: IPageSOBP): boolean {
	if (!sobp) return false;
	const { section, owner, book, page } = sobp;
	if (section < 0 || owner < 0 || book < 0 || page < 0) return false;
	return true;
}

export function availablePagesInSection(section: number) {
	const a = g_availablePagesInSection[section];
	return a || 4096;
}


export function getNextNcodePage(curr: IPageSOBP, delta = 1) {
	const { section, owner, book } = curr;
	const page = (curr.page + delta) % availablePagesInSection(section);
	const pi: IPageSOBP = {
		section, owner, book, page,
	}

	return pi;
}




function convertUnitToPt(unit: IUnitString, value: number) {
	let multiplier: number;

	switch (unit) {
		case 'inch': multiplier = 72; break;
		case 'pu': multiplier = 1; break;
		case 'nu': multiplier = NU_TO_PU; break;
		case '600dpi': multiplier = 72 / 600; break;

		case 'pt': multiplier = 1; break;
		case 'mm': multiplier = 72 / 25.4; break;
		case 'cm': multiplier = 72 / 2.54; break;
		case 'in': multiplier = 72; break;
		case 'px': multiplier = 96 / 72; break;
		case 'css': multiplier = 72 / 96; break;
		case 'pc': multiplier = 12; break;
		case 'em': multiplier = 12; break;
		case 'ex': multiplier = 6; break;
		default:
			throw new Error('Invalid unit: ' + unit);
	}

	return value * multiplier;
}

export function convertUnit(fromUnit: IUnitString, value: number, toUnit: IUnitString) {
	const points = convertUnitToPt(fromUnit, value);

	let multiplier: number;
	switch (toUnit) {
		case 'inch': multiplier = 1 / 72; break;
		case 'pu': multiplier = 1; break;
		case 'nu': multiplier = PU_TO_NU; break;
		case '600dpi': multiplier = 600 / 72; break;

		case 'pt': multiplier = 1; break;
		case 'mm': multiplier = 25.4 / 72; break;
		case 'cm': multiplier = 2.54 / 72; break;
		case 'in': multiplier = 1 / 72; break;
		case 'px': multiplier = 72 / 96; break;
		case 'css': multiplier = 96 / 72; break;
		case 'pc': multiplier = 1 / 12; break;
		case 'em': multiplier = 1 / 12; break;
		case 'ex': multiplier = 1 / 6; break;
		default:
			throw new Error('Invalid unit: ' + toUnit);
	}

	return points * multiplier;
}




export function getNcodedPdfName(filename: string, sobp: IPageSOBP, pagesPerSheet: number, serialNum?: number) {
	const name = getFilenameOnly(filename);
	const { section, owner, book, page } = sobp;
	const fn = `${name}_${pagesPerSheet}(${section}_${owner}_${book}_${page})_${serialNum !== null ? `${serialNum}_` : ""}ncoded.pdf`;

	return fn;
}


export function stringToDpiNum(unit: string) {
	let dpi = UNIT_TO_DPI[unit];

	if (!dpi) {
		const index = unit.indexOf("dpi");
		if (index > 0) dpi = parseInt(unit.substr(0, index), 10);
		else dpi = parseInt(unit, 10);
	}

	return dpi;
}

export function scalePoint(pt: IPointDpi, scale: number) {
	pt.x /= scale;
	pt.y /= scale;
	return pt;
}


export function isSameRect(rc1: IRectDpi, rc2: IRectDpi): boolean {
	if (!rc1 && !rc2) return true;
	if ((!rc1 && rc2) || (rc1 && !rc2)) return false;

	return rc1.x === rc2.x && rc1.y === rc2.y && rc1.width === rc2.width && rc1.height === rc2.height;
}

/**
 * crop range를 처리하기 위한 루틴
 *
 * @export
 * @param {INcodeSOBPRect} curr_rc
 * @param {INcodeSOBPRect} next_rc
 * @return {*}  {boolean}
 */
export function isSamePageRect(curr_rc: INcodeSOBPRect, next_rc: INcodeSOBPRect): boolean {
	if (!next_rc) return true;

	if (!curr_rc && next_rc) {
		if (next_rc.rect) {
			console.log(`return true`)
			return false;
		}

		console.log(`return false`)
		return true;
	}

	// curr_rc && next_rc
	if (!next_rc.rect) return true;
	if (!curr_rc.rect && next_rc.rect) return false;

	const rectFlag = isSameRect(curr_rc.rect, next_rc.rect);

	// pageInfo에 wild card가 들어 있으면, 페이지와 상관 없이 영역만으로 판단
	if (!next_rc.sobp) return rectFlag;

	const pageFlag = isSamePage(curr_rc.sobp, next_rc.sobp);
	return pageFlag && rectFlag;
}

export function hasNullPageInfo(cr: INcodeSOBPRect) {
	if (!cr) return true;
	if (!cr.sobp) return true;
	if (!cr.sobp.owner) return true;
	if (cr.sobp.owner === -1) return true;

	return false;
}


export function hasSamePage(prev_cr: INcodeSOBPRect, next_cr: INcodeSOBPRect): boolean {
	if (hasNullPageInfo(next_cr)) return true;
	if (hasNullPageInfo(prev_cr)) return true;

	return isSamePage(prev_cr.sobp, next_cr.sobp);
}

export function hasNullRect(cr: INcodeSOBPRect) {
	if (!cr) return true;
	if (!cr.rect) return true;
	if (!cr.rect.x) return true;

	return false;
}

export function hasSameRect(prev_cr: INcodeSOBPRect, next_cr: INcodeSOBPRect): boolean {
	if (hasNullRect(next_cr)) return false;
	if (hasNullRect(prev_cr)) return false;

	return isSameRect(prev_cr.rect, next_cr.rect);
}


export function isInRect(rc: IRect, pt: IPoint) {
	if (pt.x < rc.x) return false;
	if (pt.y < rc.y) return false;

	if (pt.x >= rc.x + rc.width) return false;
	if (pt.y >= rc.y + rc.height) return false;

	return true;
}

export function unionRect(r1: IPointRect, r2: IPointRect) {
	const left = Math.min(r1.left, r2.left);
	const right = Math.max(r1.right, r2.right);

	const top = Math.min(r1.top, r2.top);
	const bottom = Math.max(r1.bottom, r2.bottom);

	return { left, right, top, bottom } as IPointRect;
}

export function intersectRect(r1: IPointRect, r2: IPointRect) {
	const left = Math.max(r1.left, r2.left);
	const right = Math.min(r1.right, r2.right);

	const top = Math.max(r1.top, r2.top);
	const bottom = Math.min(r1.bottom, r2.bottom);

	const width = right - left;
	const height = bottom - top;

	if (width < 0 || height < 0) return null;

	return { left, right, top, bottom } as IPointRect;
}

export function hasIntersectRect(r1: IPointRect, r2: IPointRect) {
	return !(r2.left > r1.right
		|| r2.right < r1.left
		|| r2.top > r1.bottom
		|| r2.bottom < r1.top);
}


/**
 * 두개의 object를 병합하는 함수
 * target과 source에 동시에 존재하는 property는 source를 우선으로 덮어 씌운다.
 *
 * 사용 예시
 *    const target = { a: 1, b: { c: 2 }, d: [1, 2] };
 *    const source = { a: 3, b: { c: 4, e: 5 }, d: [3, 4] };
 *
 *    const merged = deepMerge(target, source);
 *    console.log(merged);
 *
 * 결과: { a: 3, b: { c: 4, e: 5 }, d: [1, 2, 3, 4] }
 *
 * @param target
 * @param source
 * @returns
 */

export function deepMerge<T>(target: T, source: T) {
	// 병합 대상이 두 개의 객체인 경우에만 처리
	if (typeof target === 'object' && target !== null &&
		typeof source === 'object' && source !== null) {
		Object.keys(source).forEach(key => {
			const targetValue = target[key];
			const sourceValue = source[key];

			// 배열인 경우 새 배열을 할당하고, 배열의 각 요소를 deepMerge 호출을 통해 병합
			if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
				target[key] = targetValue.concat(sourceValue);
			}
			// 객체인 경우 재귀적으로 병합
			else if (typeof targetValue === 'object' && targetValue !== null &&
				typeof sourceValue === 'object' && sourceValue !== null) {
				target[key] = deepMerge({ ...targetValue }, sourceValue);
			}
			// 기본값인 경우 source의 값으로 덮어쓰기
			else {
				target[key] = sourceValue;
			}
		});
	}
	return target;
}

export function isInNcodeRange(sobp: IPageSOBP, start: IPageSOBP, end: IPageSOBP) {
	const serial = getPageSerialFromSobp(sobp);
	const startSerial = getPageSerialFromSobp(start);
	const endSerial = getPageSerialFromSobp(end);

	return serial >= startSerial && serial <= endSerial;
}

