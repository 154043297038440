import { getPageSerialFromSobp, IPageSOBP } from "../../../neolab-libs/nl-lib3-common";

// models/NcodeAllocationResponse.ts
export interface INcodeAllocationResponse {
	/**
	 * 할당 식별자 (UUID)
	 */
	allocationId: string;

	/**
	 * 프로젝트 코드
	 */
	projectCode: string;

	section: number;

	/**
	 * 시작 owner (10비트: 0-1023)
	 */
	startOwner: number;

	/**
	 * 시작 book (12비트: 0-4095)
	 */
	startBook: number;

	/**
	 * 시작 page (10비트: 0-1023)
	 */
	startPage: number;

	/**
	 * 끝 owner
	 */
	endOwner: number;

	/**
	 * 끝 book
	 */
	endBook: number;

	/**
	 * 끝 page
	 */
	endPage: number;

	/**
	 * 할당된 총 페이지 수
	 */
	totalPages: number;

	/**
	 * 할당 생성 시간
	 */
	createdAt: Date;





	codeRange: string;
	id: number;

	returnedAt: string;
	returnReason: string;
	orgPdfUrl: string;
	orgDocUrl: string;
	ncodedPdfUrl: string;
	returned: boolean;

	// client side에서 쓰는 것
	isAssignmentProject?: boolean;

}



// models/NcodeAllocationResponse.ts
export class NcodeAllocationResponse implements INcodeAllocationResponse {
	/**
	 * 할당 식별자 (UUID)
	 */
	allocationId: string;


	/**
	 * 프로젝트 코드
	 */
	projectCode: string;

	section: number;

	/**
	 * 시작 owner (10비트: 0-1023)
	 */
	startOwner: number;

	/**
	 * 시작 book (12비트: 0-4095)
	 */
	startBook: number;

	/**
	 * 시작 page (10비트: 0-1023)
	 */
	startPage: number;

	/**
	 * 끝 owner
	 */
	endOwner: number;

	/**
	 * 끝 book
	 */
	endBook: number;

	/**
	 * 끝 page
	 */
	endPage: number;

	/**
	 * 할당된 총 페이지 수
	 */
	totalPages: number;

	/**
	 * 할당 생성 시간
	 */
	createdAt: Date;




	codeRange: string;
	id: number;

	returnedAt: string;
	returnReason: string;
	orgPdfUrl: string;
	orgDocUrl: string;
	ncodedPdfUrl: string;
	returned: boolean;

	constructor(data: Partial<INcodeAllocationResponse> = {}) {
		Object.assign(this, data);
	}

	static getTotalPages(allocation: INcodeAllocationResponse): number {
		const start: IPageSOBP = {
			section: allocation.section,
			owner: allocation.startOwner,
			book: allocation.startBook,
			page: allocation.startPage
		}

		const end: IPageSOBP = {
			section: allocation.section,
			owner: allocation.endOwner,
			book: allocation.endBook,
			page: allocation.endPage
		}

		const endSerial = getPageSerialFromSobp(end);
		const startSerial = getPageSerialFromSobp(start);

		const totalPages = Number(endSerial - startSerial + 1n);
		return totalPages;
	}

	static getStartSobp(allocation: INcodeAllocationResponse): IPageSOBP {
		const start: IPageSOBP = {
			section: allocation.section,
			owner: allocation.startOwner,
			book: allocation.startBook,
			page: allocation.startPage
		}

		return start;
	}
	static getEndSobp(allocation: INcodeAllocationResponse): IPageSOBP {
		const end: IPageSOBP = {
			section: allocation.section,
			owner: allocation.endOwner,
			book: allocation.endBook,
			page: allocation.endPage
		}

		return end;
	}


	/**
	 * NcodeAllocationVer2 엔티티로부터 응답 객체 생성
	 */
	static from(allocation: INcodeAllocationResponse): INcodeAllocationResponse {
		const totalPages = this.calculateTotalPages(allocation);

		return new NcodeAllocationResponse({
			allocationId: allocation.allocationId,
			projectCode: allocation.projectCode,
			startOwner: allocation.startOwner,
			startBook: allocation.startBook,
			startPage: allocation.startPage,
			endOwner: allocation.endOwner,
			endBook: allocation.endBook,
			endPage: allocation.endPage,
			totalPages: totalPages,
			createdAt: allocation.createdAt
		});
	}

	/**
	 * 총 페이지 수 계산
	 */
	private static calculateTotalPages(allocation: INcodeAllocationResponse): number {
		if (allocation.endOwner === allocation.startOwner &&
			allocation.endBook === allocation.startBook) {
			// 같은 book 내에서의 페이지 차이
			return allocation.endPage - allocation.startPage + 1;
		} else if (allocation.endOwner === allocation.startOwner) {
			// 같은 owner, 다른 book
			const fullBookPages = (allocation.endBook - allocation.startBook - 1) * 1024;
			const startBookRemainingPages = 1024 - allocation.startPage;
			const endBookPages = allocation.endPage + 1;
			return fullBookPages + startBookRemainingPages + endBookPages;
		} else {
			// 다른 owner까지 걸친 경우
			throw new Error("Allocation spans multiple owners");
		}
	}

	/**
	 * 할당된 코드 범위를 문자열로 표현
	 */
	getCodeRange(): string {
		return `Owner: ${this.startOwner}, Book: ${this.startBook}-${this.endBook}, Page: ${this.startPage}-${this.endPage}`;
	}
}
