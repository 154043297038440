

import {
	Box
} from "@mui/material";


// import 'dayjs/locale/ko';
import TextViewer from "../../../../components/common/TextViewer";
import { useStores } from "../../../../hooks/useStores";
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";
import { useCurrentOrder } from "../../000_Common/001_Functions/useCurrentOrder";
import { useCurrentQuestionHTML } from "../../000_Common/001_Functions/useCurrentQuestionHTML";
type Props = {
	examInfo: IExamInfo,
	questionInfo: IQuestionInfo,
}

export const QuestionAndReferenceTabQuestionRow = (props: Props) => {
	const { examInfo, questionInfo } = props;
	const { projectExamStore } = useStores();

	const currentOrder = useCurrentOrder(projectExamStore, examInfo, questionInfo);
	const currentQuestionHTML = useCurrentQuestionHTML(projectExamStore, questionInfo, currentOrder);

	return (
		<>
			{!!currentQuestionHTML && (
				<>
					<Box sx={{ marginTop: "10px", marginBottom: "40px" }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
						<TextViewer
							currentHTML={currentQuestionHTML}
						/>
					</Box>
				</>
			)}
		</>
	);
};
