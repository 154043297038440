// import React from "react";
import { makeAutoObservable } from "mobx";

const LogPrefix = "[MessageStore]";


export default class MessageStore {
	public open: boolean = false;
	public titleText: string = '';
	public message: string = '';
	public submitText: string = '';
	public cancelText: string = '';
	public isConfirm: boolean = false;
	public onClick: Function | undefined;
	public onClose: Function | undefined;

	constructor() {
		// this.open = false;
		// this.titleText = '';
		// this.message = '';
		// this.submitText = '';
		// this.cancelText = '';
		// this.isConfirm = false;

		// this.onClick = undefined;
		// this.onClose = undefined;

		makeAutoObservable(this);
	}

	init() {
		this.open = false;
		this.titleText = '';
		this.message = '';
		this.submitText = '';
		this.cancelText = '';
		this.isConfirm = false;

		this.onClose = undefined;
	};

	setOpen(open: boolean) {
		this.open = open
	};

	alert(
		message: string,
		titleText = undefined as string,
		submitText = undefined as string
	) {
		return new Promise((resolve, reject) => {
			try {
				this.open = true;

				this.message = message;
				this.titleText = titleText;
				this.submitText = submitText;

				this.onClick = () => {
					resolve(true);
				};
				this.onClose = () => {
					resolve(true);
				};
			} catch (e) {
				reject(e);
			}
		})
	};

	confirm(
		message: string,
		titleText = undefined as string,
		submitText = undefined as string,
		cancelText = undefined as string
	) {
		return new Promise<boolean>((resolve, reject) => {
			try {
				this.open = true;
				this.isConfirm = true;

				this.message = message;
				this.titleText = titleText;
				this.submitText = submitText;
				this.cancelText = cancelText;

				this.onClick = () => {
					resolve(true);
				};

				this.onClose = () => {
					resolve(false);
				};
			} catch (e) {
				reject(e);
			}
		});
	};
}
