// Copyright (C) 2004-2022 Artifex Software, Inc.
//
// This file is part of MuPDF.
//
// MuPDF is free software: you can redistribute it and/or modify it under the
// terms of the GNU Affero General Public License as published by the Free
// Software Foundation, either version 3 of the License, or (at your option)
// any later version.
//
// MuPDF is distributed in the hope that it will be useful, but WITHOUT ANY
// WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
// FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
// details.
//
// You should have received a copy of the GNU Affero General Public License
// along with MuPDF. If not, see <https://www.gnu.org/licenses/agpl-3.0.en.html>
//
// Alternative licensing terms are available from the licensor.
// For commercial licensing, see <https://www.artifex.com/> or contact
// Artifex Software, Inc., 1305 Grant Avenue - Suite 200, Novato,
// CA 94945, U.S.A., +1(415)492-9861, for further information.


import { Annotation } from "./classes/Annotation";
import { AnnotationList } from "./classes/AnnotationList";
import { ColorSpace } from "./classes/ColorSpace";
import { FzDevice } from "./classes/FzDevice";
import { FzBuffer } from "./classes/FzBuffer";
// import { FzDocument } from "./classes/FzDocument";
import { FzLocation } from "./classes/FzLocation";
import { FzMatrix } from "./classes/FzMatrix";
import { FzPage } from "./classes/FzPage";
import { FzPixmap } from "./classes/FzPixmap";
import { FzPoint } from "./classes/FzPoint";
import { FzRect } from "./classes/FzRect";
import { FzStream, fetchOpen, fetchRead, fetchClose } from "./classes/FzStream";
import { JobCookie, createCookie, cookieAborted, deleteCookie } from "./classes/JobCookie";
import { Link } from "./classes/Link";
import { Links } from "./classes/Links";
import { MupdfError } from "./classes/MupdfError";
import { MupdfTryLaterError } from "./classes/MupdfTryLaterError";
import { Outline } from "./classes/Outline";
import { FzOutput } from "./classes/FzOutput";
import { PdfDocument } from "./classes/PdfDocument";
import { PdfPage } from "./classes/PdfPage";
import { STextPage } from "./classes/STextPage";
import libmupdf from "../emscripten/mupdf-wasm-singlethread-esm";

const PDF_ANNOT_TEXT = 0;
const PDF_ANNOT_LINK = 1;
const PDF_ANNOT_FREE_TEXT = 2;
const PDF_ANNOT_LINE = 3;
const PDF_ANNOT_SQUARE = 4;
const PDF_ANNOT_CIRCLE = 5;
const PDF_ANNOT_POLYGON = 6;
const PDF_ANNOT_POLY_LINE = 7;
const PDF_ANNOT_HIGHLIGHT = 8;
const PDF_ANNOT_UNDERLINE = 9;
const PDF_ANNOT_SQUIGGLY = 10;
const PDF_ANNOT_STRIKE_OUT = 11;
const PDF_ANNOT_REDACT = 12;
const PDF_ANNOT_STAMP = 13;
const PDF_ANNOT_CARET = 14;
const PDF_ANNOT_INK = 15;
const PDF_ANNOT_POPUP = 16;
const PDF_ANNOT_FILE_ATTACHMENT = 17;
const PDF_ANNOT_SOUND = 18;
const PDF_ANNOT_MOVIE = 19;
const PDF_ANNOT_RICH_MEDIA = 20;
const PDF_ANNOT_WIDGET = 21;
const PDF_ANNOT_SCREEN = 22;
const PDF_ANNOT_PRINTER_MARK = 23;
const PDF_ANNOT_TRAP_NET = 24;
const PDF_ANNOT_WATERMARK = 25;
const PDF_ANNOT_3D = 26;
const PDF_ANNOT_PROJECTION = 27;
const PDF_ANNOT_UNKNOWN = -1;

// TODO - Figure out better naming scheme for fetch methods
export function onFetchCompleted(id) {
  // mupdf.onFetchCompleted(id);
}


class WasmJSLoader {

  private wasmBinaryUrl: string;

  public module: any = null;

  ready = new Promise((resolve, reject) => { });

  libmupdf_injections = {
    fetchOpen,
    fetchRead,
    fetchClose,
    MupdfError,
    MupdfTryLaterError,
    wasmBinary: new Uint8Array()
  };


  constructor(args: { wasmBinaryUrl: string }) {
    this.wasmBinaryUrl = args.wasmBinaryUrl;
  }

  public async load(args?: { wasmBinaryUrl: string }) {
    let wasmBinaryUrl = this.wasmBinaryUrl;
    if (args && args.wasmBinaryUrl) { wasmBinaryUrl = args.wasmBinaryUrl; }

    const res = await fetch(wasmBinaryUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/wasm"
      }
    });
    const binary = await res.arrayBuffer();
    const wasmBinary = new Uint8Array(binary);
    this.libmupdf_injections.wasmBinary = wasmBinary;

    const m = await libmupdf(this.libmupdf_injections);
    // console.log(`WASM Wrapper JS loaded`);
    const module = m;
    this.module = m;

    if (!globalThis.crossOriginIsolated) {
      // console.warn("MuPDF: The current page is running in a non-isolated context. This means SharedArrayBuffer is not available. See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/SharedArrayBuffer for details.");
      this.ready = new Promise((resolve, reject) => { resolve({ module, sharedBuffer: null }) });
      return { module, sharedBuffer: null };
    }
    if (globalThis.SharedArrayBuffer == null) {
      // console.warn("MuPDF: You browser does not implement SharedArrayBuffer.");
      this.ready = new Promise((resolve, reject) => { resolve({ module, sharedBuffer: null }) });
      return { module, sharedBuffer: null };
    }
    if (m.wasmMemory == null) {
      // console.error("MuPDF internal error: emscripten does not export wasmMemory");
      this.ready = new Promise((resolve, reject) => { resolve({ module, sharedBuffer: null }) });
      return { module, sharedBuffer: null };
    }
    if (!(m.wasmMemory instanceof WebAssembly.Memory) || !(m.wasmMemory.buffer instanceof SharedArrayBuffer)) {
      // console.error("MuPDF internal error: wasmMemory exported by emscripten is not a valid instance of WebAssembly.Memory");
      this.ready = new Promise((resolve, reject) => { resolve({ module, sharedBuffer: null }) });
      return { module, sharedBuffer: null };
    }

    console.log("MuPDF: WASM module running in cross-origin isolated context")
    this.ready = new Promise((resolve, reject) => { resolve({ module, sharedBuffer: m.wasmMemory.buffer }) });
    return { module, sharedBuffer: m.wasmMemory.buffer };
  }
}

// const createMuPDF = async (args: { wasmBinaryUrl: string }) => {
//   // --- EXPORTS ---
//   const mupdf = {
//     MupdfError,
//     MupdfTryLaterError,
//     FzPoint,
//     FzRect,
//     FzMatrix,
//     FzDocument,
//     PdfDocument,
//     FzPage,
//     Links,
//     Link,
//     FzLocation,
//     Outline,
//     PdfPage,
//     AnnotationList,
//     Annotation,
//     ColorSpace,
//     FzPixmap,
//     FzDevice,
//     JobCookie,
//     createCookie,
//     cookieAborted,
//     deleteCookie,
//     FzBuffer,
//     FzStream,
//     FzOutput,
//     STextPage,

//     onFetchCompleted: (id) => { },
//     doLeakCheck: () => { },


//     DeviceGray: undefined as ColorSpace,
//     DeviceRGB: undefined as ColorSpace,
//     DeviceBGR: undefined as ColorSpace,
//     DeviceCMYK: undefined as ColorSpace,

//     ready: undefined as Promise<{ sharedBuffer: any }>,

//     initWasm: undefined as (args: { wasmBinaryUrl: string; }) =>
//       Promise<{ sharedBuffer: any; }>
//   };

//   let promiseResolve: (value: { sharedBuffer: any; }) => void = undefined;;
//   mupdf.ready = new Promise((resolve, reject) => { promiseResolve = resolve });






//   const { wasmBinaryUrl } = args;

//   const res = await fetch(wasmBinaryUrl, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/wasm"
//     }
//   });
//   const binary = await res.arrayBuffer();
//   const wasmBinary = new Uint8Array(binary);
//   libmupdf_injections.wasmBinary = wasmBinary;

//   const m = await libmupdf(libmupdf_injections);
//   console.log("WASM Loaded");

//   const module = m;
//   const ctx = module._wasm_init_context(0);

//   mupdf.DeviceGray = new ColorSpace(ctx, module._wasm_device_gray(ctx));
//   mupdf.DeviceRGB = new ColorSpace(ctx, module._wasm_device_rgb(ctx));
//   mupdf.DeviceBGR = new ColorSpace(ctx, module._wasm_device_bgr(ctx));
//   mupdf.DeviceCMYK = new ColorSpace(ctx, module._wasm_device_cmyk(ctx));

//   if (!globalThis.crossOriginIsolated) {
//     // console.warn("MuPDF: The current page is running in a non-isolated context. This means SharedArrayBuffer is not available. See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/SharedArrayBuffer for details.");
//     promiseResolve({ sharedBuffer: null });
//     return { mupdf, sharedBuffer: null };
//   }
//   if (globalThis.SharedArrayBuffer == null) {
//     // console.warn("MuPDF: You browser does not implement SharedArrayBuffer.");
//     promiseResolve({ sharedBuffer: null });
//     return { mupdf, sharedBuffer: null };
//   }
//   if (module.wasmMemory == null) {
//     // console.error("MuPDF internal error: emscripten does not export wasmMemory");
//     promiseResolve({ sharedBuffer: null });
//     return { mupdf, sharedBuffer: null };
//   }
//   if (!(module.wasmMemory instanceof WebAssembly.Memory) || !(module.wasmMemory.buffer instanceof SharedArrayBuffer)) {
//     // console.error("MuPDF internal error: wasmMemory exported by emscripten is not a valid instance of WebAssembly.Memory");
//     promiseResolve({ sharedBuffer: null });
//     return { mupdf, sharedBuffer: null };
//   }

//   console.log("MuPDF: WASM module running in cross-origin isolated context")
//   promiseResolve({ sharedBuffer: module.wasmMemory.buffer });
//   return { mupdf, sharedBuffer: module.wasmMemory.buffer }
// }

// let promiseResolve;
// mupdf.ready = new Promise((resolve, reject) => { promiseResolve = resolve });

// fetch("/lib/lib-core.wasm").then(async (res) => {
//   // fetch("/favicon.ico").then(async (res) => {
//   const binary = await res.arrayBuffer();
//   const uint8array = new Uint8Array(binary);
//   libmupdf_injections.wasmBinary = uint8array;

//   mupdf.ready = libmupdf(libmupdf_injections).then(m => {
//     console.log("WASM Loaded");
//     const module = m;
//     const ctx = module._wasm_init_context();
//     globalContext.ctx = ctx;
//     globalContext.libmupdf = m;
//     let resolve;
//     globalContext.ready = new Promise<ReturnType<typeof libmupdf>>((r1, r2) => { resolve = r1 });
//     resolve(m);

//     mupdf.DeviceGray = new ColorSpace(ctx, module._wasm_device_gray(ctx));
//     mupdf.DeviceRGB = new ColorSpace(ctx, module._wasm_device_rgb(ctx));
//     mupdf.DeviceBGR = new ColorSpace(ctx, module._wasm_device_bgr(ctx));
//     mupdf.DeviceCMYK = new ColorSpace(ctx, module._wasm_device_cmyk(ctx));

//     if (!globalThis.crossOriginIsolated) {
//       console.warn("MuPDF: The current page is running in a non-isolated context. This means SharedArrayBuffer is not available. See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/SharedArrayBuffer for details.");
//       promiseResolve({ sharedBuffer: null });
//       return { sharedBuffer: null };
//     }
//     if (globalThis.SharedArrayBuffer == null) {
//       console.warn("MuPDF: You browser does not implement SharedArrayBuffer.");
//       promiseResolve({ sharedBuffer: null });
//       return { sharedBuffer: null };
//     }
//     if (module.wasmMemory == null) {
//       console.error("MuPDF internal error: emscripten does not export wasmMemory");
//       promiseResolve({ sharedBuffer: null });
//       return { sharedBuffer: null };
//     }
//     if (!(module.wasmMemory instanceof WebAssembly.Memory) || !(module.wasmMemory.buffer instanceof SharedArrayBuffer)) {
//       console.error("MuPDF internal error: wasmMemory exported by emscripten is not a valid instance of WebAssembly.Memory");
//       promiseResolve({ sharedBuffer: null });
//       return { sharedBuffer: null };
//     }

//     console.log("MuPDF: WASM module running in cross-origin isolated context")
//     promiseResolve({ sharedBuffer: module.wasmMemory.buffer });
//     return { sharedBuffer: module.wasmMemory.buffer }
//   });

// });

// // If running in Node.js environment
// if (typeof require === "function") {
// 	module.exports = mupdf;
// }

export {
  MupdfError,
  MupdfTryLaterError,
  FzPoint,
  FzRect,
  FzMatrix,
  // FzDocument,
  PdfDocument,
  FzPage,
  Links,
  Link,
  FzLocation,
  Outline,
  PdfPage,
  AnnotationList,
  Annotation,
  ColorSpace,
  FzPixmap,
  FzDevice,
  JobCookie,
  createCookie,
  cookieAborted,
  deleteCookie,
  FzBuffer,
  FzStream,
  FzOutput,
  STextPage,
  PDF_ANNOT_TEXT,
  PDF_ANNOT_LINK,
  PDF_ANNOT_FREE_TEXT,
  PDF_ANNOT_LINE,
  PDF_ANNOT_SQUARE,
  PDF_ANNOT_CIRCLE,
  PDF_ANNOT_POLYGON,
  PDF_ANNOT_POLY_LINE,
  PDF_ANNOT_HIGHLIGHT,
  PDF_ANNOT_UNDERLINE,
  PDF_ANNOT_SQUIGGLY,
  PDF_ANNOT_STRIKE_OUT,
  PDF_ANNOT_REDACT,
  PDF_ANNOT_STAMP,
  PDF_ANNOT_CARET,
  PDF_ANNOT_INK,
  PDF_ANNOT_POPUP,
  PDF_ANNOT_FILE_ATTACHMENT,
  PDF_ANNOT_SOUND,
  PDF_ANNOT_MOVIE,
  PDF_ANNOT_RICH_MEDIA,
  PDF_ANNOT_WIDGET,
  PDF_ANNOT_SCREEN,
  PDF_ANNOT_PRINTER_MARK,
  PDF_ANNOT_TRAP_NET,
  PDF_ANNOT_WATERMARK,
  PDF_ANNOT_3D,
  PDF_ANNOT_PROJECTION,
  PDF_ANNOT_UNKNOWN,

}

export default WasmJSLoader;