import { IPageSOBP } from "../../structures/Structures";

export function getPageSerialFromSobp(sobp: IPageSOBP): bigint {
	const { section, owner, book, page } = sobp;
	let serial = BigInt(0);

	switch (section) {
		case 0: // O(10), B(14), P(12)
			serial += BigInt(section) * BigInt(`${2**36}`); // 2**36
			serial += BigInt(owner & 0x3FF) * BigInt(`${2**26}`); // 2**26
			serial += BigInt(book & 0x3FFF) * BigInt(`${2**12}`); // 2**12
			serial += BigInt(page & 0xFFF) * BigInt(`${2**0}`); // 2**36
			break;

		case 1:
		case 2:
		case 6:
		case 7:
		case 8:
		case 9:
		case 10: // O(10), B(12), P(10)
			serial += BigInt(section) * BigInt(`${2**32}`);
			serial += BigInt(owner & 0x3FF) * BigInt(`${2**22}`);
			serial += BigInt(book & 0x3FFF) * BigInt(`${2**10}`);
			serial += BigInt(page & 0xFFF) * BigInt(`${2**0}`);
			break;

		case 3: // O(10), B(13), P(9)
			serial += BigInt(section) * BigInt(`${2**32}`);
			serial += BigInt(owner & 0x3FF) * BigInt(`${2**22}`);
			serial += BigInt(book & 0x3FFF) * BigInt(`${2**9}`);
			serial += BigInt(page & 0xFFF) * BigInt(`${2**0}`);
			break;

		case 4: // O(10), B(16), P(18)
			serial += BigInt(section) * BigInt(`${2**44}`);
			serial += BigInt(owner & 0x3FF) * BigInt(`${2**34}`);
			serial += BigInt(book & 0x3FFF) * BigInt(`${2**18}`);
			serial += BigInt(page & 0xFFF) * BigInt(`${2**0}`);
			break;

		case 5: // O(8), B(12), P(12)
			serial += BigInt(section) * BigInt(`${2**32}`);
			serial += BigInt(owner & 0x3FF) * BigInt(`${2**24}`);
			serial += BigInt(book & 0x3FFF) * BigInt(`${2**12}`);
			serial += BigInt(page & 0xFFF) * BigInt(`${2**0}`);
			break;

		case 11:
		case 12:
		case 13:
		case 14: // O(10), B(13), P(5)
			serial += BigInt(section) * BigInt(`${2**28}`);
			serial += BigInt(owner & 0x3FF) * BigInt(`${2**18}`);
			serial += BigInt(book & 0x3FFF) * BigInt(`${2**5}`);
			serial += BigInt(page & 0xFFF) * BigInt(`${2**0}`);
			break;

		case 15:
		default:
			throw new Error("Invalid section");
	}

	return serial;
}
