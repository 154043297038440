import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const ExamExportStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
	},
	// emptyBox:{
	//     width: '100%',
	//     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
	//     display:'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center',
	//     '& p':{
	//         fontSize: '1.375rem',
	//         color: '#87888d',
	//         fontWeight:400,
	//         textAlign: 'center',
	//         lineHeight: '30.36px'
	//     }
	// },
	// boxWrap: {
	//     backgroundColor: '#fff',
	//     padding: 20,
	//     borderRadius: 8,
	//     height: 'calc(100vh - 500px)'
	// },

	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			// marginLeft: "10px",
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},




	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		// width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},





	/////////////////////
	padding: {
		padding: 15,
	},
	mainContentBox: {
		width: '100%',
		height: 'calc(100vh - 85px)',
		maxWidth: 1024,

		padding: '22px 31px',
		overflowY: 'auto',

		background: '#fff',

		border: '1px solid #EAECEB',
		boxSizing: 'border-box',

		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
		// display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'space-between',
		// border: '1px solid #EAECEB',
		// padding: 20,
		// width: '100%',
		// height: 'calc(100vh - 145px)',
		// // overflowY: 'auto',
		// background: '#fff',
		// maxWidth: 1440,
		// '&::-webkit-scrollbar': {
		//     width: '10px',
		//     height: '10px'
		// },
		// '&::-webkit-scrollbar-thumb': {
		//     background: '#6f6f6f',
		//     borderRadius: '10px',
		//     backgroundClip: 'padding-box',
		//     border: '3px solid transparent',
		// },
		// '&::-webkit-scrollbar-track': {
		//     background: 'transparent',
		//     marginTop: "5px",
		// },
	},
	mainContentBoxLeft: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 130px)',
		overflowY: 'auto',
		background: '#fff',
		width: 'calc(100vw - 770px)',
	},
	mainContentBoxLeftInner: {
		height: 'calc(100vh - 200px)',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px"
		},
	},
	mainContentBoxRight: {
		border: '1px solid #EAECEB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		// overflowY: 'auto',
		background: '#fff',
		width: '620px',
		boxSizing: 'border-box',
		marginLeft: "10px",
	},


	commonTopTitle: {
		marginTop: "12px",
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: "20px",
	},
	commonSubTitle: {
		marginBottom: "10px",
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
		}
	},
	taskTitle: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			marginBottom: "15px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	taskTitleBold: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			marginBottom: "15px",
			fontWeight: 500,
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	searchSchoolInput: {
		'& .MuiFormControl-root': {
			maxWidth: 830,
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 50
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},




	greenBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#cdcdcd',
			'&:hover': {
				background: '#cdcdcd',
			},
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold'
			}
		}
	},
	greenBtnActive: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	greenLineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008c73',
				fontWeight: 'bold'
			}
		}
	},
	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#cdcdcd',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold'
			}
		}
	},
	lineBtnActive: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	saveBtn: {
		marginRight: "10px",
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			borderRadius: 3,
			border: '1px solid #cbcbcb',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008c73',
				fontWeight: 700,
				letterSpacing: '-0.5px',
			}
		},
		'&.Mui-disabled': {
			opacity: 0.5,
			border: '1px solid rgba(0, 0, 0, 0.12)',
			'& .MuiTypography-root': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		}
	},
	nextBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#008c73',
			borderRadius: 3,
			border: '1px solid #008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold',
				letterSpacing: '-0.5px',
			}
		},
		'&.Mui-disabled': {
			opacity: 0.5,
			border: '1px solid rgba(0, 0, 0, 0.12)',
			'& .MuiTypography-root': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		}
	},

	selectText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 'bold',
			marginRight: "15px",
		}
	},

	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#111',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},
	selectPopover: {
		'& .MuiMenuItem-root': {
			'&:first-child': {
				display: 'none'
			}
		}
	},
	formControl2: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "140px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "140px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#111',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},
	formControl3: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "210px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "210px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1rem',
			boxSizing: 'border-box',
			color: '#111',
			letterSpacing: '-0.8px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #cbcbcb',
			borderRadius: '3px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},



	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: '#fff',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
			marginTop: "3px",
		}
	},


	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentName: {
		'&.MuiTypography-root': {
			color: '#008C73 !important',
			fontSize: '0.875rem'
		}
	},
	nameStyle: {
		'&.MuiTypography-root': {
			fontSize: '0.785rem',
			color: '#555',
			letterSpacing: '-0.5px',
			fontWeight: 800,
			marginRight: "5px",
		}
	},
	tableBox: {
		marginTop: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid #CBCBCB',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.785rem',
				color: '#555',
				letterSpacing: '-0.5px',
				fontWeight: 800,
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "44px",
				borderBottom: '1px solid #CBCBCB',
				background: '#fff',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid #CBCBCB',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},
	tableBoxSm: {
		'&.MuiPaper-root': {
			// borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				letterSpacing: '-0.5px',
				fontWeight: 700,
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "36px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				background: '#F3F3F3',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.813rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},
	tableBoxPlan: {
		marginTop: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1rem',
				color: '#323232',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '10px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				borderRight: '1px solid #cbcbcb',
				// whiteSpace:'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},

	dialogInput: {
		'& .MuiFormControl-root': {
			width: '99%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "19px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
			'&:hover fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "460px",
			marginBottom: "20px",
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "440px",
		}
	},
	stepInputBox: {
		'& .MuiIconButton-root': {
			margin: '20px 10px 5px 40px'
		}
	},

	inputStep: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 36
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
			letterSpacing: '-0.5px'
		}
	},
	inputStepMulti: {
		'& .MuiOutlinedInput-root': {
			height: "60px",
		},
		'& .MuiOutlinedInput-input': {
			height: "38px",
		}
	},
	stepText: {
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			color: '#2F80ED',
			marginRight: "20px",
		}
	},

	addSentence: {
		'& .MuiButton-label': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: 'transparent'
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontSize: '0.875rem',
				marginTop: "2px",
			}
		}
	},

	boxList: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FAFAFA',
		borderRadius: 6,
		width: '100%',
		height: "200px",
		margin: '0px 5px',
		padding: 10,
		'& .MuiTypography-root': {
			color: '#6F6F6F',
			fontSize: '0.75rem',
			minHeight: 93,
		},
		'&:hover': {
			outline: '2px solid #56CCF2'
		}
	},
	boxListTitle: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 700,
			marginTop: "5px",
			marginBottom: "5px",
			minHeight: 'auto',
		}
	},
	fileUploadBox: {
		marginTop: "30px",
		background: '#E0E0E0',
		height: 'calc(100vh - 480px)',
		overflowY: 'auto',
		position: 'relative'
	},
	boardSize: {
		position: 'absolute',
		right: 10,
		bottom: 10,
		display: 'flex',
		flexDirection: 'column'
	},
	fileUploadTitle: {
		marginTop: "20px",
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			marginBottom: "20px",
		}
	},
	fileUploadText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	fileUploadBtn: {
		'&.MuiButtonBase-root': {
			background: '#008C73',
			width: "170px",
			height: "36px",
			borderRadius: 3,
			border: '1px solid #008C73',
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	fileUploadSubText: {
		margin: '15px 0px'
	},
	// urlInputBox: {
	//     '&.MuiPaper-root': {
	//         width: "600px",
	//         height: "35px",
	//         border: '1px solid #CBCBCB',
	//         marginBottom: "15px",
	//     },
	//     '& .MuiInputBase-root': {
	//         width: "480px",
	//     },
	//     '& .MuiInputBase-input': {
	//         padding: '8px 10px 7px',
	//     }
	// },
	urlBtn: {
		marginLeft: "10px",
		'&.MuiButtonBase-root': {
			width: "82px",
			height: "27px",
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				letterSpacing: '-0.5px',
			}
		}
	},
	urlBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#fff'
			}
		}
	},

	summaryAiBtn: {
		'&.MuiButtonBase-root': {
			background: '#CDCDCD',
			width: '96%',
			marginBottom: "20px",
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold',
			}
		}
	},
	summaryAiBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				color: '#fff',
			}
		}
	},
	aiSubText: {
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
		}
	},
	sideList: {
		position: 'absolute',
		left: 0,
		top: 0,
		background: '#4F4F4F',
		width: "114px",
		height: 'calc(100vh - 480px)',
		// height: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	sideListInner: {
		overflowY: 'auto',
		padding: '10px 7px',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	img: {
		position: 'relative',
		'& img': {
			width: '100%',
			borderRadius: '5px',
			'&:hover': {
				outline: '2px solid #03BF69'
			},
		},

	},
	examInfoIcon: {
		marginLeft: "10px",
		marginBottom: "13px",
	},
	pageNum: {
		position: 'absolute',
		bottom: 5,
		background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.6), #fff)',
		width: '100%',
		borderRadius: '0 0 5px 5px',
		'& .MuiTypography-root': {
			padding: '4px 5px',
			textAlign: 'right'
		}
	},
	userBox: {
		border: '1px solid #CBCBCB',
		width: '96%',
		borderRadius: 15,
		padding: 20,
		boxSizing: 'border-box',
		marginBottom: "20px",
	},

	editerArticle: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		height: "270px",
	},
	dotSubEditerWrap: {
		position: 'relative'
	},
	dotSubEditer: {
		position: 'absolute',
		top: 48,
		left: 5,
	},

	editerBox: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		height: "180px",
		marginBottom: "5px",
	},
	editerBtnSentence: {
		'&.MuiButtonBase-root': {
			border: '1px dotted #111',
			width: '100%',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontWeight: 'bold',
				marginLeft: "5px",
			}
		}
	},
	userTitleToggle: {
		'& svg': {
			marginTop: "10px",
			marginLeft: "5px",
		}
	},
	userTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
		}
	},
	userAddBtn: {
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: '#fff',
			},
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555',
			marginLeft: "5px",
		}
	},
	scoreMarkText: {
		fontWeight: 500,
	},
	scoreMarkSubText: {
		color: '#555'
	},
	scoreMark: {
		position: 'relative',
		marginRight: "10px",
		marginLeft: "20px",
		'& .MuiFormControl-root': {
			width: '80px',
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 64
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 25px',
		}
	},
	scoreMarkIcon: {
		position: 'absolute',
		right: 0,
		top: 5,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	iconYearSelect: {
		'&.MuiIconButton-root': {
			margin: '4px 5px',
		}
	},

	trigger: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: "458px",
		marginBottom: "34px",
		'&.MuiTabs-root': {
			minHeight: 40
		},
		'& .MuiTabs-flexContainer': {
			// width: "208px",
			border: '1px solid #F3F3F3',
			borderRadius: 8,
			overflow: 'hidden',
		},
		'& button': {
			// width: "48px",
			padding: '3px 10px',
			minWidth: 48,
			minHeight: 36,
			height: "36px",
			position: 'relative',
			fontSize: '0.813rem',
			letterSpacing: '-0.5px',
			color: '#787878',
			boxSizing: 'border-box',
			// padding:0,
			background: '#fff',
			'& .MuiTab-wrapper': {
				fontSize: '0.813rem',
				color: '#111'
			}
		},
		'& button.Mui-selected': {
			fontWeight: 'bold',
			color: '#fff',
			opacity: 1,
			boxSizing: 'border-box',
			background: '#2F80ED',
			borderRadius: 6,
			'& .MuiTab-wrapper': {
				color: '#fff'
			}
		},
		'& .MuiTabs-indicator': {
			height: "0px",
		}
	},
	checkedColor: {
		'& .MuiTypography-root': {
			fontSize: '1.125rem',
		},
		'& g': {
			stroke: '#7500fa',
			opacity: 1
		}
	},
	examDeleteIcon: {
		'&.MuiButton-root': {
			background: '#fff',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#F31825',
				fontWeight: 'bold'
			}
		}
	},
	importTaskBtn: {
		'&.MuiButtonBase-root': {
			background: '#CBCBCB',
			borderRadius: 6,
			marginBottom: "10px",
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				marginLeft: "5px",
			}
		}
	},
	importTaskBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			border: '1px solid #CBCBCB',
			borderRadius: 6,
			marginBottom: "10px",
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#111',
				marginLeft: "5px",
			}
		}
	},
	checkMargin: {
		marginRight: "30px",
		'& .MuiTypography-root': {
			fontSize: '1.125rem'
		},
		'& .MuiCheckbox-colorSecondary.Mui-checked:hover': {
			color: '#fff'
		},
		'& .MuiCheckbox-colorSecondary': {
			padding: 0,
			margin: 9,
		}
	},
	previewText: {
		'&.MuiTypography-root': {
			color: '#008C73',
			fontWeight: 'bold',
			fontSize: '1.25rem',
			marginBottom: "10px",
		}
	},
	previewSubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			marginBottom: "10px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 700,
			}
		}
	},
	previewSubTextNew: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			fontWeight: 400,
			letterSpacing: '-0.5px',
			'& span': {
				color: '#2F80ED',
				fontWeight: 700,
			}
		}
	},
	breadcrumbsList: {
		marginLeft: "40px",
		'& .MuiBreadcrumbs-li:first-child': {
			'& svg': {
				marginTop: "3px",
			}
		},
		'& .MuiBreadcrumbs-li:last-child': {
			'& .MuiTypography-root': {
				color: '#008C73',
				fontWeight: 'bold'
			}
		}
	},
	moreMenu: {
		border: '1px solid #CBCBCB',
		borderRadius: 3,
		width: "36px",
		height: "36px",
		marginRight: "25px",

	},
	popoverBox: {
		'& .MuiPaper-root': {
			width: "120px",
			boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
			border: '1px solid #bbb',
			borderRadius: 6,
			'& ul': {
				padding: '4px 0',
				'& li': {
					color: '#6f6f6f',
					letterSpacing: '-0.5px',
					display: 'flex',
					justifyContent: 'center',
					'& .MuiTypography-root': {
						marginLeft: "6px",
						fontSize: '0.875rem',
					},
					'&:hover': {
						background: '#eee'
					},
				},
			},
			'& *': {
				fontFamily: 'Pretendard-Regular !important',
			},
		}
	},

	answerFormAdd: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		'& .MuiButtonBase-root': {
			background: '#fff',
			'&:hover': {
				background: '#fff'
			}
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			letterSpacing: '-0.5px',
			color: '#111',
			marginRight: "3px",
		}
	},
	iconMove: {
		position: 'absolute',
		top: 0,
		left: '50%'
	},
	pageUpIcon: {
		position: 'absolute',
		bottom: '-10px',
		right: 0,
		'&.MuiIconButton-root': {
			border: '1px solid #fff',
			boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
			width: "40px",
			height: "40px",
			'&:hover': {
				background: '#fff'
			}

		}
	},
	menu: {
		'& .MuiList-root': {
			width: "388px",
			padding: 10,
		},
		'& .MuiTypography-root': {
			color: '#000',
			fontSize: '0.75rem',
		},
		// '& svg': {
		//     marginRight: 10
		// },

	},
	menuSubText: {
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#7e7e7e',
			marginBottom: "15px",
		},
	},
	titleTrashIcon: {
		marginTop: "12px",
		marginLeft: "10px",
	},
	guideBox: {
		background: 'rgba(47, 128, 237, 0.06)',
		padding: '11px 11px 16px 11px',
		border: '1px solid rgba(53, 82, 242, 0.10)',
		'& .MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 500,
			color: '#2F80ED'
		}
	},
	guideBoxSubText: {
		marginTop: "5px",
		marginLeft: "5px",
		marginBottom: "30px",
		'& .MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
			marginLeft: "5px",
		}
	},
	addAnswerBtn: {
		'&.MuiButtonBase-root': {
			marginBottom: "60px",
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontSize: '0.875rem'
			}
		}
	},
	sampleAnswerBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'right',
		'& .MuiButtonBase-root': {
			width: "167px",
			height: "40px",
			border: '1px dashed #111',
			marginBottom: "30px",
			marginTop: "10px",
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#111',
				fontSize: '1rem',
				fontWeight: 700,
				marginLeft: "5px",
			}
		}
	},
	sampleAnswerBoxActive: {
		'& .MuiButtonBase-root': {
			'& .MuiTypography-root': {
				color: 'red',
			}
		}
	},
	scrollBox: {
		overflowY: 'auto',
		height: 'calc(100vh - 290px)',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px"
		},
	},
	taskViewList: {
		border: '1px solid #cbcbcb',
		borderRadius: 15,
		height: "120px",
		padding: '30px 20px',
		boxSizing: 'border-box',
		marginBottom: "10px",
		boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)'
	},
	taskViewListOpen: {
		height: 'auto',
		display: 'block',
		// padding: '30px 0px',
	},






	listSubText: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 600,
			marginBottom: "10px",
		}
	},
	listTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			'& span': {
				color: '#03BF69'
			}
		}
	},
	listTitleGuide: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 400,
			color: '#9f9f9f',
		}
	},
	listBtnEditer: {
		'&.MuiButtonBase-root': {
			border: '1px solid #cbcbcb',
			borderRadius: 3,
			width: "114px",
			height: "37px",
			'&:hover': {
				background: '#fff'
			},
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#2F80ED',
				fontWeight: 700,
				letterSpacing: '-0.5px'
			}
		}
	},
	listBtnSelect: {
		'&.MuiButtonBase-root': {
			'& .MuiTypography-root': {
				color: '#03BF69',
			}
		}
	},
	iconBtnMoreMenu: {
		border: '1px solid #cbcbcb',
		width: "36px",
		height: "36px",
		borderRadius: 3,
	},
	topBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		background: '#fff',
		borderRadius: 8,
		// height: "170px",
		// marginBottom: "20px",
		// paddingTop: 35,
		'& .Mui-disabled': {
			'& .MuiStepLabel-iconContainer': {
				border: '1px solid #e5e5e5',
				color: '#e5e5e5',
				background: '#fff',
			}
		},
		'& .MuiStepIcon-root': {
			width: "14px",
			height: "14px",
		},
		'& .MuiStepConnector-alternativeLabel': {
			top: 20,
			left: 'calc(-50% + 30px)',
			right: 'calc(50% + 30px)',
		},
		'& .MuiStepLabel-iconContainer': {
			border: '1px solid #03BF69',
			width: "14px",
			height: "14px",
			borderRadius: '100%',
			color: '#fff',
			background: '#03BF69',
			fontSize: '1.5rem',
			fontWeight: 'bold',
			textAlign: 'center',
			display: 'inline-block',
			lineHeight: '38px',
		},
		'& .MuiStep-completed': {
			'& .MuiStepLabel-iconContainer': {
				border: '1px solid #5579f0',
				color: '#03BF69',
				background: '#fff',
			}
		},
		'& .MuiStepConnector-active': {
			'& .MuiStepLabel-iconContainer': {
				border: '1px solid #fff',
			}
		},
		'& .MuiStepLabel-label': {
			fontSize: '1rem',
			letterSpacing: '-0.5px',
			color: '#87888d',
			marginTop: "9px",
		},
		'& .MuiStepLabel-label.MuiStepLabel-completed': {
			color: '#03BF69',
		},
		'& .MuiStepLabel-label.MuiStepLabel-active': {
			color: '#03BF69',
			fontWeight: 600,
			fontSize: '1rem',
			letterSpacing: '-0.5px',
		},
	},
	activeStepIcon: {
		width: '450px',
		padding: 0,
		marginTop: "5px",
		'& .MuiStepIcon-active': {
			color: '#03BF69', // 활성 스텝 아이콘 색상 변경
		},
		'& .MuiStepIcon-completed': {
			border: '1px solid #5579f0',
			borderRadius: '100%',
			color: '#fff'
		},
	},
	backButton: {
		background: '#fff',
		boxShadow: 'none',
		padding: 0,
		'&:hover': {
			background: 'transparent',
			boxShadow: 'none',
		}
	},
	subSideMenu: {
		width: "500px",
		'& .MuiStepIcon-root.MuiStepIcon-active': {
			color: '#025949'
		},
		'& .MuiStepIcon-root.MuiStepIcon-completed': {
			color: '#025949'
		},
		'& .MuiStepIcon-root': {
			color: '#DDDDDD'
		},
		'& .MuiStepConnector-vertical': {
			padding: '0px 0 2px',
			marginLeft: "6px",
		},
		'& .MuiStepLabel-root': {
			flexDirection: 'column',
			'& .MuiStepLabel-vertical': {
			}
		},
		'& .MuiStepper-vertical': {
			padding: '24px 0px 24px 24px'
		},
		// '& .Mui-disabled': {
		//     '& rect': {
		//         fill: '#ddd'
		//     }
		// },
		'& .MuiStepper-root': {
			padding: 0,
		},
		'& .MuiStepLabel-label': {
			textAlign: 'center'
		},
		'& .MuiStepLabel-label.MuiStepLabel-active': {
			color: '#03BF69',
			fontWeight: 600,
			letterSpacing: '-0.5px',
		}
	},
	boxHeight: {
		height: "145px",
	},
	displayStyle: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'flex-start',
		marginBottom: "17px",
	},
	basicTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			fontWeight: 400,
			letterSpacing: '-0.4px',
			width: "120px",
		}
	},
	basicText: {
		paddingRight: 15,
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 500,
			letterSpacing: '-0.4px',
			width: '100%'
		}
	},
	editorIcon: {
		'&.MuiIconButton-root': {
			marginLeft: "8px",
			marginBottom: "13px",
		}
	},
	studentList: {
		marginTop: "10px",
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555',
			marginLeft: "2px",
			marginRight: "10px",
		}
	},
	schoolNameSearch: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			marginBottom: "10px",
			'& span': {
				color: '#008C73'
			}
		}
	},
	questionList: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			marginBottom: "20px",
		}
	},
	bestAnswerList: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			letterSpacing: '-1px',

		}
	},
	questionTextBox: {
		border: '2px solid #111',
		borderRadius: 3,
		padding: 10,
		height: "270px",
		marginTop: "20px",
		'& .MuiTypography-root': {
			fontSize: '1rem'
		}
	},
	tableTopTitle: {
		borderTop: '1px solid #cbcbcb',
		borderBottom: '1px solid #cbcbcb',
		background: '#f3f3f3',
		height: "40px",
		textAlign: 'center',
		padding: '7px 0',
		boxSizing: 'border-box',
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 700,
		}
	},
	tableTopText: {
		height: "85px",
		boxSizing: 'border-box',
		padding: '22px 15px',
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 400,
		}
	},
	titleBox: {
		background: '#F3F3F3',
		'&.MuiTableCell-root': {
			fontWeight: 700,
		}
	},
	TableArticel: {
		whiteSpace: 'unset !important',
		padding: '3px 10px !important',
		'& .MuiTypography-root': {
			fontSize: '0.875rem'
		}
	},
	exportBtn: {
		'&.MuiButtonBase-root': {
			background: '#cbcbcb',
			borderRadius: 3,
			width: "168px",
			height: "36px",
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#787878',
				fontWeight: 700,
				marginRight: "5px",
				width: "120px",
				borderRight: '1px solid #787878'
			}
		}
	},
	exportBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#008C73',
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#fff',
				fontWeight: 700,
				borderRight: '1px solid #fff'
			},
		}
	},
	dialogBtnGroup: {
		// marginTop: "30px",
		marginBottom: "20px",
	},
	dialogBtn: {
		'&.MuiButtonBase-root': {
			border: '1px solid #CBCBCB',
			width: "120px",
			height: "40px",
		},
		'&.MuiButton-root:hover': {
			background: '#fff'
		},
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	dialogBtnSave: {
		marginRight: "20px",
		'&.MuiButtonBase-root': {
			border: '1px solid #2F80ED',
			background: '#2F80ED',
			width: "120px",
			height: "40px",
			marginLeft: "15px",
		},
		'&.MuiButton-root:hover': {
			background: '#2F80ED'
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	urlInputBox: {
		'&.MuiPaper-root': {
			width: "180px",
			height: "35px",
			border: '1px solid #87878;',
			boxShadow: 'none'
		},
		'& .MuiInputBase-root': {
			width: "150px",
		},
		'& .MuiInputBase-input': {
			padding: '8px 10px 7px',
		}
	},
	allSelectDelete: {
		'&.MuiButtonBase-root': {
			background: '#fff',

		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#2F80ED',
			letterSpacing: '-0.5px',
			fontWeight: 500,
			marginLeft: "20px",
			position: 'relative',
			// '&:before':{
			//     content:"''",
			//     width: "4px",
			//     height: "4px",
			//     background:'#111',
			//     borderRadius: '50%',
			//     display: 'inline-block',
			//     position: 'absolute',
			//     left: '-13px',
			//     top: 10,
			// },
		}
	},
	allSelectDelete2: {
		'&.MuiButtonBase-root': {
			background: '#fff',

		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#2F80ED',
			letterSpacing: '-0.5px',
			fontWeight: 500,
			marginLeft: "20px",
			position: 'relative',
		}
	},
	selectedCount: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			letterSpacing: '-0.5px',
			fontWeight: 400,
			'& span': {
				color: '#008C73',
				fontWeight: 700,
				fontSize: '1rem',
				marginLeft: "5px",
			}
		}
	},
	pageNumber: {
		marginTop: "10px",
		paddingBottom: 10,
		borderBottom: '1px solid #CBCBCB',
		marginBottom: "10px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#555',
				color: '#fff',
				border: '1px solid #555',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #555',
			color: '#555',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},
	pageNumberAll: {
		marginTop: "20px",
		paddingBottom: 10,
		marginBottom: "10px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 600,
			borderRadius: '100%'
		}
	},
	popoverTooltip: {
		'& .MuiPopover-paper': {
			background: '#2078E8',
			color: '#fff',
			minWidth: 280,
			minHeight: 30,
			maxWidth: 200,
			padding: '10px 10px 10px 15px',
			overflow: 'visible',
			marginTop: "15px",
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '15px',
			lineHeight: '21px',
			letterSpacing: '-0.5px',
		}
	},
	tooltipTop: {
		position: 'absolute',
		top: -10,
		left: '47%',
		color: '#000'
	},
	tooltipBtn: {
		'& .MuiButtonBase-root': {
			padding: 0,
			minWidth: 'auto',
			'&:hover': {
				background: 'transparent'
			}
		}
	},
	tooltipTitle: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '1.125rem',
			letterSpacing: '-0.5px',
			fontWeight: 700,
			marginBottom: "5px",
		}
	},
	tooltipText: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '0.938rem',
			letterSpacing: '-0.5px',
			marginBottom: "10px",
		}
	},
	borderTop: {
		borderTop: '1px solid #cbcbcb',
		marginTop: "20px",
		paddingTop: 30,
	},
	iconPosition: {
		marginBottom: "12px",
		marginLeft: "5px",
	},
	printMenuBox: {
		'& .MuiList-root': {
			width: "180px",
			padding: 10,
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#6f6f6f',
			letterSpacing: '-0.5px',
		},
		'& svg': {
			marginRight: "10px",
		}
	},
	printTextBox: {
		border: '1px solid #e5e5e5',
		borderRadius: 3,
		padding: '3px 5px',
		marginTop: "10px",
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
			letterSpacing: '-0.5px'
		}
	},
	newSortBox: {
		background: '#F3F3F3',
		border: '1px solid #EAECEB',
		borderRadius: 2,
		padding: '20px 30px 10px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'end',
		marginBottom: "5px"
	},
	radioGroupBtn: {
		'& .MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 400,
			color: '#555',
			letterSpacing: '-0.5px',
			marginRight: "20px",
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#2F80ED'
		},
		'& .MuiRadio-colorSecondary': {
			padding: 0,
			marginRight: "5px",
		},
		'& .MuiFormControlLabel-root': {
			marginLeft: "0px",
		}
	},
	selectList: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		width: "100%",
		marginBottom: "20px",
	},
	selectList2: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
	},
	selectList3: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: "100%",
		marginBottom: "20px",
	},
	selectListText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			minWidth: 80,
			fontWeight: 400,
			letterSpacing: '-0.5px',
			color: '#111',
		}
	},
	selectListText2: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			minWidth: 80,
			fontWeight: 400,
			letterSpacing: '-0.5px',
			color: '#111',
			marginLeft: "40px",
		}
	},
	selectListText3: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			// minWidth: 80,
			fontWeight: 700,
			letterSpacing: '-0.5px',
			color: '#111;',
			marginBottom: "20px"
		}
	},
	btnGroup: {
		marginBottom: "0px",
	},
	btn1: {
		'&.MuiButtonBase-root': {
			width: "84px",
			height: "36px",
			boxSizing: 'border-box',
			background: '#2F80ED',
			borderRadius: 3,
			marginLeft: "20px",
			'& .MuiTypography-root': {
				color: '#fff',
				fontSize: '1rem',
				fontWeight: 400,
				letterSpacing: '-0.5px',
			}
		},
		// '&.Mui-disabled': {
		//     opacity: 0.5,
		//     border: '1px solid rgba(0, 0, 0, 0.12)',
		//     '& .MuiTypography-root': {
		//         color: 'rgba(0, 0, 0, 0.26)',
		//     },
		// }
	},
	btn2: {
		'&.MuiButtonBase-root': {
			width: "84px",
			height: "36px",
			boxSizing: 'border-box',
			background: '#CDCDCD',
			borderRadius: 3,
			marginLeft: "20px",
			'& .MuiTypography-root': {
				color: '#888888',
				fontSize: '1rem',
				fontWeight: 400,
				letterSpacing: '-0.5px',
			}
		}
	},
	btnDarkCover: {
		'&.MuiButtonBase-root': {
			border: '0px',
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				color: '#888888',
			}
		}
	},
	newGuideText: {
		marginBottom: "30px",
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '0.875rem',
			fontWeight: 400,
			letterSpacing: '-0.7px',
		}
	},
	loginInput: {
		'& .MuiFormControl-root': {
			width: '215px',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "36px",
			borderRadius: 2,
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
			height: "38px",
		},
		'& .MuiIconButton-root': {
			padding: 12,
		},
		'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
			color: '#f44336'
		}
	},
	conTitleTop: {
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 700,
			color: '#111',
			letterSpacing: '-0.5px',
			lineHeight: 'normal'
		}
	},
	allStudentTableBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "15px",
	},
	tableNoBox: {
		paddingLeft: '20px !important',
	},
	displayBetween: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},

	// kitty
	sortButton: {}


});












