import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	FormControl,
	FormControlLabel,
	IconButton,
	InputBase,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import '@mui/x-date-pickers/AdapterDayjs';
import clsx from "clsx";
// import 'dayjs/locale/ko';
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as DialogClose } from "../../common/images/DialogClose.svg";
import { ReactComponent as SearchNameIcon } from '../../common/images/SearchNameIcon.svg';
import { ReactComponent as SelectIcon } from '../../common/images/SelectIcon.svg';
import { ReactComponent as TableListOrder } from '../../common/images/TableListOrder.svg';
import { SchoolLevelSubjects } from "../../common/SchoolLevelSubjects";
import { ProjectSchoolType } from "../../stores/enums/ProjectStoreEnums";

import TemplateProjectListRows from "./10_ProjectLists/0_Lists/TemplateProjectListRows";
import SubjectMenuItem from "./10_ProjectLists/1_ExamBasicInfo/SubjectMenuItem";
import SubjectNameMenuItem from "./10_ProjectLists/1_ExamBasicInfo/SubjectNameMenuItem";
import TemplateProjectView from "./10_ProjectLists/6_Review/TemplateProjectView";
import useEmotionStyles from "./styles/useEmotionStyles";
import { TemplateProjectListForPublicStyle } from "./styles/TemplateProjectListForPublicStyle";

import { useStores } from "../../hooks/useStores";
import { DetailPageType } from "../../stores/support/DetailPageType";
import { GradeType } from "../../stores/support/GradeType";
import { StoreStatus } from "../../stores/support/StoreStatus";

type Props = {
};


function TemplateProjectList(props: Props) {
	const i18next = useTranslation();
	const classes = useEmotionStyles(TemplateProjectListForPublicStyle);
	// const theme = useTheme();

	const [createdSortRotated, setCreatedSortRotated] = React.useState(false);
	const [deadLineSortRotated] = React.useState(false);
	const [stepFilter, setStepFilter] = React.useState('all');
	const [page, setPage] = React.useState(1);
	const [rowsPerPage] = React.useState(10);
	const [selectedSortBtn, setSelectedSortBtn] = React.useState(1);
	const [subjectIndex, setSubjectIndex] = React.useState(-1);
	const [subjectNameIndex, setSubjectNameIndex] = React.useState(-1);
	const [dialogOpen, isDialogOpen] = React.useState(false);

	const { projectStore, navigateStore, storeManager } = useStores();

	const { searchFilter, project } = projectStore;
	const { storeState } = storeManager;


	const sortByCreateTime = "created_datetime";
	const sortByEndTime = "end_datetime";


	React.useEffect(() => {
		// const { loginUser } = authStore;
		const dir = convertSortingDirection(createdSortRotated);
		const sortingHints = [{ column: sortByCreateTime, hint: dir }];
		projectStore.getProjectListForShare(null, searchFilter.keyword, page - 1, rowsPerPage, sortingHints);

		navigateStore.changeNavigateRootPage(true);
		navigateStore.changeNavigateDetailPage(DetailPageType.PROJECT);
		return () => {

			projectStore.initForSearchFilter();
			navigateStore.changeNavigateDetailPage(null);
		}
	}, []);

	React.useEffect(() => {
		// const { loginUser } = authStore;
		let state = null;
		let sort = sortByCreateTime;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (stepFilter !== 'all')
			state = stepFilter;

		if (selectedSortBtn === 2) {
			sort = sortByEndTime;
			dir = deadLineSortRotated ? "ASC" : "DESC";
		}
		const sortingHints = [{ column: sort, hint: dir }];
		projectStore.getProjectListForShare(state, searchFilter.keyword, 0, rowsPerPage, sortingHints);

	}, [stepFilter, rowsPerPage, createdSortRotated, deadLineSortRotated])

	React.useEffect(() => {
		// const { loginUser } = authStore;
		let state = null;
		let sort = sortByCreateTime;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (stepFilter !== 'all')
			state = stepFilter;

		if (selectedSortBtn === 2) {
			sort = sortByEndTime;
			dir = deadLineSortRotated ? "ASC" : "DESC";
		}
		const sortingHints = [{ column: sort, hint: dir }];
		projectStore.getProjectListForShare(state, searchFilter.keyword, page - 1, rowsPerPage, sortingHints);

	}, [page])

	const convertSortingDirection = (flag) => {
		if (flag)
			return "ASC"
		else
			return "DESC"
	}

	const handleDeadLineSortClick = () => {
		setSelectedSortBtn(1);
		setCreatedSortRotated(!createdSortRotated);
	};

	// const handleSelectState = async (e) => {
	// 	// console.log(e);
	// 	setStepFilter(e.target.value);
	// }

	const handleClickPage = (e, value) => {
		setPage(value);
	}

	const handleClickKeywordSearch = () => {
		searchProjectList();
	}

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			searchProjectList();
		}
	}

	const searchProjectList = () => {
		// const { loginUser } = authStore;
		let sort = sortByCreateTime;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (selectedSortBtn === 2) {
			sort = sortByEndTime;
			dir = deadLineSortRotated ? "ASC" : "DESC";
		}
		const sortingHints = [{ column: sort, hint: dir }];
		if (stepFilter === 'all')
			projectStore.getProjectListForShare(null, searchFilter.keyword, 0, rowsPerPage, sortingHints);
		else
			projectStore.getProjectListForShare(stepFilter, searchFilter.keyword, 0, rowsPerPage, sortingHints);
	}

	const projectList = projectStore.searchFilter.projectTransfer;

	const handleChangeSchoolType = (e) => {
		const schoolType = e.target.value;
		projectStore.changeSearchFilterSchoolType(schoolType);
	}

	const handleChangeGrade = (e) => {
		const grade = e.target.value;
		projectStore.changeSearchFilterGrade(grade);
	}

	const handleChangeSubject = (e) => {
		const subject = e.target.value;
		projectStore.changeSearchFilterSubject(subject);
		if (subjectIndex > -1) {
			const subjectNameIndex = SchoolLevelSubjects[subjectIndex].subjectGroups.findIndex(item => item.name === searchFilter.subject);
			setSubjectNameIndex(subjectNameIndex);
		}
	}

	const handleChangeSubjectName = (e) => {
		const subjectName = e.target.value;
		projectStore.changeSearchFilterSubjectName(subjectName);
	}

	const handleChangeSearchKeyword = (e) => {
		const keyword = e.target.value;
		projectStore.changeSearchFilterKeyword(keyword);
	}

	React.useEffect(() => {
		const subjectIndex = SchoolLevelSubjects.findIndex(item => item.name === searchFilter.schoolType);
		setSubjectIndex(subjectIndex);
	}, [searchFilter.schoolType]);

	React.useEffect(() => {
		if (subjectIndex > -1) {
			const subjectNameIndex = SchoolLevelSubjects[subjectIndex].subjectGroups.findIndex(item => item.name === searchFilter.subject);
			setSubjectNameIndex(subjectNameIndex);
		}
	}, [searchFilter.subject, subjectIndex]);

	const handleClickForRow = () => {
		isDialogOpen(!dialogOpen);
	}

	return (
		<div className={classes.root}>

			{/* ******콘텐츠 영역******* */}
			<Box component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 86px)',
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box className={classes.titleTopMg}>
						<Typography className={classes.commonSubTitle}>
							{i18next.t("Search")} (<span>{projectList && projectList.totalCount && projectList.totalCount}</span>)
						</Typography>
					</Box>

					<Box style={{
						background: '#F3F3F3',
						border: '1px solid #EAECEB',
						padding: 25
					}} >
						<Box className={classes.basicTitleBox}>
							<Typography className={classes.dialogTitle2}>{i18next.t("학교급")}</Typography>
							<FormControl>
								<RadioGroup
									row
									aria-labelledby="school-type-radio-group"
									name="school-type-radio-buttons-group"
									onChange={handleChangeSchoolType}
									value={searchFilter.schoolType && searchFilter.schoolType}
									className={classes.radioGroupBtn}
								>
									<FormControlLabel value={ProjectSchoolType.ELEM} control={<Radio />} label={i18next.t("초등학교")} />
									<FormControlLabel value={ProjectSchoolType.MIDD} control={<Radio />} label={i18next.t("중학교")} />
									<FormControlLabel value={ProjectSchoolType.HIGH} control={<Radio />} label={i18next.t("고등학교")} />
									<FormControlLabel value={ProjectSchoolType.NOGRADE} control={<Radio />} label={i18next.t("해당 없음")} />
								</RadioGroup>
							</FormControl>
						</Box>
						<Box className={classes.basicTitleBox}>
							<Typography className={classes.dialogTitle2}>{i18next.t("학년/교과/과목")}</Typography>
							<Box className={classes.selectBoxWidth}>
								<FormControl className={classes.formControl}>
									<Select
										IconComponent={(props) => (<Box><SelectIcon  {...props} /></Box>)}
										MenuProps={{
											anchorOrigin: { vertical: "bottom", horizontal: "right" },
											transformOrigin: { vertical: 'top', horizontal: 'right' },
											// getContentAnchorEl: null,
										}}
										displayEmpty
										onChange={handleChangeGrade}
										value={searchFilter.grade ? searchFilter.grade : ""}
									>
										<MenuItem value={""}><Typography>{i18next.t("학년 선택")}</Typography></MenuItem>
										{searchFilter.schoolType ?
											searchFilter.schoolType === ProjectSchoolType.ELEM ?
												[<MenuItem value={GradeType.ELEMLOW}><Typography>1~2</Typography></MenuItem>,
												<MenuItem value={GradeType.ELEMMID}><Typography>3~4</Typography></MenuItem>,
												<MenuItem value={GradeType.ELEMHIGH}><Typography>5~6</Typography></MenuItem>]
												:
												<MenuItem value={GradeType.MIDDNHIGH}><Typography>1~3</Typography></MenuItem>
											:
											""}
									</Select>
								</FormControl>
							</Box>
							<Box className={classes.selectBoxWidth}>
								<SubjectMenuItem
									subjectIndex={subjectIndex}
									handleChangeSubject={handleChangeSubject}
									subject={searchFilter.subject}
								/>
							</Box>
							<Box className={classes.selectBoxWidth}>
								<SubjectNameMenuItem
									schoolTypeId={project.schoolType}
									subjectIndex={subjectIndex}
									subjectNameIndex={subjectNameIndex}
									subjectName={searchFilter.subjectName}
									project={project}
								/>
							</Box>
						</Box>

						<Box display='flex' alignItems='center' justifyContent='space-between'>
							<Box display='flex' alignItems='center' style={{ width: "100%", margin: 10 }}>
								<Typography className={classes.dialogTitle2}>{i18next.t("평가과제 명")}</Typography>
								<Paper component="form"
									className={classes.urlInputBox}>

									<IconButton className={classes.iconBtnReset} onClick={handleClickKeywordSearch} disableRipple>
										<SearchNameIcon />
									</IconButton>

									<InputBase sx={{ ml: 1, flex: 1 }}
										placeholder={i18next.t("평가과제 검색")}
										value={searchFilter.keyword}
										onChange={handleChangeSearchKeyword}
										onKeyDown={handleKeyDown}
										inputProps={{ 'aria-label': i18next.t("평가과제 검색") }} />
								</Paper>
							</Box>
							<Box>
								<Button className={clsx(classes.greenBtn, classes.iconAdd)}
									onClick={searchProjectList}
									disableRipple>
									<Typography>{i18next.t("검색")}</Typography>
								</Button>
							</Box>
						</Box>
					</Box>

					<Box>
						<TableContainer component={Paper} className={classes.tableBox} >
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell width='4%' className={classes.tableNoBox}>{i18next.t("No.")}</TableCell>
										<TableCell width='15%'>{i18next.t("평가과제명")}</TableCell>
										<TableCell width='7.5%'>{i18next.t("교육과정 시기")}</TableCell>
										<TableCell width='6.5%'>{i18next.t("학교급")}</TableCell>
										<TableCell width='6.5%'>{i18next.t("학년")}</TableCell>
										<TableCell width='5.5%'>{i18next.t("교과")}</TableCell>
										<TableCell width='5.5%'>{i18next.t("과목")}</TableCell>
										<TableCell width='8.5%'>
											<IconButton onClick={handleDeadLineSortClick} className={clsx(classes.iconBtnReset, classes.sortButton)} disableRipple>
												<Typography style={{ fontWeight: 800 }}>{i18next.t("등록일시")}</Typography>
												<TableListOrder style={{ transform: createdSortRotated ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} />
											</IconButton>
										</TableCell>
										{/*<TableCell width='8.5%'>진행 상태</TableCell>*/}
										<TableCell width='3%'>{i18next.t("관리")}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(projectList && projectList.projects && projectList.projects.length > 0) &&
										projectList.projects.map((project, index) => {
											return <TemplateProjectListRows
												key={project.code}
												project={project}
												index={index}
												page={page}
												rowsPerPage={rowsPerPage}
												isForShared={true}
												openAssignments={true}
												onClick={handleClickForRow}
											/>
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>

					<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
						<Stack spacing={2}>
							<Pagination count={projectList && projectList.projects && projectList.totalCount > 0 ?
								Math.ceil(projectList.totalCount / rowsPerPage) : 1}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleClickPage}
								variant="outlined" />
						</Stack>
					</Box>


					<Dialog className={classes.centerDialog} fullScreen open={dialogOpen}>
						<Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<Typography style={{ marginLeft: "30px" }}>{i18next.t("공유 디테일")}</Typography>
							<IconButton className={classes.iconBtnReset}
								style={{ height: "60px", marginRight: "10px" }}
								onClick={handleClickForRow}
								disableRipple>
								<DialogClose />
							</IconButton>
						</Box>
						<TemplateProjectView />
					</Dialog>

					<Backdrop
						style={{ color: '#fff', zIndex: 999999 }}
						open={storeState === StoreStatus.PROGRESS}>
						<CircularProgress color="inherit" />
						<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("조회 중입니다...")}</Typography>
					</Backdrop>
				</Box>
			</Box>
		</div>
	);





}

export default observer<typeof TemplateProjectList>(TemplateProjectList);
