import {
	Box,
	IconButton,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TestManageTableNStlye } from "../../styles/TestManageTableNStlye";

import '@mui/x-date-pickers/AdapterDayjs';
// import 'dayjs/locale/ko';
import { HtmlTooltip } from "../../../components/Tooltip";

import { useTranslation } from 'react-i18next';

type ManagementButtonProps = {
	label: string,
	button: JSX.Element,
	onClick: () => void
}

export function ManagementButton(props: ManagementButtonProps) {
	const classes = useEmotionStyles( TestManageTableNStlye );
	const { label, button, onClick } = props;
	const i18next = useTranslation();

	// const HtmlTooltip = styled(({ className, ...props }) => (
	// 	<Tooltip {...props} classes={{ popper: className }} />
	// ))(({ theme }) => ({
	// 	[`& .${tooltipClasses.tooltip}`]: {
	// 		backgroundColor: '#F4D219',
	// 		boxShadow: '0px 1px 1px -1px rgba(255,255,0,0.1)',
	// 		border: '0.5px solid #F3A50E',
	// 		borderRadius: 0,
	// 		marginTop: "5px",
	// 		color: '#111',
	// 		fontSize: '0.75rem',
	// 	},
	// }));

	const tooltip = (
		<Box className={classes.popover}>
			<Typography>{i18next.t(label)}</Typography>
		</Box>);

	return (
		<HtmlTooltip title={tooltip}>
			<IconButton className={classes.iconBtnReset}
				onClick={onClick}
				disableRipple>
				{button}
			</IconButton>
		</HtmlTooltip>
	)
}
