import {
	Box,
	IconButton,
	TableCell,
	TableRow
} from "@mui/material";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ReactComponent as AddSelect } from '../../../../common/images/AddSelect.svg';
import { ReactComponent as AddSelectActive } from '../../../../common/images/AddSelectActive.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { RubricStyle } from "../../styles/RubricStyle";


import React from "react";
import { useTranslation } from 'react-i18next';
import { useStores } from "../../../../hooks/useStores";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";
import { IRubricInfo } from "../../../../repositories/model/transfer/IRubricInfo";

type Props = {
	questionInfo: IQuestionInfo;
	rubricInfo: IRubricInfo;
	idx: number;
	systemRubricStartNum: number;
	selectedSystemRubricIdList: any;
	requestingAIRubric: any;
};


export const SystemRubricRow = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles(RubricStyle);
	const { questionInfo, rubricInfo, idx, systemRubricStartNum, selectedSystemRubricIdList, requestingAIRubric } = props;
	const { projectRubricStore } = useStores();

	const isSelected = React.useMemo(() => {
		return selectedSystemRubricIdList.includes(rubricInfo.id);
	}, [selectedSystemRubricIdList, rubricInfo.id]);

	const handleClickAddBtn = () => {
		if (isSelected) return;

		projectRubricStore.changeProjectRubricBySystemRubric(questionInfo, rubricInfo);
	};

	return (
		<TableRow >
			{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
			<TableCell align="center">
				{systemRubricStartNum + idx}
			</TableCell>
			<TableCell align="center">
				<Box>
					<IconButton
						className={classes.iconBtnReset}
						onClick={requestingAIRubric ? null : handleClickAddBtn}
						disableRipple>
						{isSelected ?
							<AddSelectActive /> :
							<AddSelect />
						}
					</IconButton>
				</Box>
			</TableCell>
			<TableCell>
				{rubricInfo.name}
			</TableCell>
			<TableCell>
				{i18next.t("{{standardCount}}단계 척도", { standardCount: rubricInfo.standardCount })}
			</TableCell>
			<TableCell>
				{rubricInfo.description}
			</TableCell>
		</TableRow>
	)
};
