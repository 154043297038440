import { makeNPageIdStr, uuidv4 } from "../../common/UtilFuncs";
import { getNumWorkers, getUseWorkers, setNumWorkers } from "../../config/mupdfWorkerConfig";
import { NeoPDFWorkerEmployer, createPDFWorkerEmployer } from "../PdfWorkerEmployerClass";
import { IPdfOpenOption } from "./DataTypes";
import { NeoPDFContext } from "./NeoPDFContext";
import { NeoPDFDocument } from "./NeoPDFDocument";
import EventClassBase from "./event/EventClassBase";

// eslint-disable-next-line no-use-before-define
let gPdfManager: NeoPDFManager | null = null;

/** @enum {string} */
export enum PdfManagerEventName {
  ON_PDF_LOADED = "on_pdf_loaded",
}

export type IPdfManagerEvent = {
  pdf?: NeoPDFDocument,     // ON_PDF_LOADED
}


function getUniqueKey(url: string, key: string, paperGroupId?: string) {
  if (paperGroupId) {
    return paperGroupId;
  }

  if (key) return key;

  if (!url?.length) return uuidv4();

  if (url.length > 512)
    url = url.substring(0, 512);
  const pos = url.lastIndexOf("/");
  if (pos > 0) {
    return url.substring(pos + 1);
  }

  return url;
}

const init = {
  nowInit: false
}

export class NeoPDFManager extends EventClassBase<PdfManagerEventName, IPdfManagerEvent> {
  _pdfMap: {
    [key: string]: {
      promise: Promise<NeoPDFDocument>
      pdf?: NeoPDFDocument,
      url?: string,
      referenceCount: number,
    }
  } = {};

  id = uuidv4();


  private employers: NeoPDFWorkerEmployer[] = null;

  private contexts: NeoPDFContext[] = null;

  static ready: Promise<NeoPDFManager> = null;

  private nextEmployerIndex = 0;

  get numWorkers() {
    return this.employers.length;
  }

  constructor() {
    super();
    let resolveReady: (value: NeoPDFManager) => void;
    NeoPDFManager.ready = new Promise<NeoPDFManager>((resolve) => { resolveReady = resolve });

    // console.log(`1673955168020_VeNSwSmaD :: NeoPDFManager.constructor()`)

    if (!getUseWorkers()) setNumWorkers(1);
    const numWorkers = getNumWorkers();
    this.employers = new Array(numWorkers);

    const prs = [];
    for (let i = 0; i < this.numWorkers; i++) {
      const emp = createPDFWorkerEmployer({ name: `PDFManager-${i}` });
      // console.log(`new Empolyer ${emp.id}`);
      this.employers[i] = emp;

      const promise = emp.initWorker();
      prs.push(promise);
    }


    const self = this;
    Promise.all(prs).then(async (rets) => {
      this.contexts = new Array(rets.length);

      for (let i = 0; i < rets.length; i++) {
        const pdfContext = await NeoPDFContext.fromGlobalContext(rets[i]);
        this.contexts[i] = pdfContext;
      }
      console.log("READY NeoPdfManager");
      resolveReady(self);
    });
  }


  static getInstance() {
    if (!gPdfManager) {
      gPdfManager = new NeoPDFManager();
    }

    return gPdfManager;
  }

  static get instance() {
    return NeoPDFManager.getInstance();
  }


  private getEmployerIndex = () => {
    const { nextEmployerIndex: nEmp } = this;
    this.nextEmployerIndex = (nEmp + 1) % this.employers.length;
    return nEmp;
  }

  public disposeDocument = async (pdf: NeoPDFDocument) => {
    try {
      pdf.free();
    } catch (e) {
      console.log(`disposeDocument: Failed to free pdf: ${pdf.fzId}`);
    }

    // try {
    //   const keys = Object.keys(this._pdfMap);

    //   let index = -1;
    //   for (let i = 0, ln = keys.length; i < ln; i++) {
    //     const key = keys[index];
    //     const item = this._pdfMap[key];
    //     if (item.pdf === pdf) { index = i; break }
    //   }

    //   if (index >= 0) {
    //     const key = keys[index];
    //     this._pdfMap[key] = null;
    //     delete this._pdfMap[key];
    //   }
    // } catch (e) {
    //   console.log(`disposeDocument: Failed to delete the mapped item: ${pdf.fzId}`);
    // }
  }

  public getDocument = async (options: IPdfOpenOption) => {
    await NeoPDFManager.ready;
    const { url, key, forceReload, pdfBytes, sobp, isJobStart, paperGroupId } = options;

    let { filename, ctx } = options;
    if (!url && !pdfBytes) {
      throw new Error("url or pdfBytes is required");
    }

    // console.log(`PDF Loading: ${url}`);

    if (!filename?.length && sobp) { filename = makeNPageIdStr(sobp) + ".pdf" };
    const uniqueKey = getUniqueKey(url, key, paperGroupId);

    // 로딩 중이면, 다시 로드하지 않는다.
    if (uniqueKey && !forceReload) {
      if (Object.prototype.hasOwnProperty.call(this._pdfMap, uniqueKey)) {
        this.accReferenceCount({ key: uniqueKey });
        console.log(`PDF Loading: ${uniqueKey} is already loading or loaded`);
        return this._pdfMap[uniqueKey].promise;
      }
    }
    // console.log(`PDF Loading: ${uniqueKey} is loading`);

    let emp: NeoPDFWorkerEmployer = null;
    if (ctx) {
      emp = ctx.employer;
    } else {
      const nEmp = this.getEmployerIndex();
      emp = this.employers[nEmp];
      ctx = this.contexts[nEmp];
    }

    const pdfToLoad: NeoPDFDocument = new NeoPDFDocument(emp, ctx, this);
    let pdfLoaded: NeoPDFDocument = null;
    try {
      let promise: Promise<NeoPDFDocument> = null;
      if (pdfBytes) {
        const docName = filename || key;
        // console.log(`load from buffer: ${docName}`)
        promise = pdfToLoad.openFromBuffer({ paperGroupId, buffer: pdfBytes.buffer, docName, isJobStart });
      }
      else {
        // console.log(`load from URL: ${url}`)
        promise = pdfToLoad.loadFromUrl(options);
      }

      const cacheItem = this.registerPdf({ pdf: null, promise, url, uniqueKey });
      // _pdfs[uniqueKey] = { promise, url, referenceCount: 1 };
      pdfLoaded = await promise;

      if (pdfLoaded) {
        cacheItem.pdf = pdfLoaded;
        this.dispatcher.dispatch(PdfManagerEventName.ON_PDF_LOADED, { pdf: pdfLoaded });
      }
    }
    catch (e) {
      console.error(`Failed to load pdf: ${url} ${filename} ${uniqueKey}`, pdfBytes);
      delete this._pdfMap[uniqueKey];
      throw e;
    }

    if (!pdfLoaded) {
      // 로딩 실패시, 삭제한다.
      console.error(`Failed to load pdf: ${url} ${filename} ${uniqueKey}`, pdfBytes);
      delete this._pdfMap[uniqueKey];
      throw new Error(`Failed to load pdf: ${url} ${filename} ${uniqueKey}`);
    }

    return pdfLoaded;
  }

  private addReferenceCount = (args: { pdf?: NeoPDFDocument, key?: string, delta?: number }) => {
    const { pdf, delta = 1 } = args;
    let { key } = args;
    if (!pdf && !key) return;

    if (pdf) {
      key = this.findItemByPdf(pdf);
    }

    if (key && Object.prototype.hasOwnProperty.call(this._pdfMap, key)) {
      this._pdfMap[key].referenceCount += delta;
    }
  }

  private accReferenceCount = (args: { pdf?: NeoPDFDocument, key?: string }) => {
    this.addReferenceCount({ ...args, delta: 1 });
  }

  private decReferenceCount = (args: { pdf?: NeoPDFDocument, key?: string }) => {
    this.addReferenceCount({ ...args, delta: -1 });
  }

  public registerPdf = (args: { pdf: NeoPDFDocument, uniqueKey: string, url: string, promise?: Promise<NeoPDFDocument> }) => {
    const { pdf, uniqueKey, url } = args;
    let { promise } = args;
    if (!promise) {
      let promiseResolve: (value: NeoPDFDocument) => void;
      promise = new Promise<NeoPDFDocument>((resolve) => { promiseResolve = resolve });
      promiseResolve(pdf);
    }

    this._pdfMap[uniqueKey] = { promise, url, pdf, referenceCount: 1 };

    return this._pdfMap[uniqueKey];
  }

  // private blankDocument: NeoPDFDocument = null;

  // public getBlankDocument = async () => {
  //   if (this.blankDocument) {
  //     this.accReferenceCount({ pdf: this.blankDocument });
  //     return this.blankDocument;
  //   }

  //   const nEmp = this.getEmployerIndex();
  //   const pdf = await this.create({ ctx: this.contexts[nEmp], manager: this, uniqueKey: uuidv4() });
  //   pdf.addBlankPage();
  //   this.blankDocument = pdf;

  //   this.registerPdf({ pdf, uniqueKey: "blank", url: "blank" });
  //   return pdf;
  // }



  createDocument = async (args: { ctx?: NeoPDFContext, uniqueKey: string, name?: string }) => {
    await NeoPDFManager.ready;

    let { ctx, name = null, uniqueKey } = args;
    if (!ctx) {
      const nEmp = this.getEmployerIndex();
      ctx = this.contexts[nEmp];
    }

    await ctx.ready;
    ctx.loadedDocs++;

    if (name === null) name = "NewFile";

    const docId = await ctx.employer.createNewPdfDocument(ctx.fzId);
    const doc = await NeoPDFDocument.fromDocumentId(ctx.employer, ctx, docId);
    this.registerPdf({ pdf: doc, uniqueKey, url: "" });


    // console.log("createDocument", doc.fzId, this._pdfMap);
    return doc;
  }


  public getContext = (nEmp?: number) => {
    if (nEmp === undefined) nEmp = this.getEmployerIndex();
    return this.contexts[nEmp];
  }


  public getEmployer = (nEmp?: number) => {
    if (nEmp === undefined) nEmp = this.getEmployerIndex();
    return this.employers[nEmp];
  }

  static addEventListener = (eventName: PdfManagerEventName, listener: (event: IPdfManagerEvent) => void) => {
    if (!gPdfManager) {
      gPdfManager = new NeoPDFManager();
    }

    gPdfManager.addEventListenerImpl(eventName, listener);
  }


  static removeEventListener = (eventName: PdfManagerEventName, listener: (event: IPdfManagerEvent) => void) => {
    if (!gPdfManager) { return }
    gPdfManager.removeEventListenerImpl(eventName, listener);
  }


  private addEventListenerImpl = (eventName: PdfManagerEventName, listener: (event: IPdfManagerEvent) => void) => {
    this.dispatcher.add(eventName, listener);
  }

  private removeEventListenerImpl = (eventName: PdfManagerEventName, listener: (event: IPdfManagerEvent) => void) => {
    this.dispatcher.remove(eventName, listener);
  }

  private findItemByPdf = (pdf: NeoPDFDocument) => {
    const keys = Object.keys(this._pdfMap);
    for (let i = 0, ln = keys.length; i < ln; i++) {
      const key = keys[i];
      if (this._pdfMap[key].pdf === pdf) {
        return key;
      }
    }

    return null;
  }

  public findItemById = (docId: string) => {
    const keys = Object.keys(this._pdfMap);
    for (let i = 0, ln = keys.length; i < ln; i++) {
      const key = keys[i];
      if (this._pdfMap[key].pdf.id === docId) {
        return this._pdfMap[key];
      }
    }
    return null;
  }

  public shouldPreserveDocument = (pdf: NeoPDFDocument, shouldDelete = true) => {
    const uniqueKey = this.findItemByPdf(pdf);
    if (uniqueKey) {
      this.decReferenceCount({ key: uniqueKey });
      if (this._pdfMap[uniqueKey].referenceCount <= 0) {
        if (shouldDelete) {
          delete this._pdfMap[uniqueKey];
        }
        return false;
      }
    }

    return true;
  }
}
