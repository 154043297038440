import i18next from "i18next";
import { useConfirm } from "material-ui-confirm";

export function useNeoConfirm() {

	const baseConfirm = useConfirm();
	const alert = async (
		message: string,
		titleText = undefined as string,
		okBtnText = undefined as string,
	) => {
		try {
			await baseConfirm({
				description: message,
				title: titleText,
				confirmationButtonProps: { variant: "contained" },
				confirmationText: okBtnText || i18next.t("확인"),
				hideCancelButton: true
			});
		} catch (e) {
		}
	}

	const confirm = async (
		message: string,
		titleText = undefined as string,
		okBtnText = undefined as string,
		cancelBtnText = undefined as string
	) => {
		let yesNo = false;
		try {
			await baseConfirm({
				description: message,
				title: titleText,
				confirmationButtonProps: { variant: "contained" },
				confirmationText: okBtnText || i18next.t("확인"),
				cancellationText: cancelBtnText || i18next.t("취소"),
			});
			yesNo = true;
		} catch (e) {
			yesNo = false;
		}

		return yesNo;
	}

	return { alert, confirm };
}
