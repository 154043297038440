import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	IconButton,
	Paper,
	TableContainer,
	Theme,
	Typography,
	useTheme
} from "@mui/material";
import { AnswerSummitStyle } from "../../styles/AnswerSummitStyle";
import useEmotionStyles from "../../styles/useEmotionStyles";
import clsx from "clsx";
import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as DialogClose } from '../../../../common/images/DialogClose.svg';
import { useParams } from "react-router-dom";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { useStores } from "../../../../hooks/useStores";
import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { ProjectExamType } from "../../../../repositories/model/support/ProjectExamType";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
import { WorksheetResultHeader } from "../../000_Common/WorksheetResultHeader";
import { QuestionAndReferenceTab } from "./QuestionAndReferenceTab";
import { ICompareDialogParams } from "./ICompareDialogParams";
import CompareNeoPenDataStudent from "../../20_Assignments/2_Submissions/StudentSubmission/CompareNeoPenDataStudent";

type Props = {
}

function StudentTestPaper(props: Props) {
	const { projectExamStore, projectStore, authStore, projectSubmissionStore, navigateStore, messageStore, projectSubmissionAnswerStore, } = useStores();

	const classes = useEmotionStyles(AnswerSummitStyle);
	const i18next = useTranslation();
	const [editOnlinePaper, setEditOnlinePaper] = React.useState(false);
	const [onlineAnswers, setOnlineAnswers] = React.useState([]);
	const [renderList, setRenderList] = React.useState([]);
	const [didSave, setDidSave] = React.useState(false);

	const [isProjectDownloaded, setIsProjectDownloaded] = React.useState(false);
	const [isProjectAnswerDownloaded, setIsProjectAnswerDownloaded] = React.useState(false);
	const [isProjectExamDownloaded, setIsProjectExamDownloaded] = React.useState(false);

	// const { navigate, location } = props;
	const { projectCode } = useParams();

	const { projectExamList } = projectExamStore;
	const { project, projectSelectedState } = projectStore;
	const { projectSubmissionCode } = projectSubmissionStore;
	const { submissionAnswers } = projectSubmissionAnswerStore;
	const { loginUser } = authStore;
	const theme = useTheme();

	const [compareNeoPenDataParam, setCompareNeoPenDataParam] = React.useState<ICompareDialogParams | null>(null);
	const [submissionTransfers, setSubmissionTransfers] = React.useState([]);


	const naviCustom = useNaviCustom();

	React.useEffect(() => {

		const downloadAnswer = async () => {
			if (projectSubmissionCode) {
			  await projectSubmissionAnswerStore.downloadSubmissionAnswersAndStore(projectSubmissionCode);
			}

			setIsProjectAnswerDownloaded(true);
		};

		const downloadProjectStore = async () => {
			await projectStore.downloadProjectInfoAndStore(projectCode);

			setIsProjectDownloaded(true);
		};


		const downloadExamData = async () => {
			await projectExamStore.downloadProjectExamListAndStore(projectCode);
			setIsProjectExamDownloaded(true);

			downloadAnswer();
		};


		downloadProjectStore();
		downloadExamData();


		return () => {
			projectStore.initProject();
			projectExamStore.initProjectExamList();
			projectSubmissionStore.changeProjectSubmissionCode(null);
			navigateStore.changeTopBarLeftItem(null);
			navigateStore.changeTopBarRightItem(null);

		};
	}, []);

	React.useEffect(() => {
		let i = 0;
		let renderFlagArr = [];
		projectExamList.filter(e => e.changeFlag !== ChangeFlag.DELETED)
			.forEach((exam, index) => {
				exam.questions.filter(q => q.changeFlag !== ChangeFlag.DELETED)
					.forEach((question) => {
						if (exam.type === ProjectExamType.DESCRIPTIVE) {
							if (i === 2) {
								i = 1;
								renderFlagArr.push(true);
							} else {
								i++;
								renderFlagArr.push(false);
							}
						} else {
							i = 1;
							renderFlagArr.push(true);
						}
					})
			});
		renderFlagArr[0] = false;
		setRenderList(renderFlagArr);

		if (projectSubmissionCode && submissionAnswers.length > 0) return;
		let tempArr = [];
		projectExamList.forEach((item) => {
			item.questions.filter(q => q.changeFlag !== ChangeFlag.DELETED).forEach(question => {
				tempArr.push(
					{
						projectExamCode: question.projectExamCode,
						projectExamQuestionNum: question.projectExamQuestionNum,
						answer: ""
					}
				)
			})
		});
		setOnlineAnswers(tempArr);
	}, [projectExamList, isProjectExamDownloaded])

	React.useEffect(() => {
		// console.log("submissionAnswers : ", submissionAnswers);
		if (submissionAnswers.length === 0) {
			setDidSave(false);
			// return;
		} else if (submissionAnswers && submissionAnswers.length > 0) {
			let tempArr = cloneDeep(submissionAnswers);
			setOnlineAnswers(tempArr);

			// console.log("tempArr : ", tempArr);

			let flag = tempArr.findIndex((submissionAnswer) =>
				submissionAnswer.answer !== null
			)
			if (flag === -1) {
				setDidSave(false);
			} else {
				setDidSave(true);
			}
		}
	}, [submissionAnswers, isProjectAnswerDownloaded])


	React.useEffect(() => {
		navigateStore.changeTopBarLeftItem(topBarLeftItem);
		navigateStore.changeTopBarRightItem(topBarRightItem);
	}, [project, editOnlinePaper, isProjectDownloaded])

	const checkDeadLine = (deadline) => {
		// console.log("@@", deadline)
		if (!deadline)
			return true;
		const isBefore = dayjs().isBefore(deadline);
		// console.log("@@", isBefore)
		return isBefore;
	}

	const topBarLeftItem = () => {
		return (
			<>
				<Typography style={{ paddingLeft: 20, fontWeight: 'bold', marginRight: "20px" }}>
					<span>{projectStore.project.name}</span>
				</Typography>

				<Typography >
					({
						projectExamStore.projectExamList.length > 0 && projectExamStore.projectExamList[0].type === ProjectExamType.DESCRIPTIVE ?
							i18next.t("서술형평가") : i18next.t("논술형평가")
					})
				</Typography>
			</>
		)
	}

	const topBarRightItem = () => {
		return (
			<>{projectSelectedState && projectCode && project && checkCompared(projectSelectedState) && checkDeadLine(project.endDatetime) ?
				<Box style={{ alignItems: "right" }}>
					{editOnlinePaper ?
						<>
							<Button className={clsx(classes.solveButton, classes.projectPrint)} style={{ marginRight: '20px' }} onClick={handleClickCancel}>
								<Typography>{i18next.t("취소")}</Typography>
							</Button>
							<Button className={clsx(classes.solveButton, classes.projectPrint)} style={{ marginRight: '20px' }} onClick={handleClickSave}>
								<Typography>{didSave ? i18next.t("수정") : i18next.t("저장")}</Typography>
							</Button>
							<Button className={clsx(classes.solveButton, classes.onlineSolve)} onClick={handleClickOnlineSubmit}>
								<Typography>{i18next.t("온라인 제출하기")}</Typography>
							</Button>
						</>
						:
						<>
							<Button className={clsx(classes.solveButton, classes.projectPrint)} style={{ marginRight: '20px' }} onClick={handleOpenPenDialog}>
								<Typography>
									{didSave ? i18next.t("스마트펜 데이터 재업로드") : i18next.t("스마트펜 데이터 업로드")}
								</Typography>
							</Button>

							<Button className={clsx(classes.solveButton, classes.onlineSolve)} style={{ marginRight: '20px' }} onClick={handleClickOnline}>
								<Typography>
									{didSave ? i18next.t("답안 수정하기") : i18next.t("키보드 답안 작성")}
								</Typography>
							</Button>
						</>
					}
					<IconButton style={{ marginRight: '10px' }} disableRipple onClick={handleClickX}>
						<DialogClose />
					</IconButton>
				</Box>
				:
				<IconButton style={{ marginRight: '10px' }} disableRipple onClick={handleClickX}>
					<DialogClose />
				</IconButton>
			}</>
		)
	}

	const checkCompared = (state) => {
		switch (state) {
			case ProjectStateType.CREATED:
			case ProjectStateType.POSED:
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
				return true;
			case ProjectStateType.COMPARED:
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
				return false;
			default:
				return false;
		}
	}

	const handleClose = () => {
		navigateStore.changeTopBarLeftItem(null);
		navigateStore.changeTopBarRightItem(null);
		// props.handleClickClose();
		naviCustom.go(`/projects`);
		// props.navigate(`/projects`);
	}

	const handleClickX = async () => {
		if (!editOnlinePaper) {
			handleClose();
			return;
		}
		let confirm = await messageStore.confirm(i18next.t("저장하지 않고 페이지를 나가시겠습니까?"));
		if (confirm) {
			handleClose();
		}
	}
	const handleClickOnlineSubmit = async () => {
		let confirm = await messageStore.confirm(i18next.t("제출하면 더 이상 수정이 불가능 합니다. 제출 하시겠습니까?"));
		if (confirm) {
			setEditOnlinePaper(false);
			// let response;
			if (projectSubmissionStore.projectSubmissionCode) {
				if (projectSubmissionAnswerStore.submissionAnswers.length > 0) {
					projectSubmissionAnswerStore.changeCompareAnswer(onlineAnswers);
					await projectSubmissionAnswerStore.updateSubmissionAnswers();
					projectStore.updateProjectUserState(projectCode, loginUser.code, ProjectStateType.COMPARED, true)
				} else {
					await projectSubmissionAnswerStore.createSubmissionAnswers(
						projectSubmissionStore.projectSubmissionCode,
						onlineAnswers
					)
					projectStore.updateProjectUserState(projectCode, loginUser.code, ProjectStateType.COMPARED, true)
				}
			} else {
				const response = await projectSubmissionStore.createOnlineSubmission(projectCode, loginUser.code);
				await projectSubmissionAnswerStore.createSubmissionAnswers(response, onlineAnswers);
				projectStore.updateProjectUserState(projectCode, loginUser.code, ProjectStateType.COMPARED, true);
			}
			handleClose();
		}
	}

	const handleClickOnline = async () => {
		// if(checkCompared()){
		//
		// }
		let confirm = await messageStore.confirm(i18next.t("온라인 풀이를 시작 하시겠습니까?"));
		if (!confirm) return;
		setEditOnlinePaper(true);
	}
	const handleClickCancel = async () => {
		let confirm = await messageStore.confirm(i18next.t("저장하지 않은 정보는 삭제됩니다. 취소 하시겠습니까?"));
		if (!confirm) return;

		if (projectSubmissionAnswerStore.submissionAnswers && projectSubmissionAnswerStore.submissionAnswers.length > 0) {
			let tempArr = cloneDeep(projectSubmissionAnswerStore.submissionAnswers);
			setOnlineAnswers(tempArr);
		} else {
			let tempArr = [];
			projectExamList.forEach((item) => {
				item.questions.filter(q => q.changeFlag !== ChangeFlag.DELETED).forEach(question => {
					tempArr.push(
						{
							projectExamCode: question.projectExamCode,
							projectExamQuestionNum: question.projectExamQuestionNum,
							answer: ""
						}
					)
				})
			});
			setOnlineAnswers(tempArr);
		}
		setEditOnlinePaper(false);
	}



	// const handleClickPrint = () => {
	// 	window.print();
	// }

	const handleClickSave = async () => {
		let confirm = await messageStore.confirm(i18next.t("중간 저장 하시겠습니까?"));
		if (confirm) {
			// let response;
			if (projectSubmissionStore.projectSubmissionCode) {
				if (projectSubmissionAnswerStore.submissionAnswers.length > 0) {
					await projectSubmissionAnswerStore.changeCompareAnswer(onlineAnswers);
					await projectSubmissionAnswerStore.updateSubmissionAnswers(true);
				} else {
					await projectSubmissionAnswerStore.createSubmissionAnswers(projectSubmissionStore.projectSubmissionCode, onlineAnswers, true);
				}
			} else {
				await projectSubmissionStore.createOnlineSubmission(projectCode, loginUser.code).then((response) => {
					projectSubmissionAnswerStore.createSubmissionAnswers(response, onlineAnswers, true);
				});

			}
			setEditOnlinePaper(false);
		}
	}

	const handleChangeOnlineAnswers = (answer, index) => {
		let tempArr = [...onlineAnswers];
		tempArr[index].answer = answer;
		setOnlineAnswers(tempArr);
	}



	const handleOpenPenDialog = async () => {
		const result = await projectSubmissionStore.getSubmissionTransfersWithReturnForStudentPenSubmit(projectCode, authStore.loginUser.code)
		if (!result) {
			console.log("No submission found for the user code.");
			return null; // or return some default value, if needed
		}

		const projectInfo = await projectStore.fetchProject(projectCode);
		const templateCode = projectInfo?.templateCode;

		if (!templateCode) {
			return null;
		}

		// Construct the ICompareDialogParams object
		const param: ICompareDialogParams = {
			projectCode: projectCode, // Using the provided projectCode
			templateProjectCode: templateCode, // Replace with actual templateProjectCode if needed
			submissionCode: result.submissionCode, // Assuming result has submissionCode
			submissionTransfer: { ...result, submissionIndex: 0 } // Assuming result is of type IProjectSubmissionTransfer
		};

		setCompareNeoPenDataParam(param);
		/*
				interface ICompareDialogParams {
					projectCode: string;
					templateProjectCode: string;
					submissionCode: string;
					submissionTransfer: IProjectSubmissionTransfer;
				}
		*/

		//		setSelectedSubmissionTransfer({ ...submissionTransfer, submissionIndex });
		setNoShowPenDialog(false);
		setCompareNeoPenOpen(true);
	}


	const [noShowPenDialog, setNoShowPenDialog] = React.useState(false);
	const [compareNeoPenOpen, setCompareNeoPenOpen] = React.useState(false);

	const handleClickClose = () => {
		setCompareNeoPenOpen(false);
		// projectSubmissionStore.getSubmissionTransfers(projectCode, filterState, keyword, page-1, rowsPerPage, sortingHints);
	}

	return (
		<div className={classes.root} >
			{/* <TopBar leftItem={topBarLeftItem} rightItem={topBarRightItem}/> */}

			{
				<Backdrop
				style={{ color: '#fff', zIndex: (theme as Theme).zIndex.modal + 1 }}
				open={!isProjectAnswerDownloaded}>
				<CircularProgress color="inherit" />
				</Backdrop>

			}
			<Box style={{ alignItems: 'center', width: "100%", display: 'flex', justifyContent: 'center', background: '#F2F7F3' }}>
				<Box className={classes.mainContentBox}>
					<Box>
						<Box className={classes.titleBoxStudent}>
							<Typography>{project && project.name}</Typography>
							{/*<Box>*/}
							{/*    <IconButton className={classes.iconBtnReset} disableRipple>*/}
							{/*        <ScreenPlus />*/}
							{/*    </IconButton>*/}
							{/*    <IconButton className={classes.iconBtnReset} disableRipple>*/}
							{/*        <ScreenMinus />*/}
							{/*    </IconButton>*/}
							{/*</Box>*/}
						</Box>
						<TableContainer component={Paper} className={classes.tableBox2}>
							<WorksheetResultHeader
								classes={classes}
								projectTargetGroupName={project.targetGroupName}
								projectYear={project.year}
								projectSemester={project.semesterType}
								projectSubjectName={project.subjectName}

								userName={loginUser.nickname}
								grade={loginUser.grade}
								className={loginUser.className}
								number={loginUser.number}
							/>
						</TableContainer>
					</Box>

					{/*<Box className={classes.compareContent} >*/}
					<QuestionAndReferenceTab
						editOnlinePaper={editOnlinePaper}
						onlineAnswers={onlineAnswers}
						changeAnswers={handleChangeOnlineAnswers}
					/>
					{/*</Box>*/}
				</Box>
			</Box>
			{/*<div className={classes.onlyPrint}>*/}
			{/*    <PrintAnswerSheet project={project} projectExamList={projectExamList} renderList={renderList}/>*/}
			{/*</div>*/}

			{compareNeoPenDataParam && (
				<Dialog fullScreen open={compareNeoPenOpen} >
					<CompareNeoPenDataStudent
						projectCode={compareNeoPenDataParam.projectCode}
						templateProjectCode={compareNeoPenDataParam.templateProjectCode}
						handleClickClose={handleClickClose}
						submissionTransfer={compareNeoPenDataParam.submissionTransfer}
						//						submissionCode={compareNeoPenDataParam.submissionCode}
						noShowPenDialog={noShowPenDialog}
					/>
				</Dialog>
			)}

		</div>
	);
}

export default StudentTestPaper;
