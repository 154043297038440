import {
	Box,
	TextField
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { AnswerSummitStyle } from "../../styles/AnswerSummitStyle";


import { useTranslation } from 'react-i18next';
import TextViewer from "../../../../components/common/TextViewer";
import { ProjectExamType } from "../../../../repositories/model/support/ProjectExamType";
import { useCurrentOrder } from "../../000_Common/001_Functions/useCurrentOrder";
import { useCurrentQuestionHTML } from "../../000_Common/001_Functions/useCurrentQuestionHTML";
import { useStores } from "../../../../hooks/useStores";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";

type QuestionAndReferenceTabQuestionRowProps = {
	examInfo: IExamInfo,
	questionInfo: IQuestionInfo,
	editOnlinePaper,
	projectExamList,
	onlineAnswers,
	changeAnswers
}

export const QuestionAndReferenceTabQuestionRow = (props: QuestionAndReferenceTabQuestionRowProps) => {
	const { projectExamStore } = useStores();

	const i18next = useTranslation();

	const { examInfo, questionInfo, editOnlinePaper, projectExamList, onlineAnswers, changeAnswers } = props;
	const classes = useEmotionStyles( AnswerSummitStyle );

	// const currentOrder = React.useMemo(() => {
	// 	const questionListLen = examInfo.questions.filter(q => q.changeFlag !== FlagType.DELETED).length;
	// 	return projectExamStore.setQuestionFullOrderByQuestionListLen(questionListLen, examInfo.order, questionInfo.order);
	// }, [examInfo, examInfo.order, questionInfo.order]);
	const currentOrder = useCurrentOrder(projectExamStore, examInfo, questionInfo);

	// const currentQuestionHTML = React.useMemo(() => {
	//     return setQuestionHTMLWithOrderAndScore(questionInfo.question, questionInfo.score, currentOrder);
	// }, [questionInfo, questionInfo.question, questionInfo.score, currentOrder]);

	// const currentQuestionHTML = React.useMemo(() => {
	// 	return setQuestionHTMLWithOrderAndScore(questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder);
	// }, [questionInfo, questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder]);
	const currentQuestionHTML = useCurrentQuestionHTML(projectExamStore, questionInfo, currentOrder);

	//
	// const examQuestionNum = (projectExamList)=>{
	//     let questionNum = 0;
	//     for(let i=0;i<examInfo.order;i++){
	//         if(i+1 === examInfo.order){
	//             questionNum = questionNum + questionInfo.order;
	//         }else{
	//             for(let j=0;j<projectExamList[i].questions.length;j++){
	//                 questionNum++;
	//             }
	//         }
	//     }
	//     return questionNum;
	// }
	const examQuestionNum = (projectExamList) => {
		if (onlineAnswers && onlineAnswers.length > 0) {
			let questionNum = onlineAnswers.findIndex((answer) => answer && answer.projectExamCode === questionInfo.projectExamCode &&
				answer.projectExamQuestionNum === questionInfo.projectExamQuestionNum);
			return questionNum;
		} else
			return -1;
	}
	const questionNum = examQuestionNum(projectExamList);

	return (
		<>
			{/*<Box display='flex' alignItems='center' justifyContent='left' className={clsx(classes.dialogInput, classes.dialogInputMulti)}>*/}
			{!!currentQuestionHTML && (
				<>
					<div style={{ overflow: "hidden" }}>
						<Box className={classes.questionName} display='flex' alignItems='center' justifyContent='left'>
							<TextViewer
								currentHTML={currentQuestionHTML}
							/>
						</Box>
					</div>

					<Box className={classes.answerSheet}>
						{examInfo.type === ProjectExamType.ESSAY ?
							// <>
							//     {editOnlinePaper ?
							// <Box  style={{display:'flex' ,alignItems:'center' ,justifyContent:'left' }}  className={clsx(classes.dialogInput, classes.dialogInputMulti)}>
							<TextField
								id="outlined-basic-essay"
								autoComplete="off"
								// autoFocus={questionNum===1 ? true : false}
								// autoFocus={questionInfo && questionInfo.projectExamCode === onlineAnswers[0].projectExamCode &&
								//     questionInfo.projectExamQuestionNum === 1}
								variant="outlined"
								sx={{ overflowY: "auto" }}
								value={onlineAnswers[questionNum] && onlineAnswers[questionNum].answer}
								onChange={(e) => changeAnswers(e.target.value, questionNum)}
								fullWidth
								disabled={!editOnlinePaper}
								multiline
								maxRows={25}
								minRows={6}
								placeholder={i18next.t("답안 작성")} />
							// </Box>
							//         :
							//         <Box className={classes.bestAnswerBox2} style={{wordBreak:"break-all"}}>
							//             <Typography >{onlineAnswers.length > 0 && onlineAnswers[questionNum]  && onlineAnswers[questionNum].answer}</Typography>
							//         </Box>
							//     }
							// </>


							:
							// <>{editOnlinePaper ?
							// <Box sx={{width:9999,maxWidth:"100%"}}>
							<TextField
								id="outlined-basic-essay2"
								autoComplete="off"
								// autoFocus={questionInfo && onlineAnswers[0] && questionInfo.projectExamCode === onlineAnswers[0].projectExamCode &&
								//     questionInfo.projectExamQuestionNum === 1}
								variant="outlined"
								value={onlineAnswers[questionNum] && onlineAnswers[questionNum].answer}
								maxRows={6}
								minRows={3}
								onChange={(e) => changeAnswers(e.target.value, questionNum)}
								fullWidth
								disabled={!editOnlinePaper}
								multiline
								placeholder={i18next.t("답안 작성")} />
							// </Box>
							//     :
							//     <Box className={classes.bestAnswerBox1} style={{wordBreak:"break-all"}} >
							//         <Typography style={{ fontSize: '1vw'}}>{onlineAnswers.length > 0 && onlineAnswers[questionNum] && onlineAnswers[questionNum].answer}</Typography>
							//     </Box>
							// }</>

						}
					</Box>
				</>
			)}
		</>
	);
};
