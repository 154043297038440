import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import clsx from "clsx";
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../lang';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { LandingComponentStyle } from "../../styles/LandingComponentStyle";

import { ReactComponent as ClockIcon } from "../../../../common/images/ClockIcon.svg";
import { ReactComponent as KeywordIcon } from "../../../../common/images/KeywordIcon.svg";
import { ReactComponent as LanguageIcon } from '../../../../common/images/LanguageSelectWhite.svg';
import { ReactComponent as LogoWithTile } from "../../../../common/images/logo_aigle.svg";
import { ReactComponent as PersonIcon } from "../../../../common/images/PersonIcon.svg";

import { observer } from "mobx-react";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { useStores } from "../../../../hooks/useStores";

type Props = {
}

function LandingComponent(props: Props) {
	const classes = useEmotionStyles( LandingComponentStyle );
	const cardList = [
		{ title: i18n.t('시간 절약'), content: i18n.t('복잡한 평가 과정을 단순화하여 교사의 시간을 절약해 드립니다.'), icon: <ClockIcon /> },
		{ title: i18n.t('향상된 학습 효과'), content: i18n.t('AI기술을 통해 학생 개개인에 맞춘 교육이 가능해집니다.'), icon: <PersonIcon /> },
		{ title: i18n.t('데이터 기반 의사결정'), content: i18n.t('데이터 분석을 기반으로 효과적인 교육전략을 세울 수 있습니다.'), icon: <KeywordIcon /> },
	]

	// const navigate = useNavigate();
	// const location = useLocation();
	// const { navigate, location } = props;
	const { navigateStore } = useStores();;
	const naviCustom = useNaviCustom();

	const handleClickStart = () => {
		naviCustom.go(`/login`);
		// props.navigate(`/login`);
	}

	const handleClickDownload = () => {
		naviCustom.go(`/download`);
		// props.navigate(`/login`);
	}


	const i18next = useTranslation();


	const languages = [
		{ code: 'ko', name: '한국어' },
		{ code: 'ja', name: '日本語' },
		{ code: 'en', name: 'English' }
	];


	const [anchorLangEl, setAnchorLangEl] = React.useState(null);
	const handleLangClick = (event) => { setAnchorLangEl(event.currentTarget); };
	const handleLangClose = () => { setAnchorLangEl(null); };
	const openLang = Boolean(anchorLangEl);
	const [lang, setLang] = React.useState(navigateStore.language);
	const handleLanguageSelect = (code) => {
		navigateStore.changeLanguage(code);
		setLang(code);
		handleLangClose();
	}


	const id = openLang ? 'simple-popover' : undefined;
	return (
		<Box className={classes.container}>
			<Box className={classes.topbar}>
				<LogoWithTile className={classes.logo} />
				<Box>
					<IconButton
						className={clsx(classes.iconBtnReset, classes.langButtonStyle)}
						onClick={handleLangClick}
						sx={{ marginRight: "20px" }}
						disableRipple

					>
						<LanguageIcon />
					</IconButton>
					<Popover id={id}
						open={openLang}
						anchorEl={anchorLangEl}
						onClose={handleLangClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
						className={classes.LanguageBox}>
						<Box className={classes.LanguageBoxInner}>
							{/* <Typography className={classes.languageTitle}>{i18next.t("언어 선택")}</Typography> */}
							{languages.map((language) => (
								<Button
									key={language.code}
									className={`${classes.LanguageButton} ${lang === language.code ? classes.SelectedLanguage : ''}`}
									onClick={() => handleLanguageSelect(language.code)}
									disableRipple
								>
									<Typography>{language.name}</Typography>
								</Button>
							))}
						</Box>
					</Popover>

					<Button className={classes.buttonStyle} onClick={handleClickDownload} sx={{ marginRight: "10px" }}>
						{i18next.t("필요한 기능 다운로드")}
					</Button>


					<Button className={classes.buttonStyle} onClick={handleClickStart}>
						{i18next.t("바로 사용하기")}
					</Button>
				</Box>
			</Box>
			<Box className={classes.lendingWrap}>
				<Box className={classes.info}>
					<Typography className={classes.slogan}>
						<b>{i18next.t("AI기술")}</b>{i18next.t("을 활용한")} <br /> <b>{i18next.t("미래의 교육")}</b>{i18next.t("을 위한 AiGle")}<br />
					</Typography>
					<Typography className={classes.bodyStyle}>
						{i18next.t("과제 평가 준비부터 채점까지, 모든 과정을 아이글과 함께 정확하고 공정한 평가를 가능하게 합니다.")}<br />
						{i18next.t("미래 AI기술을 활용한 서논술형 평가 도구 아이글이 함께합니다.")}
					</Typography>
				</Box>

				<Box className={classes.boxStyle}>
					{cardList.map((item, i) => (
						<Box key={item.title} className={classes.card}>
							<Typography className={classes.subTitle}>{item.title}</Typography>
							<Typography className={classes.contentTxt}>
								{item.content}</Typography>
							{item.icon}
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
}

export default observer<typeof LandingComponent>(LandingComponent);
