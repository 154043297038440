// client only
export enum GradeType {
	ELEMLOW = "1~2",
	ELEMMID = "3~4",
	ELEMHIGH = "5~6",
	MIDDNHIGH = "1~3",

	NONE = "0",
	G1 = "1",
	G2 = "2",
	G3 = "3",
	G4 = "4",
	G5 = "5",
	G6 = "6",
	G7 = "7",
	G8 = "8",
	G9 = "9",
	G10 = "10",
	G11 = "11",
	G12 = "12",
	G13 = "13",
	G14 = "14",
	G15 = "15",
	G16 = "16",
}

