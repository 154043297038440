import {
	Backdrop,
	Box,
	Checkbox,
	CircularProgress,
	Dialog,
	FormControlLabel,
	IconButton,
	InputBase,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { MarkFeedbackListStyle } from "../../styles/MarkFeedbackListStyle";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { ReactComponent as CheckCircleIcon } from '../../../../common/images/CheckCircleIcon.svg';
import { ReactComponent as CheckCircleIconOff } from '../../../../common/images/CheckCircleIconOff.svg';
import { ReactComponent as EmptyUserBg } from '../../../../common/images/EmptyUserBg.svg';
import { ReactComponent as SearchNameIcon } from '../../../../common/images/SearchNameIcon.svg';
import { ReactComponent as TableListOrder } from '../../../../common/images/TableListOrder.svg';

import { observer } from "mobx-react";

// import 'dayjs/locale/ko';
import { useTranslation } from "react-i18next";

import useNaviCustom from "../../../../hooks/useNaviCustom";
import MarkFeedback from "./MarkFeedBackPopUp/MarkFeedback";
import { Row } from "./Row";

import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useStores } from "../../../../hooks/useStores";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
import { DetailPageType } from "../../../../stores/support/DetailPageType";
import { SortingHintColumn } from "../../../../stores/support/SortingHintColumn";

type Props = {
}

function MarkFeedbackList(props: Props) {
	const [searchParams] = useSearchParams();
	const params = useParams();

	const { projectSubmissionAnswerStore, navigateStore, projectStore } = useStores();
	const classes = useEmotionStyles( MarkFeedbackListStyle );
	const i18next = useTranslation();
	const {
		projectCode,
		answerScoreList,
		answerScoreListConditionsToSearch
	} = projectSubmissionAnswerStore;

	const [pageChangingCount, setPageChangingCount] = React.useState(0);
	const [searchKeyword, setSearchKeyword] = React.useState("");
	const [sortStateMap, setSortStateMap] = React.useState({
		[SortingHintColumn.SCORED_DATE_TIME]: { sort: false },
	});
	const [checkedOnlyScored, setCheckedOnlyScored] = React.useState(false);
	const [openMarkDiolog, setOpenMarkDiolog] = React.useState(false);
	const [markSubmissionCode, setMarkSubmissionCode] = React.useState("");

	const [needInterval, setNeedInterval] = React.useState(false);
	const onTheAiIntervalId = React.useRef(null);

	const naviCustom = useNaviCustom();

	React.useEffect(() => {
		navigateStore.changeNavigateRootPage(false);
		navigateStore.changeNavigateDetailPage(DetailPageType.PROJECT);
		navigateStore.changeSideBarStepNum(4);
		projectStore.downloadProjectInfoAndStore(params.projectCode);

		return () => {
			navigateStore.changeNavigateDetailPage(null);
			projectSubmissionAnswerStore.init();
			projectStore.initProject();
		}
	}, []);

	React.useEffect(() => {
		const projectCode = params.projectCode;
		if (!projectCode || projectSubmissionAnswerStore.projectCode === projectCode) return;

		projectSubmissionAnswerStore.setProjectCode(projectCode);
		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchStateType(ProjectStateType.COMPARED);
		projectSubmissionAnswerStore.getAnswerScoreList(projectCode);
	}, [params]);

	React.useEffect(() => {
		const submissionCode = searchParams.get('submissionCode');
		if (submissionCode) {
			handleOpenMarkDialog(submissionCode);
		}
	}, [searchParams]);

	React.useEffect(() => {
		if (pageChangingCount === 0) {
			setPageChangingCount(prev => prev + 1);
			return;
		}
		initOnTheAiCheckInterval();
		projectSubmissionAnswerStore.getAnswerScoreList(projectCode);
	}, [answerScoreListConditionsToSearch.page]);

	React.useEffect(() => {
		if (answerScoreList.length > 0) {
			const result = answerScoreList.some(answer => answer.onTheAi > 0);
			setNeedInterval(result);
		}
	}, [answerScoreList])

	React.useEffect(() => {
		if (needInterval) {
			// 1. 돌고 있었는지 체크.
			if (onTheAiIntervalId.current) {
				// 2. 돌고 있었으면 인터벌을 없애고, 새롭게 결과를 가져오기
				clearInterval(onTheAiIntervalId.current);
			} else {
				// 3. 안돌고 있었으면 -> 인터벌 시작
				onTheAiIntervalId.current = setInterval(() => projectSubmissionAnswerStore.getAnswerScoreList(projectCode), 5000);
			}
		} else {
			// 이곳은 모든 채점 결과가 완료 되었을 때 이다.
			initOnTheAiCheckInterval();
		}
	}, [needInterval])

	const initOnTheAiCheckInterval = () => {
		if (onTheAiIntervalId.current) {
			clearInterval(onTheAiIntervalId.current);
		}
		setNeedInterval(false);
	}

	const currentAnswerScoreListConditionsToSearch = React.useMemo(() => {
		const list = answerScoreList;
		const condition = answerScoreListConditionsToSearch;

		return list?.length > 0
			? condition?.totalCount > condition.rowsPerPage
				? Math.ceil(condition?.totalCount / condition.rowsPerPage)
				: 1
			: 0
	}, [answerScoreList, answerScoreListConditionsToSearch]);

	const rowStartNum = React.useMemo(() => {
		const page = answerScoreListConditionsToSearch.page;
		const rowsPerPage = answerScoreListConditionsToSearch.rowsPerPage;

		return page * rowsPerPage + 1;
	}, [answerScoreListConditionsToSearch.page, answerScoreListConditionsToSearch.rowsPerPage]);

	const incompleteGradingCount = React.useMemo(() => {
		const totalCount = answerScoreListConditionsToSearch.totalCount;
		const checkCount = answerScoreListConditionsToSearch.checkCount;
		if (checkCount > totalCount) return 0;

		return totalCount - checkCount;
	}, [answerScoreListConditionsToSearch.checkCount, answerScoreListConditionsToSearch.totalCount]);

	const handleClickSort = (columnName) => {
		const sortState = sortStateMap[columnName];
		if (sortState) {
			sortState.sort = !sortState.sort;
			setSortStateMap({
				...sortStateMap,
				[columnName]: sortState,
			});

			projectSubmissionAnswerStore.changeSortingHint(columnName, sortState.sort);
		}
	};

	const handleClickPage = (e, value) => {
		const page = value - 1;
		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchPage(page);
	};

	const handleChangeSearchKeyword = (e) => {
		const value = e.target.value;
		setSearchKeyword(value);
		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchKeyword(value.trim());
	};

	const doSearchKeyword = () => {
		initOnTheAiCheckInterval();
		projectSubmissionAnswerStore.getAnswerScoreList(projectCode);
	}

	const handleKeyDownInSearchInput = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			doSearchKeyword();
		}
	};

	const handleClickSearchKeyword = () => {
		doSearchKeyword();
	};

	const handleChangeOnlyScoredCheck = (e) => {
		const checked = e.target.checked;

		// console.log("handleChangeOnlyScoredCheck checked", checked);
		initOnTheAiCheckInterval();
		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchExcept(checked ? ProjectStateType.COMPARED : "");
		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchStateType(ProjectStateType.COMPARED);
		projectSubmissionAnswerStore.getAnswerScoreList(projectCode);
		setCheckedOnlyScored(checked);
	};

	const handleClickViewProjectBtn = () => {
		naviCustom.go(`/projects/${projectCode}/create/view`);
		// props.navigate(`/projects/${projectCode}/create/view`);
	};

	const handleOpenMarkDialog = (submissionCode) => {
		setMarkSubmissionCode(submissionCode);
		setOpenMarkDiolog(true);
	};

	const handleCloseMarkDialog = () => {
		setMarkSubmissionCode("");
		setOpenMarkDiolog(false);

		projectSubmissionAnswerStore.getAnswerScoreList(projectSubmissionAnswerStore.projectCode);

		if (searchParams.get('submissionCode')) {
			naviCustom.go(`/assignments/${projectCode}/feedbacks`);
			// props.navigate(`/assignments/${projectCode}/feedbacks`);
		}
	};

	const handleChangeSubmissionCode = (code) => {
		setMarkSubmissionCode(code);
	};

	return (
		<div className={classes.root}>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 86px)'
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box>
						<Typography className={classes.commonSubTitle}>{i18next.t('채점 및 피드백')}</Typography>
						<Typography className={classes.commonSubmit}>{i18next.t('채점 대상')}(<span>{answerScoreListConditionsToSearch.totalCount}</span>)</Typography>
						<Typography className={classes.submitSubText}>{i18next.t('채점 대상에 해당되는 학생 목록입니다. 자동 채점된 제출물에 점수 확인 및 피드백을 남겨주세요.')}</Typography>
					</Box>
					<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginTop: "25px" }}>
						<Box display='flex' alignItems='center' justifyContent='space-between' >
							<Box sx={{ marginRight: "20px" }}>
								<FormControlLabel
									control={
										<Checkbox
											// checked={isElementarySchoolChecked}
											onChange={handleChangeOnlyScoredCheck}
											icon={<CheckCircleIconOff />}
											checkedIcon={<CheckCircleIcon className={classes.checkedColor} />}
											disableRipple
										/>
									}
									label={i18next.t('채점 마감만')}
									className={classes.checkMargin}
								/>
							</Box>

							<Paper
								component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
								className={classes.urlInputBox}
							>
								<IconButton className={classes.iconBtnReset} onClick={handleClickSearchKeyword} disableRipple>
									<SearchNameIcon />
								</IconButton>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder={i18next.t('이름 검색')}
									value={searchKeyword}
									onChange={handleChangeSearchKeyword}
									onKeyDown={handleKeyDownInSearchInput}
									inputProps={{ 'aria-label': i18next.t('이름 검색') }}
								/>
							</Paper>
						</Box>
						<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.submitScanText}>
							<Typography>{i18next.t('채점 마감')} <span>{answerScoreListConditionsToSearch.checkCount}</span></Typography>
						</Box>
					</Box>
					<Box>
						<TableContainer component={Paper} className={classes.tableBox}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell width='6%' align="center">{i18next.t('No.')}</TableCell>
										<TableCell width='12%' >{i18next.t('이름')}</TableCell>
										<TableCell width='12%' >{i18next.t('학년/반')}</TableCell>
										<TableCell width='12%'>{i18next.t('채점 점수')}</TableCell>
										<TableCell width='20%'>
											<Box className={classes.sortButton} onClick={() => handleClickSort(SortingHintColumn.SCORED_DATE_TIME)}>
												<Typography style={{ fontWeight: 800 }}>{i18next.t('채점일시')}</Typography>
												<IconButton className={classes.iconBtnReset} style={{ transform: sortStateMap[SortingHintColumn.SCORED_DATE_TIME].sort ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>
													<TableListOrder />
												</IconButton>
											</Box>
										</TableCell>
										<TableCell width='20%'>{i18next.t('채점 진행율')}</TableCell>
										<TableCell width='12%'>{i18next.t('채점')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{answerScoreList.length > 0 &&
										answerScoreList.map((item, idx) =>
											<Row
												key={idx}
												idx={idx}
												item={item}
												rowStartNum={rowStartNum}
												projectCode={projectCode}
												handleOpenMarkDialog={handleOpenMarkDialog}
											/>
										)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>

					{answerScoreList.length === 0 &&
						<Box className={classes.emptyTableData} display='flex' flexDirection='column' alignItems='center' justifyContent='center' >
							<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ height: '500px' }}>
								<EmptyUserBg />
								<Typography className={classes.emptyBgTitle}>{i18next.t('대상이 없습니다.')}</Typography>
								{/*<Typography className={classes.emptyBgTitle}>출제한 대상이 없습니다.</Typography>*/}
								{/*<Typography className={classes.emptyBgText}>‘과제 보기' 메뉴에서 생성한 과제 확인 후 대상을 추가하여 출제해 보세요.</Typography>*/}
								{/*<Box>*/}
								{/*    <Button className={classes.emptyViewBtn} onClick={handleClickViewProjectBtn} disableRipple>*/}
								{/*        <Typography>과제 보기</Typography>*/}
								{/*    </Button>*/}
								{/*</Box>*/}
							</Box>
						</Box>
					}

					<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
						<Stack spacing={2}>
							<Pagination
								count={currentAnswerScoreListConditionsToSearch}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleClickPage}
								variant="outlined"
							/>
						</Stack>
					</Box>
				</Box>
			</Box>

			{openMarkDiolog &&
				<Dialog
					// className={classes.centerDialog}
					fullScreen open={openMarkDiolog}>
					<MarkFeedback
						projectCode={projectSubmissionAnswerStore.projectCode}
						submissionCode={markSubmissionCode}
						handleClickClose={handleCloseMarkDialog}
						handleChangeSubmissionCode={handleChangeSubmissionCode}
					/>
				</Dialog>
			}

			{projectSubmissionAnswerStore.isSelectingAnswerScoreList &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectSubmissionAnswerStore.isSelectingAnswerScoreList}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t('조회 중입니다...')}</Typography>
				</Backdrop>
			}
		</div>
	);
}

export default observer<typeof MarkFeedbackList>(MarkFeedbackList);
