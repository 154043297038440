export const SINGLE_CODE_SIZE_PER_INCH = 0.09333333333333334; // (8 / 600) * 7;

export const NCODE_TO_MM_SCALE = 2.3706666666666663; // (25.4 * 8 * 7) / 600; // 1개 ncode의 mm 크기 (600dpi * 8pixel * 7ncode_dots) = 25.4/600 * 8 * 7

export const NCODE_TO_INCH_SCALE = 0.09333333333333334; // (8 * 7) / 600; // 1/600 * 8 * 7


export const PDF_SCALE_TO_INCH = 0.013888888888888888; // 1 / 72; // 72 DPI

export const PDF_DEFAULT_DPI = 72;

export const DISPLAY_DEFAULT_DPI = 96;

export const NCODE_TO_SCREEN_SCALE = 8.96; // NCODE_TO_INCH_SCALE * DISPLAY_DEFAULT_DPI;

export const PDF_TO_SCREEN_SCALE = PDF_SCALE_TO_INCH * DISPLAY_DEFAULT_DPI; // PDF_SCALE_TO_INCH * DISPLAY_DEFAULT_DPI;

export const INCH_TO_MM_SCALE = 25.4;
export const INCH_TO_NCODE_SCALE = 10.7142857142857; // 600 / (8 * 7)

export const DEFAULT_SECTION = 3;

export const DEFAULT_OWNER = 27;

export const DEFAULT_BOOK = 168;



export const NCODE_CLASS6_NUM_DOTS = 7;


export const NU_TO_PU = 6.72; // = 56 / 600 * 72;

export const PU_TO_NU = 0.148809523809524;

/**
 * 2021/08/17 현재
 *   S    O           B             P              XY
 * --------------------------------------------------------
 *   0   10(1,024)   14(16,384)    12(  4,096)      8(  256)
 *   3   10(1,024)   13( 8,192)     9(    512)     10(1,024)
 *   4   10(1,024)   16(65,536)    18(262,144)      4(   16)
 *   5    8(  256)   12( 4,096)    12(  4,096)     10(1,024)
 *  10   10(1,024)   12( 4,096)    10(  1,024)     10(1.024)
 *  11   10(1,024)   13( 8,192)     9(    512)     10(1,024)
 *  14   10(1,024)   13( 8,192)     5(     32)     12(4,096)
 */
export const g_availablePagesInSection: Record<number, number> = {
  0: 4096,
  3: 512,
  4: 262144,
  5: 4096,
  10: 1024,
  11: 512,
  14: 32,

  // 임시 mapping을 위한 섹션코드, 256
  256: 4096,

  2049: 500,
  2050: 500,
  2051: 500,

}




export const g_nullNcode = { section: -1, owner: -1, book: -1, page: -1 };
export const nullNcode = () => ({ ...g_nullNcode })


export const CSS_DPI = 96.0;

export const UNIT_TO_DPI: Record<string, number> = {
  "mm": 25.4,
  "pu": 72,
  "css": 96,
  "nu": 10.71428571,    // 600 / (8*7)
  "dpi600": 600,
  "dpi300": 300,
  "dpi200": 200,
  "dpi150": 150,
};


export const DPI_TO_UNIT: Record<number, string> = {
  25.4: "mm",
  72: "pu",
  96: "css",
  10.71428571: "nu",    // 600 / (8*7)
  600: "600dpi",
  300: "300dpi",
  200: "200dpi",
  150: "150dpi",
};


