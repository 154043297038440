import { Box } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import useNaviCustom from "../../hooks/useNaviCustom";

import { useStores } from "../../hooks/useStores";
import { AuthState } from "../../stores/support/AuthState";
import { getMenuType, MenuType } from "../components/menu/common/MenuItem";
import { TopBarComponent } from "../components/TopBar";
import { Styles } from "./styles/Styles";
import useEmotionStyles from "./styles/useEmotionStyles";
import StudentSideBar from "../components/StudentSideBar";


type Props = {}

// const MainSideBar = React.lazy(() => import("../components/MainSideBar"));
// const TopBar = React.lazy(() => import("../components/TopBar"));

function ProjectStudent(props: Props) {
	const classes = useEmotionStyles(Styles);
	const { pathname } = useLocation();
	const sidebarHidden = !(pathname.includes("detail") || pathname.includes("print"));

	const { navigateStore, authStore, projectStore } = useStores();
	const { topBarLeftItem, topBarRightItem } = navigateStore;
	const { loginState } = authStore;

	const [sideBarOpen, setSideBarOpen] = React.useState(sidebarHidden);

	const params = useParams();

	// const MainSideBar = React.lazy(() => import("../components/MainSideBar"));
	// const TopBar = React.lazy(() => import("../components/TopBar"));

	const naviCustom = useNaviCustom();


	const [topBar, setTopBar] = React.useState<React.ReactNode>(<></>);

	React.useEffect(() => {

		const getTopBarByType = () => {
			if (pathname.includes("/projects/")) {
				console.log(`TOPBAR /projects/`);
				return <TopBarComponent leftItem={topBarLeftItem} rightItem={topBarRightItem} />;
			} else if (pathname.includes("/assignments/")) {
				console.log(`TOPBAR /assignments/`);
				return <TopBarComponent leftItem={topBarLeftItem} rightItem={topBarRightItem} />;
			} else if (pathname.includes("/students/")) {
				console.log(`TOPBAR /students/`);
				return <TopBarComponent leftItem={null} rightItem={null} />;
			} else {
				console.log(`TOPBAR else`);
				return <TopBarComponent leftItem={() => <></>} rightItem={topBarRightItem} />;
			}
		}
		const bar = getTopBarByType();
		setTopBar(bar);

	}, [navigateStore.topBarLeftItem, navigateStore.topBarRightItem]);


	React.useEffect(() => {
		if (loginState === AuthState.Authenticated) {
			if (pathname === '/') {
				naviCustom.go(`/projects`);
				// props.navigate(`/projects`);
			}
		}
	}, [pathname])

	React.useEffect(() => {
		if (projectCode || projectCode !== "undefined") {
			projectStore.getProjectStates(projectCode);
		} else {
			projectStore.initProjectStates();
		}
	}, [pathname]);

	const { projectCode } = params;
	const menuType = getMenuType(pathname);
	const { projectStates } = projectStore;

	return (
		<div className={classes.root}>
			{/* <Suspense fallback={<></>}> */}
			<div>
				{sidebarHidden ?
					menuType === MenuType.Main ?
						<StudentSideBar
							id="simple-sidebar"
							menuType={menuType}
							projectCode={projectCode}
							sideBarOpen={sideBarOpen}
							changSideBarOpen={(open) => setSideBarOpen(open)}
							projectStates={projectStates}
						/>
						:
						<Box id="simple-sidebar" style={{ display: "flex", }}>
							<StudentSideBar
								menuType={MenuType.Main}
								projectCode={projectCode}
								sideBarOpen={sideBarOpen}
								changSideBarOpen={(open) => setSideBarOpen(open)}
								projectStates={projectStates}
							/>
							{/* <StudentSideBar
								menuType={menuType}
								projectCode={projectCode}
								sideBarOpen={sideBarOpen}
								changSideBarOpen={(open) => setSideBarOpen(open)}
								projectStates={projectStates}
							/> */}
						</Box>
					:
					<></>
				}
			</div>

			<div style={{ width: "100%" }}>
				{topBar}
				<Outlet />
			</div>
			{/* </Suspense> */}
		</div>
	);
}

export default observer<typeof ProjectStudent>(ProjectStudent);
