import { action, makeAutoObservable, runInAction } from "mobx";
import dayjs from "dayjs";

import { SchoolLevelSubjects } from "../common/SchoolLevelSubjects";
import cloneDeep from "lodash/cloneDeep";
import StoreManager from "./StoreManager";
import { convertUTCToKST } from "../hooks/convertUTCToKST";

import i18next from "../lang";
import ProjectRepository from "../repositories/ProjectRepository";
import MessageStore from "./MessageStore";
import { ProjectSchoolType, ProjectSharedState, ProjectUserSortColumn } from "./enums/ProjectStoreEnums";
import { IProjectListOnDatabase } from "../repositories/model/transfer/IProjectListOnDatabase";
import { IProject, IProjectState } from "../repositories/model/IProject";

import { ProjectSemesterType } from "../repositories/model/support/ProjectSemesterType";
import { ProjectStateType } from "../repositories/model/support/ProjectStateType";
import { ProjectType } from "../repositories/model/support/ProjectType";
import { SubjectType } from "../repositories/model/support/SubjectType";
import { StoreStatus } from "./support/StoreStatus";
import { IProjectUser } from "../repositories/model/IProjectUser";

const LogPrefix = "[ProjectStore]";


const EmptyProject: IProject = {
	code: "",
	userCode: "",
	name: "", // 과제명
	templateName: "",
	templateCode: undefined,
	type: ProjectType.NONE,
	curriculumYear: "", // 교육과정 시기


	schoolType: null as ProjectSchoolType, // 학교급
	year: dayjs().get('year'), // 학년도
	semesterType: ProjectSemesterType.FIRST, // 학기
	subject: SubjectType.DEFAULT, // "", // 교과군
	subjectName: "", // 과목명
	grade: "",
	chapter: "",
	includedOnline: false, // 온라운 출제 포함 여부
	startDatetime: "", // 온라인 출제 시작일 + 시간
	endDatetime: "", // 온라인 출제 종료일 + 시간
	description: "", // 출제 의도
	state: null,
	targetGroupName: "",

	sharedState: undefined as ProjectSharedState,

	totalCount: 0, // 0
	scoredCount: 0, // 0
	submittedCount: 0, // 0
	createdDatetime: null as string, // "2024-08-22T08:09:00"
	updatedDatetime: null as string, // "2024-08-22T08:09:00"
	projectState: undefined as IProjectState, // { ... }
};

const EmptyProjectPlanStandardGrade = {
	projectPlanStandardId: "",
	projectPlanStandardNum: "",
	grade: "",
	contents: ""
}

const EmptyProjectPlanStandard = {
	id: "",
	projectCode: "",
	standard: "",
	externalPlanCode: "",//성취 기준 코드
	grades: [
		{ ...EmptyProjectPlanStandardGrade, grade: i18next.t('상') },
		{ ...EmptyProjectPlanStandardGrade, grade: i18next.t('중') },
		{ ...EmptyProjectPlanStandardGrade, grade: i18next.t('하') },
	]
};

const EmptyProjectPlanTransfer = {
	code: "",
	projectCode: "",
	requiredSubjectAbility: "",//교과 역량
	purpose: "",//출제 의도
	standards: Array(1).fill({ ...EmptyProjectPlanStandard })
};

export function convertSubjectTypeEnToKr(type: string) {
	for (let i = 0; i < SchoolLevelSubjects.length; i++) {
		for (let j = 0; j < SchoolLevelSubjects[i].subjectGroups.length; j++) {
			if (type === SchoolLevelSubjects[i].subjectGroups[j].name)
				return i18next.t(SchoolLevelSubjects[i].subjectGroups[j].text);
		}
	}
	return type;
}

export function convertSubjectNameTypeEnToKr(type: string) {
	for (let i = 0; i < SchoolLevelSubjects.length; i++) {
		for (let j = 0; j < SchoolLevelSubjects[i].subjectGroups.length; j++) {
			for (let k = 0; k < SchoolLevelSubjects[i].subjectGroups[j].subjects.length; k++) {
				if (type === SchoolLevelSubjects[i].subjectGroups[j].subjects[k].name)
					return i18next.t(SchoolLevelSubjects[i].subjectGroups[j].subjects[k].text);
			}
		}
	}
	return type;
}

export const EmptyProjectStates = {
	code: "",
	info: false,
	plan: false,
	exam: false,
	rag: false,
	rubric: false,
	submit: false,
}



// Label은 enum으로 변환할 수 없으므로, 객체 형태로 유지합니다.
export const ProjectSchoolLabelType = {
	[ProjectSchoolType.ELEM]: "초등학교", // i18next.t('초등학교'),
	[ProjectSchoolType.MIDD]: "중학교", // i18next.t('중학교'),
	[ProjectSchoolType.HIGH]: "고등학교", // i18next.t('고등학교')
	[ProjectSchoolType.NOGRADE]: "구분없음", // i18next.t('구분없음')
}

export const EmptySharedFilter = {
	schoolType: null,
	grade: "",
	subject: "",
	subjectName: "",
	keyword: "",

	projectTransfer: undefined as any,
};

type ProjectStoreProps = {
	projectRepository: ProjectRepository;
	messageStore: MessageStore;
	storeManager: StoreManager;
}

export default class ProjectStore {
	public projectRepository: ProjectRepository;
	public messageStore: MessageStore;
	public storeManager: StoreManager;

	public project: IProject;
	public projectList: IProjectListOnDatabase;
	public assignmentProjectList: IProjectListOnDatabase;
	public templateProjectList: IProjectListOnDatabase = null;

	public projectPlanStandardTransfer: (typeof EmptyProjectPlanStandard)[];
	public projectPlanTransfer: typeof EmptyProjectPlanTransfer;

	public projectSelectedUsers: any[];
	public projectUsers: IProjectUser[];
	public projectUserAddList: IProjectUser[];
	public projectUserDelList: string[];			// userCode[]
	public projectStates: typeof EmptyProjectStates;

	public projectSelectedState: any;

	public updatedProjectUserState: boolean;

	public isSelectingProjectInfo: boolean;
	public isSelectingProjectStates: boolean;
	public isSelectingProjectUserTransfers: boolean;
	public isSelectingProjectPlanTransfer: boolean;
	public isCreatingProjectForCustom: boolean;
	public isUpdatingProject: boolean;
	public isUpdatingProjectState: boolean;
	public isUpdatingProjectUserState: boolean;
	public isDeletingProject: boolean;
	public isConfirmForUpdateRequired: boolean;

	public searchFilter: typeof EmptySharedFilter;

	// public projectStates: any;

	public isSelecting: StoreStatus;


	constructor(props: ProjectStoreProps) {
		makeAutoObservable(this);

		const ps = props;
		const { messageStore } = ps;
		this.projectRepository = props.projectRepository;
		this.messageStore = messageStore;
		this.storeManager = props.storeManager;

		this.project = Object.assign({}, EmptyProject);
		this.projectList = null;
		this.assignmentProjectList = { totalCount: 0, projects: [] };
		this.templateProjectList = null;

		this.projectPlanStandardTransfer = Array(1).fill({ ...EmptyProjectPlanStandard });
		this.projectPlanTransfer = Object.assign({}, EmptyProjectPlanTransfer);

		this.projectSelectedUsers = [];
		this.projectUsers = [];
		this.projectUserAddList = [];
		this.projectUserDelList = [];
		this.projectStates = Object.assign({}, EmptyProjectStates);

		this.projectSelectedState = null;

		this.updatedProjectUserState = false;

		this.isSelectingProjectInfo = false;
		this.isSelectingProjectStates = false;
		this.isSelectingProjectUserTransfers = false;
		this.isSelectingProjectPlanTransfer = false;
		this.isCreatingProjectForCustom = false;
		this.isUpdatingProject = false;
		this.isUpdatingProjectState = false;
		this.isUpdatingProjectUserState = false;
		this.isDeletingProject = false;
		this.isConfirmForUpdateRequired = false;

		this.searchFilter = Object.assign({}, EmptySharedFilter);
	}

	changeSearchFilterSchoolType(value) {
		if (this.searchFilter.schoolType !== value) {
			this.changeSearchFilterSubjectName("");
			this.changeSearchFilterSubject("");
			this.changeSearchFilterGrade("");
			this.searchFilter.schoolType = value;
		}
	}

	changeSearchFilterGrade(value) {
		if (this.searchFilter.grade !== value) {
			this.searchFilter.grade = value;
		}
	}

	changeSearchFilterSubject(value) {
		if (this.searchFilter.subject !== value) {
			this.changeSearchFilterSubjectName("");
			this.searchFilter.subject = value
		}
	}

	changeSearchFilterSubjectName(value) {
		this.searchFilter.subjectName = value
	}

	changeSearchFilterKeyword(value) {
		this.searchFilter.keyword = value
	}

	initForSearchFilter() {
		this.searchFilter = Object.assign({}, EmptySharedFilter);
	}

	init() {
		this.project = Object.assign({}, EmptyProject);
		this.projectList = null;
		this.projectPlanTransfer = Object.assign({}, EmptyProjectPlanTransfer);
		this.projectUsers = [];
		this.projectUserAddList = [];
		this.projectUserDelList = [];

		this.isSelectingProjectInfo = false;
		this.isSelectingProjectStates = false;
		this.isSelectingProjectUserTransfers = false;
		this.isSelectingProjectPlanTransfer = false;
		this.isCreatingProjectForCustom = false;
		this.isUpdatingProject = false;
		this.isUpdatingProjectState = false;
		this.isUpdatingProjectUserState = false;
		this.isDeletingProject = false;
		this.isConfirmForUpdateRequired = false;
		this.projectSelectedState = null;
	}

	initProject() {
		this.project = Object.assign({}, EmptyProject);
		this.projectUsers = [];
		this.projectUserAddList = [];
		this.projectUserDelList = [];
		this.isConfirmForUpdateRequired = false;
	};

	initProjectStates() {
		this.projectStates = null;
	}
	initProjectList() {
		this.projectList = null;
	}

	initProjectUserList() {
		this.projectUserAddList = [];
		this.projectUserDelList = [];
		this.projectSelectedUsers = [];
	}

	changeProjectSelectedState(state) {
		this.projectSelectedState = state;
	}

	initProjectPlanTransfer() {
		this.projectPlanTransfer = Object.assign({}, EmptyProjectPlanTransfer);
	}

	pushProjectUserAddList(user) {
		this.projectUserAddList.push(user);
		console.log(user);
		this.projectSelectedUsers.push(user);
	}

	delProjectUserAddList(index) {
		this.projectUserAddList.splice(index, 1);
	}

	copyProjectUsersToDelList() {
		this.projectUserDelList = this.projectUsers.map(item => item.userCode);
	}

	selectedProjectUserDel(userObject) {
		let findIndex = this.projectSelectedUsers.findIndex(user => user.userCode === userObject.userCode)
		if (findIndex !== -1) this.projectSelectedUsers.splice(findIndex, 1);

		findIndex = this.projectUserAddList.findIndex(user => user.userCode === userObject.userCode);
		if (findIndex !== -1) this.projectUserAddList.splice(findIndex, 1);

		findIndex = this.projectUserDelList.findIndex(user => user === userObject.userCode);
		if (findIndex === -1) this.projectUserDelList.push(userObject.userCode);

		this.changeIsConfirmForUpdateRequired(true);
	}

	selectedProjectUserAdd(userObject) {
		let findIndex = this.projectSelectedUsers.findIndex(user => user.userCode === userObject.userCode)
		if (findIndex === -1) this.projectSelectedUsers.push(userObject);

		findIndex = this.projectUserAddList.findIndex(user => user.userCode === userObject.userCode);
		if (findIndex === -1) this.projectUserAddList.push(userObject)

		findIndex = this.projectUserDelList.findIndex(user => user === userObject.userCode);
		if (findIndex !== -1) this.projectUserDelList.splice(findIndex, 1);

		this.changeIsConfirmForUpdateRequired(true);
	}

	checkProjectUserAddList(userObject) {
		const result = this.projectUserAddList.findIndex(user => user.userCode === userObject.userCode);

		if (result === -1) {
			this.pushProjectUserAddList(userObject);
			let findIndex = this.projectUserAddList.findIndex(user => user.userCode === userObject.userCode)
			if (findIndex !== -1)
				this.projectUserAddList.splice(findIndex, 1);
		} else {
			this.delProjectUserAddList(result);
			let findIndex = this.projectUserDelList.findIndex(user => user === userObject.userCode);
			if (findIndex !== -1)
				this.projectUserDelList.splice(findIndex, 1);
		}
	}
	pushProjectUserDelList(userCode) {
		this.projectUserDelList.push(userCode);
		let findIndex = this.projectSelectedUsers.findIndex(user => user.userCode === userCode);
		if (findIndex !== -1)
			this.projectSelectedUsers.splice(findIndex, 1);
		findIndex = this.projectUserAddList.findIndex(user => user.userCode === userCode)
		if (findIndex !== -1)
			this.projectUserAddList.splice(findIndex, 1);
	}

	delProjectUserDelList(index) {
		this.projectUserDelList.splice(index, 1);
	}

	checkProjectUserDelList(userObject) {
		const result = this.projectUserDelList.findIndex(user => user === userObject.userCode)
		if (result === -1) {
			this.pushProjectUserDelList(userObject.userCode);
		} else {
			this.delProjectUserDelList(result);
			this.projectSelectedUsers.push(userObject);
		}
	}

	async changeProjectUser(projectCode, projectName, targetGroupName) {
		if (this.projectUsers.length === 0) {
			if (this.projectUserAddList.length > 0) {
				const orderStartNum = 1;
				for (let i = 0; i < this.projectUserAddList.length; i++) {
					// 상태 변경을 runInAction으로 감싸기
					runInAction(() => {
						this.projectUserAddList[i].order = orderStartNum + i;
					});
				}
			}
			try {
				await this.createProjectUsers(projectCode, projectName, targetGroupName, this.projectUserAddList);
			} catch (e) {
				return false;
			}
		} else {
			if (this.projectUserDelList.length > 0) {
				try {
					await this.deleteProjectUsers(projectCode, this.projectUserDelList);
				} catch (e) {
					return false;
				}
			}
			if (this.projectUserAddList.length > 0) {
				try {
					let orderStartNum = await this.getProjectMaxOrder(projectCode);
					orderStartNum = orderStartNum + 1;

					for (let i = 0; i < this.projectUserAddList.length; i++) {
						// 상태 변경을 runInAction으로 감싸기
						runInAction(() => {
							this.projectUserAddList[i].order = orderStartNum + i;
						});
					}
					await this.createProjectUsers(projectCode, projectName, targetGroupName, this.projectUserAddList);
				} catch (e) {
					return false;
				}
			}
		}
		runInAction(() => {
			this.initProjectUserList(); // observable 상태 변경을 runInAction으로 감싸기
		});
		return true;
	}

	changeIsConfirmForUpdateRequired(isRequired) {
		if (this.isConfirmForUpdateRequired === isRequired) return;

		this.isConfirmForUpdateRequired = isRequired;
	};

	changeCirriculumYear(year) {
		this.project.curriculumYear = year;
		this.changeIsConfirmForUpdateRequired(true);
	}

	changeSchoolType(type: ProjectSchoolType) {
		this.project.schoolType = type;
		this.changeIsConfirmForUpdateRequired(true);
	}

	changeYear(year: number) {
		this.project.year = year;
	}

	changeSemesterType(type: ProjectSemesterType) {
		this.project.semesterType = type;
		this.changeIsConfirmForUpdateRequired(true);
	}

	changeSubject(subject: SubjectType) {
		// if (String(subject)) return;
		// console.log('subject', subject)
		this.project.subject = subject;
		this.changeIsConfirmForUpdateRequired(true);
	}

	clearSubject() {
		this.project.subject = SubjectType.DEFAULT;
	}

	changeSubjectName(name) {
		if (name === "") return;
		console.log('name', name)
		this.project.subjectName = name;
		this.changeIsConfirmForUpdateRequired(true);
	}

	clearSubjectName() {
		this.project.subjectName = "";
	}

	changeName(name) {
		this.project.name = name;
		this.changeIsConfirmForUpdateRequired(true);
	}
	changeChapter(chapter) {
		this.project.chapter = chapter;
		this.changeIsConfirmForUpdateRequired(true);
	}

	changeIncludedOnline(includedOnline) {
		this.project.includedOnline = includedOnline;
		this.changeIsConfirmForUpdateRequired(true);
	}

	changeStartDatetime(dateTime) {
		this.project.startDatetime = dateTime;
	}

	changeEndDatetime(dateTime) {
		this.project.endDatetime = dateTime;
		this.changeIsConfirmForUpdateRequired(true);
	}

	changeDescription(description) {
		this.project.description = description;
	}

	changeProjectPlanRequired(required) {
		this.projectPlanTransfer.requiredSubjectAbility = required;
		this.projectPlanTransfer = Object.assign({}, this.projectPlanTransfer);
		this.changeIsConfirmForUpdateRequired(true);
	}

	changeProjectPlanPurpose(purpose) {
		this.projectPlanTransfer.purpose = purpose;
		this.projectPlanTransfer = Object.assign({}, this.projectPlanTransfer);
		this.changeIsConfirmForUpdateRequired(true);
	}
	changeProjectPlanExternalPlanCode(externalPlanCode: string, index: number) {
		if (this.projectPlanTransfer.standards[index]) {
			this.projectPlanTransfer.standards[index].externalPlanCode = externalPlanCode;
			this.changeIsConfirmForUpdateRequired(true);
		}
	}

	changeProjectPlanStandard(standard, index) {
		if (this.projectPlanTransfer.standards[index]) {
			this.projectPlanTransfer.standards[index].standard = standard;
			this.projectPlanTransfer = Object.assign({}, this.projectPlanTransfer);
			this.changeIsConfirmForUpdateRequired(true);
		}
		else
			console.log("standard doesn't exist")
	}
	addProjectPlanStandardGrade(standardIndex) {
		if (this.projectPlanTransfer.standards[standardIndex]
			&& Array.isArray(this.projectPlanTransfer.standards[standardIndex].grades)
		) {
			this.projectPlanTransfer.standards[standardIndex].grades.push(EmptyProjectPlanStandardGrade);
			this.projectPlanTransfer = Object.assign({}, this.projectPlanTransfer);
			this.changeIsConfirmForUpdateRequired(true);
		}
		else
			console.log("grade doesn't exist")
	};

	deleteProjectPlanStandardGrade(standardIndex, gradeIndex) {
		if (this.projectPlanTransfer.standards[standardIndex]
			&& Array.isArray(this.projectPlanTransfer.standards[standardIndex].grades)
		) {
			this.projectPlanTransfer.standards[standardIndex].grades = [
				...this.projectPlanTransfer.standards[standardIndex].grades.filter((_, idx) => idx !== gradeIndex)
			];
			this.projectPlanTransfer = Object.assign({}, this.projectPlanTransfer);
			this.changeIsConfirmForUpdateRequired(true);
		}
		else
			console.log("grade doesn't exist")
	};

	changeProjectPlanStandardGrade(grade, standardIndex, gradeIndex) {
		if (this.projectPlanTransfer.standards[standardIndex] && this.projectPlanTransfer.standards[standardIndex].grades[gradeIndex]) {
			// this.projectPlanTransfer.standards[standardIndex].grades[gradeIndex].grade = grade;
			// let changedGrade = this.projectPlanTransfer.standards[standardIndex].grades[gradeIndex];
			// changedGrade.grade = grade;
			this.projectPlanTransfer.standards[standardIndex].grades[gradeIndex].grade = grade;
			this.projectPlanTransfer = Object.assign({}, this.projectPlanTransfer);
			this.changeIsConfirmForUpdateRequired(true);
		}
		else
			console.log("grade doesn't exist")
	}
	changeProjectPlanStandardGradeContents(contents, standardIndex, gradeIndex) {
		if (this.projectPlanTransfer.standards[standardIndex] && this.projectPlanTransfer.standards[standardIndex].grades[gradeIndex]) {
			// let changedGrade = this.projectPlanTransfer.standards[standardIndex].grades[gradeIndex];
			// changedGrade.contents = contents;
			this.projectPlanTransfer.standards[standardIndex].grades[gradeIndex].contents = contents;
			this.projectPlanTransfer = Object.assign({}, this.projectPlanTransfer);
			this.changeIsConfirmForUpdateRequired(true);
		}
		else
			console.log("grade doesn't exist")
	}

	changeProjectGrade(grade) {
		if (grade === "") return;
		this.project.grade = grade;
		this.changeIsConfirmForUpdateRequired(true);
	}

	checkSubmitted(type) {
		switch (type) {
			case ProjectStateType.CREATED:
			case ProjectStateType.POSED:
				return false;
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
			case ProjectStateType.COMPARED:
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
				return true;
			default:
				return false;
		}
	}

	addProjectPlanStandard() {
		this.projectPlanTransfer.standards.push(EmptyProjectPlanStandard);
		this.projectPlanTransfer = Object.assign({}, this.projectPlanTransfer);
		this.changeIsConfirmForUpdateRequired(true);
	}

	deleteProjectPlanStandard(index) {
		let tempObject = cloneDeep(this.projectPlanTransfer.standards)
		tempObject.splice(index, 1)
		this.projectPlanTransfer.standards = tempObject;
		this.changeIsConfirmForUpdateRequired(true);
	}

	async getUserProjectList(userCode: string, state, keyword: string, page: number, rowsPerPage: number, sortingHints = {} as { column: string, hint: string }[]) {
		try {
			const data = sortingHints;
			const param = {
				state: state,
				keyword: keyword,
				page: page,
				rowsPerPage: rowsPerPage,
			};

			console.log("Start getUserProjectList ...", param);
			const projectType = "TEMPLATE";
			const result = await this.projectRepository.getProjectList(projectType, param, data);

			runInAction(() => {
				this.templateProjectList = result;
			});

			console.log(LogPrefix, "templateProjectList", this.templateProjectList);
		} catch (e) {
			console.log(LogPrefix, "Cannot get projectList ...", e);
		}
	}


	async getCustomProjectList(state, keyword, page, rowsPerPage, sortingHints) {
		try {
			const data = sortingHints;
			const param = {
				state: state,
				keyword: keyword,
				page: page,
				rowsPerPage: rowsPerPage,
			};

			console.log("Start getCustomProjectList ...", param);
			const projectType = "CUSTOM";
			let response = await this.projectRepository.getProjectList(projectType, param, data);

			runInAction(() => {
				this.assignmentProjectList = response;
			});

			console.log(LogPrefix, "getCustomProjectList", this.assignmentProjectList);
		} catch (e) {
			console.log(LogPrefix, "Cannot get custom project list ...", e);
		}
	}

	async getTemplateProjectList(
		state,
		keyword: string,
		page: number,
		rowsPerPage: number,
		sortingHints: { column: string, hint: string }[],
		forSelect = undefined
	) {
		try {

			const data = sortingHints;
			const param = {
				createState: state,
				keyword: keyword,
				page: page,
				rowsPerPage: rowsPerPage,
				complete: forSelect,
			};

			console.log("Start getTemplateProjectList... keyword : ", keyword);
			const projectType = "TEMPLATE";
			const result = await this.projectRepository.getProjectList(projectType, param, data);

			// observable 상태 변경은 runInAction 안에서 처리
			runInAction(() => {
				this.templateProjectList = result;
			});


			// console.log(LogPrefix, "templateProjectList", this.templateProjectList);
		} catch (e) {
			console.log(LogPrefix, "Cannot get TemplateProjectList ...", e);
		}
	}

	async getProjectListForShare(state, keyword, page, rowsPerPage, sortingHints) {
		try {
			const data = sortingHints;
			const param = {
				createState: state,

				keyword: keyword,
				schoolType: this.searchFilter.schoolType,
				grade: this.searchFilter.grade,
				subject: this.searchFilter.subject,
				subjectName: this.searchFilter.subjectName,

				page: page,
				rowsPerPage: rowsPerPage,
			};

			console.log("Start getTemplateProjectList... keyword : ", keyword);
			const projectType = "TEMPLATE";
			const result = await this.projectRepository.getProjectListForShare(projectType, param, data);

			runInAction(() => {
				this.searchFilter.projectTransfer = result;
			});

			console.log(LogPrefix, "templateProjectList", this.templateProjectList);
		} catch (e) {
			console.log(LogPrefix, "Cannot get TemplateProjectList ...", e);
		}
	}

	async createProjectForTemplate(userCode) {
		try {
			if (!userCode) {
				this.messageStore.alert("There is not user code");
				return null;
			}

			const { curriculumYear, schoolType, subject, subjectName, name, grade, chapter } = this.project;
			const projectType = "TEMPLATE";
			const params = {
				curriculumYear,
				schoolType,
				subject,
				subjectName,
				name,
				grade,
				chapter,
				userCode,
				type: projectType,
				state: ProjectStateType.CREATED
			}

			console.log("createProjectForTemplate userCode=", userCode, ", project=", params);
			const data = await this.projectRepository.createProject(params);

			runInAction(() => {
				this.project = data;
				console.log("createProjectForTemplate data=", data);
			});

			return this.project;
		} catch (e) {
			console.log(LogPrefix, "Cannot create project ...", e);
		}
	}
	async createProjectForCustom(userCode: string) {
		try {
			this.isCreatingProjectForCustom = true;

			if (!userCode) {
				this.messageStore.alert("There is not user code");
				return null;
			}

			this.changeEndDatetime(dayjs(this.project.endDatetime).second(0));

			const { semesterType, name, includedOnline, endDatetime } = this.project;

			const params = {
				// curriculumYear,
				semesterType,
				name: name.trim(),
				year: dayjs().get('year'),
				includedOnline,
				endDatetime: includedOnline ? endDatetime : null,
				userCode,
				state: ProjectStateType.CREATED,
				type: ProjectType.CUSTOM
			}

			const data = await this.projectRepository.createProject(params);

			runInAction(() => {
				data.startDatetime = data.startDatetime === null ? "" : convertUTCToKST(data.startDatetime);
				data.endDatetime = data.endDatetime === null ? "" : convertUTCToKST(data.endDatetime);

				this.project = data;
			});

			return this.project;
		} catch (e) {
			console.log(LogPrefix, "Cannot create project ...", e);
		} finally {
			runInAction(() => {
				this.isCreatingProjectForCustom = false;
			});
		}
	}

	async createProject(userCode: string) {
		try {
			console.log("create userCode=", userCode, ", project=", this.project);

			if (!userCode) {
				this.messageStore.alert("There is not user code");
				return null;
			}

			// const convertSubject = this.convertSubjectTypeKrToEn(this.project.subject);

			// kitty
			this.changeSubject(this.project.subject);
			// const convertSubject = convertSubjectTypeEnToKr(this.project.subject);
			// if (convertSubject !== undefined) {
			// 	this.changeSubject(convertSubject);
			// }

			const { curriculumYear, schoolType, semesterType, subject, subjectName, name, includedOnline, startDatetime, endDatetime, description } = this.project;

			const params = {
				curriculumYear,
				schoolType,
				year: dayjs().get('year'),
				semesterType,
				subject,
				subjectName,
				name,
				includedOnline,
				startDatetime: includedOnline ? startDatetime : null,
				endDatetime: includedOnline ? endDatetime : null,
				description,
				userCode,
				state: ProjectStateType.CREATED
			}
			const data = await this.projectRepository.createProject(params);

			const ret = runInAction(() => {
				data.startDatetime = data.startDatetime === null ? "" : convertUTCToKST(data.startDatetime);
				data.endDatetime = data.endDatetime === null ? "" : convertUTCToKST(data.endDatetime);

				this.project = data;
				return this.project;
			});

			// const subjectKr = this.convertSubjectTypeEnToKr(this.project.subject);
			// if(subjectKr !== undefined){
			//   this.changeSubject(subjectKr);
			// }
			return ret;
		} catch (e) {
			console.log(LogPrefix, "Cannot create project ...", e);
		}
	}

	async updateProjectState(projectCode: string, state) {
		try {
			this.isUpdatingProjectState = true;

			const param = {
				state: state
			}
			await this.projectRepository.updateProjectState(projectCode, param);

		} catch (e) {
			console.log(LogPrefix, "Cannot update projectState ...", e);
		} finally {
			runInAction(() => {
				this.isUpdatingProjectState = false;
			});
		}
	}

	async modifyProjectSharedState(projectCode: string, state) {
		try {
			const params = { state: state };

			await this.projectRepository.modifyProjectSharedState(projectCode, params);

			runInAction(() => {
				this.project.sharedState = state;
			});
		} catch (e) {
		}
	}

	async updateProject(userCode: string) {
		try {
			this.isUpdatingProject = true;

			if (!userCode) {
				this.messageStore.alert("There is not user code");
				return;
			}

			if (!this.project.code) {
				this.messageStore.alert("There is not project code");
				return;
			}

			// const convertSubject = this.convertSubjectTypeKrToEn(this.project.subject);
			// if(convertSubject !== undefined){
			//   this.changeSubject(convertSubject);
			// }

			this.changeEndDatetime(dayjs(this.project.endDatetime).second(0));

			const params = {
				...this.project,

				startDatetime: this.project.includedOnline ? this.project.startDatetime : null,
				endDatetime: this.project.includedOnline ? this.project.endDatetime : null,
				userCode: userCode,
				year: dayjs().get('year')
			}

			const data = await this.projectRepository.updateProject(params);

			runInAction(() => {
				data.startDatetime = data.startDatetime === null ? "" : convertUTCToKST(data.startDatetime);
				data.endDatetime = data.endDatetime === null ? "" : convertUTCToKST(data.endDatetime);

				this.project = data;
			});

			return data;
		} catch (e) {
			console.log(LogPrefix, "Cannot update project ...", e);
		} finally {
			this.isUpdatingProject = false;
		}
	}

	async updateTempProject(userCode: string) {
		try {
			console.log("update userCode=", userCode, ", project=", this.project);

			if (!userCode) {
				this.messageStore.alert("There is not user code");
				return;
			}

			if (!this.project.code) {
				this.messageStore.alert("There is not project code");
				return;
			}

			const { code, curriculumYear, schoolType, year, semesterType, subject, subjectName, name, includedOnline, startDatetime, endDatetime, description, state, type, grade, chapter } = this.project;
			const params = {
				code,
				curriculumYear,
				schoolType,
				subject,
				subjectName,
				name,
				userCode,
				state,
				type,
				grade,
				chapter
			}

			const data = await this.projectRepository.updateProject(params);
			console.log("update data=", data);

			runInAction(() => {
				this.project = data;
			});

			return data;
		} catch (e) {
			console.log(LogPrefix, "Cannot update project ...", e);
		}
	}

	async downloadProjectInfoAndStore(projectCode: string) {
		try {
			this.isSelectingProjectInfo = true;

			const data = await this.projectRepository.getProject(projectCode);

			const ret = runInAction(() => {
				if (!data) {
					this.isSelecting = StoreStatus.FAILED;
					return false;
				}

				data.startDatetime = data.startDatetime === null ? "" : convertUTCToKST(data.startDatetime).toString();
				data.endDatetime = data.endDatetime === null ? "" : convertUTCToKST(data.endDatetime).toString();
				this.project = data;

				return true;
			});

			return ret;

		} catch (e) {
			console.log(LogPrefix, "Cannot get project info...", e);
			return false;

		} finally {
			runInAction(() => {
				this.isSelectingProjectInfo = false;
			});
		}
	}

	async deleteProject() {
		try {
			this.isDeletingProject = true;

			const projectCode = this.project?.code;
			if (!projectCode) {
				return;
			}

			await this.projectRepository.deleteProject(projectCode);

		} catch (e) {
			console.log(LogPrefix, "Cannot delete project...", e);
			this.messageStore.alert(i18next.t("삭제 실패했습니다."));

		} finally {
			runInAction(() => {
				this.isDeletingProject = false;
			});
		}
	}

	async deleteProjectWithProject(project) {
		try {
			const projectCode = project?.code;
			console.log("deleteProject=", projectCode);
			if (!projectCode) return;

			await this.projectRepository.deleteProject(projectCode);
		} catch (e) {
			console.log(LogPrefix, "Cannot delete project...", e);
		}
	}
	async getProjectStates(projectCode) {
		try {
			this.isSelectingProjectStates = true;
			if (!projectCode) return;

			let response = await this.projectRepository.getProjectStates(projectCode);
			// console.log(LogPrefix, "Success getProjectStates ...", response);
			runInAction(() => {
				this.projectStates = response;
			});
		} catch (e) {
			// console.log(LogPrefix, "Cannot getProjectStates...", e);
		} finally {
			runInAction(() => {
				this.isSelectingProjectStates = false;
			});
		}
	}

	async getProjectPlanTransfer(projectCode) {
		try {
			this.isSelectingProjectPlanTransfer = true;

			console.log("Start getProjectPlanTransfer ...", projectCode)
			let response = await this.projectRepository.getProjectPlanTransfer(projectCode);

			runInAction(() => {
				if (response)
					this.projectPlanTransfer = response;
				else
					this.initProjectPlanTransfer();
				console.log(LogPrefix, "Success getProjectPlanTransfer ...", this.projectPlanTransfer);
			});
		} catch (e) {
			console.log(LogPrefix, "Cannot get getProjectPlanTransfer ...", e);

		} finally {
			runInAction(() => {
				this.isSelectingProjectPlanTransfer = false;
			});
		}
	}

	async createProjectPlan(projectCode) {
		try {
			this.projectPlanTransfer.projectCode = projectCode;
			this.projectPlanTransfer.standards.forEach((standard) => {
				standard.projectCode = projectCode;
			})
			console.log("Start createProjectPlan ...", this.projectPlanTransfer)
			let response = await this.projectRepository.createProjectPlan(projectCode, this.projectPlanTransfer);
			console.log(LogPrefix, "Success createProjectPlan ...", response);
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot create createProjectPlan ...", e);
			return false;
		}
	}
	async modifyProjectPlan() {
		try {
			this.projectPlanTransfer.standards.forEach((standard) => {
				if (standard.projectCode === null || standard.projectCode === "")
					standard.projectCode = this.projectPlanTransfer.projectCode;
			})
			console.log("Start modifyProjectPlan ...", this.projectPlanTransfer)
			let response = await this.projectRepository.modifyProjectPlan(this.projectPlanTransfer.projectCode, this.projectPlanTransfer.code, this.projectPlanTransfer);
			console.log(LogPrefix, "Success modifyProjectPlan ...", response);
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot modifyProjectPlan ...", e);
			return false;
		}
	}

	async getProjectUsers(projectCode: string) {
		try {
			// console.log("Start getProjectUsers ...", projectCode)
			let response = await this.projectRepository.getProjectUsers(projectCode);

			runInAction(() => {
				this.projectUsers = response.projectUsers;
				this.projectSelectedUsers = this.projectUsers;
				console.log(LogPrefix, "Success getProjectUsers ...", response, this.projectUsers);
			});

		} catch (e) {
			console.log(LogPrefix, "Cannot get getProjectUsers ...", e);
		}
	}
	async getProjectUserTransfers(projectCode: string, sortingHints) {
		try {
			this.isSelectingProjectUserTransfers = true;

			console.log("Start getProjectUserTransfers ...", projectCode, sortingHints)
			let response = await this.projectRepository.getProjectUserTransfers(projectCode, sortingHints);
			runInAction(() => {
				this.projectUsers = response;
				// let tempArr = response;
				// if(sortingAsc){
				//   tempArr.sort((a,b)=>a.name.localeCompare(b.name));
				// } else{
				//   tempArr.sort((a,b)=>b.name.localeCompare(a.name));
				// }
				// this.projectUsers = tempArr;
				this.projectSelectedUsers = response;
				console.log(LogPrefix, "Success getProjectUserTransfers ...", this.projectUsers);
			});

		} catch (e) {
			console.log(LogPrefix, "Cannot get getProjectUserTransfers ...", e);

		} finally {
			runInAction(() => {
				this.isSelectingProjectUserTransfers = false;
			});
		}
	}

	sortProjectUserTransfer(columnName, sortType) {
		if (!Object.values(ProjectUserSortColumn).includes(columnName)) return;

		const sortValue = sortType ? 1 : -1;

		if (columnName === ProjectUserSortColumn.GRADE_CLASSNAME) {
			this.projectUsers.sort((a, b) => {
				if (a.grade < b.grade) {
					return -sortValue;
				}
				if (a.grade > b.grade) {
					return sortValue;
				}

				if (a.className < b.className) {
					return -sortValue;
				}

				if (a.className > b.className) {
					return sortValue;
				}

				return 0;
			});

		} else {
			this.projectUsers.sort((a, b) => {
				if (a[columnName] < b[columnName]) {
					return -sortValue;
				}
				if (a[columnName] > b[columnName]) {
					return sortValue;
				}
				return 0;
			});
		}

		this.projectUsers = [...this.projectUsers];
	};

	async getProjectUserForCheckState(projectCode) {
		try {
			console.log("Start getProjectUserForCheckState ...", projectCode)
			let response = await this.projectRepository.getProjectUsers(projectCode);

			const ret = runInAction(() => {
				let tempArr = response.projectUsers;
				console.log(LogPrefix, "Success getProjectUserForCheckState ...", tempArr);
				if (tempArr.length === 0) {
					return false;
				} else {
					const findingResult = tempArr.find(user => this.checkSubmitted(user.state));
					return findingResult;
				}
			});
			return ret;

		} catch (e) {
			console.log(LogPrefix, "Cannot get getProjectUserForCheckState ...", e);
		}
	}

	async createProjectUsers(projectCode, projectName, targetGroupName, projectUsers) {
		try {
			const projectUserTransfer = {
				projectCode: projectCode,
				projectName: projectName,
				targetGroupName: targetGroupName,
				projectUsers: projectUsers
			}
			console.log("Start createProjectUser ...", projectUserTransfer);
			let response = await this.projectRepository.createProjectUsers(projectUserTransfer);

			console.log(LogPrefix, "Success createProjectUser ...", response);
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot get createProjectUser ...", e);
			return false;
		}
	}

	// *appendProjectUsers(projectCode,projectName,targetGroupName,projectUsers){
	//   try{
	//     const projectUsers = {
	//       projectCode:projectCode,
	//       projectName:projectName,
	//       targetGroupName:targetGroupName,
	//       projectUsers:projectUsers
	//     }
	//     console.log("Start createProjectUsers ...", projectUsers);
	//     let response = await  this.projectRepository.createProjectUsers(projectUsers);
	//
	//     console.log(LogPrefix, "Success createProjectUsers ...", response);
	//
	//   } catch (e) {
	//     console.log(LogPrefix, "Cannot get createProjectUsers ...", e);
	//   }
	// }

	async updateProjectUserState(projectCode: string, userCode: string, state: ProjectStateType, close = false) {
		try {
			this.isUpdatingProjectUserState = true;

			const params = {
				state: state
			}
			// console.log("Start updateProjectUserState ...",userCode, params);
			await this.projectRepository.updateProjectUserState(projectCode, userCode, params);
			// console.log(LogPrefix, "Success updateProjectUserState ...", response);
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot get updateProjectUserState ...", e);
			return false;
		} finally {
			runInAction(() => {
				this.isUpdatingProjectUserState = false;

				if (close) {
					this.updatedProjectUserState = !this.updatedProjectUserState;
				}
			});
		}
	}


	async deleteProjectUser(projectCode: string, userCode: string) {
		try {
			console.log("Start deleteProjectUser ...");
			let response = await this.projectRepository.deleteProjectUser(projectCode, userCode);
			console.log(LogPrefix, "Success deleteProjectUser ...", response);

		} catch (e) {
			console.log(LogPrefix, "Cannot get deleteProjectUser ...", e);
		}
	}
	async deleteProjectUsers(projectCode: string, userCodeList) {
		try {
			console.log("Start deleteProjectUsers ...", userCodeList);
			let response = await this.projectRepository.deleteProjectUsers(projectCode, userCodeList);
			console.log(LogPrefix, "Success deleteProjectUsers ...", response);

		} catch (e) {
			console.log(LogPrefix, "Cannot get deleteProjectUsers ...", e);
		}
	}

	async getProjectMaxOrder(projectCode: string) {
		try {
			console.log("Start getProjectMaxOrder ...");
			let response = await this.projectRepository.getProjectMaxOrder(projectCode);
			console.log(LogPrefix, "Success getProjectMaxOrder ...", response);
			return response;
		} catch (e) {
			console.log(LogPrefix, "Cannot get getProjectMaxOrder ...", e);
			return -1;
		}
	}

	async copyTemplateToCustom(customCode, templateCode) {
		try {

			// this.storeManager.changeStoreState(StoreStatus.PROGRESS);
			console.log("Start copyTargetToCustom...");

			let response = await this.projectRepository.copyTemplateToCustom(customCode, templateCode);
			// this.storeManager.changeStoreState(StoreStatus.COMPLETED);

			runInAction(() => {
				console.log(LogPrefix, "Success copyTargetToCustom ...", response);
			});

			return response;
		} catch (e) {
			runInAction(() => {
				// this.storeManager.changeStoreState(StoreStatus.FAILED);
				console.warn(LogPrefix, "Cannot get copyTargetToCustom ...", e);
			});
		}
	}

	async copyTemplate(templateCode) {
		try {

			this.storeManager.changeStoreState(StoreStatus.PROGRESS);
			console.log("Start copyTargetToCustom...");

			let response = await this.projectRepository.copyTemplate(templateCode);
			this.storeManager.changeStoreState(StoreStatus.COMPLETED);

			runInAction(() => {
				console.log(LogPrefix, "Success copyTargetToCustom ...", response);
			});

			return response;
		} catch (e) {
			runInAction(() => {
				this.storeManager.changeStoreState(StoreStatus.FAILED);
				console.warn(LogPrefix, "Cannot get copyTargetToCustom ...", e);
			});
		}
	}

	async fetchProject(projectCode: string) {
		try {
			const project = await this.projectRepository.getProjectByProjectCode(projectCode);
			return project;
		}
		catch (e) {
			console.log(LogPrefix, "Cannot get template project code ...", e);
			return null;
		}
	}

	findTemplateProjectListFromLoaded(projectCode: string) {
		const project = this.templateProjectList?.projects?.find(project => project.code === projectCode) || null;
		return project;
	}
}
