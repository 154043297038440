import { Link } from "./Link";

// TODO remove class
export class Links {
  links: Link[];

  constructor(ctx, links: Link[]) {
    this.links = links;
  }

  free() {
    // TODO
  }
}