import { FzRect } from "./FzRect";
import { Wrapper } from "./Wrapper";



export class FzPixmap extends Wrapper {
  bbox: FzRect;

  constructor(libmupdf: any, ctx: number, pointer: number) {
    super(libmupdf, ctx, pointer, libmupdf._wasm_drop_pixmap);
    this.bbox = FzRect.fromIntRectPtr(libmupdf, libmupdf._wasm_pixmap_bbox(this.ctx, this.pointer));
  }

  static withBbox(libmupdf: any, ctx: number, colorspace, bbox, alpha) {
    // Note that the wasm function expects integers, but we pass JS `Number`
    // values. Floating-point numbers are truncated to integers automatically.
    return new FzPixmap(
      libmupdf, ctx,
      libmupdf._wasm_new_pixmap_with_bbox(ctx,
        colorspace.pointer,
        bbox.x0, bbox.y0, bbox.x1, bbox.y1,
        0,
        alpha,
      ));
  }

  clear() {
    const { libmupdf } = this;
    libmupdf._wasm_clear_pixmap(this.ctx, this.pointer);
  }

  clearWithWhite() {
    const { libmupdf } = this;
    libmupdf._wasm_clear_pixmap_with_value(this.ctx, this.pointer, 0xff);
  }

  // getWidth
  width() {
    return this.bbox.width();
  }

  // getHeight
  height() {
    return this.bbox.height();
  }

  // getStride
  // getAlpha
  // getColorSpace
  // getSamples

  // invert
  // invertLuminance
  // gamma
  // tint

  // TODO
  drawGrabHandle(x, y) {
    // TODO
    const VALUE = 0;
    const { libmupdf } = this;
    libmupdf._wasm_clear_pixmap_rect_with_value(this.ctx, this.pointer, VALUE, x - 5, y - 5, x + 5, y + 5);
  }

  samples(): ArrayBuffer {
    const { libmupdf } = this;
    const stride = libmupdf._wasm_pixmap_stride(this.ctx, this.pointer);
    const n = stride * this.height();
    const p = libmupdf._wasm_pixmap_samples(this.ctx, this.pointer);
    return libmupdf.HEAPU8.subarray(p, p + n);
  }

  toPNG(): ArrayBuffer {
    // doLeakCheck();
    const { libmupdf } = this;
    const buf = libmupdf._wasm_new_buffer_from_pixmap_as_png(this.ctx, this.pointer);
    try {
      const data = libmupdf._wasm_buffer_data(this.ctx, buf);
      const size = libmupdf._wasm_buffer_size(this.ctx, buf);
      const arr = libmupdf.HEAPU8.slice(data, data + size);
      return arr;
    } finally {
      libmupdf._wasm_drop_buffer(this.ctx, buf);
    }
  }

  toUint8ClampedArray() {
    const { libmupdf } = this;
    const n = libmupdf._wasm_pixmap_samples_size(this.ctx, this.pointer);
    const p = libmupdf._wasm_pixmap_samples(this.ctx, this.pointer);
    return new Uint8ClampedArray(libmupdf.HEAPU8.buffer, p, n).slice();
  }
}