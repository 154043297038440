import {
	Box,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { AssignmentProjectMainStyle } from "../../styles/AssignmentProjectMainStyle";
import React from "react";
import { IProjectUser } from "../../../../repositories/model/IProjectUser";

type Props = {
	idx: number;
	rowStartNum: number;
	user: IProjectUser;
}

export const ProjectUserTableRow = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles(AssignmentProjectMainStyle);

	const {
		idx,
		rowStartNum,
		user
	} = props;

	const userGroup = React.useMemo(() => {
		if (!user || !user?.groupName || !user?.groupCount || Number(user?.groupCount || `0`) === 0) return '-';

		if (Number(user.groupCount) > 1) {
			return i18next.t("{{groupName}} 외 {{groupCount}}개", { groupName: user.groupName, groupCount: Number(user.groupCount) - 1 });
		}

		return user.groupName;
	}, [user]);

	return (
		<TableRow key={idx}
			sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}>
			<TableCell className={classes.numberColumn}>
				{rowStartNum + idx}
			</TableCell>
			<TableCell align="left">
				<Box>
					<Typography
						className={classes.tableStudentName}>{user?.name || '-'}</Typography>
				</Box>
			</TableCell>
			<TableCell align="left">
				{user?.email || '-'}
				{/*writing@assessmemt.com*/}
			</TableCell>
			<TableCell align="left">
				{user?.school || '-'}
				{/*{user.school}*/}
			</TableCell>
			<TableCell align="left">
				{i18next.t("{{grade}}학년 {{className}}반", { grade: user?.grade || '-', className: user?.className || '-' })}
			</TableCell>
			<TableCell align="left">
				{userGroup}
			</TableCell>
		</TableRow>
	)
}
