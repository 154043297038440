import {
	Box,
	Typography
} from "@mui/material";
import React from "react";
import TextViewer from "../../../../components/common/TextViewer";
import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";
import { DataUtils } from "../../../../util/data-utils";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TemplateProjectViewStyle } from "../../styles/TemplateProjectViewStyle";
import { useCurrentParagraphHTML } from "../001_Functions/useCurrentParagraphHtml";
import { getSubQuestionNumberString } from "./getSubQuestionNumberString";
import { QuestionAndReferenceTabQuestionRow } from "./QuestionAndReferenceTabQuestionRow";

type Props = {
	examInfo: IExamInfo,
	sideBarStepNum: number,
	handleClickRagEdit: () => void;
	idx: number;
	onSetQuestionHTMLWithOrderAndScore: (question: string, score: number, reference: string, orderString: string) => void,
	questionRef: any;
	handleClickQuestionEdit: () => void;
}

export const QuestionAndReferenceTabExamRow = (props: Props) => {
	const { examInfo, sideBarStepNum, handleClickRagEdit } = props;
	const classes = useEmotionStyles( TemplateProjectViewStyle );

	// const questionListLen = React.useMemo(() => {
	// 	return examInfo.questions.filter(q => q.changeFlag !== FlagType.DELETED).length;
	// }, [examInfo, examInfo.questions]);

	// const currentParagraphHTML = React.useMemo(() => {
	// 	if (!examInfo.paragraph && !examInfo.source) return "<></>";
	// 	const sourceFontSize = "0.75rem";
	// 	const sourceColor = "#787878";
	// 	const source = examInfo.source.replace(/\n/g, "<br>");
	// 	return "<p>" + examInfo.paragraph + "\n\n" + `<div style='margin-top: 15px; color: ${sourceColor};font-size: ${sourceFontSize}; text-align: right'>` + source + '</div>' + "</p>";
	// }, [examInfo.paragraph, examInfo.source]);
	const currentParagraphHTML = useCurrentParagraphHTML({ examInfo });

	const isParagraphAndSourceBoxEmpty = React.useMemo(() => {
		const emptyParagraph = !examInfo.paragraph || (DataUtils.getTagArrFromHtml(examInfo.paragraph, 'img').length === 0 && !DataUtils.getTextFromHtml(examInfo.paragraph));
		const emptySource = !examInfo.source;
		return emptyParagraph && emptySource;
	}, [examInfo.paragraph, examInfo.source]);


	const filtered = examInfo.questions.filter(q => q.changeFlag !== ChangeFlag.DELETED);

	const questionsPerExam = {};
	filtered.forEach(question => {
		const examIndex = question.parentOrder;
		if (!questionsPerExam[examIndex]) {
			questionsPerExam[examIndex] = 1;
		} else {
			questionsPerExam[examIndex] += 1;
		}
	});

	return (
		<>
			{examInfo.directive && (
				<Typography className={classes.lightBorderTaskTitleBold}>{examInfo.directive}</Typography>
			)}

			{!isParagraphAndSourceBoxEmpty && (
				<Box display='flex' alignItems='center' justifyContent='left' className={classes.lightBorderViewerBox}>
					<TextViewer currentHTML={currentParagraphHTML} />
				</Box>
			)}

			{examInfo.questions.length > 0 &&
				filtered.map((question, idx) => {

					const questionString = getSubQuestionNumberString(examInfo, question, true);


					return (
						<div key={question.projectExamCode + question.order}>
							<Typography style={{ color: "#000", fontWeight: 700, backgroundColor: "#e0e0e040" }}>
								{questionString}
							</Typography>
							<QuestionAndReferenceTabQuestionRow
								key={question.projectExamQuestionNum}
								idx={idx}
								examInfo={examInfo}
								questionInfo={question}
								sideBarStepNum={sideBarStepNum}
								handleClickRagEdit={handleClickRagEdit}
							/>
						</div>
					);
				})}
		</>
	);
};

