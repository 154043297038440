import { IPaperTypeSet } from "../../../nl-lib3-common";

export const g_paperType_default: IPaperTypeSet = {
	'3.27.0': {
		id: '3.27.0',
		section: 3,
		owner: 27,
		book: 0,
		margin: {
			Xmin: 0,
			Ymin: 0,
			Xmax: 88.56,
			Ymax: 125.24
		},
		title: 'paperA4_dummy',
		pdf_name: '3_27_0_dummy.pdf',
		nproj_file: '3_27_0_dummy.nproj',
		ncode_start_page: 0,
		ncode_end_page: 300,
		pdf_page_count: 301
	},

	'3.27.205': {
		owner: 27,
		pdf_page_count: 210,
		margin: {
			Ymax: 132.02977,
			Xmin: 5.4836307,
			Ymin: 5.4836307,
			Xmax: 102.502975
		},
		nproj_file: 'note_205.nproj',
		book: 205,
		pdf_name: 'note_205.pdf',
		section: 3,
		id: '3.27.205',
		title: 'LAB Notebook',
		ncode_end_page: 210,
		ncode_start_page: 1
	},
	'3.27.700': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 90.922615,
			Xmin: 0,
			Ymin: 0,
			Xmax: 58.035713
		},
		nproj_file: 'note_0700.nproj',
		book: 700,
		pdf_name: 'Moleskine-Nbook.pdf',
		section: 3,
		id: '3.27.700',
		title: 'N Moleskine classic'
	},
	'3.27.603': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 99.55059,
			Xmin: 0,
			Ymin: 0,
			Xmax: 74.24107
		},
		nproj_file: 'note_0603.nproj',
		book: 603,
		pdf_name: 'N2_spring.pdf',
		section: 3,
		id: '3.27.603',
		title: 'Ring Note'
	},
	'3.27.701': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 97.09821,
			Xmin: 0,
			Ymin: 0,
			Xmax: 63.348217
		},
		nproj_file: 'note_0701.nproj',
		book: 701,
		pdf_name: 'note_0701.pdf',
		section: 3,
		id: '3.27.701',
		title: 'Large Black Dotted 1'
	},
	'3.27.704': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 93.19941,
			Xmin: 4.610119,
			Ymin: 1.6339285,
			Xmax: 56.47619
		},
		nproj_file: 'note_704.nproj',
		book: 704,
		pdf_name: 'note_704.pdf',
		section: 3,
		id: '3.27.704',
		title: 'Large Black Plain 1',
		ncode_end_page: 173,
		ncode_start_page: 1
	},
	'3.27.703': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 93.19941,
			Xmin: 4.610119,
			Ymin: 1.6339285,
			Xmax: 56.47619
		},
		nproj_file: 'note_703.nproj',
		book: 703,
		pdf_name: 'note_703.pdf',
		section: 3,
		id: '3.27.703',
		title: 'Large Red Dotted 1',
		ncode_end_page: 173,
		ncode_start_page: 1
	},
	'3.27.705': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 91.708336,
			Xmin: 3.125,
			Ymin: 3.125,
			Xmax: 57.961308
		},
		nproj_file: 'note_705.nproj',
		book: 705,
		pdf_name: 'PaperTablet_InnerPagesRuled1_130x210_AppDev.pdf',
		section: 3,
		id: '3.27.705',
		title: 'Large Black Ruled 1',
		ncode_end_page: 173,
		ncode_start_page: 1
	},
	'3.27.168': {
		owner: 27,
		pdf_page_count: 300,
		margin: {
			Ymax: 128.40625,
			Xmin: 3.125,
			Ymin: 3.125,
			Xmax: 91.708336
		},
		nproj_file: 'note_168.nproj',
		book: 168,
		pdf_name: 'Ncode_A4_300inner.pdf',
		section: 3,
		id: '3.27.168',
		title: 'Ncode A4 300',
		ncode_end_page: 300,
		ncode_start_page: 1
	},
	'3.1013.1': {
		owner: 1013,
		pdf_page_count: 4,
		margin: {
			Ymax: 81.68303,
			Xmin: 4.910714,
			Ymin: 4.910714,
			Xmax: 113.31845
		},
		nproj_file: '3_1013_1.nproj',
		book: 1,
		pdf_name: '3_1013_1.pdf',
		section: 3,
		id: '3.1013.1',
		ncode_end_page: 5,
		ncode_start_page: 1
	},
	'3.27.708': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 108.58035,
			Xmin: 3.125,
			Ymin: 3.125,
			Xmax: 83.270836
		},
		nproj_file: 'note_708.nproj',
		book: 708,
		pdf_name: 'PaperTablet XL_Inner Pages Ruled 1 Red_190x250_App Dev_noline.pdf',
		section: 3,
		id: '3.27.708',
		title: 'XL Red Ruled 1',
		ncode_end_page: 173,
		ncode_start_page: 1
	},
	'3.27.609': {
		owner: 27,
		pdf_page_count: 1,
		margin: {
			Ymax: 136.24852,
			Xmin: 0,
			Ymin: 0,
			Xmax: 99.55059
		},
		nproj_file: 'note_0609.nproj',
		book: 609,
		pdf_name: 'N2_idea.pdf',
		section: 3,
		id: '3.27.609',
		title: 'Idea Pad'
	},
	'3.27.656': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 123.59376,
			Xmin: 5.4836307,
			Ymin: 5.4836307,
			Xmax: 96.596725
		},
		nproj_file: 'note_656.nproj',
		book: 656,
		pdf_name: 'note_656.pdf',
		section: 3,
		id: '3.27.656',
		title: 'College mint',
		ncode_end_page: 144,
		ncode_start_page: 1
	},
	'3.27.655': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 123.59376,
			Xmin: 5.4836307,
			Ymin: 5.4836307,
			Xmax: 96.596725
		},
		nproj_file: 'note_655.nproj',
		book: 655,
		pdf_name: 'note_655.pdf',
		section: 3,
		id: '3.27.655',
		title: 'College gray',
		ncode_end_page: 144,
		ncode_start_page: 1
	},
	'3.27.657': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 123.59376,
			Xmin: 5.4836307,
			Ymin: 5.4836307,
			Xmax: 96.596725
		},
		nproj_file: 'note_657.nproj',
		book: 657,
		pdf_name: 'note_657.pdf',
		section: 3,
		id: '3.27.657',
		title: 'College purple',
		ncode_end_page: 144,
		ncode_start_page: 1
	},
	'3.27.517': {
		owner: 27,
		pdf_page_count: 50,
		margin: {
			Ymax: 130.7649,
			Xmin: 5.4836307,
			Ymin: 5.4836307,
			Xmax: 94.06696
		},
		nproj_file: 'note_517.nproj',
		book: 517,
		pdf_name: 'Ncode A4_171120.pdf',
		section: 3,
		id: '3.27.517',
		title: 'Ncode A4',
		ncode_end_page: 50,
		ncode_start_page: 1
	},
	'3.27.615': {
		owner: 27,
		margin: {
			Ymax: 97.440475,
			Xmin: 0,
			Ymin: 0,
			Xmax: 70.022316
		},
		nproj_file: 'note_0615.nproj',
		book: 615,
		pdf_name: 'N2_Professonal.pdf',
		section: 3,
		id: '3.27.615',
		title: 'Professional Note'
	},
	'3.27.232': {
		owner: 27,
		pdf_page_count: 72,
		margin: {
			Ymax: 113.89137,
			Xmin: 5.4836307,
			Ymin: 5.4836307,
			Xmax: 84.78571
		},
		nproj_file: 'note_232.nproj',
		book: 232,
		pdf_name: 'note_232.pdf',
		section: 3,
		id: '3.27.232',
		title: 'D_NOTEBOOK_YEL',
		ncode_end_page: 72,
		ncode_start_page: 1
	},
	'3.27.234': {
		owner: 27,
		pdf_page_count: 48,
		margin: {
			Ymax: 113.89137,
			Xmin: 5.4836307,
			Ymin: 5.4836307,
			Xmax: 84.78571
		},
		nproj_file: 'note_234.nproj',
		book: 234,
		pdf_name: 'note_234.pdf',
		section: 3,
		id: '3.27.234',
		title: 'D_NOTEBOOK_PINK',
		ncode_end_page: 48,
		ncode_start_page: 1
	},
	'3.27.233': {
		owner: 27,
		pdf_page_count: 48,
		margin: {
			Ymax: 113.89137,
			Xmin: 5.4836307,
			Ymin: 5.4836307,
			Xmax: 84.78571
		},
		nproj_file: 'note_233.nproj',
		book: 233,
		pdf_name: 'note_233.pdf',
		section: 3,
		id: '3.27.233',
		title: 'D_NOTEBOOK_MINT',
		ncode_end_page: 48,
		ncode_start_page: 1
	},
	'3.27.236': {
		owner: 27,
		pdf_page_count: 48,
		margin: {
			Ymax: 113.89137,
			Xmin: 5.4836307,
			Ymin: 5.4836307,
			Xmax: 84.78571
		},
		nproj_file: 'note_236.nproj',
		book: 236,
		pdf_name: 'note_236.pdf',
		section: 3,
		id: '3.27.236',
		title: 'D_NOTEBOOK_SKY',
		ncode_end_page: 48,
		ncode_start_page: 1
	},
	'3.27.235': {
		owner: 27,
		pdf_page_count: 48,
		margin: {
			Ymax: 113.89137,
			Xmin: 5.4836307,
			Ymin: 5.4836307,
			Xmax: 84.78571
		},
		nproj_file: 'note_235.nproj',
		book: 235,
		pdf_name: 'note_235.pdf',
		section: 3,
		id: '3.27.235',
		title: 'D_NOTEBOOK_PP',
		ncode_end_page: 48,
		ncode_start_page: 1
	},
	'3.1013.2': {
		owner: 1013,
		pdf_page_count: 2,
		margin: {
			Ymax: 49.775295,
			Xmin: 0,
			Ymin: 0,
			Xmax: 88.583336
		},
		nproj_file: '3_1013_2.nproj',
		book: 2,
		pdf_name: '3_1013_2.pdf',
		section: 3,
		id: '3.1013.2',
		ncode_end_page: 2,
		ncode_start_page: 1
	},
	'3.27.619': {
		owner: 27,
		pdf_page_count: 2,
		margin: {
			Ymax: 129.07738,
			Xmin: 0,
			Ymin: 0,
			Xmax: 102.08035
		},
		nproj_file: 'note_0619.nproj',
		book: 619,
		pdf_name: 'N2_College_3.pdf',
		section: 3,
		id: '3.27.619',
		title: 'College Note 3'
	},
	'3.27.1069': {
		owner: 27,
		pdf_page_count: 1,
		margin: {
			Ymax: 125.28571,
			Xmin: 0,
			Ymin: 0,
			Xmax: 88.58929
		},
		nproj_file: 'note_1069.nproj',
		book: 1069,
		pdf_name: 'note_1069.pdf',
		section: 3,
		id: '3.27.1069',
		title: 'Test form 1',
		ncode_end_page: 1,
		ncode_start_page: 1
	},

	'3.0.0': {
		owner: 0,
		pdf_page_count: 65536,
		margin: {
			Ymax: 128.40625,
			Xmin: 3.125,
			Ymin: 3.125,
			Xmax: 91.708336
		},
		nproj_file: undefined,
		book: 0,
		pdf_name: undefined,
		section: 3,
		id: '3.0.0',
		title: 'dummy A4',
		ncode_end_page: 65535,
		ncode_start_page: 0
	}
};

export const g_paperType = {
	defaultKey: "3.0.0",   // Ncode A4 300
	definition: g_paperType_default,
}
