

import {
	Box,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import React from "react";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { StudentResultStyle } from "../../../styles/StudentResultStyle";
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
import { IQuestionInfo } from "../../../../../repositories/model/transfer/IQuestionInfo";
import { IRubricInfo } from "../../../../../repositories/model/transfer/IRubricInfo";

type Props = {
	currentRubricInfo: IRubricInfo;
	questionInfo: IQuestionInfo;
};


export const ScoredTable = (props: Props) => {
	const classes = useEmotionStyles( StudentResultStyle );
	const { currentRubricInfo, questionInfo } = props;
	const i18next = useTranslation();

	const currentScoreInfo = React.useMemo(() => {
		const scoreInfo = questionInfo.answer.scores.find(scoreInfo => scoreInfo.rubricId === currentRubricInfo?.id);
		return scoreInfo;
	}, [currentRubricInfo]);

	const currentRubricItemInfo = React.useMemo(() => {
		const currentRubricItemInfo = currentRubricInfo.items.find(item => item.rubricItemNum === currentScoreInfo?.rubricItemNum);
		return currentRubricItemInfo;
	}, [currentScoreInfo]);

	const scoreAverage = React.useMemo(() => {
		if (!currentScoreInfo || currentScoreInfo.totalScore === undefined || currentScoreInfo.totalCount === undefined) return 0;

		return Number((currentScoreInfo.totalScore / currentScoreInfo.totalCount).toFixed(1));
	}, [currentScoreInfo]);

	const scoreAveragePercentage = React.useMemo(() => {
		if (!currentScoreInfo) return 0;

		return Number(((scoreAverage / currentScoreInfo.standardScore) * 100).toFixed(2));
	}, [scoreAverage, currentScoreInfo]);

	const scorePercentage = React.useMemo(() => {
		if (!currentScoreInfo) return 0;

		return Number(((currentScoreInfo.score / currentScoreInfo.standardScore) * 100).toFixed(2));
	}, [currentScoreInfo]);

	console.log(`currentScoreInfo?.standardScore: ${currentScoreInfo?.standardScore}`);
	return (
		<Box className={classes.scoredCompareRound}>
			<Box display='flex' alignItems='flex-end' justifyContent='space-between' className={classes.progressMainBox}>
				<Box className={classes.progressTitle}>
					<Typography className={classes.greenBoxTitle}>{i18next.t("평균 대비 내 위치")}</Typography>
				</Box>
				<Box className={classes.progressBox}>
					<Typography className={classes.progressLeftText}>0</Typography>
					<Box >
						<LinearProgress variant="determinate" value={scorePercentage} />
					</Box>
					<Box className={classes.progressGuide} sx={{ top: '-40px', left: `${scoreAveragePercentage}%` }}>
						<Typography>
							{i18next.t("평균")}
							&nbsp;
							{scoreAverage}
						</Typography>
					</Box>
					<Typography className={classes.progressRightText}>{currentScoreInfo?.standardScore || '0'}</Typography>
				</Box>
			</Box>
			<TableContainer component={Paper} className={classes.tableBox2}>
				<Table aria-label="simple table">
					<TableHead style={{ display: 'table-header-group' }}>
						<TableRow>
							<TableCell colSpan={3} align="center">{i18next.t("평가항목")}</TableCell>
							<TableCell colSpan={3} align="center" className={classes.scoreCell}>{i18next.t("채점 결과")}</TableCell>
							<TableCell colSpan={6} align="center" className={classes.bgColorGreen}>{i18next.t("채점 기준")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow >
							{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
							<TableCell colSpan={3} align="center" className={classes.rubricCommonCell}>
								<Typography>{currentScoreInfo?.rubricName || '-'}</Typography>
							</TableCell>
							<TableCell colSpan={1} align="center" className={classes.rubricCommonCell}>
								<Typography>{currentScoreInfo?.rubricItemName || '-'}</Typography>
							</TableCell>
							<TableCell colSpan={2} className={classes.rubricCommonCell}>
								<Typography className={clsx(classes.markText, classes.markTextColor)}><span>{currentScoreInfo ? currentScoreInfo.score : '-'}</span>{i18next.t("점")}</Typography>
							</TableCell>
							<TableCell colSpan={6} align="left" className={classes.borderLine}>
								<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
									<Typography className={clsx(classes.tableText, classes.tableTextGreen)}>
										{currentRubricItemInfo?.standard || '-'}
									</Typography>
								</Box>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			{currentScoreInfo?.aiFeedback &&
				<TableContainer component={Paper} className={classes.tableBox3}>
					<Table aria-label="simple table">
						<TableBody>
							<TableRow >
								{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
								<TableCell width='25%' align="center" className={classes.feedbackBgColor}>
									<Typography>{i18next.t("AI 채점 근거")}</Typography>
								</TableCell>
								<TableCell width='75%' align="left" className={classes.feedbackBox}>
									<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
										<Typography style={{ fontWeight: 500 }}>
											{currentScoreInfo?.aiFeedback}
										</Typography>
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			}
		</Box>
	);
};
