
export class SelectedAnnotation {
  annotation;

  startRect;

  currentRect;

  initial_x;

  initial_y;

  constructor(annotation, mouse_x, mouse_y) {
    // Is this necessary?
    this.annotation = annotation;
    this.startRect = annotation.rect();
    this.currentRect = annotation.rect();
    this.initial_x = mouse_x;
    this.initial_y = mouse_y;
  }

  // TODO - remove
  mouseDrag(x, y) {
    this.currentRect = this.startRect.translated(x - this.initial_x, y - this.initial_y);
    this.annotation.rect();
    // TODO - setRect doesn't quite do what we want
    this.annotation.setRect(this.currentRect);
    return true;
  }

  // TODO - remove
  mouseUp(x, y) {
    this.currentRect = this.startRect.translated(x - this.initial_x, y - this.initial_y);
    // TODO - setRect doesn't quite do what we want
    this.annotation.setRect(this.currentRect);
    return true;
  }
}
