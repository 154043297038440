import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const AssignmentProjectMainStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
		// backgroundColor: 'red'
	},
	scrollBox: {
		marginTop: "30px",
		padding: '0px 20px',
		overflowY: 'auto',
		height: 'calc(100vh - 280px)',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px"
		},
	},

	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: "20px",
		paddingRight: 0,
		height: 'calc(100vh - 90px)',
		// overflowY: 'auto',
		background: '#fff',
		width: '600px',
		boxSizing: 'border-box',
		marginLeft: "10px",
	},
	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			// marginLeft: "10px",
		}
	},
	title3Box: {
		marginTop: "60px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		marginBottom: "30px",
	},
	editorIcon: {
		'&.MuiIconButton-root': {
			marginLeft: "8px",
		}
	},
	displayStyle: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'flex-start',
		marginBottom: "12px",
	},
	basicTitle: {

		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			fontWeight: 400,
			letterSpacing: '-0.4px',
			marginRight: "40px",
			minWidth: 160,
			boxSizing: 'border-box',
		}
	},
	basicText: {
		paddingRight: 15,
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 700,
			letterSpacing: '-0.4px',
			width: '100%'
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},
	commonTitleBox: {
		marginTop: '20px'
	},
	commonSubTitle: {
		marginBottom: "23px",
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			letterSpacing: '-0.9px',
			color: '#025949',
			'& span': {
				color: '#008C73'
			}
		}
	},
	commonSubTitle2: {
		marginBottom: "20px",
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 700,
			// color: '#025949',
			letterSpacing: '-0.5px',
		}
	},
	commonSubTitle3: {
		'&.MuiTypography-root': {
			fontSize: '1.375rem',
			fontWeight: 700,
			color: '#111',
			letterSpacing: '-0.5px',
		}
	},
	commonSubTitleGreen: {
		marginTop: "50px",
		marginBottom: "23px",
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			// color: '#025949',
			'& span': {
				color: '#008C73'
			}
		}
	},

	taskTitleBold: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			marginBottom: "15px",
			fontWeight: 500,
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	searchSchoolInput: {
		'& .MuiFormControl-root': {
			maxWidth: 830,
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 50
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},
	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},
	/////////////////////
	sideBarContent: {
		// width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},

	/////////////////////
	padding: {
		padding: 15,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	mainContentBox: {
		border: '1px solid #EAECEB',
		padding: 20,
		height: 'calc(100vh - 132px)',
		overflowY: 'auto',
		background: '#fff',
		width: '100%',
		maxWidth: "1024px",
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	mainContentBoxLeft: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 130px)',
		background: '#fff',
		width: 'calc(100vw - 770px)',
	},
	mainContentBoxLeftInner: {
		height: 'calc(100vh - 200px)',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px"
		},
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
		}
	},
	taskTitle: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			marginBottom: "15px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	tableBox: {
		marginTop: "20px",
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '90%',
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#323232',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				// fontSize: '0.2rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				// borderRight: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
				'& .MuiTypography-root': {
					// fontSize: '0.75rem',
					color: '#323232',
					letterSpacing: '-0.5px',
				}
			}
		}
	},
	tableBox2: {
		marginTop: "20px",
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '95%',
		'&.MuiPaper-root': {
			// borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			borderTop: '1px solid #cbcbcb',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				// color:'#323232',
				color: '#555',
				letterSpacing: '-0.5px',
				// fontWeight: 'bold',
				fontWeight: 400,
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "50px",
				// height: "54px",
				borderBottom: '1px solid #cbcbcb',
				// borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				// fontSize: '0.2rem',
				color: '#323232',
				// height: "54px",
				height: "50px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				// borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				borderBottom: '1px solid #cbcbcb',
				fontWeight: 400,
				// borderRight: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
				'& .MuiTypography-root': {
					// fontSize: '0.75rem',
					color: '#323232',
					letterSpacing: '-0.5px',
				}
			}
		}
	},
	tableBoxN: {
		marginTop: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid #cbcbcb',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1rem',
				color: '#01261C',
				letterSpacing: '-0.5px',
				fontWeight: 700,
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid #cbcbcb',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.2rem',
				color: '#01261C',
				height: "65px",
				letterSpacing: '-0.5px',
				padding: '20px 20px',
				boxSizing: 'border-box',
				borderBottom: '1px solid #cbcbcb',
				borderRight: '1px solid #cbcbcb',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				// whiteSpace:'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
				'& .MuiTypography-root': {
					fontSize: '1rem',
					color: '#000',
					letterSpacing: '-0.5px',
					fontWeight: 400,
					whiteSpace: 'break-spaces',
					wordBreak: 'break-all'
				}
			}
		}
	},

	bgColorTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem !important',
			color: '#01261C !important',
			letterSpacing: '-0.5px !important',
			fontWeight: '700 !important',
		}
	},


	numberColumn: {
		paddingLeft: '30px !important'
	},

	pageNumber: {
		marginTop: "10px",
		marginBottom: "10px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},

	// 2024.04.04, hkkim@onthelive. 추가 -->
	lightBorderViewerBox: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		padding: '6px 13px',
		// maxHeight: '300px',
		marginBottom: "40px",
		overflowY: 'auto',

		'& .toastui-editor-contents': {
			fontSize: '1rem',
		},
	},

	lightBorderTaskTitleBold: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.125rem',
			marginBottom: "15px",
			fontWeight: 500,
			letterSpacing: '-0.8px',
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},

	lightBorderEmptyAnswerBox: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		padding: '16px 13px',
		minHeight: '150px',
		marginTop: '10px',
		marginBottom: "30px"
		// height: '300px',
		// marginBottom: '30px'
	},

	lightBorderAnswerBox: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		padding: '14px 13px',
		// padding: 14,
		marginTop: "10px",
		marginBottom: "30px",

		'& .toastui-editor-contents': {
			fontSize: '1rem',
		}
	},

	lightBorderTaskBox: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		padding: '6px 13px',
		marginTop: "20px",

		// marginTop: "10px",
	},

	lightQuestionSentence: {
		marginTop: '0px',
		'& p': {
			// fontSize: '1.25rem', // 20px
			fontSize: '1.125rem', // 18px
			letterSpacing: '-0.8px',
			fontWeight: '500',
			lineHeight: 'normal',
			color: '#111'
		}
	},

	commonSubTitleBasic18px: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			color: '#111',
			'& span': {
				color: '#008C73'
			}
		}
	},

	tableColumnGradeName: {
		fontSize: '1rem !important',
		color: '#01261C !important',
		fontWeight: "500 !important",
	},

	iconMarginRight5px: {
		marginRight: "5px",
	},

	iconMarginRight10px: {
		marginRight: "10px",
	},

	fontScoreItem: {
		'&.MuiTypography-root': {
			fontSize: '1rem !important',
			// color: '#000 !important',
			fontWeight: '700 !important',
			color: '#01261C !important',
		}
	},

	fontScoreStandard: {
		'&.MuiTypography-root': {
			fontSize: '1rem !important',
			color: '#000 !important',
			fontWeight: '400 !important',
		}
	},

	fontScorePoint: {
		fontSize: 14,
		fontWeight: 500,
		marginLeft: "5px",
	},

	fontScoreName: {
		'&.MuiTypography-root': {
			fontSize: '16px !important',
			fontWeight: '500 !important',
			color: '#01261C !important',
		}
	},

	boxMarginBottom20px: {
		marginBottom: "20px",
	},

	boxMarginBottom30px: {
		marginBottom: "30px",
	},

	boxMarginBottom40px: {
		marginBottom: "40px",
	},

	tabBox: {
		marginBottom: "40px",
		marginLeft: 20
	},
	boxMarginBottom60px: {
		marginBottom: "60px",
	},

	fontReferenceType: {
		color: '#555555',
		fontSize: 14,
		fontWeight: 400
	},
	// <--

	viewerBox: {
		border: '2px solid #111',
		padding: '0px 10px 0px 10px',
		// maxHeight: '300px',
		marginBottom: '10px',
		overflowY: 'auto',
		'& .toastui-editor-contents': {
			// height: '170px'
		},
		'& div': {
			marginBottom: '4px'
		}
	},

	emptyAnswerBox: {
		border: '2px solid black',
		padding: '0px 10px 0px 10px',
		height: '300px',
		marginBottom: '30px'
	},

	answerBox: {
		border: '2px solid black',
		padding: '0px 10px 0px 10px',
		height: '310px',
		marginBottom: '30px',
		overflowY: 'auto',
		'& .toastui-editor-contents': {
			height: '300px'
		}
	},

	trigger: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: "440px",
		marginBottom: "34px",
		'&.MuiTabs-root': {
			minHeight: 40
		},
		'& .MuiTabs-flexContainer': {
			// width: "208px",
			border: '1px solid #F3F3F3',
			borderRadius: 8,
			overflow: 'hidden',
		},
		'& button': {
			width: "110px",
			minWidth: 110,
			minHeight: 36,
			height: "36px",
			position: 'relative',
			fontSize: '0.875rem',
			letterSpacing: '-0.5px',
			color: '#787878',
			boxSizing: 'border-box',
			padding: 0,
			background: '#fff',
			'& .MuiTab-wrapper': {
				fontSize: '0.875rem',
				color: '#111'
			}
		},
		'& button.Mui-selected': {
			fontWeight: 'bold',
			color: '#000',
			opacity: 1,
			boxSizing: 'border-box',
			background: '#2F80ED',
			borderRadius: 6,
			'& .MuiTab-wrapper': {
				color: '#fff'
			}
		},
		'& .MuiTabs-indicator': {
			height: "0px",
		}
	},
	trigger2: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		//width: "441px", // 440 + 1 : scrolling에 필요
		marginBottom: "34px",
		'&.MuiTabs-root': {
			minHeight: 40
		},
		'& .MuiTabs-flexContainer': {
			// width: "208px",
			overflow: 'hidden',
		},
		'& button': {
			width: "200px",
			// maxWidth: '155px',
			// minWidth: 'auto',
			minHeight: 36,
			height: "36px",
			position: 'relative',
			fontSize: '1.125rem',
			letterSpacing: '-0.5px',
			color: '#787878',
			boxSizing: 'border-box',
			padding: '0px 20px',
			background: '#fff',
			borderBottom: '1px solid #cbcbcb',
			borderTopLeftRadius: '10px',
			borderTopRightRadius: '10px',
			'& .MuiTab-wrapper': {
				fontSize: '0.938rem',
				color: '#787878'
			}
		},
		'& button.Mui-selected': {
			fontWeight: 'bold',
			color: '#000',
			opacity: 1,
			boxSizing: 'border-box',
			background: '#e2e7e3',
			borderBottom: '1px solid #01261C',
			'& .MuiTab-wrapper': {
				color: '#01261C'
			}
		},
		'& .MuiTabs-indicator': {
			height: "0px",
		}
	},
	previewText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 700,
			fontSize: '1.5rem',
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			marginBottom: "30px",
		}
	},
	previewSubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			marginBottom: "10px",
		}
	},
	pageUpIcon: {
		position: 'absolute',
		bottom: '23px',
		right: 20,
		'&.MuiIconButton-root': {
			border: '1px solid #fff',
			boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
			background: '#fff',
			width: "40px",
			height: "40px",
			'&:hover': {
				background: '#fff'
			}

		}
	},
	bgColor: {
		// background: '#F3F3F3'
		background: 'rgb(250, 250, 250)',
	},
	planTableTitle: {
		width: "130px",
		background: 'rgb(250, 250, 250)',
	},
	borderLine: {
		borderRight: '0px !important',
	},
	dialogDisabledInput: {
		'& .MuiFormControl-root': {
			width: '300px',
			// width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "19px",
			// '&.Mui-focused fieldset': {
			//     borderColor: '#2F80ED',
			// },
			// '&:hover fieldset': {
			//     borderColor: '#2F80ED',
			// },
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	dialogDisabledInput2: {
		'& .MuiFormControl-root': {
			width: '400px',
			// width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "19px",
			// '&.Mui-focused fieldset': {
			//     borderColor: '#2F80ED',
			// },
			// '&:hover fieldset': {
			//     borderColor: '#2F80ED',
			// },
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	dialogInput: {
		'& .MuiFormControl-root': {
			width: '670px',
			// width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "19px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
			'&:hover fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	dialogInput2: {
		'& .MuiFormControl-root': {
			width: '670px',
			// width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
			marginTop: "5px",
		},
		'& .MuiOutlinedInput-root': {
			height: "19px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
			'&:hover fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "460px",
			marginBottom: "20px",
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
			height: "440px",
		}
	},
	tableStudentName: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 400,
			color: '#008C73 !important',
		}
	},
	evaluationTableBox: {
		marginBottom: "0px",
		'&.MuiPaper-root': {
			borderTop: '1px solid #cbcbcb',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1rem',
				color: '#054030',
				letterSpacing: '-0.5px',
				fontWeight: 700,
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "44px",
				background: '#F2F7F3',
				borderBottom: '1px solid #cbcbcb',
				'& span': {
					fontSize: '1rem',
					fontWeight: 'normal',
					color: '#054030',
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 15px',
				boxSizing: 'border-box',
				borderBottom: '1px solid #cbcbcb',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				// whiteSpace:'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555',
			letterSpacing: '-0.5px',
		},
	},
	evaluationTableBgColor: {
		height: '140px !important',
		// background: '#FAFAFA',
		borderRight: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},
	taskBox: {
		border: '2px solid #111',
		borderRadius: 3,
		padding: 14,
		marginTop: "10px",
	},
	// pageNumber: {
	//     marginTop: "10px",
	//     paddingBottom: 40,
	//     borderBottom: '1px solid #CBCBCB',
	//     marginBottom: "10px",
	//     '& .MuiPaginationItem-root': {
	//         '&.Mui-selected': {
	//             background: '#555',
	//             color: '#fff',
	//             border: '1px solid #555',
	//         },
	//     },
	//     '& .MuiPaginationItem-root:hover': {
	//         background: '#fff',
	//         border: '1px solid #555',
	//         color: '#555',
	//         fontWeight: 'bold'
	//     }
	// },
	pageNumberBorder: {
		borderBottom: 0,
	},
	innerBox: {
		marginLeft: "20px",
		marginRight: "20px",
	},
	innerBox2: {
		marginLeft: "20px",
		marginRight: "20px",
		marginTop: "20px",
	},
	basicTextBtn: {
		'&.MuiButtonBase-root': {
			padding: 0,
			background: '#fff',
		},
		'& .MuiTypography-root': {
			fontSize: '1rem',
			color: '#2F80ED',
			letterSpacing: '-0.5px',
			fontWeight: 500,
			textDecoration: 'underline',
		},
	},

	basicTextBtnLeft: {
		'&.MuiButtonBase-root': {
			padding: 0,
			background: '#fff',
		},
		'& .MuiTypography-root': {
			fontSize: '1rem',
			color: '#2F80ED',
			letterSpacing: '-0.5px',
			fontWeight: 500,
			textDecoration: 'underline',
			textAlign: 'left',
		},
	},


	schoolNameSearch: {
		'&.MuiTypography-root': {
			minWidth: 92,
			marginRight: "40px",
			fontSize: '1rem',
			color: '#555',
			letterSpacing: '-0.5px',

		}
	},
	searchName: {
		width: "800px",
		marginBottom: '12px'
	},
	studentListView: {
		// marginLeft: "200px",
		minWidth: 160,
		marginTop: "5px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		// '& .MuiTypography-root': {
		//     fontSize: '0.875rem',
		//     color: '#555',
		//     fontWeight: 500,
		//     letterSpacing: '-0.5px',
		//     marginRight: "5px",
		//     marginLeft: "10px",
		// }
	},
	tableBoxSm: {
		'&.MuiPaper-root': {
			// borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				letterSpacing: '-0.5px',
				fontWeight: 700,
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "36px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				background: '#F3F3F3',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.813rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},

	studentList: {
		marginTop: "10px",
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555',
			marginLeft: "2px",
			marginRight: "10px",
		}
	},
	dialogInputN: {
		marginTop: "5px",
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			// height: "19px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
			'&:hover fieldset': {
				// borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-multiline': {
			padding: 0
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
			// height: "19px",
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	bgLeftBox: {
		// background: '#F3F3F3',
		background: 'rgb(250, 250, 250)',
		borderRight: '1px solid #cbcbcb'
	},
	exportBtn: {
		'&.MuiButtonBase-root': {
			background: '#cbcbcb',
			borderRadius: 3,
			width: "168px",
			height: "36px",
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#787878',
				fontWeight: 700,
				marginRight: "5px",
				width: "120px",
				borderRight: '1px solid #787878'
			}
		}
	},
	exportBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#008C73',
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#fff',
				fontWeight: 700,
				borderRight: '1px solid #fff'
			},
		}
	},
	projectEdit: {
		backgroundColor: 'blue',
		color: 'white'
	},
	zoomPageNum: {
		marginLeft: "25px",
		marginRight: "25px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-13px',
			top: 10,
		},
		'&:after': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			right: '-13px',
			top: 10,
		},
	},
	matching: {
		'&.MuiTypography-root': {
			marginLeft: "10px",
			fontSize: '1rem',
			color: '#9F9F9F',
		},
	},
	matchingActive: {
		'&.MuiTypography-root': {
			marginLeft: "10px",
			marginRight: "10px",
			fontSize: '1rem',
			color: '#008C73'
		},
	},
	dataFileName: {
		marginBottom: "15px",

	},
	dataFileText: {
		'& span': {
			fontSize: '0.875rem',
			color: '#555'
		}
	},
	essential: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 400,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			marginBottom: "10px",
		}

	},
	questionSentence: {
		'& p': {
			// fontSize: '1.063rem !important',
			letterSpacing: '-0.8px',
			fontWeight: '500 !important',
			lineHeight: 'normal',
			color: '#111'
		}
	},

	commonSubTitleN: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			'& span': {
				color: '#008C73'
			}
		}
	},
	scoreMarkInput: {
		padding: '20px 5px',
		whiteSpace: 'normal',
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			// padding: '9px 5px',
			padding: 0,
			height: '92px !important',
			// textAlign     : 'center',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}
	},
	scoreMarkInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "120px",
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#fff',
		}
	},
	sectionTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		// marginTop: "50px",
	},
	commonSubTitleBasicBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		// marginTop: "70px",
	},
	commonSubTitleBasic: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			'& span': {
				color: '#008C73'
			}
		}
	},
	basicTitle2: {

		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			fontWeight: 400,
			letterSpacing: '-0.4px',
			marginRight: "40px",
			minWidth: 160,
			boxSizing: 'border-box',
		}
	},
	basicTitle3: {

		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			fontWeight: 400,
			letterSpacing: '-0.4px',
			marginRight: "5px",
			// minWidth: 160,
			boxSizing: 'border-box',
		}
	},
	basicText2: {
		paddingRight: 15,
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 500,
			letterSpacing: '-0.4px',
			width: '100%'
		}
	},
	examEditBtn: {
		'&.MuiButtonBase-root': {
			width: "111px",
			height: "36px",
			borderRadius: 3,
			background: '#fff',
			border: '1px solid #cbcbcb',
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontSize: '1rem',
				fontWeight: 700,
				letterSpacing: '-0.5px'
			}
		},
		'&.Mui-disabled': {
			backgroundColor: '#cbcbcb',
			'& .MuiTypography-root': {
				color: '#fff !important',
			},
		}
	},
	iconBtnMoreMenuN: {
		'&.MuiIconButton-root': {
			border: '1px solid #cbcbcb',
			width: "36px",
			height: "36px",
			borderRadius: 2,
		}
	},
	commonSubTextN: {
		marginTop: "20px",
		marginBottom: "30px",
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			letterSpacing: '-0.5px',
			fontWeight: 400,
		}
	},
	taskTitleBoldN: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			fontWeight: 500,
			lineHeight: 'normal',
			width: 'auto',
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	scoreMark1: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#01261C',
			fontWeight: 700,
			letterSpacing: '-0.5px'
		}
	},
	printMenuBox: {
		marginTop: '45px',
		marginLeft: '5px',

		'& .MuiList-root': {
			width: "180px",
			padding: 10,
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#6f6f6f',
			letterSpacing: '-0.5px',
		},
		'& svg': {
			marginRight: "10px",
		}
	},
	previewRagSubText1: {
		// minWidth: '76px',
		'&.MuiTypography-root': {
			// color: '#555',
			fontSize: '1rem',
			// marginBottom: "10px",
		}
	},
	previewRagSubText2: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			// marginBottom: "10px",
		}
	},

	// kitty
	bgColor2: {},
	tableHeight: {},

	rubricBox: {},
	rubricTaskBox: {},
	standardBox: {},


	printTextBox: {},
});












