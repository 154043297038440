/// <reference types="web-bluetooth" />
import { sleep } from "../../../../nl-lib3-common";
import { PEN_CHARACTERISTICS_NOTIFICATION_UUID_128, PEN_CHARACTERISTICS_NOTIFICATION_UUID_16, PEN_CHARACTERISTICS_WRITE_UUID_128, PEN_CHARACTERISTICS_WRITE_UUID_16, PEN_SERVICE_UUID_128, PEN_SERVICE_UUID_16 } from "../../nl-lib-pensdk/pencomm/pencomm_const";
import { DEVICE_STATE } from "../bt-protocol/bluetooth-device-status-enum";
import { escapePenPacket } from "../bt-protocol/escape-unescape-packet";
import { NeoBtDeviceBase } from "../../../../nl-lib3-common/interfaces/neo-bt-device-base";




type GetServiceReturnType = {
  service: BluetoothRemoteGATTService,
  writeSocket: BluetoothRemoteGATTCharacteristic,
  notifyIndicate: BluetoothRemoteGATTCharacteristic,
};

type BT_UUID_DEFINITION = {
  service: string | number,
  write: string | number,
  noti: string | number,
};

const PEN_BT_UUID: { [key: string]: BT_UUID_DEFINITION } = {
  // for F30, and future devices
  "new": {
    service: PEN_SERVICE_UUID_128,
    write: PEN_CHARACTERISTICS_WRITE_UUID_128,
    noti: PEN_CHARACTERISTICS_NOTIFICATION_UUID_128,
  },

  // for F51, F50, F121, F120
  "old": {
    service: PEN_SERVICE_UUID_16,
    write: PEN_CHARACTERISTICS_WRITE_UUID_16,
    noti: PEN_CHARACTERISTICS_NOTIFICATION_UUID_16,
  }
}



const getBLEService = async (svcUuid: number | string, server: BluetoothRemoteGATTServer, timeout = 5000) => {
  const waitUnit = 50;

  let isError = false;
  let service = null as unknown as BluetoothRemoteGATTService;

  isError = false;
  const servicePromise = server.getPrimaryService(svcUuid);
  servicePromise.then((s) => {
    console.log(`          FOUND`);
    service = s;
  }).catch(() => {
    console.log(`          NOT FOUND ${svcUuid}`);
    isError = true;
  });

  // eslint-disable-next-line no-await-in-loop
  for (let i = 0, ln = Math.floor(timeout / waitUnit); i < ln && (!service) && (!isError); i++) { await sleep(waitUnit); }
  if (!service) { throw new Error(`finding the service timed out ${svcUuid}`); }
  if (isError) { throw new Error(`the service not found ${svcUuid}`); }

  return service;
}

const getBLECharacteristic = async (charUuid: number | string, service: BluetoothRemoteGATTService, timeout = 5000) => {
  const waitUnit = 50;

  let isError = false;
  let characteristic = null as unknown as BluetoothRemoteGATTCharacteristic;
  const charPromise = service.getCharacteristic(charUuid);
  charPromise.then((c) => {
    console.log(`          FOUND`);
    characteristic = c;
  }).catch(() => {
    console.log(`          NOT FOUND ${charUuid}`);
    isError = true;
  });

  // eslint-disable-next-line no-await-in-loop
  for (let i = 0, ln = Math.floor(timeout / waitUnit); i < ln && (!characteristic) && (!isError); i++) { await sleep(waitUnit); }
  if (!characteristic) { throw new Error(`finding the characteristic timeout ${charUuid}`); }
  if (isError) { throw new Error(`finding the characteristic timeout ${charUuid}`); }

  return characteristic;
}



/**
 *
 * @param uuid
 * @param server
 */
async function getServiceProcess(uuid: BT_UUID_DEFINITION, server: BluetoothRemoteGATTServer): Promise<GetServiceReturnType> {
  console.log(`  2-1. getting the primary service,             uuid:${uuid.service}, connected:${server.connected}`);
  const service = await getBLEService(uuid.service, server, 5000);

  console.log(`  2-2. getting the write characteristic,        uuid:${uuid.write}, connected:${server.connected}`);
  const writeSocket = await getBLECharacteristic(uuid.write, service, 5000);

  console.log(`  2-3. getting the notification characteristic, uuid:${uuid.noti}, connected:${server.connected}`);
  const notifyIndicate = await getBLECharacteristic(uuid.noti, service, 5000);
  return { service, writeSocket, notifyIndicate };
}

async function getService(server?: BluetoothRemoteGATTServer): Promise<GetServiceReturnType> {
  if (!server) {
    throw new Error("No server given");
  }

  let uuids = [PEN_BT_UUID.old, PEN_BT_UUID.new];
  if (server.device.name === "Smartpen dimo_d") {
    uuids = [PEN_BT_UUID.new, PEN_BT_UUID.old];
  }

  try {
    const result = await getServiceProcess(uuids[0], server);
    return result;
  } catch (e) {
    try {
      console.log(`  ==> retry`);
      const result = await getServiceProcess(uuids[1], server);
      return result;
    } catch (e2) {
      console.log(`  ==> finally failed`);
      throw e2;
    }
  }
}


export class NeoBTDeviceWeb extends NeoBtDeviceBase {
  protected _name = "web_bluetooth";

  private _btDevice: BluetoothDevice | null = null;

  private _btServer: BluetoothRemoteGATTServer | null | undefined = null;

  private _btWriteSocket: BluetoothRemoteGATTCharacteristic | null = null;

  private _btNotifyIndicate: BluetoothRemoteGATTCharacteristic | null = null;


  /**
   *
   * @param server
   */


  /**
   *
   * @param btDevice
   * @param protocolStartCallback
   */
  async connectWebBluetooth(btDevice: BluetoothDevice, protocolStartCallback: () => void): Promise<{ status: string, success: boolean }> {
    if (!btDevice) {
      return {
        status: "device not given",
        success: false
      };
    }

    this._btId = btDevice.id;

    if (this._state !== DEVICE_STATE.disconnected) {
      return {
        status: "already connected",
        success: false
      }
    }

    this._connected = false;
    this._inConnecting = true;

    let server: BluetoothRemoteGATTServer | null | undefined = null;
    this._btServer = server;

    // step 1, physically connection
    try {
      console.log("1. Connecting to GATT Server.");
      btDevice.addEventListener("gattserverdisconnected", this.onDeviceDisconnected);
      server = await btDevice.gatt?.connect();
      this._btServer = server;
    } catch (e: any) {
      btDevice.removeEventListener("gattserverdisconnected", this.onDeviceDisconnected);

      let status = "cannot connect GATT server on the pen";
      const isBTdevice = e.message.indexOf("adapter");
      if (isBTdevice > -1) status = "Bluetooth LE dongle is not found";

      return {
        status,
        success: false
      };
    }


    // step 2
    let writeSocket: BluetoothRemoteGATTCharacteristic;
    let notifyIndicate: BluetoothRemoteGATTCharacteristic;

    try {
      console.log(`2. get Service and Sockets, connected:${server?.connected}`);
      const ret = await getService(server);
      writeSocket = ret.writeSocket;
      notifyIndicate = ret.notifyIndicate;

      console.log("3. Add Event listeners");
      if (notifyIndicate) {
        notifyIndicate.addEventListener("characteristicvaluechanged", this.onPenPacketReceived as any);
      } else {
        return {
          status: "cannot listen eventes on notify indicator",
          success: false
        };
      }
    } catch (e) {
      return {
        status: "cannot find services on pen",
        success: false
      };
    }

    try {
      console.log("4. Start getting notifications");
      await notifyIndicate.startNotifications();
    } catch (e: any) {
      let status = "cannot start getting notifications";
      const isBTdevice = e.message.indexOf("adapter");
      if (isBTdevice > -1) status = "Bluetooth LE dongle is not found";

      return {
        status,
        success: false,
      };
    }

    this._btDevice = btDevice;
    this._btWriteSocket = writeSocket;
    this._btNotifyIndicate = notifyIndicate;
    console.log("BLE Connected.");

    this._connected = true;
    this._inConnecting = false;

    protocolStartCallback();
    // this.protocolHandler.onPhysicallyConnected();
    return {
      status: "connnection initiated",
      success: true,
    };
  }


  /**
   * disconnect function:
   */
  async disconnect() {
    console.log(`     DISCONNECT operation`);
    this._inDisconnecting = true;
    if (!this._connected || this._btDevice === null) {
      return;
    }

    if (this._btDevice !== null) {
      this._btDevice.gatt?.disconnect();
    }
  }

  /**
   *
   */
  // eslint-disable-next-line class-methods-use-this
  preDisconnected(): void {
    throw new Error("Not implemented: preDisconnected");
  }

  /**
   *
   */
  onDeviceDisconnected = () => {
    this._inDisconnecting = false;
    this._btDevice = null;
    this._btServer = null;

    this._btWriteSocket = null;
    this._btNotifyIndicate = null;

    super.onDeviceDisconnected();
  }

  /**
   *
   * @param unescaped
   */

  // async writeArray(arr: number[]) {
  //   const len = arr.length;

  //   const bf = escapePenPacket(arr.slice(1, len - 1));
  //   bf.unshift(arr[0]);
  //   bf.push(arr[len - 1]);
  //   const escaped = new Uint8Array(bf);

  //   let reqSuccess = false;
  //   let reqRestCnt = 6;
  //   while (!reqSuccess && reqRestCnt > 0) {
  //     try {
  //       // eslint-disable-next-line no-await-in-loop
  //       await this._btWriteSocket?.writeValue(escaped);
  //       reqSuccess = true;
  //     } catch (e) {
  //       // eslint-disable-next-line no-await-in-loop
  //       console.error("-------------------------------");
  //       console.error(e);
  //       console.error("-------------------------------");
  //       await sleep(50);
  //       reqRestCnt--;
  //     }
  //   }

  //   return reqSuccess;
  // }

  async writeArray(arr: number[]) {
		if ( !this._connected ) return false;

    const MTU_SIZE = 512;
    // transmission data size is mtu - 3 (opcode 1byte + attribute handle 2byte)
    const CHUNK_SIZE = MTU_SIZE - 3;
    const len = arr.length;

    const bf = escapePenPacket(arr.slice(1, len - 1));
    bf.unshift(arr[0]);
    bf.push(arr[len - 1]);
    const data = new Uint8Array(bf);

    // chunk 없이 전송 시 MTU size 를 넘어갈 경우 다음과 같은 에러 메시지가 반환된다.
    // Failed to execute 'writeValue' on 'BluetoothRemoteGATTCharacteristic': Value can't exceed 512 bytes.
    let startIndex = 0;
    let endIndex = 0;

    let reqSuccess = false;

    while (startIndex < data.length) {
      endIndex = Math.min(startIndex + CHUNK_SIZE, data.length);
      const chunk = data.slice(startIndex, endIndex);

      let reqRestCnt = 6;
      while (!reqSuccess && reqRestCnt > 0) {
        try {
          if (data.length < CHUNK_SIZE) {
            await this._btWriteSocket?.writeValue(chunk);
          } else {
            await this._btWriteSocket?.writeValueWithoutResponse(chunk);
          }
          reqSuccess = true;
          startIndex = endIndex;
        } catch (e) {
          console.error(`BLE WRITE ERROR: ${e}`);
          await sleep(50);
          reqRestCnt--;
        }
      }

      if (!reqSuccess) {
        return false;
      }

      reqSuccess = false;
    }

    return true;
  }

  async write(buf: Uint8Array) {
    const arr = Array.from(buf);
    const ret = await this.writeArray(arr);
    return ret;
  }
}


