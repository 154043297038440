// TODO - Port fitz geometry methods
import { assert } from "./functions";
import { FzRect } from "./FzRect";

// See platform/java/src/com/artifex/mupdf/fitz/FzMatrix.java
export class FzMatrix {
  a: number;

  b: number;

  c: number;

  d: number;

  e: number;

  f: number;


  constructor(a: number, b: number, c: number, d: number, e: number, f: number) {
    this.a = a;
    this.b = b;
    this.c = c;
    this.d = d;
    this.e = e;
    this.f = f;
  }

  static identity = new FzMatrix(1, 0, 0, 1, 0, 0);

  static from(value) {
    if (value instanceof FzMatrix)
      return value;
    if (Array.isArray(value) && value.length === 6)
      return new FzMatrix(value[0], value[1], value[2], value[3], value[4], value[5]);
    throw new Error(`cannot create matrix from '${value}'`);
  }

  static fromPtr(libmupdf: any, ptr) {
    ptr >>= 2;
    return new FzMatrix(
      libmupdf.HEAPF32[ptr],
      libmupdf.HEAPF32[ptr + 1],
      libmupdf.HEAPF32[ptr + 2],
      libmupdf.HEAPF32[ptr + 3],
      libmupdf.HEAPF32[ptr + 4],
      libmupdf.HEAPF32[ptr + 5],
    );
  }

  static scale(libmupdf: any, scale_x, scale_y) {
    return FzMatrix.fromPtr(libmupdf, libmupdf._wasm_scale(0, scale_x, scale_y));
  }

  transformRect(libmupdf: any, rect) {
    assert(rect instanceof FzRect, "invalid rect argument");
    return FzRect.fromFloatRectPtr(libmupdf,
      libmupdf._wasm_transform_rect(0,
        rect.x0, rect.y0, rect.x1, rect.y1,
        this.a, this.b, this.c, this.d, this.e, this.f,
      ));
  }
}
