import pako from 'pako';

export async function neoZip(data: Uint8Array): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    try {
      const compressedData = pako.deflate(data);
      resolve(compressedData);
    } catch (e) {
      reject(e);
    }
  });
}

export async function neoUnzip(zipped: Uint8Array): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    try {
      const output = pako.inflate(zipped);
      resolve(output);
    } catch (e) {
      reject(e);
    }
  });
}

export async function neoGunzip(gzipped: Uint8Array): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    try {
      const output = pako.ungzip(gzipped);
      resolve(output);
    } catch (e) {
      reject(e);
    }
  });
}

// import * as Zlib from "zlib";
// export async function neoUnzip(zipped: Uint8Array): Promise<Uint8Array> {
//   return new Promise((resolve, reject) => {
//     try {
//       // new (Zlib.unzip as any)(
//       Zlib.unzip(
//         zipped,
//         (err: any, decompressed: Uint8Array) => {
//           if (err) {
//             console.log(err);
//             reject(new Error("unzip failed after decompressing"));
//           } else {
//             const ret = new Uint8Array(decompressed);
//             resolve(ret);
//           }
//         }
//       );
//     } catch (e) {
//       reject(new Error("unzip failed"));
//     }
//   });
// }

// export async function neoGunzip(gzipped: Uint8Array): Promise<Uint8Array> {
//   // for (let i = 0; i < gzipped.length; i += 16) {
//   //   const rowLen = gzipped.length - i >= 16 ? 16 : gzipped.length - i;
//   //   let str = "";
//   //   for (let j = 0; j < rowLen; j++) {
//   //     const ch = gzipped[i + j];
//   //     let hex = "000" + ch.toString(16);
//   //     hex = hex.slice(hex.length - 2);

//   //     str = str + hex + " ";
//   //   }
//   //   let addr = "000000" + i.toString(16);
//   //   addr = addr.slice(addr.length - 4);
//   //   console.log(`${addr}: ${str}`);
//   // }

//   return new Promise((resolve, reject) => {
//     try {
//       // new (Zlib.gunzip as any)(
//       Zlib.gunzip(
//         gzipped,
//         (err, decompressed) => {
//           if (err) {
//             console.log(err);
//             reject(new Error("gunzip failed after decompressing"));
//           } else {
//             const ret = new Uint8Array(decompressed);
//             resolve(ret);
//           }
//         }
//       );
//     } catch (e) {
//       reject(new Error("gunzip failed"));
//     }
//   });
// }
