import { Box, Button, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { ChangeFlag } from "../../repositories/model/support/ChangeFlag";
import { ProjectExamType } from "../../repositories/model/support/ProjectExamType";
import PrintTestPaper from "./21_AssignAssessment/5_PrintWorksheets/PrintTestPaper";
import PrintAnswerSheet from "./PrintAnswerSheet";

type Props = {
}

function PrintAll(props: Props) {
	const params = useParams();
	const { navigateStore, projectStore, projectExamStore } = useStores();

	const [printAnswer, setPrintAnswer] = React.useState(false);
	const [printTest, setPrintTest] = React.useState(true);
	const [printBoth, setPrintBoth] = React.useState(false);
	const [printTrigger, setPrintTrigger] = React.useState(false);
	const [init, setInit] = React.useState(false);
	const [renderList, setRenderList] = React.useState([]);
	const { projectCode } = params;
	const { projectExamList } = projectExamStore;
	const { project } = projectStore;
	const i18next = useTranslation();

	React.useEffect(() => {
		navigateStore.changeTopBarLeftItem(topBarLeftItem);
		navigateStore.changeTopBarRightItem(topBarRightItem);
		return () => {
			navigateStore.changeTopBarLeftItem(null);
			navigateStore.changeTopBarRightItem(null);
		}
	}, [])

	React.useEffect(() => {
		projectStore.downloadProjectInfoAndStore(projectCode);//기본정보 %필수정보
		projectExamStore.downloadProjectExamListAndStore(projectCode);// 문항 생성 %필수 정보
	}, [])

	React.useEffect(() => {
		if (init) {
			window.print();
		} else {
			setInit(true);
		}
	}, [printTrigger])

	React.useEffect(() => {
		let i = 0;
		let tempArr = [];
		projectExamList.filter(e => e.changeFlag !== ChangeFlag.DELETED)
			.forEach((exam, index) => {
				exam.questions.filter(q => q.changeFlag !== ChangeFlag.DELETED)
					.forEach((question) => {
						if (exam.type === ProjectExamType.DESCRIPTIVE) {
							if (i === 2) {
								i = 1;
								tempArr.push(true);
							} else {
								i++;
								tempArr.push(false);
							}
						} else {
							i = 2;
							tempArr.push(true);
						}
					})
			});
		tempArr[0] = false;
		setRenderList(tempArr);
	}, [projectExamList])

	const topBarLeftItem = () => {
		return (
			<>
				<Typography style={{ paddingLeft: 20, fontWeight: 'bold' }}> {projectStore.project.name}</Typography>
				<Typography >{i18next.t("서•논술형 평가")}</Typography>
			</>
		)
	}

	const topBarRightItem = () => {
		return (
			<Box style={{ alignItems: "right" }}>
				<Button style={{ border: '1px solid rgb(3,123,108)', color: 'rgb(3,123,108)', marginRight: '20px' }}
					onClick={handleClickTestPrint}
				>
					<Typography>{i18next.t("문제 프린트 하기")}</Typography>
				</Button>
				<Button style={{ border: '1px solid rgb(3,123,108)', color: 'rgb(3,123,108)', marginRight: '20px' }}
					onClick={handleClickAnswerPrint}
				>
					<Typography>{i18next.t("답안지 프린트 하기")}</Typography>
				</Button>
				<Button style={{ border: '1px solid rgb(3,123,108)', color: 'rgb(3,123,108)', marginRight: '20px' }}
					onClick={handleClickBothPrint}
				>
					<Typography>{i18next.t("전부 프린트 하기")}</Typography>
				</Button>
			</Box>
		)
	}

	const changePrintOption = (printOption) => {
		switch (printOption) {
			case "test":
				setPrintTest(true);
				setPrintAnswer(false);
				setPrintBoth(false);
				break;
			case "answer":
				setPrintTest(false);
				setPrintAnswer(true);
				setPrintBoth(false);
				break;
			case "both":
				setPrintTest(false);
				setPrintAnswer(false);
				setPrintBoth(true);
				break;
		}
		setPrintTrigger(prevState => !prevState);
	}

	const handleClickTestPrint = () => {
		changePrintOption("test");
	}
	const handleClickAnswerPrint = () => {
		changePrintOption("answer");
	}
	const handleClickBothPrint = () => {
		changePrintOption("both");
	}

	return (
		<div>
			{(printTest || printBoth) && <PrintTestPaper />}
			{printBoth && <div style={{ breakAfter: 'page' }} />}
			{(printAnswer || printBoth) && <PrintAnswerSheet project={project} projectExamList={projectExamList} renderList={renderList} />}
		</div>
	);
}

export default observer<typeof PrintAll>(PrintAll);
