import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const MarkFeedbackStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
		overflowY: 'hidden',
	},
	// emptyBox:{
	//     width: '100%',
	//     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
	//     display:'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center',
	//     '& p':{
	//         fontSize: '1.375rem',
	//         color: '#87888d',
	//         fontWeight:400,
	//         textAlign: 'center',
	//         lineHeight: '30.36px'
	//     }
	// },
	// boxWrap: {
	//     backgroundColor: '#fff',
	//     padding: 20,
	//     borderRadius: 8,
	//     height: 'calc(100vh - 500px)'
	// },

	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			// marginLeft: "10px",
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},
	dialogBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff'
		}
	},
	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
			backgroundColor: "#fff",
			display: "flex",
			alignItems: "center",
			justifyContent: "end",
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},





	/////////////////////
	padding: {
		// padding: 15,
	},
	mainContentBox: {
		// border: '1px solid #CBCBCB',
		// padding: 20,
		// height: 'calc(100vh - 90px)',
		// overflowY: 'auto',
		// background: '#fff',
		background: '#E5E5E9',
		// margin: '0px 15px',
		// overflowX: 'auto'
	},
	newTabContent: {
		padding: '20px 20px 20px 20px',
		background: '#E5E5E9',
		height: 'calc(100vh - 96px)',
		overflowX: 'auto',
		overflowY: 'hidden',
	},
	mainContentBoxLeft: {
		border: '1px solid #CBCBCB',
		background: '#fff',
		paddingTop: 45,
		paddingLeft: 20,
		paddingRight: 20,
		height: 'calc(100vh - 182px)',
		// width: 'calc(100vw - 765px)',
		width: 'calc((100vw - 145px) * 55 / 100)',
		// height: 'calc(100vh - 130px)',
		// width: 'calc(100vw - 715px)',
		// minWidth: '930px',
	},
	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		overflowY: 'hidden',
		background: '#fff',
		// width: '620px',
		width: 'calc((100vw - 145px) *  45/ 100)',

		boxSizing: 'border-box',
		marginLeft: "10px",
		minWidth: '620px',
	},
	mainContentBoxHeader: {
		display: "flex"
	},
	nextStudentBtnBox: {
		display: 'flex',
		alignItems: 'center'
	},
	mainContentBoxHeaderButton: {
		width: '24px',
		height: '48px',
		display: "flex",
		alignItems: "center",
		cursor: 'default',
		'&:hover': {
			background: "none"
		},
	},
	disabledMainContentBoxHeaderButton: {
		opacity: 0.4,
	},
	mainContentBoxHeaderButtonIcon: {
		// color: "#cbcbcb",
		color: "#6d6d6d",
		transform: "scaleY(2)",
		cursor: "pointer",
		'&:hover': {
			color: "#000",
		},
	},
	disabledMainContentBoxHeaderButtonIcon: {
		// color: "#cbcbcb",
		color: "#c2c1c1",
		transform: "scaleY(2)",
		cursor: 'default',
	},




	commonSubTitle: {
		marginBottom: "10px",
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
		}
	},
	schoolNameSubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			letterSpacing: '-0.5px',
			marginBottom: "15px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	searchSchoolInput: {
		'& .MuiFormControl-root': {
			maxWidth: 83,
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 50
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},




	greenBtn: {
		marginLeft: "15px",
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008c73',
				fontWeight: 'bold'
			}
		}
	},
	selectText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 'bold',
			marginRight: "15px",
		}
	},
	ownerFeedbackBoxMarginTop: {
		marginTop: "30px"
	},
	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			// padding: '14px 25px 15px 12px !important',
			padding: '14px 0px 15px 3px !important',
			// fontSize: '1.125rem',
			fontSize: '0.875rem',
			boxSizing: 'border-box',
			color: '#777',
			letterSpacing: '-0.5px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #87888d',
			borderRadius: '6px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},
	formControlWidth: {
		textAlign: 'center',
		'&.MuiFormControl-root': {
			width: "70px",
			marginRight: "0px",
		},
		'& .MuiSelect-select': {
			width: "80px",
		}
	},
	rubricItemNameText: {
		color: '#111 !important'
	},
	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: '#fff',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
			marginTop: "3px",
		}
	},
	pageNumber: {
		marginTop: '10px',
		marginBottom: "10px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentName: {
		'&.MuiTypography-root': {
			color: '#008C73'
		}
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},

	dialogInput: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "440px",
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "460px",
			marginBottom: "20px",
		},
	},
	stepInputBox: {
		'& .MuiIconButton-root': {
			margin: '20px 10px 5px 40px'
		}
	},

	inputStep: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 36
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
			letterSpacing: '-0.5px'
		}
	},
	inputStepMulti: {
		'& .MuiOutlinedInput-root': {
			height: "60px",
		},
		'& .MuiOutlinedInput-input': {
			height: "38px",
		}
	},
	stepText: {
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			color: '#2F80ED',
			marginRight: "20px",
		}
	},

	addSentence: {
		'& .MuiButton-label': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: 'transparent'
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontSize: '0.875rem',
				marginTop: "2px",
			}
		}
	},

	boxList: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FAFAFA',
		borderRadius: 6,
		width: '100%',
		height: "200px",
		margin: '0px 5px',
		padding: 10,
		'& .MuiTypography-root': {
			color: '#6F6F6F',
			fontSize: '0.75rem',
			minHeight: 93,
		},
		'&:hover': {
			outline: '2px solid #56CCF2'
		}
	},
	boxListTitle: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 700,
			marginTop: "5px",
			marginBottom: "5px",
			minHeight: 'auto'
		}
	},
	fileUploadBox: {
		width: '50%',
		background: '#fff',
		height: 'calc(100vh - 106px)',
		border: '1px solid #CBCBCB'
	},
	boardSize: {
		position: 'absolute',
		right: 10,
		bottom: 10,
		display: 'flex',
		flexDirection: 'column'
	},
	fileUploadTitle: {
		marginTop: '-18px',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 500,
			marginBottom: "20px",
		}
	},
	fileUploadText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	fileUploadBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			width: "170px",
			height: "36px",
			borderRadius: 3,
			border: '1px solid #CBCBCB',
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	fileUploadSubText: {
		margin: '15px 0px'
	},
	urlInputBox: {
		'&.MuiPaper-root': {
			width: "600px",
			height: "35px",
			border: '1px solid #CBCBCB',
			marginBottom: "15px",
		},
		'& .MuiInputBase-root': {
			width: "480px",
		},
		'& .MuiInputBase-input': {
			padding: '8px 10px 7px',
		}
	},
	urlBtn: {
		marginLeft: "10px",
		'&.MuiButtonBase-root': {
			width: "82px",
			height: "27px",
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				letterSpacing: '-0.5px',
			}
		}
	},
	urlBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#fff'
			}
		}
	},

	summaryAiBtn: {
		'&.MuiButtonBase-root': {
			background: '#CDCDCD',
			width: '100%',
			marginBottom: "20px",
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold',
			}
		}
	},
	summaryAiBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				color: '#fff',
			}
		}
	},
	aiSubText: {
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
		}
	},
	sideList: {
		position: 'absolute',
		left: 0,
		top: 0,
		background: '#4F4F4F',
		width: "114px",
		height: 'calc(100vh - 190px)',
		// height: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	sideListInner: {
		overflowY: 'auto',
		padding: '10px 7px',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	img: {
		position: 'relative',
		'& img': {
			width: '100%',
			borderRadius: '5px',
			'&:hover': {
				outline: '2px solid #03BF69'
			},
		},

	},
	examInfoIcon: {
		marginLeft: "10px",
		marginBottom: "13px",
	},
	pageNum: {
		position: 'absolute',
		bottom: 5,
		background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.6), #fff)',
		width: '100%',
		borderRadius: '0 0 5px 5px',
		'& .MuiTypography-root': {
			padding: '4px 5px',
			textAlign: 'right'
		}
	},
	newTabHeader: {
		borderBottom: '1px solid #cbcbcb',
		padding: 20,
	},
	newTabTitle: {
		'&.MuiTypography-root': {
			color: '#555',
			marginRight: "14px",

		}
	},
	newTabHeaderTitle: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.125rem',
			letterSpacing: '-1px',
			fontWeight: 700,
			marginLeft: "30px",
		}
	},
	newTabName: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.125rem',
			letterSpacing: '-1px'
		}
	},
	newTabTitlePointer: {
		marginLeft: "25px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-13px',
			top: 10,
		},
	},
	newTabSubject: {
		'&.MuiTypography-root': {
			fontWeight: 600
		}
	},
	newTabPage: {
		'&.MuiTypography-root': {
			color: '#008C73',
			fontWeight: 600,
		}
	},
	compareTop: {
		padding: '40px 0px 20px 0px',
		// padding: '20px 10px 20px 10px',
		'& > .MuiTypography-root': {
			color: '#111',
			fontWeight: 700,
			fontSize: '1.25rem'
		}
	},
	compareTopColor: {
		'& > .MuiTypography-root': {
			color: '#008C73',
		}
	},
	compareContent: {
		// padding: 20,
		height: 'calc(100vh - 420px)',
		overflowY: 'auto',

	},
	compareRound: {
		border: '1px solid #cbcbcb',
		borderRadius: 10,
		padding: 20,
		background: 'rgba(233, 243, 252, 0.10)',
		// height: 'calc(100vh - 378px)',
		height: 'calc(100vh - 510px)',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			// background: '#6f6f6f',
			background: 'rgba(0, 0, 0, 0.15)',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	editerBox: {
		background: '#f3f3f3',
		padding: 20,
		borderRadius: 10,
		border: '1px solid #cbcbcb',
		marginBottom: "40px",
	},
	editerBoxBtn: {
		display: 'flex',
		justifyContent: 'right',
		'& .MuiButtonBase-root': {
			width: "114px",
			height: "37px",
			border: '1px solid #cbcbcb',
			background: '#fff'
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#2F80ED',
			fontWeight: 700,
		}
	},
	textFieldBox: {
		background: '#fff',
		padding: 10,
		marginBottom: "20px",
	},
	scanToggleBtn: {
		marginBottom: "10px",
		'& .MuiTypography-root': {
			fontSize: '1rem',
			color: '#008C73',
			fontWeight: 600,
			marginRight: "5px",
		}
	},
	questionList: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			fontWeight: 700,
		}
	},
	questionGuideText: {
		border: '1px solid #111',
		padding: '14px 18px',
		borderRadius: 3,
		marginTop: "10px",
		'& .MuiTypography-root': {
			fontSize: '1.25rem',
			letterSpacing: '-0.4px'
		}
	},
	// titleBox: {
	//     background: '#cbcbcb',
	//     fontWeight: 700,
	//     fontSize: '1.125rem'
	// },
	titleBox: {
		background: '#F3F3F3',
		'&.MuiTableCell-root': {
			fontWeight: 700,
		}
	},
	tableContentBox: {
		padding: '0px 15px !important',
		'&.MuiTableCell-root': {

		}
	},
	contentTooltip: {
		'&.MuiTooltip-tooltip': {
			backgroundColor: '#F4D219',
			boxShadow: '0px 1px 1px -1px rgba(255,255,0,0.1)',
			border: '0.5px solid #F3A50E',
			borderRadius: 0,
			marginTop: -5,
			color: '#111',
			fontSize: '0.75rem',
			marginLeft: '15px'
		}
	},
	tooltipMarginLeft: {
		'&.MuiTooltip-tooltip': {
			marginLeft: 0
		}
	},
	tableContentBoxStudentName: {
		color: '#008C73 !important',
		fontWeight: 700,
	},
	compareTopBtn: {
		'&.MuiButtonBase-root': {
			width: "145px",
			height: "36px",
			border: '1px solid #111',
			background: '#fff',
			marginLeft: "20px",
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#111',
				fontWeight: 700,
				letterSpacing: '-0.5px'
			}
		}
	},
	compareTopBtnBg: {
		'&.MuiButtonBase-root': {
			background: '#111',
			'& .MuiTypography-root': {
				color: '#fff'
			}
		}
	},
	questionInputBox: {
		'& .MuiFormControl-root': {
			width: '100%',
			height: "72px",
			boxSizing: 'border-box',
			background: '#fff',
			marginBottom: "10px",
			marginTop: "15px",
		},
		'& .MuiOutlinedInput-root': {
			height: "72px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		},
		'& .MuiInputBase-input': {
			fontSize: '1.25rem',
			color: '#787878',
			letterSpacing: '-0.7px',
		}
	},
	numberBox: {
		width: "51px",
		height: "34px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 99,
		background: '#f3f3f3',
		boxSizing: 'border-box',
		marginRight: "11px",
		'& .MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#777',
			fontWeight: 'bold',
			'& span': {
				color: '#38cb89',
				fontWeight: 'bold'
			}
		}
	},
	progressBox: {
		maxWidth: 440,
		width: '100%',
		'& .MuiLinearProgress-root': {
			height: "16px",
			background: '#eee',
			borderRadius: 99,
		},
		'& .MuiLinearProgress-bar': {
			background: '#38cb89'
		}
	},
	progressText: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#111',
			marginLeft: "11px",
			'@media all and (max-width: 1550px)': {
				fontSize: '0.75rem',
			},
		}
	},

	bestAnswerList: {
		marginTop: "20px",
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			letterSpacing: '-1px',

		}
	},
	bestAnswerBox: {
		border: '2px solid #111',
		borderRadius: 3,
		// height: "68px",
		padding: 0,
		marginTop: "14px",
		'& .MuiTypography-root': {
			padding: '20px 30px',
			fontSize: '1.25rem',
			letterSpacing: '-0.4px'
		}
	},
	tableBox: {
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				letterSpacing: '-0.5px',
				fontWeight: 700,
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "44px",
				background: '#F3F3F3',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555',
			letterSpacing: '-0.5px',
		},
	},
	rubricTable: {
		marginBottom: '30px'
	},
	infoTableBox: {

		// width: '99%',
		width: "90%",
		marginRight: 'auto',
		marginLeft: 'auto',
		// marginTop: "30px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1rem',
				color: '#323232',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '1rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},
	bgColor: {
		height: '140px !important',
		// background: '#DFDFDF',
		background: 'rgb(250, 250, 250)',
		borderRight: '1px solid #cbcbcb',
		borderLeft: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},

	rubricCell: {
		// height: '140px !important',
		// background: '#DFDFDF',
		background: 'rgb(250, 250, 250)',
		borderRight: '1px solid #cbcbcb',
		borderLeft: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},


	scoreFormCell: {
		padding: '0 !important'
	},
	markText: {
		'&.MuiTypography-root': {
			fontWeight: 400,
			fontSize: '0.875rem',
			textAlign: 'center',
			'& span': {
				fontSize: '1rem',
				fontWeight: 700,
				marginRight: "3px",
			}
		}
	},
	sortButton: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		'& p': {
			fontSize: '0.875rem',
			color: '#555',
			marginRight: "4px",
			letterSpacing: '-0.5px',
			fontWeight: 700,
		}
	},
	dialogYear: {
		position: 'relative',
		marginRight: "5px",
		'& .MuiFormControl-root': {
			width: '64px',
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 64
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},
	yearSelectIcon: {
		position: 'absolute',
		right: 0,
		top: 5,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	iconYearSelect: {
		'&.MuiIconButton-root': {
			margin: '4px 5px',
		}
	},
	tableText: {
		// height: "120px",
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#323232',
			letterSpacing: '-0.5px',
			padding: '0 5px',
			// textOverflow:'ellipsis',
			whiteSpace: 'break-spaces',
			overflow: 'auto',
			// textOverflow:'ellipsis',
		}
	},
	aiMarkBasis: {
		// background: '#008C73',
		background: '#00b897',
		padding: '10px 20px',
		position: 'relative',
		cursor: 'pointer',
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '0.875rem',
			fontWeight: 700,
		}
	},
	disabledAiMarkBasis: {
		background: '#cbcbcb',
		padding: '10px 20px',
		position: 'relative',
		cursor: 'default',
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '0.875rem',
			fontWeight: 700,
		}
	},
	onwerFeedbackBox: {
		// background: '#008C73',
		background: '#525252',
		padding: '10px 20px',
		position: 'relative',
		cursor: 'pointer',
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '0.875rem',
			fontWeight: 700,
		}
	},
	emptyOnwerFeedbackBox: {
		// background: '#008C73',
		background: '#8a8a8a',
		padding: '10px 20px',
		position: 'relative',
		cursor: 'pointer',
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '0.875rem',
			fontWeight: 700,
		}
	},
	disabledOnwerFeedbackBasis: {
		background: '#cbcbcb',
		padding: '10px 20px',
		position: 'relative',
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '0.875rem',
			fontWeight: 700,
		}
	},
	aiMarkBasisFeedback: {
		background: '#6f6f6f',
		padding: '10px 20px',
		position: 'relative',
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '0.875rem',
			fontWeight: 700,
		}
	},
	aiMarkTextBoxSelf: {
		border: '1px solid #cbcbcb',
		padding: 15,
		// marginBottom: "20px",
		'& .MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 500,
			color: '#111',
		}
	},

	'@keyframes testAnimation': {
		from: { opacity: 0 },
		to: { opacity: 1 },
	},

	skeletonUI: {
		width: '500px',
		height: '50px',
		// backgroundColor: 'rgba(24, 24, 29, 0.7)',
		background: `linear-gradient(90deg, #f5f5f5, #969696, #f5f5f5)`,
		backgroundSize: `200% 100% 200% 100%`,
		// animation: '$skeletonAnimation 1.5s linear infinite', // 참조
		animation: '$testAnimation 1s linear infinite',
	},

	ownerFeedbackEditer: {
		minHeight: 360,
		'& .toastui-editor-contents': {
			padding: '25px'
		}
	},
	// ownerFeedbackBox: {
	//   marginTop: '30px'
	// },
	ownerMarkFeedbackBtn: {
		'&.MuiButtonBase-root': {
			border: '1px dashed #111',
			textAlign: 'center',
			width: '100%',
			minWidth: '100%',
			'&:hover': {
				background: '#fff'
			},
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#111',
				letterSpacing: '-0.5px',
				marginLeft: "5px",
			}
		}
	},
	disabledAiMarkFeedback: {
		'&.MuiButtonBase-root': {
			border: '1px dashed #111',
			textAlign: 'center',
			width: '100%',
			minWidth: '100%',
			background: '#cbcbcb',
			'&:hover': {
				background: '#fff'
			},
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#fff',
				letterSpacing: '-0.5px',
				marginLeft: "5px",
			}
		}
	},
	taskNumber: {
		border: '1px solid #008C73',
		borderRadius: 3,
		background: '#DEF8F3',
		width: "88px",
		height: "40px",
		boxSizing: 'border-box',
		padding: '4px 15px',
		textAlign: 'center',
		'& .MuiTypography-root': {
			fontSize: '1.25rem'
		}
	},
	markBigScore: {
		'&.MuiTypography-root': {
			color: '#787878',
			fontSize: '1.25rem',
			fontWeight: 700,
			marginLeft: "24px",
			'& span': {
				fontSize: '3.375rem',
				color: '#008C73',
				fontWeight: 700,
				marginRight: "5px",
			}
		}
	},
	amountScoreTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
		}
	},
	amountScoreSubTitle: {
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			fontWeight: 500,
			color: '#016FF2',
		}
	},
	amountScore: {
		borderBottom: '1px solid #cbcbcb',
		// width: "270px",
		marginBottom: "22px",
		padding: '0 10px',
		'& .MuiTypography-root': {
			fontSize: '3.375rem',
			fontWeight: 500,
			color: '#787878',
			'& span': {
				color: '#008C73',
				fontWeight: 700,
			},
		},
	},
	amountScoreText: {
		fontSize: '1.25rem',
		color: '#787878 !important',
		marginLeft: "5px",
	},
	popoverTooltip: {
		'& .MuiPopover-paper': {
			background: '#2078E8',
			color: '#fff',
			minWidth: 180,
			minHeight: 30,
			maxWidth: 314,
			padding: '10px 10px 10px 15px',
			overflow: 'visible',
			marginTop: "15px",
			boxSizing: 'border-box'
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '15px',
			lineHeight: '21px',
			letterSpacing: '-0.5px',
		}
	},
	tooltipTop: {
		position: 'absolute',
		top: -10,
		left: '47%',
		color: '#000'
	},
	tooltipBtn: {
		'& .MuiButtonBase-root': {
			padding: 0,
			minWidth: 'auto',
			'&:hover': {
				background: 'transparent'
			}
		}
	},
	tooltipTitle: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '1.125rem',
			letterSpacing: '-0.5px',
			fontWeight: 700,
			marginBottom: "5px",
		}
	},
	// tooltipText: {
	//     '&.MuiTypography-root': {
	//         color: '#fff',
	//         fontSize: '0.938rem',
	//         letterSpacing: '-0.5px',
	//         marginBottom: "10px",
	//     }
	// },
	tooltipText: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '0.938rem',
			letterSpacing: '-0.5px',
			marginBottom: "10px",
			whiteSpace: 'pre-line',
			textAlign: 'left',
			'& span': {
				position: 'relative',
				marginLeft: "10px",
				width: "250px",
				display: 'block',
				'&:before': {
					content: "''",
					width: "4px",
					height: "4px",
					background: '#fff',
					borderRadius: '50%',
					display: 'inline-block',
					position: 'absolute',
					left: '-10px',
					top: 7,
				},
			}
		}
	},


	textArticleNumber: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 500,
		}
	},
	textArticleTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 700,
			marginTop: "20px",
			marginBottom: "10px",
		}
	},
	textArticle: {
		border: '2px solid #111',
		borderRadius: 3,
		padding: 20,
		'& .MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 500,
		}
	},
	textArticleReference: {
		textAlign: 'right',
		'& .MuiTypography-root': {
			color: '#787878',
			fontSize: '0.875rem',
		}
	},
	nextStudentBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#cbcbcb',
			borderRadius: 3,
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#888',
				fontWeight: 700,
			}
		}
	},
	nextStudentBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			border: '1px solid #cbcbcb',
			'& .MuiTypography-root': {
				color: '#2F80ED',
			}
		}
	},
	studentName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			color: '#008C73'
		}
	},
	mainContentTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
		}
	},
	mainContentCount: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 500,
			'& span': {
				color: '#008C73',
				fontWeight: 700,
			}
		}
	},
	iconPosition: {
		position: 'absolute',
		right: 20,
	},
	textLenPosition: {
		zIndex: 100000,
		textAlign: 'right',
		marginRight: '5px',
		// position: 'absolute',
		// right: 8,
		// top: 48,
		'& .MuiTypography-root': {
			fontsize: '0.575rem',
			letterSpacing: '-0.5px',
			fontWeight: 100,
			color: '#787878',
		}
	},
	deleteIconPosition: {
		zIndex: 100000,
		position: 'absolute',
		right: 17,
		bottom: 10,
	},
	tableToggleBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			border: '1px solid #787878',
			borderRadius: 5,
			width: "160px",
			'& .MuiTypography-root': {
				fontsize: '0.875rem',
				letterSpacing: '-0.5px',
				marginRight: "10px",
				fontWeight: 600,
				color: '#111'
			}
		}
	},
	disabledTableToggleBtn: {
		'&.MuiButtonBase-root': {
			background: '#cbcbcb',
			borderRadius: 5,
			width: "160px",
			'& .MuiTypography-root': {
				fontsize: '0.875rem',
				letterSpacing: '-0.5px',
				marginRight: "10px",
				fontWeight: 600,
				color: '#fff',
			}
		}
	},
	tableAlertBox: {
		marginBottom: "5px",
		'& .MuiTypography-root': {
			color: '#FF0000',
			fontSize: '0.813rem',
			marginLeft: "5px",
		}
	},
	applyMarkBtn: {
		'& .MuiButtonBase-root': {
			width: "120px",
			height: "37px",
			background: '#cbcbcb',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#888'
			}
		}
	},
	applyMarkBtnActive: {
		'& .MuiButtonBase-root': {
			background: '#008C73',
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 700,
			}
		}
	},
	saveLineMarkBtn: {
		'& .MuiButtonBase-root': {
			width: "120px",
			height: "37px",
			background: '#fff',
			border: '1px solid #888',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#888'
			}
		}
	},
	saveLineMarkBtnActive: {
		'& .MuiButtonBase-root': {
			border: '1px solid #111',
			'& .MuiTypography-root': {
				color: '#111',
				fontWeight: 700,
			}
		}
	},
	scrollBottomBox: {
		// borderBottom: '1px solid #cbcbcb',
		// paddingTop: 20,
		height: 'calc(100vh - 422px)',
		overflowY: 'auto',

		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},

		'&::-webkit-scrollbar-thumb': {
			background: 'rgba(0, 0, 0, 0.16)',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},

		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},

		// '&::-webkit-scrollbar': {
		//     width: '10px',
		//     height: '10px'
		// },
		// '&::-webkit-scrollbar-thumb': {
		//     background: '#6f6f6f',
		//     borderRadius: '10px',
		//     backgroundClip: 'padding-box',
		//     border: '3px solid transparent',
		// },
		// '&::-webkit-scrollbar-track': {
		//     background: 'transparent',
		//     marginTop: "5px",
		// },
	},
	exportBtn: {
		marginRight: '10px',
		'&.MuiButtonBase-root': {
			background: '#cbcbcb',
			borderRadius: 3,
			width: "130px",
			height: "36px",
			cursor: 'default',
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#787878',
				fontWeight: 700,
			}
		}
	},
	exportBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#7730BA',
			cursor: 'pointer',
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#fff',
				fontWeight: 700,
			},
		}
	},
	aiBtnMenu: {
		'& .MuiPopover-paper': {
			width: "160px",
			top: '161px !important',
			'& .MuiListItem-root': {
				fontSize: '1rem',
				fontWeight: 700,
				color: '#111'
			}
		}
	},
	aiNoticeText: {
		// position: 'absolute',
		// top: 45,
		// left: 0,
		'& .MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#F00',
			fontWeight: 500,
			LetterSpacing: '-0.5px',
			marginLeft: "5px",
		}
	},
	aiMarkBtnAdd: {
		'& .MuiButtonBase-root': {
			background: 'rgba(119, 48, 186, 0.16)',
			borderRadius: 6,
			marginBottom: "10px",
			width: "130px",
			boxSizing: 'border-box',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#7730BA',
				fontWeight: 700,
				LetterSpacing: '-0.5px',

			}
		}
	},
	disabledAiMarkBtnAdd: {
		'& .MuiButtonBase-root': {
			background: '#cbcbcb',
			borderRadius: 6,
			marginBottom: "10px",
			width: "130px",
			boxSizing: 'border-box',
			cursor: 'default',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#FFF',
				fontWeight: 700,
				LetterSpacing: '-0.5px',

			}
		}
	},

	uploadBox: {
		textAlign: 'center',
		'& .MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#111'
		}
	},
	SubTextBox: {
		textAlign: 'center',
	},
	SubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			marginTop: "20px",
		}
	},

	'@keyframes waveAnimation': {
		'0%': {
			backgroundPosition: '0 0',
		},
		'100%': {
			backgroundPosition: '100% 0',
		},
	},

	skeletonShortUI: {
		width: '30%',
		height: '30px',
		background: `linear-gradient(90deg, rgba(245, 245, 245, 1) 0%, rgba(150, 150, 150, 1) 50%, rgba(245, 245, 245, 1) 100%)`,
		backgroundSize: '400% 100%',
		animation: '$waveAnimation 1s linear infinite',
		animationName: '$waveAnimation'
	},

	skeletonMiddleUI: {
		width: '60%',
		height: '30px',
		background: `linear-gradient(90deg, rgba(245, 245, 245, 1) 0%, rgba(150, 150, 150, 1) 50%, rgba(245, 245, 245, 1) 100%)`,
		animation: '$waveAnimation 1s linear infinite',
		animationName: '$waveAnimation'
	},

	skeletonLongUI: {
		width: '90%',
		height: '30px',
		background: `linear-gradient(90deg, rgba(245, 245, 245, 1) 0%, rgba(150, 150, 150, 1) 50%, rgba(245, 245, 245, 1) 100%)`,
		backgroundSize: '400% 100%',
		animation: '$waveAnimation 1s linear infinite',
		animationName: '$waveAnimation'
	},

	// 2024.04.04, hkkim@onthelive. 추가 -->
	lightBorderViewerBox: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		padding: '6px 25px',
		// maxHeight: '300px',
		marginBottom: "40px",
		overflowY: 'auto',

		'& .toastui-editor-contents': {
			fontSize: '1rem',
		},
	},

	lightQuestionSentence: {
		marginTop: "10px",
		'& p': {
			// fontSize: '1.25rem', // 20px
			fontSize: '1.125rem', // 18px
			letterSpacing: '-0.8px',
			fontWeight: '500',
			lineHeight: 'normal',
			color: '#111'
		}
	},

	lightBorderTaskBox: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		padding: '16px 25px',
		marginTop: "20px",

		// marginTop: "10px",
	},
	tableTitleSection: {
		height: "120px",
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#111',
			letterSpacing: '-0.5px',
			padding: '0 5px',
			whiteSpace: 'break-spaces',
			overflow: 'auto',
			// textOverflow:'ellipsis',
		}
	},
	ownerFeedbackViewer: {
		border: '1px solid #cbcbcb',
		padding: '15px',
		'& .toastui-editor-contents': {
			fontSize: '1rem',
			fontWeight: 500,
			color: '#111',
			'& > p': {
				margin: 0,
			}
		},
	},

	// kitty
	borderLine: {},
	scoreMarkInput: {},
	scoreMarkInputMulti: {},

});












