import {
	Backdrop,
	Box,
	Button, Checkbox,
	CircularProgress,
	Dialog,
	FormControl,
	FormControlLabel,
	IconButton,
	MenuItem,
	Select,
	TextField,
	Theme,
	Typography
} from "@mui/material";
import { useTheme } from "@mui/system";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { ReactComponent as DialogClose } from "../common/images/DialogClose.svg";
import { ReactComponent as GroupDeleteIcon } from "../common/images/GroupDeleteIcon.svg";
import { ReactComponent as SelectIcon } from "../common/images/SelectIcon.svg";
import { useStores } from "../hooks/useStores";
import useEmotionStyles from "../views/main/styles/useEmotionStyles";
import { ManageStudentStlye } from "../views/main/styles/ManageStudentStlye";

type Props = {
	open: boolean;
	onClose: () => void;
	selectedUser: string;
};


function GroupAppointDialog(props: Props) {
	const { userStore } = useStores();
	const classes = useEmotionStyles(ManageStudentStlye);

	const [selectedGroup, setSelectedGroup] = React.useState("DEFAULT");
	const [groupList, setGroupList] = React.useState([]);
	const [newGroupName, setNewGroupName] = React.useState('');
	const [checked, setChecked] = React.useState(false);
	const overflowBoxRef = React.useRef(null);

	const theme = useTheme();

	const { onClose, open, selectedUser } = props;

	const { myGroups, backdropOpen } = userStore;

	const handleClose = () => {
		onClose();
	};

	const handleSelectGroup = (e) => {
		setSelectedGroup(e.target.value);

		const newGroup = myGroups.find(group => String(group.code) === e.target.value);

		// 아래는 실제로 추가 될 그룹 들로, 중복인 경우엔 추가 하지 않는다.
		setGroupList(prevGroupList => {
			const isDuplicate = prevGroupList.some(group => group.code === newGroup.code);
			if (!isDuplicate) {
				return [...prevGroupList, newGroup];
			} else {
				return prevGroupList;
			}
		})
	}

	// 여기는 code 가 없으므로 , 무조건 추가.
	const handleClickNewGroupAdd = () => {
		const newGroupData = {
			name: newGroupName,
			exist: 'N',
		}

		setGroupList(prevGroupList => {
			return [...prevGroupList, newGroupData];
		})

		setTimeout(() => {
			overflowBoxRef.current.scrollTop = overflowBoxRef.current.scrollHeight;
		}, 200)
	}

	const removeGroup = (removeGroup) => {
		if (removeGroup.exist === 'N') {
			setGroupList(prevState => {
				let find = false;
				return prevState.filter(group => {
					if (!find && group.exist === 'N' && group.name === removeGroup.name) {
						find = true;
						return false;
					}
					return true;
				});
			})
		} else {
			setGroupList(prevGroupList => {
				return prevGroupList.filter(group => group.code !== removeGroup.code);
			});

			if (selectedGroup === removeGroup.code) {
				// 초기화
				setSelectedGroup("DEFAULT");
			}
		}
	}

	const groupAppoint = () => {
		if (checked) {
			// 그룹 지정 안함
			userStore.groupAllClear(selectedUser);
		} else {
			if (groupList.length > 0) {
				userStore.groupAppoint(groupList, selectedUser);
			}
		}
	}

	const handleChangeCheckBox = (e) => {
		setChecked(e.target.checked);
	}

	return (
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dataOpenDialog}>
			<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.dialogTop}>
				<Typography>그룹 지정</Typography>
				<IconButton className={classes.iconBtnReset}
					onClick={handleClose}
					disableRipple>
					<DialogClose />
				</IconButton>
			</Box>
			<Box className={classes.dialogContentGroup}>
				<Box >
					<Box>
						<Box>
							<FormControl className={clsx(classes.formControl, classes.area1Group)}>
								<Select
									IconComponent={(props) => (
										<Box>
											<SelectIcon  {...props} />
										</Box>
									)}
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "right"
										},
										transformOrigin: {
											vertical: 'top',
											horizontal: 'right',
										},
										// getContentAnchorEl: null,
										className: classes.selectPopover
									}}
									value={selectedGroup}
									onChange={handleSelectGroup}
								>
									<MenuItem disabled value="DEFAULT">
										<em>그룹 선택</em>
									</MenuItem>
									{myGroups && myGroups.map((group, index) => (
										<MenuItem id={`group-select-menuItem-${index}`} key={index} value={group.code}>{group.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>

						<Box className={classes.textFieldBox} sx={{ marginTop: "20px" }}>
							<TextField
								id="outlined-basic-group-name"
								variant="outlined"
								placeholder="그룹 입력"
								className={clsx(classes.dialogInput, classes.dialogInputSchoolEach)}
								value={newGroupName}
								onChange={(e) => { setNewGroupName(e.target.value) }}
							/>
							<Button className={classes.blackBtn}
								disabled={newGroupName === ''}
								onClick={handleClickNewGroupAdd}
								disableRipple>
								<Typography>추가</Typography>
							</Button>
						</Box>
						<FormControlLabel
							control={
								<Checkbox
									checked={checked}
									disableRipple
									onChange={handleChangeCheckBox}
								/>
							}
							label="그룹 지정 안함"
							className={classes.checkBoxStyle}
						/>
						<Box ref={overflowBoxRef} sx={{ height: "250px", overflowY: 'auto', border: '1px solid gray', borderRadius: 7, opacity: checked ? 0.5 : 1, backgroundColor: checked ? 'grey.200' : 'transparent' }}>
							{groupList.length > 0 && groupList.map((group, index) => (
								<Box id={`add-group-list-${index}`} key={index} className={classes.deleteTextBoxEdit} sx={{ margin: 6 }}>
									<Typography className={classes.textBoxName}>{group.name}</Typography>
									<IconButton className={classes.iconBtnReset}
										onClick={() => removeGroup(group)}
										disableRipple>
										<GroupDeleteIcon />
									</IconButton>
								</Box>
							))}
						</Box>

					</Box>
				</Box>
			</Box>

			<Box display='flex' alignItems='center' justifyContent='center' className={classes.dialogBtnGroup}>
				<Button className={classes.dialogBtn}
					onClick={handleClose}
					disableRipple>
					<Typography>취소</Typography>
				</Button>
				<Button className={classes.dialogBtnSave}
					disabled={!(groupList.length !== 0 || checked)}
					onClick={groupAppoint}
					disableRipple>
					<Typography>저장</Typography>
				</Button>
			</Box>

			<Backdrop
				style={{ color: '#fff', zIndex: (theme as Theme).zIndex.modal + 1 }}
				open={backdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

		</Dialog>
	);
}

export default observer<typeof GroupAppointDialog>(GroupAppointDialog);
