import { applyTransform, solveHomography, TransformPointPairs, TransformPoints } from '../../nl-lib3-common';
import { TransformParameters } from '../../nl-lib3-common/structures/mapper/TransformParameters';

export function calcRevH(h: TransformParameters) {
    // 아래는 임의의 숫자
    const srcPts: TransformPoints = {
        type: 'homography',
        unit: 'pu',
        pts: [
            { x: 0, y: 0 },
            { x: 100, y: 0 },
            { x: 100, y: 100 },
            { x: 0, y: 100 }
        ]
    };

    // 정방향 파라메터로 역방향의 대상이 되는 점을 연산
    const dstPts: TransformPoints = {
        type: 'homography',
        unit: 'nu',
        pts: new Array(4)
    };

    for (let i = 0; i < 4; i++) {
        const dstPt = applyTransform(srcPts.pts[i], h);
        dstPts.pts[i] = dstPt;
    }

    /** src:PU, dst:NU */
    const pts = {
        src: { ...dstPts },
        dst: { ...srcPts }
    } as TransformPointPairs;

    const h_rev = solveHomography(pts);
    return h_rev;
}

