import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {
	Box,
	IconButton,
	TextField,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as SelectIcon } from '../../../../common/images/SelectIcon.svg';
import { ReactComponent as SelectIconUp } from '../../../../common/images/SelectIconUp.svg';
import { ReactComponent as ToggleIcon } from '../../../../common/images/ToggleIcon.svg';
import { ReactComponent as TrashDeleteIcon } from '../../../../common/images/TrashDeleteIcon.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ExamRangeUserStyle } from "../../styles/ExamRangeUserStyle";

import { toJS } from 'mobx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextEditor from "../../../../components/common/TextEditor";
import { useStores } from '../../../../hooks/useStores';
import { IQuestionInfo } from '../../../../repositories/model/transfer/IQuestionInfo';
import { observer } from 'mobx-react';

type Props = {
	questionInfo: IQuestionInfo,
	examOrder: number,
	examQuestionListLen: number,
	onUploadFile: any,
	isNowBulkLoading: boolean,
}


export const QuestionRow = observer((props: Props) => {
	const i18next = useTranslation();
	const { questionInfo: qi, examOrder, examQuestionListLen, onUploadFile } = props;
	const { projectExamStore, messageStore } = useStores();

	const questionInfo = toJS(qi);
	const classes = useEmotionStyles( ExamRangeUserStyle );
	const { order: questionOrder } = questionInfo;
	const [openSupport, setOpenSupport] = React.useState(false);

	const isAbleToDelete = React.useMemo(() => {
		return questionOrder > 1;
	}, [questionOrder]);

	const currentOrderString = React.useMemo(() => {
		if (examQuestionListLen > 1) {
			return `${examOrder}-${questionOrder}`;
		}

		return examOrder;
	}, [examQuestionListLen, examOrder, questionOrder]);

	const currentQuestionHTML = React.useMemo(() => {
		// if (questionInfo.question.length > 0) {
		// 	console.log(`questionInfo.question: ${questionInfo.question}`);
		// 	return questionInfo.question;
		// };
		return questionInfo.initialQuestion ? questionInfo.initialQuestion : ""
	}, [questionInfo.initialQuestion]);

	const currentReferenceHTML = React.useMemo(() => {
		if (questionInfo.initialReference) {
			setOpenSupport(true);
		}

		return questionInfo.initialReference ? questionInfo.initialReference : ""
	}, [questionInfo.initialReference]);

	const handleClickDelete = async () => {
		const result = await messageStore.confirm(i18next.t("{{examOrder}}-{{questionOrder}}번 문항을 삭제하시겠습니까?", { examOrder, questionOrder }));
		if (!result) return;

		projectExamStore.deleteQuestion(examOrder, questionOrder);
	};

	const handleChangeScore = (e) => {
		const value = e.target.value;
		// console.log(value, questionOrder);

		if (!value) {
			projectExamStore.changeQuestionScore(examOrder, questionOrder, value);
			return;
		}

		const pattern = /^[1-9]\d*$/;
		const isNumber = pattern.test(value);
		if (!isNumber) return;

		projectExamStore.changeQuestionScore(examOrder, questionOrder, Number(value));
	};

	const handleIncreaseScore = () => {
		const currentScore = questionInfo.score;

		projectExamStore.changeQuestionScore(examOrder, questionOrder, currentScore + 1);
	};

	const handleDecreaseScore = () => {
		const currentScore = questionInfo.score;
		if (currentScore <= 0) return;

		projectExamStore.changeQuestionScore(examOrder, questionOrder, currentScore - 1);
	};

	const handleChangeQuestion = (questionHtml: string) => {
		projectExamStore.changeQuestionContent(examOrder, questionOrder, questionHtml);
	};

	const handleChangeReference = (reference) => {
		projectExamStore.changeQuestionReference(examOrder, questionOrder, reference);
	};

	const handleInitInitialQuestion = () => {
		console.log(`handleInitInitialQuestion`);
		projectExamStore.initQuestionInitialQuestion(examOrder, questionOrder);
	};

	const handleInitInitialReference = () => {
		projectExamStore.initQuestionInitialReference(examOrder, questionOrder);
	};

	const handleClickSupportBtn = () => {
		setOpenSupport(prev => !prev);
	};

	//#region 문제 자동 fill-in
	const editorRef = React.useRef(null);

	// React.useEffect(()=>{
	// 	if(questionInfo.question.length > 0){
	// 		setInnerHtml(questionInfo.question);
	// 	}
	// },[questionInfo.question])

	const setInnerHtml = (html: string) => {
		const inst = editorRef.current;
		inst.setHTML(html);
	}
	//#endregion

	return (
		<>
			<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginTop: "20px" }}>
				<Box display='flex' alignItems='center' justifyContent='center'>
					<Typography className={classes.userTitle}>{i18next.t("{{currentOrder}}번 문항 입력", { currentOrder: currentOrderString })}</Typography>
					{isAbleToDelete &&
						<IconButton
							className={clsx(classes.iconBtnReset, classes.titleTrashIcon)}
							onClick={handleClickDelete}
							disableRipple>
							<TrashDeleteIcon />
						</IconButton>
					}
				</Box>
				<Box display='flex' alignItems='center' justifyContent='right'>
					<Typography className={classes.scoreMarkText}>{i18next.t("점수")}</Typography>
					<Box className={classes.scoreMark}>
						<TextField
							id="outlined-basic"
							variant="outlined"
							value={questionInfo.score}
							onChange={handleChangeScore}
							placeholder="0" />
						<Box className={classes.scoreMarkIcon}>
							<IconButton
								className={clsx(classes.iconBtnReset, classes.iconYearSelect)}
								onClick={handleIncreaseScore}
								disableRipple>
								<SelectIconUp />
							</IconButton>
							<IconButton
								className={clsx(classes.iconBtnReset, classes.iconYearSelect)}
								onClick={handleDecreaseScore}
								disableRipple>
								<SelectIcon />
							</IconButton>
						</Box>
					</Box>
					<Typography className={classes.scoreMarkSubText}>{i18next.t("점")}</Typography>
				</Box>
			</Box>
			<>
				<div style={{ overflow: "hidden" }}>
					<Box className={classes.editerBox} sx={{ position: 'relative' }}>
						<TextEditor
							ref={editorRef}
							placeholder={i18next.t("서술형 문항은 응답의 내용 범위와 서술 방식, 조건을 포함하여 기재할 수 있습니다.")}
							onUploadFile={onUploadFile}
							onSetHTML={handleChangeQuestion}
							currentHTML={currentQuestionHTML}
							onInitInitialContent={handleInitInitialQuestion}
							isNowBulkLoading={props.isNowBulkLoading}

							newHtml={questionInfo.question}
						// exceptFontSizePlugin={true}
						/>

						{/*<Box className={classes.addViewBtn} onClick={handleClickSupportBtn}>*/}
						{/*    <Typography>보기 및 조건 추가</Typography>*/}
						{/*    <IconButton disableRipple*/}
						{/*                style={{ transform: !openSupport ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }}*/}
						{/*    >*/}
						{/*        <ToggleIcon />*/}
						{/*    </IconButton>*/}
						{/*</Box>*/}

					</Box>
				</div>
			</>

			<Box style={{ marginBottom: '30px' }}>
				<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginLeft: "10px" }}>
					<Box display='flex' alignItems='center' justifyContent='center' className={classes.supportEditerWrap} onClick={handleClickSupportBtn}>
						<Box>
							<SubdirectoryArrowRightIcon />
						</Box>
						<Typography className={classes.userTitleSelect}>{i18next.t("보기 및 조건")} <span>{i18next.t("(선택)")}</span></Typography>
						<IconButton
							className={clsx(classes.iconBtnReset, classes.userTitleToggle)}
							disableRipple>
							{!openSupport
								?
								<ToggleIcon style={{ transform: "scaleY(-1)" }} />
								:
								<ToggleIcon />
							}
						</IconButton>
					</Box>
				</Box>

				<Box className={openSupport ? classes.addViewBox : classes.displayNone} sx={{ position: 'relative' }}>
					<TextEditor
						// height={240}
						placeholder={i18next.t("문항의 보기, 이미지 등을 기재할 수 있습니다.")}
						onUploadFile={onUploadFile}
						onSetHTML={handleChangeReference}
						currentHTML={currentReferenceHTML}
						onInitInitialContent={handleInitInitialReference}
					/>
				</Box>
			</Box>
		</>
	)
});
