import $ from "jquery";
// import { jqueryLibInfo } from "./ImportJquery";
import { PU_TO_NU } from "../../constants/ncode-constants";
import { IPageSOBP } from "../../structures/Structures";
import { NprojJson, PuiSymbolType } from "../../structures/nproj-related/nproj-analyzed-json";
import { NprojPageJson } from "../../structures/nproj-related/nproj-page";




export function nprojToJson(nproj: string) {
  // const $preset = $(nproj).find("preset");
  // const pattern = $preset.find("pattern").text();

  const $bookXml = $(nproj).find("book");
  const title = $bookXml.find("title").text();
  const author = $bookXml.find("author").text();

  const section = parseInt($bookXml.find("section").text(), 10);
  const owner = parseInt($bookXml.find("owner").text(), 10);
  const book = parseInt($bookXml.find("code").text(), 10);
  // const start_page = parseInt($bookXml.find("start_page").text());
  let startPage = parseInt($bookXml.find("start_page").text(), 10);
  // const extra_info = $bookXml.find("extra_info").text(); //     <extra_info>pdf_page_count=2</extra_info>, 3.27.603, spring note

  const segment_info = $bookXml.find("segment_info");
  const ncode_start_page_str = segment_info.attr("ncode_start_page");
  if (ncode_start_page_str) {
    const start_page_new = parseInt(ncode_start_page_str, 10);
    startPage = start_page_new;
  }
  const extra = $bookXml.find("extra_info")?.text();
  let extra_info = undefined as { [key: string]: string };
  if (extra_info) {
    const arr = extra.split("=");
    extra_info = {};
    // eslint-disable-next-line prefer-destructuring
    extra_info[arr[0]] = arr[1];
  }
  const kind = parseInt($bookXml.find("kind")?.text(), 10);
  let offset: { left: number; top: number } = {
    left: 0,
    top: 0
  };

  let _offset = $bookXml.find("offset");
  if (_offset) {
    const leftAttr = _offset.attr("left");
    const topAttr = _offset.attr("top");

    if (leftAttr && topAttr) {
      const left = parseFloat(leftAttr);
      const top = parseFloat(topAttr);
      offset = {
        left,
        top
      }
    }
  }

  const $pdfXml = $(nproj).find("pdf");
  const filename = $pdfXml.find("path").text();

  const $pages = $(nproj).find("pages");
  const numPages = parseInt($pages.attr("count") || "", 10);

  const ret: NprojJson = {
    book: {
      title,
      author,
      section,
      owner,
      book,
      start_page: startPage,
      extra_info,
      kind,
      offset
    },

    pdf: {
      filename,
      numPages,
    },

    pages: new Array(numPages),

    symbols: [],

    resources: {}
  }

  // page item에 대한 처리
  const $page_items = $pages.find("page_item");
  $page_items.each((index: number, page) => {
    const p = $(page);
    const pageDelta = parseInt(p.attr("number") || "index", 10);
    const sobp: IPageSOBP = { section, owner, book, page: startPage + pageDelta };

    const surface_pu = {
      left: parseFloat(p.attr("x1") || "0"),
      top: parseFloat(p.attr("y1") || "0"),
      right: parseFloat(p.attr("x2") || "0"),
      bottom: parseFloat(p.attr("y2") || "0"),
    }

    const $crop_margin = p.attr("crop_margin") || "0,0,0,0";
    const margins = $crop_margin.split(",");

    // FIXED: 2024-02-13, before 2024-02-13
    // const crop_margin_pu = {
    //   left: parseFloat(margins[0]),
    //   top: parseFloat(margins[1]),
    //   right: parseFloat(margins[2]),
    //   bottom: parseFloat(margins[3]),
    // }

    // FIXED: 2024-02-13, "쏘슬러시 더블스퀘어노트"를 통해서 crop_margin="x0,x1,y0,y1"이 아닌가 싶다.
    const crop_margin_pu = {
      left: parseFloat(margins[0]) + offset.left,
      right: parseFloat(margins[1]) + offset.left,
      top: parseFloat(margins[2]) + offset.top,
      bottom: parseFloat(margins[3]) + offset.top,
    }

    const size_pu = {
      width: Math.round(surface_pu.right - crop_margin_pu.right) - (surface_pu.left + crop_margin_pu.left) + offset.left,
      height: Math.round(surface_pu.bottom - crop_margin_pu.bottom) - (surface_pu.top + crop_margin_pu.top) + offset.top,
    }

    const nu = {
      Xmin: (surface_pu.left + crop_margin_pu.left) * PU_TO_NU,
      Ymin: (surface_pu.top + crop_margin_pu.top) * PU_TO_NU,
      Xmax: (surface_pu.left + size_pu.width) * PU_TO_NU,
      Ymax: (surface_pu.top + size_pu.height) * PU_TO_NU,
    }
    // const Xmax_physical = (surface_pu.right - padding_pu.right) * PU_TO_NU;
    // const Ymax_physical = (surface_pu.bottom - padding_pu.bottom) * PU_TO_NU;


    // 결과를 push
    const item: NprojPageJson = {
      sobp,
      size_pu,
      nu,
      whole: { x1: surface_pu.left, x2: surface_pu.right, y1: surface_pu.top, y2: surface_pu.bottom },
      crop_margin: crop_margin_pu,
    };

    ret.pages[pageDelta] = item;
  });



  // symbol 정보
  const $symbols = $(nproj).find("symbols");
  const symbolXml = $symbols.find("symbol");
  // const listLength = symbolXml.length;

  $(symbolXml).each((index, sym) => {
    const pageDelta = parseInt($(sym).attr("page") || "0", 10);
    const page = pageDelta + startPage;
    const sobp = { section, owner, book, page };

    // if (targetPage !== -1 && page !== targetPage) {
    //   return; // 특정 페이지만을 구할 때 사용
    // }

    const type: string = $(sym).attr("type") || ""; // 여기서는 Rectangle만 취급한다.
    const x = parseFloat($(sym).attr("x") || "");
    const y = parseFloat($(sym).attr("y") || "");
    const width = parseFloat($(sym).attr("width") || "");
    const height = parseFloat($(sym).attr("height") || "");

    const lock = parseInt($(sym).attr("lock") || "");

    let command: string = $(sym).find("command").attr("param") || "";
    
    let symbolId = $(sym).find("id").text() || "";
    const uuidMatch = symbolId.match(/{(.*)}/);
    if (uuidMatch) {
      symbolId = uuidMatch[1];
    }

    const commandAction: string = $(sym).find('command').attr('action') || '';
    if (commandAction === "Play") {
      const langResources = $(sym).find('language_resources');
      const soundResourceId: string = langResources.find("language[resource_type='Sound']").attr('resource_id') || '';
      const soundId = `${symbolId}::${soundResourceId}`;
      // sound Play 일 경우 command 는 동일 리소스를 사용하는 각 PUI 의 일련번호 와 반복재생 금지를 위한 timeout
      // ex) resource ID = n1e2o3l4-a5b6-c7o8-n9v0-e1r2g3e4n5c6, command = "001_060"
      // 해당 PUI 의 일련번호는 001 이며 1분동안 동일 PUI 요청되더라도 막을 것이며, 음원 리소스는 n1e2~.
      command = command.length > 0 ? soundId + "!!" + command : soundId;
    }
    const commandName: string = $(sym).find('command').attr('name') || '';
    if (commandName === 'gif' || commandName === 'pdf') {
      const isGoogleDrive = command.match(
        /^https:\/\/drive\.google\.com/
      )
      if (isGoogleDrive !== null) {
        command = commandName + "!!" + command;
      }
    }

    const extra = $(sym).find("extra").attr("param") || "";

    switch (type) {
      case "Rectangle": {
        const puiSymbol: PuiSymbolType = {
          type,
          command,
          sobp,
          rect_nu: {
            left: x * PU_TO_NU - offset.left,
            top: y * PU_TO_NU - offset.top,
            width: width * PU_TO_NU,
            height: height * PU_TO_NU,
          },
          extra,
        };
        ret.symbols.push(puiSymbol);
        break;
      }

      case "Ellipse": {
        const puiSymbol: PuiSymbolType = {
          type,
          command,
          sobp,
          ellipse_nu: {
            x: x * PU_TO_NU - offset.left,
            y: y * PU_TO_NU - offset.top,
            width: width * PU_TO_NU,
            height: height * PU_TO_NU,
          },
          extra,
        };
        ret.symbols.push(puiSymbol);
        break;
      }

      case "Custom": {
        const puiSymbol: PuiSymbolType = {
          type,
          command,
          sobp,
          custom_nu: {
            left: x * PU_TO_NU - offset.left,
            top: y * PU_TO_NU - offset.top,
            width: width * PU_TO_NU,
            height: height * PU_TO_NU,
            lock: lock === 1,
          },
          extra,
        };
        ret.symbols.push(puiSymbol);
        break;
      }


      default: {
        // FIXME: Polygon은 어떻게 처리할 것인가? 2023-10-30
        console.error(`symbol type(${type} is not "Rectangle" nor "Ellipse"`);
      }
    }
  });


  const $resources = $(nproj).find("resources");
  const resourceXml = $resources.find("resource");
  $(resourceXml).each((index, res) => {
    const id = $(res).find('id').text();
    const path = $(res).find('path').text();
    if (id && path) {
      ret.resources[id] = path;
    }
  })





  return ret;
}


export function getStartEndSobp(nprojStr: string) {
  const nprojJson = nprojToJson(nprojStr);
  const startSobp = nprojJson.pages[0].sobp;
  const endSobp = nprojJson.pages[nprojJson.pages.length - 1].sobp;

  return { startSobp, endSobp, nprojJson }
}
