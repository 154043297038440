import { useParams } from "react-router-dom";
import DataViewer from "./DataViewer";

type ParamsType = {
	params: {
		fileUrl: string;
		projectCode: string;
		fileId: string;
		pages: string;
	};
};

type Props = {};

function DataViewerWrapper(props: Props) {
	const params = useParams();
	const { fileUrl, projectCode, fileId, pages } = params;

	return (
		<div>
			<DataViewer fileUrl={fileUrl}
				projectCode={projectCode}
				fileId={fileId}
				ragPage={pages} />
		</div>
	);
}

export default DataViewerWrapper;
