import { Annotation } from "./Annotation";
import { AnnotationList } from "./AnnotationList";
import { assert, neoAllocateUTF8 } from "./functions";
import { FzBuffer } from "./FzBuffer";
import { FzDocument } from "./FzDocument";
import { FzPage } from "./FzPage";
import { FzRect } from "./FzRect";
import { Link } from "./Link";
import { PdfDocument } from "./PdfDocument";
import { lineCapStyleToNumber, lineJoinStyleToNumber, LINE_CAP_STYLE, LINE_JOIN_STYLE, pathFillTypeToNumber, PATH_FILL_TYPE } from "./SvgPathDrawEnum";

export class PdfPage extends FzPage {
  // pdfPagePointer: number;

  annotationList;

  hasGsResetCmd = false;

  opacityAdded = [] as number[];


  // constructor(module: any, ctx: number, pagePointer: number, pdfPagePointer: number, pageNumber: number, pdfDoc: FzDocument) {
  constructor(module: any, ctx: number, pagePointer: number, pageNumber: number, pdfDoc: FzDocument) {
    super(module, ctx, pagePointer, pageNumber, pdfDoc);
    // this.pdfPagePointer = pdfPagePointer;
    this.annotationList = null;
  }

  update() {
    const { libmupdf } = this;
    libmupdf._wasm_pdf_update_page(this.ctx, this.pointer);
  }

  getAnnotations() {
    const annotations = [];
    const { libmupdf } = this;

    for (let annot = libmupdf._wasm_pdf_first_annot(this.ctx, this.pointer); annot !== 0; annot = libmupdf._wasm_pdf_next_annot(this.ctx, annot)) {
      annotations.push(new Annotation(libmupdf, this.ctx, annot));
    }

    // TODO - find other way to structure this
    this.annotationList = new AnnotationList(this.ctx, annotations);
    return this.annotationList;
  }

  createAnnotation(annotType) {
    // TODO - Validate annot type in separate function
    assert(typeof annotType === "number" && !Number.isNaN(annotType), "invalid annotType argument");
    const { libmupdf } = this;

    // TODO - Update annotation list?
    const annotPointer = libmupdf._wasm_pdf_create_annot(this.ctx, this.pointer, annotType);
    const annot = new Annotation(libmupdf, this.ctx, annotPointer);

    this.update();
    return annot;
  }

  deleteAnnotation(removedAnnotation) {
    assert(removedAnnotation instanceof Annotation, "invalid annotation argument");
    const { libmupdf } = this;

    libmupdf._wasm_pdf_delete_annot(this.ctx, this.pointer, removedAnnotation.pointer);
    if (this.annotationList) {
      this.annotationList.annotations = this.annotationList.annotations.filter(annot => annot.pointer === removedAnnotation.pointer);
    }
  }

  // applyRedactions

  // getWidgetsAt

  createLink(bbox, uri) {
    assert(bbox instanceof FzRect, "invalid bbox argument");
    // TODO bbox is rect

    const { libmupdf } = this;
    const uri_ptr = neoAllocateUTF8(libmupdf, this.ctx, uri);

    try {
      return new Link(libmupdf, this.ctx, libmupdf._wasm_pdf_create_link(this.ctx, this.pointer, bbox.x0, bbox.y0, bbox.x1, bbox.y1, uri_ptr));
    }
    finally {
      libmupdf._wasm_free(this.ctx, uri_ptr);
    }
  }

  /**
   *
   * @param svgPath - svg path string
   * @param r - red color value (0-255)
   * @param g - green color value (0-255)
   * @param b - blue color value (0-255)
   * @param opacity - opacity value (0-1)
   * @param stroke_width - stroke width (Default should be 0.01)
   * @param fill_type - Fill type (default should be PATH_FILL_TYPE.PATH_FILL_TYPE_FILL)
   * @param line_cap_style - Line cap style (default should be LINE_CAP_STYLE.LINE_CAP_STYLE_ROUND)
   * @param line_join_style - Line join style (default should be LINE_JOIN_STYLE.LINE_JOIN_STYLE_ROUND)
   */
  insertSVGPathOnPage(
    svgPath: string,
    r: number, g: number, b: number, opacity: number,
    stroke_width: number,
    fill_type: PATH_FILL_TYPE,
    line_cap_style: LINE_CAP_STYLE,
    line_join_style: LINE_JOIN_STYLE
  ) {
    const { libmupdf } = this;

    const fill_type_num = pathFillTypeToNumber(fill_type);
    const line_cap_style_num = lineCapStyleToNumber(line_cap_style);
    const line_join_style_num = lineJoinStyleToNumber(line_join_style);

    r /= 255;
    g /= 255;
    b /= 255;


    let strPointer = null as number;
    try {
      this.neoAddGsResetCommand();
      this.neoAddOpacityCommand(opacity);

      strPointer = neoAllocateUTF8(libmupdf, this.ctx, svgPath);
      if (!strPointer) {
        console.error("Failed to allocate svg path string");
      }

      if (!this.doc.pointer) {
        console.error("Failed to get document pointer");
      }

      if (!this.ctx) {
        console.error("Failed to get context");
      }

      libmupdf._wasm_neoInsertSvgPath(
        this.ctx, this.doc.pointer, this.pageIndex,
        strPointer,
        r, g, b, opacity,
        stroke_width,
        fill_type_num,
        line_cap_style_num,
        line_join_style_num
      );
    }
    finally {
      if (strPointer)
        libmupdf._wasm_free(this.ctx, strPointer);
    }
  }

  neoAddGsResetCommand() {
    if (!this.hasGsResetCmd) {
      const { libmupdf } = this;
      libmupdf._wasm_neoAddGsResetCommand(this.ctx, this.doc.pointer, this.pageIndex);

      this.hasGsResetCmd = true;
    }
  }

  neoAddOpacityCommand(opacity: number): void {
    if (!this.opacityAdded.includes(opacity)) {
      const { libmupdf } = this;
      libmupdf._wasm_neoAddOpacityCommand(this.ctx, this.doc.pointer, this.pageIndex, opacity);

      this.opacityAdded.push(opacity);
    }
  }


  insertSvgPathByPdfOperators(
    pdfOperatorString: string,
    r: number, g: number, b: number, opacity: number,
    stroke_width: number,
    fill_type: PATH_FILL_TYPE,
    line_cap_style: LINE_CAP_STYLE,
    line_join_style: LINE_JOIN_STYLE
  ) {
    const { libmupdf } = this;
    // opacity = 1;
    r /= 255;
    g /= 255;
    b /= 255;
    let strPointer = null as number;
    this.neoAddGsResetCommand();
    this.neoAddOpacityCommand(opacity);

    try {
      strPointer = neoAllocateUTF8(libmupdf, this.ctx, pdfOperatorString);

      libmupdf._wasm_neoInsertSvgPathByPdfOperatorString(
        this.ctx, this.doc.pointer, this.pageIndex,
        strPointer,
        r, g, b, opacity,
        stroke_width,
        fill_type,
        line_cap_style,
        line_join_style
      );
    }
    finally {
      if (strPointer)
        libmupdf._wasm_free(this.ctx, strPointer);
    }
  }


  convertColor(
    toRgb: boolean,
    toCmy: boolean,
    ncodeFont: { pointer: number, charset: string } = null,
    useRgbPseudoColor = false,
    maxBlueContrast = 0.9,
    shouldFlatten = false,
    dropContents = true,
    rgbSoftMark = false
  ) {
    const { ctx } = this;
    const { doc } = this;

    const color_convert_mode = useRgbPseudoColor ? 1 : 0;
    let flatten_mode = 0;
    if ((toRgb || toCmy) && shouldFlatten) {
      flatten_mode = toRgb ? 1 : 2;
    }

    const color_3_mode_bluemode = toRgb ? 1 : 0;
    const color_4_mode = toCmy ? 1 : 0;

    if (ncodeFont == null) {
      ncodeFont = (this.doc as PdfDocument).getNcodeFont();
    }
    const { libmupdf } = this;
    libmupdf._wasm_neoConvertPageColor(ctx, doc.pointer,
      this.pageIndex,
      ncodeFont ? ncodeFont.pointer : 0,
      color_convert_mode,
      dropContents ? 1 : 0,
      flatten_mode,
      color_3_mode_bluemode,
      color_4_mode,
      maxBlueContrast,
      rgbSoftMark ? 1 : 0
    );

    return this;
  }

  flattenPage() {
    this.convertColor(false, false, null, false, 1.0, true, true, false);
  }

  insertNcodeLayerDirect(
    isPdfInRGB: boolean,
    forceUseCarbonBlack: boolean,
    sobp: string,
    pageDelta = 0,
    x0 = 0 /* float */,
    y0 = 0 /* float */,
    x1 = 0 /* float */,
    y1 = 0 /* float */,
    ncodeFont: { pointer: number, charset: string } = null,
    ncodeBaseX = 0 /* int */,
    ncodeBaseY = 0 /* int */,
    excludeRegions: {
      x0: number /* float */, y0: number /* float */,
      x1: number /* float */, y1: number /* float */
    }[] = null
  ) {
    const { ctx } = this;
    const { doc } = this;

    if (ncodeFont == null) {
      ncodeFont = (this.doc as PdfDocument).getNcodeFont();
    }

    let excludeRegionsPtr = 0;
    let sobpPointer = 0;
    let charsetPointer = 0;

    const { libmupdf } = this;

    try {
      sobpPointer = neoAllocateUTF8(libmupdf, this.ctx, sobp);
      charsetPointer = neoAllocateUTF8(libmupdf, this.ctx, ncodeFont.charset);

      const numExcludes = excludeRegions?.length || 0;
      if (excludeRegions != null) {
        excludeRegionsPtr = libmupdf._wasm_malloc(ctx, excludeRegions.length * 4 * Float32Array.BYTES_PER_ELEMENT);
        let ptr = excludeRegionsPtr;
        for (let i = 0; i < numExcludes; ++i) {
          const r = excludeRegions[i];
          libmupdf._setRectData(ptr, r.x0, r.y0, r.x1, r.y1);
          ptr += 4 * Float32Array.BYTES_PER_ELEMENT;
        }
      }

      let carbon_black = 0;
      let is_glyph_rgb_mode = isPdfInRGB ? 1 : 0;
      if (forceUseCarbonBlack) {
        is_glyph_rgb_mode = 0;
        carbon_black = 1;
      }
      console.log(`ncode layer added: fontObj:${ncodeFont.pointer}, charset:${ncodeFont.charset}`);

      libmupdf._wasm_neoAddNcodeLayerOnPage(
        ctx, doc.pointer, this.pageIndex,
        carbon_black,
        is_glyph_rgb_mode,
        sobpPointer,
        pageDelta, x0, y0, x1, y1,
        ncodeFont.pointer, charsetPointer,
        ncodeBaseX, ncodeBaseY,
        numExcludes, excludeRegionsPtr
      );
    }
    finally {
      if (excludeRegionsPtr)
        libmupdf._wasm_free(ctx, excludeRegionsPtr);

      if (sobpPointer)
        libmupdf._wasm_free(ctx, sobpPointer);

      if (charsetPointer)
        libmupdf._wasm_free(ctx, charsetPointer);
    }
  }

  convertSvgPathToPdfOperators(svgPath: string) {
    let svgPathPointer = null as number;
    let buf: FzBuffer = null;
    let pdfOperators = "";

    const { libmupdf } = this;
    try {
      svgPathPointer = neoAllocateUTF8(libmupdf, this.ctx, svgPath);
      const bufPointer = libmupdf._wasm_neoSvgPathToPdfOperatorString(this.ctx, svgPathPointer);
      buf = new FzBuffer(libmupdf, this.ctx, bufPointer);
      pdfOperators = buf.toJsString();
    }
    finally {
      if (svgPathPointer)
        libmupdf._wasm_free(this.ctx, svgPathPointer);
      if (buf)
        buf.free();
    }

    return pdfOperators;
  }


  static staticConvertSvgPathToPdfOperators(libmupdf: any, ctx: number, svgPath: string) {
    let svgPathPointer = null as number;
    let buf: FzBuffer = null;
    let pdfOperators = "";

    try {
      svgPathPointer = neoAllocateUTF8(libmupdf, ctx, svgPath);
      const bufPointer = libmupdf._wasm_neoSvgPathToPdfOperatorString(ctx, svgPathPointer);
      buf = new FzBuffer(libmupdf, ctx, bufPointer);
      pdfOperators = buf.toJsString();
    }
    finally {
      if (svgPathPointer)
        libmupdf._wasm_free(ctx, svgPathPointer);
      if (buf)
        buf.free();
    }

    return pdfOperators;
  }
}
