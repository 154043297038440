import { defaultContextCacheSize } from "../../config/defaultCacheSize";
import { uuidv4 } from "../../common/UtilFuncs";

export class FzContext {
  public id = uuidv4();

  private _pointer: number;

  private set pointer(value: number) { this._pointer = value; }

  public get pointer() { return this._pointer; }

  public ctx: number;

  dropFunction: any;

  public libmupdf: any = null;

  static count = 0;

  constructor(libmupdf: any, cacheSize?: number) {
    const dropFunction = libmupdf._wasm_drop_context;
    // super(libmupdf, ctx, pointer, libmupdf._wasm_drop_context);
    this.libmupdf = libmupdf;
    this.dropFunction = dropFunction;

    cacheSize = cacheSize ?? defaultContextCacheSize;
    const ctx = libmupdf._wasm_init_context(cacheSize);
    this.ctx = ctx;
    this.pointer = ctx;

    // console.log(`FzContext: ${++FzContext.count}`);

    // console.log(`${this.constructor.name}: ${dropFunction}`);

    if (typeof this.pointer !== "number" || this.pointer === 0) {
      throw new Error(`cannot create ${this.constructor.name}: invalid pointer param value '${this.pointer}'`);
    }
    if (dropFunction == null)
      throw new Error(`cannot create ${this.constructor.name}: dropFunction is null`);
    if (typeof dropFunction !== "function")
      throw new Error(`cannot create ${this.constructor.name}: dropFunction value '${dropFunction}' is not a function`);
  }

  public free() {
    if (this.pointer !== 0) {
      this.dropFunction(this.pointer);
      this.pointer = 0;
    }
  }
}
