import { DotTypes, NeoDot, NeoDotBuilder } from "../../../../nl-lib3-common";

const DELTA = 10;

const MAX_X = 15070;

const MAX_Y = 8480;

// const MAX_OWNER = 1024;

const MAX_NOTE_ID = 16384;

const MAX_PAGE_ID = 262143;


function ValidateCode(dot: NeoDot) {
  return !(MAX_NOTE_ID < dot.Note || MAX_PAGE_ID < dot.Page);
}

function ValidateStartDot(dot1: NeoDot, dot2: NeoDot, dot3: NeoDot) {
  if (dot1 === null || dot2 === null || dot3 === null) { return false; }
  if (dot1.X > MAX_X || dot1.X < 1) { return false; }
  if (dot1.Y > MAX_Y || dot1.Y < 1) { return false; }

  if ((dot3.X - dot1.X) * (dot2.X - dot1.X) > 0
    && Math.abs(dot3.X - dot1.X) > DELTA && Math.abs(dot1.X - dot2.X) > DELTA) {
    return false;
  }

  if ((dot3.Y - dot1.Y) * (dot2.Y - dot1.Y) > 0
    && Math.abs(dot3.Y - dot1.Y) > DELTA && Math.abs(dot1.Y - dot2.Y) > DELTA) {
    return false;
  }

  return true;
}

function ValidateMiddleDot(dot1: NeoDot, dot2: NeoDot, dot3: NeoDot) {
  if (dot1 === null || dot2 === null || dot3 === null) { return false; }

  if (dot2.X > MAX_X || dot2.X < 1) { return false; }
  if (dot2.Y > MAX_Y || dot2.Y < 1) { return false; }

  if ((dot1.X - dot2.X) * (dot3.X - dot2.X) > 0
    && Math.abs(dot1.X - dot2.X) > DELTA && Math.abs(dot3.X - dot2.X) > DELTA) {
    return false;
  }

  if ((dot1.Y - dot2.Y) * (dot3.Y - dot2.Y) > 0
    && Math.abs(dot1.Y - dot2.Y) > DELTA && Math.abs(dot3.Y - dot2.Y) > DELTA) {
    return false;
  }

  return true;
}

function ValidateEndDot(dot1: NeoDot, dot2: NeoDot, dot3: NeoDot) {
  if (dot1 === null || dot2 === null || dot3 === null) { return false; }
  if (dot3.X > MAX_X || dot3.X < 1) { return false; }
  if (dot3.Y > MAX_Y || dot3.Y < 1) { return false; }
  if ((dot3.X - dot1.X) * (dot3.X - dot2.X) > 0
    && Math.abs(dot3.X - dot1.X) > DELTA && Math.abs(dot3.X - dot2.X) > DELTA) {
    return false;
  }

  if ((dot3.Y - dot1.Y) * (dot3.Y - dot2.Y) > 0
    && Math.abs(dot3.Y - dot1.Y) > DELTA && Math.abs(dot3.Y - dot2.Y) > DELTA) {
    return false;
  }

  return true;
}

function MakeDot(
  owner: number,
  section: number,
  note: number,
  page: number,
  timestamp: bigint,
  x: number,
  y: number,
  tiltX: number,
  tiltY: number,
  twist: number,
  force: number,
  type: DotTypes,
  color: number
) {
  const builder = new NeoDotBuilder();

  builder.owner(owner)
    .section(section)
    .note(note)
    .page(page)
    .timestamp(timestamp)
    .coord(x, y)
    .tilt(tiltX, tiltY)
    .twist(twist)
    .force(force)
    .dotType(type)
    .color(color);
  const dot = builder.Build();

  return dot;
}


export class FilterForPaper {
  /* #region  Constants */
  private static readonly DELTA = 10;

  private static readonly MAX_X = 15070;

  private static readonly MAX_Y = 8480;

  private static readonly MAX_OWNER = 1024;

  private static readonly MAX_NOTE_ID = 16384;

  private static readonly MAX_PAGE_ID = 262143;
  /* #endregion */

  /* #region  Variables */
  private dot1!: NeoDot;

  private dot2!: NeoDot;

  private makeDownDot!: NeoDot;

  private makeMoveDot!: NeoDot;

  private secondCheck = true;

  private thirdCheck = true;
  /* #endregion */


  private resultDots!: NeoDot[];

  private addFilteredDotFunc(dot: NeoDot) {
    this.resultDots.push(dot);
  }

  public Put(dot: NeoDot) {
    this.resultDots = [];

    if (!ValidateCode(dot)) {
      return this.resultDots;
    }

    if (dot.DotType === DotTypes.PEN_DOWN) {
      this.dot1 = dot;
    } else if (dot.DotType === DotTypes.PEN_MOVE) {
      if (this.secondCheck) {
        this.dot2 = dot;
        this.secondCheck = false;
      } else if (this.thirdCheck) {
        if (ValidateStartDot(this.dot1, this.dot2, dot)) {
          this.addFilteredDotFunc(this.dot1);

          if (ValidateMiddleDot(this.dot1, this.dot2, dot)) {
            this.addFilteredDotFunc(this.dot2);
            this.dot1 = this.dot2;
            this.dot2 = dot;
          } else {
            this.dot2 = dot;
          }
        } else {
          this.dot1 = this.dot2;
          this.dot2 = dot;
        }

        this.thirdCheck = false;
      } else if (ValidateMiddleDot(this.dot1, this.dot2, dot)) {
        this.addFilteredDotFunc(this.dot2);
        this.dot1 = this.dot2;
        this.dot2 = dot;
      } else {
        this.dot2 = dot;
      }
    } else if (dot.DotType === DotTypes.PEN_UP) {
      let validateStartDot = true;
      let validateMiddleDot = true;

      if (this.secondCheck) {
        this.dot2 = this.dot1;
      }

      if (this.thirdCheck && this.dot1.DotType === DotTypes.PEN_DOWN) {
        if (ValidateStartDot(this.dot1, this.dot2, dot)) {
          this.addFilteredDotFunc(this.dot1);
        } else {
          validateStartDot = false;
        }
      }

      if (ValidateMiddleDot(this.dot1, this.dot2, dot)) {
        if (!validateStartDot) {
          this.makeDownDot = MakeDot(
            this.dot2.Owner || -1,
            this.dot2.Section || -1,
            this.dot2.Note || -1,
            this.dot2.Page || -1,
            this.dot2.Timestamp || BigInt(0),
            this.dot2.X || 0,
            this.dot2.Y || 0,
            this.dot2.TiltX || 0,
            this.dot2.TiltY || 0,
            this.dot2.Twist || 0,
            this.dot2.Force || 0,
            DotTypes.PEN_DOWN,
            this.dot2.Color || 0,
          );
          this.addFilteredDotFunc(this.makeDownDot);
        }

        this.addFilteredDotFunc(this.dot2);
      } else {
        validateMiddleDot = false;
      }

      // 마지막 Dot 검증
      if (ValidateEndDot(this.dot1, this.dot2, dot)) {
        if (!validateStartDot && !validateMiddleDot) {
          this.makeDownDot = MakeDot(
            dot.Owner || -1,
            dot.Section || -1,
            dot.Note || -1,
            dot.Page || -1,
            dot.Timestamp || BigInt(0),
            dot.X || 0,
            dot.Y || 0,
            dot.TiltX || 0,
            dot.TiltY || 0,
            dot.Twist || 0,
            dot.Force || 0,
            DotTypes.PEN_DOWN,
            dot.Color || 0,
          );

          this.addFilteredDotFunc(this.makeDownDot);
        }
        if (this.thirdCheck && !validateMiddleDot) {
          this.makeMoveDot = MakeDot(
            dot.Owner || -1,
            dot.Section || -1,
            dot.Note || -1,
            dot.Page || -1,
            dot.Timestamp || BigInt(0),
            dot.X || 0,
            dot.Y || 0,
            dot.TiltX || 0,
            dot.TiltY || 0,
            dot.Twist || 0,
            dot.Force || 0,
            DotTypes.PEN_MOVE,
            dot.Color || 0,
          );
          this.addFilteredDotFunc(this.makeMoveDot);
        }
        this.addFilteredDotFunc(dot);
      } else {
        this.dot2.DotType = DotTypes.PEN_UP;
        this.addFilteredDotFunc(this.dot2);
      }

      // Dot 및 변수 초기화
      this.dot1 = new NeoDot();
      this.dot2 = new NeoDot();
      this.secondCheck = true;
      this.thirdCheck = true;
    }

    return this.resultDots;
  }
}
