import { IPageSOBP } from "../../structures/Structures";

/**
 * get IPageSOBP from pageId string ("s.o.b.p")
 *
 * @static
 * @param {string} pageId
 * @return IPageSOBP
 * @memberof InkStorage
 */
export function getPageSOBP(pageId: string) {
  if (!pageId) return undefined as unknown as IPageSOBP;
  const arr = pageId.split(".");
  if (arr.length !== 4) return undefined as unknown as IPageSOBP;

  const ret: IPageSOBP = {
    section: parseInt(arr[0], 10),
    owner: parseInt(arr[1], 10),
    book: parseInt(arr[2], 10),
    page: parseInt(arr[3], 10),
  }

  return ret;
}
