import { useMemo } from 'react';
import { IRagInfo } from '../../../../repositories/model/transfer/IRagInfo';
import { IRagRegPages } from '../../../../repositories/model/transfer/IRagItem';
import { toJS } from 'mobx';

interface QuestionInfo {
	order?: number;
	projectExamQuestionNum?: number;
	projectExamCode?: string;
}

export interface UseRagResult {
	info: IRagInfo;
	refPages: IRagRegPages;
}

export function useRag(
	projectRagList: IRagInfo[],
	questionInfo: QuestionInfo
): UseRagResult {
	return useMemo(() => {
		for (let i = 0; i < projectRagList.length; i++) {
			for (let j = 0; j < projectRagList[i].rags.length; j++) {
				const rag = projectRagList[i].rags[j];
				const question = rag?.question;
				const { order, projectExamQuestionNum, projectExamCode } = questionInfo || {};

				if (
					question &&
					order === questionInfo.order &&
					projectExamQuestionNum === questionInfo.projectExamQuestionNum &&
					projectExamCode === questionInfo.projectExamCode
				) {
					const ret1 = projectRagList[i];
					const ret2 = projectRagList[i].rags[j];
					return {
						info: toJS(ret1),
						refPages: toJS(ret2),
					};
				}
			}
		}
		return null;
	}, [projectRagList, questionInfo]);
}

// 사용 예시
// const rag = useRag(projectRagList, questionInfo);
