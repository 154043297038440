/** @enum {string} */
export enum DeviceTypeEnum {
  PEN = "pen",
  ERASER = "eraser",
  PLAYER = "player",
  STYLUS = "stylus",
  FSIR = "fsir",
  NONE = "none",
}




/** @enum {number} */
export enum PEN_STATE {
  NONE = 0,
  PEN_DOWN = 1,
  PEN_MOVE = 2,
  PEN_UP = 9,

  HOVER_MOVE = 101,
}

