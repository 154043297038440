
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ResultReviewStyle } from "../../styles/ResultReviewStyle";

import { useTranslation } from 'react-i18next';
import { RubricItemRow } from "./RubricItemRow";
import { IRubricInfo } from "../../../../repositories/model/transfer/IRubricInfo";

type Props = {
	rubricInfo: IRubricInfo;
}



export const RubricTable = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( ResultReviewStyle );
	const { rubricInfo } = props;

	return (
		<TableContainer component={Paper} className={classes.tableBox2}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell width='25%' align="center">{i18next.t("평가항목")}</TableCell>
						<TableCell width='10%'></TableCell>
						<TableCell width='10%'>{i18next.t("배점")}</TableCell>
						<TableCell width='65%' align="center">{i18next.t("채점 기준")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow >
						{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
						<TableCell width='17%' rowSpan={rubricInfo.items?.length + 1 || 0} align="center">
							<Box display='flex' flexDirection='column' alignItems='center'>
								<Typography>{rubricInfo.name}</Typography>
							</Box>
						</TableCell>
					</TableRow>

					{rubricInfo?.items.length > 0 &&
						rubricInfo.items.map((item, idx) =>
							<RubricItemRow
								key={idx}
								itemInfo={item}
							/>
						)}

				</TableBody>
			</Table>
		</TableContainer>
	)
};
