import AuthStore from "./AuthStore";
import UserStore from "./UserStore";
import NavigateStore from "./NavigateStore";
import ProjectStore from "./ProjectStore";
import ProjectExamStore from "./ProjectExamStore";
import ProjectRagStore from "./ProjectRagStore";
import FileInfoStore from "./FileInfoStore";
import ProjectSubmissionStore from "./ProjectSubmissionStore";
import ProjectRubricStore from "./ProjectRubricStore";
import ProjectSubmissionAnswerStore from "./ProjectSubmissionAnswerStore";
import MessageStore from "./MessageStore";
import NeoPenStore from "./NeoPenStore";

import AuthRepository from "../repositories/AuthRepository";
import UserRepository from "../repositories/UserRepository";
import ProjectRepository from "../repositories/ProjectRepository";
import FileInfoRepository from "../repositories/FileInfoRepository";
import ProjectSubmissionRepository from "../repositories/ProjectSubmissionRepository";
import SystemRubricRepository from "../repositories/SystemRubricRepository";

import ProjectRagRepository from "../repositories/ProjectRagRepository";

import { serverContextPath } from "../AppConstants";
import ProjectSubmissionAnswerScoreRepository from "../repositories/ProjectSubmissionAnswerScoreRepository";
import ProjectSubmissionAnswerRepository from "../repositories/ProjectSubmissionAnswerRepository";
import ProjectExamQuestionRepository from "../repositories/ProjectExamQuestionRepository";
import AiRubricRepository from "../repositories/AiRubricRepository";
import AiScoreRepository from "../repositories/AiScoreRepository";
import NeoPenRepository from "../repositories/NeoPenRepository";
import StoreManager from "./StoreManager";
import NcodeAllocationStore, { ncodeAllocationRepository } from "./NcodeAllocationStore";

const repositoryProps = {
	serverContextPath: serverContextPath,
};

const authRepository = new AuthRepository(repositoryProps);
const userRepository = new UserRepository(repositoryProps);
const projectRepository = new ProjectRepository(repositoryProps);
const fileInfoRepository = new FileInfoRepository(repositoryProps);
const projectRagRepository = new ProjectRagRepository(repositoryProps);
const projectSubmissionRepository = new ProjectSubmissionRepository(repositoryProps);
const systemRubricRepository = new SystemRubricRepository(repositoryProps);
const projectSubmissionAnswerScoreRepository = new ProjectSubmissionAnswerScoreRepository(repositoryProps);
const projectSubmissionAnswerRepository = new ProjectSubmissionAnswerRepository(repositoryProps);
const projectExamQuestionRepository = new ProjectExamQuestionRepository(repositoryProps);
const aiRubricRepository = new AiRubricRepository(repositoryProps);
const aiScoreRepository = new AiScoreRepository(repositoryProps);
const neoPenRepository = new NeoPenRepository(repositoryProps);
const storeProps = {};
const storeManager = new StoreManager({ ...storeProps });
// const messageStore = new MessageStore({ ...storeProps });
const messageStore = new MessageStore();
const authStore = new AuthStore({ authRepository, ...storeProps });

const ncodeAllocationStore = new NcodeAllocationStore({ ncodeAllocationRepository, ...storeProps });


export const stores = {
	messageStore: messageStore,

	storeManager: storeManager,

	authStore: authStore,

	userStore: new UserStore({ userRepository, authStore, ...storeProps }),

	navigateStore: new NavigateStore(),

	projectStore: new ProjectStore({ projectRepository, messageStore, storeManager, ...storeProps }),

	projectExamStore: new ProjectExamStore({ projectRepository, projectExamQuestionRepository, messageStore, ...storeProps }),

	projectRagStore: new ProjectRagStore({ projectRagRepository, ...storeProps }),

	projectSubmissionStore: new ProjectSubmissionStore({ projectRepository, projectSubmissionRepository, projectSubmissionAnswerRepository, ...storeProps }),

	fileInfoStore: new FileInfoStore({ fileInfoRepository, ...storeProps }),

	projectRubricStore: new ProjectRubricStore({ projectRepository, systemRubricRepository, aiRubricRepository, messageStore, ...storeProps }),

	projectSubmissionAnswerStore: new ProjectSubmissionAnswerStore({ projectSubmissionAnswerRepository, projectSubmissionAnswerScoreRepository, aiScoreRepository, messageStore, ...storeProps }),

	neoPenStore: new NeoPenStore({ neoPenRepository, projectRepository, ncodeAllocationRepository, ...storeProps }),

	ncodeAllocationStore
};






