export enum SubjectType {
	KOREAN = "KOREAN",

	JAPANESE = "JAPANESE",
	MATH = "MATH",
	ENGLISH = "ENGLISH",
	SCIENCE = "SCIENCE",
	SOCIAL = "SOCIAL",
	SOCIAL_STUDIES = "SOCIAL_STUDIES",
	MUSIC = "MUSIC",
	ART = "ART",
	PHYSICAL_EDUCATION = "PHYSICAL_EDUCATION",
	HISTORY = "HISTORY",
	ETHICS = "ETHICS",
	SOCIAL_SCIENCE_BRANCH = "SOCIAL_SCIENCE_BRANCH",
	HISTORY_BRANCH = "HISTORY_BRANCH",
	ENVIRONMENT = "ENVIRONMENT",
	TECHNOLOGY_AND_HOME_ECONOMICS = "TECHNOLOGY_AND_HOME_ECONOMICS",
	INFORMATION = "INFORMATION",
	CHINESE_CHARACTERS = "CHINESE_CHARACTERS",
	HEALTH = "HEALTH",
	CAREER_AND_OCCUPATION = "CAREER_AND_OCCUPATION",
	DAILY_CHINESE = "DAILY_CHINESE",
	DAILY_JAPANESE = "DAILY_JAPANESE",
	ARTS = "ARTS",
	TECHNOLOGY_AND_HOME_ECONOMICS_INFORMATION = "TECHNOLOGY_AND_HOME_ECONOMICS_INFORMATION",
	SECOND_FOREIGN_LANGUAGE_CHINESE_CHARACTERS = "SECOND_FOREIGN_LANGUAGE_CHINESE_CHARACTERS",
	LIBERAL_ARTS = "LIBERAL_ARTS",
	PROFESSIONAL_COURSES = "PROFESSIONAL_COURSES",

	NONE = "NONE",
	DEFAULT = ""
}
