import { NU_TO_PU, PU_TO_NU } from "../constants/ncode-constants";
import { IBrushEnum } from "../enums/brush-type-enum";
import {
	StrokePageAttrEnum,
	StrokeStatusEnum,
} from "../enums/stroke-type-enums";
import { IPageSOBP, IPointRect } from "../structures/Structures";
import { SVGCommandItemType } from "../util/bezier/intersection/IntersectionTypes";
import { uuidv4 } from "../util/functions/uuidv4";
import { AffineMatrix } from "./AffineMatrix";
import { INeoStroke, NeoStrokeConstructor } from "./INeoStroke";
import { IOpenStrokeArg } from "./Interfaces/IOpenStrokeArg";
import { NeoStrokeProps } from "./Interfaces/NeoStrokeProps";
import { NeoDot } from "./dot/NeoDot";
import { INeoSmartpenType } from "./pen-types-type";


export class NeoStroke implements INeoStroke {
	// https://soopdop.github.io/2020/12/01/index-signatures-in-typescript/
	// [index: string]: any;

	// initial values
	key: string;

	get uuid() {
		return this.key;
	}

	set uuid(value: string) {
		this.key = value;
	}


	cmd?: 'add' | 'remove' | 'modify' | null | undefined;

	originalKey?: string;

	// from INeoStrokeProps
	section = undefined as unknown as number; // 3

	owner = undefined as unknown as number; // 27

	book = undefined as unknown as number; // 1089

	page = undefined as unknown as number; // 1

	pageUuid = undefined as unknown as string;

	noteUuid = undefined as unknown as string;

	mac = undefined as unknown as string; // '9c:7b:d2:53:09:66'

	brushType = undefined as unknown as IBrushEnum;

	thickness = undefined as unknown as number; // 0.20000000298023224

	color = undefined as unknown as string;

	status = undefined as unknown as StrokeStatusEnum;

	multiPage?: StrokePageAttrEnum;

	penTipMode = undefined as unknown as number; // 0: pen, 1: eraser, , 9: Erased Stroke

	inputType = undefined as unknown as INeoSmartpenType;

	// own properties
	// dotsEncoded: string;    //'AACNjAw/heuxQXsUB0JuZ5ILANLRUT9cj7JBj8IHQpNokgwA+/p6PylcsUGuRwhCnWeSCwD9/Hw/cT2uQXsUCUKiaJIMAAAAgD97FKpBXI8KQp9mkgsAAACAPwrXo0H2KAxCoHGSDAAAAIA/cT2eQQrXDUKaZpELAAAAgD97FJZB4XoOQqZ8kQwAAACAP3sUkEFSuA5CoGSQDAAAAIA/9iiKQexRDkKgWo8KAAAAgD8UroNB7FEMQp9XjwwAAACAP/YogEEzMwpCmFuOOwAAAIA/SOGAQY/C/UGfTY4LAAAAgD+F64VBmpn5QaJUjwwAAACAP83MjEEpXPdBnk+PCwAAAIA/CteTQWZm9kGgU48XAAAAgD+F651BSOH4QZpTkQwAAACAP/YooEHsUfxBnFKRIwAAAIA/cT2iQdejBUKbWJIKAAAAgD+amaFBPQoJQppbkQwAAACAP7gen0EK1wxCmFmRDAAAAIA/ZmaaQSlcEEKXZpEMAP38fD8fhZVBj8IUQpJlkAsAvLs7P7gej0FcjxlCjXCQDADR0NA+ZmaIQaRwHUJxc48LAIWEhD5mZoJBFK4eQmxxkAwAlZQUPoXreUGF6x1CaW+QEQDx8PA9H4VzQY/CG0JoZZA='
	strokeType = 0 as unknown as number; // 0

	updated = 0 as unknown as number; // 1597202355308

	startTime = 0; // 1597202355308

	_endTime = 0; // 1597202355308

	duration = 0 as unknown as number; // last dot's time;  //first dot's time, it should be increase by 1 to use properly

	max_force = Number.MIN_SAFE_INTEGER;

	min_force = Number.MAX_SAFE_INTEGER; // 1

	sum_force = 0;

	max_speed = Number.MIN_SAFE_INTEGER; // 1

	min_speed = Number.MAX_SAFE_INTEGER; // 1

	sum_dist = 0;

	time_len = undefined as unknown as number; // 1

	dotCount = 0;

	dotArray: NeoDot[] = [];

	opened = true;

	isErasedOfHistory = false;

	/** 펜으로 쓴 표면의 소유자 id, 선생님 펜으로 학생 공책에 쓰면 학생이 소유자, 2021/06/09 */
	surfaceOwnerId?: string;

	/** 펜의 소유자 id, 선생님 펜으로 학생 공책에 쓰면 선생님이 소유자, 2021/06/09 */
	writerId?: string;

	// 2021/12/28, inkstore에 upload 된 것인지 아닌지
	uploaded?: boolean;

	/** 2021/09/21, maxPenPressure 값 */
	maxPenPressureValue!: number;

	private _boundingBox: IPointRect = null;

	public get boundingBox() {
		if (!this._boundingBox) {
			this._boundingBox = {
				left: Number.MAX_SAFE_INTEGER,
				top: Number.MAX_SAFE_INTEGER,
				right: Number.MIN_SAFE_INTEGER,
				bottom: Number.MIN_SAFE_INTEGER,
			}

			this.rebuildBoundingBox();
		};

		if (!this.affineMatrix && !this.editingAffineMatrix) return this._boundingBox;

		const m = AffineMatrix.multiply(this.editingAffineMatrix, this.affineMatrix);
		let { left, top, right, bottom } = this._boundingBox;
		const lt = m.getTransformedXY(left * NU_TO_PU, top * NU_TO_PU);
		const rb = m.getTransformedXY(right * NU_TO_PU, bottom * NU_TO_PU);
		left = lt.x * PU_TO_NU;
		top = lt.y * PU_TO_NU;
		right = rb.x * PU_TO_NU;
		bottom = rb.y * PU_TO_NU;

		return { left, top, right, bottom };
	}

	public set boundingBox(value: IPointRect) {
		this._boundingBox = value;
	}


	pathSVG: string = undefined;

	pathPDF: string = undefined;

	// path2D: Path2D = undefined;

	uploadTime: number = 0;

	selected: boolean = false;

	svgOffset = { ox_pu: null, oy_pu: null, thicknessScale: null }


	/**
	 * Edit mode에서, 변형이 완료된 matrix
	 *
	 * @private
	 * @type {AffineMatrix}
	 * @memberof NeoStroke
	 */
	affineMatrix: AffineMatrix = null;

	/**
	 * Edit mode에서 lasso로 묶여서 현재 변형 중인 matrix
	 *
	 * @private
	 * @type {AffineMatrix}
	 * @memberof NeoStroke
	 */
	editingAffineMatrix: AffineMatrix = null;

	// pathDataArray: {
	//   bezier?: SVGCommandItemType[];
	//   goingBezier?: SVGCommandItemType[];
	//   endCapBezier?: SVGCommandItemType[];
	//   returningBezier?: SVGCommandItemType[];
	// } = {};

	get endTime() {
		if (this._endTime === 0) {
			let end = this.startTime + this.dotArray.reduce((ts, dot) => ts + dot.deltaTime, 0);
			this._endTime = end;
		}

		return this._endTime;
	}

	set endTime(value: number) {
		this._endTime = value;
	}

	public resetEndTime() {
		this._endTime = 0;
	}

	public clone(addDotArray = true) {
		const stroke = this;
		const { mac, thickness, brushType, color, inputType, startTime, originalKey, section, owner, book, page, status, writerId, surfaceOwnerId, noteUuid, pageUuid, affineMatrix, selected } = stroke;
		const strokeProps: NeoStrokeProps = {
			mac, thickness, brushType, color, inputType, startTime, originalKey, section, owner, book, page, status, writerId, surfaceOwnerId,
			dotArray: [], noteUuid: noteUuid, pageUuid, selected
		};

		const ret = new NeoStroke(strokeProps);

		ret.svgOffset = { ...stroke.svgOffset };
		if (affineMatrix) {
			ret.setAffineMatrix(affineMatrix.clone());
		}
		else {
			ret.resetAffineMatrix();
		}

		if (addDotArray) {
			for (let i = 0, ln = this.dotArray.length; i < ln; ++i) {
				const dot = this.dotArray[i];
				ret.addDot(dot.clone());
			}
		}
		return ret as any as INeoStroke;
	}

	get avr_force() {
		return this.sum_force / this.dotCount;
	}

	get avr_speed() {
		return this.sum_dist / this.duration;
	}

	constructor(props: NeoStrokeProps) {
		// from INeoStrokeProps
		this.key = props.originalKey || uuidv4();

		Object.keys(props).forEach((k) => {
			//maui의 Local DB에서 stroke를 받아 NeoStroke로 만드는 경우 key를 lower case로 바꿔주는 작업
			const key = k.charAt(0).toLowerCase() + k.slice(1);
			this[key] = props[k];
			if (key === 'dotArray') {
				for (let i = 0; i < props[k].length; i++) {
					Object.keys(props[k][i]).forEach((dotArrayKey) => {
						const lowerDotArrayKey = dotArrayKey.charAt(0).toLowerCase() + dotArrayKey.slice(1);
						this[key][i][lowerDotArrayKey] = props[k][i][dotArrayKey];
					});
				}
			}
		});
	}

	get sobp() {
		const { section, owner, book, page, pageUuid, noteUuid } = this;
		return { section, owner, book, page, pageUuid, noteUuid: noteUuid } as IPageSOBP;
	}

	toObject() {
		return {
			key: this.key,
			cmd: this.cmd,
			originalKey: this.originalKey,
			section: this.section,
			owner: this.owner,
			book: this.book,
			page: this.page,
			pageUuid: this.pageUuid,
			noteUuid: this.noteUuid,
			mac: this.mac,
			brushType: this.brushType,
			thickness: this.thickness,
			color: this.color,
			status: this.status,
			multiPage: this.multiPage,
			penTipMode: this.penTipMode,
			inputType: this.inputType,
			strokeType: this.strokeType,
			updated: this.updated,
			startTime: this.startTime,
			_endTime: this._endTime,
			duration: this.duration,
			max_force: this.max_force,
			min_force: this.min_force,
			sum_force: this.sum_force,
			max_speed: this.max_speed,
			min_speed: this.min_speed,
			sum_dist: this.sum_dist,
			time_len: this.time_len,
			dotCount: this.dotCount,
			dotArray: this.dotArray,
			opened: this.opened,
			surfaceOwnerId: this.surfaceOwnerId,
			writerId: this.writerId,
			uploaded: this.uploaded,
			maxPenPressureValue: this.maxPenPressureValue,
			pathSVG: this.pathSVG,
			pathPDF: this.pathPDF,
			uploadTime: this.uploadTime,
			selected: this.selected,
			affineMatrix: this.affineMatrix,
			editingAffineMatrix: this.editingAffineMatrix,
			svgOffset: this.svgOffset
		};
	}

	static fromObject(obj: any): NeoStroke {
		const stroke = new NeoStroke(obj);
		stroke.key = obj.key;
		stroke.cmd = obj.cmd;
		stroke.originalKey = obj.originalKey;
		stroke.section = obj.section;
		stroke.owner = obj.owner;
		stroke.book = obj.book;
		stroke.page = obj.page;
		stroke.pageUuid = obj.pageUuid;
		stroke.noteUuid = obj.noteUuid;
		stroke.mac = obj.mac;
		stroke.brushType = obj.brushType;
		stroke.thickness = obj.thickness;
		stroke.color = obj.color;
		stroke.status = obj.status;
		stroke.multiPage = obj.multiPage;
		stroke.penTipMode = obj.penTipMode;
		stroke.inputType = obj.inputType;
		stroke.strokeType = obj.strokeType;
		stroke.updated = obj.updated;
		stroke.startTime = obj.startTime;
		stroke._endTime = obj._endTime;
		stroke.duration = obj.duration;
		stroke.max_force = obj.max_force;
		stroke.min_force = obj.min_force;
		stroke.sum_force = obj.sum_force;
		stroke.max_speed = obj.max_speed;
		stroke.min_speed = obj.min_speed;
		stroke.sum_dist = obj.sum_dist;
		stroke.time_len = obj.time_len;
		stroke.dotCount = obj.dotCount;
		stroke.dotArray = obj.dotArray;
		stroke.opened = obj.opened;
		stroke.surfaceOwnerId = obj.surfaceOwnerId;
		stroke.writerId = obj.writerId;
		stroke.uploaded = obj.uploaded;
		stroke.maxPenPressureValue = obj.maxPenPressureValue;
		stroke.pathSVG = obj.pathSVG;
		stroke.pathPDF = obj.pathPDF;
		stroke.uploadTime = obj.uploadTime;
		stroke.selected = obj.selected;
		stroke.affineMatrix = obj.affineMatrix;
		stroke.editingAffineMatrix = obj.editingAffineMatrix;
		stroke.svgOffset = obj.svgOffset;
		return stroke;
	}

	private addDotOnBoundingBox(dot: NeoDot) {
		let { x, y } = dot;

		if (!this._boundingBox) {
			this._boundingBox = {
				left: Number.MAX_SAFE_INTEGER,
				top: Number.MAX_SAFE_INTEGER,
				right: Number.MIN_SAFE_INTEGER,
				bottom: Number.MIN_SAFE_INTEGER,
			}
		}


		if (x < this._boundingBox.left) {
			this._boundingBox.left = x;
		}

		if (x > this._boundingBox.right) {
			this._boundingBox.right = x;
		}

		if (y < this._boundingBox.top) {
			this._boundingBox.top = y;
		}

		if (y > this._boundingBox.bottom) {
			this._boundingBox.bottom = y;
		}
	}

	addDot(dot: NeoDot) {
		// 필기 압력 처리
		this.min_force = Math.min(dot.f, this.min_force);
		this.max_force = Math.max(dot.f, this.max_force);
		this.sum_force += dot.f;

		// bounding box 처리
		this.addDotOnBoundingBox(dot);

		// count
		this.dotArray.push(dot);
		this.dotCount++;
	};

	public rebuildBoundingBox() {
		const dots = this.dotArray;
		const len = dots.length;
		this._boundingBox = {
			left: Number.MAX_SAFE_INTEGER,
			top: Number.MAX_SAFE_INTEGER,
			right: Number.MIN_SAFE_INTEGER,
			bottom: Number.MIN_SAFE_INTEGER,
		};

		for (let i = 0; i < len; ++i) {
			this.addDotOnBoundingBox(dots[i]);
		}
	}

	close() {
		this.opened = false;
	};

	setMultiPage(multiPage: StrokePageAttrEnum) {
		this.multiPage = multiPage;
	};

	setBrushType(brushType: IBrushEnum) {
		this.brushType = brushType;
	};

	static openStroke(args: IOpenStrokeArg) {
		const {
			mac,
			time,
			thickness,
			brushType,
			color,
			inputType: deviceType,
			writerId,
			originalKey,
			penTipMode
		} = args;

		// console.log(`brushType: ${brushType}, thickness: ${thickness}, color: ${color}, deviceType: ${deviceType}, writerId: ${writerId}, originalKey: ${originalKey}, penTipMode: ${penTipMode}`)
		const surfaceOwnerId = args.surfaceOwnerId || writerId;

		const strokeProps: NeoStrokeProps = {
			// uuid,
			mac,
			thickness,
			brushType,
			color,
			inputType: deviceType,
			startTime: time,
			originalKey,
			section: -1,
			owner: -1,
			book: -1,
			page: -1,
			penTipMode,
			status: brushType === IBrushEnum.ERASER ? StrokeStatusEnum.ERASED : StrokeStatusEnum.NORMAL,

			writerId,
			surfaceOwnerId,

			dotArray: [],
		};

		const stroke = new NeoStroke(strokeProps);
		return stroke;
	};

	public setStrokeInfo(
		sobp: IPageSOBP,
		time: number
	) {
		const { section, owner, book, page, pageUuid, noteUuid: noteUuid } = sobp;
		this.section = section;
		this.owner = owner;
		this.book = book;
		this.page = page;

		this.noteUuid = noteUuid;
		this.pageUuid = pageUuid;
		this.startTime = time;
	};

	public setStrokeSOBP(
		section: number,
		owner: number,
		book: number,
		page: number,
		noteUuid: string,
		pageUuid: string
	) {
		this.section = section;
		this.owner = owner;
		this.book = book;
		this.page = page;
		this.noteUuid = noteUuid;
		this.pageUuid = pageUuid;
	}

	public appendDot(dot: NeoDot) {
		this.addDot(dot);
	};

	public closeStroke() {
		return this as any as INeoStroke;
	};


	public isMarker() {
		return this.brushType === IBrushEnum.MARKER;
	}

	public isEraser() {
		return this.brushType === IBrushEnum.ERASER || this.brushType === IBrushEnum.ERASERPEN;;
	}




	public setSelected(selected: boolean) {
		this.selected = selected;
	}

	public appendAffineMatrix(affine: AffineMatrix) {
		this.affineMatrix = AffineMatrix.multiply(affine, this.affineMatrix);
		this.pathSVG = null;
	}


	public setAffineMatrix(affine: AffineMatrix) {
		this.affineMatrix = affine;

		this.pathSVG = null;
	}

	public setEditingAffineMatrix(affine: AffineMatrix) {
		this.editingAffineMatrix = affine;

		this.pathSVG = null;
	}

	public resetEditingAffineMatrix() {
		if (this.editingAffineMatrix) {
			this.pathSVG = null;
		}
		delete this.editingAffineMatrix;
	}

	public resetAffineMatrix() {
		if (this.affineMatrix) {
			this.pathSVG = null;
		}
		this.affineMatrix = null;
	}

	public getAffineMatrix() {
		if (!this.editingAffineMatrix && !this.affineMatrix) return null;
		return AffineMatrix.multiply(this.editingAffineMatrix, this.affineMatrix);
	}

	/**
	 * affineMatrix의 단위를 Ncode 단위라고 해석해서 transform을 적용
	 * @returns
	 */

	public applyAffineMatrixToDots_nu() {
		if (!this.affineMatrix) return;

		this.pathSVG = null;
		const m = this.affineMatrix;
		for (let i = 0, ln = this.dotArray.length; i < ln; i++) {
			const { x, y } = this.dotArray[i];
			const { x: nx, y: ny } = m.getTransformedXY(x, y);
			this.dotArray[i].x = nx;
			this.dotArray[i].y = ny;
		}

		this.resetEditingAffineMatrix();
		this.resetAffineMatrix();
		this.rebuildBoundingBox();
	}


	public createTranslatedDots_nu(m: AffineMatrix) {
		const ret: { x: number, y: number }[] = [];
		for (let i = 0, ln = this.dotArray.length; i < ln; i++) {
			const { x, y } = this.dotArray[i];
			const { x: nx, y: ny } = m.getTransformedXY(x, y);
			ret.push({
				x: nx,
				y: ny
			});
		}

		return ret;
	}


	/**
	 * affineMatrix의 단위를 PU 단위라고 해석해서 transform을 적용
	 * @returns
	 */

	public applyAffineMatrixToDots() {
		if (!this.affineMatrix) return;

		this.pathSVG = null;
		const m = this.affineMatrix;
		for (let i = 0, ln = this.dotArray.length; i < ln; i++) {
			const { x, y } = this.dotArray[i];
			const { x: nx, y: ny } = m.getTransformedXY(x * NU_TO_PU, y * NU_TO_PU);
			this.dotArray[i].x = nx * PU_TO_NU;
			this.dotArray[i].y = ny * PU_TO_NU;
		}

		this.resetEditingAffineMatrix();
		this.resetAffineMatrix();
		this.rebuildBoundingBox();
	}


	// // FIXME: debugging용, kitty, 2024-03-15, 메모리 많이 쓰니 지울 것
	// public controlPoints: {
	//   bezier: SVGCommandItemType[];
	//   goingBezier: SVGCommandItemType[];
	//   returningBezier: SVGCommandItemType[];
	//   endCapBezier: SVGCommandItemType[];
	// } = null;


}


(NeoStroke as NeoStrokeConstructor);