import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography
} from "@mui/material";
import useEmotionStyles from "./styles/useEmotionStyles";
import { PrintEmptyStyle } from "./styles/PrintEmptyStyle";

import clsx from "clsx";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import { ChangeFlag } from "../../repositories/model/support/ChangeFlag";
import { ProjectExamType } from "../../repositories/model/support/ProjectExamType";
import { convertSubjectTypeEnToKr } from "../../stores/ProjectStore";
import { getSemesterTypeString, getYearSemesterTitle } from "./000_Common/001_Functions/getSemesterTypeString";
import { PrintWorksheetHeader } from "./000_Common/PrintWorksheetHeader";

type Props = {
	projectExamList: any;
	project: any;
	renderList: any;
};

function PrintAnswerSheet(props: Props) {
	const classes = useEmotionStyles( PrintEmptyStyle );
	const i18next = useTranslation();

	const { projectExamList, project, renderList } = props;

	const handleClick = () => {
		window.print();
	}

	// const handleClickPrint = () => {
	// 	window.print();
	// }

	const renderHeader = (project) => {
		return (
			<TableContainer component={Paper} className={classes.tableBoxForPrint}>
				<PrintWorksheetHeader
					titleBoxClassName={classes.titleBox}
					projectTargetGroupName={project.targetGroupName}
					projectYear={project.year}
					projectSemester={project.semesterType}
					projectSubjectName={project.subjectName}
				/>
			</TableContainer>
		)
	}

	const examQuestionNum = (projectExamList, examInfo, questionInfo) => {
		let questionNum = 0;
		for (let i = 0; i < examInfo.order; i++) {
			if (i + 1 === examInfo.order) {
				questionNum = questionNum + questionInfo.order;
			} else {
				for (let j = 0; j < projectExamList[i].questions.length; j++) {
					questionNum++;
				}
			}
		}
		return questionNum;
	}

	return (
		<div className={classes.root} onClick={handleClick}>
			<Box className={classes.padding}>
				<Box className={classes.mainContentBox}>
					<Box className={classes.titleBoxStudent}>
						<Typography>{project && project.name}</Typography>
					</Box>
					<TableContainer component={Paper} className={classes.tableBox}>
						<Table aria-label="simple table">
							<TableBody>
								<TableRow>
									<TableCell align="center" className={classes.titleBox}>
										<Typography className={classes.headerFont}>
											{i18next.t("학교명")}
										</Typography>
									</TableCell>
									<TableCell >
										<Typography className={classes.headerFont}>
											{project.targetGroupName}
										</Typography>
									</TableCell>
									<TableCell align="center" className={classes.titleBox}>
										<Typography className={classes.headerFont}>
											{getYearSemesterTitle(project?.year, project?.semesterType)}
										</Typography>
									</TableCell>
									<TableCell >
										<Typography className={classes.headerFont}>
											{getSemesterTypeString(project?.year, project?.semesterType)}
										</Typography>
									</TableCell>
									<TableCell align="center" className={classes.titleBox}>
										<Typography className={classes.headerFont}>
											{i18next.t("과목")}
										</Typography>
									</TableCell>
									<TableCell >
										<Typography className={classes.headerFont}>
											{convertSubjectTypeEnToKr(project.subject)}
										</Typography>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="center" className={classes.titleBox}>
										<Typography className={classes.headerFont}>
											{i18next.t("학년/반/번호")}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography className={classes.cellFont}>
											{i18next.t("(&nbsp;&nbsp;&nbsp;)학년 (&nbsp;&nbsp;&nbsp;)반 (&nbsp;&nbsp;&nbsp;)번")}
										</Typography>
									</TableCell>
									<TableCell align="center" className={classes.titleBox}>
										<Typography className={classes.headerFont}>
											{i18next.t("이름")}
										</Typography>
									</TableCell>
									<TableCell colSpan={3} >
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					{projectExamList && projectExamList.length > 0 &&
						projectExamList.filter(e => e.changeFlag !== ChangeFlag.DELETED)
							.map((exam, index) => {
								return (
									<div key={`${index}Question`}>
										{exam.questions
											.filter(q => q.changeFlag !== ChangeFlag.DELETED)
											.map((question, questionIndex) => {
												return (
													<>
														{exam.questions
															.filter(q => q.changeFlag !== ChangeFlag.DELETED).length > 1 ?
															<>
																{renderList[examQuestionNum(projectExamList, exam, question) - 1] &&
																	<>
																		<Box className={classes.divinePage} key={`question padding${index}${questionIndex}`} />
																		{renderHeader(project)}
																	</>
																}
																<Typography className={classes.questionList} key={`question text${index}${questionIndex}`}>
																	{i18next.t("문항")} {exam.order}-{question.order}
																</Typography>
																{exam.type === ProjectExamType.DESCRIPTIVE ?
																	<Box className={clsx(classes.bestAnswerBox, classes.bestAnswerBoxHeight)} key={`question box${index}${questionIndex}`} />
																	:
																	<Box className={clsx(classes.bestAnswerBox, classes.bestAnswerBoxMuti)} key={`question box${index}${questionIndex}`} />
																}
															</> :
															<>
																{renderList[examQuestionNum(projectExamList, exam, question) - 1] &&
																	<>
																		<Box className={classes.divinePage} key={`question padding${index}${questionIndex}`} />
																		{project && renderHeader(project)}
																	</>
																}
																<Typography className={classes.questionList} key={`question text${index}${questionIndex}`}>
																	{i18next.t("문항")} {exam.order}
																</Typography>
																{exam.type === ProjectExamType.DESCRIPTIVE ?
																	<Box className={clsx(classes.bestAnswerBox, classes.bestAnswerBoxHeight)} key={`question answerBox${index}${questionIndex}`} />
																	:
																	<Box className={clsx(classes.bestAnswerBox, classes.bestAnswerBoxMuti)} key={`question answerBox${index}${questionIndex}`} />
																}
															</>
														}
													</>
												)
											})
										}
									</div>
								)
							})}
				</Box>
			</Box>
		</div>
	);
}

export default observer<typeof PrintAnswerSheet>(PrintAnswerSheet);
