import React from "react";
import { getBrowserAndDeviceInfo, OperatingSystemInfo } from "../neolab-libs/nl-lib3-common";
import { initMauiToJsBridge, MauiRelaySocket } from "../neolab-libs/nl-lib3-pengateway/src";
import { openAppWithDeepLinkOnly } from "../neolab-libs/nl-lib3-pengateway/src/applink-handler/openAppWithDeepLink";
import { DevServerEventName, DotNetApi, IDevServerEvent } from "../neolab-libs/nl-lib3-pengateway/src/nl-lib-maui";


function useOSAndBrowserInfo(appUrl: string, open: boolean) {
	const [osAndBrowser, setOsAndBrowser] = React.useState(getBrowserAndDeviceInfo());

	const [isWebSocketConnected, setIsWebSocketConnected] = React.useState(MauiRelaySocket.instance.connected);

	const [focusOut, setFocusOut] = React.useState(false);


	React.useEffect(() => {
		if (!isWebSocketConnected) {
			DotNetApi.instance.connectWebSocket().then((connected) => {
				setIsWebSocketConnected(connected);
			});
		}


		const info = getBrowserAndDeviceInfo();
		setOsAndBrowser(info);

		// socket 연결 상태 변경 이벤트
		const onWebSocketStatusChanged = (event: IDevServerEvent) => {
			if (event.msg === "closed") {
				setTimeout(() => {
					setIsWebSocketConnected(false);
				}, 1000);
			} else if (event.msg === "open")
				setIsWebSocketConnected(true);
		}

		DotNetApi.instance.addEventListener(DevServerEventName.ON_CONNECTION_CHANGED, onWebSocketStatusChanged);

		const onBlurWindow = () => {
			console.log("deeplink: Window lost focus");
			setFocusOut(true);

		}

		const onFocusWindow = async () => {
			console.log("deeplink: Window gained focus");
			setFocusOut(false);
		}

		window.addEventListener("blur", onBlurWindow);
		window.addEventListener("focus", onFocusWindow);

		return () => {
			DotNetApi.instance.removeEventListener(DevServerEventName.ON_CONNECTION_CHANGED, onWebSocketStatusChanged);

			window.removeEventListener("blur", onBlurWindow);
			window.removeEventListener("focus", onFocusWindow);
		}
	}, []);

	React.useEffect(
		() => {
			const checkAndGotoDownloadPage = async () => {
				if (osAndBrowser.os !== OperatingSystemInfo.Windows) return;

				let connected = isWebSocketConnected;
				if (!isWebSocketConnected) {
					for (let i = 0; i < 3 && !connected; i++) {
						connected = await initMauiToJsBridge();
						await new Promise((resolve) => setTimeout(resolve, 500));
					}
				}

				if (!connected && open) {
					try {
						const result = await openAppWithDeepLinkOnly({ appScheme: `${appUrl}://` });
						console.log(`App installed: ${result.installed}, App launched: ${result.launched}`);

						if (result.launched) {
							console.log(`App launched with ${appUrl}`);
							setIsWebSocketConnected(true);
						} else {
							setTimeout(async () => {
								connected = await initMauiToJsBridge();
								setIsWebSocketConnected(connected);
							}, 500);
						}

					} catch (e) {
						console.error(e);
					}
				}
			}

			checkAndGotoDownloadPage();
		}, [osAndBrowser.os, isWebSocketConnected, appUrl]
	);

	return { ...osAndBrowser, isWebSocketConnected, focusOut };
}

export default useOSAndBrowserInfo;