import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const ProjectPreviewStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
	},
	scrollBox: {
		// borderTop: '1px solid #cbcbcb',
		overflowY: 'auto',
		height: 'calc(100vh - 280px)',
		// marginTop: "30px",
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	mainContentBoxRight: {
		border: '1px solid #EAECEB',
		padding: 20,
		paddingRight: 0,
		height: 'calc(100vh - 90px)',
		// overflowY: 'auto',
		background: '#fff',
		width: '620px',
		boxSizing: 'border-box',
		marginLeft: "10px",
		'@media all and (max-width: 1600px)': {
			width: '100%',
			minWidth: 460,
		},
	},
	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			// marginLeft: "10px",
		}
	},
	editorIcon: {
		'&.MuiIconButton-root': {
			marginLeft: "8px",
			marginBottom: "13px",
		}
	},
	displayStyle: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'flex-start',
		marginBottom: "17px",
	},
	basicTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			fontWeight: 400,
			letterSpacing: '-0.4px',
			width: "120px",
		}
	},
	basicText: {
		paddingRight: 15,
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 500,
			letterSpacing: '-0.4px',
			width: '100%'
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},

	commonSubTitle: {
		marginBottom: "10px",
		paddingTop: "10px",
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			'& span': {
				color: '#008C73'
			}
		}
	},

	taskTitleBold: {
		'&.MuiTypography-root': {
			color: '#111',
			// fontSize: '1.25rem',
			fontSize: '1rem',
			letterSpacing: '-1px',
			marginBottom: "15px",
			fontWeight: 500,
			// fontWeight: 'bold',
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	searchSchoolInput: {
		'& .MuiFormControl-root': {
			maxWidth: 830,
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 50
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},
	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},
	/////////////////////
	sideBarContent: {
		// width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},

	/////////////////////
	padding: {
		padding: 15,
	},
	mainContentBox: {
		border: '1px solid #CBCBCB',
		padding: 20,
		width: '100%',
		height: 'calc(100vh - 145px)',
		overflowY: 'auto',
		background: '#fff',
	},
	mainContentBoxLeft: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 130px)',
		background: '#fff',
		width: 'calc(100vw - 770px)',





	},
	mainContentBoxLeftInner: {
		height: 'calc(100vh - 200px)',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px"
		},
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
		}
	},
	taskTitle: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			marginBottom: "15px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	tableBox: {
		marginTop: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1rem',
				color: '#323232',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.2rem',
				color: '#323232',
				// height: "54px",
				minHeight: 54,
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				borderRight: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
				'& .MuiTypography-root': {
					fontSize: '0.75rem',
					color: '#323232',
					letterSpacing: '-0.5px',
					whiteSpace: 'break-spaces',
					wordBreak: 'break-all'
				}
			}
		}
	},

	viewerBox: {
		border: '2px solid #111',
		padding: '0px 10px 0px 10px',
		// maxHeight: '300px',
		marginBottom: '10px',
		overflowY: 'auto',
		'& .toastui-editor-contents': {
			// height: '170px'
		},
		'& div': {
			marginBottom: '4px'
		}
	},



	emptyAnswerBox: {
		border: '2px solid black',
		padding: '0px 10px 0px 10px',
		height: '300px',
		marginBottom: '30px'
	},

	// 2024.04.03, hkkim@onthelive. 추가 -->
	lightBorderViewerBox: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		padding: '6px 13px',
		// maxHeight: '300px',
		marginBottom: '10px',
		overflowY: 'auto',

		'& .toastui-editor-contents': {
			fontSize: '1rem',
		},
	},

	lightBorderEmptyAnswerBox: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		padding: '16px 13px',
		// minHeight: '150px',
		minHeight: '75px',
		marginTop: '10px',
		marginBottom: "20px"
	},

	lightBorderAnswerBox: {
		// minHeight: '150px',
		minHeight: '75px',
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		padding: '6px 13px',
		// padding: 14,
		marginTop: "10px",
		marginBottom: "30px",
		display: 'flex',
		alignItems: 'center'
	},

	lightQuestionSentence: {
		'& p': {
			// fontSize: '1.25rem', // 20px
			// fontSize: '1.125rem', // 18px
			fontSize: '1rem', // 16px
			letterSpacing: '-0.8px',
			fontWeight: '500',
			lineHeight: 'normal',
			color: '#111'
		}
	},

	lightBorderTaskBox: {
		border: '1px solid #cbcbcb',
		borderRadius: 3,
		padding: '6px 13px',
		marginTop: "20px",
		// minHeight: '150px',
		minHeight: '75px',
		display: 'flex',
		alignItems: 'center'
	},
	// <--

	answerBox: {
		border: '2px solid black',
		// padding: '0px 10px 0px 10px',
		// height: '310px',
		// marginBottom: '30px',
		// overflowY: 'auto',
		// '& .toastui-editor-contents': {
		//     height: '300px'
		// }

		borderRadius: 3,
		padding: 14,
		marginTop: "10px",
		marginBottom: '30px',
	},

	trigger: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		//width: "441px", // 440 + 1 : scrolling에 필요
		// marginBottom: "34px",
		'&.MuiTabs-root': {
			minHeight: 40
		},
		'& .MuiTabs-flexContainer': {
			// width: "208px",
			overflow: 'hidden',
		},
		'& button': {
			maxWidth: '140px',
			minWidth: 'auto',
			minHeight: 36,
			height: "36px",
			position: 'relative',
			fontSize: '0.875rem',
			letterSpacing: '-0.5px',
			color: '#787878',
			boxSizing: 'border-box',
			padding: '0px 20px',
			background: '#fff',
			borderBottom: '1px solid #cbcbcb',
			'& .MuiTab-wrapper': {
				fontSize: '0.938rem',
				color: '#787878'
			}
		},
		'& button.Mui-selected': {
			fontWeight: 'bold',
			color: '#000',
			opacity: 1,
			boxSizing: 'border-box',
			background: '#F2F7F3',
			borderBottom: '1px solid #01261C',
			'& .MuiTab-wrapper': {
				color: '#01261C'
			}
		},
		'& .MuiTabs-indicator': {
			height: "0px",
		}
	},
	trigger2: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: "510px",
		marginBottom: "34px",
		'&.MuiTabs-root': {
			minHeight: 40
		},
		'& .MuiTabs-flexContainer': {
			// width: "208px",
			border: '1px solid #F3F3F3',
			borderRadius: 8,
			overflow: 'hidden',
		},
		'& button': {
			width: "85px",
			minWidth: 85,
			minHeight: 36,
			height: "36px",
			position: 'relative',
			fontSize: '0.675rem',
			letterSpacing: '-0.5px',
			color: '#787878',
			boxSizing: 'border-box',
			padding: 0,
			background: '#fff',
			'& .MuiTab-wrapper': {
				fontSize: '0.675rem',
				color: '#111'
			}
		},
		'& button.Mui-selected': {
			fontWeight: 'bold',
			color: '#fff',
			opacity: 1,
			boxSizing: 'border-box',
			background: '#2F80ED',
			borderRadius: 6,
			'& .MuiTab-wrapper': {
				color: '#fff'
			}
		},
		'& .MuiTabs-indicator': {
			height: "0px",
		}
	},
	previewText: {
		'&.MuiTypography-root': {
			color: '#008C73',
			fontWeight: 'bold',
			fontSize: '1.25rem',
			marginBottom: "10px",
		}
	},
	previewSubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			marginBottom: "10px",
		}
	},
	previewRagSubText1: {
		// minWidth: '76px',
		'&.MuiTypography-root': {
			// color: '#555',
			fontSize: '1rem',
			// marginBottom: "10px",
		}
	},
	previewRagSubText2: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			// marginBottom: "10px",
		}
	},
	pageUpIcon: {
		position: 'absolute',
		bottom: '23px',
		right: 20,
		'&.MuiIconButton-root': {
			border: '1px solid #fff',
			boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
			background: '#fff',
			width: "40px",
			height: "40px",
			'&:hover': {
				background: '#fff'
			}

		}
	},

	bgColor: {
		// background: '#F3F3F3'
		background: 'rgb(250, 250, 250)',
	},
	borderLine: {
		borderRight: '0px !important',
	},

	dialogInput: {
		marginTop: "5px",
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "19px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
			'&:hover fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "460px",
			marginBottom: "20px",
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "440px",
		}
	},
	evaluationTableBox: {
		marginBottom: "0px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				letterSpacing: '-0.5px',
				fontWeight: 700,
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "44px",
				background: '#F3F3F3',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '10px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555',
			letterSpacing: '-0.5px',
		},
	},
	evaluationTableBgColor: {
		// height: '140px !important',
		// background: '#DFDFDF',
		borderRight: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},
	taskBox: {
		border: '2px solid #111',
		borderRadius: 3,
		padding: 14,
		marginTop: "10px",
	},
	pageNumber: {
		marginTop: "10px",
		paddingBottom: 40,
		borderBottom: '1px solid #CBCBCB',
		marginBottom: "10px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#555',
				color: '#fff',
				border: '1px solid #555',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #555',
			color: '#555',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},
	pageNumberBorder: {
		borderBottom: 0,
	},
	openFoldBtnBox: {
		position: 'absolute',
		left: '-7px'
		// right: 0,
		// top: 1,
	},
	closedFoldBtnBox: {
		position: 'absolute',
		right: '-13px',
		// right: -30,
		// top: 0,
	},
	foldBtn: {
		'&.MuiButtonBase-root': {
			backgroundColor: '#FBFCFE',
			color: '#969393',
			minWidth: 25,
			maxWidth: 25,
			height: "100px",
			borderRadius: '0px 2px 2px 0',
			border: '2px solid #CBCBCB',
			padding: 0,
			boxSizing: 'border-box',
			opacity: 0.3,
			boxShadow: '0px 0px 10px 0px #CBCBCB',
			'&:hover': {
				transition: 'all 0.5s',
				opacity: 1,
				backgroundColor: '#FBFCFE'
			},
			// '& .MuiTypography-root': {
			//     fontSize: '0.813rem',
			//     color: '#2F80ED',
			//     letterSpacing: '-0.5px',
			//     fontWeight: 500,
			// }
		}
	},
	previewRoot: {
		position: 'relative',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	zoomPageNum: {
		marginLeft: "10px",
		marginRight: "10px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-7px',
			top: 10,
		},
		'&:after': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			right: '-7px',
			top: 10,
		},
	},
	dataFileName: {
		marginBottom: "15px",
	},
	dataFileText: {
		'&.MuiTypography-root': {
			width: "350px",
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		'& span': {
			fontSize: '0.875rem',
			color: '#555'
		}
	},
	commonSubTitleBasicBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		marginBottom: "10px",
	},
	commonSubTitleBasic: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			'& span': {
				color: '#008C73'
			}
		}
	},
	// listBoxTop: {
	//     marginTop: "40px",
	// },
	basicTitle2: {

		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			fontWeight: 400,
			letterSpacing: '-0.4px',
			marginRight: "40px",
			minWidth: 160,
			boxSizing: 'border-box',
		}
	},
	basicText2: {
		paddingRight: 15,
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 500,
			letterSpacing: '-0.4px',
			width: '100%'
		}
	},
	tableBoxN: {
		marginTop: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid #cbcbcb',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1rem',
				color: '#01261C',
				letterSpacing: '-0.5px',
				fontWeight: 700,
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid #cbcbcb',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.2rem',
				color: '#01261C',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '10px',
				boxSizing: 'border-box',
				borderBottom: '1px solid #cbcbcb',
				borderRight: '1px solid #cbcbcb',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
				'& .MuiTypography-root': {
					fontSize: '1rem',
					color: '#000',
					letterSpacing: '-0.5px',
					fontWeight: 400,
					whiteSpace: 'break-spaces',
					wordBreak: 'break-all'
				}
			}
		}
	},
	bgColorTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem !important',
			color: '#01261C !important',
			letterSpacing: '-0.5px !important',
			fontWeight: '700 !important',
		}
	},

	sectionTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		marginTop: "50px",
	},

	questionText: {

		'&.MuiTypography-root': {
			fontSize: '3rem',
		}
	},

	dialogInputN: {
		marginTop: "5px",
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "19px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
			'&:hover fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
			height: "19px",
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	dialogInputNone: {
		marginTop: "5px",
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			// height: "19px",
			'&.Mui-focused fieldset': {
				borderColor: '#cbcbcb',
			},
			'&:hover fieldset': {
				borderColor: '#cbcbcb',
			},
		},
		'& .MuiOutlinedInput-multiline': {
			padding: 0
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
			// height: "19px",
			overflow: 'hidden',
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},

	bgLeftBox: {
		// background: '#F3F3F3',
		background: 'rgb(250, 250, 250)',
		borderRight: '1px solid #cbcbcb'
	},
	exportBtn: {
		'&.MuiButtonBase-root': {
			background: '#cbcbcb',
			borderRadius: 3,
			width: "168px",
			height: "36px",
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#787878',
				fontWeight: 700,
				marginRight: "5px",
				width: "120px",
				borderRight: '1px solid #787878'
			}
		}
	},
	exportBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#008C73',
			'& .MuiTypography-root': {
				fontSize: '1rem',
				color: '#fff',
				fontWeight: 700,
				borderRight: '1px solid #fff'
			},
		}
	},
	projectEdit: {
		backgroundColor: 'blue',
		color: 'white'
	},
	matching: {
		'&.MuiTypography-root': {
			marginLeft: "10px",
			fontSize: '1rem',
			color: '#9F9F9F',
		},
	},
	matchingActive: {
		'&.MuiTypography-root': {
			marginLeft: "10px",
			marginRight: "10px",
			fontSize: '1rem',
			color: '#008C73'
		},
	},
	essential: {
		'&.MuiTypography-root': {
			// fontSize: '1.125rem',
			fontSize: '1rem',
			fontWeight: 400,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			marginBottom: "10px",

		}

	},
	questionSentence: {
		'& p': {
			// fontSize: '1.063rem !important',
			letterSpacing: '-0.8px',
			fontWeight: '500 !important',
			lineHeight: 'normal',
			color: '#111'
		}
	},
	planRoot: {
		marginBottom: '40px'
	},
	contentMark: {
		'&.MuiTypography-root': {
			color: '#000',
			padding: '0px',
			fontSize: '1rem',
			whiteSpace: 'break-spaces',
			textOverflow: 'ellipsis',
			letterSpacing: '-0.5px',
			wordBreak: 'break-all',
			overflow: 'hidden',
			display: '-webkit-box',
			'-webkit-line-clamp': 4,
			'-webkit-box-orient': 'vertical'
		}
	},
	nameText: {
		'&.MuiTypography-root': {
			whiteSpace: 'break-spaces',
			textOverflow: 'ellipsis',
			wordBreak: 'break-all',
			overflow: 'hidden',
			fontSize: '1rem',

			letterSpacing: '-0.5px',
			display: '-webkit-box',
			'-webkit-line-clamp': 4,
			'-webkit-box-orient': 'vertical'
		}
	},
	scoreMark: {
		'&.MuiTypography-root': {
			whiteSpace: 'break-spaces',
			textOverflow: 'ellipsis',
			wordBreak: 'break-all',
			overflow: 'hidden',
		}
	},
	progressBox: {
		display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'
	},
	progress: {
		color: '#cbcbcb'
	},
	rubricBox: {
		marginBottom: "40px",
	},
	contentTooltip: {
		'&.MuiTooltip-tooltip': {
			backgroundColor: '#F4D219',
			boxShadow: '0px 1px 1px -1px rgba(255,255,0,0.1)',
			border: '0.5px solid #F3A50E',
			borderRadius: 0,
			marginTop: -5,
			color: '#111',
			fontSize: '0.75rem',
		}
	},

	// kitty
	scoreMarkInputMulti: {},
	scoreMarkInput: {},
	rubricTaskBox: {},
	standardBox: {},
	listBoxTop: {},
});












