
import {
	Checkbox
} from "@mui/material";

import { ReactComponent as CheckCircleAgreeOffIcon } from '../../../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../../../common/images/CheckCircleAgreeOnIcon.svg';


import { useTranslation } from 'react-i18next';


export function NeoCheckBox({ name, value, tick, onCheck, type }) {
	const i18next = useTranslation();

	return (
		<label>
			<Checkbox
				name={name}
				value={value}
				checked={tick || false}
				onChange={onCheck}
				checkedIcon={<CheckCircleAgreeOnIcon />}
				icon={<CheckCircleAgreeOffIcon />}
				disableRipple
			/>
			{name === 'select-all' ? null : type === 'optional' ? (
				<span>[{i18next.t('선택')}] </span>
			) : (
				<span style={{ color: '#ff0000' }}>[{i18next.t('필수')}] </span>
			)}

			{value}
		</label>
	);
}
