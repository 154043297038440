import jsPDF from 'jspdf';
import { sobpImageConverter } from "./SobpImageConverter";
import { makeNPageIdStr, NeoStroke, uuidv4 } from '../neolab-libs/nl-lib3-common';
import { ProjectExamType } from '../repositories/model/support/ProjectExamType';
import { NeoPDFDocument } from '../wasm-pdf-core/src/worker-employer/classes/NeoPDFDocument';
import { NeoPDFContext } from '../wasm-pdf-core/src/worker-employer/classes/NeoPDFContext';
import { createPDFWorkerEmployer } from '../wasm-pdf-core/src/worker-employer/PdfWorkerEmployerClass';

type ArgType = {
	type: ProjectExamType;
	lang: string;
	ndpNcodeRange: {
		section: number;
		owner: number;
		bookCode: number;
		pageStart: number;
		pageEnd: number;
	};
	strokes: NeoStroke[];
	dpi: number;
};

export async function makePdfWithStrokes(args: ArgType) {
	const { ndpNcodeRange, dpi, lang, type, strokes } = args;

	const startPage = ndpNcodeRange?.pageStart ?? 1;
	const endPage = ndpNcodeRange?.pageEnd ?? 1;
	const pageLen = endPage - startPage + 1;

	let doc: jsPDF = undefined;

	for (let i = 0; i < pageLen; i++) {
		const sobp = {
			section: ndpNcodeRange.section,
			owner: ndpNcodeRange.owner,
			book: ndpNcodeRange.bookCode,
			page: startPage + i
		};

		const { url, pageSize } = await sobpImageConverter({ type, lang, sobp, numPages: pageLen, pageIndex: i, ndpNcodeRange: ndpNcodeRange, forceUpdate: true, dpi, wholeStrokes: strokes });
		// console.log('makePdfWithStrokes', pageSize);

		if (!doc) {
			doc = new jsPDF({
				orientation: 'portrait',
				unit: 'mm',
				format: [pageSize.width, pageSize.height]
			});
		}

		doc.addImage(url, 'JPEG', 0, 0, pageSize.width, pageSize.height);
		doc.addPage();
	}

	if (!doc || doc.getNumberOfPages() === 0) {
		return null;
	}

	console.log(`pages = ${doc.getNumberOfPages()}`);
	doc.deletePage(doc.getNumberOfPages());

	const ret = doc.output('datauristring', { filename: 'withStroke.pdf' }) as string;
	return ret;
}

export async function addNcodeLayerToAllPages(args: {
	fileUrl: string;
	ndpPdf: {
		section: number;
		owner: number;
		bookCode: number;
		pageStart: number;
		pageEnd: number;
	}
}) {
	const { ndpPdf, fileUrl } = args || {};

	const startPage = ndpPdf?.pageStart ?? 1;
	const endPage = ndpPdf?.pageEnd ?? 1;
	const pageLen = endPage - startPage + 1;

	const startPageStr = makeNPageIdStr({ section: ndpPdf.section, owner: ndpPdf.owner, book: ndpPdf.bookCode, page: startPage });

	const employer = createPDFWorkerEmployer({ name: 'main', useWorker: false });
	await employer.initWorker();

	const ctxId = await employer.getGlobalContext();
	const pdfContext = NeoPDFContext.fromContextId(employer, ctxId);
	console.log('startPageStr', startPageStr);

	// load PDF file
	const pdf = new NeoPDFDocument(employer, pdfContext);
	await pdf.load({ url: fileUrl, paperGroupId: uuidv4() });

	// load page
	for (let pageNumber = 1; pageNumber <= pageLen; pageNumber++) {
		const pageIndex = pageNumber - 1;
		const page = await pdf.getPage(pageIndex);

		await page.convertPageColor(false, true);
		await page.flattenPage();

		const { width, height } = await page.getSize();

		// 2023-11-04, 아래와 같이 매 페이지에서 font를 추가로 넣어야 함 (document merge를 통해서 만들어진 PDF의 경우 하나의 글로벌 object로 font를 억세스하지 못하는 경우가 있음, PDF 편집자가 프로그래머가 아닌 이상 이런 실수를 할 수 있음)
		const font1 = await pdf.prepareNcodeFont({
			glyphDiameter: 1.0,
			forceToAdd: true,
			glyphShape: 3
		}); // additional font

		// 정상적인 PDF에서라면 CMYK로만 code를 만들어주면 될텐데, 그렇지 않은 경우가 있어서, 두번 연달아 시행
		// page.startNcodeLayersOverlay(); 와 await page.flushAndDrawNcodeLayers(true); 를 각각 해 줘야, 앞의 인쇄를 뒤가 제대로 덮어쓰게 됨

		// 2023-11-04, 아래와 같이 RGB로 되었을 경우에도 Ncode가 잘 찍히도록 수정 (color space를 엉망으로 만들어 내는 PDF creator들이 있음)
		page.startNcodeLayersOverlay();

		await page.addNcodeLayer({
			isPdfInRGB: false,
			sobp: startPageStr,
			pageDelta: pageIndex,
			x0: 0,
			y0: 0,
			x1: width,
			y1: height,
			ncodeFont: font1
		});

		await page.flushAndDrawNcodeLayers(false); // Ncode 삽입 끝 (위에서 정의된 영역의 Ncode를 CarbonBlack layer가 아닌 일반 layer로 삽입)

		page.free();
	}

	// download
	const u8 = await pdf.saveBytes(true);
	return u8;
}
