import {
	Box,
	IconButton,
	Typography
} from "@mui/material";
import React from "react";
import useEmotionStyles from "./styles/useEmotionStyles";
import { DataViewerStyle } from "./styles/DataViewerStyle";

import clsx from "clsx";
import { ReactComponent as NewTabClose } from '../../common/images/NewTabClose.svg';
import { ReactComponent as PageLogo } from '../../common/images/PageLogo.svg';
import { ReactComponent as SizeDown } from '../../common/images/SizeDown.svg';
import { ReactComponent as SizeUp } from '../../common/images/SizeUp.svg';

import { observer } from "mobx-react";
import { Document, Page, pdfjs } from 'react-pdf';

import { useTranslation } from 'react-i18next';

import { useStores } from "../../hooks/useStores";
import { RagType } from "../../stores/support/RagType";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = {
	fileUrl: string;
	projectCode: string;
	fileId: string;
	ragPage: string;
};

function TaskOpen(props: Props) {
	const { projectRagStore } = useStores();

	const classes = useEmotionStyles( DataViewerStyle );
	const i18next = useTranslation();
	const { fileUrl, projectCode, fileId, ragPage } = props;

	const [pages, setPages] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [scale, setScale] = React.useState(1);
	const [url, setUrl] = React.useState("");
	const [fileName, setFileName] = React.useState("");
	const [range, setRange] = React.useState("");
	const [dataType, setDataType] = React.useState("");

	React.useEffect(() => {
		if (fileUrl === undefined) {
			handleComponentDidMount();
		}
	}, [])

	const handleComponentDidMount = async () => {
		const getProjectRagByFileId = async (projectCodeParam, fileIdParam) => {
			return await projectRagStore.getProjectRagByFileId(projectCodeParam, fileIdParam);
		}

		const ragTransfer = await getProjectRagByFileId(projectCode, fileId);
		setUrl(ragTransfer.downloadUrl);
		setFileName(ragTransfer.fileName);
		setRange(ragPage);
		setDataType(ragTransfer.type);
	}
	const loadErr = (e) => {
		console.log(i18next.t("Error while loading document!"), e.message);
	}

	const loadSuccess = (pdf) => {
		let tempArr = [];
		for (let i = 0; i < pdf.numPages; i++) {
			tempArr.push(i + 1);
		}
		setPage(1);
		setPages(tempArr);
	}

	const handleClickClose = () => {
		window.close();
	}

	const handleClickPreview = (page) => {
		setPage(page);
	}

	const handleClickZoomIn = () => {
		setScale(scale + 0.1);
	}
	const handleClickZoomOut = () => {
		setScale(scale - 0.1);
	}
	const convertRagType = (type) => {
		switch (type) {
			case RagType.TEXTBOOK:
				return i18next.t("교과서");
			case RagType.NEWS:
				return i18next.t("뉴스 기사");
			case RagType.BOOK:
				return i18next.t("참고 도서");
			case RagType.PAPER:
				return i18next.t("학술 논문");
			case RagType.OTHERS:
				return i18next.t("기타");
		}
	}

	return (
		<div className={classes.root}>
			<Box
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.newTabHeader}>
						<Box display='flex' alignItems='center' justifyContent='center'>
							<IconButton className={clsx(classes.iconBtnReset, classes.rtrr)} disableRipple >
								<PageLogo />
							</IconButton>
							{/*todo loginUser 정보 출력*/}
							<Typography className={classes.topBarTitle}>{i18next.t("Assessments")}</Typography>
							<Typography className={classes.newTabHeaderTitle}>{i18next.t("참고 자료 등록")}</Typography>
						</Box>

						<Box>
							<IconButton className={classes.iconBtnReset} disableRipple>
								<NewTabClose onClick={handleClickClose} />
							</IconButton>
						</Box>
					</Box>

					<Box className={classes.newTabContent}>
						<Box display='flex' alignItems='center' justifyContent='space-between'>
							{fileUrl === undefined &&
								<>
									<Typography className={classes.newTabName}>{fileName}</Typography>
									<Box display='flex' alignItems='center' justifyContent='space-between'>
										<Box display='flex' alignItems='center' justifyContent='space-between'>
											<Typography className={classes.newTabTitle}>{i18next.t("자료유형")}</Typography>
											<Typography className={classes.newTabSubject}>{convertRagType(dataType)}</Typography>
										</Box>
										<Box display='flex' alignItems='center' justifyContent='space-between'>
											<Typography className={clsx(classes.newTabTitle, classes.newTabTitlePointer)}>{i18next.t("참고 지정 범위")}</Typography>
											<Typography className={classes.newTabPage}>p.{range}</Typography>
										</Box>
									</Box>
								</>
							}
						</Box>
						<Box>
							<Box className={classes.fileUploadBox} display='flex' flexDirection='column' alignItems='center' justifyContent='center' >
								<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ height: 'calc(100vh - 480px)' }}>
									{/*콘텐츠 리스트 */}
									<Document file={fileUrl !== undefined ? `blob:https://${window.location.host}/${fileUrl}`.normalize('NFC') : url} onLoadError={loadErr} onLoadSuccess={loadSuccess}>
										<Page pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} scale={scale} loading />
										<Box className={classes.sideList}>
											<Box className={classes.sideListInner}>
												{pages.map((page) => {
													return (
														<Box key={page} className={classes.img} onClick={(e) => handleClickPreview(page)}>
															<Page pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} height={100} width={100} />
															<Box className={classes.pageNum}>
																<Typography>{page}</Typography>
															</Box>
														</Box>
													)
												})
												}
											</Box>
										</Box>
									</Document>
								</Box>

								<Box className={classes.boardSize}>
									<IconButton className={classes.iconBtnReset} onClick={handleClickZoomIn} disableRipple>
										<SizeUp />
									</IconButton>
									<IconButton className={classes.iconBtnReset} onClick={handleClickZoomOut} disableRipple>
										<SizeDown />
									</IconButton>
								</Box>
							</Box>
						</Box>
					</Box>

				</Box>
			</Box>
		</div>
	);
}

export default observer<typeof TaskOpen>(TaskOpen);
