


export enum IBrushEnum {
  PEN = 0,
  ERASER = 1,     // 원래는 ERASER가 3, 2021/07/02 바꿈
  MARKER = 2,     // 원래는 1, 2021/07/02 바꿈
  PENCIL = 3,     // 원래는 2, 2021/07/02 바꿈
  BRUSH = 4,
  FOUNTAINPEN = 5,
  ERASERPEN = 6,
  LASSO = 7,

  PUI = 8,
  ERASED = 9,
}
