import { manualRegisterAtMappingStorage } from "../neolab-libs/nl-lib2-pagestorage/background-pdf-mapper/functions/manualRegisterAtMappingStorage";
import { nprojToIManualRegisterPdfType } from "../neolab-libs/nl-lib2-pagestorage/background-pdf-mapper/functions/nprojToIManualRegisterPdfType";
import { IPageSOBP } from "../neolab-libs/nl-lib3-common";
import generateNprojFromSizes from "../neolab-libs/nl-lib3-common/nproj/generateNprojFromSizes";
import { retreivePdfFromNdp2 } from "../neolab-libs/nl-lib3-ndp/paperhub/retreivePdfFromNdp2";
import { ProjectExamType } from "../repositories/model/support/ProjectExamType";

export async function downloadPaperInfoFromNdp2Local(type: ProjectExamType, pdfBlobURL: string, lang: string, sobp: IPageSOBP, numPages: number) {
	if (!sobp) return;

	// // const token = await getNeolabToken();
	// const bgInfo = await PageInfoFetcher.instance.getPageInfo(sobp, token.access_token);
	//
	// const page = bgInfo.nprojJson.pages.find((p) => isSamePage(p.sobp, sobp));
	// const { nprojUrl, pdfUrl, nprojStr } = bgInfo;

	// const nprojUrl = '/pdf/config.nproj'; // '/a4.nproj';
	// const pdfUrl = '/pdf/DESCRIPTIVE.pdf'; // 'a4.pdf';

	const pdfUrl = (type === ProjectExamType.DESCRIPTIVE) ? `/pdf/${lang}/DESCRIPTIVE.pdf` : `/pdf/${lang}/ESSAY.pdf`;

	let url: string = null;
	try {
		url = await retreivePdfFromNdp2(pdfUrl);
	} catch (e) {
		console.error(e);
		return;
	}

	const nprojStr = generateNprojFromSizes({
		title: 'title',
		filename: `${sobp.section}.${sobp.owner}.${sobp.book}.${sobp.page}-${numPages}`,
		startSobp: sobp,
		sizes: Array.from({ length: numPages }).map((item) => ({ width: 585, height: 842 })), // a4
	});

	// nprojStr로부터 dataURL을 생성하여 nrpojUrl로 저장
	const nprojUrl = 'data:text/plain;charset=utf-8,' + encodeURIComponent(nprojStr);

	const registerItem = nprojToIManualRegisterPdfType(nprojStr, {
		nproj_url: nprojUrl,
		pdf_url_from_paperhub: url,
		pdf_url_for_mapper: url
	});

	await manualRegisterAtMappingStorage(registerItem);
}
