
export enum MyScriptMimeType {
	TEXT = "TEXT",
	HTML = "HTML",
	MATHML = "MATHML",
	LATEX = "LATEX",
	GRAPHML = "GRAPHML",
	MUSICXML = "MUSICXML",
	SVG = "SVG",
	JIIX = "JIIX",
	JPEG = "JPEG",
	PNG = "PNG",
	GIF = "GIF",
	PDF = "PDF",
	DOCX = "DOCX",
	PPTX = "PPTX",
	OFFICE_CLIPBOARD = "OFFICE_CLIPBOARD",
};

export enum MyScriptRecognitionType {
	TextDocument = "Text Document",
	Text = "Text",
	Diagram = "Diagram",
	Math = "Math",
	Drawing = "Drawing",
	RawContent = "Raw Content"
}

export type MyScriptLocaleType =
	"zh_TW" |
	"af_ZA" |
	"az_AZ" |
	"be_BY" |
	"bg_BG" |
	"bs_BA" |
	"ca_ES" |
	"ceb_PH" |
	"da_DK" |
	"de_AT" |
	"de_DE" |
	"el_GR" |
	"en_CA" |
	"en_GB" |
	"en_PH" |
	"en_US" |
	"en_ZA" |
	"es_CO" |
	"es_ES" |
	"es_MX" |
	"et_EE" |
	"eu_ES" |
	"fa_IR" |
	"fi_FI" |
	"fil_PH" |
	"fr_CA" |
	"fr_FR" |
	"ga_IE" |
	"gl_ES" |
	"he_IL" |
	"hi_IN" |
	"hr_HR" |
	"hu_HU" |
	"hy_AM" |
	"id_ID" |
	"is_IS" |
	"it_IT" |
	"ja_JP" |
	"ka_GE" |
	"kk_KZ" |
	"ko_KR" |
	"lt_LT" |
	"lv_LV" |
	"mg_MG" |
	"mk_MK" |
	"mn_MN" |
	"ms_MY" |
	"nl_BE" |
	"nl_NL" |
	"no_NO" |
	"pl_PL" |
	"pt_BR" |
	"pt_PT" |
	"ro_RO" |
	"ru_RU" |
	"sk_SK" |
	"sl_SI" |
	"sq_AL" |
	"sr_Cyrl_RS" |
	"sr_Latn_RS" |
	"sv_SE" |
	"sw_TZ" |
	"th_TH" |
	"tr_TR" |
	"tt_RU" |
	"uk_UA" |
	"ur_PK" |
	"vi_VN" |
	"zh_CN" |
	"zh_HK";
