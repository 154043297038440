import {
	Box,
	FormControl,
	MenuItem,
	Select,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as SelectIcon } from '../../../../../common/images/SelectIcon.svg';
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { MarkFeedbackStyle } from "../../../styles/MarkFeedbackStyle";

import React from "react";
import { useTranslation } from 'react-i18next';
import { IRubricInfo } from "../../../../../repositories/model/transfer/IRubricInfo";
import { useStores } from "../../../../../hooks/useStores";

type Props = {
	rubricInfo: IRubricInfo;
	selectedItemIdx: number;
	questionKey: string;
	changeIsUpdating: any;
	isExported: boolean;
}
export const MarkTableRow = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( MarkFeedbackStyle );
	const { rubricInfo, selectedItemIdx, questionKey, changeIsUpdating, isExported } = props;
	const { projectSubmissionAnswerStore } = useStores();

	const itemInfo = React.useMemo(() => {
		return rubricInfo?.items[selectedItemIdx];
	}, [rubricInfo?.items, selectedItemIdx]);

	const handleSelectItem = (e) => {
		const idx = e.target.value;
		projectSubmissionAnswerStore.changeRubricSelectedItem(questionKey, rubricInfo.id, idx, null);
		changeIsUpdating && changeIsUpdating(true);
	};

	return (
		<TableRow>
			<TableCell width='8%' align="center" className={clsx(classes.bgColor, classes.scoreFormCell)}>
				<Box>
					{isExported ?
						<Typography className={classes.rubricItemNameText}>
							{i18next.t(rubricInfo.items[selectedItemIdx]?.name || '-')}
						</Typography> :
						<FormControl className={clsx(classes.formControl, classes.formControlWidth)}>
							<Select
								IconComponent={(props) => (
									<Box>
										<SelectIcon  {...props} style={{ right: '3px' }} />
									</Box>
								)}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "right"
									},
									transformOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
									// getContentAnchorEl: null,
								}}
								value={selectedItemIdx}
								displayEmpty
								onChange={handleSelectItem}
							>
								<MenuItem key={-1} value={-1} style={{ display: 'none' }}>{i18next.t("척도 선택")}</MenuItem>

								{rubricInfo?.items.length > 0 &&
									rubricInfo.items.map((item, idx) =>
										<MenuItem key={idx} value={idx}>{i18next.t(item.name)}</MenuItem>
									)}
							</Select>
						</FormControl>
					}
				</Box>
			</TableCell>
			<TableCell width='24%' className={classes.bgColor}>
				<Typography className={classes.markText}><span>{itemInfo?.score || 0}</span>{i18next.t("점")}</Typography>
			</TableCell>
			<TableCell width='50%' align="left" className={classes.borderLine}>
				<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
					<Typography className={classes.tableText}>
						{i18next.t(itemInfo?.standard || "")}
					</Typography>
				</Box>
			</TableCell>
		</TableRow>
	);
};
