import { PEN_PACKET_END, PEN_PACKET_START } from "../../nl-lib-bt-devices/bt-protocol/bt-base-protorol-indicators";
import { Cmd } from "./pencomm_cmd";

export const DEFAULT_PASSWORD = "0000";

export const PEN_SERVICE_UUID_16 = 0x19f1;
export const PEN_CHARACTERISTICS_WRITE_UUID_16 = 0x2ba0;
export const PEN_CHARACTERISTICS_NOTIFICATION_UUID_16 = 0x2ba1;

export const PEN_SERVICE_UUID_16_STR = `${0x19f1}`;
export const PEN_CHARACTERISTICS_WRITE_UUID_16_STR = `${0x2ba0}`;
export const PEN_CHARACTERISTICS_NOTIFICATION_UUID_16_STR = `${0x2ba1}`;



export const PEN_SERVICE_UUID_128 = "4f99f138-9d53-5bfa-9e50-b147491afe68";
export const PEN_CHARACTERISTICS_WRITE_UUID_128 = "8bc8cc7d-88ca-56b0-af9a-9bf514d0d61a";
export const PEN_CHARACTERISTICS_NOTIFICATION_UUID_128 = "64cd86b1-2256-5aeb-9f04-2caf6c60ae57";

export const DEFAULT_MAX_FORCE = 1023;


export const bufferArray_first = new Uint8Array([
  PEN_PACKET_START, // start packet
  // cmd (1)
  Cmd.VERSION_REQUEST,

  // length (2)
  0x2a, 0x00,

  // connection code (16)
  0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,

  // app type (2)
  0x00, 0x00,

  // app version (16)
  0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,

  // protocol version(8) 2.22
  0x32, 0x2e, 0x32, 0x32, 0x00, 0x00, 0x00, 0x00,
  PEN_PACKET_END,
]); // end packet
