
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import clsx from "clsx";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { MarkFeedbackStyle } from "../../../styles/MarkFeedbackStyle";

import { useTranslation } from 'react-i18next';
import { RubricItemRow } from './RubricItemRow';

export const RubricTable = (props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( MarkFeedbackStyle );
	const { rubricInfo } = props;

	return (
		<TableContainer component={Paper} className={clsx(classes.tableBox, classes.rubricTable)}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell width='26%' align="center">{i18next.t("평가항목")}</TableCell>
						<TableCell width='30%' align={"center"} colSpan={2}>{i18next.t("배점")}</TableCell>
						<TableCell width='54%' align="center">{i18next.t("채점 기준")}</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow >

						{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
						<TableCell width='17%' rowSpan={rubricInfo.items?.length + 1 || 0} align="center">
							<Box display='flex' flexDirection='column' alignItems='center'>
								<Typography >{i18next.t(rubricInfo.name)}</Typography>
							</Box>
						</TableCell>
					</TableRow>

					{rubricInfo?.items.length > 0 &&
						rubricInfo.items.map((item, idx) =>
							<RubricItemRow
								key={idx}
								itemInfo={item}
							/>
						)}

				</TableBody>
			</Table>
		</TableContainer>
	)
};
