import { EmployerDisposable } from "./EmployerDisposable";
import { NeoPDFDocument } from "./NeoPDFDocument";
import { NeoPDFRendererManager, NeoRenderTask } from "./NeoPDFRendererManager";
import { LINE_CAP_STYLE, LINE_JOIN_STYLE, PATH_FILL_TYPE } from "../../emscripten-wrapper/classes/SvgPathDrawEnum";
import { NcodeLayer } from "./NcodeLayer";
import { NeoPDFWorkerEmployer } from "../PdfWorkerEmployerClass";

export interface NcodeFontObject {
  fontObjPointer: number;
  charset: string;
}

export interface ExcludeRect {
  x0: number,
  y0: number,
  x1: number,
  y1: number,

}

export class NeoPDFPage extends EmployerDisposable {

  name = "NeoPDFPage";

  // public docPointer: number = 0;

  private _pageIndex: number;

  private _size: { width: number, height: number } = null;

  private _doc: NeoPDFDocument;


  private ncodeLayers: Array<NcodeLayer> = new Array<NcodeLayer>(0);


  private _ready: Promise<void>;

  private resolveReady: () => void;

  get doc() { return this._doc }

  get document() { return this._doc }

  get pageIndex() { return this._pageIndex }

  public setPageIndex(pageIndex: number) { this._pageIndex = pageIndex }

  get pageNo() { return this._pageIndex + 1 }


  // get ready() { return this._ready }

  get docId() { return this.doc.id }

  constructor(employer: NeoPDFWorkerEmployer, doc: NeoPDFDocument, pageIndex: number) {
    super(employer, doc.ctxId, null, employer.freePage);

    this._ready = new Promise<void>((resolve, reject) => {
      this.resolveReady = resolve;
    });

    this._doc = doc;
    this._pageIndex = pageIndex;

    this.employer.loadPage(this.doc.id, this._pageIndex).then((pageId) => {
      this.fzId = pageId;
      this.resolveReady();
    });
  }

  get singleGlyphWidth() { return this._doc.singleGlyphWidth; }

  public get ready() { return this._ready; }


  public async loadPage() {
    await this._ready;
    if (this.fzId) return this;

    const pageId = await this.employer.loadPage(this.doc.id, this._pageIndex);
    this.fzId = pageId;

    return this;
  }

  public async getSize() {
    await this._ready;
    if (this._size === null) {
      const s = await this.employer.getPageSize(this.id);
      this._size = s;
    }

    return this._size;
  }

  public async getViewport(args?: { scale?: number }) {
    await this._ready;

    if (!args) args = {};
    const { scale = 1 } = args;
    const size = await this.getSize();
    const viewport = {
      width: size.width * scale,
      height: size.height * scale,
      scale,
      rotation: 0,
      offsetX: 0,
      offsetY: 0,
    };
    return viewport
  }

  public async free() {
    await this._ready;

    // let freed = false;
    // try {
    //   freed = await this.employer.freePage(this.fzId);
    // }
    // catch (e) {
    //   console.log(`free page error: ${e}`);
    // }

    // if (freed) {
    //   this.doc.onPageFreed(this);
    //   // this.doc.onReportPageFreed(this);
    // }

    try {
      await this.superFree();
      this.doc.onPageFreed(this);
    }
    catch (e) {
      console.log(`superFree error: ${e}`);
    }
  }

  public render(renderContext: {
    canvas?: HTMLCanvasElement;
    transform: number[];
    viewport: {
      width: number;
      height: number;
      scale: number;
      rotation: number;
      offsetX: number;
      offsetY: number;
    },
    topPriority?: boolean;
  }) {
    const { canvas, transform, viewport, topPriority } = renderContext;
    const dpi = viewport.scale * 72;

    if (canvas) {
      return this.renderPageOnCanvas(canvas, dpi, topPriority);
    }

    return this.renderPageAsPngDataUrl(dpi, topPriority);
  }

  public renderPageOnCanvas(canvas: HTMLCanvasElement, dpi: number, topPriority = false) {
    let task: NeoRenderTask;

    try {
      task = NeoPDFRendererManager.instance.requestRenderPageAsync({
        pdf: this.doc,
        pageIndex: this.pageIndex,
        dpi,
        canvas,
        topPriority,
        type: "canvas",
      });

      return task;
    }
    catch (e) {
      console.log(`rendering canceled: page: ${this._pageIndex}`);
      return undefined;
    }
  }

  public drawPageAsPixmap(dpi: number, topPriority = false) {
    let task: NeoRenderTask;

    try {
      task = NeoPDFRendererManager.instance.requestRenderPageAsync({
        pdf: this.doc,
        pageIndex: this.pageIndex,
        dpi,
        canvas: undefined,
        topPriority,
        type: "pixmap"
      });
      return task;
    }
    catch (e) {
      console.log(`rendering canceled: page: ${this._pageIndex}`);
      return undefined;
    }
  }

  public renderPageAsPngDataUrl(dpi: number, topPriority = false) {
    let task: NeoRenderTask;
    try {
      task = NeoPDFRendererManager.instance.requestRenderPageAsync({
        pdf: this.doc,
        pageIndex: this.pageIndex,
        dpi,
        canvas: undefined,
        topPriority,
        type: "dataurl"
      });

      return task;
    }
    catch (e) {
      console.log(`rendering canceled: page: ${this._pageIndex}`);
      return undefined;
    }
  }


  public drawPageAsPNG(dpi: number, topPriority = false) {
    let task: NeoRenderTask;
    try {
      task = NeoPDFRendererManager.instance.requestRenderPageAsync({
        pdf: this.doc,
        pageIndex: this.pageIndex,
        dpi,
        canvas: undefined,
        topPriority,
        type: "png"
      });

      return task;
    }
    catch (e) {
      console.log(`rendering canceled: page: ${this._pageIndex}`);
      return undefined;
    }
  }

  async convertPageColor(
    toRgb: boolean,
    toCmy: boolean,
    ncodeFont: { pointer: number, charset: string } = null,
    useRgbPseudoColor = false,
    maxBlueContrast = 0.9,
    shouldFlatten = false,
    dropContents = true,
    rgbSoftMark = false
  ) {
    await this._ready;

    await this.employer.convertPageColor(
      this.id,
      toRgb,
      toCmy,
      ncodeFont,
      useRgbPseudoColor,
      maxBlueContrast,
      shouldFlatten,
      dropContents,
      rgbSoftMark
    );
  }

  async flattenPage() {
    await this._ready;

    await this.employer.flattenPage(this.id);
  }

  private async insertNcodeLayerDirect(
    isPdfInRGB: boolean,
    forceUseCarbonBlack: boolean,
    sobp: string,
    pageDelta = 0,
    x0 = 0 /* float */,
    y0 = 0 /* float */,
    x1 = 0 /* float */,
    y1 = 0 /* float */,
    ncodeFont: NcodeFontObject = null,
    ncodeBaseX = 0 /* int */,
    ncodeBaseY = 0 /* int */,
    excludeRegions: { x0: number, y0: number, x1: number, y1: number }[] = null
  ) {
    await this._ready;

    await this.employer.insertNcodeLayerDirect(
      this.id,
      isPdfInRGB,
      forceUseCarbonBlack,
      sobp,
      pageDelta,
      x0, y0, x1, y1,
      ncodeFont ? ncodeFont.fontObjPointer : null,
      ncodeFont ? ncodeFont.charset : null,
      ncodeBaseX, ncodeBaseY,
      excludeRegions
    );
  }

  /**
   *
   * @param args
   * @param args.svgPath - svg path string
   * @param args.r - red color value (0~255)
   * @param args.g - green color value (0~255)
   * @param args.b - blue color value (0~255)
   * @param args.a - opacity (0~1)
   * @param args.stroke_width - stroke width (default: 0.1)
   * @param args.fill_type - fill type (default: PATH_FILL_TYPE_FILL)
   * @param args.line_cap_style - line cap style (default: LINE_CAP_STYLE_ROUND)
   * @param args.line_join_style - line join style (default: LINE_JOIN_STYLE_ROUND)
   * @returns
   */
  async addSVGPath(
    args: {
      svgPath: string,
      r: number, g: number, b: number,
      a?: number,
      stroke_width?: number,
      fill_type?: PATH_FILL_TYPE,
      line_cap_style?: LINE_CAP_STYLE,
      line_join_style?: LINE_JOIN_STYLE
    }
  ) {
    const { r, g, b } = args;
    const {
      svgPath,
      a: opacity = 1.0,
      stroke_width = 0.1,
      fill_type = PATH_FILL_TYPE.PATH_FILL_TYPE_FILL,
      line_cap_style = LINE_CAP_STYLE.LINE_CAP_STYLE_ROUND,
      line_join_style = LINE_JOIN_STYLE.LINE_JOIN_STYLE_ROUND,
    } = args;
    await this._ready;

    // await this.employer.insertSVGPathOnPage2Pass(
    //   this.docPointer,
    //   this.pageIndex,
    //   svgPath,
    //   r, g, b, opacity,
    //   stroke_width,
    //   fill_type,
    //   line_cap_style,
    //   line_join_style
    // );

    await this.employer.insertSVGPathOnPage(
      this.id,
      svgPath,
      r, g, b, opacity,
      stroke_width,
      fill_type,
      line_cap_style,
      line_join_style
    );

  }


  /**
   *
   * @param args
   * @param args.svgPath - svg path string
   * @param args.r - red color value (0~255)
   * @param args.g - green color value (0~255)
   * @param args.b - blue color value (0~255)
   * @param args.a - opacity (0~1)
   * @param args.stroke_width - stroke width (default: 0.1)
   * @param args.fill_type - fill type (default: PATH_FILL_TYPE_FILL)
   * @param args.line_cap_style - line cap style (default: LINE_CAP_STYLE_ROUND)
   * @param args.line_join_style - line join style (default: LINE_JOIN_STYLE_ROUND)
   * @returns
   */
  async addSVGPathAfterLoad(
    args: {
      svgPath: string,
      r: number, g: number, b: number,
      a?: number,
      stroke_width?: number,
      fill_type?: PATH_FILL_TYPE,
      line_cap_style?: LINE_CAP_STYLE,
      line_join_style?: LINE_JOIN_STYLE
    }
  ) {
    const { r, g, b } = args;
    const {
      svgPath,
      a: opacity = 1.0,
      stroke_width = 0.1,
      fill_type = PATH_FILL_TYPE.PATH_FILL_TYPE_FILL,
      line_cap_style = LINE_CAP_STYLE.LINE_CAP_STYLE_ROUND,
      line_join_style = LINE_JOIN_STYLE.LINE_JOIN_STYLE_ROUND,
    } = args;
    await this._ready;

    // await this.employer.insertSVGPathOnPage2Pass(
    //   this.docPointer,
    //   this.pageIndex,
    //   svgPath,
    //   r, g, b, opacity,
    //   stroke_width,
    //   fill_type,
    //   line_cap_style,
    //   line_join_style
    // );

    await this.employer.insertSVGPathOnPageAfterLoad(
      this.fzId,
      svgPath,
      r, g, b, opacity,
      stroke_width,
      fill_type,
      line_cap_style,
      line_join_style
    );

  }




  /**
   *
   * @param args
   * @param args.svgPath - svg path string
   * @param args.r - red color value (0~255)
   * @param args.g - green color value (0~255)
   * @param args.b - blue color value (0~255)
   * @param args.a - opacity (0~1)
   * @param args.stroke_width - stroke width (default: 0.1)
   * @param args.fill_type - fill type (default: PATH_FILL_TYPE_FILL)
   * @param args.line_cap_style - line cap style (default: LINE_CAP_STYLE_ROUND)
   * @param args.line_join_style - line join style (default: LINE_JOIN_STYLE_ROUND)
   * @returns
   */
  async insertPDFOperatorPath(
    args: {
      pdfPath: string,
      r: number, g: number, b: number,
      a?: number,
      stroke_width?: number,
      fill_type?: PATH_FILL_TYPE,
      line_cap_style?: LINE_CAP_STYLE,
      line_join_style?: LINE_JOIN_STYLE
    }
  ) {
    const { r, g, b } = args;
    const {
      pdfPath,
      a: opacity = 1.0,
      stroke_width = 0.1,
      fill_type = PATH_FILL_TYPE.PATH_FILL_TYPE_FILL,
      line_cap_style = LINE_CAP_STYLE.LINE_CAP_STYLE_ROUND,
      line_join_style = LINE_JOIN_STYLE.LINE_JOIN_STYLE_ROUND,
    } = args;
    await this._ready;

    // await this.employer.insertSVGPathOnPage2Pass(
    //   this.docPointer,
    //   this.pageIndex,
    //   svgPath,
    //   r, g, b, opacity,
    //   stroke_width,
    //   fill_type,
    //   line_cap_style,
    //   line_join_style
    // );

    await this.employer.insertPDFOperatorPath(
      this.fzId,
      pdfPath,
      r, g, b, opacity,
      stroke_width,
      fill_type,
      line_cap_style,
      line_join_style
    );

  }



  public startNcodeLayersOverlay() {
    this.ncodeLayers = [];
  }

  public async addNcodeLayer(args: {
    sobp: string,
    isPdfInRGB?: boolean,
    pageDelta?: number,
    x0?: number, y0?: number, x1?: number, y1?: number,
    alignAxisGlyph?: boolean,
    ncodeFont?: NcodeFontObject,
    ncodeBaseX?: number, ncodeBaseY?: number,
    excludeRegions?: ExcludeRect[]
  }) {
    const {
      sobp,
      isPdfInRGB = false,
      pageDelta = 0,
      alignAxisGlyph = true,
      ncodeFont = null, ncodeBaseX = 0, ncodeBaseY = 0,
      excludeRegions = null
    } = args;
    await this._ready;

    let { x0 = 0, y0 = 0, x1 = 0, y1 = 0, } = args;

    // if whole area is page giving deafult values, adjust region boundary
    if (x0 === 0 && y0 === 0 && x1 === 0 && y1 === 0) {
      const size = await this.getSize();
      x0 = 0;
      y0 = 0;
      x1 = size.width;
      y1 = size.height;
    }

    const rc: ExcludeRect = { x0, y0, x1, y1 };
    if (alignAxisGlyph) {
      const new_x0 = Math.floor(x0 / this.singleGlyphWidth) * this.singleGlyphWidth;
      const new_y0 = Math.floor(y0 / this.singleGlyphWidth) * this.singleGlyphWidth;

      rc.x0 = new_x0;
      rc.y0 = new_y0;
    }

    // create a new layer and add to the layer list
    const layer = new NcodeLayer(
      this._doc._context, this._doc, this.pageIndex,
      isPdfInRGB, sobp, pageDelta,
      rc.x0, rc.y0, rc.x1, rc.y1,
      ncodeFont,
      ncodeBaseX, ncodeBaseY, excludeRegions);


    if (alignAxisGlyph) {
      if (rc.x0 !== x0) {
        layer.addExcludeRegion(rc.x0, rc.y0, x0 - 0.01, y1);
      }

      if (rc.y0 !== y0) {
        layer.addExcludeRegion(rc.x0, rc.y0, x1, y0 - 0.01);
      }
    }
    this.ncodeLayers.push(layer);

    let xx0 = x0; let yy0 = y0; let xx1 = x1; let yy1 = y1;
    if (x0 === 0 && y0 === 0 && x1 === 0 && y1 === 0) {
      const size = await this.getSize();
      xx0 = 0;
      yy0 = 0;
      xx1 = size.width;
      yy1 = size.height;
    }

    // process exclude regions for the layer under this new layer
    for (let i = 0; i < this.ncodeLayers.length - 1; i++) {
      const underLayer = this.ncodeLayers[i];
      underLayer.addExcludeRegion(xx0, yy0, xx1, yy1);
    }
  }

  public async flushAndDrawNcodeLayers(forceUseCarbonBlack = true) {
    await this._ready;

    for (let i = 0; i < this.ncodeLayers.length; i++) {
      const layer = this.ncodeLayers[i];

      await this.insertNcodeLayerDirect(
        layer.isPdfInRGB,
        forceUseCarbonBlack,
        layer.sobp,
        layer.pageDelta,
        layer.x0, layer.y0, layer.x1, layer.y1,
        layer.ncodeFont,
        layer.ncodeBaseX, layer.ncodeBaseY,
        layer.excludeRegions);
    }

    this.ncodeLayers = [];
  }
}
