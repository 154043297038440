import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const ExamRangeUserStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
	},
	// emptyBox:{
	//     width: '100%',
	//     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
	//     display:'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center',
	//     '& p':{
	//         fontSize: '1.375rem',
	//         color: '#87888d',
	//         fontWeight:400,
	//         textAlign: 'center',
	//         lineHeight: '30.36px'
	//     }
	// },
	// boxWrap: {
	//     backgroundColor: '#fff',
	//     padding: 20,
	//     borderRadius: 8,
	//     height: 'calc(100vh - 500px)'
	// },

	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			// marginLeft: "10px",
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},


	dialogBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff'
		}
	},


	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},





	/////////////////////
	padding: {
		padding: 15,
	},
	mainContentBox: {
		border: '1px solid #EAECEB',
		padding: 20,
		width: '100%',
		height: 'calc(100vh - 132px)',
		overflowY: 'auto',
		background: '#fff',
		display: 'flex',
		// maxWidth: 1024,

	},
	minaContentLeftRight: {
		width: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	mainContentBoxLeft: {
		border: '1px solid #EAECEB',
		padding: 20,
		height: 'calc(100vh - 130px)',
		background: '#fff',
		// width: 'calc(100vw - 770px)',
		width: '93%',
		maxWidth: 1024,
	},


	mainContentBoxLeftInner: {
		height: 'calc(100vh - 200px)',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},




	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		overflowY: 'auto',
		background: '#fff',
		width: '620px',
		boxSizing: 'border-box',
		marginLeft: "10px",
	},


	commonSubTitle: {
		marginBottom: "10px",
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
		}
	},
	taskTitle: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			marginBottom: "15px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	taskTitleBold: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			marginBottom: "15px",
			fontWeight: 500,
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	searchSchoolInput: {
		'& .MuiFormControl-root': {
			maxWidth: 830,
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 50
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},




	greenBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#cdcdcd',
			'&:hover': {
				background: '#cdcdcd',
			},
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold'
			}
		}
	},
	greenBtnActive: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	greenLineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008c73',
				fontWeight: 'bold'
			}
		}
	},
	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#cdcdcd',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold'
			}
		},
	},
	lineBtnGroup: {
		'&.MuiButtonGroup-root': {
			width: "140px",
			height: "36px",
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold'
			}
		}
	},
	lineBtnActive: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008c73',
				fontWeight: 'bold'
			}
		}
	},
	selectText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 'bold',
			marginRight: "15px",
		}
	},

	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1.125rem',
			boxSizing: 'border-box',
			color: '#777',
			letterSpacing: '-0.5px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #87888d',
			borderRadius: '6px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},



	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: '#fff',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
			marginTop: "3px",
		}
	},
	pageNumber: {
		marginTop: "20px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentName: {
		'&.MuiTypography-root': {
			color: '#008C73'
		}
	},
	tableBox: {
		marginTop: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1rem',
				color: '#323232',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '1rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},

	viewerBox: {
		border: '2px solid #111',
		padding: '0px 10px 0px 10px',
		maxHeight: '300px',
		marginBottom: '10px'
	},

	answerBox: {
		border: '2px solid #111',
		padding: '0px 10px 0px 10px',
		height: '310px',
		marginBottom: '30px'
	},

	dialogInput: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			// height: "19px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
			'&:hover fieldset': {
				borderColor: '#2F80ED',
			},
		},
		// '& .MuiOutlinedInput-input': {
		// padding: '9px 0px',
		// height: "19px",
		// },
		'& .MuiOutlinedInput-multiline': {
			padding: '8px 14px'
		},
		'& .MuiInputBase-input': {
			// fontSize: '0.875rem',
			fontSize: '1rem',
			color: '#787878',
			letterSpacing: '-0.7px',
			// padding: '9px 0px'
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "460px",
			marginBottom: "20px",
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "440px",
		}
	},
	stepInputBox: {
		'& .MuiIconButton-root': {
			margin: '20px 10px 5px 40px'
		}
	},

	inputStep: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 36
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
			letterSpacing: '-0.5px'
		}
	},
	inputStepMulti: {
		'& .MuiOutlinedInput-root': {
			height: "60px",
		},
		'& .MuiOutlinedInput-input': {
			height: "38px",
		}
	},
	stepText: {
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			color: '#2F80ED',
			marginRight: "20px",
		}
	},

	addSentence: {
		'& .MuiButton-label': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: 'transparent'
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontSize: '0.875rem',
				marginTop: "2px",
			}
		}
	},

	boxList: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FAFAFA',
		borderRadius: 6,
		width: '100%',
		height: "200px",
		margin: '0px 5px',
		padding: 10,
		'& .MuiTypography-root': {
			color: '#6F6F6F',
			fontSize: '0.75rem',
			minHeight: 93,
		},
		'&:hover': {
			outline: '2px solid #56CCF2'
		}
	},
	boxListTitle: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 700,
			marginTop: "5px",
			marginBottom: "5px",
			minHeight: 'auto'
		}
	},
	fileUploadBox: {
		marginTop: "30px",
		background: '#E0E0E0',
		height: 'calc(100vh - 480px)',
		overflowY: 'auto',
		position: 'relative'
	},
	boardSize: {
		position: 'absolute',
		right: 10,
		bottom: 10,
		display: 'flex',
		flexDirection: 'column'
	},
	fileUploadTitle: {
		marginTop: "20px",
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			marginBottom: "20px",
		}
	},
	fileUploadText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	fileUploadBtn: {
		'&.MuiButtonBase-root': {
			background: '#008C73',
			width: "170px",
			height: "36px",
			borderRadius: 3,
			border: '1px solid #008C73',
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	fileUploadSubText: {
		margin: '15px 0px'
	},
	urlInputBox: {
		'&.MuiPaper-root': {
			width: "600px",
			height: "35px",
			border: '1px solid #CBCBCB',
			marginBottom: "15px",
		},
		'& .MuiInputBase-root': {
			width: "480px",
		},
		'& .MuiInputBase-input': {
			padding: '8px 10px 7px',
		}
	},
	urlBtn: {
		marginLeft: "10px",
		'&.MuiButtonBase-root': {
			width: "82px",
			height: "27px",
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				letterSpacing: '-0.5px',
			}
		}
	},
	urlBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#fff'
			}
		}
	},

	summaryAiBtn: {
		'&.MuiButtonBase-root': {
			background: '#CDCDCD',
			width: '100%',
			marginBottom: "20px",
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold',
			}
		}
	},
	summaryAiBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				color: '#fff',
			}
		}
	},
	aiSubText: {
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
		}
	},
	sideList: {
		position: 'absolute',
		left: 0,
		top: 0,
		background: '#4F4F4F',
		width: "114px",
		height: 'calc(100vh - 480px)',
		// height: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	sideListInner: {
		overflowY: 'auto',
		padding: '10px 7px',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	img: {
		position: 'relative',
		'& img': {
			width: '100%',
			borderRadius: '5px',
			'&:hover': {
				outline: '2px solid #03BF69'
			},
		},

	},
	examInfoIcon: {
		marginLeft: "10px",
		marginBottom: "13px",
	},
	pageNum: {
		position: 'absolute',
		bottom: 5,
		background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.6), #fff)',
		width: '100%',
		borderRadius: '0 0 5px 5px',
		'& .MuiTypography-root': {
			padding: '4px 5px',
			textAlign: 'right'
		}
	},
	userBox: {
		border: '1px solid #CBCBCB',
		width: '100%',
		borderRadius: 15,
		padding: '10px 20px 20px 20px',
		boxSizing: 'border-box',
		marginBottom: "20px",
		boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)',
	},
	displayNone: {
		display: 'none',
	},
	editerArticle: {
		border: '0px solid #cbcbcb',
		borderRadius: 3,
		minHeight: 360,
		marginBottom: "5px",
		marginLeft: "30px",
		// minWidth: 400
		display: 'flex',
		overflowX: 'auto',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
		'& > div': {
			width: '100%',
			minWidth: '520px',
			// overflow: 'hidden'
		},
		// '& .toastui-editor-defaultUI-toolbar': {
		//     padding: 0,
		//     height: "39px",
		//     '& button': {
		//         margin: '4px 5px'
		//     }
		// }
	},
	dotSubEditerWrapBox: {
		marginTop: "10px"
	},
	dotSubEditerWrap: {
		position: 'relative',
		marginBottom: "5px",
		marginTop: "20px",
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer'
	},
	supportEditerWrap: {
		position: 'relative',
		marginBottom: "5px",
		// marginTop: "5px",
		display: 'flex',
		alignItems: 'center',
		cursor: "pointer"
	},
	dotSubEditer: {
		position: 'absolute',
		top: 30,
		left: 5,
	},
	editerBtnSentence: {
		'&.MuiButtonBase-root': {
			border: '1px dotted #111',
			width: '100%',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontWeight: 'bold',
				marginLeft: "5px",
			}
		}
	},
	userTitleToggle: {
		'& svg': {
			// marginTop: "10px",
			marginLeft: "5px",
		}
	},
	userTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
			letterSpacing: '-0.5px',
			'& span': {
				fontSize: '1rem',
				fontWeight: 400,
				color: '#555'
			}
		}
	},
	userTitleSelect: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			color: '#111',
			letterSpacing: '-0.5px',
			'& span': {
				fontSize: '1rem',
				fontWeight: 400,
				color: '#555'
			}
		}
	},
	userAddBtn: {
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: '#fff',
			},
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555',
			marginLeft: "5px",
		}
	},
	scoreMarkText: {
		fontWeight: 500,
	},
	scoreMarkSubText: {
		color: '#555'
	},
	scoreMark: {
		position: 'relative',
		marginRight: "10px",
		marginLeft: "20px",
		'& .MuiFormControl-root': {
			width: '80px',
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 64
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 25px',
		}
	},
	scoreMarkIcon: {
		position: 'absolute',
		right: 0,
		top: 5,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	iconYearSelect: {
		'&.MuiIconButton-root': {
			margin: '4px 5px',
		}
	},

	trigger: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: "440px",
		marginBottom: "34px",
		'&.MuiTabs-root': {
			minHeight: 40
		},
		'& .MuiTabs-flexContainer': {
			// width: "208px",
			border: '1px solid #F3F3F3',
			borderRadius: 8,
			overflow: 'hidden',
		},
		'& button': {
			width: "110px",
			minWidth: 110,
			minHeight: 36,
			height: "36px",
			position: 'relative',
			fontSize: '0.875rem',
			letterSpacing: '-0.5px',
			color: '#787878',
			boxSizing: 'border-box',
			padding: 0,
			background: '#fff',
			'& .MuiTab-wrapper': {
				fontSize: '0.875rem',
				color: '#111'
			}
		},
		'& button.Mui-selected': {
			fontWeight: 'bold',
			color: '#fff',
			opacity: 1,
			boxSizing: 'border-box',
			background: '#2F80ED',
			borderRadius: 6,
			'& .MuiTab-wrapper': {
				color: '#fff'
			}
		},
		'& .MuiTabs-indicator': {
			height: "0px",
		}
	},
	checkedColor: {
		'& .MuiTypography-root': {
			fontSize: '1.125rem',
		},
		'& g': {
			stroke: '#7500fa',
			opacity: 1
		}
	},
	examDeleteIcon: {
		'&.MuiButton-root': {
			background: '#fff',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#F31825',
				fontWeight: 'bold'
			}
		}
	},
	importTaskBtn: {
		'&.MuiButtonBase-root': {
			background: '#CBCBCB',
			borderRadius: 6,
			marginBottom: "10px",
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				marginLeft: "5px",
			}
		}
	},
	importTaskBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			border: '1px solid #CBCBCB',
			borderRadius: 6,
			marginBottom: "10px",
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#111',
				marginLeft: "5px",
			}
		}
	},
	checkMargin: {
		marginRight: "40px",
		'& .MuiTypography-root': {
			fontSize: '1.125rem'
		},
		'& .MuiCheckbox-colorSecondary.Mui-checked:hover': {
			color: '#fff'
		},
		'& .MuiCheckbox-colorSecondary': {
			padding: 0,
			margin: 9,
		}
	},
	previewText: {
		'&.MuiTypography-root': {
			color: '#008C73',
			fontWeight: 'bold',
			fontSize: '1.25rem',
			marginBottom: "10px",
		}
	},
	previewSubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			marginBottom: "10px",
		}
	},
	breadcrumbsList: {
		marginLeft: "40px",
		'& .MuiBreadcrumbs-li:first-child': {
			'& svg': {
				marginTop: "3px",
			}
		},
		'& .MuiBreadcrumbs-li:last-child': {
			'& .MuiTypography-root': {
				color: '#008C73',
				fontWeight: 'bold'
			}
		}
	},
	moreMenu: {
		border: '1px solid #CBCBCB',
		borderRadius: 3,
		width: "36px",
		height: "36px",
		marginRight: "25px",

	},
	popoverBox: {
		'& .MuiPaper-root': {
			width: "120px",
			boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
			border: '1px solid #bbb',
			borderRadius: 6,
			'& ul': {
				padding: '4px 0',
				'& li': {
					color: '#6f6f6f',
					letterSpacing: '-0.5px',
					display: 'flex',
					justifyContent: 'center',
					'& .MuiTypography-root': {
						marginLeft: "6px",
						fontSize: '0.875rem',
					},
					'&:hover': {
						background: '#eee'
					},
				},
			},
			'& *': {
				fontFamily: 'Pretendard-Regular !important',
			},
		}
	},

	answerFormAdd: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		'& .MuiButtonBase-root': {
			background: '#fff',
			'&:hover': {
				background: '#fff'
			}
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			letterSpacing: '-0.5px',
			color: '#111',
			marginRight: "3px",
		}
	},
	iconMove: {
		position: 'absolute',
		top: 0,
		left: '50%'
	},
	pageUpIcon: {
		position: 'absolute',
		bottom: '-10px',
		right: 0,
		'&.MuiIconButton-root': {
			border: '1px solid #fff',
			boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
			width: 40,
			height: "40px",
			'&:hover': {
				background: '#fff'
			}

		}
	},
	menu: {
		'& .MuiList-root': {
			width: "180px",
			padding: '15px 10px 10px 10px',
		},
		'& .MuiTypography-root': {
			color: '#000',
			fontSize: '0.75rem',
		},
		'& svg': {
			marginRight: 10
		},

	},
	menuSubText: {
		border: '1px solid #cbcbcb',
		padding: 7,
		marginTop: "10px",
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
		},
	},
	titleTrashIcon: {
		marginTop: "12px",
		marginLeft: "10px",
	},
	addViewBtn: {
		// boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
		// borderRadius: '8px',
		// backgroundColor: '#ffffff',
		// padding: '5px',
		// border: '1px solid #dddddd',
		// transition: 'box-shadow 0.3s ease',
		// // minWidth: '130px !important',
		// width: '130px !important',
		// marginRight: '3px',

		cursor: 'pointer',
		position: 'absolute',
		bottom: 20,
		right: 20,
		textAlign: 'right',
		width: '50px !important',
		minWidth: '100% !important',
		display: 'flex',
		justifyContent: 'right',
		alignItems: 'center',
		zIndex: 999,
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 500,
		},
		'& .MuiIconButton-root': {
			padding: 0,
			marginLeft: "7px",
			background: '#fff',
			'& hover': {
				background: '#fff'
			}
		}
	},
	editerBox: {
		// border: '1px solid #cbcbcb',
		borderRadius: 3,
		minHeight: 360,
		// marginBottom: "5px",
		overflowX: 'auto',
		overflowY: 'hidden',
		paddingBottom: '10px',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
		'& > div': {
			width: '100%',
			minWidth: '500px',

		},
		// '& .toastui-editor-contents': {
		//     fontSize: '1rem !important',
		//     padding: '25px',
		//     '& > p': {
		//         fontSize: '1rem !important',
		//         '& > span': {
		//             fontSize: '1rem !important'
		//         }
		//     }
		// }
	},
	addViewBox: {
		// height: "250px",
		marginLeft: "30px",
		borderRadius: 3,
		// minHeight: 250,
		minHeight: 360,
		marginBottom: "5px",
		overflowX: 'auto',
		overflowY: 'hidden',
		paddingBottom: '10px',

		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
		'& > div': {
			width: '100%',
			minWidth: '550px',

		}
	},
	addViewText: {
		marginLeft: "30px",

		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
			letterSpacing: '-0.5px',
			'& span': {
				fontSize: '1rem',
				fontWeight: 400,
				color: '#555'
			}
		}
	},
	supportBox: {
		height: '40px'
	},

	deleteTooltip: {
		'&.MuiTooltip-tooltip': {
			background: '#2078E8',
			color: '#fff',
			minWidth: 70,
			minHeight: 15,
			maxWidth: 280,
			padding: '10px 15px 10px 15px',
			overflow: 'visible',
			// marginTop: "15px",
			marginLeft: "5px",
			// zIndex: 10,
			fontSize: '0.875rem'
		},
	},

	importAnnounce: {
		marginBottom: "00px",
		'&.MuiTypography-root': {
			fontSize: '0.8rem',
			'& span': {
				color: '#008C73'
			}
		}
	},
});












