import ForwardIcon from '@mui/icons-material/Forward';
import {
	Box,
	Button,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as AddPlusSentenceIcon } from "../../../../common/images/AddPlusSentenceIcon.svg";
import { ReactComponent as AIAnswerBtn } from '../../../../common/images/AIAnswerBtn.svg';
import { ReactComponent as AIAnswerBtnLoad } from '../../../../common/images/AIAnswerBtnLoad.svg';
import { ReactComponent as ExamAnswerDelete } from '../../../../common/images/ExamAnswerDelete.svg';
import { ReactComponent as InformationGuide } from '../../../../common/images/InformationGuide.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ExamAnswerStyle } from "../../styles/ExamAnswerStyle";

import React from 'react';
import { useTranslation } from 'react-i18next';
import TextEditor from "../../../../components/common/TextEditor";
import TextViewer from "../../../../components/common/TextViewer";
import { globalConfig } from '../../../../config/globalConfig';
import { useStores } from '../../../../hooks/useStores';
import { IExamInfo } from '../../../../repositories/model/transfer/IExamInfo';
import { IQuestionInfo } from '../../../../repositories/model/transfer/IQuestionInfo';
import { AIStatusType } from '../../../../stores/support/AIStatusType';
import { useCurrentOrder } from '../../000_Common/001_Functions/useCurrentOrder';
import { useCurrentQuestionHTML } from '../../000_Common/001_Functions/useCurrentQuestionHTML';
import { observer } from 'mobx-react';

type Props = {
	questionInfo: IQuestionInfo,
	examInfo: IExamInfo,
	onStatusChange: any,
	statusKey: string
}

export const QuestionAnswerRow = observer((props: Props) => {
	const { questionInfo, examInfo, onStatusChange, statusKey } = props;

	const { projectExamStore, fileInfoStore, messageStore, navigateStore } = useStores();

	const classes = useEmotionStyles(ExamAnswerStyle);
	const [requestingAI, setRequestingAI] = React.useState(false);
	let getAIAnswerId;
	const i18next = useTranslation();

	React.useEffect(() => {
		onStatusChange(statusKey, requestingAI);
	}, [requestingAI])

	const currentOrder = useCurrentOrder(projectExamStore, examInfo, questionInfo);
	const currentQuestionHTML = useCurrentQuestionHTML(projectExamStore, questionInfo, currentOrder);

	const currentCorrectedAnswerHTML = React.useMemo(() => {
		return questionInfo.initialCorrectedAnswer ? questionInfo.initialCorrectedAnswer : ""
	}, [questionInfo.initialCorrectedAnswer]);

	const handleChangeCorrectedAnswer = (question) => {
		projectExamStore.changeQuestionCorrectedAnswer(examInfo.order, questionInfo.order, question);
	};

	const handleUploadFile = async (file) => {
		const { id, downloadUrl } = await fileInfoStore.create(file);
		if (!id || !downloadUrl) return;

		projectExamStore.addImageId(id);
		return { downloadUrl, id };
	};

	const handleInitInitialCorrectedAnswer = () => {
		projectExamStore.initQuestionInitialCorrectedAnswer(examInfo.order, questionInfo.order);
	};

	const addKeywordTextField = async () => {
		projectExamStore.addKeyword(examInfo.order, questionInfo.order);
	};

	const deleteKeywordTextField = (key) => {
		projectExamStore.deleteKeyword(examInfo.order, questionInfo.order, key);
	};

	const handleChangeCorrectedKeyword = async (e, myKey) => {
		const value = e.target.value;

		if (value.endsWith('.')) {
			const keyword = value.slice(0, -1);
			if (!keyword) {
				return;
			}

			// check duplication
			await checkKeywordDuplication(myKey, keyword)
				.then(result => {
					if (result) {
						e.target.focus();
						return;
					}
				})

			// check empty field
			const firstEmptyIdx = questionInfo.keywordArr.findIndex(item => !item.keyword);
			if (firstEmptyIdx > -1) {
				const nextField = document.getElementById(`keyword-${examInfo.order}-${questionInfo.order}-${firstEmptyIdx}`);
				nextField.focus();
				return;
			}

			// add field
			addKeywordTextField()
				.then(() => {
					const maxIdx = questionInfo.keywordArr.length - 1;
					const nextField = document.getElementById(`keyword-${examInfo.order}-${questionInfo.order}-${maxIdx}`);
					nextField.focus();
				})
			return;
		}

		projectExamStore.changeQuestionCorrectedKeyword(examInfo.order, questionInfo.order, myKey, value);
	};

	const handleBlurCorrectedKeyword = async (e, myKey) => {
		const keywordObj = projectExamStore.getKeywordByKey(examInfo.order, questionInfo.order, myKey);
		if (!keywordObj) return;

		const keywordWithTrim = keywordObj?.keyword?.trim();
		projectExamStore.changeQuestionCorrectedKeyword(examInfo.order, questionInfo.order, myKey, keywordWithTrim);

		await checkKeywordDuplication(myKey, keywordObj?.keyword)
			.then(result => {
				if (result) {
					e.target.focus();
				}
			})
	};

	const checkKeywordDuplication = async (myKey, keyword) => {
		if (!myKey || !keyword) return;

		const isDuplicated = projectExamStore.checkKeywordDuplication(examInfo.order, questionInfo.order, myKey, keyword);

		if (!isDuplicated) return false;

		await messageStore.alert(i18next.t("중복된 문장입니다."));
		return true;
	};

	const handleClickAddBtn = () => {
		addKeywordTextField();
	};

	const handleClickDeleteBtn = (e, key) => {
		deleteKeywordTextField(key);
	};

	const handleClickAIAnswerPaste = async () => {
		if (!questionInfo.aiAnswer) return;

		const result = await messageStore.confirm(i18next.t("기존 답안을 삭제하고 AI 답안을 붙여넣으시겠습니까?"));
		if (!result) return;

		projectExamStore.changeQuestionInitialCorrectedAnswer(examInfo.order, questionInfo.order, questionInfo.aiAnswer);
		projectExamStore.changeQuestionCorrectedAnswer(examInfo.order, questionInfo.order, questionInfo.aiAnswer);
	};

	const handleClickAIReferenceAnswerGeneration = async () => {
		if (!questionInfo.rag) {
			messageStore.alert(i18next.t("참고 자료 등록 단계에서 등록된 자료가 없습니다. 등록 후 재실행해 주세요."));
			return;
		}

		setRequestingAI(true);
		await projectExamStore.createAIAnswer(questionInfo.projectExamCode, questionInfo.projectExamQuestionNum, navigateStore.language)
			.then(() => {
				getAIAnswerId = setInterval(getAIAnswer, 5000);
			})
			.catch(async e => {
				await messageStore.alert(i18next.t("AI 답안 생성에 실패했습니다."));
				console.log("Cannot fail requesting AI answer at createAIAnswer...", e);
				setRequestingAI(false);
			})
	};

	const getAIAnswer = async () => {
		try {
			await projectExamStore.getAIAnswer(questionInfo.projectExamCode, questionInfo.projectExamQuestionNum)
				.then(async result => {
					if (result.aiStatus === AIStatusType.COMPLETED) {
						projectExamStore.changeQuestionAiAnswer(questionInfo.parentOrder, questionInfo.order, result?.aiAnswer);
						clearInterval(getAIAnswerId);
						setRequestingAI(false);
					}

					if (result.aiStatus === AIStatusType.FAILED) {
						await messageStore.alert(i18next.t("AI 답안 생성에 실패했습니다."));
						console.log("Cannot fail requesting AI answer by status 'failed' at getAIAnswer...");
						clearInterval(getAIAnswerId);
						setRequestingAI(false);
					}
				})
		} catch (e) {
			await messageStore.alert(i18next.t("AI 답안 생성에 실패했습니다."));
			console.log("Cannot fail requesting AI answer by unknown error at getAIAnswer...", e);
			clearInterval(getAIAnswerId);
			setRequestingAI(false);
		}
	};

	React.useEffect(() => {
		if (questionInfo.aiStatus === AIStatusType.PROGRESS) {
			setRequestingAI(true);
			getAIAnswerId = setInterval(getAIAnswer, 5000);
		}
	}, []);

	const getCurrentKeyword = (key) => {
		const keywordObj = questionInfo.keywordArr.find(c => c.key === key);
		if (!keywordObj) return "";

		return keywordObj.keyword;
	};

	return (
		<>
			<div style={{ overflow: "hidden" }}>
				<Box>
					<TextViewer
						currentHTML={currentQuestionHTML}
					/>
				</Box>
			</div>
			<Box>
				<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginTop: "20px" }}>

				</Box>
				<Box className={classes.editerArticle} sx={{ position: 'relative' }}>
					<TextEditor
						onSetHTML={handleChangeCorrectedAnswer}
						onUploadFile={handleUploadFile}
						currentHTML={currentCorrectedAnswerHTML}
						onInitInitialContent={handleInitInitialCorrectedAnswer}
					/>
				</Box>

				{globalConfig.useRag &&
					<Box style={{ position: 'relative', height: '70px' }}>
						{requestingAI ?
							<Box className={clsx(classes.sampleAnswerBox, classes.sampleAnswerBoxActive)}>
								<Button disableRipple>
									<Box className={classes.aiSpinner}>
										<AIAnswerBtnLoad />
									</Box>
									<Typography>{i18next.t("AI 답안 생성")}</Typography>
								</Button>
							</Box>
							:
							<Box className={classes.sampleAnswerBox}>
								<Button
									onClick={handleClickAIReferenceAnswerGeneration}
									disableRipple>
									<AIAnswerBtn />
									<Typography>{i18next.t("AI 답안 생성")}</Typography>
								</Button>
							</Box>
						}

						{
							(questionInfo.aiAnswer && !requestingAI) &&
							<Box className={classes.sampleAnswerBox2}>
								<Tooltip
									title={i18next.t("AI 참고 답안 붙여넣기")}
									placement="bottom"
									classes={{
										tooltip: classes.lightTooltip,
									}}
								>
									<Button
										onClick={handleClickAIAnswerPaste}
										disableRipple>
										<ForwardIcon className={classes.arrowIcon} />
									</Button>
								</Tooltip>
							</Box>
						}
					</Box>
				}

				{
					((requestingAI || questionInfo.aiAnswer) &&
						<>
							<Box className={classes.guideBox}>
								{requestingAI ?
									<>
										<Box className={classes.skeletonShortUI} />
										<Box style={{ height: '10px' }} />
										<Box className={classes.skeletonLongUI} />
										<Box style={{ height: '10px' }} />
										<Box className={classes.skeletonMiddleUI} />
									</>
									:
									<Typography>
										{questionInfo.aiAnswer}
									</Typography>
								}
							</Box>
							<Box display='flex' alignItems='center' justifyContent='left' className={classes.guideBoxSubText}>
								<InformationGuide />
								<Typography>{i18next.t("등록한 참고 자료를 기반으로 생성 AI가 작성한 예시 답안입니다.")}</Typography>
							</Box>
						</>
					)
				}

				<Box sx={{ marginTop: "20px" }}>
					<Box>
						<Typography className={classes.userTitle}>{i18next.t("답변 필수 문장")}</Typography>
						<Typography className={classes.previewSubText}>{i18next.t("평가 목표와 채점 기준 등을 고려하여")} <span>{i18next.t("핵심 용어가")}</span> {i18next.t("포함하도록")} <span>{i18next.t("단어")}</span> {i18next.t("또는")} <span>{i18next.t("문장")}</span> {i18next.t("으로 입력해 주세요.")}</Typography>
					</Box>

					{questionInfo.keywordArr.map((keyword, idx) => {
						return (
							<Box key={idx} display='flex' alignItems='center' justifyContent='left' className={classes.dialogInput}>
								<TextField id={`keyword-${examInfo.order}-${questionInfo.order}-${idx}`}
									variant="outlined"
									multiline
									minRows={1}
									value={getCurrentKeyword(keyword.key)}
									onChange={e => handleChangeCorrectedKeyword(e, keyword.key)}
									onBlur={e => handleBlurCorrectedKeyword(e, keyword.key)}
									placeholder={i18next.t("마침표 기준으로 한 문장만 입력할 수 있습니다.")} />
								<Button
									className={classes.deleteAnswerBtn}
									onClick={e => handleClickDeleteBtn(e, keyword.key)}
									disableRipple>
									<ExamAnswerDelete />
								</Button>
							</Box>
						)
					})}

					<Box display='flex' alignItems='center' justifyContent='right'>
						<Button
							className={classes.addAnswerBtn}
							onClick={handleClickAddBtn}
							disableRipple>
							<AddPlusSentenceIcon />
							<Typography>{i18next.t("추가")}</Typography>
						</Button>
					</Box>
				</Box>
			</Box>

		</>
	)
});
