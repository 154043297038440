import React, { useState } from 'react';
import {
	Box,
	Button,
	ButtonGroup,
	Menu,
	MenuItem,
	Typography,
	Divider,
	Backdrop,
	Theme,
	Dialog
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import clsx from 'clsx';
import TemplateGuide from './TemplateGuideDialog';
import { useTheme } from "@mui/styles";
import TemplateGuideDialog from './TemplateGuideDialog';

export const ImportButton = ({ handleClickImportFile, isLoading, classes, i18next }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const theme = useTheme();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [backdropOpen, setBackdropOpen] = useState(false);

	const handleCloseDescription = () => {
		setBackdropOpen(false);
	}

	const handleViewDescription = () => {
		// 설명보기 로직 구현
		handleClose();
		setBackdropOpen(true);
	};

	const handleDownloadTemplate = () => {
		// 템플릿 다운로드 로직 구현, /samples/AssessmentSample_20241031.docx 를 다운로드 시킵니다.
		window.open('/samples/AssessmentSample_20241110.docx');
		handleClose();
	};

	return (
		<>
			<ButtonGroup
				variant="outlined"
				className={clsx(classes.lineBtnGroup)}
			>
				<Button
					onClick={handleClickImportFile}
					disableRipple
					disabled={isLoading}
					sx={{
						border: '1px solid #CBCBCB',
						borderRight: 'none', witdh: "100px"
					}}
					className={clsx(classes.lineBtnGroup, classes.lineBtnActive)}
				>
					<Typography >{i18next.t("불러오기")}</Typography>
				</Button>
				<Button
					size="small"
					onClick={handleClick}
					disableRipple
					disabled={isLoading}
					sx={{
						border: '1px solid #CBCBCB',
						borderLeft: '1px solid #CBCBCB',
						minWidth: '16px',
						padding: '0 4px',
						backgroundColor: 'white',
						width: "30px",
					}}
				>
					<ArrowDropDown />
				</Button>
			</ButtonGroup >
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={handleViewDescription}>
					설명보기
				</MenuItem>
				<Divider /> {/* Add a horizontal line between menu items */}
				<MenuItem onClick={handleDownloadTemplate}>
					템플릿파일 다운로드하기
				</MenuItem>
			</Menu>

			<TemplateGuideDialog
				onClose={handleCloseDescription}
				open={backdropOpen}
			/>

		</>
	);
};

export default ImportButton;