

const nullPng: number[] = [
  0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A, 0x00,
  0x00, 0x00, 0x0D, 0x49, 0x48, 0x44, 0x52, 0x00, 0x00,
  0x00, 0x01, 0x00, 0x00, 0x00, 0x01, 0x08, 0x06, 0x00,
  0x00, 0x00, 0x1F, 0x15, 0xC4, 0x89, 0x00, 0x00, 0x00,
  0x01, 0x73, 0x52, 0x47, 0x42, 0x00, 0xAE, 0xCE, 0x1C,
  0xE9, 0x00, 0x00, 0x00, 0x04, 0x67, 0x41, 0x4D, 0x41,
  0x00, 0x00, 0xB1, 0x8F, 0x0B, 0xFC, 0x61, 0x05, 0x00,
  0x00, 0x00, 0x09, 0x70, 0x48, 0x59, 0x73, 0x00, 0x00,
  0x16, 0x25, 0x00, 0x00, 0x16, 0x25, 0x01, 0x49, 0x52,
  0x24, 0xF0, 0x00, 0x00, 0x00, 0x0D, 0x49, 0x44, 0x41,
  0x54, 0x18, 0x57, 0x63, 0xF8, 0xFF, 0xFF, 0xFF, 0x7F,
  0x00, 0x09, 0xFB, 0x03, 0xFD, 0x05, 0x43, 0x45, 0xCA,
  0x00, 0x00, 0x00, 0x00, 0x49, 0x45, 0x4E, 0x44, 0xAE,
  0x42, 0x60, 0x82
];


export const nullPngUrl = {
  data: nullPng,
  url: null as string,
};

(function () {
  let binary = '';
  for (let i = 0, len = nullPng.length; i < len; i++) binary += String.fromCharCode(nullPng[i]);
  const b64encoded = btoa(binary);
  nullPngUrl.url = "data:image/png;base64," + b64encoded;
})();
