import { Repository } from "./Repository";
import { ISimpleUser } from "./model/ISimpleUser";
import { IUser } from "./model/IUser";
import { IUserProfile } from "./model/IUserProfile";
import { IUserToken } from "./model/IUserToken";

type Props = {
	serverContextPath: string;
};

export default class AuthRepository extends Repository {
	public requestPrefix: string;
	public requesUsersPrefix: string;
	public requesReCHATCHAPrefix: string;

	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/authentications";
		this.requesUsersPrefix = props.serverContextPath + "/api/v1/users";
		this.requesReCHATCHAPrefix = props.serverContextPath + "/api/v1/captcha";
	}

	public async signIn(param): Promise<IUserToken> {
		try {
			const data = await this.getRequestPromise<IUserToken>("post", this.requestPrefix + "/signin", "", param);
			return data;
		} catch (error) {
			this.removeAuthTokenFromStorage();
			throw error;
		}
	}
	public async signCheck() {
		try {
			const data = await this.getRequestPromise<ISimpleUser>("get", this.requestPrefix + "/signcheck");
			return data;
		} catch (error) {
			this.removeAuthTokenFromStorage();
			throw error;
		}
	}

	public async signOut() {
		try {
			const data = await this.getRequestPromise<void>("post", this.requestPrefix + "/signout");
			this.removeAuthTokenFromStorage();
			return data;
		} catch (error) {
			this.removeAuthTokenFromStorage();
			throw error;
		}
	}

	public async signUp(data: IUser) {
		try {
			// createUser
			const result = await this.getRequestPromise<void>("post", this.requesUsersPrefix, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async updatePassword(params: {
		userCode: string;
		email: string;
		newPassword: string;
		[key: string]: any; // 추가적인 프로퍼티를 허용
	}) {
		try {
			const data = await this.getRequestPromise<void>("put", this.requestPrefix + "/update/password", params);
			return data;
		} catch (error) {
			this.removeAuthTokenFromStorage();
			throw error;
		}
	}

	// public async resendSignUpEmail(userId) {
	// 	throw new Error("Not implemented yet");
	// 	try {
	// 		const data = await this.getRequestPromise("get", `${this.requesUsersPrefix}/${userId}/signup-email`);
	// 		return data;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	// public async resendPasswordEmail(userId: string) {
	// 	throw new Error("Not implemented yet");
	// 	try {
	// 		const data = await this.getRequestPromise("get", `${this.requesUsersPrefix}/${userId}/password-email`);
	// 		return data;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	public async checkNicknameDuplicate(nickname) {
		throw new Error("Not implemented yet");
		try {
			const data = await this.getRequestPromise("get", `${this.requesUsersPrefix}/nickname/${nickname}`);
			return data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	public async getUserByEmail(email: string) {
		try {
			const data = await this.getRequestPromise<IUser>("get", `${this.requesUsersPrefix}/email/${email}`);
			return data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	public async modifyPassword(token, params) {
		try {
			const data = await this.getRequestPromise("put", `${this.requesUsersPrefix}/token/${token}/password`, params);
			return data;
		} catch (error) {
			throw error;
		}
	}

	// public async changePassword(param) {
	// 	throw new Error("Not implemented yet");
	// 	try {
	// 		const data = await this.getRequestPromise("put", `${this.requesUsersPrefix}/userId/password`, param);
	// 		return data;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	// createUserProfile = (data) => {
	// 	return new Promise((resolve, reject) => {
	// 		this.getRequestPromise(
	// 			"get",
	// 			`${this.requesUsersPrefix}/profiles`,
	// 			{},
	// 			data
	// 		)
	// 			.then((data) => {
	// 				resolve(data);
	// 			})
	// 			.catch((error) => {
	// 				reject(error);
	// 			});
	// 	});
	// };

	public async createUserProfile(data: IUserProfile) {
		try {
			const result = await this.getRequestPromise<void>("post", `${this.requesUsersPrefix}/profiles`, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async changeUserProfile(data) {
		throw new Error("Not implemented yet");

		try {
			const result = await this.getRequestPromise("post", `${this.requesUsersPrefix}/modify`, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}


	public async getLoginUserInfo(id) {
		try {
			const data = await this.getRequestPromise("get", `${this.requesUsersPrefix}/${id}`, {});
			return data;
		} catch (error) {
			throw error;
		}
	}

	public async checkRECAPTCHA(param) {
		try {
			const data = await this.getRequestPromise("get", `${this.requesReCHATCHAPrefix}`, param);
			return data;
		} catch (error) {
			throw error;
		}
	}


	public async requestPasswordReset(email: string, language: string) {
		// API 호출을 통해 비밀번호 재설정 이메일 요청
		try {
			const body = { email, language };
			const url = `${this.requestPrefix}/forgot-password`;
			const data = await this.getRequestPromise("post", url, {}, body);
			return data;
		} catch (error) {
			throw error;
		}
	}

	public async resetPassword(token: string, newPassword: string) {
		// API 호출을 통해 새 비밀번호 설정
		try {
			const body = { token, newPassword };
			const url = `${this.requestPrefix}/reset-password`;
			const data = await this.getRequestPromise("post", url, {}, body);
			return data;
		} catch (error) {
			throw error;
		}
	}
}
