import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

export const TextEditorStyle: (theme: any) => Record<string, CSSInterpolation> = (theme) => (
	{
		main: (props: any) => ({
			height: `${props.boxHeight} !important`,
			width: '99.5% !important',
			borderRadius: '3px !important',
			position: 'relative',
			'& .toastui-editor-contents': {
				fontSize: '1rem',
			},
			'& .toastui-editor-main-container .toastui-editor-ww-container .toastui-editor.ww-mode .toastui-editor-ww-code-block:after': {
				display: 'none'
			}
		}),

		codeBlock: {
			fontSize: '1rem',
			fontWeight: 'bold',
		},

		resizer: {
			width: '10px',
			height: '10px',
			position: 'absolute',
			right: '8px',
			bottom: '8px',
			zIndex: 9999,
			cursor: 'nwse-resize',
		},
		editerArticle: {
			border: '0px solid #cbcbcb',
			borderRadius: 3,
			marginBottom: "5px",
			marginLeft: "30px",
			display: 'flex',
			overflowX: 'auto',
			overflowY: 'hidden',
			'&::-webkit-scrollbar': {
				width: '10px',
				height: '10px'
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#6f6f6f',
				borderRadius: '10px',
				backgroundClip: 'padding-box',
				border: '3px solid transparent',
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
				marginTop: "5px",
			},
			'& > div': {
				width: '100%',
				minWidth: '520px',
			},
		},
	} as any as Record<string, CSSInterpolation>);
