import { Repository } from "./Repository";


type Props = {
	serverContextPath: string;
};

export default class ProjectExamQuestionRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/projects";
	}

	public async createAIAnswer(examCode, questionNum, language: string) {
		// TODO: Implement this method
		let locale = "ja-JP";
		switch (language) {
			case "ko":
				locale = "ko-KR";
				break;
			case "en":
				locale = "en-US";
				break;
			case "zh":
				locale = "zh-CN";
				break;
			default:
				locale = "ja-JP";
				break;
		}


		const data = await this.getRequestPromise("put", `${this.requestPrefix}/exams/${examCode}/questions/${questionNum}/ai?languageCode=${locale}`, {});
		return data;
	}

	public async getAIAnswer(examCode, questionNum) {
		const data = await this.getRequestPromise("get", `${this.requestPrefix}/exams/${examCode}/questions/${questionNum}`);
		return data;
	}

	public async uploadProjectPdfFile(projectCode: string, file: File) {
		try {
			const formData = new FormData();
			formData.append('file', file);

			const result = await this.getRequestPromise(
				"post",
				`${this.requestPrefix}/${projectCode}/file`,
				{},
				formData,
				{
					'Content-Type': 'multipart/form-data'
				}
			);

			return result;
		} catch (error) {
			throw error;
		}
	}

	public async getProjectFile(projectCode: string, fileId: number) {
		const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/files/${fileId}/rags`);
		return result;
	}

	public async getProjectFiles(projectCode: string) {
		const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/files`);
		return result;
	}
}