import { Backdrop, CircularProgress, Theme } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useParams } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import SubmitManagement from "./20_Assignments/2_Submissions/OfflineSubmissions/SubmitManagement";
import OnlineSubmitManagement from "./20_Assignments/2_Submissions/OnlineSubmisstions/OnlineSubmitManagement";

type Props = {
}

function SubmitTypeCheck(props: Props) {
	const params = useParams();
	const { projectStore } = useStores();
	const { project } = projectStore;
	const { projectCode } = params;
	React.useEffect(() => {
		projectStore.downloadProjectInfoAndStore(projectCode);//기본정보 %필수정보
		return () => {
			projectStore.initProject();
		}
	}, [])

	return (
		<>
			{project ?
				<>{project.includedOnline ?
					// <OnlineSubmitManagement />
					<SubmitManagement />
					:
					<SubmitManagement />

				}</>
				:
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={true}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			}
		</>
	);
}

export default observer<typeof SubmitTypeCheck>(SubmitTypeCheck);
