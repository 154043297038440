import { BluetoothTypeEnum } from "../../../nl-lib-bt-devices";
import { IPenIdType, IPenToViewerEvent } from "../../../../../nl-lib3-common";
import PenManager from "../../PenManager";
import { autoReconnectAtRestartWebVars, mauiPenCommManagerConfig } from "./autoReconnectAtRestartWebVars";


export async function autoReconnectMauiPen(id: IPenIdType, mac: string, incomingPenPacket: Uint8Array, userId: string) {

	if (autoReconnectAtRestartWebVars.autoCreateMauiPenWorking.has(id)) {
		const working = autoReconnectAtRestartWebVars.autoCreateMauiPenWorking.get(id);

		if (incomingPenPacket) {
			working.interimBuf = new Uint8Array([...working.interimBuf, ...incomingPenPacket]);
		}
		await working.promise;
	} else {
		autoReconnectAtRestartWebVars.autoCreateMauiPenWorkingFlag = true;

		const promise = new Promise<void>((resolve) => {
			// 2024-03-12, Auto Reconnection, by kitty
			const pen = PenManager.instance.createPen({
				userId,
				bluetoothType: BluetoothTypeEnum.MAUI
			});

			pen.registerConnectedMauiBluetooth(id, true);
			pen.mac = mac;

			PenManager.instance.onConnected({ pen } as IPenToViewerEvent);
			// const pencommBase = MauiPenCommManager.instance.getMauiPenComm(id);
			// pencommBase.protocolHandler.reqVersion_01();

			if (incomingPenPacket) {
				const dv = new DataView(incomingPenPacket.buffer);
				const pencomm = mauiPenCommManagerConfig.mauiPenCommManager.getMauiPenComm(id);

				pencomm?.onPenPacketReceived({ target: { value: dv } });
			}

			resolve();
		});

		autoReconnectAtRestartWebVars.autoCreateMauiPenWorking.set(id, { promise, interimBuf: new Uint8Array(0) });

		await promise;
		autoReconnectAtRestartWebVars.autoCreateMauiPenWorkingFlag = false;
		// 중간에 쌓인 버퍼를 처리한다.
		const interimBuf = autoReconnectAtRestartWebVars.autoCreateMauiPenWorking.get(id).interimBuf;

		const dv = new DataView(interimBuf.buffer);
		const pencomm = mauiPenCommManagerConfig.mauiPenCommManager.getMauiPenComm(id);

		pencomm?.onPenPacketReceived({ target: { value: dv } });
		autoReconnectAtRestartWebVars.autoCreateMauiPenWorking.delete(id);
	}
}