import { FzDocument } from "./../emscripten-wrapper/classes/FzDocument";
import { LINE_CAP_STYLE, LINE_JOIN_STYLE, PATH_FILL_TYPE } from "../emscripten-wrapper/classes/SvgPathDrawEnum";
import { ColorSpace, cookieAborted, FzBuffer, FzDevice, FzMatrix, FzOutput, FzPage, FzPixmap, FzStream, JobCookie, Links, Outline, PdfDocument, PdfPage, STextPage } from "../emscripten-wrapper/mupdf-wrapper";
import { logConf } from "./mupdf-log-tools";
import { editToolConfig } from "./mupdf-worker-util-funcs";
import { IPageSTextResult } from "./return-types/IPageTextResult";
import WasmJSLoader from "../emscripten-wrapper/mupdf-wrapper";
import { uuidv4 } from "../common/UtilFuncs";
import { FzContext } from "../emscripten-wrapper/classes/FzContext";





// mupdf.ready
//   .then(result => {
//     console.log("mupdf-worker-methods.ts: ready.READY");
//   })
//   .catch(error => {
//     console.log("mupdf-worker-methods.ts: ready.ERROR");
//   });



class WasmMethodClass {

  // let openStream = null;
  // let openDocument:FzDocument = null as unknown as FzDocument;

  // TODO - Move this to mupdf-view
  private lastPageRender = new Map();

  // pointer to FzDocument
  private openContexts = new Map<string, FzContext>();

  public openDocuments = new Map<string, { doc: FzDocument | PdfDocument, refCnt: number }>();

  // pointer to FzPage
  private openPages = new Map<string, { page: FzPage | PdfPage, refCnt: number }>();

  // pointer to FzPixmap
  private openPixmaps = new Map<string, FzPixmap>();

  // pointer to FzBuffer
  private openBuffers = new Map<string, FzBuffer>();

  // pointer to FzStream
  private openStreams = new Map<string, FzStream>();

  // pointer to FzStream
  private openCookies = new Map<string, JobCookie>();


  public getCookiePointer = (cookieId: string) => {
    const cookie = this.openCookies.get(cookieId);
    if (cookie == null)
      throw new Error(`cookieId '${cookieId}' not found`);
    return cookie.pointer;
  }


  _mupdfInstanceReady = new Promise((resolve, reject) => { });

  loader: WasmJSLoader = null;


  module: any = null;


  public DeviceGray: ColorSpace = null;

  public DeviceRGB: ColorSpace = null;

  public DeviceBGR: ColorSpace = null;

  public DeviceCMYK: ColorSpace = null;

  private _defaultContextId: string = null;

  public get defaultContextId() { return this._defaultContextId; }

  public set defaultContextId(id: string) {
    this._defaultContextId = id;
  }



  libReady: Promise<{ module: any; sharedBuffer: any; }> = new Promise((resolve, reject) => { });

  id: string = uuidv4();
  wasmBinaryUrl: string;

  constructor(args: { wasmBinaryUrl: string, uuid: string }) {
    const { wasmBinaryUrl } = args;
    this.wasmBinaryUrl = wasmBinaryUrl;
  }

  public loadWasm = () => {
    const reservedThis = this;

    this.libReady = new Promise<{ module: any, sharedBuffer: any }>((resolve, reject) => {
      // const loader = new WasmJSLoader({ wasmBinaryUrl: origin + reservedThis.wasmBinaryUrl });
      const loader = new WasmJSLoader({ wasmBinaryUrl:  reservedThis.wasmBinaryUrl });
      reservedThis.loader = loader;

      // console.log(`WASM loading ${this.id}`);
      loader.load().then(result => {
        const { module: libmupdf } = result;
        this.module = libmupdf;

        const ctx = new FzContext(libmupdf);
        this.openContexts.set(ctx.id, ctx);
        this.defaultContextId = ctx.id;

        // const ctx2 = libmupdf._wasm_init_context(defaultContextCacheSize);

        const csGrayPtr = libmupdf._wasm_device_gray(ctx.pointer);
        this.DeviceGray = new ColorSpace(libmupdf, ctx.pointer, csGrayPtr);
        this.DeviceRGB = new ColorSpace(libmupdf, ctx.pointer, libmupdf._wasm_device_rgb(ctx.pointer));
        this.DeviceBGR = new ColorSpace(libmupdf, ctx.pointer, libmupdf._wasm_device_bgr(ctx.pointer));
        this.DeviceCMYK = new ColorSpace(libmupdf, ctx.pointer, libmupdf._wasm_device_cmyk(ctx.pointer));

        // console.log("WASM Loading complete");

        resolve(result);
      });
    });

    return this.libReady;
  }

  private get libmupdf() {
    return this.module;
  }

  private trylaterScheduled = false;
  public trylaterQueue = [];

  public onFetchCompleted = (_id: number) => {
    if (!this.trylaterScheduled) {
      this.trylaterScheduled = true;

      setTimeout(() => {
        this.trylaterScheduled = false;
        const currentQueue = this.trylaterQueue;
        this.trylaterQueue = [];
        currentQueue.forEach(onmessage);
      }, 0);
    }
  };


  /**
   *
   * @param filters
   */
  setLogFilters = (filters: RegExp[]) => {
    logConf.logFilters = filters;
  };

  createFzContext = (cacheSize?: number) => {
    const ctx = new FzContext(this.libmupdf, cacheSize);
    this.openContexts.set(ctx.id, ctx);

    return ctx.id;
  };

  getDefaultContextId = () => this.defaultContextId;

  // 하위 호환성을 위해
  getGlobalContext = () => {
    return this.defaultContextId;
  }

  /**
   *
   * @param ctx
   */

  loadPageByDoc = (doc: FzDocument, pageIndex: number) => {
    if (!doc) return null;

    const page = doc.loadPage(pageIndex);
    if (!page) {
      console.error(`loadPageByDoc: page not found: ${pageIndex}`);
      return null;
    }

    if (this.openPages.has(page.id)) {
      let item = this.openPages.get(page.id);
      if (item.page.pointer === 0 || item.refCnt <= 0) {
        item = { page, refCnt: 0 };
      }
      item.refCnt++;
      // console.log(`loadPageByDoc: page already open: ${page.pointer} RefCnt: ${item.refCnt}`);
      this.openPages.set(page.id, item);
    }
    else {
      this.openPages.set(page.id, { page, refCnt: 1 });
    }

    // console.log(`loadPageByDoc: page open: pageId: ${page.id} pagePtr: ${page.pointer} openPagesCount: ${this.openPages.size}`);

    return page;
  }

  freeContext = (ctxId: string) => {
    // this.openPages.forEach((item) => item.page.free());
    // this.openPages.clear();

    // this.openDocuments.forEach((item) => item.doc.free());
    // this.openDocuments.clear();
    const ctxPointer = this.openContexts.get(ctxId);
    this.libmupdf._wasm_drop_context(ctxPointer);
  };

  fromContextId = (ctxId: string) => {
    return this.openContexts.get(ctxId);
  }

  lockContext = (ctxId: string) => {
    const ctxPointer = this.openContexts.get(ctxId);
    this.libmupdf._wasm_lock(ctxPointer);
  }

  unlockContext = (ctxId: string) => {
    const ctxPointer = this.openContexts.get(ctxId);
    this.libmupdf._wasm_unlock(ctxPointer);
  }

  /**
   *
   * @param ctx
   * @param url
   * @param contentLength
   * @param progressive
   * @param prefetch
   * @returns FzStream Pointer
   */
  openStreamFromUrl = (ctxId: string, url: string, contentLength: number, progressive: number, prefetch: number) => {
    const ctx = this.openContexts.get(ctxId);
    const openStream = FzStream.fromUrl(
      this.libmupdf,
      ctx.pointer, url, contentLength, Math.max(progressive << 10, 1 << 16), prefetch);
    // console.log("openStreamFromUrl", openStream);
    // TODO - close stream?

    this.openStreams.set(openStream.id, openStream);
    return openStream.id;
  };


  setOpenDocuments = (docId: string, doc: FzDocument) => {
    if (this.openDocuments.has(docId)) {
      let item = this.openDocuments.get(docId);
      if (item.doc.pointer !== 0) {
        console.log("setOpenDocuments: doc already freed", docId);
        item.refCnt++;
        this.openDocuments.set(docId, item);
        return
      }
      item = { doc, refCnt: 1 };
      item.refCnt++;
      this.openDocuments.set(docId, item);
      return
    }

    this.openDocuments.set(docId, { doc, refCnt: 1 });
  }
  /**
   *
   * @param ctx
   * @param buffer
   * @param magic
   * @returns FzDocument Pointer
   */
  openDocumentFromBuffer = (buffer: ArrayBuffer, magic: string, ctxId: string, isJobStart?: boolean) => {
    const ctx = this.openContexts.get(ctxId);
    const openDocument = FzDocument.openFromJsBuffer(this.module, ctx.pointer, buffer, magic);
    // this.setOpenDocuments(openDocument.id, openDocument);
    // return openDocument.id;

    const pdf = PdfDocument.fromFzDocument(this.module, ctx.pointer, openDocument);
    this.setOpenDocuments(pdf.id, pdf);

    return pdf.id;
  };


  /**
   *
   * @param ctx
   * @param openStream
   * @param magic
   * @returns FzDocument Pointer
   */
  openDocumentFromStream = (ctxId: string, openStream: FzStream, magic: string) => {
    const ctx = this.openContexts.get(ctxId);
    if (openStream == null) {
      throw new Error("openDocumentFromStream called but no stream has been open");
    }
    const openDocument = FzDocument.openFromStream(this.module, ctx.pointer, openStream, magic);
    // this.setOpenDocuments(openDocument.id, openDocument);
    // return openDocument.id;

    const pdf = PdfDocument.fromFzDocument(this.module, ctx.pointer, openDocument);
    this.setOpenDocuments(pdf.id, pdf);

    return pdf.id;
  };

  /**
   *
   * @param ctx
   * @param streamPointer streamPointer from openStreamFromUrl()
   * @param magic
   * @returns FzDocument Pointer
   */
  openDocumentFromStreamPointer = (ctxId: string, streamPointer: number, magic: string) => {
    const ctx = this.openContexts.get(ctxId);
    const openDocument = FzDocument.openFromStreamPointer(this.module, ctx.pointer, streamPointer, magic);
    // this.setOpenDocuments(openDocument.id, openDocument);
    // return openDocument.id;

    const pdf = PdfDocument.fromFzDocument(this.module, ctx.pointer, openDocument);
    this.setOpenDocuments(pdf.id, pdf);

    return pdf.id;
  };


  /**
   *
   * @param ctx
   * @param streamPointer streamPointer from openStreamFromUrl()
   * @param magic
   * @returns FzDocument Pointer
   */
  openDocumentFromStreamId = (ctxId: string, streamId: string, magic: string) => {
    const ctx = this.openContexts.get(ctxId);
    const stream = this.openStreams.get(streamId);
    const openDocument = FzDocument.openFromStreamPointer(this.module, ctx.pointer, stream.pointer, magic);
    // this.setOpenDocuments(openDocument.id, openDocument);
    // return openDocument.id;

    const pdf = PdfDocument.fromFzDocument(this.module, ctx.pointer, openDocument);
    this.setOpenDocuments(pdf.id, pdf);

    return pdf.id;
  };

  /**
   *
   * @param docPointer
   */
  freeDocument = (docId: string) => {
    if (!docId) return;
    const item = this.openDocuments.get(docId);
    if (!item) {
      console.error(`freeDocument: doc not found: ${docId}`);
      return false;
    }

    const { doc, refCnt } = item;
    // console.log("freeDocument", docId, refCnt);

    doc?.free();

    const ref = refCnt - 1;
    if (ref <= 0) {
      this.openDocuments.set(docId, { doc, refCnt: 0 });
      // this.openPages.delete(pagePointer);
      return true;
    }
    this.openDocuments.set(docId, { doc, refCnt: ref });
    return false;
  };

  freeStream = (streamId: string) => {
    const stream = this.openStreams.get(streamId);
    stream?.free();
    // openDocument = null;
    this.openStreams.delete(streamId);
  };

  loadPage = (docId: string, pageIndex: number) => {
    const { doc } = this.openDocuments.get(docId);
    const page = this.loadPageByDoc(doc, pageIndex);
    if (!page) {
      // console.error(`loadPage: page not found: ${pageIndex}`);
      console.error(`pdf-worker-methods-class.loadPage: page not found: ${pageIndex}`);
      return null;
    }

    // console.log(`loadPage: ${pageIndex} => ${docId} / ${page.doc.pointer}`);

    return page.id;
  };

  freePage = (pageId: string) => {
    const pageItem = this.openPages.get(pageId);
    if (!pageItem) {
      console.error("loadpage, freePage: pagePointer not found", pageId);
      return false;
    }

    const { page, refCnt } = pageItem;

    const ref = refCnt - 1;
    if (ref <= 0) {
      this.openPages.set(pageId, { page, refCnt: 0 });
      // console.log(`loadpage, freePage: pageId: ${pageId}, pagePtr: ${page.pointer}, refCnt: ${ref}`);
      page.free();
      this.openPages.delete(page.id);

      return true;
    }
    this.openPages.set(pageId, { page, refCnt: ref });
    return false;
  };


  freePixmap = (pixmapId: string) => {
    const pix = this.openPixmaps.get(pixmapId);
    pix?.free();
    this.openPixmaps.delete(pixmapId);
  };

  freeBuffer = (bufferId: string) => {
    const buf = this.openBuffers.get(bufferId);
    buf?.free();
    this.openBuffers.delete(bufferId);
  };




  /**
   *
   * @param docPointer
   * @returns The title of the document
   */
  documentTitle = (docId: string) => {
    const { doc } = this.openDocuments.get(docId);
    return doc.title();
  };

  /**
   *
   * @param docPointer
   * @returns
   */
  documentOutline = (docId: string) => {
    const { doc } = this.openDocuments.get(docId);
    const root = doc.loadOutline();

    if (root == null)
      return null;

    function makeOutline(node: Outline) {
      const list = [];
      while (node) {
        const entry = {
          title: node.title(),
          page: node.pageIndex(doc),
          down: undefined,
        };

        const down = node.down();
        if (down)
          entry.down = makeOutline(down);

        list.push(entry);
        node = node.next();
      }
      return list;
    }

    try {
      return makeOutline(root);
    } finally {
      root.free();
    }
  };


  /**
   *
   * @param docPointer
   * @returns The number of pages
   */
  countPages = (docId: string) => {
    const { doc } = this.openDocuments.get(docId);
    return doc?.countPages() || 0;
  };

  // TODO - use hungarian notation for coord spaces
  // TODO - document the "- 1" better
  // TODO - keep page loaded?
  /**
   *
   * @param docPointer
   * @param pageIndex
   * @returns The size of page in PDF points unit
   */
  getPageSize = (pageId: string) => {
    const item = this.openPages.get(pageId);
    if (!item) {
      console.error("getPageSize: pagePointer not found", pageId);
      throw new Error("getPageSize: pagePointer not found");
    }

    const { page } = item;
    const bounds = page.bounds();
    return { width: bounds.width(), height: bounds.height() };
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @returns The links of a page
   */
  getPageLinks = (docId: string, pageId: string) => {
    let links_ptr: Links;
    const { doc } = this.openDocuments.get(docId);
    const { page } = this.openPages.get(pageId);

    try {
      links_ptr = page.getLinks();

      return links_ptr.links.map(link => {
        const { x0, y0, x1, y1 } = link.getBounds();

        let href = "";
        if (link.isExternal()) {
          href = link.getURI();
        } else {
          const linkPageNumber = link.resolve(doc).pageIndex(this.libmupdf, doc.ctx, doc);
          // TODO - move to front-end
          // TODO - document the "+ 1" better
          href = `#page${linkPageNumber + 1}`;
        }

        return {
          x: x0,
          y: y0,
          w: x1 - x0,
          h: y1 - y0,
          href
        };
      });
    }
    finally {
      page?.free();
      links_ptr?.free();
    }
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @returns
   */
  getPageText = (pageId: string) => {
    const { page } = this.openPages.get(pageId);
    const { doc } = page;
    const { ctx } = doc;
    // const pdf = PdfDocument.fromFzDocument(ctx, doc);

    let stextPage: STextPage;

    let buffer: FzBuffer;
    let output: FzOutput;

    try {
      console.log("getPageText", page.pageIndex, pageId, page.pointer, page.doc.pointer, page.doc.ctx);
      stextPage = page.toSTextPage();

      buffer = FzBuffer.empty(this.libmupdf, ctx);
      output = FzOutput.withBuffer(this.libmupdf, ctx, buffer);

      stextPage.printAsJson(output, 1.0);
      output.close();

      const text = buffer.toJsString();
      const ret: IPageSTextResult = JSON.parse(text);
      return ret;
    }
    finally {
      output?.free();
      buffer?.free();
      stextPage?.free();
    }
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param needle - Search term
   * @returns
   */
  search = (pageId: string, needle: string) => {
    const { page } = this.openPages.get(pageId);

    try {
      const hits = page.search(needle);
      return hits.map(searchHit => {
        const { x0, y0, x1, y1 } = searchHit;

        return {
          x: x0,
          y: y0,
          w: x1 - x0,
          h: y1 - y0,
        };
      });
    }
    finally {
    }
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param dpi - DPI to calculate the annotation coordinates
   * @returns
   */
  getPageAnnotations = (pageId: string, dpi: number) => {
    // const { doc } = this.openDocuments.get(docId);
    const { page } = this.openPages.get(pageId);

    // let pdfPage: PdfPage;
    try {
      // pdfPage = doc.loadPage(pageIndex) as unknown as PdfPage;

      if (page == null) {
        return [];
      }

      const annotations = (page as PdfPage).getAnnotations();
      const doc_to_screen = FzMatrix.scale(this.libmupdf, dpi / 72, dpi / 72);

      return annotations.annotations.map(annotation => {
        const { x0, y0, x1, y1 } = doc_to_screen.transformRect(this.libmupdf, annotation.bounds());

        return {
          x: x0,
          y: y0,
          w: x1 - x0,
          h: y1 - y0,
          type: annotation.annotType(),
          ref: annotation.pointer,
        };
      });
    }
    finally {
      // pdfPage?.free();
    }
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param dpi - DPI to draw the page
   * @param cookiePointer - Cancel and progress cookie pointer
   * @returns
   */
  drawPageAsPNG = (pageId: string, dpi: number, cookieId: string) => {
    // const { doc } = this.openDocuments.get(docId);
    const { page } = this.openPages.get(pageId);

    const doc_to_screen = FzMatrix.scale(this.libmupdf, dpi / 72, dpi / 72);
    const cookie = this.openCookies.get(cookieId);

    // let page: FzPage;
    let pixmap: FzPixmap;

    // TODO - use canvas?

    try {
      // page = doc.loadPage(pageIndex);
      pixmap = page.toPixmap(doc_to_screen, this.DeviceRGB, false, cookie ? cookie.pointer : 0);

      if (cookieAborted(this.module, page.doc.ctx, cookie ? cookie.pointer : 0)) {
        pixmap = null;
      }

      // TODO - move to frontend
      if (page.pageIndex === editToolConfig.currentTool.pageIndex)
        editToolConfig.currentTool.drawOnPage(pixmap, dpi);

      const png = pixmap?.toPNG();
      const width = pixmap.width();
      const height = pixmap.height();

      return png;
    }
    catch (e) {
      throw e;
    }
    finally {
      pixmap?.free();
      // page?.free();
    }
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param dpi - DPI to draw the page
   * @param cookiePointer - Cancel and progress cookie pointer
   * @returns
   */
  drawPageAsPixmap = (pageId: string, dpi: number, cookieId: string) => {
    // const { doc } = this.openDocuments.get(docId);
    const { page } = this.openPages.get(pageId);

    const doc_to_screen = FzMatrix.scale(this.libmupdf, dpi / 72, dpi / 72);
    const cookie = this.openCookies.get(cookieId);

    // let page: FzPage;
    let pixmap: FzPixmap;

    try {
      // page = doc.loadPage(pageIndex);
      pixmap = page.toPixmap(doc_to_screen, this.DeviceRGB, true, cookie ? cookie.pointer : 0);

      if (cookieAborted(this.module, page.doc.ctx, cookie ? cookie.pointer : 0)) {
        return null;
      }

      // TODO - move to frontend
      if (page.pageIndex === editToolConfig.currentTool.pageIndex)
        editToolConfig.currentTool.drawOnPage(pixmap, dpi);

      const pixArray = pixmap.toUint8ClampedArray();
      const imageData = new ImageData(pixArray, pixmap.width(), pixmap.height());

      return imageData;
    }
    finally {
      pixmap?.free();
      // page?.free();
    }
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param dpi - DPI to draw the page
   * @param cookiePointer - Cancel and progress cookie pointer
   * @returns
   */
  drawPageContentsAsPixmap = (pageId: string, dpi: number, cookieId: string) => {
    // const { doc } = this.openDocuments.get(docId);
    const { page } = this.openPages.get(pageId);
    const { ctx } = page.doc;
    const cookie = this.openCookies.get(cookieId);

    const doc_to_screen = FzMatrix.scale(this.libmupdf, dpi / 72, dpi / 72);

    // let page: FzPage;
    let pixmap: FzPixmap;
    let device: FzDevice;

    try {
      // page = doc.loadPage(pageIndex);

      const bbox = page.bounds().transformed(this.libmupdf, FzMatrix.from(doc_to_screen));
      pixmap = FzPixmap.withBbox(this.libmupdf, ctx, this.DeviceRGB, bbox, true);
      pixmap.clear();

      device = FzDevice.drawDevice(this.libmupdf, ctx, doc_to_screen, pixmap);
      page.runPageContents(device, FzMatrix.identity, cookie ? cookie.pointer : 0);
      device.close();

      if (cookieAborted(this.module, page.doc.ctx, cookie ? cookie.pointer : 0)) {
        return null;
      }

      const pixArray = pixmap.toUint8ClampedArray();
      const imageData = new ImageData(pixArray, pixmap.width(), pixmap.height());

      return imageData;
    }
    finally {
      pixmap?.free();
      // page?.free();
      device?.free();
    }
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param dpi - DPI to draw the page
   * @param cookiePointer - Cancel and progress cookie pointer
   * @returns
   */
  drawPageAnnotsAsPixmap = (pageId: string, dpi: number, cookieId: string) => {
    // const { doc } = this.openDocuments.get(docId);
    const { page } = this.openPages.get(pageId);
    const { ctx } = page.doc;
    const doc_to_screen = FzMatrix.scale(this.libmupdf, dpi / 72, dpi / 72);
    const cookie = this.openCookies.get(cookieId);

    // let page: FzPage;
    let pixmap: FzPixmap;
    let device: FzDevice;

    try {
      // page = doc.loadPage(pageIndex);

      const bbox = page.bounds().transformed(this.libmupdf, FzMatrix.from(doc_to_screen));
      pixmap = FzPixmap.withBbox(this.libmupdf, ctx, this.DeviceRGB, bbox, true);
      pixmap.clear();

      device = FzDevice.drawDevice(this.libmupdf, ctx, doc_to_screen, pixmap);
      page.runPageAnnots(device, FzMatrix.identity, cookie ? cookie.pointer : 0);
      device.close();

      if (cookieAborted(this.module, page.doc.ctx, cookie ? cookie.pointer : 0)) {
        return null;
      }

      const pixArray = pixmap.toUint8ClampedArray();
      const imageData = new ImageData(pixArray, pixmap.width(), pixmap.height());

      return imageData;
    }
    finally {
      pixmap?.free();
      // page?.free();
      device?.free();
    }
  };


  drawPageWidgetsAsPixmap = (pageId: string, dpi: number, cookieId: string) => {
    // const { doc } = this.openDocuments.get(docId);
    const { page } = this.openPages.get(pageId);
    const doc_to_screen = FzMatrix.scale(this.libmupdf, dpi / 72, dpi / 72);

    // let page: FzPage;
    let pixmap: FzPixmap;
    let device: FzDevice;
    const cookie = this.openCookies.get(cookieId);

    try {
      // page = doc.loadPage(pageIndex);

      const bbox = page.bounds().transformed(this.libmupdf, FzMatrix.from(doc_to_screen));
      pixmap = FzPixmap.withBbox(this.libmupdf, page.doc.ctx, this.DeviceRGB, bbox, true);
      pixmap.clear();

      device = FzDevice.drawDevice(this.libmupdf, page.doc.ctx, doc_to_screen, pixmap);
      page.runPageWidgets(device, FzMatrix.identity, cookie ? cookie.pointer : 0);
      device.close();

      if (cookieAborted(this.module, page.doc.ctx, cookie ? cookie.pointer : 0)) {
        return null;
      }

      const pixArray = pixmap.toUint8ClampedArray();
      const imageData = new ImageData(pixArray, pixmap.width(), pixmap.height());

      return imageData;
    }
    finally {
      pixmap?.free();
      // page?.free();
      device?.free();
    }
  };

  /**
   *
   * @param ctx - Context pointer
   * @returns
   */
  createCookie = (ctxId: string) => {
    const ctx = this.openContexts.get(ctxId);
    const cookie = JobCookie.create(this.module, ctx.pointer);

    this.openCookies.set(cookie.id, cookie);
    return cookie.id;
  };

  /**
   * Free the cookie
   * @param cookiePointer - Cookie pointer
   * @returns
   */
  deleteCookie = (cookieId: string) => {
    const cookie = this.openCookies.get(cookieId);
    if (cookie == null) {
      return;
    }
    cookie.free();
    this.openCookies.delete(cookieId);
  };

  /**
   *
   * @param cookiePointer - Cookie pointer
   * @returns - progress
   */
  getProgress = (cookieId: string) => {
    const cookie = this.openCookies.get(cookieId);
    if (cookie == null) {
      return 0;
    }
    return cookie.progress();
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param dpi - DPI to calulatte the coordinates of mouse on page
   * @param x - Mouse X
   * @param y - Mouse Y
   * @returns
   */
  mouseDownOnPage = (pageId: string, dpi: number, x: number, y: number) => {
    // const { doc } = this.openDocuments.get(docId);
    const { page } = this.openPages.get(pageId);

    // TODO - Do we want to do a load every time?
    // const pdfPage = doc.loadPage(pageIndex);
    const pdfPage = page as PdfPage;

    if (pdfPage == null) {
      return false;
    }

    if (page.pageIndex !== editToolConfig.currentTool.pageIndex) {
      // TODO - schedule paint
      this.lastPageRender.delete(editToolConfig.currentTool.pageIndex);
      editToolConfig.currentTool.resetPage(pdfPage, page.pageIndex);
    }

    // transform mouse pos from screen coordinates to document coordinates.
    x /= (dpi / 72);
    y /= (dpi / 72);

    const pageChanged = editToolConfig.currentTool.mouseDown(x, y);
    if (pageChanged) {
      this.lastPageRender.delete(page.pageIndex);
    }

    return pageChanged;

    // TODO - multi-selection
    // TODO - differentiate between hovered, selected, held
  };

  // TODO - handle crossing pages
  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param dpi - DPI to calulatte the coordinates of mouse on page
   * @param x - Mouse X
   * @param y - Mouse Y
   * @returns
   */
  mouseDragOnPage = (pageId: string, dpi: number, x: number, y: number) => {
    const { page } = this.openPages.get(pageId);

    if (page.pageIndex !== editToolConfig.currentTool.pageIndex)
      return false;

    // transform mouse pos from screen coordinates to document coordinates.
    x /= (dpi / 72);
    y /= (dpi / 72);

    const pageChanged = editToolConfig.currentTool.mouseDrag(x, y);
    if (pageChanged) {
      this.lastPageRender.delete(page.pageIndex);
    }
    return pageChanged;
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param dpi - DPI to calulatte the coordinates of mouse on page
   * @param x - Mouse X
   * @param y - Mouse Y
   * @returns
   */
  mouseMoveOnPage = (pageId: string, dpi: number, x: number, y: number) => {
    // const { doc } = this.openDocuments.get(docId);
    const { page } = this.openPages.get(pageId);

    if (page.pageIndex !== editToolConfig.currentTool.pageIndex)
      return false;

    // const pdfPage = doc.loadPage(pageIndex);
    const pdfPage = page as PdfPage;

    if (pdfPage == null) {
      return false;
    }

    // transform mouse pos from screen coordinates to document coordinates.
    x /= (dpi / 72);
    y /= (dpi / 72);

    const pageChanged = editToolConfig.currentTool.mouseMove(x, y);

    if (pageChanged) {
      this.lastPageRender.delete(page.pageIndex);
    }
    return pageChanged;
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param dpi - DPI to calulatte the coordinates of mouse on page
   * @param x - Mouse X
   * @param y - Mouse Y
   * @returns
   */
  mouseUpOnPage = (docId: string, pageIndex: number, dpi: number, x: number, y: number) => {
    if (pageIndex !== editToolConfig.currentTool.pageIndex)
      return false;

    // transform mouse pos from screen coordinates to document coordinates.
    x /= (dpi / 72);
    y /= (dpi / 72);

    const pageChanged = editToolConfig.currentTool.mouseUp(x, y);
    if (pageChanged) {
      this.lastPageRender.delete(pageIndex);
    }
    return pageChanged;
  };

  /**
   *
   * @returns
   */
  deleteItem = () => {
    const pageChanged = editToolConfig.currentTool.deleteItem();
    if (pageChanged) {
      this.lastPageRender.delete(editToolConfig.currentTool.pageIndex);
    }
    return editToolConfig.currentTool.pageIndex;
  };


  // 2023-02-26

  //#region - Document
  /**
   *
   * @param docPointer - Document pointer
   * @param glyph_offset - glyph offset in 600 DPI pixel unit
   * @param glyph_diameter - glyph diameter in 600 DPI pixel unit
   * @param stroke_width - stroke width in PDF pts unit
   * @param glyph_shape - tthe shape of glyph, g0=line, g1=square, g2=diamond, g3=circle
   * @returns - font pointer
   */
  addNcodeFontToDocument = (
    docId: string,
    glyph_offset: number /* double */,
    glyph_diameter: number /* double */,
    stroke_width: number /* double */,
    glyph_shape: number /* int */
  ) => {
    const { doc } = this.openDocuments.get(docId);
    if (!doc) {
      throw new Error('Document not found');
    }
    const font = (doc as PdfDocument).addNcodeFont(glyph_offset, glyph_diameter, stroke_width, glyph_shape);
    return font;
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param width - Page width in points unit (A4 = 595)
   * @param height - Page height in points unit (A4 = 842)
   * @param rotate - Page rotation (0 = normal, 1 = 90 degrees, 2 = 180 degrees, 3 = 270 degrees)
   */
  addBlankPage = (docId: string, width: number, height: number, rotate: number) => {
    const { doc } = this.openDocuments.get(docId);
    if (!doc) {
      throw new Error('Document not found');
    }

    (doc as PdfDocument).addBlankPage(width, height, rotate);
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param width - Page width in points unit (A4 = 595)
   * @param height - Page height in points unit (A4 = 842)
   * @param rotate - Page rotation (0 = normal, 1 = 90 degrees, 2 = 180 degrees, 3 = 270 degrees)
   */
  insertBlankPageAt = (docId: string, pageIndex: number, width: number, height: number, rotate: number) => {
    const { doc } = this.openDocuments.get(docId);
    if (!doc) {
      throw new Error('Document not found');
    }
    (doc as PdfDocument).insertBlankPageAt(pageIndex, width, height, rotate);
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param start - Start page index
   * @param end - End page index
   */
  removePageRange = (docId: string, start: number, end: number) => {
    const { doc } = this.openDocuments.get(docId);
    if (!doc) {
      throw new Error('Document not found');
    }
    (doc as PdfDocument).removePageRange(start, end);
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   */
  removePageAt = (docId: string, pageIndex: number) => {
    const { doc } = this.openDocuments.get(docId);
    if (!doc) {
      throw new Error('Document not found');
    }
    (doc as PdfDocument).removePageAt(pageIndex);
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param donorPointer - Donor document pointer
   * @param start - Start page index
   * @param numPages - Number of pages
   */
  appendPDFPages = (docId: string, donorId: string, start: number, numPages: number) => {
    const { doc } = this.openDocuments.get(docId);
    if (!doc) {
      throw new Error('Document(destinationDoc) not found');
    }

    // const pdf = PdfDocument.fromFzDocument(doc.ctx, doc);

    const { doc: donorDoc } = this.openDocuments.get(donorId);
    if (!donorDoc) {
      throw new Error('Document(donorDoc) not found');
    }
    // const donorPdf = PdfDocument.fromFzDocument(donorDoc.ctx, donorPointer);

    (doc as PdfDocument).appendPDFPages(donorDoc, start, numPages);
  };


  saveDocumentBytes = (docId: string, compress: boolean) => {
    const { doc } = this.openDocuments.get(docId);
    if (!doc) {
      throw new Error('Document not found');
    }

    const u8 = (doc as PdfDocument).save(compress);
    return u8;
  };

  pdfDocumentFromFzDocument = (docId: string) => {
    const { doc } = this.openDocuments.get(docId);
    if (!doc) {
      throw new Error('Document not found');
    }

    if (doc instanceof PdfDocument) {
      return doc;
    } else if (doc instanceof FzDocument) {
      const pdf = PdfDocument.fromFzDocument(this.libmupdf, doc.ctx, doc);
    return pdf;
    }

    throw new Error('Document is not FzDocument nor PdfDocument');
  };

  //#endregion - Document


  //#region - Page
  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param toRgb - Convert to RGB
   * @param toCmy - Convert to CMY
   * @param fontObj - Font object Pointer, if null, use default font
   * @param useRgbPseudoColor - Use RGB Pseudo Color when converting to RGB
   * @param maxBlueContrast - Max Blue Contrast when converting to blueprint
   * @param shouldFlatten - false = no flattening, true (flatten pages with transparency to RGB in RGB mode, flatten pages with transparency to CMY in CMY mode)
   * @param dropContents - Drop contents
   * @param rgbSoftMark - when soft masks needs add ones
   * @returns
   */
  convertPageColor = (
    pageId: string,
    toRgb: boolean,
    toCmy: boolean,
    ncodeFont: { pointer: number, charset: string } = null,
    useRgbPseudoColor = false,
    maxBlueContrast = 0.9,
    shouldFlatten = false,
    dropContents = true,
    rgbSoftMark = false
  ) => {
    const item = this.openPages.get(pageId);
    if (!item) {
      throw new Error('Page not found');
    }
    const { page } = item;

    // const { doc } = this.openDocuments.get(docId);
    // let page: FzPage;

    // const pdf = PdfDocument.fromFzDocument(doc.ctx, docPointer);
    try {
      // page = this.loadPageByDoc(doc, pageIndex);

      (page as unknown as PdfPage).convertColor(
        toRgb, toCmy,
        ncodeFont,
        useRgbPseudoColor,
        maxBlueContrast,
        shouldFlatten, dropContents, rgbSoftMark
      );
      console.log("convertPageColor DONE!")
    }
    finally {
      // this.freePage(page.id);
    }
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @returns
   */
  flattenPage = (pageId: string) => {
    const item = this.openPages.get(pageId);
    if (!item) {
      throw new Error('Page not found');
    }
    const { page } = item;


    // let page: FzPage;
    // const { doc } = this.openDocuments.get(docId);

    try {
      // const pdf = PdfDocument.fromFzDocument(doc.ctx, docPointer);
      // page = this.loadPageByDoc(doc, pageIndex);
      (page as PdfPage).flattenPage();
    }
    finally {
      // this.freePage(page.id);
    }
  };

  insertNcodeLayerDirect = (
    pageId: string,
    isPdfInRGB: boolean,
    forceUseCarbonBlack: boolean,
    sobp: string,
    pageDelta = 0,
    x0 = 0 /* float */,
    y0 = 0 /* float */,
    x1 = 0 /* float */,
    y1 = 0 /* float */,
    ncodeFontPointer: number = null,
    ncodeFontCharset: string = null,
    ncodeBaseX = 0 /* int */,
    ncodeBaseY = 0 /* int */,
    excludeRegions: { x0: number, y0: number, x1: number, y1: number }[] = null
  ) => {
    // let page: FzPage;
    // const { doc } = this.openDocuments.get(docId);

    const item = this.openPages.get(pageId);
    if (!item) {
      throw new Error('Page not found');
    }
    const { page } = item;


    try {
      // const pdf = PdfDocument.fromFzDocument(doc.ctx, docPointer);
      // page = this.loadPageByDoc(doc, pageIndex);

      (page as unknown as PdfPage).insertNcodeLayerDirect(
        isPdfInRGB, forceUseCarbonBlack,
        sobp,
        pageDelta,
        x0, y0, x1, y1,
        ncodeFontPointer ? { pointer: ncodeFontPointer, charset: ncodeFontCharset } : null,
        ncodeBaseX, ncodeBaseY,
        excludeRegions
      );
    }
    finally {
      // this.freePage(page.id);
    }
  };

  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param svgPath - SVG Path string
   * @param r - red color value (0~255)
   * @param g - green color value (0~255)
   * @param b - blue color value (0~255)
   * @param opacity - Opacity (0~1)
   * @param stroke_width - Stroke width (default should be 0.01)
   * @param fill_type - Fill type (default should be PATH_FILL_TYPE.PATH_FILL_TYPE_FILL)
   * @param line_cap_style - Line cap style (default should be LINE_CAP_STYLE.LINE_CAP_STYLE_ROUND)
   * @param line_join_style - Line join style (default should be LINE_JOIN_STYLE.LINE_JOIN_STYLE_ROUND)
   */
  insertSVGPathOnPage2Pass = (
    pageId: string,
    svgPath: string,
    r: number, g: number, b: number, opacity: number,
    stroke_width: number,
    fill_type: PATH_FILL_TYPE,
    line_cap_style: LINE_CAP_STYLE,
    line_join_style: LINE_JOIN_STYLE
  ) => {
    const item = this.openPages.get(pageId);
    if (!item) {
      throw new Error('Page not found');
    }
    const { page } = item;


    // let page: PdfPage;
    // const { doc } = this.openDocuments.get(docId);

    try {
      // page = this.loadPageByDoc(doc, pageIndex) as PdfPage;
      const pdfOperator = (page as PdfPage).convertSvgPathToPdfOperators(svgPath);

      (page as PdfPage).insertSvgPathByPdfOperators(pdfOperator, r, g, b, opacity, stroke_width, fill_type, line_cap_style, line_join_style);
    }
    finally {
      // this.freePage(page.id);
    }
  };


  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param svgPath - SVG Path string
   * @param r - red color value (0~255)
   * @param g - green color value (0~255)
   * @param b - blue color value (0~255)
   * @param opacity - Opacity (0~1)
   * @param stroke_width - Stroke width (default should be 0.01)
   * @param fill_type - Fill type (default should be PATH_FILL_TYPE.PATH_FILL_TYPE_FILL)
   * @param line_cap_style - Line cap style (default should be LINE_CAP_STYLE.LINE_CAP_STYLE_ROUND)
   * @param line_join_style - Line join style (default should be LINE_JOIN_STYLE.LINE_JOIN_STYLE_ROUND)
   */
  insertPDFOperatorPath = (
    pageId: string,
    pdfOperator: string,
    r: number, g: number, b: number, opacity: number,
    stroke_width: number,
    fill_type: PATH_FILL_TYPE,
    line_cap_style: LINE_CAP_STYLE,
    line_join_style: LINE_JOIN_STYLE
  ) => {
    const item = this.openPages.get(pageId);
    if (!item) {
      throw new Error('Page not found');
    }

    const { page } = item;
    if (!page.doc.pointer) {
      console.error('page.doc.pointer is null');
      throw new Error('page.doc.pointer is null');
    }

    try {
      (page as PdfPage).insertSvgPathByPdfOperators(pdfOperator, r, g, b, opacity, stroke_width, fill_type, line_cap_style, line_join_style);
    }
    finally {
      // this.freePage(pageId);
    }
  };


  convertSvgPathToPdfOperators = (ctxId: string, svgPath: string) => {
    const ctx = this.openContexts.get(ctxId);
    const pdfOperator = PdfPage.staticConvertSvgPathToPdfOperators(this.libmupdf, ctx.pointer, svgPath);
    return pdfOperator;
  };


  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param svgPath - SVG Path string
   * @param r - red color value (0~255)
   * @param g - green color value (0~255)
   * @param b - blue color value (0~255)
   * @param opacity - Opacity (0~1)
   * @param stroke_width - Stroke width (default should be 0.01)
   * @param fill_type - Fill type (default should be PATH_FILL_TYPE.PATH_FILL_TYPE_FILL)
   * @param line_cap_style - Line cap style (default should be LINE_CAP_STYLE.LINE_CAP_STYLE_ROUND)
   * @param line_join_style - Line join style (default should be LINE_JOIN_STYLE.LINE_JOIN_STYLE_ROUND)
   */
  insertSVGPathOnPage = (
    pageId: string,
    svgPath: string,
    r: number, g: number, b: number, opacity: number,
    stroke_width: number,
    fill_type: PATH_FILL_TYPE,
    line_cap_style: LINE_CAP_STYLE,
    line_join_style: LINE_JOIN_STYLE
  ) => {

    const item = this.openPages.get(pageId);
    if (!item) {
      throw new Error('Page not found');
    }
    const { page } = item;

    // let page: FzPage;
    // const { doc } = this.openDocuments.get(docId);
    // if (!doc) {
    //   throw new Error("Document not found");
    // }

    try {
      // page = this.loadPageByDoc(doc, pageIndex);
      (page as unknown as PdfPage).insertSVGPathOnPage(svgPath, r, g, b, opacity, stroke_width, fill_type, line_cap_style, line_join_style);
    }
    finally {
      // this.freePage(page.id);
    }
  };


  /**
   *
   * @param docPointer - Document pointer
   * @param pageIndex - Page index
   * @param svgPath - SVG Path string
   * @param r - red color value (0~255)
   * @param g - green color value (0~255)
   * @param b - blue color value (0~255)
   * @param opacity - Opacity (0~1)
   * @param stroke_width - Stroke width (default should be 0.01)
   * @param fill_type - Fill type (default should be PATH_FILL_TYPE.PATH_FILL_TYPE_FILL)
   * @param line_cap_style - Line cap style (default should be LINE_CAP_STYLE.LINE_CAP_STYLE_ROUND)
   * @param line_join_style - Line join style (default should be LINE_JOIN_STYLE.LINE_JOIN_STYLE_ROUND)
   */
  insertSVGPathOnPageAfterLoad = (
    pageId: string,
    svgPath: string,
    r: number, g: number, b: number, opacity: number,
    stroke_width: number,
    fill_type: PATH_FILL_TYPE,
    line_cap_style: LINE_CAP_STYLE,
    line_join_style: LINE_JOIN_STYLE
  ) => {
    const item = this.openPages.get(pageId);
    if (!item) {
      throw new Error('Page not found');
    }

    const { page } = item;
    if (!page.doc.pointer) {
      console.error('page.doc.pointer is null');
      throw new Error('page.doc.pointer is null');
    }
    (page as unknown as PdfPage).insertSVGPathOnPage(svgPath, r, g, b, opacity, stroke_width, fill_type, line_cap_style, line_join_style);
  };

  //#endregion - Page

  //#region - Context
  createNewPdfDocument = (ctxId: string) => {
    const ctx = this.openContexts.get(ctxId);
    const pdf = PdfDocument.createNewPdfDocument(this.module, ctx.pointer);
    this.setOpenDocuments(pdf.id, pdf);
    return pdf.id;

  };
  //#endregion - Context

  getSingleGlyphWidth = () => {
    const width = this.libmupdf._wasm_getSingleGlyphWidth();
    return width;
  }
}

export default WasmMethodClass;

