import { MappingStorage } from "../neolab-libs/nl-lib2-pagestorage/background-pdf-mapper/MappingStorage";
import { IPageSOBP, uuidv4 } from "../neolab-libs/nl-lib3-common";
import { NeoPDFDocument, NeoPDFManager } from "../nl-pdf-wrapper-importer";
import { ProjectExamType } from "../repositories/model/support/ProjectExamType";
import { downloadPaperInfoFromNdp2Local } from "./downloadPaperInfoFromNdp2Local";


export const ndpPdfToDoc = new Map();

const ndpPdfToStr = (ndpPdf: { section: number, owner: number, bookCode: number, pageStart: number, pageEnd: number }) => {
	return ndpPdf.section + '.' + ndpPdf.owner + '.' + ndpPdf.bookCode + '.' + ndpPdf.pageStart + '-' + ndpPdf.pageEnd;
};

export async function loadBackgroundPdf(
	type: ProjectExamType,
	lang: string,
	sobp: IPageSOBP,
	selectedPdf: { section: number, owner: number, bookCode: number, pageStart: number, pageEnd: number },
	numPages: number
) {
	if (!sobp) {
		console.log('sobp undefined');
		return;
	}

	const msi = MappingStorage.instance;
	let mappedItem = msi.getAssociatedPdfPage(sobp);

	if (!mappedItem?.pdf?.url) {
		await downloadPaperInfoFromNdp2Local(type, null, lang, sobp, numPages);
		mappedItem = msi.getAssociatedPdfPage(sobp);
	}

	if (!mappedItem) {
		return null;
	};

	const pageSize = mappedItem.pageMapping.pdfDesc.size_pu;
	const pdf = await loadPDFlocal(mappedItem);
	const pageNo = mappedItem.pageMapping.pdfPageNo;

	if (selectedPdf) ndpPdfToDoc.set(ndpPdfToStr(selectedPdf), { pageSize, pdf, pageNo });

	return { pageSize, pdf, pageNo };
}



async function loadPDFlocal(mappedItem) {
	const { url } = mappedItem.pdf;
	let pdf: NeoPDFDocument = null;

	if (url) {
		// const NPM = await NeoPDFManager.instance;
		const NPM = await NeoPDFManager.getInstance();
		const loadingTask = await NPM.getDocument({
			url,
			purpose: 'for MixedViewControl',
			paperGroupId: uuidv4()
		});

		pdf = await loadingTask;

		console.log('loadedPDF', pdf);
	}

	return pdf;
}
