
import {
	Box,
	Typography
} from "@mui/material";
import { ResultReviewStyle } from "../../styles/ResultReviewStyle";
import useEmotionStyles from "../../styles/useEmotionStyles";

import React from "react";
import TextViewer from "../../../../components/common/TextViewer";

import { useStores } from "../../../../hooks/useStores";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";
import { useCurrentQuestionHTML } from "../../000_Common/001_Functions/useCurrentQuestionHTML";

type Props = {
	idx: number;
	questionInfo: IQuestionInfo;
	examOrderAndQuestionsLengthMap: any;
}

export const QuestionRow = (props: Props) => {
	const { idx, questionInfo, examOrderAndQuestionsLengthMap } = props;
	const { projectExamStore } = useStores();
	const classes = useEmotionStyles(ResultReviewStyle, { isFirst: idx === 0 });

	const currentOrder = React.useMemo(() => {
		const questionListLen = examOrderAndQuestionsLengthMap[questionInfo.parentOrder];

		return projectExamStore.setQuestionFullOrderByQuestionListLen(questionListLen, questionInfo.parentOrder, questionInfo.order);
	}, [examOrderAndQuestionsLengthMap, questionInfo.parentOrder, questionInfo.order]);

	// const currentQuestionHTML = React.useMemo(() => {
	// 	return setQuestionHTMLWithOrderAndScore(questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder);
	// }, [questionInfo, questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder]);
	const currentQuestionHTML = useCurrentQuestionHTML(projectExamStore, questionInfo, currentOrder);

	return (
		<>
			<Box className={classes.compareContent}>
				{/*<Box className={classes.compareRound}>*/}
				<div style={{ overflow: "hidden" }}>
					<Box className={classes.questionViewer}>
						<TextViewer
							currentHTML={currentQuestionHTML}
						/>
					</Box>
				</div>

				<Box className={classes.answerGuideText}>
					<Typography>
						{questionInfo.answer.answer}
					</Typography>
				</Box>
				{/*</Box>*/}
			</Box>

		</>
	);
};
