import { decodeUint8ArrayInObject } from "./websocket-data-converter";
import { DevWebSocketServer } from "./websocket-server-config";
import { initWebSocketCommandProxy } from "./websocket-command-proxy";
import EventClassBase from "../../../../nl-lib3-common/event/EventClassBase";
import { CustomTypeDeserializer } from "./custom-type-deserializer";
import { CustomTypeSerializer } from "./custom-type-serializer";

export async function sleep(ms: number) {
	await new Promise((r) => {
		setTimeout(r, ms);
	});
}



type NetworkDataType = {
	type: "RESPONSE" | "REQUEST",
	func: string,
	serial: number,
	result?: any,
	request?: any,
	error?: string,
};

type ResponseEventType = {
	bubbles: boolean,
	cancelable: boolean,
	cancelBubble: boolean,
	composed: boolean,
	currentTarget: WebSocket,
	data: any,
	defaultPrevented: boolean,
	eventPhase: number,
	isTrusted: boolean,
	lastEventId: string,
	origin: string,
	path: any,
	ports: any,
	returnValue: boolean,
	source: any,
	srcElement: WebSocket,
	target: WebSocket,
	timeStamp: number,
	type: string,
	userActivation: any,
};


type NetworkJobType = {
	serial: number,
	funcName: string,
	promise?: Promise<any>;
	resolve?: (value: any) => void;
	reject?: (v: any) => void;
	started?: boolean
}

export enum DevServerEventName {
	ON_CONNECTION_CHANGED = "changed",
}


export type IDevServerEvent = {
	msg: "closed" | "open",
}


export class MauiRelaySocket extends EventClassBase<DevServerEventName, IDevServerEvent> {
	private static inst: MauiRelaySocket = new MauiRelaySocket();

	private openInProgress = false;

	socket: WebSocket;

	jsToDotNetSerial = 0;

	jobs: Map<number, NetworkJobType> = new Map<number, NetworkJobType>();

	private _connected = false;


	private openResolve: (args: { ok: boolean, reason: any }) => void;

	private openReject: (reason: any) => void;

	public _openPromise: Promise<{ ok: boolean, reason: any }>;

	private devEnvUsingWebSocket = true;

	public get connected() {
		return this._connected;
	}

	public async isConnectedAfterWaiting() {
		await this._openPromise;
		return this._connected;
	}

	// private isDevelopmentEnv() {
	//   const { DotNet, NeoDotNet } = (window as any);
	//   const dotNetEnvironment = DotNet || NeoDotNet;
	//   return !dotNetEnvironment;
	// }

	private constructor() {
		super();
	}

	static get instance() {
		if (!MauiRelaySocket.inst) {
			MauiRelaySocket.inst = new MauiRelaySocket();
		}
		return MauiRelaySocket.inst;
	}

	async close() {
		await this._openPromise;
		this.socket.close();
	}


	public open(): Promise<{ ok: boolean; reason: any; }> {
		if (!this._connected && !this.openInProgress) {
			console.log("OPEN DevServer socket");
			this.openInProgress = true;

			this._openPromise = new Promise<{ ok: boolean, reason: any }>((resolve, reject) => {
				this.openResolve = resolve;
				this.openReject = reject;
			});

			this.socket = new WebSocket(DevWebSocketServer);
			this.socket.onopen = this.onOpen;
			this.socket.onmessage = this.onPacketReceived;
			this.socket.onclose = this.onClose;
			this.socket.onerror = this.onError;
		} else {
			// this.openReject = null;
			if (this._connected) {
				this.openResolve({ ok: true, reason: null });
			}
			else {
				setTimeout(() => {
					this.openResolve({ ok: false, reason: "connecting" });
					this.openInProgress = false;
				}, 300);
			}
		}

		return this._openPromise;
	}

	/**
	 * Should use Lamda function to keep this
	 * @param event
	 */
	onClose = (event: any) => {
		this.openInProgress = false;
		this._connected = false;
		const e: IDevServerEvent = { msg: "closed", }
		this.dispatcher.dispatch(DevServerEventName.ON_CONNECTION_CHANGED, e);

		try {
			this.socket?.close();
		} catch (e) {
			console.error(`socket close error: ${e}`);
		}

		const disposeJobSerials: number[] = [];
		this.jobs.forEach((job) => {
			if (job.started) {
				disposeJobSerials.push(job.serial);
				// job.reject(new Error("Web socket connection lost"));
				job.resolve(null);
			}
		});

		disposeJobSerials.forEach((serial) => {
			this.jobs.delete(serial);
		});

		this.socket = undefined;
	}

	/**
	 * Shoud use Lamda function to keep this
	 * @param event
	 */

	onError = (event: any) => {
		if (this.openInProgress) {
			this.openInProgress = false;
			this._connected = false;

			// 이상하게 reject를 하면 오류를 낸다. 나중에 확인해 볼 것
			this.openReject({});

			// this.openResolve({ ok: false, reason: event });
		}
	}


	/**
	 * Should use Lamda function to keep this
	 * @param event
	 */
	onOpen = async (event: any) => {

		initWebSocketCommandProxy();

		this._connected = true;
		this.openResolve({ ok: true, reason: null });

		this.openInProgress = false;
		const e: IDevServerEvent = { msg: "open", }
		this.dispatcher.dispatch(DevServerEventName.ON_CONNECTION_CHANGED, e);

		// 2024-01-24, refresh 토큰을 받아오기 위한 장치
		// Web Socket debugger에서 호출하는 함수, Main.razor의 OnAfterRenderAsync()와 같은 역할
		// Web Socket 개발 환경에서는 Main.razor의 OnAfterRenderAsync()가 호출되지 않기 때문에
		// this.bridgeInvokeMethodAsync("OnAfterRenderAsyncFromWebDevEnvironment");

		// console.log(event);
		const host = document.location.hostname;
		try {
			await this.bridgeInvokeMethodAsync<void>("InitDevlopmentSocket", host, 299);
		} catch (e) {
			console.error(e);
		}

		try {
			await this.bridgeInvokeMethodAsync<void>("StopDiscovery");
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * Should use Lamda function to keep this
	 * @param event
	 * @returns
	 */
	onPacketReceived = async (event: any) => {
		// console.log(event);
		let blob: Blob;
		let data: string;
		const rawData = (event as ResponseEventType).data;
		if (!rawData) {
			console.error("No data");
			return;
		}

		if (rawData instanceof Blob) {
			blob = rawData;
			data = await blob.text();
		}
		else if (typeof rawData === "string") {
			data = rawData;
		}

		if (!data?.length) {
			console.error("No data");
			return;
		}

		const responseJson = JSON.parse(data) as NetworkDataType;
		const { type, func } = responseJson;


		// if response
		if (type === "RESPONSE") {
			console.log(`RESPONSE: ${func}, Job length ${Object.entries(this.jobs).length}`);
			if (this.jobs.has(responseJson.serial)) {
				const { error, serial, result } = responseJson;
				const job = this.jobs.get(serial);

				if (job) {
					if (error) {
						job?.reject?.(new Error(error));
					} else if (!result) {
						job?.resolve?.(null);
					} else if (typeof result === "object" && Object.keys(result).length === 0) {
						job?.resolve?.(null);
					} else {
						if (typeof result === "object") {
							const convertedResult = decodeUint8ArrayInObject(result);
							job?.resolve?.(convertedResult);
						}
						else {
							let resultJson = null;
							try {
								resultJson = CustomTypeDeserializer.deserialize(result);
							} catch (e) {
								console.error(e);
							}

							job?.resolve?.(resultJson);
						}
					}

					this.jobs.delete(responseJson.serial);
				}

				console.log("JOB resolved", responseJson.func);

				return;
			}

			console.error("NO JOB found for reposnse", responseJson);
		}

		if (type === "REQUEST") {
			const { func } = responseJson;
			const w = window as any;

			const { request } = responseJson;
			try {
				// const param = JSON.parse(request);
				if (w[func]) {
					w[func](...request);
				}
			} catch (e) {
				console.log(e);
				throw e;
			}
		}
	}

	public bridgeInvokeMethodAsync<T = any>(func: string, ...args: any[]): Promise<T> {
		const job: NetworkJobType = { serial: this.jsToDotNetSerial, funcName: func, started: true };
		const pr = new Promise<T>((resolve, reject) => {
			job.resolve = resolve;
			job.reject = reject;
		});
		job.promise = pr;

		this.jobs.set(this.jsToDotNetSerial, job);
		this.jsToDotNetSerial++;

		const serializedArgs = CustomTypeSerializer.serialize(args);
		// const simpleSerilized = JSON.stringify(args);

		const data = { func, serial: job.serial, data: serializedArgs };

		const debugMsg = JSON.stringify({
			func,
			serial: job.serial,
			data: data.data.length > 128 ? "[OVER_128] " + data.data.substring(0, 128) : data.data,
		});


		if (this.socket) {
			job.started = true;
			this.jobs.set(job.serial, job);

			this.sendDataAsync(this, job, data).then((success) => {
				if (success) console.log(`MAUI-WS: ${debugMsg} ==> initiated`);
				else console.log(`MAUI-WS: ${debugMsg} ==> failed`);
			});
		}
		else {
			if (this.devEnvUsingWebSocket) {
				this.open()
					.then((openResult) => {
						if (openResult.ok) {
							job.started = true;
							this.jobs.set(job.serial, job);

							this.sendDataAsync(this, job, data).then((success) => {
								if (success) console.log(`MAUI-WS: ${debugMsg} ==> initiated`);
								else console.log(`MAUI-WS: ${debugMsg} ==> failed`);
							});
						}
					})
					.catch((e) => {
						console.log(`MAUI-WS: ${debugMsg} ==> failed`);
						job.reject?.(e);
					});
			}
		}

		return pr;
	}



	private async sendDataAsync(that: MauiRelaySocket, job: NetworkJobType, data: any) {
		try {
			const connected = await that.isConnectedAfterWaiting();
			if (!connected) {
				// job.reject?.(new Error("No WebSocket connection"));
				console.error("No WebSocket connection");
				job.resolve(null);
				return false;
			}
		} catch (e) {
			console.error("WebSocket send error", e);
			// job.reject?.(new Error("No WebSocket connection"));
			job.resolve(null)
			return false;
		}

		let retry = true;

		while (retry) {
			try {
				that.socket?.send(JSON.stringify(data));
				retry = false;
			} catch (e: any) {
				if (e.code === 11) {
					retry = true;
					await sleep(100);
				}
				else {
					retry = false;
					job.reject?.(e);
					return false;
				}
			}
		}

		return true;
	}

	public bridgeInvokeBytesMethodAsync(func: string, bytes: Uint8Array): Promise<any> {
		const job: NetworkJobType = { serial: this.jsToDotNetSerial, funcName: func, started: true };
		this.jsToDotNetSerial++;

		const pr = new Promise((resolve, reject) => {
			job.resolve = resolve;
			job.reject = reject;
		});
		job.promise = pr;

		this.jobs.set(this.jsToDotNetSerial, job);

		const base64 = btoa(
			Array(bytes.length)
				.fill('')
				.map((_, i) => String.fromCharCode(bytes[i]))
				.join('')
		);


		const data = {
			func,
			serial: job.serial,
			data: base64,
			isBase64Bytes: true,
		};

		const debugMsg = JSON.stringify({
			func,
			serial: job.serial,
			data: base64.length > 128 ? "[OVER_128] " + base64.substring(0, 128) : base64,
		});

		console.log(`MAUI-WS: ${debugMsg}`);

		if (this.socket) {
			job.started = true;
			this.jobs.set(job.serial, job);

			this.sendDataAsync(this, job, data).then((success) => {
				if (success) console.log(`MAUI-WS: ${debugMsg} ==> initiated`);
				else console.log(`MAUI-WS: ${debugMsg} ==> failed`);
			});
		} else {
			job.reject?.(new Error("No WebSocket connection"));
		}

		return pr;
	}
}
