import { nprojToJson } from "../../../nl-lib3-common";
import { IManualRegisterPdfType, INprojMarginType } from "../types/ManualMappingItem";

export function nprojToIManualRegisterPdfType(
    nproj: string,
    options: {
        nproj_url: string;
        pdf_url_from_paperhub: string;
        pdf_url_for_mapper: string;
    }
) {
    const json = nprojToJson(nproj);
    const { author, title, section, owner, book, start_page } = json.book;
    const name = `${author}_${title}_${section}_${owner}_${book}`;
    const numPages = json.pages.length;

    const nprojMargins: INprojMarginType[] = [];
    for (let i = 0, l = json.pages.length; i < l; ++i) {
        const page = json.pages[i];
        const { sobp, nu, whole, size_pu: pu, crop_margin } = page;
        const obj: INprojMarginType = {
            numPages,
            name,
            sobp,
            nu,
            pu,
            whole,
            crop_margin,
            Mag: 1,
            homography: undefined
        };

        nprojMargins.push(obj);
    }

    const { nproj_url, pdf_url_from_paperhub, pdf_url_for_mapper } = options;
    const ret: IManualRegisterPdfType = {
        name: title, // "수학톡톡 6-1"
        // eslint-disable-next-line object-property-newline
        section,
        owner,
        book,
        startPage: start_page,
        numPages,

        nproj_url, // "/background_pdf/3_1012_1191/3_1012_1191_Math_TokTok_3-1.nproj",
        pdf_url_from_paperhub,
        pdf_url_for_mapper,
        nprojMargins, // INprojMarginType[],

        background_image_url_rule: undefined, // "/background_pdf/${section}_${owner}_${book}/${section}_${owner}_${book}_${page}.jpg";
        filename: undefined,
        fingerprint: undefined,
        nprojJson: json
    };
    return ret;
}
