import { Button } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "../../hooks/useStores";
import { ProjectSubmitType } from "../../repositories/model/support/ProjectSubmitType";

type Props = {
}

function UploadTest(props: Props) {
	const { projectSubmissionStore } = useStores();
	const [files, setFile] = React.useState<File[]>([]);

	const handleFileUpload = (e) => {
		console.log("handleFileUpload");
		console.log("e.target.files : ", e.target.files);
		const target = Object.values(e.target.files);
		setFile(target as File[]);
	}

	const upload = () => {
		console.log("upload start... ")
		const projectCode = "0v4r972M";
		const userCode = "adfdb227-6844-4d63-bb3d-6320b84e74f6";
		const submitType = ProjectSubmitType.OFFLINE;

		projectSubmissionStore.uploadTest(projectCode, userCode, submitType, files);
	}

	return (
		<div>
			<div>
				<Button onClick={upload}>upload</Button>
				<input type={"file"}
					id={"file-input"}
					aria-label="Upload Files"
					onChange={handleFileUpload}
					multiple />
			</div>
		</div>
	);
}
export default observer<typeof UploadTest>(UploadTest);
