import { assert, neoAllocateUTF8 } from "./functions";
import { FzMatrix } from "./FzMatrix";
import { FzPoint } from "./FzPoint";
import { FzRect } from "./FzRect";
import { Wrapper } from "./Wrapper";

// TODO extends PdfObj
export class Annotation extends Wrapper {
  constructor(libmupdf: any, ctx: number, pointer: number) {
    super(libmupdf, ctx, pointer, () => { });
  }

  active() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_active(this.ctx, this.pointer) !== 0;
  }

  setActive(active) {
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_active(this.ctx, this.pointer, active ? 1 : 0);
  }

  hot() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_hot(this.ctx, this.pointer) !== 0;
  }

  setHot(hot) {
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_hot(this.ctx, this.pointer, hot ? 1 : 0);
  }

  getTransform() {
    const { libmupdf } = this;
    return FzMatrix.fromPtr(libmupdf, libmupdf._wasm_pdf_annot_transform(this.ctx, this.pointer));
  }

  // TODO getObj? Or use extends?

  page() {
    // TODO - store page ref in class
  }

  bound() {
    const { libmupdf } = this;
    return FzRect.fromFloatRectPtr(libmupdf, libmupdf._wasm_pdf_bound_annot(this.ctx, this.pointer));
  }

  needsResynthesis() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_needs_resynthesis(this.ctx, this.pointer) !== 0;
  }

  setResynthesised() {
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_resynthesised(this.ctx, this.pointer);
  }

  dirty() {
    const { libmupdf } = this;
    libmupdf._wasm_pdf_dirty_annot(this.ctx, this.pointer);
  }

  setPopup(rect) {
    assert(rect instanceof FzRect, "invalid rect argument");
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_popup(this.ctx, this.pointer, rect.x0, rect.y0, rect.x1, rect.y1);
  }

  popup() {
    const { libmupdf } = this;
    return FzRect.fromFloatRectPtr(libmupdf, libmupdf._wasm_pdf_annot_popup(this.ctx, this.pointer));
  }

  typeString() {
    // the string returned by this function is static and doesn't need to be freed
    const { libmupdf } = this;
    return libmupdf.UTF8ToString(libmupdf._wasm_pdf_annot_type_string(this.ctx, this.pointer));
  }

  // TODO
  flags() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_flags(this.ctx, this.pointer);
  }

  setFlags(flags) {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_set_annot_flags(this.ctx, this.pointer, flags);
  }

  hasRect() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_has_rect(this.ctx, this.pointer) !== 0;
  }

  rect() {
    const { libmupdf } = this;
    return FzRect.fromFloatRectPtr(libmupdf, libmupdf._wasm_pdf_annot_rect(this.ctx, this.pointer));
  }

  setRect(rect) {
    assert(rect instanceof FzRect, "invalid rect argument");
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_rect(this.ctx, this.pointer, rect.x0, rect.y0, rect.x1, rect.y1);
  }

  contents() {
    const { libmupdf } = this;
    const string_ptr = libmupdf._wasm_pdf_annot_contents(this.ctx, this.pointer);
    try {
      return libmupdf.UTF8ToString(string_ptr);
    }
    finally {
      libmupdf._wasm_free(this.ctx, string_ptr);
    }
  }

  setContents(text) {
    const { libmupdf } = this;
    const text_ptr = neoAllocateUTF8(libmupdf, this.ctx, text);
    try {
      libmupdf._wasm_pdf_set_annot_contents(this.ctx, this.pointer, text_ptr);
    }
    finally {
      libmupdf._wasm_free(this.ctx, text_ptr);
    }
  }

  hasOpen() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_has_open(this.ctx, this.pointer) !== 0;
  }

  isOpen() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_is_open(this.ctx, this.pointer) !== 0;
  }

  setIsOpen(isOpen) {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_set_is_open(this.ctx, this.pointer, isOpen ? 1 : 0);
  }

  hasIconName() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_has_icon_name(this.ctx, this.pointer) !== 0;
  }

  iconName() {
    // the string returned by this function is static and doesn't need to be freed
    const { libmupdf } = this;
    return libmupdf.UTF8ToString(libmupdf._wasm_pdf_annot_icon_name(this.ctx, this.pointer));
  }

  setIconName(name) {
    const { libmupdf } = this;
    const name_ptr = neoAllocateUTF8(libmupdf, this.ctx, name);
    try {
      libmupdf._wasm_pdf_set_annot_icon_name(this.ctx, this.pointer, name_ptr);
    }
    finally {
      libmupdf._wasm_free(this.ctx, name_ptr);
    }
  }

  // TODO - line endings

  border() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_border(this.ctx, this.pointer);
  }

  setBorder(width) {
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_border(this.ctx, this.pointer, width);
  }

  // TODO - fz_document_language

  language() {
    // the string returned by this function is static and doesn't need to be freed
    const { libmupdf } = this;
    return libmupdf.UTF8ToString(libmupdf._wasm_pdf_annot_language(this.ctx, this.pointer));
  }

  setLanguage(lang) {
    const { libmupdf } = this;
    const lang_ptr = neoAllocateUTF8(libmupdf, this.ctx, lang);
    try {
      libmupdf._wasm_pdf_set_annot_language(this.ctx, this.pointer, lang_ptr);
    }
    finally {
      libmupdf._wasm_free(this.ctx, lang_ptr);
    }
  }

  // TODO
  //wasm_pdf_annot_quadding
  //wasm_pdf_set_annot_quadding

  opacity() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_opacity(this.ctx, this.pointer);
  }

  setOpacity(opacity) {
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_opacity(this.ctx, this.pointer, opacity);
  }

  // TODO
  // pdf_annot_MK_BG
  // pdf_set_annot_color
  // pdf_annot_interior_color

  hasLine() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_has_line(this.ctx, this.pointer) !== 0;
  }

  line() {
    const { libmupdf } = this;
    const line_ptr = libmupdf._wasm_pdf_annot_line(this.ctx, this.pointer);
    return [
      FzPoint.fromPtr(libmupdf, line_ptr),
      FzPoint.fromPtr(libmupdf, line_ptr + 8),
    ];
  }

  setLine(point0, point1) {
    assert(point0 instanceof FzPoint, "invalid point0 argument");
    assert(point1 instanceof FzPoint, "invalid point1 argument");
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_line(this.ctx, this.pointer, point0.x, point0.y, point1.x, point1.y);
  }

  hasVertices() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_has_vertices(this.ctx, this.pointer) !== 0;
  }

  vertexCount() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_vertex_count(this.ctx, this.pointer);
  }

  vertex(i) {
    const { libmupdf } = this;
    return FzPoint.fromPtr(libmupdf, libmupdf._wasm_pdf_annot_vertex(this.ctx, this.pointer, i));
  }

  // TODO pdf_set_annot_vertices

  clearVertices() {
    const { libmupdf } = this;
    libmupdf._wasm_pdf_clear_annot_vertices(this.ctx, this.pointer);
  }

  addVertex(point) {
    assert(point instanceof FzPoint, "invalid point argument");
    const { libmupdf } = this;
    libmupdf._wasm_pdf_add_annot_vertex(this.ctx, this.pointer, point.x, point.y);
  }

  setVertex(i, point) {
    assert(point instanceof FzPoint, "invalid point argument");
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_vertex(this.ctx, this.pointer, i, point.x, point.y);
  }

  // TODO - quad points

  modificationDate() {
    // libmupdf uses seconds since epoch, but Date expects milliseconds
    const { libmupdf } = this;
    return new Date(libmupdf._wasm_pdf_annot_modification_date(this.ctx, this.pointer) * 1000);
  }

  creationDate() {
    // libmupdf uses seconds since epoch, but Date expects milliseconds
    const { libmupdf } = this;
    return new Date(libmupdf._wasm_pdf_annot_creation_date(this.ctx, this.pointer) * 1000);
  }

  setModificationDate(date) {
    assert(date instanceof Date, "invalid date argument");
    // Date stores milliseconds since epoch, but libmupdf expects seconds
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_modification_date(this.ctx, this.pointer, date.getTime() / 1000);
  }

  setCreationDate(date) {
    assert(date instanceof Date, "invalid date argument");
    // Date stores milliseconds since epoch, but libmupdf expects seconds
    const { libmupdf } = this;
    libmupdf._wasm_pdf_set_annot_creation_date(this.ctx, this.pointer, date.getTime() / 1000);
  }

  hasAuthor() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_has_author(this.ctx, this.pointer) !== 0;
  }

  author() {
    const { libmupdf } = this;
    const string_ptr = libmupdf._wasm_pdf_annot_author(this.ctx, this.pointer);
    try {
      return libmupdf.UTF8ToString(string_ptr);
    }
    finally {
      libmupdf._wasm_free(this.ctx, string_ptr);
    }
  }

  setAuthor(name) {
    const { libmupdf } = this;
    const name_ptr = neoAllocateUTF8(libmupdf, this.ctx, name);
    try {
      libmupdf._wasm_pdf_set_annot_author(this.ctx, this.pointer, name_ptr);
    }
    finally {
      libmupdf._wasm_free(this.ctx, name_ptr);
    }
  }

  // TODO - default appearance

  fieldFlags() {
    const { libmupdf } = this;
    return libmupdf._wasm_pdf_annot_field_flags(this.ctx, this.pointer);
  }

  fieldValue() {
    const { libmupdf } = this;
    const string_ptr = libmupdf._wasm_pdf_annot_field_value(this.ctx, this.pointer);
    try {
      return libmupdf.UTF8ToString(string_ptr);
    }
    finally {
      libmupdf._wasm_free(this.ctx, string_ptr);
    }
  }

  fieldLabel() {
    const { libmupdf } = this;
    const string_ptr = libmupdf._wasm_pdf_annot_field_label(this.ctx, this.pointer);
    try {
      return libmupdf.UTF8ToString(string_ptr);
    }
    finally {
      libmupdf._wasm_free(this.ctx, string_ptr);
    }
  }

  // TODO
  //int pdf_set_annot_field_value(fz_context *ctx, pdf_document *doc, pdf_annot *annot, const char *text, int ignore_trigger_events)
  // void pdf_set_annot_appearance(fz_context *ctx, pdf_annot *annot, const char *appearance, const char *state, fz_matrix ctm, fz_rect bbox, pdf_obj *res, fz_buffer *contents)
  // void pdf_set_annot_appearance_from_display_list(fz_context *ctx, pdf_annot *annot, const char *appearance, const char *state, fz_matrix ctm, fz_display_list *list)

  // TODO filespec

}
