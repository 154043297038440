
import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

export const BatchUploadStlye: (theme: any) => Record<string, CSSInterpolation> = (theme) => (
	{
		root: {
			width: '100%',
			textAlign: 'left',
		},
		dialogBox: {
			// width: "439px",
			borderRadius: 4,
			position: 'relative',
			'& .MuiDialog-paperWidthSm': {
				width: "439px",
			}
		},
		titleDialog: {
			'&.MuiTypography-root': {
				textAlign: 'center',
				// padding: 25,
				fontSize: '1.5rem',
				fontWeight: 800,
				lineHeight: '20px',
				color: '#333',
			}
		},

		discoveryNotice: {
			color: '#333',
			'&.MuiTypography-root': {
				fontSize: '1rem',
				fontWeight: 500,
				color: '#aaaaaa',
				textAlign: 'center',
				'& span': {
					color: '#1976D2',
				}
			}
		},

		studentInfo: {
			color: '#333',
			'&.MuiTypography-root': {
				fontSize: '1rem',
				fontWeight: 500,
				color: '#333',
				textAlign: 'center',
				marginTop: "0px",
				marginBottom: "34px",
				'& span': {
					color: '#1976D2',
				}
			}
		},
		dialogBtn: {
			'&.MuiButton-root': {
				width: "357px",
				height: "50px",
				background: '#008C73',
				margin: '0 auto',
				borderRadius: 4,
				'& .MuiTypography-root': {
					color: '#fff',
					fontWeight: 400,
					fontSize: '1.125rem',
				},
				'& svg': {
					padding: 0,
				}
			}
		},
		otherStudentBtn: {
			'&.MuiButton-root': {
				background: '#F5F5F5',
				borderRadius: 4,
				width: "219px",
				margin: '25px auto',
			}
		},

		confirmBtn: {
			'&.MuiButton-root': {
				background: '#F5F5F5',
				borderRadius: 4,
				width: "219px",
				margin: 'auto',
				marginBottom: "40px",
			}
		},
		noticeBox: {
			width: "265px",
			margin: '25px auto'
		},
		noticeTitle: {
			'&.MuiTypography-root': {
				color: '#FF0000',
				fontSize: '1rem',
				fontWeight: 700,
				letterSpacing: '-0.5px',
				lineHeight: '16px'
			}
		},
		noticeText: {
			'&.MuiTypography-root': {
				color: '#FF0000',
				fontSize: '0.938rem',
				fontWeight: 400,
				letterSpacing: '-0.5px',
				marginTop: "10px",
				lineHeight: '19.5px'
			}
		},
		popoverTooltip: {
			'& .MuiPopover-paper': {
				background: '#2F80ED',
				color: '#fff',
				width: "300px",
				// height: "49px",
				padding: '16px 20px',
				marginTop: "15px",
				boxSizing: 'border-box',
				boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.1)',
				overflow: 'visible',
			},
		},
		tooltipTop: {
			position: 'absolute',
			top: -10,
			left: '47%',
			color: '#000'
		},
		tooltipBtn: {
			'& .MuiButtonBase-root': {
				padding: 0,
				minWidth: 'auto',
				'&:hover': {
					background: 'transparent'
				}
			},
			'& svg': {
				marginBottom: "11px",
			}
		},
		tooltipTitle: {
			'&.MuiTypography-root': {
				color: '#fff',
				fontSize: '1.125rem',
				letterSpacing: '-0.5px',
				fontWeight: 700,
				lineHeight: '16px',
				marginBottom: "11px",
			}
		},
		tooltipText: {
			'&.MuiTypography-root': {
				color: '#fff',
				fontSize: '0.938rem',
				letterSpacing: '-0.5px',
				fontWeight: 400,
				lineHeight: '19.5px'
			}
		},
		iconBtnReset: {
			'&.MuiIconButton-root': {
				padding: 0,
				'&:hover': {
					background: 'transparent'
				},
			}
		},
		smartPenClose: {
			position: 'absolute',
			top: 22,
			right: 28,
		},

		tableBox: {
			marginTop: "20px",
			'&.MuiPaper-root': {
				borderTop: '1px solid rgba(119, 119, 119, 0.7)',
				boxShadow: 'none',
				borderRadius: 0,
				'& table': {
					tableLayout: 'fixed'
				},
				'& .MuiTableCell-head': {
					fontSize: '0.875rem',
					color: '#555',
					letterSpacing: '-0.5px',
					padding: '0 5px',
					boxSizing: 'border-box',
					height: "50px",
					borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
					fontWeight: 800,
					'& span': {
						fontSize: '0.875rem',
						fontWeight: 'normal'
					},
					'@media all and (max-width: 1550px)': {
						fontSize: '0.875rem',
						'& span': {
							fontSize: '0.688rem',
						}
					},
				},
				'& .MuiTableCell-body': {
					fontSize: '0.875rem',
					color: '#323232',
					height: "50px",
					letterSpacing: '-0.5px',
					padding: '0 5px',
					boxSizing: 'border-box',
					borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					'@media all and (max-width: 1550px)': {
						fontSize: '0.875rem',
					},
				}
			}
		},

		sortButton: {
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			'& p': {
				fontSize: '0.875rem',
				color: '#555',
				marginRight: "4px",
				letterSpacing: '-0.5px'
			}
		},


		highlighted: {
			animation: `$background-fade 2.0s forwards`,
		},

		"@keyframes background-fade": {
			"0%": {
				backgroundColor: "#ccf",
			},
			"100%": {
				backgroundColor: "#ffffff",
			},
		},



		greenBtn: {
			'&.MuiButtonBase-root': {
				color: '#fff',
				width: "260px",
				height: "36px",
				fontWeight: 'bold',
				background: '#008c73',
				'&:hover': {
					background: '#00bca3',
				},
				'& .MuiTypography-root': {
					color: '#fff',
					fontWeight: 'bold',
					fontSize: '0.95rem',
				}
			}
		},

		dataCheckBtn: {
			'&.MuiButtonBase-root': {
				color: '#aaa',
				width: "30px",
				height: "30px",
				border: '1px solid #aaa',
				background: '#fff',
				'&:hover': {
					background: '#eee',
				},
				'& .MuiTypography-root': {
					color: '#000',
					fontSize: '0.7rem',
				}
			}
		},


		dataStatusBtn: {
			'&.MuiButtonBase-root': {
				color: '#aaa',
				width: "95px",
				height: "30px",
				border: '1px solid #aaa',
				background: '#fff',
				'&:hover': {
					background: '#eee',
				},
				'& .MuiTypography-root': {
					color: '#000',
					fontSize: '0.7rem',
				}
			}
		},


		dataStatusBtnBoxed: {
			'&.MuiButtonBase-root': {
				color: '#aaa',
				width: "95px",
				height: "30px",
				border: '1px solid #aaa',
				background: '#07a',
				'&:hover': {
					background: '#eee',
				},
				'& .MuiTypography-root': {
					color: '#fff',
					fontSize: '0.7rem',
				}
			}
		},


		dataStatusBtnBoxedNothing: {
			'&.MuiButtonBase-root': {
				color: '#aaa',
				width: "95px",
				height: "30px",
				border: '1px solid #aaa',
				background: '#a77',
				'&:hover': {
					background: '#eee',
				},
				'& .MuiTypography-root': {
					color: '#fff',
					fontSize: '0.7rem',
				}
			}
		},


		fullDialog: {
			position: "fixed",
			// top: "50%",
			// left: "50%",
		},
	}
);
