import { IPageSOBP } from "../../../nl-lib3-common";

export const g_defaultNcode: IPageSOBP = {
    section: 0, // 3,
    owner: 27, // 27
    book: 667, // 311,
    page: 1 // 0
};

export const g_defaultTemporaryNcode: IPageSOBP = {
  section: 0, // 1025,
  owner: 27,  // 2,
  book: 667,  // 1,
  page: 1,    // 1
};


export const g_defaultOverlayNcode: IPageSOBP = {
  section: 4094, // 1025,
  owner: 0,  // 2,
  book: 0,  // 1,
  page: 0,    // 1
};
