import { action, makeAutoObservable, runInAction } from "mobx";
import ProjectRagRepository from "../repositories/ProjectRagRepository";
import { IRagInfo } from "../repositories/model/transfer/IRagInfo";
import { RagUploadStatus } from "../repositories/model/support/RagUploadStatus";


const LogPrefix = "[ProjectRagStore]";

// const EmptyProjectRagFileTransfer = {
// 	projectCode: "", //
// 	fileId: "",
// 	type: "", //
// 	fileName: "",
// 	downloadUrl: "",
// 	rags: "",
// 	embeddingStatus: ""

// };

// const EmptyProjectRagTransfer = {
// 	id: "",
// 	projectCode: "",
// 	fileId: "",
// 	pages: "",
// 	question: null
// };

type ProjectRagStoreProps = {
	projectRagRepository: ProjectRagRepository;
};

export default class ProjectRagStore {
	public projectRagRepository: ProjectRagRepository;
	public projectRagList: IRagInfo[];
	public projectExamQuestionList: any[];
	public fileUploadPercent: number;
	public projectRagState: RagUploadStatus;
	public isSelectingProjectRag: boolean;
	public fileList: any[];


	constructor(props: ProjectRagStoreProps) {
		makeAutoObservable(this);

		this.projectRagRepository = props.projectRagRepository;
		this.projectRagList = [];
		this.projectExamQuestionList = [];
		this.fileUploadPercent = 0;
		this.projectRagState = RagUploadStatus.INITIAL;
		this.isSelectingProjectRag = false;
		this.fileList = [];
	}

	init() {
		this.projectRagList = [];
		this.projectExamQuestionList = [];
		this.fileList = [];
		this.fileUploadPercent = 0;
		this.projectRagState = RagUploadStatus.INITIAL;
		this.isSelectingProjectRag = false;
	}

	initFileList = action(() => {
		this.fileList = [];
	});

	addFileList(files) {
		let tempArr = this.fileList.concat(files);
		this.fileList = tempArr;
	}

	changeFileList(fileList) {
		this.fileList = fileList;
	}

	addRagProjectRagList(rags) {
		let tempArr = this.projectRagList.concat(rags);
		this.projectRagList = tempArr;
	}

	addProjectRagListRags(index) {
		this.projectRagList[index].rags.push(
			{
				id: 0,
				projectCode: "",
				fileId: 0,
				pages: ""
			})
		return this.projectRagList[index].rags.length;
	}

	deleteRagProjectRagList(rag, index: number) {
		this.projectRagList.splice(index, 1);
	}

	changeFileNameProjectRagList(fileName: string, index: number) {
		if (this.projectRagList[index]) {
			this.projectRagList[index].fileName = fileName;
		}
	}
	changeFileTypeProjectRagList(type, index: number) {
		if (this.projectRagList[index]) {
			this.projectRagList[index].type = type;
		}
	}

	changeEmbeddingStatus(embeddingStatus, index) {
		if (this.projectRagList[index]) {
			this.projectRagList[index].embeddingStatus = embeddingStatus;
		}
	}

	async createProjectRag(projectCode, dataForm) {
		try {
			this.projectRagState = RagUploadStatus.PROGRESS;
			this.fileUploadPercent = 0;
			// console.log("Start createProjectRag ...",projectCode, dataForm)
			function uploadProgress(progressEvent) {
				let percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
				if (percent === 100)
					percent = 99;
				this.fileUploadPercent = percent;
			}
			let response = await this.projectRagRepository.createProjectRag(projectCode, dataForm, uploadProgress);
			console.log(LogPrefix, "Success createProjectRag ...", response);

			runInAction(() => {
				this.projectRagState = RagUploadStatus.COMPLETED;
				this.fileUploadPercent = 100;
			});
			return true;
		} catch (e) {
			this.projectRagState = RagUploadStatus.FAILED;
			console.log(LogPrefix, "Cannot createProjectRag ...", e);
			return false;
		}
	}

	async createProjectRagPages(projectCode, fileId, pages) {
		try {
			const pageData = {
				pages: pages
			}
			console.log("Start createProjectRagPages ...", projectCode, pageData)
			let response = await this.projectRagRepository.createProjectRagPages(projectCode, fileId, pageData);
			console.log(LogPrefix, "Success createProjectRagPages ...", response);
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot createProjectRagPages ...", e);
			return false;
		}
	}

	async getProjectRag(projectCode: string) {
		try {
			console.log("Start getProjectRag ...", projectCode);
			this.projectRagState = RagUploadStatus.PROGRESS;
			this.isSelectingProjectRag = true;
			let response = await this.projectRagRepository.getProjectRag(projectCode);

			runInAction(() => {
				this.projectRagList = response;
				console.log(LogPrefix, "Success getProjectRag ...", response);
				this.projectRagState = RagUploadStatus.COMPLETED;
			});
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot getProjectRag ...", e);
			this.projectRagState = RagUploadStatus.FAILED;
			return false;
		} finally {
			runInAction(() => {
				this.isSelectingProjectRag = false;
			});
		}
	}
	async getProjectRagByFileId(projectCode, fileId) {
		try {
			console.log("Start getProjectRagByFileId ...", projectCode)
			let response = await this.projectRagRepository.getProjectRagByFileId(projectCode, fileId);
			console.log(LogPrefix, "Success getProjectRagByFileId ...", response);
			return response;
		} catch (e) {
			console.log(LogPrefix, "Cannot getProjectRagByFileId ...", e);
			return false;
		}
	}

	async updateProjectRagFileName(projectCode, fileId, fileName) {
		try {
			const params = {
				fileName: fileName
			}
			console.log("Start updateProjectRagFileName ...", params);
			let response = await this.projectRagRepository.updateProjectRagFileName(projectCode, fileId, params);
			console.log(LogPrefix, "Success updateProjectRagFileName ...", response);
		} catch (e) {
			console.log(LogPrefix, "Cannot updateProjectRagFileName ...", e);
		}
	}

	async updateProjectRagPages(ragId, pages) {
		try {
			const params = {
				pages: pages
			}
			console.log("Start updateProjectRagPages ...", params)
			let response = await this.projectRagRepository.updateProjectRagPages(ragId, params);
			console.log(LogPrefix, "Success updateProjectRagPages ...", response);
		} catch (e) {
			console.log(LogPrefix, "Cannot updateProjectRagPages ...", e);
		}
	}

	async deleteProjectRagById(ragId) {
		try {
			console.log("Start deleteProjectRagById ...", ragId)
			let response = await this.projectRagRepository.deleteProjectRagById(ragId);

			runInAction(() => {
				this.projectRagList = response;
				console.log(LogPrefix, "Success deleteProjectRagById ...", response);
			});
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot deleteProjectRagById ...", e);
			return false;
		}
	}
	async deleteProjectRagFile(projectCode, fileId) {
		try {
			console.log("Start deleteProjectRagFile ...", projectCode, fileId)
			let response = await this.projectRagRepository.deleteProjectRagFile(projectCode, fileId);

			runInAction(() => {
				this.projectRagList = response;
				console.log(LogPrefix, "Success deleteProjectRagFile ...", response);
			});
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot deleteProjectRagFile ...", e);
			return false;
		}
	}

	async getProjectExamQuestions(projectCode) {
		function compare(a, b) {
			let compareNum = a.parentOrder - b.parentOrder;
			if (compareNum === 0) {
				compareNum = a.order - b.order;
			}
			return compareNum;
		}
		try {
			console.log("Start getProjectExamQuestions ...", projectCode)
			let response = await this.projectRagRepository.getProjectExamQuestions(projectCode);
			const tempArr = response;
			runInAction(() => {
				if (tempArr?.length) {
					tempArr.sort((a, b) => compare(a, b));
					this.projectExamQuestionList = tempArr;
					console.log(LogPrefix, "Success getProjectExamQuestions ...", tempArr);
					return true;
				}
			});

			if (tempArr?.length) {
				return true;
			}
			return false;
		} catch (e) {
			console.log(LogPrefix, "Cannot getProjectExamQuestions ...", e);
			return false;
		}
	}

	async createProjectExamQuestionRag(projectExamCode, projectExamQuestionNum, ragId) {
		try {
			console.log("Start createProjectExamQuestionRag ...", projectExamCode, projectExamQuestionNum, ragId)
			const data = {
				projectExamQuestionNum: projectExamQuestionNum,
				projectExamCode: projectExamCode,
				ragId: ragId
			}
			let response = await this.projectRagRepository.createProjectExamQuestionRag(data);

			console.log(LogPrefix, "Success createProjectExamQuestionRag ...", response);
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot createProjectExamQuestionRag ...", e);
			return false;
		}
	}
	async deleteProjectExamQuestionRag(projectExamCode, projectExamQuestionNum, ragId) {
		try {
			console.log("Start deleteProjectExamQuestionRag ...", projectExamCode, projectExamQuestionNum, ragId)
			const data = {
				projectExamQuestionNum: projectExamQuestionNum,
				projectExamCode: projectExamCode,
				ragId: ragId
			}
			let response = await this.projectRagRepository.deleteProjectExamQuestionRag(data);

			console.log(LogPrefix, "Success deleteProjectExamQuestionRag ...", response);
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot deleteProjectExamQuestionRag ...", e);
			return false;
		}
	}
	async reTryRagEmbedding(projectCode, fileId) {
		try {
			console.log("Start reTryRagEmbedding ...", projectCode, fileId)
			let response = await this.projectRagRepository.reTryRagEmbedding(projectCode, fileId);

			console.log(LogPrefix, "Success reTryRagEmbedding ...", response);
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot reTryRagEmbedding ...", e);
			return false;
		}
	}


}
