



export enum UserType {
	Unknown = 'Unknown',
	Admin = 'Admin',
	User = 'User',
	System = 'System'
}
