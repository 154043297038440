import { Repository } from "./Repository";
import { IRagInfo } from "./model/transfer/IRagInfo";

type Props = {
	serverContextPath: string;
};

export default class ProjectRagRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/projects";
	}


	public async createProjectRag(projectCode: string, data, uploadProgress) {
		const result = await this.getRequestPromise("post", `${this.requestPrefix}/${projectCode}/rags`, {}, data, null, null, uploadProgress);
		return result;
	}

	public async createProjectRagPages(projectCode: string, fileId: number, pageData) {
		const result = await this.getRequestPromise("post", `${this.requestPrefix}/${projectCode}/files/${fileId}/rags`, {}, pageData);
		return result;
	}

	public async getProjectRag(projectCode: string): Promise<IRagInfo[]> {
		const result = await this.getRequestPromise<IRagInfo[]>("get", `${this.requestPrefix}/${projectCode}/rags`);
		return result;
	}

	public async getProjectRagByFileId(projectCode: string, fileId: number) {
		const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/files/${fileId}/rags`);
		return result;
	}


	public async updateProjectRagFileName(projectCode: string, fileId: number, params) {
		const result = await this.getRequestPromise("put", `${this.requestPrefix}/${projectCode}/files/${fileId}/rags`, params);
		return result;
	}

	public async updateProjectRagPages(ragId, params) {
		const result = await this.getRequestPromise("put", `${this.requestPrefix}/rags/${ragId}`, params);
		return result;
	}
	public async deleteProjectRagById(ragId) {
		const result = await this.getRequestPromise("delete", `${this.requestPrefix}/rags/${ragId}`);
		return result;
	}

	public async deleteProjectRagFile(projectCode: string, fileId) {
		const result = await this.getRequestPromise("delete", `${this.requestPrefix}/${projectCode}/files/${fileId}/rags`);
		return result;
	}

	public async getProjectExamQuestions(projectCode: string) {
		const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/exams/questions`);
		return result;
	}


	public async createProjectExamQuestionRag(data) {
		const result = await this.getRequestPromise("post", `${this.requestPrefix}/rags`, {}, data);
		return result;
	}

	public async deleteProjectExamQuestionRag(data) {
		const result = await this.getRequestPromise("delete", `${this.requestPrefix}/rags`, {}, data);
		return result;
	}

	public async reTryRagEmbedding(projectCode: string, fileId) {
		const result = await this.getRequestPromise("put", `${this.requestPrefix}/${projectCode}/files/${fileId}/embed`);
		return result;
	}
}