import { CreateCaret } from "./classes/CreateCaret";
import { CreateCircle } from "./classes/CreateCircle";
import { CreateFileAttachment } from "./classes/CreateFileAttachment";
import { CreateFreeText } from "./classes/CreateFreeText";
import { CreateLine } from "./classes/CreateLine";
import { CreatePolygon } from "./classes/CreatePolygon";
import { CreatePolyLine } from "./classes/CreatePolyLine";
import { CreateSound } from "./classes/CreateSound";
import { CreateSquare } from "./classes/CreateSquare";
import { CreateStamp } from "./classes/CreateStamp";
import { CreateText } from "./classes/CreateText";
import { SelectAnnot } from "./classes/SelectAnnot";

export const editToolConfig = {
  currentTool: undefined,
}

editToolConfig.currentTool = new SelectAnnot();

const editionTools = {
  CreateText,
  CreateFreeText,
  CreateLine,
  CreateSquare,
  CreateCircle,
  CreatePolygon,
  CreatePolyLine,
  CreateStamp,
  CreateCaret,
  CreateFileAttachment,
  CreateSound,
};

export const setEditionTool = function (toolName) {
  if (toolName in editionTools) {
    editToolConfig.currentTool = new (editionTools[toolName]);
    console.log("new tool:", toolName, " - ", editToolConfig.currentTool);
  }
  else {
    console.warn("cannot find tool", toolName);
  }
};

// function checkPath(path) {
//   fetch(path, { method: "HEAD" }).then(response => {
//     if (!response.ok)
//       postMessage(["ERROR", `Failed to load ${path}: Status ${response.status}. This likely indicates that mupdf wasn't compiled to wasm.`]);
//   });
// }

