import { Repository } from "./Repository";

type Props = {
	serverContextPath: string;
};

export default class SystemRubricRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/rubrics";
	}

	public async getSystemRubricList(param) {
		const result = await this.getRequestPromise("get", `${this.requestPrefix}/systems`, param);
		return result;
	}
}