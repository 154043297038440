

// TODO - Port fitz geometry methods

import { assert } from "./functions";
import { FzMatrix } from "./FzMatrix";

// See platform/java/src/com/artifex/mupdf/fitz/Rect.java
export class FzRect {
  x0: number;

  y0: number;

  x1: number;

  y1: number;

  constructor(x0: number, y0: number, x1: number, y1: number) {
    assert(typeof x0 === "number" && !Number.isNaN(x0), "invalid x0 argument");
    assert(typeof y0 === "number" && !Number.isNaN(y0), "invalid y0 argument");
    assert(typeof x1 === "number" && !Number.isNaN(x1), "invalid x1 argument");
    assert(typeof y1 === "number" && !Number.isNaN(y1), "invalid y1 argument");
    this.x0 = x0;
    this.y0 = y0;
    this.x1 = x1;
    this.y1 = y1;
  }

  // TODO - See 'FzMatrix.from' below
  // static from(value)

  static fromFloatRectPtr(libmupdf: any, ptr) {
    ptr >>= 2;
    return new FzRect(
      libmupdf.HEAPF32[ptr],
      libmupdf.HEAPF32[ptr + 1],
      libmupdf.HEAPF32[ptr + 2],
      libmupdf.HEAPF32[ptr + 3],
    );
  }

  static fromIntRectPtr(libmupdf: any, ptr) {
    ptr >>= 2;
    return new FzRect(
      libmupdf.HEAP32[ptr],
      libmupdf.HEAP32[ptr + 1],
      libmupdf.HEAP32[ptr + 2],
      libmupdf.HEAP32[ptr + 3],
    );
  }

  width() {
    return this.x1 - this.x0;
  }

  height() {
    return this.y1 - this.y0;
  }

  translated(xoff, yoff) {
    return new FzRect(this.x0 + xoff, this.y0 + yoff, this.x1 + xoff, this.y1 + yoff);
  }

  transformed(libmupdf: any, matrix: FzMatrix) {
    return matrix.transformRect(libmupdf, this);
  }
}
