import { NeoPDFDocument } from "./NeoPDFDocument";
import { NeoPDFContext } from "./NeoPDFContext";
import { NcodeFontObject } from "./NeoPDFPage";

export class NcodeLayer {
  ctx: NeoPDFContext;

  doc: NeoPDFDocument;

  pageNumber: number;

  public isPdfInRGB: boolean;

  public sobp: string;

  public pageDelta = 0;

  public x0 = 0; /* float */

  public y0 = 0; /* float */

  public x1 = 0; /* float */

  public y1 = 0; /* float */

  public ncodeFont: NcodeFontObject = null;

  public ncodeBaseX = 0;

  public ncodeBaseY = 0;

  public excludeRegions: Array<{ x0: number, y0: number, x1: number, y1: number }> = new Array(0);

  constructor(
    ctx: NeoPDFContext,
    doc: NeoPDFDocument,
    pageNumber: number,
    isPdfInRGB: boolean, sobp: string, pageDelta = 0, x0 = 0, y0 = 0, x1 = 0, y1 = 0,
    ncodeFont: NcodeFontObject = null, ncodeBaseX = 0, ncodeBaseY = 0,
    excludeRegions: Array<{ x0: number, y0: number, x1: number, y1: number }> = null
  ) {
    this.ctx = ctx;
    this.doc = doc;
    this.pageNumber = pageNumber;

    this.isPdfInRGB = isPdfInRGB;
    this.sobp = sobp;
    this.pageDelta = pageDelta;
    this.x0 = x0;
    this.y0 = y0;
    this.x1 = x1;
    this.y1 = y1;
    this.ncodeFont = ncodeFont;
    this.ncodeBaseX = ncodeBaseX;
    this.ncodeBaseY = ncodeBaseY;

    if (excludeRegions === null)
      this.excludeRegions = new Array(0);
    else
      this.excludeRegions = excludeRegions;
  }

  public addExcludeRegion(x0: number, y0: number, x1: number, y1: number) {
    const rc = { x0, y0, x1, y1 };
    this.excludeRegions.push(rc);
  }
}
