import { InkstoreStrokeType } from "../types/InkstoreStrokeType";
import { IBrushEnum, NeoStroke } from "../../nl-lib3-common";
import { brushTypeToInkstoreStrokePenType } from "./brushTypeToInkstoreStrokePenType";
import { toInkstoreDots } from "./toInkstoreDots";

export function toInkstoreStroke(stroke: NeoStroke) {
	const { mac, startTime, updated, thickness, writerId: writeId, originalKey: strokeUUID, brushType } = stroke;
	const { section, owner, book, page, pageUuid, noteUuid } = stroke;
	const sobp = { section, owner, book, page, pageUuid, noteUuid };
	const color = 0xff000000 | parseInt(stroke.color.substr(-6), 16);

	const { strokeType } = brushTypeToInkstoreStrokePenType(stroke.brushType);
	const dotsBase64 = toInkstoreDots(stroke);

	let penTipType = brushType === IBrushEnum.MARKER ? 1 : 0;

	const inkStroke: InkstoreStrokeType = {
		version: 1,
		strokeUUID,
		deleteFlag: 0,
		color,
		dotCount: stroke.dotArray.length,
		mac,
		penTipType,

		startTime,
		strokeType,
		thickness,
		updated,
		dots: dotsBase64,
		writeId,

		sobp,

		pageUuid,
		noteUuid
	};

	return inkStroke;
};