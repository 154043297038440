import { useLocation, useNavigate } from "react-router-dom";
import i18next from "../lang";
import AuthStore from "../stores/AuthStore";
import MessageStore from "../stores/MessageStore";
import NavigateStore from "../stores/NavigateStore";
import { useStores } from "./useStores";

type Props = {
	navigateStore?: NavigateStore,
	messageStore?: MessageStore,
	navigate?: any, // NavigateFunction,
	location?: any, // Location
};

function useNaviCustom(props?: Props) {
	// const ps = props;
	// const { navigateStore, messageStore } = ps;

	const { navigateStore, messageStore } = useStores();
	const navigate = useNavigate();
	const location = useLocation();

	const goBack = () => {
		navigate(-1);
	}

	const go = async (url, replace?: boolean) => {
		if (!navigateStore || !messageStore || !navigate || !url) return;
		const { isConfirmForUpdateRequired } = navigateStore;

		if (isNextPathSameWithCurrent(url)) return;

		if (isConfirmForUpdateRequired) {
			const accepted = await messageStore.confirm(
				i18next.t("저장하지 않고 나가시겠습니까?")
			);
			if (!accepted) return;
		}

		if (replace === true) {
			navigate(url, { replace: replace });
			return;
		}

		navigate(url);
	};

	const logout = async (args: { authStore: AuthStore, url: string }) => {
		const { authStore, url } = args;
		if (!navigateStore || !messageStore || !navigate || !authStore || !url) return;
		const { isConfirmForUpdateRequired } = navigateStore;

		if (isConfirmForUpdateRequired) {
			const accepted = await messageStore.confirm(
				i18next.t("저장하지 않고 로그아웃하시겠습니까?")
			);
			if (!accepted) return;
		}

		authStore.doLogout();
		navigate(url);
	};

	const isNextPathSameWithCurrent = (url) => {
		if (!location) return false;
		if (location.search !== "") {
			const pathname = location?.pathname + location.search;
			return url === pathname;
		}

		return url === location?.pathname;
	};

	return { go, logout, goBack };
}

export default useNaviCustom;
