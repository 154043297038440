import { NeoStroke } from "./NeoStroke";

export class NeoStrokeArray {
  private _arr: NeoStroke[] = [];

  private _startTime = Number.MAX_SAFE_INTEGER;

  private _endTime = Number.MIN_SAFE_INTEGER;

  constructor(strokes?: NeoStroke[]) {
    if (strokes && strokes?.length) {
      this._arr = strokes;
      this._arr.sort((a, b) => a.startTime - b.startTime);
      this._startTime = this._arr[0].startTime;
      this._endTime = this._arr[this._arr.length - 1].startTime;
    }
  }

  sortedFind(stroke: NeoStroke) {
    const ts = stroke.startTime;
    let start = 0;
    let end = this._arr.length - 1;
    let midPoint: number;
    let midTime: number;

    if (end < 0 || ts > this._arr[end].startTime || ts <= this._arr[start].startTime) return -1;

    while (true) {
      if (end <= start + 1) {
        if (this._arr[start].startTime === ts) return start;
        if (this._arr[end].startTime === ts) return end;
        break;
      }

      midPoint = Math.floor(start + (end - start) / 2);
      midTime = this._arr[midPoint].startTime;

      if (midTime < ts) start = midPoint;
      else if (midTime > ts) end = midPoint;
      else { // aMidPoint === num
        return midPoint;
      }
    }
    return -1;
  }

  find(func: (stroke: NeoStroke) => boolean) {
    return this._arr.find(func);
  }

  filter(func: (stroke: NeoStroke) => boolean) {
    return this._arr.filter(func);
  }

  /**
   * insert a stroke into this._arr sorted this._arr, with no duplicates
   * @note does not insert if the startTime of the stroke already exists in this._arr
   */
  sortedInsert(stroke: NeoStroke) {
    const ts = stroke.startTime;

    if (ts > this._endTime) this._endTime = ts;
    if (ts < this._startTime) this._startTime = ts;

    let start = 0;
    let end = this._arr.length - 1;
    let midPoint: number;
    let position: number;

    if (end < 0) {
      position = 0;
    } else if (ts > this._arr[end].startTime) {
      position = end + 1;
    } else if (ts <= this._arr[start].startTime) {
      position = start;
    } else {
      while (true) {
        if (end <= start + 1) {
          position = end;
          break;
        }

        midPoint = Math.floor(start + (end - start) / 2);
        if (this._arr[midPoint].startTime < ts) {
          start = midPoint;
        } else if (this._arr[midPoint].startTime > ts) {
          end = midPoint;
        } else { // aMidPoint === num
          position = midPoint;
          break;
        }
      }
    }

    // insert when startTime is NOT already in this._arr (no duplicates)
    if (this._arr[position]?.startTime !== ts) {
      this._arr.splice(position, 0, stroke);
    }

    return position;
  }

  public push(stroke: NeoStroke) {
    return this.sortedInsert(stroke);
  }

  public get array() {
    return this._arr;
  }

  public get startTime() {
    return this._startTime;
  }

  public get endTime() {
    return this._endTime;
  }

  public get length() {
    return this._arr.length;
  }

  public remove(stroke: NeoStroke) {
    const idx = this.sortedFind(stroke);
    if (idx >= 0) {
      this._arr.splice(idx, 1);
    }
  }
}
