
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { MarkFeedbackStyle } from "../../../styles/MarkFeedbackStyle";
import { useTranslation } from 'react-i18next';
import { MarkTableRow } from './MarkTableRow';
import { IRubricInfo } from "../../../../../repositories/model/transfer/IRubricInfo";
type Props = {
	rubricInfo: IRubricInfo;
	questionKey: string;
	isSelected: boolean;
	requestingAI: boolean;
	changeIsUpdating: any;
	isExported: boolean;
}

export const MarkTable = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( MarkFeedbackStyle );
	const { rubricInfo, questionKey, isSelected, requestingAI, changeIsUpdating, isExported } = props;

	if (!isSelected) {
		return <></>;
	}

	return (
		<Box>
			<TableContainer id={`${rubricInfo.id}`} component={Paper} className={classes.tableBox}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell width='26%' align="center">{i18next.t("평가항목")}</TableCell>
							<TableCell width='30%' align="center" colSpan={2}>{i18next.t("점수")}</TableCell>
							<TableCell width='54%' align="center">{i18next.t("채점 기준")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>

						{requestingAI ?
							<TableRow>
								<TableCell width='100%' colSpan={4}>
									<Box style={{ height: '10px' }} />
									<Box className={classes.skeletonShortUI} />
									<Box style={{ height: '10px' }} />
									<Box className={classes.skeletonLongUI} />
									<Box style={{ height: '10px' }} />
									<Box className={classes.skeletonMiddleUI} />
									<Box style={{ height: '10px' }} />
								</TableCell>
							</TableRow> :
							<>
								<TableRow >
									{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
									<TableCell width='17%' rowSpan={2} align="center">
										<Typography className={classes.tableTitleSection}>
											{i18next.t(rubricInfo.name)}
										</Typography>
									</TableCell>
								</TableRow>

								{rubricInfo?.items.length > 0 &&
									<MarkTableRow
										rubricInfo={rubricInfo}
										selectedItemIdx={rubricInfo?.selectedItemIdx || 0}
										questionKey={questionKey}
										changeIsUpdating={changeIsUpdating}
										isExported={isExported}
									/>
								}

							</>
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
};
