import { DispatcherEvent } from "./Event";
import { EventCallbackType, PenEventFilter } from "./EventTypesDef";

export class EventDispatcher {
  events: { [key: string]: DispatcherEvent };

  constructor() {
    this.events = {};
  }

  /**
   *
   * @param {string} eventName
   * @param {any} data
   */
  public dispatch(eventName: string, data: any) {
    // First we grab the event
    const event = this.events[eventName];
    // If the event exists then we fire it!
    if (event) {
      if (data === undefined || data === null) {
        event.fire({ eventName });
      } else {
        event.fire({ ...data, eventName });
      }
    }
  };

  /**
   *
   */
  public add(eventName: string, callback: EventCallbackType, filter?: PenEventFilter) {
    // First we grab the event from this.events
    let event = this.events[eventName];

    // If the event does not exist then we should create it!
    if (!event) {
      event = new DispatcherEvent(eventName);
      this.events[eventName] = event;
    }
    // Now we add the callback to the event
    event.registerCallback(callback, filter);
  };

  /**
   *
   */
  public remove(eventName: string, callback?: EventCallbackType) {
    const event = this.events[eventName];

    // Check that the event exists and it has the callback registered
    if (!event) return;

    if (callback) {
      const flag = event.unregisterCallback(callback);
      if (flag) {
        // if the event has no callbacks left, delete the event
        if (event.callbacks.length === 0) {
          delete this.events[eventName];
        }
      }
    } else {
      delete this.events[eventName];
    }
  };

  public set(eventName: string, callback: EventCallbackType, filter?: PenEventFilter) {
    // First we grab the event from this.events
    let event = this.events[eventName];

    // If the event does not exist then we should create it!
    if (event) {
      delete this.events[eventName];
    }

    event = new DispatcherEvent(eventName);
    this.events[eventName] = event;
    // Now we add the callback to the event
    event.registerCallback(callback, filter);
  };

  public offAll() {
    Object.keys(this.events).forEach((key) => {
      const event = this.events[key];
      event.unregisterCallbackAll();
    });
  };

  public hasListener(eventName: string) {
    const event = this.events[eventName];
    if (!event) return false;
    return event.callbacks.length > 0;
  };
}


