import { INeoSmartpenType, PenTypeEnum } from "../neostroke/pen-types-type";

export const NEO_SMARTPEN_TYPE: { [key: string]: INeoSmartpenType } = {
  NETWORK_PEN: { type: PenTypeEnum.NETWORK_PEN, local: false },

  REAL_PEN: { type: PenTypeEnum.REAL_PEN, local: true },
  MOUSE_PEN: { type: PenTypeEnum.MOUSE_PEN, local: true },
  STYLUS_PEN: { type: PenTypeEnum.STYLUS_PEN, local: true },
  FINGER_PEN: { type: PenTypeEnum.FINGER_PEN, local: true },

  SDK_PEN: { type: PenTypeEnum.SDK_PEN, local: true },

  INKSTORE_PEN: { type: PenTypeEnum.INKSTORE_PEN, local: false },

  TESTING: { type: PenTypeEnum.TESTING, local: true },
  MENU_ACTION: { type: PenTypeEnum.MENU_ACTION, local: true },

  OFFLINE_DATA: { type: PenTypeEnum.OFFLINE_DATA, local: true },
}

