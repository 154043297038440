import {
	ClassNameMap,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertSubjectNameTypeEnToKr } from "../../../stores/ProjectStore";
import { getSemesterTypeString, getYearSemesterTitle } from "./001_Functions/getSemesterTypeString";
import { ProjectSemesterType } from "../../../repositories/model/support/ProjectSemesterType";


type Props = {
	classes: ClassNameMap,

	projectTargetGroupName: string,
	projectYear: number,
	projectSemester: ProjectSemesterType
	projectSubjectName: string,

	userName: string,
	grade: number,
	className: string,
	number: number
}

export function WorksheetResultHeader(props: Props) {
	const i18next = useTranslation();
	const {
		classes,
		projectTargetGroupName,
		projectYear,
		projectSemester,
		projectSubjectName,
		userName,
		grade,
		className,
		number
	} = props;

	return (
		<Table aria-label="simple table">
			<TableBody>
				<TableRow>
					<TableCell align="center" className={classes.titleBox} sx={{ width: "70px" }}>
						<Typography className={classes.headerFont} >
							{i18next.t('과목')}
						</Typography>
					</TableCell>

					<TableCell colSpan={1}>
						<Typography className={classes.headerFont}>
							{convertSubjectNameTypeEnToKr(projectSubjectName)}
						</Typography>
					</TableCell>

					<TableCell align="center" className={classes.titleBox} sx={{ width: "140px" }}>
						<Typography className={classes.headerFont} >
							{getYearSemesterTitle(projectYear, projectSemester)}
						</Typography>
					</TableCell>

					<TableCell colSpan={4} >
						<Typography className={classes.headerFont}>
							{getSemesterTypeString(projectYear, projectSemester)}
						</Typography>
					</TableCell>

				</TableRow>


				<TableRow>
					<TableCell align="center" className={classes.titleBox}>
						<Typography className={classes.headerFont} >
							{i18next.t('학교명')}
						</Typography>
					</TableCell>

					<TableCell>
						<Typography className={classes.headerFont}>
							{projectTargetGroupName && projectTargetGroupName}
						</Typography>
					</TableCell>

					<TableCell align="center" className={classes.titleBox} >
						<Typography className={classes.headerFont}>
							{i18next.t('학년/반/번호')}
						</Typography>
					</TableCell>

					<TableCell colSpan={2}>
						<Typography className={classes.headerFont} >
							{i18next.t('{{grade}}학년 {{className}}반 {{number}}번', { grade, className, number: number })}
						</Typography>
					</TableCell>

					<TableCell align="center" className={classes.titleBox}>
						<Typography className={classes.headerFont}>
							{i18next.t('이름')}
						</Typography>
					</TableCell>

					<TableCell colSpan={1} >
						<Typography className={classes.headerFont}>
							{userName}
						</Typography>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	)
}