
import {
	Box
} from "@mui/material";


// import 'dayjs/locale/ko';

import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";
import { QuestionAndReferenceTabExamRow } from "./QuestionAndReferenceTabExamRow";
import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";

type Props = {
	projectExamList: IExamInfo[];
	onSetQuestionHTMLWithOrderAndScore: any;
}

export const QuestionAndReferenceTab = (props: Props) => {
	const { projectExamList, onSetQuestionHTMLWithOrderAndScore } = props;
	console.log(projectExamList);
	return (
		<Box sx={{ position: 'relative' }}>
			{projectExamList.length > 0 &&
				projectExamList.filter(e => e.changeFlag !== ChangeFlag.DELETED)
					.map((exam, index) => {
						return (
							<QuestionAndReferenceTabExamRow
								key={`${exam.code}_${exam.key}`}
								examInfo={exam}
								index={index + 1}
								onSetQuestionHTMLWithOrderAndScore={onSetQuestionHTMLWithOrderAndScore}
							/>
						)
					})}
		</Box>
	)
}
