import {
	Box,
	Button,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ResultExportListStyle } from "../../styles/ResultExportListStyle";
// import 'dayjs/locale/ko';
import React from "react";

import { convertUTCToKST } from "../../../../hooks/convertUTCToKST";
import i18n from "../../../../lang";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";


export const Row = (props) => {
	const classes = useEmotionStyles( ResultExportListStyle );

	const {
		item,
		idx,
		rowStartNum,
		projectCode,
		handleOpenResultDialog
	} = props;

	const gradeClassName = React.useMemo(() => {
		if (!item.grade || !item.className) {
			return '-';
		}

		return `${item.grade}${i18n.t('학년')} ${item.className}${i18n.t('반')}`
	}, [item.grade, item.className]);

	const currentExportedDatetime = React.useMemo(() => {
		if (item.exportedDatetime) {
			return convertUTCToKST(item.exportedDatetime).format("YYYY.MM.DD HH:mm");
		}

		return '-';
	}, [item.exportedDatetime]);

	const handleClickSendResult = () => {
		if (!item.submissionCode) return;

		/* case of opening new tab
		const url = `/assignments/${projectCode}/export/${item.submissionCode}/result/detail`;
		window.open(url, "_blank", "noopener,noreferrer");
		*/

		// case of opening a dialog
		handleOpenResultDialog && handleOpenResultDialog(item.submissionCode);
	};

	const handleClickView = () => {
		console.log("handleClickMark=", item.submissionCode);
		if (!item.submissionCode) return;

		const url = `/assignments/${projectCode}/export/${item.submissionCode}/result/detail`;
		window.open(url, "_blank", "noopener,noreferrer");
	};

	const reportText = React.useMemo(() => {
		if (item?.state === ProjectStateType.EXPORTED) {
			return i18n.t('결과 내보내기 완료');
		}

		return i18n.t('결과 내보내기');
	}, [item?.state]);

	return (
		<TableRow >
			{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
			<TableCell align="center">
				{rowStartNum + idx}
			</TableCell>
			<TableCell>
				{item.userName}
			</TableCell>
			<TableCell>
				{gradeClassName}
			</TableCell>
			{/*<TableCell>*/}
			{/*    <Button className={classes.tableStudentNameBtn} disableRipple onClick={handleClickView}>*/}
			{/*        <Typography>view</Typography>*/}
			{/*    </Button>*/}
			{/*</TableCell>*/}
			<TableCell>
				<Typography className={classes.resultNumber}><span>{item.scoredScore}</span>/{item.totalScore}</Typography>
			</TableCell>
			<TableCell>
				{currentExportedDatetime}
			</TableCell>
			<TableCell align="center">
				<Box>
					<Button className={classes.reportExportActive} onClick={handleClickSendResult} disableRipple>
						{/*<Typography>결과 내보내기</Typography>*/}
						<Typography>{reportText}</Typography>
					</Button>
					{/*결과 내보내기 클릭 시 확인 다이얼로그*/}
					{/*<SimpleDialog selectedValue={selectedValue} open={dialogopen} onClose={handleCloseDialog} />*/}
				</Box>
			</TableCell>
		</TableRow>
	)
}
