
// TODO - remove this class and have getAnnotations() return an Array directly
export class AnnotationList {
  annotations;

  constructor(ctx, annotations) {
    this.annotations = annotations;
  }

  free() {
    // TODO
  }
}
