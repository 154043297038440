export class AffineMatrix {
  public matrix = { a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 };


  public constructor(arg?: { a: number, b: number, c: number, d: number, e: number, f: number }) {
    if (arg !== undefined) {
      this.matrix = arg;
    }
  }

  public clone() {
    return new AffineMatrix({ ...this.matrix });
  }

  public setMatrix(arg: { a: number, b: number, c: number, d: number, e: number, f: number }) {
    this.matrix = arg;
  }

  public setTranslate(x: number, y: number) {
    this.matrix.e = x;
    this.matrix.f = y;
  }

  public setRotate(angle: number) {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);

    this.matrix.a = cos;
    this.matrix.b = sin;
    this.matrix.c = -sin;
    this.matrix.d = cos;
  }

  public setScale(scaleX: number, scaleY: number) {
    this.matrix.a = scaleX;
    this.matrix.d = scaleY;
  }

  // public applyTranslate(x: number, y: number) {
  //   this.matrix.e += x;
  //   this.matrix.f += y;
  // }

  // public applyRotate(angle: number) {
  //   const cos = Math.cos(angle);
  //   const sin = Math.sin(angle);

  //   const a = this.matrix.a;
  //   const b = this.matrix.b;
  //   const c = this.matrix.c;
  //   const d = this.matrix.d;

  //   this.matrix.a = cos * a + sin * c;
  //   this.matrix.b = cos * b + sin * d;
  //   this.matrix.c = -sin * a + cos * c;
  //   this.matrix.d = -sin * b + cos * d;
  // }

  // public applyScale(scaleX: number, scaleY: number) {
  //   this.matrix.a *= scaleX;
  //   this.matrix.b *= scaleX;
  //   this.matrix.c *= scaleY;
  //   this.matrix.d *= scaleY;
  // }

  public getMatrix() {
    return this.matrix;
  }

  /**
   * A * B
   * @param A
   * @param B
   * @returns A * B
   */

  public static multiply(A: AffineMatrix, B: AffineMatrix): AffineMatrix {
    if (!B && !A) return new AffineMatrix;
    if (!B) return A;
    if (!A) return B;

    // A * B
    const C = new AffineMatrix();
    C.matrix.a = A.matrix.a * B.matrix.a + A.matrix.c * B.matrix.b;
    C.matrix.b = A.matrix.b * B.matrix.a + A.matrix.d * B.matrix.b;
    C.matrix.c = A.matrix.a * B.matrix.c + A.matrix.c * B.matrix.d;
    C.matrix.d = A.matrix.b * B.matrix.c + A.matrix.d * B.matrix.d;
    C.matrix.e = A.matrix.a * B.matrix.e + A.matrix.c * B.matrix.f + A.matrix.e;
    C.matrix.f = A.matrix.b * B.matrix.e + A.matrix.d * B.matrix.f + A.matrix.f;

    return C;
  }

  /**
   * B * this
   * @param B
   */

  public multiplyLeft(B: AffineMatrix) {
    const C = AffineMatrix.multiply(B, this);
    this.matrix = { ...C.matrix };
  }

  /**
   * this * B
   * @param B
   */

  public multiplyRight(B: AffineMatrix) {
    const C = AffineMatrix.multiply(this, B);
    this.matrix = { ...C.matrix };
  }


  /**
   * A ^ -1
   * @param A
   * @returns A ^ -1
   */
  public static invert(A: AffineMatrix): AffineMatrix {
    const det = A.matrix.a * A.matrix.d - A.matrix.b * A.matrix.c;
    if (det === 0) {
      return null;
    }

    const B = new AffineMatrix();
    B.matrix.a = A.matrix.d / det;
    B.matrix.b = -A.matrix.b / det;
    B.matrix.c = -A.matrix.c / det;
    B.matrix.d = A.matrix.a / det;
    B.matrix.e = (A.matrix.c * A.matrix.f - A.matrix.d * A.matrix.e) / det;
    B.matrix.f = (A.matrix.b * A.matrix.e - A.matrix.a * A.matrix.f) / det;

    return B;
  }

  /**
   * this ^ -1
   * @returns this ^ -1
   */
  public getInvertMatrix(): AffineMatrix {
    return AffineMatrix.invert(this);
  }

  /**
   * (x', y') = A * (x, y)
   * @param x
   * @param y
   * @param A
   * @returns (x', y') = A * (x, y)
   */
  public static getTransformedXY(x: number, y: number, A: AffineMatrix): { x: number, y: number } {
    return {
      x: A.matrix.a * x + A.matrix.c * y + A.matrix.e,
      y: A.matrix.b * x + A.matrix.d * y + A.matrix.f
    };
  }

  /**
   * (x', y') = this * (x, y)
   * @param x
   * @param y
   * @returns (x', y') = this * (x, y)
   */
  public getTransformedXY(x: number, y: number): { x: number, y: number } {
    return AffineMatrix.getTransformedXY(x, y, this);
  }

  public static fromTranslate(x: number, y: number): AffineMatrix {
    const m = new AffineMatrix();
    m.setTranslate(x, y);
    return m;

  }

  public isIdentity() {
    return this.matrix.a === 1 && this.matrix.b === 0 && this.matrix.c === 0 && this.matrix.d === 1 && this.matrix.e === 0 && this.matrix.f === 0;
  }

  public isEqualMatrix(m: AffineMatrix) {
    if (!m?.matrix) return false;

    return this.matrix.a === m.matrix.a && this.matrix.b === m.matrix.b && this.matrix.c === m.matrix.c && this.matrix.d === m.matrix.d && this.matrix.e === m.matrix.e && this.matrix.f === m.matrix.f;
  }

}
