import { useMemo } from 'react';
import { IExamInfo } from '../../../../repositories/model/transfer/IExamInfo';
import { DataUtils } from '../../../../util/data-utils';

// const isParagraphAndSourceBoxEmpty = React.useMemo(() => {
// 	const emptyParagraph = !examInfo.paragraph || (DataUtils.getTagArrFromHtml(examInfo.paragraph, 'img').length === 0 && !DataUtils.getTextFromHtml(examInfo.paragraph));
// 	const emptySource = !examInfo.source;

// 	return emptyParagraph && emptySource;
// }, [examInfo.paragraph, examInfo.source]);

export function useIsParagraphAndSourceBoxEmpty(
	examInfo: IExamInfo
): boolean {
	return useMemo(() => {
		const emptyParagraph = !examInfo.paragraph ||
			(DataUtils.getTagArrFromHtml(examInfo.paragraph, 'img').length === 0 &&
				!DataUtils.getTextFromHtml(examInfo.paragraph));
		const emptySource = !examInfo.source;

		return emptyParagraph && emptySource;
	}, [examInfo.paragraph, examInfo.source]);
}

// 사용 예시
// const isParagraphAndSourceBoxEmpty = useIsParagraphAndSourceBoxEmpty(examInfo);
