

import { assert } from "./functions";
import { FzMatrix } from "./FzMatrix";
import { FzPixmap } from "./FzPixmap";
import { Wrapper } from "./Wrapper";

export class FzDevice extends Wrapper {
  constructor(libmupdf: any, ctx: number, pointer: number) {
    super(libmupdf, ctx, pointer, libmupdf._wasm_drop_device);
  }

  static drawDevice(libmupdf: any, ctx: number, transformMatrix: FzMatrix, pixmap: FzPixmap) {
    assert(pixmap instanceof FzPixmap, "invalid pixmap argument");
    const m = FzMatrix.from(transformMatrix);
    return new FzDevice(libmupdf, ctx,
      libmupdf._wasm_new_draw_device(
        ctx,
        m.a, m.b, m.c, m.d, m.e, m.f,
        pixmap.pointer
      )
    );
  }

  // displayListDevice

  close() {
    const { libmupdf } = this;
    libmupdf._wasm_close_device(this.ctx, this.pointer);
  }
}
