import { IBrushEnum } from "../enums/brush-type-enum";

export const _defaultMaxPenPressureValue = 852;

export const _minPenPressureValue = 10 / 852;


export const DEFAULT_PEN_COLOR_NUM = 4;
export const DEFAULT_PEN_THICKNESS = 2;
export const DEFAULT_PEN_RENDERER_TYPE = IBrushEnum.PEN;


