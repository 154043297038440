
// TODO - All constructors should take a pointer, plus a private token
import { uuidv4 } from "../../common/UtilFuncs";
import { finalizer } from "./finalizer";

export class Wrapper {
  public id = uuidv4();

  private _pointer: number;

  private set pointer(value: number) { this._pointer = value; }

  public get pointer() { return this._pointer; }

  public ctx: number;

  dropFunction: any;

  public libmupdf: any = null;

  constructor(libmupdf: any, ctx: number, pointer: number, dropFunction: any) {
    this.libmupdf = libmupdf;
    this.ctx = ctx;
    this.pointer = pointer;
    this.dropFunction = dropFunction;

    // console.log(`${this.constructor.name}: ${dropFunction}`);

    if (typeof pointer !== "number" || pointer === 0) {
      throw new Error(`cannot create ${this.constructor.name}: invalid pointer param value '${pointer}'`);
    }
    if (dropFunction == null)
      throw new Error(`cannot create ${this.constructor.name}: dropFunction is null`);
    if (typeof dropFunction !== "function")
      throw new Error(`cannot create ${this.constructor.name}: dropFunction value '${dropFunction}' is not a function`);

    finalizer.register(this, () => dropFunction(ctx, pointer), this);
  }

  public async free() {
    finalizer.unregister(this);
    if (this.pointer !== 0) {
      this.dropFunction(this.ctx, this.pointer);
      this.pointer = 0;
    }
  }

  toString() {
    return `[${this.constructor.name} ${this.pointer}]`;
  }
}
