import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Menu, MenuItem,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	TextField,
	Theme,
	Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import clsx from "clsx";
import { observer } from "mobx-react";
import printJS from 'print-js';
import { ReactComponent as PageUpIcon } from '../../../../common/images//PageUpIcon.svg';
import { ReactComponent as ScanToggleIcon } from '../../../../common/images//ScanToggleIcon.svg';
import { ReactComponent as TableListOrder } from '../../../../common/images//TableListOrder.svg';
import { ReactComponent as ExportDownIconWhite } from '../../../../common/images/ExportDownIconWhite.svg';
import { ReactComponent as SmartPrint } from '../../../../common/images/SmartPrint.svg';
import { ReactComponent as StandardPrint } from '../../../../common/images/StandardPrint.svg';
import { SchoolLevelSubjects } from "../../../../common/SchoolLevelSubjects";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { addNcodeLayerToAllPages } from "../../../../ndp/NdpPdfPrinter";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { AssignmentProjectMainStyle } from "../../styles/AssignmentProjectMainStyle";

import { useTranslation } from 'react-i18next';
import { ProjectSchoolType, ProjectUserSortColumn } from "../../../../stores/enums/ProjectStoreEnums";
import { getSemesterTypeString } from "../../000_Common/001_Functions/getSemesterTypeString";
// import { AnswerTab } from "./AnswerTab";
// import { PlanTab } from "./PlanTab";
import { ProjectUserTableRow } from "./ProjectUserTableRow";

// import { RubricTab } from "./RubricTab";
import React from "react";
import { useParams } from "react-router-dom";
import { useStores } from '../../../../hooks/useStores';
import { AcrobatReaderPrintErrorCode, NativeMethods } from '../../../../neolab-libs/nl-lib3-pengateway/src/nl-lib-native-methods/NativeMethods';
import { IProject } from "../../../../repositories/model/IProject";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
import { setQuestionHTMLWithOrderAndScore } from "../../../../util/setQuestionHTMLWithOrderAndScore";
import { AnswerTab } from "../../000_Common/002_Tabs/AnswerTab";
import { PlanTab } from "../../000_Common/002_Tabs/PlanTab";
import { QuestionAndReferenceTab } from "../../000_Common/002_Tabs/QuestionAndReferenceTab";
import { RubricTab } from "../../000_Common/002_Tabs/RubricTab";
import { AssignmentCurriculumInfo } from "./AssignmentCurriculumInfo";

type Props = {
}

function AssignmentProjectMain(props: Props) {
	const { navigateStore, projectStore, projectRagStore, projectExamStore, projectRubricStore, messageStore, ncodeAllocationStore, authStore } = useStores();

	const params = useParams();
	const i18next = useTranslation();
	const classes = useEmotionStyles(AssignmentProjectMainStyle);

	const basicInfoRef = React.useRef<HTMLElement>();
	const testTargetRef = React.useRef<HTMLElement>();
	const templateProjectRef = React.useRef<HTMLElement>();
	const questionRef = React.useRef();
	const goodAnswerRef = React.useRef();
	const rubricRef = React.useRef();

	const [tabIndex, setTabIndex] = React.useState(0);
	const [projectCode, setProjectCode] = React.useState("");
	const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
	const [projectUserListOpenArrowRotated, setProjectUserListOpenArrowRotated] = React.useState(true);
	// const [projectUserListSortingArrowRotated, setProjectUserListSortingArrowRotated] = React.useState(true);// true : asc
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [menuItemAnchorEl, setMenuItemAnchorEl] = React.useState(null);
	const [isSelectingAll, setIsSelectingAll] = React.useState(false);

	const [templateProject, setTemplateProject] = React.useState<IProject>(null);
	// const [isSelectingProjectRubricList, setIsSelectingProjectRubricList] = React.useState(false);
	const [unusualApproach, setUnusualApproach] = React.useState(false);

	const isMenuOpen = Boolean(menuAnchorEl);
	const menuItemOpen = Boolean(menuItemAnchorEl);

	const { project, projectUsers, projectStates } = projectStore;
	const {
		projectExamQuestionsList,
		questionListLenObjPerExam,
		projectRubricTransferList,
		questionKeyAndSelectedProjectRubricIdxMap,
		questionKeyAndSystemRubricConditionsToSearchMap,
		questionKeyAndSystemRubricListMap,
		questionKeyAndSelectedSystemRubricIdsMap,
	} = projectRubricStore;

	const naviCustom = useNaviCustom();

	React.useEffect(() => {
		const currentProjectCode = params?.projectCode;
		if (!currentProjectCode || currentProjectCode === projectCode) return;
		// if (!currentProjectCode) return;
		setProjectCode(currentProjectCode);
	}, [params]);

	React.useEffect(() => {
		if (!projectCode) return;
		projectStore.getProjectStates(projectCode);
		projectStore.downloadProjectInfoAndStore(projectCode);
		projectStore.getProjectUserTransfers(projectCode, []);
		projectStore.getProjectPlanTransfer(projectCode);
		projectExamStore.downloadProjectExamListAndStore(projectCode);
		projectRagStore.getProjectRag(projectCode);
		projectRubricStore.getProjectExamQuestions(projectCode)
		// .then(result => {
		//   if (!result) {
		//     setIsSelectingProjectRubricList(true);
		//     return;
		//   }
		//
		//   const promisesForProjectRubric = projectRubricStore.projectExamQuestionsList.map((question, idx) => {
		//     return projectRubricStore.getProjectRubricList(question.projectExamCode, question.projectExamQuestionNum, question.key)
		//   });
		//
		//   Promise.all(promisesForProjectRubric)
		//     .then(_ => {
		//       setIsSelectingProjectRubricList(true);
		//     })
		//     .catch(error => {
		//       messageStore.alert(i18next.t("데이터 조회 중 오류가 발생했습니다."));
		//     })
		// })

		setIsSelectingAll(true);

		if (projectCode) {
			projectStore.fetchProject(projectCode).then(prj => {
				if (prj.templateCode) {
					projectStore.fetchProject(prj.templateCode).then(template => {
						setTemplateProject(template);
					});
				}
			});
		}


		return () => {
			projectStore.init();
			projectExamStore.init();
			projectRagStore.init();
			projectRubricStore.init();
		}
	}, [projectCode]);

	React.useEffect(() => {
		if (!isSelectingAll) return;

		if (
			!projectStore.isSelectingProjectInfo &&
			!projectStore.isSelectingProjectStates &&
			!projectStore.isSelectingProjectUserTransfers &&
			!projectStore.isSelectingProjectPlanTransfer &&
			!projectExamStore.isSelectingProjectExamList &&
			!projectRagStore.isSelectingProjectRag &&
			!projectRubricStore.isSelectingProjectExamQuestions
			// isSelectingProjectRubricList
		) {
			setIsSelectingAll(false);
			// setIsSelectingProjectRubricList(false);

			if (!project?.state || project?.state === ProjectStateType.CREATED) {
				setUnusualApproach(true);
				processAboutNotSubmitted();
			}
		}

	}, [
		isSelectingAll,
		projectStore.isSelectingProjectInfo,
		projectStore.isSelectingProjectStates,
		projectStore.isSelectingProjectUserTransfers,
		projectStore.isSelectingProjectPlanTransfer,
		projectExamStore.isSelectingProjectExamList,
		projectRagStore.isSelectingProjectRag,
		projectRubricStore.isSelectingProjectExamQuestions,
		// isSelectingProjectRubricList
	]);

	const processAboutNotSubmitted = async () => {
		messageStore.alert(i18next.t("비정상적인 접근입니다. 목록으로 이동합니다."))
			.then(_ => {
				naviCustom.go('/assignments', true);
				// props.navigate('/assignments', {replace: true});
			});
	};

	const handleChangeTab = (event, newIndex) => {
		switch (newIndex) {
			case 0:
				basicInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
				break;
			case 1:
				testTargetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
				break;
			case 2:
				templateProjectRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
				break;
			/*
		case 1:
			questionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
			break;
		case 2:
			goodAnswerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
			break;
		case 3:
			rubricRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
			break;
			*/
		}

		setTabIndex(newIndex);
	};

	const handleClickScrollToTopBtn = () => {
		const projectTotalInfo = document.getElementById('projectTotalInfo');
		if (projectTotalInfo) {
			projectTotalInfo.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

	const handleClickProjectUserList = () => {
		setProjectUserListOpenArrowRotated(!projectUserListOpenArrowRotated);
	};


	const currentSchoolLevelObj = React.useMemo(() => {
		return SchoolLevelSubjects.find(school => school.name === project?.schoolType);
	}, [project]);

	const currentSubjectGroupObj = React.useMemo(() => {
		if (!currentSchoolLevelObj) return;

		return currentSchoolLevelObj.subjectGroups.find(group => group.name === project?.subject);
	}, [project, currentSchoolLevelObj]);

	const currentSubjectObj = React.useMemo(() => {
		if (!currentSubjectGroupObj) return;

		return currentSubjectGroupObj.subjects.find(subject => subject.name === project?.subjectName);
	}, [project, currentSubjectGroupObj]);

	const handleClickTemplateProjectInfo = () => {
		if (!projectCode) return;

		const code = project.templateCode ? project.templateCode : projectCode;
		naviCustom.go(`/projects/${code}/create/view`);
		// props.navigate(`/projects/${projectCode}/create/view`);
	};

	const rowStartIdx = React.useMemo(() => {
		return page * rowsPerPage;
	}, [page, rowsPerPage]);

	const handleClickPage = (e, value) => {
		setPage(value - 1);
	};

	const projectUsersCount = React.useMemo(() => {
		if (!projectUsers) return 0;

		const totalCount = projectUsers?.length;

		return totalCount > 0
			? totalCount > rowsPerPage
				? Math.ceil(totalCount / rowsPerPage)
				: 1
			: 0
	}, [projectUsers, rowsPerPage]);

	const [sortStateMap, setSortStateMap] = React.useState({
		[ProjectUserSortColumn.NAME]: { sort: false },
		[ProjectUserSortColumn.SCHOOL]: { sort: false },
		[ProjectUserSortColumn.GRADE_CLASSNAME]: { sort: false },
	});

	const handleClickSort = (columnName) => {
		const sortState = sortStateMap[columnName];
		if (sortState) {
			sortState.sort = !sortState.sort;
			setSortStateMap({
				...sortStateMap,
				[columnName]: sortState,
			});

			projectStore.sortProjectUserTransfer(columnName, sortState.sort);
		}
	};

	const currentSemesterString = getSemesterTypeString(project?.year, project?.semesterType);

	const handleClickPrintBtn = (event) => {
		setMenuItemAnchorEl(event.currentTarget);
	};

	const handleMenuItemClose = () => {
		setMenuItemAnchorEl(null);
	};

	const handleClickStandardPrint = async () => {
		if (!projectCode) return;

		window.open(`/projects/${projectCode}/create/printPaper`, '_blank');
		// window.open(`/projects/${projectCode}/create/printAnswer`,'_blank');

		handleMenuItemClose();
	};

	async function handleClickSmartPrint() {
		let templateProject: IProject = null;
		if (project.templateCode) {
			templateProject = await projectStore.fetchProject(project.templateCode);
		}

		const { pdfBlobURL, pageCount } = await ncodeAllocationStore.addTextToPDF({
			project,
			templateProject,
			currentSemesterString,
			examList: projectExamStore.projectExamList,
			lang: navigateStore.language,
			teacher: authStore.loginUser
		});

		// 가장 최근에 allocation 된 것을 재활용한다.
		let ret = await ncodeAllocationStore.getProjectLastAllocation(projectCode, project.templateCode, pageCount);

		// allocation 된 것이 없으면 새로 할당한다.
		if (!ret) {
			ret = await ncodeAllocationStore.allocateCode(projectCode, project.templateCode, pageCount);
		}

		if (!ret) {
			await messageStore.alert(i18next.t("스마트 페이퍼 구분을 위한 코드 할당에 실패했습니다. 서버 관리자에게 연락해 주십시오."));
			return;
		}

		console.log(`allocated code: ${ret.section}.${ret.startOwner}.${ret.startBook}.${ret.startPage} ~ ${ret.section}.${ret.endOwner}.${ret.endBook}.${ret.endPage}`);

		const pdfBytes = await addNcodeLayerToAllPages({
			ndpPdf: {
				section: 10,
				owner: ret.startOwner,
				bookCode: ret.startBook,
				pageStart: ret.startPage,
				pageEnd: ret.startPage + pageCount - 1
			}, fileUrl: pdfBlobURL
		});
		handleMenuItemClose();

		// 이제 프린트한다
		let result = null;
		try {
			result = await NativeMethods.PrintPDFThroughAcrobatReader(project.name + ".pdf", pdfBytes);


			if (!result?.success) {
				let tryToInstall = false;
				if (!result || result?.errorCode === AcrobatReaderPrintErrorCode.AcrobatAppNotFound) {
					tryToInstall = await messageStore.confirm(i18next.t("빠른 인쇄를 위한 Acrobat Reader가 설치되어 있지 않습니다. '확인'을 누르면 설치 페이지로 이동합니다. 설치 이후 다시 시도하면 빠른 인쇄를 경험하실 수 있습니다.\n\\n'취소'를 눌러' 설치하지 않으셔도 일반 인쇄를 통해 인쇄하실 수 있습니다."));
				}

				if (tryToInstall) {
					window.open('https://get.adobe.com/reader/', '_blank');
				}

				else {
					const blob = new Blob([pdfBytes], { type: 'application/pdf' });
					const url = URL.createObjectURL(blob);
					printJS(url);
				}
			}
		} catch (e) {
			if (!e.message || (e.message as string).search("Method not found") > -1) {
				await messageStore.alert(i18next.t("NeoStudio2Lite 응응 프로그램이 설치되어 있지 않거나, 이전 버전의 응응 프로그램입니다. '확인'을 누르면 다운로드 페이지로 이동합니다."), i18next.t("다운로드 및 설치 필요"));

				window.open('/download', '_blank');
			}
		}


	}

	if (unusualApproach) {
		return <></>;
	}

	const isNotSimpleModeAssignment = currentSemesterString.length > 1;

	return (
		<div className={classes.root}>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 86px)'
				}}
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginBottom: "10px" }}>
						<Typography className={classes.previewText}>{i18next.t('평가과제운영 상세')}</Typography>

						{/* {!project?.includedOnline && projectStates?.submit && */}
						<Box display='flex' alignItems='center' sx={{ marginBottom: "20px" }}>
							<Box sx={{ marginLeft: "15px" }}>
								<Button
									className={clsx(classes.exportBtn, classes.exportBtnActive)}
									// disabled={!projectStates?.submit}
									id="basic-button"
									aria-controls={menuItemOpen ? 'basic-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={menuItemOpen ? 'true' : undefined}
									onClick={handleClickPrintBtn}
									disableRipple
								>
									<Typography>{i18next.t('인쇄하기')}</Typography>
									{/*{(basicInfoComplete && testTargetDone && questionDone) ?*/}
									<ExportDownIconWhite />
									{/*:*/}
									{/*<ExportDownIcon />*/}
									{/*}*/}
								</Button>

								<Menu
									id="basic-menu"
									anchorEl={menuItemAnchorEl}
									open={menuItemOpen}
									onClose={handleMenuItemClose}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
									className={classes.printMenuBox}
								>

									<MenuItem key={'smart-print'} onClick={handleClickSmartPrint}>
										<SmartPrint />
										<Typography>{i18next.t('스마트 페이퍼 인쇄')}</Typography>
									</MenuItem>

									<MenuItem key={'common-print'} onClick={handleClickStandardPrint} disabled>
										<StandardPrint />
										<Typography>{i18next.t('문제지 인쇄 (지원 예정)')}</Typography>
									</MenuItem>
									<br />
									<Typography key={'print-guide'} className={classes.printTextBox}>
										{i18next.t("* 인쇄는 ‘문제지'와 ‘답안지'를 분리하여 출력합니다.")}
									</Typography>
								</Menu>
							</Box>
							{/*<Box sx={{marginLeft: 15}}>*/}
							{/*  <Button*/}
							{/*    className={clsx(classes.lineBtn, classes.lineBtnActive)}*/}
							{/*    // onClick={handleClickTemporalStorage}*/}
							{/*    disableRipple>*/}
							{/*    <Typography>{i18next.t('저장')}</Typography>*/}
							{/*  </Button>*/}
							{/*</Box>*/}
						</Box>
						{/* } */}
					</Box>

					<Tabs value={tabIndex} onChange={handleChangeTab} className={classes.trigger2}>
						<Tab value={0} label={i18next.t('평가과제 운영 정보')} disableRipple />
						<Tab value={1} label={i18next.t('평가 대상')} disableRipple />
						<Tab value={2} label={i18next.t('평가과제')} disableRipple />
						{/*
            <Tab value={0} label={i18next.t('평가과제 운영 정보')} disableRipple />
            <Tab value={1} label={i18next.t('문항 및 참고 자료')} disableRipple />
            <Tab value={2} label={i18next.t('모범 답안')} disableRipple />
						<Tab value={3} label={i18next.t('평가 기준표')} disableRipple />
            */}
					</Tabs>
					<div className={classes.root}>
						<Box className={classes.scrollBox}>

							<AssignmentCurriculumInfo
								project={project}
								templateProject={templateProject}
								basicInfoRef={basicInfoRef}
								isNotSimpleModeAssignment={isNotSimpleModeAssignment}
							/>

							{/* ================================================================================================ */}
							<Box ref={testTargetRef} display='flex' alignItems='center'
								justifyContent='left'
								className={classes.commonTitleBox}>
								<Box>
									<Typography className={classes.commonSubTitle3} id={"projectTotalInfo"}>{i18next.t('평가 대상')}</Typography>
								</Box>
							</Box>
							<Box className={classes.innerBox2}>
								<Box>
									<Box className={classes.searchName}>
										<Box display='flex' alignItems='center' justifyContent='left'>
											<Typography className={classes.basicTitle}>{i18next.t('학교명')}</Typography>
											<Box display='flex' alignItems='center' justifyContent='left' className={classes.dialogDisabledInput2}>
												<TextField
													id="outlined-basic-school-name"
													autoComplete="off"
													variant="outlined"
													multiline
													disabled={true}
													value={project.targetGroupName ? project.targetGroupName : "-"}
													minRows={1}
													placeholder={i18next.t('입력한 학교명 표시')} />
											</Box>
										</Box>
									</Box>

									<Box className={classes.searchName}>
										<Box display='flex' alignItems='center' justifyContent='left'
											className={classes.studentListView}
										>
											<Typography className={classes.basicTitle3}>{i18next.t("학생 목록 보기")}</Typography>
											<IconButton className={classes.iconBtnReset}
												onClick={handleClickProjectUserList}
												disableRipple
												id="basic-button"
												aria-controls={isMenuOpen ? 'basic-menu' : undefined}
												aria-haspopup="true"
												aria-expanded={isMenuOpen ? 'true' : undefined}>
												{/*<SchoolListIcon />*/}
												<ScanToggleIcon style={{ transform: !projectUserListOpenArrowRotated ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} />
											</IconButton>
										</Box>
									</Box>

								</Box>
							</Box>

							{projectUserListOpenArrowRotated &&
								<Box>
									<TableContainer component={Paper} className={classes.tableBox2}>
										<Table aria-label="simple table">
											<TableHead>
												<TableRow>
													{/*<TableCell width='6%' className={classes.tableNoBox}>{i18next.t('선택')}</TableCell>*/}
													<TableCell width='10%' className={classes.numberColumn}>{i18next.t('No.')}</TableCell>
													<TableCell width='15%'>
														<Box style={{ cursor: 'default' }} display='flex'
															onClick={() => handleClickSort(ProjectUserSortColumn.NAME)}>
															{i18next.t('이름')}
															<IconButton className={classes.iconBtnReset} style={{ transform: sortStateMap[ProjectUserSortColumn.NAME].sort ? 'none' : 'rotate(180deg)', transition: 'transform 0.3s ease' }} disableRipple>
																<TableListOrder />
															</IconButton>
														</Box>
													</TableCell>
													<TableCell width='29%' >{i18next.t('이메일')}</TableCell>
													<TableCell width='18%'>
														<Box style={{ cursor: 'default' }} display='flex'
															onClick={() => handleClickSort(ProjectUserSortColumn.SCHOOL)}>
															{i18next.t('학교')}
															<IconButton className={classes.iconBtnReset} style={{ transform: sortStateMap[ProjectUserSortColumn.SCHOOL].sort ? 'none' : 'rotate(180deg)', transition: 'transform 0.3s ease' }} disableRipple>
																<TableListOrder />
															</IconButton>
														</Box>
													</TableCell>
													<TableCell width='18%'>
														<Box style={{ cursor: 'default' }} display='flex'
															onClick={() => handleClickSort(ProjectUserSortColumn.GRADE_CLASSNAME)}>
															{i18next.t('학년반')}
															<IconButton className={classes.iconBtnReset} style={{ transform: sortStateMap[ProjectUserSortColumn.GRADE_CLASSNAME].sort ? 'none' : 'rotate(180deg)', transition: 'transform 0.3s ease' }} disableRipple>
																<TableListOrder />
															</IconButton>
														</Box>
													</TableCell>
													<TableCell width='18%' >{i18next.t('그룹')}</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{projectUsers && projectUsers
													.slice(rowStartIdx, rowStartIdx + rowsPerPage)
													.map((user, idx) => {
														return (
															<ProjectUserTableRow
																key={idx}
																idx={idx}
																rowStartNum={rowStartIdx + 1}
																user={user}
															/>
														)
													})
												}
											</TableBody>
										</Table>
									</TableContainer>
									<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
										<Stack spacing={2}>
											<Pagination count={projectUsersCount}
												// page={page}
												onChange={handleClickPage}
												boundaryCount={2}
												showFirstButton
												showLastButton
												variant="outlined"
											/>
										</Stack>
									</Box>
								</Box>
							}

							{/* ================================================================================================ */}

							<Box ref={templateProjectRef} className={classes.title3Box}>
								<Typography className={classes.commonSubTitle3}>{i18next.t('평가 과제')}</Typography>
							</Box>
							{/* ---------------------------------------------------------------------------------- */}
							<Box>
								<Box className={classes.innerBox}>
									<Box display='flex' alignItems='center' justifyContent='left'>
										<Typography className={classes.commonSubTitle}>{i18next.t('과제 개요')}</Typography>
										{/*<IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple>*/}
										{/*  <EditContainedIcon />*/}
										{/*</IconButton>*/}
									</Box>

									<Box className={classes.tabBox}>
										{isNotSimpleModeAssignment &&
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle}>{i18next.t('교육과정 시기')}</Typography>
												<Typography className={classes.basicText}>{i18next.t('{{curriculumYear}}년 개정', { curriculumYear: project?.curriculumYear || '-' })}</Typography>
											</Box>
										}

										<Box className={classes.displayStyle}>
											<Typography className={classes.basicTitle}>{i18next.t('평가과제 운영명')}</Typography>
											<Typography className={classes.basicText}>{project?.name || '-'}</Typography>
										</Box>



										{isNotSimpleModeAssignment &&
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle}>{i18next.t('학교급')}</Typography>
												<Typography className={classes.basicText}>
													{project.schoolType === ProjectSchoolType.ELEM ? i18next.t("초등학교") :
														project.schoolType === ProjectSchoolType.MIDD ? i18next.t("중학교") :
															project.schoolType === ProjectSchoolType.HIGH ? i18next.t("고등학교") : "-"}
												</Typography>
											</Box>
										}
										{isNotSimpleModeAssignment &&
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle}>{i18next.t('학년/교과/과목')}</Typography>
												<Typography className={classes.basicText}>{project?.grade || '-'} {i18next.t('학년')} / {currentSubjectGroupObj?.text || '-'} / {currentSubjectObj?.text || '-'}</Typography>
												{/*<Typography className={classes.basicText}>{i18next.t('{{year}}학년도/ {{semester}}학기', {year: project.year, semester: project.semesterType === ProjectSemesterType.FIRST ? '1':'2'})}</Typography>*/}
											</Box>
										}



										{!isNotSimpleModeAssignment &&
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle}>{i18next.t('운영 레벨')}</Typography>
												<Typography className={classes.basicText}>{project?.grade || '-'} </Typography>
												{/*<Typography className={classes.basicText}>{i18next.t('{{year}}학년도/ {{semester}}학기', {year: project.year, semester: project.semesterType === ProjectSemesterType.FIRST ? '1':'2'})}</Typography>*/}
											</Box>
										}


										<Box className={classes.displayStyle}>
											<Typography className={classes.basicTitle}>{i18next.t('운영 단원명')}</Typography>
											<Typography className={classes.basicText}>{project?.chapter || '-'}</Typography>
										</Box>
										<Box className={classes.displayStyle}>
											<Typography className={classes.basicTitle}>{i18next.t('평가과제 정보 ')}</Typography>
											<Button className={classes.basicTextBtnLeft} disableRipple onClick={handleClickTemplateProjectInfo}>
												<Typography>
													{templateProject?.name || i18next.t('평가과제 정보로 가기')}
												</Typography>
											</Button>
										</Box>
									</Box>



									{/* ---------------------------------------------------------------------------------- */}
									{isNotSimpleModeAssignment &&
										<Box>
											<Typography className={classes.commonSubTitle}>{i18next.t('성취기준 및 평가기준')}</Typography>
											<PlanTab key={"planTab_Main"} />
										</Box>
									}

									{/* ---------------------------------------------------------------------------------- */}
									<Box className={clsx(classes.sectionTitle, classes.commonSubTitleBasicBox)}>
										<Typography className={classes.commonSubTitle} ref={questionRef}>{i18next.t('문항 및 참고자료')}</Typography>
									</Box>

									{/*{(activeExam || activeRag) &&*/}


									<QuestionAndReferenceTab
										onClickScrollToTopBtn={handleClickScrollToTopBtn}
										projectExamList={projectExamStore.projectExamList}
										onSetQuestionHTMLWithOrderAndScore={setQuestionHTMLWithOrderAndScore}
										sideBarStepNum={navigateStore.sideBarMakeProjectStepNum}
										questionRef={questionRef}

										handleClickRagEdit={() => { }}
										handleClickQuestionEdit={() => { }}
									/>
									{/*}*/}

									<Box className={clsx(classes.sectionTitle, classes.commonSubTitleBasicBox)}>
										<Typography className={classes.commonSubTitle} ref={goodAnswerRef}>{i18next.t('모범 답안')}</Typography>
										{/*{activeAnswer &&*/}
										{/*  <IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple onClick={handleClickAnswerEdit}>*/}
										{/*    <EditContainedIcon />*/}
										{/*  </IconButton>*/}
										{/*}*/}
									</Box>

									{/*{ !isEmptyStage(TemplateProjectStateType.ANSWER) &&*/}
									{/*{activeAnswer &&*/}
									<AnswerTab projectExamList={projectExamStore.projectExamList} />
									{/*}*/}

									<Box className={clsx(classes.sectionTitle, classes.commonSubTitleBasicBox)}>
										<Typography className={classes.commonSubTitle} ref={rubricRef}>{i18next.t('평가 기준표')}</Typography>
										{/*{activeRubric &&*/}
										{/*  <IconButton className={clsx(classes.iconBtnReset, classes.editorIcon)} disableRipple onClick={handleClickRubricEdit}>*/}
										{/*    <EditContainedIcon />*/}
										{/*  </IconButton>*/}
										{/*}*/}
									</Box>

									{/*{ !isEmptyStage(TemplateProjectStateType.RUBRIC) &&*/}
									{/*{activeRubric &&*/}
									<RubricTab
										projectExamQuestionsList={projectExamQuestionsList}
										questionListLenObjPerExam={questionListLenObjPerExam}
										projectRubricTransferList={projectRubricTransferList}
										questionKeyAndSelectedProjectRubricIdxMap={questionKeyAndSelectedProjectRubricIdxMap}
										questionKeyAndSystemRubricConditionsToSearchMap={questionKeyAndSystemRubricConditionsToSearchMap}
										questionKeyAndSystemRubricListMap={questionKeyAndSystemRubricListMap}
										questionKeyAndSelectedSystemRubricIdsMap={questionKeyAndSelectedSystemRubricIdsMap}
									/>
									{/*}*/}
								</Box>
							</Box>
						</Box>
					</div>

					<IconButton
						className={classes.pageUpIcon}
						onClick={handleClickScrollToTopBtn}
						disableRipple>
						<PageUpIcon />
					</IconButton>

					{isSelectingAll &&
						<Backdrop
							sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
							open={isSelectingAll}
						>
							<CircularProgress color="inherit" />
							<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t('조회 중입니다...')}</Typography>
						</Backdrop>
					}

				</Box>
			</Box>
		</div>
	);
}

export default observer<typeof AssignmentProjectMain>(AssignmentProjectMain);
