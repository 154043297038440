import { makeAutoObservable } from "mobx";
import { StoreStatus } from "./support/StoreStatus";


const LogPrefix = "[StoreManager]";

type Props = {
	// signUpRepository: SignUpRepository;
};

export default class StoreManager {
	public storeState: StoreStatus;

	constructor(props: Props) {
		this.storeState = StoreStatus.INITIAL;

		makeAutoObservable(this);
	}

	changeStoreState = (status) => {
		this.storeState = status
	}

}
