import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { ReactComponent as SelectIcon } from '../../../../common/images/SelectIcon.svg';
import { SchoolLevelSubjects } from "../../../../common/SchoolLevelSubjects";
import i18next from "../../../../lang";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TableHomeTaskStlye } from "../../styles/TableHomeTaskStlye";
import { ReactNode } from "react";

type Props = {
	handleChangeSubject: (event: SelectChangeEvent<string | number>, child: ReactNode) => void,
	subjectIndex: number,
	subject: string
}

function SubjectMenuItem(props: Props) {
	const classes = useEmotionStyles( TableHomeTaskStlye );
	const { subjectIndex, handleChangeSubject, subject } = props;

	return (
		<FormControl className={classes.formControl}>
			<Select
				IconComponent={(props) => (
					<Box>
						<SelectIcon  {...props} />
					</Box>
				)}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "right"
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					// getContentAnchorEl: null,
					className: classes.selectPopover
				}}
				displayEmpty
				onChange={handleChangeSubject}
				value={subject ? subject : 0}
			>
				<MenuItem value={0}><Typography>{i18next.t("교과 선택")}</Typography></MenuItem>
				{subjectIndex >= 0 ? SchoolLevelSubjects[subjectIndex].subjectGroups.map((subject) => {
					return (
						<MenuItem key={subject.name} value={subject.name}>{subject.text}</MenuItem>
					)
				})
					:
					<MenuItem key={"ItemNone"} disabled>{i18next.t("교과 없음")}</MenuItem>
				}
			</Select>
		</FormControl>
	)

}

export default SubjectMenuItem;
