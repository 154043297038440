import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as OutOfService } from '../../common/images/OutOfService.svg';
import useEmotionStyles from "./styles/useEmotionStyles";
import { DashboardStlye } from "./styles/DashboardStlye";

type Props = {
}


function Dashboard(props: Props) {
	const classes = useEmotionStyles( DashboardStlye );
	const i18next = useTranslation();

	return (
		<div className={classes.root}>
			{/* ******콘텐츠 영역******* */}
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 86px)'
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box>
						<Typography className={classes.commonSubTitle}>{i18next.t('대시보드')}</Typography>
					</Box>
					<Box className={classes.outOfService}>
						<OutOfService />
						<Typography className={classes.notServiceTitle}>{i18next.t('서비스 준비중 입니다.')}</Typography>
						<Typography className={classes.notServiceSub}>{i18next.t('과제별 학생과 문항 응답 현황과 분석 리포트를 확인할 수 있습니다.')}</Typography>
					</Box>
				</Box>
			</Box>
		</div>
	);
}

export default observer<typeof Dashboard>(Dashboard);
