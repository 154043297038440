import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";

export function getSubQuestionNumberString(exam: IExamInfo, question: IQuestionInfo, ignoreSingleQuestion = false) {
	const questionIndex = question.projectExamQuestionNum;
	const filtered = exam.questions.filter(q => q.changeFlag !== ChangeFlag.DELETED);


	let questionString = ignoreSingleQuestion ? "" : `${exam.order})`;
	if (filtered.length > 1) {
		questionString = `${exam.order}-${questionIndex})`;
	}

	return questionString;
}


