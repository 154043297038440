import PdfFetcher from "../datacacher/PdfFetcher";

export async function retreivePdfFromNdp2(pdfUrl: string) {
    const AuthTokenStorageKey = '__OTL_Authentication_Token__';
    const token = sessionStorage.getItem(AuthTokenStorageKey);
    const options = {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.

        headers: {
            Accept: 'application/pdf',
            'X-Auth-Token': token
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': `bearer ${access_token}`,
        }
    };

    try {
        // console.log(`NPROJ LOADER: download ${nprojUrl}`)
        const dataUrl = await PdfFetcher.instance.cachedFetch(pdfUrl, options);

        return dataUrl;
    } catch (e) {
        console.error('HTTP-error: ', e);
    }

    return undefined;
}
