import { ProjectCreateStatusType } from "../../repositories/model/support/ProjectCreateStatusType";


// TemplateProjectStates는 enum으로 변환할 수 없으므로 그대로 둡니다.
export const TemplateProjectStates = [
	{
		id: ProjectCreateStatusType.INFO,
		text: "과제 개요", // i18next.t("과제 개요"),
	},
	{
		id: ProjectCreateStatusType.EXAM,
		text: "문항 생성", // i18next.t("문항 생성"),
	},
	{
		id: ProjectCreateStatusType.RAG,
		text: "참고 자료", // i18next.t("참고 자료"),
	},
	{
		id: ProjectCreateStatusType.ANSWER,
		text: "모범 답안", // i18next.t("모범 답안"),
	},
	{
		id: ProjectCreateStatusType.RUBRIC,
		text: "평가 기준표", // i18next.t("평가 기준표"),
	}
];
