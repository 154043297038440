/// <reference types="web-bluetooth" />

import {
	_defaultMaxPenPressureValue,
	DEFAULT_PEN_THICKNESS,
	DOT_TYPE,
	EventClassBase,
	GetCurrentPageSizeCbType,
	IBrushEnum,
	IBrushState,
	INeoSmartpen,
	INeoSmartpenType,
	IOfflineDataProgressEvent,
	IOpenStrokeArg,
	IPageSOBP,
	IPenComm,
	IPenCommEvent,
	IPenIdType,
	IPenManager,
	IPenMovement,
	IPenToViewerEvent,
	makeNPageIdStrWithUuid,
	NEO_SMARTPEN_TYPE,
	NeoDot,
	NeoStroke,
	OfflineDataInfo,
	PEN_STATE,
	PenEventName,
	StrokePageAttrEnum,
} from "../../../nl-lib3-common";
import { IPennCommEvnetStatus } from "../../../nl-lib3-common/event-args/pencomm-event";
import { IDeviceVersionInfo } from "../../../nl-lib3-common/interfaces/pencomm-interface";
import { DotNetApi } from "../nl-lib-maui/maui-bridge/js-to-maui";
import { isDotNetEnvironment } from "../nl-lib-maui/maui-dev-environment";

export default class SmartPenBase extends EventClassBase<PenEventName, IPenToViewerEvent> implements INeoSmartpen {

	passwordRequestMsg = (retryCount: number, retryLimit: number) => `펜의 비밀번호를 입력하세요. ${retryLimit - retryCount}회 이상 비밀번호를 잘못 넣는 경우 펜의 데이터가 초기화됩니다. (임시)`;

	protocolHandler: IPenComm;

	getDeviceInfo() {
		const info = this.protocolHandler.deviceInfo;
		return JSON.parse(JSON.stringify(info)) as IDeviceVersionInfo;
	}

	getPenStatus() {
		const status = this.protocolHandler.getPenStatus();
		return JSON.parse(JSON.stringify(status)) as IPennCommEvnetStatus;
	}

	/**
	 * remote 속성을 강제로 덮어써서 컨트롤 하려고 하는 경우를 대비해서
	 *
	 * remote pen의 경우 true라고 되어 있으면,
	 * 펜의 속성에 따라 스트로크 속성을 (기본은 리모트에서 적용된 것)
	 * 2021/06/20
	 */
	overrideRemoteBrushType: boolean;

	surfaceOwnerId: string;

	writerId: string;

	inputType: INeoSmartpenType;

	protected currPenMovement: IPenMovement;

	/** 펜 종류 마다의 굵기와 색깔 */
	penState: IBrushState[];

	/** 펜 종류 (렌더러 종류) */
	penRendererType: IBrushEnum;

	// protocolHandler: PenComm = new PenComm(this);

	mac: string;      // "9C:7B:D2:54:0F:6F" 와 같은 real mac

	webBluetoothId: string;

	// 2021/08/18
	maxPenPressureValue: number;

	lastState: PEN_STATE;

	// calibrationData = {
	//   section: 0, owner: 0, book: 0, page: 0, points: new Array(0),
	// }

	manager: IPenManager;

	connected: boolean;

	// 펜을 생성한 userId
	protected userId: string = null;

	constructor(args: {
		manager: IPenManager;
		userId: string;
		// customStorage?: InkStorage;
		networkId?: string;
		mac?: string;
	}) {
		const { manager } = args;
		super();

		this.inputType = NEO_SMARTPEN_TYPE.REAL_PEN;
		this.surfaceOwnerId = null as unknown as string;
		this.writerId = null as unknown as string;

		this.currPenMovement = {
			downEvent: null as unknown as IPenCommEvent,
			infoEvent: null as unknown as IPenCommEvent,
			moveEvents: [],
			upEvent: null as unknown as IPenCommEvent,
			numMovement: 0,
			stroke: null as unknown as NeoStroke,
		};

		// 2021/08/18
		this.maxPenPressureValue = _defaultMaxPenPressureValue;

		/** 펜 종류 마다의 굵기와 색깔 */
		this.penState = new Array(Object.keys(IBrushEnum).length);

		/** 펜 종류 (렌더러 종류) */
		this.penRendererType = IBrushEnum.PEN;

		this.mac = null as unknown as string;
		this.webBluetoothId = null as unknown as string;
		this.lastState = PEN_STATE.NONE;

		// this.storage = InkStorage.instance
		this.manager = manager;
		this.connected = false;

		// if (customStorage) {
		//   console.log('use custom Ink Storage');
		//   this.storage = customStorage;
		// } else {
		//   console.log('use default Ink Storage');
		//   this.storage = InkStorage.instance;
		// }

		const { color } = this.manager;
		for (let i = 0; i < this.penState.length; i++) {
			this.penState[i] = {
				thickness: DEFAULT_PEN_THICKNESS / 10,
				color,
			};
		}


		// 2021/06/20
		this.overrideRemoteBrushType = false;
	}

	// eslint-disable-next-line class-methods-use-this
	public getMac(): string {
		throw new Error('getMac() not implemented');
	}

	// eslint-disable-next-line class-methods-use-this,
	public async connect(requestOnlineDataOnConencted: boolean, passwordRequestMsg: (retryCount: number, retryLimit: number) => string): Promise<{ success: boolean, reason: string }> {
		throw new Error('connect() not implemented');
	}

	// eslint-disable-next-line class-methods-use-this,
	public async connectBrowserPen(
		args: {
			device: BluetoothDevice,
			requestOnlineData: boolean,
			passwordRequestMsg: (retryCount: number, retryLimit: number) => string
		}
	): Promise<{ success: boolean, reason: string }> {
		throw new Error('connect() not implemented');
	}

	// eslint-disable-next-line class-methods-use-this,
	public async connectMauiPen(
		args: {
			penId: IPenIdType,
			requestOnlineData: boolean,
			shouldStopScanTask?: boolean
		}
	): Promise<{ success: boolean, reason: string }> {
		throw new Error('connect() not implemented');
	}

	// eslint-disable-next-line class-methods-use-this,
	public registerConnectedMauiBluetooth(
		penId: IPenIdType,
		requestOnlineDataOnConencted = true
	): boolean {
		throw new Error('connect() not implemented');
	}

	/**
	 *
	 */
	// eslint-disable-next-line class-methods-use-this,
	public async disconnect() {
		throw new Error(`disconnect() not implemented yet.`);
	}

	public forceBLEDisconnect() {
		this.protocolHandler.forceBLEDisconnect();
	}

	/**
	 * DO NOT USE with remote pen, 2021/08/18
	 * because this function will set stroke.originalKey
	 */
	private createPenDownStrokeInfo(e: IPenCommEvent) {
		// pen down 처리
		const { mac } = this;
		const time = e.timeStamp;

		// TODO: uuid 로직 변경 NULL_UUID
		// const uuid = uuidv4(); // TODO: 신규 생성되는 스트로크의 UUID, 이것 수정할 것, 2022/04/06

		const openStrokeArg: IOpenStrokeArg = {
			// uuid,
			mac,
			time,
			penTipMode: e.penTipMode,
			brushType: this.penRendererType,
			thickness: this.penState[this.penRendererType].thickness,
			color: this.penState[this.penRendererType].color,
			inputType: this.inputType,

			writerId: this.writerId,
			surfaceOwnerId: this.surfaceOwnerId,

			// 2021/06/20
			originalKey: null,
		};



		const stroke = NeoStroke.openStroke(openStrokeArg);
		const strokeKey = stroke.key;

		// 2021/08/18
		stroke.originalKey = strokeKey;

		this.currPenMovement.stroke = stroke;
		return { strokeKey, mac, time, stroke };
	}

	/**
	 *
	 */
	public onPenDown(e: IPenCommEvent) {
		this.resetPenStroke();
		this.currPenMovement.downEvent = e as IPenCommEvent;
		this.lastState = PEN_STATE.PEN_DOWN;

		const penDownStrokeInfo = this.createPenDownStrokeInfo(e);
		if (this.manager.isCalibrationMode && !e.fromNetworkPen) {
			const event: IPenToViewerEvent = {
				// ...penDownStrokeInfo,
				strokeKey: penDownStrokeInfo.strokeKey,
				mac: penDownStrokeInfo.mac,
				time: penDownStrokeInfo.time,
				stroke: penDownStrokeInfo.stroke,
				pen: this,
				inputType: this.inputType,
			};
			this.dispatcher.dispatch(PenEventName.ON_CALIBRATION_PEN_DOWN, event);
		} else {
			const event: IPenToViewerEvent = {
				// ...penDownStrokeInfo,
				strokeKey: penDownStrokeInfo.strokeKey,
				mac: penDownStrokeInfo.mac,
				time: penDownStrokeInfo.time,
				stroke: penDownStrokeInfo.stroke,
				pen: this,
				inputType: this.inputType,
			};

			this.dispatcher.dispatch(PenEventName.ON_PEN_DOWN, event);

			if (e.mac !== this.manager.getActivePen()?.mac) {
				this.manager.setActivePen(e.mac);
			}
		}
	}

	/**
	 * 펜의 움직임
	 *    1) down/up이 있는 경우: DOWN -> INFO -> MOVE -> MOVE -> ... -> UP -> INFO와 같이 나옴
	 *    2) hove의 경우: (페이지가 바뀌면) INFO -> HOVER_MOVE -> HOVER_MOVE...
	 *
	 * pen down 된 후의 page info, 실질적으로 pen_down과 같음
	 *
	 * @param e
	 *    {
	 *       section: number, owner: number, book: number, page: number,
	 *       timeStamp: number,
	 *       physicalSobp?: IPageSOBP,
	 *    }
	 * @param hover
	 * @returns
	 */
	public onPageInfo(e: IPenCommEvent, hover: boolean) {
		e.physicalSobp = {
			section: e.section,
			owner: e.owner,
			book: e.book,
			page: e.page,
			noteUuid: e.noteUuid,     // mouse pen 인 경우에는 들어온다
			pageUuid: e.pageUuid,     // mouse pen 인 경우에는 들어온다
		};

		const { stroke } = this.currPenMovement;
		// TODO: pui라고 하면, 스트로크 타입을 PUI로 변경하고 추가할 것
		// 추가되고 나면, close에서 스트로크의 PUI 이벤트를 발생 시킬 것

		const { timeStamp } = e;
		const sobpMightBeWithoutUuid = {
			section: e.section, owner: e.owner, book: e.book, page: e.page,
			noteUuid: e.noteUuid, pageUuid: e.pageUuid // mouse pen 인 경우에는 들어온다
		};


		this.currPenMovement.infoEvent = e;

		// 이전에 펜 down이 있었으면
		if (this.lastState === PEN_STATE.PEN_DOWN) {
			// this.currPenMovement.infoEvent = e;
			if (this.manager.isCalibrationMode) {
				return;
			}

			const { mac } = this;
			if (!mac) {
				throw new Error('mac address was not registered');
			}

			if (!hover) {
				// storage에 저장
				// const { stroke } = this.currPenMovement;
				const strokeKey = stroke.key;

				stroke.setStrokeInfo(sobpMightBeWithoutUuid, timeStamp);

				// hand pen page the event
				const event: IPenToViewerEvent = {
					inputType: this.inputType,
					pen: this,
					strokeKey,
					mac,
					stroke,
					sobp: sobpMightBeWithoutUuid,
					time: e.timeStamp,
				};

				console.log(`sobp = ${makeNPageIdStrWithUuid(sobpMightBeWithoutUuid)}, stroke.sobp = ${makeNPageIdStrWithUuid(stroke.sobp)}`)

				this.dispatcher.dispatch(PenEventName.ON_PEN_PAGEINFO, event);

				// this.cachedStorage.onPageInfo(event);

			} else {
				// hand hover page the event
				const event: IPenToViewerEvent = {
					inputType: this.inputType,
					pen: this,
					mac,
					sobp: sobpMightBeWithoutUuid,
					time: e.timeStamp,
				};

				this.dispatcher.dispatch(PenEventName.ON_PEN_HOVER_PAGEINFO, event);
			}
		} else if (this.lastState === PEN_STATE.PEN_MOVE) {
			// 펜 move 중 페이지가 바뀌는 경우

			// 1) pen up 처리
			{
				// const { stroke } = this.currPenMovement;
				stroke.setMultiPage(StrokePageAttrEnum.MULTIPAGE);

				const penUpStrokeInfo = this.closePenDownStrokeInfo(e);
				const { mac, section, owner, book, page, noteUuid, pageUuid } = penUpStrokeInfo.stroke;
				if (!noteUuid || !pageUuid) {
					console.error(`noteUuid or pageUuid is not valid in onPageInfo`);
				}

				console.log(`NeoSmartpen dispatch event VIRTUAL ON_PEN_UP`);
				const event: IPenToViewerEvent = {
					stroke: penUpStrokeInfo.stroke,
					strokeKey: penUpStrokeInfo.strokeKey,
					mac,
					pen: this,
					inputType: this.inputType,
					sobp: { section, owner, book, page, noteUuid: noteUuid, pageUuid },
				};
				this.dispatcher.dispatch(PenEventName.ON_PEN_UP_VIRTUAL, event);
				this.resetPenStroke();
			}

			// 2) pen down처리
			{
				// this.currPenMovement.infoEvent = e;
				const penDownStrokeInfo = this.createPenDownStrokeInfo(e);
				// const { stroke } = penDownStrokeInfo;
				stroke.setMultiPage(StrokePageAttrEnum.MULTIPAGE);

				console.log(`NeoSmartpen dispatch event VIRTUAL ON_PEN_DOWN`);
				const event: IPenToViewerEvent = {
					inputType: this.inputType,
					pen: this,
					...penDownStrokeInfo,
				};
				this.dispatcher.dispatch(PenEventName.ON_PEN_DOWN_VIRTUAL, event);
			}

			// 3) page Info 처리
			// const { section, owner, book, page, timeStamp } = e;
			const { mac } = this;

			if (!hover) {
				// storage에 저장
				// const { stroke } = this.currPenMovement;
				const strokeKey = stroke.key;
				stroke.setStrokeInfo(sobpMightBeWithoutUuid, timeStamp);

				// hand pen page the event
				const event: IPenToViewerEvent = {
					inputType: this.inputType,
					pen: this,
					strokeKey,
					mac,
					stroke,
					sobp: sobpMightBeWithoutUuid,
					time: e.timeStamp,
				};
				this.dispatcher.dispatch(PenEventName.ON_PEN_PAGEINFO, event);
				// this.cachedStorage.onPageInfo(event);

			} else {
				// hand hover page the event
				const event: IPenToViewerEvent = {
					inputType: this.inputType,
					pen: this,
					mac,
					sobp: sobpMightBeWithoutUuid,
					time: e.timeStamp,
				};
				this.dispatcher.dispatch(PenEventName.ON_PEN_HOVER_PAGEINFO, event);
			}
		}

		if (hover) {
			const { timeStamp } = e;
			const { mac } = this;
			if (this.manager.isCalibrationMode) {
				return;
			}

			const event: IPenToViewerEvent = {
				inputType: this.inputType,
				mac,
				sobp: sobpMightBeWithoutUuid,
				time: timeStamp,
				pen: this,
			};

			this.dispatcher.dispatch(PenEventName.ON_PEN_HOVER_PAGEINFO, event);
		}
	}

	/**
	 * pen down 상태에서 움직임
	 */
	public onPenMove(e: IPenCommEvent) {
		// let event = originalEvent as IPenCommEvent;
		this.lastState = PEN_STATE.PEN_MOVE;

		// 기존의 방식에서는 처리하지 않았던 것, redundant할 수 있다.
		if (this.currPenMovement.infoEvent) {
			e.section = this.currPenMovement.infoEvent.section;
			e.owner = this.currPenMovement.infoEvent.owner;
			e.book = this.currPenMovement.infoEvent.book;
			e.page = this.currPenMovement.infoEvent.page;
			e.noteUuid = this.currPenMovement.infoEvent.noteUuid;
			e.pageUuid = this.currPenMovement.infoEvent.pageUuid;
		} else {
			/**
			 * 종이에 터치되지 않고 들어오는 호버 이벤트
			 *
			 */
			e.section = -1;
			e.owner = -1;
			e.book = -1;
			e.page = -1;
			e.noteUuid = null;
			e.pageUuid = null;

			return;
		}

		// console.log(`${event.section}.${event.owner}.${event.book}.${event.page}`);

		let { force } = e;
		if (force >= 1) {
			force /= this.maxPenPressureValue;
		}

		if (this.manager.isCalibrationMode) {
			this.manager.calibrationData.points.push({ x: e.x, y: e.y, f: force, });
			return;
		}

		this.currPenMovement.numMovement++;
		e.isFirstDot = this.currPenMovement.numMovement === 1;

		// 2021/06/30, Plate 처리
		const sobp = this.currPenMovement.infoEvent?.physicalSobp;

		if (e.section === 14) {
			e.x -= 4096 * e.page;
		}
		const dot = new NeoDot({
			dotType: DOT_TYPE.penmove, // moving
			deltaTime: e.timediff,
			time: e.timeStamp,
			f: force,
			x: e.x,
			y: e.y,
		});

		const { stroke } = this.currPenMovement;
		const strokeKey = stroke.key;
		stroke.appendDot(dot);


		// hand the event
		const event: IPenToViewerEvent = {
			inputType: this.inputType,
			strokeKey,
			mac: stroke.mac,
			stroke,
			dot,
			pen: this,
			pencommEvent: e,
		};

		this.dispatcher.dispatch(PenEventName.ON_PEN_MOVE, event);
		// this.cachedStorage.onPenMove(event);
	}

	/**
	 * hover 상태에서 움직임
	 */
	public onHoverMove(e: IPenCommEvent) {
		this.lastState = PEN_STATE.HOVER_MOVE;
		if (this.manager.isCalibrationMode) {
			return;
		}

		// 기존의 방식에서는 처리하지 않았던 것, redundant할 수 있다.
		if (this.currPenMovement.infoEvent) {
			e.section = this.currPenMovement.infoEvent.section;
			e.owner = this.currPenMovement.infoEvent.owner;
			e.book = this.currPenMovement.infoEvent.book;
			e.page = this.currPenMovement.infoEvent.page;
			e.noteUuid = this.currPenMovement.infoEvent.noteUuid;
			e.pageUuid = this.currPenMovement.infoEvent.pageUuid;
		} else {
			/**
			 * 종이에 터치되지 않고 들어오는 호버 이벤트
			 *
			 */
			e.section = -1;
			e.owner = -1;
			e.book = -1;
			e.page = -1;
			e.noteUuid = null;
			e.pageUuid = null;

			return;
		}

		// 2021/06/30, Plate 처리
		const { mac } = this;
		if (!mac) {
			throw new Error('mac address was not registered');
		}

		const event: IPenToViewerEvent = {
			inputType: this.inputType,
			pen: this,
			mac,
			pencommEvent: e,
		};

		this.dispatcher.dispatch(PenEventName.ON_HOVER_MOVE, event);
	}

	/**
	 * hover 상태에서 페이지 바뀜
	 */
	public onHoverPageInfo(e: IPenCommEvent) {
		this.lastState = PEN_STATE.HOVER_MOVE;
		if (this.manager.isCalibrationMode) {
			return;
		}

		const { mac } = this;
		if (!mac) {
			throw new Error('mac address was not registered');
		}

		// 2021/08/04, network pen이면서, plate이면 move 자체를 처리하지 않는다.
		if (!e.fromNetworkPen) {
			const event: IPenToViewerEvent = {
				inputType: this.inputType,
				pen: this,
				mac,
				pencommEvent: e,
			};
			this.dispatcher.dispatch(PenEventName.ON_PEN_HOVER_PAGEINFO, event);
		}
	}

	// eslint-disable-next-line
	protected closePenDownStrokeInfo = (e: IPenCommEvent) => {
		const { stroke } = this.currPenMovement;

		// 2021/08/04, network pen이면서, plate이면 처리하지 않는다.
		stroke.closeStroke();

		const strokeKey = stroke.key;
		return { strokeKey, stroke };
	};

	/**
	 *
	 */
	public async onPenUp(e: IPenCommEvent) {
		this.lastState = PEN_STATE.PEN_UP;
		this.currPenMovement.upEvent = e;
		const penUpStrokeInfo = this.closePenDownStrokeInfo(e);

		if (!this.currPenMovement.infoEvent) {
			// sobp 자체가 안들어와서 dotArray가 0인 경우에는 addStroke도 하지 않는다.
			console.log(`onPenUp, sobp is not set`);
			this.resetPenStroke();
			return;
		}


		// 2021/08/04, network pen이면서, plate이면 처리하지 않는다.
		if (!e.fromNetworkPen && penUpStrokeInfo.stroke.brushType !== IBrushEnum.ERASER && penUpStrokeInfo.stroke.brushType !== IBrushEnum.ERASERPEN) {
			const { stroke } = penUpStrokeInfo;
			const { mac, section, owner, book, page, noteUuid, pageUuid } = stroke;
			if (!noteUuid || !pageUuid) {
				console.error(`on PenUp, noteUuid or pageUuid is not set`);
			}

			const event: IPenToViewerEvent = {
				stroke,
				strokeKey: penUpStrokeInfo.strokeKey,
				inputType: this.inputType,
				mac,
				pen: this,
				sobp: { section, owner, book, page, noteUuid, pageUuid },
			};
			this.dispatcher.dispatch(PenEventName.ON_PEN_UP, event);

			// this.storage.addStroke(stroke);
			stroke.cmd = 'add';

			// this.cachedStorage.onPenUp(event);
		} else if (penUpStrokeInfo.stroke.brushType === IBrushEnum.ERASER || penUpStrokeInfo.stroke.brushType === IBrushEnum.ERASERPEN) {
			const { stroke } = penUpStrokeInfo;
			const { mac, section, owner, book, page, noteUuid, pageUuid } = stroke;
			if (!noteUuid || !pageUuid) {
				console.error(`on PenUp, noteUuid or pageUuid is not set`);
			}

			const event: IPenToViewerEvent = {
				stroke,
				strokeKey: penUpStrokeInfo.strokeKey,
				inputType: this.inputType,
				mac,
				pen: this,
				sobp: { section, owner, book, page, noteUuid: noteUuid, pageUuid },
			};
			this.dispatcher.dispatch(PenEventName.ON_PEN_UP, event);
		}

		this.resetPenStroke();
	}

	/**
	 *
	 */
	public onNcodeError(e: IPenCommEvent) {
		// console.log(event);

		// let ph = this.appPen;
		// ph.onNcodeError(event);
		const { mac } = this;
		if (!mac) {
			throw new Error('mac address was not registered');
		}

		this.manager.onNcodeError({ pen: this, event: e });
		const event: IPenToViewerEvent = {
			inputType: this.inputType,
			pen: this,
			mac,
			pencommEvent: e,
		};
		this.dispatcher.dispatch(PenEventName.ON_NCODE_ERROR, event);
	}

	/**
	 *
	 */
	// eslint-disable-next-line class-methods-use-this,
	public onConnected(e: IPenCommEvent): void {
		throw new Error('onConnected is not implemented');
	}

	/**
	 *
	 */
	// eslint-disable-next-line class-methods-use-this,
	public onFirmwareUpgradeNeeded(e: IPenCommEvent): void {
		throw new Error('onFirmwareUpgradeNeeded is not implemented');
	}

	/**
	 *
	 */
	public onDisconnected(e: IPenCommEvent) {
		this.connected = false;
		const { mac } = this;
		if (!mac) {
			console.error(`mac address was not registered`);
			console.log(`mac address was not registered`);
			// console.log(event);
		} else {
			const event: IPenToViewerEvent = {
				inputType: this.inputType,
				pen: this,
				mac,
				pencommEvent: e,
			};

			this.dispatcher.dispatch(PenEventName.ON_DISCONNECTED, event);
			this.forceBLEDisconnect();
			this.removeEventListenerAll();
		}
	}

	/**
	 *
	 *
	 * @memberof SmartPenBase
	 */
	resetPenStroke() {
		const p = this.currPenMovement;

		p.downEvent = null;
		p.infoEvent = null;
		p.numMovement = 0;
		p.moveEvents = [];
		p.upEvent = null;
	}

	/**
	 *
	 *
	 * @param {string} color
	 * @memberof SmartPenBase
	 */
	public setColor(color: string) {
		this.penState[this.penRendererType].color = color;
		isDotNetEnvironment().then((isDotNet) => {
			if (isDotNet) {
				DotNetApi.instance.invoke('SetCurrentColor', this.mac, color);
			}
		})
	}

	/**
	 *
	 *
	 * @param {number} thickness
	 * @memberof SmartPenBase
	 */
	public setThickness(thickness: number) {
		this.penState[this.penRendererType].thickness = thickness;
		isDotNetEnvironment().then((isDotNet) => {
			if (isDotNet) {
				DotNetApi.instance.invoke('SetCurrentThickness', this.mac, thickness);
			}
		})
	}

	/**
	 *
	 *
	 * @param {IBrushEnum} type
	 * @memberof SmartPenBase
	 */
	public setPenRendererType(type: IBrushEnum) {
		this.penRendererType = type;
	}

	public setSurfaceOwnerId(ownerId: string) {
		this.surfaceOwnerId = ownerId;

		const event: IPenToViewerEvent = {
			mac: this.getMac(),
			pen: this,
			inputType: this.inputType,
			surfaceOwnerId: ownerId,
		};

		this.dispatcher.dispatch(PenEventName.ON_SURFACE_OWNER_CHANGED, event);
	}

	public setWriterId(writerId: string) {
		this.writerId = writerId;

		if (!this.surfaceOwnerId) {
			this.surfaceOwnerId = writerId;
		}
		const event: IPenToViewerEvent = {
			mac: this.getMac(),
			pen: this,
			inputType: this.inputType,
			writerId,
		};
		this.dispatcher.dispatch(PenEventName.ON_WRITER_CHANGED, event);
	}

	/** Renderer가 세팅할 현재 페이지의 사이즈 */
	currentPageSizePuFn: GetCurrentPageSizeCbType = undefined;

	public setPageSizeretrieveFn = (
		fn: () => { sobp: IPageSOBP; width_pu: number; height_pu: number },
		enable: boolean
	) => {
		if (enable) {
			this.currentPageSizePuFn = fn;
		} else if (this.currentPageSizePuFn === fn) {
			// viewer 자신이 설정한 것만 disable 가능하게 한다
			this.currentPageSizePuFn = undefined;
		}
	};

	/**
	 *
	 */
	// private convertPlateXyToPage = (e: IPenCommEvent, sobp: IPageSOBP): IPenCommEvent => {
	//   const definition = g_plateDefinitions[makeNPageIdStr({ section: sobp.section, owner: sobp.owner, book: sobp.book, page: undefined })];
	//   if (!definition) return null;
	//
	//   // 좌표 변환을 위해 활성화된 렌더러가 없으면, 이벤트를 삭제하도록 null을 리턴한다
	//   if (!this.currentPageSizePuFn) return null;
	//
	//   const pageSize_pu = this.currentPageSizePuFn();
	//   const pageSize_nu = { width: pageSize_pu.width_pu * PU_TO_NU, height: pageSize_pu.height_pu * PU_TO_NU };
	//
	//   const { Xmin, Ymin, Xmax, Ymax } = definition.margin;
	//   const plateSize_nu = { width: Xmax - Xmin, height: Ymax - Ymin };
	//   const scale_x = pageSize_nu.width / plateSize_nu.width;
	//   const scale_y = pageSize_nu.height / plateSize_nu.height;
	//   const scale = Math.max(scale_x, scale_y);
	//
	//   e.x -= Xmin;
	//   e.y -= Ymin;
	//   e.x *= scale;
	//   e.y *= scale;
	//
	//   const nu_offset = {
	//     x: (plateSize_nu.width * scale - pageSize_nu.width) / 2,
	//     y: (plateSize_nu.height * scale - pageSize_nu.height) / 2
	//   }
	//   e.x -= nu_offset.x;
	//   e.y -= nu_offset.y;
	//
	//   return e;
	// }

	/**
	 * Pen의 호버 모드를 ON/OFF
	 */
	// eslint-disable-next-line class-methods-use-this,
	public setHoverMode(on: boolean): void {
		throw new Error('not implemented');
	}

	public setBtLocalName(name: string): void {
		throw new Error('not implemented');
	}

	public setAutoPowerOnOff(on: boolean): void {
		throw new Error('not implemented');
	}

	public setAutoPenCapOnOff(on: boolean): void {
		throw new Error('not implemented');
	}

	public setBeepOnOff(on: boolean): void {
		throw new Error('not implemented');
	}

	/**
	 * 펜 마다의 최대 필압을 제공
	 * TODO: 현재는 구현되어 있지 않아서, 1024로
	 * 2021/08/17
	 */
	public getMaxPressure = () => this.maxPenPressureValue;

	/**
	 * 필압을 0.0 ~ 1.0 까지로
	 * 2021/08/17
	 */
	public getNormalizedPressure(intPressure: number) {
		let floatPressure = intPressure / this.maxPenPressureValue;
		if (floatPressure > 1) floatPressure = 1;
		return floatPressure;
	}

	/**
	 * 2021/08/18
	 */
	// eslint-disable-next-line class-methods-use-this,
	public onPenStatusResponse(e: IPenCommEvent): void {
		throw new Error('onPenStatusResponse is not implemented');
	}

	/**
	 *
	 */
	// eslint-disable-next-line class-methods-use-this,
	onPasscodeRequired(e: IPenCommEvent): void {
		throw new Error('onPasscodeRequired is not implemented');
	}

	/**
	 * 2021/08/18
	 * TODO: passcode를 넣으면 어떻게 동작하는지를 한번 더 살펴 봐야 한다
	 */
	// eslint-disable-next-line class-methods-use-this,
	sendPasscode(passcode: string): void {
		throw new Error('sendPasscode is not implemented');
	}

	setPassword(oldPassword: string, newPassword: string, isSetPassword: boolean): boolean {
		throw new Error('setPassword is not implemented');
	}

	setAutoPowerOffTime(autoPowerOffTime: number): Promise<boolean> {
		throw new Error('setAutoPowerOffTime is not implemented');
	}

	setEchoMode(isEchoMode: boolean): void {
		throw new Error('setEchoMode is not implemented');
	}

	startFirmwareUpgrade(fwVersion: string, isCompress: boolean, firmwareFileUrl: string): Promise<void> {
		throw new Error('startFirmwareUpgrade is not implemented');
	}

	cancelFirmwareUpgrade(): void {
		throw new Error('cancelFirmwareUpgrade is not implemented');
	}

	// 2022/05/17

	// eslint-disable-next-line class-methods-use-this,
	public onFinishedOfflineDownload(e: IPenCommEvent): void {
		throw new Error('onFinishedOfflineDownload() is not implemented');
	}

	// eslint-disable-next-line class-methods-use-this,
	public onStartOfflineDownload(e: IPenCommEvent): void {
		throw new Error('onStartOfflineDownload() is not implemented');
	}

	// eslint-disable-next-line class-methods-use-this,
	public onOfflineDataDelivered(e: IPenCommEvent): void {
		throw new Error('onOfflineDataDelivered() is not implemented');
	}

	// eslint-disable-next-line class-methods-use-this,
	public onOfflineDataListDelivered(e: IPenCommEvent): void {
		throw new Error('onOfflineDataListDelivered() is not implemented');
	}

	// eslint-disable-next-line class-methods-use-this,
	public onPasswordChanged(e: IPenCommEvent): void {
		console.log(`onPasswordChanged`, e);
	}

	// eslint-disable-next-line class-methods-use-this,
	public onShutdownEvent(e: IPenCommEvent): void {
		console.log(`onShutdownEvent`, e);
	}

	// eslint-disable-next-line class-methods-use-this,
	public onLowBatteryEvent(e: IPenCommEvent): void {
		console.log(`onLowBatteryEvent`, e);
	}

	public onSystemPerformanceResponse(e: IPenCommEvent): void {
		console.log(`onSystemPerformanceResponse`, e);
	}

	public onEchoModeChanged(e: IPenCommEvent): void {
		console.log(`onEchoModeChanged`, e);
	}

	public onFirmwareUpgradeStart(e: IPenCommEvent): void {
		console.log(`onFirmwareUpgradeStart`, e);
	}

	public onFirmwareUpgradeProgress(e: IPenCommEvent): void {
		console.log(`onFirmwareUpgradeProgress`, e);
	}

	public async requestSetPenInfo(): Promise<void> {
		console.log(`requestSetPenInfo`);
	}

	// eslint-disable-next-line class-methods-use-this,
	public requestOfflineDataList(args: {
		section?: number;
		owner?: number;
		book?: number;
		isLarge?: boolean;
	}): Promise<OfflineDataInfo[]> {
		throw new Error('requestOfflineDataList() is not implemented');
	}
	public getOfflineDataByRange(args: {
		range?: { start: IPageSOBP, end: IPageSOBP },
		deleteOnFinished: boolean,
		offlineDataPageList?: OfflineDataInfo[]
	}): Promise<{ numPages: number, numStrokes: number, strokes: NeoStroke[] }> {
		throw new Error('requestOfflineDataList() is not implemented');
	}

	public removeOfflineDataByRange(args: {
		range?: { start: IPageSOBP, end: IPageSOBP },
		offlineDataPageList?: OfflineDataInfo[]
	}): Promise<boolean> {
		throw new Error('requestOfflineDataList() is not implemented');
	}

	public getOfflinePageList(range?: { start: IPageSOBP, end: IPageSOBP }): Promise<OfflineDataInfo[]> {
		throw new Error('requestOfflineDataList() is not implemented');
	}

	// eslint-disable-next-line class-methods-use-this,
	public reqOfflineData(args: {
		s: number;
		o: number;
		b: number;
		pgs?: number[];
		deleteOnFinished?: boolean;
		progressReportCb?: (event: IOfflineDataProgressEvent) => void;
	}): Promise<{
		mTotalOfflineStroke: number;
		mTotalOfflineDataSize: number;
		strokes?: NeoStroke[];
	}> {
		throw new Error('reqOfflineData() is not implemented');
	}

	// public addEventListener = (eventName: PenEventName, listener: (event: IPenToViewerEvent) => void, filter?: any) => {
	//   super.addEventListener(eventName, listener, filter);
	// }

	// public removeEventListener = (eventName: PenEventName, listener: (event: IPenToViewerEvent) => void) => {
	//   super.removeEventListener(eventName, listener);
	// }

	// public removeEventListenerAll = () => {
	//   super.removeEventListenerAll();
	// }
}
