import {
	Box,
	Button,
	Checkbox,
	IconButton,
	InputBase,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ExamSelectedStlye } from "../../styles/ExamSelectedStlye";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { ReactComponent as SearchNameIcon } from '../../../../common/images/SearchNameIcon.svg';
import { ReactComponent as TableListOrder } from '../../../../common/images/TableListOrder.svg';

import { observer } from "mobx-react";

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
// import 'dayjs/locale/ko';
import React from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { convertUTCToKST } from "../../../../hooks/convertUTCToKST";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { useStores } from "../../../../hooks/useStores";
import {
	convertSubjectNameTypeEnToKr,
	convertSubjectTypeEnToKr,
	ProjectSchoolLabelType
} from "../../../../stores/ProjectStore";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const sortByCreateTime = "created_datetime";

type ExamSelectedProps = {
}

function ExamSelected(props: ExamSelectedProps) {
	const params = useParams();
	const { navigateStore, projectStore, messageStore } = useStores();
	const classes = useEmotionStyles( ExamSelectedStlye );
	const { project, templateProjectList } = projectStore;
	const { projectSearchKeyword } = navigateStore;
	const i18next = useTranslation();

	const [createdSortRotated, setCreatedSortRotated] = React.useState(false);
	const [stepFilter, setStepFilter] = React.useState<number | string>(0);
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [sortingHints, setSortingHints] = React.useState<{
		column: string, hint: string
	}[]>([{ column: "created_datetime", hint: "DESC" }]);

	const [selectedTemplate, selectTemplate] = React.useState(project.templateCode);

	const naviCustom = useNaviCustom();

	React.useEffect(() => {
		const { projectCode } = params;

		navigateStore.changeProjectSearchKeyword("");
		projectStore.downloadProjectInfoAndStore(projectCode);
		projectStore.getTemplateProjectList(null, projectSearchKeyword, page - 1, rowsPerPage, sortingHints, true);

		return () => {
			projectStore.initProject();
			navigateStore.changeIsConfirmForUpdateRequired(false);
		}
	}, []);

	React.useEffect(() => {
		selectTemplate(project.templateCode);
	}, [project]);

	React.useEffect(() => {
		// const { loginUser } = authStore;
		let state = null;
		let sort = sortByCreateTime;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (stepFilter !== 'all') state = stepFilter;

		const sortingHints = [{ column: sort, hint: dir }];
		projectStore.getTemplateProjectList(null, projectSearchKeyword, page - 1, rowsPerPage, sortingHints, true);

	}, [stepFilter, rowsPerPage, createdSortRotated]);

	const totalCountExams = React.useMemo(() => {
		return templateProjectList?.totalCount || 0;
	}, [templateProjectList]);

	const handleCreatedSortClick = () => {
		setCreatedSortRotated(!createdSortRotated);
	};

	// const covertNumToState = (num) => {
	// 	switch (num) {
	// 		case 0:
	// 			return "";
	// 		case 1:
	// 			return HomeworkStateType.CREATED;
	// 		case 2:
	// 			return HomeworkStateType.POSED;
	// 		case 3:
	// 			return HomeworkStateType.SUBMITTED;
	// 		case 4:
	// 			return HomeworkStateType.SCORED;
	// 	}
	// }

	const handleClickPage = (e, value) => {
		navigateStore.changeTableHomePage(value);
		setPage(value);
		const { projectSearchKeyword } = navigateStore;
		projectStore.getTemplateProjectList(null, projectSearchKeyword, value - 1, rowsPerPage, sortingHints, true);
	}

	const handleChangeSearchKeyword = (e) => {
		navigateStore.changeProjectSearchKeyword(e.target.value);
	}

	const handleClickKeywordSearch = () => {
		searchProjectList();

	}

	// const handleClickProjectEdit = (projectCode) => {
	// 	naviCustom.go(`/projects/${projectCode}/create/view`);
	// 	// props.navigate(`/projects/${projectCode}/create/view`);
	// }

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			console.log("projectSearchKeyword : ", projectSearchKeyword);
			projectStore.getTemplateProjectList(null, projectSearchKeyword, page - 1, rowsPerPage, sortingHints, true);
		}
	}

	const handleClickProjectName = (projectCode) => {
		// props.navigate(`/projects/${projectCode}/create/view`);
		window.open(`/projects/${projectCode}/create/view`, '_blank');
	}

	const searchProjectList = () => {
		const { projectSearchKeyword } = navigateStore;
		// const { loginUser } = authStore;
		projectStore.getTemplateProjectList(null, projectSearchKeyword, page - 1, rowsPerPage, sortingHints, true);
	}

	const copyTemplateToCustom = async (projectCode, templateCode) => {
		const result = await projectStore.copyTemplateToCustom(projectCode, selectedTemplate);
		return result;
	}

	const save = async (isNext) => {
		const { projectCode } = params;

		if (selectedTemplate) {
			const massage = isNext ? i18next.t("저장하고 다음으로 넘어가시겠습니까?") : i18next.t("저장하시겠습니까?");
			const result = await messageStore.confirm(massage);
			if (result) {
				copyTemplateToCustom(projectCode, selectedTemplate)
					.then(async () => {
						processAfterStorage();

						if (isNext) {
							naviCustom.go(`/assignments/${projectCode}/create/submit`);
							// props.navigate(`/assignments/${projectCode}/create/submit`);
						} else {
							projectStore.getProjectStates(projectCode);
						}
					});
			}
		} else {
			console.log("you have to check template.");
		}
	}

	const handleClickTemplateProject = (template) => {
		if (selectedTemplate === template.code) {
			selectTemplate(null);
		} else {
			selectTemplate(template.code);
		}

		changeIsConfirmForUpdateRequired();
	}

	const changeIsConfirmForUpdateRequired = () => {
		if (navigateStore.isConfirmForUpdateRequired) return;

		navigateStore.changeIsConfirmForUpdateRequired(true);
	};

	const processAfterStorage = () => {
		navigateStore.changeIsConfirmForUpdateRequired(false);
	};

	return (
		<div className={classes.root} >

			{/* ******콘텐츠 영역******* */}
			< Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 85px)'
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>

				<Box className={classes.mainContentBox}>

					<Box className={classes.commonTopTitle} >
						<Typography className={classes.commonMainTitle}>{i18next.t("3. 평가과제 선택")}</Typography>
						<Box>
							<Button className={classes.saveBtn}
								disabled={selectedTemplate === null}
								onClick={() => save(false)}
								disableRipple>
								<Typography>{i18next.t("저장")}</Typography>
							</Button>
							<Button className={classes.nextBtn}
								disabled={selectedTemplate === null}
								onClick={() => save(true)}
								disableRipple>
								<Typography>{i18next.t("다음")}</Typography>
							</Button>
						</Box>
					</Box>

					<Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px 10px 0px 10px" }}>
						<Typography className={classes.commonSubTitle2}>{i18next.t("전체 평가과제")} (<span>{totalCountExams}</span>)</Typography>
						<Box >
							<Paper
								component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
								className={classes.urlInputBox}>
								<IconButton className={classes.iconBtnReset} onClick={handleClickKeywordSearch} disableRipple>
									<SearchNameIcon />
								</IconButton>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder={i18next.t("평가과제 검색")}
									value={projectSearchKeyword}
									onChange={handleChangeSearchKeyword}
									onKeyDown={handleKeyDown}
									inputProps={{ 'aria-label': i18next.t("평가과제 검색") }}
								/>

							</Paper>
						</Box>
					</Box>
					<Box>
						<TableContainer component={Paper} className={classes.tableBox}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell width='5%'>
										</TableCell>
										<TableCell width='6%'>{i18next.t("No.")}</TableCell>
										<TableCell width='27%'>
											{i18next.t("과제명")}
										</TableCell>
										<TableCell width='12%' >{i18next.t("교육과정 시기")}</TableCell>
										<TableCell width='10%'>{i18next.t("학교급")}</TableCell>
										<TableCell width='10%'>
											{i18next.t("학년")}
										</TableCell>
										<TableCell width='10%'>
											{i18next.t("교과")}
										</TableCell>
										<TableCell width='10%'>
											{i18next.t("과목")}
										</TableCell>
										<TableCell width='10%'>
											<Box className={classes.sortButton} style={{ cursor: 'default' }}>
												<Typography>{i18next.t("등록일")}</Typography>
												<IconButton onClick={handleCreatedSortClick} className={classes.iconBtnReset} style={{ transform: createdSortRotated ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>
													<TableListOrder />
												</IconButton>
											</Box>
										</TableCell>

									</TableRow>
								</TableHead>
								<TableBody>
									{templateProjectList
										&& templateProjectList.projects
										&& templateProjectList.projects.map((project, index) => {
											return (
												<TableRow
													key={index}
													sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}
												>
													<TableCell className={classes.checkBoxStyle}>
														<Checkbox {...label} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} checked={selectedTemplate === project.code} onClick={() => handleClickTemplateProject(project)} />
													</TableCell>
													<TableCell>
														{((page - 1) * rowsPerPage) + index + 1}
													</TableCell>
													<TableCell>
														<Button className={classes.tableStudentNameBtn} onClick={() => handleClickProjectName(project.code)} disableRipple>
															<Typography>{project.name ? project.name : ""}</Typography>
														</Button>
													</TableCell>
													<TableCell>
														{project.curriculumYear ? `${project.curriculumYear}${i18next.t("년 개정")}` : ""}
													</TableCell>
													<TableCell>
														{ProjectSchoolLabelType[project.schoolType]}
													</TableCell>
													<TableCell>
														{`${project.grade}${i18next.t("학년")}`}
													</TableCell>
													<TableCell>
														{convertSubjectTypeEnToKr(project.subject)}
													</TableCell>
													<TableCell>
														{convertSubjectNameTypeEnToKr(project.subjectName)}
													</TableCell>
													<TableCell>
														{convertUTCToKST(project.createdDatetime).format("YYYY-MM-DD")}
													</TableCell>
												</TableRow>
											)
										})
									}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
						<Stack spacing={2}>
							<Pagination
								count={templateProjectList?.totalCount && templateProjectList.totalCount > 0 ?
									Math.ceil(templateProjectList.totalCount / rowsPerPage) : 1}
								page={page}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleClickPage}
								variant="outlined"
							/>
						</Stack>
					</Box>
				</Box>
			</Box >
		</div >
	);
}

export default observer<typeof ExamSelected>(ExamSelected);
