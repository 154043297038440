import { DOT_TYPE, IBrushEnum, IPageSOBP, NEO_SMARTPEN_TYPE, NeoDot, NeoStroke, NeoStrokeProps, StrokeStatusEnum } from "../../nl-lib3-common";
import { AffineMatrix } from "../../nl-lib3-common/neostroke/AffineMatrix";
import { NDPStrokeType } from "../../nl-lib3-common/typedef/ndp-types/NDPStrokeType";
import { inkstoreStrokePenTypeToBrushType } from "./brushTypeToInkstoreStrokePenType";
import { convertBase64DotsToUint8Array } from "./convertBase64DotsToUint8Array";



function fillStrokefromInkstoreStroke_without_speed(
	neoStroke: NeoStroke,
	initialDot: NeoDot,
	view: DataView,
	shiftDot: number,
	dotPacketSize: number,
	dotCount: number
) {
	const startTime = initialDot.time;

	// 중간 점과 끝 점
	let sum_deltaTime = 0;

	for (let i = 1; i < dotCount; i++) {
		const st = i * dotPacketSize;

		const f = view.getFloat32(st + 1 + shiftDot, true);
		const x = view.getFloat32(st + 5 + shiftDot, true);
		const y = view.getFloat32(st + 9 + shiftDot, true);
		const deltaTime = view.getUint8(st);

		sum_deltaTime += deltaTime;
		const dot: NeoDot = new NeoDot({
			dotType: i === dotCount - 1 ? DOT_TYPE.penup : DOT_TYPE.penmove,
			x,
			y,
			f,
			deltaTime,
			time: startTime + sum_deltaTime,
		});

		neoStroke.addDot(dot);
	}

	neoStroke.duration = sum_deltaTime;
	neoStroke._endTime = startTime + sum_deltaTime;

	return neoStroke;
}

function fillStrokefromInkstoreStroke_with_speed(
	neoStroke: NeoStroke,
	initialDot: NeoDot,
	view: DataView,
	shiftDot: number,
	dotPacketSize: number,
	dotCount: number
) {
	const startTime = initialDot.time;

	// 중간 점과 끝 점
	let sum_deltaTime = 0;
	let { f: force_sum, x: x0, y: y0 } = initialDot;

	for (let i = 1; i < dotCount; i++) {
		const st = i * dotPacketSize;

		const f = view.getFloat32(st + 1 + shiftDot, true);
		force_sum += f;
		const x = view.getFloat32(st + 5 + shiftDot, true);
		const y = view.getFloat32(st + 9 + shiftDot, true);
		const deltaTime = view.getUint8(st);

		sum_deltaTime += deltaTime;
		const dot: NeoDot = new NeoDot({
			dotType: i === dotCount - 1 ? DOT_TYPE.penup : DOT_TYPE.penmove,
			x,
			y,
			f,
			deltaTime,
			time: startTime + sum_deltaTime,
		});

		neoStroke.addDot(dot);

		const dx = x - x0;
		const dy = y - y0;
		const dist = Math.sqrt(dx * dx + dy * dy);
		neoStroke.sum_dist += dist;

		if (deltaTime > 0) {
			const speed = dist / (deltaTime / 1000);
			neoStroke.max_speed = Math.max(speed, neoStroke.max_speed);
			neoStroke.min_speed = Math.min(speed, neoStroke.min_speed);
		}

		x0 = x;
		y0 = y;
	}

	neoStroke.duration = sum_deltaTime;
	neoStroke._endTime = startTime + sum_deltaTime;
	neoStroke.sum_force = force_sum;

	return neoStroke;
}



/**
 *
 *
 * dot blob 구조체
 *
 *    const dotPacketSize = dotBlob.length / dotCount; // 16 or 17
 *    const shiftDot = dotPacketSize === 17 ? 1 : 0;
 *
 *    |00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|
 *    |dt|force float|  x float  |  y flot   |        |            if dotPacketSize === 16
 *
 *    |00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|
 *    |  |dt|force float|  x float  |  y flot   |        |         if dotPacketSize === 17
 */


export function fromInkstoreStroke(args: {
	sobp: IPageSOBP;
	stroke: NDPStrokeType;
}): NeoStroke {
	const { stroke } = args;
	const sobp = stroke.sobp ? stroke.sobp : args.sobp;


	// 스트로크 생성
	const brushType = inkstoreStrokePenTypeToBrushType(
		stroke.strokeType,
		stroke.penTipType
	);
	let colorHex =
		"00000000" + (stroke.color < 0 ? (stroke.color >>> 0).toString(16) : stroke.color.toString(16));
	colorHex = colorHex.slice(-6);

	const { mac, startTime, updated, thickness, penTipType: penTipMode, writeID: writerId, strokeUUID: originalKey, uploadTime, cmd } = stroke;
	const { section, owner, book, page, pageUuid, noteUuid } = sobp;

	const status = (brushType === IBrushEnum.ERASER) || (brushType === IBrushEnum.ERASERPEN) || (stroke.deleteFlag)
		? StrokeStatusEnum.ERASED
		: StrokeStatusEnum.NORMAL;

	const props: NeoStrokeProps = {
		inputType: NEO_SMARTPEN_TYPE.INKSTORE_PEN,
		startTime, mac, color: "#" + colorHex,

		thickness, penTipMode, brushType, status,

		section, owner, book, page, pageUuid, noteUuid: noteUuid,
		writerId, originalKey, uploadTime, updated,
		cmd
	};

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const neoStroke = new NeoStroke(props);
	const { dotCount, dots } = stroke;
	const dotBlobInfo = convertBase64DotsToUint8Array(dots, dotCount);
	const { dotPacketSize, shiftDot } = dotBlobInfo;
	const { dotBlob } = dotBlobInfo;

	// 최초의 1점
	// const d = dotBlob.slice(0, dotPacketSize);
	if (dotBlob?.length) {
		let view = new DataView(dotBlob.buffer);

		const initialForce = view.getFloat32(1 + shiftDot, true);
		const x0 = view.getFloat32(5 + shiftDot, true);
		const y0 = view.getFloat32(9 + shiftDot, true);

		const initialDot: NeoDot = new NeoDot({
			dotType: DOT_TYPE.pendown,
			x: x0, y: y0, f: initialForce,
			deltaTime: 0, time: startTime,
		});

		neoStroke.addDot(initialDot);

		if (stroke.sum_dist_nu) {
			// 이미 속도 계산을 할 수 있는 상태라면
			fillStrokefromInkstoreStroke_without_speed(
				neoStroke, initialDot, view, shiftDot, dotPacketSize, dotCount
			);
		} else {
			// 속도 계산이 필요한 상태라면
			// fillStrokefromInkstoreStroke_with_speed(
			fillStrokefromInkstoreStroke_without_speed(
				neoStroke, initialDot, view, shiftDot, dotPacketSize, dotCount
			);
		}
		view = undefined;
		dotBlobInfo.dotBlob = null;
		delete dotBlobInfo.dotBlob;
	}

	if (stroke.matrix) {
		const binaryStr = atob(stroke.matrix);
		const bytes = new Uint8Array(binaryStr.length);
		for (let i = 0; i < binaryStr.length; i++) {
			bytes[i] = binaryStr.charCodeAt(i);
		}

		const matrix = [];
		for (let i = 0; i < bytes.length; i += 4) {
			const view = new DataView(new Uint8Array(bytes.slice(i, i + 4)).buffer);
			matrix.push(view.getFloat32(0, true));
		}

		/*
			 AOS matrix  = a, b, c
										 d, e, f
			 Neo2 matrix = a, c, e
										 b, d, f
		*/
		let m: AffineMatrix;
		if (JSON.stringify(matrix) === JSON.stringify([1, 0, 0, 1, 0, 0])) {
			m = new AffineMatrix({ a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 });
		} else {
			m = new AffineMatrix({ a: matrix[0], b: matrix[3], c: matrix[1], d: matrix[4], e: matrix[2], f: matrix[5] });
		}

		neoStroke.resetEditingAffineMatrix();
		neoStroke.appendAffineMatrix(m);

		// 이전 neostudio에 있던 matrix는 NU 단위이다.
		neoStroke.applyAffineMatrixToDots_nu();
	}

	let isThereAbnormalForce = false;
	const { dotArray } = neoStroke;
	for (let i = 0, len = dotArray.length; i < len; i++) {
		const { f } = dotArray[i];
		if (f > 1 && Math.floor(f) !== f) {
			isThereAbnormalForce = true;
			break;
		}
	}

	if (isThereAbnormalForce) {
		console.error(`dot force recorded as abnormal`);
		for (let i = 0, len = dotArray.length; i < len; i++) {
			dotArray[i].f = 0.5;
		}
	}

	return neoStroke;
}

