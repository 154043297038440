import { MappingStorage } from "../neolab-libs/nl-lib2-pagestorage/background-pdf-mapper/MappingStorage";
import { IBrushEnum, IPageSOBP, isSamePageOnlySOBP, makeNPageIdStr, NeoStroke } from "../neolab-libs/nl-lib3-common";
import { ProjectExamType } from "../repositories/model/support/ProjectExamType";
import { drawPath } from "./DrawCurves";
import { loadBackgroundPdf } from "./loadBackgroundPdf";

export const sobpImgMap = new Map();
export const sobpImgSizeMap = new Map();

const imgLoadedSobpSet = new Set();

const stringToRgb = (str) => {
	if (str.startsWith('#')) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(str);
		let r = 0,
			g = 0,
			b = 0;
		if (result) {
			r = parseInt(result[1], 16);
			g = parseInt(result[2], 16);
			b = parseInt(result[3], 16);
		}

		// console.log('stringToRgb str 2', r, g, b);
		return { r, g, b };
	} else {
		const values = str.match(/\d+/g);
		// 문자열을 정수로 변환
		const r = parseInt(values[0]);
		const g = parseInt(values[1]);
		const b = parseInt(values[2]);
		return { r, g, b };
	}
};


export const getPageSize = async (sobp: IPageSOBP, numPages: number, pageIndex: number, selectedPdf) => {
	console.log('getPageSize', sobp, pageIndex, selectedPdf);
	const sobpStr = makeNPageIdStr(sobp);

	if (!sobpImgSizeMap.has(sobpStr)) {
		const doc = await loadBackgroundPdf(
			ProjectExamType.DESCRIPTIVE, "ko",
			sobp, selectedPdf, numPages);

		if (!doc) return;

		const page = await doc.pdf.getPage(pageIndex);
		const pageSize = await page.getSize();

		sobpImgSizeMap.set(sobpStr, pageSize);
	}

	return sobpImgSizeMap.get(sobpStr);
};

export const sobpImageConverter = async (args: {
	type: ProjectExamType,
	lang: string,
	sobp: IPageSOBP,
	numPages: number,
	pageIndex: number,
	ndpNcodeRange: { section: number, owner: number, bookCode: number, pageStart: number, pageEnd: number },
	forceUpdate?: boolean,
	dpi?: number,
	wholeStrokes: NeoStroke[]
}) => {
	const { wholeStrokes, type, lang, sobp, numPages, pageIndex, ndpNcodeRange, forceUpdate = false, dpi = 40 } = args;
	const sobpStr = makeNPageIdStr(sobp);

	if (!imgLoadedSobpSet.has(sobpStr) || forceUpdate) {
		imgLoadedSobpSet.add(sobpStr);

		const doc = await loadBackgroundPdf(type, lang, sobp, ndpNcodeRange, numPages);

		console.log('sobpImageConverter', doc, sobp, ndpNcodeRange, pageIndex);

		if (!doc) return;

		const page = await doc.pdf.getPage(pageIndex);

		const pdfItem = MappingStorage.instance.getAssociatedPdfPage(sobp);
		const homography = pdfItem.pageMapping.h;

		// const strokes = InkStorage.instance.getPageStrokes(sobp);
		const strokes = wholeStrokes.filter((stroke) => isSamePageOnlySOBP(stroke.sobp, sobp));

		console.log('sobpImageConverter page strokes', strokes);

		for (let j = 0; j < strokes.length; j++) {
			const stroke = strokes[j];
			const { status, thickness, brushType, dotArray: da, color } = stroke;

			const rrggbb = stringToRgb(color);

			const { a: a0, b: b0, c: c0, d: d0, e: e0, f: f0, g: g0, h: h0 } = homography;

			// console.log('homography', homography);

			let opacity = 1;
			if (brushType === IBrushEnum.MARKER) {
				opacity = 0.3;
			}

			const pointArray = [];

			for (let k = 0, len_k = da.length; k < len_k; k++) {
				const dot = da[k];
				const nom = g0 * dot.x + h0 * dot.y + 1;
				const px = (a0 * dot.x + b0 * dot.y + c0) / nom;
				const py = (d0 * dot.x + e0 * dot.y + f0) / nom;

				const pdf_xy = { x: px, y: py };

				const f = dot.f;
				pointArray.push({ x: pdf_xy.x, y: pdf_xy.y, f });
			}
			// page.setRotation(degrees(gridaPageObj.rotation));

			let strokeThickness = thickness; // * 4
			switch (brushType) {
				case IBrushEnum.MARKER:
					strokeThickness *= 5;
					break;
				default:
					break;
			}

			const pathData = drawPath(pointArray, strokeThickness);

			// console.log("pathData", pathData, rrggbb, opacity);

			await page.addSVGPath({
				svgPath: pathData,
				r: rrggbb.r,
				g: rrggbb.g,
				b: rrggbb.b,
				a: opacity,
				// scale: 1
			});
		}

		const urlTask = page.renderPageAsPngDataUrl(dpi);
		const urlPromise = await urlTask.promise;
		const dataUrl = urlPromise.dataUrl;

		const pageSize = await page.getSize();

		console.log('converter', sobpStr, pageSize);

		if (pageSize !== undefined) {
			sobpImgSizeMap.set(sobpStr, pageSize);
		}
		sobpImgMap.set(sobpStr, dataUrl);



	}

	// console.log(`converter return`);

	return { url: sobpImgMap.get(sobpStr), pageSize: sobpImgSizeMap.get(sobpStr) };
};

export default loadBackgroundPdf;
