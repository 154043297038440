import { EmployerDisposable } from "./EmployerDisposable";
import { NeoPDFWorkerEmployer } from "../PdfWorkerEmployerClass";

export class NeoPDFContext extends EmployerDisposable {
  loadedDocs = 0;

  name = "NeoPDFContext";

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  ready = new Promise<NeoPDFContext>(async (resolve, reject) => { });

  constructor(employer: NeoPDFWorkerEmployer) {
    super(employer, null, null, employer.freeContext);
  }

  initContext = async (employer: NeoPDFWorkerEmployer, cacheSize?: number) => {
    const id = await employer.createFzContext(cacheSize);
    this.fzId = id;
    // this.ctx = pointer;

    let resolvePromise = null;
    this.ready = new Promise<NeoPDFContext>(async (resolve) => { resolvePromise = resolve; });
    resolvePromise(this);
  }

  public free = async () => {
    this.superFree();
  }

  public createCookie = async () => {
    const cookie = await this.employer.createCookie(this.fzId);
    return cookie;
  }

  public deleteCookie = async (cookie: string) => {
    await this.employer.deleteCookie(cookie);
  }

  static fromContextId(employer: NeoPDFWorkerEmployer, ctxId: string) {
    const ctx = new NeoPDFContext(employer);
    ctx.fzId = ctxId;

    let resolvePromise = null;
    ctx.ready = new Promise<NeoPDFContext>(async (resolve) => { resolvePromise = resolve; });
    resolvePromise(this);
    return ctx;
  }

  static async fromGlobalContext(employer: NeoPDFWorkerEmployer) {
    const ctxId = await employer.getDefaultContextId();
    const pdfContext = NeoPDFContext.fromContextId(employer, ctxId);

    let resolvePromise = null;
    pdfContext.ready = new Promise<NeoPDFContext>(async (resolve) => { resolvePromise = resolve; });
    resolvePromise(this);

    return pdfContext;
  }

  public lock = async () => {
    await this.employer.lockContext(this.fzId);
  }

  public unlock = async () => {
    await this.employer.unlockContext(this.fzId);
  }
}
