
export enum InkStorageEventName {
  ON_PAGE_CHANGED = "is_page_changed_without_uuids",
  ON_PAGE_CHANGED_WITH_UUIDS = "is_page_changed",
  ON_PAGE_ADDED = "is_page_added",
  ON_PAGE_REMOVED = "is_page_removed",
  ON_PEN_ADDED = "is_pen_added",

  /**
   * 단위 stroke가 지워진 경우
   * 보내지는 event의 내용물은 지워진 처리가 된 NeoStroke의 배열
   * 2021/06/09 추가
   */

  ON_STROKE_SPLIT = "stroke_split",

  // 단위 스트로크가 지워진 경우, 이 경우에는 DB에는 반영되어 있지 않다.
  ON_STROKE_ERASED_AND_ADDED = 'ON_STROKE_ERASED_AND_ADDED',

  ON_STROKE_ERASED = "stroke_erased",

  ON_STROKE_ERASED_TO_REMOTE = "stroke_erased_to_be_sent",

  ON_OWNER_ADDED = "owner_added",

  ON_WRITER_ADDED = "writer_added",

  // 2021/07/06
  ON_NETWORK_STROKE_ADDED = "nw_stroked_added",

  ON_STROKE_ADDED = "stroke_added",

  ON_ATTACH_ADDED = "attach_added",

  ON_ATTACH_REMOVED = "attach_removed",

  ON_ATTACH_EDIT = "attach_edit",



  // Note Server와 관련된 이벤트
  ON_NOTE_ADDED = "noted_added",

  ON_NOTE_UPDATED = "noted_updated",

  ON_PAGE_UPDATED = "page_updated",

  ON_UNDO_REDO_HISTORY_CHANGED = "undo_redo_history_changed",

  LASSO_MODIFY_IN_PROGRESS = "lasso_modify_in_progress",

  LASSO_MODIFY_PROGRESS_UNDO_REDO = "lasso_modify_progress_undo_redo",


  // all other undo/redo history changed
  HISTORY_CHANGED = "history_changed",
}
