
import {
	Box,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import clsx from "clsx";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ResultReviewStyle } from "../../styles/ResultReviewStyle";

import { useTranslation } from 'react-i18next';
import { IRubricItem } from "../../../../repositories/model/transfer/IRubricInfo";


type Props = {
	itemInfo: IRubricItem;
}

export const RubricItemRow = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( ResultReviewStyle );
	const { itemInfo } = props;

	return (
		<TableRow >
			{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
			<TableCell width='8%' align="center" className={classes.bgColor}>
				<Typography>{itemInfo.name}</Typography>
			</TableCell>
			<TableCell width='24%' className={classes.bgColor}>
				<Typography className={classes.markText}><span>{itemInfo.score}</span>{i18next.t("점")}</Typography>
			</TableCell>
			<TableCell width='50%' align="left" className={classes.borderLine}>
				<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
					<Typography className={classes.tableText}>
						{itemInfo.standard}
					</Typography>
				</Box>
			</TableCell>
		</TableRow>
	);
};
