import {
	Box,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { PrintTextStyle } from "../../styles/PrintTextStyle";


// import 'dayjs/locale/ko';
import { useTranslation } from 'react-i18next';

import TextViewer from "../../../../components/common/TextViewer";
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";
import { useCurrentParagraphHTML } from "../../000_Common/001_Functions/useCurrentParagraphHtml";
import { useIsParagraphAndSourceBoxEmpty } from "../../000_Common/001_Functions/useIsParagraphAndSourceBoxEmpty";
import { QuestionAndReferenceTabQuestionRow } from "./QuestionAndReferenceTabQuestionRow";
import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";

type Props = {
	examInfo: IExamInfo;
	onSetQuestionHTMLWithOrderAndScore: any;
	index: number;
};

export const QuestionAndReferenceTabExamRow = (props: Props) => {
	const { examInfo, index } = props;
	const classes = useEmotionStyles(PrintTextStyle);
	const i18next = useTranslation();

	// const questionListLen = React.useMemo(() => {
	// 	return examInfo.questions.filter(q => q.changeFlag !== FlagType.DELETED).length;
	// }, [examInfo, examInfo.questions]);

	// const currentParagraphHTML = React.useMemo(() => {
	// 	if (!examInfo.paragraph && !examInfo.source) return "<></>";
	// 	const source = examInfo.source.replace(/\n/g, "<br>");

	// 	return (
	// 		"<p>" +
	// 		"<div style='font-size: 1rem;font-weight: 500;padding-left: 10px'>" +
	// 		examInfo.paragraph +
	// 		"\n\n" +
	// 		'</div>' +
	// 		"<div style='font-size: 0.875rem; text-align: right;padding-right: 10px'>" +
	// 		source +
	// 		'</div>' +
	// 		"</p>"
	// 	);
	// }, [examInfo.paragraph, examInfo.source]);

	const currentParagraphHTML = useCurrentParagraphHTML({
		examInfo,
		paragraph: { fontSize: "1rem", fontWeight: 500, paddingLeft: "10px" },
		source: { fontSize: "0.875rem", textAlign: "right", paddingRight: "10px" }
	});


	// const isParagraphAndSourceBoxEmpty = React.useMemo(() => {
	// 	const emptyParagraph = !examInfo.paragraph || (DataUtils.getTagArrFromHtml(examInfo.paragraph, 'img').length === 0 && !DataUtils.getTextFromHtml(examInfo.paragraph));
	// 	const emptySource = !examInfo.source;

	// 	return emptyParagraph && emptySource;
	// }, [examInfo.paragraph, examInfo.source]);
	const isParagraphAndSourceBoxEmpty = useIsParagraphAndSourceBoxEmpty(examInfo);

	return (
		<>
			<Typography className={classes.textArticleNumber}>{i18next.t("{{index}}번 문항", { index })}</Typography>
			{examInfo.directive && (
				<Typography className={classes.textArticleTitle}>{examInfo.directive}</Typography>
			)}
			{!isParagraphAndSourceBoxEmpty && (
				<Box display='flex' alignItems='center' justifyContent='left' className={classes.textArticle}>
					<TextViewer
						currentHTML={currentParagraphHTML}
					/>
				</Box>
			)}

			{examInfo.questions.length > 0 &&
				examInfo.questions
					.filter(q => q.changeFlag !== ChangeFlag.DELETED)
					.map(question => {
						return (
							<QuestionAndReferenceTabQuestionRow
								key={question.projectExamQuestionNum}
								examInfo={examInfo}
								questionInfo={question}
							/>
						)
					})
			}
		</>
	)
};