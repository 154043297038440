import { observer } from "mobx-react";

import React from "react";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { useStores } from "../../../../hooks/useStores";

type Props = {
}

function LandingEN(props: Props) {
	const { navigateStore } = useStores();
	const naviCustom = useNaviCustom();

	React.useEffect(() => {
		navigateStore.changeLanguage('en');
		naviCustom.go(`/`);
	}, []);


	return (
		<></>
	);
}

export default observer<typeof LandingEN>(LandingEN);
