import { NeoPDFDocument } from "../../../../nl-pdf-wrapper-importer";
import { INoteServerItem_forPOD, IPageSOBP, PU_TO_NU } from "../../../nl-lib3-common";

export async function generateTempPaperTypeFromPdf(sobp: IPageSOBP, pdfDoc: NeoPDFDocument | undefined, pageIdx: number) {
  const id = `${sobp.section}.${sobp.owner}.${sobp.book}.${sobp.page + pageIdx}`;
  const { filename = "" } = pdfDoc || {};
  const size_pu = await pdfDoc?.getPageSize(pageIdx + 1) || { width: (210 * 72) / 254, height: (297 * 72) / 25.4 };

  const ret: INoteServerItem_forPOD = {
    id,                 // 3.27.964
    title: filename,              // "페가수스 수학1_3권"
    pdf_page_count: 1,     // 131
    nproj_file: undefined,         // "note_964.nproj"
    pdf_name: filename,           // "math1_vol.3_print.pdf"
    ncode_end_page: sobp.page + pageIdx,
    ncode_start_page: sobp.page + pageIdx,

    sobp,

    /** Ncode Unit margin  */
    margin: {
      Xmin: 0,
      Xmax: size_pu.width * PU_TO_NU,

      Ymin: 0,
      Ymax: size_pu.height * PU_TO_NU,
    },

    /** n,u,l,d,r */
    glyphData: undefined,
    isDefault: false,
		paperGroupId: "temp",
  }

  return ret;
}
