// TODO - implement class Outline as follows:
/*
public class Outline
{
  public String title;
  public String uri;
  public Outline[] down; // children
}
*/

import { assert } from "./functions";
import { FzDocument } from "./FzDocument";
import { Wrapper } from "./Wrapper";

export class Outline extends Wrapper {
  constructor(libmupdf: any, ctx: number, pointer: number) {
    // TODO
    super(libmupdf, ctx, pointer, () => { });
  }

  pageIndex(doc) {
    assert(doc instanceof FzDocument, "invalid doc argument");
    const { libmupdf } = this;
    return libmupdf._wasm_outline_page(this.ctx, doc.pointer, this.pointer);
  }

  title() {
    // the string returned by this function is borrowed and doesn't need to be freed
    const { libmupdf } = this;
    return libmupdf.UTF8ToString(libmupdf._wasm_outline_title(this.ctx, this.pointer));
  }

  down() {
    const { libmupdf } = this;
    return new_outline(libmupdf, this.ctx, libmupdf._wasm_outline_down(this.ctx, this.pointer));
  }

  next() {
    const { libmupdf } = this;
    return new_outline(libmupdf, this.ctx, libmupdf._wasm_outline_next(this.ctx, this.pointer));
  }
}


export function new_outline(libmupdf: any, ctx: number, pointer: number,) {
  if (pointer === 0)
    return null;
  return new Outline(libmupdf, ctx, pointer);
}
