import { assert } from "./functions";
import { FzOutput } from "./FzOutput";
import { Wrapper } from "./Wrapper";



export class STextPage extends Wrapper {
  constructor(module: any, ctx: number, pointer: number) {
    super(module, ctx, pointer, module._wasm_drop_page);
  }

  printAsJson(output, scale) {
    assert(output instanceof FzOutput, "invalid output argument");
    this.libmupdf._wasm_print_stext_page_as_json(this.ctx, output.pointer, this.pointer, scale);
  }
}
