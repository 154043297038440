import {
	Box,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { AnswerSummitStyle } from "../../styles/AnswerSummitStyle";


import TextViewer from "../../../../components/common/TextViewer";
import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { useCurrentParagraphHTML } from "../../000_Common/001_Functions/useCurrentParagraphHtml";
import { useIsParagraphAndSourceBoxEmpty } from "../../000_Common/001_Functions/useIsParagraphAndSourceBoxEmpty";
import { QuestionAndReferenceTabQuestionRow } from "./QuestionAndReferenceTabQuestionRow";
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";

type Props = {
	index: number,
	examInfo: IExamInfo,
	editOnlinePaper,
	projectExamList,
	onlineAnswers,
	changeAnswers
}
export const QuestionAndReferenceTabExamRow = (props: Props) => {
	const { examInfo, editOnlinePaper, projectExamList, onlineAnswers, changeAnswers } = props;
	const classes = useEmotionStyles( AnswerSummitStyle );

	// const questionListLen = React.useMemo(() => {
	// 	return examInfo.questions.filter(q => q.changeFlag !== FlagType.DELETED).length;
	// }, [examInfo, examInfo.questions]);

	// const currentParagraphHTML = React.useMemo(() => {
	// 	if (!examInfo.paragraph && !examInfo.source) return "<></>";
	// 	const source = examInfo.source.replace(/\n/g, "<br>");

	// 	return (
	// 		"<p>" +
	// 		"<div style='font-size: 1rem;font-weight: 500;padding-left: 20px; padding-right: 20px;padding-top: 5px'>" +
	// 		examInfo.paragraph +
	// 		"\n\n" +
	// 		'</div>' +
	// 		"<div style='font-size: 0.875rem;  text-align: right; padding-left: 20px; padding-right: 20px'>" +
	// 		source +
	// 		'</div>' +
	// 		"</p>"
	// 	);
	// 	// return "<p>"+"<div style='font-size: 1rem;font-weight: 500;padding-left: 20px; padding-right: 20px;padding-top: 5px'>" + examInfo.paragraph + "\n\n"+'</div>' + "<div style='font-size: 0.875rem;  text-align: right; padding-left: 20px; padding-right: 20px'>" + examInfo.source + '</div>' + "</p>";
	// }, [examInfo.paragraph, examInfo.source]);

	const currentParagraphHTML = useCurrentParagraphHTML({
		examInfo,
		paragraph: { fontSize: '1rem', fontWeight: 500, paddingLeft: "20px", paddingRight: "20px", paddingTop: "5px" },
		source: { fontSize: '0.875rem', textAlign: 'right', paddingLeft: "20px", paddingRight: "20px" }
	});

	// const isParagraphAndSourceBoxEmpty = React.useMemo(() => {
	// 	const emptyParagraph = !examInfo.paragraph || (DataUtils.getTagArrFromHtml(examInfo.paragraph, 'img').length === 0 && !DataUtils.getTextFromHtml(examInfo.paragraph));
	// 	const emptySource = !examInfo.source;

	// 	return emptyParagraph && emptySource;
	// }, [examInfo.paragraph, examInfo.source]);
	const isParagraphAndSourceBoxEmpty = useIsParagraphAndSourceBoxEmpty(examInfo);

	return (
		<>
			{/*<Typography className={classes.textArticleNumber}>{`${{index}}번 문항 ${examInfo.type === ProjectExamType.ESSAY ? "(논술형)":"(서술형)"}`}</Typography>*/}
			{/*<Box className={classes.compareRound}>*/}
			{examInfo.directive && (
				<Typography className={classes.textDirective}>{examInfo.directive}</Typography>
			)}
			{!isParagraphAndSourceBoxEmpty && (
				<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} className={classes.textArticle}>
					<TextViewer
						currentHTML={currentParagraphHTML}
					/>
				</Box>
			)}

			{examInfo.questions.length > 0 &&
				examInfo.questions
					.filter(q => q.changeFlag !== ChangeFlag.DELETED)
					.map(question => {
						return (
							<QuestionAndReferenceTabQuestionRow
								editOnlinePaper={editOnlinePaper}
								key={question.projectExamQuestionNum}
								onlineAnswers={onlineAnswers}
								changeAnswers={changeAnswers}
								examInfo={examInfo}
								questionInfo={question}
								projectExamList={projectExamList}
							/>
						)
					})
			}
			{/*</Box>*/}
		</>
	)
};
