// import i18next from '../../lang';

export const menuPath = {
	"dashboard": {
		"id": "dashboard",
		"text": "대시보드", // i18next.t("대시보드"),
		"isClick": true
	},
	"projects": {
		"id": "projects",
		"text": "평가 과제 관리", // i18next.t("평가 과제 관리"),
		"isClick": true
	},
	"assignments": {
		"id": "assignments",
		"text": "평가 과제 운영", // i18next.t("평가 과제 운영"),
		"isClick": true
	},
	"students": {
		"id": "students",
		"text": "학생 관리", // i18next.t("학생 관리"),
		"isClick": true
	},
	"code": {
		"id": "code",
		"text": "상세", // i18next.t("{{category}} 상세", { category: "{{category}}" }),
		"isClick": true
	},
	"projectCode": {
		"id": "{{projectCode}}",
		"text": "{{projectCode}}",
		"isClick": false
	},
	"submissionCode": {
		"id": "submissionCode",
		"text": "{{submissionCode}}",
		"isClick": false
	},
	"view": {
		"id": "view",
		"text": "상세", // i18next.t("{{category}} 상세", { category: "{{category}}" }),
		"isClick": false
	},
	"create": {
		"id": "create",
		"text": "만들기", // i18next.t("{{category}} 만들기", { category: "{{category}}" }),
		"isClick": true
	},
	"info": {
		"id": "info",
		"text": "정보", // i18next.t("{{category}} 정보", { category: "{{category}}" }),
		"isClick": false
	},
	"question": {
		"id": "question",
		"text": "문항 생성", // i18next.t("문항 생성"),
		"isClick": false
	},
	"rag": {
		"id": "rag",
		"text": "참고 자료", // i18next.t("참고 자료"),
		"isClick": false
	},
	"answer": {
		"id": "answer",
		"text": "모범 답안", // i18next.t("모범 답안"),
		"isClick": false
	},
	"rubric": {
		"id": "rubric",
		"text": "평가 기준표", // i18next.t("평가 기준표"),
		"isClick": false
	},
	"target": {
		"id": "target",
		"text": "대상", // i18next.t("{{category}} 대상", { category: "{{category}}" }),
		"isClick": false
	},
	"paper": {
		"id": "target",
		"text": "평가과제 선택", // i18next.t("평가과제 선택"),
		"isClick": false
	},
	"submit": {
		"id": "submit",
		"text": "과제 제시하기", // i18next.t("과제 제시하기"),
		"isClick": false
	},
	"submissions": {
		"id": "submissions",
		"text": "제출물 관리", // i18next.t("제출물 관리"),
		"isClick": false
	},
	"feedbacks": {
		"id": "feedbacks",
		"text": "채점 및 피드백", // i18next.t("채점 및 피드백"),
		"isClick": false
	},
	"export": {
		"id": "export",
		"text": "결과 내보내기", // i18next.t("결과 내보내기"),
		"isClick": false
	},
	"printPaper": {
		"id": "printPaper",
		"text": "출력하기", // i18next.t("출력하기"),
		"isClick": false
	},
	"printAnswer": {
		"id": "printAnswer",
		"text": "답안지 출력", // i18next.t("답안지 출력"),
		"isClick": false
	},
	"test": {
		"id": "studentTestPaper",
		"text": "문제 풀이", // i18next.t("문제 풀이"),
		"isClick": false
	}
}
