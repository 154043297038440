import { EventClassBase } from "../../../../nl-lib3-common";
import { isDotNetEnvironment } from "../maui-dev-environment";
// import { IPenIdType } from "../../nl-lib-common";
import { DevServerEventName, IDevServerEvent, MauiRelaySocket } from "../maui-ws-relay/maui-relay-websocket";
import MauiPenCommManager from "./maui-pencomm-proxy";


// async function fetchWithTimeout(resource, options = {} as any) {
// 	const { timeout = 600000 } = options;

// 	const controller = new AbortController();
// 	const id = setTimeout(() => controller.abort(), timeout);

// 	const response = await fetch(resource, {
// 		...options,
// 		signal: controller.signal
// 	});
// 	clearTimeout(id);

// 	return response;
// }

export function getAssemblyName() {
	// const name = (window as any).mauiAssemblyName || "NeoLibBridge";
	const name = (window as any).mauiAssemblyName || "MauiContainer";

	// console.log(`AssemblyName from JS: ${(window as any).mauiAssemblyName}, ${name}`);
	return name;
}


export class DotNetApi extends EventClassBase<DevServerEventName, IDevServerEvent> {
	private static _inst: DotNetApi = undefined;

	private connectTask: { connectPromise?: Promise<boolean>, resolve?: (flag: boolean) => void, reject?: () => void } = {};

	// private _functions = new Map<string, { returnType: string, params: { name: string, type: string }[] }>();

	private constructor() {
		super();

		MauiRelaySocket.instance.addEventListener(DevServerEventName.ON_CONNECTION_CHANGED, this.onConnectionStatusChanged);
	}

	onConnectionStatusChanged = (e: { msg: string }) => {
		this.dispatcher.dispatch(DevServerEventName.ON_CONNECTION_CHANGED, e);
		// if (resolve) resolve();
	}

	static get instance() {
		if (!DotNetApi._inst) {
			DotNetApi._inst = new DotNetApi();
			MauiPenCommManager.instance.init();
			return DotNetApi._inst;
		}

		return DotNetApi._inst;
	}

	// register(funcName: string, args: { returnType: string, params: { name: string, type: string }[] }) {
	// 	this._functions.set(funcName, args);
	// }

	get connected() {
		return MauiRelaySocket.instance.connected;
	}

	public connectWebSocket = () => {
		let resolve: (flag: boolean) => void = null;
		let reject = null;
		const promise = new Promise<boolean>((rs, rj) => {
			resolve = rs;
			reject = rj;
		});

		this.connectTask = { connectPromise: promise, resolve, reject };

		MauiRelaySocket.instance.open()
			.then((result) => {
				if (this.connectTask && result.ok) {
					this.connectTask.resolve?.(true);
					this.connectTask.reject = null;
					this.connectTask.resolve = null;
					this.connectTask = null;
				}
				else {
					if (this.connectTask) {
						this.connectTask.resolve?.(false);
						this.connectTask.reject = null;
						this.connectTask.resolve = null;
						this.connectTask = null;
					}
				}
			})
			.catch((e) => {
				if (this.connectTask) {
					this.connectTask.resolve?.(false);
					this.connectTask.reject = null;
					this.connectTask.resolve = null;
					this.connectTask = null;
				}
			});


		window.setTimeout(() => {
			if (this.connectTask?.resolve) {
				this.connectTask?.resolve?.(false);
				if (this.connectTask) {
					this.connectTask.reject = null;
					this.connectTask.resolve = null;
					this.connectTask = null;
				}
			}
		}, 100);

		return promise;
	}

	private async invoke_WebSocket(funcName: string, ...args: any[]) {

		// console.log(`WebSocket Invoke ${funcName}@$WebSocket`);
		// FIXME: 매번 실행해 주기에는 부담되지 않나?
		console.log(`Bridge Invoke: ${funcName}@${getAssemblyName()}`);

		const ret = await MauiRelaySocket.instance.bridgeInvokeMethodAsync(funcName, ...args);
		console.log(`Bridge Returned: ${funcName}@${getAssemblyName()}`, ret?.length);

		return ret;
	}


	public async invoke(funcName: string, ...args: any[]) {
		return this.invoke_WebSocket(funcName, ...args);
	}


	invokeBytesMethod = async (funcName: string, bytes: Uint8Array) => {
		// console.log(`Method Invoke ${funcName}`);

		if (!await isDotNetEnvironment()) {
			throw new Error(`invokeWithBytes is not supported in Web environment`);
		}

		// 순수한 local host일 때
		const { NeoDotNet, DotNet } = (window as any);
		if (NeoDotNet || DotNet) {
			if (NeoDotNet) {
				try {
					console.log(`NeoDotNet Invoke: ${funcName}`);
					const ret = await NeoDotNet.invokeMethodAsync(funcName, bytes);
					console.log(`NeoDotNet Returned: ${funcName}`);
					return ret;

				} catch (e) {
					console.error(`js-to-maui, invokeBytesMethod, const ret = await NeoDotNet.invokeMethodAsync('${funcName}', bytes); error`, e);

					return null
				}
			}

			// throw new Error("DotNet is not found");

			try {
				console.log(`DotNet Invoke ${funcName}@${getAssemblyName()}`);
				const ret = await DotNet?.invokeMethodAsync(getAssemblyName(), funcName, bytes);
				console.log(`DotNet Returned: ${funcName}@${getAssemblyName()}`);
				return ret;
			} catch (e) {
				console.error(`js-to-maui, invokeBytesMethod, const ret = await DotNet?.invokeMethodAsync('${getAssemblyName()}', '${funcName}', bytes); error`, e);

				return null
			}
		}

		// web socket일 때
		console.log(`WebSocket Invoke ${funcName} @$WebSocket`);
		const ret = await MauiRelaySocket.instance.bridgeInvokeBytesMethodAsync(funcName, bytes);
		return ret;
	}

	static httpDownload = async (url: string): Promise<{ ok: boolean, binary?: Uint8Array, error?: any }> => {
		try {
			const resultJson = await DotNetApi.instance.invoke('HttpDownload', url);
			const result = JSON.parse(resultJson) as { success: boolean, result?: string, error?: string };

			if (result.success) {
				const b = window.atob(result.result);
				const len = b.length;
				const bytes = new Uint8Array(len);
				for (let i = 0; i < len; i++) {
					bytes[i] = b.charCodeAt(i);
				}

				return { ok: true, binary: bytes };
			}
			else {
				throw new Error(result.error);
			}

		} catch (e) {
			console.error(e);
			return { ok: false, error: e };
		}
	}

	public static async downloadLangPack(locale: string, requestUri: string): Promise<{ ok: boolean, error?: any }> {
		try {
			const resultJson = await DotNetApi.instance.invoke('DownloadLangPack', locale, requestUri);
			const result = JSON.parse(resultJson) as { success: boolean, error?: string };

			if (result.success) {
				return { ok: true };
			}
			else {
				throw new Error(result.error);
			}

		} catch (e) {
			console.error(e);
			return { ok: false, error: e };
		}
	}

	static deleteLangPack = async (locale: string): Promise<{ ok: boolean, error?: any }> => {
		try {
			const resultJson = await DotNetApi.instance.invoke('DeleteLangPack', locale);
			const result = JSON.parse(resultJson) as { success: boolean, error?: string };

			if (result.success) {
				return { ok: true };
			}
			else {
				throw new Error(result.error);
			}

		} catch (e) {
			console.error(e);
			return { ok: false, error: e };
		}
	}
}
export type mauiApiType = typeof DotNetApi;
