import { uuidv4 } from "../../../../neolab-libs/nl-lib3-common";
import { QuestionareHtmlContent } from "../../../../neolab-libs/nl-lib3-pengateway/src/nl-lib-native-methods/ImportFromFileTypes";
import FileInfoStore from "../../../../stores/FileInfoStore";
import ProjectExamStore from "../../../../stores/ProjectExamStore";

export async function uploadImageFilesToCloud(subQuestion: QuestionareHtmlContent, fileInfoStore: FileInfoStore, projectExamStore: ProjectExamStore) {
	const { arrangedHtml, images } = subQuestion;
	let processedHtml = arrangedHtml;

	const imgUrlMap: { ori: string, rep: string }[] = [];
	for (const image of images) {
		const blob = new Blob([image.bytes], { type: 'image/png' });

		// blob to image/png File
		const file = new File([blob], uuidv4() + ".png", { type: 'image/png' });
		let id = 0 as number;
		let downloadUrl = null as string;
		try {
			const ret = await fileInfoStore.create(file);
			if (!ret) continue;
			id = ret.id;
			downloadUrl = ret.downloadUrl
			projectExamStore.addImageId(id);
			imgUrlMap.push({ ori: image.url, rep: downloadUrl });
		} catch (error) {
			console.error('이미지 업로드 중 오류:', error);
			continue;
		}
	}

	for (const img of imgUrlMap) {
		processedHtml = processedHtml.replace(img.ori, img.rep);
		processedHtml = processedHtml.replace(/<img\s+([^>]*?)alt="[^"]*"(.*?)>/gi, '<img $1$2>');

		// <p class="MsoNormal __web-inspector-hide-shortcut__"><span style="color:#D9D9D9" lang="EN-US">&nbsp;</span></p> 제거
		const pattern = /<p\s+class="MsoNormal\s+__web-inspector-hide-shortcut__">\s*<span\s+style="color:#D9D9D9"\s+lang="EN-US">\s*&nbsp;\s*<\/span>\s*<\/p>/gi;
		// 패턴과 일치하는 부분을 빈 문자열로 대체하여 제거
		processedHtml = processedHtml.replace(pattern, '');
	}

	return processedHtml;
}