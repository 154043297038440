
// export type IPenIdType = string;

import { IDiscoveredDevices, IPenIdType } from "./IDiscoveredDevices";

// export type IDiscoveredDevices = {
//   id: IPenIdType,
//   name: string,
//   rssi: number,
// 	mac: string,
//   colorCode: number,
//   svcUuid: string,
// }


export enum MauiToJsEventName {
  onReportDeviceScanningFinished = "onReportDeviceScanningFinished",
  onAutoConnectCompleted = "onAutoConnectCompleted",
  onBluetoothTurnOff = "onBluetoothTurnOff",

  setAssemblyName = "setAssemblyName",
  onDeviceDiscoveringUpdate = "onDeviceDiscoveringUpdate",
  onDeviceNotification = "onDeviceNotification",
  onDeviceDisconnected = "onDeviceDisconnected",
  onAuthCodeReceived = "onAuthCodeReceived",
  onRefreshTokenReceived = "onRefreshTokenReceived",

  onAppActivated = "onAppActivated",
  onAppDeactivated = "onAppDeactivated",
  onAppSleep = "onAppSleep",
  requestSyncNotebooks = "requestSyncNotebooks",

  onRefreshTokenVoid = "onRefreshTokenVoid",
  onRefreshTokenExist = "onRefreshTokenExist",

  onBackPressed = "onBackPressed",

  CloseDrawer = "CloseDrawer",

  onFailedToGetGoogleDriveToken = "onFailedToGetGoogleDriveToken",

  transferProgress = "transferProgress",

  onErrorCodeReceived = "onErrorCodeReceived",

  onKeyboardVisibilityChanged = "onKeyboardVisibilityChanged",
}

export type MauiToJsEvent_onAutoConnectCompleted = {
  deviceId: string
}



export type MauiToJsEvent_onAuthCodeReceived = {
  code: string;
}


export type MauiToJsEvent_onRefreshTokenReceived = {
  refreshJson: string
}

export type MauiToJsEvent_onDeviceDisconnected = {
  id: IPenIdType
}

export type MauiToJsEvent_onDeviceNotification = {
  id: IPenIdType,
  bytes: Uint8Array,
  mac: string
}


export type MauiToJsEvent_onDeviceDiscoveringUpdate = {
  devices: IDiscoveredDevices[]
}



export type MauiToJsEvent_transferProgress = {
  progress: number
}

export type MauiToJsEvent_onErrorCodeReceived = {
  code: string
}

export type MauiToJsEvent_onKeyboardVisibilityChanged = {
  onVisible: boolean
}



export type MauiToJsEvent = MauiToJsEvent_onAuthCodeReceived | MauiToJsEvent_onRefreshTokenReceived | MauiToJsEvent_onDeviceDisconnected | MauiToJsEvent_onDeviceNotification | MauiToJsEvent_onDeviceDiscoveringUpdate | MauiToJsEvent_onAutoConnectCompleted | MauiToJsEvent_transferProgress | MauiToJsEvent_onErrorCodeReceived | MauiToJsEvent_onKeyboardVisibilityChanged;
