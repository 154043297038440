import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	IconButton, Popper,
	Theme,
	Typography
} from "@mui/material";
import clsx from "clsx";
import React from "react";
import { ReactComponent as TooltipClose } from '../../../../common/images/TooltipClose.svg';
import { ReactComponent as TooltipTipIcon } from '../../../../common/images/TooltipTipIcon.svg';
import { ReactComponent as WarningCircle } from '../../../../common/images/WarningCircle.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ExamAnswerStyle } from "../../styles/ExamAnswerStyle";

import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
// import { withRouter } from "../../../../components/WithRouter";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { useStores } from "../../../../hooks/useStores";
import { ProjectExamListUpdateType } from "../../../../repositories/model/support/ProjectExamListUpdateType";
import { DetailPageType } from "../../../../stores/support/DetailPageType";
import ProjectPreviewRightPannel from "../0_RightPannel/ProjectPreviewRightPannel";
import { QuestionAnswerRow } from './QuestionAnswerRow';
import { useParams } from "react-router-dom";

type Props = {
}

function ExamAnswer(props: Props) {
	const params = useParams();
	const classes = useEmotionStyles(ExamAnswerStyle);
	const { navigateStore, projectExamStore, messageStore, projectStore } = useStores();
	const i18next = useTranslation();

	const contentsBoxRef = React.useRef(null);
	const [childStatuses, setChildStatuses] = React.useState({});
	const [anchorEl, setAnchorEl] = React.useState(null);
	const naviCustom = useNaviCustom();


	React.useEffect(() => {
		navigateStore.changeNavigateRootPage(false);
		navigateStore.changeNavigateDetailPage(DetailPageType.PROJECT);
		navigateStore.changeProjectSideBarStep(4);
		navigateStore.changeSideBarStepNum(2);

		return () => {
			navigateStore.changeIsConfirmForUpdateRequired(false);
			// projectExamStore.changeIsConfirmForUpdateRequired(false);
			navigateStore.changeNavigateDetailPage(null);
			projectExamStore.init();
		}
	}, []);

	React.useEffect(() => {
		const projectCode = params.projectCode;
		if (!projectCode || projectCode === projectExamStore.projectCode) return;

		projectExamStore.setProjectCode(projectCode);
		projectExamStore.downloadProjectExamListAndStore(projectCode).then(result => {
			if (projectExamStore.projectExamList.length === 0) {
				return;
			}

			setTimeout(() => {
				// 랜더링 이후 수행, 스크롤 상단으로 이동
				contentsBoxRef.current.scrollTop = 0;
			}, 10);
		});
	}, [params]);

	React.useEffect(() => {
		if (!projectExamStore.isConfirmForUpdateRequired
			|| navigateStore.isConfirmForUpdateRequired
		) return;

		navigateStore.changeIsConfirmForUpdateRequired(true);
	}, [projectExamStore.isConfirmForUpdateRequired]);

	const handleChildStatusChange = (childId, status) => {
		setChildStatuses(prev => ({
			...prev,
			[childId]: status,
		}));
	};

	const isAnyChildTrue = Object.values(childStatuses).some(status => status === true);

	const processAfterStorage = () => {
		navigateStore.changeIsConfirmForUpdateRequired(false);
		projectExamStore.changeIsConfirmForUpdateRequired(false);
	};

	const handleClickTemporalStorage = async () => {
		const result = await messageStore.confirm(i18next.t("임시 저장하시겠습니까?"));
		if (!result) return;

		await projectExamStore.updateProjectExamList(ProjectExamListUpdateType.ANSWER)
			.then(async result => {
				if (!result || !projectExamStore.projectCode) return;

				processAfterStorage();
				await messageStore.alert(i18next.t("저장되었습니다."));
				const { projectCode } = params;
				projectStore.getProjectStates(projectCode);
			})
	};

	const handleClickNextBtn = async () => {
		const result = await messageStore.confirm(i18next.t("저장하고 다음으로 넘어가시겠습니까?"));
		if (!result) return;

		await projectExamStore.updateProjectExamList(ProjectExamListUpdateType.ANSWER)
			.then(result => {
				if (!result || !projectExamStore.projectCode) return;

				processAfterStorage();
				naviCustom.go(`/projects/${projectExamStore.projectCode}/create/rubric`);
				// props.navigate(`/projects/${projectExamStore.projectCode}/create/rubric`);
			})
	};

	const handleClickDisabledTemporalStorage = () => {
		messageStore.alert(i18next.t("AI 답안 생성 중에는 임시 저장 버튼을 누를 수 없습니다."));
	};

	const handleClickDisabledNextBtn = () => {
		messageStore.alert(i18next.t("AI 답안 생성 중에는 다음 버튼을 누를 수 없습니다."));
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div className={classes.root}>
			{/* ******콘텐츠 영역******* */}
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 86px)'
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>
				<Box className={classes.minaContentLeftRight} sx={{ position: 'relative' }}>
					<Box id={"mainContentBoxLeft"} className={classes.mainContentBoxLeft}>
						<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginBottom: "20px" }}>
							<Box display='flex' alignItems='center' justifyContent='center' className={classes.moreInfoPopover}>
								<Typography className={classes.commonSubTitle}>{i18next.t("4. 모범 답안")}</Typography>
								<IconButton onClick={handleClick} className={classes.iconBtnReset} disableRipple>
									<WarningCircle />
								</IconButton>

								<Popper id={id} open={open} anchorEl={anchorEl}
									// anchorOrigin={{
									//     vertical: 'bottom',
									//     horizontal: 'right',
									// }}
									// transformOrigin={{
									//     vertical: 'top',
									//     horizontal: 'left',
									// }}
									className={classes.popoverTooltipInfo}
								>
									<Box className={classes.tooltipTopInfo}>
										<TooltipTipIcon />
									</Box>
									<Box display={'flex'} justifyContent={'flex-end'} className={classes.tooltipBtn}>
										<Button
											onClick={handleClose}
											disableRipple>
											<TooltipClose />
										</Button>
									</Box>
									<Typography className={classes.tooltipTitle}>{i18next.t("모범 답안 작성 방법")}</Typography>
									<Typography className={classes.tooltipText}>
										{i18next.t("공정한 채점을 위한 채점 기준 해석의 토대가 되고 평가 목표에 맞는 모범 답안을 작성해 보세요.")}
										<br /> {i18next.t("참고 자료 단계에서 등록한 자료를 기반으로 AI 참고 답안을 생성할 수 있습니다.")}
									</Typography>
								</Popper>
							</Box>

							<Box sx={{ marginBottom: "15px" }} display='flex' alignItems='center' justifyContent='space-between'>
								<Box sx={{ marginLeft: "15px" }}>
									<Button
										className={isAnyChildTrue ? classes.lineBtn : clsx(classes.lineBtn, classes.lineBtnActive)}
										onClick={isAnyChildTrue ? handleClickDisabledTemporalStorage : handleClickTemporalStorage}
										// disabled={isAnyChildTrue}
										disableRipple>
										<Typography>{i18next.t("임시 저장")}</Typography>
									</Button>
								</Box>

								<Box sx={{ marginLeft: "15px" }}>
									<Button
										className={isAnyChildTrue ? classes.greenBtn : clsx(classes.greenBtn, classes.greenBtnActive)}
										onClick={isAnyChildTrue ? handleClickDisabledNextBtn : handleClickNextBtn}
										// disabled={isAnyChildTrue}
										disableRipple>
										<Typography>{i18next.t("다음")}</Typography>
									</Button>
								</Box>
							</Box>
						</Box>

						<Box ref={contentsBoxRef} className={classes.mainContentBoxLeftInner}>
							<Box>
								{projectExamStore.projectExamList.length > 0 &&
									projectExamStore.projectExamList.map((exam, examIndex) => {
										return (
											exam.questions.map((question, questionIndex) => {
												return (
													<QuestionAnswerRow
														key={question.projectExamQuestionNum}
														questionInfo={question}
														examInfo={exam}
														onStatusChange={handleChildStatusChange}
														statusKey={`${examIndex}-${questionIndex}`}
													/>
												)
											})
										)
									})
								}
							</Box>
						</Box>
					</Box>

					<ProjectPreviewRightPannel />
				</Box>
			</Box>

			{projectExamStore.isSelectingProjectExamList &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectExamStore.isSelectingProjectExamList}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("조회 중입니다...")}</Typography>
				</Backdrop>
			}

			{projectExamStore.isUpdatingProjectExamList &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectExamStore.isUpdatingProjectExamList}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("저장 중입니다...")}</Typography>
				</Backdrop>
			}

		</div>
	);
}

export default observer<typeof ExamAnswer>(ExamAnswer);
