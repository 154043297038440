import { INoteServerItem_forPOD, IPageSOBP, PU_TO_NU } from "../../../nl-lib3-common";

// 2021/08/25, Overlay를 위해
export function generateTempPaperTypeFromSize(
  sobp: IPageSOBP,
  size_pu: { width: number, height: number },
  pageIdx: number
) {
  const id = `${sobp.section}.${sobp.owner}.${sobp.book}.${sobp.page + pageIdx}`;

  const ret: INoteServerItem_forPOD = {
    id,                 // 3.27.964
    title: undefined,              // "페가수스 수학1_3권"
    pdf_page_count: 1,     // 131
    nproj_file: undefined,         // "note_964.nproj"
    pdf_name: undefined,           // "math1_vol.3_print.pdf"
    ncode_end_page: sobp.page + pageIdx,
    ncode_start_page: sobp.page + pageIdx,

    sobp,

    /** Ncode Unit margin  */
    margin: {
      Xmin: 0,
      Xmax: size_pu.width * PU_TO_NU,

      Ymin: 0,
      Ymax: size_pu.height * PU_TO_NU,
    },

    /** n,u,l,d,r */
    glyphData: undefined,
    isDefault: false,

		paperGroupId: "temp",
  }

  return ret;
}
