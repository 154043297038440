import { Theme } from '@mui/material/styles'

import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";


export const Styles: (theme: any) => Record<string, CSSInterpolation> = (theme) => {
	const style =
		{
			root: {
				display: "flex",
			},
			mainContainer: {
				flexGrow: 1,
				padding: (theme as Theme).spacing(3),
			},
			appBarSpacer: {
				...(theme as Theme).mixins.toolbar, // toolbar 속성을 포함시킵니다.
			},
			mainContent: {
				marginTop: (theme as Theme).spacing(2),
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			},
			toolbar: {
				width: '100%',
			},
		} as Record<string, CSSInterpolation>

	return style;
};


