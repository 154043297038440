import { NU_TO_PU } from "../constants/ncode-constants";
import { IPageSOBP } from "../structures/Structures";

const PU_TO_NU = 0.148809523809524;

type GenNprojArgType = {
  title: string,
  filename: string,
  startSobp: IPageSOBP,
  sizes: { width: number, height: number }[],
}

function generatePresetTag() {
  const pattern = "PDS3";
  const resCount = 4;
  const defaultLang = "Korean";
  const exportScript = "pds";
  const hasNcp = false;       // ogirinally, true
  const relationLink = true;
  const dummy = true;
  const manualAction = true;

  const result = `<preset>\n`
    + `<pattern>${pattern}</pattern>\n`
    + `<sound set="true" filter="*.mp3"/>\n`
    + `<video set="true" filter="*.wmv,*.avi"/>\n`
    + `<image set="true" filter="*.jpg,*.png"/>\n`
    + `<res_count>${resCount}</res_count>\n`
    + `<default_res_lang>${defaultLang}</default_res_lang>\n`
    + `<export_script>${exportScript}</export_script>\n`
    + `<ncp>${hasNcp}</ncp>\n`
    + `<relation_link>${relationLink}</relation_link>\n`
    + `<dummy>${dummy}</dummy>\n`
    + `<manual_action>${manualAction}</manual_action>\n`
    + `<symbol_setting align="4" bgcolor="#00ff00" bgalpha="70" outline_alpha="70" textcolor="#000000" textsize="9" texton="1"/>\n`
    + `<margin left="0" top="0" right="0" bottom="0"/>\n`
    + `</preset>`;

  return result;
}


function generateBookTag(args: GenNprojArgType) {
  const { startSobp, filename, sizes } = args;
  let { title } = args;
  const revision = 15;
  const lineSegmentLength = 1;
  const dotSize = 1;
  const dpi = 600;
  const kind = 0;

  if (!title) title = filename;

  const author = "NeoLAB POD Generator";
  const { section, owner, book } = startSobp;
  let { page: numStartPage } = startSobp;
  if (numStartPage === null || numStartPage === undefined) numStartPage = 1;

  const isLineCode = false;

  const numPages = sizes.length;

  const result = `<book>\n`
    + `<title>${title}</title>\n`
    + `<author>${author}</author>\n`
    + `<section>${section}</section>\n`
    + `<owner>${owner}</owner>\n`
    + `<code>${book}</code>\n`
    + `<revision>${revision}</revision>\n`
    + `<scale>0.001</scale>\n`
    // `<start_page side="">${numStartPage}</start_page>\n` +
    + `<start_page side="">${numStartPage}</start_page>\n`
    + `<dot_is_line_segment>${isLineCode}</dot_is_line_segment>\n`
    + `<line_segment_length>${lineSegmentLength}</line_segment_length>\n`
    + `<target_dpi>${dpi}</target_dpi>\n`
    + `<dotsize>${dotSize}</dotsize>\n`
    + `<segment_info sub_code="" total_size="${numPages}" size="${numPages}" current_sequence="0" ncode_start_page="${numStartPage}" ncode_end_page="${numStartPage + numPages - 1}"/>\n`
    + `<extra_info>\n`
    + `pdf_page_count=${numPages}\n`
    + `</extra_info>\n`
    + `<kind>${kind}</kind>\n`
    + `</book>`;

  return result;
}

function generateTicketTag() {
  const result = `<ticket>\n`
    + `<path></path>\n`
    + `</ticket>`;


  return result;
}

function generatePdfTag(args: GenNprojArgType) {
  const { filename } = args;
  const pdfFilename = filename;
  const result = `<pdf>\n`
    + `<path>${pdfFilename}</path>\n`
    + `</pdf>`;

  return result;
}

function generateConfigTag() {
  const resourceMappingMode = "NORMAL";
  const result = `<config>\n`
    + `<resource_mapping_mode>${resourceMappingMode}</resource_mapping_mode>\n`
    + `</config>`;

  return result;
}

function generatePagesTag(args: GenNprojArgType) {
  const { sizes } = args;
  const numPages = sizes.length;

  let result = `<pages count="${numPages}">\n`;

  // 각 페이지별로, x1, y1, x2, y2, crop_margin 값을 세팅 (모두 pu)
  // 추가로 nl_lib_zoom 이라는 값을 넣는다. 이 값은 PDF를 축소해서 인쇄하는 경우가 있기 때문이다
  for (let i = 0, ln = sizes.length; i < ln; i++) {
    const index = i;
    const size = sizes[i];
    const pu = size;
    const nu = {
      Xmin: 0,
      Ymin: 0,

      Xmax: size.width * PU_TO_NU,
      Ymax: size.height * PU_TO_NU,

      width: 0,
      height: 0,
    }

    nu.width = nu.Xmax - nu.Xmin;
    nu.height = nu.Ymax - nu.Ymin;

    const crop_margin_l = nu.Xmin * NU_TO_PU;
    const crop_margin_t = nu.Ymin * NU_TO_PU;
    const crop_margin_r = 0;
    const crop_margin_b = 0;

    const x1 = 0;
    const y1 = 0;
    const x2 = crop_margin_l + pu.width;
    const y2 = crop_margin_t + pu.height;


    // homography도 넣어줘야 한다.
    const a = 0, b = 0, c = 0, d = 0, e = 0, f = 0, g = 0, h = 0;

    // printed scale은 1:1 (즉, pu/nu)일 때 1,
    // 축소되어 인쇄되면 1보다 작은 값
    // 확대되어 인쇄되면 1보다 큰 값
    const printed_scale = 1 * NU_TO_PU / (pu.width / nu.width);
    const pgStr = `<page_item number="${index}" `
      // kitty upgraded, 2021/06/14
      + `printed_scale="${printed_scale}" `
      // kitty upgraded, 2021/06/14
      + `homography="${a},${b},${c},${d},${e},${f},${g},${h},1" `

      + `rotate_angle="0" `
      + `x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" `
      + `crop_margin="${crop_margin_l},${crop_margin_t},${crop_margin_r},${crop_margin_b}" `
      + `bg_disabled="false" `
      + `page_type="0" `
      + `/>\n`;

    result += pgStr;
  }

  result += `</pages>`;


  return result;
}


function generateActionTableTag() {
  const result = `<action_table>\n`
    + `</action_table>`;



  return result;
}

function generateSymbolsTag() {
  const result = `<symbols>\n`
    + `</symbols>`;

  return result;
}

export default function generateNprojFromSizes(args: GenNprojArgType) {
  const preset = generatePresetTag();
  const book = generateBookTag(args);
  const ticket = generateTicketTag();
  const pdf = generatePdfTag(args);
  const config = generateConfigTag();
  const pages = generatePagesTag(args);
  const actions = generateActionTableTag();
  const symbols = generateSymbolsTag();

  const result = `<?xml version="1.0" encoding="UTF-8"?>\n`
    + `<!DOCTYPE nproj>\n`
    + `<nproj version="2.31" category="simple">\n`
    + `${preset}\n`
    + `${book}\n`
    + `${ticket}\n`
    + `${pdf}\n`
    + `${config}\n`
    + `${pages}\n`
    + `${actions}\n`
    + `${symbols}\n`
    + `<resources>\n`
    + `</resources>\n`
    + `</nproj>\n`;


  return result;
}
