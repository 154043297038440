import {
	Box,
	Button,
	LinearProgress,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { MarkFeedbackListStyle } from "../../styles/MarkFeedbackListStyle";


import { ReactComponent as AiLoadingIcon } from '../../../../common/images/AiLoadingIcon.svg';


// import 'dayjs/locale/ko';
import { useTranslation } from "react-i18next";

import { convertUTCToKST } from "../../../../hooks/convertUTCToKST";
import React from "react";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";

type Props = {
	item: any;
	idx: number;
	rowStartNum: number;
	projectCode: string;
	handleOpenMarkDialog?: (submissionCode: string) => void;
};

export const Row = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( MarkFeedbackListStyle );

	const {
		item,
		idx,
		rowStartNum,
		handleOpenMarkDialog
	} = props;

	const gradeClassName = React.useMemo(() => {
		if (!item.grade || !item.className) {
			return '-';
		}

		return i18next.t("{{grade}}학년 {{className}}반", { grade: item.grade, className: item.className });
	}, [item.grade, item.className]);

	const scoredRate = React.useMemo(() => {
		return Math.ceil(item.scoredCount / item.totalCount * 100);
	}, [item.scoredCount, item.totalCount]);

	const currentScoredDatetime = React.useMemo(() => {
		if (item.scoredDatetime) {
			return convertUTCToKST(item.scoredDatetime).format("YYYY.MM.DD HH:mm");
		}

		return '-';
	}, [item.scoredDatetime]);

	const isScored = React.useMemo(() => {
		return item.state === ProjectStateType.SCORED;
	}, [item]);

	const isExported = React.useMemo(() => {
		return item.state === ProjectStateType.EXPORTED;
	}, [item]);

	const handleClickMark = () => {
		if (!item.submissionCode) return;

		/* case of opening new tab
		const url = `/assignments/${projectCode}/submissions/${item.submissionCode}/feedbacks/detail`;
		window.open(url, "_blank", "noopener,noreferrer");
		*/

		// case of opening a dialog
		handleOpenMarkDialog && handleOpenMarkDialog(item.submissionCode);
	};

	return (
		<TableRow >
			{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
			<TableCell align="center">
				{rowStartNum + idx}
			</TableCell>
			<TableCell>
				{item.userName}
			</TableCell>
			<TableCell>
				{gradeClassName}
			</TableCell>
			<TableCell>
				<Typography className={classes.resultNumber}><span>{item.scoredScore}</span>/{item.totalScore}</Typography>
			</TableCell>
			<TableCell>
				{currentScoredDatetime}
			</TableCell>
			<TableCell>
				<Box display='flex' alignItems='center'>
					<Box className={classes.numberBox}>
						<Typography><span>{item.scoredCount}</span>/{item.totalCount}</Typography>
					</Box>
					<Box className={classes.progressBox}>
						<LinearProgress variant="determinate" value={scoredRate} />
					</Box>
					<Typography className={classes.progressText}>{scoredRate}%</Typography>
				</Box>
			</TableCell>
			<TableCell>
				<Box>
					{item.onTheAi > 0 ?
						// <Button className={classes.aiMark} disabled={true} disableRipple>
						<Button className={classes.aiMark} onClick={handleClickMark} disableRipple>
							<AiLoadingIcon />
							<Typography>{i18next.t('AI 채점 중..')}</Typography>
						</Button>
						:
						<Button className={classes.reMarkSelf} onClick={handleClickMark} disableRipple>
							<Typography>{isExported ? i18next.t("채점 완료") : (isScored ? i18next.t("재채점하기") : i18next.t("채점하기"))}</Typography>
						</Button>
					}
				</Box>
			</TableCell>
		</TableRow>
	)
}
