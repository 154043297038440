import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import dayjs from "dayjs";
import UserRepository from "../repositories/UserRepository";
import AuthStore from "./AuthStore";
import { ISiGunGoo, ISiGunGooListResponse } from "../repositories/model/transfer/ISiGunGoo";
import { ISido, ISidoListResponse } from "../repositories/model/transfer/ISido";
import { IStudentInfoForSave } from "../repositories/model/transfer/IStudentInfoForSave";
import { ProjectSchoolType } from "./enums/ProjectStoreEnums";
import { IUserProfile } from "../repositories/model/IUserProfile";
import { ISchoolCodeTransfer } from "../repositories/model/transfer/ISchoolCodeTransfer";
import { IUser } from "../repositories/model/IUser";

const LogPrefix = "[UserStore]";

const searchingInterval = 1000;

export const accountEmailAddress = '@aigle.cloud'; // @assessment.kr
// export enum State {
// 	Authenticated = "Authenticated",
// 	NotAuthenticated = "NotAuthenticated",
// 	Pending = "Pending",
// 	Failed = "Failed"
// }


const EmptyUserProfile: IUserProfile = {} as any as IUserProfile;
// {
// 	userCode: "",
// 	id: "",
// 	name: "",
// 	gender: "",
// 	birth: "",
// 	organization: "",

// 	avatarImageObject: undefined as any,
// };

const EmptyUser: IUser = {} as any as IUser;
// {
// 	id: "",
// 	email: "",
// 	password: "",
// 	nickname: "",
// 	type: "",
// 	name: "",
// 	gender: "",
// 	birth: "",
// 	organization: "",
// 	avatarImageObject: "",
// };

type Props = {
	userRepository: UserRepository;
	authStore: AuthStore;
};

export default class UserStore {
	public userRepository: UserRepository;
	public authStore: AuthStore;
	public users: any[];
	public userProfileGroupTransfers: any[];

	public groupTransfers: any[];

	public searchedUsers: any[];
	public searchedGroups: any[];
	public modifyUser: typeof EmptyUser;
	public modifyUserBirthYear: string;
	public modifyUserBirthMonth: string;
	public modifyUserBirthDay: string;

	public userProfile: typeof EmptyUserProfile;

	public isSearchLoading: boolean;
	public searchKeyword: string;
	public searchIntervalState: any;

	public myUsers: any[];
	public myUserCount: number;
	public mySchools: ISchoolCodeTransfer[];

	public checkedEmails: any[];
	public sidoList: ISido[];
	public siGunGooList: ISiGunGoo[];
	public checkedStNumList: any[];
	public myGroupsForConfig: any[];
	public myGroupsTotalCount: number;
	public myGroupsSaveData: any[];

	public myUserLoading: boolean;
	public checkEmailLoading: boolean;
	public backdropOpen: boolean;
	public saveComplete: boolean;
	public groupConfigLoading: boolean;
	public userInfo: any;
	public emailDuplicated: boolean;
	public stDuplicated: boolean;
	public canSaveBatch: boolean;

	public updateGroupState: boolean;
	public updateGroupFinishDialog: boolean;
	public appointGroupFinishDialog: boolean;

	public myGroups: {
		code: string, //  = 'wSGOHb1P'
		createdDatetime: string, //  = '2024-06-13T04:54:21'
		name: string, //  '네오랩'
		ownerCode: string, // 'hPMqXBvV'
		updatedDatetime: string, //'2024-06-13T04:54:21'
		exist?: string, // 'Y' or 'N'
	}[];

	public get myGroupCount(): number {
		return this.myGroups?.length || 0;
	}

	public lastSearchKeyword: string;



	constructor(props: Props) {
		const ps = props;
		const { userRepository, authStore } = ps;
		this.userRepository = userRepository;
		this.authStore = authStore;
		this.users = [];
		this.userProfileGroupTransfers = [];

		this.groupTransfers = [];

		this.searchedUsers = [];
		this.searchedGroups = [];
		this.modifyUser = Object.assign({}, EmptyUser);
		this.modifyUserBirthYear = "";
		this.modifyUserBirthMonth = "";
		this.modifyUserBirthDay = "";

		this.userProfile = Object.assign({}, EmptyUserProfile);

		this.isSearchLoading = false;
		this.searchKeyword = "";
		this.searchIntervalState = undefined;

		this.myUsers = [];
		this.myUserCount = 0;
		this.mySchools = [];

		this.checkedEmails = []; // 중복 체크 받고온 리스트. email + 결과(중복 여부)
		this.sidoList = []; // 전체 시도 리스트
		this.siGunGooList = []; // 시도 선택에 따른 시군구 리스트
		this.checkedStNumList = []; // 학년 반 번호 중복 체크 받고난 이후의 리스트.
		this.myGroupsForConfig = [];
		this.myGroupsTotalCount = 0;
		this.myGroupsSaveData = []; // 그룹 설정 에서 이름 변경 또는 추가된 데이터들 즉, DB에 반영 해야 되는 데이터들이 들어 간다.

		this.myUserLoading = false;
		this.checkEmailLoading = false;
		this.backdropOpen = false;
		this.saveComplete = false; // 저장이 성공 했을 때만 true.
		this.groupConfigLoading = false;
		this.userInfo = undefined; // 정보 수정때 사용
		this.emailDuplicated = false; // 하나라도 중복인지 -> 일괄 등록에서 사용
		this.stDuplicated = false; // 하나라도 중복인지 -> 일괄 등록에서 사용
		this.canSaveBatch = false;

		this.updateGroupState = undefined; // 성공시 true 실패시 false -> 안내 메세지용
		this.updateGroupFinishDialog = false; // 안내메세지 띄울 다이얼로그
		this.appointGroupFinishDialog = false; // 안내메세지 띄울 다이얼로그

		makeAutoObservable(this);
	}

	initCheckedDatas() {
		this.emailDuplicated = false;
		this.checkedEmails = [];
		this.checkedStNumList = [];
		this.stDuplicated = false;
		this.initCanSaveBatch();
	}

	initBatchCheckResult() {
		this.emailDuplicated = false;
		this.stDuplicated = false;
	}

	initCanSaveBatch() {
		this.canSaveBatch = false;
	}

	initUserInfo() {
		this.userInfo = undefined;
		this.initCheckedEmails();
		this.sidoList = []; // 전체 시도 리스트
		this.siGunGooList = []; // 시도 선택에 따른 시군구 리스트
		this.initCheckedStNums();
		this.initCheckedDatas();
	}

	init() {
		this.users = [];

		this.myUsers = [];
		this.mySchools = [];

		this.searchedUsers = [];
		this.searchedGroups = [];

		this.isSearchLoading = false;
		this.searchKeyword = "";
		this.searchIntervalState = undefined;
		this.modifyUser = Object.assign({}, EmptyUser);
		this.modifyUserBirthYear = "";
		this.modifyUserBirthMonth = "";
		this.modifyUserBirthDay = "";
		this.userProfile = Object.assign({}, EmptyUserProfile);
		this.myUserCount = 0;

		this.myGroups = [];
		// this.myGroupCount = 0;
	};

	initCheckedEmails() {
		this.checkedEmails = [];
	}

	initCheckedStNums() {
		this.checkedStNumList = [];
		this.initCanSaveBatch();
	}

	initMyUsers() {
		this.myUsers = [];
		this.myUserCount = 0;
	}

	initMyGroups() {
		this.myGroups = [];
		// this.myGroupCount = 0;
	}

	initUserProfileGroupTransfers() {
		this.userProfileGroupTransfers = [];
	};

	initUserProfile() {
		this.userProfile = Object.assign({}, EmptyUserProfile);
	};
	initModifyUser() {
		this.modifyUser = Object.assign({}, EmptyUser);
	};

	// code 를 가지고있는 대상만 골라서 없앨것
	deleteCodeInGroupSaveData() {
		this.myGroupsSaveData = this.myGroupsSaveData.filter(preGroup => preGroup.code === null);
	}

	updateGroupFinishDialogClose() {
		this.updateGroupFinishDialog = false;
	}

	appointGroupFinishDialogClose() {
		this.appointGroupFinishDialog = false;
	}

	// type = name || save
	setMyGroupsSaveData(group, type) {
		const data = {
			...group,
			type: type
		}

		// code 가 있는 녀석만 이름 수정이 가능 하고, 이떄 중복은 제거되어야 함.
		if (group.code) {
			this.myGroupsSaveData = this.myGroupsSaveData.filter(preGroup => preGroup.code !== group.code);
		}

		this.myGroupsSaveData.push(data);
	}

	deleteAddedGroup(group) {
		let find = false;
		const newData = this.myGroupsSaveData.filter(preGroup => {
			if (!find && preGroup.name === group.name) {
				find = true;
				return false;
			}
			return true;
		})

		this.myGroupsSaveData = newData;

		console.log("this.myGroupsSaveData : ", this.myGroupsSaveData);
	}

	changeModifyUser() {
		this.changeModifyUserName(this.userProfile.name);
		this.changeModifyUserGender(this.userProfile.gender);
		this.changeModifyUserBirth(this.userProfile.birth);
		this.changeModifyUserOrganization(this.userProfile.organization);
		this.changeModifyUserAvatarImageObject(this.userProfile.avatarImageObject);
		if (
			this.userProfile.birth &&
			!isNaN(dayjs(this.userProfile.birth).year())
		) {
			this.changeModifyUserBirthYear(dayjs(this.userProfile.birth).year());
			this.changeModifyUserBirthMonth(
				dayjs(this.userProfile.birth).month() + 1
			);
			this.changeModifyUserBirthDay(dayjs(this.userProfile.birth).date());
		} else {
			this.changeModifyUserBirthYear("");
			this.changeModifyUserBirthMonth("");
			this.changeModifyUserBirthDay("");
		}
	};

	changeModifyUserGender(gender: string) {
		this.modifyUser.gender = gender;
	};
	changeModifyUserOrganization(organization: string) {
		this.modifyUser.organization = organization;
	};
	changeModifyUserName(name: string) {
		this.modifyUser.name = name;
	};
	changeModifyUserBirth(birth: string) {
		this.modifyUser.birth = birth;
	};
	changeModifyUserAvatarImageObject(avatarImageObject: any) {
		this.modifyUser.avatarImageObject = avatarImageObject;
	};

	changeModifyUserBirthYear(birthYear) {
		this.modifyUserBirthYear = birthYear;
	};
	changeModifyUserBirthMonth(birthMonth) {
		this.modifyUserBirthMonth = birthMonth;
	};
	changeModifyUserBirthDay(birthDay) {
		this.modifyUserBirthDay = birthDay;
	};

	settingForModifyDialog(emailData, stNumData) {
		this.checkedEmails.push(emailData);
		this.checkedStNumList.push(stNumData);
	}

	settingCheckedEmails(emailData) {
		this.checkedEmails.push(emailData);
	}

	saveCompleteClose() {
		this.saveComplete = false;
	}

	async deleteUser(userId: string) {
		console.log(LogPrefix, "Start deleteUser ...");
		try {
			await this.userRepository.deleteUser(userId);
			console.log(LogPrefix, "Success deleteUser ...");
		} catch (e) {
			console.log(LogPrefix, "Failed deleteUser ...", e);
		}
	}

	async getUserProfile(code: string) {
		try {
			const ret = await this.userRepository.getUserProfile(code);
			// this.changeModifyUserName(this.userProfile.name);
			// this.changeModifyUserGender(this.userProfile.gender);
			// this.changeModifyUserBirth(this.userProfile.birth);
			// this.changeModifyUserOrganization(this.userProfile.organization);
			// this.changeModifyUserAvatarImageObject(this.userProfile.avatarImageObject);
			// this.changeModifyUserBirthYear(dayjs(this.userProfile.birth).year());
			// this.changeModifyUserBirthMonth(dayjs(this.userProfile.birth).month()+1);
			// this.changeModifyUserBirthDay(dayjs(this.userProfile.birth).date());

			runInAction(() => {
				this.userProfile = ret;
				this.changeModifyUser();
			});

			// console.log(LogPrefix, "userProfile", this.userProfile);
		} catch (e) {
			// console.log(LogPrefix, "Cannot get User Profile ...", e);
		}
	}

	searchUsers(keyword) {
		this.isSearchLoading = true;
		this.users = [];
		this.searchKeyword = keyword;
		if (
			this.searchIntervalState === undefined ||
			this.searchIntervalState === null
		) {
			console.log(LogPrefix, "Starting SearchInterval ...");
			this.searchIntervalState = setInterval(
				() => this.getUsers(),
				searchingInterval
			);
		} else {
			console.log(LogPrefix, "SearchInterval already started ...");
		}
	};

	// * getAllUsers() {
	// 	console.log(LogPrefix, "Start getAllUsers...");
	// 	try {
	// 		this.users = await  this.userRepository.getAllUsers();
	// 		console.log(LogPrefix, "Success getAllUsers...", this.users);
	// 	} catch (e) {
	// 		console.log(LogPrefix, "Failed getAllUsers...", e);
	// 	}
	// }

	// * getUserProfileGroupTransfers(loginId: string) {
	// 	console.log(LogPrefix, "Start getUserProfileGroupTransfers...");
	// 	try {
	// 		this.userProfileGroupTransfers =
	// 			await  this.userRepository.getUserProfileGroupTransfers(loginId);
	// 		console.log(
	// 			LogPrefix,
	// 			"Success getUserProfileGroupTransfers...",
	// 			this.userProfileGroupTransfers
	// 		);
	// 	} catch (e) {
	// 		console.log(LogPrefix, "Failed getUserProfileGroupTransfers...", e);
	// 	}
	// }
	// * getGroupTransfersByCreatorId(loginId: string) {
	// 	console.log(LogPrefix, "Start getGroupTransfersByCreatorId...");
	// 	try {
	// 		this.groupTransfers =
	// 			await  this.userRepository.getGroupTransfersByCreatorId(loginId);
	// 		console.log(
	// 			LogPrefix,
	// 			"Success getGroupTransfersByCreatorId...",
	// 			this.groupTransfers
	// 		);
	// 	} catch (e) {
	// 		console.log(LogPrefix, "Failed getGroupTransfersByCreatorId...", e);
	// 	}
	// }

	async getUsers() {
		if (this.searchKeyword === "") {
			this.lastSearchKeyword = "";
			// console.log(LogPrefix, "Clear SearchInterval ...");
			clearInterval(this.searchIntervalState);
			this.searchIntervalState = undefined;
			this.isSearchLoading = false;
		} else {
			if (this.searchKeyword !== this.lastSearchKeyword) {
				try {
					this.lastSearchKeyword = this.searchKeyword;

					const param = { keyword: this.lastSearchKeyword };
					const users = await this.userRepository.getUsers(param);
					const groups = await this.userRepository.getGroups(param);

					runInAction(() => {
						this.searchedUsers = users;
						this.searchedGroups = groups;

						this.isSearchLoading = false;
					});
					console.log(LogPrefix, "users", users);
				} catch (error) {
					console.log(LogPrefix, "Cannot Search Teams or Users ...", error);
				}
			} else {
				// console.log(LogPrefix, "Clear SearchInterval ...");
				clearInterval(this.searchIntervalState);
				this.searchIntervalState = undefined;
				this.lastSearchKeyword = "";
				this.isSearchLoading = false;
			}
		}
	}

	async getMyUsersByParams(userCode: string, groupCode: string, keyword: string, page: any, rowsPerPage: number) {
		try {
			const params: { groupCode?: string, keyword?: string, page?: any, rowsPerPage?: number } = {};

			if (groupCode) params.groupCode = groupCode;
			if (keyword) params.keyword = keyword;
			if (page) params.page = page;
			if (rowsPerPage) params.rowsPerPage = rowsPerPage;

			console.log("start getMyUsers...", params);
			let response = await this.userRepository.getMyUsersByParams(userCode, params);
			console.log(LogPrefix, "Success getMyUsers...", response);
		} catch (e) {
			console.log(LogPrefix, "Failed getMyUsers...", e);
		}
	}

	async getMyUsers(groupCode: string, keyword: string, page: any, rowsPerPage: number, sortingHints: any) {
		try {

			let params: { groupCode?: string, keyword?: string, page?: any, rowsPerPage?: number } = {
				page: page,
				rowsPerPage: rowsPerPage
			};
			if (groupCode) params.groupCode = groupCode;
			if (keyword) params.keyword = keyword;

			if (rowsPerPage) params.rowsPerPage = rowsPerPage;
			let data = sortingHints;

			console.log("start getMyUsers table...", params);
			let response = await this.userRepository.getMyUsers(params, data);
			console.log(LogPrefix, "Success getMyUsers table...", this.myUsers, this.myUserCount);

			runInAction(() => {
				this.myUsers = response.users;
				this.myUserCount = response.totalCount;
			});
		} catch (e) {
			console.log(LogPrefix, "Failed getMyUsers table...", e);
		}
	}

	async getMyUsersForEvaluation() {
		try {
			console.log("start getMyUsersForEvaluation...");
			let response = await this.userRepository.getMyUsersForEvaluation();
			this.myUsers = response;
			console.log(LogPrefix, "Success getMyUsersForEvaluation...", this.myUsers);
		} catch (e) {
			console.log(LogPrefix, "Failed getMyUsersForEvaluation...", e);
		}
	}

	async getMyGroups() {
		try {
			console.log("start getMyGroups...");
			let groups = await this.userRepository.getGroups({});
			console.log(LogPrefix, "Success getMyGroups", groups);
			runInAction(() => {
				this.myGroups = groups; // 학생 관리 메인 에서 셀렉트 박스에 쓰임
			});
		} catch (e) {
			console.log(LogPrefix, "Failed getMyGroups", e);
		}
	}

	async getMySchools() {
		try {
			console.log("start getMySchools...");
			let response = await this.userRepository.getMySchools();
			console.log(LogPrefix, "Success getMySchools...", response);

			runInAction(() => {
				this.mySchools = response;
			});
		} catch (e) {
			console.log(LogPrefix, "Failed getMySchools...", e);
		}
	}


	* getMyUsersForManage(groupCode: string, keyword: string, page: any, rowsPerPage: number, sortingHints: any) {
		try {
			this.myUserLoading = true;

			let params: { page?: any, rowsPerPage?: number, groupCode?: string, keyword?: string } = {
				page: page,
				rowsPerPage: rowsPerPage
			};
			if (groupCode) params.groupCode = groupCode;
			if (keyword) params.keyword = keyword;

			if (rowsPerPage) params.rowsPerPage = rowsPerPage;
			let data = sortingHints;

			// console.log("start getMyUsersForManage...",params, data);
			// console.log("this.userRepository : ", this.userRepository);
			const response = yield this.userRepository.getMyUsersForManage(params, data);
			const groups = yield this.userRepository.getGroups({});

			this.myGroups = groups; // 학생 관리 메인 에서 셀렉트 박스에 쓰임

			this.myGroups.forEach(group => group.exist = 'Y');

			this.myUsers = response.users;
			this.myUserCount = response.totalCount;
			console.log(LogPrefix, "Success getMyUsersForManage...", this.myUsers, this.myUserCount);
		} catch (e) {
			console.log(LogPrefix, "Failed getMyUsersForManage...", e);
		} finally {
			this.myUserLoading = false;
		}
	}

	async checkDuplicatedEmail(email) {
		try {
			this.checkEmailLoading = true;
			const param = {
				email: email
			}
			const response = await this.userRepository.checkDuplicatedEmail(param);

			runInAction(() => {
				if (response) {
					this.authStore.changeExistEmailState(true);
				} else {
					this.authStore.changeExistEmailState(false);
				}
			});
			console.log(LogPrefix, "success checkEmails...", !!response);
		} catch (e) {
			console.log(LogPrefix, "Failed checkEmails...", e);
			runInAction(() => {
				this.authStore.changeExistEmailState(true);
			});
		} finally {
			runInAction(() => {
				this.checkEmailLoading = false;
			});
		}
	}

	async checkEmailAccountExist(email: string) {
		try {
			this.checkEmailLoading = true;
			const param = {
				email: email
			}
			const response = await this.userRepository.checkDuplicatedEmail(param);
			if (response) {
				return true;
			} else {
				return false;
			}
		} catch (e) {
			console.log(LogPrefix, "Failed checkEmails...", e);
			return false;
		}
	}



	async checkEmails(emails: string[], batch?: boolean, datas?: any) {
		try {
			this.checkEmailLoading = true;
			if (batch) {
				this.backdropOpen = true;
				this.canSaveBatch = false; // 시작할때 항상 트루
			}

			const data = emails.map(email => email + accountEmailAddress);
			const response = await this.userRepository.checkEmails(data);

			runInAction(async () => {
				this.checkedEmails = response;
				this.checkedEmails.forEach(data => {
					const index = data.email.indexOf('@');
					data.email = data.email.substring(0, index);
					if (data.duplicate) {
						this.emailDuplicated = true;
					}
				})
				console.log(LogPrefix, "Success checkEmails...", response);

				if (batch) {
					const ret = await this.userRepository.checkStNum(datas);

					runInAction(() => {
						this.checkedStNumList = ret;
						this.checkedStNumList.forEach(data => {
							if (data.stNumDuplicate) {
								this.stDuplicated = true;
							}
						})
						console.log(LogPrefix, "Success checkStNum...", this.checkedStNumList);
					});
				}

				if (batch) {
					if (this.emailDuplicated === false && this.stDuplicated === false) {
						// 저장 가능한 상태
						this.canSaveBatch = true;
					}
				}
			});

		} catch (e) {
			console.log(LogPrefix, "Failed checkEmails...", e);
			this.checkedEmails = [];
		} finally {
			this.checkEmailLoading = false;
			this.backdropOpen = false;
		}
	}

	async getUserInfo(userCode, type) {
		try {
			this.backdropOpen = true;
			console.log(LogPrefix, "getUserInfo Start - userCode : ", userCode);

			const ret = await this.userRepository.getUserInfo(userCode);
			runInAction(() => {
				this.userInfo = ret;
			});

			console.log(LogPrefix, "Success getUserInfo");
		} catch (e) {
			console.log(LogPrefix, "Failed getUserInfo...", e);
		} finally {
			runInAction(() => {
				this.backdropOpen = false;
			});
		}
	}

	getSido = async (args?: {
		setFirstFn?: (first: boolean) => void,
		region: string
	}) => {
		const { setFirstFn = null, region = "KR" } = args || {};
		try {
			if (region === 'KR') {
				const data = await this.getSidoApi();
				console.log(LogPrefix, "Success getSido...", data);
				runInAction(() => {
					this.sidoList = data.body.items;
				});
			} else if (region === "JP") {
				this.sidoList = [
					{
						ctprvnCd: "100",
						ctprvnNm: "日本",
						stdrDt: "2024-01-01"
					}
				]
			}
			// throw new Error("이것은 테스트 에러입니다.");
		} catch (e) {
			console.log(LogPrefix, "Failed getSido...", e);
			runInAction(() => {
				this.sidoList = [];
				this.backdropOpen = false;
				setFirstFn?.(false);
			});
		}
	}

	async getSiGunGoo(args: { ctprvnCd: string, region: string }) {
		const { ctprvnCd, region = "KR" } = args;

		try {
			this.backdropOpen = true;

			if (region === 'KR') {
				const data = await this.getSiGunGooApi(ctprvnCd);
				console.log(LogPrefix, "Success getSiGunGoo...", data);

				runInAction(() => {
					this.siGunGooList = data.body.items as ISiGunGoo[];
				});
			} else if (region === "JP") {
				this.siGunGooList = [{
					ctprvnCd: "10000",
					ctprvnNm: "日本",
					signguCd: "日本",
					signguNm: "日本",
					stdrDt: "2024-01-01"
				}]
			}

		} catch (e) {
			console.log(LogPrefix, "Failed getSiGunGoo...", e);
			runInAction(() => {
				this.siGunGooList = [];
			});
		} finally {
			runInAction(() => {
				this.backdropOpen = false;
			});
		}
	}

	checkStNum = async (datas:
		{
			city: string; // "DEFAULT"
			country: string; // "DEFAULT"
			schoolType: string; // "DEFAULT"
			schoolName: string; // "네오랩"
			grade: string; // "1"
			className: string; // "1"
			number: string; // "1"
		}[]
	) => {
		try {
			this.backdropOpen = true; // 백드롭

			const response = await this.userRepository.checkStNum(datas);
			console.log(LogPrefix, "Success checkStNum...", response);

			runInAction(() => {
				this.checkedStNumList = response;
			});
		} catch (e) {
			console.log(LogPrefix, "Failed checkStNum...", e);
			runInAction(() => {
				this.checkedStNumList = [];
			});
		} finally {
			runInAction(() => {
				this.backdropOpen = false;
			});
		}
	}

	async saveMyUsers(data: IStudentInfoForSave[]) {
		try {
			this.backdropOpen = true; // 백드롭

			data.forEach(data => data.email = data.email + accountEmailAddress);
			const copied = data.map((item) => {
				const c = { ...item };
				if (c.schoolType === "DEFAULT") {
					c.schoolType = String(ProjectSchoolType.NOGRADE);
				}

				return c;
			});


			const response = await this.userRepository.saveMyUsers(copied);
			console.log(LogPrefix, "Success saveMyUsers...", response);
			runInAction(() => {
				if (response === 'COMPLETE') {
					this.saveComplete = true; // 완료 다이얼로그 오픈 및 확인 누를시 다이얼로그 전체 닫힘
					this.initCheckedEmails();
					this.initCheckedStNums();
				}
			});
			// this.checkedStNumList = response;
		} catch (e) {
			console.log(LogPrefix, "Failed saveMyUsers...", e);
			// this.checkedStNumList = [];
		} finally {
			runInAction(async () => {
				const ret = await this.userRepository.getGroups({}); // 그룹이 추가 되었을 경우를 가정 해서, 다시 가져 온다.
				runInAction(() => {
					this.myGroups = ret;
				});
				this.backdropOpen = false;
			});
		}
	}

	async modifyMyUsers(datas) {
		try {
			this.backdropOpen = true; // 백드롭

			datas.forEach(data => data.email = data.email + accountEmailAddress);

			const response = await this.userRepository.modifyMyUsers(datas);
			console.log(LogPrefix, "Success modifyMyUsers...", response);

			runInAction(() => {
				if (response === 'COMPLETE') {
					this.saveComplete = true; // 완료 다이얼로그 오픈 및 확인 누를시 다이얼로그 전체 닫힘
					this.initCheckedEmails();
					this.initCheckedStNums();
				}
			});
			// this.checkedStNumList = response;
		} catch (e) {
			console.log(LogPrefix, "Failed modifyMyUsers...", e);
			// this.checkedStNumList = [];
		} finally {
			runInAction(async () => {
				this.backdropOpen = false;
				const ret = await this.userRepository.getGroups({}); // 그룹이 추가 되었을 경우를 가정 해서, 다시 가져 온다.

				runInAction(() => {
					this.myGroups = ret;
				});
			});
		}
	}

	async saveMyGroups() {
		try {
			this.backdropOpen = true; // 백드롭

			const response = await this.userRepository.saveMyGroups(this.myGroupsSaveData);
			console.log(LogPrefix, "Success saveMyGroups...", response);
			runInAction(() => {
				this.updateGroupState = true;
			});
		} catch (e) {
			console.log(LogPrefix, "Failed saveMyGroups...", e);
			runInAction(() => {
				this.updateGroupState = false;
			});
		} finally {
			runInAction(async () => {
				this.updateGroupFinishDialog = true;
				this.myGroupsSaveData = [];

				const ret = await this.userRepository.getGroups({}); // 학생 관리 메인 에서 셀렉트 박스에 쓰이고, 그룹지정때도 쓰이므로 다시 가져온다.

				runInAction(() => {
					this.myGroups = ret;
					this.myGroups.forEach(group => group.exist = 'Y');
					this.backdropOpen = false;
				});
			});
		}
	}

	// 백엔드 로직은 일괄 추가를 대비하여 구성하였으므로, 배열로 보낸다.
	async groupAppoint(groupList, selectedUser) {
		try {
			this.backdropOpen = true; // 백드롭

			const userCode = selectedUser.userCode;
			console.log("userCode : ", userCode);
			const data = {
				code: userCode,
				myGroups: groupList
			}
			const response = await this.userRepository.appointGroup([data]);
			console.log(LogPrefix, "Success groupAppoint...", response);

			runInAction(() => {
				this.updateGroupState = true;
			});
		} catch (e) {
			console.log(LogPrefix, "Failed groupAppoint...", e);
			runInAction(() => {
				this.updateGroupState = false;
			});
		} finally {
			runInAction(async () => {
				this.appointGroupFinishDialog = true;

				const ret = await this.userRepository.getGroups({}); // 학생 관리 메인 에서 셀렉트 박스에 쓰이고, 그룹지정때도 쓰이므로 다시 가져온다.

				runInAction(() => {
					this.myGroups = ret;
					this.myGroups.forEach(group => group.exist = 'Y');
					this.backdropOpen = false;
				});
			});
		}
	}

	async groupAllClear(selectedUser) {
		try {
			this.backdropOpen = true; // 백드롭

			const userCode = selectedUser.userCode;
			console.log("userCode : ", userCode);

			const response = await this.userRepository.allClearGroup([userCode]);
			console.log(LogPrefix, "Success groupAllClear...", response);
			runInAction(() => {
				this.updateGroupState = true;
			});
		} catch (e) {
			console.log(LogPrefix, "Failed groupAllClear...", e);
			runInAction(() => {
				this.updateGroupState = false;
			});
		} finally {
			runInAction(async () => {
				this.appointGroupFinishDialog = true;

				const ret = await this.userRepository.getGroups({}); // 학생 관리 메인 에서 셀렉트 박스에 쓰이고, 그룹지정때도 쓰이므로 다시 가져온다.

				runInAction(() => {
					this.myGroups = ret;
					this.myGroups.forEach(group => group.exist = 'Y');
					this.backdropOpen = false;
				});
			});
		}
	}

	async getGroupListForConfig(page, rowsPerPage) {
		try {
			this.groupConfigLoading = true;
			let params = {
				page: page,
				rowsPerPage: rowsPerPage
			};
			const response = await this.userRepository.getGroupsForConfig(params);
			console.log(LogPrefix, "Success getGroupListForConfig");
			runInAction(() => {
				this.myGroupsForConfig = response.myGroupList;
				this.myGroupsTotalCount = response.totalCount;
			});
		} catch (e) {
			console.log(LogPrefix, "Failed getGroupListForConfig...", e);
		} finally {
			runInAction(() => {
				this.groupConfigLoading = false;
			});
		}


	}

	getSidoApi = (): Promise<ISidoListResponse> => {
		return new Promise((resolve, reject) => {
			const url = 'https://apis.data.go.kr/B553077/api/open/sdsc2/baroApi';
			const params = {
				resId: 'dong',
				catId: 'mega',
				ServiceKey: 'zYEaMOYNzlc4+p4n48ZhTrlissFGY+ToxPhQx8kNFDiKaAEgx48yNvYC52KZNRVfEbkNcWpQeDi2MI2RJGimng==', // URL 디코딩된 서비스키
				type: 'json'
			}

			axios.get(url, { params })
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		})
	}

	getSiGunGooApi = (ctprvnCd: string): Promise<ISiGunGooListResponse> => {
		return new Promise((resolve, reject) => {
			const url = 'https://apis.data.go.kr/B553077/api/open/sdsc2/baroApi';
			const params = {
				resId: 'dong',
				catId: 'cty',
				ServiceKey: 'zYEaMOYNzlc4+p4n48ZhTrlissFGY+ToxPhQx8kNFDiKaAEgx48yNvYC52KZNRVfEbkNcWpQeDi2MI2RJGimng==', // URL 디코딩된 서비스키
				type: 'json',
				ctprvnCd: ctprvnCd
			}

			axios.get(url, { params })
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		})
	}

	async addMyUsers(newUsers) {
		try {
			console.log("start getMyUsers...");
			let response = await this.userRepository.addMyUsers(newUsers);
			console.log(LogPrefix, "Success getMyUsers...", response);
		} catch (e) {
			console.log(LogPrefix, "Failed getMyUsers...", e);
		}
	}

	// * getMyGroups() {
	// 	try {
	// 		console.log("start getMyGroups...");
	// 		let groups = await  this.userRepository.getMyGroups();
	// 		this.myGroups = groups;
	// 		console.log(LogPrefix, "Success getMyGroups...", groups);
	// 	} catch (e) {
	// 		console.log(LogPrefix, "Failed getMyGroups...", e);
	// 	}
	// }

	async doSignUp() {
		try {
			console.log(LogPrefix, "start Sign Up...");

			const { signUp } = this.authStore;
			const { email, password, name, furigana, lastName, firstName, lastFurigana, firstFurigana } = signUp;

			const data = {
				email, password, nickname: name, type: "Admin",
				furigana, lastName, firstName, lastFurigana, firstFurigana,
			};
			// const data = {
			// 	email: this.authStore.signUp.email,
			// 	password: this.authStore.signUp.password,
			// 	nickname: this.authStore.signUp.name,

			// 	furigana: this.auto
			// };
			const response = await this.userRepository.doSignUp(data);
			console.log(LogPrefix, "doSignUp Success!! result : ");
			return true;
		} catch (e) {
			console.log(LogPrefix, "doSignUp failed!! result : ", e);
			return false;
		}
	}


}
