import { DotNetApi } from "../nl-lib-maui";
import { connectMauiBridgeSocket, initMauiToJsBridge } from "../nl-lib-maui/maui-bridge/init";
import { MauiRelaySocket } from "../nl-lib-maui/maui-ws-relay/maui-relay-websocket";


export const DeeplinkPopupVisible = {
	visible: true,
}



type ApplinkReturnType = { installed: boolean, launched: boolean };

let timer = 0;

/**
 * 앱을 딥 링크로 실행하고, 일정 시간 대기 후 앱이 실행되었는지 여부를 반환
 *
 * ```typescript
		openAppWithDeepLink({
				appScheme: `${appUrl}://`,
				fallbackUrl: '/download',
				timeOut: 10000,
				shouldLaunchDeepLink: () => {
					const info = getBrowserAndDeviceInfo();
					if (info.os === OperatingSystemInfo.Windows) return true;
					return false;
				},
				shouldRedirect: () => {
					const info = getBrowserAndDeviceInfo();
					if (info.os === OperatingSystemInfo.Windows) return true;
					return false;
				}
			}).then((result) => {
				console.log(`App installed: ${result.installed}, App launched: ${result.launched}`);
			});
 * ```
 *
 *
 */
export function openAppWithDeepLink(args: {
	appScheme: string,
	fallbackUrl: string,
	timeOut: number,
	shouldLaunchDeepLink?: boolean;
	shouldRedirect?: boolean;
}): Promise<ApplinkReturnType> {
	const { appScheme, fallbackUrl, timeOut, shouldLaunchDeepLink, shouldRedirect } = args;
	let hasAppOpened = false;

	return new Promise(async (resolve) => {
		// initialConnectMauiBridgeSocket().then((connected) => {
		// 	if (connected) {
		// 		resolve({ installed: true, launched: true });
		// 	}

		let isWebSocketConnected = false;
		isWebSocketConnected = MauiRelaySocket.instance.connected;
		if (isWebSocketConnected) {
			resolve({ installed: true, launched: true });
			return;
		}

		const launchDeepLink = shouldLaunchDeepLink ? shouldLaunchDeepLink : true;

		// deep link를 띄울 수 없는 환경이라면 바로 리턴
		if (!launchDeepLink) {
			resolve({ installed: false, launched: false });
			return;
		}

		// 딥 링크 URL을 만듦
		const appLink = document.createElement('a');
		appLink.href = appScheme;
		appLink.style.display = 'none';
		document.body.appendChild(appLink);

		// 링크 클릭하여 앱 실행 시도
		appLink.click();

		console.log(`Trying to launch app with ${appScheme}`);

		connectMauiBridgeSocket(10).then((connected) => {
			isWebSocketConnected = connected;
		});


		// 딥 링크 시도 후 일정 시간 대기
		if (timer) {
			window.clearTimeout(timer);
			timer = 0;
		}


		timer = window.setTimeout(async () => {
			await initMauiToJsBridge();
			const connected = MauiRelaySocket.instance.connected;

			// 딥 링크 URL이 제거된 후 앱이 실행되었는지 여부 확인
			document.body.removeChild(appLink);

			if (!connected && !hasAppOpened && !isWebSocketConnected) {
				// 앱이 설치되어 있지 않은 것으로 판단
				const should = shouldRedirect ? shouldRedirect : true;
				if (should) {
					window.location.href = fallbackUrl;
				}
				resolve({ installed: false, launched: false });
			} else {
				// 앱이 실행된 경우
				console.log(`Native app launched with ${appScheme}`);
				resolve({ installed: true, launched: true });
			}
		}, timeOut);
	});
}

let connectionTrial = {

	interval: null as NodeJS.Timeout,
	resolveFn: null as (args: { installed: boolean, launched: boolean }) => void,
}

async function tryToConnectWebSocketLoop(resolveFn: (args: { installed: boolean, launched: boolean }) => void) {
	// 이전의 promise는 reject에 준하는 return
	if (connectionTrial.interval) {
		clearInterval(connectionTrial.interval);
		connectionTrial.resolveFn({ installed: false, launched: false });
		connectionTrial.interval = null;
	}

	connectionTrial.resolveFn = resolveFn;
	connectionTrial.interval = setInterval(async () => {
		const connected = await DotNetApi.instance.connectWebSocket();
		if (connected) {
			resolveFn({ installed: true, launched: true });
			clearInterval(connectionTrial.interval);
			return;
		}
	}, 1000);
}

export function openAppWithDeepLinkOnly(args: {
	appScheme: string,
	// fallbackUrl: string,
	// timeOut: number,
	// shouldLaunchDeepLink?: boolean;
	// shouldRedirect?: boolean;
}): Promise<ApplinkReturnType> {
	const { appScheme } = args;


	return new Promise(async (resolve) => {
		const onBlurWindow = () => {
			console.log("deeplink: Window lost focus");
			DeeplinkPopupVisible.visible = true;
		}

		const onFocusWindow = async () => {
			console.log("deeplink: Window gained focus");
			DeeplinkPopupVisible.visible = false;

			window.removeEventListener("blur", onBlurWindow);
			window.removeEventListener("focus", onFocusWindow);

			const connected = await DotNetApi.instance.connectWebSocket();
			if (connected) {
				resolve({ installed: true, launched: true });
				return;
			}
			resolve({ installed: false, launched: false });

			// 시간 Promise가 call back임을 이용한 꼼수
			tryToConnectWebSocketLoop(resolve);
		}

		let isWebSocketConnected = false;
		isWebSocketConnected = MauiRelaySocket.instance.connected;
		if (isWebSocketConnected) {
			resolve({ installed: true, launched: true });
			return;
		}

		window.addEventListener("blur", onBlurWindow);
		window.addEventListener("focus", onFocusWindow);

		// 딥 링크 URL을 만듦
		const appLink = document.createElement('a');
		appLink.href = appScheme;
		appLink.style.display = 'none';
		document.body.appendChild(appLink);

		// 링크 클릭하여 앱 실행 시도
		appLink.click();
		document.body.removeChild(appLink);
	});
}
