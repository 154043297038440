import { assert } from "./functions";
import { FzBuffer } from "./FzBuffer";
import { new_outline } from "./Outline";
import { FzPage } from "./FzPage";
import { PdfPage } from "./PdfPage";
import { FzStream } from "./FzStream";
import { Wrapper } from "./Wrapper";
import { NcodeFont } from "./NcodeFont";

// platform/java/src/com/artifex/mupdf/fitz/Document.java
export class FzDocument extends Wrapper {
	buffer: FzBuffer;

	ncodeFont: NcodeFont = null;

	numPages = null as number | null;

	pages: (FzPage | PdfPage)[] = null;

	constructor(libmupdf: any, ctx: number, pointer: number, buffer: FzBuffer = null) {
		const freeFunc = (ctx2, pointer2) => {
			// console.log(`loadpage, drop_document: ctxPtr: ${ctx2}, fzDocPtr: ${pointer2}`);
			libmupdf._wasm_drop_document(ctx2, pointer2)
		}

		super(libmupdf, ctx, pointer, freeFunc);
		// If this document is built from a buffer, we keep a
		// reference so it's not garbage-collected before the document
		this.buffer = buffer;

		if (pointer) {
			this.numPages = this.countPages();
			this.pages = new Array<FzPage | PdfPage>(this.numPages);
		}
	}

	override async free() {
		await super.free();
		await this.buffer?.free();
		for (let i = 0, ln = this.pages?.length || 0; i < ln; i++) {
			const page = this.pages[i];
			if (page) {
				await page.free();
			}
			this.pages[i] = null;
		}
		this.pages = null;
		//this.stream?.free();
	}


	// recognize
	// needsPassword
	// authenticatePassword

	// resolveLink

	// getMetaData(String key);
	// setMetaData(String key, String value);

	// isReflowable
	// layout

	// isPDF

	static openFromJsBuffer(libmupdf: any, ctx: number, buffer: ArrayBuffer, magic: string): FzDocument {
		const fzBuffer = FzBuffer.fromJsBuffer(libmupdf, ctx, buffer);
		return FzDocument.openFromBuffer(libmupdf, ctx, fzBuffer, magic);
	}

	static openFromBuffer(libmupdf: any, ctx: number, buffer: FzBuffer, magic: string): FzDocument {
		assert(buffer instanceof FzBuffer, "invalid buffer argument");
		assert(typeof magic === "string", "invalid magic argument");

		const pointer = libmupdf.ccall(
			"wasm_open_document_with_buffer",
			"number",
			["number", "number", "string"],
			[ctx, buffer.pointer, magic]
		);


		const doc = new FzDocument(libmupdf, ctx, pointer, buffer);
		const numPages = doc.countPages();
		doc.numPages = numPages;
		doc.pages = new Array<FzPage | PdfPage>(numPages);

		return doc;
	}

	static openFromStream(libmupdf: any, ctx: number, stream: FzStream, magic: string): FzDocument {
		assert(stream instanceof FzStream, "invalid stream argument");
		assert(typeof magic === "string", "invalid magic argument");

		const pointer = libmupdf.ccall(
			"wasm_open_document_with_stream",
			"number",
			["number", "number", "string"],
			[ctx, stream.pointer, magic]
		);
		// TODO - Add reference to stream.
		const doc = new FzDocument(libmupdf, ctx, pointer);
		const numPages = doc.countPages();
		doc.numPages = numPages;
		doc.pages = new Array<FzPage | PdfPage>(numPages);

		return doc;
	}

	static openFromStreamPointer(libmupdf: any, ctx: number, streamPointer: number, magic: string): FzDocument {
		assert(typeof magic === "string", "invalid magic argument");

		const pointer = libmupdf.ccall(
			"wasm_open_document_with_stream",
			"number",
			["number", "number", "string"],
			[ctx, streamPointer, magic]
		);
		// TODO - Add reference to stream.
		const doc = new FzDocument(libmupdf, ctx, pointer);

		const numPages = doc.countPages();
		doc.numPages = numPages;
		doc.pages = new Array<FzPage | PdfPage>(numPages);

		return doc;
	}



	countPages(): number {
		// check numPages already set
		const { libmupdf } = this;
		if (this.numPages === null) {
			this.numPages = libmupdf._wasm_count_pages(this.ctx, this.pointer);
		}
		return this.numPages;
	}

	public loadPage(pageIndex: number): FzPage {
		// check if page is already loaded
		if (this.pages[pageIndex]?.pointer) {
			return this.pages[pageIndex];
		}

		const { libmupdf } = this;
		let page_ptr = 0;
		// console.log(`loadPage: wasm_load_page: ctxPtr: ${this.ctx}, fzDocPtr: ${this.pointer}, pageIndex: ${pageIndex}`);

		try {
			page_ptr = libmupdf._wasm_load_page(this.ctx, this.pointer, pageIndex);
		}
		catch (e) {
			console.error(e);
			throw new Error("loadPage: error " + (e as any).message);
		}
		const pdfPage_ptr = libmupdf._wasm_pdf_page_from_fz_page(this.ctx, page_ptr);

		let page: FzPage = null;
		if (pdfPage_ptr !== 0) {
			page = new PdfPage(libmupdf, this.ctx, pdfPage_ptr, pageIndex, this);
		} else {
			page = new FzPage(libmupdf, this.ctx, page_ptr, pageIndex, this);
		}

		// set page in pages array
		this.pages[pageIndex] = page;

		return page;
	}

	title(): string {
		// the string returned by this function is static and doesn't need to be freed
		const { libmupdf } = this;
		// console.log( "wasm_document_title: start", this.ctx, this.pointer);
		const ptr = libmupdf._wasm_document_title(this.ctx, this.pointer);
		// console.log( "wasm_document_title: after wasm", this.ctx, this.pointer);
		const ret = libmupdf.UTF8ToString(ptr);
		// console.log( "wasm_document_title: done", this.ctx, this.pointer);
		return ret;
	}

	loadOutline() {
		const { libmupdf } = this;
		return new_outline(libmupdf, this.ctx, libmupdf._wasm_load_outline(this.ctx, this.pointer));
	}

	onFreePage(pageIndex: number) {
		// console.log(`loadpage: onFreePage: pageIndex: ${pageIndex}`);
		this.pages[pageIndex] = null;
	}


	// addNcodeFont = (
	//   glyph_offset: number /* double */,
	//   glyph_diameter: number /* double */,
	//   stroke_width: number /* double */,
	//   glyph_shape: number /* int */
	// ) => {
	//   const { libmupdf } = this;
	//   const size = 256;

	//   let charsetPointer = 0;
	//   let charset = "";
	//   let fontObjPointer = null as number;
	//   try {
	//     charsetPointer = libmupdf._wasm_malloc(this.ctx, size);

	//     fontObjPointer = libmupdf._wasm_neoAddNcodeFontToDocument(
	//       this.ctx, this.pointer,
	//       glyph_offset, glyph_diameter, stroke_width, glyph_shape,
	//       charsetPointer, size
	//     );

	//     charset = libmupdf.UTF8ToString(charsetPointer);
	//     this.ncodeFont = new NcodeFont(libmupdf, this.ctx, fontObjPointer, charset);
	//   }
	//   finally {
	//     if (charsetPointer)
	//       libmupdf._wasm_free(this.ctx, charsetPointer);
	//   }

	//   return {
	//     fontObjPointer,
	//     charset
	//   };
	// }

	// getNcodeFont = () => {
	//   return this.ncodeFont;
	// }

	// addBlankPage = (width: number, height: number, rotate: number) => {
	//   const { libmupdf } = this;
	//   libmupdf._wasm_appendBlankPage(this.ctx, this.pointer, width, height, rotate);
	//   this.pages.push(null);
	//   this.numPages = null;
	// }

	// insertBlankPageAt = (pageIndex: number, width: number, height: number, rotate: number) => {
	//   const { libmupdf } = this;
	//   libmupdf._wasm_insertBlankPageAt(this.ctx, this.pointer, pageIndex, width, height, rotate);
	//   // insert null at pageIndex on this.pages array
	//   this.pages.splice(pageIndex, 0, null);
	//   this.numPages = null;
	// }

	// removePageRange = (start: number, end: number) => {
	//   const { libmupdf } = this;
	//   libmupdf._wasm_removePageRange(this.ctx, this.pointer, start, end);
	//   // remove pages from this.pages array
	//   this.pages.splice(start, end - start + 1);
	//   this.numPages = null;
	// }

	// removePageAt = (pageIndex: number) => {
	//   const { libmupdf } = this;
	//   libmupdf._wasm_removePageAt(this.ctx, this.pointer, pageIndex);
	//   // remove page from this.pages array
	//   this.pages.splice(pageIndex, 1);
	//   this.numPages = null;
	// }

	// appendPDFPages = (donor: FzDocument, start: number, numPages: number) => {
	//   const { libmupdf } = this;
	//   libmupdf._wasm_neoAppendPdfPages(this.ctx, this.pointer, donor.pointer, start, numPages);
	//   // append nulls to this.pages array
	//   for (let i = 0; i < numPages; i++) {
	//     this.pages.push(null);
	//   }
	//   this.numPages = null;
	// }

	// save = (compress: boolean) => {
	//   const { libmupdf } = this;
	//   const bufPointer = libmupdf._wasm_neoPdfToFzBuffer(this.ctx, this.pointer, compress ? 1 : 0);
	//   const buf = new FzBuffer(libmupdf, this.ctx, bufPointer);
	//   const u8arr = buf.toUint8Array();
	//   return u8arr;
	// }
}
