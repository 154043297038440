import React from "react";
import {styled} from "@mui/styles";
import {Tooltip, tooltipClasses, TooltipProps, Zoom} from "@mui/material";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps & { className?: string }) => (
    <Tooltip TransitionComponent={Zoom} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#F4D219',
        boxShadow: '0px 1px 1px -1px rgba(255,255,0,0.1)',
        border: '0.5px solid #F3A50E',
        borderRadius: 0,
        marginTop: -5,
        color: '#111',
        fontSize: '0.75rem',
    },
}));

export const CustomTooltip = styled(({ className, ...props }: TooltipProps & { className?: string }) => (
    <Tooltip TransitionComponent={Zoom} placement={"bottom-end"} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: '#fff',
        color: '#fff',
        maxWidth: 510,
        padding: '16px 20px',
        marginTop: "15px",
        boxSizing: 'border-box',
        boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.1)',
    },
}));

// export const HtmlTooltip = styled(({ className, ...props }) => (
//     <Tooltip  TransitionComponent={Zoom} {...props} classes={{ popper: className }}/>
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: '#F4D219',
//         boxShadow : '0px 1px 1px -1px rgba(255,255,0,0.1)',
//         border: '0.5px solid #F3A50E',
//         borderRadius: 0,
//         marginTop: -5,
//         color: '#111',
//         fontSize: '0.75rem',
//     },
// }));

// export const CustomTooltip = styled(({ className, ...props }) => (
//     <Tooltip  TransitionComponent={Zoom} placement={"bottom-end"} {...props} classes={{
//         popper: className
//     }}/>
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         background: '#fff',
//         color: '#fff',
//         maxWidth: 510,
//         padding: '16px 20px',
//         marginTop: "15px",
//         boxSizing: 'border-box',
//         boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.1)',
//     },
// }));