import React from "react";

type ProgressBarProps = {
	width: string | number;
	height: string | number;
	percent: number; // 0 ~ 100
};

export function UploadProgressBar(props: ProgressBarProps) {
	// Validate percent range
	const { width, height, percent } = props;
	const clampedPercent = Math.min(100, Math.max(0, percent));

	return (
		<div
			style={{
				width: `${typeof width === "number" ? width.toString() + "px" : width}`,
				height: `${typeof height === "number" ? height.toString() + "px" : height}`,
				backgroundColor: "#e0e0e0", // Background color for the unfilled part
				borderRadius: "4px",
				overflow: "hidden",
				position: "relative",
			}}
		>
			<div
				style={{
					width: `${clampedPercent}%`,
					height: "100%",
					backgroundColor: "#1976D2", // Filled part color
					transition: "width 0.3s ease", // Smooth transition
				}}
			></div>
		</div>
	);
};
