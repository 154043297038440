import { INCH_TO_MM_SCALE, INoteServerItem_forPOD, IPageSOBP, IPaperSize, ISize, NCODE_TO_INCH_SCALE, PDF_DEFAULT_DPI, UNIT_TO_DPI } from "../../../nl-lib3-common";
import { g_paperType } from "../constants/g_paperType";


/**
 * paper size를 해당 inch 단위로 돌려 준다.
 */
export function getNPaperInfo(sobp: IPageSOBP) {
	const { section = -1, owner = -1, book = -1 } = sobp;

	let isDefault = false;
	let key = section.toString() + "." + owner.toString() + "." + book.toString();
	let found = g_paperType.definition[key];
	if (!found) {
		key = g_paperType.defaultKey;
		found = g_paperType.definition[key];
		isDefault = true;
	}

	const desc: INoteServerItem_forPOD = {
		...found,
		id: key,
		sobp,
		glyphData: "",
		isDefault,
		paperGroupId: key
	};

	return desc;
}


function getNPaperSize_nu(item: IPageSOBP | INoteServerItem_forPOD): ISize {
	let desc = item as INoteServerItem_forPOD;

	// IPageSOBP 이면 noteserver item을 가져 온다.
	if (!item || !Object.prototype.hasOwnProperty.call(item, "margin")) {
		const sobp = item as IPageSOBP;
		desc = getNPaperInfo(sobp);
	}

	const { margin } = desc;
	return {
		width: margin.Xmax - margin.Xmin,
		height: margin.Ymax - margin.Ymin
	};
}


function getNPaperSize_dpi(item: IPageSOBP | INoteServerItem_forPOD, dpi: number): ISize {
	const size = getNPaperSize_nu(item);

	return {
		width: Math.round(size.width * NCODE_TO_INCH_SCALE * dpi),
		height: Math.round(size.height * NCODE_TO_INCH_SCALE * dpi),
	}
}

export function getNPaperSize_pu(item: IPageSOBP | INoteServerItem_forPOD): ISize {
	return getNPaperSize_dpi(item, PDF_DEFAULT_DPI);
}



export function convertNPaperSize_pu(item: IPageSOBP | INoteServerItem_forPOD): ISize {
	return getNPaperSize_dpi(item, PDF_DEFAULT_DPI);
}

export function getMediaSize_pu(mediaType: IPaperSize): ISize {
	const { width, height } = mediaType;

	return {
		width: width * INCH_TO_MM_SCALE * PDF_DEFAULT_DPI,
		height: height * INCH_TO_MM_SCALE * PDF_DEFAULT_DPI,
	}
}

export function getSurfaceSize_dpi(size: IPaperSize, dpi: number, isLandscape: boolean, padding = 0) {
	/** numerator of converting to INCH */
	const numerator = UNIT_TO_DPI[size.unit];
	const mm_numerator = UNIT_TO_DPI.mm;
	const ratio = 1.0;

	let width = (size.width * ratio * dpi) / numerator;
	let height = (size.height * ratio * dpi) / numerator;

	padding = (padding * ratio * dpi) / mm_numerator;
	width -= padding;
	height -= padding;

	width = Math.floor(width);
	height = Math.floor(height);

	if (isLandscape) {
		return {
			width: height,
			height: width,
		};
	}

	return { width, height };
}


export function getSurfaceSize_px_600dpi(size: IPaperSize, isLandscape: boolean, padding = 0) {
	return getSurfaceSize_dpi(size, UNIT_TO_DPI.dpi600, isLandscape, padding);
}

export function getSurfaceSize_inch(size: IPaperSize, isLandscape: boolean, padding = 0) {
	return getSurfaceSize_dpi(size, 1, isLandscape, padding);
}

export function getSurfaceSize_mm(size: IPaperSize, isLandscape: boolean, padding = 0) {
	return getSurfaceSize_dpi(size, UNIT_TO_DPI.mm, isLandscape, padding);
}

export function getSurfaceSize_css(size: IPaperSize, isLandscape: boolean, padding = 0) {
	return getSurfaceSize_dpi(size, UNIT_TO_DPI.css, isLandscape, padding);
}


export function getSurfaceSize_pu(size: IPaperSize, isLandscape: boolean, padding = 0) {
	return getSurfaceSize_dpi(size, UNIT_TO_DPI.pu, isLandscape, padding);
}

export function isPortrait(size: ISize) {
	return size.width < size.height;
}

export function getCssDpi() {
	return UNIT_TO_DPI.css;
}


