/* eslint-disable no-bitwise */

import { _DEBUG_STROKE_TIME_STAMP } from "../../../../nl-lib3-common";

/**
 * return little endian value from start with length
 * @param data - byte array
 * @param start
 * @param length
 */
export function intFromBytes(data: Uint8Array, start: number, length: number): number {
  let val = 0;
  for (let i = start + length - 1; i >= start; --i) {
    val += data[i];
    if (i > start) {
      val *= 256;
    }
  }
  return val;
}

export function strFromBytes(data: Uint8Array, start: number, length: number): string {
  let result = "";
  for (let i = 0; i < length; i++) {
    const v = data[start + i];
    if (v === 0) break;

    result += String.fromCharCode(v);
  }
  return result;
}



/*

export async function sleep(ms: number) {
  return new Promise(function (resolve) {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}


/**
 *
 * @param val - integer number
 * @param padding - how many hex digits
 */
export function decimalToHex(val: number, padding: number) {
  let hex = Number(val).toString(16);
  padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

  while (hex.length < padding) {
    hex = "0" + hex;
  }

  return hex.toUpperCase();
}

export function toUTF8Array(str: string, len?: number) {
  let utf8: number[] = [];
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i);
    if (charcode < 0x80) utf8.push(charcode);
    else if (charcode < 0x800) {
      utf8.push(
        0xc0 | (charcode >> 6),
        0x80 | (charcode & 0x3f)
      );
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(
        0xe0 | (charcode >> 12),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    } else {
      // surrogate pair
      i++;
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode = 0x10000 + (((charcode & 0x3ff) << 10)
        | (str.charCodeAt(i) & 0x3ff));
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
  }

  if (len) {
    if (utf8.length > len) {
      utf8 = utf8.slice(0, len);
    } else {
      while (utf8.length < len) {
        utf8.push(0);
      }
    }
  }

  return utf8;
}

export function intToBytes(val: number, len: number) {
  const ret: number[] = [];
  for (let i = 0; i < len; i++) {
    ret.push(val & 0xff);
    val = Math.floor(val / 256);
  }

  return ret;
}


export function int16ToBytes(val: number) {
  return intToBytes(val, 2);
}

export function int32ToBytes(val: number) {
  return intToBytes(val, 4);
}

export function int64ToBytes(val: number) {
  return intToBytes(val, 8);
}


export function getSectionOwnerBytes(section: number, owner: number) {
  const ret = int32ToBytes(owner);
  ret[3] = section & 0xff;

  return ret;
}


// export function escapePenPacketByte(ch: number) {
//   const ret: number[] = [];

//   if (ch === PEN_PACKET_START || ch === PEN_PACKET_END || ch === PK_DLE) {
//     ret.push(PK_DLE);
//     ret.push(ch ^ 0x20);
//   } else {
//     ret.push(ch);
//   }

//   return ret;
// }

export function logTimeStamp(timeStamp: number) {
  if (_DEBUG_STROKE_TIME_STAMP) {
    const obj = {} as { stack: string };
    (Error as any).captureStackTrace(obj);
    const stackArr = obj.stack.split("\n");

    const d = new Date(timeStamp);
    console.log(`TIMESTAMP: ${d.toLocaleString()}.${(d.getMilliseconds() / 1000).toFixed(3).slice(2)}, ${stackArr[2].trim()}`);
  }
}
