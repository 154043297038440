export class OfflineDataInfo {
  public Section: number;

  public Owner: number;

  public Note: number;

  public Pages: number[] | undefined;

  constructor(sectionId: number, ownerId: number, noteId: number, pages?: number[]) {
    this.Section = sectionId;
    this.Owner = ownerId;
    this.Note = noteId;
    this.Pages = pages;
  }

  public ToString() {
    return `sec:${this.Section}, owner:${this.Owner}, note:${this.Note}`;
  }
}

