import {
	Box,
	Button,
	LinearProgress,
	Paper,
	TableContainer,
	Typography
} from "@mui/material";

import clsx from "clsx";

import React from "react";
import { useTranslation } from "react-i18next";
import { ProjectStateType } from "../../../../../repositories/model/support/ProjectStateType";
import { IProjectSubmissionTransfer } from "../../../../../repositories/model/transfer/IProjectSubmissionTransfer";
import { WorksheetResultHeader } from "../../../000_Common/WorksheetResultHeader";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { CompareDataStyle } from "../../../styles/CompareDataStyle";
import { QuestionAndReferenceTab } from "../OfflineSubmissions/CompareNeoPenData/QuestionAndReferenceTab";
import { useStores } from "../../../../../hooks/useStores";
import useStrokeRecognition, { codeToRecognizedTextPromise, IOcrResult } from "../../../../../ndp/hooks/useStrokeRecognition";
import { IPageSOBP, NeoStroke } from "../../../../../neolab-libs/nl-lib3-common";
import { DownloadNativePopup } from "../OfflineSubmissions/CompareBatchNeoPenData/DownloadNativePopup";

export type IRecognizedAnswerType = {
	projectExamCode: string,
	projectExamQuestionNum: number,
	answer: string,
	ocrResult: string,
	ocrStatus: "none" | "requested" | "done" | "error"
}

enum CompareState {
	none = 'none',
	comparing = 'comparing',
	uploaded = "uploaded"
};

type Props = {
	projectCode: string;
	templateProjectCode: string;
	submissionTransfer: IProjectSubmissionTransfer & { submissionIndex: number };

	pdfWithStroke: any,
	disabled: boolean,
	strokes: NeoStroke[],
	ncodeRange: { start: IPageSOBP, end: IPageSOBP },
	handleClickClose: () => void;
};


function CompareOcrDataStudent(props: Props) {
	const i18next = useTranslation();
	const classes = useEmotionStyles(CompareDataStyle);
	const [answerList, setAnswerList] = React.useState<
		{
			projectExamCode: string,
			projectExamQuestionNum: number,
			answer: string,
			ocrResult: string,
			ocrStatus: "none" | "requested" | "done" | "error"
		}[]
	>([]);

	const [compareState, setCompareState] = React.useState(CompareState.none);
	const [editMode, setEditMode] = React.useState(false);

	const { projectCode, submissionTransfer, strokes, handleClickClose } = props;
	const { userProfile } = submissionTransfer || {};
	const { name = "", grade = 0, className = "", number: studentNumber = 0 } = userProfile || {};

	const { messageStore, projectSubmissionStore, projectStore, projectExamStore, neoPenStore, projectSubmissionAnswerStore } = useStores();

	const { projectSubmission } = projectSubmissionStore;
	const { project } = projectStore;

	const { codeToOcrResult, numRequested, numDone, putOfflineStrokesToRecognize } = useStrokeRecognition({ project, examList: projectExamStore.projectExamList });

	const checkScored = (state: ProjectStateType) => {
		switch (state) {
			case ProjectStateType.CREATED:
			case ProjectStateType.POSED:
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
			case ProjectStateType.COMPARED:
				return false;
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
				return true;
		}
	}

	const handleClickEdit = async () => {
		if (editMode) {
			let response = await messageStore.confirm(i18next.t("편집을 취소 하시겠습니까?"));
			if (!response) return;

			setAnswerListFromRecognizedText(codeToOcrResult);
		}
		setEditMode(!editMode);
	}


	const setAnswerListFromRecognizedText = (codeToRecognizedText: { [questionCode: string]: IOcrResult }) => {
		const answers: IRecognizedAnswerType[] = [];

		const examList = projectExamStore.projectExamList;
		console.log(`ANSWER ------------------------------------------------------------------`);
		for (let examIndex = 0; examIndex < examList.length; examIndex++) {
			const questions = examList[examIndex].questions;

			for (let questionIndex = 0; questionIndex < questions.length; questionIndex++) {
				let code = examList[examIndex].code + "-" + (questionIndex + 1);
				const ocrResult = codeToRecognizedText[code];
				const { ocrStatus, recognizedText } = ocrResult || {};
				console.log(`code: ${code}, answerText: ${ocrResult}`);

				if (ocrStatus === "done") {
					const answer: IRecognizedAnswerType = {
						projectExamCode: examList[examIndex].code,
						projectExamQuestionNum: questionIndex + 1,
						answer: recognizedText,
						ocrResult: recognizedText,
						ocrStatus
					};

					answers.push(answer);
				} else {
					const answer: IRecognizedAnswerType = {
						projectExamCode: examList[examIndex].code,
						projectExamQuestionNum: questionIndex + 1,
						answer: null,
						ocrResult: null,
						ocrStatus
					};

					answers.push(answer);
				}
			}
		}

		setAnswerList(answers);
	}

	const handleClickComplete = async () => {
		if (editMode) {
			let response = await messageStore.confirm(i18next.t("편집한 내용을 저장 하시겠습니까?"));
			if (!response) return;

			projectSubmissionAnswerStore.changeCompareAnswer(answerList);
			await projectSubmissionAnswerStore.updateSubmissionAnswers();
			// await projectSubmissionAnswerStore.getSubmissionAnswers(submissionCode);
			setEditMode(!editMode);
		} else {
			let response = await messageStore.confirm(i18next.t("대조 작업을 완료 하시겠습니까?"));
			if (!response) return;

			setCompareState(CompareState.comparing);
			const range = props.ncodeRange;

			const isCompletingWithoutSubmissionCode = submissionTransfer.submissionCode == null;
			let submissionCode = submissionTransfer.submissionCode;
			let userCode = submissionTransfer.userCode;
			if (isCompletingWithoutSubmissionCode) {
				// refresh transfer
				const refreshedTransfer = await projectSubmissionStore.getSubmissionTransfersWithReturnForStudentPenSubmit(projectCode, userCode);
				submissionCode = refreshedTransfer.submissionCode;
				userCode = refreshedTransfer.userCode;
			}


			await projectSubmissionStore.uploadAnswerDataAndRenderedPdf({
				projectCode,
				userCode,
				pdfBlobURL: null, // props.pdfWithStroke,
				answerList: answerList.map(item => ({ ...item, ocrStatus: "done" })),
				range,
				submissionCode: submissionCode,
			});
			await projectStore.updateProjectUserState(projectCode, userCode, ProjectStateType.COMPARED, true);
			setCompareState(CompareState.uploaded);

			await neoPenStore.disconnectPen();
			neoPenStore.setSortedOfflineStrokes([]);
			Object.keys(codeToRecognizedTextPromise).forEach(key => delete codeToRecognizedTextPromise[key]);

			props.handleClickClose();
		}
	}


	const handleChangeAnswers = (answer, index) => {
		let temp = answerList.map(item => item);
		temp[index].answer = answer;
		setAnswerList(temp);
	}

	const renderButtonText = (editMode: boolean, compareState: CompareState) => {
		if (editMode) {
			return <Typography>{i18next.t('저장')}</Typography>;
		}

		switch (compareState) {
			case CompareState.none:
				return <Typography>{i18next.t('대조 작업 완료')}</Typography>;
			case CompareState.comparing:
				return <Typography>{i18next.t('필기 업로드 중')}</Typography>;
			case CompareState.uploaded:
				return <Typography>{i18next.t('필기 업로드 완료')}</Typography>;
			default:
				return <Typography>{i18next.t('저장')}</Typography>;
		}
	}


	const onNativeAvailibilityChanged = React.useCallback((isAvailable: boolean) => {
		console.log("onNativeAvailibilityChanged", isAvailable);
		// if (strokes && isAvailable) {
		// 	putOfflineStrokesToRecognize([...strokes]);
		// }
	}, []);

	React.useEffect(() => {
		// if (strokes) {
		putOfflineStrokesToRecognize(strokes);
		// }
	}, [strokes]);

	React.useEffect(() => {
		setAnswerListFromRecognizedText(codeToOcrResult);
	}, [codeToOcrResult]);

	const goBack = () => {
		// handleClickClose();
		// customNavi.goBack();

	}

	return (
		<Box className={classes.fileUploadBox}>
			{/* Dim 처리 레이어 */}
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.5)', // 반투명 검은색
					display: props.disabled ? 'block' : 'none', // dim 상태에 따라 표시/숨김
					// display: 'none', // dim 상태에 따라 표시/숨김
					zIndex: 1,
					pointerEvents: 'none',  // 추가: 마우스 이벤트를 하위 요소로 전달

				}}
			/>

			<Box sx={{ position: 'relative' }} >
				<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={clsx(classes.compareTop, classes.compareTopColor)}>
					<Typography style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
						{i18next.t('필기 인식 결과')}
					</Typography>
					<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
						<Button className={classes.compareTopBtn}
							style={{ marginRight: "5px" }}
							disabled={checkScored(projectSubmission.state) || props.disabled}
							onClick={handleClickEdit} disableRipple

						>
							{editMode ? <Typography>{i18next.t('취소')}</Typography>
								: <Typography>{i18next.t('편집')}</Typography>
							}
						</Button>

						<Button className={classes.compareTopBtn} disabled={compareState !== CompareState.none || props.disabled} onClick={handleClickComplete} disableRipple>
							{renderButtonText(editMode, compareState)}
						</Button>

						<Button className={classes.compareCloseBtn} sx={{ zIndex: 999 }} onClick={props.handleClickClose}>
							<Typography>
								{i18next.t("취소(닫기)")}
							</Typography>
						</Button>
					</Box>
				</Box>

				<Box className={classes.recognizedProgress}>
					<Box display='flex' alignItems='center' sx={{ position: 'relative' }}>
						<Box className={classes.numberBox}>
							{/* <Typography><span>{recognizedCount}</span>/{projectExamStore.projectExamList.length}</Typography> */}

							<Typography><span>{numDone}</span>/{numRequested}</Typography>
						</Box>
						<Box className={classes.progressBox}>
							<LinearProgress variant="determinate" value={numRequested ? numDone * 100 / numRequested : 0} />
						</Box>
						<Typography className={classes.progressText}>{numRequested ? numDone * 100 / numRequested : 0}%</Typography>
					</Box>
				</Box>

				<Box className={classes.compareContent}>
					<Box>
						<TableContainer component={Paper} className={classes.tableBox}>
							<WorksheetResultHeader
								classes={classes}
								projectTargetGroupName={project.targetGroupName}
								projectYear={project.year}
								projectSemester={project.semesterType}
								projectSubjectName={project.subjectName}

								userName={name}
								grade={grade}
								className={className}
								number={studentNumber}
							/>
						</TableContainer>
					</Box>
					<QuestionAndReferenceTab
						isRecognizedAll={false}
						answerList={answerList}
						changeAnswer={handleChangeAnswers}
						editMode={editMode}
						projectExamList={projectExamStore.projectExamList}
					/>
				</Box>
			</Box>

			{/* {!props.disabled && */}
				<DownloadNativePopup onClose={goBack} onNativeAvailibilityChanged={onNativeAvailibilityChanged} />
			{/* } */}

		</Box >
	)
}

export default CompareOcrDataStudent;
