/// <reference types="web-bluetooth" />
import { deviceSelectDlg, DeviceSelectDlgResult } from "../DeviceSelectDlg";
import SmartPenBase from "../SmartPenBase";
import { setLastBluetoothError } from "../last-bluetooth-error";
import { PenComm } from "../pencomm/pencomm";
import { IPenManager, NEO_SMARTPEN_TYPE, IPenIdType, IPenCommEvent, PenEventName, INeoSmartpen, IOfflineDataProgressEvent, PEN_STATE, IPageSOBP, OfflineDataInfo, isInNcodeRange, NeoStroke, } from "../../../../nl-lib3-common";
import { BluetoothTypeEnum } from "../../nl-lib-bt-devices";


export default class NeoSmartpen extends SmartPenBase {

	bluetoothType: BluetoothTypeEnum;

	constructor(args: {
		manager: IPenManager,
		bluetoothType?: BluetoothTypeEnum,
		userId: string,
		// customStorage?: InkStorage
	}) {
		const { manager, bluetoothType, userId } = args;
		super({ manager, userId });

		this.inputType = NEO_SMARTPEN_TYPE.REAL_PEN;
		this.connected = false;
		this.bluetoothType = bluetoothType || BluetoothTypeEnum.WEB;

		this.protocolHandler = new PenComm(this, this.bluetoothType);
	}


	public getMac() {
		return this.protocolHandler.getMac();
	}

	/**
	 * deprecated, 2022/10/16
	 */
	public async connect(requestOnlineData: boolean, passwordRequestMsg: (retryCount: number, retryLimit: number) => string): Promise<{ success: boolean, reason: string }> {
		this.passwordRequestMsg = passwordRequestMsg;
		console.error("The default connect function was deprecated on 2022/10/16");
		let device = null as unknown as BluetoothDevice;
		try {
			const result = await deviceSelectDlg();
			if (result.success !== DeviceSelectDlgResult.Success) {
				return { success: false, reason: result.success as string };
			}

			device = result.device;
			this.webBluetoothId = device.id;
		} catch (e: any) {
			setLastBluetoothError(e);
			return { success: false, reason: "device selection failed" };
		}
		return this.connectBrowserPen({ device, requestOnlineData, passwordRequestMsg });
	}


	public async connectBrowserPen(args: {
		device: BluetoothDevice,
		requestOnlineData: boolean,
		passwordRequestMsg: (retryCount: number, retryLimit: number) => string
	}): Promise<{ success: boolean, reason: string }> {
		const { device, requestOnlineData, passwordRequestMsg } = args;

		if (!device) {
			return { success: false, reason: "device is null" };
		}

		this.passwordRequestMsg = passwordRequestMsg;

		if (this.manager.isBtPenAlreadyConnected(device.id)) {
			console.error(`Bluetooth device(id:${device.id}) is already connected or in the middle of the connecting process`);
			setLastBluetoothError(new Error(`Bluetooth device(id:${device.id}) is already connected or in the middle of the connecting process`));
			return { success: true, reason: "already connected" };
		}
		this.webBluetoothId = device.id;
		const ret = await this.protocolHandler.connect({ btDevice: device, requestOnlineData });
		setLastBluetoothError(new Error(`Null device`));
		return { success: ret, reason: "unknown" };
	}


	// eslint-disable-next-line class-methods-use-this
	public async connectMauiPen(args: {
		penId: IPenIdType,
		requestOnlineData: boolean,
		passwordRequestMsg: (retryCount: number, retryLimit: number) => string
		shouldStopScanTask?: boolean
	}): Promise<{ success: boolean, reason: string }> {
		const { penId, requestOnlineData, shouldStopScanTask = true, passwordRequestMsg } = args;
		this.passwordRequestMsg = passwordRequestMsg;

		this.webBluetoothId = penId;
		if (this.manager.isBtPenAlreadyConnected(penId)) {
			setLastBluetoothError(new Error(`Bluetooth device(id:${penId}) is already connected or in the middle of the connecting process`));

			return { success: true, reason: "already connected" };
		}
		const ret = await this.protocolHandler.connect({ mauiDeviceId: penId, requestOnlineData, shouldStopScanTask });
		if (ret) {
			// this.manager.onConnected({ pen: this } as unknown as IPenCommEvent);
		}

		setLastBluetoothError(new Error(`Null device`));
		return { success: ret, reason: "unknown" };
	}

	// eslint-disable-next-line class-methods-use-this
	public registerConnectedMauiBluetooth(penId: IPenIdType, requestOnlineData: boolean): boolean {
		this.webBluetoothId = penId;

		const ret = this.protocolHandler.registerConnectedMauiBluetooth({ mauiDeviceId: penId, requestOnlineData });
		setLastBluetoothError(new Error(`Null device`));
		return ret;
	}


	/**
	 *
	 */
	public disconnect = async () => {
		if (!this.connected) {
			console.log(`Pen is not connected`);
			return;
		}

		await this.protocolHandler?.disconnect();
		this.connected = false;
	}

	public forceBLEDisconnect() {
		this.protocolHandler.forceBLEDisconnect();
	}

	/**
	 *
	 */
	onConnected = (event: IPenCommEvent) => {
		const webBluetoothId = this.protocolHandler.getId();

		if (this.webBluetoothId !== webBluetoothId) {
			// 이런 경우가 생기면 안되는 데 말이지
			console.error(`BT dialog BTID(${this.webBluetoothId}is not equal to BtDevideID(${webBluetoothId})`);
			this.webBluetoothId = webBluetoothId;
		}

		const mac = this.protocolHandler.getMac();
		console.log(`CONNECTED ${mac}`);

		this.mac = mac;
		this.connected = true;

		console.log(`Connected: ${mac}`);
		// this.manager.add(this);

		if (this.manager.getPens().length === 1) {
			this.manager.setActivePen(this.mac);
		}

		// this.manager.onConnected({ pen: this, event });
		this.dispatcher.dispatch(
			PenEventName.ON_CONNECTED,
			{
				inputType: this.inputType,
				pen: this as unknown as INeoSmartpen,
				mac,
				event
			}
		);
	}


	/**
	 *
	 */
	public onFirmwareUpgradeNeeded(event: IPenCommEvent) {
		const { mac } = this;
		this.dispatcher.dispatch(
			PenEventName.ON_UPGRADE_NEEDED,
			{
				inputType: this.inputType,
				pen: this,
				mac,
				event
			}
		);
	}


	/**
	 * 2021/08/09
	 * Pen의 호버 모드를 ON/OFF
	 * @param on
	 */
	public setHoverMode(on: boolean): void {
		if (!this.connected) {
			console.error(`Pen is not connected`);
			return null;
		}
		this.protocolHandler.setHoverMode(on ? 1 : 0);
	}

	public setBtLocalName(name: string): void {
		this.protocolHandler.setBtLocalName(name);
	}

	public setAutoPowerOnOff(on: boolean): void {
		this.protocolHandler.setAutoPowerOnOff(on ? 1 : 0);
	}

	public setAutoPenCapOnOff(on: boolean): void {
		this.protocolHandler.setAutoPenCapOnOff(on ? 1 : 0);
	}

	public setBeepOnOff(on: boolean): void {
		this.protocolHandler.setBeepOnOff(on ? 1 : 0);
	}

	/**
 * 2021/08/18
 * @param event
 */
	public onPenStatusResponse(event: IPenCommEvent) {
		if (event.status?.maxPressureValue) {
			this.maxPenPressureValue = event.status.maxPressureValue;
		}
		const { mac } = event;
		this.dispatcher.dispatch(
			PenEventName.ON_PEN_STATUS_RESPONSE,
			{
				inputType: this.inputType,
				pen: this as unknown as INeoSmartpen,
				mac,
				event
			}
		);
	}

	/**
	 * 2022/05/18
	 */

	public onFinishedOfflineDownload(event: IPenCommEvent) {
		console.log(`onFinishedOfflineDownload, numStrokes:${event.offlineData?.strokes?.length}`);
		this.offlineDataProgressReportCb = undefined as unknown as (event: IOfflineDataProgressEvent) => void;
		// console.log(event, event.offlineData.strokes);
	}

	// eslint-disable-next-line class-methods-use-this,
	public onStartOfflineDownload(event: IPenCommEvent) {
		console.log(`onStartOfflineDownload`);
		// console.log(event);
	}

	public onOfflineDataDelivered(event: IPenCommEvent) {
		if (this.offlineDataProgressReportCb && event.offlineData) {
			const { totalStrokeCount, receivedStrokeCount, strokes } = event.offlineData;
			this.offlineDataProgressReportCb({
				pen: this as unknown as INeoSmartpen,
				numTotal: totalStrokeCount,
				numCompleted: receivedStrokeCount,
				strokes
			});
		}
		// console.log(`onOfflineDataDelivered, received stroke at this turn ${event.offlineData?.strokes?.length}`);
		// console.log(event);
	}

	// eslint-disable-next-line class-methods-use-this
	public onOfflineDataListDelivered(event: IPenCommEvent) {
		console.log(`onOfflineDataListDelivered, offline pages: ${event.offlineDataList.length}`);
		// console.log(event);

		// data list 에서 데이터를 받자.
		// const list = event.offlineDataList;
		// if (list?.length) {
		//   const item = list[0];
		//   const { Section: s, Owner: o, Note: b, Pages: pgs } = item;
		//   this.protocolHandler.reqOfflineData(s, o, b, pgs)
		// }
	}

	// eslint-disable-next-line class-methods-use-this,
	public onPasswordChanged(event: IPenCommEvent) {
		const mac = this.protocolHandler?.getMac();
		if (!mac) {
			throw new Error("mac address was not given before");
		}
		this.dispatcher.dispatch(
			PenEventName.ON_PW_SET_COMPLETED,
			{
				inputType: this.inputType,
				pen: this as unknown as INeoSmartpen,
				mac,
				pencommEvent: event
			}
		);
	}

	public onShutdownEvent(event: IPenCommEvent) {
		const mac = this.protocolHandler?.getMac();
		if (!mac) {
			throw new Error("mac address was not given before");
		}
		this.dispatcher.dispatch(
			PenEventName.ON_SHUTDOWN_EVENT,
			{
				inputType: this.inputType,
				pen: this as unknown as INeoSmartpen,
				mac,
				pencommEvent: event
			}
		);
	}

	public onLowBatteryEvent(event: IPenCommEvent) {
		const mac = this.protocolHandler?.getMac();
		if (!mac) {
			throw new Error("mac address was not given before");
		}
		this.dispatcher.dispatch(
			PenEventName.ON_LOW_BATTERY_EVENT,
			{
				inputType: this.inputType,
				pen: this as unknown as INeoSmartpen,
				mac,
				pencommEvent: event
			}
		);
	}


	public async requestSetPenInfo() {
		this.protocolHandler.requestSetInfo();
	}

	// eslint-disable-next-line
	public async requestOfflineDataList(args?: { section?: number, owner?: number, book?: number, needPageData?: boolean }) {
		if (!this.connected) {
			console.error(`Pen is not connected`);
			return null;
		}

		const { section, owner, book, needPageData: l } = args || {};
		const isLarge = (l !== undefined) ? l : false;

		const ret = await this.protocolHandler.reqOfflineDataList(section, owner, book, isLarge);
		return ret;
	}

	/**
	 * range가 주어지면 주어긴 range에 속하는 페이지들만 있는지 확인해서, 그 리스트를 가져 온다. 2024-12-04
	 * @param range
	 * @returns
	 */

	public async getOfflinePageList(range?: { start: IPageSOBP, end: IPageSOBP }) {
		const requestOfflinePageList: OfflineDataInfo[] = [];

		const { start, end } = range || {};
		const startWithoutPage = { ...start, page: 0 };
		const endWithoutPage = { ...end, page: 0 };

		// page 정보를 제외한 offline data를 요청하고
		const bookList = await this.requestOfflineDataList();
		if (bookList) {
			for (const offlineDataPage of bookList) {
				// section, owner, book 까지 들어간 상태에서 다시 요청해서 page 정보를 받아온다.
				const { Section: section, Owner: owner, Note: book } = offlineDataPage;
				const sobp: IPageSOBP = { section, owner, book, page: 0 };

				if (!range || (range && isInNcodeRange(sobp, startWithoutPage, endWithoutPage))) {
					const pageList = await this.requestOfflineDataList({ section, owner, book, needPageData: true });

					if (range) {
						for (let i = 0, ln = pageList.length; i < ln; i++) {
							const pageSet = pageList[i];
							pageSet.Pages = pageSet.Pages.filter((pg) => {
								const sobp = { section, owner, book, page: pg };
								if (isInNcodeRange(sobp, start, end)) return true;

								return false;
							});
						}
					}
					const filtered = pageList.filter(pl => pl.Pages.length > 0);
					requestOfflinePageList.push(...filtered);
				}
			}
		}

		return requestOfflinePageList;
	}

	public onDisconnected(e: IPenCommEvent) {
		super.onDisconnected(e);

		// this.protocolHandler.reqOfflineDataTask?.reject?.('Disconnected');
		// this.protocolHandler.reqOfflineDataListTask?.reject?.('Disconnected');
	}

	public async getOfflineDataByRange(
		args: {
			range?: { start: IPageSOBP, end: IPageSOBP },
			deleteOnFinished: boolean,
			offlineDataPageList?: OfflineDataInfo[],
			handleProgress?: (progress: { numTotal: number, numDone: number }) => void
		}
	): Promise<{ numPages: number, numStrokes: number, strokes: NeoStroke[] }> {
		let numTotalSteps = 40;
		const { handleProgress, range, deleteOnFinished, offlineDataPageList: odp } = args;

		handleProgress?.({ numTotal: numTotalSteps, numDone: 1 });
		const offlineDataPageList = odp || await this.getOfflinePageList(range);
		if (!offlineDataPageList || offlineDataPageList.length === 0) {
			return null;
		}

		handleProgress?.({ numTotal: numTotalSteps, numDone: 2 });
		// page 정보를 제외한 offline data를 요청하고
		let numStrokes = 0;
		let numPages = 0;

		numTotalSteps = 3 + offlineDataPageList.length;

		let strokes: NeoStroke[] = [];
		for (const offlineDataPage of offlineDataPageList) {
			handleProgress?.({ numTotal: numTotalSteps, numDone: 3 + numPages });
			numPages++;
			try {
				const offlineData = await this.reqOfflineData({
					s: offlineDataPage.Section,
					o: offlineDataPage.Owner,
					b: offlineDataPage.Note,
					pgs: offlineDataPage.Pages,
					deleteOnFinished
				});

				const { strokes: sts = [] } = offlineData || {};
				strokes = [...strokes, ...sts];

				// strokes.forEach(stroke => { stroke.thickness = 1; });

				numStrokes += strokes.length;
			} catch (e) {
				if ((e as Error).message === 'Disconnected') {
					throw e;
				}
				console.log('continue', offlineDataPage, e);
			}
		}

		handleProgress?.({ numTotal: numTotalSteps, numDone: 3 + numPages });
		return { numPages, numStrokes, strokes };
	}

	public async removeOfflineDataByRange(args: {
		range?: { start: IPageSOBP, end: IPageSOBP },
		offlineDataPageList?: OfflineDataInfo[]
	}) {
		await this.getOfflineDataByRange({ range: args.range, deleteOnFinished: true, offlineDataPageList: args.offlineDataPageList });

		return true;
	}


	private offlineDataProgressReportCb: (event: IOfflineDataProgressEvent) => void = null;

	private totalOfflineStrokes = 0;

	private downloadedStrokes = 0;

	public async reqOfflineData(args: {
		s: number,
		o: number,
		b: number,
		pgs?: number[],
		deleteOnFinished?: boolean,
		progressReportCb?: (event: IOfflineDataProgressEvent) => void
	}) {
		if (!this.connected) {
			console.error(`Pen is not connected`);
			return null;
		}

		const { s, o, b, pgs, deleteOnFinished: d, progressReportCb } = args;
		const deleteOnFinished = (d !== undefined) ? d : true;

		this.offlineDataProgressReportCb = progressReportCb;
		this.totalOfflineStrokes = 0;
		this.downloadedStrokes = 0;

		return this.protocolHandler.reqOfflineData(s, o, b, pgs, deleteOnFinished);
	}

	/**
	 *
	 */
	public onPasscodeRequired(event: IPenCommEvent) {
		const mac = this.protocolHandler?.getMac();
		if (!mac) {
			throw new Error("mac address was not given before");
		}
		this.dispatcher.dispatch(PenEventName.ON_PW_REQUIRED, {
			inputType: this.inputType,
			pen: this,
			mac,
			pencommEvent: event,
		});
	}

	public sendPasscode(passcode: string) {
		if (this.protocolHandler) {
			this.protocolHandler.sendPasscode(passcode);
		}
	}

	public setPassword(oldPassword: string, newPassword: string, isSetPassword: boolean) {
		if (this.protocolHandler) {
			return this.protocolHandler.setPassword(oldPassword, newPassword, isSetPassword);
		}
		return false;
	}

	public async setAutoPowerOffTime(minTime: number) {
		if (!this.connected) {
			console.error(`Pen is not connected`);
			return null;
		}

		if (this.protocolHandler) {
			return await this.protocolHandler.setAutoPowerOffTime(minTime);
		}
	}

	public onSystemPerformanceResponse(event: IPenCommEvent): void {
		console.log(`onSystemPerformanceResponse`, event);

		const mac = this.protocolHandler?.getMac();
		this.dispatcher.dispatch(
			PenEventName.ON_PEN_SYSTEM_PERFORMANCE_RESPONSE,
			{
				inputType: this.inputType,
				pen: this,
				mac,
				event
			}
		);
	}

	public setEchoMode(isEchoMode: boolean) {
		if (!this.connected) {
			console.error(`Pen is not connected`);
			return null;
		}
		if (this.protocolHandler) {
			this.protocolHandler.setEchoMode(isEchoMode);
		}
	}

	public onEchoModeChanged(e: IPenCommEvent): void {
		console.log(`onEchoModeChanged`, e);
	}

	public async startFirmwareUpgrade(fwVersion: string, isCompress: boolean, firmwareFileUrl: string) {
		if (!this.connected) {
			console.error(`Pen is not connected`);
			return null;
		}

		if (this.protocolHandler) {
			await this.protocolHandler.firmwareUpgrade(fwVersion, isCompress, firmwareFileUrl);
		}
	}

	public cancelFirmwareUpgrade() {
		if (!this.connected) {
			console.error(`Pen is not connected`);
			return null;
		}
		if (this.protocolHandler) {
			this.protocolHandler.cancelFirmwareUpgrade();
		}
	}

	public onFirmwareUpgradeStart(event: IPenCommEvent): void {
		console.log(`onFirmwareUpgradeStart`, event);

		const mac = this.protocolHandler?.getMac();
		this.dispatcher.dispatch(
			PenEventName.ON_FIRMWARE_UPGRADE_STARTED,
			{
				inputType: this.inputType,
				pen: this,
				mac,
				pencommEvent: event
			}
		);
	}

	public onFirmwareUpgradeProgress(event: IPenCommEvent): void {
		console.log(`onFirmwareUpgradeProgress`, event);

		const mac = this.protocolHandler?.getMac();
		this.dispatcher.dispatch(
			PenEventName.ON_FIRMWARE_UPGRADE_PROGRESS,
			{
				inputType: this.inputType,
				pen: this,
				mac,
				pencommEvent: event
			}
		);
	}



	/**
	 *
	 */
	public async onPenUp(event: IPenCommEvent) {

		this.lastState = PEN_STATE.PEN_UP;
		this.currPenMovement.upEvent = event;
		// logTimeStamp(event.timeStamp);
		const { stroke } = this.currPenMovement;
		if (!stroke) return;

		// calibration 모드이면
		if (this.manager.isCalibrationMode) {
			const penUpStrokeInfo = { strokeKey: null, stroke: null };
			const { section, owner, book, page } = this.currPenMovement.infoEvent;

			// 2021/08/04, network pen이면서, plate이면 처리하지 않는다.
			if (!event.fromNetworkPen) {
				this.dispatcher.dispatch(
					PenEventName.ON_CALIBRATION_PEN_UP,
					{
						stroke: penUpStrokeInfo.stroke,
						strokeKey: penUpStrokeInfo.strokeKey,
						inputType: this.inputType,
						mac: null,
						pen: this,
						section,
						owner,
						book,
						page
					}
				);
			}

			this.resetPenStroke();
			return;
		}

		if (this.currPenMovement.infoEvent?.section === -1) return;

		super.onPenUp(event);
	}
}

