/* eslint-disable no-bitwise */
import { ByteConverter } from "./ByteConverter";
import { NeoBtDeviceBase } from "../../../../nl-lib3-common/interfaces/neo-bt-device-base";
import { NeoBTDeviceWeb } from "../../nl-lib-bt-devices/neo-bt-device/neo-bt-device-web";
import { NeoBTDeviceMaui } from "../../nl-lib-bt-devices/neo-bt-device/neo-bt-device-maui";
import { Cmd, FwUpgrade, PenProfile, SettingType } from "./pencomm_cmd";
import { bufferArray_first, DEFAULT_MAX_FORCE, DEFAULT_PASSWORD } from "./pencomm_const";
import { makePenEvent, PenCommEventEnum } from "./pencomm_event";
import { OfflineDataResultType, processOfflinePacketReceived_main } from "./pencomm_offlinedata";
import { Packet } from "./pen_packet";
import { decimalToHex, getSectionOwnerBytes, int16ToBytes, int32ToBytes, int64ToBytes, intFromBytes, strFromBytes, toUTF8Array } from "./pen_util_func";
import { IPenComm, INeoSmartpen, IPenIdType, OfflineDataInfo, NeoStroke, sleep, DeviceTypeEnum, PenUsbMode } from "../../../../nl-lib3-common";
import { ByteUtil } from "./pen_byteutil";
import { neoZip } from "../../../../nl-lib3-common/util/functions/unzip-gunzip";
import { IPennCommEvnetStatus, IPennCommEvnetSystemPerformance } from "../../../../nl-lib3-common/event-args/pencomm-event";
import { IDeviceVersionInfo } from "../../../../nl-lib3-common/interfaces/pencomm-interface";
import { BluetoothTypeEnum, PEN_PACKET_END, PEN_PACKET_START } from "../../nl-lib-bt-devices";
import MauiPenCommManager from "../../nl-lib-maui/maui-bridge/maui-pencomm-proxy";

/**
 * protocol handshake duration in ms, while Handshaking
 */
const protocolTimeoutLimit = 10000;


// export default class PenComm implements ProtocolHandlerBase {
export class PenComm implements IPenComm {
	bluetoothType: BluetoothTypeEnum;

	// btDevice: BluetoothDevice = undefined as unknown as BluetoothDevice;

	// device information
	deviceInfo: IDeviceVersionInfo = {
		/** @type {string} */
		modelName: "", // NWP-F30 ~ NWP-F121HL

		/** @type {string} */
		companyName: "", // Neosmartpen_A1 ...

		/** @type {string} */
		firmwareVer: "", // "1.00"

		/** @type {number} */
		protocolVer: 100, // 1.00 ==> 100

		/** @type {DeviceTypeEnum} */
		deviceType: DeviceTypeEnum.NONE,

		/** @type {string} */
		mac: "00:00:00:00:00:00",

		/** @type {number} */
		companyCode: 0, // 0

		/** @type {number} */
		productCode: 0, // 0

		/** @type {number} */
		colorCode: 0, // device color, 0
	};

	isPenDown = false;

	penCommBase: NeoBtDeviceBase;

	penHandler: INeoSmartpen;


	private _shouldProcessReqOnlineData = false;

	private _connectOptions = {
		_requestOnlineDataOnConencted: false
	}



	private _strokeStartTime = 0;

	private _currentTime = 0;

	private _resetDeviceInfo = false;

	private initialStatus: IPennCommEvnetStatus;

	public getPenStatus = () => this.initialStatus;

	private pendingUpdateStatus: { [status: number]: number } = {};

	private systemPerformanceStatus: IPennCommEvnetSystemPerformance;

	private pendingUpdatePerformanceStatus: { [status: number]: number } = {};

	/* #region for process Offline data , 2022/05/17 */

	public penMaxForce = DEFAULT_MAX_FORCE + 1;

	private reCheckPassword = false;

	private newPassword = "";

	/* #endregion */

	/* #region for firmware update , 2023/06/30 */
	private fwByteUtil: ByteUtil;

	private isFwCompress = false;

	private packetSize = 0;
	/* #endregion */

	constructor(penHandler: INeoSmartpen, bluetoothType: BluetoothTypeEnum) {
		// super();
		this.bluetoothType = bluetoothType;

		if (bluetoothType === BluetoothTypeEnum.WEB) {
			this.penCommBase = new NeoBTDeviceWeb(this, MauiPenCommManager.instance);
		} else {
			this.penCommBase = new NeoBTDeviceMaui(this, MauiPenCommManager.instance);
		}

		this.penHandler = penHandler;
		this.setTimeStamp(0);

		this.initialStatus = {
			isPwLocked: false,
			retryLimit: 10,
			currentRetryCount: 0,
			penTime: 0,
			autoPowerOffTimeMinutes: 20,
			maxPressureValue: 0,
			storageUsagePercent: 0,
			autoPenCapOnOff: false,
			autoPowerOn: false,
			beepOn: false,
			hoverModeOn: false,
			batteryLevelPercent: 0,
			isSupportOfflineStore: false,
			pressureSensitivity: 0,
			usbMode: PenUsbMode.BULK,
			isPathSimplifyOn: false,
			btLocalName: "",
			isStoreErrorLogOn: false,
			nowRecharging: false,
		}
	}


	// 2022/06/27
	private get isNowConnecting() { return (!!this._connectionTask.promise) }

	private get lastReceivedPacketCmd() { return (this._connectionTask.lastReceivedPacketCmd || 0) }
	private set lastReceivedPacketCmd(value: number) { this._connectionTask.lastReceivedPacketCmd = value; }

	private onConnectingProtocolTimeout = () => {
		if (this._connectionTask?.promise) {
			if (this._connectionTask.resolve) {
				console.log(`awaited ======== connection resolved: false,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
				this._connectionTask.resolve(false);
			}
			this._connectionTask = {};
		}
	}

	// 2022/06/27
	private clearProtocolTimeout = () => {
		if (this._connectionTask.timer !== undefined && this._connectionTask.timer !== null) {
			window.clearTimeout(this._connectionTask.timer);
		}
	}

	// 2022/06/27
	private setProtocolTimeout = (status: string, ...args) => {
		// console.log(status, args);
		this._connectionTask.status = status;

		this.clearProtocolTimeout();
		this._connectionTask.timer = window.setTimeout(() => {
			this.onConnectingProtocolTimeout();
		}, protocolTimeoutLimit);
	}


	private get strokeStartTime(): number {
		return this._strokeStartTime;
	}

	private set strokeStartTime(value: number) {
		this._currentTime = value;
		this._strokeStartTime = value;
	}

	public setTimeStamp = (value: number) => {
		this._currentTime = value;
		this._strokeStartTime = value;
	}


	public get currentTime(): number {
		return this._currentTime;
	}

	private accTime = (delta: number) => {
		this._currentTime += delta;
	}


	getMac = (): string => this.deviceInfo.mac

	getId = (): string => this.penCommBase.deviceId;

	getModelName = () => this.deviceInfo.modelName;

	getProtocolVer = () => this.deviceInfo.protocolVer;

	getStorageUsage = () => this.initialStatus.storageUsagePercent;

	getBatteryLevel = () => {
		// this.reqConfig_04();
		// sleep(50);
		return this.initialStatus.batteryLevelPercent;
	};

	getRechargingStatus = () => this.initialStatus.nowRecharging;

	getIsPwLocked = () => this.initialStatus.isPwLocked;

	getAutoPowerOn = () => this.initialStatus.autoPowerOn;

	getAutoPenCapOnOff = () => this.initialStatus.autoPenCapOnOff;

	getBeepOn = () => this.initialStatus.beepOn;

	getAutoPowerOffTime = () => this.initialStatus.autoPowerOffTimeMinutes;

	getIsSupportSystemPermance = () => this.systemPerformanceStatus?.isSupported;

	getEchoMode = () => this.systemPerformanceStatus?.isEchoMode;

	private _connectionTask: {
		promise?: Promise<boolean>,
		resolve?: (success: boolean) => void,
		reject?: (reason?: any) => void,
		status?: string,
		timer?: number,

		lastReceivedPacketCmd?: number,
	} = {};


	connect = (args: {
		btDevice?: BluetoothDevice,
		mauiDeviceId?: IPenIdType,
		requestOnlineData: boolean,
		shouldStopScanTask?: boolean
	}): Promise<boolean> => {
		const { btDevice, mauiDeviceId, requestOnlineData, shouldStopScanTask = true } = args;

		// this.btDevice = btDevice;
		this._connectOptions._requestOnlineDataOnConencted = requestOnlineData;

		this._connectionTask = {};
		const promise = new Promise<boolean>((resolve, reject) => {
			this._connectionTask.resolve = resolve;
			this._connectionTask.reject = reject;
		});
		this._connectionTask.promise = promise;

		//
		if (this.bluetoothType === BluetoothTypeEnum.MAUI && !mauiDeviceId) {
			this._connectionTask.reject(new Error("PenComm type is MAUI, but no mauiBtId was given."));
		}

		if (this.bluetoothType === BluetoothTypeEnum.WEB && !btDevice) {
			this._connectionTask.reject(new Error("PenComm type is WEB, but no web bluetooth device was given."));
		}

		//
		this.penCommBase.connect({ btDevice, mauiDeviceId, protocolStartCallback: this.startHandshaking, shouldStopScanTask })
			.then((ret) => {
				if (!ret.success) {
					console.log(`connection failed physically: ${ret.status}`);
					window.setTimeout(() => {
						this.clearProtocolTimeout();

						if (this._connectionTask.resolve) {
							this._connectionTask.resolve(false);
						}
						this._connectionTask = {};
					}, 50);
				}
			});

		return this._connectionTask.promise;
	}


	registerConnectedMauiBluetooth = (args: {
		mauiDeviceId?: IPenIdType,
		requestOnlineData: boolean
	}): boolean => {
		const { mauiDeviceId, requestOnlineData } = args;

		// this.btDevice = btDevice;
		this._connectOptions._requestOnlineDataOnConencted = requestOnlineData;


		const ret = this.penCommBase.registerConnectedMauiBluetooth(mauiDeviceId, this.starGetPenInfo);

		if (!ret.success) {
			console.log(`connection failed physically: ${ret.status}`);
			return false;
		}

		return true;
	}


	private disconnectTask: {
		promise?: Promise<void>,
		resolve?: () => void,
		reject?: () => void,
	}

	disconnect = () => {
		this.disconnectTask = {};
		const disconnectPromise = new Promise<void>((resolve, reject) => {
			this.disconnectTask.resolve = resolve;
			this.disconnectTask.reject = reject;
		});
		this.disconnectTask.promise = disconnectPromise;

		this.penCommBase.disconnect();

		return disconnectPromise;
	}

	writeArray = async (bf: number[]) => {
		const ret = await this.penCommBase.writeArray(bf);
		return ret;
	}

	write = async (buf: Uint8Array) => {
		// buf를 console로 16진수로 dump
		const ret = await this.penCommBase.write(buf);
		return ret;
	}

	onDisconnected = () => {
		// if connection on progress, 2022/06/27
		console.log(`awaited ######## onDisconnect, isNowConnecting: ${this.isNowConnecting},  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


		if (this.disconnectTask?.resolve) {
			this.disconnectTask.resolve();

			this.disconnectTask = {};
		}

		if (this.isNowConnecting) {
			console.log(`awaited ==== physically disconnected during finding services`);
			console.error(`awaited ==== physically disconnected during finding services`);

			this._connectionTask?.resolve(false);
			this._connectionTask = {};
		}

		if (this.reqOfflineDataTask?.reject) {
			// this.reqOfflineDataTask?.reject?.(new Error('Disconnected'));
			this.reqOfflineDataTask?.resolve?.(null);
			this.reqOfflineDataTask = {};

		}

		if (this.reqOfflineDataListTask?.reject) {
			// this.reqOfflineDataListTask?.reject?.(new Error('Disconnected'));
			this.reqOfflineDataListTask?.resolve?.(null);
			this.reqOfflineDataListTask = {};
		}


		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.ON_DISCONNECTED, { timeStamp: this.currentTime });
		this.penHandler.onDisconnected(e);

	}

	// 2022/05/10
	process_62_shutdown_event = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x62)
		 * 2: 2 - length
		 *
		 * 4: 1 - reason (0: auto power off, 1: low battery, 2: update, 3: power key, 4: pen cap off, 5: alert, 6: usb disk in(BT disconnected), 7: password fail)
		 */
		const reason = buf[4];

		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.ON_SHUTDOWN_EVENT, { timeStamp: this.currentTime, powerOffEvent: reason });
		this.penHandler.onShutdownEvent(e);
	}

	process_61_low_battery_event = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x61)
		 * 2: 2 - length
		 *
		 * 4: 1 - battery (%)
		 */
		const battery = buf[4];

		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.ON_LOW_BATTERY_EVENT, { timeStamp: this.currentTime, batteryAlarmLevelPercent: battery });
		this.penHandler.onLowBatteryEvent(e);
	}


	reqVersion_01 = () => {
		// CMD 0x01
		console.log(`awaited --> 0x01, reqVersion_01 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		this.write(bufferArray_first);
	}

	reqPassword_02 = (passcode: string) => {

		if (passcode == null) { return; }

		if (passcode === DEFAULT_PASSWORD) { return; }

		console.log(`awaited --> 0x02, reqPassword_02 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


		this.newPassword = passcode;
		const passBytes = toUTF8Array(passcode, 16);
		const bf = [
			PEN_PACKET_START,
			Cmd.PASSWORD_REQUEST, // 0x02,
			0x10, 0x00,
			...passBytes,
			PEN_PACKET_END
		];
		this.writeArray(bf);
	}

	reqSetUpPassword_03 = (oldPassword: string, newPassword = "") => {
		if (oldPassword == null || newPassword == null) { return false; }

		// 새 비밀번호를 설정할 때에는 oldPw 에 DEFAULT_PASSWORD 인 0000 을 넣어야한다.
		// if (oldPassword === DEFAULT_PASSWORD) { return false; }
		if (newPassword === DEFAULT_PASSWORD) { return false; }

		this.newPassword = newPassword;

		const oldBytes = toUTF8Array(oldPassword, 16);
		const newBytes = toUTF8Array(newPassword, 16);

		console.log(`awaited --> 0x03, reqSetUpPassword_03 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);



		const bf = [
			PEN_PACKET_START,
			Cmd.PASSWORD_CHANGE_REQUEST, // 0x03,
			33, 0x00,
			newPassword === "" ? 0 : 1,
			...oldBytes,
			...newBytes,
			PEN_PACKET_END
		];

		this.writeArray(bf);
		return true;
	}


	reqConfig_04 = () => {
		// CMD 0x04, 펜 설정을 확인하는 패킷을 보낸다. request pen status
		console.log(`awaited --> 0x04, reqConfig_04 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


		const bf = [
			PEN_PACKET_START,
			Cmd.SETTING_INFO_REQUEST,
			0x00, 0x00,
			PEN_PACKET_END
		];
		this.writeArray(bf);
	}

	requestSetInfo = () => {
		this._resetDeviceInfo = true;

		console.log(`awaited --> requestSetInfo ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		this.reqConfig_04();
	}



	reqChangeSetting_05 = async (type: SettingType, value: any, needReturn?: boolean) => {
		/**
		 * 0: 1 - header
		 * 1: 1 - cmd (0x05)
		 * 2: 2 - length (0x02)
		 * 4: 1 - attribute type (SettingType)
		 * 5: n - value
		 */



		const bf = [
			PEN_PACKET_START,
			Cmd.SETTING_CHANGE_REQUEST, // 0x05,
		];

		let tmp: number[];

		console.log(`awaited --> 0x05, reqChangeSetting_05, SettingType.${SettingType[type]} ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


		switch (type) {
			case SettingType.Timestamp:
				bf.push(...int16ToBytes(9));
				bf.push(type);
				bf.push(...int64ToBytes(value as number));
				break;

			case SettingType.AutoPowerOffTime:
				bf.push(...int16ToBytes(3));
				bf.push(type);
				bf.push(...int16ToBytes(value as number));
				break;

			case SettingType.LedColor:
				bf.push(...int16ToBytes(5));
				bf.push(type);

				tmp = int32ToBytes(value as number);
				bf.push(...[tmp[3], tmp[2], tmp[1], tmp[0]]);
				break;

			case SettingType.PenCapOff:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				bf.push(value as number ? 1 : 0);
				break;

			case SettingType.AutoPowerOn:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				bf.push(value as number ? 1 : 0);
				break;

			case SettingType.Beep:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				bf.push(value as number ? 1 : 0);
				break;

			case SettingType.Hover:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				bf.push(value as number ? 1 : 0);
				break;

			case SettingType.DownSampling:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				bf.push(value as number ? 1 : 0);
				break;

			case SettingType.OfflineData:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				// offline store mode (0x00: off, 0x01: save offline, 0x0A: both of realtime and offline)
				bf.push(value as number ? 1 : 0);
				break;

			case SettingType.Sensitivity:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				bf.push(value as number);
				break;

			case SettingType.UsbMode:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				bf.push(value as number);
				break;

			case SettingType.BtLocalName:
				bf.push(...int16ToBytes(18));
				bf.push(type);
				tmp = toUTF8Array(value as string, 16);
				bf.push(...tmp);
				break;

			case SettingType.FscSensitivity:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				bf.push(value as number & 0xff);
				break;

			case SettingType.DataTransmissionType:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				bf.push(value as number & 0xff);
				break;

			case SettingType.BeepAndLight:
				bf.push(...int16ToBytes(2));
				bf.push(type);
				bf.push(0);
				break;

			case SettingType.SetPenStatus:
				bf.push(...int16ToBytes(5));
				bf.push(type);
				bf.push(value);
				bf.push(0x0B);
				bf.push(0x1C);
				bf.push(0x4F);
				break;

			default:
				break;
		}

		this.pendingUpdateStatus[type] = value;

		bf.push(PEN_PACKET_END);
		if (needReturn) {
			return await this.writeArray(bf);
		}
		this.writeArray(bf);
	}

	/* #region Offline Data */
	reqOnlineData_11 = () => {
		console.log(`awaited --> 0x11, reqOnlineData_11,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
		const bf = [
			PEN_PACKET_START,
			Cmd.ONLINE_DATA_REQUEST, // 0x11,
			0x02, 0x00, 0xff, 0xff,
			PEN_PACKET_END
		];
		this.writeArray(bf);
	}

	/**
	 * request the list of Offline data by section, owner, note (or all)
	 * @param section
	 * @param owner
	 * @param book
	 */

	public reqOfflineDataListTask: {
		promise?: Promise<OfflineDataInfo[]>,
		resolve?: any,
		reject?: any
	} = {};

	reqOfflineDataList = (section?: number, owner?: number, book?: number, needPageData = false): Promise<OfflineDataInfo[]> => {
		if (section === undefined) section = -1;
		if (owner === undefined) owner = -1;

		if (this.penHandler.connected === false) {
			return new Promise<OfflineDataInfo[]>((resolve, reject) => {
				reject("Pen is not connected")
			});
		}

		const promise = new Promise<OfflineDataInfo[]>((resolve, reject) => {
			this.reqOfflineDataListTask.resolve = resolve;
			this.reqOfflineDataListTask.reject = reject;
		});
		this.reqOfflineDataListTask.promise = promise;


		if (book === undefined) {
			// 이 경우 response인 0xa1는 note list (즉, book 까지만) 받아온다
			this.reqOfflineDataList_21(section, owner);
		} else {
			if (!needPageData) {
				// 이 경우 response인 0xa1는 note list (즉, book 까지만) 받아온다
				this.reqOfflineDataList_22(section, owner, book);
			} else {
				if (this.deviceInfo.protocolVer < 216) {
					// 128개까지 가져오는 것, page ID가 16bit
					this.reqOfflineDataList_22(section, owner, book);
				}
				else {
					// FIXME: 펌웨어에서 pageID를 늘려야 한다. 2024-11-10, kitty
					// 12800개까지 가져오는 것, pageID가 8bit(?),
					this.reqOfflineDataList_26(section, owner, book);
				}
			}
		}

		return promise;
	}

	/**
	 * request the list of Offline data by section, owner (or all)
	 * @param section
	 * @param owner
	 */

	reqOfflineDataList_21 = (section = -1, owner = -1) => {
		console.log(`awaited --> 0x21, reqOfflineDataList_21,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


		const bf = [
			PEN_PACKET_START,
			Cmd.OFFLINE_NOTE_LIST_REQUEST, // 0x21,
			0x04, 0x00
		];

		bf.push(...getSectionOwnerBytes(section, owner));
		bf.push(PEN_PACKET_END);

		this.writeArray(bf);
	}


	/**
	 * request the list of Offline data by each note
	 * @param section
	 * @param owner
	 * @param book
	 */

	reqOfflineDataList_22 = (section: number, owner: number, book: number) => {
		console.log(`awaited --> 0x22, reqOfflineDataList_22,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);



		const bf = [
			PEN_PACKET_START,
			Cmd.OFFLINE_PAGE_LIST_REQUEST, // 0x22,
			0x08, 0x00
		];



		bf.push(...getSectionOwnerBytes(section, owner));
		bf.push(...int32ToBytes(book));
		bf.push(PEN_PACKET_END);
		this.writeArray(bf);
	}

	/**
	 * request the list of Offline data by each note
	 * @param section
	 * @param owner
	 * @param book
	 */
	reqOfflineDataList_26 = (section: number, owner: number, book: number) => {
		console.log(`awaited --> 0x26, reqOfflineDataList_26,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


		const bf = [
			PEN_PACKET_START,
			Cmd.OFFLINE_PAGE_LIST_LARGE_REQUEST, // 0x26,
			0x08, 0x00
		];

		bf.push(...getSectionOwnerBytes(section, owner));
		bf.push(...int32ToBytes(book));
		bf.push(PEN_PACKET_END);
		this.writeArray(bf);
	}


	onReceiveOfflineDataList = (infos: OfflineDataInfo[]) => {
		this.setTimeStamp((new Date()).getTime());

		const e = makePenEvent(
			this.deviceInfo.deviceType,
			PenCommEventEnum.ON_OFFLINE_DATA_LIST_DELIVERED,
			{
				timeStamp: this.currentTime,
				offlineDataList: infos,
			}
		);

		if (this.reqOfflineDataListTask?.resolve) {
			this.reqOfflineDataListTask?.resolve(infos);
			this.reqOfflineDataListTask = {};
		} else {
			console.error(`pencomm.ts: onReceiveOfflineDataList: reqOfflineDataListTask is null`);
		}

		this.penHandler?.onOfflineDataListDelivered(e);
	}




	/**
	 * request the list of Offline data by pages
	 * @param s
	 * @param o
	 * @param b
	 * @param deleteOnFinished
	 * @param pgs
	 */

	public reqOfflineDataTask: {
		promise?: Promise<{ mTotalOfflineStroke: number, mTotalOfflineDataSize: number, strokes?: NeoStroke[] }>,
		resolve?: any,
		reject?: any
	} = {};



	/* #region Receiving the offline data main */
	public mReceivedOfflineStroke = 0;

	public mTotalOfflineStroke = -1;

	public mTotalOfflineDataSize = -1;

	public offlineDataPacketRetryCount = 0;

	public offlineStrokes: NeoStroke[] = [];

	// public offlineFilterForPaper: FilterForPaper = null;




	reqOfflineData = (s: number, o: number, b: number, pgs?: number[], deleteOnFinished = true): Promise<{
		mTotalOfflineStroke: number;
		mTotalOfflineDataSize: number;
		strokes?: NeoStroke[];
	}> => {
		console.log(`offline data downloading: ${s}.${o}.${b}.${pgs?.length}`);
		return this.reqOfflineData_23(s, o, b, pgs, deleteOnFinished);
	}



	reqOfflineData_23 = (section: number, owner: number, note: number, pages?: number[], deleteOnFinished = true): Promise<{
		mTotalOfflineStroke: number;
		mTotalOfflineDataSize: number;
		strokes?: NeoStroke[];
	}> => {
		this.offlineStrokes = [];

		if (this.penHandler.connected === false) {
			return new Promise<{ mTotalOfflineStroke: number, mTotalOfflineDataSize: number, strokes?: NeoStroke[] }>((resolve, reject) => {
				reject("Pen is not connected")
			});
		}

		const promise = new Promise<{ mTotalOfflineStroke: number, mTotalOfflineDataSize: number, strokes?: NeoStroke[] }>(
			(resolve, reject) => {
				console.log("promise start");
				this.reqOfflineDataTask.resolve = resolve;
				this.reqOfflineDataTask.reject = reject;
			}
		);
		this.reqOfflineDataTask.promise = promise;


		// request 본체
		let length = 14;
		length += (pages == null ? 0 : pages.length * 4);

		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.OFFLINE_DATA_REQUEST); //0x23

		bf.push(...int16ToBytes(length));
		bf.push(deleteOnFinished ? 1 : 2);
		bf.push(1); // compress:1, uncompress:0
		bf.push(...getSectionOwnerBytes(section, owner));
		bf.push(...int32ToBytes(note));
		bf.push(...int32ToBytes(pages == null ? 0 : pages.length));

		if (pages != null) {
			pages.forEach((page) => {
				bf.push(...int32ToBytes(page));
			});
		}
		bf.push(PEN_PACKET_END);

		// this.writeWithRetry(new Uint8Array(bf));
		sleep(10).then(() => {
			this.write(new Uint8Array(bf)).then((ret) => {
				if (ret) {
					console.log("write succeeded");
				} else {
					this.reqOfflineDataTask?.reject(new Error("GATT retry count failed"));
					this.reqOfflineDataTask = {};
				}
			}).catch(() => {
				console.log("ReqOfflineData error");
			});
		});

		return promise;
	}



	onStartOfflineDownload = () => {
		this.setTimeStamp((new Date()).getTime());

		const e = makePenEvent(
			this.deviceInfo.deviceType,
			PenCommEventEnum.ONSTARTOFFLINEDOWNLOAD,
			{
				timeStamp: this.currentTime,
			}
		);
		this.penHandler.onStartOfflineDownload(e);
	}


	processOfflinePacketReceived_24 = async (packet: Packet) => {
		console.log(`awaited    <-- 0x24, processOfflinePacketReceived_24,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);



		const assembled = await processOfflinePacketReceived_main(this, packet);
		const { success, strokes, packetId, packetLocation } = assembled;

		switch (success) {
			case OfflineDataResultType.UNCOMPRESS_ERROR:
			case OfflineDataResultType.PACKET_SIZE_ERROR:
				if (this.offlineDataPacketRetryCount < 3) {
					this.sendOfflinePacketResponse_a4(packetId, false);
					this.offlineDataPacketRetryCount++;
				} else {
					this.offlineDataPacketRetryCount = 0;
					// pencomm.onFinishedOfflineDownload(false);
					this.onFinishedOfflineDownload(packetLocation === 2);
				}
				break;

			case OfflineDataResultType.DOT_DATA_CHECKSUM_ERROR:
				this.offlineDataPacketRetryCount = 0;
				this.onFinishedOfflineDownload(packetLocation === 2);
				break;

			case OfflineDataResultType.SUCCESS:
				{
					this.offlineStrokes.push(...strokes);
					this.sendOfflinePacketResponse_a4(packetId);
					this.offlineDataPacketRetryCount = 0;

					this.setTimeStamp((new Date()).getTime());
					const event = makePenEvent(
						this.deviceInfo.deviceType,
						PenCommEventEnum.ON_OFFLINE_DATA_PART_DELIVERED,
						{
							timeStamp: this.currentTime,
							offlineData: {
								totalStrokeCount: this.mTotalOfflineStroke,
								receivedStrokeCount: this.mReceivedOfflineStroke,
								strokes
							}
						}
					);
					this.penHandler.onOfflineDataDelivered(event);
				}
				break;

			default:
				break;
		}

		if (packetLocation === 2) {
			this.onFinishedOfflineDownload(true);
		}
	}


	sendOfflinePacketResponse_a4 = (/* short */ index: number, isSuccess = true) => {
		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.OFFLINE_PACKET_RESPONSE); //0xa4
		bf.push(isSuccess ? 0 : 1);
		bf.push(...int16ToBytes(3));
		bf.push(...int16ToBytes(index));
		bf.push(1);
		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0xa4, sendOfflinePacketResponse_a4,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
		this.writeArray(bf);
	}


	onFinishedOfflineDownload = (success: boolean) => {
		this.setTimeStamp((new Date()).getTime());

		const e = makePenEvent(
			this.deviceInfo.deviceType,
			PenCommEventEnum.ONFINISHEDOFFLINEDOWNLOAD,
			{
				timeStamp: this.currentTime,
				success,
				offlineData: {
					totalStrokeCount: this.mTotalOfflineStroke,
					receivedStrokeCount: this.mReceivedOfflineStroke,
					strokes: this.offlineStrokes,
				}
			}
		);
		this.penHandler.onFinishedOfflineDownload(e);

		if (success) {
			this.reqOfflineDataTask?.resolve({
				mTotalOfflineStroke: this.mTotalOfflineStroke,
				mTotalOfflineDataSize: this.mTotalOfflineDataSize,
				strokes: this.offlineStrokes,
			});
			this.reqOfflineDataTask = {};
		}
	}

	/* #endregion */






	reqRemoveOfflineData_25 = (section: number, owner: number, notes: number[]) => {
		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.OFFLINE_DATA_DELETE_REQUEST);

		const length = (5 + (notes.length * 4));
		bf.push(...int16ToBytes(length));
		bf.push(...getSectionOwnerBytes(section, owner));
		bf.push(notes.length);

		notes.forEach((note) => {
			bf.push(...int32ToBytes(note));
		});

		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0x25, reqRemoveOfflineData_25,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
		this.writeArray(bf);
	}


	/* #region Firmware Upgrade */

	firmwareUpgrade = async (fwVersion: string, isCompress: boolean, firmwareFileUrl: string) => {
		const deviceName = this.deviceInfo.modelName;

		// if (this.deviceInfo.protocolVer < 222) {
		//   if (
		//     deviceName === 'NWP-F151' ||
		//     deviceName === 'NWP-F63' ||
		//     deviceName === 'NWP-F53' ||
		//     deviceName === 'NEP-E100' ||
		//     deviceName === 'NEP-E101' ||
		//     deviceName === 'NSP-D100' ||
		//     deviceName === 'NSP-D101' ||
		//     deviceName === 'NSP-C200' ||
		//     deviceName === 'NPP-P201'
		//   ) {
		//     this.isFwCompress = false;
		//   } else {
		//     this.isFwCompress = isCompress;
		//   }
		// } else {
		//   if (this.isFwCompress) {
		//     this.isFwCompress = isCompress;
		//   }
		// }

		if (deviceName === 'NWP-F50' || deviceName === 'NWP-F70' || deviceName === 'NWP-F121C') {
			this.isFwCompress = true;
		} else {
			this.isFwCompress = false;
		}

		if (deviceName === 'NSP-D100' || deviceName === 'NSP-D101' || deviceName === 'NSP-C200') {
			this.packetSize = 128;
		} else {
			this.packetSize = 2 * 1024;
			// this.packetSize = 128;
		}

		await this.loadFirmwareFile(firmwareFileUrl);

		this.reqPenSwUpgrade_31(deviceName, fwVersion);
	}

	loadFirmwareFile = async (url: string) => {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error(`[FW] received fw upload status: ${response.status}`);
		}
		const firmwareArrayBuffer = await response.arrayBuffer();
		const firmwareUint8Array = new Uint8Array(firmwareArrayBuffer);

		this.fwByteUtil = new ByteUtil({ data: firmwareUint8Array, packetSize: this.packetSize });
	}

	cancelFirmwareUpgrade = async () => {
		this.fwByteUtil = null;
		this.packetSize = 0;
		this.isFwCompress = false;

		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.FIRMWARE_PACKET_RESPONSE); // 0xb2
		bf.push(0);
		bf.push(...int16ToBytes(1));
		bf.push(1);
		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0xb2, cancelFirmwareUpgrade ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


		this.writeArray(bf);
	}

	reqPenSwUpgrade_31 = (deviceName: string, fwVersion: string) => {
		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.FIRMWARE_UPLOAD_REQUEST); // 0x31
		bf.push(...int16ToBytes(42));
		bf.push(...toUTF8Array(deviceName, 16));
		bf.push(...toUTF8Array(fwVersion, 16));
		bf.push(...int32ToBytes(this.fwByteUtil.Size));
		bf.push(...int32ToBytes(this.packetSize));
		bf.push(this.isFwCompress ? 1 : 0);
		bf.push(this.fwByteUtil.GetChecksum(this.fwByteUtil.Size));
		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0x31, reqPenSwUpgrade_31 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		this.writeArray(bf);
	}

	reqPenSwUpload_b2 = async (status: number, offset: number, data: Uint8Array) => {
		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.FIRMWARE_PACKET_RESPONSE); // 0xb2

		if (status === FwUpgrade.FIRMWARE_PACKET_STATUS_ERROR) {
			bf.push(1); // errorCode ( 0 = SUCCESS, 1 = ERROR )
		} else {
			bf.push(0); // errorCode ( 0 = SUCCESS, 1 = ERROR )

			const beforeCompressSize = data.length;
			let afterCompressSize = 0;
			let compressData: Uint8Array;
			if (this.isFwCompress) {
				compressData = await neoZip(data);
				afterCompressSize = compressData.length;
			} else {
				compressData = data;
			}

			bf.push(...int16ToBytes(14 + compressData.length));
			bf.push(0); // isTransfer ( 0 = continue, 1: cancel )
			bf.push(...int32ToBytes(offset));
			bf.push(this.fwByteUtil.GetChecksum(this.packetSize));
			bf.push(...int32ToBytes(beforeCompressSize));
			bf.push(...int32ToBytes(afterCompressSize));
			bf.push(...compressData);
		}

		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0xb2, reqPenSwUpload_b2 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		this.writeArray(bf);
	}

	/* #endregion */


	/* #region Pen profile */

	reqCreateProfile_65 = (profileName: Uint8Array, password: Uint8Array) => {
		const bf: number[] = [];

		bf.push(PEN_PACKET_START);
		bf.push(Cmd.PEN_PROFILE_REQUEST); // command
		bf.push(...int16ToBytes(PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME + 1 + PenProfile.LIMIT_BYTE_LENGTH_PASSWORD + 2 + 2)); // length
		bf.push(...Array.from(profileName), PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME); // profile file name
		bf.push(PenProfile.PROFILE_CREATE); // type
		bf.push(...Array.from(password), PenProfile.LIMIT_BYTE_LENGTH_PASSWORD); // password
		bf.push(...int16ToBytes(32)); // section 크기 -> 32인 이유? 우선 android따라감. 확인필요
		bf.push(...int16ToBytes(32)); // sector 개수(2^N 현재는 고정 2^8)
		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0x65, reqCreateProfile_65 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		this.writeArray(bf);
	}

	reqDeleteProfile_65 = (profileName: Uint8Array, password: Uint8Array) => {
		const bf: number[] = [];

		bf.push(PEN_PACKET_START);
		bf.push(Cmd.PEN_PROFILE_REQUEST) // command
		bf.push(...int16ToBytes((PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME + 1 + PenProfile.LIMIT_BYTE_LENGTH_PASSWORD))) // length
		bf.push(...Array.from(profileName), PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME) // profile file name
		bf.push(PenProfile.PROFILE_DELETE) // type
		bf.push(...Array.from(password), PenProfile.LIMIT_BYTE_LENGTH_PASSWORD) // password
		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0x65, reqDeleteProfile_65 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		this.writeArray(bf);
	}

	reqProfileInfo_65 = (profileName: Uint8Array) => {
		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.PEN_PROFILE_REQUEST) // command
		bf.push(...int16ToBytes((PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME + 1))); // length
		bf.push(...Array.from(profileName), PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME) // profile file name
		bf.push(PenProfile.PROFILE_INFO) // type
		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0x65, reqProfileInfo_65 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		this.writeArray(bf);
	}

	reqWriteProfileValue_65 = (
		profileName: Uint8Array,
		password: Uint8Array,
		keys: Uint8Array[],
		data: Uint8Array[]
	) => {
		let dataLength = 0;
		const dataCount = data.length;
		for (let i = 0; i < dataCount; ++i) {
			dataLength += PenProfile.LIMIT_BYTE_LENGTH_KEY; // key
			dataLength += 2; // data length
			dataLength += data[i].length; // data
		}

		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.PEN_PROFILE_REQUEST) // command
		bf.push(...int16ToBytes((PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME + 1 + PenProfile.LIMIT_BYTE_LENGTH_PASSWORD + 1 + dataLength))); // length
		bf.push(...Array.from(profileName), PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME) // profile file name
		bf.push(PenProfile.PROFILE_WRITE_VALUE) // type
		bf.push(...Array.from(password), PenProfile.LIMIT_BYTE_LENGTH_PASSWORD) // password
		bf.push(dataCount); // count

		for (let i = 0; i < dataCount; ++i) {
			bf.push(...Array.from(keys[i]), PenProfile.LIMIT_BYTE_LENGTH_KEY)
			bf.push(...int16ToBytes(data[i].length));
			bf.push(...Array.from(data[i]));
		}

		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0x65, reqWriteProfileValue_65 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		this.writeArray(bf);
	}

	reqReadProfileValue_65 = (profileName: Uint8Array, keys: Uint8Array[]) => {
		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.PEN_PROFILE_REQUEST) // command
		bf.push(...int16ToBytes((PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME + 1 + 1 + PenProfile.LIMIT_BYTE_LENGTH_KEY * keys.length))); // Length
		bf.push(...Array.from(profileName), PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME) // profile file name
		bf.push(PenProfile.PROFILE_READ_VALUE) // Type
		bf.push(keys.length); // Key Count

		for (let i = 0; i < keys.length; ++i) {
			bf.push(...Array.from(keys[i]), PenProfile.LIMIT_BYTE_LENGTH_KEY);
		}

		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0x65, reqReadProfileValue_65 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		this.writeArray(bf);
	}

	reqDeleteProfileValue_65 = (profileName: Uint8Array, password: Uint8Array, keys: Uint8Array[]) => {

		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.PEN_PROFILE_REQUEST) // command
		bf.push(...int16ToBytes((PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME + 1 + PenProfile.LIMIT_BYTE_LENGTH_PASSWORD + 1 + PenProfile.LIMIT_BYTE_LENGTH_KEY * keys.length))); // Length
		bf.push(...Array.from(profileName), PenProfile.LIMIT_BYTE_LENGTH_PROFILE_NAME) // profile file name
		bf.push(PenProfile.PROFILE_DELETE_VALUE) // Type
		bf.push(...Array.from(password), PenProfile.LIMIT_BYTE_LENGTH_PASSWORD) // password
		bf.push(keys.length); // key count

		for (let i = 0; i < keys.length; ++i) {
			bf.push(...Array.from(keys[i]), PenProfile.LIMIT_BYTE_LENGTH_KEY);
		}

		bf.push(PEN_PACKET_END);

		console.log(`awaited --> 0x65, reqDeleteProfileValue_65 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		this.writeArray(bf);
	}
	/* #endregion */


	/* #region Systme Control */

	reqGetSystemPerformance_07 = () => {
		console.log(`awaited --> 0x07, reqGetSystemPerformance_07 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


		const bf = [
			PEN_PACKET_START,
			Cmd.SYSTEM_INFO_REQUEST,
			0x00, 0x00,
			PEN_PACKET_END
		];
		this.writeArray(bf);
	}

	reqSetSystemPerformance_06 = (isEchoMode: boolean) => {
		console.log(`awaited --> 0x06, reqSetSystemPerformance_06 ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


		const bf: number[] = [];
		bf.push(PEN_PACKET_START);
		bf.push(Cmd.SYSTEM_CHANGE_REQUEST); // command 0x06
		bf.push(...int16ToBytes(9)); // Length
		bf.push(1); // Type = set performance(=eco mode) = 1
		bf.push(...int32ToBytes(0)); // Reserved (4bytes, uint8[])
		// MT2523 모델
		// 0 : 최대 104 MHz, 43 frame
		// 1 : 최대 208 MHz, 86 frame
		bf.push(...int32ToBytes(isEchoMode ? 0 : 1)); // Echo Mode (0: ON, 1: OFF)
		bf.push(PEN_PACKET_END);

		this.pendingUpdatePerformanceStatus[1] = isEchoMode ? 0 : 1;

		this.writeArray(bf);
	}

	/* #endregion */



	// PROCESS A UNIT PACKET GOTTEN FROm PEN
	//
	// unit packet should be start with 0xC0 and end by 0xC1
	processUnitPacket = (unit_packet: Uint8Array) => {
		// console.log(`received packet length = ${unit_packet.length}`);
		// const this = this;
		const cmd = unit_packet[1];

		// console.log(` processUnitPacket: 0x${cmd.toString(16)} size(${unit_packet.length})`);
		switch (cmd) {
			case Cmd.VERSION_RESPONSE: // 0x81:
				console.log(`awaited    <-- ${cmd}, 0x81, process_81_device_first_info,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				this.process_81_device_first_info(unit_packet);
				break;

			// /* #region request pen data */
			// case Cmd.ONLINE_PEN_DATA_REQUEST: // 0x12
			// // TODO: 여기 할 것 채워 넣기, V200 에서는 없다
			// break;
			// /* #endregion */

			/* #region Event */
			case Cmd.SHUTDOWN_EVENT: // 0x62
				console.log(`awaited    <-- ${cmd}, 0x62, process_62_shutdown_event,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				this.process_62_shutdown_event(unit_packet);
				break;


			case Cmd.LOW_BATTERY_EVENT: // 0x61
				console.log(`awaited    <-- ${cmd}, 0x61, process_61_low_battery_event,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


				this.process_61_low_battery_event(unit_packet);
				break;

			case Cmd.ONLINE_PEN_UPDOWN_EVENT: // 0x63:
				this.process_63_penupdown_v100(unit_packet);
				break;

			case Cmd.ONLINE_PAPER_INFO_EVENT: // 0x64:
				this.process_64_pageinfo_v100(unit_packet);
				break;

			case Cmd.ONLINE_PEN_DOT_EVENT_V100: // 0x65:
				this.process_65_penmove_v100(unit_packet);
				break;

			case Cmd.ONLINE_PEN_DOT_EVENT_V200: // 0x66:
				this.process_66_penmove_simple_v200(unit_packet);
				break;

			case Cmd.ONLINE_PEN_ERROR_EVENT: // 0x68:
				console.log(`awaited    <-- ${cmd}, 0x68, process_68_ndac_error_v100,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				this.process_68_ndac_error_v100(unit_packet);
				break;

			case Cmd.ONLINE_NEW_PEN_DOWN_EVENT: // 0x69:
				this.process_69_pendown_v212(unit_packet);
				break;

			case Cmd.ONLINE_NEW_PEN_UP_EVENT: // 0x6a:
				this.process_6a_penup_v212(unit_packet);
				break;

			case Cmd.ONLINE_NEW_PAPER_INFO_EVENT: // 0x6b:
				this.process_6b_pageinfo_v212(unit_packet);
				break;

			case Cmd.ONLINE_NEW_PEN_DOT_EVENT: // 0x6c:
				this.process_6c_penmove_v212(unit_packet);
				break;

			case Cmd.ONLINE_PEN_HOVER_MOVE_EVENT: // 0x6f:
				this.process_6f_hovermove_v218(unit_packet);
				break;

			case Cmd.ONLINE_NEW_PEN_ERROR_EVENT: // 0x6d:
				this.process_6d_ndacerror_v212(unit_packet);
				break;

			/* #endregion */


			/* #region Setting response */
			case Cmd.SETTING_INFO_RESPONSE: // 0x84:
				console.log(`awaited    <-- ${cmd}, 0x84, process_84_device_status,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				this.process_84_device_status(unit_packet);
				break;

			case Cmd.SETTING_CHANGE_RESPONSE: // 0x85:
				console.log(`awaited    <-- ${cmd}, 0x85, process_85_config_response,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				this.process_85_config_response(unit_packet);
				break;


			case Cmd.ONLINE_DATA_RESPONSE: // 0x91:
				console.log(`awaited    <-- ${cmd}, 0x91, process_91_realtime_mode_response,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				this.process_91_realtime_mode_response(unit_packet);
				break;

			/* #endregion */



			/* #region Password response */
			case Cmd.PASSWORD_RESPONSE: // 0x82:
				console.log(`awaited    <-- ${cmd}, 0x82, process_82_pw_result,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				this.process_82_pw_result(unit_packet);
				break;

			case Cmd.PASSWORD_CHANGE_RESPONSE: // 0x83
				{
					const packet = new Packet(unit_packet);
					// const cntRetry = packet.GetByteToInt();
					// const cntMax = packet.GetByteToInt();

					if (packet.result_code === 0x00) {
						this.reCheckPassword = true;
						this.reqPassword_02(this.newPassword);
					} else {
						this.newPassword = "";
						this.onPenPasswordSetupResponse(false);
					}
				}
				break;
			/* #endregion */

			/* #region Firmware Upgrade */
			case Cmd.FIRMWARE_UPLOAD_RESPONSE: // 0xb1:
				this.process_b1_fw_upgrade_res(unit_packet);
				break;

			case Cmd.FIRMWARE_PACKET_REQUEST: // 0x32:
				this.process_32_fw_upload_chunk(unit_packet);
				break;
			/* #endregion */

			/* #region Offline response */
			case Cmd.OFFLINE_NOTE_LIST_RESPONSE: // 0xa1
				console.log(`awaited    <-- ${cmd}, 0xa1, onReceiveOfflineDataList,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				{
					const packet = new Packet(unit_packet);

					const length = packet.GetShort();
					const result: OfflineDataInfo[] = [];


					for (let i = 0; i < length; i++) {
						const rb = packet.GetBytes(4);

						const section = (rb[3] & 0xFF);
						const owner = ByteConverter.ByteToInt([rb[0], rb[1], rb[2], 0x00]);
						const note = packet.GetInt();

						result.push(new OfflineDataInfo(section, owner, note));
					}

					this.onReceiveOfflineDataList(result);
				}
				break;

			case Cmd.OFFLINE_PAGE_LIST_RESPONSE: // 0xa2
				console.log(`awaited    <-- ${cmd}, 0xa2, onReceiveOfflineDataList,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				{
					const packet = new Packet(unit_packet);

					const rb = packet.GetBytes(4);

					const section = (rb[3] & 0xFF);
					const owner = ByteConverter.ByteToInt([rb[0], rb[1], rb[2], 0x00]);
					const note = packet.GetInt();

					const length = packet.GetShort(); // MAX 128 pages

					const pages = Array.from({ length }, () => 0);
					for (let i = 0; i < length; i++) {
						pages[i] = packet.GetInt();
					}

					const info: OfflineDataInfo = new OfflineDataInfo(section, owner, note, pages);
					this.onReceiveOfflineDataList([info]);
				} break;


			case Cmd.OFFLINE_DATA_RESPONSE: // 0xa3
				console.log(`awaited    <-- ${cmd}, 0xa3, Cmd.OFFLINE_DATA_RESPONSE,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				{
					const packet = new Packet(unit_packet);

					this.mTotalOfflineStroke = packet.GetInt();
					this.mReceivedOfflineStroke = 0;
					this.mTotalOfflineDataSize = packet.GetInt();

					if (this.mTotalOfflineStroke === 0) {
						this.onFinishedOfflineDownload(true);
					} else {
						this.onStartOfflineDownload();
					}
				}
				break;

			case Cmd.OFFLINE_PAGE_LIST_LARGE_RESPONSE: // 0xa6, v2.16
				console.log(`awaited    <-- ${cmd}, 0xa6 Cmd.OFFLINE_PAGE_LIST_LARGE_RESPONSE,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

				{
					const packet = new Packet(unit_packet);

					const status = packet.GetByte(); // 1 = fail, 0 = success

					if (status === 0) {
						const rb = packet.GetBytes(4);
						const section = rb[3] & 0xff;
						const owner = ByteConverter.ByteToInt([rb[0], rb[1], rb[2], 0x00]);
						const note = packet.GetInt();
						const noteVersion = packet.GetShort();

						const reserved = packet.GetBytes(32);

						const invaildPageId = packet.GetByte(); // 1 = There are pages in the page list that are not represented

						const pageCount = packet.GetShort(); // MAX 12800 Pages
						const pageListCount = packet.GetShort(); // ( pageCount / 8 ) byte

						const pages = [];
						for (let i = 0; i < pageListCount; i++) {
							let pl = packet.GetByte(); // Processing by bits
							if (pl === 0) {
								// If a byte is 0, there is no page corresponding to that byte's bit array.
								continue;
							}

							for (let j = 0; j < 8; j++) {
								// The sequence corresponding to each bit array is the page number
								// ex) starting Page = 0, first PageListCount (i = 0)
								// bit   7  6  5  4  3  2  1  0
								// data  0  0  1  0  0  0  1  0  => 1 Page, 5 Page
								if ((pl % 2) === 1) {
									pages.push(i * 8 + j);
								}
								pl >>= 1; // right bitwise shift (removing the least significant bit)

								if (pl === 0) {
									// there are no more bits to check.
									break;
								}
							}
						}

						const info: OfflineDataInfo = new OfflineDataInfo(section, owner, note, pages);
						this.onReceiveOfflineDataList([info]);
					}
				}
				break;

			case Cmd.OFFLINE_PACKET_REQUEST: // 0x24
				console.log(`awaited    <-- ${cmd}, 0x24 processOfflinePacketReceived_24,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


				{
					const packet = new Packet(unit_packet);
					this.processOfflinePacketReceived_24(packet);
				}
				break;

			case Cmd.SYSTEM_INFO_RESPONSE: // 0x87
				console.log(`awaited    <-- ${cmd}, 0x87 process_87_system_setting,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


				this.process_87_system_setting(unit_packet)
				break;

			case Cmd.SYSTEM_CHANGE_RESPONSE: // 0x86
				console.log(`awaited    <-- ${cmd}, 0x86 process_86_system_setting_change,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);


				this.process_86_system_setting_change(unit_packet);
				break;
			/* #endregion */

			default:
				break;
		}
	}

	private startHandshaking = () => {
		if (this.isNowConnecting) {
			this.setProtocolTimeout(`awaited BT protocol #1 -> send the first packet, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

			// Protocol handshaking은 다음과 같다.
			//
			// reqVersion_01 >>>
			//                <<< process_81_device_first_info
			// reqGetSystemPerformance_07 >>>
			//               <<< process_87_system_setting
			// reqConfig_04 >>>
			//               <<< process_84_device_status

			this.reqVersion_01();
		} else {
			// this.disconnect();
		}
	}


	starGetPenInfo = async () => {
		const promise = new Promise<boolean>((resolve, reject) => {
			this._connectionTask.resolve = resolve;
			this._connectionTask.reject = reject;
		});
		this._connectionTask.promise = promise;


		this.setProtocolTimeout(" BT protocol GET INFO -> send the first packet");
		this.reqVersion_01();
	}

	/**
	 * process response packet from cmd 0x01
	 * 디바이스이름, 펌웨어버전, 회사 이름, 펜 종류, 필압센서 종류 등의 reposnse
	 *
	 * @param buf
	 */
	process_81_device_first_info = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 * 1: 1 - cmd (0x81)
		 * 2: 1 - error code
		 * 3: 2 - length
		 *
		 * 5: 16 - device name
		 * 21: 16 - firmware version
		 * 37: 8 - protocol version
		 * 45: 16 - company name (sub name)
		 * 61: 2 - device type (0,:pen, 2:eraser, 3:player)
		 * 63: 6 - MAC address
		 * 69: 1 - FS (0:FSR, 1:FSC, 2:up/down, 3:FSIR)
		 * 70: 4 - device color type id
		 */
		this.clearProtocolTimeout();

		const Errcode = buf[2];
		const modelNameArry = []; // NWP-F30 ~ NWP-F121HL
		for (let i = 5; i < 16; i++) {
			if (buf[i] > 0) modelNameArry.push(buf[i] & 0xff);
		}
		const modelNameString = String.fromCharCode.apply(null, modelNameArry);

		const companyNameArry = []; // Neosmartpen_A1 ...
		for (let i = 45; i < 61; i++) {
			if (buf[i] > 0) companyNameArry.push(buf[i] & 0xff);
		}
		const companyNameString = String.fromCharCode.apply(null, companyNameArry);

		const firmwareArry = []; // Neosmartpen_A1 ...
		for (let i = 21; i < 37; i++) {
			if (buf[i] > 0) firmwareArry.push(buf[i] & 0xff);
		}
		const firmwareString = String.fromCharCode.apply(null, firmwareArry);

		const protocolVer = (buf[37] - 0x30) * 100 + (buf[39] - 0x30) * 10 + (buf[40] - 0x30);
		// console.log(`awaited    <-- 0x81, process_81_device_first_info ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}  ${Errcode} ${protocolVer}`);

		this.deviceInfo.modelName = modelNameString;
		this.deviceInfo.companyName = companyNameString;
		this.deviceInfo.firmwareVer = firmwareString;
		this.deviceInfo.protocolVer = protocolVer;
		// this.deviceInfo.mac = intFromBytes(buf, 63, 6).toString(16).toUpperCase();
		this.deviceInfo.mac = decimalToHex(buf[63], 2) + ":"
			+ decimalToHex(buf[64], 2) + ":"
			+ decimalToHex(buf[65], 2) + ":"
			+ decimalToHex(buf[66], 2) + ":"
			+ decimalToHex(buf[67], 2) + ":"
			+ decimalToHex(buf[68], 2);

		const cCodeArry = new Uint8Array([buf[70], buf[71]]);
		const dataView = new DataView(cCodeArry.buffer);
		const companyCode = dataView.getUint16(0, true);
		this.deviceInfo.companyCode = companyCode;
		this.deviceInfo.productCode = buf[72];
		this.deviceInfo.colorCode = buf[73];
		if (this.deviceInfo.protocolVer >= 222) {
			const isSupportCompress = buf[74] ? true : false;
			this.isFwCompress = isSupportCompress;
		}

		//
		const typeNumber = intFromBytes(buf, 61, 2);

		function getPenType_on_81(n: number) {
			let result = DeviceTypeEnum.PEN;
			switch (n) {
				case 0:
				case 1:
					result = DeviceTypeEnum.PEN;
					break;

				case 2:
					result = DeviceTypeEnum.ERASER;
					break;

				case 3:
					result = DeviceTypeEnum.FSIR;
					break;

				default:
					result = DeviceTypeEnum.PEN;
					console.error(`device type mismatch (not 0, 1, neither 2), Pen type from pen is ${typeNumber}`);
					break;
			}

			return result;
		}
		this.deviceInfo.deviceType = getPenType_on_81(typeNumber);

		// t.deviceType = getPenType(typeNumber);
		if (this.isNowConnecting && this.lastReceivedPacketCmd === 0) {
			this.lastReceivedPacketCmd = 0x81;

			this.setProtocolTimeout(`awaited BT protocol #2-1 -> request pen system performance status, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
			if (protocolVer >= 220) {
				this.reqGetSystemPerformance_07();
			} else {
				this.reqConfig_04();
			}
		} else {
			// this.disconnect();
		}
	}


	/**
	 * 펜 설정 확인의 response (CMD 0x04의 결과)
	 * @param buf
	 */
	process_84_device_status = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x82)
		 * 2: 1 - error code
		 * 3: 2 - length
		 *
		 * 5: 1 - locked or not (0:no password, 1:password locked)
		 * 6: 1 - retry limit
		 * 7: 1 - current retry count
		 * 8: 8 - penTime(uint64), millisecond tick from 1970/01/01
		 * 16: 2 - auto power off time (unit: minutes)
		 * 18: 2 - maximum force (< 0x3ff)
		 * 20: 1 - storage capacity % (100%)
		 * 21: 1 - pencap auto on/off (0:false, 1:true)
		 * 22: 1 - auto power on (0:false, 1:true)
		 * 23: 1 - beep on (0:off, 1:on)
		 * 24: 1 - hover on (0:off, 1:on)
		 * 25: 1 - battery status (MSB:in charging, LSB 7bits: 100%)
		 * 26: 1 - offline stroke store or not (0:off, 1:on)
		 * 27: 1 - pressure sensor step (0~4, 0 is most sensitive)
		 * 28: 1 - usb mode (0:disk, 1:bulk)
		 * 29: 1 - down sampling (0:off, 1:on), isPathSimplifyOn
		 * 30: 16 - BT local name
		 * 46: 1 - data transfer mode (0:event, 1:requset/response(from 2.10 deleted))
		 * 47: 1 - ndac error code offline store or not (0:false, 1:true)
		 * 48: 21 - reserverd
		 */

		this.clearProtocolTimeout();

		// response
		const isPwLocked = buf[5] !== 0;
		const retryLimit = buf[6];
		const retryCount = buf[7];
		const penTime = intFromBytes(buf, 8, 8);
		const autoPowerOffTimeMinutes = intFromBytes(buf, 16, 2);
		const maxPressureValue = intFromBytes(buf, 18, 2);
		this.penMaxForce = maxPressureValue;

		const storageUsagePercent = buf[20];
		const autoPenCapOnOff = buf[21] !== 0;
		const autoPowerOn = buf[22] !== 0;
		const beepOn = buf[23] !== 0;
		const hoverModeOn = buf[24] !== 0;
		const batteryLevelPercent = buf[25];
		const isSupportOfflineStore = buf[26] !== 0;
		const pressureSensitivity = buf[27]; // 0~4, 0: the most senstive
		const usbMode = buf[28] === 0 ? PenUsbMode.DISK : PenUsbMode.BULK;
		const isPathSimplifyOn = buf[29] !== 0;
		const btLocalName = strFromBytes(buf, 30, 16);
		const isStoreErrorLogOn = buf[47] !== 0;

		// console.log(`awaited    <-- 0x84, process_84_device_status, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}, IsLock? ${isPwLocked} memory:${storageUsagePercent}%, battery:${batteryLevelPercent}%`);

		const status: IPennCommEvnetStatus = {
			isPwLocked,
			retryLimit,
			currentRetryCount: retryCount,
			penTime,
			autoPowerOffTimeMinutes,
			maxPressureValue,
			storageUsagePercent,
			autoPenCapOnOff,
			autoPowerOn,
			beepOn,
			hoverModeOn,
			batteryLevelPercent: batteryLevelPercent & 0x7F, // 7bit mask
			isSupportOfflineStore,
			pressureSensitivity,
			usbMode,
			isPathSimplifyOn,
			btLocalName,
			isStoreErrorLogOn,

			nowRecharging: (batteryLevelPercent & 0x80) > 0, // MSB
		};
		this.initialStatus = status;

		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.PASSWORD_REQUIRED, { timeStamp: this.currentTime, status });
		this.penHandler.onPenStatusResponse(e);

		if (this._resetDeviceInfo) {
			this._resetDeviceInfo = false;
			return;
		}

		// let bufferArray;
		if (isPwLocked) {
			const e2 = makePenEvent(
				this.deviceInfo.deviceType,
				PenCommEventEnum.PASSWORD_REQUIRED,
				{
					timeStamp: this.currentTime,
					passcodeStatus: {
						retryLimit,
						retryCount,
					}
				}
			);

			this.penHandler.onPasscodeRequired(e2);
			// // input passcode
			// console.log(" BT protocol #3 -> input passcode ");
			// bufferArray = new Uint8Array([0xc0, 0x02, 0x10, 0x00, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0xc1]);

			// bufferArray[4] = passcode.charCodeAt(0);
			// bufferArray[5] = passcode.charCodeAt(1);
			// bufferArray[6] = passcode.charCodeAt(2);
			// bufferArray[7] = passcode.charCodeAt(3);
			// console.log(" passcode1 :" + bufferArray[4]);
			// console.log(" passcode2 :" + bufferArray[5]);
			// console.log(" passcode3 :" + bufferArray[6]);
			// console.log(" passcode4 :" + bufferArray[7]);

			// this.write(bufferArray);
			// // g_btWriteSocket.writeValue(bufferArray);
		} else {
			// unlocked
			// time setting and go next

			// 연결 중에 불린 것이라면
			if (this.isNowConnecting &&
				(
					this.lastReceivedPacketCmd === 0x87		// if (protocolVer >= 220)
					|| this.lastReceivedPacketCmd === 0x81
				)
			) {
				this.lastReceivedPacketCmd = 0x84;
				this.setTimeAtConnectionAndReqOnlineData();
			}

			// bufferArray = new Uint8Array([
			// PEN_PACKET_START,
			// Cmd.ONLINE_DATA_REQUEST, // 0x11,
			// 0x02, 0x00, 0xff, 0xff,
			// PEN_PACKET_END
			// ]);
			// // g_btWriteSocket.writeValue(bufferArray);
			// this.write(bufferArray);
		}
	}


	process_b1_fw_upgrade_res = async (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 * 1: 1 - cmd (0xb1)
		 * 2: 1 - error code
		 * 3: 2 - length
		 *
		 * 5: 1 - authorize firmware transfer (0: transfer, 1: same version, 2: insufficient disk space, 3: failed, 4: compresdsion not supported)
		 */

		const resultCode = buf[2];
		if (resultCode === 0) {
			const status = buf[5];
			if (status === FwUpgrade.FIRMWARE_RECEIVED) {
				const e = makePenEvent(
					this.deviceInfo.deviceType,
					PenCommEventEnum.ON_FIRMWARE_UPGRADE_START,
					{
						timeStamp: this.currentTime,
						firmwareUpgradeStatus: {
							status,
							fwFileSize: this.fwByteUtil.Size,
							isCompress: this.isFwCompress
						},
					}
				);
				this.penHandler.onFirmwareUpgradeStart(e);
			} else {
				this.cancelFirmwareUpgrade();
			}
		}
		else {
			console.error(`[FW] received fw upload status : FW Error !! ${resultCode}`);
			// this.cancelFirmwareUpgrade();
		}
	};


	process_32_fw_upload_chunk = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 * 1: 1 - cmd (0x32)
		 * 2: 2 - length
		 *
		 * 4: 1 - chunk status (0: start, 1: continue, 2: end, 3: error)
		 * 5: 4 - file offset
		 */
		const chunkStatus = buf[4];
		const fileOffset = intFromBytes(buf, 5, 4);

		if (chunkStatus !== null && chunkStatus !== undefined && fileOffset !== null && fileOffset !== undefined) {
			this.processFwUpload(chunkStatus, fileOffset);
		}
	};

	processFwUpload = async (status: number, offset: number) => {
		if (!this.fwByteUtil) {
			return;
		}

		const data = this.fwByteUtil.GetBytesByOffset(offset);

		if (
			status === FwUpgrade.FIRMWARE_PACKET_STATUS_START ||
			status === FwUpgrade.FIRMWARE_PACKET_STATUS_CONTINUE ||
			status === FwUpgrade.FIRMWARE_PACKET_STATUS_END
		) {
			console.log("[FW] received fw upload status : " + status);
		} else if (status === FwUpgrade.FIRMWARE_PACKET_STATUS_ERROR) {
			console.error('[FW] received fw upload status : FW Error !!');
			return;
		} else {
			console.error('[FW] received fw upload status : Unknown');
			return;
		}

		let totalBytes = this.fwByteUtil.Size;
		if (totalBytes % this.packetSize === 0) {
			totalBytes = totalBytes / this.packetSize;
		} else {
			totalBytes = totalBytes / this.packetSize + 1;
		}
		let progressBytes = offset / this.packetSize;

		if (status === FwUpgrade.FIRMWARE_PACKET_STATUS_END) {
			if (data !== null) {
				await this.reqPenSwUpload_b2(status, offset, data);
			}
			progressBytes = totalBytes;
		} else {
			await this.reqPenSwUpload_b2(status, offset, data);
		}

		const e = makePenEvent(
			this.deviceInfo.deviceType,
			PenCommEventEnum.ON_FIRMWARE_UPGRADE_PROGRESS,
			{
				timeStamp: this.currentTime,
				firmwareUpgradeProgress: {
					progressBytes,
					totalBytes,
				},
			}
		);
		this.penHandler.onFirmwareUpgradeProgress(e);
	}

	process_87_system_setting = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 * 1: 1 - cmd (0x87)
		 * 2: 1 - error code
		 * 3: 2 - length
		 *
		 * 5: 1 - availability of performace setting (0: not avail, 1: avail)
		 * 6: 8 - set performance (struct, 4 bytes = reserved, [10] = isEchoMode(0: ON, 1: OFF))
		 * 14: 119 - reserved
		 */

		// const enableSetPerform = buf[5] === 1;

		const isEchoMode = buf[10] === 0;
		const echoModeSupportPenList = ['NWP-F80', 'NWP-F80K', 'NWP-F130'];

		const status: IPennCommEvnetSystemPerformance = {
			isSupported: echoModeSupportPenList.includes(this.deviceInfo.modelName),
			isEchoMode,
		};
		this.systemPerformanceStatus = status;

		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.SYSTEM_PERFORMANCE_STATUS, {
			timeStamp: this.currentTime,
			systemPerformance: status,
		});
		this.penHandler.onSystemPerformanceResponse(e);

		if (this.isNowConnecting && this.lastReceivedPacketCmd === 0x81) {
			this.lastReceivedPacketCmd = 0x87;

			this.setProtocolTimeout(`awaited BT protocol #2-2 -> request pen status, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}, `, buf);
			this.reqConfig_04();
		} else {
			// this.disconnect();
		}
	};

	process_86_system_setting_change = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 * 1: 1 - cmd (0x86)
		 * 2: 1 - error code
		 * 3: 2 - length
		 *
		 * 5: 1 - type
		 * 6: 1 - status (0:success, 1:unsupported, 2: invalid)
		 */

		const type = buf[5];
		const status = buf[6];
		console.log(`set EchoMode => type: ${type}, status: ${status}`);

		if (status === 0) {
			const updateKeys = Object.keys(this.pendingUpdatePerformanceStatus);
			for (let i = 0; i < updateKeys.length; i++) {
				const key = Number(updateKeys[i]);
				if (key === type) {
					const value = this.pendingUpdatePerformanceStatus[key];

					switch (type) {
						case 1: // EchoMode
							this.systemPerformanceStatus.isEchoMode = value === 0 ? true : false;
							break;

						default:
							break;
					}
				}
			}
		}

		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.SYSTEM_PERFORMANCE_STATUS, {
			timeStamp: this.currentTime,
			systemPerformance: {
				changedEchoModeStatus: status,
			},
		});
		this.penHandler.onEchoModeChanged(e);
	};

	/**
	 * CMD 0x02
	 * @param passcode
	 */
	sendPasscode(passcode: string) {
		console.log(`awaited BT protocol #3 -> input passcode, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
		this.reqPassword_02(passcode);

		// const bufferArray = new Uint8Array([
		// PEN_PACKET_START,
		// Cmd.PASSWORD_REQUEST, // 0x02,
		// 0x10, 0x00, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		// PEN_PACKET_END
		// ]);

		// bufferArray[4] = passcode.charCodeAt(0);
		// bufferArray[5] = passcode.charCodeAt(1);
		// bufferArray[6] = passcode.charCodeAt(2);
		// bufferArray[7] = passcode.charCodeAt(3);

		// // g_btWriteSocket.writeValue(bufferArray);
		// this.write(bufferArray);
	}

	setPassword(oldPassword: string, newPassword: string, isSetPassword = false) {
		let res: boolean;
		if (isSetPassword) {
			console.log('Set your password.');
			res = this.reqSetUpPassword_03(DEFAULT_PASSWORD, newPassword);
		} else {
			console.log('Change your password.');
			res = this.reqSetUpPassword_03(oldPassword, newPassword);
		}
		return res;
	}


	/**
	 * 패스워드 송신 후의 reponse
	 * @param buf
	 */
	process_82_pw_result = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 * 1: 1 - cmd (0x82)
		 * 2: 1 - error code
		 * 3: 2 - length
		 * 5: 1 - password response (0:pw needed, 1:success(or not needed), 2:pen was reset, 3:system error)
		 * 6: 1 - retry limit
		 * 7: 1 - current retry count
		 */

		// response check passcode
		const pwCheckResult = buf[5];

		/** @type {number} */
		const retryCount = buf[6];
		const maxCount = buf[7];

		if (pwCheckResult === 1) {
			if (this.reCheckPassword) {
				this.onPenPasswordSetupResponse(true);
				this.reCheckPassword = false;
			}

			console.log(`awaited     BT protocol #3 <- passcode none ==> OK, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

			// passcode OK

			// 연결 핸드쉐이킹 도중이라면
			if (this.isNowConnecting) {
				this.lastReceivedPacketCmd = 0x82;
				this.setOfflineStoreModeAtConnection();
			}
		} else {
			console.log(`awaited     BT protocol #3 <- passcode NG ${retryCount}/${maxCount},${this.deviceInfo.modelName}, ${this.deviceInfo.mac} `);
			// retry input passcode
			// alert("wrong passcode");
			const e = makePenEvent(
				this.deviceInfo.deviceType,
				PenCommEventEnum.PASSWORD_REQUIRED,
				{
					timeStamp: this.currentTime,
					// retryCount,
					passcodeStatus: {
						retryLimit: this.initialStatus?.retryLimit || 10,
						retryCount,
					}

				}
			);
			this.penHandler.onPasscodeRequired(e);
		}
	}


	/**
	 * Set transfer mode, by 5th byte
	 * offline store mode (0:off, 1:offline while realtime available, 100:both of realtime and offline)
	 * @param mode
	 */
	setTransferMode = (mode: number) => {
		this.reqChangeSetting_05(SettingType.OfflineData, mode);
		// /**
		// * 0: 1 - header
		// * 1: 1 - cmd (0x05)
		// * 2: 2 - length (0x02)
		// * 4: 1 - attribute type (0x07)
		// * 5: 1 - offline store mode (0:off, 1:offline while realtime available, 100:both of realtime and offline)
		// */

		// if (val !== 0 && val !== 1 && val !== 100) return;

		// const bufferArray = new Uint8Array([
		// PEN_PACKET_START,
		// Cmd.SETTING_CHANGE_REQUEST, // 0x05,
		// 0x02, 0x00, 0x07, val,
		// PEN_PACKET_END
		// ]);
		// // g_btWriteSocket.writeValue(bufferArray);
		// this.write(bufferArray);
	}

	setHoverMode = (mode: number) => {
		mode &= 0x01;
		this.reqChangeSetting_05(SettingType.Hover, mode);
	};

	setBtLocalName = (name: string) => {
		this.reqChangeSetting_05(SettingType.BtLocalName, name);
	};

	setAutoPowerOnOff = (mode: number) => {
		mode &= 0x01;
		this.reqChangeSetting_05(SettingType.AutoPowerOn, mode);
	};

	setAutoPenCapOnOff = (mode: number) => {
		mode &= 0x01;
		this.reqChangeSetting_05(SettingType.PenCapOff, mode);
	};

	setBeepOnOff = (mode: number) => {
		mode &= 0x01;
		this.reqChangeSetting_05(SettingType.Beep, mode);
	};

	setEchoMode = (isEchoMode: boolean) => {
		this.reqSetSystemPerformance_06(isEchoMode);
	}

	setAutoPowerOffTime = async (minTime: number) => {
		return await this.reqChangeSetting_05(SettingType.AutoPowerOffTime, minTime, true);
	}

	forceBLEDisconnect = () => {
		if (this.deviceInfo.protocolVer >= 223) {
			this.reqChangeSetting_05(SettingType.SetPenStatus, 0x01);
		}
	}

	forcePowerOff = () => {
		if (this.deviceInfo.protocolVer >= 223) {
			this.reqChangeSetting_05(SettingType.SetPenStatus, 0x02);
		}
	}

	forceShutdown = () => {
		if (this.deviceInfo.protocolVer >= 223) {
			this.reqChangeSetting_05(SettingType.SetPenStatus, 0x03);
		}
	}

	setOfflineStoreModeAtConnection = () => {
		if (this.isNowConnecting) {
			this.setProtocolTimeout(`awaited    BT protocol #4 -> setup offline store mode to 0x01, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

			// offline store mode (0x00: off, 0x01: save offline data, 0x0A: both of realtime and offline)
			this.reqChangeSetting_05(SettingType.OfflineData, 1);
		} else {
			// this.disconnect();
		}
	}

	setTimeAtConnectionAndReqOnlineData = () => {
		if (this.isNowConnecting) {
			this.setProtocolTimeout(`awaited    BT protocol #5 -> setup realtime clock, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

			// refer to this.process_85_config_response(),
			// if (this._shouldProcessReqOnlineData), req online data next
			this._shouldProcessReqOnlineData = true;
			this.reqChangeSetting_05(SettingType.Timestamp, (new Date()).getTime());
		} else {
			// this.disconnect();
		}
	}

	on_85_config_response_duringConnection = (buf: Uint8Array) => {
		this.clearProtocolTimeout();

		const type = buf[5];

		switch (type) {
			case SettingType.OfflineData:
				if (this.isNowConnecting) {
					console.log(`awaited        offline store mode ==> OK,on_85_config_response_duringConnection, ${this.deviceInfo.modelName} ${this.deviceInfo.mac}`);
					this.setTimeAtConnectionAndReqOnlineData();
				}
				break;

			case SettingType.Timestamp:
				console.log(`awaited        setup realtime clock ==> OK,on_85_config_response_duringConnection, ${this.deviceInfo.modelName} ${this.deviceInfo.mac}`);

				if (this.isNowConnecting) {
					if (this._shouldProcessReqOnlineData) {
						this._shouldProcessReqOnlineData = false;

						// 온라인 데이터를 받는 옵션이 설정되어 있다면
						if (this._connectOptions._requestOnlineDataOnConencted) {
							// 온라인 데이터를 받는 옵션이 설정되어 있다면, 온라인 데이터를 받는 부분으로 proceed
							this.requestOnlineStrokeOnConnection();
						} else {
							// 온라인 데이터를 받는 옵션이 설정되어 있지 않다면, 바로 connection promise를 resolve
							console.log(`awaited ********* connection resolved: true,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
							this.clearProtocolTimeout();
							this._connectionTask.resolve(true);
							this._connectionTask = {};


							// pen event 송출
							let errorCode = 0;
							let infoMessage = "";
							if (this.deviceInfo.protocolVer < 218) {
								errorCode = 1;
								infoMessage = `need to upgrade firmware greater than 2.18 (current version=${this.deviceInfo.protocolVer / 100})`;
							}

							// window.setTimeout(() => {
							const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.ON_CONNECTED, {
								errorCode, infoMessage, timeStamp: this.currentTime
							});
							this.penHandler.onConnected(e);
							// set auto hover mode
							// }, 5);

						}
					}
				}
				break;

			// 아래는 Hover mode를 설정하는 경우이나 현재 사용하지 않음, 2024-05-09

			// case SettingType.Hover:
			// 	{
			// 		if (this.isNowConnecting) {
			// 			console.log(`awaited ======== connection resolved: true,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

			// 			this.clearProtocolTimeout();
			// 			this._connectionTask.resolve(true);
			// 			this._connectionTask = {};
			// 		}
			// 	}
			// 	break;

			default:
				break;
		}

		// if (type === SettingType.OfflineData) {
		// 	console.log(`awaited        offline store mode ==> OK,on_85_config_response_duringConnection, ${this.deviceInfo.modelName} ${this.deviceInfo.mac}`);
		// 	this.setTimeAtConnectionAndReqOnlineData();
		// } else if (type === SettingType.Timestamp) {
		// 	console.log(`awaited        setup realtime clock ==> OK,on_85_config_response_duringConnection, ${this.deviceInfo.modelName} ${this.deviceInfo.mac}`);
		// 	if (this._shouldProcessReqOnlineData) {
		// 		this._shouldProcessReqOnlineData = false;

		// 		// 온라인 데이터를 받는 옵션이 설정되어 있다면
		// 		if (this._connectOptions._requestOnlineDataOnConencted) {
		// 			this.requestOnlineStrokeOnConnection();
		// 		} else {
		// 			// 여기서 connect()의 promise를 resolve
		// 			this.clearProtocolTimeout();
		// 			if (this._connectionTask.resolve) {
		// 				console.log(`awaited ********* connection resolved: true,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
		// 				this._connectionTask.resolve(true);
		// 			}
		// 			this._connectionTask = {};
		// 		}
		// 	}
		// } else if (type === SettingType.Hover) {
		// 	this.clearProtocolTimeout();
		// 	if (this._connectionTask.resolve) {
		// 		console.log(`awaited ======== connection resolved: true,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
		// 		this._connectionTask.resolve(true);
		// 	}
		// 	this._connectionTask = {};
		// }
	}

	/**
	 * pen up/down, protocol version 1.00
	 * @param buf
	 */
	process_63_penupdown_v100 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x63)
		 * 2: 2 - length
		 *
		 * 4: 1 - up/down (0:down, 1:up)
		 * 5: 8 - timestamp
		 * 13: 1 - pen tip type (0:normal, 1:eraser)
		 * 14: 4 - color (argb)
		 */

		const isPenDown = buf[4] === 0;
		const penTipMode = buf[13];
		const mac = this.getMac();
		const timeStamp = intFromBytes(buf, 5, 8); // ARGB
		this.setTimeStamp(timeStamp);

		if (isPenDown) {
			console.log("------------ Pen down --------------");
			this.isPenDown = true;

			const event = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.PEN_DOWN, { penTipMode, timeStamp, mac });
			this.penHandler.onPenDown(event);
		} else {
			console.log("^^^^^^^^^^^^^^ pen up ^^^^^^^^^^^^^");
			this.isPenDown = false;

			const event = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.PEN_UP, { penTipMode, timeStamp });
			this.penHandler.onPenUp(event);
		}
	}

	/**
	 * page information,
	 * pen down 직후, pen up 직후에 전송되어 옴
	 * @param buf
	 */
	process_64_pageinfo_v100 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x64)
		 * 2: 2 - length
		 *
		 * 4: 3 - owner
		 * 7: 1 - section
		 * 8: 4 - note id
		 * 12: 4 - page id
		 */

		const section = buf[7];
		const owner = intFromBytes(buf, 4, 3);
		const book = intFromBytes(buf, 8, 4);
		const page = intFromBytes(buf, 12, 4);
		// console.log('owner:' + ownerId + ', book:' + bookId + ', page:' + pageId);

		let eventMode = PenCommEventEnum.PAGE_INFO;
		if (!this.isPenDown) {
			eventMode = PenCommEventEnum.PAGE_INFO_HOVER;
		}

		const e = makePenEvent(this.deviceInfo.deviceType, eventMode, { section, owner, book, page, timeStamp: this.currentTime });

		// console.log(" #1 INFO");
		const isHover = eventMode === PenCommEventEnum.PAGE_INFO_HOVER;

		// console.log( "0x64");
		this.penHandler.onPageInfo(e, isHover);
	}


	/**
	 * Pen Move, protocol version 1.00
	 * @param buf
	 */
	private process_65_penmove_v100 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x65)
		 * 2: 2 - length
		 * 4: 1 - time diff
		 *
		 * 5: 2 - force
		 * 7: 2 - x
		 * 9: 2 - y
		 * 11: 1 - float x
		 * 12: 1 - float y
		 * 13: 1 - x tilt
		 * 14: 1 - y tilt
		 * 15: 2 - twist (0~180, 360 degree => 180)
		 */

		const timediff = buf[4];
		this.accTime(timediff);

		const force = intFromBytes(buf, 5, 2);
		const dotX = intFromBytes(buf, 7, 2);
		const dotY = intFromBytes(buf, 9, 2);
		const dotFx = intFromBytes(buf, 11, 1);
		const dotFy = intFromBytes(buf, 12, 1);

		const x = dotX + dotFx / 100;
		const y = dotY + dotFy / 100;

		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.PEN_MOVE, { force, x, y, timediff, timeStamp: this.currentTime });
		// e.force = force;
		// e.x = x;
		// e.y = y;
		// e.timediff = timediff;

		console.log(` #1 MOVE ( ${x}, ${y} )`);
		this.penHandler.onPenMove(e);
	}


	/**
	 * pen move, for slow connection, protocol version 2.00
	 * @param buf
	 */
	private process_66_penmove_simple_v200 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x66)
		 * 2: 2 - length
		 * 4: 1 - time diff
		 *
		 * 5: 2 - x
		 * 7: 2 - y
		 * 9: 1 - float x
		 * 10: 1 - float y
		 */

		const timediff = buf[4];
		this.accTime(timediff);

		const force = 100;
		const dotX = intFromBytes(buf, 5, 2);
		const dotY = intFromBytes(buf, 7, 2);
		const dotFx = intFromBytes(buf, 9, 1);
		const dotFy = intFromBytes(buf, 10, 1);

		const x = dotX + dotFx / 100;
		const y = dotY + dotFy / 100;

		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.PEN_MOVE, { force, x, y, timediff, timeStamp: this.currentTime });
		// e.force = force;
		// e.x = x;
		// e.y = y;
		// e.timediff = timediff;

		console.log(` #2 MOVE ( ${x}, ${y} )`);
		this.penHandler.onPenMove(e);
	}


	/**
	 * On NDAC Error, protocol v1.00
	 * @param buf
	 */
	process_68_ndac_error_v100 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x68)
		 * 2: 2 - length
		 * 4: 1 - time diff
		 *
		 * 5: 2 - force
		 * 7: 1 - image brightness
		 * 8: 1 - exposure time
		 * 9: 1 - NDAC process time
		 * 10: 2 - label count
		 * 12: 1 - ndac error code
		 */

		// const this = this;
		const timediff = buf[4];
		this.accTime(timediff);

		const force = intFromBytes(buf, 5, 2);

		const brightness = buf[7];
		const exposureTime = buf[8];
		const ndacProcessTime = buf[9];

		const labelCount = intFromBytes(buf, 10, 2);
		const ndacErrorCode = buf[12];
		const ndacClassType = buf[14];
		const mac = this.getMac();

		const e = makePenEvent(
			this.deviceInfo.deviceType,
			PenCommEventEnum.ERROR,
			{
				timeStamp: this.currentTime,
				timediff,
				force,
				brightness,
				exposureTime,
				ndacProcessTime,
				labelCount,
				ndacErrorCode,
				ndacClassType,
				mac
			}
		);
		// this.isPenDown = true;

		console.log(" PEN DOWN");
		this.penHandler.onPenDown(e);
	}


	/**
	 * pen down, v2.12
	 * @param buf
	 */
	process_69_pendown_v212 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x69)
		 * 2: 2 - length
		 * 4: 1 - event count
		 *
		 * 5: 8 - timeStamp
		 * 13: 1 - pen tip type (0:normal, 1:eraser)
		 * 14: 4 - pen tip color (argb)
		 */
		// console.log(" PEN DOWN");

		const timeStamp = intFromBytes(buf, 5, 8); // ARGB
		this.setTimeStamp(timeStamp);

		const penTipMode = buf[13];
		const color = intFromBytes(buf, 14, 4); // ARGB
		const mac = this.getMac();

		this.isPenDown = true;

		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.PEN_DOWN, { penTipMode, color, timeStamp, mac });
		this.penHandler.onPenDown(e);
	}

	/**
	 * pen up, v2.12
	 * @param buf
	 */
	process_6a_penup_v212 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x6A)
		 * 2: 2 - length
		 * 4: 1 - event count
		 *
		 * 5: 8 - timestamp
		 *
		 * 13: 2 - dot count (down-up 사이에 BT로 전송한 dot code 개수)
		 * 15: 2 - total image count (down-up 사이에 촬영된 이미지 개수)
		 * 17: 2 - proc image count (처리한 이미지 개수)
		 * 19: 2 - success image count (처리에 성공한 이미지 개수)
		 * 21: 2 - valid image count (밝기 등으로 유효한 이미지 개수)
		 */
		// console.log(" PEN UP");

		const timeStamp = intFromBytes(buf, 5, 8); // ARGB
		this.setTimeStamp(timeStamp);

		// const dotCount = intFromBytes(buf, 13, 2);
		const totalImageCount = intFromBytes(buf, 15, 2);
		// const procImageCount = intFromBytes(buf, 17, 2);
		const successImageCount = intFromBytes(buf, 19, 2);
		const validImageCount = intFromBytes(buf, 21, 2);

		// console.log(`totalImageCount: ${totalImageCount} `);
		// console.log(`procImageCount: ${procImageCount} `);
		// console.log(`successImageCount: ${successImageCount} `);
		// console.log(`validImageCount: ${validImageCount} `);

		const successRate_ndac = successImageCount / totalImageCount;
		const successRate_optical = validImageCount / totalImageCount;

		this.isPenDown = false;

		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.PEN_UP, { successRate_ndac, successRate_optical, timeStamp });
		this.penHandler.onPenUp(e);
	}

	/**
	 * page info packet, v2.12
	 * @param buf
	 */
	process_6b_pageinfo_v212 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x6B)
		 * 2: 2 - length
		 * 4: 1 - event count
		 *
		 * 5: 3 - owner
		 * 8: 1 - section
		 * 9: 4 - book
		 * 13: 4 - page
		 */

		const owner = intFromBytes(buf, 5, 3);
		const section = intFromBytes(buf, 8, 1);
		const book = intFromBytes(buf, 9, 4);
		const page = intFromBytes(buf, 13, 4);

		let eventMode = PenCommEventEnum.PAGE_INFO;
		if (!this.isPenDown) {
			eventMode = PenCommEventEnum.PAGE_INFO_HOVER;
		}

		const e = makePenEvent(this.deviceInfo.deviceType, eventMode, { section, owner, book, page, timeStamp: this.currentTime });
		const isHover = eventMode === PenCommEventEnum.PAGE_INFO_HOVER;

		// console.log("0x6b");

		// console.log(` PEN INFO, page address: ${o}.${b}.${p} ${isHover ? "HOVER" : "PEN DOWN"} `);
		this.penHandler.onPageInfo(e, isHover);
	}


	/**
	 * pen move, v2.12
	 * @param buf
	 */
	process_6c_penmove_v212 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x6C)
		 * 2: 2 - length
		 * 4: 1 - event count
		 * 5: 1 - delta time
		 *
		 * 6: 2 - force
		 * 8: 2 - x
		 * 10: 2 - y
		 * 12: 1 - float x
		 * 13: 1 - float y
		 *
		 * 14: 1 - x tilt
		 * 15: 1 - y tilt
		 * 16: 2 - twist
		 */
		// pen down moving (or hover moving, in firmware <2.18 )
		const timediff = buf[5];
		this.accTime(timediff);

		const force = intFromBytes(buf, 6, 2);
		const dotX = intFromBytes(buf, 8, 2);
		const dotY = intFromBytes(buf, 10, 2);
		const dotFx = intFromBytes(buf, 12, 1);
		const dotFy = intFromBytes(buf, 13, 1);


		const x = dotX + dotFx / 100;
		const y = dotY + dotFy / 100;
		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.PEN_MOVE, { x, y, force, timediff, timeStamp: this.currentTime });

		this.penHandler.onPenMove(e);
	}


	/**
	 * pen dot hover, v2.18
	 * @param buf
	 */
	process_6f_hovermove_v218 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x6F)
		 * 2: 2 - length
		 * 4: 1 - delta time
		 *
		 * 8: 2 - x
		 * 10: 2 - y
		 * 12: 1 - float x
		 * 13: 1 - float y
		 */

		const timediff = buf[4];
		this.accTime(timediff);

		const force = 0;
		const dotX = intFromBytes(buf, 5, 2);
		const dotY = intFromBytes(buf, 7, 2);
		const dotFx = intFromBytes(buf, 9, 1);
		const dotFy = intFromBytes(buf, 10, 1);

		const x = dotX + dotFx / 100;
		const y = dotY + dotFy / 100;
		const e = makePenEvent(DeviceTypeEnum.PEN, PenCommEventEnum.PEN_MOVE_HOVER, { x, y, force, timediff, timeStamp: this.currentTime });

		this.penHandler.onHoverMove(e);
	}

	/**
	 * NDAC Error, v2.12
	 * @param buf
	 */
	process_6d_ndacerror_v212 = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x6D)
		 * 2: 2 - length
		 * 4: 1 - event count
		 * 5: 1 - delta time
		 *
		 * 6: 2 - force
		 * 8: 1 - image brightness
		 * 9: 1 - exposure time
		 * 10: 1 - NDAC process time
		 * 11: 2 - label count
		 * 13: 1 - ndac error code
		 * 14: 1 - class type (0:G3C6, 1:N3C6)
		 * 15: 1 - error count (down부터 카운트, 초기화:0)
		 */

		const timediff = buf[5];
		this.accTime(timediff);

		const force = intFromBytes(buf, 6, 2);

		const brightness = buf[8];
		const exposureTime = buf[9];
		const ndacProcessTime = buf[10];

		const labelCount = intFromBytes(buf, 11, 2);
		const ndacErrorCode = buf[13];
		const ndacClassType = buf[14];
		const continuousErrorCount = buf[15];

		const options = {
			timeStamp: this.currentTime,

			timediff,
			force,

			brightness,
			exposureTime,
			ndacProcessTime,
			labelCount,
			ndacErrorCode,
			ndacClassType,
			continuousErrorCount,
		}

		const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.CODE_ERROR, options);
		this.penHandler.onNcodeError(e);
	}


	/**
	 * response at pen configuration (ex. hover mode)
	 * @param buf
	 */
	process_85_config_response = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x85)
		 * 2: 1 - error code
		 * 3: 2 - length
		 * 5: 1 - type
		 * 6: 1 - result (0:success, 1:fail)
		 */
		this.clearProtocolTimeout();

		const error_code = buf[2];
		const type = buf[5];
		const success = buf[6] === 0 ? "success" : "fail";
		// console.log(` BT protocol response(0x85): ${type} ==> ${success}`);

		// console.log(`awaited    process_85_config_response, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);

		if (success === "success") {
			const updateKeys = Object.keys(this.pendingUpdateStatus);
			for (let i = 0; i < updateKeys.length; i++) {
				const key = Number(updateKeys[i]);
				if (key === type) {
					const value = this.pendingUpdateStatus[key];

					switch (type) {
						case SettingType.AutoPowerOffTime:
							this.initialStatus.autoPowerOffTimeMinutes = value;
							break;
						case SettingType.PenCapOff:
							this.initialStatus.autoPenCapOnOff = value ? true : false;
							break;
						case SettingType.AutoPowerOn:
							this.initialStatus.autoPowerOn = value ? true : false;
							break;
						case SettingType.Beep:
							this.initialStatus.beepOn = value ? true : false;
							break;
						case SettingType.Hover:
							this.initialStatus.hoverModeOn = value ? true : false;
							break;

						default:
							break;
					}
				}
			}
		}


		// 연결 중 상태에서 온 것이라면 lastRecivedPacketCmd = 0x84
		// 연결 중 Lock 을 해제했다면 lastRecivedPacketCmd = 0x82 -> 0x85
		if (this.isNowConnecting && (this.lastReceivedPacketCmd === 0x84 || this.lastReceivedPacketCmd === 0x82 || this.lastReceivedPacketCmd === 0x85)) {
			this.lastReceivedPacketCmd = 0x85;

			// if (this.deviceInfo.protocolVer >= 218) {
			// 	this.setHoverMode(1); // enable hover
			// }

			this.on_85_config_response_duringConnection(buf);
		}
	}

	requestOnlineStrokeOnConnection = () => {
		// const bufferArray = new Uint8Array([0xc0, 0x11, 0x02, 0x00, 0xff, 0xff, 0xc1]);
		// this.write(bufferArray);
		if (this.isNowConnecting) {
			this.setProtocolTimeout(`awaited BT protocol #END -> request online data, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
			this.reqOnlineData_11();
		} else {
			// this.disconnect();
		}
	}

	/**
	 * response, 실시간 필기 데이터 요청에 따른
	 * @param buf
	 */
	process_91_realtime_mode_response = (buf: Uint8Array) => {
		/**
		 * 0: 1 - header
		 *
		 * 1: 1 - cmd (0x91)
		 * 2: 1 - error code
		 * 3: 2 - length
		 */

		const Errcode = buf[2];

		if (Errcode === 0) {
			console.log(" BT protocol response(realtime mode) <- You can write.");
			console.log(" Device info ");
			console.log(" modelName: " + this.deviceInfo.modelName);
			console.log(" protocolVer: " + this.deviceInfo.protocolVer / 100);
			console.log(" deviceType: " + this.deviceInfo.deviceType);


			// 연결 중인 상태라면 ON_CONNECTED를 생성, 2022/06/27
			if (this.isNowConnecting && this.lastReceivedPacketCmd === 0x85) {
				this.lastReceivedPacketCmd = 0x91;

				// 여기서 connect()의 promise를 resolve, 2022/06/27
				this.clearProtocolTimeout();
				if (this._connectionTask.resolve) {
					console.log(`awaited ======== connection resolved: true,  ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
					this._connectionTask.resolve(true);
				}
				this._connectionTask = {};


				// pen event 송출
				let errorCode = 0;
				let infoMessage = "";

				// callback
				if (this.deviceInfo.protocolVer < 218) {
					errorCode = 1;
					infoMessage = `need to upgrade firmware greater than 2.18 (current version=${this.deviceInfo.protocolVer / 100})`;
				}

				// window.setTimeout(() => {
				const e = makePenEvent(this.deviceInfo.deviceType, PenCommEventEnum.ON_CONNECTED, {
					errorCode, infoMessage, timeStamp: this.currentTime
				});
				this.penHandler.onConnected(e);
				// set auto hover mode
				// }, 5);
			}
		} else {
			console.log(`awaited     BT protocol #4 <- error : ${Errcode}, ${this.deviceInfo.modelName}, ${this.deviceInfo.mac}`);
		}
	}


	onPenPasswordSetupResponse = (success: boolean) => {
		this.setTimeStamp((new Date()).getTime());

		const e = makePenEvent(
			this.deviceInfo.deviceType,
			PenCommEventEnum.PASSWORD_SET_COMPLETED,
			{
				timeStamp: this.currentTime,
				success
			}
		);
		this.penHandler.onPasswordChanged(e);
	}
}

