

import { Wrapper } from "./Wrapper";

export class NcodeFont extends Wrapper {
  charset: string = "";

  constructor(libmupdf: any, ctx: number, pointer: number, charset: string) {
    super(libmupdf, ctx, pointer, libmupdf._wasm_disposePdfObject);
    this.charset = charset;
  }
}
