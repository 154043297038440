import { EventClassBase, getPageSOBP, IBrushEnum, INeoSmartpenType, INeoStroke, InkStorageEventName, IPageSOBP, makeNPageIdStrWithUuid, NEO_SMARTPEN_TYPE, NeoStroke, NeoStrokeArray, PenEventName, StrokeStatusEnum } from '../../nl-lib3-common';
import { IInkStorageEvent } from './event-args/IInkStorageEvent';

/** @type {InkStorage} */
let is_inst: InkStorage | null = null;
let is_inst_2nd: InkStorage | null = null;

/*
 * register event handler, following events will be dispatched from this storage
 *   1) PenEventName.ON_PEN_DOWN
 *   2) PenEventName.ON_PEN_PAGEINFO
 *   3) PenEventName.ON_PEN_MOVE
 *   4) PenEventName.ON_PEN_UP
 *   5) InkStorageEventName.ON_PAGE_ADDED
 *   6) InkStorageEventName.ON_PAGE_REMOVED
 *   7) InkStorageEventName.ON_PEN_ADDED
 */

export default class InkStorage extends EventClassBase<PenEventName | InkStorageEventName, IInkStorageEvent> {
    name: string;

    // 2021/12/27
    strokes: NeoStroke[] = [];

    get numStrokes() {
        return this.strokes.length;
    }

    /** strokeKey ==> Stroke */
    completed: Map<string, NeoStroke> = new Map(); // completed strokes

    /** stroke.startTime + sobp string => boolean */
    registered: Map<string, boolean> = new Map(); // completed strokes

    /** originalKey ==> Stroke */
    originalKeyStrokes: Map<string, NeoStroke> = new Map(); // originalKey completed strokes

    /** sourceKey ("uuid" ) ==> Stroke */
    realtime: Map<string, NeoStroke> = new Map(); // realtime strokes (incompleted strokes)

    /** (pageId (s.o.b.p)) ==> ({mac : NeoStroke[]}) */
    completedOnPage: Map<string, NeoStrokeArray> = new Map();

    lastPageInfo: IPageSOBP = { section: -1, book: -1, owner: -1, page: -1 };

    private _startTime = Number.MAX_VALUE;

    private _endTime = Number.MIN_VALUE;

    private _surfaceOwnerIds: string[] = [];

    private _writerIds: string[] = [];

    /** 스트로크를 한번이라도 등록한 적이 있는 펜 리스트 */
    private _penUIDs: string[] = [];

    private history = []; //command: {type: string, payload: NeoStroke}

    private future = [];

    public getPenList() {
        return this._penUIDs;
    }

    get writerIds() {
        return this._writerIds;
    }

    get surfaceOwnerIds() {
        return this._surfaceOwnerIds;
    }

    /** @type {InkStorage} */
    // static instance;
    private constructor(name: string) {
        super();
        this.name = name;
    }

    /**
     *
     */
    static getInstance(): InkStorage {
        if (is_inst) return is_inst;

        is_inst = new InkStorage('1st');
        return is_inst;
    }

    static get instance() {
        return this.getInstance();
    }

    /**
     * 2021/07/06
     */
    static get2ndInstance(): InkStorage {
        if (!is_inst_2nd) {
            is_inst_2nd = new InkStorage('2nd');
        }
        return is_inst_2nd;
    }

    /**
     * get strokes in a certain page, not including 'realtime state' strokes
     *
     * @param {IPageSOBP} sobp
     * @return {*}  {NeoStroke[]}
     * @memberof InkStorage
     */
    public getPageStrokes = (sobp: IPageSOBP, getEvenErased = false): NeoStroke[] => {
        const { section, owner, book, page, noteUuid, pageUuid } = sobp;
        const pageId = makeNPageIdStrWithUuid({ section, owner, book, page, noteUuid, pageUuid });

        const arr = this.completedOnPage.get(pageId);

        let ret: NeoStroke[] = [];
        if (arr) {
            ret = arr.array;

            if (!getEvenErased) {
                ret = ret.filter((st) => st.status !== StrokeStatusEnum.ERASED && st.brushType !== IBrushEnum.ERASER);
            }
        }

        return ret;
    };

    /**
     * get pageIds from this ink storage
     *
     * @return string[]
     * @memberof InkStorage
     */
    public getPageList = () => Array.from(this.completedOnPage.keys()).map((pageId) => getPageSOBP(pageId));

    /**
     * completely remove stokres on a page
     *
     * @param {IPageSOBP} sobp
     * @memberof InkStorage
     */
    public removeStrokeFromPage = (sobp: IPageSOBP) => {
        const { section, owner, book, page, noteUuid, pageUuid } = sobp;
        const pageId = makeNPageIdStrWithUuid({ section, owner, book, page, noteUuid, pageUuid });

        this.completedOnPage.delete(pageId);
    };

    /**
     * mark a stokre as 'removed'
     *
     * @param {NeoStroke} stroke
     * @memberof InkStorage
     */
    // eslint-disable-next-line class-methods-use-this
    public markErased = (stroke: NeoStroke) => {
        stroke.status = StrokeStatusEnum.ERASED;
    };

    public markNormal = (stroke: NeoStroke) => {
        stroke.status = StrokeStatusEnum.NORMAL;
    };

    /**
     *
     */
    public getLastPageInfo = (): IPageSOBP => this.lastPageInfo;

    /**
     * Append a dummy page on ink storage
     *
     * @param {IPageSOBP} sobp
     * @return boolean
     * @memberof InkStorage
     */
    public addDummyPage = (sobp: IPageSOBP) => {
        const pageId = makeNPageIdStrWithUuid(sobp);

        if (!this.completedOnPage.has(pageId)) {
            this.completedOnPage.set(pageId, new NeoStrokeArray());

            // hand the event
            const event: IInkStorageEvent = {
                inputType: NEO_SMARTPEN_TYPE.MENU_ACTION,
                strokeKey: '',
                mac: '',
                sobps: [sobp]
            };
            this.dispatcher.dispatch(InkStorageEventName.ON_PAGE_ADDED, event);
            return true;
        }

        return false;
    };

    /**
     * append a stroke to 'completed state' storage
     *    internal operation will be done like the below
     *      1) Create a stroke
     *      2) add the stroke to 'realtime state' storage
     *      3) processing page_info -> page_move -> pen_move -> ... -> pen_up
     *      4) remove the stroke from 'realtime state' storage
     *      5) append the stroke to 'completed state' storage, and remove it from 'realtime state' storage
     *
     * @private
     * @param {NeoStroke} stroke
     * @memberof InkStorage
     */
    private addCompletedToPage = (stroke: NeoStroke, dispatEvent = true) => {
        const { section, owner, book, page, pageUuid, noteUuid } = stroke;
        const pageId = makeNPageIdStrWithUuid({ section, owner, book, page, pageUuid, noteUuid });
        // console.log( `add completed: ${mac},  ${pageId} = ${section}.${book}.${owner}.${page} `);

        // stroke에 점이 하나라도 있어야 옮긴다.
        if (stroke.dotArray.length > 0) {
            // 배열이 없으면 만들어 준다.
            if (!this.completedOnPage.has(pageId)) {
                this.completedOnPage.set(pageId, new NeoStrokeArray());

                if (dispatEvent) {
                    // hand the event
                    const event: IInkStorageEvent = {
                        inputType: stroke.inputType,
                        strokeKey: stroke.key,
                        mac: stroke.mac,
                        sobps: [{ section, owner, book, page }],
                        surfaceOwnerId: stroke.surfaceOwnerId,
                        writerId: stroke.writerId
                    };

                    this.dispatcher.dispatch(InkStorageEventName.ON_PAGE_ADDED, event);
                }
            }

            // 배열에 넣는다.
            const arr = this.completedOnPage.get(pageId) || [];
            arr.push(stroke);

            // 2021/12/27
            this.strokes.push(stroke);
            if (dispatEvent) {
                this.execute({ type: 'ADD_STROKE', payload: stroke });
            }

            this.lastPageInfo = { section, owner, book, page };
            // console.log(completed);

            // 2022/09/23
            const { startTime: ts } = stroke;
            if (ts > this._endTime) this._endTime = ts;
            if (ts < this._startTime) this._startTime = ts;
        }
    };

    private execute(command) {
        this.history.push(command);
        this.future = [];
    }

    /**
     * 2022/09/18
     */

    public addStrokeWithoutEvent = (stroke: NeoStroke, dispatchEvent = false) => {
        const regKey = `${makeNPageIdStrWithUuid(stroke.sobp)}_${stroke.startTime}_${stroke.dotArray.length}`;
        // 중복된 stroke는 추가하지 않는다.
        if (this.registered.has(regKey)) return;
        this.registered.set(regKey, true);

        this.completed.set(stroke.key, stroke);
        if (stroke.originalKey) this.originalKeyStrokes.set(stroke.originalKey, stroke);
        this.addCompletedToPage(stroke, dispatchEvent);

        // console.log(this.strokes.length);
    };

    public addStroke = (stroke: NeoStroke, shouldNotMoveToCompleteSet = false) => {
        if (!shouldNotMoveToCompleteSet) {
            this.addStrokeWithoutEvent(stroke, true);

            // 렌더러에 알린다
            const event: IInkStorageEvent = { stroke, sobps: [stroke.sobp], isLive: true };

            this.dispatcher.dispatch(InkStorageEventName.ON_STROKE_ADDED, event);
        }

        // console.log(this.strokes.length);
    };

    private notifyStrokeErased = (command) => {
        const event: IInkStorageEvent = {
            inputType: command.payload.inputType.type || NEO_SMARTPEN_TYPE.NETWORK_PEN,
            erasedStrokes: [command.payload],
            isLive: false
        };
        this.dispatcher.dispatch(InkStorageEventName.ON_STROKE_ERASED, event);
    };

    private notifyStrokeAdded = (command) => {
        const event: IInkStorageEvent = {
            stroke: command.payload,
            sobps: [command.payload.sobp],
            isLive: false
        };

        this.dispatcher.dispatch(InkStorageEventName.ON_STROKE_ADDED, event);
    };

    //Todo: NDP에 알려주기
    public undo = () => {
        const command = this.history.pop();

        if (!command) return;

        if (command.type === 'ADD_STROKE') {
            this.markErased(command.payload);
            this.notifyStrokeErased(command);
        } else if (command.type === 'DELETE_STROKE') {
            this.markNormal(command.payload);
            this.notifyStrokeAdded(command);
        }
        // NDPSyncController.instance.handleCompletedDot(command);
        this.future.push(command);
    };

    //Todo: NDP에 알려주기
    public redo = () => {
        const command = this.future.pop();

        if (!command) return;

        if (command.type === 'ADD_STROKE') {
            this.markNormal(command.payload);
            this.notifyStrokeAdded(command);
        } else if (command.type === 'DELETE_STROKE') {
            this.markErased(command.payload);
            this.notifyStrokeErased(command);
        }
        // NDPSyncController.instance.handleCompletedDot(command);
        this.history.push(command);
    };

    public pageInfoNotify = (args: { sobp: IPageSOBP; stroke: NeoStroke }) => {
        const { sobp, stroke } = args;
        const event: IInkStorageEvent = { stroke, sobps: [sobp] };
        this.dispatcher.dispatch(InkStorageEventName.ON_PAGE_CHANGED, event);
    };

    /**
     * 2021/06/09 추가
     *
     * @param {string} erasedStrokeKey
     * @param {IPageSOBP} sobp
     * @memberof InkStorage
     */
    public markStrokeErased = (erasedStrokeKey: string, eraserStroke: INeoStroke, sobp: IPageSOBP, inputType: INeoSmartpenType) => {
        const pageId = makeNPageIdStrWithUuid(sobp);
        const pageStrokes = this.completedOnPage.get(pageId);
        const erasedStroke = pageStrokes?.find((ns) => ns.key === erasedStrokeKey);
        if (erasedStroke) {
            this.markErased(erasedStroke);

            // 렌더러에 알린다
            const event: IInkStorageEvent = {
                inputType: inputType || NEO_SMARTPEN_TYPE.NETWORK_PEN,
                erasedStrokes: [erasedStroke]
                // eraserStroke: eraserStroke,
            };
            this.dispatcher.dispatch(InkStorageEventName.ON_STROKE_ERASED, event);

            // TODO: 네트워크 센더에 알려야 하는 부분을 추가하자
            const networkEvent: IInkStorageEvent = {
                inputType: inputType || NEO_SMARTPEN_TYPE.NETWORK_PEN,
                erasedStrokes: [erasedStroke],
                eraserStroke
            };
            this.dispatcher.dispatch(InkStorageEventName.ON_STROKE_ERASED_TO_REMOTE, networkEvent);
        }
    };

    public markStrokeErasedWithOriginalKey = (originalKey: string, sobp: IPageSOBP, inputType: INeoSmartpenType) => {
        const pageId = makeNPageIdStrWithUuid(sobp);

        // original key가 있는 stroke만
        const pageStrokes = this.completedOnPage.get(pageId)?.filter((stroke) => !!stroke.originalKey);

        const erasedStroke = pageStrokes?.find((ns) => ns.originalKey === originalKey);
        if (erasedStroke) {
            this.markErased(erasedStroke);

            // 렌더러에 알린다
            const event: IInkStorageEvent = {
                inputType: inputType || NEO_SMARTPEN_TYPE.NETWORK_PEN,
                erasedStrokes: [erasedStroke]
            };

            this.dispatcher.dispatch(InkStorageEventName.ON_STROKE_ERASED, event);
        }
    };

    public hasStroke = (strokeKey: string) => this.completed.has(strokeKey);

    public hasOriginalKeyStroke = (originalKey: string) => this.originalKeyStrokes.has(originalKey);
}
