
import { createTheme, Theme } from '@mui/material/styles';

export default function configureTheme() {
	const theme: Theme = createTheme({
		// 필요한 다른 테마 속성들
	});

	theme.shape.borderRadius = 5;

	return {
		...theme,
		drawerWidth: 240,
	};
};
