
import {
	Box,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { StudentResultStyle } from "../../../styles/StudentResultStyle";
import TextViewer from "../../../../../components/common/TextViewer";
import { useTranslation } from 'react-i18next';
import React from "react";
import { IQuestionInfo } from "../../../../../repositories/model/transfer/IQuestionInfo";

type Props = {
	questionInfo: IQuestionInfo;
	examOrderAndQuestionsLengthMap: any;
};

export const StandardAnswerRow = (props: Props) => {
	const classes = useEmotionStyles( StudentResultStyle );
	const { questionInfo, examOrderAndQuestionsLengthMap } = props;
	const i18next = useTranslation();

	const questionFullOrder = React.useMemo(() => {
		const questionsLen = examOrderAndQuestionsLengthMap[questionInfo.parentOrder];
		if (!questionsLen) return '-';

		if (questionsLen > 1) {
			return `${questionInfo.parentOrder}-${questionInfo.order}`;
		}

		return questionInfo.parentOrder;
	}, [questionInfo, examOrderAndQuestionsLengthMap]);

	return (
		<Box id={`standard-answer-${questionFullOrder}`}>
			<Typography className={classes.textArticleTitle}>{i18next.t("문항")}{questionFullOrder}</Typography>
			<Box className={classes.redContainer}>
				<TextViewer currentHTML={questionInfo.correctedAnswer} />
			</Box>
		</Box>
	);
};
