
import {
	Box,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { MarkFeedbackStyle } from "../../../styles/MarkFeedbackStyle";

import TextViewer from "../../../../../components/common/TextViewer";

import { useTranslation } from 'react-i18next';
import { ProjectExamType } from "../../../../../repositories/model/support/ProjectExamType";
import { IExamInfo } from "../../../../../repositories/model/transfer/IExamInfo";
import { useCurrentParagraphHTML } from "../../../000_Common/001_Functions/useCurrentParagraphHtml";
import { useIsParagraphAndSourceBoxEmpty } from "../../../000_Common/001_Functions/useIsParagraphAndSourceBoxEmpty";
import { QuestionRow } from './QuestionRow';

type Props = {
	examInfo: IExamInfo;
}

export const ExamRow = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( MarkFeedbackStyle );
	const { examInfo } = props;

	// const currentParagraphHTML = React.useMemo(() => {
	// 	if (!examInfo.paragraph && !examInfo.source) return "<></>";
	// 	const sourceFontSize = "0.75rem";
	// 	const sourceColor = "#787878";
	// 	const source = examInfo.source.replace(/\n/g, "<br>");
	// 	return "<p>" + examInfo.paragraph + "\n\n" + `<div style='margin-top: 15px; color: ${sourceColor};font-size: ${sourceFontSize}; text-align: right'>` + source + '</div>' + "</p>";
	// }, [examInfo.paragraph, examInfo.source]);
	const currentParagraphHTML = useCurrentParagraphHTML({ examInfo });

	// const isParagraphAndSourceBoxEmpty = React.useMemo(() => {
	// 	const emptyParagraph = !examInfo.paragraph || (DataUtils.getTagArrFromHtml(examInfo.paragraph, 'img').length === 0 && !DataUtils.getTextFromHtml(examInfo.paragraph));
	// 	const emptySource = !examInfo.source;

	// 	return emptyParagraph && emptySource;
	// }, [examInfo.paragraph, examInfo.source]);
	const isParagraphAndSourceBoxEmpty = useIsParagraphAndSourceBoxEmpty(examInfo);

	const submitType = (type) => {
		switch (type) {
			case ProjectExamType.DESCRIPTIVE: return i18next.t("서술형");
			case ProjectExamType.ESSAY: return i18next.t("논술형");
			default: return "";
		}
	};

	return (
		<div style={{ marginTop: "20px", marginBottom: "60px" }}>
			<Typography className={classes.textArticleNumber}>{examInfo.order}번 문항 ({submitType(examInfo.type)})</Typography>
			<Typography className={classes.textArticleTitle}>{i18next.t(examInfo.directive)}</Typography>
			{!isParagraphAndSourceBoxEmpty && (
				<Box className={classes.lightBorderViewerBox}>
					<TextViewer currentHTML={currentParagraphHTML} />
				</Box>
			)}

			{examInfo?.questions?.length > 0 &&
				examInfo.questions.map((question, idx) =>
					<QuestionRow key={idx} examInfo={examInfo} questionInfo={question} />
				)}
		</div>
	)
};
