import {
	Box,
	Button,
	Dialog,
	IconButton,
	Typography
} from "@mui/material";


import { ReactComponent as DialogClose } from '../../../../../common/images/DialogClose.svg';

import i18n from "../../../../../lang";
import { ProjectStateType } from "../../../../../repositories/model/support/ProjectStateType";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { SubmitManagementStyle } from "../../../styles/SubmitManagementStyle";




export function SimpleDialog(props) {
	const classes = useEmotionStyles(SubmitManagementStyle);

	const { onClose, open, dialogType, confirmDelte, overWrite, submissionTransfer, projectCode } = props;
	const name = submissionTransfer ? `${submissionTransfer.userProfile.name}` : i18n.t("임시학생");
	const titleByType = [<Typography>{i18n.t("파일 삭제")}</Typography>, <Typography>{i18n.t("파일 등록")}</Typography>];
	const bodyByType = [<Typography>{i18n.t("({name}) 학생의 파일을 삭제하시겠습니까?", { name })}</Typography>, <Typography>{i18n.t("기존 제출물이 존재합니다. 기록을 덮어쓰시겠습니까?")}</Typography>];

	const handleClickClose = (e) => {
		onClose();
	}

	const handleClickConfirm = async (e) => {
		if (dialogType === 0) {
			//파일삭제
			await confirmDelte(submissionTransfer.submissionCode);
		} else if (dialogType === 1) {
			//파일 덮어쓰기
			// await confirmDelte(submissionTransfer.submissionCode);
			await overWrite(submissionTransfer.submissionCode, projectCode, submissionTransfer.userCode, ProjectStateType.POSED);
		}
		onClose();
	}

	return (
		<Dialog aria-labelledby="simple-dialog-title" open={open} className={classes.dataOpenDialog}>
			<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.dialogTop}>
				{titleByType[dialogType]}
				<IconButton className={classes.iconBtnReset} onClick={handleClickClose} disableRipple>
					<DialogClose />
				</IconButton>
			</Box>
			<Box className={classes.dialogContent}>
				<Box className={classes.uploadBox}>
					{bodyByType[dialogType]}
				</Box>
			</Box>

			<Box display='flex' alignItems='center' justifyContent='center' className={classes.dialogBtnGroup}>
				<Button className={classes.dialogBtn} onClick={handleClickClose} disableRipple>
					<Typography>{i18n.t("취소")}</Typography>
				</Button>
				<Button className={classes.dialogBtnSave} onClick={handleClickConfirm} disableRipple>
					<Typography>{i18n.t("삭제")}</Typography>
				</Button>
			</Box>

		</Dialog>
	);
}

