import axios from "axios";
import { makeAutoObservable } from "mobx";
import { IExamInfo } from "../repositories/model/transfer/IExamInfo";
import NeoPenRepository from "../repositories/NeoPenRepository";
import ProjectRepository from "../repositories/ProjectRepository";
import { MyScriptMimeType, MyScriptRecognitionType } from "./../neolab-libs/nl-lib3-common/typedef/myscript-types/myscript-types";

import i18n from "../lang";
import InkStorage from "../neolab-libs/nl-lib2-pagestorage/memory-stroke-storage/InkStorage";
import { getBrowserAndDeviceInfo, IBrushEnum, INeoSmartpen, IPageSOBP, isInNcodeRange, isSamePageOnlySOBP, makeNPageIdStr, NEO_SMARTPEN_TYPE, NeoStroke, OfflineDataInfo, PenEventName } from "../neolab-libs/nl-lib3-common";
import { MyScriptLocaleType } from "../neolab-libs/nl-lib3-common/typedef/myscript-types/myscript-types";
import { toInkstoreStroke } from "../neolab-libs/nl-lib3-ndp/functions/toInkstoreStroke";
import { BluetoothTypeEnum } from "../neolab-libs/nl-lib3-pengateway/src/nl-lib-bt-devices/bt-protocol/bluetooth-device-type-enum";
import { MauiRelaySocket } from "../neolab-libs/nl-lib3-pengateway/src/nl-lib-maui/maui-ws-relay/maui-relay-websocket";
import { toMyscriptMimeType } from "../neolab-libs/nl-lib3-pengateway/src/nl-lib-native-methods/MyscriptOutputMimeType";
import { NativeMethods } from "../neolab-libs/nl-lib3-pengateway/src/nl-lib-native-methods/NativeMethods";
import { deviceSelectDlg, DeviceSelectDlgResult } from "../neolab-libs/nl-lib3-pengateway/src/nl-lib-pensdk/DeviceSelectDlg";
import PenManager from "../neolab-libs/nl-lib3-pengateway/src/nl-lib-pensdk/PenManager";
import { IProject } from "../repositories/model/IProject";
import { ProjectExamType } from "../repositories/model/support/ProjectExamType";
import NcodeAllocationRepository from "../repositories/NcodeAllocationRepository";
import { IRecognitionOptions, IRecognitionRequest } from "./support/IRecognitionRequest";
import { PenState } from "./support/PenState";
import { DeeplinkPopupVisible } from "../neolab-libs/nl-lib3-pengateway/src/applink-handler/openAppWithDeepLink";
// import Project from "../views/main/Project";

const prefix = '[NeoPenStore]';


type NeoPenStoreProps = {
	neoPenRepository: NeoPenRepository;
	projectRepository: ProjectRepository;
	ncodeAllocationRepository: NcodeAllocationRepository
};

export default class NeoPenStore {
	public neoPenRepository: NeoPenRepository;
	public projectRepository: ProjectRepository;

	public ncodeAllocationRepository: NcodeAllocationRepository;

	public penState: string;
	public pens: any[];
	public penColor: string;
	public penThickness: number;
	public penType: IBrushEnum;
	public sortedOfflineStrokes: any[];
	public isSamrtPenPopupClosed: boolean;



	constructor(props: NeoPenStoreProps) {
		this.neoPenRepository = props.neoPenRepository;
		this.projectRepository = props.projectRepository;
		this.ncodeAllocationRepository = props.ncodeAllocationRepository;

		this.penState = PenState.disconnected;
		this.pens = [];

		this.penColor = '#000000';
		this.penThickness = 0.4;
		this.penType = IBrushEnum.PEN;

		this.sortedOfflineStrokes = [];

		this.isSamrtPenPopupClosed = false;

		makeAutoObservable(this);
	}

	setPenState(neoPenState) {
		this.penState = neoPenState;
	}

	addEventsOnPenManager() {
		console.log("addEventsOnPenManager")

		const pM = PenManager.instance;

		const onConnect = (e) => {
			console.log("onConnect", e.pen.getMac());

			const pen = e.pen;

			if (
				!(
					pen.inputType.type === NEO_SMARTPEN_TYPE.SDK_PEN.type ||
					pen.inputType.type === NEO_SMARTPEN_TYPE.REAL_PEN.type ||
					pen.inputType.type === NEO_SMARTPEN_TYPE.MOUSE_PEN.type
				)
			) {
				return;
			}

			//TODO : set SurfaceOwnerId, WriterId with userId
			// pen.setSurfaceOwnerId(userId);
			// pen.setWriterId(userId);

			const color = this.penColor;
			const thickness = this.penThickness;

			pen.penState[IBrushEnum.PEN].color = color;
			pen.penState[IBrushEnum.MARKER].color = color;

			pen.penState[IBrushEnum.PEN].thickness = thickness;
			pen.penState[IBrushEnum.MARKER].thickness = thickness;

			pen.setPenRendererType(this.penType);

			this.pens = [...this.pens, pen];

			if (pen.inputType.type !== NEO_SMARTPEN_TYPE.MOUSE_PEN.type) {
				this.setPenState(PenState.connected);
			}
		};

		const onDisconnect = (e) => {
			this.setPenState(PenState.disconnected);

			this.pens = this.pens.filter((pen) => pen.mac !== e.pen.getMac());
		};

		pM.addEventListener(PenEventName.ON_CONNECTED, (e) => onConnect(e));
		pM.addEventListener(PenEventName.ON_DISCONNECTED, (e) => onDisconnect(e));
	}

	async connectPen(
		webDeviceSelectResult?: {
			success: DeviceSelectDlgResult;
			device: BluetoothDevice;
		}

	): Promise<{ success: DeviceSelectDlgResult, pen: INeoSmartpen }> {
		let device = null;

		const penManager_instance = PenManager.getInstance();

		this.setPenState(PenState.connecting);

		try {
			const result = webDeviceSelectResult || await deviceSelectDlg();
			if (result.success === DeviceSelectDlgResult.Cancel || result.success === DeviceSelectDlgResult.BluetoothError) {
				return { success: result.success, pen: null };
			}
			device = result.device;
		} catch (e) {
			this.setPenState(PenState.disconnected);
			return { success: DeviceSelectDlgResult.UnknownFail, pen: null };
		}

		if (device === null) {
			this.setPenState(PenState.disconnected);
			return { success: DeviceSelectDlgResult.UnknownFail, pen: null };
		}

		const pen = PenManager.instance.createPen({
			userId: 'aea.web',
			bluetoothType: BluetoothTypeEnum.WEB
		});

		const passwordRequestMsg = (retryCount: number, retryLimit: number) => {
			return i18n.t("펜의 비밀번호를 입력하세요. {{retryRemain}}회 이상 비밀번호를 잘못 넣는 경우 펜의 데이터가 초기화됩니다.", { retryRemain: retryLimit - retryCount });
		}

		const success = await pen.connectBrowserPen({ device, requestOnlineData: false, passwordRequestMsg });

		if (!success) {
			this.setPenState(PenState.disconnected);
			return { success: DeviceSelectDlgResult.UnknownFail, pen: null };
		}

		if (success && penManager_instance.getActivePen() == null) {
			penManager_instance.setActivePen(pen.getMac());

			this.setPenState(PenState.connected);
		}

		return { success: DeviceSelectDlgResult.Success, pen };
	}

	async disconnectPen() {
		if (this.penState === PenState.connected) {
			let pen = undefined;

			for (const p of this.pens) {
				if (p.inputType.type === NEO_SMARTPEN_TYPE.REAL_PEN.type) {
					pen = p;

					break;
				}
			}

			if (!pen) return;

			if (pen) {
				await pen?.disconnect();
			}
		}
	}

	async groupStrokesByQuestion(
		args: {
			assignmentProject: IProject,
			examList: IExamInfo[],
			wholeStrokes: NeoStroke[],
			questionNameToPageInfo: {
				[key: string]: {
					page: number,
					descriptiveCount: number,
				}
			}
		}
	) {
		const { assignmentProject, examList, wholeStrokes: _wholeStrokes, questionNameToPageInfo } = args;
		const wholeStrokes = _wholeStrokes || [];

		console.log("recognizeOfflineStrokes examList", examList);

		const codeToStrokes = {};

		const assignmentProjectCode = assignmentProject.code;
		const templateProject = assignmentProject.templateCode;

		const allocation = await this.ncodeAllocationRepository.getProjectLastAllocation(assignmentProjectCode, templateProject);

		if (!allocation) {
			return null;
		}

		const sobp: IPageSOBP = {
			section: allocation.section,
			owner: allocation.startOwner,
			book: allocation.startBook,
			page: allocation.startPage
		};

		for (let examIndex = 0; examIndex < examList.length; examIndex++) {
			const type = examList[examIndex].type;
			const questions = examList[examIndex].questions;

			for (let questionIndex = 0; questionIndex < questions.length; questionIndex++) {
				let questionText = (examIndex + 1) + "-" + (questionIndex + 1);
				let code = examList[examIndex].code + "-" + (questionIndex + 1);

				const { page, descriptiveCount } = questionNameToPageInfo[questionText];

				const contentSobp = { ...sobp, page: sobp.page + page };

				if (type === ProjectExamType.DESCRIPTIVE) { // 서술형
					// const strokes = InkStorage.instance.getPageStrokes(contentSobp);
					const strokes = wholeStrokes.filter((stroke) => isSamePageOnlySOBP(stroke.sobp, contentSobp));

					codeToStrokes[code] = [];

					for (const stroke of strokes) {
						if (descriptiveCount === 0) {
							if (stroke.dotArray[0]?.y > 20 && stroke.dotArray[0]?.y < 75) {
								codeToStrokes[code].push(stroke);
							}
						}

						if (descriptiveCount === 1) {
							if (stroke.dotArray[0]?.y >= 75) {
								codeToStrokes[code].push(stroke);
							}
						}
					}
				}

				if (type === ProjectExamType.ESSAY) { // 논술형
					// const strokes = InkStorage.instance.getPageStrokes(sobp);
					const strokes = wholeStrokes.filter((stroke) => isSamePageOnlySOBP(stroke.sobp, contentSobp));

					codeToStrokes[code] = [];

					for (const stroke of strokes) {
						if (stroke.dotArray[0]?.y > 20) {
							codeToStrokes[code].push(stroke);
						}
					}
				}
			}
		}

		return codeToStrokes;
	}

	async removeOfflineData(args: {
		range: { start: IPageSOBP, end: IPageSOBP }
	}) {
		const { range } = args
		const pens = PenManager.instance.getPens();
		console.log("removeOfflineData pens", pens);

		let pen = undefined;
		for (const p of pens) {
			if (p.inputType.type === NEO_SMARTPEN_TYPE.REAL_PEN.type) {
				pen = p;

				break;
			}
		}

		if (!pen) return;

		// page 정보를 제외한 offline data를 요청하고
		try {
			const offlineDataBookList = await pen.requestOfflineDataList(pen, range);

			console.log("offlineDataPageList", offlineDataBookList);

			for (const offlineDataPage of offlineDataBookList) {

				await pen.reqOfflineData({
					s: offlineDataPage.Section,
					o: offlineDataPage.Owner,
					b: offlineDataPage.Note,
					pgs: offlineDataPage.Pages,
					deleteOnFinished: true
				});

			}
		} catch (e) {
			console.error("removeOfflineData error", e);
		}

		InkStorage.instance.strokes.splice(0, InkStorage.instance.strokes.length);
		InkStorage.instance.completed.clear();
		InkStorage.instance.registered.clear();
		InkStorage.instance.originalKeyStrokes.clear();
		InkStorage.instance.completedOnPage.clear();

		console.log("removeOfflineData finished");
	}

	async getOfflineData(
		args: {
			range: { start: IPageSOBP, end: IPageSOBP }
		}
	): Promise<{ success: "success" | "error" | "pen_error", numOfflinePages: number; numOfflineStrokes: number; strokes: NeoStroke[]; }> {
		const { range } = args;
		const pens = PenManager.instance.getPens();

		console.log("getOfflineData pageSize:", range);

		let pen = undefined as INeoSmartpen;

		for (const p of pens) {
			if (p.inputType.type === NEO_SMARTPEN_TYPE.REAL_PEN.type) {
				pen = p;

				break;
			}
		}

		if (!pen) return {
			success: "pen_error",
			numOfflinePages: 0,
			numOfflineStrokes: 0,
			strokes: []
		};

		const offlineDataPageList = await this.getOfflinePageList(pen, range);
		if (!offlineDataPageList || offlineDataPageList.length === 0) {
			return {
				success: "success",
				numOfflinePages: 0,
				numOfflineStrokes: 0,
				strokes: []
			};
		}

		// page 정보를 제외한 offline data를 요청하고
		let numOfflineStrokes = 0;
		let numOfflinePages = 0;

		let strokes: NeoStroke[] = [];
		for (const offlineDataPage of offlineDataPageList) {
			numOfflinePages++;
			try {
				const offlineData = await pen.reqOfflineData({
					s: offlineDataPage.Section,
					o: offlineDataPage.Owner,
					b: offlineDataPage.Note,
					pgs: offlineDataPage.Pages,
					deleteOnFinished: false
				});

				console.log(prefix, 'offlineData', offlineData);
				strokes = offlineData.strokes;

				// 시험 이전에 쓴 데이터는 제외 (F30에서는 동작하지 않음으로 삭제)
				// const strokes = []
				// for (const stroke of offlineData.strokes) {
				// 	let index = 0;
				// 	for (const sortedStroke of strokes) {
				// 		if (sortedStroke.startTime > stroke.startTime) break;
				// 		index++;
				// 	}
				// 	strokes.splice(index, 0, stroke);
				// }

				for (let i = 0; i < strokes.length; i++) {
					const stroke = strokes[i];
					const strokeSobpStr = makeNPageIdStr(stroke);
					console.log(prefix, 'stroke sobp : ', strokeSobpStr);

					stroke.thickness = 0.4;

					for (const dot of stroke.dotArray) {
						dot.f = 0.5;
					}

					// if (stroke.section !== 0) {
					stroke.uploaded = true;
					// InkStorage.instance.addStroke(stroke);
					numOfflineStrokes++;
					// }
				}

				this.sortedOfflineStrokes = strokes;
			} catch (e) {
				console.log(prefix, 'continue', offlineDataPage, e);
				return {
					success: "error",
					numOfflinePages,
					numOfflineStrokes,
					strokes
				};

			}
		}


		return { success: "success", numOfflinePages, numOfflineStrokes, strokes };
	}

	async getTextFromTaskId(taskId: string): Promise<string | "FAILED"> {
		if (!taskId) {
			return "FAILED";
		}

		const limit = 100; // 100번

		for (let i = 0; i < limit; i++) {
			const taskRes = await axios.get(`/api/v1/neolab/recognition/task/${taskId}`);
			console.log(`getTextFromTaskId result, try #${i + 1} `, taskId, taskRes.data);

			const { status } = taskRes.data?.contents || {};

			if (status === "FAILED") {
				return "FAILED";
			}

			if (status !== "PENDING" && status !== "PROCESSING") {
				let resultText = JSON.parse(taskRes.data.contents.result);
				console.log(`getTextFromTaskId Finished at try#${i + 1}, ${taskId}:`, taskRes.data, resultText);

				return resultText[0].iink.label;
			}

			// 1초 더하기 random 0~1000ms
			await new Promise((resolve) => setTimeout(resolve, 4000 + Math.floor(Math.random() * 2000)));
		}

		return "FAILED";
	}

	private async requestStrokeRecognitionFromServer(requestData: IRecognitionRequest) {
		try {
			const taskRes = await axios.post(
				'/api/v1/neolab/recognition',
				requestData
			);

			if (taskRes) {
				const taskId = taskRes.data?.taskId;
				console.log("taskRes", taskRes.data?.taskId);

				if (taskId) {
					const text = await this.getTextFromTaskId(taskId);
					console.log("requestStrokeRecognition fin text:", text);
					return text;
				}

				return null;

			}
		} catch (e) {
			console.log("requestStrokeRecognition error", e);
		}

		return null;
	}


	private async requestStrokeRecognitionFromLocalEngine(requestData: IRecognitionRequest) {
		const jsonStr = JSON.stringify(requestData);
		const recognitionOptions: IRecognitionOptions = requestData.pages?.[0]?.recognition || {} as IRecognitionOptions;

		const locale = (recognitionOptions.language || "ko_KR") as MyScriptLocaleType;

		const contentType = (recognitionOptions.contentType || "Text") as MyScriptRecognitionType;

		const outputMimeType = toMyscriptMimeType(recognitionOptions.responseContentType) || MyScriptMimeType.JIIX;

		let resultJson: any = null;
		try {
			resultJson = await NativeMethods.RequestRecognizeWithTask(locale, outputMimeType, jsonStr, contentType);
		}
		catch (e) {
			console.error(`RequestRecognize failed: `, e);
			return null;
		}

		if (!resultJson) {
			return null;
		}

		try {
			const jiix = JSON.parse(resultJson.jiix);
			if (jiix?.type === "Text") {
				return jiix.label;
			}
			return null;
		}
		catch (e) {
			console.error(e);
		}

		return null;
	}



	async requestStrokeRecognition(locale: string, strokes: NeoStroke[], fromServer = false) {
		const inkStoreStrokes: {
			deleteFlag: number;
			startTime: number;
			dotCount: number;
			dots: string;
		}[] = [];

		if (strokes.length === 0) {
			return "";
		}

		for (const stroke of strokes) {
			const inkStoreStroke = toInkstoreStroke(stroke);
			const simple = {
				deleteFlag: inkStoreStroke.deleteFlag,
				startTime: inkStoreStroke.startTime,
				dotCount: inkStoreStroke.dotCount,
				dots: inkStoreStroke.dots,
			}
			inkStoreStrokes.push(simple);
		}


		const sobp: IPageSOBP = {
			section: strokes[0].section,
			owner: strokes[0].owner,
			book: strokes[0].book,
			page: strokes[0].page
		}

		const requestData: IRecognitionRequest = {
			"mimeType": "application/vnd.neolab.ndp2.stroke+json",
			"pages": [{
				"section": sobp.section,
				"owner": sobp.owner,
				"bookCode": sobp.book,
				"pageNumber": sobp.page,

				"recognition": {
					"xDpi": 110,
					"yDpi": 110,
					"width": 297,
					"height": 297,
					"scale": 10,
					"language": "ko_KR",
					"contentType": "Text",
					responseContentType: "application/vnd.myscript.jiix,application/json", // "application/vnd.myscript.jiix,application/json" "application/mathml+xml" "application/x-latex"
					"configuration": {},
					"saveResult": false,
					"analyzer": {
						"removeShape": true,
						"separateShapesAndText": true,
						"paperWidth": 297,
						"paperHeight": 297,
						"blockSizeSamplingSteps": 3,
						"kindOfEngine": 0,
					}
				},

				"strokes": inkStoreStrokes,
			}],
		};

		// local에서 인식하라고 했는데, socket이 연결되어 있지 않으면 서버에서 인식
		if (fromServer === false && !MauiRelaySocket.instance.connected) {
			fromServer = true;
		}

		const browserInfo = getBrowserAndDeviceInfo();
		if (browserInfo.os === "Windows") {
			fromServer = false;
			while (!MauiRelaySocket.instance.connected && DeeplinkPopupVisible.visible) {
				console.log(`wait for MauiRelaySocket connected`);
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
		}

		if (fromServer) {
			const ret = await this.requestStrokeRecognitionFromServer(requestData);
			return ret;
		}
		else {
			const ret = await this.requestStrokeRecognitionFromLocalEngine(requestData);
			return ret;
		}
	}


	async uploadToInk(projectCode: string, userCode: string) {
		const noteId = await axios.post(`/api/v1/neolab/create/note?projectCode=${projectCode}&userCode=${userCode}`);

		const inkStorePages = {};

		const strokes = this.sortedOfflineStrokes;

		for (const stroke of strokes) {
			const sobpStr = makeNPageIdStr(stroke);

			if (inkStorePages.hasOwnProperty(sobpStr)) {
				const pageId = await axios.post(`/api/v1/neolab/create/page?userCode=${userCode}`, {
					noteUUID: noteId,
					section: 11,
					owner: 1,
					book: 1,
					page: stroke.page
				});

				inkStorePages[sobpStr] = {
					section: 11,
					owner: 1,
					book: 1,
					page: stroke.page,
					noteUUID: noteId,
					pageUUID: pageId
				}
			}

			inkStorePages[sobpStr].strokes.push(stroke.toInkstoreStroke());
		}

		// inkStorePagesList.push(inkStorePages);

		// return await  this.uploadToInkStorePagesToInkStore(inkStorePagesList);
	}






	/**
	 * Offline data list를 페이지 단위까지 추출, range가 있으면 range가 있는 것만 추출
	 * @param pen
	 * @param range
	 */
	async getOfflinePageList(pen: INeoSmartpen, range?: { start: IPageSOBP, end: IPageSOBP }) {
		const ret = await pen.getOfflinePageList(range);
		return ret;
	}

	setSortedOfflineStrokes(strokes: NeoStroke[]) {
		this.sortedOfflineStrokes = strokes;
	}
}