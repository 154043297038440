import {
	Box,
	Paper,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TemplateProjectViewStyle } from "../../styles/TemplateProjectViewStyle";
import { QuestionAndReferenceTabExamRow } from "./QuestionAndReferenceTabExamRow";
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";
import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { useTranslation } from "react-i18next";

type Props = {
	projectExamList: IExamInfo[];
	sideBarStepNum: number;
	onSetQuestionHTMLWithOrderAndScore: (question: string, score: number, reference: string, orderString: string) => void,

	onClickScrollToTopBtn: () => void;
	questionRef: any;

	handleClickRagEdit: () => void;
	handleClickQuestionEdit: () => void;
};


export const QuestionAndReferenceTab = (props: Props) => {
	const classes = useEmotionStyles( TemplateProjectViewStyle );
	const { projectExamList, sideBarStepNum, onSetQuestionHTMLWithOrderAndScore, handleClickRagEdit, questionRef, handleClickQuestionEdit } = props;

	const i18next = useTranslation();
	return (
		<Box sx={{ position: 'relative' }} className={classes.tabBoxQuestions}>
			{projectExamList.length > 0 &&
				projectExamList.filter(e => e.changeFlag !== ChangeFlag.DELETED)
					.map((exam, idx) => {
						return (
							<>
								<Typography style={{ color: "#000", fontWeight: 700, backgroundColor: "#ddd", paddingLeft: "10px" }}>
									{i18next.t("문제 {{problemNumber}})", { problemNumber: idx + 1 })}
								</Typography>
								<Paper sx={{ mb: 5, padding: "15px" }} variant={"outlined"} square={true}>
									<QuestionAndReferenceTabExamRow
										key={exam.key}
										idx={idx}
										examInfo={exam}
										sideBarStepNum={sideBarStepNum}
										onSetQuestionHTMLWithOrderAndScore={onSetQuestionHTMLWithOrderAndScore}
										handleClickRagEdit={handleClickRagEdit}
										questionRef={questionRef}
										handleClickQuestionEdit={handleClickQuestionEdit}
									/>
								</Paper>
							</>
						);
					})}
		</Box>
	);
};