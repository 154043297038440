
export enum StrokeStatusEnum {
  NORMAL = "normal",
  ERASED = "erased",
}


export enum StrokePageAttrEnum {
  SINGLEPAGE = "singlepage",
  MULTIPAGE = "multipage",
}

