import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Popper,
	Theme,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ReactComponent as PageUpIcon } from '../../../../common/images/PageUpIcon.svg';
import { ReactComponent as RubricWarningCircle } from '../../../../common/images/RubricWarningCircle.svg';
import { ReactComponent as TooltipClose } from '../../../../common/images/TooltipClose.svg';
import { ReactComponent as TooltipTipIcon } from '../../../../common/images/TooltipTipIcon.svg';
// import { withRouter } from "../../../../components/WithRouter";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import ProjectPreviewRightPannel from "../0_RightPannel/ProjectPreviewRightPannel";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { RubricStyle } from "../../styles/RubricStyle";


import { useTranslation } from 'react-i18next';
import { QuestionRow } from "./QuestionRow";
import { DetailPageType } from "../../../../stores/support/DetailPageType";
import { useStores } from "../../../../hooks/useStores";
import { useParams } from "react-router-dom";

const SelectingStateType = {
	INITIAL: 'INITIAL',
	PROGRESS: 'PROGRESS',
	COMPLETED: 'COMPLETED'
};

type Props = {
};

function Rubric(props: Props) {
	const params = useParams();
	const { navigateStore, projectRubricStore, messageStore } = useStores();
	const { projectExamQuestionsList, questionKeyAndSystemRubricListMap, questionKeyAndSystemRubricConditionsToSearchMap, questionListLenObjPerExam, questionKeyAndSelectedProjectRubricIdxMap, questionKeyAndSelectedSystemRubricIdsMap, isNew, examQuestionOrderArr } = projectRubricStore;
	const classes = useEmotionStyles(RubricStyle);
	const i18next = useTranslation();
	// const [selectedExamQuestionIdx, setSelectedExamQuestionIdx] = React.useState(0);

	const questionDivRefList = React.useRef(Array(projectExamQuestionsList.length).fill(null));
	const [mounted, setMounted] = React.useState(true);
	const [shownDetailIdx, setShownDetailIdx] = React.useState(0);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectingInitialDataState, setSelectingInitialDataState] = React.useState(SelectingStateType.INITIAL);
	const [childStatuses, setChildStatuses] = React.useState({});

	const naviCustom = useNaviCustom();

	let questionSetTimeOut;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	React.useEffect(() => {
		navigateStore.changeNavigateRootPage(false);
		navigateStore.changeNavigateDetailPage(DetailPageType.PROJECT);
		navigateStore.changeProjectSideBarStep(5);
		navigateStore.changeSideBarStepNum(2);

		return () => {
			navigateStore.changeIsConfirmForUpdateRequired(false);
			navigateStore.changeNavigateDetailPage(null);
			projectRubricStore.init();
		}
	}, []);

	React.useEffect(() => {
		const projectCode = params.projectCode;
		if (!projectCode || projectRubricStore.projectCode === projectCode) return;

		projectRubricStore.setProjectCode(projectCode);
		projectRubricStore.getProjectExamQuestions(projectCode);
		projectRubricStore.getInitialSystemRubric(projectCode);
		setSelectingInitialDataState(SelectingStateType.PROGRESS);

	}, [params]);

	React.useEffect(() => {
		if (selectingInitialDataState !== SelectingStateType.PROGRESS) return;

		if (
			!projectRubricStore.isSelectingProjectExamQuestions &&
			!projectRubricStore.isSelectingInitialSystemRubric
		) {
			setSelectingInitialDataState(SelectingStateType.COMPLETED);
		}
	}, [selectingInitialDataState, projectRubricStore.isSelectingProjectExamQuestions, projectRubricStore.isSelectingInitialSystemRubric]);

	React.useEffect(() => {
		if (!projectRubricStore.isConfirmForUpdateRequired
			|| navigateStore.isConfirmForUpdateRequired
		) return;

		navigateStore.changeIsConfirmForUpdateRequired(true);
	}, [projectRubricStore.isConfirmForUpdateRequired]);

	const changeIsConfirmForUpdateRequired = () => {
		if (navigateStore.isConfirmForUpdateRequired) return;

		navigateStore.changeIsConfirmForUpdateRequired(true);
	};

	const handleClickQuestionOrder = (e, idx, orderText) => {
		handleSetVisibleQuestion(idx);

		questionSetTimeOut = setTimeout(() => {
			const questionBoxTag = document.getElementById(`question-${orderText}`);
			questionBoxTag.scrollIntoView({ behavior: 'smooth', block: 'start' });

			clearTimeout(questionSetTimeOut);
		}, 100);
	};

	const handleChildStatusChange = (childId, status) => {
		setChildStatuses(prev => ({
			...prev,
			[childId]: status,
		}));
	};

	const isAnyChildTrue = Object.values(childStatuses).some(status => status === true);

	const createOrUpdateProjectRubricList = async () => {
		if (isNew) {
			return await projectRubricStore.createProjectRubricList();
		} else {
			return await projectRubricStore.updateProjectRubricList();
		}
	}

	const handleSetMountedFalse = () => {
		setMounted(false);
	};

	const handleClickPageUp = () => {
		const questionBoxTag = document.getElementById('questionBox');
		if (questionBoxTag) {
			questionBoxTag.scrollTop = 0;
		}
	};

	const processAfterStorage = () => {
		navigateStore.changeIsConfirmForUpdateRequired(false);
		projectRubricStore.changeIsConfirmForUpdateRequired(false);
	};

	const handleClickSave = async () => {
		const r2 = await messageStore.confirm(i18next.t("저장하시겠습니까?"));
		if (!r2) return;

		await createOrUpdateProjectRubricList()
			.then(async result => {
				if (result === undefined) {
					return;
				}

				if (result === false) {
					await messageStore.alert(i18next.t("저장 실패했습니다."));
					return;
				}

				processAfterStorage();
				await messageStore.alert(i18next.t("저장되었습니다."));

				if (!projectRubricStore.projectCode) return;
				naviCustom.go(`/projects/${projectRubricStore.projectCode}/create/view`);
			});
	};

	const handleClickDisabledSave = () => {
		messageStore.alert(i18next.t("AI 평가 기준표 생성 중에는 저장 버튼을 누를 수 없습니다."));
	};

	const handleSetVisibleQuestion = (idx: number) => {
		setShownDetailIdx(idx);
	};

	// 어떤 question이 보이고 있는지 확인하는 것

	const visibleQuestions = React.useRef(new Set());

	const handleVisibilityChange = (index: number, isVisible: boolean) => {
		if (isVisible) {
			visibleQuestions.current.add(index);
			setShownDetailIdx(index);
		} else {
			visibleQuestions.current.delete(index);
			for (let i = index - 1; i >= 0; i--) {
				if (visibleQuestions.current.has(i)) {
					setShownDetailIdx(i);
					break;
				}
			}
		}
	};

	return (
		<div className={classes.root}>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 86px)',
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>
				<Box className={classes.minaContentLeftRight} sx={{ position: 'relative' }}>
					<Box className={classes.mainContentBoxLeft} sx={{ position: 'relative' }}>
						<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginBottom: "10px" }}>
							<Box className={classes.moreInfoPopover}>
								<Typography className={classes.commonSubTitle}>5. {i18next.t("평가 기준표")}</Typography>
								<IconButton onClick={handleClick} className={classes.iconBtnReset} disableRipple>
									<RubricWarningCircle />
								</IconButton>
								<Popper id={id} open={open} anchorEl={anchorEl}
									className={classes.popoverTooltipInfo}
								>
									<Box className={classes.tooltipTopInfo}>
										<TooltipTipIcon />
									</Box>
									<Box display={'flex'} justifyContent={'flex-end'} className={classes.tooltipBtn}>
										<Button
											onClick={handleClose}
											disableRipple>
											<TooltipClose />
										</Button>
									</Box>
									<Typography className={classes.tooltipTitle}>{i18next.t("평가 기준표 작성 방법")}</Typography>
									<Typography className={classes.tooltipText}>{i18next.t("1. 평가과제의 ‘교과'와 ‘과목' 기준으로 자동 추천하는 표준 평가 기준표에서 항목을 선택하세요.")}<br />
										{i18next.t("2. 선택한 평가 항목에 해당되는 척도와 평가 기준으로 채점 기준을 작성합니다.")}<br />
										{i18next.t("3. AI 시스템을 적용하면 작성된 항목 및 기준과 문항을 연결하여 문맥을 파악하고 더 정확한 기준표를 얻을 수 있습니다.")}<br />
										{i18next.t("4. AI 기반 기준표를 사용하거나 사용자가 편집할 수도 있습니다.")}</Typography>
								</Popper>
							</Box>

							<Box sx={{ marginBottom: "15px" }} display='flex' alignItems='center' justifyContent='space-between'>
								<Box>
									<Button
										className={isAnyChildTrue ? classes.greenBtn : clsx(classes.greenBtn, classes.greenBtnActive)}
										onClick={isAnyChildTrue ? handleClickDisabledSave : handleClickSave}
										disableRipple>
										<Typography>{i18next.t("저장")}</Typography>
									</Button>
								</Box>
							</Box>
						</Box>
						<Box>
							<Box sx={{ marginBottom: "16px" }}>
								{examQuestionOrderArr.length > 0 &&
									examQuestionOrderArr.map((orderText, idx) => {
										return (
											<Button
												key={idx}
												className={shownDetailIdx === idx ? clsx(classes.countTopPage, classes.countTopPageActive) : classes.countTopPage}
												onClick={e => handleClickQuestionOrder(e, idx, orderText)}
												disableRipple>
												<Typography>{orderText}</Typography>
											</Button>
										)
									})
								}
							</Box>
							<Box className={classes.scrollBox} id={"questionBox"}>
								{selectingInitialDataState === SelectingStateType.COMPLETED
									&& projectExamQuestionsList.length > 0 &&
									projectExamQuestionsList.map((question, idx) =>
										<>
											<QuestionRow
												key={idx}
												questionIdx={idx}										// 질문 인덱스
												shownDetailIdx={shownDetailIdx}			// 활성화된 질문의 인덱스
												onVisibilityChanged={handleVisibilityChange}

												ref={questionDivRefList.current[idx]}
												questionInfo={question}
												questionListLen={questionListLenObjPerExam[question.parentOrder]}
												projectRubricList={projectRubricStore.projectRubricTransferList}
												selectedProjectRubricIdx={questionKeyAndSelectedProjectRubricIdxMap[question.key]}
												systemRubricConditionsToSearch={questionKeyAndSystemRubricConditionsToSearchMap[question.key]}
												systemRubricList={questionKeyAndSystemRubricListMap[question.key]}
												selectedSystemRubricIdList={questionKeyAndSelectedSystemRubricIdsMap[question.key]}
												mounted={mounted}
												statusKey={`${idx}`}
												isLastQuestion={idx === projectExamQuestionsList.length - 1}

												handleSetMountedFalse={handleSetMountedFalse}
												onVisibilityChangedTrue={handleSetVisibleQuestion}
												onStatusChange={handleChildStatusChange}
												changeIsConfirmForUpdateRequired={changeIsConfirmForUpdateRequired}
											/>
											{idx !== projectExamQuestionsList.length - 1 &&
												<div style={{ height: "40px" }}></div>
											}
										</>
									)
								}
							</Box>
						</Box>

						<IconButton
							className={classes.pageUpIcon}
							onClick={handleClickPageUp}
							disableRipple>
							<PageUpIcon />
						</IconButton>
					</Box>

					<ProjectPreviewRightPannel />
				</Box>
			</Box>

			{selectingInitialDataState === SelectingStateType.PROGRESS &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={selectingInitialDataState === SelectingStateType.PROGRESS}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("조회 중입니다...")}</Typography>
				</Backdrop>
			}

			{(projectRubricStore.isCreatingProjectRubricList || projectRubricStore.isUpdatingProjectRubricList) &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectRubricStore.isCreatingProjectRubricList || projectRubricStore.isUpdatingProjectRubricList}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("저장 중입니다...")}</Typography>
				</Backdrop>
			}

		</div>
	);
}

export default observer<typeof Rubric>(Rubric);
