import {
	Box,
	TextField
} from "@mui/material";
import useEmotionStyles from "../../../../styles/useEmotionStyles";
import { CompareDataStyle } from "../../../../styles/CompareDataStyle";

import clsx from "clsx";

import TextViewer from "../../../../../../components/common/TextViewer";

import { useCurrentOrder } from "../../../../000_Common/001_Functions/useCurrentOrder";
import { useCurrentQuestionHTML } from "../../../../000_Common/001_Functions/useCurrentQuestionHTML";
import { useStores } from "../../../../../../hooks/useStores";
import { observer } from "mobx-react";

type Props = {
	examInfo: any;
	questionInfo: any;
	editMode: boolean;
	answerList: any;
	changeAnswer: any;
}
export const QuestionAndReferenceTabQuestionRow = observer((props: Props) => {
	const { examInfo, questionInfo, editMode, answerList, changeAnswer } = props;
	const { projectExamStore } = useStores();
	const classes = useEmotionStyles(CompareDataStyle);

	// const currentOrder = React.useMemo(() => {
	// 	const questionListLen = examInfo.questions.filter(q => q.changeFlag !== FlagType.DELETED).length;
	// 	return projectExamStore.setQuestionFullOrderByQuestionListLen(questionListLen, examInfo.order, questionInfo.order);
	// }, [examInfo, examInfo.order, questionInfo.order]);
	const currentOrder = useCurrentOrder(projectExamStore, examInfo, questionInfo);

	// const currentQuestionHTML = React.useMemo(() => {
	//     return setQuestionHTMLWithOrderAndScore(questionInfo.question, questionInfo.score, currentOrder);
	// }, [questionInfo, questionInfo.question, questionInfo.score, currentOrder]);

	// const currentQuestionHTML = React.useMemo(() => {
	// 	return setQuestionHTMLWithOrderAndScore(questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder);
	// }, [questionInfo, questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder]);
	const currentQuestionHTML = useCurrentQuestionHTML(projectExamStore, questionInfo, currentOrder);

	// const examQuestionNum = (projectExamList) => {
	// 	let questionNum = 0;
	// 	for (let i = 0; i < examInfo.order; i++) {
	// 		if (i + 1 === examInfo.order) {
	// 			questionNum = questionNum + questionInfo.order;
	// 		} else {
	// 			for (let j = 0; j < projectExamList[i].questions.length; j++) {
	// 				questionNum++;
	// 			}
	// 		}
	// 	}
	// 	return questionNum;
	// }
	// const questionNum = examQuestionNum(projectExamList);
	const answerIndex = answerList.findIndex(item => item.projectExamQuestionNum === questionInfo.projectExamQuestionNum
		&& item.projectExamCode === questionInfo.projectExamCode);

	return (
		<>
			{/*<Box display='flex' alignItems='center' justifyContent='left' className={clsx(classes.dialogInput, classes.dialogInputMulti)}>*/}
			{!!currentQuestionHTML && (
				<>
					<Box sx={{ marginTop: "10px" }} display='flex' alignItems='center' justifyContent='left'>
						<TextViewer
							currentHTML={currentQuestionHTML}
						/>
					</Box>
					<Box className={clsx(classes.dialogInput, classes.dialogInputMulti)} sx={{ marginTop: "10px" }}>
						<TextField
							id="outlined-basic-compare-answer"
							autoComplete="off"
							variant="outlined"
							multiline
							// rows={10}
							minRows={15}
							maxRows={13}
							onChange={(e) => changeAnswer(e.target.value, answerIndex)}
							// value={answerList[questionNum-1] ?answerList[questionNum-1]:"" }
							// value={projectSubmissionAnswerStore.submissionAnswers.find(item=>item.projectExamQuestionNum === questionInfo.projectExamQuestionNum
							//  &&  item.projectExamCode === questionInfo.projectExamCode)?.answer}
							value={answerIndex > -1 && answerList[answerIndex].answer ? answerList[answerIndex].answer : ""}

							disabled={!editMode}
						// value={standard.standard}
						// onChange={(e)=>{projectStore.changeProjectPlanStandard(e.target.value,standardIndex)}}
						// placeholder={i18next.t('내용을 입력하세요. (최대 10,000자)')}
						/>
					</Box>
					{/*{exam.type === ProjectExamType.DESCRIPTIVE ?*/}
					{/*    <Box className={clsx(classes.bestAnswerBox, classes.bestAnswerBoxHeight)} sx={{marginBottom: "50px"}}/>*/}
					{/*    :*/}
					{/*    <Box className={clsx(classes.bestAnswerBox, classes.bestAnswerBoxMuti)} sx={{marginBottom: "50px"}}/>*/}
					{/*}*/}
					<Box style={{ marginBottom: '10' }} />
				</>
			)}
		</>
	);
});