
import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const MarkFeedbackListStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
	},
	// emptyBox:{
	//     width: '100%',
	//     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
	//     display:'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center',
	//     '& p':{
	//         fontSize: '1.375rem',
	//         color: '#87888d',
	//         fontWeight:400,
	//         textAlign: 'center',
	//         lineHeight: '30.36px'
	//     }
	// },
	// boxWrap: {
	//     backgroundColor: '#fff',
	//     padding: 20,
	//     borderRadius: 8,
	//     height: 'calc(100vh - 500px)'
	// },

	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			letterSpacing: '-0.5px',
			marginLeft: "7px",
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},


	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center',
			padding: '5px 16px',
		},
		'&.MuiListItemButton-root:hover': {
			padding: '5px 16px',
			background: '#fff'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		},
		'& .MuiListItemButton-root:hover': {
			background: '#DEF8F3'
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		},
		'& .MuiButtonBase-root': {
			height: 42
		},
		'& .MuiListItemButton-root:hover': {
			background: '#DEF8F3'
		},
		'& .MuiTypography-root': {
			display: 'none'
		}
	},





	/////////////////////
	padding: {
		padding: 15,
	},
	mainContentBox: {
		border: '1px solid #EAECEB',
		padding: 20,
		height: 'calc(100vh - 132px)',
		overflowY: 'auto',
		background: '#fff',
		maxWidth: 1024,
	},
	mainContentBoxLeft: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 130px)',
		overflowY: 'auto',
		background: '#fff',
		width: 'calc(100vw - 770px)',
	},
	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		overflowY: 'auto',
		background: '#fff',
		width: '620px',
		boxSizing: 'border-box',
		marginLeft: "10px",
	},


	commonSubTitle: {
		textAlign: 'left',
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 700,
			marginBottom: "20px",
			lineHeight: 'normal',
			letterSpacing: '-0.5px'
		}
	},
	commonSubmit: {
		textAlign: 'left',
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			marginBottom: "14px",
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "20px",
		}
	},
	schoolNameSubText: {
		color: '#555',
		fontSize: '1rem',
		letterSpacing: '-0.5px',
		marginBottom: "15px",
	},
	dialogInput: {
		'& .MuiFormControl-root': {
			width: '100%',
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: 220
		},
	},




	greenBtn: {
		'&.MuiButtonBase-root': {
			width: "168px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "168px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	selectText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 'bold',
			marginRight: "15px",
		}
	},

	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1.125rem',
			boxSizing: 'border-box',
			color: '#777',
			letterSpacing: '-0.5px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #87888d',
			borderRadius: '6px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},



	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: 'transparent',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem'
		}
	},
	pageNumber: {
		marginTop: "20px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentNameBtn: {
		'&.MuiButtonBase-root': {
			background: 'transparent',
			minWidth: 'auto',
			'& hover': {
				background: 'transparent',
			},
			'& .MuiTypography-root': {
				color: '#008C73',
				fontSize: '0.875rem',
				textDecoration: 'underline',
				textTransform: 'capitalize',
				letterSpacing: '-0.5px'
			}
		}
	},
	noDataText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '0.875rem',
		}
	},
	tableBox: {
		marginTop: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "50px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				fontWeight: 800,
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				color: '#323232',
				height: "50px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},
	iconAdd: {
		'& svg': {
			marginBottom: "2px",
			marginRight: "5px",
		}
	},

	stepOpen: {
		'&.MuiButtonBase-root': {
			border: '1px solid #111',
			width: "120px",
			boxSizing: 'border-box',
			background: '#fff',
			color: '#111',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.813rem',
				fontWeight: 'bold',
			}
		}
	},
	taskSave: {
		'&.MuiButtonBase-root': {
			border: '1px solid #E97C17',
			color: '#E97C17',
		}
	},
	taskMark: {
		'&.MuiButtonBase-root': {
			border: '1px solid #7730BA',
			color: '#7730BA',
		}
	},
	taskResult: {
		'&.MuiButtonBase-root': {
			border: '1px solid #3850D5',
			color: '#3850D5',
		}
	},



	numberBox: {
		width: "41px",
		height: "27px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 13,
		background: '#eee',
		boxSizing: 'border-box',
		marginRight: "11px",
		'& p': {
			fontSize: '0.875rem',
			color: '#777',
			fontWeight: 'bold',
			'& span': {
				color: '#38cb89',
				fontWeight: 'bold'
			}
		}
	},
	progressBox: {
		width: "80px",
		'& .MuiLinearProgress-root': {
			height: "6px",
			background: '#eee',
			borderRadius: 3
		},
		'& .MuiLinearProgress-bar': {
			background: '#38cb89'
		}
	},
	progressText: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#323232',
			marginLeft: "11px",
			'@media all and (max-width: 1550px)': {
				fontSize: '0.75rem',
			},
		}
	},

	popoverBox: {
		'& .MuiPaper-root': {
			width: "120px",
			boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
			border: '1px solid #bbb',
			borderRadius: 6,
			'& ul': {
				padding: '4px 0',
				'& li': {
					color: '#6f6f6f',
					letterSpacing: '-0.5px',
					display: 'flex',
					justifyContent: 'center',
					'& .MuiTypography-root': {
						marginLeft: "6px",
						fontSize: '0.875rem',
					},
					'&:hover': {
						background: '#eee'
					},
				},
			},
			'& *': {
				fontFamily: 'Pretendard-Regular !important',
			},
		}
	},
	deleteText: {
		'&.MuiTypography-root': {
			color: 'red'
		},
	},




	taskOpenDialog: {
		'& .MuiPaper-root': {
			width: "785px",
			height: '90vh',
			borderRadius: '15px'
		},
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 785,
		}
	},

	dialogTop: {
		borderBottom: '1px solid #CBCBCB',
		height: "70px",
		'& .MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			marginLeft: "20px",
		},
		'& .MuiIconButton-root': {
			marginRight: "20px",
		}
	},
	dialogContent: {
		padding: '30px 20px',
	},
	uploadBox: {
		textAlign: 'center',
		'& .MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#555'
		}
	},
	dialogHalf: {
		width: '50%',
	},
	dialogTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			marginBottom: "20px",
			marginTop: "30px",
			'& span': {
				fontSize: '1rem',
				fontWeight: 'normal',
				color: '#555'
			}
		}
	},
	dialogSubTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	dialogTime: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 'bold',
			marginLeft: "40px",
			marginRight: 10.
		}
	},

	dialogBtnGroup: {
		// marginTop: "30px",
		marginBottom: "20px",
	},
	dialogBtn: {
		'&.MuiButtonBase-root': {
			border: '1px solid #CBCBCB',
			width: "120px",
			height: "40px",
		},
		'&.MuiButton-root:hover': {
			background: '#fff'
		},
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	dialogBtnSave: {
		'&.MuiButtonBase-root': {
			border: '1px solid #2F80ED',
			background: '#2F80ED',
			width: "120px",
			height: "40px",
			marginLeft: "15px",
		},
		'&.MuiButton-root:hover': {
			background: '#2F80ED'
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	dialogYear: {
		position: 'relative',
		marginRight: "20px",
		'& .MuiFormControl-root': {
			width: '110px',
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 64
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},
	yearSelectIcon: {
		position: 'absolute',
		right: 0,
		top: 5,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	iconYearSelect: {
		'&.MuiIconButton-root': {
			margin: '4px 5px',
		}
	},

	dateBox: {
		'& .MuiStack-root': {
			paddingTop: 0,
			// paddingBottom: 28,
			display: 'flex',
			alignItems: 'center',
			overflow: 'unset'
		},
		'& .MuiFormControl-root': {
			minWidth: '145px !important',
			maxWidth: '145px !important',
			margin: 0
		},
		'& .MuiInputBase-root': {
			paddingRight: 0,
			borderRadius: 6
		},
		'& .MuiInputBase-input': {
			height: "30px",
			boxSizing: 'border-box',
			fontSize: '0.875rem',
			color: '#323232',
			letterSpacing: '-0.5px',
			fontWeight: 500,
			padding: '0 11px'
		},
		'& .MuiInputAdornment-root': {
			marginLeft: "0px",
			'& .MuiButtonBase-root': {
				padding: 0,
				marginRight: "5px",
				'&:hover': {
					background: 'transparent'
				}
			},
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 'solid 1px #bbbbbb',
			borderRadius: 6
		},
	},
	calendarBox: {
		cursor: 'pointer',
		width: "23px",
		height: "23px",
		marginRight: 5
	},
	waveText: {
		'&.MuiTypography-root': {
			marginLeft: "10px",
			marginRight: "10px",
		}
	},

	subSideMenu: {
		'& .MuiStepIcon-root.MuiStepIcon-active': {
			color: '#025949'
		},
		'& .MuiStepIcon-root.MuiStepIcon-completed': {
			color: '#025949'
		},
		'& .MuiStepIcon-root': {
			color: '#DDDDDD'
		},
		'& .MuiStepConnector-vertical': {
			padding: '0px 0 2px',
			marginLeft: "6px",
		},
		'& .MuiStepLabel-root': {
			'& .MuiStepLabel-vertical': {

			}
		},
		'& .MuiStepper-vertical': {
			padding: '24px 0px 24px 24px'
		},
		'& .Mui-disabled': {
			'& rect': {
				fill: '#ddd'
			}
		},
		'& .MuiStepLabel-labelContainer': {
			textAlign: 'left'
		},

	},
	nameSearchBtn: {
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#787878',
				marginLeft: "5px",
			}
		}
	},

	sortButton: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		'& p': {
			fontSize: '0.875rem',
			color: '#555',
			marginRight: "4px",
			marginLeft: "10px",
			letterSpacing: '-0.5px'
		}
	},
	urlInputBox: {
		'&.MuiPaper-root': {
			width: "180px",
			height: "35px",
			border: '1px solid #87878;',
			boxShadow: 'none'
		},
		'& .MuiInputBase-root': {
			width: "150px",
		},
		'& .MuiInputBase-input': {
			padding: '8px 10px 7px',
		}
	},
	urlBtn: {
		marginLeft: "10px",
		'&.MuiButtonBase-root': {
			width: "82px",
			height: "27px",
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				letterSpacing: '-0.5px',
			}
		}
	},
	urlBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#fff'
			}
		}
	},
	submitScanText: {
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#111',
			marginLeft: "20px",
			marginRight: "5px",
			'& span': {
				fontWeight: 700,
				color: '#008C73'
			}
		}
	},
	submitScanTextPointer: {
		position: 'relative',
		'&:before': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#111',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-13px',
			top: 8,
		},
	},
	emptyTableData: {
		borderBottom: '1px solid #cbcbcb'
	},
	emptyBgTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 700,
			marginTop: "28px",
			marginBottom: "20px",
		}
	},
	emptyBgText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 400,
			color: '#555',
			marginBottom: "45px"
		}
	},
	emptyViewBtn: {
		'&.MuiButtonBase-root': {
			background: '#008C73',
			width: "168px",
			height: "36px",
			'& .MuiTypography-root': {
				fontSize: '1rem',
				fontWeight: 400,
				color: '#fff'
			}

		}
	},
	matchAfter: {
		fontSize: '0.875rem',
		color: '#008C73',
		marginLeft: "25px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: "18px",
			height: "18px",
			background: '#03BF69',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-25px',
			top: 2,
		},
	},
	matchBefore: {
		fontSize: '0.875rem',
		color: '#111',
		marginLeft: "25px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: "18px",
			height: "18px",
			background: '#CBCBCB',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-25px',
			top: 2,
		},
	},
	matchTemp: {
		fontSize: '0.875rem',
		color: '#F31825',
		marginLeft: "25px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: "18px",
			height: "18px",
			background: '#F31825',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-25px',
			top: 2,
		},
	},
	unsubmit: {
		fontSize: '0.875rem',
		color: '#111',
		position: 'relative',
		marginLeft: "25px",
		'&:before': {
			content: "''",
			width: "18px",
			height: "18px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-25px',
			top: 2,
		},
	},
	submit: {
		fontSize: '0.875rem',
		color: '#111',
		position: 'relative',
		marginLeft: "25px",
		'&:before': {
			content: "''",
			width: "18px",
			height: "18px",
			background: '#E97C17',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-25px',
			top: 2,
		},
	},
	enrollDelete: {
		'&.MuiButtonBase-root': {
			padding: '6px 8px 6px 0px',
			'&:hover': {
				background: '#fff'
			}
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			marginLeft: "4px",
			color: '#787878'
		}
	},
	enrollDeleteActive: {
		'&.MuiButtonBase-root': {
			padding: '6px 8px 6px 0px',
			'&:hover': {
				background: '#fff'
			}
		},
		'& .MuiTypography-root': {
			color: '#F31825'
		}
	},
	enroll: {
		marginRight: "15px",
		'&.MuiButtonBase-root': {
			padding: '6px 8px 6px 0px',
			'&:hover': {
				background: '#fff'
			}
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			marginLeft: "4px",
			color: '#111'
		}
	},
	checkMargin: {
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555'
		}
	},
	submitSubText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555'
		}
	},

	popoverTooltip: {
		'& .MuiPopover-paper': {
			background: '#2078E8',
			color: '#fff',
			minWidth: 180,
			minHeight: 30,
			maxWidth: 200,
			padding: '10px 10px 10px 15px',
			overflow: 'visible',
			marginTop: "15px",
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '15px',
			lineHeight: '21px',
			letterSpacing: '-0.5px',
		}
	},
	tooltipTop: {
		position: 'absolute',
		top: -10,
		left: '47%',
		color: '#000'
	},
	tooltipBtn: {
		'& .MuiButtonBase-root': {
			padding: 0,
			minWidth: 'auto',
			'&:hover': {
				background: 'transparent'
			}
		}
	},
	tooltipTitle: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '1.125rem',
			letterSpacing: '-0.5px',
			fontWeight: 700,
			marginBottom: "5px",
		}
	},
	tooltipText: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '0.938rem',
			letterSpacing: '-0.5px',
			marginBottom: "10px",
		}
	},
	tooltipUploadBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			width: '100%',
			'& .MuiTypography-root': {
				color: '#111',
				fontSize: '0.875rem',
				letterSpacing: '-0.5px',
				fontWeight: 700,
			}
		}
	},
	dataOpenDialog: {
		'& .MuiPaper-root': {
			width: "785px",
			height: '25vh',
			borderRadius: '15px'
		},
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 785,
		}
	},
	aiReMark: {
		'&.MuiButtonBase-root': {
			width: "120px",
			height: "32px",
			background: '#111',
			borderRadius: 3,
			marginRight: "20px",
			'& .MuiTypography-root': {
				color: '#fafafa',
				fontSize: '0.813rem',
				fontWeight: 700,
				letterSpacing: '-0.5px'
			}
		}
	},
	aiMark: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "32px",
			background: '#7730BA',
			borderRadius: 3,
			marginRight: "20px",
			'& .MuiTypography-root': {
				color: '#fafafa',
				fontSize: '0.813rem',
				fontWeight: 700,
				letterSpacing: '-0.5px'
			},
			'& svg': {
				marginRight: "5px",
			}
		}
	},
	reMark: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "32px",
			background: '#fff',
			borderRadius: 3,
			marginRight: "20px",
			border: '1px solid #111',
			'& .MuiTypography-root': {
				color: '#111',
				fontSize: '0.813rem',
				fontWeight: 700,
				letterSpacing: '-0.5px'
			}
		}
	},
	reMarkSelf: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "32px",
			background: '#fff',
			borderRadius: 3,
			marginRight: "20px",
			border: '1px solid #7730BA',
			'& .MuiTypography-root': {
				color: '#7730BA',
				fontSize: '0.813rem',
				fontWeight: 700,
				letterSpacing: '-0.5px'
			}
		}
	},
	resultNumber: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#323232',
			'& span': {
				color: '#008C73',
				fontWeight: 700,
			}
		}
	},
	centerDialog: {
		position: "fixed",
		top: "50%",
		left: "50%",
	},


	// kitty
	checkedColor: {},
});












