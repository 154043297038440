import { IBrushEnum } from "../../nl-lib3-common";

export function inkstoreStrokePenTypeToBrushType(strokeType: number, penTipMode: number) {
  if (penTipMode === 0) {
    if (strokeType === 0 || strokeType === 1) { return IBrushEnum.PEN; }
    if (strokeType === 2) { return IBrushEnum.MARKER }
    return IBrushEnum.ERASER;
  }
  if (penTipMode === 6) {
    return IBrushEnum.ERASERPEN;
  }
  return IBrushEnum.ERASER;
}

// TODO: 이것 확인할 것
export function brushTypeToInkstoreStrokePenType(brushType: IBrushEnum) {
  if (brushType === IBrushEnum.PEN) return { penTipMode: 0, strokeType: 0 };
  if (brushType === IBrushEnum.PENCIL) return { penTipMode: 0, strokeType: 0 };
  if (brushType === IBrushEnum.MARKER) return { penTipMode: 0, strokeType: 2 };
  if (brushType === IBrushEnum.ERASER) return { penTipMode: 9, strokeType: 9 };
  if (brushType === IBrushEnum.ERASERPEN) return { penTipMode: 6, strokeType: 6 };
  return { penTipMode: 0, strokeType: 0 };
}

export function penTipModeToBrushType(penTipMode: number) {
  if (penTipMode === 0) return IBrushEnum.PEN;
  return IBrushEnum.ERASER;
}
