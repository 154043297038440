/**
 * Provides a mechanism for releasing resources.
 */

export interface IDisposable {
    dispose();
}

export function using<T extends IDisposable>(resource: T, func: (resource: T) => void) {
    try {
        func(resource);
    } finally {
        resource.dispose();
    }
}

// Example use:
class Camera implements IDisposable {
    takePicture() { /* omitted */ }
    // etc...
    dispose() {
        // (navigator as any).camera.cleanup();
        console.log(`cleanup`);
    }
}

using(new Camera(), (camera) => {
    camera.takePicture();
});




export interface IDisposableAsync {
    dispose(): Promise<void>;
}

/**
 * Provides a convenient syntax that ensures the correct use of IDisposable objects
 */
export async function usingAsync<T extends IDisposableAsync>(resource: T, func: (resource: T) => Promise<void>) {
    try {
        await func(resource);
    } finally {
        await resource.dispose();
    }
}



// example

// (async () => {
//     await using(ioc.get<IUdpClient>(types.IUdpClient), async (client) => {
//         client.connect(endpoint);
//         client.send({toBuffer: () => Buffer.from("hello world1")});
//         let response = await client.receive();
//         console.log(response);

//         await Task.delay(1000);
//         client.send({toBuffer: () => Buffer.from("hello world2")});
//         response = await client.receive();
//         console.log(response);


//         console.log("using complete");
//     });
//     console.log("end");
// })();