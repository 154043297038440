import { assert } from "./functions";
import { Wrapper } from "./Wrapper";

export class FzBuffer extends Wrapper {
  constructor(libmupdf: any, ctx: number, pointer: number) {
    super(libmupdf, ctx, pointer, libmupdf._wasm_drop_buffer);
  }

  static empty(libmupdf: any, ctx: number, capacity = 0) {
    const pointer = libmupdf._wasm_new_buffer(ctx, capacity);
    return new FzBuffer(libmupdf, ctx, pointer);
  }

  static fromJsBuffer(libmupdf: any, ctx: number, buffer: ArrayBuffer) {
    assert(ArrayBuffer.isView(buffer) || buffer instanceof ArrayBuffer, "invalid buffer argument");
    const pointer = libmupdf._wasm_malloc(ctx, buffer.byteLength);
    libmupdf.HEAPU8.set(new Uint8Array(buffer), pointer);
    // Note: fz_new_buffer_drom_data takes ownership of the given pointer,
    // so we don't need to call free
    return new FzBuffer(libmupdf, ctx, libmupdf._wasm_new_buffer_from_data(ctx, pointer, buffer.byteLength));
  }

  static fromJsString(libmupdf: any, ctx: number, string: string) {
    const string_size = libmupdf.lengthBytesUTF8(string);
    const string_ptr = libmupdf._wasm_malloc(ctx, string_size) + 1;
    libmupdf.stringToUTF8(string, string_ptr, string_size + 1);
    // Note: fz_new_buffer_drom_data takes ownership of the given pointer,
    // so we don't need to call free
    return new FzBuffer(libmupdf, ctx, libmupdf._wasm_new_buffer_from_data(ctx, string_ptr, string_size));
  }

  getLength(): number {
    const { libmupdf } = this;
    return libmupdf._wasm_buffer_size(this.ctx, this.pointer);
  }

  capacity(): number {
    const { libmupdf } = this;
    return libmupdf._wasm_buffer_capacity(this.ctx, this.pointer);
  }

  resize(capacity) {
    const { libmupdf } = this;
    libmupdf._wasm_resize_buffer(this.ctx, this.pointer, capacity);
  }

  grow() {
    const { libmupdf } = this;
    libmupdf._wasm_grow_buffer(this.ctx, this.pointer);
  }

  trim() {
    const { libmupdf } = this;
    libmupdf._wasm_trim_buffer(this.ctx, this.pointer);
  }

  clear() {
    const { libmupdf } = this;
    libmupdf._wasm_clear_buffer(this.ctx, this.pointer);
  }

  toUint8Array(): Uint8Array {
    const { libmupdf } = this;
    const data = libmupdf._wasm_buffer_data(this.ctx, this.pointer);
    const size = libmupdf._wasm_buffer_size(this.ctx, this.pointer);
    return libmupdf.HEAPU8.slice(data, data + size);
  }

  toJsString(): string {
    const { libmupdf } = this;
    const data = libmupdf._wasm_buffer_data(this.ctx, this.pointer);
    const size = libmupdf._wasm_buffer_size(this.ctx, this.pointer);

    return libmupdf.UTF8ToString(data, size);
  }

  sameContentAs(otherBuffer) {
    const { libmupdf } = this;
    return libmupdf._wasm_buffers_eq(this.ctx, this.pointer, otherBuffer.pointer) !== 0;
  }
}
