import { FzBuffer } from "./FzBuffer";
import { FzDocument } from "./FzDocument";
import { NcodeFont } from "./NcodeFont";
import { PdfPage } from "./PdfPage";
import { finalizer } from "./finalizer";

// platform/java/src/com/artifex/mupdf/fitz/PdfDocument.java
export class PdfDocument extends FzDocument {

  ncodeFont: NcodeFont = null;

  constructor(libmupdf: any, ctx: number, pointer: number, buffer: FzBuffer = null) {
    super(libmupdf, ctx, pointer, buffer);
  }

  static createNewPdfDocument(libmupdf: any, ctx: number): PdfDocument {
    const pointer = libmupdf.ccall(
      "wasm_createNewPDFDocument",
      "number",
      ["number"],
      [ctx]
    );
    // TODO - Add reference to stream.
    const doc = new PdfDocument(libmupdf, ctx, pointer);

    return doc;
  }

  // static fromFzDocument(libmupdf: any, ctx: number, docPointer: number) {
  //   const pdfPointer = libmupdf._wasm_pdfDocumentFromFzDocument(ctx, docPointer);
  //   return new PdfDocument(libmupdf, ctx, pdfPointer);
  // }

  static fromFzDocument(libmupdf: any, ctx: number, fzDoc: FzDocument) {
    finalizer.unregister(fzDoc);
    const pdfPointer = libmupdf._wasm_pdfDocumentFromFzDocument(ctx, fzDoc.pointer);
    return new PdfDocument(libmupdf, ctx, pdfPointer);
  }

  addNcodeFont(
    glyph_offset: number /* double */,
    glyph_diameter: number /* double */,
    stroke_width: number /* double */,
    glyph_shape: number /* int */
  ) {
    const { libmupdf } = this;
    const size = 256;

    let charsetPointer = 0;
    let charset = "";
    let fontObjPointer = null as number;
    try {
      charsetPointer = libmupdf._wasm_malloc(this.ctx, size);

      fontObjPointer = libmupdf._wasm_neoAddNcodeFontToDocument(
        this.ctx, this.pointer,
        glyph_offset, glyph_diameter, stroke_width, glyph_shape,
        charsetPointer, size
      );

      charset = libmupdf.UTF8ToString(charsetPointer);
      this.ncodeFont = new NcodeFont(libmupdf, this.ctx, fontObjPointer, charset);
    }
    finally {
      if (charsetPointer)
        libmupdf._wasm_free(this.ctx, charsetPointer);
    }

    return {
      fontObjPointer,
      charset
    };
  }

  getNcodeFont() {
    return this.ncodeFont;
  }

  addBlankPage(width: number, height: number, rotate: number) {
    const { libmupdf } = this;
    libmupdf._wasm_appendBlankPage(this.ctx, this.pointer, width, height, rotate);
    this.pages.push(null);
    this.numPages = null;
  }

  insertBlankPageAt(pageIndex: number, width: number, height: number, rotate: number) {
    const { libmupdf } = this;
    libmupdf._wasm_insertBlankPageAt(this.ctx, this.pointer, pageIndex, width, height, rotate);
    // insert null at pageIndex on this.pages array
    this.pages.splice(pageIndex, 0, null);
    this.numPages = null;
  }

  removePageRange(start: number, end: number) {
    const { libmupdf } = this;
    libmupdf._wasm_removePageRange(this.ctx, this.pointer, start, end);
    // remove pages from this.pages array
    this.pages.splice(start, end - start + 1);
    this.numPages = null;
  }

  removePageAt(pageIndex: number) {
    const { libmupdf } = this;
    libmupdf._wasm_removePageAt(this.ctx, this.pointer, pageIndex);
    // remove page from this.pages array
    this.pages.splice(pageIndex, 1);
    this.numPages = null;
  }

  appendPDFPages(donor: FzDocument, start: number, numPages: number) {
    const { libmupdf } = this;
    libmupdf._wasm_neoAppendPdfPages(this.ctx, this.pointer, donor.pointer, start, numPages);
    // append nulls to this.pages array
    for (let i = 0; i < numPages; i++) {
      this.pages.push(null);
    }
    this.numPages = null;
  }

  save(compress: boolean) {
    const { libmupdf } = this;
    const bufPointer = libmupdf._wasm_neoPdfToFzBuffer(this.ctx, this.pointer, compress ? 1 : 0);
    const buf = new FzBuffer(libmupdf, this.ctx, bufPointer);
    const u8arr = buf.toUint8Array();
    return u8arr;
  }


  public loadPageOld(pageIndex: number): PdfPage {
    // check if page is already loaded
    if (this.pages[pageIndex]?.pointer) {
      return this.pages[pageIndex] as PdfPage;
    }
    const { libmupdf } = this;

    const page_ptr = libmupdf._wasm_load_page(this.ctx, this.pointer, pageIndex);
    const pdfPage_ptr = libmupdf._wasm_pdf_page_from_fz_page(this.ctx, page_ptr);

    let page: PdfPage = null;
    if (pdfPage_ptr !== 0) {
      page = new PdfPage(libmupdf, this.ctx, pdfPage_ptr, pageIndex, this);
    } else {
      return null;
    }

    // set page in pages array
    this.pages[pageIndex] = page;

    return page;
  }



  // isPDF !

  // hasUnsavedChanges
  // canBeSavedIncrementally

  // save !

  // JS Form Support
  // Undo history

  // canUndo
  // canRedo
  // undo
  // redo

  // beginOperation
  // endOperation

  // getLanguage !
  // setLanguage

  // addEmbeddedFile
  // getEmbeddedFileParams
  // loadEmbeddedFileContents
  // verifyEmbeddedFileChecksum

}
