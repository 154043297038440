import { assert } from "./functions";
import { FzDocument } from "./FzDocument";


export class FzLocation {
  chapter;

  page;

  constructor(ctx, chapter, page) {
    this.chapter = chapter;
    this.page = page;
  }

  pageIndex(libmupdf: any, ctx: number, doc: FzDocument) {
    assert(doc instanceof FzDocument, "invalid doc argument");

    return libmupdf._wasm_page_number_from_location(ctx, doc.pointer, this.chapter, this.page);
  }
}
