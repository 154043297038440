import { assert } from "./functions";
import { FzLocation } from "./FzLocation";
import { FzRect } from "./FzRect";
import { Wrapper } from "./Wrapper";
import { FzDocument } from "./FzDocument";


export class Link extends Wrapper {
  constructor(libmupdf: any, ctx: number, pointer: number) {
    // TODO
    super(libmupdf, ctx, pointer, () => { });
  }

  getBounds() {
    const { libmupdf } = this;
    return FzRect.fromFloatRectPtr(libmupdf, libmupdf._wasm_link_rect(this.ctx, this.pointer));
  }

  // setBounds

  isExternal() {
    const { libmupdf } = this;
    return libmupdf._wasm_is_external_link(this.ctx, this.pointer) !== 0;
  }

  getURI() {
    // the string returned by this function is borrowed and doesn't need to be freed
    const { libmupdf } = this;
    return libmupdf.UTF8ToString(libmupdf._wasm_link_uri(this.ctx, this.pointer));
  }

  // setURI

  resolve(doc) {
    assert(doc instanceof FzDocument, "invalid doc argument");
    const { libmupdf } = this;
    const uri_string_ptr = libmupdf._wasm_link_uri(this.ctx, this.pointer);
    return new FzLocation(
      this.ctx,
      libmupdf._wasm_resolve_link_chapter(this.ctx, doc.pointer, uri_string_ptr),
      libmupdf._wasm_resolve_link_page(this.ctx, doc.pointer, uri_string_ptr),
    );
  }
}
