import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const TopBarStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		// width: 'calc(100% - 211px)',
		textAlign: 'left',
	},
	'@media print': {
		root: {
			display: 'none',
		},
	},
	// @media print {
	//     body {
	//         font-family: Times, "Times New Roman", serif;
	//         background-color: #fff;
	//     }
	//
	//     nav, .no-print {
	//         display: none; /* 네비게이션과 원치 않는 요소 숨기기 */
	//     }
	// }
	// emptyBox:{
	//     width: '100%',
	//     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
	//     display:'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center',
	//     '& p':{
	//         fontSize: '1.375rem',
	//         color: '#87888d',
	//         fontWeight:400,
	//         textAlign: 'center',
	//         lineHeight: '30.36px'
	//     }
	// },
	// boxWrap: {
	//     backgroundColor: '#fff',
	//     padding: 20,
	//     borderRadius: 8,
	//     height: 'calc(100vh - 500px)'
	// },

	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			letterSpacing: '-0.5px',
			marginLeft: "7px",
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},


	dialogBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff'
		}
	},


	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 56,
			// width: 'calc(100vw - 220px)',
			// marginLeft: "210px",
			boxSizing: 'border-box'
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},





	/////////////////////
	padding: {
		padding: 15,
	},
	mainContentBox: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 145px)',
		overflowY: 'auto',
		background: '#fff',
		margin: '0px 15px',
	},
	mainContentBoxLeft: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 130px)',
		overflowY: 'auto',
		background: '#fff',
		width: 'calc(100vw - 770px)',
	},
	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		overflowY: 'auto',
		background: '#fff',
		width: '620px',
		boxSizing: 'border-box',
		marginLeft: "10px",
	},


	commonSubTitle: {
		marginBottom: "15px",
		textAlign: 'left',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
		textAlign: 'left'
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "10px",
		}
	},
	schoolNameSubText: {
		textAlign: 'left',
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			letterSpacing: '-0.5px',
			marginBottom: "10px",
		}
	},
	searchSchoolInput: {
		'& .MuiFormControl-root': {
			maxWidth: 830,
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "50px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},




	greenBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#cdcdcd',
			'&:hover': {
				background: '#cdcdcd',
			},
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold'
			}
		}
	},
	greenBtnActive: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},

	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #2F80ED',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	wideLineBtn: {
		'&.MuiButtonBase-root': {
			width: "168px",
		}
	},
	selectText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 'bold',
			marginRight: "15px",
		}
	},

	subSideMenu: {
		'& .MuiStepIcon-root.MuiStepIcon-active': {
			color: '#025949'
		},
		'& .MuiStepIcon-root.MuiStepIcon-completed': {
			color: '#025949'
		},
		'& .MuiStepIcon-root': {
			color: '#DDDDDD'
		},
		'& .MuiStepConnector-vertical': {
			padding: '0px 0 2px',
			marginLeft: "6px",
		},
		'& .MuiStepLabel-root': {
			'& .MuiStepLabel-vertical': {

			}
		},
		'& .MuiStepper-vertical': {
			padding: '24px 0px 24px 24px'
		},
		'& .Mui-disabled': {
			'& rect': {
				fill: '#ddd'
			}
		}
	},
	nameSearchBtn: {
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#787878',
				marginLeft: "5px",
			}
		}
	},

	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1.125rem',
			boxSizing: 'border-box',
			color: '#777',
			letterSpacing: '-0.5px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #87888d',
			borderRadius: '6px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},



	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: '#fff',
			'&:hover': {
				background: '#fff'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
			marginTop: "3px",
		}
	},
	pageNumber: {
		marginTop: "20px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentName: {
		'&.MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
		}
	},
	tableBox: {
		marginTop: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "44px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				color: '#323232',
				height: "44px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},
	sortButton: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		'& p': {
			fontSize: '0.875rem',
			color: '#555',
			marginRight: "4px",
			marginLeft: "10px",
		}
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},
	selectedBox: {

	},
	breadcrumbsList: {
		marginLeft: "40px",
		'& .MuiBreadcrumbs-li:first-child': {
			'& svg': {
				marginTop: "3px",
			}
		},
		'& .MuiBreadcrumbs-li:last-child': {
			'& .MuiTypography-root': {
				color: '#008C73',
				fontWeight: 'bold'
			}
		}
	},
	leftItemList: {
		marginLeft: "40px",
		'& .MuiBreadcrumbs-li:first-child': {
			'& svg': {
				marginTop: "3px",
			}
		},
		'& .MuiBreadcrumbs-li:last-child': {
			'& .MuiTypography-root': {
				color: '#008C73',
				fontWeight: 'bold'
			}
		}
	},
	countPosition: {
		marginRight: "10px",
		position: 'absolute',
		right: 'calc(100% - 825px)',
		top: 12,
	},
	countNum: {
		'&.MuiTypography-root': {
			color: '#787878',
			fontSize: '0.875rem',
			marginRight: "10px",
		}
	},
	ProfileBox: {
		'& .MuiPopover-paper': {
			padding: '16px 20px',
			borderRadius: 15,
			border: '1px solid #ccc'
		}
	},
	ProfileBoxInner: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	ProfileImg: {
		borderRadius: '100%',
		border: '1px solid #ccc',
		width: "64px",
		height: "64px",
		overflow: 'hidden'
	},
	ProfileJob: {
		marginTop: "14px",
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#3552F2',
			fontWeight: 600,
		}
	},
	ProfileTeacher: {
		marginTop: "14px",
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#03BF69',
			fontWeight: 600,
		}
	},
	ProfileNmae: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#000',
			fontWeight: 600,
		}
	},
	ProfileEmail: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#9F9F9F',
			fontWeight: 400,
		}
	},
	ProfileSchool: {
		marginTop: "16px",
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 500,
		}
	},
	ProfileLogout: {
		marginTop: "20px",
		'&.MuiButtonBase-root': {
			width: "178px",
			background: '#555',
			'& hover': {
				background: '#555',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 500,
				fontSize: '0.875rem'
			}
		}
	},






	// kitty
	LanguageBox: {
		'& .MuiPopover-paper': {
			// borderRadius: 15,
			borderRadius: (theme as Theme).shape.borderRadius,
			boxShadow: (theme as Theme).shadows[3],
			minWidth: 150,  // 최소 너비를 150px로 줄임
      maxWidth: 200,  // 최대 너비를 200px로 제한
		},
	},
	LanguageBoxInner: {
		padding: (theme as Theme).spacing(2),
	},
	languageTitle: {
		fontWeight: 600,
		fontSize: '1.1rem',
		marginBottom: (theme as Theme).spacing(2),
		borderBottom: `1px solid ${theme.palette.divider}`,
		paddingBottom: theme.spacing(1),
	},
	LanguageButton: {
		width: '100%',
		justifyContent: 'flex-start',
		padding: (theme as Theme).spacing(1, 2),
		margin: (theme as Theme).spacing(0.5, 0),
		borderRadius: theme.shape.borderRadius,
		transition: 'background-color 0.3s',
		textTransform: 'none',  // 추가: 대문자 변환 비활성화

		'&:hover': {
			backgroundColor: (theme as Theme).palette.action.hover,
		},
		'& .MuiTypography-root': {
			fontWeight: 500,
		},
	},
	SelectedLanguage: {
		color: (theme as Theme).palette.primary.main,
		backgroundColor: (theme as Theme).palette.action.selected,
		'&:hover': {
			backgroundColor: (theme as Theme).palette.action.selected,
		},
		'& .MuiTypography-root': {
			fontWeight: 600,
		},
	},

});












