import { sprintf } from "sprintf-js";

export function compareObject(curr: Record<string, unknown>, next: Record<string, unknown>, header = "") {
	const arr = Object.entries(next);
	for (let i = 0, ln = arr.length; i < ln; i++) {
		const [key, value] = arr[i];
		if (curr[key] !== value) {
			console.log(`[${header}] state[${key}] was changed, from "${curr[key]} to "${value}"`);
		}
	}
}

export function makePdfId(argsOrPaperGroupId: { paperGroupId: string, pagesPerSheet: number } | string, pagesPerSheet = 1) {
	if (typeof argsOrPaperGroupId === "object") {
		const { paperGroupId, pagesPerSheet: ps } = argsOrPaperGroupId;
		return paperGroupId + "/" + ps.toString();
	}

	return (argsOrPaperGroupId as string) + "/" + pagesPerSheet.toString();
}

export function cloneObj(obj: any) {
	return deepClone(obj);
}

export function isSameObject(a: Record<string, any>, b: Record<string, any>) {
	if (a === b) return true;
	if (a === undefined && b) return false;
	if (a && b === undefined) return false;

	if (Object.keys(a).length !== Object.keys(b).length) return false;

	const keysA = Object.keys(a);
	for (let i = 0, ln = keysA.length; i < ln; i++) {
		const key = keysA[i];
		if (typeof a[key] === "object") {
			if (!isSameObject(a[key], b[key])) return false;
		} else if (a[key] !== b[key]) {
			// console.log(`[printOption diff] key=${key} values="${a[key]}", "${b[key]}`);
			return false;
		}
	}
	return true;
}

export function deepClone<T = any>(obj: any | Record<string, any>): T {
	if (obj === undefined || obj === null || typeof obj !== 'object') {
		return obj;
	}

	const result = Array.isArray(obj) ? [] : {};
	const keys = Object.keys(obj);
	for (let i = 0, ln = keys.length; i < ln; i++) {
		const key = keys[i];
		(result as Record<string, any>)[key] = deepClone(obj[key]);
	}

	return result as T;
}

export function getNowTimeStr(): string {
	const now = new Date();
	const timeStr = sprintf(
		"%04d-%02d-%02d %02d:%02d:%02d.%03d",
		now.getFullYear(),
		now.getMonth() + 1,
		now.getDate(),
		now.getHours(),
		now.getMinutes(),
		now.getSeconds(),
		now.getMilliseconds()
	);

	return timeStr;
}


export function unixToDateStr(t: number, miliseconds = false): string {
	const date = new Date(t);
	const hours = date.getHours();
	let minutes = "0" + date.getMinutes();
	let seconds = "0" + date.getSeconds();
	let day = "0" + date.getDate();
	const dayWeek = date.getDay();
	let month = "0" + (date.getMonth() + 1);
	const year = date.getFullYear();
	minutes = minutes.substr(-2);
	seconds = seconds.substr(-2);
	day = day.substr(-2);
	month = month.substr(-2);

	if (miliseconds) {
		// let mili = "00" + (t % 1000);
		// mili = mili.substr(-3);
		return year + "/" + month + "/" + day + " " + hours + ":" + minutes + ":" + seconds + "." + miliseconds;
	}

	return `${year}/${month}/${day}(${"일월화수목금토".substr(dayWeek, 1)}) ${hours}:${minutes}:${seconds}`;
}



export function addZeros(num: number | string, digit: number) {
	// 자릿수 맞춰주기
	let zero = "";
	num = num.toString();
	if (num.length < digit) {
		for (let i = 0; i < digit - num.length; i++) {
			zero += "0";
		}
	}
	return zero + num;
}

/**
 * shouldComponentUpdated 등에서 쓰이는 상태 비교 디버거 메시지
 * @param prefix - prefix string of debug message
 * @param prevProps
 * @param nextProps
 * @param prevState
 * @param nextState
 */
export function dumpDiffPropsAndState(
	prefix: string,
	prevProps: Record<string, any>,
	nextProps: Record<string, any>,
	prevState?: Record<string, any>,
	nextState?: Record<string, any>
) {
	let isChanged = false;

	const et1 = Object.entries(nextProps);
	for (let i = 0, ln = et1.length; i < ln; i++) {
		const [key, value] = et1[i];
		// if (typeof value === "function" || typeof value === "object") continue;
		if (prevProps[key] !== value) {
			isChanged = true;
		}
	}

	if (prevState && nextState) {
		const et2 = Object.entries(nextState);
		for (let i = 0, ln = et2.length; i < ln; i++) {
			const [key, value] = et2[i];
			// if (typeof value === "function" || typeof value === "object") continue;
			if (prevState[key] !== value) {
				isChanged = true;
			}
		}
	}
	console.log("");
	if (!isChanged) {
		console.log(`[${prefix}] =============== Properties & State NOT CHANGED`);
		return
	}

	console.log(`[${prefix}] =============== Properties & State ===========================================================================================`);
	for (let i = 0, ln = et1.length; i < ln; i++) {
		const [key, value] = et1[i];
		// if (typeof value === "function" || typeof value === "object") continue;
		if (prevProps[key] !== value) {
			console.log(`[${prefix}] PROPERTY [${key}] was changed, "${prevProps[key]}" ==> "${value}"`);
		}
	}

	// console.log(`[${prefix}] State .......................................................................................................................`);
	if (prevState && nextState) {
		const et2 = Object.entries(nextState);
		for (let i = 0, ln = et2.length; i < ln; i++) {
			const [key, value] = et2[i];
			// if (typeof value === "function" || typeof value === "object") continue;
			if (prevState[key] !== value) {
				console.log(`[${prefix}] STATE    [${key}] was changed, "${prevState[key]}" ==> "${value}"`);
			}
		}
	}

	// console.log(`[${prefix}]==============================================================================================================================`);
}


export function getExtensionName(str: string) {
	const re = /(?:\.([^.]+))?$/;

	const ext = re.exec(str)?.[1] || "";   // "txt"
	return ext;
}

export function getFilenameOnly(str: string) {
	const ext = getExtensionName(str);
	let name = (str.split('/').pop()?.replace("." + ext, '')) || "";
	name = name.split('\\').pop()?.replace("." + ext, '') || "";
	return name;
}


export function rgb2Hex(rgb: string) {
	const splitted = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
	return (splitted && splitted.length === 4) ? "#"
		+ ("0" + parseInt(splitted[1], 10).toString(16)).slice(-2)
		+ ("0" + parseInt(splitted[2], 10).toString(16)).slice(-2)
		+ ("0" + parseInt(splitted[3], 10).toString(16)).slice(-2) : '';
}

export function hex2ColorObject(hex: string) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}


export function callstackDepth() {
	const callstack = new Error().stack?.toString();
	const lines = callstack?.split("\n");
	if (!lines) return "";

	const re = /at\s(.+)\.(.+)\s\((.+)\)/

	// let cnt = 0;
	let spc = "";
	let caller = sprintf("%20s", "");
	for (let i = 2, br = true; br; i++) {
		// cnt++;
		spc += "  ";
		if (!lines[i]) {
			br = false;
		} else {
			const arg = lines[i].split(re);
			// console.log(`VIEW SIZE   ${ arg.length } ${ lines[i] } `);

			if (arg.length !== 5) {
				br = false;
				const root = arg[0].split(/at\s(.+)\s\(/);
				caller = sprintf("%30s", root[1]);
				// console.log(arg);
			}
		}
	}

	return " " + caller + spc;
}

export function isSameArray(p1: any[], p2: any[]): boolean {
	if (!p1 && !p2) return true;
	if (!p1 && p2) return false;
	if (p1 && !p2) return false;

	if (p1.length !== p2.length) return false;


	// for (let i = 0; i < p1.length; i++)
	//   if (!p2.includes(p1[i])) return false;

	// for (let i = 0; i < p2.length; i++)
	//   if (!p1.includes(p2[i])) return false;

	// return true;


	const intersection = p1.filter((p1_item) => p2.includes(p1_item));
	if (intersection.length !== p1.length) return false;
	return true;
}




/**
 * string.right()
 *
 * @export
 * @param {string} str
 * @param {number} n
 * @return {*}  {string}
 */
export function strRight(str: string, n: number): string {
	if (n <= 0) return "";
	if (n > String(str).length) return str;

	const iLen = String(str).length;
	return String(str).substring(iLen, iLen - n);
}


/**
 * Return a string only with valid characters
 *
 * @export
 * @param {string} target
 * @param {string} validChars - valid character set
 * @param {boolean} [makeUpper]
 * @param {number} [maxLen]
 * @return {*}  {string}
 */
export function removeInvalidChar(target: string, validChars: string, makeUpper?: boolean, maxLen?: number): string {
	const val = makeUpper ? target.toUpperCase() : target;
	let ret = "";
	for (let i = 0, l = val.length; i < l; i++) {
		const hexIndex = validChars.indexOf(val.charAt(i));
		if (hexIndex >= 0) {
			if (maxLen && ret.length < maxLen) ret += val.charAt(i);
		}
	}
	return ret;
}



export function getCssValue(name: string) {
	return getComputedStyle(document.documentElement).getPropertyValue(`${name} `);
}


export function getTimeStringFormatted(ms: number, format?: string) {
	let sec = Math.floor(ms / 1000);
	// let mili = miliseconds - sec * 1000;
	// let deci = Math.round(mili / 100);

	let mm = Math.floor(sec / 60);
	let hh = Math.floor(mm / 60);
	const dd = Math.floor(hh / 24);

	hh -= dd * 24;
	mm -= hh * 60;
	sec = sec - hh * 3600 - mm * 60;

	let mm_str = "0" + mm;
	let ss_str = "0" + sec;
	let hh_str = "0" + hh;
	const dd_str = "" + dd;

	ss_str = ss_str.substr(-2);
	mm_str = mm_str.substr(-2);
	hh_str = hh_str.substr(-2);

	if (!format) return `${hh_str}: ${mm_str}: ${ss_str} `;

	let ret_val = format;
	if (dd > 0) {
		ret_val = ret_val.replace("d", `${dd_str} `);
	} else {
		ret_val = ret_val.replace("d", "");
	}
	ret_val = ret_val.replace("hh", `${hh_str} `);
	ret_val = ret_val.replace("mm", `${mm_str} `);
	ret_val = ret_val.replace("ss", `${ss_str} `);

	return ret_val;
};

export function getTimeString(ms: number) {
	const a = new Date(ms);
	const time_str_locale = a.toLocaleTimeString("en-GB");
	return time_str_locale;
};



export function getDateString(ms: number) {
	const a = new Date(ms);


	// const lang = navigator.languages;
	// const time_str_24 = a.toLocaleTimeString('en-GB');
	// const time_str_locale = a.toLocaleTimeString(lang ? lang[0] : "en-GB");

	const year = a.getFullYear();
	const month = a.getMonth() + 1;
	const wday = a.getDay();
	const day = a.getDate();

	// const hour = a.getHours();
	// const min = a.getMinutes();
	// const sec = a.getSeconds();

	const weekdays = ["일", "월", "화", "수", "목", "금", "토"];
	const ret = sprintf("%04d-%02d-%02d(%s)", year, month, day, weekdays[wday]);
	return ret;
};

export function isSameDay(ms1: number, ms2: number) {
	return getDateString(ms1) === getDateString(ms2);
}

export function getDateTimeString(ms: number) {
	return getDateString(ms) + " " + getTimeString(ms);
}

export function getYMDhmsString(d: Date) {
	const dateTimeStr = sprintf(
		"%04d%02d%02d_%02d%02d",
		d.getFullYear(),
		d.getMonth(),
		d.getDate(),
		d.getHours(),
		d.getMinutes(),
		d.getSeconds()
	);

	return dateTimeStr;
}


export function dataURItoBlob(dataURI: string) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
	else byteString = unescape(dataURI.split(',')[1]);

	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to a typed array
	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
}

export function throttle(fn: any, delay: number) {
	let timer: any = null;
	return function (this: unknown) {
		const context = this;
		const args = arguments;
		if (!timer) {
			timer = setTimeout(function () {
				fn.apply(context, args);
				timer = null;
			}, delay);
		}
	};
}

export function debounce(fn: any, delay: number) {
	let timer: any = null;
	return function (this: unknown) {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(() => {
			fn.apply(context, args);
		}, delay);
	};
}

