import { observer } from "mobx-react";
import React from "react";

import clsx from "clsx";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { LandingStyle } from "../../styles/LandingStyle";

import { ReactComponent as CheckLogin } from "../../../../common/images/CheckLogin.svg";
import { ReactComponent as CheckLoginOff } from "../../../../common/images/CheckLoginOff.svg";
import { ReactComponent as VisibilityIcon } from '../../../../common/images/VisibilityIcon.svg';
import { ReactComponent as VisibilityOffIcon } from '../../../../common/images/VisibilityOffIcon.svg';
import { ReactComponent as WarningTableIcon } from "../../../../common/images/WarningTableIcon.svg";


import LandingComponent from "./LandingComponent";

import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
	Typography
} from "@mui/material";
import useNaviCustom from "../../../../hooks/useNaviCustom";

import { useStores } from "../../../../hooks/useStores";
import { AuthState } from "../../../../stores/support/AuthState";

type LangindProps = {
}

function Landing(props: LangindProps) {
	const { authStore, userStore } = useStores();

	const classes = useEmotionStyles( LandingStyle );
	const [showPassword, setShowPassword] = React.useState(false);
	const [showPasswordChange, setShowPasswordChange] = React.useState(false);
	const [showPasswordChangeConform, setShowPasswordChangeConform] = React.useState(false);
	const [passwordChange, setPasswordChange] = React.useState('');
	const [passwordChangeConform, setPasswordChangeConform] = React.useState('');

	const { login, loginState, passwordUpdateFail, loginFail } = authStore;

	const naviCustom = useNaviCustom();


	React.useEffect(() => {
		return () => {
			authStore.initLoginFails(); // 혹시 몰라서 -> 자체적으로 init 되긴 함
		}
	}, [])

	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleClickShowPasswordChange = () => setShowPasswordChange(!showPasswordChange);
	const handleClickShowPasswordChangeConform = () => setShowPasswordChangeConform(!showPasswordChangeConform);
	const handleChangePasswordChange = (e) => setPasswordChange(e.target.value);
	const handleChangePasswordChangeConform = (e) => setPasswordChangeConform(e.target.value);
	const handleMouseDownPassword = (event) => event.preventDefault();
	const handleChangeEmail = (event) => authStore.changeLoginEmail(event.target.value);
	const handleChangePassword = (event) => authStore.changeLoginPassword(event.target.value);
	const handleKeypress = (event) => { if (event.key === 'Enter') { handleClickLogin(); } }
	const handleClickPasswordUpdate = () => authStore.updatePassword(passwordChange);
	const handleClickMoveLogin = () => {
		authStore.initLogin();
		authStore.setLoginState(AuthState.NotAuthenticated);
	}
	const handleClickLogin = () => {
		authStore.doLogin(loginCallback);
		naviCustom.go(`/projects`);
		// props.navigate(`/projects`);
	}

	const checkPasswordLength = () => { return !!(passwordChange.length >= 8); }
	const checkPasswordMatch = () => { return passwordChange === passwordChangeConform; }
	const checkPasswordCombination = () => {
		const regexPatterns = {
			letters: /[A-Za-z]/, // 영문자
			numbers: /\d/, // 숫자
			specialChars: /[\W_]/ // 특수문자 (공백 제외)
		};

		let matchCount = 0;
		Object.values(regexPatterns).forEach(pattern => {
			if (pattern.test(passwordChange)) {
				matchCount += 1;
			}
		});
		return matchCount >= 2;
	}

	const loginCallback = () => {
		if (loginState === AuthState.Authenticated) {
			userStore.getUserProfile(authStore.loginUser.code);
		}
	};

	const changePwComponent = () => {
		return (
			<Box className={classes.loginBox}>
				<Box className={clsx(classes.loginBoxTitle, classes.loginBoxTopPassword)}>
					<Typography>비밀번호 재설정</Typography>
					<Typography className={classes.loginBoxSubText}>새로운 비밀번호를 등록해 주세요.</Typography>
				</Box>
				<Box sx={{ marginTop: "40px" }}>
					<Box className={classes.loginInput} sx={{ position: 'relative' }}>
						<FormControl variant="outlined">
							<OutlinedInput
								id="outlined-adornment-password"
								type={showPasswordChange ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPasswordChange}
											onMouseDown={handleMouseDownPassword}
											edge="end"
											className={classes.iconBtnReset}
											disableRipple
										>
											{showPasswordChange ? <VisibilityOffIcon /> : <VisibilityIcon />}
										</IconButton>
									</InputAdornment>
								}
								placeholder="비밀번호"
								value={passwordChange}
								onChange={handleChangePasswordChange}
							/>
						</FormControl>
					</Box>
					<Box className={classes.passwordCheckBoxInner}>
						<FormControlLabel
							control={
								<Checkbox
									icon={<CheckLoginOff />}
									checked={checkPasswordCombination()}
									checkedIcon={<CheckLogin className={classes.checkedColor} />}
									disableRipple
									disabled
								/>
							}
							label="영문, 숫자, 특수문자 중 2가지 이상 조합"
							className={classes.rememberId}
						/>
						<FormControlLabel
							control={
								<Checkbox
									icon={<CheckLoginOff />}
									checked={checkPasswordLength()}
									checkedIcon={<CheckLogin className={classes.checkedColor} />}
									disableRipple
									disabled
								/>
							}
							label="8자 이상"
							className={classes.rememberId}
						/>
					</Box>
					<Box className={classes.loginInput} sx={{ marginBottom: "14px" }}>
						<FormControl variant="outlined">
							<OutlinedInput
								id="outlined-adornment-password"
								type={showPasswordChangeConform ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPasswordChangeConform}
											onMouseDown={handleMouseDownPassword}
											edge="end"
											className={classes.iconBtnReset}
											disableRipple
										>
											{showPasswordChangeConform ? <VisibilityOffIcon /> : <VisibilityIcon />}
										</IconButton>
									</InputAdornment>
								}
								placeholder="비밀번호 확인"
								value={passwordChangeConform}
								onChange={handleChangePasswordChangeConform}
							/>
						</FormControl>
					</Box>
					<Box className={classes.loginNoticeText} style={!checkPasswordMatch() && (passwordChange.length <= passwordChangeConform.length) ? {} : { display: 'none' }}>
						<WarningTableIcon />
						<Typography style={{ color: '#d92020' }}>비밀번호와 일치하지 않습니다.</Typography>
					</Box>
				</Box>
				<Box className={clsx(classes.loginPageBtn, classes.loginPageBtnActive)}>
					<Button className={classes.passwordUpdateBtn}
						disableRipple
						onClick={handleClickPasswordUpdate}
						disabled={!checkPasswordCombination() || !checkPasswordLength() || !checkPasswordMatch()}
					>
						<Typography>비밀번호 재설정</Typography>
					</Button>
				</Box>
				<Box className={classes.loginNoticeText} style={passwordUpdateFail ? {} : { display: 'none' }}>
					<WarningTableIcon />
					<Typography style={{ color: '#d92020' }}>재설정에 실패했습니다. 다시 시도해주세요.</Typography>
				</Box>
			</Box>
		)
	}

	const updatedPwComponent = () => {
		return (
			<Box className={classes.loginBox}>
				<Box className={clsx(classes.loginBoxTitle, classes.loginBoxTopPassword)}>
					<Typography>비밀번호 재설정 완료</Typography>
					<Typography className={classes.loginBoxSubText}>비밀번호 재설정이 완료되었습니다.</Typography>
				</Box>
				<Box className={clsx(classes.loginPageBtn, classes.loginPageBtnActive)}>
					<Button disableRipple
						onClick={handleClickMoveLogin}>
						<Typography>로그인</Typography>
					</Button>
				</Box>
			</Box>
		)
	}

	const loginComponent = () => {
		return (
			<Box className={classes.loginBox}>
				<Box className={classes.loginBoxTitle}>
					<Typography>로그인</Typography>
				</Box>
				<Box>
					<Box className={classes.loginInput} sx={{ marginBottom: "24px", position: 'relative' }}>
						<TextField
							id="outlined-basic-login-email"
							variant="outlined"
							placeholder="이메일"
							value={login.email}
							onChange={handleChangeEmail}
						// error
						/>
						{/*<Box className={classes.loginInputNotice}>*/}
						{/*    /!*<LoginAlertIcon />*!/*/}
						{/*    <Typography>이메일 형식이 잘못되었습니다.</Typography>*/}
						{/*</Box>*/}
					</Box>

					<Box className={classes.loginInput} sx={{ marginBottom: "14px" }}>
						<FormControl variant="outlined">
							<OutlinedInput id="outlined-adornment-password"
								placeholder="비밀번호"
								value={login.password}
								onChange={handleChangePassword}
								onKeyPress={handleKeypress}
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
											className={classes.iconBtnReset}
											disableRipple>
											{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
										</IconButton>
									</InputAdornment>
								} />
						</FormControl>
					</Box>
					{/*<Box className={classes.checkBoxInner}>*/}
					{/*    <FormControlLabel control={<Checkbox className={classes.iconBtnReset} disableRipple/>}*/}
					{/*                      label="아이디 기억하기"*/}
					{/*                      className={classes.rememberId}/>*/}
					{/*</Box>*/}
				</Box>

				<Box className={login.email?.length > 0 && login.password?.length > 0 ? clsx(classes.loginPageBtn, classes.loginPageBtnActive) : classes.loginPageBtn}>

					<Button onClick={handleClickLogin}
						disabled={!(login.email?.length > 0 && login.password?.length > 0)}
						disableRipple>
						<Typography>로그인</Typography>
					</Button>
				</Box>
				<Box className={classes.loginNoticeText} style={loginFail ? {} : { display: 'none' }}>
					<WarningTableIcon />
					<Typography style={{ color: '#d92020' }}>로그인에 실패했습니다. 다시 시도해주세요.</Typography>
				</Box>

			</Box>
		);
	}


	return (
		<div className={classes.root}>
			{/*<TopBar leftItem={" "} rightItem={" "}/>*/}
			<Box className={classes.body}>
				{/*<Box className={classes.center}>*/}
				<Box className={classes.main}>
					{/*<Box>*/}
					{/*    <LogoWithTile className={classes.logo}/>*/}

					{/*</Box>*/}
					{/*/!*<Typography className={classes.title}> AiGle </Typography>*!/*/}
					{/*<Box className={classes.borderLine}>*/}
					{/*    <LoginBorderLine/>*/}
					{/*</Box>*/}
					{/*<div>미래의 교육을 위한 AI, 아이글이 함께합니다.</div>*/}
					{/*<div>선생님의 수고를 덜어주는 AI 기반 서술형 과제 평가 도구</div>*/}


					{/*{loginState === AuthState.NeedPasswordChange && changePwComponent()}*/}
					{/*{loginState === AuthState.PassWordUpdated && updatedPwComponent()}*/}
					{/*{loginState !== AuthState.PassWordUpdated && loginState !== AuthState.NeedPasswordChange && loginComponent()}*/}

					{/*<LoginSideImg className={classes.sideImg}/>*/}

					{/*  pb 240425  */}
					<LandingComponent />

				</Box>
				{/*</Box>*/}

			</Box>
		</div>
	);
}

export default observer<typeof Landing>(Landing);
