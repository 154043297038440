export class SimpleLock {
    private queue: (() => void)[] = [];
    private isLocked = false;

    async acquire(): Promise<void> {
        return new Promise((resolve) => {
            if (!this.isLocked) {
                // Lock이 사용 가능하면 바로 획득
                this.isLocked = true;
                resolve();
            } else {
                // Lock이 이미 사용 중이면 queue에 추가
                this.queue.push(resolve);
            }
        });
    }

    release(): void {
        if (this.queue.length > 0) {
            // 대기 중인 작업이 있으면 하나를 처리
            const next = this.queue.shift();
            if (next) next();
        } else {
            // 더 이상 대기 중인 작업이 없으면 lock 해제
            this.isLocked = false;
        }
    }
}
