import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Paper,
	TableContainer,
	Theme,
	Typography
} from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import clsx from "clsx";
import { observer } from "mobx-react";
import { ReactComponent as PageUpIcon } from '../../../../../common/images/PageUpIcon.svg';
import { convertUTCToKST } from "../../../../../hooks/convertUTCToKST";
import useNaviCustom from "../../../../../hooks/useNaviCustom";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { StudentResultStyle } from "../../../styles/StudentResultStyle";

import React from "react";
import { useTranslation } from 'react-i18next';
import { useStores } from "../../../../../hooks/useStores";
import { ProjectStateType } from "../../../../../repositories/model/support/ProjectStateType";
import { ResultHeaderTable } from "../../../000_Common/ResultHeader";
import { QuestionRow } from "./QuestionRow";
import { StandardAnswerRow } from "./StandardAnswerRow";

type Props = {
	projectCode?: string;
	submissionCode?: string;
	handleClickClose?: () => void;
};

function StudentResult(props: Props) {
	const classes = useEmotionStyles( StudentResultStyle );
	const { projectSubmissionStore, projectSubmissionAnswerStore, projectStore, messageStore } = useStores();

	const { handleClickClose } = props;
	let { projectCode, submissionCode } = props;
	const { project } = projectStore;
	if (!projectCode) {
		projectCode = project.code;
	}
	if (!submissionCode) {
		const submissionCode2 = projectSubmissionStore.projectSubmissionCode;
		submissionCode = projectSubmissionAnswerStore.submissionInfo.submissionCode;
		if ( submissionCode2 !== submissionCode) {
			throw new Error("submissionCode is not matched");
		}
	}

	const { submissionInfo, questionList, examOrderAndQuestionsLengthMap } = projectSubmissionAnswerStore;

	const i18next = useTranslation();

	const naviCustom = useNaviCustom();


	const disabledToMarkAgain = React.useMemo(() => {
		if (!submissionInfo?.state) return;

		return submissionInfo.state === ProjectStateType.EXPORTED;
	}, [submissionInfo?.state]);

	const disabledToSubmit = React.useMemo(() => {
		if (!submissionInfo?.state) return;

		return submissionInfo.state === ProjectStateType.EXPORTED;
	}, [submissionInfo?.state]);

	const currentCreatedDatetime = React.useMemo(() => {
		if (submissionInfo?.project?.createdDatetime) {
			return convertUTCToKST(submissionInfo.project.createdDatetime).format("YYYY년 M월 D일");
		}

		return '-';
	}, [submissionInfo]);

	const currentGradeClassName = React.useMemo(() => {
		if (!submissionInfo || !submissionInfo.grade || !submissionInfo.className) return '-';

		if (!submissionInfo.grade || !submissionInfo.className) return '-';

		return i18next.t("{{grade}}학년 {{className}}반", { grade: submissionInfo.grade, className: submissionInfo.className });
	}, [submissionInfo, submissionInfo?.grade, submissionInfo?.className]);

	React.useEffect(() => {
		const callBack = () => handleClickClose?.();

		if (!projectCode || !submissionCode) {
			callBack();
			return;
		}

		projectSubmissionAnswerStore.getSubmissionInfoBySubmissionCode(submissionCode, callBack);
	}, [projectCode, submissionCode]);

	// const topBarLeftItem = () => {
	// 	return (
	// 		<p>&nbsp;&nbsp;&nbsp;<strong>'{submissionInfo?.projectName}'</strong> {i18next.t("서·논술형 평가 결과")}</p>
	// 	)
	// };

	// const topBarRightItem = () => {
	// 	return (
	// 		<Box display='flex'>
	// 			<Box className={classes.printBtn} sx={{ marginRight: "10px" }}>
	// 				<Button disableRipple onClick={handleClickPrint}>
	// 					<Typography>{i18next.t("프린트 하기")}</Typography>
	// 				</Button>
	// 			</Box>
	// 			<Box className={disabledToMarkAgain ? classes.saveLineMarkBtn : clsx(classes.saveLineMarkBtn, classes.saveLineMarkBtnActive)} sx={{ marginRight: "10px" }}>
	// 				<Button disableRipple disabled={disabledToMarkAgain} onClick={handleClickMarkAgain}>
	// 					<Typography>{i18next.t("다시 채점하기")}</Typography>
	// 				</Button>
	// 			</Box>
	// 			<Box className={disabledToSubmit ? classes.applyMarkBtn : clsx(classes.applyMarkBtn, classes.applyMarkBtnActive)}>
	// 				<Button disableRipple disabled={disabledToSubmit} onClick={handleClickExportResult}>
	// 					<Typography> {disabledToSubmit ? i18next.t("결과 내보내기 완료") : i18next.t("결과 내보내기")}</Typography>
	// 				</Button>
	// 			</Box>
	// 		</Box>
	// 	)
	// };

	const handleClickMarkAgain = async () => {
		const result = await messageStore.confirm(i18next.t("다시 채점하시겠습니까?"));
		if (!result) return;
		if (!projectCode || !submissionCode) return;

		naviCustom.go(`/assignments/${projectCode}/feedbacks?submissionCode=${submissionCode}`);
	};
	const handleClickPrint = async () => {
		window.print();
	};

	const updateProjectUserState = async (projectCode, userCode, state) => {
		return await projectStore.updateProjectUserState(projectCode, userCode, state)
	}

	const handleClickExportResult = async () => {
		let result = await messageStore.confirm(i18next.t("{{userName}} 학생에게 {{projectName}} 과제 채점 결과를 발송합니다.", { userName: submissionInfo?.userName, projectName: submissionInfo?.projectName }));
		if (!result) return;

		updateProjectUserState(projectCode, submissionInfo?.userCode, ProjectStateType.EXPORTED)
			.then(result => {
				if (!result) {
					messageStore.alert(i18next.t("결과 발송을 실패했습니다."));
					return;
				}

				messageStore.alert(i18next.t("결과 발송을 완료했습니다."))
					.then(_ => {
						handleClickClose && handleClickClose();
					});
			});
	};

	const standardTotalScore = React.useMemo(() => {
		let totalScore = 0;
		questionList.map(question => {
			totalScore += Number(question.score);
		})

		return totalScore;
	}, [questionList]);

	const markedTotalScore = React.useMemo(() => {
		let totalScore = 0;
		questionList.map(question => {
			if (!question.answer) return;

			totalScore += Number(question.answer.totalScore);
		});

		return totalScore;
	}, [questionList]);

	const getExamInfoByExamCode = (examCode) => {
		const examInfo = submissionInfo?.exams.find(exam => exam.code === examCode);
		return examInfo;
	};

	const handleClickPageUp = () => {
		const rootTag = document.getElementById(`detail-page-root`);
		if (rootTag) {
			rootTag.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div className={classes.root} id={'detail-page-root'}>
			{!projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode &&
				<Box className={classes.padding}>

					<Toolbar className={classes.topBar}>
						<Box>
							<p>&nbsp;&nbsp;&nbsp;<strong>'{submissionInfo?.projectName}'</strong> {i18next.t("서·논술형 평가 결과")}</p>
						</Box>

						<Box display='flex'>
							<Box className={classes.printBtn} sx={{ marginRight: "10px" }}>
								<Button disableRipple onClick={handleClickPrint}>
									<Typography>{i18next.t("프린트 하기")}</Typography>
								</Button>
							</Box>
							<Box className={disabledToMarkAgain ? classes.saveLineMarkBtn : clsx(classes.saveLineMarkBtn, classes.saveLineMarkBtnActive)} sx={{ marginRight: "10px" }}>
								<Button disableRipple disabled={disabledToMarkAgain} onClick={handleClickMarkAgain}>
									<Typography>{i18next.t("다시 채점하기")}</Typography>
								</Button>
							</Box>
							<Box className={disabledToSubmit ? classes.applyMarkBtn : clsx(classes.applyMarkBtn, classes.applyMarkBtnActive)}>
								<Button disableRipple disabled={disabledToSubmit} onClick={handleClickExportResult}>
									<Typography> {disabledToSubmit ? i18next.t("결과 내보내기 완료") : i18next.t("결과 내보내기")}</Typography>
								</Button>
							</Box>
							<Button onClick={props.handleClickClose}>X</Button>
						</Box>
					</Toolbar>


					<Box className={classes.mainContentBox}>
						<Box>
							<TableContainer component={Paper} className={classes.tableBox}>

								<TableContainer component={Paper} className={classes.tableBox}>
									<ResultHeaderTable
										schoolName={submissionInfo?.school}
										currentGradeClassName={currentGradeClassName}
										userName={submissionInfo?.userName}
										year={submissionInfo?.project?.year}
										semester={submissionInfo?.project?.semesterType} currentCreatedDatetime={currentCreatedDatetime}

										titleBoxClassName={classes.titleBox}
									/>
								</TableContainer>
							</TableContainer>
						</Box>
						<Box display='flex' alignItems='center' justifyContent='right' sx={{ marginTop: "10px" }}>
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>

								<Typography className={classes.amountScoreTitle}>{i18next.t("총점")}</Typography>
								<Box className={classes.amountScore}>
									<Typography><span>{markedTotalScore}</span>/{standardTotalScore}<span className={classes.amountScoreText}>{i18next.t("점")}</span></Typography>
								</Box>
							</Box>
						</Box>
						{questionList.length > 0 &&
							questionList.map((question, idx) =>
								<QuestionRow
									key={idx}
									idx={idx}
									examInfo={getExamInfoByExamCode(question.projectExamCode)}
									questionInfo={question}
									examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
								/>
							)
						}

						<Box className={clsx(classes.greenInnerBox, classes.redInnerBox)}>
							<Box className={clsx(classes.tagNameBox, classes.tagNameBoxRed)}>
								<Typography>{i18next.t("모범 답안")}</Typography>
							</Box>
							{questionList.length > 0 &&
								questionList.map((question, idx) =>
									<StandardAnswerRow
										key={idx}
										questionInfo={question}
										examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
									/>
								)}
						</Box>

						<Box display='flex' alignItems='center' justifyContent='center'>
							<Box className={clsx(classes.borderGuide, classes.borderGuideText)}>
								<Typography>{i18next.t("마지막입니다.")}</Typography>
							</Box>
						</Box>

						<IconButton
							className={classes.pageUpIcon}
							onClick={handleClickPageUp}
							disableRipple>
							<PageUpIcon />
						</IconButton>
					</Box>
				</Box>
			}

			{projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("조회 중입니다...")}</Typography>
				</Backdrop>
			}

			{projectStore.isUpdatingProjectUserState &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectStore.isUpdatingProjectUserState}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("결과를 내보내는 중입니다...")}</Typography>
				</Backdrop>
			}

		</div>
	);
}

export default observer<typeof StudentResult>(StudentResult);



