import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { ReactComponent as SelectIcon } from '../../../../common/images/SelectIcon.svg';
import { SchoolLevelSubjects } from "../../../../common/SchoolLevelSubjects";
import { useStores } from "../../../../hooks/useStores";
import i18next from "../../../../lang";
import { IProject } from "../../../../repositories/model/IProject";
import { ProjectSchoolType } from "../../../../stores/enums/ProjectStoreEnums";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TableHomeTaskStlye } from "../../styles/TableHomeTaskStlye";
import { observer } from "mobx-react";

type Props = {
	subjectName: string,
	subjectIndex: number,
	subjectNameIndex: number,
	schoolTypeId: ProjectSchoolType,
	project: IProject,
}

function SubjectNameMenuItem(props: Props) {
	const classes = useEmotionStyles(TableHomeTaskStlye);
	const { subjectName, schoolTypeId, subjectIndex, subjectNameIndex, } = props;
	const { navigateStore, projectStore } = useStores();

	const schoolTypeIndex = SchoolLevelSubjects.findIndex((subject) => subject.id === schoolTypeId);

	// const currentSemesterString = React.useMemo(() => {
	// 	const semesterType = project?.semesterType;
	// 	return getSemesterTypeString(semesterType);
	// }, [project?.semesterType]);

	// const isNotSimpleModeAssignment = ((project.subjectName?.length || 0) > 1 && ((currentSemesterString?.length || 0) > 1)) || (!navigateStore.simpleMode);

	const isNotSimpleModeAssignment = (!navigateStore.simpleMode);


	const handleChangeSubjectName = (e) => {
		const subjectName = e.target.value;
		projectStore.changeSubjectName(subjectName);

		if (!isNotSimpleModeAssignment) {
			projectStore.changeSubject(subjectName);
		}
	}


	return (
		<FormControl className={classes.formControl}>
			<Select
				IconComponent={(props) => (
					<Box>
						<SelectIcon  {...props} />
					</Box>
				)}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "right"
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					// getContentAnchorEl: null,
					className: classes.selectPopover
				}}
				displayEmpty
				onChange={handleChangeSubjectName}
				value={subjectName ? subjectName : 0}
			>
				<MenuItem value={0}>
					<Typography>{i18next.t("과목 선택")}</Typography>
				</MenuItem>

				{
					(isNotSimpleModeAssignment)
						? (
							(subjectIndex >= 0 && subjectNameIndex >= 0 && SchoolLevelSubjects[subjectIndex].subjectGroups[subjectNameIndex].subjects.length > 0)
								? SchoolLevelSubjects[subjectIndex].subjectGroups[subjectNameIndex].subjects.map(
									(subject) => {
										return (
											<MenuItem key={subject.name} value={subject.name}>
												{i18next.t(subject.text)}
											</MenuItem>
										)
									})
								: <MenuItem key={"ItemNone"} disabled>{i18next.t("과목 없음")}</MenuItem>
						)
						: (
							schoolTypeIndex >= 0
								?
								SchoolLevelSubjects[schoolTypeIndex].subjectGroups.map(
									(subject) => {
										return (
											<MenuItem key={subject.name} value={subject.name}>
												{i18next.t(subject.text)}
											</MenuItem>
										)
									})
								:
								<MenuItem key={"ItemNone"} disabled>{i18next.t("과목 없음")}</MenuItem>
						)
				}
			</Select>
		</FormControl>
	)

}

export default observer<typeof SubjectNameMenuItem>(SubjectNameMenuItem);
