import i18n from 'i18next';
import sprintf from 'i18next-sprintf-postprocessor';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


import en from './en';
import ko from './ko';
import ja from './ja';
import fr from './fr';
import de from './de';
import es from './es';
import zhCN from './zhCN';
import zhTW from './zhTW';

const resources = {
	en: {
		translation: en,
	},
	ko: {
		translation: ko,
	},
	ja: {
		translation: ja,
	},
	fr: {
		translation: fr,
	},
	de: {
		translation: de,
	},
	es: {
		translation: es,
	},
	'zh-CN': {
		translation: zhCN,
	},
	'zh-TW': {
		translation: zhTW,
	},
};

export const getBrowserLanguage = () => {
	const browserLanguage = window.navigator.language || (window.navigator as any).userLanguage;
	let languageCode = browserLanguage.split('-')[0];
	if (languageCode === 'zh') {
		languageCode = browserLanguage;
	}
	const defaultLanguage = 'ko';

	if (Object.prototype.hasOwnProperty.call(resources, languageCode)) {
		return languageCode;
	}

	return defaultLanguage;
};

console.log(`LANG: ${getBrowserLanguage()}`);

i18n.use(initReactI18next)
	.use(Backend)
	.use(LanguageDetector)
	.use(sprintf)
	.init({
		resources,
		// lng: "ja",
		lng: getBrowserLanguage(),
		fallbackLng: 'ja',
		debug: false,
		defaultNS: 'translation',
		ns: 'translation',
		interpolation: {
			escapeValue: false, // HTML을 안전하게 렌더링합니다.
			alwaysFormat: true,
			format(value, format, lng) {
				if (format === 'uppercase') return value.toUpperCase();
				// interpolation 되는 값이 number로 들어올 때, 언어 설정에 맞는 locale string으로 변환해서 반환
				if (typeof value === 'number') return value.toLocaleString(lng);
				return value;
			},
		},
		react: {
			defaultTransParent: 'div',
			transEmptyNodeValue: '',
			transSupportBasicHtmlNodes: true,
			// <Trans> 컴포넌트 내부에 들어가는 html 태그들
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'button', 'a', 'span', 'div', 'input'],
			transWrapTextNodes: '',
		},
		nsSeparator: '=>', // default: ':', :가 번역 키에 포함되는 사례가 있어, 번역 키로 사용되지 않을 것 같은 seperator를 설정
	});


// i18next를 파라미터로 넘길 때 사용할 인터페이스 타입
export interface I18Next {
	t: (str: string, option?: Object) => string;
}

// scanner를 사용하기 위한 dummy function
export const i18nextScanKey = (key: string): string => key;

export default i18n;
