import i18next from "i18next";

export enum ProjectStateType {
	CREATED = "CREATED", // 문항정보 생성
	POSED = "POSED", // 문항출제
	SUBMITTED = "SUBMITTED", // 제출물 제출
	TRANSLATED = "TRANSLATED", // OCR 판독
	OFFLINE_UPLOADED = "OFFLINE_UPLOADED", // 오프라인 제출물 업로드
	COMPARED = "COMPARED", // 대조완료
	SCORED = "SCORED", // 채점완료 -> projectUser api
	EXPORTED = "EXPORTED", // -> projectUser api
	REVIEWED = "REVIEWED" // -> projectUser api
}

export function getSubmitStateString(state: ProjectStateType) {
	switch (state) {
		case ProjectStateType.CREATED:
			return i18next.t("과제 제시 전");
		case ProjectStateType.POSED:
			return i18next.t("필기 업로드 전");
		case ProjectStateType.SUBMITTED:
			return i18next.t("스캔물 제출완료");
		case ProjectStateType.TRANSLATED:
			return i18next.t("OCR 판독 완료");
		case ProjectStateType.OFFLINE_UPLOADED:
			return i18next.t("OCR 대조 대기");
		case ProjectStateType.COMPARED:
			return i18next.t("OCR 대조 완료");
		case ProjectStateType.SCORED:
			return i18next.t("채점완료");
		case ProjectStateType.EXPORTED:
			return i18next.t("파일출력");
		case ProjectStateType.REVIEWED:
			return i18next.t("리뷰완료");
		default:
			return i18next.t("알 수 없음");
	}

}