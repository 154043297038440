import {
	Table,
	TableBody,
	TableCell,
	TableRow
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProjectSemesterType } from "../../../repositories/model/support/ProjectSemesterType";
import { getSemesterTypeString, getYearSemesterTitle } from "./001_Functions/getSemesterTypeString";


type Props = {
	schoolName: string,
	currentGradeClassName: string,
	userName: string,
	year: number,
	semester: ProjectSemesterType,
	currentCreatedDatetime: string,

	titleBoxClassName: string
}

export function ResultHeaderTable(props: Props) {
	const i18next = useTranslation();
	const { titleBoxClassName, schoolName, currentGradeClassName, userName, year, semester, currentCreatedDatetime } = props;

	let yearSemsterString = getSemesterTypeString(year, semester);
	let yearSemsterTitle = getYearSemesterTitle(year, semester);

	return (<Table aria-label="simple table">
		<TableBody>
			<TableRow>
				<TableCell align="center" className={titleBoxClassName}>
					{i18next.t("학교명")}
				</TableCell>
				<TableCell colSpan={5}>
					{schoolName || '-'}
				</TableCell>
			</TableRow>

			<TableRow>
				<TableCell align="center" className={titleBoxClassName}>
					{i18next.t("학년/반")}
				</TableCell>
				<TableCell colSpan={2}>
					{currentGradeClassName}
				</TableCell>
				<TableCell align="center" className={titleBoxClassName}>
					{i18next.t("이름")}
				</TableCell>
				<TableCell colSpan={2}>
					{userName || '-'}
				</TableCell>
			</TableRow>

			<TableRow>
				<TableCell align="center" className={titleBoxClassName} colSpan={1}>
					{yearSemsterTitle}
				</TableCell>
				<TableCell colSpan={2}>
					{yearSemsterString}
				</TableCell>
				<TableCell align="center" className={titleBoxClassName} colSpan={1}>
					{i18next.t("시행일자")}
				</TableCell>
				<TableCell colSpan={2}>
					{currentCreatedDatetime}
				</TableCell>
			</TableRow>
		</TableBody>
	</Table>
	)
}