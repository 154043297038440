/* eslint-disable no-bitwise */
import { NeoStroke, DotTypes, NeoDotBuilder, IPenComm, neoUnzip, NEO_SMARTPEN_TYPE, IBrushEnum, StrokeStatusEnum } from "../../../../nl-lib3-common";
import { FilterForPaper } from "./FilterForPaper";

import { DEFAULT_MAX_FORCE } from "./pencomm_const";
import { ByteUtil } from "./pen_byteutil";
import { Packet } from "./pen_packet";




export function penTipModeToBrushType(penTipMode: number) {
  if (penTipMode === 0) return IBrushEnum.PEN;
  return IBrushEnum.ERASER;
}


// export const PACKET_SIZE_ERROR = "Packet length error";

export enum OfflineDataResultType {
  SUCCESS = "success",

  PACKET_SIZE_ERROR = "Packet length error",

  UNCOMPRESS_ERROR = "Uncompressed data size error",

  DOT_DATA_CHECKSUM_ERROR = "Dot array check sum error",

}

export type ProcessOfflineDataReturnType = {
  success: OfflineDataResultType,
  strokes: NeoStroke[],
  packetId: number,
  packetLocation: number,
}


function MakeDot(
  penMaxForce: number,
  section: number,
  owner: number,
  note: number,
  page: number,
  timestamp: bigint,
  x: number,
  y: number,
  fx: number,
  fy: number,
  force: number,
  type: DotTypes,
  color: number
) {
  let builder = null;

  if (penMaxForce === DEFAULT_MAX_FORCE + 1) builder = new NeoDotBuilder();
  else builder = new NeoDotBuilder(penMaxForce);

  builder.owner(owner)
    .section(section)
    .note(note)
    .page(page)
    .timestamp(timestamp)
    .coord(x + fx * 0.01, y + fy * 0.01)
    .force(force)
    .dotType(type)
    .color(color);
  return builder.Build();
}




export const processOfflinePacketReceived_main = async (pencomm: IPenComm, packet: Packet) => {
  let strokes: NeoStroke[] = [];

  const packetId = packet.GetShort();
  const isCompressed = packet.GetByte() === 1;
  const sizeBefore = packet.GetShort();
  const sizeAfter = packet.GetShort();
  const packetLocation = (packet.GetByte() & 0xFF);     // 0: first, 1: middle, 2: last
  const rb = packet.GetBytes(4);
  const section = (rb[3] & 0xFF);
  const owner = rb[0] + rb[1] * 256 + rb[2] * 65536;
  const note = packet.GetInt();
  const numStrokes = packet.GetShort();

  pencomm.mReceivedOfflineStroke += numStrokes;

  // console.log(` packetId : ${packetId}, isCompressed : ${isCompressed}, sizeBefore : ${sizeBefore}, sizeAfter : ${sizeAfter}, size : ${packet.Data.length - 18}`);

  // 패킷 자체가 정해진 길이가 아니면 다시 받아야 한다.
  const oDataSize = isCompressed ? sizeAfter : sizeBefore;
  if (oDataSize !== (packet.Data.length - 18)) {
    const ret: ProcessOfflineDataReturnType = {
      success: OfflineDataResultType.PACKET_SIZE_ERROR,
      strokes,
      packetId,
      packetLocation
    }
    return ret;
  }

  const oData = packet.GetBytes(oDataSize);
  let strokeBinData = oData;
  if (isCompressed) {
    strokeBinData = await neoUnzip(oData);
  }

  // 압축 푼 후 길이가 다르면, 잘못된 패킷이니 다시 받아야 한다.
  if (strokeBinData.length !== sizeBefore) {
    const ret: ProcessOfflineDataReturnType = {
      success: OfflineDataResultType.UNCOMPRESS_ERROR,
      strokes,
      packetId,
      packetLocation
    }
    return ret;
  }

  const butil = new ByteUtil({ data: strokeBinData });

  let checksumErrorCount = 0;
  for (let i = 0; i < numStrokes; i++) {
    const offlineFilterForPaper = new FilterForPaper();

    const pageId = butil.GetInt();
    const timeStart = butil.GetLong();
    butil.GetLong();    // timeEnd
    const penTipType = butil.GetByte() & 0xFF;
    const color = butil.GetInt();
    const dotCount = butil.GetShort();


    let time = timeStart;
    if (timeStart > Number.MAX_SAFE_INTEGER) {
      console.error(`time stamp is over than MAX_VALUE ${timeStart}`);
    }

    // console.log(`${section}.${owner}.${note}, pageId: ${pageId}, timeStart: ${timeStart}, timeEnd: ${timeEnd}, penTipType: ${penTipType}, color: ${color}, dotCount: ${dotCount}, time: ${time}`);
    const brushType = penTipModeToBrushType(penTipType);

    let colorHex = '00000000' + (color < 0 ? (color >>> 0).toString(16) : color.toString(16));

    colorHex = colorHex.slice(-6);

    const stroke = new NeoStroke({
      mac: pencomm.getMac(),
      color: "#" + colorHex,

      startTime: Number(timeStart),
      thickness: 1,
      penTipMode: penTipType,

      brushType,
      inputType: NEO_SMARTPEN_TYPE.INKSTORE_PEN,
      section,
      owner,
      book: note,
      page: pageId,

      status: brushType === IBrushEnum.ERASER ? StrokeStatusEnum.ERASED : StrokeStatusEnum.NORMAL,
    });

    for (let j = 0; j < dotCount; j++) {
      const dotChecksum = butil.GetChecksum(15);
      const timeadd = butil.GetByte();
      time += BigInt(timeadd);

      const force = butil.GetShort();
      const x = butil.GetUShort();
      const y = butil.GetUShort();

      const fx = butil.GetByte();
      const fy = butil.GetByte();
      butil.GetByte();   // tx
      butil.GetByte();   // ty

      butil.GetShort();   // twist
      butil.GetShort();    // reserver
      const checksum = butil.GetByte();

      // System.Console.WriteLine( "x : {0}, y : {1}, force : {2}, checksum : {3}, dotChecksum : {4}", tx, ty, twist, checksum, dotChecksum );

      if (dotChecksum !== checksum) {
        // 체크섬 에러 3번 이상이면 에러로 전송 종료
        if (checksumErrorCount++ > 1) {
          strokes = [];
          const ret: ProcessOfflineDataReturnType = {
            success: OfflineDataResultType.DOT_DATA_CHECKSUM_ERROR,
            strokes,
            packetId,
            packetLocation
          }
          return ret;
        }
        // eslint-disable-next-line no-continue
        continue;
      }

      let dotType: DotTypes;

      if (j === 0) {
        dotType = DotTypes.PEN_DOWN;
      } else if (j === dotCount - 1) {
        dotType = DotTypes.PEN_UP;
      } else {
        dotType = DotTypes.PEN_MOVE;
      }

      const dot = MakeDot(
        pencomm.penMaxForce,
        section,
        owner,
        note,
        pageId,
        time,
        x,

        y,

        fx,

        fy,

        force,
        dotType,

        color
      );

      const dots = offlineFilterForPaper.Put(dot);
      dots.forEach((d) => stroke.addDot(d));
      // stroke.addDot(dot);
    }
    // console.log(dotCount, stroke.dotCount);

    if (dotCount >= 3) {
      strokes.push(stroke);
    }
  }

  // pencomm.offlineStrokes.push(...strokes);

  // pencomm.sendOfflinePacketResponse_a4(packetId);
  // pencomm.offlineDataPacketRetryCount = 0;

  // pencomm.setTimeStamp((new Date()).getTime());
  // const event = makePenEvent(
  //   pencomm.deviceInfo.deviceType,
  //   PenCommEventEnum.ON_OFFLINE_DATA_PART_DELIVERED,
  //   {
  //     timeStamp: pencomm.currentTime,
  //     offlineData: {
  //       totalStrokeCount: pencomm.mTotalOfflineStroke,
  //       receivedStrokeCount: pencomm.mReceivedOfflineStroke,
  //       strokes: strokes
  //     }
  //   }
  // );
  // pencomm.penHandler.onOfflineDataDelivered(event);
  // if (packetLocation == 2) {
  //   pencomm.onFinishedOfflineDownload(true);
  // }

  const ret: ProcessOfflineDataReturnType = {
    success: OfflineDataResultType.SUCCESS,
    strokes,
    packetId,
    packetLocation
  }
  return ret;



  // return { strokes, packetId, packetLocation };
}

