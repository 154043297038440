import { NeoPDFManager } from "./NeoPDFManager";
import { NeoPDFContext } from "./NeoPDFContext";
import { IPageOverview, IPdfOpenOption } from "./DataTypes";
import { EmployerDisposable } from "./EmployerDisposable";
import { NeoPDFPage } from "./NeoPDFPage";
import { NeoStream } from "./NeoStream";
import { NeoPDFWorkerEmployer } from "../PdfWorkerEmployerClass";

// let _appendReady = new Promise<void>((resolve) => resolve());


export class NeoPDFDocument extends EmployerDisposable {
  static loadedDocCount = 0;

  name = "NeoPDFDocument";
  // _ready: PdfJs.PDFLoadingTask<PdfJs.PDFDocumentProxy>;

  // private _pageCount: number = null;

  public _context: NeoPDFContext = null;


  private _name = "";

  private _url!: string;

  private _filename: string | undefined;

  private _paperGroupId: string | undefined;

  private _purpose: string | undefined;

  private _fingerprint!: string;

  // private _id!: string;

  private _title: string = null;


  _pagesOverview: IPageOverview[] = [];

  private _defaultSize: { width: number, height: number };

  private _pages: NeoPDFPage[] = [];

  get context() { return this._context; }

  public async title() {
    if (this._title === null) {
      this._title = await this.employer.documentTitle(this.fzId);
    }
    return this._title;
  }

  public get numPages() { return this._pages.length; }

  public get url() { return this._url; }

  public set url(u: string) { this._url = u; }

  public get filename() { return this._filename; }

  public get paperGroupId() { return this._paperGroupId; }

  public get fingerprint() { return this._fingerprint; }

  public get purpose() { return this._purpose }


  public singleGlyphWidth = 0.96;

  private manager: NeoPDFManager = null;


  direction: "portrait" | "landscape" = "portrait";

  static count = 0;

  constructor(employer: NeoPDFWorkerEmployer, context: NeoPDFContext, manager: NeoPDFManager = null) {
    super(employer, context.fzId, null, employer.freeDocument);
    this._context = context;
    this.manager = manager;

    // console.log(`NeoPDFDocument: ${NeoPDFDocument.count++} ${this.fzId} (${this.ctxId})`);

    // employer.getSingleGlyphWidth().then((ret) => { this.singleGlyphWidth = ret; });
  }

  static async fromDocumentId(employer: NeoPDFWorkerEmployer, context: NeoPDFContext, docId: string, manager: NeoPDFManager = null) {
    await context.ready;
    const doc = new NeoPDFDocument(employer, context, manager);
    doc.fzId = docId;
    return doc;
  }

  static async create(args: { ctx: NeoPDFContext, uniqueKey?: string, manager?: NeoPDFManager, name?: string }) {
    let { ctx, manager = null, name = null, uniqueKey } = args;
    await ctx.ready;
    ctx.loadedDocs++;

    if (name === null) name = "NewFile";

    const docId = await ctx.employer.createNewPdfDocument(ctx.id);
    const doc = await NeoPDFDocument.fromDocumentId(ctx.employer, ctx, docId);

    uniqueKey = uniqueKey || docId;
    manager.registerPdf({ pdf: doc, uniqueKey, url: "" });

    return doc;

    // const pdfBytes = await doc.saveBytes();
    // doc.free();

    // const retDoc = new NeoPDFDocument(ctx.employer, ctx);
    // await retDoc.openFromBuffer(pdfBytes.buffer, name);
    // await retDoc.addBlankPage();
    // await retDoc.removePageAt(0);
    // manager.registerPdf({ pdf: retDoc, uniqueKey, url: "" });

    // return retDoc;
  }

  // clone() {
  //   finalizer.unregister(this);

  //   const cloned = new NeoPDFDocument(this.employer, this.context);
  //   cloned.pointer = this.pointer;
  //   cloned.initDocument(this._name, cloned.pointer)
  //   return cloned;
  // }

  openFromBuffer = async (args: { paperGroupId: string, buffer: ArrayBuffer, docName: string, isJobStart?: boolean, magic?: string }) => {
    const { paperGroupId, buffer, docName, isJobStart = false, magic = ".pdf" } = args;
    await this.context.ready;
    // console.log(`NeoPDFDocument: openFromBuffer ${JSON.stringify(this._name)}... ${this._fzId}`, buffer);

    this._paperGroupId = paperGroupId;
    const docId = await this.employer.openDocumentFromBuffer(buffer, magic, this.ctxId, isJobStart);
    await this.initDocument({ docName, docId, paperGroupId });
    return this;
  }

  openDocumentFromBuffer = this.openFromBuffer;

  openFromStream = async (args: { paperGroupId: string, stream: NeoStream, magic: string, docName?: string }) => {
    const { paperGroupId, stream, magic, docName = "" } = args;
    await this.context.ready;

    this._paperGroupId = paperGroupId;
    const docId = await this.employer.openDocumentFromStreamId(this.ctxId, stream.fzId, magic);
    await this.initDocument({ docName, docId, paperGroupId });
    return this;
  }

  openFromStreamPointer = async (args: { paperGroupId: string, streamPointer: number, magic: string, docName?: string }) => {
    const { paperGroupId, streamPointer, magic, docName = "" } = args;
    await this.context.ready;

    this._paperGroupId = paperGroupId;
    const docId = await this.employer.openDocumentFromStreamPointer(this.ctxId, streamPointer, magic);
    await this.initDocument({ docName, docId, paperGroupId });
    return this;
  }

  private initDocument = async (args: { docName: string, docId: string, paperGroupId: string }) => {
    const { docName, docId, paperGroupId } = args;
    this.fzId = docId;
    this._name = docName;
    this._filename = docName;

    // console.log(`NeoPDFDocument: initDocument ${JSON.stringify(this._name)}...${this.pointer}`);
    const pageCount = await this.employer.countPages(this.fzId);
    // this._pageCount = pageCount;
    // console.log(`NeoPDFDocument: after countPages ${JSON.stringify(this._name)}...${this.pointer}`);

    // this._title = await this.employer.documentTitle(this.pointer);
    // console.log( `NeoPDFDocument: after documentTitle ${JSON.stringify(this._name)}... ${this.pointer}`);

    this._pages = new Array(pageCount);
    this._pagesOverview = new Array(pageCount);
    this._defaultSize = { width: 595, height: 842 };
    // if (pageCount === 0) {
    //   this._defaultSize = { width: 595, height: 842 };
    // }
    // else {
    //   const defaultSizePageIndex = pageCount > 1 ? 1 : 0;
    //   const defaultSize = await this.employer.getPageSize(this.pointer, defaultSizePageIndex);
    //   this._defaultSize = defaultSize;
    // }

    // console.log("NeoPDFDocument: Loaded", JSON.stringify(this._name), "with", pageCount, "pages.");

    this._paperGroupId = paperGroupId;
  }


  public getPage = async (pageIndex: number) => {
    if (this.numPages === 0) {
      throw new Error("문서에 페이지가 없습니다.");
    }

    if (pageIndex >= this.numPages) {
      console.warn(`예전에 씌였던 돌려쓰기 PDF, 총 페이지 ${this.numPages} 보다 큰 페이지 ${pageIndex}를 요구`);

      // const repeatCount = Math.floor(pageIndex / this.numPages) + 1;
      // for (let i = 0; i < repeatCount; ++i) {
      //   await this.appendPDFPages(this, 0, this.numPages);
      // }
      pageIndex %= this.numPages;
    }

    try {
      let shouldGetNewPage = false;
      const prevPage = this._pages[pageIndex];

      if (!prevPage) shouldGetNewPage = true;
      else if (!(prevPage.id?.length)) {
        shouldGetNewPage = true;
        try {
          await prevPage.free();
        } catch (e) { }
        this._pages[pageIndex] = null;
      }

      if (shouldGetNewPage) {
        const page = new NeoPDFPage(this.employer, this, pageIndex);
        // await page.loadPage();
        await page.ready;
        this._pages[pageIndex] = page;
      }

      return this._pages[pageIndex];
    }
    catch (e) {
      return null;
    }
  }

  public countPages = async () => {
    return this.numPages;
  }

  public documentTitle = async () => {
    return this.title();
  }

  public getPages = async () => {
    const pages = [];

    const promises = [];
    for (let i = 0; i < this.numPages; i++) {
      const pr = this.getPage(i);
      promises.push(pr);
    }

    await Promise.all(promises);
    for (let i = 0; i < this.numPages; i++) {
      const page = await promises[i];
      pages.push(page);
    }
    return pages;
  }

  private doFree = async () => {
    for (let i = 0; i < this._pages.length; ++i) {
      const page = this._pages[i];
      if (page) {
        await page.free();
      }
    }
    this._pages = new Array(this.numPages);

    await this.superFree();
  }

  public free = async () => {
    const shouldPreserve = this.manager?.shouldPreserveDocument(this);
    if (!shouldPreserve) {
      this.doFree();
    }
  }

  onReportPageFreed = (page: NeoPDFPage) => {
    const pageIndex = page.pageIndex;
    this._pages[pageIndex] = undefined;
  }

  // TODO: implement better logic with try/catch
  loadFromUrl = async (options: IPdfOpenOption) => {
    await this.employer.ready;
    // this.free();

    NeoPDFDocument.loadedDocCount++;
    // console.log(`document loaded: ${NeoPDFDocument.loadedDocCount}`);

    const { url, pdfBytes, progressive = 0, prefetch = 0, filename, isJobStart, paperGroupId } = options;

    let docId = "";

    if (pdfBytes) {
      const buffer = pdfBytes.buffer;
      docId = await this.employer.neoOpenDocumentFromBuffer(this.ctxId, buffer, "application/pdf" );
    }
    else if (url && url.startsWith("blob:") || url.startsWith("data:")) {
      // console.log(`loadFromUrl: BLOB or DATA ${url}`);
      try {
        const bodyResponse = await fetch(url);
        const contentType = bodyResponse.headers.get("Content-Type");
        // const contentLength = parseInt(bodyResponse.headers.get("Content-Length")) || 0;
        if (!bodyResponse.ok)
          throw new Error("Could not fetch document.");
        const buffer = await bodyResponse.arrayBuffer();
        docId = await this.employer.neoOpenDocumentFromBuffer(this.ctxId, buffer, contentType || url);
      } catch (e) {
        console.error(e);
      }
    }
    else {
      console.log(`loadFromUrl: NOT BLOB nor DATA ${url}`);

      // try {
      //   const bodyResponse = await fetch(url);
      //   const contentType = bodyResponse.headers.get("Content-Type");
      //   // const contentLength = parseInt(bodyResponse.headers.get("Content-Length")) || 0;
      //   if (!bodyResponse.ok)
      //     throw new Error("Could not fetch document.");
      //   const buffer = await bodyResponse.arrayBuffer();
      //   docId = await this.employer.neoOpenDocumentFromBuffer(this.ctxId, buffer, contentType || url, isJobStart);

      // }
      // catch (e) {
      //   console.error(e);

      // }
      try {
        console.log(`loadFromUrl: NORMAL FETCH ${url}`);

        const bodyResponse = await fetch(url);
        const contentType = bodyResponse.headers.get("Content-Type");
        // const contentLength = parseInt(bodyResponse.headers.get("Content-Length")) || 0;
        if (!bodyResponse.ok) {
          throw new Error("Could not fetch document.");
          // console.error("Could not fetch document. RETRY TO Read blank", url);
          // return this.loadFromUrl({ ...options, url: blankPdfUrl })
        }
        const buffer = await bodyResponse.arrayBuffer();
        docId = await this.employer.neoOpenDocumentFromBuffer(this.ctxId, buffer, contentType || url, isJobStart);
      }
      catch (e) {
        console.log(`loadFromUrl: HEAD FETCH ${url}`);
        const headResponse = await fetch(url, { method: "HEAD" });
        if (!headResponse.ok)
          throw new Error("Could not fetch document.");
        const acceptRanges = headResponse.headers.get("Accept-Ranges");
        const contentLength = parseInt(headResponse.headers.get("Content-Length")) || 0;
        const contentType = headResponse.headers.get("Content-Type");

        if (acceptRanges === 'bytes' && progressive) {
          console.log("USING HTTP RANGE REQUESTS");
          docId = await this.employer.neoOpenDocumentFromUrl(this.ctxId, url, contentLength, progressive, prefetch, contentType || url);
        } else if (contentLength) {
          const bodyResponse = await fetch(url);
          if (!bodyResponse.ok)
            throw new Error("Could not fetch document.");
          const buffer = await bodyResponse.arrayBuffer();
          docId = await this.employer.neoOpenDocumentFromBuffer(this.ctxId, buffer, contentType || url, isJobStart);
        }
      }
    }

    if (docId) {
      const docName = this.getDocName(filename, url);
      await this.initDocument({ docName, docId, paperGroupId });
      this._url = url;
      return this;
    }

    return null;
  }

  // TOOD: 하위호환성을 위해 만들었으나, 추후 삭제할 예정
  load = this.loadFromUrl;

  private getDocName = (filename: string, url: string, docName?: string) => {
    if (!docName?.length) {
      docName = filename || url || "Untitled.pdf";
    }



    if (docName.length > 128) docName = docName.substr(0, 128);
    return docName;
  }

  getPageSize = async (pageIndex: number) => {
    const page = await this.getPage(pageIndex);
    const size = await page.getSize();
    return size;
  }

  documentOutline = async () => {
    return this.employer.documentOutline(this.fzId);
  }

  prepareNcodeFont = async (args: {
    glyphOffset?: number /* double */,
    glyphDiameter?: number  /* double */,
    glyphShape?: number  /* Line 0, Square 1, Diamond 2, Circle 3 */,
    forceToAdd?: boolean
  }) => {
    const {
      glyphOffset = 1.0 /* double */,
      glyphDiameter = 1.0 /* double */,
      glyphShape = 0  /* Line 0, Square 1, Diamond 2, Circle 3 */,
      forceToAdd = false
    } = args;

    const stroke_width = 0.01;

    return this.employer.addNcodeFontToDocument(this.fzId, glyphOffset, glyphDiameter, stroke_width, glyphShape);
  }

  addBlankPage = async (width?: number, height?: number, rotate?: number) => {
    if (width === undefined) width = 595;
    if (height === undefined) height = 842;
    if (height === undefined) rotate = 0;

    await this.employer.addBlankPage(this.fzId, width, height, rotate);
    this._pages.push(null);
    // await this.initDocument(this._name, this.pointer);

    this.resetPageIndexes();

    console.log(`addBlankPage pageCount: ${this.fzId} ${this.numPages}`);
  }

  insertBlankPageAt = async (pageIndex: number, width?: number, height?: number, rotate?: number) => {
    if (width === undefined) width = 595;
    if (height === undefined) height = 842;
    if (height === undefined) rotate = 0;

    await this.employer.insertBlankPageAt(this.fzId, pageIndex, width, height, rotate);
    this._pages.splice(pageIndex, 0, null);

    this.resetPageIndexes();

    console.log(`insertBlankPageAt pageCount: ${this.fzId} ${this.numPages}`);
  }

  removePageRange = async (start: number, end: number) => {
    const ret = await this.employer.removePageRange(this.fzId, start, end);
    this._pages.splice(start, end - start + 1);

    this.resetPageIndexes();
    return ret;
  }

  removePageAt = async (pageIndex: number) => {
    const ret = await this.employer.removePageAt(this.fzId, pageIndex);
    this._pages.splice(pageIndex, 1);

    this.resetPageIndexes();
    return ret;
  }


  appendPDFPages = async (donor: NeoPDFDocument, start: number, numPages: number) => {
    if (donor._context !== this._context) {
      throw new Error("Cannot append pages from a different context.");
    }

    await this.employer.appendPDFPages(this.fzId, donor.fzId, start, numPages);
    for (let i = 0; i < numPages; i++) {
      this._pages.push(null);
    }

    this.resetPageIndexes();
  }

  // save document to Uint8Array
  saveBytes = async (compress?: boolean) => {
    if (compress === undefined) compress = true;

    return this.employer.saveDocumentBytes(this.fzId, compress);
  }

  // generate page viewpoint for each page
  setPageOverview = async () => {
    this._pagesOverview = new Array(this.numPages);

    let numPortraitPages = 0;
    let numLandscapePages = 0;

    // const pr1 = [];
    // for (let i = 0; i < this.numPages; i++) {
    //   pr1.push(this._pages[i].ready);
    // }
    // await Promise.all(pr1);

    const pr2 = [];
    for (let i = 0; i < this.numPages; i++) {
      const page = this._pages[i];
      const vptPromise = page.getViewport({ scale: 1 });
      pr2.push(vptPromise);
    }

    const vpts = await Promise.all(pr2);

    for (let i = 0; i < this.numPages; i++) {
      const vpt = vpts[i];
      const { width, height } = vpt;

      const landscape = width > height;
      if (landscape) {
        numLandscapePages++;
      } else {
        numPortraitPages++;
      }

      const pageOverview = {
        rotation: vpt.rotation,
        landscape,
        sizePu: { width, height },
      }
      this._pagesOverview[i] = pageOverview;
    }

    if (numPortraitPages >= numLandscapePages) {
      this.direction = "portrait";
    } else {
      this.direction = "landscape";
    }

    return this.direction;
  }

  // called when a page is added or removed
  private resetPageIndexes = () => {
    for (let i = 0; i < this._pages.length; i++) {
      this._pages[i]?.setPageIndex(i);
    }
  }

  // called by NeoPDFPage
  public onPageFreed = (page: NeoPDFPage) => {
    const pageIndex = page.pageIndex;
    this._pages[pageIndex] = null;
  }
}
