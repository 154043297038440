import { NeoStroke } from "../../nl-lib3-common";

export function toInkstoreDots(stroke: NeoStroke) {
	// dot array를 binary로 바꾸어 base64 인코딩하는 부분
	const dotCount = stroke.dotArray.length;
	const dotPacketSize = 17;

	const dotBlob = new Uint8Array(dotPacketSize * stroke.dotCount);
	const view = new DataView(dotBlob.buffer);

	for (let i = 0, ln = dotCount; i < ln; i++) {
		const base = i * dotPacketSize;
		const dot = stroke.dotArray[i];
		view.setUint16(base + 0, dot.deltaTime, true);

		const force = dot.f;

		view.setFloat32(base + 2, force, true);
		view.setFloat32(base + 6, dot.x, true);
		view.setFloat32(base + 10, dot.y, true);
		view.setUint8(base + 14, 0); // tilt X
		view.setUint8(base + 15, 0); // tilt Y
		view.setUint8(base + 16, 0); // twist
	}

	let binary = '';
	for (let i = 0, ln = dotBlob.length; i < ln; i++) {
		binary += String.fromCharCode(dotBlob[i]);
	}
	const dotsBase64 = btoa(binary);

	return dotsBase64;
};