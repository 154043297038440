import {
	Box,
	Button,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography
} from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as AiStudyIcon } from '../../../../../common/images/AiStudyIcon.svg';
import { ReactComponent as MarkViewMore } from '../../../../../common/images/MarkViewMore.svg';
import { ReactComponent as NoResultIcon } from '../../../../../common/images/NoResultIcon.svg';
import { ReactComponent as ViewRightIcon } from '../../../../../common/images/ViewRightIcon.svg';
import TextViewer from "../../../../../components/common/TextViewer";
import { IExamInfo } from "../../../../../repositories/model/transfer/IExamInfo";
import { IQuestionInfo } from "../../../../../repositories/model/transfer/IQuestionInfo";
import { useCurrentParagraphHTML } from "../../../000_Common/001_Functions/useCurrentParagraphHtml";
import { useCurrentQuestionHTML } from "../../../000_Common/001_Functions/useCurrentQuestionHTML";
import { useIsParagraphAndSourceBoxEmpty } from "../../../000_Common/001_Functions/useIsParagraphAndSourceBoxEmpty";
import { RubricTable } from "./RubricTable";
import { ScoredTable } from "./ScoredTable";
import { useStores } from "../../../../../hooks/useStores";
import { StudentResultStyle } from "../../../styles/StudentResultStyle";
import useEmotionStyles from "../../../styles/useEmotionStyles";

type Props = {
	idx: number;
	examInfo: IExamInfo;
	questionInfo: IQuestionInfo;
	examOrderAndQuestionsLengthMap: any;
};


export const QuestionRow = (props: Props) => {
	const { idx, examInfo, questionInfo, examOrderAndQuestionsLengthMap } = props;

	const { projectExamStore } = useStores();
	const classes = useEmotionStyles( StudentResultStyle, { isFirst: idx === 0 } );

	// const [rubricIdx, setRubricIdx] = React.useState(0);
	const [openResult, setOpenResult] = React.useState(true);
	// const [isRubricFolded, setIsRubricFolded] = React.useState(false);
	const i18next = useTranslation();

	const currentParagraphHTML = useCurrentParagraphHTML({ examInfo });
	const isParagraphAndSourceBoxEmpty = useIsParagraphAndSourceBoxEmpty(examInfo);

	const currentOrder = React.useMemo(() => {
		const questionListLen = examOrderAndQuestionsLengthMap[questionInfo.parentOrder];

		return projectExamStore.setQuestionFullOrderByQuestionListLen(questionListLen, questionInfo.parentOrder, questionInfo.order);
	}, [examOrderAndQuestionsLengthMap, questionInfo.parentOrder, questionInfo.order]);

	// const currentQuestionHTML = React.useMemo(() => {
	// 	return setQuestionHTMLWithOrderAndScore(questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder);
	// }, [questionInfo, questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder]);
	const currentQuestionHTML = useCurrentQuestionHTML(projectExamStore, questionInfo, currentOrder);

	// const submitType = (type) => {
	// 	switch (type) {
	// 		case ProjectExamType.DESCRIPTIVE: return i18next.t("서술형");
	// 		case ProjectExamType.ESSAY: return i18next.t("논술형");
	// 		default: return "";
	// 	}
	// };

	// const [rotated, setRotated] = React.useState(false);

	// const handleClick = () => {
	// 	setRotated(!rotated);
	// };

	// const handleClickRubricFolded = () => {
	// 	setIsRubricFolded(prev => !prev);
	// };

	const handleClickStandardAnswer = () => {
		const answerTag = document.getElementById(`standard-answer-${currentOrder}`);
		if (answerTag) {
			answerTag.scrollIntoView({ behavior: "smooth" });
		}
	};


	const handleClickResultFold = () => {
		setOpenResult(prev => !prev);
	};

	return (
		<>
			<Box className={classes.compareContent}>
				<Box className={classes.compareRound}>
					{questionInfo.order === 1 &&
						<>
							{examInfo.directive &&
								<Typography className={classes.textArticleTitle}>
									{examInfo.directive}
								</Typography>
							}

							{!isParagraphAndSourceBoxEmpty && (
								<div style={{ overflow: "hidden" }}>

									<Box className={classes.textArticle}>
										<TextViewer
											currentHTML={currentParagraphHTML}
										/>
									</Box>
								</div>
							)}
						</>
					}

					<div style={{ overflow: "hidden" }}>
						<Box className={classes.questionViewer}>
							<TextViewer
								currentHTML={currentQuestionHTML}
							/>
						</Box>
					</div>

					<Box className={classes.answerGuideText}>
						<Typography>
							{i18next.t(questionInfo.answer.answer)}
						</Typography>
					</Box>
				</Box>
			</Box>

			{openResult &&
				<Box className={classes.greenBorderBox}>
					<Box className={questionInfo?.answer?.scores.length > 0 ? classes.greenInnerBox : clsx(classes.greenInnerBox, classes.noResultMark)}>
						<Box className={classes.tagBtnView}>
							<Button disableRipple onClick={handleClickStandardAnswer}>
								<Typography>{i18next.t("모범 답안 보기")}</Typography>
								<ViewRightIcon />
							</Button>
						</Box>

						<Box className={classes.tagNameBox}>
							<Typography>{i18next.t("채점 결과")}</Typography>
						</Box>

						<>
							{questionInfo?.answer?.scores.length > 0 ?
								<>
									{questionInfo?.answer?.scores.map((scoreInfo, idx) =>
										<ScoredTable
											key={idx}
											currentRubricInfo={questionInfo?.answer?.rubrics[idx]}
											questionInfo={questionInfo}
										/>
									)}

									{questionInfo?.answer?.ownerFeedback &&
										<TableContainer component={Paper} className={clsx(classes.tableBox2, classes.tableBoxMargin)}>
											<Table aria-label="simple table">
												<TableBody>
													<TableRow >
														{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
														<TableCell width='25%' align="center" className={classes.feedbackBgColor}>
															<Typography>{i18next.t("피드백")}</Typography>
														</TableCell>
														<TableCell width='75%' align="left" className={clsx(classes.feedbackBox, classes.feedbackBoxPadding)}>
															<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
																<TextViewer
																	currentHTML={questionInfo.answer.ownerFeedback}
																/>
															</Box>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									}
								</>
								:
								<Box className={classes.noResultBox}>
									<NoResultIcon />
									<Typography>{i18next.t("채점 결과가 없습니다.")}</Typography>
									<Typography className={classes.noResultSub}>{i18next.t("'채점하기' 버튼을 눌러 채점해 주세요.")}</Typography>
								</Box>
							}
						</>
					</Box>

					<Box className={clsx(classes.iconBtn, classes.iconBtnSupport)} display='flex' alignItems='center' justifyContent='left'>
						<Typography className={classes.viewMoreText}>{i18next.t("채점 기준표 보기")}</Typography>
					</Box>

					<Box>
						{questionInfo?.answer?.rubrics.length > 0 &&
							<RubricTable
								rubrics={questionInfo?.answer?.rubrics}
							/>
						}
					</Box>
				</Box>
			}

			<Box display='flex' alignItems='center' justifyContent='center'>
				<Box className={classes.resultBtnToggle} display="flex" alignItems="center" onClick={handleClickResultFold}>
					<Typography>
						{currentOrder}
						{i18next.t("번 문항 결과")}
						&nbsp;
						{openResult ? i18next.t("접기") : i18next.t("열기")}
					</Typography>
					<IconButton className={classes.iconBtnReset} style={{ transform: openResult ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>
						<MarkViewMore />
					</IconButton>
				</Box>
			</Box>

			<Box display='flex' alignItems='center' justifyContent='center'>
				<Box className={classes.borderGuide}>
					<AiStudyIcon />
				</Box>
			</Box>

		</>
	);
};
