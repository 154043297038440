import React, { createContext, useContext } from 'react';

// LocaleContext 생성
const LocaleContext = createContext(undefined);

export const LocaleProvider = ({ children }) => {
	const [locale, setLocale] = React.useState('ja'); // 기본 locale을 ja으로 설정

	return (
		<LocaleContext.Provider value={{ locale, setLocale }}>
			{children}
		</LocaleContext.Provider>
	);
};

// custom hook을 만들어 쉽게 locale을 사용할 수 있도록 함
export const useLocale = () => {
	return useContext(LocaleContext);
};
