import { makeAutoObservable, runInAction } from "mobx";
import * as validation from "../common/Validation";
import { AuthTokenStorageKey } from "../repositories/Repository";
import AuthRepository from "../repositories/AuthRepository";

import { UserAuthType } from "../repositories/model/support/UserAuthType";
import { UserType } from "../repositories/model/support/UserType";
import { AuthState } from "./support/AuthState";
import { ISimpleUser } from "../repositories/model/ISimpleUser";
import { IUser } from "../repositories/model/IUser";

const LogPrefix = "[AuthStore]";


// export const State = {
// 	Authenticated: "Authenticated",
// 	NotAuthenticated: "NotAuthenticated",
// 	Pending: "Pending",
// 	Failed: "Failed",
// 	NeedPasswordChange: "NeedPasswordChange",
// 	PassWordUpdated: 'PassWordUpdated',
// 	None: 'None'
// };

export const ErrorMsg = {
	CanNotValidateToken: "유효하지 않는 요청입니다.",
	ExpiredToken: "요청 시간이 만료 되었습니다.",
};

export const LocalStorageTokenKey = "_BASIC_AUTHENTICATION_TOKEN_";

const EmptyLogin = {
	email: "",
	password: "",
};

const EmptyUser: ISimpleUser = {
	code: "",
	email: "",
	nickname: "",
	type: UserType.User,

	authType: UserAuthType.NOTAUTHENTICATED,
	createdDatetime: "",
	updatedDatetime: "",
	furigana: "",
	lastName: "",
	firstName: "",
	lastFurigana: "",
	firstFurigana: "",
	number: 0,
	grade: 0,
	className: "",

	passwordChangeDatetime: null,

	clientOnly: {}
};

const EmptyLoginProcessElement = {
	loginId: "",
	emailError: false,
	loginIdError: false,
	passwordError: false,
	loginError: false,
	loginPasswordShow: false,
	loginButtonActive: false,
	loginSessionMaintain: false,
};

const EmptyFindPassword = {
	error: false,
	errorMatch: false,
	emailButton: false,
	email: "",
};

const EmptySignUp: {
	email: string,
	password: string,
	name: string,
	allowEmail: boolean,
	licenseKey: string,
	id?: string,

	furigana: string,
	lastName: string,
	firstName: string,
	lastFurigana: string,
	firstFurigana: string,
} = {
	email: '',
	password: '',
	name: '',
	allowEmail: false,
	licenseKey: '',

	furigana: '',
	lastName: '',
	firstName: '',
	lastFurigana: '',
	firstFurigana: '',

};

type AuthStoreProps = {
	authRepository: AuthRepository;
}

export default class AuthStore {
	public authRepository: AuthRepository;

	public login: typeof EmptyLogin;
	public loginState: AuthState;
	public loginUser: ISimpleUser;
	public signupUser: ISimpleUser;
	public loginElement: typeof EmptyLoginProcessElement;
	public findPassword: typeof EmptyFindPassword;
	public signUp: typeof EmptySignUp;

	public isEmailAuth: boolean;
	public newPassword: string;
	public newPasswordConform: string;
	public passwordDialogOpen: boolean;
	public passwordDialogMsg: string;
	public passwordDialogError: boolean;
	public passwordUpdateFail: boolean; // 결과 표시용.
	public loginFail: boolean; // 결과 표시용.
	public captcha: boolean;
	public captchaValue: string;

	public isExistEmail: any;

	public loginToken: string;

	constructor(props: AuthStoreProps) {
		this.authRepository = props.authRepository;

		this.login = Object.assign({}, EmptyLogin);
		this.loginState = AuthState.None;
		this.loginUser = Object.assign({}, EmptyUser);
		this.signupUser = Object.assign({}, EmptyUser);
		this.loginElement = Object.assign({}, EmptyLoginProcessElement);
		this.findPassword = Object.assign({}, EmptyFindPassword);

		this.signUp = Object.assign({}, EmptySignUp);

		this.isEmailAuth = true;
		this.newPassword = "";
		this.newPasswordConform = "";
		this.passwordDialogOpen = false;
		this.passwordDialogMsg = "";
		this.passwordDialogError = false;
		this.passwordUpdateFail = false; // 결과 표시용.
		this.loginFail = false; // 결과 표시용.
		this.captcha = false;
		this.captchaValue = '';

		this.isExistEmail = undefined;

		makeAutoObservable(this);
	}

	initLogin() {
		this.login = Object.assign({}, EmptyLogin);
	}

	initSignUp() {
		this.signUp = Object.assign({}, EmptySignUp);
	};

	initExistEmailState() {
		this.isExistEmail = undefined;
	};

	initLoginFails() {
		this.passwordUpdateFail = false; // 결과 표시용.
		this.loginFail = false; // 결과 표시용.
	}

	setLoginState(state: AuthState) {
		this.loginState = state;
	}

	changeExistEmailState(flag: boolean) {
		this.isExistEmail = flag;
	}

	changePasswordDialogOpen(value: boolean) {
		this.passwordDialogOpen = value;
	};
	changePasswordDialogMsg(msg: string) {
		this.passwordDialogMsg = msg;
	};
	changePasswordDialogError(value: boolean) {
		this.passwordDialogError = value;
	};

	changeEmailAuth(isEmailAuth: boolean) {
		this.isEmailAuth = isEmailAuth;
	};

	changeFindPasswordEmailButton(flag: boolean) {
		this.findPassword.emailButton = flag;
	};

	changeFindPasswordErrorMatch(flag: boolean) {
		this.findPassword.errorMatch = flag;
	};

	changeLoginElementLoginId(loginId: string) {
		this.loginElement.loginId = loginId;
	};
	changeLoginElementEmailError(emailError: boolean) {
		this.loginElement.emailError = emailError;
	};

	changeLoginEmail(email: string) {
		this.login.email = email;
	};

	changeLoginPassword(password: string) {
		this.login.password = password;
	};

	changeSignUpEmail(email: string) {
		let result = true;
		if (email && email.trim()) {
			result = this.emailValidation(email);
		}
		this.signUp.email = email;
		return result;
	};

	changeSignUpName(name: string) {
		let result = true;
		if (name && name.trim()) {
			result = this.nameValidation(name);
		}
		this.signUp.name = name;
		return result;
	}

	changeSignUpFurigana(furigana: string) {
		this.signUp.furigana = furigana;
	}

	changeSignUpLastName(lastName: string) {
		this.signUp.lastName = lastName;
	}

	changeSignUpFirstName(firstName: string) {
		this.signUp.firstName = firstName;
	}

	changeSignUpLastFurigana(lastFurigana: string) {
		this.signUp.lastFurigana = lastFurigana;
	}

	changeSignUpFirstFurigana(firstFurigana: string) {
		this.signUp.firstFurigana = firstFurigana;
	}

	changeSignUpPassword(password: string) {
		this.signUp.password = password;
		return this.passwordValidation(password);
	}

	changeCaptchaState(state: boolean) {
		this.captcha = state;
	}

	emailValidation(id: string) {
		return validation.validateEmail(id);
	};

	nameValidation(name: string) {
		return validation.validateName(name);
	}

	passwordValidation(password: string) {
		let passwordCombination = validation.validatePasswordCombination(password);
		let passwordLength = validation.validatePasswordLength(password);
		let result = passwordCombination && passwordLength;
		return [password === '', result, passwordCombination, passwordLength];
	}

	initCaptcha() {
		this.captchaValue = '';
		this.captcha = false;
		console.log(this.captchaValue);
	}

	verifyCaptcha(captchaValue: string) {
		this.captchaValue = captchaValue;
		// console.log(this.captchaValue);
		this.verifyRecaptcha();
	}

	async verifyRecaptcha() {
		try {
			// console.log("this.serverContextPath ",this.serverContextPath );
			// const captchaResponse = await  axios.get(this.serverContextPath + '/api/v1/captcha?captchaValue=' + this.captchaValue);
			const param = {
				captchaValue: this.captchaValue
			}
			const captchaResponse = await this.authRepository.checkRECAPTCHA(param);

			runInAction(() => {
				if (captchaResponse === 'captcha 인증 성공.') {
					console.log('captcha 인증 성공');
					this.captcha = true;
				} else {
					console.log('captcha 인증 실패');
					this.captcha = false;
				}
			});
		} catch (e) {
			console.log('captcha 인증 실패', e);
			runInAction(() => {
				this.captcha = false;
			});
		}
	};

	async doLogin(loginCallback: () => void) {
		this.loginState = AuthState.Pending;

		try {
			const param = this.login;
			const response = await this.authRepository.signIn(param);

			runInAction(() => {
				if (response.user.authType === UserAuthType.AUTHENTICATED) {
					localStorage.setItem(AuthTokenStorageKey, response.token);

					this.loginUser = response.user as any;

					const passwordChangeDatetime = this.loginUser.passwordChangeDatetime;
					const userType = this.loginUser.type;

					if (passwordChangeDatetime === null && userType === UserType.User) {
						this.loginState = AuthState.NeedPasswordChange;
					} else {
						this.loginState = AuthState.Authenticated;
						this.loginFail = false;
					}

					loginCallback();

				} else {
					this.loginState = AuthState.NotAuthenticated;
					this.loginUser = response.user as any;
					this.changeEmailAuth(false);
				}
			});
		} catch (e) {
			runInAction(() => {
				this.loginState = AuthState.NotAuthenticated;
				this.loginToken = "";
				this.loginUser = Object.assign({}, EmptyUser);
				this.loginFail = true;
				console.log("[LoginFailed]", e);
			});
		}
	}

	async checkLogin(checkLoginCallback: () => any) {
		console.log("checkLogin start...");
		this.loginState = AuthState.Pending;
		const token = localStorage.getItem(LocalStorageTokenKey);
		if (token) {
			try {
				const user = await this.authRepository.signCheck();
				runInAction(() => {
					this.loginUser = user;
					const passwordChangeDatetime = this.loginUser.passwordChangeDatetime;
					const userType = this.loginUser.type;

					if (passwordChangeDatetime === null && userType === UserType.User) {
						this.loginState = AuthState.NeedPasswordChange;
					} else {
						this.loginState = AuthState.Authenticated;
					}

				});

				await checkLoginCallback();
			} catch (e) {
				runInAction(async () => {
					this.loginState = AuthState.NotAuthenticated;
					this.loginUser = Object.assign({}, EmptyUser);
					await checkLoginCallback();
					console.log("checkLogin error...", e);
				});
			}
		} else {
			this.loginState = AuthState.NotAuthenticated;
		}
	}

	// 성공시 성공화면으로 바뀌어야함. 그 화면에서 로그인 버튼을 누르면, 다시 로그인 할수있도록 해줘야함.
	// 실패시 -> 업데이트 실패 메세지를 띄움
	async updatePassword(newPassword: string) {
		try {
			const params = {
				userCode: this.loginUser.code,
				email: this.loginUser.email,
				newPassword: newPassword
			}
			const result = await this.authRepository.updatePassword(params);
			console.log(LogPrefix, "updatePassword success.");

			runInAction(() => {
				this.loginState = AuthState.PassWordUpdated;
				this.passwordUpdateFail = false;
			});
		} catch (e) {
			runInAction(() => {
				console.log(LogPrefix, "updatePassword Failed. result=", e);
				this.loginState = AuthState.NotAuthenticated;
				this.passwordUpdateFail = true;
			});
		}
	}

	async getLoginUserInfo() {
		try {
			const result = await this.authRepository.getLoginUserInfo(this.loginUser.code);

			runInAction(() => {
				this.loginUser = result;
				console.log(LogPrefix, "getLoginUserInfo success. result=", result);
			});
			return result;
		} catch (e) {
			console.log(LogPrefix, "getLoginUserInfo Failed. error=", e);
		}
	}

	async doLogout() {
		localStorage.removeItem(LocalStorageTokenKey);

		try {
			await this.authRepository.signOut();

			runInAction(() => {
				this.login = Object.assign({}, EmptyLogin);
				this.loginState = AuthState.NotAuthenticated;
				this.loginUser = Object.assign({}, EmptyUser);
			});
		} catch (e) {
			runInAction(() => {
				this.login = Object.assign({}, EmptyLogin);
				this.loginState = AuthState.NotAuthenticated;
				this.loginUser = Object.assign({}, EmptyUser);
			});
		}
	}


	async doCheckEmailDuplicate(func: (arg: IUser) => Promise<void>) {
		try {
			const result = await this.authRepository.getUserByEmail(this.signupUser.email);
			await func(result);
		} catch (e) {
			console.log(e);
		}
	}

	// *doSignUp() {
	// 	console.log(LogPrefix, "doSignUp Start... ");
	// 	try {
	// 		const data: IUser = {
	// 			email: this.signUp.email,
	// 			password: this.signUp.password,
	// 			nickname: this.signUp.name,
	// 			type: UserType.User,
	// 			userProfile: {
	// 				avatarImageObject: "",
	// 				name: this.signupUser.clientOnly.name,
	// 				gender: this.signupUser.clientOnly.gender,
	// 				birth: this.signupUser.clientOnly.birth,
	// 				organization: this.signupUser.clientOnly.organization,
	// 			},
	// 		};
	// 		const result = await  this.authRepository.signUp(data);

	// 		const user = result;
	// 		this.signupUser.clientOnly.id = user.id;
	// 		console.log(LogPrefix, "doSignUp Success!! result : ", result);
	// 	} catch (e) {
	// 		console.log(LogPrefix, "doSignUp Failed. error : ", e);
	// 	}
	// }

	// *resendSignUpEmail(code) {
	// 	console.log(LogPrefix, "resendSignUpEmail Start... code : ", code);
	// 	try {
	// 		const result = await  this.authRepository.resendSignUpEmail(code);
	// 		console.log(LogPrefix, "resendSignUpEmail Success!!");
	// 	} catch (e) {
	// 		console.log(LogPrefix, "resendSignUpEmail Failed. error : ", e);
	// 	}
	// }

	// *resendPasswordEmail() {
	// 	console.log(LogPrefix, "resendPasswordEmail Start...");
	// 	try {
	// 		const email = this.findPassword.email;

	// 		const user = await  this.authRepository.getUserByEmail(email);
	// 		if (user) {
	// 			const result = await  this.authRepository.resendPasswordEmail(user.id);
	// 			this.changeFindPasswordEmailButton(true);
	// 			console.log(LogPrefix, "resendPasswordEmail Success!!");
	// 		} else {
	// 			this.changeFindPasswordErrorMatch(true);
	// 			console.log(
	// 				LogPrefix,
	// 				"resendPasswordEmail Failed. error : Can not found User by email."
	// 			);
	// 		}
	// 	} catch (e) {
	// 		console.log(LogPrefix, "resendPasswordEmail Failed. error : ", e);
	// 	}
	// }

	async modifyPassword(token: string, callback) {
		console.log(LogPrefix, "modifyPassword Start...");
		try {
			const params = {
				password: this.newPassword,
			};

			const user = await this.authRepository.modifyPassword(token, params);

			runInAction(() => {
				this.changePasswordDialogMsg("비밀번호 재설정이 완료되었습니다.");
				this.changePasswordDialogOpen(true);
				this.changePasswordDialogError(false);
			});
		} catch (e) {
			const { errorCode } = e.response.data;

			const msg = ErrorMsg[errorCode];
			if (msg) {
				this.changePasswordDialogMsg(msg);
				this.changePasswordDialogOpen(true);
				this.changePasswordDialogError(true);
			} else {
				this.changePasswordDialogMsg(
					"알 수 없는 오류입니다. 관리자에게 문의하세요."
				);
				this.changePasswordDialogOpen(true);
				this.changePasswordDialogError(true);
			}
		}
	}

	// *changePassword(currentPassword, modifyPassword) {
	// 	console.log(LogPrefix, "changePassword Start...");
	// 	try {
	// 		const param = {
	// 			code: this.loginUser.code,
	// 			currentPassword: currentPassword,
	// 			modifyPassword: modifyPassword,
	// 		};
	// 		const changeFlag = await  this.authRepository.changePassword(param);
	// 		console.log(LogPrefix, "changePassword Success...", changeFlag);
	// 		return changeFlag;
	// 	} catch (e) {
	// 		console.log(LogPrefix, "changePassword Failed ...", e);
	// 	}
	// }

	async changeUserProfile(code: string, callback) {
		console.log(LogPrefix, "changeUserProfile Start... ");
		try {
			const data = {
				code: code,
				email: this.signupUser.email === "" ? null : this.signupUser.email,
				nickname:
					this.signupUser.nickname === "" ? null : this.signupUser.nickname,
				userProfile: {
					code: code,
					avatarImageObject: this.signupUser.clientOnly.avatarImageObject,
					name: this.signupUser.clientOnly.name,
					gender: this.signupUser.clientOnly.gender,
					birth: this.signupUser.clientOnly.birth,
					organization: this.signupUser.clientOnly.organization,
				},
			};

			console.log(LogPrefix, "changeUserProfile data : ", data);

			const result = await this.authRepository.changeUserProfile(data);
			await callback();
			// const user = result;
			//this.signupUser.id = user.id;
			console.log(LogPrefix, "changeUserProfile Success!! result : ", result);
		} catch (e) {
			console.log(LogPrefix, "changeUserProfile Failed. error : ", e);
		}
	}


	async requestPasswordReset(email: string, language: string) {
		// API 호출을 통해 비밀번호 재설정 이메일 요청
		await this.authRepository.requestPasswordReset(email, language);
	}

	async resetPassword(token: string, newPassword: string) {
		// API 호출을 통해 새 비밀번호 설정
		await this.authRepository.resetPassword(token, newPassword);
	}

	changeLoginElementLoginButtonActive(sw: boolean) {
		this.loginElement.loginButtonActive = sw;
	}
}
