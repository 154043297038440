import { IDiscoveredDevicesExtended, PenStudentInfo } from "./PenStudentInfo";


export const rowSortingHits = [
	{
		name: "none",
		func: (isAsending: boolean, src: IDiscoveredDevicesExtended[]) => src
		,
		isAsending: true,
	},
	{
		name: "penName",
		func: (isAsending: boolean, src: IDiscoveredDevicesExtended[]) => {
			const dst = src.sort((a, b) => {
				let val = 0;
				if (a.name === b.name) val = 0;
				else val = a.name < b.name ? -1 : 1;

				return isAsending ? val : -val;
			});
			return dst;
		},
		isAsending: true,
	},
	{
		name: "mac",
		func: (isAsending: boolean, src: IDiscoveredDevicesExtended[]) => {
			const dst = src.sort((a, b) => {
				let val = 0;
				if (a.mac === b.mac) val = 0;
				else val = a.mac < b.mac ? -1 : 1;

				return isAsending ? val : -val;
			});
			return dst;
		},
		isAsending: true,
	},
	{
		name: "studentInfo",
		func: (isAsending: boolean, src: IDiscoveredDevicesExtended[]) => {
			const dst = src.sort((a, b) => {
				const val = PenStudentInfo.compare(a.studentInfo, b.studentInfo);
				return isAsending ? val : -val;
			});
			return dst;
		},
		isAsending: false,
	},
	{
		name: "schoolName",
		func: (isAsending: boolean, src: IDiscoveredDevicesExtended[]) => {
			const dst = src.sort((a, b) => {
				const val = PenStudentInfo.compareSchool(a.studentInfo, b.studentInfo);
				return isAsending ? val : -val;
			});
			return dst;
		},
		isAsending: false,
	},
	{
		name: "studentName",
		func: (isAsending: boolean, src: IDiscoveredDevicesExtended[]) => {
			const dst = src.sort((a, b) => {
				const val = PenStudentInfo.compareName(a.studentInfo, b.studentInfo);
				return isAsending ? val : -val;
			});
			return dst;
		},
		isAsending: false,
	},
	{
		name: "rssi",
		func: (isAsending: boolean, src: IDiscoveredDevicesExtended[]) => {
			const dst = src.sort((a, b) => {
				let val = 0;
				if (a.rssi === b.rssi) val = 0;
				else val = (-a.rssi) < (-b.rssi) ? -1 : 1;	 // rssi는 마이너스

				return isAsending ? val : -val;
			});
			return dst;
		},
		isAsending: false,
	},
];

export const getSortedData = (name: string, src: IDiscoveredDevicesExtended[]) => {
	const row = rowSortingHits.find(row => row.name === name);
	const isAsending = row.isAsending;
	return row.func(isAsending, src);
}

export const getSortingHitOrderBy = (name: string) => {
	const row = rowSortingHits.find(row => row.name === name);
	return row.isAsending;
}
