import { ColorSpace } from "./ColorSpace";
import { assert, neoAllocateUTF8 } from "./functions";
import { FzDevice } from "./FzDevice";
import { FzDocument } from "./FzDocument";
import { FzMatrix } from "./FzMatrix";
import { FzPixmap } from "./FzPixmap";
import { FzRect } from "./FzRect";
import { Link } from "./Link";
import { Links } from "./Links";
import { STextPage } from "./STextPage";
import { Wrapper } from "./Wrapper";

export class FzPage extends Wrapper {
  pageIndex: number;

  doc: FzDocument = null;


  constructor(libmupdf: any, ctx: number, pointer: number, pageIndex: number, doc: FzDocument) {
    const freeFunc = (ctx2, pointer2) => {
      doc.onFreePage(pageIndex);
      libmupdf._wasm_drop_page(ctx2, pointer2)
    }

    super(libmupdf, ctx, pointer, freeFunc);
    this.doc = doc;
    this.pageIndex = pageIndex;
  }

  bounds() {
    const { libmupdf } = this;
    return FzRect.fromFloatRectPtr(libmupdf, libmupdf._wasm_bound_page(this.ctx, this.pointer));
  }

  width() {
    return this.bounds().width();
  }

  height() {
    return this.bounds().height();
  }

  run(device, transformMatrix = FzMatrix.identity, cookie = null) {
    assert(device instanceof FzDevice, "invalid device argument");
    const m = FzMatrix.from(transformMatrix);
    const { libmupdf } = this;
    libmupdf._wasm_run_page(
      this.ctx,
      this.pointer,
      device.pointer,
      m.a, m.b, m.c, m.d, m.e, m.f,
      cookie?.pointer,
    );
  }

  runPageContents(device, transformMatrix = FzMatrix.identity, cookie = null) {
    assert(device instanceof FzDevice, "invalid device argument");
    const m = FzMatrix.from(transformMatrix);
    const { libmupdf } = this;
    libmupdf._wasm_run_page_contents(
      this.ctx,
      this.pointer,
      device.pointer,
      m.a, m.b, m.c, m.d, m.e, m.f,
      cookie?.pointer,
    );
  }

  runPageAnnots(device, transformMatrix = FzMatrix.identity, cookie = null) {
    assert(device instanceof FzDevice, "invalid device argument");
    const m = FzMatrix.from(transformMatrix);
    const { libmupdf } = this;
    libmupdf._wasm_run_page_annots(
      this.ctx,
      this.pointer,
      device.pointer,
      m.a, m.b, m.c, m.d, m.e, m.f,
      cookie?.pointer,
    );
  }

  runPageWidgets(device, transformMatrix = FzMatrix.identity, cookie = null) {
    assert(device instanceof FzDevice, "invalid device argument");
    const m = FzMatrix.from(transformMatrix);
    const { libmupdf } = this;
    libmupdf._wasm_run_page_widgets(
      this.ctx,
      this.pointer,
      device.pointer,
      m.a, m.b, m.c, m.d, m.e, m.f,
      cookie?.pointer,
    );
  }

  // showExtras?
  toPixmap(transformMatrix, colorspace, alpha = false, cookie = null) {
    const { ctx } = this;
    assert(colorspace instanceof ColorSpace, "invalid colorspace argument");

    const { libmupdf } = this;
    const bbox = this.bounds().transformed(libmupdf, FzMatrix.from(transformMatrix));
    const pixmap = FzPixmap.withBbox(libmupdf, ctx, colorspace, bbox, alpha);
    if (alpha)
      pixmap.clear();
    else
      pixmap.clearWithWhite();

    const device = FzDevice.drawDevice(libmupdf, this.ctx, transformMatrix, pixmap);
    this.run(device, FzMatrix.identity, cookie);
    device.close();
    device.free();

    return pixmap;
  }

  // toDisplayList(showExtras?)

  // options
  toSTextPage() {
    const { libmupdf } = this;
    const stextPointer = libmupdf._wasm_new_stext_page_from_page(this.ctx, this.pointer);
    return new STextPage(libmupdf, this.ctx, stextPointer);
  }

  getLinks() {
    const links: Link[] = [];
    const { libmupdf } = this;

    for (
      let link = libmupdf._wasm_load_links(this.ctx, this.pointer);
      link !== 0;
      link = libmupdf._wasm_next_link(this.ctx, link)
    ) {
      links.push(new Link(libmupdf, this.ctx, link));
    }

    // TODO - return plain array
    return new Links(this.ctx, links);
  }

  search(needle) {
    const MAX_HIT_COUNT = 500;
    const needle_ptr = 0;
    let hits_ptr = 0;
    const { libmupdf } = this;

    try {
      hits_ptr = libmupdf._wasm_malloc(this.ctx, libmupdf._wasm_size_of_quad(this.ctx) * MAX_HIT_COUNT);

      const needle_ptr = neoAllocateUTF8(libmupdf, this.ctx, needle);
      const hitCount = libmupdf._wasm_search_page(this.ctx,
        this.pointer, needle_ptr, hits_ptr, MAX_HIT_COUNT
      );

      const rects = [];
      for (let i = 0; i < hitCount; ++i) {
        const hit = hits_ptr + i * libmupdf._wasm_size_of_quad(this.ctx);
        const rect = FzRect.fromFloatRectPtr(libmupdf, libmupdf._wasm_rect_from_quad(this.ctx, hit));
        rects.push(rect);
      }

      return rects;
    }
    finally {
      libmupdf._wasm_free(this.ctx, needle_ptr);
      libmupdf._wasm_free(this.ctx, hits_ptr);
    }
  }
}
