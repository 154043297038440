class DataUtils {
	static getTagArrFromHtml<K extends keyof HTMLElementTagNameMap>(html: string, tagName: K) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(html, 'text/html');
		return doc.querySelectorAll(tagName);
	};

	static getTextFromHtml(html: string) {
		if (!html) return '';

		const parser = new DOMParser();
		const doc = parser.parseFromString(html, 'text/html');
		return doc.body.textContent;
	};

}

export { DataUtils };
