import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	IconButton,
	MenuItem, MenuList, Popover,
	TextField,
	Theme,
	Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/styles";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccountStop } from "../common/images/AccountStop.svg";
import { ReactComponent as BoardTextDelete } from "../common/images/BoardTextDelete.svg";
import { ReactComponent as DialogClose } from "../common/images/DialogClose.svg";
import { ReactComponent as DotsMoreMenu } from "../common/images/DotsMoreMenu.svg";
import { ReactComponent as FolderOpenIcon } from "../common/images/FolderOpenIcon.svg";
import { ReactComponent as GroupUsersProfiles } from "../common/images/GroupUsersProfiles.svg";
import { ReactComponent as NameChange } from "../common/images/NameChange.svg";
import { useStores } from "../hooks/useStores";
import MessageDialog from "../views/components/MessageDialog";
import useEmotionStyles from "../views/main/styles/useEmotionStyles";
import { ManageStudentStlye } from "../views/main/styles/ManageStudentStlye";

const UpdateType = {
	save: 'save', // 신규 추가
	name: 'name', // 기존꺼 이름 변경
	delete: 'delete', // 기존꺼 삭제
}

type Props = {
	onClose: () => void;
	open: boolean;
}

function GroupDialog(props: Props) {
	const { userStore } = useStores();
	const classes = useEmotionStyles(ManageStudentStlye);
	const i18next = useTranslation();

	const [page, setPage] = React.useState(1);
	const [pageBackup, setPageBackup] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(5); // 사실상 바뀌지 않음
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedGroup, setSelectedGroup] = React.useState(null);
	const [editingNameGroup, setEditingNameGroup] = React.useState(null); // 이름 수정 대상을 가리키는 값
	const [groupEditName, setGroupEditName] = React.useState(''); // 수정 중인 이름. 오직 수정 중일때만 유효한 값을 갖고, 완료시 임시 저장을 한뒤 null 로 만들 것.
	const [addGroupName, setAddGroupName] = React.useState(''); // 새로 추가할 그룹 입력
	const [addGroupList, setAddGroupList] = React.useState([]); // 아래에 보여주는 용도
	const [deleteGroupList, setDeleteGroupList] = React.useState([]); // 기존에 있던 그룹중 삭제 버튼을 누른애들. 표시용
	const [pageChangeDialog, setPageChangeDialog] = React.useState(false); // 기존 데이터 수정건이 있는데 페이지를 옮기려할때 경고 다이얼로그

	const theme = useTheme();

	const { onClose, open } = props; // selectedUser 설정 에서는 selectedUser 는 필요 없다.

	const bodyHeight = (rowsPerPage * 51) + 26;

	const { myGroupsForConfig, backdropOpen, groupConfigLoading, myGroupsSaveData,
		myGroupsTotalCount } = userStore;

	const pageCount = myGroupsTotalCount && myGroupsTotalCount > 0 ?
		myGroupsTotalCount > rowsPerPage ? Math.ceil(myGroupsTotalCount / rowsPerPage) : 1 : 0;

	React.useEffect(() => {
		userStore.getGroupListForConfig(page - 1, rowsPerPage); // 그룹별 인원수 + 페이지 네이션
	}, [])

	const handleClose = () => {
		onClose();
	};

	const handleClickPage = (e, value) => {
		setPageBackup(value);
		if (myGroupsSaveData.length > addGroupList.length) {
			// 기존 데이터 수정건이 있다는 것
			setPageChangeDialog(true);
			return;
		}
		setPage(value);
		userStore.getGroupListForConfig(value - 1, rowsPerPage); // 그룹별 인원수 + 페이지 네이션
	}

	// 취소 눌렀을때
	const pageChangeDialogClose = () => {
		setPageChangeDialog(false);
	}

	// 계속 눌렀을때 -> 스토어의 myGroupsSaveData 에서 code 가 있는 데이터는 삭제, 나머진 유지
	const pageChangeDialogConfirm = () => {
		// 1. code 가 있는 데이터는 삭제
		userStore.deleteCodeInGroupSaveData();

		setPage(pageBackup);
		setPageChangeDialog(false);
		setDeleteGroupList([]);
		userStore.getGroupListForConfig(pageBackup - 1, rowsPerPage); // 그룹별 인원수 + 페이지 네이션
	}

	const handleClickDetailDot = (event, group) => {
		setAnchorEl(event.currentTarget);
		setSelectedGroup(group);
	};

	const handleClosePopover = () => {
		setAnchorEl(null);
	}

	const handleClickEditName = () => {
		setEditingNameGroup(selectedGroup);
		setAnchorEl(null);
	}

	// 기존 그룹 삭제
	const handleClickGroupDelete = () => {

		setDeleteGroupList(prev => {
			const isDuplicate = prev.some(group => group.code === selectedGroup.code);
			if (!isDuplicate) {
				return [...prev, selectedGroup];
			} else {
				return prev;
			}
		})

		// 내부에서 중복처리를 자체적으로 하니까 일단 수행해도 된다.
		userStore.setMyGroupsSaveData(selectedGroup, UpdateType.delete);
		setAnchorEl(null);
	}

	// 신규 추가 했던 그룹 삭제
	const handleClickAddedGroupDelete = (addGroup) => {
		setAddGroupList(prevState => {
			let find = false;
			return prevState.filter(group => {
				if (!find && group.code === null && group.name === addGroup.name) {
					find = true;
					return false;
				}
				return true;
			});
		})

		userStore.deleteAddedGroup(addGroup);
	}

	const handleChangeGroupName = (event) => {
		setGroupEditName(event.target.value);
	}

	const handleClickSaveGroupName = () => {
		// 1. 현재 선택된 녀석이 누군지 체크 (code) -> selectedGroup
		const destinationCode = editingNameGroup.code;
		// 2. 해당 그룹 객체의 name 값 자체를 바꿔줌 -> myGroupsForConfig 에서 찾아서 바꾸기(표기용)
		const destination = myGroupsForConfig.find(group => group.code === destinationCode);
		if (destination) {
			destination.name = groupEditName;
			// 3. 스토어의 myGroupsSaveData 에 해당 그룹을 push 하고, UpdateType -> name
			userStore.setMyGroupsSaveData(destination, UpdateType.name);
		}

		// 수정 관련 값 초기화
		setEditingNameGroup(null);
		setGroupEditName('');

	}

	const handleClickCancelGroupName = () => {
		setEditingNameGroup(null);
		setGroupEditName('');
	}

	const handleClickAddGroup = () => {
		const newGroup = {
			code: null,
			name: addGroupName
		}
		userStore.setMyGroupsSaveData(newGroup, UpdateType.save);

		// 수정 관련 값 초기화
		setAddGroupName('');

		// 보여지는 값 셋팅, 여기는 code 가 없으므로 무조건 추가.
		setAddGroupList((prevGroupList) => {
			return [...prevGroupList, newGroup];
		})
	}

	const handleChangeAddGroupName = (e) => {
		setAddGroupName(e.target.value);
	}

	const handleClickMyGroupSave = () => {
		userStore.saveMyGroups();
	}

	const groupDetailOpen = Boolean(anchorEl);

	return (
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dataOpenDialog}>
			<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.dialogTop}>
				<Typography>{i18next.t("그룹 설정")}</Typography>
				<IconButton className={classes.iconBtnReset}
					onClick={handleClose}
					disableRipple>
					<DialogClose />
				</IconButton>
			</Box>
			<Box className={classes.dialogContentGroup}>
				<Box >
					<Box>
						<Typography className={classes.groupListText}>{i18next.t("신규 등록 및 이름 변경된 그룹은 학생 등록/편집 화면에서 확인하고 지정할 수 있습니다.")}</Typography>

						{groupConfigLoading ?
							<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: bodyHeight }}>
								<CircularProgress size={50} />
							</Box>
							:
							<>
								{myGroupsForConfig && myGroupsForConfig.length > 0 &&
									myGroupsForConfig.map((group, index) => {
										const deleted = deleteGroupList.some(deleteGroup => deleteGroup.code === group.code);
										return <Box key={index}
											sx={deleted ? { backgroundColor: '#e33131' } : {}}
											className={clsx(classes.folderList, { [classes.folderListTop]: index === 0 })}>
											{editingNameGroup && editingNameGroup.code === group.code ?
												<Box className={classes.folderListBox}>
													<TextField
														id="outlined-basic-group-name"
														variant="outlined"
														placeholder={group.name}
														value={groupEditName}
														className={clsx(classes.groupNameInput)}
														onChange={handleChangeGroupName}
													/>
													<Button className={classes.blackBtn}
														style={{ background: '#2F80ED', marginLeft: "4px", width: 80 }}
														onClick={handleClickSaveGroupName}
														disabled={groupEditName === ''}
														disableRipple>
														<Typography
															style={{ marginLeft: "0px", color: '#fff' }}>{i18next.t("적용")}</Typography>
													</Button>
													<Button className={classes.blackBtn}
														style={{ marginLeft: "4px", width: 80 }}
														onClick={handleClickCancelGroupName}
														disableRipple>
														<Typography style={{ marginLeft: 0 }}>{i18next.t("취소")}</Typography>
													</Button>
												</Box>
												:
												<Box className={classes.folderListBox}>
													<FolderOpenIcon />
													<Typography>{group.name}</Typography>
												</Box>
											}
											<Box className={classes.UsersListBox}>
												<GroupUsersProfiles />
												<Typography
													className={classes.GroupUsersProfilesText}><span>{group.userCount}</span>{i18next.t("명")}</Typography>
												<IconButton className={classes.iconBtnReset} disableRipple
													onClick={(e) => handleClickDetailDot(e, group)}
												>
													<DotsMoreMenu />
												</IconButton>
												<Popover
													id="simple-popper"
													open={groupDetailOpen}
													anchorEl={anchorEl}
													onClose={handleClosePopover}
													className={classes.popoverBox}
												>
													<Box>
														<MenuList>
															<MenuItem disableRipple onClick={handleClickEditName}>
																<NameChange /><Typography>{i18next.t("이름 변경")}</Typography>
															</MenuItem>
														</MenuList>
														<MenuList>
															<MenuItem disableRipple onClick={handleClickGroupDelete}>
																<AccountStop /><Typography
																	className={classes.deleteText}>{i18next.t("그룹 삭제")}</Typography>
															</MenuItem>
														</MenuList>
													</Box>
												</Popover>
											</Box>
										</Box>
									})
								}
								{myGroupsForConfig.length < rowsPerPage &&
									Array.from({ length: rowsPerPage - myGroupsForConfig.length }, (_, index) => (
										<Box id={`group-list-empty-${index}`}
											className={clsx(classes.folderList)}
											style={{ border: 0, height: 31 }}
											key={index}>
										</Box>
									))
								}
							</>
						}
						<Box className={clsx(classes.pageNumber, classes.pageNumberGroup)}>
							<Stack spacing={2}>
								<Pagination
									count={pageCount}
									boundaryCount={2}
									page={page}
									showFirstButton
									showLastButton
									onChange={handleClickPage}
									variant="outlined"
								/>
							</Stack>
						</Box>

						<Box className={clsx(classes.textFieldBox, classes.textFieldBoxGroup)}>
							<TextField
								id="outlined-basic-new-group-name"
								variant="outlined"
								placeholder={i18next.t("새 그룹명 입력")}
								className={clsx(classes.dialogInput, classes.dialogInputSchoolEach)}
								value={addGroupName}
								onChange={handleChangeAddGroupName}
							/>
							<Button className={classes.blackBtn}
								onClick={handleClickAddGroup}
								disabled={addGroupName === ''}
								disableRipple>
								<Typography>{i18next.t("추가")}</Typography>
							</Button>
						</Box>

						<Box className={classes.deleteTextBox} style={{ maxHeight: 100, overflowY: 'auto' }}>
							{addGroupList && addGroupList.length > 0 &&
								addGroupList.map((addGroup, index) => (
									<Box
										id={`add-group-list-${index}`}
										key={index}
										className={`${classes.deleteTextBoxInner} ${classes.deleteTextBoxInnerForGroupAdd}`}>
										<Typography className={classes.textBoxName}>{addGroup.name}</Typography>
										<IconButton className={classes.iconBtnReset}
											onClick={() => handleClickAddedGroupDelete(addGroup)}
											disableRipple>
											<BoardTextDelete />
										</IconButton>
									</Box>
								))
							}
						</Box>

					</Box>
				</Box>


			</Box>





			<Box display='flex' alignItems='center' justifyContent='center' className={classes.dialogBtnGroup}>
				<Button className={classes.dialogBtn}
					onClick={handleClose}
					disableRipple>
					<Typography>{i18next.t("취소")}</Typography>
				</Button>
				<Button className={classes.dialogBtnSave}
					disabled={myGroupsSaveData.length === 0}
					onClick={handleClickMyGroupSave}
					disableRipple>
					<Typography>{i18next.t("저장")}</Typography>
				</Button>
			</Box>

			<Backdrop
				style={{ color: '#fff', zIndex: (theme as Theme).zIndex.modal + 1 }}
				open={backdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			{pageChangeDialog &&
				<MessageDialog
					titleText={i18next.t("변경 사항")}
					onClose={pageChangeDialogClose}
					isConfirm={true}
					children={i18next.t("현재 페이지 내에 수정중인 데이터가 있습니다. 계속 하시겠습니까? 수정 내용은 초기화 됩니다.")}
					onClick={pageChangeDialogConfirm}
					open={pageChangeDialog} />}

		</Dialog>
	);
}

export default observer<typeof GroupDialog>(GroupDialog);
