import {
	Box,
	InputAdornment,
	TextField
} from "@mui/material";
import useEmotionStyles from "../../../../styles/useEmotionStyles";
import { CompareDataStyle } from "../../../../styles/CompareDataStyle";

import clsx from "clsx";

import { CircularProgress } from "@mui/material";

import TextViewer from "../../../../../../components/common/TextViewer";
import { useCurrentOrder } from "../../../../000_Common/001_Functions/useCurrentOrder";
import { useCurrentQuestionHTML } from "../../../../000_Common/001_Functions/useCurrentQuestionHTML";
import { useStores } from "../../../../../../hooks/useStores";
import { IQuestionInfo } from "../../../../../../repositories/model/transfer/IQuestionInfo";
import { IExamInfo } from "../../../../../../repositories/model/transfer/IExamInfo";

type Props = {
	examInfo: IExamInfo;
	questionInfo: IQuestionInfo;
	editMode: boolean;
	answerList: any;
	changeAnswer: any;
	isRecognizedAll: boolean;
}

export const QuestionAndReferenceTabQuestionRow = (props: Props) => {
	const { examInfo, questionInfo, editMode, answerList, changeAnswer } = props;
	const { projectExamStore } = useStores();
	const classes = useEmotionStyles(CompareDataStyle);

	const currentOrder = useCurrentOrder(projectExamStore, examInfo, questionInfo);
	const currentQuestionHTML = useCurrentQuestionHTML(projectExamStore, questionInfo, currentOrder);
	const answerIndex = answerList.findIndex(item => item.projectExamQuestionNum === questionInfo.projectExamQuestionNum
		&& item.projectExamCode === questionInfo.projectExamCode);

	console.log("ANSWER", answerList[answerIndex]?.answer);

	return (
		<>
			{!!currentQuestionHTML && (
				<>
					<div>
						<Box sx={{ marginTop: "10px" }} display='flex' alignItems='center' justifyContent='left'>
							<TextViewer
								currentHTML={currentQuestionHTML}
							/>
						</Box>
					</div>
					<Box className={clsx(classes.dialogInput, classes.dialogInputMulti)} sx={{ marginTop: "20px" }}>
						<TextField
							id={`outlined-basic-group-name-${questionInfo.projectExamCode}-${questionInfo.projectExamQuestionNum}`}
							autoComplete="off"
							variant="outlined"
							multiline
							maxRows={13}
							minRows={12}
							onChange={(e) => changeAnswer(e.target.value, answerIndex)}
							value={(answerIndex > -1 && answerList[answerIndex].answer) ? answerList[answerIndex].answer : ""}
							InputProps={(
								answerList[answerIndex]?.answer === null ||
								answerList[answerIndex]?.answer === undefined
							) ? {
								startAdornment: (
									<InputAdornment position="start">
										<CircularProgress sx={{ marginLeft: "5px", color: '#008c73' }} size={35} />
									</InputAdornment>
								),
								style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } // 직접 input 스타일 적용

							} : null}
							disabled={!editMode}
						/>
					</Box>
					<Box style={{ marginBottom: '10' }} />
				</>
			)}
		</>
	);
}
