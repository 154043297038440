// OS Enum 정의
export enum OperatingSystemInfo {
    Windows = "Windows",
    macOS = "macOS",
    iPad = "iPad",
    iPhone = "iPhone",
    Android = "Android",
    Unknown = "Unknown",
}

// Browser Enum 정의
export enum BrowserInfo {
    Chrome = "Chrome",
    Edge = "Edge",
    Firefox = "Firefox",
    Safari = "Safari",
    Opera = "Opera",
    Unknown = "Unknown",
}

/**
 * OS와 Browser의 버전 정보를 반환합니다.
 *
 * ```typescript
 * const info = getBrowserAndDeviceInfo();
 * console.log(`현재 브라우저: ${info.browser}`);
 * console.log(`현재 Chromium 기반 여부: ${info.isChromium}`);
 * console.log(`현재 OS: ${info.os}`);
 * ```
 * @returns
 */
export function getBrowserAndDeviceInfo(): { isChromium: boolean; os: OperatingSystemInfo; browser: BrowserInfo } {
    const userAgentData = (navigator as any).userAgentData; // 최신 브라우저에서만 제공
    const userAgent = navigator.userAgent;

    let isChromium = false;
    let os = OperatingSystemInfo.Unknown;
    let browser = BrowserInfo.Unknown;

    // OS 확인
    if (userAgentData) {
        const brands = userAgentData.brands || [];
        isChromium = brands.some((brand: any) => /chrome|chromium/i.test(brand.brand));
        const platform = userAgentData.platform || '';

        if (/windows/i.test(platform)) {
            os = OperatingSystemInfo.Windows;
        } else if (/mac/i.test(platform)) {
            os = OperatingSystemInfo.macOS;
        } else if (/ipad/i.test(platform)) {
            os = OperatingSystemInfo.iPad;
        } else if (/iphone/i.test(platform)) {
            os = OperatingSystemInfo.iPhone;
        } else if (/android/i.test(platform)) {
            os = OperatingSystemInfo.Android;
        }
    } else {
        // Fallback for older browsers using userAgent
        isChromium = /chrome|chromium/i.test(userAgent) && !/edg/i.test(userAgent); // Edge는 후속에서 처리
        if (/windows/i.test(userAgent)) {
            os = OperatingSystemInfo.Windows;
        } else if (/macintosh|mac os x/i.test(userAgent)) {
            os = OperatingSystemInfo.macOS;
        } else if (/ipad/i.test(userAgent)) {
            os = OperatingSystemInfo.iPad;
        } else if (/iphone/i.test(userAgent)) {
            os = OperatingSystemInfo.iPhone;
        } else if (/android/i.test(userAgent)) {
            os = OperatingSystemInfo.Android;
        }
    }

    // 브라우저 확인
    if (/edg/i.test(userAgent)) {
        browser = BrowserInfo.Edge;
        isChromium = true; // Chromium 기반의 Edge는 Chromium으로 처리
    } else if (/chrome/i.test(userAgent) && !/edg/i.test(userAgent)) {
        browser = BrowserInfo.Chrome;
        isChromium = true;
    } else if (/firefox/i.test(userAgent)) {
        browser = BrowserInfo.Firefox;
    } else if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent)) {
        browser = BrowserInfo.Safari;
    } else if (/opera|opr/i.test(userAgent)) {
        browser = BrowserInfo.Opera;
        isChromium = true; // Opera도 Chromium 기반
    }

    return { isChromium, os, browser };
}

