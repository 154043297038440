import { DeviceTypeEnum, IPenCommEvent } from "../../../../nl-lib3-common";

export const PenCommEventEnum = {
  PEN_UP: "pen_up",
  PEN_MOVE: "pen_move",
  PEN_MOVE_HOVER: "pen_move_hover",
  PEN_DOWN: "pen_down",

  PAGE_INFO: "page_info",
  PAGE_INFO_HOVER: "page_info_hover",

  PASSWORD_REQUIRED: "pass_required",
  PASSWORD_SET_COMPLETED: "pass_set_completed",
  FIRMWARE_UPDATE_REQUIRED: "firmware_update_needed",

  DISCONNECTED: "disconnected",
  CONNECTED: "connected",
  ON_CONNECTED: "on_connected",
  ON_DISCONNECTED: "on_disconnected",

  CODE_ERROR: "recognize error",
  ERROR: "error",
  NONE: "none",

  PEN_STATUS: "pen_status",

  // 2022/05/10
  ON_SHUTDOWN_EVENT: "on_shutdown_event",

  ON_LOW_BATTERY_EVENT: "on_low_battery_event",

  ONFINISHEDOFFLINEDOWNLOAD: "onFinishedOfflineDownload",
  ONSTARTOFFLINEDOWNLOAD: "onStartOfflineDownload",

  ON_OFFLINE_DATA_PART_DELIVERED: "on_offline_data_part_delivered",
  ON_OFFLINE_DATA_LIST_DELIVERED: "on_offline_data_list_delivered",

  SYSTEM_PERFORMANCE_STATUS: "system_performance_status",

  ON_FIRMWARE_UPGRADE_START: "on_firmware_upgrade_start",
  ON_FIRMWARE_UPGRADE_PROGRESS: "on_firmware_upgrade_progress",
};

/**
 *
 */

// function getTimeTicks() {
//   // 0.1 milliseconds tick
//   const d = new Date();

//   // https://stackoverflow.com/questions/7966559/how-to-convert-javascript-date-object-to-ticks
//   //
//   // The JavaScript Date type's origin is the Unix epoch: midnight on 1 January 1970.
//   // The .NET DateTime type's origin is midnight on 1 January 0001.
//   // var epochTicks = 621355968000000000;
//   // var ticks = d.getTime() * 10000 + epochTicks;
//   const ticks = d.getTime() * 10000;
//   return ticks;
// }


/**
 *
 * @param penType
 * @param eventName
 * @param penCommEvent
 */
export function makePenEvent(
  penType: DeviceTypeEnum,
  eventName: string,
  penCommEvent: IPenCommEvent
): IPenCommEvent {
  if (!penCommEvent || penCommEvent.timeStamp === undefined) {
    console.error("timeStamp has not been provided");
  }

  const { timeStamp } = penCommEvent;
  // if (!timeStamp) timeStamp = getTimeTicks();

  const event: IPenCommEvent = {
    ...penCommEvent,
    timeStamp,
    event: eventName,
    // systemTicks: getTimeTicks(),
    penType,
    isFirstDot: false,
  };

  return event;
}

