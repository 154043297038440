
export function assert(pred, message: string = null) {
  if (!pred) {
    if (message == null)
      throw new Error("assertion failed");
    else
      throw new Error(message);
  }
}

export function neoAllocateUTF8(libmupdf: any, ctx: number, str: string) {
  const size = libmupdf.lengthBytesUTF8(str) + 1;
  const pointer = libmupdf._wasm_malloc(ctx, size);

  libmupdf.stringToUTF8(str, pointer, size);
  return pointer;
}
