import {
	Box, Button
} from "@mui/material";



import i18next from "i18next";
import { NeoCheckBox } from "./CheckBox";

//v3
const AIGLE_SITE_KEY = '6LeXLN4pAAAAAKTr_NM_vt3ZHS93cppVaLxU2IhC';
//v2
// const AIGLE_SITE_KEY = '6Lf1vd8pAAAAAK0iohc-uxVKrnHGc0DUsGqSKb6S';

export function NeoCheckBoxList(props) {
	const { options, isCheckedAll, onCheck, classes } = props;
	const checkBoxOptions = (
		<div className="checkbox-list">
			{options.map((option, index) => {
				return (
					<Box key={index} display="flex" alignItems="center" justifyContent="space-between" className={classes.checkedListBoxIn}>
						<NeoCheckBox
							key={index}
							name={option.name}
							value={option.value}
							tick={option.checked}
							type={option.type}
							onCheck={e => onCheck(option.value, e.target.checked)}
						/>
						<Button
							onClick={() => {
								if (option.name === 'TERMS_OF_SERVICE') {
									return window.open('/termsofuse', '_blank');
								} else if (option.name === 'PRIVACY_POLICY') {
									return window.open('/privacypolicy', '_blank');
								}
							}}
							className={classes.checkedViewButton}
							disableRipple
						>
							{i18next.t('보기')}
						</Button>
					</Box>
				);
			})}
		</div>
	);

	return (
		<Box className="checkbox-list">
			<Box display="flex" alignItems="center" className={classes.allCheckedBox}>
				<NeoCheckBox type="checkbox" name="select-all" value={i18next.t('전체 약관동의')} tick={isCheckedAll} onCheck={e => onCheck('all', e.target.checked)} />
			</Box>
			{checkBoxOptions}
		</Box>
	);
}
