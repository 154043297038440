import React from "react";
import { IProject } from "../../repositories/model/IProject";
import { IExamInfo } from "../../repositories/model/transfer/IExamInfo";
import { NeoStroke } from "../../neolab-libs/nl-lib3-common";
import { useStores } from "../../hooks/useStores";

export const codeToRecognizedTextPromise = {};

export type IOcrResult =
	{
		recognizedText: string,
		ocrStatus: "none" | "requested" | "done" | "error"
	};


type Props = {
	project: IProject;
	examList: IExamInfo[];
	// offlineStrokes: NeoStroke[];
};

function useStrokeRecognition(props: Props) {
	const { neoPenStore, ncodeAllocationStore, navigateStore } = useStores();
	const ps = props;
	const { project, examList } = ps;
	const [codeToOcrResult, setCodeToRecognizedText] = React.useState<{ [questionCode: string]: IOcrResult }>({});

	const [offlineStrokes, setOfflineStrokes] = React.useState<NeoStroke[]>([]);
	const [numRequested, setNumRequested] = React.useState(0);
	const [numDone, setNumDone] = React.useState(0);

	// const isRecognizedAll = Object.keys(codeToRecognizedTextPromise).length !== 0 && Object.keys(codeToRecognizedTextPromise).length === Object.keys(codeToRecognizedText).length;

	// console.log("isRecognizedAll", isRecognizedAll);

	// const recognize = async (code) => {
	// 	try {
	// 		const text = await codeToRecognizedTextPromise[code];
	// 		setCodeToRecognizedText(prevTexts => ({ ...prevTexts, [code]: text }));
	// 	} catch (e) {
	// 		console.error(e);
	// 	}
	// 	finally {
	// 		delete codeToRecognizedTextPromise[code];
	// 	}
	// }

	const putOfflineStrokesToRecognize = (strokes: NeoStroke[]) => {
		setOfflineStrokes(strokes);
	}

	React.useEffect(() => {
		const recognizeStrokes = async (proj: IProject, eaxmArr: IExamInfo[], strokes: NeoStroke[], qInfo: { [key: string]: { page: number, descriptiveCount: number, } }) => {

			if (proj &&
				eaxmArr?.length > 0
				&& eaxmArr[0].projectCode === proj.code
				// && Object.keys(codeToRecognizedTextPromise).length === 0
			) {
				setCodeToRecognizedText({});

				console.log("useStrokeRecognition", proj, eaxmArr, strokes);

				const codeToStrokes = await neoPenStore.groupStrokesByQuestion({ assignmentProject: proj, examList: eaxmArr, wholeStrokes: strokes, questionNameToPageInfo: qInfo });

				if (!codeToStrokes) {
					return;
				}

				console.log("indexToStrokes", codeToStrokes);
				setNumRequested(Object.keys(codeToStrokes).length);
				setNumDone(0);

				for (const code of Object.keys(codeToStrokes)) {
					if (codeToStrokes[code]) {
						console.log(`requestStrokeRecognition ${code}`, codeToStrokes[code]);

						setCodeToRecognizedText(prev => ({
							...prev,
							[code]: { recognizedText: null, ocrStatus: "requested" }
						}));

						codeToRecognizedTextPromise[code] = neoPenStore.requestStrokeRecognition(navigateStore.locale, codeToStrokes[code])
							.then((text) => {
								setCodeToRecognizedText(prev => ({
									...prev,
									[code]: { recognizedText: text, ocrStatus: "done" }
								}));
							})
							.catch((e) => {
								setCodeToRecognizedText(prev => ({
									...prev,
									[code]: { recognizedText: "", ocrStatus: "error" }
								}));
							})
							.finally(() => {
								setNumDone(prevDone => prevDone + 1);
							});
					}
					else {
						setNumDone(prevDone => prevDone + 1);
					}
				}
			}

			// for (const code of Object.keys(codeToRecognizedTextPromise)) {
			// 	try {
			// 		recognize(code);
			// 	} catch (e) {
			// 		console.error(e);
			// 	}
			// }
		}

		// 문제 정보와 해답 영역 정보가 들어오고, offlineStrokes가 들어오면 인식을 시작한다.
		const questionPageInfo = ncodeAllocationStore.questionNameToPageInfo;
		const infoLen = Object.keys(questionPageInfo).length;

		if (infoLen > 0) {
			recognizeStrokes(project, examList, offlineStrokes, questionPageInfo);
		}
	}, [offlineStrokes, ncodeAllocationStore.questionNameToPageInfo, neoPenStore]);

	return { codeToOcrResult, numRequested, numDone, putOfflineStrokesToRecognize };
}

export default useStrokeRecognition;