import { IDot } from "./IDot";
import { DotTypes } from "./DotTypes";



export class NeoDot implements IDot {
  dotType = 0; // - 1:pen-down(first), 2:moving, 3:pen-up(last)

  deltaTime = 0; // - time delta from the beginning of the stroke

  time = 0; // - absolute time (unix timetick)

  f = 0; // - force

  x = 0; // - x

  y = 0; // - y

  /* #region  UWP용 Dot과 같은 형식을 위해 */
  Section = undefined as unknown as number;

  Owner = undefined as unknown as number;

  Note = undefined as unknown as number;   // book

  Page = undefined as unknown as number;   // page


  TiltX?: number;

  TiltY?: number;

  Twist?: number;

  Color?: number;

  Timestamp?: bigint;

  constructor(dot?: IDot) {
    if (dot) {
      const { dotType, deltaTime, time, f, x, y, Section, Owner, Note, Page, TiltX, TiltY, Twist, Color, Timestamp } = dot;

      this.dotType = dotType;
      this.deltaTime = deltaTime;
      this.time = time;
      this.f = f;
      this.x = x;
      this.y = y;

      if (Section) this.Section = Section;
      if (Owner) this.Owner = Owner;
      if (Note) this.Note = Note;
      if (Page) this.Page = Page;
      if (TiltX) this.TiltX = TiltX;
      if (TiltY) this.TiltY = TiltY;
      if (Twist) this.Twist = Twist;
      if (Color) this.Color = Color;
      if (Timestamp) this.Timestamp = Timestamp;
    }
  }

  /* #region  UWP용 Dot과 같은 형식을 위해 */
  public get DotType() {
    return this.dotType;
  }

  public set DotType(val: DotTypes) {
    this.dotType = val;
  }

  public get X() { return this.x }

  public get Y() { return this.y }

  public get Force() { return this.f }

  public clone() {
    return new NeoDot(this);
  }

  /* #endregion */
}

