import i18next from "../lang";
import { ProjectSchoolType } from "../stores/enums/ProjectStoreEnums";

export function getSubjectName(schoolType: ProjectSchoolType, subjectName: string) {
	let schoolTypeIndex = SchoolLevelSubjects.findIndex((subject) => subject.id === schoolType);
	if (schoolTypeIndex < 0) return "";

	let subjectNameIndex = SchoolLevelSubjects[schoolTypeIndex].subjectGroups.findIndex((subject) => subject.name === subjectName);
	if (subjectNameIndex < 0) return "";

	return i18next.t(SchoolLevelSubjects[schoolTypeIndex].subjectGroups[subjectNameIndex].text);
}

export const SchoolLevelSubjects = [
	{
		name: 'ELEM',
		id: ProjectSchoolType.ELEM,
		text: "초등학교",
		subjectGroups: [
			{
				name: 'KOREAN', text: "국어",
				subjects: [
					{ name: 'KOREAN', text: "국어" }
				]
			},
			{
				name: 'ENGLISH', text: "영어",
				subjects: [
					{ name: 'ENGLISH', text: "영어" }
				]
			},
			{
				name: 'MATH',
				text: "수학",
				subjects: [
					{ name: 'MATH', text: "수학" }
				]
			},
			{
				name: 'SOCIAL_STUDIES', text: "사회", subjects: [
					{ name: 'SOCIAL_STUDIES', text: "사회" }
				]
			},
			{
				name: 'SCIENCE', text: "과학", subjects: [
					{ name: 'SCIENCE', text: "과학" }
				]
			},
			{
				name: 'MUSIC', text: "음악", subjects: [
					{ name: 'MUSIC', text: "음악" }
				]
			},
			{
				name: 'ART', text: "미술", subjects: [
					{ name: 'ART', text: "미술" }
				]
			},
			{
				name: 'PHYSICAL_EDUCATION', text: "체육", subjects: [
					{ name: 'PHYSICAL_EDUCATION', text: "체육" }
				]
			}
		]
	},

	{
		name: 'MIDD',
		id: ProjectSchoolType.MIDD,
		text: "중학교",
		subjectGroups: [
			{
				name: 'KOREAN', text: "국어", subjects: [
					{ name: 'KOREAN', text: "국어" }
				]
			},
			{
				name: 'ENGLISH', text: "영어", subjects: [
					{ name: 'ENGLISH', text: "영어" }
				]
			},
			{
				name: 'MATH', text: "수학", subjects: [
					{ name: 'MATH', text: "수학" }
				]
			},
			{
				name: 'SOCIAL_STUDIES', text: "사회", subjects: [
					{ name: 'SOCIAL_STUDIES', text: "사회" }
				]
			},
			{
				name: 'SCIENCE', text: "과학", subjects: [
					{ name: 'SCIENCE', text: "과학" }
				]
			},
			{
				name: 'HISTORY', text: "역사", subjects: [
					{ name: 'HISTORY', text: "역사" }
				]
			},
			{
				name: 'ETHICS', text: "도덕", subjects: [
					{ name: 'ETHICS', text: "도덕" }
				]
			},
			{
				name: 'SOCIAL_SCIENCE_BRANCH', text: "사회과 부도", subjects: [
					{ name: 'SOCIAL_SCIENCE_BRANCH', text: "사회과 부도" }
				]
			},
			{
				name: 'HISTORY_BRANCH', text: "역사 부도", subjects: [
					{ name: 'HISTORY_BRANCH', text: "역사 부도" }
				]
			},
			{
				name: 'ENVIRONMENT', text: "환경", subjects: [
					{ name: 'ENVIRONMENT', text: "환경" }
				]
			},
			{
				name: 'TECHNOLOGY_AND_HOME_ECONOMICS', text: "기술·가정", subjects: [
					{ name: 'TECHNOLOGY_AND_HOME_ECONOMICS', text: "기술·가정" }
				]
			},
			{
				name: 'MUSIC', text: "음악", subjects: [
					{ name: 'MUSIC', text: "음악" }
				]
			},
			{
				name: 'ART', text: "미술", subjects: [
					{ name: 'ART', text: "미술" }
				]
			},
			{
				name: 'PHYSICAL_EDUCATION', text: "체육", subjects: [
					{ name: 'PHYSICAL_EDUCATION', text: "체육" }
				]
			},
			{
				name: 'INFORMATION', text: "정보", subjects: [
					{ name: 'INFORMATION', text: "정보" }
				]
			},
			{
				name: 'CHINESE_CHARACTERS', text: "한문", subjects: [
					{ name: 'CHINESE_CHARACTERS', text: "한문" }
				]
			},
			{
				name: 'HEALTH', text: "보건", subjects: [
					{ name: 'HEALTH', text: "보건" }
				]
			},
			{
				name: 'CAREER_AND_OCCUPATION', text: "진로와 직업", subjects: [
					{ name: 'CAREER_AND_OCCUPATION', text: "진로와 직업" }
				]
			},
			{
				name: 'DAILY_CHINESE', text: "생활 중국어", subjects: [
					{ name: 'DAILY_CHINESE', text: "생활 중국어" }
				]
			},
			{
				name: 'DAILY_JAPANESE', text: "생활 일본어", subjects: [
					{ name: 'DAILY_JAPANESE', text: "생활 일본어" }
				]
			}
		]
	},

	{
		name: 'HIGH',
		id: ProjectSchoolType.HIGH,
		text: "고등학교",
		subjectGroups: [
			{
				name: 'KOREAN', text: "국어", subjects: [
					{ name: 'COMMON_KOREAN_1', text: "공통국어1" },
					{ name: 'COMMON_KOREAN_2', text: "공통국어2" },
					{ name: 'RHETORIC_AND_LANGUAGE', text: "화법과 언어" },
					{ name: 'READING_AND_WRITING', text: "독서와 작문" },
					{ name: 'LITERATURE', text: "문학" },
					{ name: 'THEME_EXPLORATION_READING', text: "주제 탐구 독서" },
					{ name: 'LITERATURE_AND_VISUALS', text: "문학과 영상" },
					{ name: 'JOB_COMMUNICATION', text: "직무 의사소통" },
					{ name: 'LITERARY_APPRECIATION_AND_CRITICISM', text: "문학 감상과 비평" },
					{ name: 'READING_DISCUSSION_AND_WRITING', text: "독서 토론과 글쓰기" },
					{ name: 'MEDIA_COMMUNICATION', text: "매체 의사소통" },
					{ name: 'LANGUAGE_LIFE_INVESTIGATION', text: "언어생활 탐구" }
				]
			},
			{
				name: 'MATH', text: "수학", subjects: [
					{ name: 'COMMON_MATH_1', text: "공통수학1" },
					{ name: 'COMMON_MATH_2', text: "공통수학2" },
					{ name: 'BASIC_MATH_1', text: "기본수학1" },
					{ name: 'BASIC_MATH_2', text: "기본수학2" },
					{ name: 'ALGEBRA', text: "대수" },
					{ name: 'CALCULUS_1', text: "미적분1" },
					{ name: 'CALCULUS_2', text: "미적분2" },
					{ name: 'PROBABILITY_AND_STATISTICS', text: "확률과 통계" },
					{ name: 'GEOMETRY', text: "기하" },
					{ name: 'ECONOMIC_MATHEMATICS', text: "경제 수학" },
					{ name: 'AI_MATHEMATICS', text: "인공지능 수학" },
					{ name: 'JOB_MATHEMATICS', text: "직무 수학" },
					{ name: 'SPECIALIZED_MATH', text: "전문 수학" },
					{ name: 'MATH_AND_CULTURE', text: "수학과 문화" },
					{ name: 'PRACTICAL_STATISTICS', text: "실용 통계" }
				]
			},
			{
				name: 'ENGLISH', text: "영어", subjects: [
					{ name: 'COMMON_ENGLISH_1', text: "공통영어1" },
					{ name: 'COMMON_ENGLISH_2', text: "공통영어2" },
					{ name: 'BASIC_ENGLISH_1', text: "기본영어1" },
					{ name: 'BASIC_ENGLISH_2', text: "기본영어2" },
					{ name: 'ENGLISH_1', text: "영어1" },
					{ name: 'ENGLISH_2', text: "영어2" },
					{ name: 'ENGLISH_READING_AND_WRITING', text: "영어 독해와 작문" },
					{ name: 'READING_AMERICAN_LITERATURE', text: "영미 문학 읽기" },
					{ name: 'ENGLISH_SPEAKING_AND_DEBATE', text: "영어 발표와 토론" },
					{ name: 'ADVANCED_ENGLISH_READING_AND_WRITING', text: "심화 영어 독해와 작문" },
					{ name: 'ADVANCED_ENGLISH_JOB_ENGLISH', text: "심화 영어 직무 영어" },
					{ name: 'PRACTICAL_ENGLISH_CONVERSATION', text: "실생활 영어 회화" },
					{ name: 'MEDIA_ENGLISH', text: "미디어 영어" },
					{ name: 'WORLD_CULTURE_AND_ENGLISH', text: "세계 문화와 영어" }
				]
			},
			{
				name: 'SOCIAL_STUDIES', text: "사회", subjects: [
					{ name: 'KOREAN_HISTORY_1', text: "한국사1" },
					{ name: 'KOREAN_HISTORY_2', text: "한국사2" },
					{ name: 'INTEGRATED_SOCIAL_STUDIES_1', text: "통합사회1" },
					{ name: 'INTEGRATED_SOCIAL_STUDIES_2', text: "통합사회2" },
					{ name: 'WORLD_CITIZENSHIP_AND_GEOGRAPHY', text: "세계시민과 지리" },
					{ name: 'WORLD_HISTORY', text: "세계사" },
					{ name: 'SOCIAL_STUDIES_AND_CULTURE', text: "사회와 문화" },
					{ name: 'MODERN_SOCIETY_AND_ETHICS', text: "현대사회와 윤리" },
					{ name: 'GEOGRAPHY_FAULT', text: "지리 부도" },
					{ name: 'HISTORY_FAULT', text: "역사 부도" },
					{ name: 'EXPLORING_KOREAN_GEOGRAPHY', text: "한국지리 탐구" },
					{ name: 'EXPLORING_THE_FUTURE_OF_CITIES', text: "도시의 미래 탐구" },
					{ name: 'JOURNEY_OF_EAST_ASIAN_HISTORY', text: "동아시아 역사 기행" },
					{ name: 'POLITICS', text: "정치" },
					{ name: 'LAW_AND_SOCIETY', text: "법과 사회" },
					{ name: 'ECONOMICS', text: "경제" },
					{ name: 'ETHICS_AND_THOUGHT', text: "윤리와 사상" },
					{ name: 'HUMANITIES_AND_ETHICS', text: "인문학과 윤리" },
					{ name: 'TRAVEL_GEOGRAPHY', text: "여행지리" },
					{ name: 'EXPLORING_THE_MODERN_WORLD_THROUGH_HISTORY', text: "역사로 탐구하는 현대 세계" },
					{ name: 'SOCIAL_PROBLEMS_EXPLORATION', text: "사회문제 탐구" },
					{ name: 'FINANCE_AND_ECONOMIC_LIFE', text: "금융과 경제생활" },
					{ name: 'ETHICAL_ISSUES_EXPLORATION', text: "윤리문제 탐구" },
					{ name: 'CLIMATE_CHANGE_AND_SUSTAINABLE_WORLD', text: "기후변화와 지속가능한 세계" }
				]
			},
			{
				name: 'SCIENCE', text: "과학", subjects: [
					{ name: 'INTEGRATED_SCIENCE_1', text: "통합과학1" },
					{ name: 'INTEGRATED_SCIENCE_2', text: "통합과학2" },
					{ name: 'SCIENCE_INVESTIGATION_EXPERIMENT_1', text: "과학탐구실험1" },
					{ name: 'SCIENCE_INVESTIGATION_EXPERIMENT_2', text: "과학탐구실험2" },
					{ name: 'PHYSICS', text: "물리학" },
					{ name: 'CHEMISTRY', text: "화학" },
					{ name: 'LIFE_SCIENCE', text: "생명과학" },
					{ name: 'EARTH_SCIENCE', text: "지구과학" },
					{ name: 'DYNAMICS_AND_ENERGY', text: "역학과 에너지" },
					{ name: 'ELECTROMAGNETISM_AND_QUANTUM', text: "전자기와 양자" },
					{ name: 'MATTER_AND_ENERGY', text: "물질과 에너지" },
					{ name: 'WORLD_OF_CHEMICAL_REACTIONS', text: "화학 반응의 세계" },
					{ name: 'CELL_AND_METABOLISM', text: "세포와 물질대사" },
					{ name: 'GENETICS_OF_LIFE', text: "생물의 유전" },
					{ name: 'EARTH_SYSTEM_SCIENCE', text: "지구시스템과학" },
					{ name: 'PLANETARY_AND_COSMIC_SCIENCE', text: "행성우주과학" },
					{ name: 'HISTORY_AND_CULTURE_OF_SCIENCE', text: "과학의 역사와 문화" },
					{ name: 'CLIMATE_CHANGE_AND_ENVIRONMENTAL_ECOLOGY', text: "기후변화와 환경생태" },
					{ name: 'CONVERGENT_SCIENCE_INVESTIGATION', text: "융합과학 탐구" }
				]
			},
			{
				name: 'PHYSICAL_EDUCATION', text: "체육", subjects: [
					{ name: 'PHYSICAL_EDUCATION_1', text: "체육1" },
					{ name: 'PHYSICAL_EDUCATION_2', text: "체육2" },
					{ name: 'EXERCISE_AND_HEALTH', text: "운동과 건강" },
					{ name: 'SPORTS_CULTURE', text: "스포츠 문화" },
					{ name: 'SPORTS_SCIENCE', text: "스포츠 과학" },
					{ name: 'SPORTS_LIFE_1', text: "스포츠 생활1" },
					{ name: 'SPORTS_LIFE_2', text: "스포츠 생활2" }
				]
			},
			{
				name: 'ARTS', text: "예술", subjects: [
					{ name: 'MUSIC', text: "음악" },
					{ name: 'MUSIC_PERFORMANCE_AND_CREATION', text: "음악 연주와 창작" },
					{ name: 'MUSIC_APPRECIATION_AND_CRITICISM', text: "음악 감상과 비평" },
					{ name: 'MUSIC_AND_MEDIA', text: "음악과 미디어" },
					{ name: 'VISUAL_ARTS', text: "미술" },
					{ name: 'ART_CREATION', text: "미술 창작" },
					{ name: 'ART_APPRECIATION_AND_CRITICISM', text: "미술 감상과 비평" },
					{ name: 'ART_AND_MEDIA', text: "미술과 매체" }
				]
			},
			{
				name: 'TECHNOLOGY_AND_HOME_ECONOMICS_INFORMATION', text: "기술가정/정보", subjects: [
					{ name: 'TECHNOLOGY_AND_HOME_ECONOMICS', text: "기술가정" },
					{ name: 'ROBOTICS_AND_ENGINEERING_WORLD', text: "로봇과 공학세계" },
					{ name: 'LIFE_SCIENCE_INVESTIGATION', text: "생활과학 탐구" },
					{ name: 'CREATIVE_ENGINEERING_DESIGN', text: "창의 공학 설계" },
					{ name: 'INTELLECTUAL_PROPERTY_GENERAL', text: "지식 재산 일반" },
					{ name: 'LIFE_DESIGN_AND_INDEPENDENCE', text: "생애 설계와 자립" },
					{ name: 'CHILD_DEVELOPMENT_AND_PARENTS', text: "아동발달과 부모" },
					{ name: 'INFORMATION', text: "정보" },
					{ name: 'BASIC_OF_ARTIFICIAL_INTELLIGENCE', text: "인공지능 기초" },
					{ name: 'DATA_SCIENCE', text: "데이터 과학" },
					{ name: 'SOFTWARE_AND_LIFE', text: "소프트웨어와 생활" }
				]
			},
			{
				name: 'SECOND_FOREIGN_LANGUAGE_CHINESE_CHARACTERS', text: "제2외국어/한문", subjects: [
					{ name: 'CHINESE', text: "중국어" },
					{ name: 'JAPANESE', text: "일본어" },
					{ name: 'CHINESE_CONVERSATION', text: "중국어 회화" },
					{ name: 'ADVANCED_CHINESE', text: "심화 중국어" },
					{ name: 'JAPANESE_CONVERSATION', text: "일본어 회화" },
					{ name: 'ADVANCED_JAPANESE', text: "심화 일본어" },
					{ name: 'CHINESE_CULTURE', text: "중국 문화" },
					{ name: 'JAPANESE_CULTURE', text: "일본 문화" },
					{ name: 'CHINESE_CLASSICS_READING', text: "한문 고전 읽기" },
					{ name: 'LANGUAGE_LIFE_AND_KANJI', text: "언어생활과 한자" }
				]
			},
			{
				name: 'LIBERAL_ARTS', text: "교양", subjects: [
					{ name: 'CAREER_AND_OCCUPATION', text: "진로와 직업" },
					{ name: 'ECOLOGY_AND_ENVIRONMENT', text: "생태와 환경" },
					{ name: 'HUMAN_AND_PHILOSOPHY', text: "인간과 철학" },
					{ name: 'LOGIC_AND_THINKING', text: "논리와 사고" },
					{ name: 'HUMAN_AND_PSYCHOLOGY', text: "인간과 심리" },
					{ name: 'UNDERSTANDING_OF_EDUCATION', text: "교육의 이해" },
					{ name: 'HEALTH', text: "보건" },
					{ name: 'HUMAN_AND_ECONOMIC_ACTIVITY', text: "인간과 경제활동" },
					{ name: 'ESSAY', text: "논술" }
				]
			},
			{
				name: 'PROFESSIONAL_COURSES', text: "전문 교과", subjects: [
					{ name: 'SUCCESSFUL_CAREER_LIFE', text: "성공적인 직업 생활" },
					{ name: 'LABOR_POPULATION_AND_INDUSTRIAL_SAFETY_AND_HEALTH', text: "노동 인권과 산업 안전 보건" },
					{ name: 'DIGITAL_AND_PROFESSIONAL_LIFE', text: "디지털과 직업 생활" },
					{ name: 'COMMERCIAL_ECONOMICS', text: "상업 경제" },
					{ name: 'PRINCIPLES_OF_ACCOUNTING', text: "회계 원리" },
					{ name: 'COMPUTER_SYSTEM_GENERAL', text: "컴퓨터 시스템 일반" }
				]
			}
		]
	},


	{
		name: 'UNGR',
		id: ProjectSchoolType.NOGRADE,
		text: "초등학교",
		subjectGroups: [
			// {
			// 	name: 'JAPANESE', text: "国語",
			// 	subjects: [
			// 		{
			// 			name: 'JAPANESE', text: "国語",

			// 		}
			// 	]
			// },
			{
				name: 'NATIONAL_LANGUAGE', text: "국어",
				subjects: [
					{
						name: 'NATIONAL_LANGUAGE', text: "국어",
					}
				]
			},
			// {
			// 	name: 'MATH', text: "수학", subjects: [
			// 		{ name: 'MATH', text: "수학" }
			// 	]
			// },
			// {
			// 	name: 'ENGLISH', text: "영어", subjects: [
			// 		{ name: 'ENGLISH', text: "영어" }
			// 	]
			// },
			{
				name: 'SOCIAL_STUDIES', text: "사회", subjects: [
					{ name: 'SOCIAL_STUDIES', text: "사회" }
				]
			},
			{
				name: 'SCIENCE', text: "과학", subjects: [
					{ name: 'SCIENCE', text: "과학" }
				]
			},
			// {
			// 	name: 'MUSIC', text: "음악", subjects: [
			// 		{ name: 'MUSIC', text: "음악" }
			// 	]
			// },
			// {
			// 	name: 'ART', text: "미술", subjects: [
			// 		{ name: 'ART', text: "미술" }
			// 	]
			// },
			// {
			// 	name: 'PHYSICAL_EDUCATION', text: "체육", subjects: [
			// 		{ name: 'PHYSICAL_EDUCATION', text: "체육" }
			// 	]
			// }
		]
	},

];

export const selectedSchoolLevelIdx = (name) => SchoolLevelSubjects.findIndex(level => level.name === name);

export const selectedSubjectGroupIdx = (selectedSchoolLevelObj, name) => selectedSchoolLevelObj.subjectGroups.findIndex(level => level.name === name);

export const selectedSubjectIdx = (selectedSubjectGroupObj, name) => selectedSubjectGroupObj.subjects.findIndex(level => level.name === name);
