import {
	Box,
	TextField,
	Typography
} from "@mui/material";
import TextViewer from "../../../../components/common/TextViewer";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TemplateProjectViewStyle } from "../../styles/TemplateProjectViewStyle";

import React from "react";
import { useTranslation } from 'react-i18next';
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";

type Props = {
	questionInfo: IQuestionInfo,
}

export const AnswerTabQuestionAnswerRow = (props: Props) => {
	const i18next = useTranslation();

	const { questionInfo } = props;
	const classes = useEmotionStyles( TemplateProjectViewStyle );

	const getCurrentKeyword = (key: number) => {
		const keywordObj = questionInfo.keywordArr.find(c => c.key === key);
		if (!keywordObj) return "";

		return keywordObj.keyword;
	};

	const isCorrectedKeywordEmpty = React.useMemo(() => {
		return questionInfo.keywordArr.length === 0
			|| (questionInfo.keywordArr.length === 1 && !questionInfo.keywordArr[0].keyword)
	}, [questionInfo.keywordArr]);

	return (
		<>
			<Box display='flex' alignItems='center' justifyContent='left' className={classes.lightBorderAnswerBox}>
				<TextViewer
					currentHTML={questionInfo.correctedAnswer}
					height={300}
				/>
			</Box>
			{!isCorrectedKeywordEmpty &&
				<Typography className={classes.essential}>{i18next.t('답변 필수 문장')}</Typography>
			}

			{!isCorrectedKeywordEmpty &&
				questionInfo.keywordArr.map((keyword, idx) => {
					return (
						<Box key={idx} display='flex' alignItems='center' justifyContent='left' className={classes.dialogInputN}>
							<TextField id={`keyword-${idx}`}
								variant="outlined"
								multiline
								minRows={1}
								value={getCurrentKeyword(keyword.key)}
								disabled={true}
								placeholder="" />
						</Box>
					)
				})}

			<Box sx={{ marginBottom: "25px" }} />
		</>
	)
}
