import {
	Box,
	Button,
	Dialog,
	Typography
} from "@mui/material";
import { observer } from "mobx-react";
import * as React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as SmartPenClose } from '../../../../../../common/images/SmartPenClose.svg';
import { useStores } from "../../../../../../hooks/useStores";
import { IPageSOBP } from "../../../../../../neolab-libs/nl-lib3-common";
import { IProject } from "../../../../../../repositories/model/IProject";
import { IProjectSubmissionTransfer } from "../../../../../../repositories/model/transfer/IProjectSubmissionTransfer";
import useEmotionStyles from "../../../../styles/useEmotionStyles";
import { OfflineDataUploadDialogStlye } from "./OfflineDataUploadDialogStlye";

const RequestOfflineDataState = {
	disconnected: "disconnected",
	connecting: "connecting",
	connected: "connected",
	requested: "requested",
	received: "received",
}

type Props = {
	project: IProject,
	submissionTransfer: IProjectSubmissionTransfer & { submissionIndex: number },
	onClose: () => void,
}

function NcodeIsNotAllocationDialog(props: Props) {
	const i18next = useTranslation();
	const { neoPenStore, ncodeAllocationStore } = useStores();
	const classes = useEmotionStyles(OfflineDataUploadDialogStlye);

	// const buttonRef = React.useRef(null);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const openTool = Boolean(anchorEl);
	const id = openTool ? 'simple-popover' : undefined;

	const { submissionTransfer, project } = props;

	// const projectCode = project.code;
	// const templateProjectCode = project.templateCode;

	const { userProfile } = submissionTransfer;
	const { name } = userProfile;

	// const handleClickTooltipClosedBtn = () => {
	// 	neoPenStore.isSamrtPenPopupClosed = true;
	// 	setAnchorEl(null);
	// };

	const [open, setOpen] = React.useState(true);
	const [requestOfflineDataState, setRequestOfflineDataState] = React.useState(RequestOfflineDataState.disconnected);

	const handleClickCloseDialog = () => {
		if (requestOfflineDataState !== RequestOfflineDataState.received) {
			props.onClose();

			return;
		}

		setOpen(false);
	}

	// const handleClickOpen = () => {
	// 	setOpen(true);
	// };

	const handleClose = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement> | React.KeyboardEvent<HTMLDivElement>,
		reason?: 'backdropClick' | 'escapeKeyDown'
	) => {
		if (reason && reason === "backdropClick")
			return;

		setOpen(false);
	};

	const getNcodeRangeFromProject = async (projectCode: string, templateProjectCode: string) => {
		const alloc = await ncodeAllocationStore.getProjectLastAllocation(projectCode, templateProjectCode);
		const range: { start: IPageSOBP, end: IPageSOBP } = {
			start: {
				section: alloc.section,
				owner: alloc.startOwner,
				book: alloc.startBook,
				page: alloc.startPage
			},
			end: {
				section: alloc.section,
				owner: alloc.endOwner,
				book: alloc.endBook,
				page: alloc.endPage
			}
		};
		return range;
	}

	return (
		<React.Fragment>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				className={classes.dialogBox}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						padding: '25px',
						'&:before': { content: "''", width: '24px', height: '24px' }
					}}
				>
					<DialogTitle sx={{ m: 0, padding: 0 }} id="customized-dialog-title" className={classes.titleDialog}>
						<b>
							{i18next.t("스마트 페이퍼 인쇄 후 사용")}
						</b>
					</DialogTitle>
					<IconButton className={clsx(classes.iconBtnReset)} onClick={handleClose} disableRipple>
						<SmartPenClose />
					</IconButton>
				</Box>

				<Typography className={classes.studentInfo}>
					<Trans i18nKey="{{name}} 학생에게 제시한 {{projectName}}의 데이터를 업로드 하기 위해서는 스마트 페이퍼 출력이 필요합니다." projectName={project.name}>
						<span>{name}</span>학생에게 제시한<br />
						<span>{project.name}</span>의 <br />
						데이터를 업로드 하기 위해서는<br />
						1) 스마트 페이퍼를 먼저 출력하고 <br />
						2) 펜으로 필기한 다음 데이터를 업로드애햐 합니다.
					</Trans>
				</Typography>

				{/*펜 연결 중 취소및 다른학생 선택*/}
				<Button
					className={classes.confirmBtn}
					onClick={() => handleClickCloseDialog()}
					disableRipple
				>
					<Typography>
						{i18next.t("확인")}
					</Typography>
				</Button>
			</Dialog>
		</React.Fragment>
	);
}

export default observer<typeof NcodeIsNotAllocationDialog>(NcodeIsNotAllocationDialog);