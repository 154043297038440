import { Cmd } from "./pencomm_cmd";

export class Packet {
  private buf: Uint8Array;

  public Data: Uint8Array;


  private ptr = 0;

  // private cmd: number;

  public result_code: number;

  private length: number;

  constructor(buf: Uint8Array) {
    this.buf = new Uint8Array(buf);
    const cmd = buf[1];

    // RESPONSE에는 길이 이전에 result code가 나온다
    const cmdstr = Cmd[cmd];
    const result_size = (
      // eslint-disable-next-line no-bitwise
      ((cmd >> 4) !== 0x6 && cmd !== Cmd.PDS_COMMAND_EVENT)
      && (cmdstr && cmdstr.endsWith("RESPONSE"))
    ) ? 1 : 0;

    if (result_size) {
      // eslint-disable-next-line prefer-destructuring
      this.result_code = buf[2];
    } else {
      this.result_code = -1;
    }

    // this.cmd = cmd;
    this.length = buf[result_size + 2] + buf[result_size + 3] * 256;
    this.Data = this.buf.subarray(result_size + 4, buf.length - 1);
  }

  private getNumber(len: number) {
    let ret = 0;
    for (let i = len - 1; i >= 0; --i) {
      ret *= 256;
      ret += this.Data[this.ptr++];
    }

    return ret;
  }

  public GetLong() {
    const bytes = this.GetBytes(8);
    let size = 8;
    let pos = 0;

    let value = 0;
    let first = true;
    while (size--) {
      if (first) {
        const byte = bytes[pos++];
        // eslint-disable-next-line no-bitwise
        value += byte & 0x7f;
        // eslint-disable-next-line no-bitwise
        if (byte & 0x80) {
          value -= 0x80;
          // Treat most-significant bit as -2^i instead of 2^i
        }
        first = false;
      } else {
        value *= 256;
        value += bytes[pos++];
      }
    }
    return value;
  }

  public GetUlong() {
    return this.getNumber(8);
  }

  public GetInt() {
    const bytes = this.GetBytes(4);
    const val = new Int32Array(bytes.buffer)[0];
    return val;
  }

  public GetUInt() {
    const bytes = this.GetBytes(4);
    const val = new Uint32Array(bytes.buffer)[0];
    return val;
  }


  public GetShort() {
    const bytes = this.GetBytes(2);
    const val = new Int16Array(bytes.buffer)[0];
    return val;
  }

  public GetUshort() {
    const bytes = this.GetBytes(2);
    const val = new Uint16Array(bytes.buffer)[0];
    return val;
  }


  public GetByte() {
    return this.getNumber(1);
  }

  public GetBytes(len?: number) {
    const ret: number[] = [];

    if (len) {
      for (let i = 0; i < len; i++) {
        ret.push(this.Data[this.ptr++]);
      }
    } else {
      for (let i = this.ptr; i < this.Data.length; i++) {
        ret.push(this.Data[this.ptr++]);
      }
    }

    return new Uint8Array(ret);
  }

  public GetString(len: number) {
    const ret: number[] = [];

    for (let i = 0; i < len; i++) {
      ret.push(this.Data[this.ptr++]);
    }
    ret.push(0);

    const str = new TextDecoder("utf-8").decode(new Uint8Array(ret));
    return str;
  }

  public GetByteToInt() {
    // eslint-disable-next-line no-bitwise
    return (this.GetByte() & 0xFF);
  }
}
