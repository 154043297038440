import {
	Box,
	Button,
	Dialog,
	IconButton,
	Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { ReactComponent as DialogClose } from '../../common/images/DialogClose.svg';
import { useStores } from "../../hooks/useStores";
import i18next from "../../lang";
import { CommonDialogStyle } from "./styles/CommonDialogStyle";
import useEmotionStyles from "../main/styles/useEmotionStyles";

type Props = {
	open?: boolean;
	titleText?: string;
	submitText?: string;
	cancelText?: string;
	onClick?: Function;
	onClose?: Function;
	isConfirm?: boolean;
	children: React.ReactNode;
};


function MessageDialog(props: Props) {
	const classes = useEmotionStyles( CommonDialogStyle );
	const {
		open = false,
		titleText,
		submitText,
		cancelText,
		onClick,
		onClose,
		isConfirm = false,
		children,
	} = props;

	const { messageStore } = useStores();

	const titleExist = (titleText !== "") && (titleText !== undefined);

	React.useEffect(() => {
		return () => {
			// 왜 이렇게 해 뒀지?
			// messageStore.init();
		};
	}, []);

	const handleClose = () => {
		if (onClose) {
			onClose();
		}

		messageStore.init();
	};

	const handleClick = () => {
		if (onClick) {
			onClick();
		}

		messageStore.init();
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			handleClick();
		}

		if (e.key === "Escape") {
			e.preventDefault();
			handleClose();
		}
	};

	return (
		<Dialog open={open} className={classes.dialogMain} onKeyDown={handleKeyDown}>
			{titleExist ?
				<Box className={classes.dialogTop}>
					<Typography>{titleText}</Typography>
					<Box display="flex" alignItems="center">
						<IconButton className={classes.iconBtnReset} disableRipple onClick={handleClose}>
							<DialogClose />
						</IconButton>
					</Box>
				</Box>
				:
				<Box className={classes.dialogTopNoTitle}>
					<IconButton className={classes.iconBtnResetNoTitle} onClick={handleClose} disableRipple>
						<DialogClose />
					</IconButton>
				</Box>
			}
			<Box className={classes.dialogContent}>
				<div className={classes.dialogContentText}>
					{children}
				</div>
			</Box>
			<Box display='flex' alignItems='center' justifyContent='center' className={classes.dialogBtnGroup}>
				{isConfirm &&
					<Button className={classes.dialogBtn} disableRipple onClick={handleClose}>
						<Typography>{cancelText ? cancelText : i18next.t('취소')}</Typography>
						{/*<Typography>취소</Typography>*/}
					</Button>
				}

				<Button className={classes.dialogBtnSave} disableRipple onClick={handleClick}>
					<Typography>{submitText ? submitText : i18next.t('확인')}</Typography>
					{/*<Typography>AI채점하기</Typography>*/}
				</Button>
			</Box>
		</Dialog>
	);
}

export default observer<typeof MessageDialog>(MessageDialog);
