import {
	Box,
	Breadcrumbs,
	Button,
	IconButton,
	Popover,
	Typography
} from "@mui/material";

import { TopBarStyle } from "./styles/TopBarStyle";

import Toolbar from '@mui/material/Toolbar';
import clsx from "clsx";
import { ReactComponent as BreadcrumbsIcon } from '../../common/images/BreadcrumbsIcon.svg';
import { ReactComponent as HelpIcon } from '../../common/images/HelpIcon.svg';
import { ReactComponent as NoticeIcon } from '../../common/images/NoticeIcon.svg';
import { ReactComponent as ProfileIcon } from '../../common/images/ProfileIcon.svg';

import { ReactComponent as LanguageIcon } from '../../common/images/LanguageSelect.svg';



import { styled } from '@mui/system';
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";
import useNaviCustom from "../../hooks/useNaviCustom";

import { useStores } from "../../hooks/useStores";
import { UserType } from "../../repositories/model/support/UserType";
import { menuPath } from "./MenuPath";
import useEmotionStyles from "../main/styles/useEmotionStyles";

const TopBarBlock = styled(Toolbar)({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
});

type Props = {
	leftItem: () => JSX.Element,
	rightItem: () => JSX.Element,
	includeDetail?: boolean,
}

export function TopBarComponent(props: Props) {
	const params = useParams();
	const classes = useEmotionStyles(TopBarStyle);
	const { leftItem, rightItem } = props;
	const { navigateStore, authStore, projectStore } = useStores();

	// const { sideBarOpen } = navigateStore;

	const { loginUser } = authStore;
	const { project } = projectStore;

	const projectCode = params?.projectCode;
	const submissionCode = params?.submissionCode;
	// const projectName = project.name ? project.name : "{과제명}";
	// const submissionName = projectName + '평가 결과';

	// const [open, setOpen] = React.useState(false);
	// const [showIcon, setShowIcon] = React.useState(true);
	// const [isTestIcon, setIsTestIcon] = React.useState(true);
	const [breadcrumbs, setBreadcrumbs] = React.useState([]);

	const [jsonPaths, setJsonPaths] = React.useState({});
	const [currentPaths, setcurrentPaths] = React.useState([]);
	const [mainText, setMainText] = React.useState('');
	const [projectName, setProjectName] = React.useState('{projectCode}');
	const [submissionName, setSubmissionName] = React.useState('{submissionCode}');

	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleClick = (event) => { setAnchorEl(event.currentTarget); };
	const handleClose = () => { setAnchorEl(null); };
	const openProfile = Boolean(anchorEl);


	const [anchorLangEl, setAnchorLangEl] = React.useState(null);
	const handleLangClick = (event) => { setAnchorLangEl(event.currentTarget); };
	const handleLangClose = () => { setAnchorLangEl(null); };
	const openLang = Boolean(anchorLangEl);
	const [lang, setLang] = React.useState(navigateStore.language);
	const handleLanguageSelect = (code) => {
		navigateStore.changeLanguage(code);
		setLang(code);
		handleLangClose();
	}

	const handleSelectMode = (mode: boolean) => {
		if (navigateStore.language === "ko")
			navigateStore.changeSimpleMode(mode);
		else
			navigateStore.changeSimpleMode(true);
	}

	const languages = [
		{ code: 'ko', name: '한국어' },
		{ code: 'ja', name: '日本語' },
		{ code: 'en', name: 'English' }
	];

	// let filteringPaths = [];
	const [filteringPaths, setFilteringPaths] = React.useState<{
		id: string,
		text: string,
		isClick: boolean,
		i18n: string,
	}[]>([]);
	// let mainText = '';

	const id = openProfile ? 'simple-popover' : undefined;
	const idLang = openLang ? 'simple-popover2' : undefined;
	const { pathname } = useLocation();

	const naviCustom = useNaviCustom();

	const i18next = useTranslation();

	React.useEffect(() => {
		if (project.name) {
			// console.log("?. project.name : ", project.name);
			setProjectName(project.name);
			setSubmissionName(project.name + i18next.t(" : 제출물"));
		}
	}, [project.name])

	// 1. json load. -> 이론상 최초 1회
	React.useEffect(() => {
		// fetch('/path.json')
		//     .then(response => response.json())
		//     .then(data => setJsonPaths(data))
		//     .catch(error => console.error('Error fetching data:', error));
		setJsonPaths(menuPath);
	}, []);

	// 2. pathname 변경시 마다 pathname 분석
	React.useEffect(() => {
		const pathList = pathname.split('/').slice(1);
		setProjectName('{projectCode}');
		setSubmissionName('{submissionCode}');
		// setcurrentPaths([]);
		setBreadcrumbs([]);

		// console.log("1. init");

		setcurrentPaths(pathList);
	}, [pathname])

	React.useEffect(() => {
		if (currentPaths.length > 0 && Object.keys(jsonPaths).length > 0) {
			if (!(currentPaths.length === 1 && currentPaths[0] === '')) {
				const results = currentPaths.map((part, index) => {
					let result = cloneDeep(jsonPaths[part]);
					if (result === undefined) {
						// 1. projectCode || submissionCode 판단
						if (part === projectCode) {
							result = cloneDeep(jsonPaths["projectCode"]);
						} else if (part === submissionCode) {
							result = cloneDeep(jsonPaths["submissionCode"]);
						}
					}
					if (index === 0) {
						// main
						try {
							setMainText(result.text);
						} catch (e) {
							console.error("mainText error : ", e);
						}
					}
					return result;
				})
				// console.log("2. 현재 경로를 기반으로 json 매칭 : ", results);
				setFilteringPaths(results);
			}
		}

	}, [currentPaths, Object.keys(jsonPaths).length])

	React.useEffect(() => {
		let replacements = {};
		if (projectName !== '{projectCode}' && submissionName !== '{submissionCode}' && mainText !== '') {
			// console.log("3. 재료가 준비되어, 텍스트 치환 작업 실시 projectName = ", projectName);
			replacements = {
				"{{category}}": "", //mainText,
				"{{projectCode}}": projectName,
				"{{submissionCode}}": submissionName,
			};

			if (filteringPaths.length > 0) {
				filteringPaths.forEach(path => {
					// 객체의 각 키-값 쌍을 사용하여 치환
					Object.keys(replacements).forEach(key => {
						// const t = i18next.t(path.text);
						// console.log("key : ", key);
						// console.log("path.text :", path.text);
						if (path.text) {
							path.text = path.text.replace(new RegExp(key, "g"), replacements[key]);
							path.i18n = i18next.t(path.text);
						}
						// console.log("path.text 2 :", path.text);
					});
				});
				createBreadcrumbs(filteringPaths);
			}
		}


	}, [filteringPaths, projectName, submissionName, mainText, navigateStore.language])

	const createBreadcrumbs = (
		list: {
			id: string,
			text: string,
			isClick: boolean,
			i18n: string,
		}[],
	) => {

		const crumbs = list.map((path, i) => convertJsonToComponent(path, i));
		// console.log("4. 빵조각 준비완료 = ", crumbs);
		setBreadcrumbs(crumbs);
	}

	const convertJsonToComponent = (
		jsonPath: {
			id: string,
			text: string,
			isClick: boolean,
			i18n: string,
		},
		index
	) => {
		const title = jsonPath?.i18n;
		const id = jsonPath.id;
		// if(id === 'view') return;
		const isClick = jsonPath.isClick;
		const onClick = (e) => {
			const id = e.target.id;

			if (id === "projectCode" || id === "submissionCode") {
				// 뒤에 /view 를 붙여준다.
				const trimmedParts = currentPaths.slice(0, 2);
				const trimmedUrl = '/' + trimmedParts.join('/');
				naviCustom.go(trimmedUrl + '/view');
				// navigate(trimmedUrl + '/view');
			} else {
				const indexPath = currentPaths.indexOf(id);
				const trimmedParts = currentPaths.slice(0, indexPath + 1);
				let trimmedUrl = '/' + trimmedParts.join('/');
				if (id === 'create') {
					trimmedUrl = trimmedUrl + '/info';
				}
				naviCustom.go(trimmedUrl);
				// navigate(trimmedUrl);
			}
		}
		const s = `
				${title}

`;

		return (
			<Typography
				key={index} id={id}
				style={isClick ? { cursor: 'pointer' } : {}}
				onClick={isClick ? (e) => onClick(e) : () => { }}
			>
				{title}
			</Typography>
		)
	}

	const handleLogout = () => {
		const urlAfterLogout = "/";
		naviCustom.logout({ authStore, url: urlAfterLogout });

		// authStore.doLogout();
		// goToHome();
	}
	const goToHome = () => {
		naviCustom.go("/");
		// props.navigate("/");
	}

	const convertUserTypeToText = (type) => {
		switch (type) {
			case UserType.User:
				return <Typography className={classes.ProfileJob}>{i18next.t("학생")}</Typography>
			case UserType.Admin:
				return <Typography className={classes.ProfileTeacher}>{i18next.t("교사")}</Typography>
			default:
				return ""
		}
	}

	const convertGradeInfoForView = (user) => {
		const grade = user.grade;
		const className = user.className;
		const number = user.number;

		const text = i18next.t("{{grade}}학년 {{className}}반 {{number}}번", { grade: grade, className: className, number: number ? number : "" });
		return text;
	}

	return (
		<div className={classes.root}>
			<TopBarBlock className={classes.topBar} style={{ backgroundColor: "#fff" }}>
				<Box display='flex' alignItems='center' justifyContent='center'>
					<Box display='flex'
						alignItems='center'
						justifyContent='center'
						style={{ cursor: "pointer" }}
						onClick={goToHome}>
						{/*<Box*/}
						{/*    color="inherit"*/}
						{/*    aria-label="toggle drawer"*/}
						{/*    className={clsx(classes.iconBtnReset, classes.iconToggle)}>*/}
						{/*    <PageLogo />*/}
						{/*</Box>*/}
						{/*<Typography className={classes.topBarTitle}>{i18next.t("서논술형 평가 도구")}</Typography>*/}
					</Box>

					{/*<BreadcrumbComponent></BreadcrumbComponent>*/}
					<Box display='flex' alignItems='center' justifyContent='center' className={classes.breadcrumbsList}>
						{leftItem ?
							leftItem() :
							<Breadcrumbs
								separator={<BreadcrumbsIcon fontSize="small" />}
								aria-label="breadcrumb">
								{breadcrumbs}

							</Breadcrumbs>
						}
					</Box>
				</Box>

				<Box>
					{rightItem ?
						rightItem() :
						<Box>
							<Button
								// key={navigateStore.simpleMode ? "mode-S" : "mode-N"}
								className={classes.SelectedLanguage}
								onClick={() => handleSelectMode(!navigateStore.simpleMode)}
								disableRipple
								sx={{ textTransform: 'none' }}
							>
								<Typography>
									{
										navigateStore.simpleMode ? i18next.t("일반용") : i18next.t("학교용")
									}
								</Typography>
							</Button>

							<IconButton className={clsx(classes.iconBtnReset, classes.iconButton)} disableRipple>
								<NoticeIcon />
							</IconButton>
							<IconButton className={clsx(classes.iconBtnReset, classes.iconButton)} disableRipple>
								<HelpIcon />
							</IconButton>

							<IconButton className={clsx(classes.iconBtnReset, classes.iconButton)} onClick={handleLangClick} disableRipple>
								<LanguageIcon />
							</IconButton>
							<Popover id={idLang}
								open={openLang}
								anchorEl={anchorLangEl}
								onClose={handleLangClose}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
								className={classes.LanguageBox}>
								<Box className={classes.LanguageBoxInner}>
									{/* <Typography className={classes.languageTitle}>{i18next.t("언어 선택")}</Typography> */}
									{languages.map((language) => (
										<Button
											key={language.code}
											className={`${classes.LanguageButton} ${lang === language.code ? classes.SelectedLanguage : ''}`}
											onClick={() => handleLanguageSelect(language.code)}
											disableRipple
										>
											<Typography>{language.name}</Typography>
										</Button>
									))}
								</Box>
							</Popover>

							<IconButton className={clsx(classes.iconBtnReset, classes.iconButton)} onClick={handleClick} disableRipple>
								<ProfileIcon />
							</IconButton>
							<Popover id={id}
								open={openProfile}
								anchorEl={anchorEl}
								onClose={handleClose}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
								className={classes.ProfileBox}>
								<Box className={classes.ProfileBoxInner}>
									{/*<Box className={classes.ProfileImg}>*/}
									{/*    <img src={ProfileImg} alt={i18next.t('프로필 사진')}/>*/}
									{/*</Box>*/}
									{convertUserTypeToText(loginUser.type)}
									<Typography className={classes.ProfileNmae}>{loginUser.nickname}</Typography>
									<Typography className={classes.ProfileEmail}>{loginUser.email}</Typography>
									{loginUser.type === UserType.User &&
										<Typography className={classes.ProfileSchool}>{convertGradeInfoForView(loginUser)}</Typography>
									}
									<Button className={classes.ProfileLogout}
										onClick={handleLogout}
										disableRipple>
										<Typography>{i18next.t("로그아웃")}</Typography>
									</Button>
								</Box>

							</Popover>
						</Box>
					}
				</Box>
			</TopBarBlock>
		</div>
	);
}


