import {
	Box
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TemplateProjectViewStyle } from "../../styles/TemplateProjectViewStyle";
import { AnswerTabQuestionAnswerRow } from "./AnswerTabQuestionAnswerRow";
import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";
import { getSubQuestionNumberString } from "./getSubQuestionNumberString";

type Props = {
	projectExamList: IExamInfo[];
}

export const AnswerTab = (props: Props) => {
	const classes = useEmotionStyles( TemplateProjectViewStyle );
	const { projectExamList } = props;

	return (
		<Box className={classes.tabBox}>
			{projectExamList.length > 0 &&
				projectExamList
					.filter(e => e.changeFlag !== ChangeFlag.DELETED)
					.map((exam, examIndex) => {
						return (
							exam.questions.filter(q => q.changeFlag !== ChangeFlag.DELETED).map((question, index) => {

								const questionString = getSubQuestionNumberString(exam, question);
								return (
									<>
										{questionString}
										<AnswerTabQuestionAnswerRow
											key={question.projectExamQuestionNum}
											questionInfo={question}
										/>
									</>
								)
							})
						)
					})
			}
		</Box>
	)
};
