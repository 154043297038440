import i18next from "../../../../lang";
import { ProjectSemesterType } from "../../../../repositories/model/support/ProjectSemesterType";

export function getSemesterTypeString(year: number, semesterType: ProjectSemesterType) {
	if (year === null || year === undefined) { return "" }
	if (!semesterType) {
		return i18next.t("{{year}}학년도", { year });
	}

	switch (semesterType) {
		case ProjectSemesterType.FIRST:
			return i18next.t("{{year}}학년도 / 1학기", { year: year.toString() });
		case ProjectSemesterType.SECOND:
			return i18next.t("{{year}}학년도 / 2학기", { year: year.toString() });
		case ProjectSemesterType.THIRD:
			return i18next.t("{{year}}학년도 / 3학기", { year: year.toString() });
		default:
			return i18next.t("{{year}}학년도", { year: year.toString() });
	}
}


export function getYearSemesterTitle(year: number, semesterType: ProjectSemesterType) {
	if (year === null || year === undefined) {
		return i18next.t("학년도");
	}

	if (!semesterType) {
		return i18next.t("학년도");
	}

	switch (semesterType) {
		case ProjectSemesterType.FIRST:
			return i18next.t("학년도/학기", { year });
		case ProjectSemesterType.SECOND:
			return i18next.t("학년도/학기", { year });

		case ProjectSemesterType.THIRD:
			return i18next.t("학년도/학기", { year });
		default:
			return i18next.t("학년도");
	}
}