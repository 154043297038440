import { IPageSOBP, PU_TO_NU } from "../../../nl-lib3-common";
import { getTranslater } from "../Calibration/getTranslater";
import { MappingStorage } from "../MappingStorage";
import PdfDocMapper from "../PdfDocMapper";
import { IPdfPageDesc } from "../types/IPdfPageDesc";
import { IManualRegisterPdfType } from "../types/ManualMappingItem";


function generateMappingTranslater(
  pageNum: number,
  pdfPageDesc: IPdfPageDesc,
  sobp: IPageSOBP,
  nu_margin: {
    Xmin: number;
    Ymin: number;
    Xmax: number;
    Ymax: number;
  },
  size_pu: {
    width: number;
    height: number;
  }
) {
  const toAvoidZero_pu = 10;   // pu의 p0가 0이 들어가면 계산이 이상하기 때문
  const toAvoidZero_nu = toAvoidZero_pu * PU_TO_NU;

  const l_nu = nu_margin.Xmin + toAvoidZero_nu;
  const t_nu = nu_margin.Ymin + toAvoidZero_nu;
  const r_nu = nu_margin.Xmax - toAvoidZero_nu;
  const b_nu = nu_margin.Ymax - toAvoidZero_nu;
  const nu = {
    p0: { x: l_nu, y: t_nu },
    p1: { x: r_nu, y: t_nu },
    p2: { x: r_nu, y: b_nu },
    p3: { x: l_nu, y: b_nu }
  };

  const l_pu = 0 + toAvoidZero_pu;
  const t_pu = 0 + toAvoidZero_pu;
  const r_pu = size_pu.width - toAvoidZero_pu;
  const b_pu = size_pu.height - toAvoidZero_pu;

  const pu = {
    p0: { x: l_pu, y: t_pu },
    p1: { x: r_pu, y: t_pu },
    p2: { x: r_pu, y: b_pu },
    p3: { x: l_pu, y: b_pu }
  };

  const pts = { nu, pu };
  const translater = getTranslater(pageNum, pdfPageDesc, sobp, pts);
  return translater;
}

export async function manualRegisterAtMappingStorage(item: IManualRegisterPdfType) {
  const pagesPerSheet = 1;
  /** homography를 구하기 위해서 CalibrationData를 쓴다 */

  // Page info를 세팅하고 나서
  const { numPages, pdf_url_for_mapper: pdf_url, filename, fingerprint, background_image_url_rule, nprojJson } = item;

  // PDF 정보를 세팅
  const pdfCommonDesc: IPdfPageDesc = {
    url: pdf_url || "",
    filename,
    fingerprint: fingerprint || "",
    numPages,
    id: fingerprint + `/${pagesPerSheet}`,
    pageNum: undefined,
    size_pu: undefined,
  };

  // pdf size를 넣을 곳
  const mapper = new PdfDocMapper(filename, pagesPerSheet, fingerprint || "", background_image_url_rule);
  for (let i = 0; i < numPages; i++) {
    const pageNum = i + 1;
    const { size_pu, sobp, nu: nu_margin } = nprojJson.pages[i];
    const pdfPageDesc: IPdfPageDesc = { ...pdfCommonDesc, pageNum, size_pu };

    const translater = generateMappingTranslater(pageNum, pdfPageDesc, sobp, nu_margin, size_pu);
    mapper.append([translater]);
  }

  // 2021/08/12
  // 그 다음 등록
  const msi = MappingStorage.getInstance();
  msi.register(mapper);
}


