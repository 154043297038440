import {
	Box,
	Dialog,
	FormControl,
	IconButton,
	InputBase,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { StudentTaskListStlye } from "../../styles/StudentTaskListStlye";
import { ReactComponent as SearchNameIcon } from '../../../../common/images/SearchNameIcon.svg';
import { ReactComponent as SelectIcon } from '../../../../common/images/SelectIcon.svg';
import { ReactComponent as TableListOrder } from '../../../../common/images/TableListOrder.svg';
import { observer } from "mobx-react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useEffect } from "react";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { useStores } from "../../../../hooks/useStores";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
import { StudentTaskListRow } from "./StudentTaskListRow";
import { ICompareDialogParams } from "../120_OnlineAssignment/ICompareDialogParams";
import CompareNeoPenDataStudent from "../../20_Assignments/2_Submissions/StudentSubmission/CompareNeoPenDataStudent";

const sortByCreateTime = "ps.created_datetime";

type Props = {
};

function StudentTaskList(props: Props) {
	const classes = useEmotionStyles( StudentTaskListStlye );
	const { navigateStore,  projectSubmissionStore, projectStore, projectSubmissionAnswerStore, authStore } = useStores();
	// const { navigate, location } = props;

	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [createdSortRotated, setCreatedSortRotated] = React.useState(false);
	const [searchKeyword, setSearchKeyword] = React.useState("");
	const [page, setPage] = React.useState(1);
	const [sortingHints, setSortingHints] = React.useState([{ column: "ps.updated_datetime", hint: "DESC" }]);

	const [deadLineSortRotated, setDeadLineSortRotated] = React.useState(false);
	const [stepFilter, setStepFilter] = React.useState("all");
	// const [openDialog,setOpenDialog] = React.useState(false);
	// const [selectedProjectCode,setSelectedProjectCode] = React.useState("");
	// const [selectedState,setSelectedState] = React.useState("");

	const naviCustom = useNaviCustom();

	const [noShowPenDialog, setNoShowPenDialog] = React.useState(false);
	const [compareNeoPenOpen, setCompareNeoPenOpen] = React.useState(false);

	const [compareNeoPenDataParam, setCompareNeoPenDataParam] = React.useState<ICompareDialogParams | null>(null);
	const [submissionTransfers, setSubmissionTransfers] = React.useState([]);



	const { studentProjectSubmissionList } = projectSubmissionStore;

	React.useEffect(() => {
		projectSubmissionStore.getStudentProjectSubmissionTransfer(null, searchKeyword, page - 1, rowsPerPage, sortingHints);
		navigateStore.changeTopBarLeftItem(topBarLeftItem);
	}, []);

	React.useEffect(() => {
		let state = null;
		let sort = sortByCreateTime;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (stepFilter !== "all") state = stepFilter;

		const sortingHints = [{ column: 'ps.updated_datetime', hint: dir }];
		projectSubmissionStore.getStudentProjectSubmissionTransfer(state, searchKeyword, page - 1, rowsPerPage, sortingHints);
	}, [stepFilter, rowsPerPage, createdSortRotated, deadLineSortRotated, page]);

	// React.useEffect(()=>{
	//     if(selectedProjectCode)
	//         setOpenDialog(true);
	//     else {
	//         // let dir = createdSortRotated ? "ASC" : "DESC";
	//         // const sortingHints = [{ column: 'ps.updated_datetime', hint: dir }];
	//         // projectSubmissionStore.getStudentProjectSubmissionTransfer(null, searchKeyword, page-1, rowsPerPage, sortingHints);
	//         setOpenDialog(false);
	//     }
	// },[selectedProjectCode])

	React.useEffect(() => {
		let state = null;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (stepFilter !== "all") state = stepFilter;

		const sortingHints = [{ column: 'ps.updated_datetime', hint: dir }];

		projectSubmissionStore.getStudentProjectSubmissionTransfer(state, searchKeyword, page - 1, rowsPerPage, sortingHints);
	}, [projectStore.updatedProjectUserState, projectSubmissionAnswerStore.answerCallbackFlag]);

	const topBarLeftItem = () => { return (<></>) }



	const checkCompared = (state) => {
		switch (state) {
			case ProjectStateType.CREATED:
			case ProjectStateType.POSED:
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
				return false;
			case ProjectStateType.COMPARED:
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
				return true;
		}
	}

	// Function to fetch the data asynchronously for each studentTransfer
	const fetchProjectSubmissionTransfers = async (projectCode) => {
		try {
		  // Fetching submission transfers for the given projectCode
		  const result = await projectSubmissionStore.getSubmissionTransfersWithReturnForStudentPenSubmit(projectCode, authStore.loginUser.code);
		  return result;
		} catch (error) {
		  console.error("Failed to load submission transfers:", error);
		  return null; // Return null if there's an error
		}
	};

	// UseEffect to fetch data when the component mounts or dependencies change
	useEffect(() => {
		const fetchData = async () => {
		  const transfers = studentProjectSubmissionList.transfers || [];
		  const results = await Promise.all(transfers.map(async (studentTransfer) => {
			// For each studentTransfer, fetch the data based on its projectCode
			return fetchProjectSubmissionTransfers(studentTransfer.projectCode);
		  }));

		  // Set the fetched data (ensure the array matches the length of transfers)
		  setSubmissionTransfers(results);
		};

		if (studentProjectSubmissionList && studentProjectSubmissionList.transfers && studentProjectSubmissionList.transfers.length > 0) {
		  fetchData();
		}
	}, [studentProjectSubmissionList]); // Fetch data when these dependencies change


	const handleCreatedSortClick = () => {
		setCreatedSortRotated(!createdSortRotated);
	};

	const handleSelectState = async (e) => {
		setStepFilter(e.target.value);

		// searchProjectList();
	}


	const handleClickAnswer = async (projectCode, state, submissionCode) => {
		console.log(submissionCode);
		await projectSubmissionStore.changeProjectSubmissionCode(submissionCode);
		// setSelectedProjectCode(projectCode);
		// setSelectedState(state);
		projectStore.changeProjectSelectedState(state);

		naviCustom.go(`/assignments/${projectCode}/submissions/test`);
		// props.navigate(`/assignments/${projectCode}/submissions/test`);

		// setOpenDialog(true);
		// window.open(`/projects/${projectCode}/create/printPaper`,"_blank")
	}

	// const handleClickClose = () =>{
	//     setSelectedProjectCode(null);
	//     // setOpenDialog(false);
	// }

	const handleClickProjectName = (state, projectCode, submissionCode) => {
		if (state === ProjectStateType.EXPORTED) {
			//결과지 출력

		} else {
			//문제지 출력
			handleClickAnswer(projectCode, state, submissionCode);
		}
	}

	const searchProjectList = () => {
		let state = null;
		let sort = sortByCreateTime;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (stepFilter !== "all") state = stepFilter;

		const sortingHints = [{ column: sort, hint: dir }];
		projectSubmissionStore.getStudentProjectSubmissionTransfer(state, searchKeyword, page - 1, rowsPerPage, sortingHints);
	}

	const handleClickPage = (e, value) => {
		setPage(value);
	};

	const handleChangeSearchKeyword = (e) => {
		setSearchKeyword(e.target.value);
	};

	const handleClickKeywordSearch = () => {
		searchProjectList();
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			searchProjectList();
		}
	};

	const handleClickCompletedReview = (projectCode, submissionCode) => {
		naviCustom.go(`/assignments/${projectCode}/export/${submissionCode}/result/detail`);
		// props.navigate(`/assignments/${projectCode}/export/${submissionCode}/result/detail`);
	}
	const handleClickReview = (projectCode, submissionCode) => {
		naviCustom.go(`/assignments/${projectCode}/export/${submissionCode}/result/detail`);
		// props.navigate(`/assignments/${projectCode}/export/${submissionCode}/result/detail`);
	}

	async function handleOpenPenDialog(projectCode: string, submissionIndex: number) {


		const result = await projectSubmissionStore.getSubmissionTransfersWithReturnForStudentPenSubmit(projectCode, authStore.loginUser.code)

		// Check if the result is valid
        if (!result) {
            console.log("No submission found for the user code.");
            return null; // or return some default value, if needed
        }

		const projectInfo = await projectStore.fetchProject(projectCode);
		const templateCode = projectInfo?.templateCode;

		if (!templateCode) {
			return null;
		}


		// Construct the ICompareDialogParams object
		const param: ICompareDialogParams = {
            projectCode: projectCode, // Using the provided projectCode
            templateProjectCode: templateCode, // Replace with actual templateProjectCode if needed
            submissionCode: result.submissionCode, // Assuming result has submissionCode
            submissionTransfer: { ...result, submissionIndex } // Assuming result is of type IProjectSubmissionTransfer
        };

		setCompareNeoPenDataParam(param);
/*
		interface ICompareDialogParams {
			projectCode: string;
			templateProjectCode: string;
			submissionCode: string;
			submissionTransfer: IProjectSubmissionTransfer;
		}
*/

//		setSelectedSubmissionTransfer({ ...submissionTransfer, submissionIndex });
		setNoShowPenDialog(false);
		setCompareNeoPenOpen(true);
	}

	const handleClickClose = () => {
		setCompareNeoPenOpen(false);
		// projectSubmissionStore.getSubmissionTransfers(projectCode, filterState, keyword, page-1, rowsPerPage, sortingHints);
	}

	return (
		<div className={classes.root}>

			{/* ******콘텐츠 영역******* */}
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#e5e5e9',
					height: 'calc(100vh - 86px)'
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box sx={{ marginTop: "25px" }}>
						<Typography className={classes.commonSubTitle}>내 평가 과제 (<span>{studentProjectSubmissionList && studentProjectSubmissionList.totalCount}</span>)</Typography>
					</Box>
					<Box display='flex' alignItems='center' justifyContent='space-between'>
						<Box display='flex' alignItems='center' justifyContent='space-between'>
							<Typography className={classes.selectText}>실행상태</Typography>
							<Box>
								<FormControl className={classes.formControl}>
									<Select
										IconComponent={(props) => (
											<Box>
												<SelectIcon  {...props} />
											</Box>
										)}
										MenuProps={{
											anchorOrigin: { vertical: "bottom", horizontal: "right" },
											transformOrigin: { vertical: 'top', horizontal: 'right' },
											// getContentAnchorEl: null,
											className: classes.selectPopover
										}}
										value={stepFilter}
										onChange={handleSelectState}
									>
										<MenuItem value={"all"}>전체</MenuItem>
										<MenuItem value={ProjectStateType.POSED}>답안 제출하기</MenuItem>
										<MenuItem value={ProjectStateType.COMPARED}>답안 제출 완료</MenuItem>
										<MenuItem value={ProjectStateType.EXPORTED}>결과 리뷰하기</MenuItem>
										<MenuItem value={ProjectStateType.REVIEWED}>결과 리뷰 완료</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<Paper
								component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
								className={classes.urlInputBox}
							>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder="과제 검색"
									value={searchKeyword}
									onChange={handleChangeSearchKeyword}
									onKeyDown={handleKeyDown}
									inputProps={{ 'aria-label': '과제검색' }}
								/>
								<IconButton className={classes.iconBtnReset}
									onClick={handleClickKeywordSearch}
									disableRipple>
									<SearchNameIcon />
								</IconButton>
							</Paper>
						</Box>
					</Box>
					<Box>
						<TableContainer component={Paper} className={classes.tableBox}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell width='5%' align="center">No.</TableCell>
										<TableCell width='20%' >과제명</TableCell>
										<TableCell width='8%' align="center">채점 결과</TableCell>
										<TableCell width='12%'>채점 결과 발표일시</TableCell>
										<TableCell width='13%'>
											<Box className={classes.sortButton} style={{ cursor: 'default' }}>
												<Typography>제출일시(온라인 제출일시)</Typography>
												<IconButton onClick={handleCreatedSortClick} className={classes.iconBtnReset}
													style={{ transform: createdSortRotated ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>
													<TableListOrder />
												</IconButton>
											</Box>
										</TableCell>
										<TableCell width='13%'>
											온라인 제출기한
										</TableCell>
										{/*<TableCell width='13%'>*/}
										{/*    결과 리뷰 완료일시*/}
										{/*</TableCell>*/}
										<TableCell width='10%'>실행 단계</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{studentProjectSubmissionList
										&& studentProjectSubmissionList.transfers
										&& studentProjectSubmissionList.transfers.map((studentTransfer, index) => {

											return (
												<StudentTaskListRow
												studentTransfer={studentTransfer}
												projectSubmissionTransfer={index < submissionTransfers.length ? submissionTransfers[index] : {}}
												projectCode={studentTransfer.projectCode}
												index={index}
												rowsPerPage={rowsPerPage}
												page={page}
												handleClickAnswer={handleClickAnswer}
												handleClickCompletedReview={handleClickCompletedReview}
												handleClickReview={handleClickReview}
												handleClickProjectName={handleClickProjectName}
												handleOpenPenDialog={handleOpenPenDialog}
											/>)
										})
									}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
						<Stack spacing={2}>
							<Pagination count={studentProjectSubmissionList && studentProjectSubmissionList.totalCount > 0 ?
								Math.ceil(studentProjectSubmissionList.totalCount / rowsPerPage) : 1}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleClickPage}
								variant="outlined"
							/>
						</Stack>
					</Box>
				</Box>
			</Box>
			{/*<Dialog className={classes.centerDialog} fullScreen open={openDialog} >*/}
			{/*    <StudentTestPaper projectCode={selectedProjectCode} state={selectedState} handleClickClose={handleClickClose} />*/}
			{/*</Dialog>*/}
			{compareNeoPenDataParam && (
				<Dialog fullScreen open={compareNeoPenOpen} >
					<CompareNeoPenDataStudent
						projectCode={compareNeoPenDataParam.projectCode}
						templateProjectCode={compareNeoPenDataParam.templateProjectCode}
						handleClickClose={handleClickClose}
						submissionTransfer={compareNeoPenDataParam.submissionTransfer}
//						submissionCode={compareNeoPenDataParam.submissionCode}
						noShowPenDialog={noShowPenDialog}
					/>
				</Dialog>
			)}
		</div>
	);
}

export default observer<typeof StudentTaskList>(StudentTaskList);
