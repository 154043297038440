
import {
	Box,
	Button,
	IconButton,
	Popover,
	Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import clsx from "clsx";
import { ReactComponent as AddFeedbackPlus } from '../../../../../common/images/AddFeedbackPlus.svg';
import { ReactComponent as MarkToggleIcon } from '../../../../../common/images/MarkToggleIcon.svg';
import { ReactComponent as ScanToggleIcon } from '../../../../../common/images/ScanToggleIcon.svg';
import { ReactComponent as TableAlert } from '../../../../../common/images/TableAlert.svg';
import { ReactComponent as TooltipClose } from '../../../../../common/images/TooltipClose.svg';
import { ReactComponent as TooltipTipIcon } from '../../../../../common/images/TooltipTipIcon.svg';
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { MarkFeedbackStyle } from "../../../styles/MarkFeedbackStyle";

import TextEditor from "../../../../../components/common/TextEditor";
import TextViewer from "../../../../../components/common/TextViewer";
import { DataUtils } from "../../../../../util/data-utils";
import MessageDialog from "../../../../components/MessageDialog";

import React from "react";
import { useTranslation } from 'react-i18next';
import { useStores } from "../../../../../hooks/useStores";
import i18n from '../../../../../lang';
import { IQuestionInfo } from "../../../../../repositories/model/transfer/IQuestionInfo";
import { AIStatusType } from "../../../../../stores/support/AIStatusType";
import { MarkTable } from './MarkTable';
import { RubricTable } from './RubricTable';
import { observer } from "mobx-react";

type Props = {
	questionInfo: IQuestionInfo;
	idx: number;
	examOrderAndQuestionsLengthMap: any;
	markType: string;
	resetState: boolean;
	requestionAIMap: any;
	changeIsUpdating: any;
	isExported: boolean;
	checkedAiProcessingWhenMounted: boolean;
	changeCheckedAiProcessing: any;
};


export const QuestionMarkRow = observer((props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( MarkFeedbackStyle );
	const { questionInfo, examOrderAndQuestionsLengthMap, resetState, requestionAIMap, changeIsUpdating, isExported, checkedAiProcessingWhenMounted, changeCheckedAiProcessing } = props;

	const { projectExamStore, projectSubmissionAnswerStore, navigateStore, fileInfoStore, messageStore } = useStores();



	const [openRubric, setOpenRubric] = React.useState(false);
	const [openAIMarkBasis, setOpenAIMarkBasis] = React.useState(false);
	const [openFeedback, setOpenFeedback] = React.useState(false);
	const [isFeedbackReset, setIsFeedbackReset] = React.useState(false);
	const [rubricIdx, setRubricIdx] = React.useState(0);
	const [markTableIdx, setMarkTableIdx] = React.useState(0);
	const [feedbackTextLen, setFeedbackTextLen] = React.useState(0);
	const [openFeedbackReset, setOpenFeedbackReset] = React.useState(false);
	const [aIAnchorEl, setAIAnchorEl] = React.useState(null);
	// const [countOpenTooltip, setCountOpenTooltip] = React.useState(0);
	const [aIcompleteToggle, setAIcompleteToggle] = React.useState(false);
	const [openNewOwnerFeedback, setOpenNewOwnerFeedback] = React.useState(false);
	const [openAIAlertByClick, setOpenAIAlertByClick] = React.useState(false);
	// const [checkedAiProcessingWhenMounted, setCheckedAiProcessingWhenMounted] = React.useState(false);

	const AIFeedbackRef = React.useRef(null);
	const tooltipId = !!aIAnchorEl ? 'simple-popover' : undefined;
	let getAIScoreId;

	const currentOrder = React.useMemo(() => {
		const questionListLen = examOrderAndQuestionsLengthMap[questionInfo.parentOrder];

		return projectExamStore.setQuestionFullOrderByQuestionListLen(questionListLen, questionInfo.parentOrder, questionInfo.order);
	}, [examOrderAndQuestionsLengthMap, questionInfo.parentOrder, questionInfo.order]);

	// React.useEffect(() => {
	//     if (idx !== 0 || !openAIMarkBasis || countOpenTooltip > 0) return;
	//
	//     handleSetTooltipAnchorEl();
	//
	//     setTimeOutForTooltipWhenMounted = setTimeout(() => {
	//         handleCloseTooltip();
	//         clearTimeout(setTimeOutForTooltipWhenMounted);
	//     }, 3000);
	//
	//     setCountOpenTooltip(prev => prev + 1);
	// }, [idx, openAIMarkBasis]);

	React.useEffect(() => {
		if (!resetState) return;

		setIsFeedbackReset(true);
		projectSubmissionAnswerStore.changeResetState(questionInfo.key, false);
	}, [resetState]);

	React.useEffect(() => {
		if (openNewOwnerFeedback || !questionInfo?.answer?.ownerFeedback) return;

		setOpenNewOwnerFeedback(true);
		setOpenFeedback(true);
	}, [questionInfo?.answer?.ownerFeedback, openNewOwnerFeedback]);

	const handleClickRublicPage = (e, value) => {
		const idx = value - 1;
		setRubricIdx(idx);
	};

	const handleClickMarkTablePage = (e, value) => {
		const idx = value - 1;
		setMarkTableIdx(idx);
	};

	const handleClickRublicToggle = () => {
		setOpenRubric(prev => !prev);
	};

	const handleClickAIMarkBasis = () => {
		setOpenAIMarkBasis(prev => !prev);
	};

	const handleClickFeedbackBtn = () => {
		if (!openFeedback) {
			const isEmptyWithTrim = projectSubmissionAnswerStore.checkAnswerFeedbackEmptyWithTrim(questionInfo.key);
			if (isEmptyWithTrim) {
				setIsFeedbackReset(true);
			}
		}

		setOpenFeedback(prev => !prev);
	};

	// const currentCorrectedFeedbackHTML = React.useMemo(() => {
	//     if (!questionInfo.answer) return "";
	//
	//     console.log("questionInfo.answer.ownerFeedback=",questionInfo.answer.ownerFeedback)
	//
	//     return questionInfo.answer.ownerFeedback ? questionInfo.answer.ownerFeedback : ""
	// }, [questionInfo.answer.ownerFeedback]);

	const handleUploadFile = async (file) => {
		const { id, downloadUrl } = await fileInfoStore.create(file);
		if (!id || !downloadUrl) return;

		projectSubmissionAnswerStore.addImageId(id);
		return { downloadUrl, id };
	};

	const handleChangeFeedback = (html) => {
		projectSubmissionAnswerStore.changeAnswerFeedback(questionInfo.key, html);
		changeIsUpdating && changeIsUpdating(true);
	};

	const isOwnerFeedbackEmpty = React.useMemo(() => {
		const feedback = questionInfo?.answer?.ownerFeedback;
		const isEmpty = DataUtils.getTagArrFromHtml(feedback, 'img').length === 0 && !DataUtils.getTextFromHtml(feedback).trim();

		return isEmpty;
	}, [questionInfo?.answer?.ownerFeedback]);

	// const handleInitInitialFeedback = () => {
	//     projectSubmissionAnswerStore.initAnswerFeedback(questionInfo.key);
	// };

	const handleClickDeleteFeedback = (e) => {
		e.preventDefault();
		setOpenFeedbackReset(true);
	};

	const handleClickSubmitInFeedbackResetAlert = () => {
		setIsFeedbackReset(true);
		setOpenFeedbackReset(false);
	};

	const handleCloseFeedbackResetAlert = () => {
		setOpenFeedbackReset(false);
	};

	const handleProcessAfterFeedbackReset = () => {
		setIsFeedbackReset(false);
	};

	const handleChangeFeedbackTextLen = (len) => {
		setFeedbackTextLen(len);
	};

	const handleMouseEnterInTooltip = () => {
		handleSetTooltipAnchorEl();
	};

	const handleSetTooltipAnchorEl = () => {
		if (AIFeedbackRef.current) {
			setAIAnchorEl(AIFeedbackRef.current);
		}
	};

	const handleCloseTooltip = () => {
		setAIAnchorEl(null);
	};

	const handleInitInitialOwnerFeedback = () => {
		projectSubmissionAnswerStore.initAnswerFeedback(questionInfo.key);
	};

	const answerCode = React.useMemo(() => {
		return questionInfo?.answer?.code;
	}, [questionInfo?.answer?.code]);

	const requestingAI = React.useMemo(() => {
		if (!checkedAiProcessingWhenMounted) return true;
		if (!answerCode) return false;

		const selectedObj = projectSubmissionAnswerStore.getRequestingAIFromMap(answerCode);
		if (!selectedObj) return false;

		return selectedObj.requesting;
	}, [requestionAIMap, checkedAiProcessingWhenMounted]);

	React.useEffect(() => {
		if (checkedAiProcessingWhenMounted === false) {
			checkAIProgressing();
		}
	}, [checkedAiProcessingWhenMounted]);

	React.useEffect(() => {
		if (!checkedAiProcessingWhenMounted || !requestingAI) return;

		processBeforeAIMark();
		processAIMark();
	}, [requestingAI, checkedAiProcessingWhenMounted]);


	const getAIStatusFromStore = async (answerCode: string) => {
		const result = await projectSubmissionAnswerStore.getAIStatus(answerCode) as any as AIStatusType;
		return result;
	}

	const createAIScoreFromStore = async (answerCode: string, language: string, force: boolean) => {
		const result = await projectSubmissionAnswerStore.createAIScore(answerCode, language, force);
		return result;
	}

	const getAIScoreFromStore = async (answerCode: string) => {
		const result = await projectSubmissionAnswerStore.getAIScore(answerCode);
		return result;
	}

	const checkAIProgressing = async () => {
		try {
			if (!answerCode) return;

			const status = await getAIStatusFromStore(answerCode);

			if (status === AIStatusType.PROGRESS) {
				processBeforeAIMark();
				getAIScoreId = setInterval(getAIStatus, 5000);
				return;
			}

			changeCheckedAiProcessing(true);
		} catch (e) {
			console.log("Cannot fail requesting AI score at checkAIProgressing...", e);
			setRequestingAI(false);
		}
	};

	const processBeforeAIMark = () => {
		setOpenAIMarkBasis(false);
		setOpenFeedback(false);
		setOpenRubric(false);
		projectSubmissionAnswerStore.resetAnswerScores(questionInfo.key);
	};

	const processAIMark = async () => {
		// await projectSubmissionAnswerStore.resetAnswerScores(questionInfo.key);
		await createAIScore();
	};

	const createAIScore = async () => {
		if (!answerCode) return;

		// 강제 AI 채점하게 만들기, kitty 2024-09-05
		await createAIScoreFromStore(answerCode, navigateStore.language, true)
			.then(() => {
				getAIScoreId = setInterval(getAIStatus, 2000);
				getAIStatus(); // AI 채점 요청 후, 즉시 실행 1회
			})
			.catch(async e => {
				await messageStore.alert(i18n.t("AI 채점을 실패했습니다."))
				console.log("Cannot fail requesting AI score at createAIScore...", e);
				setRequestingAI(false);
			})
	};

	const getAIStatus = async () => {
		try {
			if (!answerCode) return;

			await getAIStatusFromStore(answerCode)
				.then(async status => {
					if (status === AIStatusType.COMPLETED) {
						await getAIScore();
						processAfterAIMark();
					}

					if (status === AIStatusType.FAILED) {
						processAfterAIMark();

						await messageStore.alert(i18next.t("AI 채점을 실패했습니다."));
						console.log("Cannot get requesting AI score by status 'failed' at getAIStatus...", answerCode);
					}
				})
		} catch (e) {
			await messageStore.alert(i18next.t("AI 채점을 실패했습니다."));
			console.log("Cannot fail requesting AI score by unknown error at getAIStatus...", e);
			clearInterval(getAIScoreId);
			setRequestingAI(false);
		}
	};

	const processAfterAIMark = () => {
		clearInterval(getAIScoreId);
		setRequestingAI(false);
		changeCheckedAiProcessing(true);
	};

	const getAIScore = async () => {
		if (!answerCode) return;

		const items = await getAIScoreFromStore(answerCode)
		console.log('result=', items);

		items.map(item => {
			const rubricObj = questionInfo.answer.rubrics.find(rubric => rubric.id === item.rubricId);
			if (!rubricObj) return;

			const rubricItemIdx = rubricObj.items.findIndex(c => c.rubricItemNum === item.rubricItemNum);
			if (rubricItemIdx < 0) return;

			projectSubmissionAnswerStore.changeRubricSelectedItem(questionInfo.key, item.rubricId, rubricItemIdx, item.feedback);

			changeIsUpdating && changeIsUpdating(true);
		});

		setAIcompleteToggle(prev => !prev);

	};

	const setRequestingAI = (boolean) => {
		console.log("answerCode=", answerCode)
		if (!answerCode) return;

		projectSubmissionAnswerStore.changeRequestingAI(answerCode, boolean);
	};

	const handleClickAIMarkEach = () => {
		setOpenAIAlertByClick(true);
	};

	const handleClickSubmitBtnInAIAlert = () => {
		console.log("handleClickSubmitInAlert");
		handleCloseAIAlert();
		handleAIMarkEach();
	};

	const handleAIMarkEach = () => {
		setRequestingAI(true);
	};

	const handleCloseAIAlert = () => {
		setOpenAIAlertByClick(false);
	};

	const currentAIFeddback = React.useMemo(() => {
		const rubricId = questionInfo?.answer?.rubrics[markTableIdx]?.id;
		if (rubricId === undefined) return;

		const scoreObj = questionInfo?.answer?.scores.find(scoreInfo => scoreInfo.rubricId === rubricId);
		if (!scoreObj) return;

		return scoreObj.aiFeedback;
	}, [questionInfo, markTableIdx, aIcompleteToggle]);

	React.useEffect(() => {
		if (requestingAI) {
			setOpenAIMarkBasis(false); // AI 채점 중에는 AI 채점 근거가 안 보이도록

		} else if (currentAIFeddback) {
			setOpenAIMarkBasis(true); // AI 채점 중이 아니고, AI Feedback이 있으면 AI 채점 근거가 보이도록
		}
	}, [requestingAI, currentAIFeddback]);

	const projectRubricsLength = React.useMemo(() => {
		return questionInfo?.answer?.rubrics.length;
	}, [questionInfo?.answer?.rubrics]);

	const handleClickOpenNewOwnerFeedback = () => {
		setOpenNewOwnerFeedback(true);
		setOpenFeedback(true);
	};

	// React.useEffect(() => {
	//     if (!requestingAI) return;
	//
	//     setOpenAIMarkBasis(false);
	// }, [requestingAI]);

	return (
		<Box style={{ marginBottom: "50px" }}>
			<Box display='flex' alignItems='center' justifyContent='space-between'>
				<Box display='flex' alignItems='center' justifyContent='space-between'>
					<Box className={classes.taskNumber}>
						<Typography>{currentOrder}</Typography>
					</Box>
					<Typography className={classes.markBigScore}><span>{questionInfo.answer.totalScore}</span>{i18next.t("점")}</Typography>
				</Box>
				<Box display='flex' alignItems='center' justifyContent='right'>
					<Button className={requestingAI ? classes.disabledTableToggleBtn : classes.tableToggleBtn} disableRipple onClick={handleClickRublicToggle} disabled={requestingAI}>
						<Typography>{i18next.t("채점 기준표 보기")}</Typography>
						<span
							className={classes.iconBtnReset}
							style={{ transform: !openRubric ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }}>
							{requestingAI ?
								<MarkToggleIcon /> :
								<ScanToggleIcon />
							}
						</span>
					</Button>
				</Box>
			</Box>

			<Box>
				{!questionInfo?.answer?.scored &&
					<Box display='flex' alignItems='center' justifyContent='left' className={classes.tableAlertBox}>
						<TableAlert />
						<Typography>{i18next.t("{{currentOrder}}번 문항에 점수 미선택 평가 항목이 있습니다.", { currentOrder })}</Typography>
					</Box>
				}

				{/*<Box display='flex' className={classes.aiNoticeText}>*/}
				{/*    <AIProgressNotice />*/}
				{/*    <Typography >AI 채점 중 오류가 발생했습니다. 문항별 'AI 채점하기'를 실행해 주세요.</Typography>*/}
				{/*</Box>*/}

				{/*<Typography className={classes.amountScoreSubTitle}>*AI 채점이 포함된 결과입니다. 문항 단위로 직접 채점할 수 있습니다.</Typography>*/}

				{openRubric &&
					questionInfo?.answer?.rubrics.length > 0 &&
					<RubricTable
						rubricInfo={questionInfo?.answer?.rubrics[rubricIdx]}
					/>
				}

				{openRubric &&
					projectRubricsLength > 1 &&
					<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
						<Stack spacing={2}>
							<Pagination
								count={questionInfo?.answer?.rubrics.length || 0}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleClickRublicPage}
								variant="outlined"
							/>
						</Stack>
					</Box>
				}

				{(requestingAI || isExported) ?
					<Box display='flex' alignItems='center' justifyContent='right' className={classes.disabledAiMarkBtnAdd}>
						<Button disableRipple>
							<Typography>{i18next.t("문항 AI채점")}</Typography>
						</Button>
					</Box> :
					<Box display='flex' alignItems='center' justifyContent='right' className={classes.aiMarkBtnAdd}>
						<Button disableRipple onClick={handleClickAIMarkEach}>
							<Typography>{i18next.t("문항 AI채점")}</Typography>
						</Button>
					</Box>
				}
			</Box>

			{questionInfo?.answer?.rubrics.length > 0 &&
				questionInfo?.answer?.rubrics.map((rubricInfo, idx) => {
					return (
						<MarkTable
							key={rubricInfo.id}
							isSelected={markTableIdx === idx}
							rubricInfo={rubricInfo}
							questionKey={questionInfo.key}
							// answerCode={questionInfo?.answer?.code}
							// requestionAIMap={requestionAIMap}
							requestingAI={requestingAI}
							changeIsUpdating={changeIsUpdating}
							isExported={isExported}
						/>
					)
				})
			}

			{/*{ questionInfo?.answer?.rubrics.length > 0 &&*/}
			{/*  <MarkTable*/}
			{/*    key={questionInfo?.answer?.rubrics[markTableIdx]?.id}*/}
			{/*    rubricInfo={questionInfo?.answer?.rubrics[markTableIdx]}*/}
			{/*    questionKey={questionInfo.key}*/}
			{/*    answerCode={questionInfo?.answer?.code}*/}
			{/*    requestionAIMap={requestionAIMap}*/}
			{/*  />*/}
			{/*}*/}

			<Box >
				<Box display='flex' alignItems='center' justifyContent='center' className={requestingAI ? classes.disabledAiMarkBasis : classes.aiMarkBasis} onClick={handleClickAIMarkBasis} >
					<Typography>{i18next.t("AI 채점 근거")}</Typography>
					<IconButton className={clsx(classes.iconBtnReset, classes.iconPosition)} style={{ transform: !openAIMarkBasis ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple disabled={requestingAI}>
						<MarkToggleIcon />
					</IconButton>
				</Box>
			</Box>

			{openAIMarkBasis &&
				<Box
					ref={AIFeedbackRef}
					onClick={handleMouseEnterInTooltip}
				>
					<Box className={classes.aiMarkTextBoxSelf}>
						{/*<Box className={classes.skeletonUI}/>*/}

						{currentAIFeddback ?
							<Typography>
								{currentAIFeddback}
							</Typography>
							:
							<Typography>
								{i18next.t("AI 채점 시 근거가 자동으로 표시됩니다.")}
							</Typography>
						}
					</Box>
				</Box>
			}

			<Popover
				id={tooltipId}
				open={!!aIAnchorEl}
				anchorEl={aIAnchorEl}
				onClose={handleCloseTooltip}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				className={classes.popoverTooltip}
			>
				<Box className={classes.tooltipTop}>
					<TooltipTipIcon />
				</Box>
				<Box display={'flex'} justifyContent={'flex-end'} className={classes.tooltipBtn}>
					<Button disableRipple onClick={handleCloseTooltip}>
						<TooltipClose />
					</Button>
				</Box>
				<Typography className={classes.tooltipTitle}>{i18next.t("AI채점 근거")}</Typography>
				<Typography className={classes.tooltipText}>
					<span>{i18next.t("‘AI 채점 근거’는 AI 채점 시 자동으로 표시됩니다.")}</span>
					<span>{i18next.t("선생님이 피드백을 작성하는데 참고로 활용할 수 있습니다.")}</span>
					<span>{i18next.t("학생이 받아보는 채점 결과표에는 표시되지 않습니다.")}</span>
				</Typography>
			</Popover>

			{!requestingAI &&
				projectRubricsLength > 1 &&
				<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
					<Stack spacing={2}>
						<Pagination
							count={questionInfo?.answer?.rubrics.length || 0}
							boundaryCount={2}
							showFirstButton
							showLastButton
							onChange={handleClickMarkTablePage}
							variant="outlined"
						/>
					</Stack>
				</Box>
			}

			{!isExported && !openNewOwnerFeedback &&
				<Box className={classes.ownerFeedbackBoxMarginTop}>
					<Button className={classes.ownerMarkFeedbackBtn} disableRipple onClick={handleClickOpenNewOwnerFeedback}>
						<AddFeedbackPlus />
						<Typography>{i18next.t("피드백 추가")}</Typography>
					</Button>
				</Box>
			}

			{openNewOwnerFeedback &&
				<Box className={classes.ownerFeedbackBoxMarginTop}>
					<Box display='flex' alignItems='center' justifyContent='center' className={isOwnerFeedbackEmpty ? classes.emptyOnwerFeedbackBox : classes.onwerFeedbackBox} onClick={handleClickFeedbackBtn}>
						<Typography>{i18next.t("피드백")}</Typography>
						<IconButton className={clsx(classes.iconBtnReset, classes.iconPosition)} style={{ transform: !openFeedback ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple disabled={requestingAI}>
							<MarkToggleIcon />
						</IconButton>
					</Box>
				</Box>
			}

			{isExported ?
				(openFeedback && questionInfo?.answer?.ownerFeedback &&
					<Box className={classes.ownerFeedbackViewer}>
						<TextViewer
							currentHTML={questionInfo.answer.ownerFeedback}
						/>
					</Box>
				) :
				<Box style={(!requestingAI && openFeedback) ? null : { display: 'none' }}>
					{/*<Box display='flex' alignItems='center' justifyContent='center' className={classes.aiMarkBasisFeedback} >*/}
					{/*    <Typography>피드백</Typography>*/}
					{/*</Box>*/}

					<Box className={classes.ownerFeedbackEditer} sx={{ position: 'relative' }}>
						{/*에디터 height: 140*/}
						<TextEditor
							// height={140}
							currentHTML={questionInfo?.answer?.initialOwnerFeedback}
							onInitInitialContent={handleInitInitialOwnerFeedback}
							placeholder={i18next.t("내용을 입력하세요. (최대 10,000자)")}
							onUploadFile={handleUploadFile}
							onSetHTML={handleChangeFeedback}
							isReset={isFeedbackReset}
							processAfterReset={handleProcessAfterFeedbackReset}
							onGetTextLen={handleChangeFeedbackTextLen}
							maxTextLen={10000}

						// currentHTML={currentCorrectedFeedbackHTML}
						// onInitInitialContent={handleInitInitialFeedback}
						/>
						{/*<IconButton className={clsx(classes.iconBtnReset, classes.deleteIconPosition)} disableRipple onClick={e => handleClickDeleteFeedback(e)}>*/}
						{/*    <TrashDeleteIcon />*/}
						{/*</IconButton>*/}
					</Box>
					<Box className={clsx(classes.iconBtnReset, classes.textLenPosition)}>
						<Typography> {feedbackTextLen} / 10,000 </Typography>
					</Box>
				</Box>
			}

			{/*{ openAIMarkBasis &&*/}
			{/*  <Box className={classes.aiMarkTextBoxSelf}>*/}
			{/*      /!*<Box className={classes.skeletonUI}/>*!/*/}

			{/*      {currentAIFeddback ?*/}
			{/*        <Typography>*/}
			{/*            {currentAIFeddback}*/}
			{/*        </Typography>*/}
			{/*        :*/}
			{/*        <Typography>*/}
			{/*            AI 채점 시 근거가 자동으로 표시됩니다.*/}
			{/*        </Typography>*/}
			{/*      }*/}
			{/*  </Box>*/}
			{/*}*/}

			{openAIAlertByClick &&
				<MessageDialog
					open={openAIAlertByClick}
					titleText={i18next.t("AI 개별 채점하기")}
					submitText={i18next.t("AI채점하기")}
					onClick={handleClickSubmitBtnInAIAlert}
					onClose={handleCloseAIAlert}
					isConfirm={true}
				>
					{/*<>*/}
					{/*    <Box className={classes.uploadBox}>*/}
					{/*        <Typography>AI 채점이 실행되면 기존 결과값은 사라집니다. 실행하시겠습니까?</Typography>*/}
					{/*    </Box>*/}
					{/*</>*/}

					{i18next.t("AI 채점이 실행되면 기존 결과값은 사라집니다. 실행하시겠습니까?")}
				</MessageDialog>
			}

			{openFeedbackReset &&
				<MessageDialog
					open={openFeedbackReset}
					onClick={handleClickSubmitInFeedbackResetAlert}
					onClose={handleCloseFeedbackResetAlert}
					isConfirm={true}
				>
					{/*<Box style={{ textAlign: 'center' }}>*/}
					{/*    <Typography>입력한 피드백이 삭제됩니다.</Typography>*/}
					{/*</Box>*/}

					{i18next.t("입력한 피드백이 삭제됩니다.")}
				</MessageDialog>
			}
		</Box>
	);
});