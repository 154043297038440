import {
	Box,
	Button,
	Collapse,
	IconButton,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import React from "react";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TestManageTableNStlye } from "../../styles/TestManageTableNStlye";

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import '@mui/x-date-pickers/AdapterDayjs';
import clsx from "clsx";
import { ReactComponent as ManageCopyN } from '../../../../common/images/ManageCopyN.svg';
import { ReactComponent as ManageEditN } from '../../../../common/images/ManageEditN.svg';
import { ReactComponent as StepIconActiveN } from '../../../../common/images/StepIconActiveN.svg';
import { ReactComponent as StepIconN } from '../../../../common/images/StepIconN.svg';
// import 'dayjs/locale/ko';
import { convertUTCToKST } from "../../../../hooks/convertUTCToKST";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import {
	convertSubjectNameTypeEnToKr,
	convertSubjectTypeEnToKr,
	ProjectSchoolLabelType
} from "../../../../stores/ProjectStore";
import { CustomTooltip } from "../../../components/Tooltip";

import { useTranslation } from 'react-i18next';
import { useStores } from "../../../../hooks/useStores";
import { IProject } from "../../../../repositories/model/IProject";
import { ProjectSchoolType } from "../../../../stores/enums/ProjectStoreEnums";
import { ManagementButton } from "./ManagementButton";


type Props = {
	project: IProject,
	index: number,
	page: number,
	rowsPerPage: number,
	isForShared: boolean,
	openAssignments: boolean,
	expendable?: boolean
	onClick: () => void
}

function TemplateProjectListRows(props: Props) {
	const { navigateStore, messageStore, projectStore } = useStores();

	const i18next = useTranslation();
	const classes = useEmotionStyles( TestManageTableNStlye );
	const { project, index, page, rowsPerPage, isForShared, expendable } = props;


	const [currentStep, setCurrentStep] = React.useState(null);
	// const [anchorEl2, setAnchorEl2] = React.useState(null);

	const naviCustom = useNaviCustom();

	// const handleClickTooltip2 = (event) => {
	// 	setAnchorEl2(event.currentTarget);
	// };
	// const handleClose2 = () => {
	// 	setAnchorEl2(null);
	// };

	// const openTool2 = Boolean(anchorEl2);
	// const id2 = openTool2 ? 'simple-popover' : undefined;

	// const handleClickProjectName = () => {
	// 	const { code } = project;
	// 	naviCustom.go(`/projects/${code}/create/info`);
	// 	// props.navigate(`/projects/${code}/create/info`);
	// }

	const handleEdit = () => {
		const { code, projectState } = project;
		console.log("projectState : ", projectState);

		if (projectState) {
			let currentStep: "info" | "exam" | "answer" | "rubic" | "question" | "view" | "plan" =
				(Object.keys(project.projectState)
					.filter(key => key === "info" || key === "exam" || key === "answer" || key === "rubric")
					.find(key => !project.projectState[key])) as any;

			if (currentStep === "exam" || currentStep === "plan") {
				currentStep = "question";
			}
			if (!currentStep) {
				currentStep = "view";
			}

			naviCustom.go(`/projects/${code}/create/${currentStep}`);
			// props.navigate(`/projects/${code}/create/${currentStep}`);
		} else {
			props.onClick();
		}
	}

	const handleCopy = async (project) => {
		const result = await messageStore.confirm(i18next.t(`[${project.name}] 프로젝트를 나의 평가 과제로 복제하시겠습니까?`));
		if (result) {
			projectStore.copyTemplate(project.code);
		}
	}

	const getStep = (label, active, isLast) => {
		return (
			<Box className={
				isLast ?
					active ? clsx(classes.stepIconBoxList, classes.stepIconActiveBox) : classes.stepIconBoxList
					: active ? clsx(classes.stepIconBox, classes.stepIconActiveBox) : classes.stepIconBox}>
				{active ? <StepIconActiveN /> : <StepIconN />}
				<Typography>{label}</Typography>
			</Box>
		);
	}

	React.useEffect(() => {
		if (project && project.projectState) {
			getCurrentStep();
		}
	}, [project.projectState, navigateStore.language]);

	const getCurrentStep = () => {
		let result;
		const { info, exam, rag, answer, rubric } = project.projectState;
		if (rubric) {
			result = i18next.t("평가 기준표");
		} else if (answer) {
			result = i18next.t("모범 답안");
		} else if (rag) {
			result = i18next.t("참고 자료");
		} else if (exam) {
			result = i18next.t("문항 생성")
		} else if (info) {
			result = i18next.t("과제 개요");
		} else {
			result = "-"
		}
		setCurrentStep(result);
	}

	const [showAssignments, setShowAssignments] = React.useState(props.openAssignments);
	React.useEffect(() => { setShowAssignments(props.openAssignments) }, [props.openAssignments]);
	const handleClickOpenAssignments = () => {
		setShowAssignments(!showAssignments);
	}

	return (
		<>
			<TableRow id={project.code} key={project.code} sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}>

				{expendable &&
					<TableCell className={classes.tableNoBox}>
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={handleClickOpenAssignments}
						>
							{showAssignments ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</IconButton>
					</TableCell>
				}

				<TableCell className={classes.tableNoBox}>
					{((page - 1) * rowsPerPage) + index + 1}
				</TableCell>
				<TableCell>
					<Button className={classes.tableStudentNameBtn}
						onClick={handleEdit}
						disableRipple>
						<Typography>{(project.name)}</Typography>
					</Button>
				</TableCell>
				{(!navigateStore.simpleMode) &&
					<TableCell> {i18next.t("{{curriculumYear}}년 개정", { curriculumYear: project.curriculumYear })} </TableCell>
				}
				<TableCell> {i18next.t(ProjectSchoolLabelType[project.schoolType])} </TableCell>
				{
					project.schoolType === ProjectSchoolType.NOGRADE
						? <TableCell> {i18next.t("{{grade}}", { grade: project.grade })} </TableCell>
						: <TableCell> {i18next.t("{{grade}}학년", { grade: project.grade })} </TableCell>
				}

				<TableCell> {convertSubjectTypeEnToKr(project.subject)} </TableCell>
				{(!navigateStore.simpleMode) &&
					<TableCell> {convertSubjectNameTypeEnToKr(project.subjectName)} </TableCell>
				}
				<TableCell> {convertUTCToKST(project.createdDatetime).format("YYYY-MM-DD")} </TableCell>
				{!isForShared &&
					<TableCell>
						<CustomTooltip title={
							<Box className={classes.tooltipStepIconCont}>
								{getStep(i18next.t("과제 개요"), project && project.projectState && project.projectState.info, false)}
								{getStep(i18next.t("문항 생성"), project && project.projectState && project.projectState.exam, false)}
								{getStep(i18next.t("참고 자료"), project && project.projectState && project.projectState.rag, false)}
								{getStep(i18next.t("모범 답안"), project && project.projectState && project.projectState.answer, false)}
								{getStep(i18next.t("평가 기준표"), project && project.projectState && project.projectState.rubric, true)}
							</Box>
						}>
							<Box className={clsx(classes.stepIconBoxList, classes.stepIconActiveBox)}>
								{/*<StepIconActiveN/>*/}
								{currentStep}
							</Box>
						</CustomTooltip>
					</TableCell>
				}
				<TableCell>
					{isForShared ?
						<ManagementButton label={"평가과제 복제"}
							button={<ManageCopyN />}
							onClick={() => handleCopy(project)} />
						:
						<ManagementButton label={"평가과제 편집"}
							button={<ManageEditN />}
							onClick={handleEdit} />
					}

				</TableCell>
			</TableRow>

			{expendable &&
				<TableRow style={{ height: showAssignments ? 'auto' : 0 }}>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0, height: showAssignments ? 'auto' : 0 }} colSpan={2}>
						<Collapse in={showAssignments} timeout="auto" unmountOnExit>
							<Typography variant="h6" gutterBottom component="div">

							</Typography>
						</Collapse>
					</TableCell>

					<TableCell style={{ paddingBottom: 0, paddingTop: 0, height: showAssignments ? 'auto' : 0 }} colSpan={(!navigateStore.simpleMode ? 9 : 7)}>
						<Collapse in={showAssignments} timeout="auto" unmountOnExit>
							<Typography variant="h6" gutterBottom component="div">
								asaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa.aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaassdfasdfdffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssffs..
							</Typography>
						</Collapse>
					</TableCell>
				</TableRow>
			}
		</>
	);
}

export default TemplateProjectListRows;
