import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import clsx from "clsx";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TemplateProjectViewStyle } from "../../styles/TemplateProjectViewStyle";

import { useTranslation } from 'react-i18next';
import { IRubricInfo } from "../../../../repositories/model/transfer/IRubricInfo";

type Props = {
	rubricInfo: IRubricInfo
}

export const ProjectRubricTable = (props: Props) => {
	const i18next = useTranslation();

	const classes = useEmotionStyles( TemplateProjectViewStyle );
	const { rubricInfo } = props;

	return (
		<Box sx={{ margin: '20px 0px 10px' }}>

			<TableContainer component={Paper} className={classes.evaluationTableBox}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell width='11%' align="center" className={classes.bgLeftBox}>{i18next.t('평가항목')}</TableCell>
							<TableCell width='16%' colSpan={2} align="center" className={classes.bgLeftBox}>{i18next.t('배점')}</TableCell>
							<TableCell width='73%' align="center">{i18next.t('채점 기준')}</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow >
							{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
							<TableCell width='28%' rowSpan={rubricInfo?.items?.length + 1 || 0} align="center" className={classes.bgLeftBox}>
								<Typography className={classes.rubricNameItem}>
									{rubricInfo.name}
								</Typography>
								<br />
								<Typography className={classes.fontScoreItem}>{rubricInfo.score}<span className={classes.fontScorePoint}> {i18next.t('rubric점', { count: rubricInfo.score })}</span></Typography>
							</TableCell>
						</TableRow>

						{rubricInfo.items.length > 0 &&
							rubricInfo.items.map((item, idx) => {
								return (
									<TableRow key={idx} >

										<TableCell align="center" className={classes.evaluationTableBgColor}>
											<Typography className={classes.fontScoreName}>{item.name}</Typography>
										</TableCell>
										<TableCell align="center" className={classes.evaluationTableBgColor}>
											<Typography className={classes.scoreMark}>
												{item.score}<span className={classes.fontScorePoint}>
													{i18next.t('rubric점', { count: item.score })}
												</span>
											</Typography>
										</TableCell>
										<TableCell className={classes.borderLine}>
											<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
												<Typography className={classes.fontScoreStandard}>
													{item.standard}
												</Typography>
											</Box>
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}






