import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Paper,
	Popover,
	TableContainer,
	Theme,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as ScreenMinus } from '../../../../common/images/ScreenMinus.svg';
import { ReactComponent as ScreenPlus } from '../../../../common/images/ScreenPlus.svg';
import { ReactComponent as TooltipClose } from '../../../../common/images/TooltipClose.svg';
import { ReactComponent as TooltipTipIcon } from '../../../../common/images/TooltipTipIcon.svg';
import { ReactComponent as WarningIcon } from '../../../../common/images/WarningIcon.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ResultReviewStyle } from "../../styles/ResultReviewStyle";

import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
// import { withRouter } from "../../../../components/WithRouter";
import { convertUTCToKST } from "../../../../hooks/convertUTCToKST";
import useNaviCustom from "../../../../hooks/useNaviCustom";

import { useStores } from "../../../../hooks/useStores";
import { DetailPageType } from "../../../../stores/support/DetailPageType";
import { ResultHeaderTable } from "../../000_Common/ResultHeader";
import { ExamRow } from "./ExamRow";
import { ResultRow } from "./ResultRow";
import { StandardAnswerRow } from "./StandardAnswerRow";
import { useParams } from "react-router-dom";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = {
}

function ResultReview(props: Props) {
	const params = useParams();
	const i18next = useTranslation();
	const classes = useEmotionStyles(ResultReviewStyle);
	const { navigateStore, projectSubmissionAnswerStore, messageStore } = useStores();

	const {
		submissionInfo,
		questionList,
		examOrderAndQuestionsLengthMap,
	} = projectSubmissionAnswerStore;

	const [page, setPage] = React.useState(0);
	const [pages, setPages] = React.useState([]);
	const [pdfWidth, setPdfWidth] = React.useState(0);

	const [anchorEl, setAnchorEl] = React.useState<Element>(null);
	const openTool = Boolean(anchorEl);
	const id = openTool ? 'simple-popover' : undefined;
	const pdfBoxRef = React.useRef<HTMLElement>();
	const [viewScale, setViewScale] = React.useState(1);
	const [submissionCode, setSubmissionCode] = React.useState("");

	const naviCustom = useNaviCustom();

	React.useEffect(() => {
		navigateStore.changeNavigateRootPage(false);
		navigateStore.changeNavigateDetailPage(DetailPageType.STUDENT);
		navigateStore.changeTopBarLeftItem(topBarLeftItem);
		navigateStore.changeTopBarRightItem(topBarRightItem);
		window.addEventListener('resize', handleResize);

		return () => {
			navigateStore.changeNavigateDetailPage(null);
			navigateStore.changeTopBarLeftItem(null);
			navigateStore.changeTopBarRightItem(null);
			window.removeEventListener('resize', handleResize);
		}
	}, []);

	React.useEffect(() => {
		const currentSubmissionCode = params.submissionCode;
		if (!currentSubmissionCode || currentSubmissionCode === submissionCode) return;

		setSubmissionCode(currentSubmissionCode);
		projectSubmissionAnswerStore.init();

		const callBack = () => naviCustom.go(`/projects`, true);
		// const callBack = () => props.navigate(`/projects`, {replace: true});
		projectSubmissionAnswerStore.getSubmissionInfoBySubmissionCode(currentSubmissionCode, callBack);
	}, [params]);

	React.useEffect(() => {
		navigateStore.changeTopBarLeftItem(topBarLeftItem);
	}, [submissionInfo?.projectName]);

	const handleClickReviewComplete = async () => {
		console.log("handleClickReviewComplete")
		const result = await messageStore.confirm(i18next.t("리뷰를 완료하시고 목록으로 돌아가시겠습니까?"));
		if (!result) return;

		naviCustom.go(`/projects`);
		// props.navigate(`/projects`);
	};

	const topBarLeftItem = () => {
		return (
			<p>&nbsp;&nbsp;&nbsp;<strong>
				{submissionInfo?.projectName}&nbsp;
				{i18next.t("서·논술형 평가 결과")}
			</strong>
			</p>
		)
	};

	const topBarRightItem = () => {
		return (
			<Box display='flex'>
				<Box className={clsx(classes.applyMarkBtn, classes.applyMarkBtnActive)}>
					<Button disableRipple onClick={handleClickReviewComplete}>
						<Typography>{i18next.t("리뷰 완료")}</Typography>
					</Button>
				</Box>
			</Box>
		)
	};

	const loadErr = (e) => {
		console.log(i18next.t('Error while loading document! '), e.message);
	};

	const loadSuccess = ({ numPages }) => {
		let tempArr = [];
		for (let i = 0; i < numPages; i++) {
			tempArr.push(i + 1);
		}

		setPage(1);
		setPages(tempArr);

		setPdfWidth(pdfBoxRef.current?.offsetWidth || 0);
	};

	const handleResize = () => {
		setPdfWidth(pdfBoxRef.current?.offsetWidth || 0);
	};

	const handleClickTooltip = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseTooltip = () => {
		setAnchorEl(null);
	};

	const handleClickTooltipClosedBtn = () => {
		handleCloseTooltip();
	};

	const handleViewPlus = () => {
		if (viewScale >= 4) return;

		setViewScale(prev => prev * 2);
	};

	const handleViewMinus = () => {
		if (viewScale <= 1 / 4) return;

		setViewScale(prev => prev / 2);
	};

	const currentViewScalePercentage = React.useMemo(() => {
		return viewScale * 100
	}, [viewScale]);

	const standardTotalScore = React.useMemo(() => {
		let totalScore = 0;
		questionList.map(question => {
			totalScore += Number(question.score);
		})

		return totalScore;
	}, [questionList]);

	const markedTotalScore = React.useMemo(() => {
		let totalScore = 0;
		questionList.map(question => {
			if (!question.answer) return;

			totalScore += Number(question.answer.totalScore);
		});

		return totalScore;
	}, [questionList]);

	const getExamInfoByExamCode = (examCode: string) => {
		const examInfo = submissionInfo?.exams.find(exam => exam.code === examCode);
		return examInfo;
	};

	// const handleClickPageUp = () => {
	// 	const rootTag = document.getElementById(`detail-page-root`);
	// 	if (rootTag) {
	// 		rootTag.scrollIntoView({ behavior: "smooth" });
	// 	}
	// };

	const disabledViewPlus = React.useMemo(() => {
		return viewScale >= 4;
	}, [viewScale]);

	const disabledViewMinus = React.useMemo(() => {
		return viewScale <= 1 / 4;
	}, [viewScale]);

	const questionObjByExamOrder = React.useMemo(() => {
		const obj = {};

		questionList.map(q => {
			if (!obj.hasOwnProperty(q.parentOrder)) {
				obj[q.parentOrder] = [];
			}

			obj[q.parentOrder].push(q);
		});

		return obj;
	}, [questionList]);

	const examOrderList = React.useMemo(() => {
		return Object.keys(questionObjByExamOrder);
	}, [questionObjByExamOrder]);


	const currentCreatedDatetime = React.useMemo(() => {
		if (submissionInfo?.project?.createdDatetime) {
			return convertUTCToKST(submissionInfo?.project.createdDatetime).format(i18next.t("YYYY년 M월 D일"));
		}

		return '-';
	}, [submissionInfo]);

	const currentGradeClassName = React.useMemo(() => {
		if (!submissionInfo?.grade || !submissionInfo?.className) return '-';

		return `${submissionInfo?.grade}${i18next.t('학년')} ${submissionInfo?.className}${i18next.t('반')}`;
	}, [submissionInfo?.grade, submissionInfo?.className]);

	return (
		<div className={classes.root}>
			{!projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode &&
				<Box
					className={classes.padding}
				>
					<Box className={classes.mainContentBoxLeft}>

						{submissionInfo?.project?.includedOnline
							?
							<Box>
								<Box className={classes.titleBoxStudent}>
									<Typography>{i18next.t("평가 과제")}</Typography>
								</Box>
								<Box>
									<TableContainer component={Paper} className={classes.tableBox}>
										<ResultHeaderTable
											schoolName={submissionInfo?.school}
											currentGradeClassName={currentGradeClassName}
											userName={submissionInfo?.userName}
											year={submissionInfo?.project?.year}
											semester={submissionInfo?.project?.semesterType} currentCreatedDatetime={currentCreatedDatetime}

											titleBoxClassName={classes.titleBox}
										/>
									</TableContainer>
								</Box>

								{examOrderList.length > 0 &&
									examOrderList.map((examOrder, idx) =>
										<ExamRow
											key={idx}
											examInfo={getExamInfoByExamCode(questionObjByExamOrder[examOrder][0].projectExamCode)}
											questionList={questionObjByExamOrder[examOrder]}
											examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
										/>
									)}
							</Box>
							:
							<Box>
								<Box className={classes.titleBoxStudent}>
									<Typography>{i18next.t("평가 과제")}</Typography>
									<Box display="flex" alignItems="center">
										<Typography className={classes.viewScale}>{currentViewScalePercentage}%</Typography>
										{!disabledViewPlus &&
											<IconButton className={classes.iconBtnReset} disableRipple onClick={handleViewPlus} disabled={disabledViewPlus}>
												<ScreenPlus />
											</IconButton>
										}
										{!disabledViewMinus &&
											<IconButton className={classes.iconBtnReset} disableRipple onClick={handleViewMinus} disabled={disabledViewMinus}>
												<ScreenMinus />
											</IconButton>
										}
									</Box>
								</Box>

								<Box className={classes.compareContent} ref={pdfBoxRef}
								// style={{alignItems:"center"}}
								>
									{/*todo 여러 파일 받을때는 files map 으로 변경*/}
									<Document
										file={submissionInfo && submissionInfo?.files && submissionInfo?.files.length > 0 ? submissionInfo?.files[0].downloadUrl : ""}
										onLoadError={loadErr}
										onLoadSuccess={loadSuccess}>
										{pages && pages.map((item) => {
											return (
												<Page key={`page${item}`} className={classes.page} pageNumber={item} renderTextLayer={false} renderAnnotationLayer={false} scale={viewScale} width={pdfWidth} />
											)
										})}
									</Document>
								</Box>
							</Box>
						}
					</Box>

					<Box className={classes.mainContentBoxRight}>
						<Box display='flex' alignItems='center' justifyContent='space-between' className={clsx(classes.compareTop, classes.compareTopColor)}>
							<Box display='flex' alignItems='center' justifyContent='center'>
								<Typography className={classes.commonSubTitle}>
									{i18next.t("채점 결과")}
								</Typography>
								<IconButton className={clsx(classes.iconBtnReset, classes.examInfoIcon)} onClick={handleClickTooltip} disableRipple >
									<WarningIcon />
								</IconButton>
								<Popover
									id={id}
									open={openTool}
									anchorEl={anchorEl}
									onClose={handleCloseTooltip}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'center',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
									className={classes.popoverTooltip}
								>
									<Box className={classes.tooltipTop}>
										<TooltipTipIcon />
									</Box>
									<Box display={'flex'} alignItems='center' justifyContent='space-between' style={{ marginTop: '7px', marginBottom: '12px' }}>
										<Typography className={classes.tooltipTitle}>{i18next.t("채점 결과 근거와 진단 참고")}</Typography>

										<Box display={'flex'} justifyContent={'flex-end'} className={classes.tooltipBtn}>
											<Button disableRipple onClick={handleClickTooltipClosedBtn}>
												<TooltipClose />
											</Button>
										</Box>
									</Box>
									<Typography className={classes.tooltipText}>
										{i18next.t("선생님이 작성한 ‘채점 기준표'를 기준으로 평가 항목에 대한 배점을 부여합니다.")}<br /><br />{i18next.t("상세한 채점 결과 설명과 의견은 선생님의 코멘트에 기재되어 있습니다.")}<br />{i18next.t("모범 답안을 참고하여 자신이 어떤 부분에서 부족했는지 판단하고 비교해 볼 수 있습니다.")}
									</Typography>
								</Popover>
							</Box>
						</Box>

						<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginTop: "40px" }}>
							<Typography className={classes.amountScoreTitle}>{i18next.t("총점")}</Typography>
						</Box>
						<Box className={classes.totalAmountScore}>
							<Typography><span>{markedTotalScore}</span>/{standardTotalScore}<span className={classes.amountScoreText}>{i18next.t("점")}</span></Typography>
						</Box>

						{questionList.length > 0 &&
							questionList.map((question, idx) =>
								<ResultRow
									key={idx}
									// examInfo={getExamInfoByExamCode(question.projectExamCode)}
									questionInfo={question}
									examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
								/>
							)}

						<Box className={clsx(classes.greenInnerBox, classes.redInnerBox)}>
							<Box className={clsx(classes.tagNameBox, classes.tagNameBoxRed)}>
								<Typography>{i18next.t("모범 답안")}</Typography>
							</Box>

							{questionList.length > 0 &&
								questionList.map((question, idx) =>
									<StandardAnswerRow
										key={idx}
										questionInfo={question}
										examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
									/>
								)}
						</Box>

						<Box display='flex' alignItems='center' justifyContent='center'>
							<Box className={clsx(classes.borderGuide, classes.borderGuideText)}>
								<Typography>{i18next.t("마지막입니다.")}</Typography>
							</Box>
						</Box>

						{/*<IconButton*/}
						{/*  className={classes.pageUpIcon}*/}
						{/*  onClick={handleClickPageUp}*/}
						{/*  disableRipple>*/}
						{/*    <PageUpIcon />*/}
						{/*</IconButton>*/}
					</Box>
				</Box>
			}

			{projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("조회 중입니다...")}</Typography>
				</Backdrop>
			}
		</div>
	);
}

export default observer<typeof ResultReview>(ResultReview);

