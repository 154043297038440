import {
	Box,
	Typography
} from "@mui/material";
import useEmotionStyles from "../../../../styles/useEmotionStyles";
import { CompareDataStyle } from "../../../../styles/CompareDataStyle";

import { useTranslation } from 'react-i18next';

import TextViewer from "../../../../../../components/common/TextViewer";

import { ChangeFlag } from "../../../../../../repositories/model/support/ChangeFlag";
import { useCurrentParagraphHTML } from "../../../../000_Common/001_Functions/useCurrentParagraphHtml";
import { useIsParagraphAndSourceBoxEmpty } from "../../../../000_Common/001_Functions/useIsParagraphAndSourceBoxEmpty";
import { QuestionAndReferenceTabQuestionRow } from "./QuestionAndReferenceTabQuestionRow";

type Props = {
	examInfo: any;
	index: number;
	editMode: boolean;
	answerList: any;
	changeAnswer: any;
}
export const QuestionAndReferenceTabExamRow = (props: Props) => {
	const i18next = useTranslation();
	const { examInfo, index, editMode, answerList, changeAnswer } = props;
	const classes = useEmotionStyles(CompareDataStyle);

	// const questionListLen = React.useMemo(() => {
	// 	return examInfo.questions.filter(q => q.changeFlag !== FlagType.DELETED).length;
	// }, [examInfo]);

	// const currentParagraphHTML = React.useMemo(() => {
	// 	if (!examInfo.paragraph && !examInfo.source) return "<></>";
	// 	const source = examInfo.source.replace(/\n/g, "<br>");

	// 	return (
	// 		"<p>" +
	// 		"<div style='font-size: 1rem;font-weight: 500;'>" +
	// 		examInfo.paragraph +
	// 		"\n\n" +
	// 		'</div>' +
	// 		"<div style='font-size: 0.875rem; text-align: right'>" +
	// 		source +
	// 		'</div>' +
	// 		"</p>"
	// 	);

	// 	// return "<p>"+"<div style='font-size: 1rem;font-weight: 500;'>" + examInfo.paragraph + "\n\n"+'</div>' + "<div style='font-size: 0.875rem; text-align: right'>" + examInfo.source + '</div>' + "</p>";
	// }, [examInfo.paragraph, examInfo.source]);

	const currentParagraphHTML = useCurrentParagraphHTML({
		examInfo,
		paragraph: { fontSize: "1rem", fontWeight: 500, paddingLeft: "10px" },
		source: { fontSize: "0.875rem", textAlign: "right", paddingRight: "10px" }
	});

	// const isParagraphAndSourceBoxEmpty = React.useMemo(() => {
	// 	const emptyParagraph = !examInfo.paragraph || (DataUtils.getTagArrFromHtml(examInfo.paragraph, 'img').length === 0 && !DataUtils.getTextFromHtml(examInfo.paragraph));
	// 	const emptySource = !examInfo.source;

	// 	return emptyParagraph && emptySource;
	// }, [examInfo.paragraph, examInfo.source]);
	const isParagraphAndSourceBoxEmpty = useIsParagraphAndSourceBoxEmpty(examInfo);

	return (
		<>
			<Typography className={classes.textArticleNumber}>{`${index}${i18next.t('번 문항')}`}</Typography>
			<Box className={classes.compareRound}>
				{examInfo.directive && (
					<Typography className={classes.textArticleTitle}>{i18next.t(examInfo.directive)}</Typography>
				)}
				{!isParagraphAndSourceBoxEmpty && (
					<Box display='flex' alignItems='center' justifyContent='left' className={classes.textArticle}>
						<TextViewer
							currentHTML={currentParagraphHTML}
						/>
					</Box>
				)}

				{examInfo.questions.length > 0 &&
					examInfo.questions
						.filter(q => q.changeFlag !== ChangeFlag.DELETED)
						.map(question => {
							return (
								<QuestionAndReferenceTabQuestionRow
									key={question.projectExamQuestionNum}
									examInfo={examInfo}
									editMode={editMode}
									answerList={answerList}
									changeAnswer={changeAnswer}
									// projectExamList={projectExamList}
									questionInfo={question}
								/>
							)
						})
				}
			</Box>
		</>
	)
};