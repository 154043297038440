import React from 'react';
import {
	Box,
	Button,
	Dialog,
	IconButton,
	Typography,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { ReactComponent as DialogClose } from "../../../../common/images/DialogClose.svg";
import { ReactComponent as CheckListSave } from '../../../../common/images/CheckListSave.svg';
import { ReactComponent as InformationRed } from '../../../../common/images/InformationRed.svg';
import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";
import useEmotionStyles from '../../styles/useEmotionStyles';

const TemplateGuideDialogStyle: (theme: any) => Record<string, CSSInterpolation> = (theme) => ({
	dataOpenDialog: {
		'& .MuiDialog-paper': {
			maxWidth: '1000px',
			width: '100%',
			margin: '24px',
			borderRadius: '8px',
		},
	},
	dialogTop: {
		padding: '20px 30px',
		borderBottom: '1px solid #e1e1e1',
		'& p': {
			fontSize: '1.125rem',
			fontWeight: 700,
		},
	},
	dialogContent: {
		padding: '30px',
		overflowY: 'auto',
		maxHeight: 'calc(100vh - 260px)',
	},
	borderBox: {
		border: '1px solid #e1e1e1',
		borderRadius: '8px',
		padding: '20px',
		marginBottom: '24px',
	},
	stepCont: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '12px',
	},
	stepNumber: {
		width: '24px',
		height: '24px',
		borderRadius: '50%',
		backgroundColor: '#2377cc',
		color: '#fff',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '8px',
		fontSize: '0.875rem',
	},
	listBoxTitleEach: {
		fontSize: '1rem',
		fontWeight: 700,
	},
	greenTitle: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		fontSize: '0.875rem',
		fontWeight: 500,
		color: '#2EA090',
		marginBottom: '8px',
		'& svg': {
			width: '16px',
			height: '16px',
		},
	},
	redTitle: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		fontSize: '0.875rem',
		fontWeight: 500,
		color: '#d92020',
		marginBottom: '8px',
		'& svg': {
			width: '16px',
			height: '16px',
		},
	},
	guideTextList: {
		fontSize: '0.875rem',
		lineHeight: 1.6,
		color: '#666',
	},
	guideTextListRed: {
		fontSize: '0.875rem',
		lineHeight: 1.6,
		color: '#d92020',
	},
	tableBox: {
		border: '1px solid #e1e1e1',
		borderRadius: '8px !important',
		overflow: 'hidden',
		boxShadow: 'none !important',
		'& th': {
			backgroundColor: '#f8f9fa',
			fontWeight: 700,
			fontSize: '0.875rem',
			padding: '12px 16px',
			borderBottom: '1px solid #e1e1e1',
		},
		'& td': {
			fontSize: '0.875rem',
			padding: '12px 16px',
			borderBottom: '1px solid #e1e1e1',
		},
	},
	dialogBtnGroup: {
		padding: '20px 30px',
		borderTop: '1px solid #e1e1e1',
		gap: '10px',
	},
	dialogBtn: {
		width: '120px',
		height: '42px',
		border: '1px solid #999999',
		borderRadius: '4px',
		'&:hover': {
			backgroundColor: '#f8f9fa',
		},
	},
	dialogBtnSave: {
		width: '120px',
		height: '42px',
		backgroundColor: '#2377cc',
		color: '#fff',
		borderRadius: '4px',
		'&:hover': {
			backgroundColor: '#1b5ca5',
		},
	},
});

const exampleContent = [
	{ type: 'parent', level: '##문제', description: '문제의 시작을 나타냅니다.', mandatory: true },
	{ type: 'child', level: '##지시문', description: '(생략가능) 이후에 지시문을 추가합니다. 일반 텍스트만 가능합니다.', mandatory: false },
	{ type: 'child', level: '##지문', description: '(생략가능) 이후에 지문을 추가합니다. 글자와 함께 그림, 표, 수식을 넣을 수 있습니다.', mandatory: false },
	{ type: 'child', level: '##문항', description: '(복수개) 문제에 속한 문항을 추가합니다. 글자와 함께 그림, 표, 수식을 넣을 수 있습니다.', mandatory: false },
	{ type: 'child', level: '##답안', description: '(문항에 종속) 문항의 답안 작성 영역, 이후에 1x1 크기의 표가 반드시 있어야 합니다.', mandatory: false },
	{ type: 'parent', level: '##끝', description: '(생략가능) 문제 끝 구분자, 명시적 종료자가 없으면 다음 ##문제까지', mandatory: false },
];

function TemplateGuideDialog({ open, onClose }) {
	const classes = useEmotionStyles( TemplateGuideDialogStyle );

	return (
		<Dialog
			onClose={onClose}
			open={open}
			className={classes.dataOpenDialog}
		>
			<Box display="flex" alignItems="center" justifyContent="space-between" className={classes.dialogTop}>
				<Typography>템플릿 작성 가이드</Typography>
				<IconButton onClick={onClose}>
					<DialogClose />
				</IconButton>
			</Box>

			<Box className={classes.dialogContent}>
				<Box className={classes.stepCont}>
					<Typography className={classes.stepNumber}>1</Typography>
					<Typography className={classes.listBoxTitleEach}>
						문서 구조
					</Typography>
				</Box>
				<Box className={classes.borderBox}>
					<Typography className={classes.greenTitle}>
						<CheckListSave />
						기본 구조
					</Typography>
					<Typography className={classes.guideTextList}>
						[##문제 → ##지시문 → ##지문 → ##문항 → ##답안 → ##끝]의 반복<br />
					</Typography>
				</Box>

				<Box className={classes.stepCont}>
					<Typography className={classes.stepNumber}>2</Typography>
					<Typography className={classes.listBoxTitleEach}>
						섹션별 설명
					</Typography>
				</Box>

				<TableContainer component={Paper} className={classes.tableBox}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell colSpan={2}>섹션</TableCell>
								<TableCell >설명</TableCell>
								<TableCell >필수여부</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{exampleContent.map((item, index) => (
								<TableRow key={index}>
									<TableCell style={{ minWidth: "60px" }}><strong>{item.type === 'parent' ? item.level : ""}</strong></TableCell>
									<TableCell style={{ minWidth: "60px" }}><strong>{item.type === 'parent' ? "" : item.level}</strong></TableCell>
									<TableCell width="80%">{item.description}</TableCell>
									<TableCell width="20%" style={{ minWidth: "10px" }}>{item.mandatory ? (<strong>필수</strong>) : '선택'}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>

				<Box className={classes.stepCont} style={{ marginTop: '24px' }}>
					<Typography className={classes.stepNumber}>3</Typography>
					<Typography className={classes.listBoxTitleEach}>
						주의사항
					</Typography>
				</Box>
				<Box className={classes.borderBox}>
					<Typography className={classes.redTitle}>
						<InformationRed />
						<strong>답안 영역의 표시</strong>
					</Typography>
					<Typography className={classes.guideTextListRed}>
						- <strong>##답안</strong> 태그에서부터 해당 문항의 종료 부분까지에서 가로x세로 1x1인 표를 답안 영역으로 인식합니다. <br />
						- <strong>##답안</strong> 태그가 없거나, <strong>##답안</strong> 태그 이후에 1x1 형식의 표가 없으면 자동 인식을 위한 답안 영역을 표현할 수 없습니다.
					</Typography>
					<br />

					<Typography className={classes.greenTitle}>
						<CheckListSave />
						<strong>문제 종료 조건</strong>
					</Typography>
					<Typography className={classes.guideTextList}>
						- <strong>##끝</strong> 태그를 만나는 경우, 새로운 <strong>##문제</strong> 태그를 만나는 경우, 또는 문서의 끝에 도달한 경우
					</Typography>
					<br />

					<Typography className={classes.greenTitle}>
						<CheckListSave />
						<strong>생략 가능한 섹션 생략</strong>
					</Typography>
					<Typography className={classes.guideTextList}>
						- <strong>##지시문</strong>이나 <strong>##지문</strong>이 생략된 경우에는 문항으로만 구성됩니다.<br />
						- <strong>##문항</strong>이 생략되면, 문제는 단일 문항으로 처리됩니다.<br />
						- <strong>##답안</strong>과 1x1 의 표가 없으면 답안 작성 공간에 따른 자동 인식은 지원되지 않습니다.
					</Typography>
				</Box>
			</Box>

			<Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogBtnGroup}>
				<Button className={classes.dialogBtn} onClick={onClose}>
					<Typography>닫기</Typography>
				</Button>
			</Box>
		</Dialog>
	);
}

export default TemplateGuideDialog;