export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    // eslint-disable-next-line one-var, one-var-declaration-per-line, no-bitwise
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}


export async function sleep(ms: number) {
  await new Promise((r) => {
    setTimeout(r, ms);
  });
}

// TODO: URL이 있는지 점검하는 루틴이 제대로 동작하지 않아서, 일단은 모두 true를 리턴하도록, 나중에 수정해야 한다
export function urlExists(url: string) {
  const blobTest = /^blob:/
  const dataTest = /^data:/

  if (blobTest.test(url) || dataTest.test(url)) {
    return true;
  }

  return true;

  // const http = new XMLHttpRequest();
  // http.open('HEAD', url, false);
  // http.send();
  // return http.status != 404;
}



const sobpSeparator = ".";

export function makeNPageIdStr(sobp: { section: number, owner: number, book: number, page: number }, separator = sobpSeparator) {
  if (sobp) {
    const { section, owner, book, page } = sobp;

    if (page !== undefined) return `${section}${separator}${owner}${separator}${book}${separator}${page}`;

    if (book !== undefined) return `${section}${separator}${owner}${separator}${book}`;

    if (owner !== undefined) return `${section}${separator}${owner}`;

    if (section !== undefined) return `${section}`;

    return `${section}${separator}${owner}${separator}${book}${separator}${page}`;
  }
  return `${sobp}`;
}

