import { Wrapper } from "./Wrapper";

// typedef struct
// {
// 	int abort;
// 	int progress;
// 	size_t progress_max; /* (size_t)-1 for unknown */
// 	int errors;
// 	int incomplete;
// } fz_cookie;


export class JobCookie extends Wrapper {
  constructor(libmupdf: any, ctx: number, pointer: number) {
    super(libmupdf, ctx, pointer, libmupdf._wasm_free_cookie);
  }

  static create(libmupdf: any, ctx: number) {
    return new JobCookie(libmupdf, ctx, libmupdf._wasm_new_cookie(ctx));
  }

  aborted() {
    const { libmupdf } = this;
    return libmupdf._wasm_cookie_aborted(this.ctx, this.pointer);
  }

  cancel() {
    const int32pointer = this.pointer >> 2;
    const { libmupdf } = this;
    const wasmMemoryView32 = new Int32Array(libmupdf.wasmMemory);
    wasmMemoryView32[int32pointer] = 1;
  }

  progress() {
    const int32pointer = this.pointer >> 2;
    const { libmupdf } = this;
    const wasmMemoryView32 = new Int32Array(libmupdf.wasmMemory);
    return {
      progress: wasmMemoryView32[int32pointer + 1],
      progressMax: wasmMemoryView32[int32pointer + 2],
    };
  }
}

export function createCookie(libmupdf: any, ctx: number): number {
  return libmupdf._wasm_new_cookie(ctx);
}

/**
 *
 * @param cookiePointer
 * @returns cookie->abort
*  typedef struct
 * {
 * 	  int abort;
 * 	  int progress;
 * 	  size_t progress_max; // (size_t)-1 for unknown
 * 	  int errors;
 * 	  int incomplete;
 * } fz_cookie;
 */
export function cookieAborted(libmupdf: any, ctx: number, cookiePointer: number): number {
  return libmupdf._wasm_cookie_aborted(ctx, cookiePointer);
}

export function deleteCookie(libmupdf: any, ctx: number, cookiePointer: number) {
  libmupdf._wasm_free_cookie(ctx, cookiePointer);
}

