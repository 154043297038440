

// ProjectRagStore

export enum RagType {
	TEXTBOOK = "TEXTBOOK",
	NEWS = "NEWS",
	BOOK = "BOOK",
	PAPER = "PAPER",
	OTHERS = "OTHERS"
}
