import { IPageSOBP } from '../../nl-lib3-common';
import { availablePagesInSection } from '../../nl-lib3-common/util/functions/depFunctions';
import { getNowTimeStr, makePdfId } from '../../nl-lib3-common/util/functions/indepFunctions';
import CoordinateTanslater from './CoordinateTanslater';
import { IPageMapItem, IPdfToNcodeMapItem } from './types/MapDataTypes';

export default class PdfDocMapper {
	private _arrMapped: IPageMapItem[] = [];

	private _docMap!: IPdfToNcodeMapItem;

	private _filename: string;

	private _fingerprint: string;

	private _pagesPerSheet: number;

	/**
	 * 배경 URL을 만드는 방법
	 * ex) "/background_pdf/${section}_${owner}_${book}/${section}_${owner}_${book}_${page}.jpg";
	 *     real_url = completeBackgroundImageUrl( string, {section, owner, book, page} )
	 * @memberof PdfDocMapper
	 */

	private _background_image_url_rule: string | undefined;


	constructor(filename: string, pagesPerSheet: number, fingerprint: string, background_image_url_rule?: string) {
		this._filename = filename;
		this._pagesPerSheet = pagesPerSheet;
		this._background_image_url_rule = background_image_url_rule;
		this._fingerprint = fingerprint;
	}


	get length() {
		return this._arrMapped.length;
	}

	getAt = (index: number) => this._arrMapped[index]


	get docMap() {
		return this._docMap;
	}

	public setFingerprint = (fingerprint: string) => {
		this._fingerprint = fingerprint;
	}


	public push = (item: IPageMapItem) => {
		this._arrMapped.push(item);

		const compare = (a: IPageMapItem, b: IPageMapItem) => {
			const { section: s1, owner: o1, book: b1, page: p1 } = a.sobp;
			const { section: s2, owner: o2, book: b2, page: p2 } = b.sobp;

			if (s1 !== s2) return s1 - s2;
			if (o1 !== o2) return o1 - o2;
			if (b1 !== b2) return b1 - b2;
			return p1 - p2;
		}
		this._arrMapped.sort(compare);
	}

	public append = (items: CoordinateTanslater[]) => {
		items.forEach((item) => {
			this.push(item.mappingParams);
		});
	}

	private insertDummy = (sobp: IPageSOBP) => {
		/** 빈 페이지를 채워 넣자 */
		const arr = [];
		for (let i = 0, l = this._arrMapped.length; i < l; i++) {
			const params = this._arrMapped[i];

			arr.push(params.pdfDesc.pageNum);
		}

		const head = this._arrMapped[0];
		const { numPages } = head.pdfDesc;
		const availablePages = availablePagesInSection(sobp.section);
		for (let pgNo = 1; pgNo <= numPages; pgNo++) {
			const idx = arr.indexOf(pgNo);

			/**
			 * 페이지가 발견되지 않으면 더미를 넣는다
			 * 더미는, 첫 페이지의 매핑 정보를 그대로 활용한다. 대부분 같으리라
			 */
			if (idx < 0) {
				const item: IPageMapItem = JSON.parse(JSON.stringify(head));

				item.pdfDesc.pageNum = pgNo;
				item.sobp.page = (sobp.page + (pgNo - 1)) % availablePages;
				this.push(item);
			}
		}
	}


	private getStartingNPageInfo = () => {
		const head = this._arrMapped[0];
		if (!head) {
			console.error("Temp Mapping Storage: nothing to summary up");
			return undefined;
		}

		const { section, owner, book, page } = head.sobp;
		const availablePages = availablePagesInSection(section);
		const printCodeStart: IPageSOBP = {
			section,
			owner,
			book,
			page: ((page - (head.pdfDesc.pageNum - 1)) + availablePages) % availablePages,
		}

		return printCodeStart;
	}


	public makeSummary = () => {
		/** PDF 파일 전체의 첫 페이지에 해당하는 ncode 정보를 가져온다 */
		const printCodeStart = this.getStartingNPageInfo();
		if (!printCodeStart) return;

		/** 빈 페이지에 더미 데이터를 채워 넣는다 */
		this.insertDummy(printCodeStart);

		/** 파일 전체의 매핑 정보를 기록해 둔다 */
		const head = this._arrMapped[0];
		const { url, numPages } = head.pdfDesc;
		const fingerprint = head.pdfDesc.fingerprint ? head.pdfDesc.fingerprint : this._fingerprint;
		const id = makePdfId({ paperGroupId: fingerprint, pagesPerSheet: this._pagesPerSheet });


		this._docMap = {
			url,
			numPages,
			fingerprint,
			id,
			pagesPerSheet: this._pagesPerSheet,
			filename: this._filename,
			printPageInfo: printCodeStart,
			sobp: printCodeStart,
			pages: this._arrMapped,
			timeString: getNowTimeStr(),
			background_image_url_rule: this._background_image_url_rule,      /// 2021/04/20 kitty
			// 2021/08/12
			// pdfDoc: pdfDoc,

		}

		// console.log(`${numPages}: starting from ${Util.makeNPageIdStr(printCodeStart)}`);
		// for (let i = 0; i < this._arrMapped.length; i++) {
		//   const pageNo = this._arrMapped[i].pdfDesc.pageNo;
		//   const sobp = this._arrMapped[i].sobp;
		//   console.log(`         ${pageNo}/${numPages}: ${Util.makeNPageIdStr(sobp)}`);
		// }
	}


	dump = (prefix = "debug") => {
		console.log(`[${prefix}]----------------------------------------------------------------------`);
		const str = JSON.stringify(this._arrMapped, null, "  ");
		const arr = str.split("\n");

		for (let i = 0, l = arr.length; i < l; i++) {
			console.log(`[${prefix}] ${arr[i]}`);
		}
		console.log(`[${prefix}]----------------------------------------------------------------------`);
	}
}



// https://jess2.xyz/JavaScript/variable-regex/
export function completeBackgroundImageUrl(url_template: string, args: { [key: string]: any }) {
	if (url_template === null || url_template.length < 1) return null;

	let ret = url_template;

	Object.keys(args).forEach((key) => {
		const regexp = new RegExp(`\\\${${key}\\}`, "gi");
		ret = ret.replace(regexp, args[key]);
	});

	const regexp = /\{([^{}]*)\}/g;
	// const rest= ret.match(regexp);
	const rest = regexp.exec(ret);
	if (rest) {
		throw new Error(`The parameter, ${rest[1]}, has not been given.`);
	}

	return ret;
}
