import { configure } from "mobx";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { MobXProviderContext } from "mobx-react";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import packageJson from '../package.json';
import { serverContextPath } from "./AppConstants";
import configureTheme from "./configureTheme";
import { initMauiToJsBridge } from "./neolab-libs/nl-lib3-pengateway/src";
import { DotNetApi, MauiToJsBridge } from "./neolab-libs/nl-lib3-pengateway/src/nl-lib-maui";
import { stores } from "./stores";
import { ThemeProvider } from "@mui/material/styles";
import { ConfirmProvider } from "material-ui-confirm";

let environment = "dev";
if (process.env.NODE_ENV === "production") {
	environment = "production";
}

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://64d2363ff57b7c3142cd8076299a50d6@o4504339503775744.ingest.us.sentry.io/4508323366567936", //'https://936224baf4b127f1466b8c5ab59beee3@o4504339503775744.ingest.us.sentry.io/4507880668528640',
		release: packageJson.version,
		environment,

		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.browserProfilingIntegration(),

			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],

		// Performance Monitoring
		tracesSampleRate: 1.0, // 센트리에 보내는 트랜잭션 샘플 비율 (0.0 ~ 1.0) 너무 많은 이벤트 기록 않도록 부하 조절
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production. Sentry SDK 에서 세션 리플레이 기능의 샘플링 비율 제어
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur. 오류가 발생한 세션 중 어느 정도의 비율로 리플레이 데이터 수집할지 결정
	});
}


// init neo-lib *important*
(async () => {
	try {
		MauiToJsBridge.instance.init();
		await initMauiToJsBridge();
		const connected = DotNetApi.instance.connected;
	} catch (e) {
		console.error(e);
	}
})();



const root = ReactDOM.createRoot(document.getElementById("root"));

configure({ enforceActions: "always" });
const theme = configureTheme();


root.render(
	<React.StrictMode>
		<BrowserRouter basename={serverContextPath}>
			<MobXProviderContext.Provider value={stores}>
				<ThemeProvider theme={theme}>
					{/*<GoogleReCaptchaProvider*/}
					{/*    reCaptchaKey={'6LeXLN4pAAAAAKTr_NM_vt3ZHS93cppVaLxU2IhC'}*/}
					{/*    scriptProps={{*/}
					{/*        async: true, // optional, default to false,*/}
					{/*        // defer: true, // optional, default to false*/}
					{/*        // appendTo: 'head', // optional, default to "head", can be "head" or "body",*/}
					{/*        // nonce: undefined // optional, default undefined*/}
					{/*    }}>*/}
					<ConfirmProvider
						defaultOptions={{
							confirmationButtonProps: { autoFocus: true, variant: "contained", color: "primary" },
						}}>
						<App />
					</ConfirmProvider>
					{/*</GoogleReCaptchaProvider>*/}

					{/* <DownloadNativePopup onClose={() => { }} /> */}
				</ThemeProvider>
			</MobXProviderContext.Provider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
