import { assert, neoAllocateUTF8 } from "./functions";
import { FzBuffer } from "./FzBuffer";
import { Wrapper } from "./Wrapper";

export function onFetchCompleted(id) {
  // mupdf.onFetchCompleted(id);
}


export class FzStream extends Wrapper {
  internalBuffer: FzBuffer;

  constructor(libmupdf: any, ctx: number, pointer: number, internalBuffer: FzBuffer) {
    super(libmupdf, ctx, pointer, libmupdf._wasm_drop_stream);
    // We keep a reference so the internal buffer isn't dropped before the stream is.
    this.internalBuffer = internalBuffer;
  }

  static fromUrl(libmupdf: any, ctx: number, url: string, contentLength: number, block_size: number, prefetch: number) {
    const url_ptr = neoAllocateUTF8(libmupdf, ctx, url);

    try {
      const pointer = libmupdf._wasm_open_stream_from_url(ctx, url_ptr, contentLength, block_size, prefetch);
      return new FzStream(libmupdf, ctx, pointer, null);
    }
    finally {
      libmupdf._wasm_free(ctx, url_ptr);
    }
  }

  // This takes a reference to the buffer, not a clone.
  // Modifying the buffer after calling this function will change the returned stream's output.
  static fromBuffer(libmupdf: any, ctx: number, buffer: FzBuffer) {
    assert(buffer instanceof FzBuffer, "invalid buffer argument");
    return new FzStream(libmupdf,
      ctx,
      libmupdf._wasm_new_stream_from_buffer(ctx, buffer.pointer), buffer
    );
  }

  static fromJsBuffer(libmupdf: any, ctx: number, buffer: ArrayBuffer) {
    return FzStream.fromBuffer(libmupdf, ctx, FzBuffer.fromJsBuffer(libmupdf, ctx, buffer));
  }

  static fromJsString(libmupdf: any, ctx: number, string) {
    return FzStream.fromBuffer(libmupdf, ctx, FzBuffer.fromJsString(libmupdf, ctx, string));
  }

  readAll(suggestedCapacity = 0) {
    const { libmupdf } = this;
    return new FzBuffer(libmupdf, this.ctx, libmupdf._wasm_read_all(this.ctx, this.pointer, suggestedCapacity));
  }
}



// Background progressive fetch

// TODO - move in Stream
function onFetchData(libmupdf: any, ctx, id, block, data: ArrayBuffer) {

  const n = data.byteLength;
  const p = libmupdf._wasm_malloc(ctx, n);
  libmupdf.HEAPU8.set(new Uint8Array(data), p);
  libmupdf._wasm_on_data_fetched(ctx, id, block, p, n);
  libmupdf._wasm_free(ctx, p);
}

// TODO - replace with map
const fetchStates = {};

export function fetchOpen(id, url, contentLength, blockShift, prefetch) {
  console.log("OPEN", url, "PROGRESSIVELY");
  fetchStates[id] = {
    url,
    blockShift,
    blockSize: 1 << blockShift,
    prefetch,
    contentLength,
    map: new Array((contentLength >>> blockShift) + 1).fill(0),
    closed: false,
  };
}

export async function fetchRead(libmupdf: any, ctx, id, block) {
  const state = fetchStates[id];

  if (state.map[block] > 0)
    return;

  state.map[block] = 1;
  const { contentLength } = state;
  const { url } = state;
  const start = block << state.blockShift;
  let end = start + state.blockSize;
  if (end > contentLength)
    end = contentLength;

  try {
    const response = await fetch(url, { headers: { Range: `bytes=${start}-${end - 1}` } });
    if (state.closed)
      return;

    // TODO - use ReadableStream instead?
    const buffer = await response.arrayBuffer();
    if (state.closed)
      return;

    console.log("READ", url, block + 1, "/", state.map.length);
    state.map[block] = 2;

    onFetchData(libmupdf, ctx, id, block, buffer);

    onFetchCompleted(id);

    // TODO - Does this create a risk of stack overflow?
    if (state.prefetch)
      fetchReadNext(libmupdf, ctx, id, block + 1);
  } catch (error) {
    state.map[block] = 0;
    console.log("FETCH ERROR", url, block, error.toString);
  }
}

export async function fetchReadNext(libmupdf, ctx, id, next) {
  const state = fetchStates[id];
  if (!state)
    return;

  // Don't prefetch if we're already waiting for any blocks.
  for (let block = 0; block < state.map.length; ++block)
    if (state.map[block] === 1)
      return;

  // Find next block to prefetch (starting with the last fetched block)
  for (let block = next; block < state.map.length; ++block)
    if (state.map[block] === 0) {
      await fetchRead(libmupdf, ctx, id, block);
      return;
    }

  // Find next block to prefetch (starting from the beginning)
  for (let block = 0; block < state.map.length; ++block)
    if (state.map[block] === 0) {
      await fetchRead(libmupdf, ctx, id, block);
      return;
    }

  console.log("ALL BLOCKS READ");

}

export function fetchClose(id) {
  fetchStates[id].closed = true;
  delete fetchStates[id];
}

