import { INeoSmartpen, IPageSOBP, makeNPageIdStr, NeoStroke, OfflineDataInfo, setImmediatePolyfill, uuidv4 } from "../../../../../../neolab-libs/nl-lib3-common";
import { BluetoothTypeEnum } from "../../../../../../neolab-libs/nl-lib3-pengateway/src/nl-lib-bt-devices";
import { NativeMethods } from "../../../../../../neolab-libs/nl-lib3-pengateway/src/nl-lib-native-methods/NativeMethods";
import PenManager from "../../../../../../neolab-libs/nl-lib3-pengateway/src/nl-lib-pensdk/PenManager";

enum PenTaskRequestMethodEnum {
	getOfflinePageList = "getOfflinePageList",
	getOfflineData = "getOfflineData",
	removeOfflineData = "removeOfflineData",
}

type ISmartpenJobRequestOptions = {
	penId: string,
	passwordRequestMsg?: (retryCount: number, retryLimit: number) => string,
	range?: { start: IPageSOBP, end: IPageSOBP },
	deleteOnFinished?: boolean,

	topPriority?: boolean
	handleProgress?: (progress: { numTotal: number, numDone: number }) => void,

	keepConnection?: boolean,
	givenPen?: INeoSmartpen
}

type ISmartpenJobModuleOptions = Omit<ISmartpenJobRequestOptions, 'topPriority'>;

export type ISmartpenJob = {
	args: ISmartpenJobRequestOptions, //AxiosRequestConfig,
	// response?: AxiosResponse<any, any>,
	topPriority: boolean
	// purpose?: string,

	// func: (arrgs: any) => any;

	method: PenTaskRequestMethodEnum,
	JobCookie: string,		// 같은 종류의 일을 구분하기 위한 ID

	task?: {
		promise: Promise<any>,
		resolve: (value: any) => void,
		reject: (reason?: any) => void
	};
};



export class PenJobManager {
	private isProcessing = false;
	private activeWorkers = 0;

	private static maxBTConnections = 4;

	private static _inst: PenJobManager | null = null;

	private _queue: ISmartpenJob[] = [];

	static getInstance() {
		if (this._inst) return this._inst;

		this._inst = new PenJobManager();
		return this._inst;
	}

	static get instance() {
		return PenJobManager.getInstance();
	}

	/**
	 *
	 * @param JobCookie
	 */
	public static cancelJob(JobCookie: string) {
		const founds = this._inst._queue.filter(j => j.JobCookie === JobCookie);

		for (const found of founds) {
			found.task.reject('Canceled');
		}
		this._inst._queue = this._inst._queue.filter(j => j.JobCookie !== JobCookie);
	}

	/**
	 *
	 * @param args
	 * @returns
	 */
	public static getOfflinePageList(args: Omit<ISmartpenJobRequestOptions, 'deleteOnFinished'>) {
		const method = PenTaskRequestMethodEnum.getOfflinePageList;

		const { penId, range, topPriority = false } = args;
		const JobCookie = `${penId}-${method}-${makeNPageIdStr(range?.start)}-${makeNPageIdStr(range?.end)}`;

		const newJob: ISmartpenJob = { args, topPriority, method, JobCookie };
		const promise = PenJobManager.instance.requestJob<{ result: OfflineDataInfo[], pen: INeoSmartpen }>(newJob);

		return { promise, JobCookie, cancel: () => PenJobManager.cancelJob(JobCookie) }
	}

	/**
	 *
	 * @param args
	 * @returns
	 */
	public static getOfflineData(args: ISmartpenJobRequestOptions) {
		const method = PenTaskRequestMethodEnum.getOfflineData;

		const { penId, range, deleteOnFinished = false, topPriority = false } = args;
		const JobCookie = `${penId}-${method}-${makeNPageIdStr(range?.start)}-${makeNPageIdStr(range?.end)}-${deleteOnFinished}`;

		const newJob: ISmartpenJob = { args, topPriority, method, JobCookie, }
		const promise = PenJobManager.instance.requestJob<{ result: { numPages: number; numStrokes: number; strokes: NeoStroke[]; }, pen: INeoSmartpen }>(newJob);

		return { promise, JobCookie, cancel: () => PenJobManager.cancelJob(JobCookie) }
	}

	/**
	 *
	 * @param args
	 * @returns
	 */
	public static removeOfflineData(args: Omit<ISmartpenJobRequestOptions, 'deleteOnFinished'>) {
		const method = PenTaskRequestMethodEnum.removeOfflineData;

		const { penId, range, topPriority = false } = args;
		const JobCookie = `${penId}-${method}-${makeNPageIdStr(range?.start)}-${makeNPageIdStr(range?.end)}`;

		const newJob: ISmartpenJob = { args, topPriority, method, JobCookie, };
		const promise = PenJobManager.instance.requestJob<{ result: boolean, pen: INeoSmartpen }>(newJob);

		return { promise, JobCookie, cancel: () => PenJobManager.cancelJob(JobCookie) }
	}




	private static async getOfflinePageListModule(args: Omit<ISmartpenJobModuleOptions, "deleteOnFinished">) {
		const { penId, passwordRequestMsg, range, givenPen, keepConnection = false } = args;

		const pen = givenPen || PenManager.instance.createPen({ userId: uuidv4(), bluetoothType: BluetoothTypeEnum.MAUI });


		let connected = pen.connected;
		if (!connected) {
			const ret = await pen.connectMauiPen({ penId, requestOnlineData: false, shouldStopScanTask: false, passwordRequestMsg });
			connected = ret.success;
		}

		if (!connected) {
			return { result: null, pen };
		}

		const ret = await pen.getOfflinePageList(range);

		if (!keepConnection) {
			// throw new Error('Failed to connect pen');
			await pen.disconnect();
		}
		// // 끊길 때까지 기다리기
		// await new Promise((resolve) => setTimeout(resolve, 3000));


		return { result: ret, pen };
	}

	private static async getOfflineDataModule(args: ISmartpenJobModuleOptions) {
		const { penId, passwordRequestMsg, range, deleteOnFinished, handleProgress, givenPen, keepConnection = false } = args;

		const pen = givenPen || PenManager.instance.createPen({ userId: uuidv4(), bluetoothType: BluetoothTypeEnum.MAUI });

		let numDone = 0;
		handleProgress?.({ numTotal: 2, numDone });

		let connected = pen.connected;
		if (!connected) {
			const ret = await pen.connectMauiPen({ penId, requestOnlineData: false, shouldStopScanTask: false, passwordRequestMsg });
			connected = ret.success;
		}
		if (!connected) {
			// throw new Error('Failed to connect pen');
			return { result: null, pen };
		}

		try {
			const ret = await pen.getOfflineDataByRange({ range, deleteOnFinished, offlineDataPageList: null, handleProgress });


			if (!keepConnection) {
				await pen.disconnect();
			}

			// 끊길 때까지 기다리기
			// await new Promise((resolve) => setTimeout(resolve, 3000));

			return { result: ret, pen };
		} catch (error) {
			return { result: null, pen };
		}
	}

	private static async removeOfflineDataModule(args: Omit<ISmartpenJobModuleOptions, "deleteOnFinished">) {
		const { penId, range, passwordRequestMsg, givenPen, keepConnection = false } = args;
		const pen = givenPen || PenManager.instance.createPen({ userId: uuidv4(), bluetoothType: BluetoothTypeEnum.MAUI })


		let connected = pen.connected;
		if (!connected) {
			const ret = await pen.connectMauiPen({ penId, requestOnlineData: false, shouldStopScanTask: false, passwordRequestMsg });
			connected = ret.success;
		}

		if (!connected) {
			throw new Error('Failed to connect pen');
			return { result: null, pen };
		}

		const ret = await pen.removeOfflineDataByRange({ range, offlineDataPageList: null });

		if (!keepConnection) {
			await pen.disconnect();
		}

		// 끊길 때까지 기다리기
		await new Promise((resolve) => setTimeout(resolve, 3000));

		return { result: ret, pen };
	}


	private async checkAndStartNewJobs() {
		// 현재 처리 가능한 새 작업이 있는지 확인
		// const numPens = PenManager.instance.getPens().length;
		const numPens = await NativeMethods.GetNumConnectedPens();
		console.log(`PenJobManager, numPens: ${numPens}`)

		const availableSlots = PenJobManager.maxBTConnections - numPens - this.activeWorkers;

		// 새 작업을 시작할 수 있는 상황이면 processLoop 트리거
		if (availableSlots > 0 && this._queue.length > 0 && !this.isProcessing) {
			setImmediatePolyfill(() => this.processLoop());
		}
	}



	private async processLoop() {
		if (this.isProcessing) {
			return;
		}

		try {
			this.isProcessing = true;

			// 현재 시점에서 시작 가능한 모든 작업 시작
			while (true) {
				// const numPens = PenManager.instance.getPens().length;
				const numPens = await NativeMethods.GetNumConnectedPens();
				console.log(`PenJobManager processLoop, numPens: ${numPens}`)
				const availableSlots = PenJobManager.maxBTConnections - numPens;

				if (availableSlots <= 0 || this._queue.length === 0) {
					break;
				}

				const job = this._queue.shift();
				if (job) {
					this.activeWorkers++;
					this.executeJob(job)
						.then(result => {
							job.task.resolve(result);
						})
						.catch(error => {
							job.task.reject(error);
						})
						.finally(() => {
							this.activeWorkers--;
							// 작업 완료 후 새 작업 시작 가능성 확인
							this.checkAndStartNewJobs();
						});
				}
			}
		} finally {
			this.isProcessing = false;
		}
	}

	private async executeJob(job: ISmartpenJob) {
		const { method, args: options } = job;

		try {
			let result;
			switch (method) {
				case PenTaskRequestMethodEnum.getOfflinePageList:
					result = await PenJobManager.getOfflinePageListModule(options);
					break;

				case PenTaskRequestMethodEnum.getOfflineData:
					result = await PenJobManager.getOfflineDataModule(options);
					break;

				case PenTaskRequestMethodEnum.removeOfflineData:
					result = await PenJobManager.removeOfflineDataModule(options);
					break;

				default:
					throw new Error(`Unknown method: ${method}`);
			}

			return result;
		} catch (error) {
			throw error;
		}
	}

	public requestJob<T>(job: ISmartpenJob): Promise<T> {
		const found = this._queue.find(j => j.JobCookie === job.JobCookie);
		if (found) {
			return found.task.promise;
		}

		const { topPriority } = job;
		let resolve: (value: T) => void;
		let reject: (reason?: any) => void;

		const promise = new Promise<T>((rsv, rjt) => {
			resolve = rsv;
			reject = rjt;
		});

		const task = { promise, resolve, reject };
		const newJob: ISmartpenJob = { ...job, task };

		if (topPriority) {
			this._queue.unshift(newJob);
		} else {
			this._queue.push(newJob);
		}

		// 새 작업이 추가되었으므로 시작 가능성 확인
		this.checkAndStartNewJobs();

		return promise;
	}
}

