import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	TextField,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as ArrowDownRight } from '../../../../common/images/ArrowDownRight.svg';
import { ReactComponent as CheckCircleIcon } from '../../../../common/images/CheckCircleIcon.svg';
import { ReactComponent as CheckCircleIconOff } from '../../../../common/images/CheckCircleIconOff.svg';
import { ReactComponent as DotSubEditer } from '../../../../common/images/DotSubEditer.svg';
import { ReactComponent as ToggleIcon } from '../../../../common/images/ToggleIcon.svg';
import { ReactComponent as TrashDeleteIcon } from '../../../../common/images/TrashDeleteIcon.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ExamRangeUserStyle } from "../../styles/ExamRangeUserStyle";

import React from "react";
import { useTranslation } from 'react-i18next';
import TextEditor from "../../../../components/common/TextEditor";
import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { ProjectExamType } from "../../../../repositories/model/support/ProjectExamType";
import { IExamInfo } from "../../../../repositories/model/transfer/IExamInfo";
import { QuestionRow } from "./QuestionRow";
import { useStores } from "../../../../hooks/useStores";
import { toJS } from "mobx";

type Props = {
	onUploadFile: any,
	examInfo: IExamInfo,
	idx: number,
	isNowBulkLoading: boolean,
}

export const ExamRow = (props: Props) => {
	const i18next = useTranslation();
	const { projectExamStore, messageStore } = useStores();
	const { onUploadFile, examInfo, idx } = props;

	const { order: examOrder } = examInfo;
	const classes = useEmotionStyles( ExamRangeUserStyle );
	const [openPassage, setOpenParagraph] = React.useState(false);
	const [openDirective, setOpenDirective] = React.useState(false);
	const [checkDirectiveWhenMounted, setCheckDirectiveWhenMounted] = React.useState(false);

	const [currentHTML, setCurrentHTML] = React.useState("");

	const checkedTypeDescriptive = React.useMemo(() => {
		return examInfo.type === ProjectExamType.DESCRIPTIVE;
	}, [examInfo.type]);

	const checkedTypeEssay = React.useMemo(() => {
		return examInfo.type === ProjectExamType.ESSAY;
	}, [examInfo.type]);

	const questionListLen = React.useMemo(() => {
		return examInfo.questions.filter(q => q.changeFlag !== ChangeFlag.DELETED).length;
	}, [examInfo, examInfo.questions]);

	React.useEffect(() => {
		if (props.isNowBulkLoading) {
			if ((examInfo.paragraph?.length || 0) > 0) {
				setOpenParagraph(true);
				setCurrentHTML(examInfo.paragraph);
				return;
			}
		}
		else {
			if (examInfo.initialParagraph) {
				setOpenParagraph(true);
			}

			setCurrentHTML(examInfo.initialParagraph ? examInfo.initialParagraph : "");
		}
	}, [examInfo.paragraph, examInfo.initialParagraph]);

	React.useEffect(() => {
		if (!examInfo.directive || checkDirectiveWhenMounted) return;

		setOpenDirective(true);
		setCheckDirectiveWhenMounted(true);
	}, [examInfo.directive]);

	const handleClickDelete = async () => {
		const result = await messageStore.confirm(i18next.t("{{examOrder}}번 문항 전체를 삭제하시겠습니까?", { examOrder }));
		if (!result) return;

		projectExamStore.deleteExam(examOrder);
	};

	const handleChangeType = (e) => {
		const value = e.target.value;
		if (!value) return;

		projectExamStore.changeExamType(examOrder, value);
	};

	const handleChangeDirective = (e) => {
		const value = e.target.value;
		projectExamStore.changeExamDirective(examOrder, value);
	};

	const handleClickParagraphToggle = () => {
		setOpenParagraph(prev => !prev);
	};

	const handleClickDirectiveToggle = () => {
		setOpenDirective(prev => !prev);
	};

	const handleChangeParagraph = (paragraph: string) => {
		console.log(`paragraph`, paragraph);
		projectExamStore.changeExamParagraph(examOrder, paragraph);
	};

	const handleInitInitialParagraph = () => {
		projectExamStore.initExamInitialParagraph(examOrder);
	};

	const handleChangeSource = (e) => {
		const value = e.target.value;
		projectExamStore.changeExamSource(examOrder, value);
	};

	const handleClickAddQuestion = () => {
		projectExamStore.addQuestion(examOrder);
	};

	const [, forceUpdate] = React.useReducer(x => x + 1, 0);

	React.useEffect(() => {
		const el = toJS(projectExamStore.projectExamList);
		const ex = toJS(examInfo);
		console.log(el);

		forceUpdate();
	}, [projectExamStore.projectExamList]);

	React.useEffect(() => {
		const el = toJS(projectExamStore.projectExamList);
		const ex = toJS(examInfo);
		console.log(el);

		forceUpdate();
	}, [examInfo]);


	// const [answerYypeAnchorEl, setAnswerTypeAnchorEl] = React.useState(null);
	// const openAnswerTypeMenu = React.useMemo(() => {
	//     return !!answerYypeAnchorEl;
	// }, [answerYypeAnchorEl])
	// const handleClickAnswerTypeMenu = (e) => {
	//     setAnswerTypeAnchorEl(e.currentTarget);
	// };
	// const handleCloseAnswerTypeMenu = (e) => {
	//     setAnswerTypeAnchorEl(null);
	// };


	//#region 문제 자동 fill-in
	// const editorRef = React.useRef(null);

	// React.useEffect(()=>{
	// 	if(questionInfo.question.length > 0){
	// 		setInnerHtml(questionInfo.question);
	// 	}
	// },[questionInfo.question])

	// const setInnerHtml = (html: string) => {
	// 	const inst = editorRef.current;
	// 	inst?.setHTML(html);
	// }
	//#endregion

	return (
		<Box className={classes.userBox}>

			<Box >
				<Box display='flex' alignItems='center' justifyContent='flex-end' sx={{ position: 'relative' }} className={classes.supportBox}>
					{/*<IconButton className={clsx(classes.iconBtnReset, classes.iconMove)} disableRipple>*/}
					{/*    <DotsSix />*/}
					{/*</IconButton>*/}
					{idx !== 0 &&
						<Button
							// display='flex'
							sx={{ display: 'flex' }}
							className={classes.examDeleteIcon} disableRipple
							onClick={handleClickDelete}
						>
							<TrashDeleteIcon />
							<Typography>{i18next.t("삭제")}</Typography>
						</Button>
					}
				</Box>

				<Box sx={{ marginBottom: "20px" }}>
					<FormControlLabel
						control={
							<Checkbox
								onChange={handleChangeType}
								value={ProjectExamType.DESCRIPTIVE}
								checked={checkedTypeDescriptive}
								icon={<CheckCircleIconOff />}
								checkedIcon={<CheckCircleIcon className={classes.checkedColor} />}
								disableRipple
							/>
						}
						label={i18next.t("서술형")}
						className={classes.checkMargin}
					/>
					{/*<FormControlLabel*/}
					{/*  control={*/}
					{/*      <Checkbox*/}
					{/*        onChange={handleChangeType}*/}
					{/*        value={ProjectExamType.ESSAY}*/}
					{/*        checked={checkedTypeEssay}*/}
					{/*        icon={<CheckCircleIconOff />}*/}
					{/*        checkedIcon={<CheckCircleIcon className={classes.checkedColor} />}*/}
					{/*        disableRipple*/}
					{/*      />*/}
					{/*  }*/}
					{/*  label="논술형"*/}
					{/*  className={classes.checkMargin}*/}
					{/*/>*/}
				</Box>
				<Box>
					<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.dotSubEditerWrapBox}>
						<Box display='flex' alignItems='center' justifyContent='center' className={classes.dotSubEditerWrap} onClick={handleClickDirectiveToggle}>
							<Typography className={classes.userTitleSelect}>{i18next.t("지시문")} <span>{i18next.t("(선택)")}</span></Typography>
							<IconButton
								className={clsx(classes.iconBtnReset, classes.userTitleToggle)}
								disableRipple>
								{!openDirective
									?
									<ToggleIcon style={{ transform: "scaleY(-1)" }} />
									:
									<ToggleIcon />
								}
							</IconButton>
						</Box>

					</Box>
					{/*<Typography className={classes.userTitle}>지시문 <span>(선택)</span></Typography>*/}
					{openDirective &&
						<Box display='flex' alignItems='center' justifyContent='left' className={classes.dialogInput}>
							<TextField
								id="outlined-basic-directive"
								autoComplete="off"
								variant="outlined"
								multiline
								minRows={1}
								value={examInfo.directive}
								onChange={handleChangeDirective}
								placeholder={i18next.t("주어진 자료를 읽고 물음에 답하시오")} />
						</Box>
					}
				</Box>
				<Box>
					<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.dotSubEditerWrapBox}>
						<Box display='flex' alignItems='center' justifyContent='center' className={classes.dotSubEditerWrap} onClick={handleClickParagraphToggle}>
							<Typography className={classes.userTitleSelect}>{i18next.t("지문")} <span>{i18next.t("(선택)")}</span></Typography>
							<IconButton
								className={clsx(classes.iconBtnReset, classes.userTitleToggle)}
								disableRipple>
								{!openPassage
									?
									<ToggleIcon style={{ transform: "scaleY(-1)" }} />
									:
									<ToggleIcon />
								}
							</IconButton>

							{openPassage &&
								<Box className={classes.dotSubEditer}>
									<DotSubEditer />
								</Box>
							}

						</Box>
					</Box>

					<Box className={openPassage ? classes.editerArticle : classes.displayNone} sx={{ position: 'relative' }}>
						<TextEditor
							// height={350}
							onSetHTML={handleChangeParagraph}
							onUploadFile={onUploadFile}
							currentHTML={currentHTML}
							onInitInitialContent={handleInitInitialParagraph}
							isNowBulkLoading={props.isNowBulkLoading}
							newHtml={currentHTML}
						/>
					</Box>


					{/* FIXME: 아래의 코드가 에러가 생기는 코드, 확인해서 수정할 것, ResizeObserver loop limit exceeded 에러가 발생함, 2024-10-028 */}

					{/*
					{openPassage && (
						<Box display='flex' alignItems='center' justifyContent='left' className={classes.dialogInput} sx={{ marginLeft: "30px", marginTop: "5px" }}>
							<TextField
								id="outlined-basic-source"
								autoComplete="off"
								variant="outlined"
								multiline
								minRows={1}
								value={examInfo.source}
								onChange={handleChangeSource}
								placeholder={i18next.t("출처를 정확히 입력하세요. 평가 문항에 노출되어 학생이 볼 수 있습니다.예) 한글 맞춤법, 문화체육관광부 고시 22017-12호")} />
						</Box>
					)}
					 */}

				</Box>

				{examInfo.questions.length > 0 &&
					examInfo.questions
						.filter(question => question.changeFlag !== ChangeFlag.DELETED)
						.map((question, idx) => {
							return (
								<QuestionRow
									key={question.projectExamQuestionNum}
									questionInfo={question}
									examOrder={examOrder}
									examQuestionListLen={questionListLen}
									onUploadFile={onUploadFile}
									isNowBulkLoading={props.isNowBulkLoading}
								/>
							)
						})
				}

				<Box>
					<Button
						className={classes.editerBtnSentence}
						onClick={handleClickAddQuestion}
						disableRipple>
						<ArrowDownRight /><Typography>{i18next.t("하위 문항 추가")}</Typography>
					</Button>
				</Box>
			</Box>
		</Box>
	)
}
